import { Grid, Typography } from "@mui/material";
import React from "react";
import { formatDate } from "../../../../utils/date-converter";

const PurchaseRequestPDFInfo = ({ purchaseRequest, destinationWarehouse }) => {
  const leftHeader = [
    "ผู้ขอ / Requester",
    "แผนก / Department",
    "ตำแหน่ง / Position",
    "วันที่ออกเอกสาร / Issue Date",
    "ต้องการภายในวันที่ / Due Date",
  ];

  const leftInfo = [
    purchaseRequest?.requester_name || "-",
    purchaseRequest?.requester_department || "-",
    purchaseRequest?.requester_position || "-",
    formatDate(purchaseRequest?.issue_date) || "-",
    formatDate(purchaseRequest?.due_date) || "-",
  ];

  const rightHeader = [
    "เลขที่เอกสาร / Document No.",
    "อ้างอิงถึง / Ref. Document",
    "คลังปลายทาง / Destination WH",
  ];

  const rightInfo = [
    purchaseRequest?.document_id || "-",
    purchaseRequest?.reference_document_list &&
    purchaseRequest?.reference_document_list.length > 0
      ? purchaseRequest?.reference_document_list
          .map((ref) => ref.reference_document_id)
          .join(", ")
      : "-",
    destinationWarehouse || "-",
  ];

  return (
    <Grid container sx={{ my: 1 }}>
      <Grid item xs={3}>
        {leftHeader.map((name, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: 12,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
            }}
          >
            {name}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={3}>
        {leftInfo.map((name, index) => (
          <Typography
            ley={index}
            sx={{ fontSize: 12, lineHeight: "24px", letterSpacing: "0.15px" }}
          >
            {name}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={3}>
        {rightHeader.map((name, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: 12,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
            }}
          >
            {name}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={3}>
        {rightInfo.map((name, index) => (
          <Typography
            key={index}
            sx={{ fontSize: 12, lineHeight: "24px", letterSpacing: "0.15px" }}
          >
            {name}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default PurchaseRequestPDFInfo;
