import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReturnTable from "../../../components/Table/DocumentTable/ReturnTable";
import { getSalesReturnTab } from "../../../features/Sales/SalesOrder/sales-order-actions";
import { salesOrderActions } from "../../../features/Sales/SalesOrder/sales-order-slice";

const ReturnTab = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { salesReturnTab } = useSelector((state) => state.salesOrder);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(getSalesReturnTab(id, enqueueSnackbar));
    }
    return () => dispatch(salesOrderActions.resetSalesReturnTab());
  }, [dispatch, enqueueSnackbar, id]);

  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        {t("sales.return.index")}
      </Typography>
      <ReturnTable gridRef={gridRef} height={680} rowData={salesReturnTab} />
    </>
  );
};

export default ReturnTab;
