import styled from "@emotion/styled";
import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { formatNumber, roundDecimal } from "../../utils/dataTransformer";

const CustomizedSummary = styled(Box)({
  display: "grid",
  justifyContent: "flex-end",
  textAlign: "right",
  gridGap: "10px",
  gap: "10px",
  gridTemplateColumns:
    "minmax(min-content,max-content) minmax(6rem,max-content) 24px",
});

const PDFFooter = ({ data, documentType }) => {
  return (
    <>
      {documentType === "delivery_order" ? (
        <>
          <Box
            sx={{ mb: 2, background: "#F5F5F5", width: "100%", height: 18 }}
          />
          <Box sx={{ breakInside: "avoid" }}>
            <Box sx={{ display: "flex", gap: 0.75 }}>
              <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                หมายเหตุภายใน/Internal Remark:
              </Typography>
              <Typography sx={{ fontSize: 12 }}>
                {data.internal_remark}
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: "flex", gap: 0.75 }}>
              <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                หมายเหตุ/Note:
              </Typography>
              <Typography sx={{ fontSize: 12 }}>{data.remark}</Typography>
            </Box>
          </Box>
        </>
      ) : documentType === "delivery_trip" || documentType === "bom" ? (
        <Box sx={{ display: "flex", gap: 0.75, mt: 2, breakInside: "avoid" }}>
          <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
            หมายเหตุภายใน/Internal Remark:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>{data.internal_remark}</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ mb: 2, background: "#F5F5F5" }}>
            {documentType !== "purchase_request" ? (
              <CustomizedSummary>
                <>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    รวม
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    {formatNumber(data.pre_vat_amount)}
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    บาท
                  </Typography>
                </>
              </CustomizedSummary>
            ) : (
              <Box sx={{ mb: 2, background: "#F5F5F5", height: "16px" }} />
            )}
          </Box>
          <Grid container sx={{ breakInside: "avoid" }}>
            <Grid item xs={5}>
              <Box sx={{ display: "flex", gap: 0.75 }}>
                <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                  หมายเหตุ/Note:
                </Typography>
                <Typography sx={{ fontSize: 12 }}>{data.remark}</Typography>
              </Box>
            </Grid>
            {documentType !== "purchase_request" && (
              <Grid item xs={7}>
                <Box sx={{ mb: 1 }}>
                  <CustomizedSummary>
                    <Typography sx={{ fontSize: 12 }}>
                      ส่วนลดเพิ่มเติม / Additional Discount
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      {formatNumber(data.additional_discount)}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      {data.additional_discount_type === "percent"
                        ? "%"
                        : "บาท"}
                    </Typography>
                  </CustomizedSummary>
                  {data.additional_discount_type === "percent" && (
                    <CustomizedSummary>
                      <Box />
                      <Typography sx={{ fontSize: 12 }}>
                        {formatNumber(
                          data.pre_vat_amount * (data.additional_discount / 100)
                        )}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>บาท</Typography>
                    </CustomizedSummary>
                  )}
                  <CustomizedSummary>
                    <Typography sx={{ fontSize: 12 }}>
                      ยอดเงิน / Sub-total
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      {formatNumber(
                        roundDecimal(
                          data.pre_vat_amount -
                            (data.additional_discount_type === "percent"
                              ? data.pre_vat_amount *
                                (data.additional_discount / 100)
                              : data.additional_discount)
                        )
                      )}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>บาท</Typography>
                  </CustomizedSummary>
                </Box>
                <Box sx={{ mb: 1 }}>
                  {data.vat_exempted_amount > 0 && (
                    <CustomizedSummary>
                      <Typography sx={{ fontSize: 12 }}>
                        ยอดเงินรายการยกเว้นภาษี / VAT Exempted Amount
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        {formatNumber(data.vat_exempted_amount)}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>บาท</Typography>
                    </CustomizedSummary>
                  )}
                  {data.vat_0_amount > 0 && (
                    <CustomizedSummary>
                      <Typography sx={{ fontSize: 12 }}>
                        ยอดเงินรายการภาษี 0% / VAT 0% Amount
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        {formatNumber(data.vat_0_amount)}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>บาท</Typography>
                    </CustomizedSummary>
                  )}
                  {data.vat_7_amount > 0 && (
                    <CustomizedSummary>
                      <Typography sx={{ fontSize: 12 }}>
                        ยอดเงินรายการภาษี 7% / VAT 7% Amount
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        {formatNumber(data.vat_7_amount)}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>บาท</Typography>
                    </CustomizedSummary>
                  )}
                  <CustomizedSummary>
                    <Typography sx={{ fontSize: 12 }}>
                      ภาษีมูลค่าเพิ่มรวม / VAT Amount
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      {formatNumber(data.vat_amount)}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>บาท</Typography>
                  </CustomizedSummary>
                </Box>
                <CustomizedSummary>
                  <Typography sx={{ fontSize: 12 }}>
                    ยอดรวมสุทธิ / Net Amount
                  </Typography>
                  <Typography sx={{ fontSize: 12 }}>
                    {formatNumber(data.net_amount)}
                  </Typography>
                  <Typography sx={{ fontSize: 12 }}>บาท</Typography>
                </CustomizedSummary>
                {data.withholding_tax_amount > 0 && (
                  <CustomizedSummary>
                    <Typography sx={{ fontSize: 12 }}>
                      ภาษีหัก ณ ที่จ่าย / Withholding Tax
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      {formatNumber(data.withholding_tax_amount)}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>บาท</Typography>
                  </CustomizedSummary>
                )}
                {data.shipping_cost > 0 && (
                  <CustomizedSummary>
                    <Typography sx={{ fontSize: 12 }}>
                      ค่าส่ง / Shipping Cost
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      {formatNumber(data.shipping_cost)}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>บาท</Typography>
                  </CustomizedSummary>
                )}
                <Divider sx={{ my: 1 }} />
                <CustomizedSummary>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    จำนวนเงินรวมทั้งสิ้น (บาท) / Total Amount
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    {formatNumber(data.total_amount)}
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    บาท
                  </Typography>
                </CustomizedSummary>
                <Divider sx={{ mt: 1, mb: 0.5 }} />
                <Divider />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default PDFFooter;
