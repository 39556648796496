import * as Yup from "yup";

export const deliveryPlanSchema = {
  delivery_type: "",
  goods_deliver_list: [],
  vehicle: null,
  trip: 1,
  in_date: null,
  out_date: null,
};

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

export const deliveryPlanValidation = Yup.object().shape({
  delivery_type: Yup.string().required("กรุณาระบุรูปแบบการจัดส่ง"),
  vehicle: Yup.object().required("กรุณาระบุทะเบียนรถ").nullable(),
  trip: Yup.number().required("กรุณาระบุรอบ"),
  goods_deliver_list: Yup.array().min(1, "กรุณาเลือกผู้จัดส่งอย่างน้อย 1 คน"),
  in_date: Yup.date()
    .nullable()
    .transform((value) => dateIsValid(value)),
  out_date: Yup.date()
    .when(
      "in_date",
      (in_date, schema) =>
        in_date && schema.min(in_date, "กรุณาระบุเวลาที่มากกว่าเวลาเข้า")
    )
    .nullable()
    .transform((value) => dateIsValid(value)),
});
