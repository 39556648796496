import { manufactureActions } from "./manufacture-slice";
import SettingService from "../../../services/Setting/index";
import { errorMessageHandler } from "../../../utils/dataTransformer";

export const getAllWorkCenter = (enqueueSnackbar) => async (dispatch) => {
  dispatch(manufactureActions.onLoading("allWorkCenter"));
  try {
    const allWorkCenter = await SettingService.getAllWorkCenterWithoutAggrid();
    dispatch(manufactureActions.loadedAllWorkCenter(allWorkCenter));
  } catch (err) {
    dispatch(
      manufactureActions.rejectedActions({ ...err, name: "allWorkCenter" })
    );
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const getWorkCenterById = (input) => async (dispatch) => {
  dispatch(manufactureActions.onLoading("workCenter"));
  try {
    const workcenter = await SettingService.getWorkCenterById(input);
    dispatch(manufactureActions.loadedWorkCenter(workcenter));
  } catch (err) {
    dispatch(
      manufactureActions.rejectedActions({ ...err, name: "workCenter" })
    );
  }
};

export const createWorkCenter =
  (payload, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureActions.onLoading("workCenter"));
    try {
      const workcenter = await SettingService.createWorkCenter(payload);
      dispatch(manufactureActions.addWorkCenter(workcenter));
      enqueueSnackbar("เพิ่มศูนย์ผลิตสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      dispatch(
        manufactureActions.rejectedActions({ ...err, name: "workCenter" })
      );
      enqueueSnackbar("เพิ่มศูนย์ผลิตไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const updateWorkCenter =
  (payload, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureActions.onLoading("workCenter"));
    try {
      const workcenter = await SettingService.updateWorkCenter(payload);
      dispatch(manufactureActions.editWorkCenter(workcenter));
      enqueueSnackbar("แก้ไขข้อมูลศูนย์ผลิตสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      dispatch(
        manufactureActions.rejectedActions({ ...err, name: "workCenter" })
      );
      enqueueSnackbar("แก้ไขข้อมูลศูนย์ผลิตไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const deleteWorkCenter =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureActions.onLoading("workCenter"));
    try {
      const result = await SettingService.deleteWorkCenter(input);
      dispatch(manufactureActions.removeWorkCenter(result));
      enqueueSnackbar("ลบข้อมูลศูนย์ผลิตสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      dispatch(
        manufactureActions.rejectedActions({ ...err, name: "workCenter" })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("ลบข้อมูลศูนย์ผลิตไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };
