import { Avatar, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { filterParamsOptions } from "../../utils/filterparams";
import { formatNumberWithValidate } from "../../utils/dataTransformer";

import CustomizedLetterAvatar from "../../components/Custom/CustomizedLetterAvatar";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import CustomizedChips from "../../components/Custom/CustomizedChips";
import { unixToDateWithFormat } from "../../utils/date-converter";

const useItemNoEntryColumnDef = (
  isReport,
  getAllItemGroupLevel1,
  getAllTag
) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (isReport) {
      setColumnDefs([
        {
          field: "created_date",
          headerName: t("inventory.stockEntry.createdDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
          },
          valueFormatter: (params) => unixToDateWithFormat(params.value),
        },
        {
          field: "document_id",
          headerName: t("inventory.items.itemId"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "name",
          headerName: t("inventory.items.itemName"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "description",
          headerName: t("inventory.items.itemDescription"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "current_ordered_purchase_qty",
          headerName: t("inventory.quantities.currentOrderedPurchaseQty"),
          filter: false,
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        },
        {
          field: "current_committed_sales_qty",
          headerName: t("inventory.quantities.currentCommittedSalesQty"),
          filter: false,
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        },
        {
          field: "current_ordered_manufacture_qty",
          headerName: t("inventory.quantities.currentOrderedManufactureQty"),
          filter: false,
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        },
        {
          field: "current_stock_qty",
          headerName: t("inventory.quantities.currentStockQty"),
          filter: false,
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        },
        {
          field: "current_available_qty",
          headerName: t("inventory.quantities.currentAvailableQty"),
          filter: false,
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        },
        {
          field: "item_group_sub_level_1_name",
          headerName: t("inventory.items.itemGroup") + " " + 1,
          filter: "agSetColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
            values: async (params) => {
              // fetch values from server
              const values = await getAllItemGroupLevel1();
              const formatValues = values.map((group) => group.name);
              params.success(formatValues);
            },
          },
        },
        {
          field: "item_group_sub_level_2_name",
          headerName: t("inventory.items.itemGroup") + " " + 2,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_group_sub_level_3_name",
          headerName: t("inventory.items.itemGroup") + " " + 3,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "tag_list",
          headerName: t("inventory.items.groupTagList"),
          filter: "agSetColumnFilter",
          sortable: false,
          filterParams: {
            values: async (params) => {
              // fetch values from server
              const values = await getAllTag();
              const formatValues = values
                .filter((tag) => tag.entity === "item")
                .map((tag) => tag.name);
              params.success(formatValues);
            },
          },
          cellRenderer: (params) => {
            return (
              <Box sx={{ display: "inline-flex", mr: 1, gap: 1 }}>
                {params.value.map((tag) => (
                  <CustomizedChips key={tag} value={`${tag}`} />
                ))}
              </Box>
            );
          },
        },
        {
          field: "is_active",
          headerName: t("inventory.status"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["true", "false"],
            valueFormatter: (params) => {
              if (params.value === "true") {
                return "ใช้งาน";
              }
              return "หยุดใช้งาน";
            },
          },
          cellRenderer: (params) => (
            <CustomizedStatus status={params.value ? "active" : "inactive"} />
          ),
          cellStyle: {
            display: "flex",
            alignItems: "center",
          },
        },
        {
          field: "created_by_full_name",
          headerName: t("inventory.createdBy"),
          filter: "agSetColumnFilter",
          hide: true,
          cellRenderer: (params) => {
            if (params.data.created_by_img_url) {
              return (
                <Avatar alt="img_url" src={params.data.created_by_img_url} />
              );
            } else {
              return <CustomizedLetterAvatar name={params.value} />;
            }
          },
        },
      ]);
    }
  }, [getAllItemGroupLevel1, getAllTag, isReport, t]);
  return columnDefs;
};

export default useItemNoEntryColumnDef;
