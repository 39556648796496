import { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Box, LinearProgress } from "@mui/material";
import { contactActions } from "../../features/Contact/contact-slice";
import { resetForm } from "../../features/Contact/contact-actions";
import ContactService from "../../services/Contact";
import ContactForm from "../../components/Form/Contact/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema, validation } from "../../components/Form/Contact/schema";
import { useAuth } from "../../hooks/use-auth";
import { uploadFileToS3 } from "../../utils/s3";
import ActivityLogsService from "../../services/ActivityLogs";
import {
  createActivityLogEditPayload,
  createActivityLogPayload,
} from "../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../utils/dataTransformer";

const NewContactContainer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { contactSnapshot } = useSelector((state) => state.contact);
  const { user } = useAuth();
  const generalSectionRef = useRef();
  const [isInit, setIsInit] = useState(false);
  const [contactTypeError, setContactTypeError] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    reset,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues: { ...schema },
    resolver: yupResolver(validation),
  });
  const isEditPage = useMemo(
    () => pathname.split("/")[2] === "edit",
    [pathname]
  );

  const isVendorChecked = watch("is_vendor");
  const isCustomerChecked = watch("is_customer");

  useEffect(() => {
    if (isVendorChecked || isCustomerChecked) {
      setContactTypeError(false);
    }
  }, [isVendorChecked, isCustomerChecked]);

  useEffect(() => {
    if (!isInit) {
      if (isEditPage) {
        const document_id = pathname.split("/")[3]?.replaceAll("%20", " ");
        const loadContact = async () => {
          try {
            const loadedContact = await ContactService.getContact({
              document_id,
            });
            const transformedAddresses = loadedContact.address_list.map(
              (address) => {
                return {
                  ...address,
                  district: address.district ?? "",
                };
              }
            );
            reset({
              ...loadedContact,
              img_url: loadedContact.img_url ? loadedContact.img_url : "",
              created_date: moment.unix(loadedContact.created_date).toDate(),
              contact_type_2: "",
              registered_capital: loadedContact.registered_capital ?? "",
              estimate_sales_volume: loadedContact.estimate_sales_volume ?? "",
              address_list: transformedAddresses,
              finance: {
                ...loadedContact.finance,
                bank_list: loadedContact.finance.bank_list ?? [],
                billing_day: loadedContact.finance.billing_day ?? "",
                payment_day: loadedContact.finance.payment_day ?? "",
                request_credit: {
                  credit_limit_day:
                    loadedContact.finance.request_credit.credit_limit_day ?? "",
                  credit_limit_value:
                    loadedContact.finance.request_credit.credit_limit_value ??
                    "",
                },
              },
              contact_person_list: loadedContact.contact_person_list ?? [],
            });
            dispatch(contactActions.initialize(loadedContact));
            setIsInit(true);
          } catch (err) {
            console.error(err);
            enqueueSnackbar("ไม่พบข้อมูลผู้ติดต่อ", {
              variant: "error",
            });
            navigate("/contact/contacts", { replace: true });
          }
        };
        loadContact();
      } else {
        dispatch(contactActions.initialize(null));
      }
    } else {
      return () => dispatch(contactActions.uninitialize());
    }
  }, [
    isInit,
    reset,
    pathname,
    dispatch,
    setValue,
    isEditPage,
    enqueueSnackbar,
    navigate,
  ]);

  const formatSubmittedData = async (data) => {
    const { created_date, ...otherData } = data;
    let updatedImageUrl = otherData.img_url;
    if (typeof otherData.img_url[0] === "object") {
      try {
        const { Location } = await uploadFileToS3(
          otherData.img_url[0],
          "contact",
          user.document_id
        );
        updatedImageUrl = Location;
      } catch (err) {
        console.error("Could not upload image");
        updatedImageUrl = "";
      }
    } else if (typeof otherData.img_url[0] === "string") {
      updatedImageUrl = otherData.img_url[0];
    } else {
      updatedImageUrl = "";
    }
    const transformedContactPersons = await Promise.all(
      otherData.contact_person_list.map(async (person, index) => {
        let updatedImgUrl = person.img_url;
        if (typeof person.img_url === "object") {
          try {
            const { Location } = await uploadFileToS3(
              person.img_url,
              "contact",
              user.document_id
            );
            updatedImgUrl = Location;
          } catch (err) {
            console.error("Could not upload image", index);
            updatedImgUrl = "";
          }
        }
        return { ...person, img_url: updatedImgUrl ?? "" };
      })
    );

    const transformedAttachments = await Promise.all(
      otherData.attachment_list.map(async (attachment) => {
        // check if there's url (exists only on newly added files)
        if (typeof attachment.url === "undefined") {
          try {
            const { Location } = await uploadFileToS3(
              attachment,
              "contact",
              attachment.uploaded_by.document_id
            );
            return {
              name: attachment.attachment_name,
              url: Location,
              creator_document_id: attachment.uploaded_by.document_id,
            };
          } catch (err) {
            console.error(err);
            console.error("Could not upload attachment");
            return null;
          }
        }
        return {
          name: attachment.attachment_name ?? attachment.name,
          url: attachment.url,
          creator_document_id: attachment.uploaded_by?.document_id,
        };
      })
    );
    const filteredAttachments = transformedAttachments.filter(
      (attachment) => attachment !== null
    );

    const transformedSalesList = otherData.sales_list.map(
      ({
        id,
        status,
        role_list,
        created_date,
        created_by,
        last_login_date,
        last_login_by,
        last_updated_date,
        ...sale
      }) => sale
    );
    // check if numeric otherData is empty string or not, if so, convert to null before submitting
    const updatedContactData = {
      ...otherData,
      id: undefined,
      document_id: isEditPage ? undefined : otherData.document_id.trim(),
      created_by: undefined,
      creator_document_id: otherData.creator_document_id
        ? otherData.creator_document_id
        : user.document_id,
      last_updator_document_id: otherData.creator_document_id
        ? user.document_id
        : null,
      img_url: updatedImageUrl,
      contact_person_list: transformedContactPersons,
      attachment_list: filteredAttachments,
      sales_list: transformedSalesList,
      tag_list: otherData.tag_list.map((tag) => {
        return tag.name;
      }),
      registered_capital: otherData.registered_capital
        ? otherData.registered_capital
        : null,
      estimate_sales_volume: otherData.estimate_sales_volume
        ? otherData.estimate_sales_volume
        : null,
      finance: {
        ...otherData.finance,
        payment_day: otherData.finance.payment_day
          ? otherData.finance.payment_day
          : null,
        billing_day: otherData.finance.billing_day
          ? otherData.finance.billing_day
          : null,
        request_credit: {
          credit_limit_value: otherData.finance.request_credit
            .credit_limit_value
            ? otherData.finance.request_credit.credit_limit_value
            : undefined,
          credit_limit_day: otherData.finance.request_credit.credit_limit_day
            ? otherData.finance.request_credit.credit_limit_day
            : undefined,
        },
      },
    };
    return updatedContactData;
  };

  const onValidate = () => {
    const contactIdError = getFieldState("document_id").error;
    const contactTypeError = getFieldState("contact_type_1").error;
    const contactNameError = getFieldState("contact_name_1").error;
    const lastNameError = getFieldState("contact_name_2").error;
    const IdentityNoError = getFieldState("identity_no").error;
    const isVendorError = getFieldState("is_vendor").error;
    const isCustomerError = getFieldState("is_customer").error;
    const errorsCount =
      (contactIdError ? 1 : 0) +
      (contactTypeError ? 1 : 0) +
      (contactNameError ? 1 : 0) +
      (lastNameError ? 1 : 0) +
      (IdentityNoError ? 1 : 0) +
      (isVendorError || isCustomerError ? 1 : 0);
    if (errorsCount > 1) {
      enqueueSnackbar("กรุณากรอกข้อมูลให้ครบถ้วน", {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(contactIdError)) {
      enqueueSnackbar(contactIdError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(contactTypeError)) {
      enqueueSnackbar(contactTypeError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(contactNameError)) {
      enqueueSnackbar(contactNameError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(lastNameError)) {
      enqueueSnackbar(lastNameError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(IdentityNoError)) {
      enqueueSnackbar(IdentityNoError.message, {
        variant: "error",
      });
    }
    if (Boolean(isVendorError) || Boolean(isCustomerError)) {
      setContactTypeError(true);
      if (errorsCount === 1) {
        enqueueSnackbar("กรุณาเลือกประเภทผู้ติดต่อ", {
          variant: "error",
        });
      }
    }
    if (errorsCount > 0) {
      generalSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const submitCreateContactHandler = async (data) => {
    onValidate();
    const formattedFormData = await formatSubmittedData(data);
    try {
      await ContactService.createContact(formattedFormData);
      const createActivityLog = createActivityLogPayload(
        formattedFormData,
        "contact",
        "สร้างผู้ติดต่อ"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      enqueueSnackbar("เพิ่มผู้ติดต่อสำเร็จ", {
        variant: "success",
      });
      navigate("/contact/contacts");
    } catch (err) {
      const errorMessage =
        errorMessageHandler(
          err,
          "เลขที่ผู้ติดต่อนี้มีอยู่ในระบบแล้ว กรุณาระบุเลขที่ผู้ติดต่อใหม่"
        ) ?? "ไม่สามารถเพิ่มผู้ติดต่อ";
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  };

  const submitEditContactHandler = async (data) => {
    const { document_id } = data;
    const formattedFormData = await formatSubmittedData(data);
    try {
      await ContactService.updateContact({ document_id }, formattedFormData);
      enqueueSnackbar("แก้ไขผู้ติดต่อสำเร็จ", {
        variant: "success",
      });
      try {
        const logPayload = createActivityLogEditPayload(
          { creator_document_id: user.document_id, document_id },
          "contact"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: logPayload,
        });
      } catch (err) {
        console.error(err);
      }
      dispatch(contactActions.uninitialize());
      setIsInit(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ไม่สามารถแก้ไขผู้ติดต่อ", {
        variant: "error",
      });
    }
  };

  const resetFormHandler = async () => {
    if (contactSnapshot) {
      const transformedAddresses = contactSnapshot.address_list.map(
        (address) => {
          return {
            ...address,
            district: address.district ?? "",
          };
        }
      );
      reset({
        ...contactSnapshot,
        created_date: moment.unix(contactSnapshot.created_date).toDate(),
        contact_type_2: "",
        registered_capital: contactSnapshot.registered_capital ?? "",
        estimate_sales_volume: contactSnapshot.estimate_sales_volume ?? "",
        address_list: transformedAddresses,
        finance: {
          ...contactSnapshot.finance,
          bank_list: contactSnapshot.finance.bank_list ?? [],
          billing_day: contactSnapshot.finance.billing_day ?? "",
          payment_day: contactSnapshot.finance.payment_day ?? "",
          request_credit: {
            credit_limit_day:
              contactSnapshot.finance.request_credit.credit_limit_day ?? "",
            credit_limit_value:
              contactSnapshot.finance.request_credit.credit_limit_value ?? "",
          },
        },
        contact_person_list: contactSnapshot.contact_person_list ?? [],
      });
    } else {
      const document_id = getValues("document_id");
      reset({ ...schema, document_id });
    }
    resetForm(dispatch);
  };

  const errorHandler = () => {
    onValidate();
    console.error(errors);
  };

  return (
    <>
      {isEditPage && !isInit && (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <LinearProgress />
        </Box>
      )}
      {(!isEditPage || contactSnapshot) && (
        <ContactForm
          ref={generalSectionRef}
          control={control}
          onSubmit={handleSubmit(submitCreateContactHandler, errorHandler)}
          onSubmitEdit={handleSubmit(submitEditContactHandler, errorHandler)}
          onCancel={resetFormHandler}
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          watch={watch}
          reset={reset}
          errors={errors}
          id={pathname.split("/")[3]?.replaceAll("%20", " ")}
          contactTypeError={contactTypeError}
          isContactPage
        />
      )}
    </>
  );
};

export default NewContactContainer;
