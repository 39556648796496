import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { validation } from "../../../../pages/Inventory/Items/schema";
import { formatVatOption } from "../../../../utils/dataTransformer";
import { getItem } from "../../../../features/Inventory/ItemMaster/itemMaster-actions";
import { getAllUom } from "../../../../features/Setting/Uom/uom-actions";
import { itemMasterActions } from "../../../../features/Inventory/ItemMaster/itemMaster-slice";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import AgGrid from "../../../Table/AgGrid";
import ItemInfo from "../../../../pages/Inventory/Items/ItemInfo";
import ModalUI from "../../../UI/ModalUI";
import DeleteItemConfirmation from "../../../UI/Confirmation/DeleteItemConfirmation";
import CheckedDeliveryOrderTable from "../../../Table/CheckedDeliveryOrderTable";
import CustomizedButton from "../../../Custom/CustomizedButton";
import DeliveryTripFooter from "./DeliveryTripFooter";
import useDeliveryTripColumnDef from "../../../../hooks/Logistic/useDeliveryTripItemList";

const DeliveryTripItemListForm = ({
  control,
  setValue,
  getValues,
  errors,
  viewOnly,
  documentType,
  setHeaderValue,
  openModal,
  setOpenModal,
  deliveryOrderIds,
  setDeliveryOrderIds,
  deliveryOrderIdsSnapshot,
  setDeliveryOrderIdsSnapshot,
  deliveryPlanControl,
  isEdit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { item } = useSelector((state) => state.itemMaster);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openItemDetailModal, setOpenItemDetailModal] = useState(false);
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);

  const gridRef = useRef(null);
  const footerGridRef = useRef(null);

  const {
    control: itemInfoControl,
    handleSubmit: handleSubmitItemInfo,
    getValues: getItemInfoValues,
    setValue: setItemInfoValue,
    formState: { errors: itemInfoErrors },
    reset: resetItemInfo,
    clearErrors: clearItemInfoErrors,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    if (item.document_id !== "") {
      Object.entries(item).forEach(([key, value]) => {
        if (key === "sales_vat_type")
          setItemInfoValue(key, formatVatOption(value));
        else if (key === "attribute_list")
          setItemInfoValue(
            key,
            value.map((item) => item.attribute)
          );
        else setItemInfoValue(key, value);
      });
    }
  }, [item, setItemInfoValue]);

  const { fields, remove } = useFieldArray({
    name: "item_list",
    control,
  });

  const onRowDragMove = useCallback(
    (event) => {
      const movingNode = event.node;
      const overNode = event.overNode;
      const rowNeedsToMove = movingNode !== overNode;
      const itemList = getValues("item_list");

      const moveInArray = (arr, fromIndex, toIndex) => {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      };

      if (rowNeedsToMove) {
        // the list of rows we have is data, not row nodes, so extract the data
        const movingData = movingNode.data;
        const overData = overNode.data;
        const fromIndex = itemList
          .map((item) => item.uid)
          .indexOf(movingData.uid);
        const toIndex = itemList.map((item) => item.uid).indexOf(overData.uid);
        moveInArray(itemList, fromIndex, toIndex);
        setValue("item_list", itemList);
        gridRef.current.api.clearFocusedCell();
      }
    },
    [getValues, gridRef, setValue]
  );

  const getRowId = useCallback((params) => {
    return params.data.uid + (params.data.do_reference_document_id || "");
  }, []);

  const openDeleteItemConfirmationHandler = (index) => {
    setDeletedIndex(index);
    setOpenDeleteItemModal(true);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setDeletedIndex(null);
    setOpenDeleteItemModal(false);
  };

  const handleRemoveItems = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const handleShowItemModal = (data) => {
    dispatch(getItem({ document_id: data.item_document_id }, enqueueSnackbar));
    dispatch(getAllUom());
    setOpenItemDetailModal(true);
  };

  const handleCloseItemDetailModal = () => {
    searchParams.delete("itemtab");
    setSearchParams(searchParams);
    clearItemInfoErrors();
    setOpenItemDetailModal(false);
    dispatch(itemMasterActions.resetItem());
  };

  const columnDefs = useDeliveryTripColumnDef(
    control,
    errors,
    getValues,
    setValue,
    handleShowItemModal,
    openDeleteItemConfirmationHandler,
    viewOnly,
    false
  );

  return (
    <>
      <CustomizedBox
        margin="0 0rem 2rem 0rem"
        radius="0"
        padding="0"
        boxShadow="0px 2px 7px #E5E5E5"
        clipPath="inset(0 -15px -2px -15px)"
      >
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={fields}
          onRowDragMove={onRowDragMove}
          rowDragManaged={true}
          getRowId={getRowId}
          suppressMenu
          disabledSidebar={true}
          disableFloatingFilter
          suppressContextMenu
          autoHeight
          headerClass={["center"]}
          isClientSide
          disableResized
          alignedGrids={
            footerGridRef.current ? [footerGridRef.current] : undefined
          }
          suppressHorizontalScroll
        />
        <DeliveryTripFooter
          control={control}
          errors={errors}
          footerGridRef={footerGridRef}
          getValues={getValues}
          gridRef={gridRef}
          handleShowItemModal={handleCloseItemDetailModal}
          openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
          setValue={setValue}
          viewOnly={viewOnly}
          deliveryPlanControl={deliveryPlanControl}
        />

        {!viewOnly && (
          <CustomizedButton
            sx={{ m: 3 }}
            title="นำเข้าใบส่งของ"
            variant="outlined"
            onClick={() => setOpenModal(true)}
            color="secondary"
          />
        )}
      </CustomizedBox>
      <ModalUI
        open={openItemDetailModal}
        handleClose={handleCloseItemDetailModal}
        navigateTo={`/inventory/items/${item.document_id}`}
        title="รายละเอียดสินค้า"
        fullWidth
      >
        <ItemInfo
          control={itemInfoControl}
          errors={itemInfoErrors}
          getValues={getItemInfoValues}
          handleSubmit={handleSubmitItemInfo}
          reset={resetItemInfo}
          setValue={setItemInfoValue}
          onCancel={handleCloseItemDetailModal}
          viewOnly={true}
        />
      </ModalUI>
      <DeleteItemConfirmation
        documentType={documentType}
        openDeleteItemConfirmation={openDeleteItemModal}
        closeDeleteItemConfirmationHandler={closeDeleteItemConfirmationHandler}
        deleteItemConfirmationAction={handleRemoveItems}
      />
      <CheckedDeliveryOrderTable
        getValues={getValues}
        setValue={setValue}
        setHeaderValue={setHeaderValue}
        openModal={openModal}
        setOpenModal={setOpenModal}
        deliveryOrderIds={deliveryOrderIds}
        setDeliveryOrderIds={setDeliveryOrderIds}
        deliveryOrderIdsSnapshot={deliveryOrderIdsSnapshot}
        setDeliveryOrderIdsSnapshot={setDeliveryOrderIdsSnapshot}
        isEdit={isEdit}
      />
    </>
  );
};

export default DeliveryTripItemListForm;
