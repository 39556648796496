import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../components/Custom/CustomizedMenuBox";
import { useAuth, usePermission } from "../../hooks/use-auth";

const User = () => {
  const { permissions } = useAuth();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [allPermissionList, setAllPermissionList] = useState([]);

  const breadcrumbs = [
    {
      name: t("user.index"),
    },
  ];

  const isAdmin = "ADMIN__ADMIN__ADMIN";

  useEffect(() => {
    setAllPermissionList(permissions);
  }, [permissions]);
  const { accountPermission, rbacPermission } = usePermission();

  const menuList = [
    allPermissionList.some((permission) =>
      [accountPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("user.account.index"),
      navigateTo: "account",
    },
    allPermissionList.some((permission) =>
      [rbacPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("user.rbac.index"),
      navigateTo: "rbac",
    },
    {
      title: t("user.setting.index"),
      navigateTo: "setting",
    },
  ];

  const formatMenuList = menuList.filter((menu) => menu !== false);

  const renderMenuList = () =>
    formatMenuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          isGridItem
          title={menu.title}
          linkTo={`${pathname}/${menu.navigateTo}`}
        />
      </Grid>
    ));

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2, textAlign: "-webkit-center" }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default User;
