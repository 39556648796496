import { customHeaderColRender } from "../../../utils/customCellRenderer";
import { Box, Typography } from "@mui/material";
import AgGrid from "../AgGrid";
import { useSelector } from "react-redux";
import { forwardRef } from "react";

const customCellStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 6px",
};

const PurchaseItemList = forwardRef((props, ref) => {
  const { purchaseRequest } = useSelector((state) => state.purchaseRequest);
  const columnDefs = [
    {
      field: "no",
      headerComponentParams: {
        template: customHeaderColRender("No.", "รายการ"),
      },
      valueGetter: (params) => params.node.rowIndex + 1,
      cellStyle: customCellStyle,
      width: 90,
      checkboxSelection: true,
    },
    {
      field: "item_name",
      headerComponentParams: {
        template: customHeaderColRender("Name", "ชื่อสินค้า"),
      },
      cellRenderer: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "stretch",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "stretch",
              flex: 1,
              textAlign: "left",
            }}
          >
            <Typography variant="body2" align="left" mt={1} color="primary">
              {params.data.item_name}
            </Typography>
            <Typography
              variant="overline2"
              sx={{
                my: 0,
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              {params.data.item_document_id}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              gutterBottom
              sx={{
                whiteSpace: "normal",
              }}
            >
              {params.data.item_description}
            </Typography>
          </Box>
        </Box>
      ),
      autoHeight: true,
      cellStyle: {
        padding: "0 6px",
      },
      width: 250,
    },
    {
      field: "qty",
      headerComponentParams: {
        template: customHeaderColRender("Qty", "จำนวน"),
      },
      cellRenderer: (params) => (
        <Typography variant="body2" align="center">
          {params.data.qty}
        </Typography>
      ),
      cellStyle: customCellStyle,
      width: 160,
    },
    {
      field: "uom_id",
      headerComponentParams: {
        template: customHeaderColRender("Unit", "หน่วย"),
      },
      cellRenderer: (params) => (
        <Typography variant="body2" align="center">
          {params.data.uom.name}
        </Typography>
      ),
      cellStyle: customCellStyle,
      width: 200,
    },
    {
      field: "ordered_qty",
      headerComponentParams: {
        template: customHeaderColRender("PO Qty", "สั่งซื้อแล้ว"),
      },
      cellStyle: customCellStyle,
      valueFormatter: (params) => params.value ?? 0,
      width: 130,
    },
    {
      field: "remark",
      headerComponentParams: {
        template: customHeaderColRender("Remark", "หมายเหตุ"),
      },
      cellRenderer: (params) => (
        <Typography variant="body2" align="center">
          {params.data.item_remark}
        </Typography>
      ),
      cellStyle: customCellStyle,
      width: 250,
    },
  ];
  return (
    <AgGrid
      ref={ref}
      columnDefs={columnDefs}
      rowData={purchaseRequest.item_list || []}
      isClientSide
      suppressMenu
      disabledSidebar
      disableFloatingFilter
      suppressContextMenu
      rowSelection="multiple"
      rowMultiSelectWithClick
      height={300}
    />
  );
});

export default PurchaseItemList;
