import moment from "moment";
import React from "react";

import {
  Box,
  Chip,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";

import { CustomizedTooltip } from "../Custom/CustomizedTooltip";

import InfoIcon from "@mui/icons-material/Info";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";

import {
  unixToDateTimeWithFormat,
  unixToDateWithFormat,
} from "../../utils/date-converter";

const TimeStampReportLayout = ({
  fetching,
  lastTimestamp,
  fetchTimestamp,
  detail,
  genDetail,
  generateReportHandler,
  documentType,
}) => {
  const mapFieldName = (name) => {
    switch (name) {
      case "document_date":
        return documentType === "gr" ? "วันที่นำเข้า" : "วันที่นำออก";
      case "created_date":
        return documentType === "entry" ? "วันที่สร้าง" : "วันที่ออกเอกสาร";
      case "delivery_date":
        return "วันกำหนดส่งของ";
      case "issue_date":
        return "วันที่ออกเอกสาร";
      default:
        return documentType === "entry" ? "วันที่สร้าง" : "วันที่ออกเอกสาร";
    }
  };

  const RenderDetail = () =>
    detail && detail.date_filter ? (
      <Box display="flex" gap={1}>
        <Typography variant="body2" alignSelf="center">
          ชื่อตัวกรอง:
        </Typography>
        <Typography variant="body2" alignSelf="center">
          {mapFieldName(detail?.date_filter?.date_field_name)},
        </Typography>
        <Typography variant="body2" alignSelf="center">
          วันที่:
        </Typography>
        <Typography variant="body2" alignSelf="center">
          {unixToDateWithFormat(detail?.date_filter?.start_date)} -{" "}
          {unixToDateWithFormat(detail?.date_filter?.end_date)}
        </Typography>
      </Box>
    ) : (
      detail &&
      detail.server_params && (
        <Box display="flex" gap={1}>
          <Typography variant="body2" alignSelf="center">
            ชื่อตัวกรอง:
          </Typography>
          <Typography variant="body2" alignSelf="center">
            {Object.keys(detail.server_params).map((key) => mapFieldName(key))}
          </Typography>
          <Typography variant="body2" alignSelf="center">
            วันที่:
          </Typography>
          <Typography variant="body2" alignSelf="center">
            {Object.entries(detail.server_params).map(
              ([key, value]) =>
                value["start_date"] && unixToDateWithFormat(value["start_date"])
            )}
            {Object.entries(detail.server_params).map(
              ([key, value]) => ` - ${unixToDateWithFormat(value["end_date"])}`
            )}
          </Typography>
        </Box>
      )
    );

  const RenderGenDetail = () =>
    genDetail &&
    genDetail.date_filter && (
      <Box display="flex" gap={1}>
        <Typography variant="body2" alignSelf="center">
          ชื่อตัวกรอง:
        </Typography>
        <Typography variant="body2" alignSelf="center">
          {mapFieldName(genDetail?.date_filter?.date_field_name)},
        </Typography>
        <Typography variant="body2" alignSelf="center">
          วันที่:
        </Typography>
        <Typography variant="body2" alignSelf="center">
          {unixToDateWithFormat(genDetail?.date_filter?.start_date)} -{" "}
          {unixToDateWithFormat(genDetail?.date_filter?.end_date)}
        </Typography>
      </Box>
    );

  return (
    <Box
      sx={{
        mb: 3,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box display="flex" gap={1} alignItems="center">
        <CustomizedTooltip
          title="ระบบจะส่งคำขอข้อมูลให้อัตโนมัติทุกๆ 30 นาที หากต้องการข้อมูลก่อนคำขออัตโนมัติสามารถกดส่งคำขอข้อมูล"
          placement="top"
        >
          <IconButton sx={{ padding: 0, my: 0.5 }} size="small">
            <InfoIcon />
          </IconButton>
        </CustomizedTooltip>
        <Box display="flex" gap={1}>
          <Typography variant="body2" alignSelf="center">
            ข้อมูลล่าสุด:
          </Typography>
          <CustomizedTooltip
            title={`${unixToDateTimeWithFormat(lastTimestamp)}`}
            placement="top"
          >
            <Chip
              label={
                lastTimestamp
                  ? ` ${moment(lastTimestamp * 1000).fromNow()}`
                  : "-"
              }
            />
          </CustomizedTooltip>
        </Box>
        <RenderDetail />
      </Box>
      <Box display="flex" gap={1} alignItems="center">
        <CustomizedTooltip title="ส่งคำขอข้อมูล">
          {fetching ? (
            <CircularProgress size={18} sx={{ ml: 0.25, mt: 0.25 }} />
          ) : (
            <IconButton
              onClick={generateReportHandler}
              sx={{ padding: 0 }}
              color="primary"
              size="small"
            >
              <LoopOutlinedIcon />
            </IconButton>
          )}
        </CustomizedTooltip>
        <Box display={"flex"} gap={1}>
          <Typography variant="body2" alignSelf="center">
            ส่งคำขอล่าสุด:
          </Typography>
          <CustomizedTooltip title="" placement="top">
            <Chip
              label={fetchTimestamp ? moment(fetchTimestamp).fromNow() : " - "}
            />
          </CustomizedTooltip>
        </Box>
        <RenderGenDetail />
      </Box>
    </Box>
  );
};

export default TimeStampReportLayout;
