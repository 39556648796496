import React, { useRef, forwardRef } from "react";
import CustomerDescriptionForm from "../../../components/Form/Sales/CustomerDescription";
import AddItemListForm from "../../../components/Form/Sales/AddItemListForm";
import Summary from "../../../components/Form/Sales/Summary";
import InternalRemark from "../../../components/Form/Logistic/InternalRemark";
import DeliveryDetails from "../../../components/Form/Logistic/DeliveryDetails";
import ModalUI from "../../../components/UI/ModalUI";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Controller } from "react-hook-form";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";

const DocumentInfoTab = forwardRef(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      submitCustomerDesc,
      customerDescUseForm,
      contactUseForm,
      deliveryDetailsUseForm,
      viewOnly,
      status,
      openDeliveryDetail,
      onDeliveryDetailCloseHandler,
      submitDeliveryDetail,
      itemListRef,
      submitForm,
      reset,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const gridRef = useRef();
    const {
      control: customerDescControl,
      setValue: setCustomerDescValue,
      getValues: getCustomerDescValues,
      formState: { errors: customerDescErrors },
    } = customerDescUseForm;

    const {
      control: deliveryDetailsControl,
      setValue: setDeliveryDetailsValue,
      getValues: getDeliveryDetailsValues,
      formState: { errors: deliveryDetailsErrors },
    } = deliveryDetailsUseForm;

    const priceVatTypeOption = [
      {
        id: 1,
        label: t("sales.includedVat"),
        value: "included_vat",
      },
      {
        id: 2,
        label: t("sales.excludedVat"),
        value: "excluded_vat",
      },
    ];

    return (
      <>
        <CustomerDescriptionForm
          ref={ref}
          mainControl={control}
          control={customerDescControl}
          onSubmitContact={submitCustomerDesc}
          setValue={setCustomerDescValue}
          getValues={getCustomerDescValues}
          getMainValues={getValues}
          errors={customerDescErrors}
          contactUseForm={contactUseForm}
          viewOnly={viewOnly}
          isDeliveryOrder
        />
        <Box sx={{ mb: 2 }}>
          <Controller
            name="price_vat_type"
            control={control}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                sx={{ width: 200 }}
                error={Boolean(errors.price_vat_type)}
                helperText={errors.price_vat_type?.message}
                label={t("sales.priceVatType")}
                options={priceVatTypeOption}
                disabled={viewOnly}
              />
            )}
          />
        </Box>
        <AddItemListForm
          itemListRef={itemListRef}
          gridRef={gridRef}
          contactUseForm={contactUseForm}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          draggable
          documentType="do"
        />
        <Summary
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          documentType="do"
        />
        <InternalRemark control={control} disabled={viewOnly} />
        {status === "completed" && (
          <DeliveryDetails
            onSubmit={submitDeliveryDetail}
            viewOnly={status === "completed" || status === "notCompleted"}
            control={deliveryDetailsControl}
            errors={deliveryDetailsErrors}
            setValue={setDeliveryDetailsValue}
            getValues={getDeliveryDetailsValues}
            status={status}
          />
        )}
        <ModalUI
          maxWidth="sm"
          title={t("logistic.deliveryOrder.deliveryDetails")}
          open={openDeliveryDetail}
          handleClose={onDeliveryDetailCloseHandler}
        >
          <DeliveryDetails
            onSubmit={submitDeliveryDetail}
            viewOnly={status === "completed" || status === "not_completed"}
            control={deliveryDetailsControl}
            errors={deliveryDetailsErrors}
            setValue={setDeliveryDetailsValue}
            getValues={getDeliveryDetailsValues}
            onDeliveryDetailCloseHandler={onDeliveryDetailCloseHandler}
            open={openDeliveryDetail}
            status={status}
            submitForm={submitForm}
            reset={reset}
          />
        </ModalUI>
      </>
    );
  }
);

export default DocumentInfoTab;
