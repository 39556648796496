import React, { useRef, forwardRef } from "react";
import { Controller, useWatch } from "react-hook-form";
import CustomerDescriptionForm from "../../../components/Form/Sales/CustomerDescription";
import ProofForm from "../../../components/Form/Sales/Proof";
import AddItemListForm from "../../../components/Form/Sales/AddItemListForm";
import Summary from "../../../components/Form/Sales/Summary";
import ModalUI from "../../../components/UI/ModalUI";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import { useTranslation } from "react-i18next";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";

const DocumentInfoTab = forwardRef(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      submitCustomerDesc,
      customerDescUseForm,
      contactUseForm,
      acceptProofUseForm,
      onSubmitAcceptProof,
      viewOnly,
      openAcceptProof,
      onAcceptProofCloseHandler,
      itemListRef,
      setHeaderValue,
    },
    ref
  ) => {
    const gridRef = useRef();
    const { t } = useTranslation();

    const priceVatTypeOption = [
      {
        id: 1,
        label: t("sales.includedVat"),
        value: "included_vat",
      },
      {
        id: 2,
        label: t("sales.excludedVat"),
        value: "excluded_vat",
      },
    ];

    const {
      control: customerDescControl,
      setValue: setCustomerDescValue,
      getValues: getCustomerDescValues,
      formState: { errors: customerDescErrors },
    } = customerDescUseForm;

    const {
      control: acceptProofControl,
      setValue: setAcceptProofValue,
      getValues: getAcceptProofValues,
      formState: { errors: acceptProofErrors },
    } = acceptProofUseForm;

    const watchStatus = useWatch({ control: control, name: "render_status" });

    const shouldShowAcceptProofForm = () => {
      switch (watchStatus) {
        case "accepted":
        case "finished":
          return true;
        default:
          return false;
      }
    };

    const viewOnlyAcceptProof = () => {
      switch (watchStatus) {
        case "accepted":
        case "finished":
          return true;
        default:
          return false;
      }
    };

    return (
      <>
        <CustomerDescriptionForm
          ref={ref}
          mainControl={control}
          control={customerDescControl}
          onSubmitContact={submitCustomerDesc}
          setValue={setCustomerDescValue}
          getValues={getCustomerDescValues}
          getMainValues={getValues}
          errors={customerDescErrors}
          contactUseForm={contactUseForm}
          viewOnly={viewOnly}
          setHeaderValue={setHeaderValue}
        />
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "flex-start",
            gap: 0.5,
          }}
        >
          <Box sx={{ width: 200 }}>
            <Controller
              name="price_vat_type"
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  {...field}
                  error={Boolean(errors.price_vat_type)}
                  helperText={errors.price_vat_type?.message}
                  label={t("sales.priceVatType")}
                  options={priceVatTypeOption}
                  disabled={viewOnly}
                />
              )}
            />
          </Box>
          <CustomizedTooltip
            title={
              <Box>
                <Typography sx={{ fontSize: 12 }}>
                  ราคาแยกภาษี คือ ราคาขาย + ภาษีมูลค่าเพิ่ม 7%
                </Typography>
                <Divider variant="middle" sx={{ my: 0.5 }} />
                <Typography sx={{ fontSize: 12 }}>
                  ราคารวมภาษี คือ ราคาขายรวมภาษีมูลค่าเพิ่ม 7% แล้ว
                </Typography>
              </Box>
            }
          >
            <IconButton>
              <ErrorOutlineOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
        <AddItemListForm
          itemListRef={itemListRef}
          gridRef={gridRef}
          contactUseForm={contactUseForm}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          draggable
          documentType="qa"
        />
        <Summary
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          documentType="qa"
        />
        {shouldShowAcceptProofForm() && (
          <ProofForm
            onSubmit={onSubmitAcceptProof}
            control={acceptProofControl}
            setValue={setAcceptProofValue}
            getValues={getAcceptProofValues}
            errors={acceptProofErrors}
            type="accepted"
            viewOnly={viewOnlyAcceptProof()}
          />
        )}
        <ModalUI
          maxWidth="sm"
          title="ยอมรับใบเสนอราคา"
          open={openAcceptProof}
          handleClose={onAcceptProofCloseHandler}
        >
          <ProofForm
            onSubmit={onSubmitAcceptProof}
            control={acceptProofControl}
            setValue={setAcceptProofValue}
            getValues={getAcceptProofValues}
            errors={acceptProofErrors}
            type="accepted"
            open={openAcceptProof}
            viewOnly={viewOnlyAcceptProof()}
          />
        </ModalUI>
      </>
    );
  }
);

export default DocumentInfoTab;
