import { forwardRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import GeneralForm from "./general";
import ContactChannelForm from "./ContactChannelForm";
import CustomizedButton from "../../../Custom/CustomizedButton";
import AddressForm from "../../Contact/AddressTab/AddressTab";

const CompanyInfoSettingForm = forwardRef(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      watch,
      onReset,
      onSubmit,
      editPermission,
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <>
        <Accordion
          sx={{ mt: 3, borderRadius: "15px", boxShadow: "0px 2px 7px #E5E5E5" }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">
              {t("setting.companyInfo")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GeneralForm
              ref={ref}
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              disabled={!editPermission}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ mt: 3, borderRadius: "15px", boxShadow: "0px 2px 7px #E5E5E5" }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">
              {t("contact.contact_channel.index")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ContactChannelForm
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              disabled={!editPermission}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{ mt: 3, borderRadius: "15px", boxShadow: "0px 2px 7px #E5E5E5" }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">
              {t("contact.address.index")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddressForm
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              viewOnly={!editPermission}
            />
          </AccordionDetails>
        </Accordion>
        {editPermission && (
          <Box
            sx={{
              mt: 3,
              display: "flex",
            }}
          >
            <Box sx={{ mr: 1 }}>
              <CustomizedButton
                title={t("button.cancel")}
                variant="outlined"
                type="reset"
                size="medium"
                onClick={onReset}
              />
            </Box>
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              size="medium"
              onClick={onSubmit}
            />
          </Box>
        )}
      </>
    );
  }
);

export default CompanyInfoSettingForm;
