import { createSlice } from "@reduxjs/toolkit";
import { workOrderInitialState as initialState } from "./work-order-initial";

const workOrderSlice = createSlice({
  name: "workOrder",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllWorkOrders(state, action) {
      state.allWorkOrders = action.payload;
      state.isLoading.allWorkOrders = false;
    },
    loadedAllWorkOrdersCalendar(state, action) {
      state.allWorkOrdersCalendar = action.payload;
      state.isLoading.allWorkOrders = false;
    },
    loadedAllWorkOrdersExport(state, action) {
      state.allWorkOrdersExport = action.payload;
      state.isLoading.allWorkOrdersExport = false;
    },
    loadedWorkOrder(state, action) {
      state.workOrder = action.payload;
      state.isLoading.workOrder = false;
    },
    resetAllWorkOrders(state) {
      state.allWorkOrders = initialState.allWorkOrders;
      state.error = null;
    },
    resetAllWorkOrdersExport(state) {
      state.allWorkOrdersExport = initialState.allWorkOrdersExport;
      state.error = null;
    },
    resetAllWorkOrdersCalendar(state) {
      state.allWorkOrdersCalendar = initialState.allWorkOrdersCalendar;
      state.error = null;
    },
    resetWorkOrder(state) {
      state.workOrder = initialState.workOrder;
      state.isLoading.workOrder = false;
      state.error = null;
    },
  },
});

export const workOrderActions = workOrderSlice.actions;

export default workOrderSlice.reducer;
