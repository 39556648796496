import React from "react";
import AgGrid from "../AgGrid";
import { useItemListColumns } from "../../../hooks/use-item-list-columns";

const ItemListTable = ({ gridRef, itemList, documentType, viewOnly }) => {
  const columnDefs = useItemListColumns(documentType, viewOnly);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      rowData={itemList ?? []}
      suppressMenu
      disabledSidebar
      disableFloatingFilter
      suppressContextMenu
      height={500}
      isClientSide
      rowSelection={
        documentType === "qa" || documentType === "so" || documentType === "mo"
          ? "single"
          : "multiple"
      }
      rowMultiSelectWithClick={
        documentType !== "qa" || documentType !== "so" || documentType !== "mo"
      }
      paginationSize={(documentType === "qa" || documentType === "so") && 5}
    />
  );
};

export default ItemListTable;
