import { Grid, Typography } from "@mui/material";
import React from "react";
import { unixToDateWithFormat } from "../../../../utils/date-converter";
import { formatNumber } from "../../../../utils/dataTransformer";

const BomPDFInfo = ({ bom, t }) => {
  const leftHeader = [
    t("manufacture.bom.bom_name"),
    t("manufacture.bom.bom_description"),
    t("manufacture.bom.item_document_id"),
    t("manufacture.bom.item_name"),
    t("manufacture.bom.mfg_qty"),
    t("manufacture.bom.stock_uom"),
    t("manufacture.bom.type"),
  ];

  const leftInfo = [
    bom?.name || "-",
    bom?.description || "-",
    bom?.item_document_id || "-",
    bom?.item_name || "-",
    bom?.mfg_qty ? formatNumber(bom?.mfg_qty) : "-",
    bom?.stock_uom || "-",
    bom?.type || "-",
  ];

  const rightHeader = [
    t("manufacture.bom.created_date"),
    t("manufacture.bom.start_date"),
    t("manufacture.bom.end_date"),
  ];

  const rightInfo = [
    unixToDateWithFormat(bom?.created_date) || "-",
    bom?.start_date ? unixToDateWithFormat(bom?.start_date) : "-",
    bom?.end_date ? unixToDateWithFormat(bom?.end_date) : "-",
  ];

  return (
    <Grid container sx={{ my: 1 }}>
      <Grid item xs={3}>
        {leftHeader.map((name, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: 12,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
            }}
          >
            {name}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={3}>
        {leftInfo.map((name, index) => (
          <Typography
            ley={index}
            sx={{ fontSize: 12, lineHeight: "24px", letterSpacing: "0.15px" }}
          >
            {name}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={3}>
        {rightHeader.map((name, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: 12,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
            }}
          >
            {name}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={3}>
        {rightInfo.map((name, index) => (
          <Typography
            key={index}
            sx={{ fontSize: 12, lineHeight: "24px", letterSpacing: "0.15px" }}
          >
            {name}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default BomPDFInfo;
