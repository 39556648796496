import React, { forwardRef } from "react";
import CustomerDescriptionForm from "../../../components/Form/Sales/CustomerDescription";
import AddItemListForm from "../../../components/Form/Sales/AddItemListForm";
import Summary from "../../../components/Form/Sales/Summary";
import { Controller } from "react-hook-form";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import { useTranslation } from "react-i18next";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";

const DocumentInfoTab = forwardRef(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      submitCustomerDesc,
      customerDescUseForm,
      contactUseForm,
      viewOnly,
      itemListRef,
      setHeaderValue,
      getHeaderValues,
      gridRef,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const {
      control: customerDescControl,
      setValue: setCustomerDescValue,
      getValues: getCustomerDescValues,
      formState: { errors: customerDescErrors },
    } = customerDescUseForm;

    const priceVatTypeOption = [
      {
        id: 1,
        label: t("sales.includedVat"),
        value: "included_vat",
      },
      {
        id: 2,
        label: t("sales.excludedVat"),
        value: "excluded_vat",
      },
    ];

    return (
      <>
        <CustomerDescriptionForm
          ref={ref}
          mainControl={control}
          control={customerDescControl}
          onSubmitContact={submitCustomerDesc}
          setValue={setCustomerDescValue}
          getValues={getCustomerDescValues}
          getMainValues={getValues}
          errors={customerDescErrors}
          contactUseForm={contactUseForm}
          viewOnly={viewOnly}
          setHeaderValue={setHeaderValue}
          getHeaderValues={getHeaderValues}
          documentType="so"
        />
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "flex-start",
            gap: 0.5,
          }}
        >
          <Box sx={{ width: 200 }}>
            <Controller
              name="price_vat_type"
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  {...field}
                  error={Boolean(errors.price_vat_type)}
                  helperText={errors.price_vat_type?.message}
                  label={t("sales.priceVatType")}
                  options={priceVatTypeOption}
                  disabled={viewOnly}
                />
              )}
            />
          </Box>
          <CustomizedTooltip
            title={
              <Box>
                <Typography sx={{ fontSize: 12 }}>
                  ราคาแยกภาษี คือ ราคาขาย + ภาษีมูลค่าเพิ่ม 7%
                </Typography>
                <Divider variant="middle" sx={{ my: 0.5 }} />
                <Typography sx={{ fontSize: 12 }}>
                  ราคารวมภาษี คือ ราคาขายรวมภาษีมูลค่าเพิ่ม 7% แล้ว
                </Typography>
              </Box>
            }
          >
            <IconButton>
              <ErrorOutlineOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
        <AddItemListForm
          itemListRef={itemListRef}
          gridRef={gridRef}
          control={control}
          errors={errors}
          contactUseForm={contactUseForm}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          documentType="so"
          draggable
        />
        <Summary
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          documentType="so"
        />
      </>
    );
  }
);

export default DocumentInfoTab;
