import { useEffect, useState } from "react";
import ModalUI from "../components/UI/ModalUI";
import { Box, DialogContentText } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../components/Custom/CustomizedButton";

const OnlineVerify = () => {
  const { t } = useTranslation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const InternetErrMessagenger = () => setIsOnline(navigator.onLine === true); // for do like this shortform

  useEffect(() => {
    let interval = null;
    interval = setInterval(InternetErrMessagenger, 10000); // call the function name only not with function with call `()`
    return () => {
      clearInterval(interval); // for component unmount stop the interval
    };
  }, []);

  const handleClose = () => {
    setIsOnline(true);
    setTimeout(() => InternetErrMessagenger(), 500);
  };

  return (
    <ModalUI
      title={t("sentence.connection_error_title")}
      handleClose={handleClose}
      open={!isOnline}
      maxWidth="xs"
      centered
    >
      <DialogContentText sx={{ textAlign: "center" }}>
        {t("sentence.connection_error_message")}
      </DialogContentText>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 3,
        }}
      >
        <CustomizedButton
          title="ตกลง"
          variant="contained"
          onClick={handleClose}
          fullWidth
        />
      </Box>
    </ModalUI>
  );
};

export default OnlineVerify;
