import * as Yup from "yup";

export const initialState = {
  isLoading: {
    allVehicles: false,
    allVehiclesModal: false,
    vehicle: false,
  },
  allVehicles: [],
  vehicle: {
    license_plate: "",
    vehicle_type: "",
    status: "active",
    remark: "",
    remark_status: "",
    volume: 0,
    weight: 0,
  },
  error: null,
};

export const vehicleValidation = Yup.object().shape({
  license_plate: Yup.string().required("กรุณาระบุทะเบียนรถ"),
  vehicle_type: Yup.string().required("กรุณาระบุประเภทรถ"),
  volume: Yup.number().required("กรุณาระบุปริมาตร"),
  weight: Yup.number().required("กรุณาระบุน้ำหนัก"),
});
