import {
  //   Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const DeliveryOrderPdf = ({ itemList }) => {
  const header = [
    {
      label: "No.",
      thaiLabel: "รายการ",
      width: 55,
    },
    {
      label: "Name",
      thaiLabel: "ชื่อสินค้า",
      width: 260,
    },
    {
      label: "Qty",
      thaiLabel: "จำนวน",
      width: 100,
    },
    {
      label: "Unit",
      thaiLabel: "หน่วย",
      width: 120,
    },
    {
      label: "Product Remark",
      thaiLabel: "หมายเหตุสินค้า",
      width: 180,
    },
  ];
  return (
    <TableContainer>
      <Table sx={{ overflow: "scroll" }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#e8f1e8" }}>
          <TableRow>
            {header.map((header, index) => (
              <TableCell
                align="center"
                key={index}
                sx={{
                  px: 0.25,
                  py: 1,
                }}
              >
                <Typography width={header.width} fontSize={12} fontWeight={600}>
                  {header.thaiLabel}
                </Typography>
                <Typography width={header.width} fontSize={12} fontWeight={600}>
                  {header.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {itemList.map((item, index) => (
            <TableRow key={item.uid} sx={{ breakInside: "avoid" }}>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{index + 1}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12} fontWeight={600}>
                  {item.item_name}
                </Typography>
                {/* <Divider sx={{ my: 1 }} /> */}
                <Typography fontSize={12} whiteSpace="pre-line">
                  {item.item_description}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {item.qty.toLocaleString()}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{item.uom.name}</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{item.item_remark || "-"}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeliveryOrderPdf;
