import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./sales-invoice-initial";

const salesInvoiceSlice = createSlice({
  name: "salesInvoice",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllSalesInvoices(state, action) {
      state.allSalesInvoices = action.payload;
      state.isLoading.allSalesInvoices = false;
    },
    loadedSalesInvoice(state, action) {
      state.salesInvoice = action.payload;
      state.isLoading.salesInvoice = false;
    },
    // loadedApprovalList(state, action) {
    //   const { approvalProgress } = action.payload;
    //   const sortedApprovalProgress =
    //     approvalProgress &&
    //     approvalProgress.length > 0 &&
    //     approvalProgress.sort((a, b) => a.created_date - b.created_date);

    //   state.approvalList = sortedApprovalProgress;
    // },
    loadedPaymentReceiptTab(state, action) {
      state.paymentReceiptTab = action.payload;
      state.isLoading.salesInvoice = false;
    },
    loadedCreditNoteTab(state, action) {
      state.creditNoteTab = action.payload;
      state.isLoading.salesInvoice = false;
    },
    updateSalesInvoiceStatus(state, action) {
      state.salesInvoice.render_status = action.payload;
      state.isLoading.salesInvoice = false;
    },
    resetAllSalesInvoices(state) {
      state.allSalesInvoices = initialState.allSalesInvoices;
      state.error = initialState.error;
    },
    resetSalesInvoice(state) {
      state.salesInvoice = initialState.salesInvoice;
      state.paymentReceiptTab = initialState.paymentReceiptTab;
      state.creditNoteTab = initialState.creditNoteTab;
      state.error = initialState.error;
    },
    resetSalesInvoiceError(state) {
      state.error = initialState.error;
    },
  },
});

export const salesInvoiceActions = salesInvoiceSlice.actions;

export default salesInvoiceSlice.reducer;
