import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PDFFooter from "../../../../components/UI/PDFFooter";
import PDFHeader from "../../../../components/UI/PDFHeader";
import BomPDFInfo from "./Info";
import { useReactToPrint } from "react-to-print";
import PDFSigner from "../../../../components/UI/PDFSigner";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../hooks/use-auth";
import { getBomById } from "../../../../features/Manufacture/Bom/bom-actions";
import { useTranslation } from "react-i18next";
import BomPdf from "../../../../components/Table/Pdf/BomPdf";
import CustomizedScrollbar from "../../../../components/Custom/CustomizedScrollbar";
import { bomActions } from "../../../../features/Manufacture/Bom/bom-slice";

const BomPDF = () => {
  const dispatch = useDispatch();
  const { bom } = useSelector((state) => state.bom);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getBomById(id, enqueueSnackbar));
    return () => {
      dispatch(bomActions.resetBom());
    };
  }, [dispatch, enqueueSnackbar, id, user]);

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h5">สูตรการผลิต/BOM</Typography>
          </Box>
          <Box>
            <Button size="small" variant="outlined" onClick={handlePrint}>
              พิมพ์เอกสาร
            </Button>
          </Box>
        </Box>
        <Box sx={{ m: "10px auto", width: "fit-content" }}>
          <Box
            sx={{
              width: "210mm",
              height: "297mm",
              border: "1px solid #eee",
              borderRadius: "5px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <CustomizedScrollbar
              sx={{
                height: "100%",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  p: 3,
                }}
                ref={componentRef}
              >
                <Box>
                  <PDFHeader
                    imgSize={150}
                    documentType="สูตรการผลิต/BOM"
                    noCompany
                  />
                  <Divider />
                  <BomPDFInfo bom={bom} t={t} />
                  <Divider />
                  <BomPdf itemList={bom.ingredient_list} isBom />
                  <PDFFooter data={bom} documentType="bom" />
                  <Divider sx={{ mt: 2 }} />
                </Box>
                <Box sx={{ py: 3, breakInside: "avoid" }}>
                  <PDFSigner documentType="bom" />
                </Box>
              </Box>
            </CustomizedScrollbar>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BomPDF;
