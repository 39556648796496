import { Box, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";

const ManufactureReport = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.report"),
    },
  ];

  const menuList = [
    { title: t("manufacture.bom.index"), navigateTo: "bom" },
    { title: t("manufacture.order.index"), navigateTo: "order" },
    { title: t("manufacture.workOrder.index"), navigateTo: "work-order" },
    {
      title: t("manufacture.bom.under_quantity_item_list"),
      navigateTo: "under-quality",
    },
  ];

  const renderMenuList = () =>
    menuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          linkTo={`${pathname}/${menu.navigateTo}`}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default ManufactureReport;
