import { createContext, useEffect, useState, useCallback } from "react";
import UserService from "../services/User";
import { useAuth } from "../hooks/use-auth";

const initialState = {
  approvals: [],
  pendingsCount: 0,
};

const ApprovalContext = createContext({
  ...initialState,
  refreshApprovals: () => {},
});

export const ApprovalContextProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [isInit, setIsInit] = useState(false);
  const [approvals, setApprovals] = useState([]);
  const [pendingsCount, setPendingsCount] = useState(0);
  const [allProgressCount, setAllProgressCount] = useState(0);
  // const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    if (!isAuthenticated) {
      setApprovals([]);
      setPendingsCount(0);
      setAllProgressCount(0);
      setIsInit(false);
    }
  }, [isAuthenticated]);

  const getPendingsCount = useCallback(async () => {
    const pendings = await UserService.getSelfPendingApprovalCount();
    return pendings;
  }, []);

  const refresh = useCallback(async () => {
    const data = (await UserService.getSelfApprovalProgresses()).filter(
      (progress) => progress.approval_status !== "PENDING"
    );
    const pendingData = await UserService.getSelfApprovalPendings();
    setApprovals([...pendingData, ...data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInit, allProgressCount, pendingsCount]);

  useEffect(() => {
    if (!isInit && isAuthenticated) {
      const getInitialAllProgressCount = async () => {
        const newProgressCount =
          await UserService.getSelfAllApprovalProgressCount();
        setAllProgressCount(newProgressCount);
      };
      const getInitialPendingsCount = async () => {
        const pendings = await getPendingsCount();
        setPendingsCount(pendings);
      };
      refresh();
      getInitialPendingsCount();
      getInitialAllProgressCount();
      setIsInit(true);
    }
  }, [
    isInit,
    approvals,
    getPendingsCount,
    refresh,
    allProgressCount,
    isAuthenticated,
  ]);

  // useEffect(() => {
  //   // Update network status
  //   const handleStatusChange = () => {
  //     setIsOnline(navigator.onLine);
  //   };

  //   // Listen to the online status
  //   window.addEventListener("online", handleStatusChange);

  //   // Listen to the offline status
  //   window.addEventListener("offline", handleStatusChange);

  //   // Specify how to clean up after this effect for performance improvment
  //   return () => {
  //     window.removeEventListener("online", handleStatusChange);
  //     window.removeEventListener("offline", handleStatusChange);
  //   };
  // }, [isOnline]);

  // useEffect(() => {
  //   let interval = null;
  //   if (isAuthenticated) {
  //     interval = setInterval(async () => {
  //       const pendings = await getPendingsCount();
  //       if (pendings !== pendingsCount) {
  //         setPendingsCount(pendings);
  //       }
  //     }, 10000);

  //     if (!isOnline) clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [pendingsCount, getPendingsCount, isAuthenticated, isOnline]);

  const state = { approvals, pendingsCount };

  return (
    <ApprovalContext.Provider value={{ ...state, refreshApprovals: refresh }}>
      {children}
    </ApprovalContext.Provider>
  );
};

export default ApprovalContext;
