// import { collection, addDoc, getFirestore } from "firebase/firestore";
// import firebaseApp from "../firebase";
// Add a new document with a generated id.
// const db = getFirestore(firebaseApp);

export const createLog = async (
  type = "info",
  api = "/",
  location = "/",
  email = "",
  req = {},
  res = {}
) => {
  // await addDoc(
  //   collection(
  //     db,
  //     "Projects",
  //     "vpi-erp-prod",
  //     "users",
  //     email ?? "unknown",
  //     "year",
  //     new Date().getFullYear().toString() ?? "00",
  //     "month",
  //     (new Date().getMonth() + 1).toString() ?? "0",
  //     "day",
  //     new Date().getDate().toString() ?? "0",
  //     "logs"
  //   ),
  //   {
  //     type: type,
  //     timestamp: new Date(),
  //     api: api,
  //     location: location,
  //     user: email,
  //     request:
  //       typeof req === "object" && req !== null ? JSON.stringify(req) : req,
  //     response:
  //       typeof res === "object" && res !== null ? JSON.stringify(res) : res,
  //   }
  // );
};
