import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IconButton, styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLocation } from "react-router-dom";
import { quotationActions } from "../../features/Sales/Quotation/quotation-slice";
import { salesOrderActions } from "../../features/Sales/SalesOrder/sales-order-slice";
import { salesInvoiceActions } from "../../features/Sales/SalesInvoice/sales-invoice-slice";
import { depositInvoiceActions } from "../../features/Sales/DepositInvoice/deposit-invoice-slice";
import { salesReturnActions } from "../../features/Sales/SalesReturn/sales-return-slice";
import { creditNoteActions } from "../../features/Sales/CreditNote/credit-note-slice";
import { useDispatch } from "react-redux";

const StyledSnackbar = styled(SnackbarProvider)(({ theme }) => ({
  "&.SnackbarItem-variantSuccess": {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
  "&.SnackbarItem-variantError": {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  "&.SnackbarItem-variantWarning": {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
  "&.SnackbarItem-variantInfo": {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
  "& > .SnackbarItem-message": {
    display: "flex",
    gap: "12px",
  },
}));

export default function CustomizedSnackbar({ children }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const currentPage = pathname.split("/")[2];
  const notistackRef = useRef(null);
  const dismiss = (key) => {
    notistackRef.current.closeSnackbar(key);
    switch (currentPage) {
      case "quotation":
        dispatch(quotationActions.resetQuotationError());
        break;
      case "order":
        dispatch(salesOrderActions.resetSalesOrderError());
        break;
      case "invoice":
        dispatch(salesInvoiceActions.resetSalesInvoiceError());
        break;
      case "deposit-invoice":
        dispatch(depositInvoiceActions.resetDepositInvoiceError());
        break;
      case "return":
        dispatch(salesReturnActions.resetSalesReturnError());
        break;
      case "credit-note":
        dispatch(creditNoteActions.resetCreditNoteError());
        break;
      default:
        break;
    }
  };
  return (
    <StyledSnackbar
      ref={notistackRef}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      iconVariant={{
        success: <CheckCircleOutlinedIcon color="success" />,
        error: <ErrorOutlineIcon color="error" />,
        warning: <ReportProblemOutlinedIcon color="warning" />,
        info: <InfoOutlinedIcon color="info" />,
      }}
      maxSnack={7}
      preventDuplicate={true}
      action={(key) => (
        <IconButton size="small" onClick={() => dismiss(key)}>
          <CloseOutlinedIcon />
        </IconButton>
      )}
      style={{ fontFamily: "Kanit" }}
    >
      {children}
    </StyledSnackbar>
  );
}
