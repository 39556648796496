import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contactSnapshot: null,
  isInit: false,
  isReset: false,
  imageIsInit: false,
  contactPersonListIsInit: false,
  attachmentIsInit: false,
  salesListIsInit: false,
  generalIsInit: false,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    initialize(state, action) {
      state.contactSnapshot = action.payload;
      state.isInit = true;
    },
    uninitialize(state) {
      state.isInit = false;
      state.imageIsInit = true;
      state.generalIsInit = false;
      state.contactPersonListIsInit = false;
      state.attachmentIsInit = false;
      state.salesListIsInit = false;
      state.contactSnapshot = null;
    },
    initializeImage(state) {
      state.imageIsInit = true;
    },
    initalizeGeneral(state) {
      state.generalIsInit = true;
    },
    initalizeContactPersonList(state) {
      state.contactPersonListIsInit = true;
    },
    initializeAttachment(state) {
      state.attachmentIsInit = true;
    },
    initalizeSalesList(state) {
      state.salesListIsInit = true;
    },
    startReset(state) {
      state.isReset = true;
    },
    finishReset(state) {
      state.isReset = false;
    },
  },
});

export const contactActions = contactSlice.actions;

export default contactSlice.reducer;
