import { useState, useEffect, forwardRef } from "react";
import { useSnackbar } from "notistack";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ControlledDatePicker from "../../Custom/ControlledDatePicker";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../Custom/CustomizedBreadcrumbs";
import CustomizedMenuOption from "../../Custom/CustomizedMenuOption";
import ContactFormGeneral from "./general";
import ModalUI from "../../UI/ModalUI";
import RightDrawer from "../../UI/RightDrawer";
import HistoryIcon from "@mui/icons-material/History";
import ContactService from "../../../services/Contact";
import ActivityLogsService from "../../../services/ActivityLogs";
import { createActivityLogDeletePayload } from "../../../utils/activityLogsPayloadFormatter";
import { useAuth } from "../../../hooks/use-auth";
import { userHavePermission } from "../../../utils/userInfo";
import { errorMessageHandler } from "../../../utils/dataTransformer";

const ContactForm = forwardRef(
  (
    {
      id,
      control,
      onSubmit,
      onSubmitEdit,
      onCancel,
      setValue,
      getValues,
      watch,
      errors,
      disableModes,
      isContactPage,
      contactTypeError,
    },
    ref
  ) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { user, permissions } = useAuth();
    const navigate = useNavigate();
    const [isViewOnly, setIsViewOnly] = useState(true);
    const [mode, setMode] = useState("ดูข้อมูล");
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const isEditPage = pathname.split("/")[2] !== "add";
    const watchContactId = watch("document_id");
    const watchIsVendor = watch("is_vendor");
    const watchIsCustomer = watch("is_customer");
    const [openDrawer, setOpenDrawer] = useState(false);

    const allEditPermission = userHavePermission(
      permissions,
      "contact",
      "all",
      "edit"
    );
    const allDeletePermission = userHavePermission(
      permissions,
      "contact",
      "all",
      "delete"
    );
    const vendorEditPermission = userHavePermission(
      permissions,
      "contact",
      "vendor",
      "edit"
    );
    const vendorDeletePermission = userHavePermission(
      permissions,
      "contact",
      "vendor",
      "delete"
    );
    const customerEditPermission = userHavePermission(
      permissions,
      "contact",
      "customer",
      "edit"
    );
    const customerDeletePermission = userHavePermission(
      permissions,
      "contact",
      "customer",
      "delete"
    );

    const selectModifyOptions = [
      {
        value: "แก้ไข",
        disabled:
          (!allEditPermission &&
            !vendorEditPermission &&
            !customerEditPermission) ||
          (!watchIsVendor && vendorEditPermission && !allEditPermission) ||
          (!watchIsCustomer && customerEditPermission && !allEditPermission),
      },
      {
        value: "ลบ",
        disabled:
          (!allDeletePermission &&
            !vendorDeletePermission &&
            !customerDeletePermission) ||
          (!watchIsVendor && vendorDeletePermission && !allDeletePermission) ||
          (!watchIsCustomer &&
            customerDeletePermission &&
            !allDeletePermission),
      },
    ];

    useEffect(() => {
      if (mode === "แก้ไข") {
        setIsViewOnly(false);
      } else if (mode === "ลบ") {
        setDeleteModalIsOpen(true);
      }
    }, [mode]);

    useEffect(() => {
      if (pathname === "/contact/add") {
        setIsViewOnly(false);
        setMode("สร้าง");
      }
    }, [pathname]);

    useEffect(() => {
      if (getValues("created_date") === "") {
        setValue("created_date", new Date());
      }
    });

    const breadcrumbs = [
      {
        name: t("contact.index"),
        to: "/contact/contacts",
      },
      {
        name: isEditPage
          ? watchContactId || "แก้ไขผู้ติดต่อ"
          : "เพิ่มผู้ติดต่อ",
      },
    ];

    const cancelDeleteHandler = () => {
      setMode("แก้ไข");
      setDeleteModalIsOpen(false);
    };

    const deleteContactHandler = async () => {
      try {
        await ContactService.deleteContact({ document_id: id });
        try {
          const logPayload = createActivityLogDeletePayload(
            { creator_document_id: user.document_id, document_id: id },
            "contact"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: logPayload,
          });
        } catch (err) {
          console.error(err);
        }
        enqueueSnackbar("ลบข้อมูลผู้ติดต่อสำเร็จ", {
          variant: "success",
        });
        navigate("/contact", { replace: true });
      } catch (err) {
        err.response.errors.forEach((error) => {
          if (errorMessageHandler(error.message)) {
            enqueueSnackbar(errorMessageHandler(error.message), {
              variant: "error",
            });
          } else {
            enqueueSnackbar("เกิดความผิดพลาดในการลบข้อมูลผู้ติดต่อ", {
              variant: "error",
            });
          }
        });
      }
      setDeleteModalIsOpen(false);
    };

    const renderDatePicker = (
      <ControlledDatePicker
        name="created_date"
        control={control}
        error={errors.created_date?.error}
        isOpen={datePickerIsOpen}
        onClose={() => setDatePickerIsOpen(false)}
        onOpen={() => setDatePickerIsOpen(true)}
        label="วันที่สร้าง"
        disabled={isViewOnly}
      />
    );

    return (
      <>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{ mt: isContactPage ? 0 : 1 }}
        >
          <Grid item xs={12} sm={12} md={6}>
            {isContactPage && (
              <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            )}
          </Grid>
        </Grid>
        <form>
          <Box sx={{ width: "100%" }}>
            {isContactPage && (
              <Grid container alignItems={"flex-end"} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                  <Typography variant="h5">
                    {isEditPage
                      ? watchContactId || "แก้ไขผู้ติดต่อ"
                      : "เพิ่มผู้ติดต่อ"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                      {renderDatePicker}
                    </Grid>
                    {isContactPage && (
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
                        <CustomizedMenuOption
                          size="medium"
                          fullWidth
                          label={"ตัวเลือก"}
                          options={selectModifyOptions}
                          onSelect={(e) => {
                            setMode(e.target.innerText);
                          }}
                          disabled={mode === "สร้าง"}
                        />
                      </Grid>
                    )}
                    {isEditPage && (
                      <Grid item xs={2}>
                        <IconButton onClick={() => setOpenDrawer(true)}>
                          <HistoryIcon
                            fontSize="small"
                            color=" rgba(0, 0, 0, 0.54)"
                          />
                        </IconButton>
                        <RightDrawer
                          open={openDrawer}
                          onClose={() => setOpenDrawer(false)}
                          title={t("inventory.activity")}
                          documentId={id}
                          documentType="contact"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!isContactPage && (
              <Box sx={{ pr: "2rem" }}>
                <Grid
                  container
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                >
                  <Grid item xs={5} sm={5} md={3}>
                    {renderDatePicker}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <ContactFormGeneral
            ref={ref}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
            isContactPage={isContactPage}
            viewOnly={isViewOnly}
            isEditPage={isEditPage}
            contactTypeError={contactTypeError}
          />
          {!isViewOnly && (
            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: 1 }}>
                <CustomizedButton
                  title={"ยกเลิก"}
                  variant="outlined"
                  type="reset"
                  onClick={onCancel}
                />
              </Box>
              <CustomizedButton
                title={"บันทึก"}
                variant="contained"
                onClick={isEditPage ? onSubmitEdit : onSubmit}
              />
            </Box>
          )}
        </form>
        <ModalUI
          open={deleteModalIsOpen}
          handleClose={cancelDeleteHandler}
          fullWidth
          maxWidth="sm"
        >
          <Typography fontWeight="bold">ยืนยันการลบข้อมูลผู้ติดต่อ</Typography>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ mr: 1 }}>
              <CustomizedButton
                title={"ยกเลิก"}
                variant="outlined"
                type="reset"
                size="medium"
                onClick={cancelDeleteHandler}
              />
            </Box>
            <CustomizedButton
              title={"ยืนยัน"}
              variant="contained"
              size="medium"
              onClick={deleteContactHandler}
            />
          </Box>
        </ModalUI>
      </>
    );
  }
);

export default ContactForm;
