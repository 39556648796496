import { Box, DialogContentText } from "@mui/material";
import React from "react";
import { formatNumber } from "../../../utils/dataTransformer";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";

const ItemPriceConfirmation = ({
  openItemPriceConfirmation,
  itemPriceConfirmationAction,
  closeItemPriceConfirmationHandler,
  items,
}) => {
  return (
    <ModalUI
      isRed={"#f44336"}
      title="ราคาขายต่ำกว่าราคาทุน ต้องการบันทึกใบเสนอราคาหรือไม่"
      open={openItemPriceConfirmation}
      handleClose={closeItemPriceConfirmationHandler}
      maxWidth="sm"
    >
      {items.map((item) => (
        <DialogContentText sx={{ color: "#f44336" }}>
          ราคาทุนของ {item.item_name} คือ{" "}
          {formatNumber(item.purchase_standard_price)}
        </DialogContentText>
      ))}

      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeItemPriceConfirmationHandler}
        />
        <CustomizedButton
          title="บันทึก"
          variant="contained"
          onClick={itemPriceConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default ItemPriceConfirmation;
