import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import CustomizedToolTipAvatar from "../../../components/Custom/CustomizedToolTipAvatar";
import AgGrid from "../../../components/Table/AgGrid";
import { getGoodReceiveTab } from "../../../features/Purchase/PurchaseOrder/purchase-order-actions";
import { purchaseOrderActions } from "../../../features/Purchase/PurchaseOrder/purchase-order-slice";
import {
  dateComparator,
  filterParamsOptions,
} from "../../../utils/filterparams";

const GoodsReceiveTab = () => {
  const gridRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { goodReceiveTab } = useSelector((state) => state.purchaseOrder);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getGoodReceiveTab(id, enqueueSnackbar));
    }
    return () => dispatch(purchaseOrderActions.resetGoodReceiveTab());
  }, [dispatch, enqueueSnackbar, id]);

  const columnDefs = [
    {
      field: "created_date",
      headerName: t("inventory.documentDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
    },
    {
      field: "document_date",
      headerName: t("inventory.receive.receiveDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
    },
    {
      field: "document_id",
      headerName: t("inventory.receive.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "reference_document_id",
      headerName: t("inventory.referenceNo"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },

    {
      field: "source_warehouse.thai_name",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "type",
      headerName: t("inventory.receive.receiveType"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ซื้อ", "ผลิต", "แปรรูป", "อื่นๆ"],
      },
    },
    {
      field: "created_by",
      headerName: t("inventory.receive.importedBy"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.value.img_url) {
          return <CustomizedToolTipAvatar avatar={params.value} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.value.first_name + " " + params.value.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "remark",
      headerName: t("inventory.remark"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("inventory.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["draft", "finished", "cancelled"],
        valueFormatter: (params) => {
          if (params.value === "draft") {
            return "ร่าง";
          }
          if (params.value === "finished") {
            return "เสร็จสิ้น";
          }
          return "ยกเลิก";
        },
      },
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.data.status} />;
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  const onRowDoubleClicked = (params) => {
    navigate(
      `/inventory/receive/${encodeURIComponent(params.data.document_id)}`
    );
  };

  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        ใบ{t("inventory.receive.index")}
      </Typography>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={goodReceiveTab}
        onRowDoubleClicked={onRowDoubleClicked}
        height={650}
        path={`/inventory/receive`}
      />
    </>
  );
};

export default GoodsReceiveTab;
