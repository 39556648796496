import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { formatDate } from "../../../../utils/date-converter";

const DeliveryOrderPDFInfo = ({ deliveryOrder }) => {
  const renderAddressString = (addressValues) => {
    let result = "";

    if (Array.isArray(addressValues)) {
      addressValues.forEach((item) => {
        if (item.length > 0) {
          result = result + item + ", ";
        }
      });
    } else {
      const keys = Object.keys(addressValues);
      keys.forEach((key) => {
        if (addressValues[key].length > 0) {
          if (key !== "address_type") {
            result = result + addressValues[key] + ", ";
          }
        }
      });
    }
    if (result.trim().length === 0) {
      return "-";
    }
    // remove whitespace and last comma
    return result.trim().slice(0, -1);
  };

  const leftHeader = [
    "รหัสลูกค้า / Customer ID",
    "ชื่อลูกค้า / Customer Name",
    "ที่อยู่จดทะเบียน / Billing Address",
    "เลขผู้เสียภาษี / Tax ID",
    "เบอร์โทรศัพท์ / Phone",
    "แฟกซ์ / Fax",
    "อีเมล / Email",
  ];

  const leftInfo = [
    deliveryOrder?.contact_document_id || "-",
    deliveryOrder.contact
      ? deliveryOrder.contact?.contact_name_1 +
        (deliveryOrder.contact?.contact_name_2
          ? " " + deliveryOrder.contact?.contact_name_2
          : "")
      : "-",
    renderAddressString(deliveryOrder?.customer?.billing_address || {}) || "",
    deliveryOrder.contact?.identity_no || "-",
    deliveryOrder?.customer?.phone || "-",
    deliveryOrder?.customer?.fax || "-",
    deliveryOrder?.customer?.email || "-",
  ];

  const rightHeader = [
    "เลขที่เอกสาร / Document No.",
    "อ้างอิงถึง / Ref. Document",
    "วันที่ออกเอกสาร / Issue date",
    "วันที่ส่งของ / Delivery Date",
    "ที่อยู่จัดส่ง / Delivery Address",
  ];

  const rightInfo = [
    deliveryOrder?.document_id || "-",
    deliveryOrder?.reference_document_id &&
    deliveryOrder?.reference_document_id.length > 0
      ? deliveryOrder?.reference_document_id
          .map((ref) => ref.reference_document_id)
          .join(", ")
      : "-",
    deliveryOrder?.issue_date ? formatDate(deliveryOrder?.issue_date) : "-",
    deliveryOrder?.delivery_date
      ? formatDate(deliveryOrder?.delivery_date)
      : "-",
    renderAddressString(deliveryOrder?.customer?.delivery_address || {}) || "",
  ];

  return (
    <Grid container sx={{ my: 1 }}>
      <Grid item xs={6}>
        {leftHeader.map((name, index) => (
          <Box sx={{ display: "flex" }} key={index}>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 1,
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[index]}
            </Typography>
          </Box>
        ))}
      </Grid>
      <Grid item xs={6}>
        {rightHeader.map((name, index) => (
          <Box sx={{ display: "flex" }} key={index}>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 1,
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {rightInfo[index]}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default DeliveryOrderPDFInfo;
