import { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedRadioGroup from "../../../components/Custom/CustomizedRadioGroup";
import ApprovalSettingBox from "../../../components/UI/ApprovalSettingBox";

const ManufactureApprovalSetting = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const { control: settingControl } = useForm({
    defaultValues: { enableSetting: false },
  });
  const { control, setValue, getValues, reset } = useForm({
    defaultValues: { approvers: [] },
  });

  const watchEnableSetting = useWatch({
    control: settingControl,
    name: "enableSetting",
  });
  const shouldEnableSetting = watchEnableSetting === "true";

  const radioLists = [
    { value: false, label: "ไม่ตั้งค่าการอนุมัติ" },
    {
      value: true,
      label: "ตั้งค่าการอนุมัติ",
      tooltip: "ผู้มีสิทธิ์อนุมัติผู้ใดก็ได้ในลำดับนั้นๆ สามารถอนุมัติได้",
    },
  ];

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.approval.index"), to: "/setting/approval" },
    { name: t("setting.approval.manufacture") },
  ];

  useEffect(() => {
    reset({ approvers: [] });
  }, [tab, reset]);

  const tabs = [
    {
      label: t("manufacture.order.index"),
      path: `${pathname}?tab=order`,
    },
  ];

  const submitUpdatedPermissions = () => {};

  const cancelUpdatedPermissions = () => {};

  const currentTab = tab ? pathname + `?tab=${tab}` : pathname + `?tab=order`;

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ width: "100%" }}>
        <CustomizedTab tabs={tabs} currentTab={currentTab} scrollable divider />
        <Controller
          name="enableSetting"
          control={settingControl}
          render={({ field }) => (
            <Box sx={{ ml: 2 }}>
              <CustomizedRadioGroup
                radioList={radioLists}
                {...field}
                disabled={false}
              />
            </Box>
          )}
        />
        {shouldEnableSetting && (
          <ApprovalSettingBox
            control={control}
            setValue={setValue}
            getValues={getValues}
          />
        )}
        <Box sx={{ display: "flex", mt: 2 }}>
          <Box sx={{ mr: 1 }}>
            <CustomizedButton
              title={"ยกเลิก"}
              variant="outlined"
              type="reset"
              onClick={cancelUpdatedPermissions}
            />
          </Box>
          <CustomizedButton
            title={"บันทึก"}
            variant="contained"
            onClick={submitUpdatedPermissions}
          />
        </Box>
      </Box>
    </>
  );
};

export default ManufactureApprovalSetting;
