import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PDFFooter from "../../../../components/UI/PDFFooter";
import PDFHeader from "../../../../components/UI/PDFHeader";
import SalesOrderPDFInfo from "./Info";
import { useReactToPrint } from "react-to-print";
import PDFSigner from "../../../../components/UI/PDFSigner";
import { useDispatch, useSelector } from "react-redux";
import { getSalesOrderById } from "../../../../features/Sales/SalesOrder/sales-order-actions";
import { useState } from "react";
import ContactService from "../../../../services/Contact";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../hooks/use-auth";
import SalesPdf from "../../../../components/Table/Pdf/SalesPdf";
import CustomizedScrollbar from "../../../../components/Custom/CustomizedScrollbar";
import { salesOrderActions } from "../../../../features/Sales/SalesOrder/sales-order-slice";

const SalesOrderPDF = () => {
  const dispatch = useDispatch();
  const { salesOrder } = useSelector((state) => state.salesOrder);
  const [contact, setContact] = useState({
    contact_name: "",
    identity_no: "",
  });
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: salesOrder?.document_id,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    dispatch(
      getSalesOrderById(
        {
          document_id: id,
        },
        user,
        enqueueSnackbar
      )
    );
    return () => {
      dispatch(salesOrderActions.resetSalesOrder());
    };
  }, [dispatch, enqueueSnackbar, id, user]);

  useEffect(() => {
    const getContact = async () => {
      if (salesOrder && salesOrder.contact_document_id) {
        const contactData = await ContactService.getContact({
          document_id: salesOrder.contact_document_id,
        });
        setContact({
          contact_name:
            contactData.contact_name_1 +
            (contactData.contact_name_2
              ? " " + contactData.contact_name_2
              : ""),
          identity_no: contactData.identity_no,
        });
      }
    };
    getContact();
  }, [salesOrder]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">ใบสั่งขาย/Sale Order</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>

      <Box sx={{ m: "10px auto", width: "fit-content" }}>
        <Box
          sx={{
            width: "210mm",
            height: "297mm",
            border: "1px solid #eee",
            borderRadius: "5px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <CustomizedScrollbar
            sx={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 3,
              }}
              ref={componentRef}
            >
              <Box>
                <PDFHeader documentType="ใบสั่งขาย/Sale Order" />
                <Divider />
                <SalesOrderPDFInfo salesOrder={salesOrder} contact={contact} />
                <Divider />
                <SalesPdf itemList={salesOrder.item_list} />
                <PDFFooter data={salesOrder} />
                <Divider sx={{ mt: 2 }} />
              </Box>
              <Box
                sx={{
                  py: 3,
                  breakInside: "avoid",
                }}
              >
                <PDFSigner />
              </Box>
            </Box>
          </CustomizedScrollbar>
        </Box>
      </Box>
    </Box>
  );
};

export default SalesOrderPDF;
