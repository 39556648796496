import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AgGrid from "../../../components/Table/AgGrid";
import { getManufactureOrderTab } from "../../../features/Sales/SalesOrder/sales-order-actions";
import { manufactureOrderColumnDef } from "../../../components/Table/ColumnDefs/manufactureOrderColumnDef";

const ManufactureOrderTab = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { manufactureOrderTab } = useSelector((state) => state.salesOrder);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getManufactureOrderTab(id, enqueueSnackbar));
    }
  }, [dispatch, enqueueSnackbar, id]);

  const onRowDoubleClicked = (params) => {
    navigate(
      `/manufacture/order/${encodeURIComponent(params.data.document_id)}`
    );
  };

  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        {t("manufacture.order.index")}
      </Typography>
      <AgGrid
        ref={gridRef}
        columnDefs={manufactureOrderColumnDef(t)}
        height={680}
        onRowDoubleClicked={onRowDoubleClicked}
        rowData={manufactureOrderTab}
        path={`/manufacture/order`}
      />
    </>
  );
};

export default ManufactureOrderTab;
