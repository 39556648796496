import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../Table/AgGrid";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import TotalPreVatAmount from "../TotalPreVatAmount";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ModalUI from "../../../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllItemsAggrid,
  getItem,
} from "../../../../features/Inventory/ItemMaster/itemMaster-actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { poColumnDefs } from "../ColumnDefs/poColumnDefs";
import { columnDefs } from "../ColumnDefs/columnDefs";
import { useLocation, useSearchParams } from "react-router-dom";
import { validation } from "../../../../pages/Inventory/Items/schema";
import { formatVatOption } from "../../../../utils/dataTransformer";
import { getAllUom } from "../../../../features/Setting/Uom/uom-actions";
import { itemMasterActions } from "../../../../features/Inventory/ItemMaster/itemMaster-slice";
import ItemInfo from "../../../../pages/Inventory/Items/ItemInfo";
import { v4 as uuidv4 } from "uuid";
import DeleteItemConfirmation from "../../../UI/Confirmation/DeleteItemConfirmation";
import { rsColumnDefs } from "../ColumnDefs/rsColumnDefs";
import { getPurchaseOrderPriceList } from "../../../../features/Purchase/PurchaseOrder/purchase-order-actions";
import { purchaseOrderActions } from "../../../../features/Purchase/PurchaseOrder/purchase-order-slice";
import CustomizedTab from "../../../Custom/CustomizedTab";
import ItemListTable from "../../../Table/DocumentTable/ItemListTable";
import { Box } from "@mui/material";
import CustomizedButton from "../../../Custom/CustomizedButton";

const AddItemListForm = ({
  control,
  contactUseForm,
  setValue,
  getValues,
  setHeaderValue,
  getHeaderValues,
  errors,
  gridRef,
  viewOnly,
  documentType,
  itemListRef,
  salesOrderIds,
  setSalesOrderIds,
  salesOrderIdsSnapshot,
  setSalesOrderIdsSnapshot,
  purchaseRequestIds,
  setPurchaseRequestIds,
  purchaseRequestIdsSnapshot,
  setPurchaseRequestIdsSnapshot,
  purchaseTableGridRef,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { item } = useSelector((state) => state.itemMaster);
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openItemsModal, setOpenItemsModal] = useState(false);
  const [openModalPurchase, setOpenModalPurchase] = useState(false);
  const [openPurchaseRequestModal, setOpenPurchaseRequestModal] =
    useState(false);
  const [openItemHistoryModal, setItemHistoryModal] = useState(false);
  const [openItemDetailModal, setOpenItemDetailModal] = useState(false);
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);
  const [selectedPriceIndex, setSelectedPriceIndex] = useState(null);
  const [currentTab, setCurrentTab] = useState(pathname);

  const itemTableGridRef = useRef();
  const itemHistoryRef = useRef();
  const subtab = searchParams.get("subtab");
  const { purchaseOrderPriceList, isLoading } = useSelector(
    (state) => state.purchaseOrder
  );

  const contact_document_id = contactUseForm
    ? contactUseForm.watch("document_id")
    : "";

  const tabs = [
    {
      label: t("purchase.order.index"),
      path: `${pathname}?subtab=purchase-order-list`,
    },
  ];

  useEffect(() => {
    switch (subtab) {
      case "purchase-order-list":
        setCurrentTab(pathname + "?subtab=purchase-order-list");
        break;
      default:
        setCurrentTab(pathname + "?subtab=purchase-order-list");
        break;
    }
  }, [pathname, subtab]);

  const {
    control: itemInfoControl,
    handleSubmit: handleSubmitItemInfo,
    getValues: getItemInfoValues,
    setValue: setItemInfoValue,
    formState: { errors: itemInfoErrors },
    reset: resetItemInfo,
    clearErrors: clearItemInfoErrors,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    if (item.document_id !== "") {
      Object.entries(item).forEach(([key, value]) => {
        if (key === "sales_vat_type")
          setItemInfoValue(key, formatVatOption(value));
        else if (key === "attribute_list")
          setItemInfoValue(
            key,
            value.map((item) => item.attribute)
          );
        else setItemInfoValue(key, value);
      });
    }
  }, [item, setItemInfoValue]);

  const { fields, append, remove } = useFieldArray({
    name: "item_list",
    control,
  });

  const watchStatus = useWatch({
    control,
    name: "render_status",
  });

  const headerClass = ["center"];

  const handleSelectItems = () => {
    const selectedItems = itemTableGridRef.current.api.getSelectedRows();
    selectedItems.forEach((item) => {
      if (item.item_document_id) {
        const existingItemList = getValues("item_list");
        if (
          existingItemList.findIndex((oldItem) => oldItem.uid === item.uid) < 0
        ) {
          if (documentType === "rs") {
            append({
              ...item,
              qty_return: 1,
            });
          } else if (documentType === "pr") {
            append({
              ...item,
              po_qty: 0,
            });
          } else {
            append(item);
          }
        }
      } else {
        let formatItem = {
          uid: uuidv4(),
          item_document_id: (item?.document_id || item?.item_document_id) ?? "",
          item_name: (item?.name || item?.item_name) ?? "",
          item_description: (item?.description || item?.item_description) ?? "",
          qty: 1,
          uom: {
            document_id: item?.uom?.document_id ?? item?.base_uom.document_id,
            id: item?.uom?.id ?? item?.base_uom.id,
            name: item?.uom?.name ?? item?.base_uom.name,
          },
          uom_id: item?.uom_id ?? item?.base_uom.id,
          qty_uom: 1,
          price_per_unit: item?.purchase_standard_price ?? 0,
          discount_amount: 0,
          vat_type: item?.purchase_vat_type ?? "ไม่มี",
          pre_vat_amount: item?.purchase_standard_price ?? 0,
          withholding_tax: {
            type: "ยังไม่ระบุ",
            amount: 0,
          },
          item_remark: item?.item_remark ?? "",
          uom_group: item?.uom_group,
          current_ordered_purchase_qty: item?.current_ordered_purchase_qty ?? 0,
          current_ordered_manufacture_qty:
            item?.current_ordered_manufacture_qty ?? 0,
          current_committed_sales_qty: item?.current_committed_sales_qty ?? 0,
          current_stock_qty: item?.current_stock_qty ?? 0,
          current_available_qty: item?.current_available_qty ?? 0,
        };
        if (documentType === "pr")
          append({
            ...formatItem,
            po_qty: 0,
          });
        else append(formatItem);
      }
    });
    setOpenItemsModal(false);
  };

  const openDeleteItemConfirmationHandler = (index) => {
    setDeletedIndex(index);
    setOpenDeleteItemModal(true);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setDeletedIndex(null);
    setOpenDeleteItemModal(false);
  };

  const handleRemoveItems = () => {
    remove(deletedIndex);
    gridRef.current.api.redrawRows();
    if (documentType !== "rs") {
      const newRefList = [];
      const newItemList = getValues("item_list");
      newItemList.forEach((item) => {
        if (item.ref_document_id)
          if (
            !newRefList.some(
              (list) => list.reference_document_id === item.ref_document_id
            )
          )
            newRefList.push({
              reference_document_id: item.ref_document_id,
              reference_document_type:
                documentType === "pr" ? "sales_order" : "purchase_request",
            });
      });
      setHeaderValue("reference_document_list", newRefList);
      const refIdList = newRefList.map((list) => list.reference_document_id);
      if (documentType === "pr") {
        setSalesOrderIdsSnapshot(refIdList);
        setSalesOrderIds(refIdList);
      } else if (documentType === "po") {
        setPurchaseRequestIdsSnapshot(refIdList);
        setPurchaseRequestIds(refIdList);
      }
    }
    closeDeleteItemConfirmationHandler();
  };

  const handleShowItemModal = (data) => {
    dispatch(getItem({ document_id: data.item_document_id }, enqueueSnackbar));
    dispatch(getAllUom());
    setOpenItemDetailModal(true);
  };

  const handleCloseItemDetailModal = () => {
    searchParams.delete("itemtab");
    setSearchParams(searchParams);
    clearItemInfoErrors();
    setOpenItemDetailModal(false);
    dispatch(itemMasterActions.resetItem());
  };

  const handleShowItemHistoryModal = (params) => {
    dispatch(
      getPurchaseOrderPriceList(
        params.data.item_document_id,
        contact_document_id,
        enqueueSnackbar
      )
    );
    setSelectedPriceIndex(params.node.rowIndex);
    setSearchParams({ subtab: "purchase-order-list" });
    setItemHistoryModal(true);
  };

  const handleCloseItemHistoryModal = () => {
    setSearchParams();
    setItemHistoryModal(false);
    dispatch(purchaseOrderActions.resetPurchaseOrderPriceList());
  };

  const handleItemHistorySelect = () => {
    const [selectedItem] = itemHistoryRef.current.api.getSelectedRows();

    setValue(
      `item_list[${selectedPriceIndex}].price_per_unit`,
      selectedItem.price_per_unit
    );
    const newPreVatAmount =
      getValues(`item_list[${selectedPriceIndex}].qty`) *
        selectedItem.price_per_unit -
      getValues(`item_list[${selectedPriceIndex}].discount_amount`);

    setValue(
      `item_list[${selectedPriceIndex}].pre_vat_amount`,
      newPreVatAmount
    );
    const rowNode =
      gridRef.current.api.getDisplayedRowAtIndex(selectedPriceIndex);
    rowNode.setDataValue("pre_vat_amount", newPreVatAmount);

    const withholding_tax = getValues(
      `item_list[${selectedPriceIndex}].withholding_tax`
    );
    const pre_vat_amount = getValues(
      `item_list[${selectedPriceIndex}].pre_vat_amount`
    );
    if (withholding_tax) {
      const withholding_tax_amount =
        (pre_vat_amount *
          parseFloat(
            withholding_tax.type === "ยังไม่ระบุ" ||
              withholding_tax.type === "ไม่มี" ||
              withholding_tax.type === ""
              ? 0
              : withholding_tax.type
          )) /
        100;
      setValue(`item_list[${selectedPriceIndex}].withholding_tax`, {
        type: withholding_tax.type,
        amount: withholding_tax_amount,
      });
      rowNode.setDataValue("withholding_tax", {
        type: withholding_tax.type,
        amount: withholding_tax_amount,
      });
    }
    handleCloseItemHistoryModal();
  };

  const switchColDef = () => {
    switch (documentType) {
      case "po":
        return poColumnDefs(
          t,
          watchStatus,
          documentType,
          control,
          errors,
          getValues,
          setValue,
          handleShowItemModal,
          openDeleteItemConfirmationHandler,
          viewOnly,
          handleShowItemHistoryModal
        );
      case "rs":
        return rsColumnDefs(
          t,
          control,
          errors,
          getValues,
          setValue,
          handleShowItemModal,
          openDeleteItemConfirmationHandler,
          viewOnly
        );
      default:
        return columnDefs(
          t,
          watchStatus,
          documentType,
          control,
          errors,
          getValues,
          setValue,
          handleShowItemModal,
          openDeleteItemConfirmationHandler,
          viewOnly
        );
    }
  };

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { document_id, name, is_active, item_group_sub_level_1, tag_list } =
        request.filterModel;
      const formatFilterModal = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        name: name && {
          ...name,
          mode: "insensitive",
        },
        tag_list: tag_list
          ? {
              filterType: "objectArray",
              type: "some",
              filter: {
                name: {
                  filterType: "set",
                  values: tag_list.values,
                },
              },
            }
          : {},
        item_group_sub_level_1: item_group_sub_level_1 && {
          filterType: "object",
          filter: {
            name: {
              ...item_group_sub_level_1,
              mode: "insensitive",
            },
          },
        },
        is_active: is_active &&
          is_active.values.length > 0 && {
            filterType: "boolean",
            type: "equals",
            filter: is_active.values[0],
          },
      };

      dispatch(
        getAllItemsAggrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModal,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    const instance = params.api.getFilterInstance("is_active");
    instance.setModel({
      filterType: "set",
      values: ["true"],
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const onRowDragMove = useCallback(
    (event) => {
      const movingNode = event.node;
      const overNode = event.overNode;
      const rowNeedsToMove = movingNode !== overNode;
      const itemList = getValues("item_list");

      const moveInArray = (arr, fromIndex, toIndex) => {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      };

      if (rowNeedsToMove) {
        // the list of rows we have is data, not row nodes, so extract the data
        const movingData = movingNode.data;
        const overData = overNode.data;
        const fromIndex = itemList
          .map((item) => item.uid)
          .indexOf(movingData.uid);
        const toIndex = itemList.map((item) => item.uid).indexOf(overData.uid);
        moveInArray(itemList, fromIndex, toIndex);
        setValue("item_list", itemList);
        gridRef.current.api.clearFocusedCell();
      }
    },
    [getValues, gridRef, setValue]
  );

  const getRowId = useCallback((params) => {
    return params.data.uid + (params.data.ref_document_id ?? "");
  }, []);

  const getItemId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  return (
    <>
      <CustomizedBox
        margin="0"
        radius="0"
        padding="0"
        boxShadow="0px 2px 7px #E5E5E5"
        clipPath="inset(0 -15px -2px -15px)"
        ref={itemListRef}
      >
        <AgGrid
          ref={gridRef}
          columnDefs={switchColDef()}
          rowData={fields}
          onRowDragMove={onRowDragMove}
          rowDragManaged={true}
          getRowId={getRowId}
          suppressMenu
          disabledSidebar
          disableFloatingFilter
          suppressContextMenu
          autoHeight
          headerClass={headerClass}
          isClientSide
          disableResized
        />
      </CustomizedBox>
      <ModalUI
        open={openItemDetailModal}
        handleClose={handleCloseItemDetailModal}
        navigateTo={`/inventory/items/${item.document_id}`}
        title="รายละเอียดสินค้า"
        fullWidth
      >
        <ItemInfo
          control={itemInfoControl}
          errors={itemInfoErrors}
          getValues={getItemInfoValues}
          handleSubmit={handleSubmitItemInfo}
          reset={resetItemInfo}
          setValue={setItemInfoValue}
          onCancel={handleCloseItemDetailModal}
          viewOnly={true}
        />
      </ModalUI>
      <TotalPreVatAmount
        itemTableGridRef={itemTableGridRef}
        onGridReady={onGridReady}
        handleSelectItems={handleSelectItems}
        control={control}
        openModal={openItemsModal}
        openModalPurchase={openModalPurchase}
        setOpenModalPurchase={setOpenModalPurchase}
        setOpenModal={setOpenItemsModal}
        viewOnly={viewOnly}
        setValue={setValue}
        documentType={documentType}
        getRowId={getItemId}
        setHeaderValue={setHeaderValue}
        // addRowHandler={addRowHandler}
        purchaseTableGridRef={purchaseTableGridRef}
        salesOrderIds={salesOrderIds}
        setSalesOrderIds={setSalesOrderIds}
        salesOrderIdsSnapshot={salesOrderIdsSnapshot}
        setSalesOrderIdsSnapshot={setSalesOrderIdsSnapshot}
        purchaseRequestIds={purchaseRequestIds}
        setPurchaseRequestIds={setPurchaseRequestIds}
        purchaseRequestIdsSnapshot={purchaseRequestIdsSnapshot}
        setPurchaseRequestIdsSnapshot={setPurchaseRequestIdsSnapshot}
        openPurchaseRequestModal={openPurchaseRequestModal}
        setOpenPurchaseRequestModal={setOpenPurchaseRequestModal}
      />
      <DeleteItemConfirmation
        documentType={documentType}
        openDeleteItemConfirmation={openDeleteItemModal}
        closeDeleteItemConfirmationHandler={closeDeleteItemConfirmationHandler}
        deleteItemConfirmationAction={handleRemoveItems}
      />
      <ModalUI
        open={openItemHistoryModal}
        handleClose={handleCloseItemHistoryModal}
        title="ประวัติราคาซื้อของสินค้า"
        width={1380}
        isLoading={isLoading.purchaseOrderPriceList}
      >
        <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
        {subtab === "purchase-order-list" && (
          <ItemListTable
            gridRef={itemHistoryRef}
            itemList={purchaseOrderPriceList}
            documentType="po"
            viewOnly={viewOnly}
          />
        )}
        {!viewOnly && (
          <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              onClick={handleCloseItemHistoryModal}
            />
            <CustomizedButton
              title="เลือกใช้รายการ"
              variant="contained"
              onClick={handleItemHistorySelect}
            />
          </Box>
        )}
      </ModalUI>
    </>
  );
};

export default AddItemListForm;
