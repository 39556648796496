import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import { forwardRef } from "react";
import ControlledServerSideCreatable from "../../Custom/ControlledSSCreatable";

const ReturnReasonForm = forwardRef(
  ({ control, errors, viewOnly, setValue }, ref) => {
    const { t } = useTranslation();

    const radioLists = [
      { value: true, label: "มีผล" },
      { value: false, label: "ไม่มีผล" },
    ];

    return (
      <CustomizedBox ref={ref} margin="2rem 0 0 0">
        <Box display={"flex"} gap={0.5} ml={1} mb={4}>
          <Typography fontWeight="bold">
            {t("sales.return.reasonToReturn")}
          </Typography>
          <Typography sx={{ color: "red" }}>*</Typography>
        </Box>
        <ControlledServerSideCreatable
          sx={{ width: 300 }}
          control={control}
          name="reason_to_return"
          documentType="sales_return"
          defaultOptions={[]}
          error={Boolean(errors.reason_to_return)}
          helperText={errors.reason_to_return?.message}
          setValue={setValue}
          viewOnly={viewOnly}
          required
        />
        <Typography fontWeight="bold" sx={{ ml: 1, my: 4 }}>
          ส่งผลต่อสต๊อก
        </Typography>
        <Controller
          name="is_effect_stock"
          control={control}
          render={({ field }) => {
            return (
              <CustomizedRadioGroup
                {...field}
                radioList={radioLists}
                row
                disabled={viewOnly}
              />
            );
          }}
        />
      </CustomizedBox>
    );
  }
);

export default ReturnReasonForm;
