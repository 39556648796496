import { userPermission } from "../../../../utils/userInfo";

export const createOptions = (page, status, permissions, disabled) => {
  const optionQuotation = [
    {
      value: "ใบสั่งขาย",
      disabled: userPermission(permissions, "sales", "order", "create"),
    },
  ];

  const optionOrder = [
    {
      value: "ใบขอซื้อ",
      disabled: userPermission(permissions, "purchase", "request", "create"),
    },
    {
      value: "ใบสั่งผลิต",
      disabled: userPermission(permissions, "manufacture", "order", "create"),
    },
    {
      value: "ใบส่งของ",
      disabled:
        userPermission(permissions, "logistic", "delivery-order", "create") ||
        disabled.delivery_order,
    },
    {
      value: "ใบนำออก",
      disabled:
        userPermission(permissions, "inventory", "issue", "create") ||
        disabled.goods_issue,
    },
    {
      value: "ใบรับคืน",
      disabled:
        userPermission(permissions, "sales", "return", "create") ||
        disabled.sales_return,
    },
  ];

  const optionReturn = [
    {
      value: "ใบนำเข้า",
      disabled:
        userPermission(permissions, "inventory", "return", "create") ||
        disabled.goods_receive,
    },
  ];

  const optionDO = [
    {
      value: "รอบจัดส่ง",
      disabled:
        userPermission(permissions, "logistic", "delivery-trip", "create") ||
        disabled.delivery_trip,
    },
  ];

  switch (page) {
    case "quotation":
      switch (status) {
        case "accepted":
        case "finished":
          return optionQuotation;
        // TODO: check this logic
        default:
          break;
      }
      break;
    case "order":
      switch (status) {
        case "approved":
        case "finished":
          return optionOrder;
        default:
          break;
      }
      break;
    case "invoice":
      switch (status) {
        case "waitPayment":
          return ["ใบลดหนี้"];
        case "fullyPaid":
          return ["ใบลดหนี้"];
        default:
          break;
      }
      break;
    case "return":
      switch (status) {
        case "approved":
        case "finished":
          return optionReturn;
        default:
          break;
      }
      break;
    case "delivery-order":
      switch (status) {
        case "waitDeliver":
        case "completed":
        case "notCompleted":
          return optionDO;
        default:
          break;
      }
      break;
    default:
      return [];
  }
  return [];
};
