import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OrderTable from "../../../components/Table/DocumentTable/OrderTable";
import { getSalesOrderTab } from "../../../features/Sales/Quotation/quotation-actions";

const OrderTab = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { salesOrderTab } = useSelector((state) => state.quotation);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(getSalesOrderTab(id, enqueueSnackbar));
    }
  }, [dispatch, enqueueSnackbar, id]);

  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        {t("sales.order.index")}
      </Typography>
      <OrderTable gridRef={gridRef} height={680} rowData={salesOrderTab} />
    </>
  );
};

export default OrderTab;
