import { createContext } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useMemo, useState } from "react";

const NavbarContext = createContext({
  sidebar: {},
});

export const NavbarContextProvider = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isSmallScreen ? true : false);

  const sidebar = useMemo(
    () => ({ isSidebarOpen, setIsSidebarOpen }),
    [isSidebarOpen, setIsSidebarOpen]
  );

  const navbarContext = {
    sidebar: sidebar,
  };

  return (
    <NavbarContext.Provider value={navbarContext}>
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContext;
