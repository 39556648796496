import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import PDFHeader from "../../../../components/UI/PDFHeader";
import BomPDFInfo from "./Info";
import TableInfo from "./Table";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../hooks/use-auth";
import { useTranslation } from "react-i18next";
import { getManufactureOrderById } from "../../../../features/Manufacture/ManufactureOrder/manufacture-order-actions";
import QRCode from "./QRCode";
import CustomizedScrollbar from "../../../../components/Custom/CustomizedScrollbar";
import { manufactureOrderActions } from "../../../../features/Manufacture/ManufactureOrder/manufacture-order-slice";

const ManufactureOrderPDF = () => {
  const dispatch = useDispatch();
  const { manufactureOrder } = useSelector((state) => state.manufactureOrder);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getManufactureOrderById(id, enqueueSnackbar));
    return () => {
      dispatch(manufactureOrderActions.resetManufactureOrder());
    };
  }, [dispatch, enqueueSnackbar, id, user]);

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h5">ใบสั่งผลิต/Manufacture Order</Typography>
            <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
          </Box>
          <Box>
            <Button size="small" variant="outlined" onClick={handlePrint}>
              พิมพ์เอกสาร
            </Button>
          </Box>
        </Box>
        <Box sx={{ m: "10px auto", width: "fit-content" }}>
          <Box
            sx={{
              width: "210mm",
              height: "297mm",
              border: "1px solid #eee",
              borderRadius: "5px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <CustomizedScrollbar
              sx={{
                height: "100%",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  p: 3,
                }}
                ref={componentRef}
              >
                <Box>
                  <PDFHeader
                    imgSize={140}
                    documentType="ใบสั่งผลิต/Manufacture Order"
                    noCompany
                  />
                  <Divider />
                  <BomPDFInfo manufactureOrder={manufactureOrder} t={t} />
                  <Divider />
                  <TableInfo manufactureOrder={manufactureOrder} />
                  <Footer
                    data={manufactureOrder}
                    documentType="manufacture_order"
                  />
                </Box>
                <QRCode />
              </Box>
            </CustomizedScrollbar>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ManufactureOrderPDF;
