import { useEffect, useMemo, useState } from "react";
import { customHeaderColRender } from "../utils/customCellRenderer";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatNumber, formatPriceNumber } from "../utils/dataTransformer";
import { unixToDateWithFormat } from "../utils/date-converter";

const customCellStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 6px",
};

export const useItemListColumns = (documentType, viewOnly) => {
  const { t } = useTranslation();

  const defaultColumns = useMemo(
    () => [
      {
        field: "no",
        headerComponentParams: {
          template: customHeaderColRender("No.", "รายการ"),
        },
        valueGetter: (params) => params.node.rowIndex + 1,
        cellStyle: customCellStyle,
        width: 90,
        checkboxSelection: viewOnly ? false : true,
      },
      {
        field: "item_name",
        headerComponentParams: {
          template: customHeaderColRender("Name", "ชื่อสินค้า"),
        },
        cellRenderer: (params) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "stretch",
                flex: 1,
                textAlign: "left",
              }}
            >
              <Typography variant="body2" align="left" mt={1} color="primary">
                {params.data.item_name}
              </Typography>
              <Typography
                variant="overline2"
                sx={{
                  my: 0,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {params.data.item_document_id}
              </Typography>
              <Typography
                variant="body2"
                align="left"
                gutterBottom
                sx={{
                  whiteSpace: "normal",
                }}
              >
                {params.data.item_description}
              </Typography>
            </Box>
          </Box>
        ),
        autoHeight: true,
        cellStyle: {
          padding: "0 6px",
        },
        width: 250,
      },
      {
        field: "qty",
        headerComponentParams: {
          template:
            documentType === "rs"
              ? customHeaderColRender("PO Qty", "จำนวนสั่งซื้อ")
              : customHeaderColRender("Qty", "จำนวน"),
        },
        cellRenderer: (params) => (
          <Typography variant="body2" align="center">
            {formatNumber(params.data.qty)}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 120,
      },
      {
        field: "uom_id",
        headerComponentParams: {
          template: customHeaderColRender("Unit", "หน่วย"),
        },
        cellRenderer: (params) => (
          <Typography variant="body2" align="center">
            {params.data.uom.name}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 130,
      },
      {
        field: "qty_uom",
        headerComponentParams: {
          template: customHeaderColRender("Stock Qty", "จำนวนคลัง"),
        },
        cellRenderer: (params) => (
          <Box>
            <Typography variant="body2" align="center">
              {formatNumber(params.data.qty_uom)}
            </Typography>
            <Typography
              align="center"
              sx={{ fontSize: "10px", whiteSpace: "normal" }}
            >
              {params.data.uom_group?.base_uom?.name}
            </Typography>
          </Box>
        ),
        cellStyle: customCellStyle,
        autoHeight: true,
        width: 120,
      },
      {
        field: "width_length_tolerance_positive",
        headerComponentParams: {
          template: customHeaderColRender(
            t("sales.tolerancePositive"),
            t("sales.widthLength")
          ),
        },
        cellRenderer: (params) => (
          <Typography variant="body2" align="center">
            {params.data.width_length_tolerance_positive}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 140,
      },
      {
        field: "width_length_tolerance_negative",
        headerComponentParams: {
          template: customHeaderColRender(
            t("sales.toleranceNegative"),
            t("sales.widthLength")
          ),
        },
        cellRenderer: (params) => (
          <Typography variant="body2" align="center">
            {params.data.width_length_tolerance_negative}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 140,
      },
      {
        field: "thickness_tolerance_positive",
        headerComponentParams: {
          template: customHeaderColRender(
            t("sales.tolerancePositive"),
            t("sales.thickness")
          ),
        },
        cellRenderer: (params) => (
          <Typography variant="body2" align="center">
            {params.data.thickness_tolerance_positive}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 100,
      },
      {
        field: "thickness_tolerance_negative",
        headerComponentParams: {
          template: customHeaderColRender(
            t("sales.toleranceNegative"),
            t("sales.thickness")
          ),
        },
        cellRenderer: (params) => (
          <Typography variant="body2" align="center">
            {params.data.thickness_tolerance_negative}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 100,
      },
      {
        field: "price_per_unit",
        headerComponentParams: {
          template: customHeaderColRender("Price/Unit", t("sales.price/unit")),
        },
        cellRenderer: (params) => (
          <Box>
            <Typography variant="body2" align="center">
              {formatPriceNumber(params.data.price_per_unit)}
            </Typography>
            {documentType !== "rs" && (
              <Typography
                align="center"
                sx={{ fontSize: "10px", whiteSpace: "normal" }}
              >
                ราคาทุน {formatPriceNumber(params.data.purchase_standard_price)}
              </Typography>
            )}
          </Box>
        ),
        cellStyle: customCellStyle,
        width: 125,
      },
      {
        field: "discount_amount",
        headerComponentParams: {
          template: customHeaderColRender("Discount", t("sales.discount")),
        },
        cellRenderer: (params) => (
          <Typography variant="body2" align="center">
            {formatPriceNumber(params.data.discount_amount)}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 125,
      },
      {
        field: "vat_type",
        headerComponentParams: {
          template: customHeaderColRender("Vat (%)", `${t("sales.vat")} (%)`),
        },
        cellRenderer: (params) => (
          <Typography variant="body2" align="center">
            {params.data.vat_type} {params.data.vat_type === "ไม่มี" ? "" : "%"}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 120,
      },
      {
        field: "pre_vat_amount",
        headerComponentParams: {
          template: customHeaderColRender(
            "Pre-vat Amount",
            t("sales.preVatAmount")
          ),
        },
        valueFormatter: (params) => formatNumber(params.data.pre_vat_amount),
        cellStyle: customCellStyle,
        width: 180,
      },
      {
        field: "withholding_tax",
        headerComponentParams: {
          template: customHeaderColRender(
            "Withholding Tax (%)",
            `${t("sales.withholdingTax")} (%)`
          ),
        },
        cellRenderer: (params) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="body2" align="center">
              {params.data.withholding_tax.type}{" "}
              {params.data.withholding_tax.type === "ยังไม่ระบุ" ||
              params.data.withholding_tax.type === "ไม่มี"
                ? ""
                : "%"}
            </Typography>
          </Box>
        ),
        cellStyle: customCellStyle,
        width: 180,
      },
      {
        field: "item_remark",
        headerComponentParams: {
          template: customHeaderColRender(
            "Product Remark",
            t("sales.itemRemark")
          ),
        },
        cellRenderer: (params) => (
          <Typography variant="body2" align="center">
            {params.data.item_remark}
          </Typography>
        ),
        cellStyle: customCellStyle,
        width: 220,
      },
    ],
    [documentType, t, viewOnly]
  );

  const [columnDefs, setColumnDefs] = useState(defaultColumns);

  useEffect(() => {
    if (documentType === "qa") {
      const newColumns = [...defaultColumns];
      newColumns.splice(
        1,
        0,
        {
          field: "document_id",
          headerComponentParams: {
            template: customHeaderColRender(
              "Document Id",
              t("sales.documentId")
            ),
          },
          cellRenderer: (params) => (
            <Typography variant="body2" align="center" color="primary">
              {params.data.document_id}
            </Typography>
          ),
          cellStyle: {
            padding: "0 6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          },
          width: 200,
        },
        {
          field: "issue_date",
          headerComponentParams: {
            template: customHeaderColRender("Issue Date", t("sales.issueDate")),
          },
          valueFormatter: (params) =>
            unixToDateWithFormat(params.data.issue_date),
          cellStyle: customCellStyle,
          width: 150,
        }
      );
      newColumns.splice(13, 3);
      setColumnDefs(newColumns);
    } else if (documentType === "so") {
      const newColumns = [...defaultColumns];
      newColumns.splice(
        1,
        0,
        {
          field: "document_id",
          headerComponentParams: {
            template: customHeaderColRender(
              "Document Id",
              t("sales.documentId")
            ),
          },
          cellRenderer: (params) => (
            <Typography variant="body2" align="center" color="primary">
              {params.data.document_id}
            </Typography>
          ),
          cellStyle: {
            padding: "0 6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          },
          width: 200,
        },
        {
          field: "issue_date",
          headerComponentParams: {
            template: customHeaderColRender("Issue Date", t("sales.issueDate")),
          },
          valueFormatter: (params) =>
            unixToDateWithFormat(params.data.issue_date),
          cellStyle: customCellStyle,
          width: 150,
        }
      );
      newColumns.splice(4, 0, {
        field: "bom_description",
        headerComponentParams: {
          template: customHeaderColRender("", "รายละเอียดวัตถุดิบ"),
        },
        cellStyle: customCellStyle,
      });
      newColumns.splice(14, 3);
      setColumnDefs(newColumns);
    } else if (documentType === "do" || documentType === "sr") {
      const newColumns = [...defaultColumns];
      newColumns.splice(5, 4);
      setColumnDefs(newColumns);
    } else if (documentType === "rs") {
      const newColumns = [...defaultColumns];
      newColumns.splice(5, 4);
      newColumns.splice(10, 1);
      setColumnDefs(newColumns);
    } else if (documentType === "mo") {
      const newColumns = [...defaultColumns];
      newColumns.splice(2, 0, {
        field: "bom_description",
        headerComponentParams: {
          template: customHeaderColRender("", "รายละเอียดวัตถุดิบ"),
        },
        cellStyle: customCellStyle,
      });
      newColumns.splice(6, 4);
      newColumns.splice(11, 1);
      setColumnDefs(newColumns);
    } else if (documentType === "po") {
      const newColumns = [...defaultColumns];
      newColumns.splice(
        1,
        0,
        {
          field: "document_id",
          headerComponentParams: {
            template: customHeaderColRender(
              "Document Id",
              t("sales.documentId")
            ),
          },
          cellRenderer: (params) => (
            <Typography variant="body2" align="center" color="primary">
              {params.data.document_id}
            </Typography>
          ),
          cellStyle: {
            padding: "0 6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          },
          width: 200,
        },
        {
          field: "issue_date",
          headerComponentParams: {
            template: customHeaderColRender("Issue Date", t("sales.issueDate")),
          },
          valueFormatter: (params) =>
            unixToDateWithFormat(params.data.issue_date),
          cellStyle: customCellStyle,
          width: 150,
        }
      );
      newColumns.splice(7, 4);
      newColumns.splice(9, 4);
      setColumnDefs(newColumns);
    }
  }, [defaultColumns, documentType, t]);

  return columnDefs;
};
