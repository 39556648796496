import { useCallback, useEffect, useState } from "react";
import SettingService from "../../../services/Setting";

export const useWorkCenterOptions = () => {
  // Plant
  const [plantLoading, setPlantLoading] = useState(false);
  const [plantOptions, setPlantOptions] = useState([]);
  const [openPlant, setOpenPlant] = useState(false);

  // Machine
  const [machineLoading, setMachineLoading] = useState(false);
  const [machineOptions, setMachineOptions] = useState([]);
  const [openMachine, setOpenMachine] = useState(false);

  const getAllPlants = useCallback(async () => {
    setPlantLoading(true);
    const { results } = await SettingService.getAllWorkCenter({
      startRow: 0,
      endRow: Math.pow(10, 10),
      filterModel: {},
      sortModel: [],
    });
    const formatPlantOptions = results.map((result) => ({
      id: result.id,
      label: result.plant,
      value: result.plant,
    }));

    setPlantOptions(formatPlantOptions);
    setPlantLoading(false);
  }, []);

  const getAllMachines = useCallback(async () => {
    setMachineLoading(true);
    const { results } = await SettingService.getAllWorkCenter({
      startRow: 0,
      endRow: Math.pow(10, 10),
      filterModel: {},
      sortModel: [],
    });
    const formatMachineOptions = results.map((result) => ({
      id: result.id,
      label: result.name,
      value: result.name,
    }));
    setMachineOptions(formatMachineOptions);
    setMachineLoading(false);
  }, []);

  useEffect(() => {
    if (openPlant) {
      getAllPlants();
    }
    if (openMachine) {
      getAllMachines();
    }
  }, [getAllMachines, getAllPlants, openMachine, openPlant]);

  const onOpenPlant = () => {
    setOpenPlant(true);
  };

  const onClosePlant = () => {
    setOpenPlant(false);
    setPlantOptions([]);
  };

  const onOpenMachine = () => {
    setOpenMachine(true);
  };

  const onCloseMachine = () => {
    setOpenMachine(false);
    setMachineOptions([]);
  };
  return {
    plantLoading,
    plantOptions,
    onOpenPlant,
    onClosePlant,
    machineLoading,
    machineOptions,
    onOpenMachine,
    onCloseMachine,
  };
};
