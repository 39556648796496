import React from "react";
import AgGrid from "../AgGrid";
import useGoodsIssueColumnDef from "../../../hooks/Inventory/useGoodsIssueColumnDef";

const GoodsIssueReportTable = ({ gridRef, onGridReady, rowData, isReport }) => {
  const columnDefs = useGoodsIssueColumnDef(isReport);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      onGridReady={onGridReady}
      rowData={rowData}
    />
  );
};

export default GoodsIssueReportTable;
