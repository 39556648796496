import { useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedCheckboxes from "../../../Custom/CustomizedCheckboxes";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import ModalUI from "../../../UI/ModalUI";
import addressData from "../../../../data/address.json";

const DeliveryAddressModal = ({
  control,
  errors,
  setValue,
  getDeliveryAddressValues,
  onSubmit,
  isOpen,
  onClose,
  viewOnly,
  setSubdistrict,
  setDistrict,
  setProvince,
  subdistrictOptions,
  districtOptions,
  provinceOptions,
  isDeliveryOrder,
  reset,
}) => {
  const { t } = useTranslation();
  const { contactSnapshot } = useSelector((state) => state.contact);

  const addressOptions = contactSnapshot
    ? contactSnapshot.address_list.map((address) => address.address_type)
    : [];

  const checkBoxField = [
    {
      name: "is_same_as_default_address",
      label: "เหมือนที่อยู่จดทะเบียน",
      xs: 12,
    },
  ];

  const countryOptions = ["ไทย"];

  const watchAddressType = useWatch({ control, name: "address_type" });
  const isSameAddress = useWatch({
    control,
    name: "is_same_as_default_address",
  });

  useEffect(() => {
    // autofill when is_same_as_default_address is checked
    if (isSameAddress) {
      const deliveryAddress =
        contactSnapshot.address_list?.filter(
          (address) => address.address_type === "ที่อยู่จดทะเบียน"
        )[0] || null;
      const contactName = getDeliveryAddressValues("address_contact_name");
      const contactPhone = getDeliveryAddressValues("address_contact_phone");
      const addressType = getDeliveryAddressValues("address_type");
      if (deliveryAddress) {
        const formattedDeliveryAddress = {
          address_type: addressType || "ที่อยู่จัดส่ง",
          is_same_as_default_address: true,
          address_contact_name: contactName || "",
          address_contact_phone: contactPhone || "",
          address: deliveryAddress.address || "",
          sub_district: deliveryAddress.sub_district || "",
          district: deliveryAddress.district || "",
          province: deliveryAddress.province || "",
          postal_code: deliveryAddress.postal_code || "",
          country: deliveryAddress.country || "",
        };
        reset({ ...formattedDeliveryAddress });
      }
    }
  }, [isSameAddress, reset, contactSnapshot, getDeliveryAddressValues]);

  const autofillDeliveryAddress = (option) => {
    const selectedAddress =
      contactSnapshot.address_list?.filter(
        (address) => address.address_type === option
      )[0] || null;
    if (selectedAddress) {
      const formattedDeliveryAddress = {
        address_type: selectedAddress.address_type,
        is_same_as_default_address: false,
        address_contact_name: selectedAddress.address_contact_name || "",
        address_contact_phone: selectedAddress.address_contact_phone || "",
        address: selectedAddress.address || "",
        sub_district: selectedAddress.sub_district || "",
        district: selectedAddress.district || "",
        province: selectedAddress.province || "",
        postal_code: selectedAddress.postal_code || "",
        country: selectedAddress.country || "",
      };
      reset({ ...formattedDeliveryAddress });
    }
  };

  const renderCheckBox = (name, label) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedCheckboxes label={label} isDisabled={viewOnly} {...field} />
      )}
    />
  );

  const renderAddressDetail = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-address_contact_name"
            name="address_contact_name"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.address_contact_name)}
                helperText={errors.address_contact_name?.message}
                label="ชื่อผู้ติดต่อ"
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-address_contact_phone"
            name="address_contact_phone"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.address_contact_phone)}
                helperText={errors.address_contact_phone?.message}
                label={t("user.account.phone")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            key="delivery-address"
            name="address"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.address)}
                helperText={errors.address?.message}
                label="ที่อยู่"
                {...field}
                disabled={viewOnly || (isSameAddress && !isDeliveryOrder)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-sub_district"
            name="sub_district"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={subdistrictOptions}
                title="แขวง/ตำบล"
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSubdistrict();
                    setDistrict();
                    setProvince();
                    return;
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setSubdistrict(option);
                  const filter = addressData.filter((adddress) => {
                    return `${adddress.sub_district}` === `${option}`;
                  });
                  if (filter && filter.length !== 0) {
                    setValue("district", filter[0].district);
                    setValue("province", filter[0].province);
                    setValue("postal_code", filter[0].postal_code);
                  } else if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly || (isSameAddress && !isDeliveryOrder)}
                disabled={viewOnly || (isSameAddress && !isDeliveryOrder)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-district"
            name="district"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={districtOptions}
                title="เขต/อำเภอ"
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSubdistrict();
                    setDistrict();
                    setProvince();
                    return;
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setDistrict(option);
                  const filter = addressData.filter((adddress) => {
                    return `${adddress.district}` === `${option}`;
                  });
                  if (filter && filter.length !== 0) {
                    setValue("province", filter[0].province);
                  } else if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly || (isSameAddress && !isDeliveryOrder)}
                disabled={viewOnly || (isSameAddress && !isDeliveryOrder)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-province"
            name="province"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={provinceOptions}
                title="จังหวัด"
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSubdistrict();
                    setDistrict();
                    setProvince();
                    return;
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setProvince(option);
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly || (isSameAddress && !isDeliveryOrder)}
                disabled={viewOnly || (isSameAddress && !isDeliveryOrder)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-postal_code"
            name="postal_code"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.postal_code)}
                helperText={errors.postal_code?.message}
                label="รหัสไปรษณีย์"
                {...field}
                disabled={viewOnly || (isSameAddress && !isDeliveryOrder)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-country"
            name="country"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={countryOptions}
                title="ประเทศ"
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  if (option.value) {
                    return field.onChange(option.value);
                  }
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly || (isSameAddress && !isDeliveryOrder)}
                disabled={viewOnly || (isSameAddress && !isDeliveryOrder)}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalUI
      title={
        <Typography fontWeight="bold" sx={{ ml: 1 }}>
          ที่อยู่จัดส่ง
        </Typography>
      }
      open={isOpen}
      handleClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-address_type"
            name="address_type"
            control={control}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                fullWidth
                error={Boolean(errors.address_type)}
                helperText={errors.address_type?.message}
                label="เลือกที่อยู่"
                options={addressOptions}
                onChange={(e) => {
                  autofillDeliveryAddress(e.target.value);
                  return field.onChange(e.target.value);
                }}
                disabled={viewOnly || (isSameAddress && !isDeliveryOrder)}
              />
            )}
          />
        </Grid>
      </Grid>
      {!isDeliveryOrder &&
        checkBoxField.map((field, index) => (
          <Box sx={{ ml: 1, mb: 2 }} key={index}>
            {renderCheckBox(field.name, field.label, index)}
          </Box>
        ))}
      {(watchAddressType || isDeliveryOrder) && renderAddressDetail()}
      <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
        <CustomizedButton
          title={t("button.cancel")}
          variant="outlined"
          size="medium"
          onClick={onClose}
          disabled={viewOnly}
        />
        <CustomizedButton
          title={t("button.save")}
          onClick={onSubmit}
          type="submit"
          variant="contained"
          size="medium"
          disabled={viewOnly}
        />
      </Box>
    </ModalUI>
  );
};

export default DeliveryAddressModal;
