import { vehicleActions } from "./vehicle-slice";
import SettingService from "../../../services/Setting/index";
import LogisticService from "../../../services/Logistic";

export const getAllVehicles = (enqueueSnackbar) => async (dispatch) => {
  dispatch(vehicleActions.onLoading("allVehicles"));
  try {
    const allVehicle = await SettingService.getAllVehicles();
    dispatch(vehicleActions.loadedAllVehicles(allVehicle));
  } catch (err) {
    dispatch(vehicleActions.rejectedActions({ ...err, name: "allVehicles" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const getAllVehiclesWithDT =
  (enqueueSnackbar, date) => async (dispatch) => {
    dispatch(vehicleActions.onLoading("allVehiclesModal"));
    try {
      const allVehicle = await SettingService.getAllVehicles();
      const allVehicleLicense_plate = allVehicle.map(
        (vehicle) => vehicle.license_plate
      );
      const deliveryTrips = await LogisticService.getAllDeliveryTripsByVehicle(
        allVehicleLicense_plate,
        date
      );
      allVehicle.forEach((vehicle, index) => {
        const allDeliveryCount = deliveryTrips.results.filter(
          (dt) => dt.vehicle.license_plate === vehicle.license_plate
        ).length;
        allVehicle[index].total_delivery_trip = allDeliveryCount;
      });
      dispatch(vehicleActions.loadedAllVehicles(allVehicle));
    } catch (err) {
      dispatch(
        vehicleActions.rejectedActions({ ...err, name: "allVehiclesModal" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getVehicleById = (id) => async (dispatch) => {
  dispatch(vehicleActions.onLoading("vehicle"));
  try {
    const vehicle = await SettingService.getVehicleById(id);
    dispatch(vehicleActions.loadedVehicle(vehicle));
  } catch (err) {
    dispatch(vehicleActions.rejectedActions({ ...err, name: "vehicle" }));
  }
};

export const createVehicle =
  (payload, navigate, enqueueSnackbar) => async (dispatch) => {
    dispatch(vehicleActions.onLoading("vehicle"));
    try {
      const vehicle = await SettingService.createVehicle(payload);
      dispatch(vehicleActions.loadedVehicle(vehicle));
      navigate(`/setting/logistic/vehicle/${vehicle.id}`);
      enqueueSnackbar("เพิ่มรถสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      dispatch(vehicleActions.rejectedActions({ ...err, name: "vehicle" }));
      enqueueSnackbar("เพิ่มรถไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const updateVehicle = (payload, enqueueSnackbar) => async (dispatch) => {
  dispatch(vehicleActions.onLoading("vehicle"));
  try {
    const vehicle = await SettingService.updateVehicle(payload);
    dispatch(vehicleActions.loadedVehicle(vehicle));
    enqueueSnackbar("แก้ไขข้อมูลรถสำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    dispatch(vehicleActions.rejectedActions({ ...err, name: "vehicle" }));
    enqueueSnackbar("แก้ไขข้อมูลรถไม่สำเร็จ", {
      variant: "error",
    });
  }
};

export const deleteVehicle =
  (removeVehicleId, navigate, enqueueSnackbar) => async (dispatch) => {
    dispatch(vehicleActions.onLoading("vehicle"));
    try {
      await SettingService.deleteVehicle(removeVehicleId);
      dispatch(vehicleActions.onLoaded());
      navigate("/setting/logistic/vehicle");
      enqueueSnackbar("ลบข้อมูลรถสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      dispatch(vehicleActions.rejectedActions({ ...err, name: "vehicle" }));
      enqueueSnackbar("ลบข้อมูลรถไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
