import { createSlice } from "@reduxjs/toolkit";

const remarkTemplateSlice = createSlice({
  name: "remarkTemplate",
  initialState: {
    isLoading: {
      allRemarkTemplate: false,
      selectedRemarkTemplate: false,
    },
    allRemarkTemplate: {
      remarkTemplate: [],
    },
    selectedRemarkTemplate: [],
    error: null,
  },
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload] = false;
      state.error = action.payload;
    },
    loadedAllRemarkTemplate(state, action) {
      const filterByType = action.payload.allRemarkTemplate
        .filter((template) => template.document_type === action.payload.type)
        .map((data) => {
          return {
            ...data,
            refId: data.id,
          };
        });
      state.allRemarkTemplate.remarkTemplate = filterByType;
      state.isLoading.allRemarkTemplate = false;
    },
    resetRemarkTemplate(state, action) {
      state.remarkTemplate = [];
    },
  },
});

export const remarkTemplateActions = remarkTemplateSlice.actions;

export default remarkTemplateSlice.reducer;
