import { useEffect, useState } from "react";
import { permissionAction } from "../../utils/userInfo";

const allPermissionList = permissionAction();

const formatAction = (formatList) => {
  return formatList.reduce((a, v) => {
    const action = v.split("__")[2];
    return { ...a, [action]: v };
  }, {});
};

export const usePurchaseRequestPermission = () => {
  const [purchasePermission, setPurchasePermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("PURCHASE__REQUEST")
    );
    const actions = formatAction(formatList);
    setPurchasePermission(actions);
  }, []);

  return purchasePermission;
};

export const usePurchaseOrderPermission = () => {
  const [purchasePermission, setPurchasePermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("PURCHASE__ORDER")
    );
    const actions = formatAction(formatList);
    setPurchasePermission(actions);
  }, []);

  return purchasePermission;
};

export const usePurchaseReturnPermission = () => {
  const [purchasePermission, setPurchasePermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("PURCHASE__RETURN")
    );
    const actions = formatAction(formatList);
    setPurchasePermission(actions);
  }, []);

  return purchasePermission;
};

export const usePurchaseReportPermission = () => {
  const [purchasePermission, setPurchasePermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("PURCHASE__REPORT")
    );
    const actions = formatAction(formatList);
    setPurchasePermission(actions);
  }, []);

  return purchasePermission;
};
