import { useEffect, useState } from "react";

export const useStatusOptions = (status) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    switch (status) {
      case "pendingManu":
      case "inProgress":
        setDisabled(false);
        break;
      case "finished":
        setDisabled(true);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return disabled;
};
