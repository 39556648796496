import React from "react";
import { useRef } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import AgGrid from "../AgGrid";

const customCellStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 6px",
};

const options = [
  {
    label: "SI_123123123",
    value: "SI_123123123",
  },
  {
    label: "SI_12312312123",
    value: "SI_12312312232",
  },
  {
    label: "SI_123123123222",
    value: "SI_123123123333",
  },
];

const CreditTable = ({ control, disabled, errors }) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const columnDefs = [
    {
      field: "si_document_id",
      headerName: t("sales.documentId"),
      cellRenderer: () => (
        <Controller
          control={control}
          name="si_document_id"
          render={({ field }) => (
            <CustomizedSelect
              {...field}
              disabled={disabled}
              fullWidth
              options={options}
              error={Boolean(errors.si_document_id)}
              helperText={errors.si_document_id?.message}
            />
          )}
        />
      ),
      filter: false,
      width: 250,
      cellStyle: customCellStyle,
    },
    {
      field: "issue_date",
      headerName: t("sales.issueDate"),
      width: 200,
      filter: false,
    },
    {
      field: "pending_payment",
      headerName: t("sales.paymentReceipt.waitingToPay"),
      filter: false,
      width: 200,
    },
    {
      field: "total_amount",
      headerName: t("sales.totalAmountInTable"),
      filter: false,
      width: 200,
    },
    {
      field: "employee_list",
      headerName: t("sales.employeeList"),
      filter: false,
      cellRenderer: (params) => {
        if (params.data.employee_list) {
          return (
            <CustomizedAvatar
              avatars={[params.data.created_by, ...params.data.employee_list]}
            />
          );
        }
      },
      width: 200,
    },
  ];

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={120}
      disableFloatingFilter
      disabledSidebar
      rowData={[
        {
          si_document_id: "",
          contact_name: null,
          issue_date: null,
          total_amount: null,
          employee_list: null,
        },
      ]}
    />
  );
};

export default CreditTable;
