import { useState, forwardRef } from "react";
import { Grid, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedCheckboxes from "../../../Custom/CustomizedCheckboxes";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import GeneralImageDropzone from "../../Contact/GeneralImageDropzone";
import ControlledDatePicker from "../../../Custom/ControlledDatePicker";

const CompanyInfoGeneral = forwardRef(
  ({ control, errors, setValue, getValues, watch, disabled }, ref) => {
    const { t } = useTranslation();
    const [identityNoError, setIdentityNoError] = useState(false);
    const [registerDatePickerIsOpen, setRegisterDatePickerIsOpen] =
      useState(false);
    const [registerVatDatePickerIsOpen, setRegisterVatDatePickerIsOpen] =
      useState(false);

    const watchRegisterVat = watch("is_registered_vat");

    const corporateTypes = [
      {
        id: 1,
        label: t("contact.info.coperate_type.company"),
        value: "บริษัท",
      },
      {
        id: 2,
        label: t("contact.info.coperate_type.public_limited"),
        value: "บริษัทมหาชนจำกัด",
      },
      {
        id: 3,
        label: t("contact.info.coperate_type.limited_partnership"),
        value: "ห้างหุ้นส่วนจำกัด",
      },
      {
        id: 4,
        label: t("contact.info.coperate_type.juristic_partnership"),
        value: "ห้างหุ้นส่วนสามัญนิติบุคคล",
      },
      {
        id: 5,
        label: t("contact.info.coperate_type.association"),
        value: "สมาคม",
      },
      { id: 6, label: t("contact.info.coperate_type.other"), value: "อื่นๆ" },
    ];

    const renderCorporateType = () => {
      return (
        <>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              key="corporate-brand"
              name="company_name"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.company_name)}
                  helperText={errors.company_name?.message}
                  label={t("contact.info.brand_name")}
                  disabled={disabled}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              key="corporate-type"
              name="contact_type_2"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.contact_type_2)}
                  helperText={errors.contact_type_2?.message}
                  label={t("contact.info.coperate_type.index")}
                  options={corporateTypes}
                  disabled={disabled}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              key="corporate-taxpayer_id"
              name="identity_no"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.identity_no) || identityNoError}
                  helperText={
                    identityNoError
                      ? "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"
                      : errors.identity_no?.message
                  }
                  label={t("contact.info.taxpayer_id")}
                  {...field}
                  onChange={(e) => {
                    if (e.target.value.length > 13) {
                      setIdentityNoError(true);
                      return getValues("identity_no");
                    }
                    setIdentityNoError(false);
                    return field.onChange(e.target.value);
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ControlledDatePicker
              key="corporate-register_date"
              name="register_date"
              control={control}
              error={errors.register_date}
              isOpen={registerDatePickerIsOpen}
              onClose={() => setRegisterDatePickerIsOpen(false)}
              onOpen={() => setRegisterDatePickerIsOpen(true)}
              label="วันที่จดทะเบียนกิจการ"
              disabled={disabled}
            />
          </Grid>
        </>
      );
    };

    return (
      <>
        <Grid container spacing={2} ref={ref}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Grid container spacing={2}>
              {renderCorporateType()}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2, ml: 1 }}>
                <Controller
                  name="is_registered_vat"
                  control={control}
                  render={({ field }) => (
                    <CustomizedCheckboxes
                      label="จดทะเบียนภาษีมูลค่าเพิ่ม"
                      isDisabled={disabled}
                      {...field}
                    />
                  )}
                />
              </Grid>
              {watchRegisterVat && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ControlledDatePicker
                    name="vat_registration_date"
                    control={control}
                    error={errors.vat_registration_date?.error}
                    isOpen={registerVatDatePickerIsOpen}
                    onClose={() => setRegisterVatDatePickerIsOpen(false)}
                    onOpen={() => setRegisterVatDatePickerIsOpen(true)}
                    label="วันที่จดทะเบียนภาษีมูลค่าเพิ่ม"
                    disabled={disabled}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box sx={{ width: "50%", mx: "auto" }}>
              <GeneralImageDropzone
                control={control}
                setValue={setValue}
                valueToSet={"img_url"}
                filesLimit={1}
                disabled={disabled}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default CompanyInfoGeneral;
