import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/dataTransformer";
import AgGrid from "../AgGrid";

const PurchaseByVendorTable = ({ gridRef, rowData }) => {
  const { t } = useTranslation();

  const columnDefs = [
    {
      field: "contact_document_id",
      headerName: t("purchase.report.vendorDocumentId"),
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      field: "contact_name",
      headerName: t("purchase.report.vendorName"),
      filter: "agTextColumnFilter",
    },
    {
      field: "total_purchase_order",
      headerName: t("purchase.report.totalPurchaseOrder"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "sum_pre_vat_amount_exclude_discount",
      headerName: t("sales.report.preVatAmountExcludeDiscount"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) =>
        formatNumber(params.data.sum_pre_vat_amount_exclude_discount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_item_discount",
      headerName: t("reports.item_discount"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) => formatNumber(params.data.sum_item_discount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_additional_discount",
      headerName: t("sales.report.additionalDiscount"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) =>
        formatNumber(params.data.sum_additional_discount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_pre_vat_amount_include_discount",
      headerName: t("reports.pre_vat_amount"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) =>
        formatNumber(params.data.sum_pre_vat_amount_include_discount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_vat_amount",
      headerName: t("sales.report.vatAmount"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) => formatNumber(params.data.sum_vat_amount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_net_amount",
      headerName: t("sales.report.netAmount"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) => formatNumber(params.data.sum_net_amount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_withholding_tax",
      headerName: t("sales.report.withholdingTax"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) => formatNumber(params.data.sum_withholding_tax),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_shipping_cost",
      headerName: t("sales.report.shippingCost"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) => formatNumber(params.data.sum_shipping_cost),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_total_amount",
      headerName: t("sales.report.totalAmount"),
      filter: "agNumberColumnFilter",
      valueGetter: (params) => formatNumber(params.data.sum_total_amount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  ];

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      rowData={rowData}
    />
  );
};

export default PurchaseByVendorTable;
