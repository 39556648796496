import React from "react";
import AgGrid from "../AgGrid";
import useGoodsReceiveColumnDef from "../../../hooks/Inventory/useGoodsReceiveColumnDef";

const GoodsReceiveReportTable = ({
  gridRef,
  onGridReady,
  rowData,
  isReport,
}) => {
  const columnDefs = useGoodsReceiveColumnDef(isReport);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      onGridReady={onGridReady}
      rowData={rowData}
    />
  );
};

export default GoodsReceiveReportTable;
