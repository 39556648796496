import * as Yup from "yup";

export const acceptProofSchema = {
  accepted_date: null,
  accepted_remark: "",
  attachment_list: [],
};

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

export const acceptProofValidation = Yup.object().shape({
  accepted_date: Yup.date()
    .required("กรุณาระบุวันที่ลูกค้ายอมรับ")
    .nullable()
    .transform((value) => dateIsValid(value)),
});
