import InventoryService from "../../../services/Inventory";
import ManufactureService from "../../../services/Manufacture";
import ActivityLogsService from "../../../services/ActivityLogs";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import { dateToUnix } from "../../../utils/date-converter";
import { bomActions } from "./bom-slice";
import { bomsExportFormatter } from "../../../utils/manufacturePayloadFormatter";
import {
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
} from "../../../utils/activityLogsPayloadFormatter";

export const formatBom = async (data) => {
  const { ingredient_list, stock_uom, item, ...payload } = data;

  const allItemsDocumentId =
    ingredient_list?.map((item) => item.item_document_id) || [];

  const items = await InventoryService.getAllItems({
    findManyInput: {
      where: {
        document_id: {
          in: allItemsDocumentId,
        },
      },
    },
  });

  const formatItemList = [];

  ingredient_list?.forEach((item) => {
    const foundItemIndex = items?.findIndex(
      (realItem) => realItem.document_id === item.item_document_id
    );
    formatItemList.push({
      ...item,
      current_ordered_purchase_qty:
        items[foundItemIndex]?.current_ordered_purchase_qty,
      current_ordered_manufacture_qty:
        items[foundItemIndex]?.current_ordered_manufacture_qty,
      current_committed_sales_qty:
        items[foundItemIndex]?.current_committed_sales_qty,
      current_committed_manufacture_qty:
        items[foundItemIndex]?.current_committed_manufacture_qty,
      current_stock_qty: items[foundItemIndex]?.current_stock_qty,
      current_available_qty: items[foundItemIndex]?.current_available_qty,
    });
  });

  const formatPayload = {
    ...payload,
    ingredient_list: formatItemList,
    stock_uom: stock_uom?.name,
    minimum_stock_qty: item?.manufacture_minimum_qty ?? 0,
  };

  return formatPayload;
};

export const getAllBoms =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(bomActions.onLoading("allBoms"));
    try {
      const allBoms = await ManufactureService.getAllBoms(input);
      params.successCallback(allBoms.results, allBoms.count);
      dispatch(bomActions.loadedAllBoms(allBoms.results));
    } catch (err) {
      console.error(err);
      dispatch(
        bomActions.rejectedActions({
          ...err,
          name: "allBoms",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getBomById = (id, enqueueSnackbar) => async (dispatch) => {
  dispatch(bomActions.onLoading("bom"));
  try {
    const bom = await ManufactureService.getBomById(id);
    const formattedBom = await formatBom(bom);
    dispatch(bomActions.loadedBom(formattedBom));
  } catch (err) {
    console.error(err);
    dispatch(bomActions.rejectedActions({ ...err, name: "bom" }));
    err.response.errors.forEach((error) => {
      if (errorMessageHandler(error.message)) {
        enqueueSnackbar(errorMessageHandler(error.message), {
          variant: "error",
        });
      }
    });
  }
};

export const createBom =
  (data, enqueueSnackbar, navigate, user, state) => async (dispatch) => {
    dispatch(bomActions.onLoading("bom"));
    const {
      ingredient_list,
      stock_uom,
      created_date,
      start_date,
      end_date,
      mfg_qty,
      minimum_stock_qty,
      ...payload
    } = data;
    const unixStartDate = dateToUnix(start_date);
    const unixEndDate = dateToUnix(end_date);

    const formatItemList = ingredient_list.map(
      ({
        current_ordered_purchase_qty,
        current_ordered_manufacture_qty,
        current_committed_sales_qty,
        current_committed_manufacture_qty,
        current_stock_qty,
        current_available_qty,
        qty,
        ...otherItemList
      }) => ({ ...otherItemList, qty: parseFloat(qty) })
    );

    const formatPayload = {
      ...payload,
      mfg_qty: mfg_qty,
      start_date: unixStartDate,
      end_date: unixEndDate,
      ingredient_list: formatItemList,
    };

    try {
      const createdBom = await ManufactureService.createBom(formatPayload);
      const activityLogPayload = {
        document_id: createdBom.id.toString(),
        creator_document_id: user.document_id,
      };
      const createActivityLog = createActivityLogPayload(
        activityLogPayload,
        "bom",
        "สร้างสูตรการผลิต"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      if (state && state?.isCopied) {
        const activityLogPayload = {
          document_id: state.id.toString(),
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogPayload,
          "bom"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }
      enqueueSnackbar("สร้างสูตรการผลิตสำเร็จ", {
        variant: "success",
      });
      navigate(`/manufacture/bom/${createdBom.id}`);
    } catch (err) {
      console.error(err);
      dispatch(bomActions.rejectedActions({ ...err, name: "bom" }));
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(
            errorMessageHandler(error.message, "ชื่อสูตรนี้มีในระบบแล้ว"),
            {
              variant: "error",
            }
          );
        } else {
          enqueueSnackbar("สร้างสูตรการผลิตไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const updateBom =
  (updateId, data, enqueueSnackbar, user, changeMainBom) =>
  async (dispatch) => {
    dispatch(bomActions.onLoading("bom"));
    const {
      ingredient_list,
      stock_uom,
      created_date,
      start_date,
      end_date,
      created_by,
      creator_document_id,
      mfg_qty,
      minimum_stock_qty,
      id,
      ...payload
    } = data;
    const unixStartDate = dateToUnix(start_date);
    const unixEndDate = dateToUnix(end_date);

    const formatItemList = ingredient_list.map(
      ({
        current_ordered_purchase_qty,
        current_ordered_manufacture_qty,
        current_committed_sales_qty,
        current_committed_manufacture_qty,
        current_stock_qty,
        current_available_qty,
        qty,
        ...otherItemList
      }) => ({ ...otherItemList, qty: parseFloat(qty) })
    );

    const formatPayload = {
      ...payload,
      start_date: unixStartDate,
      end_date: unixEndDate,
      ingredient_list: formatItemList,
      mfg_qty: mfg_qty,
    };

    const activityLogPayload = {
      document_id: data.id.toString(),
      creator_document_id: user.document_id,
    };

    try {
      const updatedBom = await ManufactureService.updateBom(
        updateId,
        formatPayload
      );
      const formattedBom = await formatBom(updatedBom);
      dispatch(bomActions.loadedBom(formattedBom));
      enqueueSnackbar("แก้ไขสูตรการผลิตสำเร็จ", {
        variant: "success",
      });
      if (changeMainBom) {
        const createActivityLogStatus = createActivityLogStatusPayload(
          activityLogPayload,
          "bom",
          null,
          "is_main_bom"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createActivityLogStatus,
        });
      } else {
        const editActivityLog = createActivityLogEditPayload(
          activityLogPayload,
          "bom"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
      }
    } catch (err) {
      console.error(err);
      dispatch(bomActions.rejectedActions({ ...err, name: "bom" }));
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(
            errorMessageHandler(error.message, "ชื่อสูตรนี้มีในระบบแล้ว"),
            {
              variant: "error",
            }
          );
        } else {
          enqueueSnackbar("แก้ไขสูตรการผลิตไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const deleteBom =
  (id, enqueueSnackbar, navigate) => async (dispatch) => {
    dispatch(bomActions.onLoading("bom"));
    try {
      await ManufactureService.deleteBom(id);
      navigate("/manufacture/bom");
      enqueueSnackbar("ลบสูตรการผลิตสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      dispatch(bomActions.rejectedActions({ ...err, name: "bom" }));
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("ลบสูตรการผลิตไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getAllBomsExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(bomActions.onLoading("allBomsExport"));
    try {
      const { results } = await ManufactureService.getAllBoms(input);
      let boms = await bomsExportFormatter(results);
      dispatch(bomActions.loadedAllBomsExport(boms));
    } catch (err) {
      console.error(err);
      dispatch(
        bomActions.rejectedActions({
          ...err,
          name: "allBomsExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllBomsExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(bomActions.onLoading("allBomsExport"));
    try {
      const { results } = await ManufactureService.getAllBomsReport(input);
      let boms = await bomsExportFormatter(results);
      dispatch(bomActions.loadedAllBomsExport(boms));
    } catch (err) {
      console.error(err);
      dispatch(
        bomActions.rejectedActions({
          ...err,
          name: "allBomsExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
