import React, { forwardRef } from "react";
import { Mention, MentionsInput } from "react-mentions";

const CustomizedMentionsInput = forwardRef(
  ({ value, onChange, data, ...props }, ref) => {
    const mentionInputStyle = {
      control: {
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: "normal",
        // margin: "1rem 0",
        fontFamily: ["Kanit", "sans-serif"].join(","),
        "&focus-visible": { outline: " 4px solid #328F35" },
      },
      highlighter: {
        overflow: "hidden",
        height: 120,
        lineHeight: "1.5rem",
      },
      input: {
        overflow: "auto",
        height: 120,
        lineHeight: "1.5rem",
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
      },

      "&multiLine": {
        control: {
          height: 120,
        },
        highlighter: {
          padding: 9,
        },
        input: {
          padding: 9,
          border: "1px solid silver",
          "&focused": { outline: " 4px solid #328F35" },
          marginLeft: 0,
          marginRight: 0,
        },
      },

      suggestions: {
        list: {
          backgroundColor: "white",
          fontSize: 14,
          maxHeight: "200px",
          overflow: "auto",
          boxShadow: "0px 2px 5px #E5E5E5",
        },
        item: {
          padding: "5px 15px",
          "&focused": {
            backgroundColor: "#C7E6C7",
          },
        },
      },
    };

    const mentionStyle = {
      position: "relative",
      zIndex: 1,
      color: "#328F35",
      pointerEvents: "none",
      border: "none",
      backgroundColor: "#C7E6C7",
      borderRadius: "3px",
    };

    return (
      <MentionsInput
        {...props}
        ref={ref}
        value={value}
        onChange={onChange}
        style={mentionInputStyle}
      >
        <Mention
          trigger="@"
          displayTransform={(_, display) => `@${display}`}
          data={data}
          allowSuggestionsAboveCursor
          style={mentionStyle}
          appendSpaceOnAdd
        />
      </MentionsInput>
    );
  }
);

export default CustomizedMentionsInput;
