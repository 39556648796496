import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import PermissionForm from "../../../components/Form/Permission";
import { useSnackbar } from "notistack";
import CustomizedMenuOption from "../../../components/Custom/CustomizedMenuOption";
import { usePermission } from "../../../hooks/use-auth";

const PermissionTab = ({
  control,
  errors,
  trigger,
  viewOnly,
  fields,
  onRoleDelete,
  onTriggerRevalidate,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [option, setOption] = useState("");
  const { roleId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { editPermission, deletePermission } = usePermission();

  const selectedOption = [
    {
      value: "แก้ไข",
      disabled: !editPermission,
    },
    {
      value: "ลบ",
      disabled: !deletePermission,
    },
  ];

  const onButtonClicked = async () => {
    const validInput = await trigger(["name", "description"]);
    onTriggerRevalidate();
    if (validInput) navigate(`${pathname}?step=employee`);
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 3 }}
      >
        <Grid item>
          <Typography variant="h5">{t("user.rbac.index")}</Typography>
        </Grid>
        {viewOnly && roleId !== "RL-ADMIN" && (
          <Grid item>
            <CustomizedMenuOption
              label={"ตัวเลือก"}
              options={selectedOption}
              onSelect={(e) => {
                setOption(e.target.innerText);
                if (e.target.innerText === "ลบ") {
                  if (fields.length === 0) {
                    onRoleDelete(roleId);
                    //handle success
                  } else {
                    enqueueSnackbar("กรุณาลบพนักงานที่มีสิทธิ์", {
                      variant: "error",
                    });
                  }
                }
              }}
            />
          </Grid>
        )}
      </Grid>
      <CustomizedBox>
        <Typography fontWeight="bold" ml={1} mb={2}>
          {t("user.rbac.index")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  label={t("user.rbac.name")}
                  {...field}
                  disabled={option === "แก้ไข" ? false : viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  label={t("user.rbac.description")}
                  {...field}
                  disabled={option === "แก้ไข" ? false : viewOnly}
                />
              )}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <PermissionForm
        control={control}
        errors={errors}
        onClick={onButtonClicked}
        viewOnly={option === "แก้ไข" ? false : viewOnly}
      />
    </>
  );
};

export default PermissionTab;
