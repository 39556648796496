import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PDFHeaderGT from "../../../../components/UI/PDFHeaderGT";
import { useReactToPrint } from "react-to-print";
import PDFSigner from "../../../../components/UI/PDFSigner";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../hooks/use-auth";
import { getGoodsTransferById } from "../../../../features/Inventory/GoodsTransfer/goodsTransfer-actions";
import GoodsTransferPDFInfo from "../Pdf/Info";
import GoodsTransferTablePDF from "./GoodsTransferTablePDF";

const GoodsTransferPDF = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const { item } = useSelector((state) => state.goodsTransfer);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { id } = useParams();

  useEffect(() => {
    dispatch(getGoodsTransferById({ uniqueInput: { document_id: id } }));
  }, [dispatch, enqueueSnackbar, id, user]);

  return (
    <Box>
      <style>{`@media print {
        html,
        body {
          height: 100%;
          margin: 0 !important;
          padding: 0 !important;
          overflow: hidden;
        }
      
        @page {
          size: 297mm 210mm;
        }
      }`}</style>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">โอนย้าย/Transfer</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          m: "10px auto",
          width: "fit-content",
        }}
      >
        <div
          ref={componentRef}
          style={{
            width: "297mm",
            height: "210mm",
            border: "1px solid #eee",
            borderRadius: "5px",
            padding: "2rem",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <PDFHeaderGT>
            <GoodsTransferPDFInfo goodsTransfer={item} />
          </PDFHeaderGT>
          <Divider />
          <Box pt={2}>
            <GoodsTransferTablePDF fields={item.goods_transfer_list} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "2rem",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          >
            <PDFSigner documentType="GT" />
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default GoodsTransferPDF;
