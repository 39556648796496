import { Grid, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { useTranslation } from "react-i18next";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import ControlledServerSideCreatable from "../../Custom/ControlledSSCreatable";

const VehicleForm = ({ control, errors, disabled, setValue }) => {
  const { t } = useTranslation();

  const radioList = [
    { value: "active", label: "ใช้งาน" },
    { value: "inactive", label: "หยุดใช้งาน" },
  ];

  const vehicleType = [
    { value: "รถเล็ก", label: "รถเล็ก" },
    { value: "รถใหญ่", label: "รถใหญ่" },
    { value: "ลูกพ่วง", label: "ลูกพ่วง" },
  ];

  return (
    <>
      <CustomizedBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
              {t("setting.logistic.vehicleInfo")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {/* <ControlledServerSideCreatable
                  title={t("setting.logistic.licensePlate")}
                  control={control}
                  name="license_plate"
                  documentType="vehicle"
                  defaultOptions={[]}
                  error={Boolean(errors.license_plate)}
                  helperText={errors.license_plate?.message}
                  setValue={setValue}
                  viewOnly={disabled}
                /> */}
                <Controller
                  name="license_plate"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.license_plate)}
                      helperText={errors.license_plate?.message}
                      label={t("setting.logistic.licensePlate")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledServerSideCreatable
                  title={t("setting.logistic.vehicleType")}
                  control={control}
                  name="vehicle_type"
                  documentType="vehicle"
                  defaultOptions={vehicleType}
                  error={Boolean(errors.vehicle_type)}
                  helperText={errors.vehicle_type?.message}
                  setValue={setValue}
                  viewOnly={disabled}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("setting.logistic.vehicleSize")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="volume"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      type="number"
                      error={Boolean(errors.volume)}
                      helperText={errors.volume?.message}
                      label={t("setting.logistic.volume")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="weight"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      type="number"
                      error={Boolean(errors.weight)}
                      helperText={errors.weight?.message}
                      label={t("setting.logistic.weight")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              สถานะ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <CustomizedRadioGroup
                      {...field}
                      radioList={radioList}
                      row
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="remark_status"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.remark)}
                      label={t("user.account.remark")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("setting.logistic.remark")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="remark"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.remark)}
                      helperText={errors.remark?.message}
                      multiline
                      minRows={3}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1.5} xl={1.5} />
        </Grid>
      </CustomizedBox>
    </>
  );
};

export default VehicleForm;
