import { useRef, useEffect, useState } from "react";
import { Box, Typography, LinearProgress, Avatar } from "@mui/material";
import CustomizedLetterAvatar from "../../components/Custom/CustomizedLetterAvatar";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import moment from "moment";
import AgGrid from "../../components/Table/AgGrid";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import CustomizedTab from "../../components/Custom/CustomizedTab";
import { unixToDateWithFormat } from "../../utils/date-converter";
import { useApproval } from "../../hooks/use-approval";
import { useAuth } from "../../hooks/use-auth";
import UserService from "../../services/User";

const Approval = () => {
  const gridRef = useRef();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("type");
  const { t } = useTranslation();
  const { approvals, refreshApprovals } = useApproval();
  const { user } = useAuth();
  const [mappedApprovals, setMappedApprovals] = useState([]);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    refreshApprovals();
  }, [refreshApprovals]);

  useEffect(() => {
    if (approvals.length > 0) {
      const salesDocumentTypes = [
        "quotation",
        "sales_order",
        "deposit_invoice",
        "sales_invoice",
        "payment_receive",
        "sales_return",
        "credit_note",
      ];

      const purchaseDocumentTypes = [
        "purchase_request",
        "purchase_order",
        "purchase_invoice",
        "payment_made",
        "purchase_return",
      ];
      const mapApproval = async () => {
        const approverIds = [
          ...new Set(
            approvals
              .map((approval) => approval.approved_by_document_id)
              .filter((id) => id !== null)
          ),
        ];
        const requestorIds = [
          ...new Set(
            approvals
              .map((approval) => approval.created_by_document_id)
              .filter((id) => id !== null)
          ),
        ];

        const approvers = await Promise.all(
          approverIds.map(async (approverId) => {
            const approverInfo = await UserService.getUser({
              document_id: approverId,
            });
            return {
              ...approverInfo,
              full_name: `${approverInfo.first_name} ${approverInfo.last_name}`,
            };
          })
        );

        const requestors = await Promise.all(
          requestorIds.map(async (requestorId) => {
            const requestorInfo = await UserService.getUser({
              document_id: requestorId,
            });
            return {
              ...requestorInfo,
              full_name: `${requestorInfo.first_name} ${requestorInfo.last_name}`,
            };
          })
        );

        const formattedApprovals = approvals.map((approval) => {
          return {
            ...approval,
            type: salesDocumentTypes.includes(
              approval.workflow_progress.reference_document_type
            )
              ? "การขาย"
              : purchaseDocumentTypes.includes(
                  approval.workflow_progress.reference_document_type
                )
              ? "จัดซื้อ"
              : "การผลิต",
            approver: approvers.find(
              (approver) =>
                approver.document_id === approval.approved_by_document_id
            ),
            requestor: requestors.find(
              (requestor) =>
                requestor.document_id === approval.created_by_document_id
            ),
            approval_status: approval.approval_status.trim(),
          };
        });
        setMappedApprovals(formattedApprovals);
        setIsInit(true);
      };
      mapApproval();
    }
  }, [approvals, isInit, user]);

  useEffect(() => {
    if (gridRef.current.api) {
      const instance = gridRef.current.api.getFilterInstance("approval_status");
      switch (tab) {
        case "waitApprove":
          instance.setModel({ values: ["PENDING"] });
          break;
        case "approved":
          instance.setModel({ values: ["APPROVED"] });
          break;
        case "notApproved":
          instance.setModel({ values: ["DECLINED"] });
          break;
        case "all":
          instance.setModel(null);
          break;
        default:
          instance.setModel({ values: ["PENDING"] });
      }
      gridRef.current.api.onFilterChanged();
    }
  }, [tab]);

  const columnDefs = [
    {
      field: "workflow_progress.reference_document_id",
      headerName: "เลขที่เอกสาร",
      filter: "agTextColumnFilter",
    },
    {
      field: "type",
      rowGroup: true,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "created_date",
      headerName: "วันที่ขออนุมัติ",
      filter: "agTextColumnFilter",
      filterParams: {
        comparator: (a, b) => {
          return moment(a).isBefore(moment(b));
        },
        textMatcher: ({ value, filterText }) => {
          return moment(value).format("DD/MM/YYYY").includes(filterText);
        },
      },
      valueFormatter: ({ value }) => {
        if (typeof value === "undefined" || value.length === 0) {
          return "-";
        }
        return unixToDateWithFormat(value);
      },
    },
    {
      field: "requestor.img_url",
      headerName: t("user.account.picture"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data.img_url) {
          return <Avatar alt="img_url" src={params.data.requestor.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={
                params.data.requestor.first_name +
                " " +
                params.data.requestor.last_name
              }
            />
          );
        }
      },
    },
    {
      field: "requestor.full_name",
      headerName: "ผู้ขออนุมัติ",
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      field: "requestor.position",
      headerName: "ตำแหน่งผู้ขออนุมัติ",
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      field: "approver.full_name",
      headerName: "ผู้อนุมัติ",
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      field: "approver.position",
      headerName: "ตำแหน่งผู้อนุมัติ",
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      field: "remark",
      headerName: "หมายเหตุ",
      filter: "agTextColumnFilter",
      valueFormatter: ({ value }) => {
        if (value) {
          return value;
        } else {
          return "-";
        }
      },
    },
    {
      field: "approval_status",
      headerName: "สถานะ",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["DECLINED", "PENDING", "APPROVED"],
      },
      floatingFilter: false,
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  const rowSelectedHandler = (params) => {
    if (!params.data) {
      return;
    }
    const { workflow_progress, type } = params.data;
    const formatType = () => {
      switch (type) {
        case "การขาย":
          return "sales";
        case "จัดซื้อ":
          return "purchase";
        default:
          return;
      }
    };

    const document_id = workflow_progress.reference_document_id;
    const document_type = workflow_progress.reference_document_type
      .replace("sales_", "")
      .replace("purchase_", "")
      .replace("manufacturing_", "")
      .replaceAll("_", "-");
    navigate(`/${formatType()}/${document_type}/${document_id}`);
  };

  const onFirstDataRendered = () => {
    const instance = gridRef.current.api.getFilterInstance("approval_status");
    switch (tab) {
      case "waitApprove":
        instance.setModel({ values: ["PENDING"] });
        break;
      case "approved":
        instance.setModel({ values: ["APPROVED"] });
        break;
      case "notApproved":
        instance.setModel({ values: ["DECLINED"] });
        break;
      default:
        instance.setModel({ values: ["PENDING"] });
        setSearchParams({ type: "waitApprove" });
    }
    gridRef.current.api.onFilterChanged();
  };

  const tabs = [
    {
      label: t("status.waitApprove"),
      path: `${pathname}?type=waitApprove`,
    },
    {
      label: t("status.approved"),
      path: `${pathname}?type=approved`,
    },
    {
      label: t("status.notApproved"),
      path: `${pathname}?type=notApproved`,
    },
    {
      label: "ทั้งหมด",
      path: `${pathname}?type=all`,
    },
  ];

  const currentTab = tab
    ? pathname + `?type=${tab}`
    : pathname + "?type=waitApprove";

  return (
    <>
      {!isInit && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Typography variant="h5" sx={{ mb: 2 }}>
        การอนุมัติ
      </Typography>
      <CustomizedTab tabs={tabs} currentTab={currentTab} scrollable table />
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        height={649}
        rowData={mappedApprovals}
        groupDisplayType={"groupRows"}
        onRowDoubleClicked={rowSelectedHandler}
        onFirstDataRendered={onFirstDataRendered}
        groupDefaultExpanded={1}
        suppressContextMenu={true}
      />
    </>
  );
};

export default Approval;
