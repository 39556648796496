import moment from "moment";
import * as Yup from "yup";

export const bomInitialState = {
  isLoading: {
    allBoms: false,
    allBomsExport: false,
    bom: false,
  },
  allBoms: [],
  allBomsExport: [],
  bom: {
    created_date: moment().startOf("day").unix(),
    start_date: null,
    end_date: null,
    name: "",
    description: "",
    item_document_id: "",
    item_name: "",
    mfg_qty: 0,
    stock_uom_document_id: "",
    stock_uom: "",
    type: "",
    status: "active",
    ingredient_list: [],
    internal_remark: "",
    is_main_bom: "FALSE",
    minimum_stock_qty: 0,
  },
};

const ingredientValidation = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวน")
    .required("กรุณาระบุจำนวน"),
});

export const bomValidation = Yup.object().shape({
  name: Yup.string().required("กรุณาระบุชื่อสูตร"),
  item_document_id: Yup.string().required("กรุณาระบุสินค้า"),
  mfg_qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวน")
    .required("กรุณาระบุจำนวน"),
  ingredient_list: Yup.array()
    .of(ingredientValidation)
    .min(1, "กรุณาระบุส่วนประกอบ")
    .nullable(),
});
