import { Box, Typography } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DeliveryOrderTable from "../../../components/Table/DocumentTable/DeliveryOrderTable";
import { getAllDeliveryOrders } from "../../../features/Logistic/DeliveryOrder/delivery-order-actions";
import { filterDateWithUnix } from "../../../utils/filterparams";
import { useSnackbar } from "notistack";
import { deliveryOrderActions } from "../../../features/Logistic/DeliveryOrder/delivery-order-slice";

const DeliveryOrder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const breadcrumbs = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.deliveryOrder.index"),
    },
  ];
  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.waitDeliver"),
      path: `${pathname}?filter=waitDeliver`,
    },
    {
      label: t("status.completed"),
      path: `${pathname}?filter=completed`,
    },
    {
      label: t("status.notCompleted"),
      path: `${pathname}?filter=notCompleted`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const {
        document_id,
        contact,
        contact_document_id,
        issue_date,
        delivery_date,
        created_by,
        employee_list,
        province,
        address,
        sub_district,
        district,
        postal_code,
        render_status,
        item_group_level_1_name_list,
        ...otherFilter
      } = filterModel;

      const customerFilterList = [];

      if (province) {
        customerFilterList.push({
          path: ["billing_address", "province"],
          type: province.type,
          filter: province.filter,
        });
      }
      if (address) {
        customerFilterList.push({
          path: ["billing_address", "address"],
          type: address.type,
          filter: address.filter,
        });
      }
      if (sub_district) {
        customerFilterList.push({
          path: ["billing_address", "sub_district"],
          type: sub_district.type,
          filter: sub_district.filter,
        });
      }
      if (district) {
        customerFilterList.push({
          path: ["billing_address", "district"],
          type: district.type,
          filter: district.filter,
        });
      }
      if (postal_code) {
        customerFilterList.push({
          path: ["billing_address", "postal_code"],
          type: postal_code.type,
          filter: postal_code.filter,
        });
      }

      const formatFilterModel = {
        ...otherFilter,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        contact: (contact || contact_document_id) && {
          filterType: "object",
          filter: {
            document_id: contact_document_id && {
              ...contact_document_id,
              mode: "insensitive",
            },
            contact_name_1: contact && {
              ...contact,
              mode: "insensitive",
            },
          },
        },
        issue_date: issue_date && filterDateWithUnix(issue_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...employee_list, mode: "insensitive" },
          },
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: {
              ...created_by,
              mode: "insensitive",
            },
          },
        },
        item_group_level_1_name_list: item_group_level_1_name_list && {
          filterType: "array",
          type: "hasSome",
          values: item_group_level_1_name_list.values,
        },
        customer: (province ||
          address ||
          sub_district ||
          district ||
          postal_code) && {
          filterType: "json",
          filterList: customerFilterList,
        },
        render_status: render_status,
      };

      dispatch(
        getAllDeliveryOrders(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("render_status");
      switch (isFilter) {
        case "draft":
          instance.setModel({ filterType: "set", values: ["draft"] });
          break;
        case "waitDeliver":
          instance.setModel({ filterType: "set", values: ["waitDeliver"] });
          break;
        case "completed":
          instance.setModel({ filterType: "set", values: ["completed"] });
          break;
        case "notCompleted":
          instance.setModel({ filterType: "set", values: ["notCompleted"] });
          break;
        case "cancelled":
          instance.setModel({ filterType: "set", values: ["cancelled"] });
          break;
        default:
          instance.setModel({
            values: ["draft", "waitDeliver", "completed", "notCompleted"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    return () => {
      dispatch(deliveryOrderActions.resetAllDeliveryOrders());
    };
  }, [dispatch, onFilterChanged]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          {t("logistic.deliveryOrder.index")}
        </Typography>
      </Box>
      <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
      <DeliveryOrderTable gridRef={gridRef} onGridReady={onGridReady} />
    </>
  );
};

export default DeliveryOrder;
