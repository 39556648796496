import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@mui/material";
import { attributeActions } from "../../../features/Setting/Attribute/attribute-slice";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import AttributeForm from "../../../components/Form/Attribute";
import ModalUI from "../../../components/UI/ModalUI";
import {
  deleteAttribute,
  getAllAttribute,
  getAttribute,
} from "../../../features/Setting/Attribute/attributes-actions";
import { AgGridReact } from "ag-grid-react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteItemConfirmation from "../../../components/UI/Confirmation/DeleteItemConfirmation";
import { useSnackbar } from "notistack";
import { filterParamsOptions } from "../../../utils/filterparams";
import { usePermission } from "../../../hooks/use-auth";

const Attribute = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal, attribute, allAttribute } = useSelector(
    (state) => state.attribute
  );
  const [deleteModal, setDeleteModal] = useState();
  const [data, setData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { createPermission, editPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.warehouseSetting"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.attribute.index"),
    },
  ];

  const handleOpenModal = (input) => {
    setData({ id: input.id });
    setDeleteModal(true);
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  };

  const handleClickDelete = () => {
    try {
      dispatch(deleteAttribute({ uniqueInput: data, enqueueSnackbar }));
      setDeleteModal(false);
    } catch (error) {
      setDeleteModal(false);
    }
  };

  const columnDefs = [
    {
      field: "name",
      headerName: t("setting.inventory.attribute.index"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 250,
      sort: "asc",
    },
    {
      field: "acronym",
      headerName: t("setting.inventory.attribute.acronym"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "data_type",
      headerName: t("setting.inventory.attribute.type"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["number", "text"],
        valueFormatter: (params) => {
          if (params.value === "text") {
            return "ตัวอักษร";
          }
          return "ตัวเลข";
        },
      },
      cellRenderer: (params) => {
        if (params.value === "number") return "ตัวเลข";
        else return "ตัวอักษร";
      },
    },
    {
      field: "description",
      headerName: t("setting.description"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "",
      headerName: "",
      filter: false,
      sortable: false,
      cellRenderer: (params) =>
        editPermission && (
          <Box
            display={"flex"}
            flex={"end"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <IconButton
                onClick={() => {
                  handleOpen(params, params.node.rowIndex);
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton onClick={() => handleOpenModal(params.data)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ),
    },
  ];

  useEffect(() => {
    dispatch(getAllAttribute());
  }, [dispatch]);

  const handleOpen = async (params, index) => {
    await dispatch(attributeActions.loadedAttributeIndex(index));
    if (params && params.data && params.data.id) {
      await dispatch(getAttribute({ uniqueInput: { id: params.data.id } }));
    }
    await dispatch(attributeActions.openModal());
  };

  const handleClose = () => {
    dispatch(attributeActions.closeModal());
    setTimeout(() => {
      dispatch(attributeActions.resetAttribute());
    }, 500);
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      floatingFilter: true,
      filter: true,
      sortable: true,
    };
  }, []);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container my={2}>
        <Grid item xs={12} sm={10} md={10} lg={10.5} xl={11}>
          <Typography variant="h5">
            {t("setting.inventory.attribute.index")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1.5}
          xl={1}
          alignSelf="center"
          textAlign={"end"}
        >
          {createPermission && (
            <CustomizedButton
              fullWidth
              title={t("setting.inventory.attribute.add")}
              variant="contained"
              onClick={handleOpen}
            />
          )}
        </Grid>
      </Grid>
      <ModalUI
        open={Boolean(modal)}
        handleClose={handleClose}
        title={
          !attribute.id
            ? t("setting.inventory.attribute.add")
            : t("setting.edit")
        }
        width={720}
      >
        <AttributeForm handleClose={handleClose} />
      </ModalUI>
      <DeleteItemConfirmation
        title="ยืนยันการลบคุณสมบัติใช่หรือไม่"
        openDeleteItemConfirmation={deleteModal}
        deleteItemConfirmationAction={handleClickDelete}
        closeDeleteItemConfirmationHandler={handleCloseModal}
      />
      <Box sx={{ my: 2 }}>
        <div
          id="myGrid"
          className="ag-theme-material"
          style={{ height: 450, width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={allAttribute}
            columnDefs={columnDefs}
            enableRangeSelection={false}
            defaultColDef={defaultColDef}
          />
        </div>
      </Box>
    </>
  );
};

export default Attribute;
