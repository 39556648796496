import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import PurchaseByVendorTable from "../../../components/Table/DocumentTable/PurchaseByVendorTable";
import NewReportDateFilter from "../../../components/UI/NewReportDateFilter";
import { getPurchaseReportByVendorReport } from "../../../features/Purchase/PurchaseReport/purchase-report-action";
import { dateToUnix } from "../../../utils/date-converter";
import { exportCSVParams } from "../../../utils/filterparams";
import { purchaseReportActions } from "../../../features/Purchase/PurchaseReport/purchase-report-slice";

const PurchaseByVendor = () => {
  const { purchaseReportByVendor, isLoading } = useSelector(
    (state) => state.purchaseReport
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();

  const breadcrumbs = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.report.index"),
      to: "/purchase/report",
    },
    {
      name: t("purchase.report.purchaseByVendor"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: new Date(moment().startOf("day").subtract(6, "day")),
      dateTo: new Date(moment().endOf("day")),
    },
  });

  const onBtnExport = useCallback(async () => {
    let startDate = getValues("date");
    let endDate = getValues("dateTo");

    const params = exportCSVParams(
      t("purchase.report.purchaseByVendor"),
      startDate,
      endDate
    );
    gridRef.current.api.exportDataAsCsv(params);
    enqueueSnackbar("นำออกรายงานยอดซื้อตามผู้ขายสำเร็จ", {
      variant: "success",
    });
  }, [enqueueSnackbar, getValues, t]);

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
    reset({
      dateType: "lastWeek",
      date: new Date(moment().startOf("day").subtract(6, "day")),
      dateTo: new Date(moment().endOf("day")),
    });
    dispatch(
      getPurchaseReportByVendorReport(
        {
          startRow: 0,
          endRow: Math.pow(10, 10),
          filterModel: {
            issue_date: {
              filter: parseInt(
                dateToUnix(new Date(moment().startOf("day").subtract(6, "day")))
              ),
              filterTo: parseInt(dateToUnix(new Date(moment().endOf("day")))),
              filterType: "number",
              type: "inRange",
            },
            render_status: {
              values: ["partiallyImported", "fullyImported"],
              filterType: "set",
            },
          },
          sortModel: [],
        },
        enqueueSnackbar
      )
    );
  };

  const getFilteredPurchaseByVendor = useCallback(() => {
    let startDate = getValues("date");
    let endDate = getValues("dateTo");

    dispatch(
      getPurchaseReportByVendorReport(
        {
          startRow: 0,
          endRow: Math.pow(10, 10),
          filterModel: {
            issue_date: {
              filter: parseInt(dateToUnix(startDate)),
              filterTo: parseInt(dateToUnix(endDate)),
              filterType: "number",
              type: "inRange",
            },
            render_status: {
              values: ["partiallyImported", "fullyImported"],
              filterType: "set",
            },
          },
          sortModel: [],
        },
        enqueueSnackbar
      )
    );
  }, [dispatch, enqueueSnackbar, getValues]);

  useEffect(() => {
    return () => dispatch(purchaseReportActions.resetPurchaseByVendor());
  }, [dispatch]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          {t("purchase.report.purchaseByVendor")}
        </Typography>
        <CustomizedButton
          title="นำออกข้อมูล"
          variant="contained"
          onClick={onBtnExport}
        />
      </Box>
      <CustomizedBox>
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFilteredPurchaseByVendor}
                disabled={isLoading.purchaseReportByVendor}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.purchaseReportByVendor}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </CustomizedBox>
      {purchaseReportByVendor && purchaseReportByVendor.length > 0 && (
        <PurchaseByVendorTable
          gridRef={gridRef}
          rowData={purchaseReportByVendor}
        />
      )}
    </>
  );
};

export default PurchaseByVendor;
