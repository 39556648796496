import { attributeActions } from "./attribute-slice";
// import { showSnackbar } from "../../UI/ui-actions";
import SettingService from "../../../services/Setting/index";

export const getAllAttribute = (input, params) => async (dispatch) => {
  dispatch(attributeActions.onLoading("allAttribute"));
  try {
    const allAttribute = await SettingService.getAllAttribute(input);
    dispatch(attributeActions.loadedAllAttribute(allAttribute));
  } catch (err) {
    dispatch(
      attributeActions.rejectedActions({ ...err, name: "allAttribute" })
    );
  }
};

export const getAttribute = (input) => async (dispatch) => {
  dispatch(attributeActions.onLoading("attribute"));
  try {
    const result = await SettingService.getAttribute(input);
    dispatch(attributeActions.loadedAttribute({ result }));
  } catch (err) {
    dispatch(attributeActions.rejectedActions({ ...err, name: "attribute" }));
  }
};

export const createAttribute = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(attributeActions.onLoading("attribute"));
  try {
    const result = await SettingService.postCreateAttribute(input);
    dispatch(attributeActions.loadedAttribute({ result, create: true }));
    enqueueSnackbar("สร้างคุณสมบัติสำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    enqueueSnackbar("สร้างคุณสมบัติไม่สำเร็จ", {
      variant: "error",
    });
    dispatch(attributeActions.rejectedActions({ ...err, name: "attribute" }));
  }
};

export const updateAttribute =
  ({ input, index, enqueueSnackbar }) =>
  async (dispatch) => {
    dispatch(attributeActions.onLoading("attribute"));
    try {
      const result = await SettingService.postUpdateAttribute(input);
      dispatch(attributeActions.loadedAttribute({ result, index }));
      enqueueSnackbar("แก้ไขคุณสมบัติสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("แก้ไขคุณสมบัติไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(attributeActions.rejectedActions({ ...err, name: "attribute" }));
    }
  };

export const deleteAttribute =
  ({ uniqueInput, index, enqueueSnackbar }) =>
  async (dispatch) => {
    dispatch(attributeActions.onLoading("attribute"));
    try {
      const result = await SettingService.postDeleteAttribute({ uniqueInput });
      dispatch(attributeActions.removeAttribute({ result }));
      enqueueSnackbar("ลบคุณสมบัติสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ลบคุณสมบัติไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(attributeActions.rejectedActions({ ...err, name: "attribute" }));
    }
  };
