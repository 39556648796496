import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";
import CustomizedIcon from "../Custom/CustomizedIcon";
import CustomizedSelect from "../Custom/CustomizedSelect";
import { Box, Link } from "@mui/material";
import {
  mapActivityToIconName,
  formatComment,
} from "../../utils/dataTransformer";
import CustomizedStatus from "../Custom/CustomizedStatus";
import parse from "html-react-parser";
import { unixToDateTimeWithFormat } from "../../utils/date-converter";
import { useDispatch } from "react-redux";
import { activityLogsActions } from "../../features/ActivityLogs/activityLogs-slice";
import { useTranslation } from "react-i18next";

const img = {
  width: "40%",
  height: "auto",
  objectFit: "contain",
};

const ActivityLog = ({ logData, isApproval }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState("ใหม่ไปเก่า");
  const options = [
    { id: 1, value: "เก่าไปใหม่", label: "เก่าไปใหม่" },
    { id: 2, value: "ใหม่ไปเก่า", label: "ใหม่ไปเก่า" },
  ];

  const handleSelectOption = (selectedOption) => {
    setValue((prev) => {
      if (prev === "" && selectedOption === "ใหม่ไปเก่า") return selectedOption;
      if (prev !== selectedOption) {
        dispatch(activityLogsActions.activitySort(selectedOption));
        dispatch(activityLogsActions.reverseActivity());
      }
      return selectedOption;
    });
  };

  const allImgFile = ["png", "jpg", "jpeg", "svg", "gif"];

  const getApprovalMessage = (approval_status, step_number) => {
    switch (approval_status) {
      case "APPROVED":
        return `ลำดับที่ ${step_number} อนุมัติแล้ว`;
      case "DECLINED":
        return `ลำดับที่ ${step_number} ไม่อนุมัติ`;
      default:
        return "";
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "10px",
        border: `1px solid`,
        borderColor: (theme) => theme.palette.secondary.light,
        padding: "10px",
        overflow: "auto",
      }}
    >
      {!isApproval && (
        <CustomizedSelect
          options={options}
          label="เรียงลำดับ"
          onChange={(e) => handleSelectOption(e.target.value)}
          value={value}
          sx={{
            mb: 1,
            width: "115px",
          }}
        />
      )}

      <CustomizedScrollbar
        sx={{
          height: isApproval ? "calc(100vh - 6.5rem)" : "calc(100vh - 28rem)",
        }}
      >
        <Timeline position="right" sx={{ my: 0 }}>
          {!isApproval &&
            logData.map((log, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  sx={{
                    mb: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                  }}
                >
                  <Typography variant="body2" mt={1}>
                    {unixToDateTimeWithFormat(log.created_date)}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      bgcolor: "#f5f5f5",
                      color: "#0000008A",
                      boxShadow: "none",
                    }}
                  >
                    <CustomizedIcon
                      name={mapActivityToIconName(
                        log.activity_type,
                        log.attachment_list
                      )}
                    />
                  </TimelineDot>
                  <TimelineConnector sx={{ width: "1px", color: "#C8C8C8" }} />
                </TimelineSeparator>

                <TimelineContent sx={{ pb: "12px", px: 2, mb: "20px" }}>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography variant="body2" mr={2}>
                      {(log.activity_type === "create" ||
                        log.activity_type === "approve") &&
                      log.activity_details.message?.length > 0
                        ? log.activity_details.message
                        : t(`activityLog.activityType.${log.activity_type}`)}
                    </Typography>
                    {log.activity_type === "status_change" && (
                      <CustomizedStatus
                        status={log.activity_details.curr_status}
                        sx={{
                          fontSize: "0.75rem",
                        }}
                        variant="logStatus"
                      />
                    )}
                  </Box>
                  {log.activity_type === "comment" && (
                    <Typography variant="caption" component={"div"}>
                      {parse(formatComment(log.activity_details.message))}
                    </Typography>
                  )}
                  {log.activity_type === "add_related_employee" &&
                    log.activity_details.added_related_employee
                      .map((employee) => (
                        <Link
                          target="_blank"
                          href={"/user/account/" + employee.document_id}
                        >
                          <Typography variant="caption">
                            {employee.first_name} {employee.last_name}
                          </Typography>
                        </Link>
                      ))
                      .reduce((prev, curr) => [prev, ", ", curr])}
                  {log.activity_type === "delete_related_employee" &&
                    log.activity_details.deleted_related_employee
                      .map((employee) => (
                        <Link
                          target="_blank"
                          href={"/user/account/" + employee.document_id}
                        >
                          <Typography variant="caption">
                            {employee.first_name} {employee.last_name}
                          </Typography>
                        </Link>
                      ))
                      .reduce((prev, curr) => [prev, ", ", curr])}
                  {log.attachment_list.length > 0 && (
                    <Typography variant="caption" display={"inline-block"}>
                      {log.attachment_list.map((file) =>
                        allImgFile.includes(file.name.split(".")[1]) ? (
                          <Link target="_blank" href={file.url}>
                            <img
                              alt={file?.name || file}
                              src={file?.url || file}
                              style={img}
                              // Revoke data uri after image is loaded
                              onLoad={() => URL.revokeObjectURL(file.url)}
                            />
                          </Link>
                        ) : (
                          <Box>
                            <Link target="_blank" href={file.url}>
                              {file.name}
                            </Link>
                          </Box>
                        )
                      )}
                    </Typography>
                  )}
                  <Box>
                    <Typography variant="caption">{`โดย `}</Typography>
                    <Link
                      target="_blank"
                      href={"/user/account/" + log.created_by.document_id}
                    >
                      <Typography variant="caption">
                        {log.created_by.first_name} {log.created_by.last_name}
                      </Typography>
                    </Link>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
          {isApproval &&
            logData.map((log, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  sx={{
                    mb: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                  }}
                >
                  <Typography mt={1} variant="body2">
                    {log.approval_status === "PENDING"
                      ? "ผู้มีสิทธิ์อนุมัติลำดับถัดไป"
                      : "วันที่ " + unixToDateTimeWithFormat(log.created_date)}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      bgcolor: "#f5f5f5",
                      color: "#0000008A",
                      boxShadow: "none",
                    }}
                  >
                    <CustomizedIcon
                      name={mapActivityToIconName(log.approval_status, null)}
                    />
                  </TimelineDot>
                  <TimelineConnector sx={{ width: "1px", color: "#C8C8C8" }} />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: "12px", px: 2, mb: "20px" }}>
                  <Typography variant="body2" sx={{ color: "#328F35" }}>
                    {getApprovalMessage(log.approval_status, log.step_number)}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      flexWrap: "wrap",
                    }}
                  >
                    {log.approval_status !== "PENDING" && (
                      <>
                        <Typography
                          variant="caption"
                          mr={1}
                        >{`โดย `}</Typography>
                        <Link
                          target="_blank"
                          href={
                            "/user/account/" + log?.approved_by?.document_id
                          }
                        >
                          <Typography
                            variant="caption"
                            sx={{ color: "#328F35" }}
                          >
                            {log?.approved_by?.first_name}{" "}
                            {log?.approved_by?.last_name}
                          </Typography>
                        </Link>
                      </>
                    )}
                    {log.approval_status === "PENDING" &&
                      log.reviewer_list
                        .map((reviewer) => (
                          <Link
                            target="_blank"
                            href={"/user/account/" + reviewer.document_id}
                          >
                            <Typography
                              variant={
                                log.approval_status !== "PENDING"
                                  ? "caption"
                                  : "body2"
                              }
                              sx={{ color: "#328F35" }}
                              mt={log.approval_status === "PENDING" ? 1 : 0}
                            >
                              {reviewer.first_name} {reviewer.last_name}
                            </Typography>
                          </Link>
                        ))
                        .reduce((prev, curr) => [prev, ", ", curr])}
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      </CustomizedScrollbar>
    </Box>
  );
};

export default ActivityLog;
