import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { QRCodeSVG } from "qrcode.react";
import React from "react";

const QRCode = () => {
  return (
    <Box sx={{ py: 3, breakInside: "avoid" }}>
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          flexWrap: "nowrap",
          width: "197.3mm",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <QRCodeSVG value={window.location.href} size={72} />
          <Typography sx={{ fontSize: 8 }}>สแกนดูเอกสารออนไลน์</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default QRCode;
