import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LogisticService from "../../../services/Logistic";
import AgGrid from "../AgGrid";
import { deliveryOrderColumnDefs } from "../ColumnDefs/deliveryOrderColumnDefs";
import useDeliveryTripColumnDef from "../../../hooks/Logistic/useDeliveryTripColumnDef";

const DeliveryTripTable = ({ gridRef, onGridReady, rowData, isReport }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columnDefs = useDeliveryTripColumnDef(isReport);

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: deliveryOrderColumnDefs(t, null, true, false, true),
        suppressContextMenu: true,
      },
      getDetailRowData: async (params) => {
        const { do_document_id_list } = params.data;
        try {
          const { results } = await LogisticService.getAllDeliveryOrders({
            startRow: 0,
            endRow: Math.pow(10, 10),
            filterModel: {
              document_id: {
                filterType: "set",
                values: do_document_id_list,
              },
            },
            sortModel: [],
          });
          params.successCallback(results);
        } catch (err) {
          console.error(err);
        }
      },
    };
  }, [t]);

  const onRowDoubleClicked = (params) => {
    const { document_id } = params.data;
    navigate(`/logistic/delivery-trip/${encodeURIComponent(document_id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      masterDetail
      detailCellRendererParams={detailCellRendererParams}
      onGridReady={onGridReady}
      onRowDoubleClicked={!isReport && onRowDoubleClicked}
      rowData={rowData}
      path={`/logistic/delivery-trip`}
    />
  );
};

export default DeliveryTripTable;
