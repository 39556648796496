import { useEffect, useState } from "react";
import { permissionAction } from "../../utils/userInfo";

const allPermissionList = permissionAction();

const formatAction = (formatList) => {
  return formatList.reduce((a, v) => {
    const action = v.split("__")[2];
    return { ...a, [action]: v };
  }, {});
};

export const useDeliveryOrderPermission = () => {
  const [deliveryPermission, setDeliveryPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("LOGISTIC__DELIVERY_ORDER")
    );
    const actions = formatAction(formatList);
    setDeliveryPermission(actions);
  }, []);

  return deliveryPermission;
};

export const useDeliveryTripPermission = () => {
  const [deliveryPermission, setDeliveryPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("LOGISTIC__DELIVERY_TRIP")
    );
    const actions = formatAction(formatList);
    setDeliveryPermission(actions);
  }, []);

  return deliveryPermission;
};

export const useDeliveryReportPermission = () => {
  const [deliveryPermission, setDeliveryPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("LOGISTIC__REPORT")
    );
    const actions = formatAction(formatList);
    setDeliveryPermission(actions);
  }, []);

  return deliveryPermission;
};
