import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import DeliveryTripTable from "../../../components/Table/DocumentTable/DeliveryTripTable";
import { getNewDeliveryTripReport } from "../../../features/Logistic/DeliveryTrip/delivery-trip-actions";
import { useSnackbar } from "notistack";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import moment from "moment";
import { useForm } from "react-hook-form";
import NewReportDateFilter from "../../../components/UI/NewReportDateFilter";
import {
  filterDateWithUnix,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import {
  dateToUnix,
  formatDateAgGrid,
  formatDateExport,
  unixToDateTimeWithFormat,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import LogisticService from "../../../services/Logistic";
import useDeliveryTripColumnDef from "../../../hooks/Logistic/useDeliveryTripColumnDef";
import { CSVLink } from "react-csv";
import { deliveryTripActions } from "../../../features/Logistic/DeliveryTrip/delivery-trip-slice";
import GlobalService from "../../../services/Global";
import TimeStampReportLayout from "../../../components/UI/TimeStampReportLayout";
import { formatExportQuotes } from "../../../utils/exporter";

const DeliveryTripReportView = () => {
  const { isLoading } = useSelector((state) => state.deliveryTrip);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const [filename, setFilename] = useState("");
  const [data, setData] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [fetchTimestamp, setFetchTimestamp] = useState(null);
  const csvInstance = useRef(null);
  const [showTable, setShowTable] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [detail, setDetail] = useState(null);
  const [genDetail, setGenDetail] = useState(null);

  const columnDef = useDeliveryTripColumnDef(true);

  const breadcrumbs = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.report.index"),
      to: "/logistic/report",
    },
    {
      name: "รายงาน" + t("logistic.deliveryTrip.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: new Date(moment().startOf("day").subtract(6, "day")),
      dateTo: new Date(moment().endOf("day")),
    },
  });

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const { issue_date, delivery_date, in_date, out_date } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        issue_date: issue_date && filterDateWithUnix(issue_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        in_date: in_date && filterDateWithUnix(in_date),
        out_date: out_date && filterDateWithUnix(out_date),
      };

      dispatch(
        getNewDeliveryTripReport(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      let startDate = getValues("date");
      let endDate = getValues("dateTo");

      const delivery_date = params.api.getFilterInstance("delivery_date");
      delivery_date?.setModel({
        filterType: "date",
        type: "inRange",
        dateFrom: formatDateAgGrid(startDate),
        dateTo: formatDateAgGrid(endDate),
      });
      params.api.onFilterChanged();
      setFilename(
        `${t("logistic.deliveryTrip.index")}_${formatDateExport(
          delivery_date.dateFrom
        )}_${formatDateExport(delivery_date.dateTo)}.csv`
      );
    },
    [getValues, t]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const getFilteredDeliveryTrip = async () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    const { timestamp, in_queue_timestamp, detail } =
      await GlobalService.getReportViewTimestamp("DeliveryTrip");
    setDetail(detail);
    setLastTimestamp(timestamp);
    setFetchTimestamp(
      in_queue_timestamp ? in_queue_timestamp.timestamp * 1000 : null
    );
    setGenDetail(null);
  };

  const onFilterReset = () => {
    reset({
      dateType: "lastWeek",
      date: new Date(moment().startOf("day").subtract(6, "day")),
      dateTo: new Date(moment().endOf("day")),
    });

    if (gridRef) {
      onFilterChanged(gridRef.current);
    }
  };

  const exportAllDataAsAgGridCsv = async () => {
    try {
      const filterModel = gridRef.current.api.getFilterModel();
      const sortModel = gridRef.current.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const { issue_date, delivery_date, in_date, out_date } = filterModel;

      const formatFilterModel = {
        ...filterModel,
        issue_date: issue_date && filterDateWithUnix(issue_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        in_date: in_date && filterDateWithUnix(in_date),
        out_date: out_date && filterDateWithUnix(out_date),
      };
      dispatch(deliveryTripActions.onLoading("deliveryTripReports"));
      const { results: deliveryTripReport } =
        await LogisticService.getAllNewDeliveryTripsReport({
          startRow: 0,
          endRow: Math.pow(10, 5),
          filterModel: formatFilterModel,
          sortModel,
        });

      const formatValue = await deliveryTripReport.map(
        ({
          issue_date,
          delivery_date,
          created_date,
          updated_date,
          in_date,
          out_date,
          render_status,
          do_status,
          ...other
        }) => ({
          ...other,
          issue_date: issue_date ? unixToDateWithFormat(issue_date) : "-",
          delivery_date: delivery_date
            ? unixToDateWithFormat(delivery_date)
            : "-",
          created_date: created_date ? unixToDateWithFormat(created_date) : "-",
          updated_date: updated_date ? unixToDateWithFormat(updated_date) : "-",
          in_date: in_date ? unixToDateTimeWithFormat(in_date) : "-",
          out_date: out_date ? unixToDateTimeWithFormat(out_date) : "-",
          render_status: filterStatusValueFormatter(render_status),
          do_status: filterStatusValueFormatter(do_status),
        })
      );
      setData(formatExportQuotes(formatValue));
      dispatch(deliveryTripActions.onLoaded("deliveryTripReports"));
      setTimeout(() => {
        if (csvInstance.current) {
          csvInstance.current.link.click();
        }
      });
      enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error exporting CSV:", error);
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const columnDefHeader = columnDef.map((col) => ({
    label: col.headerName,
    key: col.field,
  }));

  const generateReportHandler = async () => {
    setFetching(true);
    let endDate = getValues("dateTo");
    let startDate = getValues("date");

    const { status, timestamp, detail } = await GlobalService.generateReport(
      "DeliveryTrip",
      {
        end_date: dateToUnix(endDate),
        start_date: dateToUnix(startDate),
        date_field_name: "delivery_date",
      }
    );
    setGenDetail(detail);
    setTimeout(() => {
      setFetching(false);
    }, [5000]);

    if (status === "success") {
      setFetchTimestamp(timestamp);
      enqueueSnackbar("ส่งคำขอสำเร็จ กรุณารอสักครู่", {
        variant: "success",
      });
      // setTimeout(async () => {
      //   await gridRef.current.api.refreshServerSide({ route: [], purge: true });
      //   const { timestamp } = await GlobalService.getReportViewTimestamp(
      //     "DeliveryTrip"
      //   );
      //   setLastTimestamp(timestamp);
      // }, [500]);
    } else if (status === "error") {
      enqueueSnackbar(
        `ระบบกำลังดำเนินการ กรุณารอสักครู่\n(ขอล่าสุด ${moment(
          fetchTimestamp
        ).fromNow()})`,
        {
          variant: "warning",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          รายงาน{t("logistic.deliveryTrip.index")}
        </Typography>
        <Box>
          <CustomizedButton
            title="นำออกข้อมูล"
            variant="contained"
            onClick={exportAllDataAsAgGridCsv}
            disabled={isLoading.deliveryTripReports}
          />
          <CSVLink
            headers={columnDefHeader}
            data={data}
            filename={filename}
            ref={csvInstance}
          />
        </Box>
      </Box>
      <CustomizedBox>
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFilteredDeliveryTrip}
                disabled={isLoading.deliveryTripReports}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.deliveryTripReports}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </CustomizedBox>
      {showTable && (
        <TimeStampReportLayout
          detail={detail}
          genDetail={genDetail}
          fetching={fetching}
          generateReportHandler={generateReportHandler}
          lastTimestamp={lastTimestamp}
          fetchTimestamp={fetchTimestamp}
        />
      )}
      {showTable && (
        <DeliveryTripTable
          gridRef={gridRef}
          onGridReady={onGridReady}
          isReport
        />
      )}
    </>
  );
};

export default DeliveryTripReportView;
