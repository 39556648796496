import { useFieldArray, useWatch } from "react-hook-form";
import { CustomizedBox } from "../Custom/CustomizedBox";
import CustomizedButton from "../Custom/CustomizedButton";
import ApprovalSettingAccordion from "./ApprovalSettingAccordion";

const defaultApprover = {
  selectedEmployees: [],
};

const ApprovalSettingBox = ({
  control,
  setValue,
  getValues,
  stepsSelectedRowIds,
  isInit,
  viewOnly,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "approvers",
  });

  const watchFieldArray = useWatch({ control, name: "approvers" });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const removeApproverRankHandler = (index) => {
    remove(index);
  };

  const addApproverRankHandler = () => {
    if (fields.length >= 3) {
      return;
    }
    append({ ...defaultApprover });
  };

  const renderApprovalAccordions = controlledFields?.map((item, index) => {
    return (
      <ApprovalSettingAccordion
        key={item.id}
        index={index}
        setApproversValue={setValue}
        getApproversValues={getValues}
        stepsSelectedRowIds={stepsSelectedRowIds}
        onRemove={() => removeApproverRankHandler(index)}
        viewOnly={viewOnly}
      />
    );
  });

  return (
    <CustomizedBox>
      {!viewOnly && (
        <CustomizedButton
          onClick={() => addApproverRankHandler()}
          size="medium"
          title={"เพิ่มลำดับการอนุมัติ"}
          variant="outlined"
          sx={{ mt: 2, mr: 2 }}
          disabled={!isInit || fields.length === 3}
        />
      )}
      {renderApprovalAccordions}
    </CustomizedBox>
  );
};

export default ApprovalSettingBox;
