import { combineReducers } from "@reduxjs/toolkit";
import accountReducer from "../features/User/Account/account-slice";
import rbacReducer from "../features/User/Rbac/rbac-slice";
import itemMasterReducer from "../features/Inventory/ItemMaster/itemMaster-slice";
import uiReducer from "../features/UI/ui-slice";
import attributeReducer from "../features/Setting/Attribute/attribute-slice";
import uomReducer from "../features/Setting/Uom/uom-slice";
import locationReducer from "../features/Setting/Location/location-slice";
import categoryReducer from "../features/Setting/Category/category-slice";
import goodsReceiveReducer from "../features/Inventory/GoodsReceive/goodsReceive-slice";
import goodsIssueReducer from "../features/Inventory/GoodsIssue/goodsIssue-slice";
import goodsTransferReducer from "../features/Inventory/GoodsTransfer/goodsTransfer-slice";
import goodsAdjustmentReducer from "../features/Inventory/GoodsAdjustment/goodsAdjustment-slice";
import inventoryReport from "../features/Inventory/Report/report-slice";
import contactReducer from "../features/Contact/contact-slice";
import quotationReducer from "../features/Sales/Quotation/quotation-slice";
import salesOrderReducer from "../features/Sales/SalesOrder/sales-order-slice";
import salesInvoiceReducer from "../features/Sales/SalesInvoice/sales-invoice-slice";
import depositInvoiceReducer from "../features/Sales/DepositInvoice/deposit-invoice-slice";
import salesReturnReducer from "../features/Sales/SalesReturn/sales-return-slice";
import creditNoteReducer from "../features/Sales/CreditNote/credit-note-slice";
import deliveryOrderReducer from "../features/Logistic/DeliveryOrder/delivery-order-slice";
import activityLogsReducer from "../features/ActivityLogs/activityLogs-slice";
import remarkTemplateReducer from "../features/Setting/RemarkTemplate/remark-template-slice";
import salesReportReducer from "../features/Sales/SalesReport/sales-report-slice";
import deliveryTripReducer from "../features/Logistic/DeliveryTrip/delivery-trip-slice";
import vehicleReducer from "../features/Setting/Vehicle/vehicle-slice";
import workCenterReducer from "../features/Setting/Manufacture/manufacture-slice";
import purchaseRequestReducer from "../features/Purchase/PurchaseRequest/purchase-request-slice";
import purchaseOrderReducer from "../features/Purchase/PurchaseOrder/purchase-order-slice";
import purchaseReturnReducer from "../features/Purchase/PurchaseReturn/purchase-return-slice";
import purchaseReportReducer from "../features/Purchase/PurchaseReport/purchase-report-slice";
import bomReducer from "../features/Manufacture/Bom/bom-slice";
import manufactureOrderReducer from "../features/Manufacture/ManufactureOrder/manufacture-order-slice";
import workOrderReducer from "../features/Manufacture/WorkOrder/work-order-slice";

export const rootReducer = combineReducers({
  account: accountReducer,
  rbac: rbacReducer,
  itemMaster: itemMasterReducer,
  ui: uiReducer,
  attribute: attributeReducer,
  uom: uomReducer,
  location: locationReducer,
  category: categoryReducer,
  goodsReceive: goodsReceiveReducer,
  goodsIssue: goodsIssueReducer,
  goodsTransfer: goodsTransferReducer,
  goodsAdjustment: goodsAdjustmentReducer,
  inventoryReport: inventoryReport,
  contact: contactReducer,
  quotation: quotationReducer,
  salesOrder: salesOrderReducer,
  salesInvoice: salesInvoiceReducer,
  depositInvoice: depositInvoiceReducer,
  salesReturn: salesReturnReducer,
  creditNote: creditNoteReducer,
  salesReport: salesReportReducer,
  deliveryOrder: deliveryOrderReducer,
  deliveryTrip: deliveryTripReducer,
  activityLogs: activityLogsReducer,
  remarkTemplate: remarkTemplateReducer,
  vehicle: vehicleReducer,
  purchaseOrder: purchaseOrderReducer,
  purchaseRequest: purchaseRequestReducer,
  purchaseReturn: purchaseReturnReducer,
  purchaseReport: purchaseReportReducer,
  bom: bomReducer,
  workOrder: workOrderReducer,
  workCenter: workCenterReducer,
  manufactureOrder: manufactureOrderReducer,
});
