import * as Yup from "yup";

const itemListSchema = {
  item_name: Yup.string().required("กรุณากรอกชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนที่มากกว่า 0")
    .required("กรุณาระบุจำนวนสินค้า"),
  uom_id: Yup.string().required("กรุณาเลือกหน่วย"),
  is_active: Yup.bool().oneOf(
    [true],
    "กรุณาระบุสินค้าใหม่ เนื่องจากมีสินค้าที่หยุดใช้งาน"
  ),
};

export const deliveryTripItemListValidation = Yup.object().shape({
  item_list: Yup.array()
    .of(Yup.object().shape(itemListSchema))
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
});
