import { Grid } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./validation";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  createAttribute,
  updateAttribute,
} from "../../../features/Setting/Attribute/attributes-actions";
import { useSnackbar } from "notistack";

const AttributeForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { attribute, attributeIndex } = useSelector((state) => state.attribute);
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: attribute,
    resolver: yupResolver(validation),
  });

  const { t } = useTranslation();

  const onSubmit = (data) => {
    const input = {
      ...data,
      name: data.name,
    };
    try {
      if (!attribute.id) {
        // const createInput = { ...input, document_id: data.name };
        dispatch(createAttribute({ createInput: input }, enqueueSnackbar));
      } else if (attribute.id) {
        delete input.id;
        dispatch(
          updateAttribute({
            input: {
              uniqueInput: { name: attribute.name },
              updateInput: input,
            },
            index: attributeIndex,
            enqueueSnackbar,
          })
        );
      }
      handleClose();
    } catch (error) {
      handleClose();
    }
  };

  const resetForm = () => {
    handleClose();
    reset();
  };

  const typeOption = [
    {
      id: "number",
      value: "number",
      label: "ตัวเลข",
    },
    {
      id: "text",
      value: "text",
      label: "ตัวอักษร",
    },
  ];

  const renderTextField = (name, label, errors, helperText, required) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedTextField
          error={errors}
          helperText={helperText}
          label={label}
          required={required}
          {...field}
        />
      )}
    />
  );

  const renderSelect = (name, label, options) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedSelect label={label} options={options} {...field} />
      )}
    />
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container gap={1} mt={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container gap={1}>
            <Grid item xs={12}>
              {renderTextField(
                "name",
                t("setting.inventory.attribute.index"),
                Boolean(errors.name),
                errors.name?.message,
                true
              )}
            </Grid>
            <Grid item xs={12}>
              {renderTextField(
                "acronym",
                t("setting.inventory.attribute.acronym"),
                Boolean(errors.acronym),
                errors.acronym?.message,
                true
              )}
            </Grid>
            <Grid item xs={12}>
              {renderSelect(
                "data_type",
                t("setting.inventory.attribute.type"),
                typeOption
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"description"}
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                multiline={true}
                rows={3}
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
                label={t("setting.description")}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <CustomizedButton
        type="reset"
        onClick={resetForm}
        title={t("button.cancel")}
        variant="outlined"
        sx={{ mt: 3, mr: 2 }}
      />
      <CustomizedButton
        type="submit"
        title={t("button.save")}
        variant="contained"
        sx={{ mt: 3 }}
      />
    </form>
  );
};

export default AttributeForm;
