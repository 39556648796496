import {
  Box,
  Divider,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Link,
} from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { Controller, useWatch } from "react-hook-form";
import CustomizedSelect from "../components/Custom/CustomizedSelect";
import CustomizedTextField from "../components/Custom/CustomizedTextField";
import { CustomizedTooltip } from "../components/Custom/CustomizedTooltip";
import LaunchIcon from "@mui/icons-material/Launch";
import CircleIcon from "@mui/icons-material/Circle";
import { useState } from "react";
import { useEffect } from "react";

export const customHeaderColRender = (engName, thaiName, required) => {
  return `<div class="ag-cell-label-container" role="presentation">
      <span
        ref="eMenu"
        class="ag-header-icon ag-header-cell-menu-button"
        aria-hidden="true"
      ></span>
      <div ref="eLabel" class="ag-header-cell-label center" role="presentation">
        ${
          engName
            ? `
            <div class="double-header center">
              <div class="ag-header-cell-text center" role="columnheader">
                ${thaiName}
              </div>
              <div class="ag-header-cell-text center" role="columnheader">
                ${engName}
                <div class="color-red">${required ? "*" : ""}</div>
              </div>
            </div>
          `
            : `
            <div class="center">
              <div class="ag-header-cell-text center" role="columnheader">
                ${thaiName}
              </div>
            </div>
          `
        }
      </div>
    </div>`;
};

export const RenderCustomizedTextField = ({
  sx,
  name,
  control,
  error,
  disabled,
  type,
  helperText,
  onChange,
  multiline,
  minRows,
  maxRows,
  inputProps,
  pricePerUom,
  InputProps,
}) => {
  const [warn, setWarn] = useState(false);
  const showWarning = () => setWarn(true);
  const hideWarning = () => setWarn(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <CustomizedTextField
          {...field}
          disabled={disabled}
          error={error || warn}
          type={type}
          helperText={pricePerUom ? `ราคาทุน ${pricePerUom}` : helperText}
          FormHelperTextProps={{
            style: { fontSize: "10px", whiteSpace: "normal" },
          }}
          onChange={(e) => {
            field.onChange(e);
            onChange && onChange(e);
            //validate price_per_unit
            if (pricePerUom) {
              const value = parseFloat(e.target.value);
              if (pricePerUom > value) {
                showWarning();
              } else hideWarning();
            }
          }}
          fullWidth
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          sx={sx || (error && { mt: "22px" })}
          inputProps={inputProps}
          InputProps={InputProps}
        />
      )}
    />
  );
};

const RenderTooltip = ({
  isSufficient,
  current_ordered_purchase_qty,
  current_ordered_manufacture_qty,
  current_committed_sales_qty,
  current_committed_manufacture_qty,
  current_stock_qty,
  current_available_qty,
  uom_name,
}) => {
  const data = [
    { status: "กำลังจัดซื้อ", amount: current_ordered_purchase_qty ?? 0 },
    { status: "กำลังผลิต", amount: current_ordered_manufacture_qty ?? 0 },
    { status: "ถูกจอง (ขาย)", amount: current_committed_sales_qty ?? 0 },
    { status: "ถูกจอง (ผลิต)", amount: current_committed_manufacture_qty ?? 0 },
    { status: "คงคลัง", amount: current_stock_qty ?? 0 },
    { status: "คงเหลือสุทธิ", amount: current_available_qty ?? 0 },
  ];

  return (
    <Box
      sx={{ p: 1, display: "flex", flexDirection: "column", width: "250px" }}
    >
      <Box
        sx={{
          mb: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: isSufficient ? "#8FCBB1" : "#F44336",
          fontSize: "13px",
        }}
      >
        <CircleIcon fontSize="inherit" />
        <Typography ml={1}>
          {isSufficient ? "สินค้าเพียงพอ" : "สินค้าไม่เพียงพอ"}
        </Typography>
      </Box>
      <Divider />
      {data.map((item) => (
        <Box
          key={item.status}
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography>{item.status}</Typography>
          <Typography sx={{ flex: "1", mr: 1 }} align="right">
            {item.amount && item.amount.toLocaleString()}
          </Typography>
          <Typography>{uom_name}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export const RenderCustomizedSelect = ({
  name,
  control,
  error,
  disabled,
  options,
  params,
  getValues,
  setValue,
  helperText,
  isDeliveryOrder,
  isPurchaseRequest,
  isDeliveryTrip,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <CustomizedSelect
          {...field}
          fullWidth
          sx={error && { mt: "22px" }}
          onChange={
            params
              ? (e) => {
                  if (e.target.value) {
                    const currentQty = getValues(
                      `item_list[${params.node.rowIndex}].qty`
                    );
                    const currentQtyUom = getValues(
                      `item_list[${params.node.rowIndex}].qty_uom`
                    );
                    const rowNode = params.api.getRowNode(
                      params.data.uid +
                        (isDeliveryTrip
                          ? params.data.do_reference_document_id ?? ""
                          : params.data.ref_document_id ?? "")
                    );

                    if (!isPurchaseRequest) {
                      if (params.data.uom_group) {
                        if (
                          e.target.value === params.data.uom_group?.base_uom?.id
                        ) {
                          if (isDeliveryOrder) {
                            setValue(
                              `item_list[${params.node.rowIndex}].qty`,
                              currentQtyUom
                            );
                            rowNode.setDataValue("qty", currentQtyUom);
                          } else {
                            if (params.data.qty_return) {
                              const currentQty = getValues(
                                `item_list[${params.node.rowIndex}].qty_return`
                              );
                              setValue(
                                `item_list[${params.node.rowIndex}].qty_uom`,
                                currentQty
                              );
                              rowNode.setDataValue("qty_uom", currentQty);
                            } else {
                              setValue(
                                `item_list[${params.node.rowIndex}].qty_uom`,
                                currentQty
                              );
                              rowNode.setDataValue("qty_uom", currentQty);
                            }
                          }
                          setValue(`item_list[${params.node.rowIndex}].uom`, {
                            document_id:
                              params.data.uom_group?.base_uom?.document_id,
                            id: params.data.uom_group?.base_uom?.id,
                            name: params.data.uom_group?.base_uom?.name,
                          });
                        } else {
                          const conversionUom =
                            params.data.uom_group.uom_conversion_list.find(
                              (conversion) =>
                                conversion.target_uom.id === e.target.value
                            );
                          const targetUomQty =
                            conversionUom.base_uom_rate /
                            conversionUom.target_uom_rate;

                          if (params.data.qty_return) {
                            const currentQty = getValues(
                              `item_list[${params.node.rowIndex}].qty_return`
                            );
                            setValue(
                              `item_list[${params.node.rowIndex}].qty_uom`,
                              currentQty * targetUomQty
                            );
                            rowNode.setDataValue(
                              "qty_uom",
                              currentQty * targetUomQty
                            );
                          } else {
                            setValue(
                              `item_list[${params.node.rowIndex}].qty_uom`,
                              currentQty * targetUomQty
                            );
                            rowNode.setDataValue(
                              "qty_uom",
                              currentQty * targetUomQty
                            );
                          }

                          setValue(`item_list[${params.node.rowIndex}].uom`, {
                            document_id: conversionUom.target_uom.document_id,
                            id: conversionUom.target_uom.id,
                            name: conversionUom.target_uom.name,
                          });
                        }
                      }
                    }

                    field.onChange(e.target.value);
                  }
                }
              : (e) => field.onChange(e.target.value)
          }
          options={options}
          error={error}
          disabled={disabled}
          helperText={helperText}
          helperTextSize={10}
        />
      )}
    />
  );
};

export const RenderItemNameCell = ({
  data,
  index,
  control,
  errors,
  viewOnly,
  handleShowItemModal,
  disabled,
  documentType,
  status,
  name,
}) => {
  const {
    current_ordered_purchase_qty,
    current_ordered_manufacture_qty,
    current_committed_sales_qty,
    current_committed_manufacture_qty,
    current_stock_qty,
    current_available_qty,
    uom_group,
    item_document_id,
    item_name,
    item_description,
  } = data;
  const [isSufficient, setIsSufficient] = useState(true);

  const watchQty = useWatch({
    control,
    name: `item_list[${index}].qty`,
  });

  useEffect(() => {
    switch (documentType) {
      case "qa": {
        const curr_qty = watchQty ? parseFloat(watchQty) : 0;
        const totalAvailableQty = current_available_qty - curr_qty;
        if (totalAvailableQty >= 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
      }
      case "pr": {
        const curr_qty = watchQty ? parseFloat(watchQty) : 0;
        const totalAvailableQty = current_available_qty + curr_qty;
        if (totalAvailableQty >= 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
      }
      case "so": {
        switch (status) {
          case null:
          case "draft":
          case "waitApprove":
            const curr_qty = watchQty ? parseFloat(watchQty) : 0;
            const totalAvailableQty = current_available_qty - curr_qty;
            if (totalAvailableQty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
          default:
            if (current_available_qty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
        }
        break;
      }
      case "po": {
        switch (status) {
          case null:
          case "draft":
          case "waitApprove":
            const curr_qty = watchQty ? parseFloat(watchQty) : 0;
            const totalAvailableQty = current_available_qty + curr_qty;
            if (totalAvailableQty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
          default:
            if (current_available_qty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
        }
        break;
      }
      default:
        if (current_available_qty >= 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
    }
  }, [current_available_qty, documentType, status, watchQty]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        wordWrap: "break-word",
        whiteSpace: "pre-line",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "stretch",
          flex: 1,
          textAlign: "left",
        }}
      >
        {viewOnly ? (
          <Link
            href={`/inventory/items/${item_document_id}?tab=item&subtab=general`}
            underline="none"
            target="_blank"
            rel="noopener"
          >
            <Typography variant="body2" align="left" mt={1} color="primary">
              {item_name}
            </Typography>
          </Link>
        ) : (
          <RenderCustomizedTextField
            disabled={disabled}
            control={control}
            error={
              errors[name] &&
              errors[name][index] &&
              errors[name][index]?.item_name
            }
            helperText={
              errors[name] &&
              errors[name][index] &&
              errors[name][index]?.item_name &&
              errors[name][index]?.item_name?.message
            }
            name={`${name}[${index}].item_name`}
            sx={{ mt: 1 }}
            textAlign="left"
          />
        )}
        <Typography
          variant="overline2"
          sx={{
            my: 0,
            ml: !viewOnly ? 2 : 0,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {item_document_id}
        </Typography>
        {viewOnly ? (
          <Typography
            variant="body2"
            align="left"
            gutterBottom
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {item_description}
          </Typography>
        ) : (
          <RenderCustomizedTextField
            disabled={disabled}
            control={control}
            name={`${name}[${index}].item_description`}
            multiline
            minRows={1}
            maxRows={4}
            sx={{ mb: 1 }}
            textAlign="left"
          />
        )}
      </Box>
      {!viewOnly && (
        <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
          <IconButton
            size="small"
            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
            onClick={() => handleShowItemModal(data)}
          >
            <LaunchIcon fontSize="small" />
          </IconButton>
        </CustomizedTooltip>
      )}
      <CustomizedTooltip
        placement="bottom-start"
        title={
          <RenderTooltip
            isSufficient={isSufficient}
            uom_name={uom_group?.base_uom?.name || null}
            current_ordered_purchase_qty={current_ordered_purchase_qty}
            current_ordered_manufacture_qty={current_ordered_manufacture_qty}
            current_committed_sales_qty={current_committed_sales_qty}
            current_committed_manufacture_qty={
              current_committed_manufacture_qty
            }
            current_stock_qty={current_stock_qty}
            current_available_qty={current_available_qty}
          />
        }
      >
        <IconButton
          size="small"
          sx={{ color: isSufficient ? "#8FCBB1" : "#F44336", fontSize: "13px" }}
        >
          <CircleIcon fontSize="inherit" />
        </IconButton>
      </CustomizedTooltip>
    </Box>
  );
};

export const RenderMenu = ({ menuItems }) => {
  const [anchorPos, setAnchorPos] = useState(null);
  const showActions = Boolean(anchorPos);

  const openActionsHandler = (event) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const menuItemAction = (onClick, trigger) => {
    if (trigger === "close") {
      closeActionsHandler();
    }
    onClick();
  };

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={item.label || index}
            onClick={() => menuItemAction(item.onClick, item.trigger)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <IconButton
        aria-label="edit"
        size="small"
        onClick={openActionsHandler}
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CreateOutlinedIcon />
      </IconButton>
    </>
  );
};
