import { graphQLClientWithHeader } from "../graphQL";
import {
  CREATE_NEW_ROLE,
  CREATE_NEW_USER,
  UPDATE_ROLE,
  DELETE_ROLE,
  UPDATE_USER,
  DISABLE_USER,
} from "./mutations";
import {
  GET_ALL_ROLES,
  GET_ALL_USERS,
  GET_USER,
  GET_ROLE,
  GET_ALL_ROLES_NO_AGGRID,
  GET_ALL_USERS_NO_AGGRID,
  GET_APPROVAL_PROGRESSES_SELF,
  GET_APPROVAL_PROGRESS_COUNT_SELF,
  GET_USER_WITH_DOCUMENT_ID,
  GET_APPROVAL_PENDING_COUNT_SELF,
  GET_APPROVAL_PENDINGS_SELF,
} from "./queries";

class UserService {
  async getAllUsers(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();

    const { employeesAggrid } = await graphQLClient.request(GET_ALL_USERS, {
      aggridInput,
    });

    const formattedResults = employeesAggrid.results.filter(
      (user) => user.document_id !== "EP-0000000001"
    );

    const formattedEmployeesAggrid = {
      results: formattedResults,
      count: employeesAggrid.count,
    };
    return formattedEmployeesAggrid;
  }

  async getAllUsersDocumentIdName(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { employeesAggrid } = await graphQLClient.request(GET_ALL_USERS, {
      aggridInput,
    });
    return employeesAggrid;
  }

  async getAllUsersNoAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { employees } = await graphQLClient.request(GET_ALL_USERS_NO_AGGRID, {
      aggridInput,
    });
    return employees;
  }

  async getUsersWithDocumentId(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { employees } = await graphQLClient.request(
      GET_USER_WITH_DOCUMENT_ID,
      input
    );
    return employees;
  }

  async getAllRoles(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { rolesAggrid } = await graphQLClient.request(GET_ALL_ROLES, {
      aggridInput,
    });
    const formattedRoles = {
      results: rolesAggrid.results.filter(
        (role) => role.document_id !== "RL-0000000001"
      ),
      count: rolesAggrid.count,
    };
    return formattedRoles;
  }
  async getAllRolesNoAggrid(findManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { roles } = await graphQLClient.request(GET_ALL_ROLES_NO_AGGRID, {
      findManyInput,
    });

    const formattedRoles = roles.filter(
      (role) => role.document_id !== "RL-0000000001"
    );

    const sortedRoles = formattedRoles.sort((a, b) => {
      return a.id - b.id;
    });

    return sortedRoles;
  }
  async postNewUser(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { employeeCreate } = await graphQLClient.request(CREATE_NEW_USER, {
      createInput,
    });
    return employeeCreate;
  }
  async postNewRole(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { roleCreate } = await graphQLClient.request(CREATE_NEW_ROLE, {
      createInput,
    });
    return roleCreate;
  }
  async getUser(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { employee } = await graphQLClient.request(GET_USER, {
      uniqueInput,
    });
    return employee;
  }
  async getRole(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { role } = await graphQLClient.request(GET_ROLE, {
      uniqueInput,
    });
    return role;
  }
  async updateRole(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { roleUpdate } = await graphQLClient.request(UPDATE_ROLE, {
      uniqueInput,
      updateInput,
    });
    return roleUpdate;
  }
  async deleteRole(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { roleDelete } = await graphQLClient.request(DELETE_ROLE, {
      uniqueInput,
    });
    return roleDelete;
  }
  async updateUser(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { employeeUpdate } = await graphQLClient.request(UPDATE_USER, {
      uniqueInput,
      updateInput,
    });
    return employeeUpdate;
  }
  async disableUser(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { employeeDisable } = await graphQLClient.request(DISABLE_USER, {
      uniqueInput,
    });
    return employeeDisable;
  }
  async getSelfApprovalProgresses() {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalProgressesSelf } = await graphQLClient.request(
      GET_APPROVAL_PROGRESSES_SELF
    );
    return approvalProgressesSelf;
  }
  async getSelfApprovalPendings() {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalPendingApproveSelf } = await graphQLClient.request(
      GET_APPROVAL_PENDINGS_SELF
    );
    return approvalPendingApproveSelf;
  }
  async getSelfAllApprovalProgressCount() {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalProgressesCountSelf } = await graphQLClient.request(
      GET_APPROVAL_PROGRESS_COUNT_SELF
    );
    return approvalProgressesCountSelf;
  }
  async getSelfApprovalProgressCount() {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalProgressesCountSelf } = await graphQLClient.request(
      GET_APPROVAL_PROGRESS_COUNT_SELF,
      { requestStatus: "PENDING" }
    );
    return approvalProgressesCountSelf;
  }
  async getSelfPendingApprovalCount() {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalPendingApproveCountSelf } = await graphQLClient.request(
      GET_APPROVAL_PENDING_COUNT_SELF
    );
    return approvalPendingApproveCountSelf;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService();
