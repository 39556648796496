import { Box } from "@mui/material";
import GlobalService from "../../../services/Global";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import CustomizedChips from "../../Custom/CustomizedChips";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { formatNumber } from "../../../utils/dataTransformer";
import InventoryService from "../../../services/Inventory";

export const manufactureOrderColumnDef = (t, isReport) => {
  if (isReport)
    return [
      {
        field: "document_id",
        headerName: t("manufacture.order.document_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "issue_date",
        headerName: t("sales.issueDate"),
        // sort: "desc",
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "expect_date",
        headerName: t("manufacture.order.expect_date_report"),
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "expect_product_date",
        headerName: t("manufacture.order.expect_product_date_report"),
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "delivery_date",
        headerName: t("manufacture.order.delivery_date"),
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "render_status",
        headerName: t("sales.status"),
        sortable: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ร่าง", "รอผลิต", "กำลังผลิต", "เสร็จสิ้น", "ยกเลิก"],
        },
        cellRenderer: (params) => <CustomizedStatus status={params.value} />,
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
      },
      {
        field: "customer_contact_document_id",
        headerName: t("manufacture.order.contact_document_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "customer_contact_name",
        headerName: t("manufacture.order.contact_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_group_level_1_name",
        headerName: "หมวดหมู่สินค้า",
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params) => {
            const data = await InventoryService.getAllItemLevel1();
            const itemLevel1 = data.map((warehouse) => warehouse.name);
            params.success(itemLevel1);
          },
        },
      },
      {
        field: "item_document_id",
        headerName: t("manufacture.order.item_document_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_name",
        headerName: t("manufacture.order.item_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "production_qty",
        headerName: t("manufacture.order.production_qty") + "ตั้งต้น",
        filter: false,
        sortable: false,
        valueFormatter: (params) => formatNumber(params.value),
      },
      {
        field: "actual_production_qty",
        headerName: t("manufacture.order.production_qty") + "จริง",
        filter: false,
        sortable: false,
        valueFormatter: (params) => formatNumber(params.value),
      },
      {
        field: "goods_receive_qty",
        headerName: t("manufacture.order.good_receive_qty"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => formatNumber(params.value),
      },
      {
        field: "uom",
        headerName: t("manufacture.order.stock_uom"),
        filter: false,
        sortable: false,
      },
      {
        field: "item_remark",
        headerName: t("manufacture.order.item_remark"),
        filter: false,
        sortable: false,
      },
      {
        field: "item_description",
        headerName: t("manufacture.order.item_description_2"),
        filter: false,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "width_tolerance_positive",
        headerName: t("manufacture.order.width_tolerance_positive"),
        filter: false,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "width_tolerance_negative",
        headerName: t("manufacture.order.width_tolerance_negative"),
        filter: false,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "thickness_tolerance_positive",
        headerName: t("manufacture.order.thickness_tolerance_positive"),
        filter: false,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "thickness_tolerance_negative",
        headerName: t("manufacture.order.thickness_tolerance_negative"),
        filter: false,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "bom_name",
        headerName: t("manufacture.bom.index"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "ingredient_document_id",
        headerName: t("manufacture.order.ingredient_document_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "ingredient_name",
        headerName: t("manufacture.order.ingredient_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "ingredient_description",
        headerName: t("manufacture.order.ingredient_description"),
        filter: false,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "ingredient_qty",
        headerName: t("manufacture.order.ingredient_qty"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => formatNumber(params.value),
      },
      {
        field: "ingredient_issue_qty",
        headerName: t("manufacture.order.ingredient_issue_qty"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => formatNumber(params.value),
      },
      {
        field: "ingredient_uom",
        headerName: t("manufacture.order.ingredient_uom"),
        filter: false,
        sortable: false,
      },
      {
        field: "type",
        headerName: t("manufacture.order.type"),
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: (params) => {
            return filterStatusValueFormatter(params.value);
          },
          values: async (params) => {
            const loadedOptions = await GlobalService.getCreatables({
              usage_field_type: "item",
              usage_field_name: "manufacture_type",
            });
            const formatType = loadedOptions.map((option) => option.name);
            params.success(formatType);
          },
        },
      },
      {
        field: "group",
        headerName: t("manufacture.order.group"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params) => {
            const loadedOptions = await GlobalService.getTagListByEntity(
              "manufacture_order"
            );
            const formatType = loadedOptions.map((option) => option.name);
            params.success(formatType);
          },
        },
        cellRenderer: (params) => {
          return params.value.map((group, index) => (
            <Box sx={{ display: "inline-block", mr: 1, gap: 1 }}>
              <CustomizedChips key={group + index} value={`${group}`} />
            </Box>
          ));
        },
      },
      {
        field: "internal_remark",
        headerName: t("manufacture.bom.internal_remark"),
        filter: false,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "reference_document_list",
        headerName: t("manufacture.order.reference_document_list"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "external_ref_id",
        headerName: t("manufacture.order.external_ref_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
        hide: true,
      },
      {
        field: "created_date",
        headerName: t("reports.created_date"),
        filter: "agDateColumnFilter",
        hide: true,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
        width: 200,
        sort: "desc",
        // suppressColumnsToolPanel: true,
      },
      {
        field: "updated_date",
        headerName: t("manufacture.bom.updated_date"),
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "created_by",
        headerName: t("sales.createdBy"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
          suppressAndOrCondition: true,
        },
        cellRenderer: (params) => (
          <CustomizedAvatar avatars={[params.data.created_by]} />
        ),
        valueGetter: (params) =>
          params.data.created_by.first_name +
          " " +
          params.data.created_by.last_name,
        sortable: false,
        width: 150,
      },
      {
        field: "employee_list",
        headerName: t("sales.employeeList"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
          suppressAndOrCondition: true,
        },
        cellRenderer: (params) => {
          if (params.data.employee_list) {
            return <CustomizedAvatar avatars={params.data.employee_list} />;
          }
        },
        valueGetter: (params) =>
          params.data.employee_list.map(
            (employee) => employee.first_name + " " + employee.last_name
          ),
        sortable: false,
      },
    ];

  const formatColumnDef = [
    {
      field: "document_id",
      headerName: t("manufacture.order.document_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "issue_date",
      headerName: t("manufacture.order.issue_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.value),
    },
    {
      field: "expect_date",
      headerName: t("manufacture.order.expect_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.value),
    },
    {
      field: "expect_product_date",
      headerName: t("manufacture.order.expect_product_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.value),
    },
    {
      field: "delivery_date",
      headerName: t("manufacture.order.delivery_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => {
        if (params.value) {
          return unixToDateWithFormat(params.value);
        }
        return "-";
      },
    },
    {
      field: "render_status",
      headerName: t("sales.status"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: ["draft", "pendingManu", "inProgress", "finished", "cancelled"],
      },
      cellRenderer: (params) => (
        <CustomizedStatus status={params.data.render_status} />
      ),
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
    {
      field: "customer_detail",
      headerName: t("manufacture.order.contact_name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      valueFormatter: (params) => params?.value.contact_name_1,
    },
    {
      field: "item_group_level_1_name",
      headerName: "หมวดหมู่สินค้า",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          const data = await InventoryService.getAllItemLevel1();
          const itemLevel1 = data.map((warehouse) => warehouse.name);
          params.success(itemLevel1);
        },
      },
    },
    {
      field: "item_document_id",
      headerName: t("manufacture.order.item_document_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "item_name",
      headerName: t("manufacture.order.item_name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "production_qty",
      headerName: t("manufacture.order.production_qty") + "ตั้งต้น",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
    },
    {
      field: "actual_production_qty",
      headerName: t("manufacture.order.production_qty") + "จริง",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
    },
    {
      field: "uom",
      headerName: t("manufacture.order.stock_uom"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "item_remark",
      headerName: t("manufacture.order.item_remark"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "type",
      headerName: t("manufacture.order.type"),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: async (params) => {
          const loadedOptions = await GlobalService.getCreatables({
            usage_field_type: "item",
            usage_field_name: "manufacture_type",
          });
          const formatType = loadedOptions.map((option) => option.name);
          params.success(formatType);
        },
      },
    },
    {
      field: "group",
      headerName: t("manufacture.order.group"),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: async (params) => {
          const loadedOptions = await GlobalService.getTagListByEntity(
            "manufacture_order"
          );
          const formatType = loadedOptions.map((option) => option.name);
          params.success(formatType);
        },
      },
      valueFormatter: (params) => params.value.map((value) => value.name),
      cellRenderer: (params) => {
        return params.value.map((group, index) => (
          <Box sx={{ display: "inline-block", mr: 1, gap: 1 }}>
            <CustomizedChips key={group.name + index} value={`${group.name}`} />
          </Box>
        ));
      },
    },
    {
      field: "created_date",
      headerName: t("sales.createdDate"),
      sort: "desc",
      hide: true,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.created_date),
      width: 200,
    },
    {
      field: "created_by",
      headerName: t("sales.createdBy"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => (
        <CustomizedAvatar avatars={[params.data.created_by]} />
      ),
      valueGetter: (params) =>
        params.data.created_by.first_name +
        " " +
        params.data.created_by.last_name,
      sortable: false,
      width: 150,
    },
    {
      field: "employee_list",
      headerName: t("sales.employeeList"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.data.employee_list) {
          return <CustomizedAvatar avatars={params.data.employee_list} />;
        }
      },
      valueGetter: (params) =>
        params.data.employee_list.map(
          (employee) => employee.first_name + " " + employee.last_name
        ),
      sortable: false,
    },
  ];
  return formatColumnDef;
};
