import { Box } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllVehiclesWithDT } from "../../../features/Setting/Vehicle/vehicle-actions";
import LogisticService from "../../../services/Logistic";
import CustomizedDate from "../../Custom/CustomizedDate";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import CheckboxModalTable from "../CheckboxModalTable";
import { unixToDateWithFormat } from "../../../utils/date-converter";

const VehicleDTTable = ({
  setValue,
  showSelectVehicle,
  setShowSelectVehicle,
  vehicleIds,
  setVehicleIds,
  vehicleIdsSnapshot,
  setVehicleIdsSnapshot,
  currentRowData,
  setMainValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectVehicleGridRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { allVehicles, isLoading } = useSelector((state) => state.vehicle);
  const [date, setDate] = useState(moment());

  useEffect(() => {
    dispatch(getAllVehiclesWithDT(enqueueSnackbar, date));
  }, [date, dispatch, enqueueSnackbar]);

  const columnDefs = [
    {
      field: "license_plate",
      headerName: t("setting.logistic.licensePlate"),
      filter: "agSetColumnFilter",
    },
    {
      field: "vehicle_type",
      headerName: t("setting.logistic.vehicleType"),
      filter: "agTextColumnFilter",
    },
    {
      field: "total_delivery_trip",
      headerName: "จำนวนรอบรถต่อวัน",
      filter: "agNumberColumnFilter",
    },
    {
      field: "weight",
      headerName: t("setting.logistic.weight"),
      filter: "agTextColumnFilter",
    },
    {
      field: "volume",
      headerName: t("setting.logistic.volume"),
      filter: "agTextColumnFilter",
    },
    {
      field: "remark",
      headerName: t("setting.logistic.remark"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("inventory.status"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <CustomizedStatus status={params.value} />,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            field: "document_id",
            headerName: t("logistic.documentId"),
            filter: false,
            sort: false,
            flex: 1,
          },
          {
            field: "issue_date",
            headerName: t("sales.issueDate"),
            filter: "agDateColumnFilter",
            flex: 1,
            valueFormatter: (params) => unixToDateWithFormat(params.value),
          },
          {
            field: "render_status",
            headerName: t("sales.status"),
            sortable: false,
            flex: 1,
            filter: false,
            sort: false,
            cellRenderer: (params) => (
              <CustomizedStatus status={params.value} />
            ),
            cellStyle: {
              display: "flex",
              justifycontent: "center",
              alignItems: "center",
            },
          },
          {
            field: "internal_remark",
            headerName: t("logistic.internalRemark"),
            filter: false,
            sort: false,
            flex: 1,
          },
        ],
        suppressContextMenu: true,
      },
      getDetailRowData: async (params) => {
        const { license_plate } = params.data;
        try {
          const { results } =
            await LogisticService.getAllDeliveryTripsByVehicle(
              license_plate,
              date
            );
          params.successCallback(results);
        } catch (err) {
          console.error(err);
        }
      },
    };
  }, [date, t]);

  const finishVehicleSelect = useCallback(
    (data) => {
      setMainValue("delivery_order_list", currentRowData);
      setValue("vehicle", data);
      setShowSelectVehicle(false);
    },
    [currentRowData, setMainValue, setShowSelectVehicle, setValue]
  );

  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);

  const dateOption = [
    {
      name: "issue_date",
      value: date,
      onChange: (value) => {
        setDate(value);
        selectVehicleGridRef.current.api.forEachNode((node) => {
          node.setExpanded(false);
        });
      },
    },
  ];

  return (
    <CheckboxModalTable
      modalTitle="ทะเบียนรถ"
      btnTitle={t("button.add")}
      gridRef={selectVehicleGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection="single"
      onFinishEditing={finishVehicleSelect}
      modalIsOpen={showSelectVehicle}
      closeModal={() => setShowSelectVehicle(false)}
      selectedIds={vehicleIds}
      setSelectedIds={setVehicleIds}
      idsSnapshot={vehicleIdsSnapshot}
      setIdsSnapshot={setVehicleIdsSnapshot}
      masterDetail
      detailCellRendererParams={detailCellRendererParams}
      getRowId={getRowId}
      rowData={isLoading.allVehiclesModal ? [] : allVehicles}
      disabledSidebar
      isLoading={isLoading.allVehiclesModal}
    >
      <Box sx={{ mb: 2 }}>
        <CustomizedDate dateOption={dateOption} />
      </Box>
    </CheckboxModalTable>
  );
};

export default VehicleDTTable;
