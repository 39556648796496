import { Avatar, Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import AgGrid from "../../../components/Table/AgGrid";
import { getAllGoodsTransferReport } from "../../../features/Inventory/GoodsTransfer/goodsTransfer-actions";
import {
  dateToUnix,
  mapStatusToThai,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import moment from "moment";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useForm } from "react-hook-form";
import NewReportDateFilter from "../../../components/UI/NewReportDateFilter";
import {
  dateComparator,
  exportCSVParams,
  filterParamsOptions,
} from "../../../utils/filterparams";
import { goodsTransferActions } from "../../../features/Inventory/GoodsTransfer/goodsTransfer-slice";
import { formatNumber } from "../../../utils/dataTransformer";

const GoodsTransfer = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { allItems, isLoading } = useSelector((state) => state.goodsTransfer);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.transfer.index"),
    },
  ];

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("inventory.transfer.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "created_date",
      headerName: t("inventory.documentDate"),
      filter: false,
    },
    {
      field: "document_date",
      headerName: t("inventory.transfer.transferDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
    },
    {
      field: "item_document_id",
      headerName: t("inventory.items.itemId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: false,
    },
    {
      field: "item_name",
      headerName: t("inventory.items.itemName"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: false,
    },
    {
      field: "item_description",
      headerName: t("inventory.items.itemDescription"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: false,
    },
    {
      field: "posted_quantity",
      headerName: t("inventory.quantity"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      sortable: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "uom_name",
      headerName: t("inventory.unit"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: false,
    },
    {
      field: "source_warehouse.thai_name",
      headerName: t("inventory.transfer.sourceWarehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "destination_warehouse.thai_name",
      headerName: t("inventory.list.destinationWarehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "remark",
      headerName: t("inventory.remark"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: false,
    },
    {
      field: "created_by_full_name",
      headerName: t("inventory.transfer.transferedBy"),
      filter: "agSetColumnFilter",
      sortable: false,
      cellRenderer: (params) => {
        if (params.data.created_by.img_url) {
          return <Avatar alt="img_url" src={params.data.created_by.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={
                params.data.created_by.first_name +
                " " +
                params.data.created_by.last_name
              }
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "status",
      headerName: t("inventory.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["สำเร็จ"],
      },
      cellRenderer: (params) => <CustomizedStatus status={params.value} />,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  const formatValueForAgGrid = () => {
    if (allItems.length !== 0) {
      const newGiArr = [];
      allItems.forEach((doc, index) => {
        return doc.goods_transfer_list.forEach((list) => {
          return newGiArr.push({
            ...doc,
            created_date: doc.created_date
              ? unixToDateWithFormat(doc.created_date)
              : "",
            document_date: doc.document_date
              ? unixToDateWithFormat(doc.document_date)
              : "",
            item_document_id: list.stock_entry_list[0].item?.document_id,
            item_name: list.stock_entry_list[0].item?.name,
            item_description: list.stock_entry_list[0].item?.description,
            posted_quantity: list.posted_quantity,
            uom_name: list.stock_entry_list[0].uom?.name,
            created_by_full_name:
              doc.created_by?.first_name + " " + doc.created_by?.last_name,
            status: mapStatusToThai(doc.status),
          });
        });
      });
      return newGiArr;
    } else return [];
  };

  const exportHandler = async () => {
    try {
      let startDate = getValues("date");
      let endDate = getValues("dateTo");

      const params = exportCSVParams(
        t("inventory.report") + t("inventory.transfer.index"),
        startDate,
        endDate
      );
      gridRef.current.api.exportDataAsCsv(params);
      enqueueSnackbar("นำออกสินค้าสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("นำออกสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: moment(new Date()).startOf("day").subtract(7, "day"),
      dateTo: new Date(),
    },
  });

  const getAllItems = useCallback(
    async (isDefault) => {
      let input = {
        startRow: 0,
        endRow: 999999,
        filterModel: {
          document_date: {
            filter: parseInt(
              dateToUnix(moment(new Date()).startOf("day").subtract(6, "day"))
            ),
            filterTo: parseInt(dateToUnix(moment(new Date()).endOf("day"))),
            filterType: "number",
            type: "inRange",
          },
        },
        sortModel: [
          { colId: "document_id", sort: "desc" },
          { colId: "created_date", sort: "desc" },
        ],
      };
      if (isDefault) {
        dispatch(getAllGoodsTransferReport(input, null, enqueueSnackbar));
      } else {
        const getDateValue = getValues("date");
        const getDateToValue = getValues("dateTo");
        input.filterModel.document_date.filter = dateToUnix(
          moment(getDateValue).startOf("day")
        );
        input.filterModel.document_date.filterTo = dateToUnix(getDateToValue);
        dispatch(getAllGoodsTransferReport(input, null, enqueueSnackbar));
      }
    },
    [dispatch, enqueueSnackbar, getValues]
  );

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
    getAllItems(true);
    reset();
  };

  useEffect(() => {
    return () => dispatch(goodsTransferActions.resetAllItems());
  }, [dispatch]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          {t("inventory.report") + t("inventory.transfer.index")}
        </Typography>
        <CustomizedButton
          sx={{ mr: 2 }}
          title={t("inventory.exportReport")}
          variant="contained"
          onClick={exportHandler}
        />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <NewReportDateFilter
              t={t}
              control={control}
              setValue={setValue}
              getValues={getValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.5} alignSelf="center">
            <Box display="flex" gap={2}>
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={() => getAllItems(false)}
                disabled={isLoading.allItems}
                fullWidth
              />
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.allItems}
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {allItems && allItems.length > 0 && (
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={formatValueForAgGrid()}
          height={649}
        />
      )}
    </>
  );
};

export default GoodsTransfer;
