import InventoryService from "../../../services/Inventory";
import {
  createActivityLogPayload,
  createActivityLogStatusPayload,
} from "../../../utils/activityLogsPayloadFormatter";
import { goodsAdjustmentActions } from "./goodsAdjustment-slice";
import ActivityLogsService from "../../../services/ActivityLogs";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import { createLog } from "../../../services/Logs/firebase";

export const getAllGoodsAdjustment = (input, params) => async (dispatch) => {
  dispatch(goodsAdjustmentActions.onLoading("allItems"));
  try {
    const allItems = await InventoryService.getAllGAAggrid(input);
    if (params) params.successCallback(allItems.results, allItems.count);
    dispatch(goodsAdjustmentActions.loadedAllItems(allItems.results));
    dispatch(goodsAdjustmentActions.loadedAllCount(allItems.count));
  } catch (err) {
    dispatch(
      goodsAdjustmentActions.rejectedActions({ ...err, name: "allItems" })
    );
    params.failCallback();
  }
};

export const getAllGoodsAdjustmentNoItem =
  (input, params) => async (dispatch) => {
    dispatch(goodsAdjustmentActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllGAAggridNoItem(input);
      dispatch(goodsAdjustmentActions.loadedAllItems(allItems.results));
      dispatch(goodsAdjustmentActions.loadedAllCount(allItems.count));
      if (params) params.successCallback(allItems.results, allItems.count);
    } catch (err) {
      dispatch(
        goodsAdjustmentActions.rejectedActions({ ...err, name: "allItems" })
      );
      params.failCallback();
    }
  };

export const getAllGoodsAdjustmentReport =
  (input, params) => async (dispatch) => {
    dispatch(goodsAdjustmentActions.onLoading("allItemsExport"));
    try {
      const allItems = await InventoryService.getAllGAReportAggrid(input);
      if (params) params.successCallback(allItems.results, allItems.count);
      dispatch(goodsAdjustmentActions.loadedAllItems(allItems.results));
      dispatch(goodsAdjustmentActions.loadedAllCount(allItems.count));
      dispatch(goodsAdjustmentActions.onLoaded("allItemsExport"));
    } catch (err) {
      dispatch(
        goodsAdjustmentActions.rejectedActions({
          ...err,
          name: "allItemsExport",
        })
      );
      params.failCallback();
    }
  };

export const getGoodsAdjustmentById = (input) => async (dispatch) => {
  dispatch(goodsAdjustmentActions.onLoading("item"));
  try {
    const item = await InventoryService.getGA(input);
    // for await (const [index, value] of item.goods_adjustment_list.entries()) {
    //   let goodsIndex = index;
    //   const stock_entry_list = value.stock_entry_list[0];
    //   const trace_entry_list = stock_entry_list.trace_entry_list;
    //   for await (const [index, value] of trace_entry_list.entries()) {
    //     const { results } =
    //       await InventoryService.getItemCurrentStockSerialNumber({
    //         startRow: 0,
    //         endRow: 999,
    //         filterModel: {
    //           serial_number: {
    //             filter: value.serial_number,
    //             filterType: "text",
    //             type: "equals",
    //           },
    //         },
    //       });
    //     item.goods_adjustment_list[
    //       goodsIndex
    //     ].stock_entry_list[0].trace_entry_list[index].current_quantity =
    //       results[0].current_quantity;
    //   }
    // }
    dispatch(goodsAdjustmentActions.loadedItem(item));
  } catch (err) {
    console.error(err);
    dispatch(goodsAdjustmentActions.rejectedActions({ ...err, name: "item" }));
  }
};

export const createGoodsAdjustmentWithApprove =
  (input, enqueueSnackbar, navigate, user, location) => async (dispatch) => {
    dispatch(goodsAdjustmentActions.onLoading("item"));
    try {
      const item = await InventoryService.createGAWithApprove(input);
      navigate(`/inventory/adjustment/${encodeURIComponent(item.document_id)}`);
      if (enqueueSnackbar)
        enqueueSnackbar("สร้างเอกสารปรับปรุงสำเร็จ", {
          variant: "success",
        });
      const createActivityLog = createActivityLogPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_adjustment",
        "สร้างใบปรับปรุง"
      );
      const createActivityLogApprove = createActivityLogStatusPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_adjustment",
        null,
        "finished"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLogApprove,
      });
      createLog(
        "info",
        "goodsAdjustmentCreateWithApprove",
        location,
        user?.email,
        input,
        item
      );
    } catch (err) {
      console.error("error", err);
      if (enqueueSnackbar)
        err.response.errors.forEach((error) => {
          if (errorMessageHandler(error.message)) {
            enqueueSnackbar(
              errorMessageHandler(
                error.message,
                "รหัสเอกสารนี้มีในระบบแล้ว กรุณาเรียกรหัสเอกสารใหม่"
              ),
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar("สร้างเอกสารปรับปรุงไม่สำเร็จ", {
              variant: "error",
            });
          }
        });
      createLog(
        "error",
        "goodsAdjustmentCreateWithApprove",
        location,
        user?.email,
        input,
        err
      );
      dispatch(
        goodsAdjustmentActions.rejectedActions({ ...err, name: "item" })
      );
    }
  };
