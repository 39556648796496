import { useCallback, useEffect, useState } from "react";
import InventoryService from "../../../services/Inventory";

export const useItemOptions = () => {
  const [itemLoading, setItemLoading] = useState(false);
  const [itemOptions, setItemOptions] = useState([]);
  const [openItem, setOpenItem] = useState(false);

  const getAllItems = useCallback(async () => {
    setItemLoading(true);
    const { results } = await InventoryService.getAllItemsAggridDocumentIdName({
      startRow: 0,
      endRow: Math.pow(10, 10),
      filterModel: {},
      sortModel: [],
    });
    const formatItemOptions = results.map((result) => ({
      id: result.document_id,
      label: `${result.document_id} - ${result.name}`,
      value: result.document_id,
    }));
    setItemOptions(formatItemOptions);
    setItemLoading(false);
  }, []);

  useEffect(() => {
    if (openItem) {
      getAllItems();
    }
  }, [getAllItems, openItem]);

  const onOpenItem = () => {
    setOpenItem(true);
  };

  const onCloseItem = () => {
    setOpenItem(false);
    setItemOptions([]);
  };
  return {
    itemLoading,
    itemOptions,
    onOpenItem,
    onCloseItem,
  };
};
