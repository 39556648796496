import {
  graphQLClientWithHeader,
  graphQLClientReportWithHeader,
} from "../graphQL";
import {
  GET_ALL_CONTACTS_AGGRID,
  GET_ALL_CONTACTS,
  GET_ALL_CONTACT_IDS,
  GET_CONTACT,
  GET_ALL_CONTACTS_GROUPS,
} from "./queries";
import {
  CREATE_CONTACT,
  CREATE_CONTACTS,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  CREATE_OR_UPDATE_CONTACTS,
} from "./mutations";

class ContactService {
  async getAllContactsAgGrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactsAggrid } = await graphQLClient.request(
      GET_ALL_CONTACTS_AGGRID,
      {
        aggridInput,
      }
    );
    return contactsAggrid;
  }
  async getAllContactsAgGridReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { contactsAggrid } = await graphQLClient.request(
      GET_ALL_CONTACTS_AGGRID,
      {
        aggridInput,
      }
    );
    return contactsAggrid;
  }
  async getAllContacts(findManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contacts } = await graphQLClient.request(GET_ALL_CONTACTS, {
      findManyInput,
    });
    return contacts;
  }
  async getAllContactIds(findManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contacts } = await graphQLClient.request(GET_ALL_CONTACT_IDS, {
      findManyInput,
    });
    return contacts;
  }
  async getContact(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contact } = await graphQLClient.request(GET_CONTACT, {
      uniqueInput,
    });
    return contact;
  }
  async createContact(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactCreate } = await graphQLClient.request(CREATE_CONTACT, {
      createInput,
    });
    return contactCreate;
  }
  async createContacts(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactsCreateMany } = await graphQLClient.request(
      CREATE_CONTACTS,
      createManyInput
    );
    return contactsCreateMany;
  }
  async createOrUpdateContacts(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactsCreateOrUpdateMany } = await graphQLClient.request(
      CREATE_OR_UPDATE_CONTACTS,
      createManyInput
    );
    return contactsCreateOrUpdateMany;
  }
  async updateContact(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactUpdate } = await graphQLClient.request(UPDATE_CONTACT, {
      uniqueInput,
      updateInput,
    });
    return contactUpdate;
  }
  async deleteContact(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { contactDelete } = await graphQLClient.request(DELETE_CONTACT, {
      uniqueInput,
    });
    return contactDelete;
  }
  async getAllContactsGroups() {
    const graphQLClient = graphQLClientWithHeader();
    const { contactsGroupsList } = await graphQLClient.request(
      GET_ALL_CONTACTS_GROUPS
    );
    return contactsGroupsList;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContactService();
