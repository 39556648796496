import * as Yup from "yup";

export const validation = Yup.object().shape({
  document_id: Yup.string().required("กรุณากรอก"),
  type: Yup.string().required("กรุณากรอก"),
  reference_document_id: Yup.string().when("type", {
    is: (value) => value !== "อื่นๆ",
    then: Yup.string().required("กรุณาระบุเอกสารอ้างอิง"),
  }),
  lot: Yup.string().required("กรุณาระบุ Lot"),
  source_warehouse_document_id: Yup.string().required("กรุณากรอก"),
  remark: Yup.string().required("กรุณาระบุหมายเหตุ"),
  goods_receive_list: Yup.array()
    .of(
      Yup.object().shape({
        stock_entry_list: Yup.object().shape({
          posted_quantity: Yup.number().required("กรุณากรอก"),
          trace_entry_list: Yup.array().of(
            Yup.object().shape({
              posted_quantity: Yup.number().required(
                "กรุณาระบุจำนวนที่จะนำเข้า"
              ),
              is_scanned: Yup.boolean(),
              source_bin_location_document_id: Yup.string().when("is_scanned", {
                is: (value) => value,
                then: Yup.string().required("กรุณาระบุสถานที่"),
                otherwise: Yup.string().nullable().notRequired(),
              }),
            })
          ),
        }),
      })
    )
    .min(1, "กรุณาเพิ่มรายการสินค้า"),
  // barcode: Yup.string().required("กรุณากรอก"),
  // description: Yup.string().required("กรุณากรอก"),
  // sales_standard_price: positiveNumberValidate,
  // maximum_discount: positiveNumberValidate,
  // purchase_standard_price: positiveNumberValidate,
  // sales_vat_type: Yup.string().required("กรุณากรอก"),
});
