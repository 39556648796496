import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import CircleIcon from "@mui/icons-material/Circle";

const CustomizedEventContent = (props) => {
  const { event } = props;
  const { title, extendedProps, start, end } = event;
  const { status } = extendedProps;
  const [disabled, setDisabled] = useState(false);

  const isSameDay = (d1, d2) => {
    if (d2) {
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    }
    return false;
  };

  useEffect(() => {
    if (
      status === "finished" ||
      status === "cancelled" ||
      status === "canceled"
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [status]);

  return (
    <CustomizedTooltip title={title} isCalendar isDisabled={disabled}>
      <Box display="flex" gap={0.5} overflow="hidden" alignItems="center">
        {isSameDay(start, end) && (
          <CircleIcon fontSize="inherit" color="primary" />
        )}
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 14,
            fontWeight: disabled ? 400 : 500,
            color: (theme) =>
              disabled ? "#666666" : theme.palette.secondary.main,
          }}
        >
          {title}
        </Typography>
      </Box>
    </CustomizedTooltip>
  );
};

export default CustomizedEventContent;
