import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import RemarkTemplateForm from "../../../components/Form/RemarkTemplate";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  createRemarkTemplate,
  deleteRemarkTemplate,
  getAllRemarkTemplate,
  updateRemarkTemplate,
} from "../../../features/Setting/RemarkTemplate/remark-template-actions";
import { useSnackbar } from "notistack";
import { usePermission } from "../../../hooks/use-auth";

const validation = Yup.object().shape({
  remarkTemplate: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("กรุณากรอก"),
      remark_text: Yup.string().required("กรุณากรอก"),
    })
  ),
});

const ManufactureTemplate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { allRemarkTemplate } = useSelector((state) => state.remarkTemplate);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteData, setDeleteData] = useState([]);
  const { createPermission, editPermission } = usePermission();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: allRemarkTemplate,
    resolver: yupResolver(validation),
  });

  const fetchRemark = useCallback(() => {
    dispatch(getAllRemarkTemplate({}, tab));
  }, [dispatch, tab]);

  useEffect(() => {
    fetchRemark();
  }, [fetchRemark]);

  useEffect(() => {
    setValue("remarkTemplate", allRemarkTemplate.remarkTemplate);
  }, [allRemarkTemplate.remarkTemplate, setValue]);

  useEffect(() => {
    setDeleteData([]);
  }, [tab]);

  // useEffect(() => {
  //   setValue("remarkTemplate", allRemarkTemplate);
  //   console.log("test");
  // }, [allRemarkTemplate, setValue]);

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.remarkSetting"),
      to: "/setting/remark",
    },
    {
      name: t("setting.remark.manufacture"),
    },
  ];

  const tabs = [
    {
      label: t("manufacture.order.index"),
      path: `${pathname}?tab=manufacture_order`,
    },
  ];

  const currentTab = tab
    ? pathname + `?tab=${tab}`
    : pathname + `?tab=manufacture_order`;

  const onSubmit = (data) => {
    const createData = [];
    const updateData = [];
    data.remarkTemplate.forEach((template) => {
      if (template.refId) return updateData.push(template);
      else return createData.push(template);
    });
    createData.forEach((data) => {
      const input = {
        ...data,
        document_type: tab,
      };
      dispatch(createRemarkTemplate(input));
    });
    updateData.forEach((data) => {
      const input = {
        uniqueInput: {
          id: data.refId,
        },
        updateInput: {
          name: data.name,
          document_type: data.document_type,
          remark_text: data.remark_text,
        },
      };
      dispatch(updateRemarkTemplate(input));
    });
    deleteData.forEach((data) => {
      const input = {
        uniqueInput: {
          name_document_type: {
            ...data,
          },
        },
      };
      dispatch(deleteRemarkTemplate(input));
    });
    enqueueSnackbar("บันทึกสำเร็จ", {
      variant: "success",
    });
    return setDeleteData([]);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
        <Typography variant="h5" my={2}>
          {t("setting.remark.manufacture")}
        </Typography>
        <CustomizedTab
          subtab
          tabs={tabs}
          currentTab={currentTab}
          scrollable
          divider
        />
        <CustomizedBox>
          <RemarkTemplateForm
            control={control}
            setValue={setValue}
            errors={errors}
            setDeleteData={setDeleteData}
            createPermission={createPermission}
            editPermission={editPermission}
          />
        </CustomizedBox>
        {(createPermission || editPermission) && (
          <Box display="flex" gap={1} mt={2}>
            <CustomizedButton
              type="reset"
              variant={"outlined"}
              title={t("button.cancel")}
            />
            <CustomizedButton
              type="submit"
              variant={"contained"}
              title={t("button.save")}
            />
          </Box>
        )}
      </form>
    </>
  );
};

export default ManufactureTemplate;
