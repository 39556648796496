import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../../features/Inventory/ItemMaster/itemMaster-actions";
import { itemMasterActions } from "../../../features/Inventory/ItemMaster/itemMaster-slice";
import { getAllUom } from "../../../features/Setting/Uom/uom-actions";
import ItemInfo from "../../../pages/Inventory/Items/ItemInfo";
import { validation } from "../../../pages/Inventory/Items/schema";
import { RenderItemNameCell } from "../../../utils/customCellRenderer";
import DeleteItemConfirmation from "../../UI/Confirmation/DeleteItemConfirmation";
import ModalUI from "../../UI/ModalUI";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { formatNumber, formatVatOption } from "../../../utils/dataTransformer";
import ControlledTextField from "../../Controlled/ControlledTextField";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomizedNumberTextField from "../../Custom/CustomizedNumberTextField";

const exceptThisSymbols = ["e", "E", "+", "-", "."];

const BomItemTable = ({
  fields,
  remove,
  control,
  errors,
  disabled,
  isUnderQuality,
  isIngredient,
  isTooltip,
  name,
}) => {
  const dispatch = useDispatch();
  const { item } = useSelector((state) => state.itemMaster);
  const [headers, setHeaders] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [deletedIndex, setDeletedIndex] = useState(null);

  const [openItemDetailModal, setOpenItemDetailModal] = useState(false);
  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    control: itemInfoControl,
    handleSubmit: handleSubmitItemInfo,
    getValues: getItemInfoValues,
    setValue: setItemInfoValue,
    formState: { errors: itemInfoErrors },
    reset: resetItemInfo,
    clearErrors: clearItemInfoErrors,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    if (item.document_id !== "") {
      Object.entries(item).forEach(([key, value]) => {
        if (key === "sales_vat_type")
          setItemInfoValue(key, formatVatOption(value));
        else if (key === "attribute_list")
          setItemInfoValue(
            key,
            value.map((item) => item.attribute)
          );
        else setItemInfoValue(key, value);
      });
    }
  }, [item, setItemInfoValue]);

  const openDeleteItemConfirmationHandler = (index) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(null);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const handleShowItemModal = (data) => {
    dispatch(getItem({ document_id: data.item_document_id }, enqueueSnackbar));
    dispatch(getAllUom());
    setOpenItemDetailModal(true);
  };

  const handleCloseItemDetailModal = () => {
    searchParams.delete("itemtab");
    setSearchParams(searchParams);
    clearItemInfoErrors();
    setOpenItemDetailModal(false);
    dispatch(itemMasterActions.resetItem());
  };

  useEffect(() => {
    if (disabled) {
      if (isUnderQuality)
        setHeaders([
          { label: "รายการ", width: 40 },
          { label: "สินค้า/วัตถุดิบ", width: 250 },
          { label: "จำนวน", width: 150 },
          { label: "หน่วย", width: 150 },
          { label: "หมายเหตุ", width: 150 },
        ]);
      else {
        if (isIngredient)
          setHeaders([
            { label: "รายการ", width: 40 },
            { label: "วัตถุดิบ", width: 250 },
            { label: "จำนวนที่ต้องใช้", width: 100 },
            { label: "จำนวนที่นำออก", width: 100 },
            { label: "หน่วย", width: 100 },
          ]);
        else
          setHeaders([
            { label: "รายการ", width: 40 },
            { label: "วัตถุดิบ", width: 250 },
            { label: "จำนวนที่ต้องใช้", width: 150 },
            { label: "หน่วย", width: 150 },
          ]);
      }
    } else {
      if (isUnderQuality)
        setHeaders([
          { label: "รายการ", width: 40 },
          { label: "สินค้า/วัตถุดิบ", width: 250 },
          { label: "จำนวน", width: 150 },
          { label: "หน่วย", width: 150 },
          { label: "หมายเหตุ", width: 150 },
          { label: "", width: 10 },
        ]);
      else
        setHeaders([
          { label: "รายการ", width: 40 },
          { label: "วัตถุดิบ", width: 250 },
          { label: "จำนวนที่ต้องใช้", width: 150 },
          { label: "หน่วย", width: 150 },
          { label: "", width: 10 },
        ]);
    }
  }, [disabled, isUnderQuality, isIngredient]);

  return (
    <Box sx={{ my: 3 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: "#E8F1E8",
            }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell align="center" key={index} width={header.width}>
                  <Box display="flex" gap={1} justifyContent="center">
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={700}
                    >
                      {header.label}
                    </Typography>
                    {isTooltip && index === 2 && (
                      <CustomizedTooltip title="จำนวนด้านล่างคือจำนวนที่ต้องใช้ตาม BOM">
                        <InfoOutlinedIcon fontSize="small" />
                      </CustomizedTooltip>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <Typography fontSize={14}>{index + 1}</Typography>
                </TableCell>
                <TableCell align="center">
                  <RenderItemNameCell
                    data={row}
                    index={index}
                    control={control}
                    errors={errors}
                    viewOnly={disabled}
                    handleShowItemModal={handleShowItemModal}
                    name={name}
                  />
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    <Typography fontSize={14}>
                      {formatNumber(row.qty)}
                    </Typography>
                  ) : (
                    <Box mt={fields[index].unrounded_qty ? 3 : 0}>
                      <CustomizedNumberTextField
                        onKeyDown={
                          isIngredient
                            ? (e) =>
                                exceptThisSymbols.includes(e.key) &&
                                e.preventDefault()
                            : null
                        }
                        name={`${name}[${index}].qty`}
                        control={control}
                        error={Boolean(
                          errors[name] &&
                            errors[name][index] &&
                            errors[name][index].qty
                        )}
                        helperText={
                          fields[index].unrounded_qty
                            ? formatNumber(fields[index].unrounded_qty)
                            : null
                        }
                      />
                    </Box>
                  )}
                </TableCell>
                {isIngredient && disabled && (
                  <TableCell align="center">
                    <Typography fontSize={14}>
                      {formatNumber(row.goods_issue_qty ?? 0)}
                    </Typography>
                  </TableCell>
                )}
                <TableCell align="center">
                  {disabled ? (
                    <Typography fontSize={14}>{row.uom}</Typography>
                  ) : (
                    <ControlledTextField
                      name={`${name}[${index}].uom`}
                      control={control}
                      disabled={true}
                    />
                  )}
                </TableCell>
                {isUnderQuality && (
                  <TableCell align="center">
                    {disabled ? (
                      <Typography fontSize={14}>{row.item_remark}</Typography>
                    ) : (
                      <ControlledTextField
                        name={`${name}[${index}].item_remark`}
                        control={control}
                        disabled={disabled}
                      />
                    )}
                  </TableCell>
                )}
                {!disabled && (
                  <TableCell align="center">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                      onClick={() => openDeleteItemConfirmationHandler(index)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalUI
        open={openItemDetailModal}
        handleClose={handleCloseItemDetailModal}
        navigateTo={`/inventory/items/${item.document_id}`}
        title="รายละเอียดสินค้า"
        fullWidth
      >
        <ItemInfo
          control={itemInfoControl}
          errors={itemInfoErrors}
          getValues={getItemInfoValues}
          handleSubmit={handleSubmitItemInfo}
          reset={resetItemInfo}
          setValue={setItemInfoValue}
          onCancel={handleCloseItemDetailModal}
          viewOnly={true}
        />
      </ModalUI>
      <DeleteItemConfirmation
        openDeleteItemConfirmation={openDeleteItemConfirmation}
        deleteItemConfirmationAction={deleteItemConfirmationAction}
        closeDeleteItemConfirmationHandler={closeDeleteItemConfirmationHandler}
      />
    </Box>
  );
};

export default BomItemTable;
