import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";
import { useAuth, usePermission } from "../../../hooks/use-auth";

const RemarkSetting = () => {
  const { permissions } = useAuth();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [allPermissionList, setAllPermissionList] = useState([]);

  const isAdmin = "ADMIN__ADMIN__ADMIN";

  useEffect(() => {
    setAllPermissionList(permissions);
  }, [permissions]);

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.remarkSetting"),
    },
  ];

  const {
    salesRemarkPermission,
    purchaseRemarkPermission,
    logisticRemarkPermission,
    manufactureRemarkPermission,
  } = usePermission();

  const menuList = [
    allPermissionList.some((permission) =>
      [salesRemarkPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("setting.remark.sales"),
      navigateTo: "sales?tab=quotation",
    },
    allPermissionList.some((permission) =>
      [purchaseRemarkPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("setting.remark.purchase"),
      navigateTo: "purchase?tab=purchase_request",
    },
    allPermissionList.some((permission) =>
      [logisticRemarkPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("setting.remark.logistic"),
      navigateTo: "logistic?tab=delivery_order",
    },
    allPermissionList.some((permission) =>
      [manufactureRemarkPermission.VIEW, isAdmin].includes(permission)
    ) && {
      title: t("setting.remark.manufacture"),
      navigateTo: "manufacture?tab=manufacture_order",
    },
  ];

  const formatMenuList = menuList.filter((menu) => menu !== false);

  const renderMenuList = () =>
    formatMenuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          linkTo={`${pathname}/${menu.navigateTo}`}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default RemarkSetting;
