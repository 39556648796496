import * as Yup from "yup";

export const schema = {
  vendor: "",
  identity_no: "",
  phone: "",
  email: "",
  fax: "",
  billing_address: {
    address: "",
    sub_district: "",
    district: "",
    province: "",
    postal_code: "",
    country: "",
  },
  delivery_address: {
    address_type: "",
    is_same_as_default_address: false,
    address_contact_name: "",
    address_contact_phone: "",
    address: "",
    sub_district: "",
    district: "",
    province: "",
    postal_code: "",
    country: "",
  },
};

export const billingAddressValidation = Yup.object()
  .shape({
    address: Yup.string().nullable(),
    sub_district: Yup.string().nullable(),
    district: Yup.string().nullable(),
    province: Yup.string().nullable(),
    postal_code: Yup.string().nullable(),
    country: Yup.string().nullable(),
  })
  .nullable();

export const deliveryAddressValidation = Yup.object()
  .shape({
    address_type: Yup.string().nullable(),
    is_same_as_default_address: Yup.boolean().nullable(),
    address_contact_name: Yup.string().nullable(),
    address_contact_phone: Yup.string().nullable(),
    address: Yup.string().nullable(),
    sub_district: Yup.string().nullable(),
    district: Yup.string().nullable(),
    province: Yup.string().nullable(),
    postal_code: Yup.string().nullable(),
    country: Yup.string().nullable(),
  })
  .nullable();

export const validation = Yup.object().shape({
  vendor: Yup.string().required("กรุณาเลือกผู้ขาย"),
  phone: Yup.string().nullable(),
  email: Yup.string().nullable().email("รูปแบบอีเมลไม่ถูกต้อง"),
  billing_address: billingAddressValidation,
  delivery_address: deliveryAddressValidation,
});
