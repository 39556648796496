import { Box, Typography } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";

const ChangeStatusConfirmation = ({
  title,
  content,
  currStatus,
  nextStatus,
  documentName,
  openConfirmation,
  confirmationAction,
  closeConfirmationHandler,
}) => {
  return (
    <ModalUI
      title={
        title ??
        `ยืนยันการปรับสถานะ${documentName}จาก ${currStatus} เป็น ${nextStatus}`
      }
      open={openConfirmation}
      handleClose={closeConfirmationHandler}
      maxWidth="sm"
    >
      {content && <Typography>{content}</Typography>}
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={confirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default ChangeStatusConfirmation;
