import { gql } from "graphql-request";

export const CREATE_CONTACT = gql`
  mutation ContactCreate($createInput: CreateContactInput!) {
    contactCreate(createInput: $createInput) {
      id
      document_id
      created_date
      created_by {
        id
        document_id
        first_name
        last_name
        email
        department
      }
    }
  }
`;

export const CREATE_CONTACTS = gql`
  mutation ContactsCreateMany($createManyInput: [CreateContactInput!]) {
    contactsCreateMany(createManyInput: $createManyInput) {
      errors {
        index
        document_id
        errorMessage
        field
      }
      results {
        document_id
      }
    }
  }
`;

export const CREATE_OR_UPDATE_CONTACTS = gql`
  mutation ContactsCreateOrUpdateMany($createManyInput: [CreateContactInput!]) {
    contactsCreateOrUpdateMany(createManyInput: $createManyInput) {
      errors {
        index
        document_id
        errorMessage
        field
      }
      results {
        document_id
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation ContactUpdate(
    $uniqueInput: ContactWhereUniqueInput!
    $updateInput: ContactUpdateInput!
  ) {
    contactUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      document_id
      last_updator_document_id
      is_vendor
      is_customer
      contact_status
      contact_status_remark
      creator_document_id
      created_by {
        id
        document_id
        first_name
        last_name
        email
        phone
        line_uid
        img_url
        department
        position
        status
      }
      tag_list {
        id
        name
        entity
      }
      last_updator_document_id
      created_date
      identity_no
      registered_capital
      contact_type_1
      contact_type_2
      contact_name_1
      contact_name_2
      img_url
      initial
      contact_source
      remark
      estimate_sales_volume
      contact_group
      estimate_sales_item_group
      contact_person_list {
        position
        initial
        name_1
        name_2
        name_3
        img_url
        remark
        contact_channel_list {
          contact_channel
          contact_info_1
          contact_info_2
        }
      }
      contact_channel_list {
        contact_channel
        contact_info_1
        contact_info_2
      }
      finance {
        is_cheque
        is_transfer
        is_cash
        payment_day
        payment_contact_name
        payment_contact_phone
        billing_day
        billing_contact_name
        billing_contact_phone
        credit_count
        account_payable_id
        account_receivable_id
        request_credit {
          credit_limit_value
          credit_limit_day
        }
        bank_list {
          bank_name
          bank_account_name
          bank_account_no
          bank_account_branch
          bank_account_type
          description
        }
      }
      address_list {
        address_type
        default_address
        same_as_default_address
        address
        sub_district
        district
        province
        postal_code
        country
        address_contact_name
        address_contact_phone
      }
      attachment_list {
        id
        name
        url
        uploaded_by {
          id
          document_id
          first_name
          last_name
          email
          phone
          line_uid
          img_url
          department
          position
          status
        }
        uploaded_date
      }
      sales_list {
        document_id
        first_name
        last_name
        email
        phone
        line_uid
        img_url
        department
        position
      }
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation ContactUpdate($uniqueInput: ContactWhereUniqueInput!) {
    contactDelete(uniqueInput: $uniqueInput)
  }
`;
