import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import CustomizedNumberTextField from "../../../Custom/CustomizedNumberTextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useWatch } from "react-hook-form";
import { useEffect } from "react";

const TraceEntryList = ({
  row,
  control,
  disabled,
  index,
  renderBoxMaxContent,
  renderSerialNumber,
  onChangeBarcodeHandler,
  deleteItem,
  totalError,
  setTotalError,
}) => {
  const watchType = useWatch({ control, name: "type" });
  const watchTotalItemFromDocument = useWatch({
    control,
    name: `goods_receive_list[${index}].initial_quantity`,
  });

  const watchTotalItemAlreadyQty =
    useWatch({
      control,
      name: `goods_receive_list[${index}].already_posted_quantity`,
    }) || 0;

  const watchQuantity = useWatch({
    control,
    name: `goods_receive_list[${index}].stock_entry_list.trace_entry_list`,
  });

  const totalQuantity =
    watchQuantity?.reduce(
      (prev, curr) => prev + parseFloat(curr?.posted_quantity),
      0
    ) || 0;

  useEffect(() => {
    if (watchType !== "อื่นๆ") {
      if (
        totalQuantity >
        watchTotalItemFromDocument - watchTotalItemAlreadyQty
      ) {
        setTotalError(true);
      } else {
        setTotalError(false);
      }
    }
  }, [
    setTotalError,
    totalQuantity,
    watchTotalItemAlreadyQty,
    watchTotalItemFromDocument,
    watchType,
  ]);

  return row.stock_entry_list.trace_entry_list?.map((subRow, subIndex) => (
    <>
      <TableRow
        key={row.stock_entry_list.item.document_id + subIndex}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center">
          {renderBoxMaxContent(renderSerialNumber(subRow.padSNNumber))}
        </TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center">
          <Box width={75}>
            <CustomizedNumberTextField
              control={control}
              name={`goods_receive_list[${index}].stock_entry_list.trace_entry_list[${subIndex}].posted_quantity`}
              disabled={disabled}
              onChange={(e) => onChangeBarcodeHandler(index, subIndex, e)}
              textAlign="left"
            />
            {/* <Controller
                    control={control}
                    name={`goods_receive_list[${index}].stock_entry_list.trace_entry_list[${subIndex}].posted_quantity`}
                    render={({ field }) => (
                      <CustomizedTextField
                        {...field}
                        disabled={disabled}
                        onChange={(e) =>
                          onChangeBarcodeHandler(index, subIndex, e)
                        }
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                    )}
                  /> */}
          </Box>
        </TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center">
          <Box display={"flex"} justifyContent={"end"}>
            <IconButton
              onClick={() =>
                deleteItem({
                  index: index + 1,
                  subIndex: subIndex + 1,
                })
              }
              disabled={disabled}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  ));
};

export default TraceEntryList;
