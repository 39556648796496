import { Box } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";

const DeleteItemConfirmation = ({
  title,
  documentType,
  openDeleteItemConfirmation,
  deleteItemConfirmationAction,
  closeDeleteItemConfirmationHandler,
}) => {
  return (
    <ModalUI
      title={
        title
          ? title
          : documentType === "di"
          ? "ต้องการลบรายการใช่หรือไม่"
          : "ต้องการลบสินค้าใช่หรือไม่"
      }
      open={openDeleteItemConfirmation}
      handleClose={closeDeleteItemConfirmationHandler}
      maxWidth="xs"
      fullWidth
    >
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeDeleteItemConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={deleteItemConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default DeleteItemConfirmation;
