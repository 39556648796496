import React from "react";
import AgGrid from "../AgGrid";
import { useTranslation } from "react-i18next";
import GlobalService from "../../../services/Global";
import SettingService from "../../../services/Setting";
import { useCallback } from "react";
import { useState } from "react";
import { ItemColumnDef } from "./ItemColumnDef";
import { useLocation, useNavigate } from "react-router-dom";

const ItemTable = ({
  gridRef,
  onGridReady,
  rowSelection,
  height,
  enableCheckbox,
  rowData,
  onRowDoubleClicked,
  enableRangeSelection,
  getRowId,
  onRowClicked,
}) => {
  const { t } = useTranslation();
  const [tagList] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getAllTag = useCallback(async () => {
    const { results } = await GlobalService.getTagList({
      startRow: 0,
      endRow: 9999,
    });
    const mapTagValue = results.map((tag) => {
      return {
        ...tag,
        value: tag.name,
        label: tag.name,
      };
    });
    return mapTagValue;
  }, []);

  const getCreables = useCallback(async () => {
    const data = await GlobalService.getCreatables({
      usage_field_type: "item",
      usage_field_name: "item_type",
    });
    return data;
  }, []);

  const getAllItemGroupLevel1 = useCallback(async () => {
    const data = await SettingService.getAllCategory();
    return data;
  }, []);

  const onClickTagListHandler = (tagName) => {
    navigate(`${pathname}?filterTag=` + tagName);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={ItemColumnDef(
        t,
        enableCheckbox,
        tagList,
        onClickTagListHandler,
        getAllTag,
        getCreables,
        getAllItemGroupLevel1
      )}
      height={height}
      onGridReady={onGridReady}
      rowSelection={rowSelection}
      rowMultiSelectWithClick={rowSelection !== "single"}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
      onRowClicked={onRowClicked}
      enableRangeSelection={enableRangeSelection}
      getRowId={getRowId}
      path={pathname}
    />
  );
};

export default ItemTable;
