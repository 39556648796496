import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./sales-order-initial";

const salesOrderSlice = createSlice({
  name: "salesOrder",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    onLoaded(state, action) {
      state.isLoading[action.payload] = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllSalesOrders(state, action) {
      state.allSalesOrders = action.payload;
      state.isLoading.allSalesOrders = false;
    },
    loadedAllSalesOrdersExports(state, action) {
      state.allSalesOrdersExport = action.payload;
      state.isLoading.allSalesOrders = false;
    },
    loadedSalesOrder(state, action) {
      state.salesOrder = action.payload;
      state.isLoading.salesOrder = false;
    },
    updateSalesOrderIsLate(state, action) {
      state.salesOrder.is_late = action.payload;
    },
    loadedApprovalList(state, action) {
      const { approvalTemplates, approvalProgress } = action.payload;
      const sortedApprovalTemplates = approvalTemplates.sort(
        (a, b) => a.step_number - b.step_number
      );
      const sortedApprovalProgress =
        approvalProgress &&
        approvalProgress.length > 0 &&
        approvalProgress.sort((a, b) => a.created_date - b.created_date);

      // 1.) check current approval list compare with approval template
      // 2.) if they not have people in that particular template then use people form approval list instead
      // 3.) else if they still have approval and not deleted yet then use approval template as usual

      let formatApprovalList = [];
      sortedApprovalTemplates.forEach((approvalTemplate, index) => {
        if (approvalTemplate.is_required_approve) {
          const formatApprovalWithStatus = {
            id: approvalTemplate.id,
            step_number: approvalTemplate.step_number,
            reviewer_list: approvalTemplate.reviewer_list,
            approval_status:
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                sortedApprovalProgress[index].approval_status) ||
              null,
            created_date:
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                sortedApprovalProgress[index].created_date) ||
              null,
            approved_by:
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                approvalTemplate.reviewer_list.find(
                  (approve) =>
                    approve.document_id ===
                    sortedApprovalProgress[index].approved_by_document_id
                )) ||
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                sortedApprovalProgress[index].approved_by) ||
              null,
            is_required_approve: approvalTemplate.is_required_approve,
          };
          formatApprovalList.push(formatApprovalWithStatus);
        }
      });

      state.approvalList = formatApprovalList;
    },
    loadedSalesInvoiceTab(state, action) {
      state.salesInvoiceTab = action.payload;
      state.isLoading.salesOrder = false;
    },
    loadedPurchaseRequestTab(state, action) {
      state.purchaseRequestTab = action.payload;
      state.isLoading.salesOrder = false;
    },
    loadedManufactureOrderTab(state, action) {
      state.manufactureOrderTab = action.payload;
      state.isLoading.salesOrder = false;
    },
    loadedDeliveryOrderTab(state, action) {
      state.deliveryOrderTab = action.payload;
      state.isLoading.salesOrder = false;
    },
    loadedGoodIssueTab(state, action) {
      state.goodIssueTab = action.payload;
      state.isLoading.salesOrder = false;
    },
    loadedSalesReturnTab(state, action) {
      state.salesReturnTab = action.payload;
      state.isLoading.salesOrder = false;
    },
    updateSalesOrderStatus(state, action) {
      state.salesOrder.render_status = action.payload;
      state.isLoading.salesOrder = false;
    },
    updateApprovalStatus(state, action) {
      const { approval_list, user } = action.payload;

      const sortedApprovalProgress = approval_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      const findApprover =
        sortedApprovalProgress &&
        sortedApprovalProgress.length > 0 &&
        sortedApprovalProgress?.map((approval) => {
          return {
            approval_status: approval?.approval_status,
            reviewer_document_id_list:
              approval?.approval_template &&
              approval?.approval_template?.reviewer_document_id_list,
            reviewer_list:
              approval?.approval_template &&
              approval?.approval_template?.reviewer_list,
            created_date: approval?.created_date,
          };
        });

      const isHaveApproval = sortedApprovalProgress?.every(
        (approval) =>
          approval?.approval_template &&
          approval?.approval_template?.is_required_approve === true
      );

      const formatApprovalList =
        findApprover &&
        findApprover.filter((approval) => approval.reviewer_list !== null);

      const foundWaitApprove =
        formatApprovalList &&
        formatApprovalList.find(
          (approval) => approval.approval_status === "PENDING"
        );

      const isHaveApprovalPermission =
        foundWaitApprove &&
        foundWaitApprove?.reviewer_document_id_list?.includes(user.document_id);

      state.isHaveApprovalPermission = isHaveApprovalPermission;
      state.isHaveApproval = isHaveApproval;

      // 1.) check current approval list compare with approval template
      // 2.) if they not have people in that particular template then use people form approval list instead
      // 3.) else if they still have approval and not deleted yet then use approval template as usual

      if (formatApprovalList && formatApprovalList.length > 0) {
        formatApprovalList.forEach((approval, index) => {
          if (state.approvalList[index]) {
            state.approvalList[index].approval_status =
              approval.approval_status;
          }
        });
      }
    },
    resetAllSalesOrders(state) {
      state.allSalesOrders = initialState.allSalesOrders;
      state.error = initialState.error;
    },
    resetAllSalesOrdersExport(state) {
      state.allSalesOrdersExport = initialState.allSalesOrdersExport;
      state.error = initialState.error;
    },
    resetSalesOrder(state) {
      state.salesOrder = initialState.salesOrder;
      state.salesInvoiceTab = initialState.salesInvoiceTab;
      state.purchaseRequestTab = initialState.purchaseRequestTab;
      state.manufactureOrderTab = initialState.manufactureOrderTab;
      state.deliveryOrderTab = initialState.deliveryOrderTab;
      state.salesReturnTab = initialState.salesReturnTab;
      state.error = initialState.error;
    },
    resetDeliveryOrderTab(state) {
      state.deliveryOrderTab = initialState.deliveryOrderTab;
      state.error = initialState.error;
    },
    resetGoodIssueTab(state) {
      state.goodIssueTab = initialState.goodIssueTab;
      state.isLoading.salesOrder = false;
    },
    resetSalesReturnTab(state) {
      state.salesReturnTab = initialState.salesReturnTab;
      state.error = initialState.error;
    },
    resetSalesOrderError(state) {
      state.error = initialState.error;
    },
  },
});

export const salesOrderActions = salesOrderSlice.actions;

export default salesOrderSlice.reducer;
