import { userPermission } from "../../../../utils/userInfo";

export const createOptions = (page, status, permissions, disabled) => {
  const optionRequest = [
    {
      value: "ใบสั่งซื้อ",
      disabled:
        userPermission(permissions, "purchase", "order", "create") ||
        disabled.purchase_order,
    },
  ];

  const optionOrder = [
    {
      value: "ใบนำเข้า",
      disabled:
        userPermission(permissions, "inventory", "receive", "create") ||
        disabled.goods_receive,
    },
    {
      value: "ใบส่งคืน",
      disabled:
        userPermission(permissions, "purchase", "return", "create") ||
        disabled.purchase_return,
    },
  ];

  const optionReturn = [
    {
      value: "ใบนำออก",
      disabled:
        userPermission(permissions, "inventory", "issue", "create") ||
        disabled.goods_issue,
    },
  ];

  switch (page) {
    case "request":
      switch (status) {
        case "approved":
        case "partiallyOrdered":
        case "fullyOrdered":
          return optionRequest;
        default:
          break;
      }
      break;
    case "order":
      switch (status) {
        case "approved":
        case "partiallyImported":
        case "fullyImported":
          return optionOrder;
        default:
          break;
      }
      break;
    // case "invoice":
    //   switch (status) {
    //     case "waitPayment":
    //       return ["ใบลดหนี้"];
    //     case "fullyPaid":
    //       return ["ใบลดหนี้"];
    //     default:
    //       break;
    //   }
    //   break;
    // case "payment-made":
    //   switch (status) {
    //     case "waitDeliver":
    //       return ["รอบจัดส่ง", "ใบนำออก"];
    //     default:
    //       break;
    //   }
    //   break;
    case "return":
      switch (status) {
        case "approved":
        case "finished":
          return optionReturn;
        default:
          break;
      }
      break;

    default:
      return [];
  }
  return [];
};
