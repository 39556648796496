import React, { useCallback } from "react";
import AgGrid from "../AgGrid";

import GlobalService from "../../../services/Global";
import SettingService from "../../../services/Setting";
import useItemNoEntryColumnDef from "../../../hooks/Inventory/useItemNoEntryColumnDef";

const ItemNoEntryReportTable = ({
  gridRef,
  onGridReady,
  rowData,
  isReport,
}) => {
  const getAllTag = useCallback(async () => {
    const { results } = await GlobalService.getTagList({
      startRow: 0,
      endRow: 9999,
    });
    const mapTagValue = results.map((tag) => {
      return {
        ...tag,
        value: tag.name,
        label: tag.name,
      };
    });
    // setTagList(mapTagValue);
    return mapTagValue;
  }, []);

  const getAllItemGroupLevel1 = useCallback(async () => {
    const data = await SettingService.getAllCategory();
    return data;
  }, []);

  const columnDefs = useItemNoEntryColumnDef(
    isReport,
    getAllItemGroupLevel1,
    getAllTag
  );

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      onGridReady={onGridReady}
      rowData={rowData}
    />
  );
};

export default ItemNoEntryReportTable;
