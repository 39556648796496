export const initialState = {
  isLoading: {
    allItems: false,
    allItemsExport: false,
    item: false,
  },
  allItems: [],
  count: 0,
  item: {
    document_id: "",
    type: "ซื้อ",
    reference_document_id: "",
    source_warehouse_document_id: "",
    remark: "",
    created_date: null,
    document_date: null,
    lot: null,
    goods_receive_list: [
      // {
      //   initial_quantity: 0,
      //   posted_quantity: 0,
      //   posted_value: 0,
      //   stock_entry_list: {
      //     posted_quantity: 0,
      //     posted_value: 0,
      //     posted_date: 0,
      //     uom_document_id: "",
      //     item_document_id: "",
      //     creator_document_id: "",
      //     source_warehouse_document_id: "0",
      //     trace_entry_list: [
      //       {
      //         posted_date: 0,
      //         posted_quantity: 0,
      //         posted_value: 0,
      //         item_document_id: "",
      //         uom_document_id: "",
      //         creator_document_id: "",
      //       },
      //     ],
      //   },
      // },
    ],
  },
  error: null,
};
