import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ControlledSelect from "../../../Controlled/ControlledSelect";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedDateTime from "../../../Custom/CustomizedDateTime";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import { Controller, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import ControlledServerSideCreatable from "../../../Controlled/ControlledSSCreatable";
import CustomizedChips from "../../../Custom/CustomizedChips";

const timeUnit = [
  {
    value: "minute",
    label: "นาที",
  },
  {
    value: "hour",
    label: "ชั่วโมง",
  },
  {
    value: "day",
    label: "วัน",
  },
];

const WorkOrderDetailForm = ({
  control,
  errors,
  setValue,
  reset,
  disabled,
}) => {
  const { t } = useTranslation();
  const { allWorkCenter } = useSelector((state) => state.workCenter);

  const watchPlant = useWatch({ control, name: "plant" });

  const plantOptions = [...new Set(allWorkCenter?.map((wc) => wc.plant))];
  const machineOptions =
    allWorkCenter &&
    allWorkCenter.length > 0 &&
    allWorkCenter
      .filter((wc) => {
        if (watchPlant !== "") return wc.plant === watchPlant;
        else return wc;
      })
      .map((wc) => wc.name);

  const watchTag = useWatch({ control, name: "manufacture_order.group" });

  const handleChange = (e, field) => {
    field.onChange(e.target.value.replace(/[^0-9]/g, ""));
  };

  return (
    <CustomizedBox>
      <Typography sx={{ ml: 1, fontWeight: "bold" }}>
        {t("manufacture.workOrder.detail")}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            dataName="work_order_list_name"
            name="name"
            title={t("manufacture.workOrder.name")}
            control={control}
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors?.name.message}
            viewOnly={disabled}
            documentType="work_order"
            defaultOptions={[]}
            setValue={setValue}
          />
          {/* <ControlledTextField
            name="name"
            label={t("manufacture.workOrder.name")}
            control={control}
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors?.name.message}
            disabled={disabled}
          /> */}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledSelect
            name="plant"
            label={t("manufacture.workOrder.plant")}
            control={control}
            error={Boolean(errors?.plant)}
            helperText={errors?.plant && errors?.plant.message}
            disabled={disabled}
            options={plantOptions}
            onChange={() => setValue("machine", "")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledSelect
            name="machine"
            label={t("manufacture.workOrder.machine")}
            control={control}
            error={Boolean(errors?.machine)}
            helperText={errors?.machine && errors?.machine.message}
            disabled={disabled}
            options={machineOptions}
            onChange={(e) =>
              setValue(
                "plant",
                allWorkCenter.find((wc) => wc.name === e.target.value).plant
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Controller
            name="start_date_time"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <CustomizedDateTime
                inputFormat="dd/MM/yyyy HH:mm"
                label={t("manufacture.workOrder.start_date")}
                onChange={onChange}
                value={value}
                ref={ref}
                renderInput={(params) => (
                  <CustomizedTextField
                    {...params}
                    ref={params.inputRef}
                    error={Boolean(errors?.start_date_time)}
                    helperText={
                      errors?.start_date_time && errors?.start_date_time.message
                    }
                  />
                )}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="duration"
            type="text"
            label={t("manufacture.workOrder.duration")}
            control={control}
            error={Boolean(errors?.duration)}
            helperText={errors?.duration && errors?.duration.message}
            disabled={disabled}
            onChange={(e, field) => handleChange(e, field)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledSelect
            name="duration_unit"
            label={t("manufacture.workOrder.unitDuration")}
            control={control}
            error={Boolean(errors?.duration_unit)}
            helperText={errors?.duration_unit && errors?.duration_unit.message}
            disabled={disabled}
            options={timeUnit}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="description"
            label={t("manufacture.workOrder.description")}
            control={control}
            error={Boolean(errors?.description)}
            helperText={errors?.description && errors?.description.message}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Box sx={{ ml: 1, mt: 3 }}>
        <Typography sx={{ fontWeight: "bold", mb: 2 }}>
          {t("manufacture.workOrder.group")}
        </Typography>
      </Box>
      <Grid item xs={8} container spacing={1}>
        {watchTag?.length > 0 ? (
          watchTag?.map((data, index) => (
            <Grid item key={index}>
              <CustomizedChips
                value={data.name}
                // onClick={() => onClickTagListHandler(data.name)}
              />
            </Grid>
          ))
        ) : (
          <Box sx={{ ml: 2 }}>
            <Typography>-</Typography>
          </Box>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default WorkOrderDetailForm;
