import AgGrid from "../../Table/AgGrid";
import ModalUI from "../../UI/ModalUI";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import React, { useRef, useState, useCallback } from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Box, Grid, IconButton } from "@mui/material";
import CustomizedTextField from "../../Custom/CustomizedTextField";

export default function SpecificInfo({
  control,
  errors,
  getValues,
  viewOnly,
  setValue,
  isInventoryPage,
}) {
  const { t } = useTranslation();
  const gridRef = useRef();
  const attributeListGridRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const { allAttribute } = useSelector((state) => state.attribute);

  const { fields, append } = useFieldArray({
    control,
    name: "attribute_list",
  });

  const handleSelectAttribute = () => {
    const selectedInfo = attributeListGridRef.current.api.getSelectedRows();
    const filedId = fields.map((field) => field.name);
    selectedInfo.forEach((info) => {
      if (!filedId.includes(info.name)) append(info);
    });
    setOpenModal(false);
  };

  const handleRemoveAttribute = useCallback(
    (index) => {
      const allValue = getValues("attribute_list");
      const filterValue = allValue.filter((_, ind) => index !== ind);
      setValue("attribute_list", filterValue);
    },
    [getValues, setValue]
  );

  const columnDefs = useCallback(
    (enableCheckbox) => {
      return [
        {
          field: "name",
          headerName: t("inventory.attribute.attributeName"),
          checkboxSelection: enableCheckbox,
          headerCheckboxSelection: enableCheckbox,
        },
        {
          field: "acronym",
          headerName: t("inventory.attribute.acronym"),
        },
        {
          field: "data_type",
          headerName: t("inventory.attribute.attributeType"),
          cellRenderer: (params) => {
            if (params.data.type === "number") return "ตัวเลข";
            else return "ตัวอักษร";
          },
        },
        {
          field: "description",
          headerName: t("inventory.attribute.description"),
          width: 400,
          autoHeight: true,
        },
      ];
    },
    [t]
  );

  const columnDefsTable = useCallback(() => {
    return [
      {
        field: "name",
        headerName: t("inventory.attribute.attributeName"),
      },
      {
        field: "acronym",
        headerName: t("inventory.attribute.acronym"),
      },
      {
        field: "attribute_value",
        headerName: t("inventory.attribute.attributeValue"),
        width: 400,
        autoHeight: true,
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Controller
                name={`attribute_list[${params.rowIndex}].attribute_value`}
                control={control}
                error={errors}
                render={({ ...field }) => (
                  <CustomizedTextField
                    {...field}
                    testId={`item-attribute-value-text-field-${params.rowIndex}`}
                    onChange={(e) => {
                      setValue(
                        `attribute_list[${params.rowIndex}].attribute_value`,
                        e.target.value
                      );
                    }}
                    value={getValues(
                      `attribute_list[${params.rowIndex}].attribute_value`
                    )}
                    error={
                      errors &&
                      errors?.attribute_list &&
                      errors?.attribute_list[params.rowIndex].attribute_value
                    }
                    type={params.data_type === "number" ? "number" : "text"}
                    disabled={!isInventoryPage || viewOnly}
                  />
                )}
              />
              <IconButton
                size="small"
                sx={{ ml: 1 }}
                onClick={() => handleRemoveAttribute(params.rowIndex)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          );
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
      },
    ];
  }, [
    control,
    errors,
    handleRemoveAttribute,
    getValues,
    setValue,
    t,
    isInventoryPage,
    viewOnly,
  ]);

  return (
    <>
      <Box>
        {!viewOnly && (
          <Grid container alignItems={"center"}>
            <CustomizedButton
              testId="item-open-modal-add-attribute-btn"
              title={t("button.add")}
              variant="outlined"
              color={"secondary"}
              onClick={async () => {
                setOpenModal(true);
              }}
              sx={{ my: 2 }}
              disabled={!isInventoryPage}
            />
          </Grid>
        )}
        <AgGrid
          ref={gridRef}
          autoHeight
          rowData={fields}
          columnDefs={columnDefsTable()}
          disabledSidebar
          disableFloatingFilter
          suppressMenu
        />
      </Box>
      <ModalUI
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title={t("inventory.items.attribute")}
        fullWidth
      >
        <AgGrid
          ref={attributeListGridRef}
          columnDefs={columnDefs(true)}
          height={450}
          disabledSidebar
          rowData={allAttribute}
          rowSelection="multiple"
        />
        <CustomizedButton
          testId="item-add-attribute-btn"
          title="เพิ่ม"
          variant="contained"
          onClick={() => handleSelectAttribute()}
          sx={{ mt: 2 }}
          disabled={!isInventoryPage && viewOnly}
        />
      </ModalUI>
    </>
  );
}
