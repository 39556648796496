import LogisticService from "../../../services/Logistic";
// import SalesService from "../../../services/Sales";
import { deliveryOrderActions } from "./delivery-order-slice";
import {
  logisticQueryFormatter,
  logisticExportFormatter,
  getItemsWithWeightVolume,
} from "../../../utils/logisticPayloadFormatter";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import GlobalService from "../../../services/Global";
import SalesService from "../../../services/Sales";
import { salesQueryFormatter } from "../../../utils/salesPayloadFormatter";

export const getAllDeliveryOrders =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("allDeliveryOrders"));
    try {
      const allDeliveryOrders = await LogisticService.getAllDeliveryOrders(
        input
      );
      params.successCallback(
        allDeliveryOrders.results,
        allDeliveryOrders.count
      );
      dispatch(
        deliveryOrderActions.loadedAllDeliveryOrders(allDeliveryOrders.results)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryOrderActions.rejectedActions({
          ...err,
          name: "allDeliveryOrders",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllDeliveryOrdersModal =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("allDeliveryOrdersModal"));
    try {
      const allDeliveryOrders = await LogisticService.getAllDeliveryOrders(
        input
      );
      params.successCallback(
        allDeliveryOrders.results,
        allDeliveryOrders.count
      );
      dispatch(
        deliveryOrderActions.loadedAllDeliveryOrdersModal(
          allDeliveryOrders.results
        )
      );
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryOrderActions.rejectedActions({
          ...err,
          name: "allDeliveryOrders",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllDeliveryOrdersExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("allDeliveryOrders"));
    try {
      const { results } = await LogisticService.getAllDeliveryOrders(input);
      // const salesOrders =
      //   await SalesService.getAllSalesOrdersForLogisticReport();
      let deliveryOrders = await logisticExportFormatter(
        results,
        // salesOrders,
        "deliveryOrder"
      );
      dispatch(
        deliveryOrderActions.loadedAllDeliveryOrdersExport(deliveryOrders)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryOrderActions.rejectedActions({
          ...err,
          name: "allDeliveryOrders",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllDeliveryOrdersExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("allDeliveryOrders"));
    try {
      const { results } = await LogisticService.getAllDeliveryOrdersReport(
        input
      );
      // const salesOrders =
      //   await SalesService.getAllSalesOrdersForLogisticReport();
      let deliveryOrders = await logisticExportFormatter(
        results,
        // salesOrders,
        "deliveryOrder"
      );
      dispatch(
        deliveryOrderActions.loadedAllDeliveryOrdersExport(deliveryOrders)
      );
    } catch (err) {
      console.log(err);
      dispatch(
        deliveryOrderActions.rejectedActions({
          ...err,
          name: "allDeliveryOrders",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewDeliveryOrder =
  (payload, isSendDeliver, state, user, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("deliveryOrder"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const result = await LogisticService.createDeliveryOrder(payload);
      const createActivityLog = createActivityLogPayload(
        activityLogPayload,
        "delivery_order",
        "สร้างใบส่งของ"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      const createRefActivityLog = createActivityLogPayload(
        {
          document_id: payload.reference_document_id,
          creator_document_id: user.document_id,
        },
        "sales_order",
        `สร้างใบส่งของ ${result.document_id}`
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createRefActivityLog,
      });
      if (state && state?.isCopied) {
        const activityLogPayload = {
          document_id: state.document_id,
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogPayload,
          "delivery_order"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }
      if (isSendDeliver) {
        const documentInput = {
          document_type: "delivery_order",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "delivery_order",
          null,
          "wait_deliver"
        );
        await LogisticService.updateDeliveryOrder({
          ...payload,
          id: result.id,
          update_item_list: true,
        });
        await LogisticService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("ยืนยันใบส่งของสำเร็จ", {
          variant: "success",
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "delivery_order",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(`/logistic/delivery-order/${result.document_id}`);
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryOrderActions.rejectedActions({ ...err, name: "deliveryOrder" })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบส่งของไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getDeliveryOrderById =
  (uniqueInput, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("deliveryOrder"));
    try {
      const deliveryOrder = await LogisticService.getDeliveryOrderById(
        uniqueInput
      );
      const formatDeliveryOrder = await logisticQueryFormatter(deliveryOrder);
      dispatch(deliveryOrderActions.loadedDeliveryOrder(formatDeliveryOrder));

      const salesOrder = await SalesService.getSalesOrderById({
        document_id: deliveryOrder.reference_document_id,
      });
      const formatSalesOrder = await salesQueryFormatter(
        salesOrder,
        "sales_order"
      );

      const itemsWeightVolume = await getItemsWithWeightVolume(
        formatSalesOrder.item_list
      );

      dispatch(deliveryOrderActions.loadedSalesOrderItem(itemsWeightVolume));
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryOrderActions.rejectedActions({ ...err, name: "deliveryOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getDeliveryTripTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("deliveryOrder"));
    try {
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredDeliveryTrip = referenceDocument.filter(
        (document) => document.document_type === "delivery_trip"
      );
      const mappedDTId = filteredDeliveryTrip.map((dt) => dt.document_id);
      const { results } = await LogisticService.getAllDeliveryTrips({
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          document_id: {
            filterType: "set",
            values: mappedDTId,
          },
        },
        sortModel: [],
      });
      dispatch(deliveryOrderActions.loadedDeliveryTripTab(results));
    } catch (err) {
      dispatch(
        deliveryOrderActions.rejectedActions({ ...err, name: "deliveryOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const updateDeliveryOrder =
  (
    payload,
    user,
    status,
    isChangedStatus,
    existing,
    enqueueSnackbar,
    isManualChanged,
    step
  ) =>
  async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("deliveryOrder"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const deliveryOrder = await LogisticService.updateDeliveryOrder(payload);
      const formatDeliveryOrder = await logisticQueryFormatter(deliveryOrder);
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "delivery_order",
        };
        const updatedDeliveryOrder = await LogisticService.postNextStatus(
          documentInput
        );
        //TODO: activity logs
        const acceptedActivityLogs = createActivityLogStatusPayload(
          activityLogPayload,
          "delivery_order",
          "draft",
          "wait_deliver"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: acceptedActivityLogs,
        });

        dispatch(
          deliveryOrderActions.loadedDeliveryOrder({
            ...formatDeliveryOrder,
            render_status: updatedDeliveryOrder.status,
          })
        );
        enqueueSnackbar("ยืนยันใบส่งของสำเร็จ", {
          variant: "success",
        });
      } else if (isManualChanged) {
        const updatedDeliveryOrder = await GlobalService.setDocumentStep({
          document_id: payload.document_id,
          document_type: "delivery_order",
          step: step,
        });
        //TODO: activity logs
        if (step === 3) {
          const changedActivityLogs = createActivityLogStatusPayload(
            activityLogPayload,
            "delivery_order",
            "wait_deliver",
            "not_completed"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: changedActivityLogs,
          });
        } else {
          const changedActivityLogs = createActivityLogStatusPayload(
            activityLogPayload,
            "delivery_order",
            "wait_deliver",
            "completed"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: changedActivityLogs,
          });
        }
        dispatch(
          deliveryOrderActions.loadedDeliveryOrder({
            ...formatDeliveryOrder,
            render_status: updatedDeliveryOrder.status,
          })
        );
        enqueueSnackbar("บันทึกการจัดส่งสำเร็จ", {
          variant: "success",
        });
      } else {
        const editActivityLog = createActivityLogEditPayload(
          activityLogPayload,
          "delivery_order"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
        if (findDifferenceEmployee(payload, existing)) {
          const { addedEmployeeList, deletedEmployeeList } =
            findDifferenceEmployee(payload, existing);
          if (addedEmployeeList && addedEmployeeList.length > 0) {
            // add related employee
            const addedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "add_related_employee",
              addedEmployeeList,
              "delivery_order"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: addedEmployeeListLog,
            });
          }
          if (deletedEmployeeList && deletedEmployeeList.length > 0) {
            // delete related employee
            const deletedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "delete_related_employee",
              deletedEmployeeList,
              "delivery_order"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: deletedEmployeeListLog,
            });
          }
        }
        switch (status) {
          case "draft":
            dispatch(
              deliveryOrderActions.loadedDeliveryOrder(formatDeliveryOrder)
            );
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
            break;
          default:
            dispatch(
              deliveryOrderActions.loadedDeliveryOrder({
                ...formatDeliveryOrder,
                render_status: status,
              })
            );
            enqueueSnackbar("แก้ไขใบส่งของสำเร็จ", {
              variant: "success",
            });
        }
      }
    } catch (err) {
      dispatch(
        deliveryOrderActions.rejectedActions({ ...err, name: "deliveryOrder" })
      );
      switch (status) {
        case "draft":
          if (isChangedStatus) {
            err.response.errors.forEach((error) => {
              if (errorMessageHandler(error.message)) {
                enqueueSnackbar(errorMessageHandler(error.message), {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("ยืนยันใบส่งของไม่สำเร็จ", {
                  variant: "error",
                });
              }
            });
          } else {
            enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        case "wait_deliver":
          if (isManualChanged) {
            enqueueSnackbar("บันทึกการจัดส่งไม่สำเร็จ", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("แก้ไขใบส่งของไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        default:
          enqueueSnackbar("แก้ไขใบส่งของไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const cancelDeliveryOrder =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("deliveryOrder"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const cancelActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "delivery_order",
        null,
        "cancelled"
      );
      await GlobalService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(deliveryOrderActions.updateDeliveryOrderStatus("cancelled"));
    } catch (err) {
      dispatch(
        deliveryOrderActions.rejectedActions({ ...err, name: "deliveryOrder" })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const getNewDeliveryOrderReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryOrderActions.onLoading("deliveryOrderReports"));
    try {
      const allDeliveryOrders =
        await LogisticService.getAllNewDeliveryOrdersReport(input);
      params.successCallback(
        allDeliveryOrders.results,
        allDeliveryOrders.count
      );
      dispatch(deliveryOrderActions.onLoaded("deliveryOrderReports"));
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryOrderActions.rejectedActions({
          ...err,
          name: "deliveryOrderReports",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
