import moment from "moment";
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import NewReportDateFilter from "../../../components/UI/NewReportDateFilter";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import ItemNoEntryReportTable from "../../../components/Table/DocumentTable/ItemNoEntryReportTable";
import TimeStampReportLayout from "../../../components/UI/TimeStampReportLayout";
import ControlledSelect from "../../../components/Controlled/ControlledSelect";

import GlobalService from "../../../services/Global";
import InventoryService from "../../../services/Inventory";

import { getItemNoEntryReport } from "../../../features/Inventory/Report/report-actions";
import { inventoryReportActions } from "../../../features/Inventory/Report/report-slice";
import useItemNoEntryColumnDef from "../../../hooks/Inventory/useItemNoEntryColumnDef";
import useReportOptions from "../../../hooks/Inventory/useReportOptions";
import { formatExportQuotes } from "../../../utils/exporter";

import {
  dateToUnix,
  formatDateAgGrid,
  formatDateExport,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import { filterDateWithUnix } from "../../../utils/filterparams";
import { useCallback } from "react";

const defaultDate = {
  dateType: "lastWeek",
  date: new Date(moment().startOf("day").subtract(6, "day")),
  dateTo: new Date(moment().endOf("day")),
  refType: "ทั้งหมด",
  type: "ทั้งหมด",
};

const ItemNoEntryReportView = () => {
  const { isLoading } = useSelector((state) => state.inventoryReport);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const [filename, setFilename] = useState("");
  const [data, setData] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [fetchTimestamp, setFetchTimestamp] = useState(null);
  const csvInstance = useRef(null);
  const [showTable, setShowTable] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [detail, setDetail] = useState(null);
  const [genDetail, setGenDetail] = useState(null);

  const columnDef = useItemNoEntryColumnDef(true);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.noEntry.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: defaultDate,
  });

  const watchRefType = useWatch({ control, name: "refType" });
  const watchType = useWatch({ control, name: "type" });
  const watchStartDate = useWatch({ control, name: "date" });
  const watchEndDate = useWatch({ control, name: "dateTo" });

  const refTypeOption = useReportOptions("refType");
  const typeOption = useReportOptions("type");

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const { is_active, created_date } = filterModel;

      const formatFilterModel = {
        ...filterModel,
        is_active: is_active &&
          is_active.values.length > 0 && {
            filterType: "boolean",
            type: "equals",
            filter: is_active.values[0],
          },
        created_date: created_date && filterDateWithUnix(created_date),
      };

      const findEntryInput = {
        end_posted_date: dateToUnix(watchEndDate),
        reference_document_type:
          watchRefType !== "ทั้งหมด" ? watchRefType : undefined,
        start_posted_date: dateToUnix(watchStartDate),
        type: watchType !== "ทั้งหมด" ? watchType : undefined,
      };

      dispatch(
        getItemNoEntryReport(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar,
          findEntryInput
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      let startDate = getValues("date");
      let endDate = getValues("dateTo");

      const created_date = params.api.getFilterInstance("created_date");

      created_date?.setModel({
        filterType: "date",
        type: "inRange",
        dateFrom: formatDateAgGrid(startDate),
        dateTo: formatDateAgGrid(endDate),
      });
      params.api.onFilterChanged();
    },
    [getValues]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const getFiltered = async () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
      gridRef.current.api.setServerSideDatasource(datasource);
    }
    const { timestamp, in_queue_timestamp, detail } =
      await GlobalService.getReportViewTimestamp("ItemNoEntry");
    setDetail(detail);
    setLastTimestamp(timestamp);
    setFetchTimestamp(
      in_queue_timestamp ? in_queue_timestamp.timestamp * 1000 : null
    );
    setGenDetail(null);
  };

  const exportAllDataAsAgGridCsv = async () => {
    try {
      const filterModel = gridRef.current.api.getFilterModel();
      const sortModel = gridRef.current.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const { created_date } = filterModel;

      const formatFilterModel = {
        ...filterModel,
        created_date: created_date && filterDateWithUnix(created_date),
      };

      dispatch(inventoryReportActions.onLoading("itemNoEntry"));
      const { results: itemNoEntry } =
        await InventoryService.getNewItemNoEntryReport({
          startRow: 0,
          endRow: Math.pow(10, 5),
          filterModel: formatFilterModel,
          sortModel,
        });
      let startDate = getValues("date");
      let endDate = getValues("dateTo");
      const newResult = await itemNoEntry.map(
        ({ status, created_date, ...other }) => ({
          ...other,
          created_date: created_date && unixToDateWithFormat(created_date),
        })
      );
      setData(formatExportQuotes(newResult));
      setFilename(
        `${t("inventory.noEntry.index")}_${formatDateExport(
          startDate
        )}_${formatDateExport(endDate)}`
      );
      dispatch(inventoryReportActions.onLoaded("itemNoEntry"));
      setTimeout(() => {
        if (csvInstance.current) {
          csvInstance.current.link.click();
        }
      });
      enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error exporting CSV:", error);
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const columnDefHeader = columnDef.map((col) => ({
    label: col.headerName,
    key: col.field,
  }));

  const generateReportHandler = async () => {
    setFetching(true);
    let endDate = getValues("dateTo");
    let startDate = getValues("date");
    const { status, timestamp, detail } = await GlobalService.generateReport(
      "ItemNoEntry",
      {
        end_date: dateToUnix(endDate),
        start_date: dateToUnix(startDate),
      }
    );
    setGenDetail(detail);
    setTimeout(() => {
      setFetching(false);
    }, [5000]);

    if (status === "success") {
      setFetchTimestamp(timestamp);
      enqueueSnackbar("ส่งคำขอสำเร็จ กรุณารอสักครู่", {
        variant: "success",
      });
    } else if (status === "error") {
      enqueueSnackbar(
        `ระบบกำลังดำเนินการ กรุณารอสักครู่\n(ขอล่าสุด ${moment(
          fetchTimestamp
        ).fromNow()})`,
        {
          variant: "warning",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
  };

  const onFilterReset = () => {
    reset(defaultDate);
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  useEffect(() => {
    if (
      watchRefType &&
      typeOption &&
      typeOption.length > 0 &&
      !typeOption
        .find((option) => option.value === getValues("type"))
        .ref.includes(watchRefType) > 0
    )
      return setValue("type", "ทั้งหมด");
  }, [getValues, setValue, typeOption, watchRefType]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("inventory.noEntry.index")}</Typography>
        <Box>
          <CustomizedButton
            title={t("inventory.exportReport")}
            variant="contained"
            onClick={exportAllDataAsAgGridCsv}
            disabled={isLoading.itemNoEntry}
          />
          <CSVLink
            headers={columnDefHeader}
            data={data}
            filename={filename}
            ref={csvInstance}
          />
        </Box>
      </Box>
      <CustomizedBox>
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <ControlledSelect
                control={control}
                name={"refType"}
                options={refTypeOption}
                label={t("inventory.stockEntry.entryDocumentType")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <ControlledSelect
                control={control}
                name={"type"}
                options={typeOption.filter(
                  (option) => option.ref.includes(watchRefType) > 0
                )}
                disabled={
                  watchRefType !== "ทั้งหมด" &&
                  watchRefType !== "receive" &&
                  watchRefType !== "issue"
                }
                label={t("inventory.stockEntry.entryDocumentType")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFiltered}
                disabled={isLoading.itemNoEntry}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.itemNoEntry}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </CustomizedBox>
      {showTable && (
        <TimeStampReportLayout
          detail={detail}
          genDetail={genDetail}
          fetching={fetching}
          generateReportHandler={generateReportHandler}
          lastTimestamp={lastTimestamp}
          fetchTimestamp={fetchTimestamp}
          documentType="entry"
        />
      )}
      {showTable && (
        <ItemNoEntryReportTable
          gridRef={gridRef}
          onGridReady={onGridReady}
          isReport
        />
      )}
    </>
  );
};

export default ItemNoEntryReportView;
