import { useState, useEffect, Fragment } from "react";
import { TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import GlobalService from "../../services/Global";

const filter = createFilterOptions();
// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }

const ControlledServerSideCreatable = ({
  control,
  sx,
  name,
  dataName,
  setValue,
  error,
  defaultOptions,
  onInputChange,
  onKeyDown,
  onChange,
  helperText,
  title,
  placeholder,
  viewOnly,
  required,
  documentType,
  endAdornment,
}) => {
  // const [isInit, setIsInit] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let active = true;
    // if (!isInit) {
    const getOptions = async () => {
      const loadedOptions = await GlobalService.getCreatables({
        usage_field_type: documentType,
        usage_field_name: dataName || name,
      });
      // await sleep(1e3);
      if (active) {
        setOptions(
          loadedOptions.map((option) => {
            return {
              value: option.name,
              label: option.name,
              isDeletable: true,
            };
          })
        );
      }
    };
    getOptions();
    // setIsInit(true);
    // }
    return () => {
      active = false;
    };
  }, [documentType, dataName, name]);

  const addOptionHandler = async (optionToAdd) => {
    setValue(name, optionToAdd);
    setOptions((prevOptions) => [
      ...prevOptions,
      { value: optionToAdd, label: optionToAdd, isDeletable: true },
    ]);
    try {
      await GlobalService.createCreatable({
        usage_field_type: documentType,
        usage_field_name: dataName || name,
        name: optionToAdd,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteOptionHandler = async (optionToDelete) => {
    try {
      await GlobalService.deleteCreatable({
        name_usage_field_type_usage_field_name: {
          usage_field_type: documentType,
          usage_field_name: dataName || name,
          name: optionToDelete,
        },
      });
    } catch (err) {
      console.error(err);
    }
    setOptions((prevOptions) =>
      prevOptions.filter((option) => option.value !== optionToDelete)
    );
    setValue(name, "");
  };

  const combinedOptions = [...defaultOptions, ...options];

  return (
    <>
      <Controller
        key={`${name}-creatable`}
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            fullWidth
            {...field}
            onChange={
              onChange
                ? (e, option) => {
                    if (option?.label?.includes("เพิ่ม ")) {
                      addOptionHandler(option.inputValue);
                    }
                    onChange(e, option, field);
                  }
                : (e, option) => {
                    if (option?.label?.includes("เพิ่ม ")) {
                      addOptionHandler(option.inputValue);
                    }
                    if (!option) {
                      return field.onChange("");
                    }
                    if (option.value) {
                      return field.onChange(option.value);
                    }
                    if (option.inputValue) {
                      return field.onChange(option.inputValue);
                    }
                    return field.onChange(option);
                  }
            }
            disabled={viewOnly}
            onInputChange={onInputChange}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.label
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `เพิ่ม "${inputValue}"`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            disableClearable={endAdornment ? true : false}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={combinedOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.value || option.type;
            }}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  value={option.value ?? option}
                  key={option.label ?? option}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {option.label ?? option}
                  {!option?.label?.includes("เพิ่ม ") && option.isDeletable && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        deleteOptionHandler(option.value ?? option);
                        e.stopPropagation();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </li>
              );
            }}
            sx={sx}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label={title}
                size="small"
                name={name}
                error={error}
                onKeyDown={onKeyDown}
                helperText={helperText}
                required={required}
                disabled={viewOnly}
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: endAdornment ?? (
                    <Fragment>{params.InputProps.endAdornment}</Fragment>
                  ),
                }}
              />
            )}
            readOnly={viewOnly}
          />
        )}
      />
    </>
  );
};

export default ControlledServerSideCreatable;
