export const purchasePrimaryButtonHandler = (
  status,
  isEdit,
  sendApproveHandler,
  approveHandler,
  editSubmitHandler
) => {
  if (isEdit) {
    editSubmitHandler();
  } else {
    switch (status) {
      case "draft":
        sendApproveHandler();
        break;
      case "waitApprove":
        approveHandler();
        break;
      default:
        sendApproveHandler();
    }
  }
};

export const purchaseSecondaryButtonHandler = (
  status,
  isEdit,
  saveDraftHandler,
  notApproveHandler,
  editCancelHandler
) => {
  if (isEdit) {
    editCancelHandler();
  } else {
    switch (status) {
      case "draft":
        saveDraftHandler();
        break;
      case "waitApprove":
        notApproveHandler();
        break;
      default:
        saveDraftHandler();
    }
  }
};
