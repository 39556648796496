import { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  List,
  ListItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import InventoryService from "../../../services/Inventory";
import SettingService from "../../../services/Setting";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import ImporterDropzoneUI from "../../../components/UI/ImporterDropzoneUI";
import ModalUI from "../../../components/UI/ModalUI";
import AgGrid from "../../../components/Table/AgGrid";
import CustomizedCheckboxes from "../../../components/Custom/CustomizedCheckboxes";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import { formatFloat, formatStringArray } from "../../../utils/dataTransformer";
import ImportErrorTable from "../../../components/Table/ImportErrorTable";

const keys = ["uom_group.uom_conversion_list", "attribute_list", "tag_list"];

const requiredKeys = [
  "document_id",
  "name",
  "sku",
  "item_group_sub_level_1_document_id",
  "base_uom_document_id",
  "sales_uom_document_id",
  "purchase_uom_document_id",
  "deliver_uom_document_id",
];

const columns = [
  "document_id",
  "name",
  "sku",
  "barcode",
  "type",
  "description",
  "tag_list",
  "item_group_sub_level_1_document_id",
  "item_group_sub_level_2_document_id",
  "item_group_sub_level_3_document_id",
  "width",
  "width_uom_document_id",
  "length",
  "length_uom_document_id",
  "height",
  "height_uom_document_id",
  "thickness",
  "thickness_uom_document_id",
  "package_width",
  "package_width_uom_document_id",
  "package_length",
  "package_length_uom_document_id",
  "package_height",
  "package_height_uom_document_id",
  "package_weight",
  "package_weight_uom_document_id",
  "purchase_standard_price",
  "purchase_minimum_qty",
  "purchase_vat_type",
  "sales_standard_price",
  "sales_vat_type",
  "uom_group",
  "sales_uom_document_id",
  "purchase_uom_document_id",
  "deliver_uom_document_id",
  "base_uom_document_id",
  "manufacture_type",
  "manufacture_minimum_qty",
  "main_bom_name",
  "mrp_default_method",
  "remark_status",
  "attribute_list",
];

const ItemsImporterPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const gridRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [createErrors, setCreateErrors] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [formattedCreateOnlyData, setFormattedCreateOnlyData] = useState([]);
  const [createData, setCreateData] = useState([]);
  const [updateOrSkipData, setUpdateOrSkipData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [missingCategories, setMissingCategories] = useState([]);
  const [missingAttributes, setMissingAttributes] = useState([]);
  const [missingUoms, setMissingUoms] = useState([]);
  const [idsWithWhitespace, setIdsWithWhitespace] = useState([]);
  const [errorStep, setErrorStep] = useState(null);
  const [isImported, setIsImported] = useState(false);

  const { control } = useForm({
    defaultValues: { is_upsert: true },
  });
  const watchIsUpsert = useWatch({ control, name: "is_upsert" });

  const errorCount = isImported
    ? (idsWithWhitespace?.length > 0 ? 1 : 0) +
      (missingAttributes?.length > 0 ? 1 : 0) +
      (missingCategories?.length > 0 ? 1 : 0) +
      (missingUoms?.length > 0 ? 1 : 0)
    : 0;

  const tabs = [
    {
      label: `สร้าง (${createData.length})`,
      path: `${pathname}`,
    },
    {
      label: `แก้ไข (${watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=updates`,
    },
    {
      label: `ข้าม (${!watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=skips`,
    },
    {
      label: `Error (${errorCount})`,
      path: `${pathname}?tab=error`,
      errors: errorCount > 0,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "updates":
        if (watchIsUpsert) {
          setDisplayedData(updateOrSkipData);
        } else {
          setDisplayedData([]);
        }
        break;
      case "skips":
        if (!watchIsUpsert) {
          setDisplayedData(updateOrSkipData);
        } else {
          setDisplayedData([]);
        }
        break;
      default:
        setDisplayedData(createData);
    }
    // setDisplayedData(flattenedSkuList);
  }, [watchIsUpsert, tab, createData, updateOrSkipData]);

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("inventory.items.itemId"),
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      width: 200,
    },
    {
      field: "name",
      headerName: t("inventory.items.itemName"),
      filter: "agTextColumnFilter",
    },
    {
      field: "sku",
      headerName: t("inventory.items.itemInternalName"),
      filter: "agTextColumnFilter",
    },
    {
      field: "is_active",
      headerName: t("inventory.status"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <CustomizedStatus status={params.value ? "active" : "inactive"} />
      ),
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
      to: "/inventory/items",
    },
    {
      name: "นำเข้าสินค้า",
    },
  ];

  const formatItemdata = async (data) => {
    setIsImported(false);
    let missingCols;
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const existingUoms = await SettingService.getAllUom();
      const formattedExistingUoms = existingUoms.map((uom) => uom.document_id);

      const existingAttributes = await SettingService.getAllAttribute();
      const formattedExistingAttributes = existingAttributes.map(
        (attribute) => attribute.name
      );

      const existingCategoryLv1 = [];
      const existingCategoryLv2 = [];
      const existingCategoryLv3 = [];
      const categories = await SettingService.getAllCategory();
      categories?.forEach((categoryLv1) => {
        existingCategoryLv1.push(categoryLv1.document_id);
        categoryLv1.sub_level_2_list?.forEach((categoryLv2) => {
          existingCategoryLv2.push(categoryLv2.document_id);
          categoryLv2.sub_level_3_list?.forEach((categoryLv3) => {
            existingCategoryLv3.push(categoryLv3.document_id);
          });
        });
      });

      const filteredAttributes = data
        .map((item) =>
          item.attribute_list.map((attribute) =>
            attribute.attribute_name?.toString()
          )
        )
        .flat()
        .filter(
          (attribute) =>
            typeof attribute !== "undefined" &&
            !formattedExistingAttributes.includes(attribute)
        );

      const uniqueFilteredAttributes = [...new Set(filteredAttributes)];

      const filteredUoms = data
        .map((item) => [
          item.width_uom_document_id?.toString(),
          item.length_uom_document_id?.toString(),
          item.height_uom_document_id?.toString(),
          item.thickness_uom_document_id?.toString(),
          item.package_width_uom_document_id?.toString(),
          item.package_length_uom_document_id?.toString(),
          item.package_height_uom_document_id?.toString(),
          item.package_weight_uom_document_id?.toString(),
          item.sales_uom_document_id?.toString(),
          item.purchase_uom_document_id?.toString(),
          item.deliver_uom_document_id?.toString(),
          item.base_uom_document_id?.toString(),
          item.uom_group.uom_conversion_list?.map((uomConversion) =>
            uomConversion.target_uom_document_id?.toString()
          ),
        ])
        .flat()
        .flat()
        .filter(
          (uom) =>
            typeof uom !== "undefined" && !formattedExistingUoms.includes(uom)
        );
      const uniqueFilteredUoms = [...new Set(filteredUoms)];

      const missingCategoryLv1 = data
        .map((item) => item.item_group_sub_level_1_document_id)
        .filter(
          (categoryLv1) =>
            categoryLv1 !== null && !existingCategoryLv1.includes(categoryLv1)
        );
      const missingCategoryLv2 = data
        .map((item) => item.item_group_sub_level_2_document_id)
        .filter(
          (categoryLv2) =>
            categoryLv2 !== null && !existingCategoryLv2.includes(categoryLv2)
        );
      const missingCategoryLv3 = data
        .map((item) => item.item_group_sub_level_2_document_id)
        .filter(
          (categoryLv3) =>
            categoryLv3 !== null && !existingCategoryLv2.includes(categoryLv3)
        );
      const filteredCategories = [
        ...new Set(
          [missingCategoryLv1, missingCategoryLv2, missingCategoryLv3].flat()
        ),
      ];

      setMissingUoms(uniqueFilteredUoms);
      setMissingAttributes(uniqueFilteredAttributes);
      setMissingCategories(filteredCategories);

      const formatAttributeList = (attributeList) => {
        return attributeList
          .map((attribute) => {
            return {
              attribute_name:
                attribute.attribute_name?.toString().trim() ?? undefined,
              attribute_value:
                attribute.attribute_value?.toString().trim() ?? undefined,
            };
          })
          .filter(
            (attribute) =>
              typeof attribute.attribute_name !== "undefined" &&
              typeof attribute.attribute_value !== "undefined"
          );
      };

      const formatMRPDefaultMethod = (method) => {
        switch (method) {
          case "ผลิต":
            return "produce";
          case "สั่งซื้อ":
            return "buy";
          default:
            return "no_setting";
        }
      };

      const formattedItemData = data.map((item) => {
        const formattedAttributeList = formatAttributeList(item.attribute_list);
        const formattedUomConversionList = item.uom_group?.uom_conversion_list
          ?.map((uomConversion) => {
            return {
              base_uom_rate: !isNaN(parseFloat(uomConversion.base_uom_rate))
                ? parseFloat(parseFloat(uomConversion.base_uom_rate).toFixed())
                : 0,
              target_uom_rate: !isNaN(parseFloat(uomConversion.target_uom_rate))
                ? parseFloat(
                    parseFloat(uomConversion.target_uom_rate).toFixed()
                  )
                : 0,
              target_uom_document_id: uomConversion.target_uom_document_id
                ?.toString()
                .trim(),
            };
          })
          .filter(
            (uomConversion) =>
              typeof uomConversion.target_uom_document_id !== "undefined"
          );

        const formatVatType = (type) => {
          switch (type) {
            case 7:
            case 0.07:
            case "7":
            case "7%":
            case "7 %":
              return "7";
            case 0:
            case "0":
            case "0%":
            case "0 %":
              return "0";
            default:
              return "ไม่มี";
          }
        };

        const formatUomDocumentId = (uomDocumentId, defaultValue) => {
          const formattedUom =
            uomDocumentId && uomDocumentId !== "0"
              ? uomDocumentId.toString().trim()
              : defaultValue;
          return formattedUom;
        };

        return {
          ...item,
          document_id: item.document_id?.toString().trim(),
          name: item.name?.toString().trim() ?? "",
          sku: item.sku?.toString().trim() ?? "",
          barcode: item.barcode?.toString().trim() ?? "",
          type: item.type || "อื่นๆ",
          description: item.description?.toString().trim() ?? "",
          is_purchasable: true,
          is_salable: true,
          is_stockable: true,
          is_manufactuable: true,
          is_active: true,
          item_group_sub_level_1_document_id:
            item.item_group_sub_level_1_document_id?.toString().trim() ?? "",
          item_group_sub_level_2_document_id:
            item.item_group_sub_level_2_document_id?.toString().trim() ?? "",
          item_group_sub_level_3_document_id:
            item.item_group_sub_level_3_document_id?.toString().trim() ?? "",
          width_uom_document_id: formatUomDocumentId(
            item.width_uom_document_id,
            "เซนติเมตร"
          ),
          length_uom_document_id: formatUomDocumentId(
            item.length_uom_document_id,
            "เซนติเมตร"
          ),
          height_uom_document_id: formatUomDocumentId(
            item.height_uom_document_id,
            "เซนติเมตร"
          ),
          weight_uom_document_id: formatUomDocumentId(
            item.weight_uom_document_id,
            "กิโลกรัม"
          ),
          thickness_uom_document_id: formatUomDocumentId(
            item.thickness_uom_document_id,
            "เซนติเมตร"
          ),
          width: !isNaN(parseFloat(item.width)) ? formatFloat(item.width) : 0,
          length: !isNaN(parseFloat(item.length))
            ? formatFloat(item.length)
            : 0,
          height: !isNaN(parseFloat(item.height))
            ? formatFloat(item.height)
            : 0,
          weight: !isNaN(parseFloat(item.weight))
            ? formatFloat(item.weight)
            : 0,
          thickness: !isNaN(parseFloat(item.thickness))
            ? formatFloat(item.thickness)
            : 0,
          package_width: !isNaN(parseFloat(item.package_width))
            ? formatFloat(item.package_width)
            : 0,
          package_length: !isNaN(parseFloat(item.package_length))
            ? formatFloat(item.package_length)
            : 0,
          package_height: !isNaN(parseFloat(item.package_height))
            ? formatFloat(item.package_height)
            : 0,
          package_weight: !isNaN(parseFloat(item.package_weight))
            ? formatFloat(item.package_weight)
            : 0,
          package_width_uom_document_id:
            item.package_width_uom_document_id?.toString().trim() ??
            "เซนติเมตร",
          package_length_uom_document_id:
            item.package_length_uom_document_id?.toString().trim() ??
            "เซนติเมตร",
          package_height_uom_document_id:
            item.package_height_uom_document_id?.toString().trim() ??
            "เซนติเมตร",
          package_weight_uom_document_id:
            item.package_weight_uom_document_id?.toString().trim() ??
            "กิโลกรัม",
          purchase_standard_price: !isNaN(
            parseFloat(item.purchase_standard_price)
          )
            ? formatFloat(item.purchase_standard_price)
            : 0,
          purchase_minimum_qty: !isNaN(parseInt(item.purchase_minimum_qty))
            ? formatFloat(item.purchase_minimum_qty)
            : 0,
          purchase_vat_type: formatVatType(item.purchase_vat_type),
          sales_standard_price: !isNaN(parseFloat(item.sales_standard_price))
            ? formatFloat(item.sales_standard_price)
            : 0,
          sales_vat_type: formatVatType(item.sales_vat_type),
          uom_group: {
            name: item.document_id?.toString().trim() + "_uom_group",
            document_id: item.document_id?.toString().trim(),
            description: item.uom_group.description?.toString().trim() ?? "",
            uom_conversion_list: formattedUomConversionList,
          },
          base_uom_document_id:
            item.base_uom_document_id?.toString().trim() ?? "",
          sales_uom_document_id:
            item.sales_uom_document_id?.toString().trim() ?? "",
          purchase_uom_document_id:
            item.purchase_uom_document_id?.toString().trim() ?? "",
          deliver_uom_document_id:
            item.deliver_uom_document_id?.toString().trim() ?? "",
          mrp_default_method: formatMRPDefaultMethod(item.mrp_default_method),
          manufacture_type: item.manufacture_type?.toString().trim(),
          manufacture_minimum_qty: !isNaN(
            parseInt(item.manufacture_minimum_qty)
          )
            ? parseInt(item.manufacture_minimum_qty)
            : undefined,
          main_bom_name: item.main_bom_name?.toString().trim() ?? "",
          manufacture_bom_document_id: undefined,
          // // current_stock_qty: !isNaN(parseInt(item.current_stock_qty))
          // //   ? parseInt(item.current_stock_qty)
          // //   : undefined,
          // // current_committed_sales_qty: !isNaN(
          // //   parseInt(item.current_committed_sales_qty)
          // // )
          // //   ? parseInt(item.current_committed_sales_qty)
          // //   : undefined,
          // // current_committed_manufacture_qty: !isNaN(
          // //   parseInt(item.current_committed_manufacture_qty)
          // // )
          //   ? parseInt(item.current_committed_manufacture_qty)
          //   : undefined,
          // current_ordered_purchase_qty: !isNaN(
          //   parseInt(item.current_ordered_purchase_qty)
          // )
          //   ? parseInt(item.current_ordered_purchase_qty)
          //   : undefined,
          // current_ordered_manufacture_qty: !isNaN(
          //   parseInt(item.current_ordered_manufacture_qty)
          // )
          //   ? parseInt(item.current_ordered_manufacture_qty)
          //   : undefined,
          // current_available_qty: !isNaN(parseInt(item.current_available_qty))
          //   ? parseInt(item.current_available_qty)
          //   : undefined,
          remark_status: item.remark_status?.toString().trim() ?? "",
          // remark_staff: item.remark_staff?.toString().trim(),
          // remark_datetime: !isNaN(parseInt(item.remark_datetime))
          //   ? parseInt(item.remark_datetime)
          //   : undefined,
          attribute_list:
            formattedAttributeList.length > 0
              ? formattedAttributeList
              : undefined,
          tag_list: formatStringArray(item.tag_list),
        };
      });
      const existingItems = await InventoryService.getAllItemsIds();
      const existingItemIds = existingItems.map((item) => item.document_id);
      const itemsToCreate = [];
      const itemsToSkipOrUpdate = [];

      formattedItemData?.forEach((item) => {
        if (existingItemIds.includes(item.document_id)) {
          itemsToSkipOrUpdate.push(item);
        } else {
          itemsToCreate.push(item);
        }
      });
      setCreateData(itemsToCreate);
      setUpdateOrSkipData(itemsToSkipOrUpdate);

      setFormattedCreateOnlyData(
        formattedItemData.filter(
          (item) => !existingItemIds.includes(item.document_id)
        )
      );

      return formattedItemData;
    } catch (err) {
      console.error(err);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const importFormattedDocuments = async () => {
    setIsLoading(true);
    setHasCreateError(false);
    setCreateErrors([]);
    if (formattedData.length === 0) {
      return;
    }
    const formattedDataSnapshot = [...formattedData];
    const requiredFieldErrors = [];
    for (let i = 0; i < formattedDataSnapshot.length; i++) {
      for (let j = 0; j < requiredKeys.length; j++) {
        if (
          !formattedDataSnapshot[i][requiredKeys[j]] &&
          formattedDataSnapshot[i][requiredKeys[j]] !== 0
        ) {
          requiredFieldErrors.push({
            document_id: formattedDataSnapshot[i].document_id,
            type: "required",
            field: requiredKeys[j],
          });
          formattedDataSnapshot.splice(i, 1);
          i--;
          break;
        }
      }
    }
    if (requiredFieldErrors.length > 0) {
      setCreateErrors(requiredFieldErrors);
      setIsLoading(false);
      setHasCreateError(true);
    } else {
      let idsWithSpace = [];
      try {
        let data;
        if (watchIsUpsert) {
          const dataWithFormattedId = formattedData.filter((data) => {
            if (data.document_id.includes(" ")) {
              idsWithSpace.push(data.document_id);
              return false;
            }
            return true;
          });
          data = await InventoryService.createOrUpdateItems({
            dataInput: dataWithFormattedId,
          });
        } else {
          const dataWithFormattedId = formattedCreateOnlyData.filter((data) => {
            if (data.document_id.includes(" ")) {
              idsWithSpace.push(data.document_id);
              return false;
            }
            return true;
          });
          data = await InventoryService.createItems({
            createManyInput: dataWithFormattedId,
          });
        }
        if (data.errors?.length === 0 && idsWithSpace.length === 0) {
          let message = "";
          if (
            watchIsUpsert &&
            createData.length > 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "นำเข้าและแก้ไขสินค้าสำเร็จ";
          } else if (
            watchIsUpsert &&
            createData.length === 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "แก้ไขสินค้าสำเร็จ";
          } else if (
            (watchIsUpsert &&
              createData.length > 0 &&
              updateOrSkipData.length === 0) ||
            (!watchIsUpsert && createData.length > 0)
          ) {
            message = "นำเข้าสำเร็จ";
          }
          enqueueSnackbar(message, {
            variant: "success",
          });
          navigate("/inventory/items");
          if (idsWithSpace.length > 0) {
            throw new Error("id_whitespace");
          }
        } else {
          console.log(data);
          if (
            idsWithSpace.length > 0 ||
            missingAttributes.length > 0 ||
            missingUoms.length > 0 ||
            missingCategories.length > 0
          ) {
            throw new Error();
          }
        }
      } catch (err) {
        console.error(err);
        if (idsWithSpace.length > 0) {
          setIdsWithWhitespace(idsWithSpace);
          setCreateErrors((prevErrors) => [
            ...prevErrors,
            { type: "id_whitespace", ids: idsWithSpace },
          ]);
        }
        if (missingAttributes.length > 0) {
          setErrorStep("1");
        } else if (missingUoms.length > 0) {
          setErrorStep("2");
        } else if (missingCategories.length > 0) {
          setErrorStep("3");
        } else {
          setErrorStep("4");
        }
        setHasCreateError(true);
      }
    }
    setIsImported(true);
    setIsLoading(false);
  };

  const handleErrorNextStep = (currentStep) => {
    const whiteSpaceError = createErrors.find(
      (error) => error.type === "id_whitespace"
    );
    switch (currentStep) {
      case "1":
        if (missingUoms.length > 0) {
          setErrorStep("2");
        } else if (missingCategories.length > 0) {
          setErrorStep("3");
        } else if (whiteSpaceError) {
          setErrorStep("4");
        } else {
          setHasCreateError(false);
        }
        break;
      case "2":
        if (missingCategories.length > 0) {
          setErrorStep("3");
        } else if (whiteSpaceError) {
          setErrorStep("4");
        } else {
          setHasCreateError(false);
        }
        break;
      case "3":
        if (whiteSpaceError) {
          setErrorStep("4");
        } else {
          setHasCreateError(false);
        }
        break;
      default:
    }
  };

  const renderCreateErrors = () => {
    if (createErrors.find((error) => error.type === "template_from_importer")) {
      enqueueSnackbar("Template ไม่ตรง", {
        variant: "error",
      });
    }
    const requiredFieldErrors = [
      ...new Set(
        createErrors
          .filter((error) => error.type === "required")
          .map((error) => error.field)
      ),
    ];
    if (requiredFieldErrors.length > 0) {
      enqueueSnackbar(
        `ไม่พบข้อมูลใน column\n${requiredFieldErrors.join(", ")}`,
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
    const whiteSpaceError = createErrors.find(
      (error) => error.type === "id_whitespace"
    );
    switch (errorStep) {
      case "1":
        return (
          <ModalUI
            open={hasCreateError}
            handleClose={() => handleErrorNextStep("1")}
            fullWidth
            title="ไม่สามารถสร้างได้ เนื่องจากไม่พบคุณสมบัติที่ถูกบันทึกในระบบ"
            titleSize="16px"
            maxWidth="xs"
          >
            <Typography fontWeight="bold">หน่วยที่ไม่มีในระบบ:</Typography>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
            >
              {missingAttributes.map((attribute) => (
                <ListItem
                  key={`attr-${attribute}`}
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography
                    sx={{
                      display: "inline",
                      color: (theme) => theme.palette.warning.main,
                    }}
                  >
                    {attribute}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Typography mt={1}>
              {"(สร้างหน่วยได้ที่ ตั้งค่าคลัง > หน่วย > เพิ่มหน่วย)"}
            </Typography>
          </ModalUI>
        );
      case "2":
        return (
          <ModalUI
            open={hasCreateError}
            handleClose={() => handleErrorNextStep("2")}
            fullWidth
            title="ไม่สามารถสร้างได้ เนื่องจากไม่พบหน่วยที่ถูกบันทึกในระบบ"
            titleSize="16px"
            maxWidth="xs"
          >
            <Typography fontWeight="bold">หน่วยที่ไม่มีในระบบ:</Typography>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
            >
              {missingUoms.map((uom) => (
                <ListItem
                  key={`uom-${uom}`}
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography
                    sx={{
                      display: "inline",
                      color: (theme) => theme.palette.warning.main,
                    }}
                  >
                    {uom}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Typography mt={1}>
              {"(สร้างหน่วยได้ที่ ตั้งค่าคลัง > หน่วย > เพิ่มหน่วย)"}
            </Typography>
          </ModalUI>
        );
      case "3":
        return (
          <ModalUI
            open={hasCreateError}
            handleClose={() => handleErrorNextStep("3")}
            fullWidth
            title="ไม่สามารถสร้างได้ เนื่องจากไม่พบหมวดหมู่ที่ถูกบันทึกในระบบ"
            titleSize="16px"
            maxWidth="xs"
          >
            <Typography fontWeight="bold">หมวดหมู่ที่ไม่มีในระบบ:</Typography>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
            >
              {missingCategories.map((category) => (
                <ListItem
                  key={`cat-${category}`}
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography
                    sx={{
                      display: "inline",
                      color: (theme) => theme.palette.warning.main,
                    }}
                  >
                    {category}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Typography mt={1}>
              {"(สร้างหมวดหมู่ได้ที่ ตั้งค่าคลัง > หมวดหมู่ > เพิ่มหมวดหมู่)"}
            </Typography>
          </ModalUI>
        );
      case "4":
        return (
          <ModalUI
            open={hasCreateError}
            handleClose={() => setHasCreateError(false)}
            fullWidth
            title="ไม่สามารถสร้างได้ เนื่องจากรหัสสินค้ามีการเว้นวรรค"
            titleSize="16px"
            maxWidth="xs"
          >
            <Typography fontWeight="bold">
              รายการที่ไม่สามารถสร้างได้:
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
            >
              {whiteSpaceError.ids.map((id) => (
                <ListItem
                  key={id}
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography
                    sx={{
                      display: "inline",
                      color: (theme) => theme.palette.warning.main,
                    }}
                  >
                    {id}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </ModalUI>
        );
      default:
    }
    return <></>;
  };

  const renderErrorTab = (
    <>
      {idsWithWhitespace?.length > 0 && (
        <ImportErrorTable
          sx={{ mt: 2, mb: 2 }}
          data={idsWithWhitespace}
          keyName={"item-whitespace-row"}
          title={"รหัสสินค้า"}
          errorDescription={"รหัสสินค้ามีการเว้นวรรค"}
          columnName={"document_id"}
          documentType={"inventory"}
        />
      )}
      {missingUoms?.length > 0 && (
        <ImportErrorTable
          sx={{ mt: 2, mb: 2 }}
          data={missingUoms}
          keyName={"item-uom-row"}
          title={"หน่วย"}
          errorDescription={"หน่วยที่ไม่มีในระบบ"}
          columnName={"uom"}
          documentType={"inventory"}
        />
      )}
      {missingAttributes?.length > 0 && (
        <ImportErrorTable
          sx={{ mt: 2, mb: 2 }}
          data={missingAttributes}
          keyName={"item-attribute-row"}
          title={"คุณสมบัติ"}
          errorDescription={"คุณสมบัติที่ไม่มีในระบบ"}
          columnName={"attribute"}
          documentType={"inventory"}
        />
      )}
      {missingCategories?.length > 0 && (
        <ImportErrorTable
          sx={{ mt: 2, mb: 2 }}
          data={missingCategories}
          keyName={"item-category-row"}
          title={"หมวดหมู่"}
          errorDescription={"หมวดหมู่ที่ไม่มีในระบบ"}
          columnName={"category"}
          documentType={"inventory"}
        />
      )}
    </>
  );

  const currentTab = tab ? pathname + `?tab=${tab}` : pathname;

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้าสินค้า"}</Typography>
      </Box>
      <Box
        sx={{ mt: 1, mb: 2, display: "flex", justifyContent: "space-between" }}
      >
        <CustomizedButton
          title="ดาวน์โหลด Template"
          variant="outlined"
          onClick={() =>
            window.open("/static/item_import_template.xlsx", "_blank")
          }
        />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <ImporterDropzoneUI
            keys={keys}
            setImportedDoc={setFormattedData}
            dataFormatter={formatItemdata}
            setLoading={setIsLoading}
            setErrors={setCreateErrors}
            setHasError={setHasCreateError}
            hasLabelHeader
          />
        </Grid>
      </Grid>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Controller
              name={"is_upsert"}
              control={control}
              render={({ field }) => (
                <CustomizedCheckboxes label="อัปเดตรายการปัจจุบัน" {...field} />
              )}
            />
            <CustomizedTooltip
              title={
                "กรณีที่มีรหัสสินค้าเดิมอยู่แล้ว จะเป็นการเลือกเพื่ออัปเดตข้อมูลใหม่ในรายการสินค้าเดิม"
              }
              noMaxWidth
            >
              <ErrorOutlineOutlinedIcon sx={{ fontSize: "14px" }} />
            </CustomizedTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomizedButton
              title="นำเข้า"
              variant="contained"
              onClick={importFormattedDocuments}
              disabled={
                formattedData.length === 0 ||
                isLoading ||
                (!watchIsUpsert && formattedCreateOnlyData.length === 0)
              }
            />
          </Box>
        </Grid>
      </Grid>

      {isLoading && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {formattedData.length > 0 && (
        <Box>
          <Box sx={{ maxWidth: "100%", overflow: "scroll", mt: 4 }}>
            <CustomizedTab
              tabs={tabs}
              currentTab={currentTab}
              scrollable
              table
            />
          </Box>
          {!currentTab.includes("error") && (
            <Box sx={{ mt: 2 }}>
              <AgGrid
                ref={gridRef}
                columnDefs={columnDefs}
                height={649}
                rowData={displayedData}
                groupDefaultExpanded={1}
              />
            </Box>
          )}
        </Box>
      )}
      {errorCount > 0 && currentTab.includes("error") && renderErrorTab}
      {hasCreateError && renderCreateErrors()}
    </>
  );
};

export default ItemsImporterPage;
