import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";

const ManufactureSetting = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.manufacture.index") },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6} md={3}>
          <CustomizedMenuBox
            isGridItem
            title={t("setting.manufacture.workCenter")}
            linkTo={`${pathname}/work-center`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ManufactureSetting;
