import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/dataTransformer";
import AgGrid from "../AgGrid";

const PurchaseByItemTable = ({ gridRef, rowData }) => {
  const { t } = useTranslation();

  const columnDefs = [
    {
      field: "item_document_id",
      headerName: t("sales.report.itemId"),
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      field: "item_name",
      headerName: t("sales.report.itemName"),
      filter: "agTextColumnFilter",
    },
    {
      field: "sum_qty",
      headerName: t("sales.report.qty"),
      filter: false,
      valueGetter: (params) => formatNumber(params.data.sum_qty),
    },
    {
      field: "uom",
      headerName: t("sales.report.uom"),
      filter: false,
    },
    {
      field: "sum_pre_vat_amount_exclude_discount",
      headerName: t("sales.report.preVatAmountExcludeDiscount"),
      filter: false,
      valueGetter: (params) =>
        formatNumber(params.data.sum_pre_vat_amount_exclude_discount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_item_discount",
      headerName: t("reports.item_discount"),
      filter: false,
      valueGetter: (params) => formatNumber(params.data.sum_item_discount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_item_additional_discount",
      headerName: t("sales.report.itemAdditionalDiscount"),
      filter: false,
      valueGetter: (params) =>
        formatNumber(params.data.sum_item_additional_discount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_pre_vat_amount_include_discount",
      headerName: t("reports.pre_vat_amount"),
      filter: false,
      valueGetter: (params) =>
        formatNumber(params.data.sum_pre_vat_amount_include_discount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_item_vat_amount",
      headerName: t("sales.report.vatAmount"),
      filter: false,
      valueGetter: (params) => formatNumber(params.data.sum_item_vat_amount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_item_net_amount",
      headerName: t("sales.report.netAmount"),
      filter: false,
      valueGetter: (params) => formatNumber(params.data.sum_item_net_amount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_item_withholding_tax",
      headerName: t("sales.report.withholdingTax"),
      filter: false,
      valueGetter: (params) =>
        formatNumber(params.data.sum_item_withholding_tax),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_item_total_amount",
      headerName: t("sales.report.totalAmount"),
      filter: false,
      valueGetter: (params) => formatNumber(params.data.sum_item_total_amount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "sum_item_returned_qty",
      headerName: t("sales.report.returnedQty"),
      filter: false,
      valueGetter: (params) => formatNumber(params.data.sum_item_returned_qty),
    },
  ];

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      rowData={rowData}
    />
  );
};

export default PurchaseByItemTable;
