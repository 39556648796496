import React, { forwardRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomizedTextField from "./CustomizedTextField";
import { DateTimePicker } from "@mui/x-date-pickers";

const CustomizedDateTime = forwardRef(
  ({ name, disabled, label, value, disabledPast, onChange }, ref) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          ref={ref}
          name={name}
          disabled={disabled}
          label={label}
          inputFormat="dd/MM/yyyy HH:mm"
          value={value}
          disablePast={disabledPast}
          onChange={onChange}
          renderInput={(params) => (
            <CustomizedTextField fullWidth {...params} ref={params.inputRef} />
          )}
        />
      </LocalizationProvider>
    );
  }
);

export default CustomizedDateTime;
