import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import Calendar from "../../../components/UI/Calendar";
import { getAllManufactureOrdersCalendarView } from "../../../features/Manufacture/ManufactureOrder/manufacture-order-actions";
import { manufactureOrderActions } from "../../../features/Manufacture/ManufactureOrder/manufacture-order-slice";
import { useEmployeeOptions } from "../../../hooks/ManufactureOrder/Calendar/use-employee-options";
import { useGroupOptions } from "../../../hooks/ManufactureOrder/Calendar/use-group-options";
import { useItemOptions } from "../../../hooks/ManufactureOrder/Calendar/use-item-options";
import { useTypeOptions } from "../../../hooks/ManufactureOrder/Calendar/use-type-options";

const statusOptions = [
  {
    id: 1,
    label: "ร่าง",
    value: "draft",
  },
  {
    id: 2,
    label: "รอผลิต",
    value: "pendingManu",
  },
  {
    id: 3,
    label: "กำลังผลิต",
    value: "inProgress",
  },
  {
    id: 4,
    label: "เสร็จสิ้น",
    value: "finished",
  },
  {
    id: 5,
    label: "ยกเลิก",
    value: "cancelled",
  },
];

const ManufactureOrderCalendar = () => {
  const dispatch = useDispatch();

  const { allManufactureOrdersCalendar } = useSelector(
    (state) => state.manufactureOrder
  );

  const { itemLoading, itemOptions, onOpenItem, onCloseItem } =
    useItemOptions();
  const { typeLoading, typeOptions, onOpenType, onCloseType } =
    useTypeOptions();
  const { groupLoading, groupOptions, onOpenGroup, onCloseGroup } =
    useGroupOptions();
  const { employeeLoading, employeeOptions, onOpenEmployee, onCloseEmployee } =
    useEmployeeOptions();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { control } = useForm({
    defaultValues: {
      item_document_id: null,
      type: null,
      group: null,
      employee_document_id: null,
      status: null,
    },
  });

  const itemDocumentId = useWatch({
    control,
    name: "item_document_id",
  });

  const type = useWatch({
    control,
    name: "type",
  });

  const group = useWatch({
    control,
    name: "group",
  });

  const employeeDocumentId = useWatch({
    control,
    name: "employee_document_id",
  });

  const status = useWatch({
    control,
    name: "status",
  });

  useEffect(() => {
    dispatch(
      getAllManufactureOrdersCalendarView({
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          expect_product_date:
            startDate && endDate
              ? {
                  filter: startDate,
                  filterTo: endDate,
                  filterType: "number",
                  type: "inRange",
                }
              : undefined,
          item_document_id: itemDocumentId
            ? {
                filterType: "text",
                type: "contains",
                filter: itemDocumentId.value,
              }
            : undefined,
          type: type
            ? {
                filterType: "text",
                type: "contains",
                filter: type.value,
              }
            : undefined,
          group: group
            ? {
                filterType: "objectArray",
                type: "some",
                filter: {
                  name: {
                    filterType: "set",
                    values: [group.value],
                  },
                },
              }
            : undefined,
          employee_list: employeeDocumentId
            ? {
                filterType: "objectArray",
                type: "some",
                filter: {
                  document_id: {
                    filterType: "text",
                    type: "contains",
                    filter: employeeDocumentId.value,
                  },
                },
              }
            : undefined,
          render_status: status
            ? {
                values: [status.value],
                filterType: "set",
              }
            : undefined,
        },
        sortModel: [],
      })
    );
    return () => {
      dispatch(manufactureOrderActions.resetAllManufactureOrdersCalendar());
    };
  }, [
    dispatch,
    employeeDocumentId,
    endDate,
    group,
    itemDocumentId,
    startDate,
    status,
    type,
  ]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <Controller
            control={control}
            name="item_document_id"
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={itemLoading}
                onOpen={onOpenItem}
                onClose={onCloseItem}
                label="รหัสสินค้า - ชื่อสินค้า"
                // ListboxProps={{
                //   role: "list-box",
                //   onScroll: (event) => {
                //     const listboxNode = event.currentTarget;
                //     if (
                //       listboxNode.scrollTop + listboxNode.clientHeight ===
                //       listboxNode.scrollHeight
                //     ) {
                //       const nextPage = itemPage + 10;
                //       setItemPage(nextPage);
                //     }
                //   },
                // }}
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    dispatch(
                      manufactureOrderActions.resetAllManufactureOrdersCalendar()
                    );
                    field.onChange(null);
                  }
                }}
                options={itemOptions || []}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={typeLoading}
                label="ประเภทการผลิต"
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    dispatch(
                      manufactureOrderActions.resetAllManufactureOrdersCalendar()
                    );
                    field.onChange(null);
                  }
                }}
                options={typeOptions || []}
                onOpen={onOpenType}
                onClose={onCloseType}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <Controller
            control={control}
            name="group"
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={groupLoading}
                label="การจัดกลุ่ม"
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    dispatch(
                      manufactureOrderActions.resetAllManufactureOrdersCalendar()
                    );
                    field.onChange(null);
                  }
                }}
                options={groupOptions || []}
                onOpen={onOpenGroup}
                onClose={onCloseGroup}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <Controller
            control={control}
            name="employee_document_id"
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={employeeLoading}
                label="ผู้เกี่ยวข้อง"
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    dispatch(
                      manufactureOrderActions.resetAllManufactureOrdersCalendar()
                    );
                    field.onChange(null);
                  }
                }}
                options={employeeOptions || []}
                onOpen={onOpenEmployee}
                onClose={onCloseEmployee}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                label="สถานะ"
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    dispatch(
                      manufactureOrderActions.resetAllManufactureOrdersCalendar()
                    );
                    field.onChange(null);
                  }
                }}
                options={statusOptions}
              />
            )}
          />
        </Grid>
      </Grid>
      <Calendar
        events={allManufactureOrdersCalendar}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </Box>
  );
};

export default ManufactureOrderCalendar;
