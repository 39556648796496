import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import CustomizedAvatar from "./CustomizedAvatar";
import { CustomizedTooltip } from "./CustomizedTooltip";

const CustomizedMuliChip = ({ approval }) => {
  const mapStatusStyle = (status) => {
    switch (status) {
      case "PENDING":
        return {
          color: "#C3762E",
          label: "รออนุมัติ",
        };
      case "APPROVED":
        return {
          color: "#328F35",
          label: "อนุมัติแล้ว",
        };
      case "DECLINED":
        return {
          color: "#F44336",
          label: "ไม่อนุมัติ",
        };
      default:
        return;
    }
  };
  return (
    <CustomizedTooltip
      title={
        <Box sx={{ px: 1 }}>
          {approval.reviewer_list.map((reviewer, index) => (
            <Box key={index}>
              <Typography
                sx={{ color: "#333333" }}
              >{`${reviewer.first_name} ${reviewer.last_name}`}</Typography>
              <Typography sx={{ color: "#333333" }}>
                {reviewer.email}
              </Typography>
              {index !== approval.reviewer_list.length - 1 && (
                <Divider sx={{ my: 0.5 }} />
              )}
            </Box>
          ))}
          {approval.approval_status && (
            <Typography
              sx={{
                mt: 0.5,
                color: mapStatusStyle(approval.approval_status).color,
                fontWeight: "bold",
              }}
            >
              {mapStatusStyle(approval.approval_status).label}
            </Typography>
          )}
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
          cursor: "pointer",
          p: 1,
          border: 1,
          borderRadius: 3,
          borderColor:
            approval.approval_status === "PENDING"
              ? "rgba(195, 118, 46, 0.6)"
              : approval.approval_status === "APPROVED"
              ? "rgba(50, 143, 53, 0.6)"
              : approval.approval_status === "DECLINED"
              ? "rgba(181, 72, 57, 0.6)"
              : "#C0C0C0",
          bgcolor:
            approval.approval_status === "PENDING"
              ? "rgba(255, 241, 197, 0.4)"
              : approval.approval_status === "APPROVED"
              ? "rgba(199, 230, 199, 0.2)"
              : approval.approval_status === "DECLINED"
              ? "rgba(254, 236, 235, 0.4)"
              : "white",
        }}
      >
        {approval.reviewer_list.map((reviewer, index) => (
          <Box
            sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
            key={index}
          >
            <CustomizedAvatar
              avatars={[reviewer]}
              sx={{ width: 24, height: 24, fontSize: "14px" }}
              noTooltip
            />
            <Typography>{`${reviewer.first_name}`}</Typography>
          </Box>
        ))}
      </Box>
    </CustomizedTooltip>
  );
};

export default CustomizedMuliChip;
