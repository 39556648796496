import { useState, useEffect } from "react";
import { Box, Grid, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import CustomizedTextField from "../Custom/CustomizedTextField";
import CustomizedButton from "../Custom/CustomizedButton";
import ModalUI from "../UI/ModalUI";
import GlobalService from "../../services/Global";

const filter = createFilterOptions();

const ControlledServerSideCreatable = ({
  control,
  sx,
  name,
  setValue,
  error,
  defaultOptions,
  onInputChange,
  onKeyDown,
  onChange,
  helperText,
  title,
  placeholder,
  viewOnly,
  required,
  documentType,
}) => {
  const [isInit, setIsInit] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionToAdd, setOptionToAdd] = useState("");
  const [optionToDelete, setOptionToDelete] = useState("");
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  useEffect(() => {
    if (!isInit) {
      const getOptions = async () => {
        const loadedOptions = await GlobalService.getCreatables({
          usage_field_type: documentType,
          usage_field_name: name,
        });
        setOptions(
          loadedOptions.map((option) => {
            return {
              value: option.name,
              label: option.name,
              isDeletable: true,
            };
          })
        );
      };
      getOptions();
      setIsInit(true);
    }
  }, [documentType, isInit, name]);

  const cancelAddHandler = () => {
    setAddModalIsOpen(false);
    setValue(name, "");
  };

  const addOptionHandler = async () => {
    setValue(name, optionToAdd);
    setOptions((prevOptions) => [
      ...prevOptions,
      { value: optionToAdd, label: optionToAdd, isDeletable: true },
    ]);
    try {
      await GlobalService.createCreatable({
        usage_field_type: documentType,
        usage_field_name: name,
        name: optionToAdd,
      });
    } catch (err) {
      console.error(err);
    }
    setOptionToAdd("");
    setAddModalIsOpen(false);
  };

  const cancelDeleteHandler = () => {
    setDeleteModalIsOpen(false);
    setValue(name, "");
  };

  const deleteOptionHandler = async () => {
    try {
      await GlobalService.deleteCreatable({
        name_usage_field_type_usage_field_name: {
          usage_field_type: documentType,
          usage_field_name: name,
          name: optionToDelete,
        },
      });
    } catch (err) {
      console.error(err);
    }
    setOptions((prevOptions) =>
      prevOptions.filter((option) => option.value !== optionToDelete)
    );
    setValue(name, "");
    setOptionToDelete("");
    setDeleteModalIsOpen(false);
  };

  const openDeleteModalHandler = (option) => {
    setOptionToDelete(option);
    setDeleteModalIsOpen(true);
  };

  const combinedOptions = [...defaultOptions, ...options];

  return (
    <>
      <Controller
        key={`${name}-creatable`}
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            fullWidth
            {...field}
            onChange={
              onChange
                ? (e, option) => {
                    if (option?.label?.includes("เพิ่ม ")) {
                      setOptionToAdd(option.inputValue);
                      setAddModalIsOpen(true);
                    }
                    onChange(e, option, field);
                  }
                : (e, option) => {
                    if (option?.label?.includes("เพิ่ม ")) {
                      setOptionToAdd(option.inputValue);
                      setAddModalIsOpen(true);
                    }
                    if (!option) {
                      return field.onChange("");
                    }
                    if (option.value) {
                      return field.onChange(option.value);
                    }
                    if (option.inputValue) {
                      return field.onChange(option.inputValue);
                    }
                    return field.onChange(option);
                  }
            }
            disabled={viewOnly}
            onInputChange={onInputChange}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.label
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `เพิ่ม "${inputValue}"`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={combinedOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.value || option.type;
            }}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  value={option.value ?? option}
                  key={option.label ?? option}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {option.label ?? option}
                  {!option?.label?.includes("เพิ่ม ") && option.isDeletable && (
                    <IconButton
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                      onClick={() => {
                        openDeleteModalHandler(option.value ?? option);
                      }}
                    >
                      <CloseIcon
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                      />
                    </IconButton>
                  )}
                </li>
              );
            }}
            sx={sx}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label={title}
                size="small"
                name={name}
                error={error}
                onKeyDown={onKeyDown}
                helperText={helperText}
                required={required}
                disabled={viewOnly}
                placeholder={placeholder}
              />
            )}
            readOnly={viewOnly}
          />
        )}
      />
      <ModalUI
        title="เพิ่มตัวเลือก"
        open={addModalIsOpen}
        handleClose={cancelAddHandler}
        maxWidth="sm"
      >
        <Grid container spacing={2} mt={0.5}>
          <Grid item xs={12} md={6}>
            <CustomizedTextField
              label={title}
              fullWidth
              onChange={(e) => {
                setOptionToAdd(e.target.value);
              }}
              value={optionToAdd}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <CustomizedButton
            title={"ยกเลิก"}
            variant="outlined"
            size="medium"
            onClick={cancelAddHandler}
          />
          <CustomizedButton
            sx={{ ml: 1 }}
            title={"เพิ่ม"}
            variant="contained"
            size="medium"
            onClick={addOptionHandler}
          />
        </Box>
      </ModalUI>
      <ModalUI
        open={deleteModalIsOpen}
        title={`ยืนยันการลบตัวเลือก “${optionToDelete}”`}
        handleClose={cancelDeleteHandler}
        maxWidth="sm"
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <CustomizedButton
            title={"ยกเลิก"}
            variant="outlined"
            size="medium"
            onClick={cancelDeleteHandler}
          />
          <CustomizedButton
            sx={{ ml: 1 }}
            title={"ยืนยัน"}
            variant="contained"
            size="medium"
            onClick={deleteOptionHandler}
          />
        </Box>
      </ModalUI>
    </>
  );
};

export default ControlledServerSideCreatable;
