import PurchaseService from "../../../services/Purchase";
import { purchaseReturnActions } from "./purchase-return-slice";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import {
  createActivityLogApprovePayload,
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  purchaseExportFormatter,
  purchaseQueryFormatter,
} from "../../../utils/purchasePayloadFormatter";
import GlobalService from "../../../services/Global";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import InventoryService from "../../../services/Inventory";

export const getAllPurchaseReturns =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("allPurchaseReturns"));
    try {
      const allPurchaseReturn = await PurchaseService.getAllPurchaseReturns(
        input
      );
      params.successCallback(
        allPurchaseReturn.results,
        allPurchaseReturn.count
      );
      dispatch(
        purchaseReturnActions.loadedAllPurchaseReturns(
          allPurchaseReturn.results
        )
      );
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "allPurchaseReturns",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllPurchaseReturnsExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("allPurchaseReturns"));
    try {
      const { results } = await PurchaseService.getAllPurchaseReturns(input);
      let purchaseReturns = purchaseExportFormatter(results, "purchase_return");
      dispatch(
        purchaseReturnActions.loadedAllPurchaseReturnsExport(purchaseReturns)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "allPurchaseReturns",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllPurchaseReturnsExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("allPurchaseReturnsExport"));
    try {
      const { results } = await PurchaseService.getAllPurchaseReturnsReport(
        input
      );
      let purchaseReturns = purchaseExportFormatter(results, "purchase_return");
      dispatch(
        purchaseReturnActions.loadedAllPurchaseReturnsExport(purchaseReturns)
      );
    } catch (err) {
      console.log(err);
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "allPurchaseReturnsExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getGoodIssueTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("purchaseReturn"));
    try {
      const { results } = await InventoryService.getAllGIAggrid({
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          reference_document_id: {
            filter: documentId,
            filterType: "text",
            type: "equals",
          },
        },
        sortModel: [],
      });

      const formatResults = results.map((goodsIssue) => ({
        ...goodsIssue,
        created_date: unixToDateWithFormat(goodsIssue.created_date),
        document_date: unixToDateWithFormat(goodsIssue.document_date),
      }));

      dispatch(purchaseReturnActions.loadedGoodIssueTab(formatResults));
    } catch (err) {
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "purchaseReturn",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewPurchaseReturn =
  (payload, isSendApprove, state, user, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("purchaseReturn"));
    try {
      const { creator_document_id, ...otherPayload } = payload;
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const result = await PurchaseService.createPurchaseReturn(otherPayload);
      const createActivityLog = createActivityLogPayload(
        activityLogPayload,
        "purchase_return",
        "สร้างใบส่งคืน"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });

      if (payload.reference_document_id) {
        const createRefActivityLog = createActivityLogPayload(
          {
            ...activityLogPayload,
            document_id: payload.reference_document_id,
          },
          "purchase_order",
          "สร้างใบส่งคืน"
        );
        // const finishedActivityLog = createActivityLogStatusPayload(
        //   {
        //     ...payload,
        //     document_id: payload.reference_document_id,
        //   },
        //   "purchase_order",
        //   "accepted",
        //   "finished"
        // );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createRefActivityLog,
        });
        // await ActivityLogsService.createActivityLogs({
        //   createActivityLogInput: finishedActivityLog,
        // });
      }

      if (state && state?.isCopied) {
        const activityLogPayload = {
          document_id: state.document_id,
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogPayload,
          "purchase_return"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }

      if (isSendApprove) {
        const documentInput = {
          document_type: "purchase_return",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "purchase_return",
          null,
          "wait_approve"
        );
        await PurchaseService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
          variant: "success",
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "purchase_return",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(`/purchase/return/${encodeURIComponent(result.document_id)}`);
    } catch (err) {
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "purchaseReturn",
        })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบส่งคืนไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getPurchaseReturnById =
  (uniqueInput, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("purchaseReturn"));
    try {
      const purchaseReturn = await PurchaseService.getPurchaseReturnById(
        uniqueInput
      );
      const formatPurchaseReturn = await purchaseQueryFormatter(
        purchaseReturn,
        "purchase_return"
      );

      if (
        formatPurchaseReturn.reference_document_id &&
        formatPurchaseReturn.reference_document_id.length > 0
      ) {
        const purchaseOrder = await PurchaseService.getPurchaseOrderById({
          document_id:
            formatPurchaseReturn.reference_document_id[0].reference_document_id,
        });
        const formatPurchaseOrder = await purchaseQueryFormatter(
          purchaseOrder,
          "purchase_order"
        );
        dispatch(
          purchaseReturnActions.loadedPurchaseOrderItem(
            formatPurchaseOrder.item_list
          )
        );
      }

      const approvalTemplates =
        await PurchaseService.getApprovalTemplatesByDocumentType(
          "purchase_return"
        );

      const approvalProgress = await PurchaseService.getApprovalProgress({
        reference_document_type: "purchase_return",
        reference_document_id: uniqueInput.document_id,
      });

      dispatch(
        purchaseReturnActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress,
        })
      );

      dispatch(
        purchaseReturnActions.updateApprovalStatus({
          approval_list: approvalProgress,
          user,
        })
      );

      dispatch(
        purchaseReturnActions.loadedPurchaseReturn(formatPurchaseReturn)
      );
    } catch (err) {
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "purchaseReturn",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const updatePurchaseReturn =
  (payload, status, isChangedStatus, existing, user, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("purchaseReturn"));
    try {
      const { document_id, creator_document_id, ...otherPayload } = payload;
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };

      const purchaseReturn = await PurchaseService.updatePurchaseReturn(
        { document_id },
        otherPayload
      );
      const formatPurchaseReturn = await purchaseQueryFormatter(
        purchaseReturn,
        "purchase_return"
      );
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "purchase_return",
        };
        if (status === "notApproved") {
          const sendToApproveActivityLog = createActivityLogStatusPayload(
            activityLogPayload,
            "purchase_return",
            "not_approved",
            "wait_approve"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToApproveActivityLog,
          });
          await GlobalService.setDocumentStep({
            document_id: payload.document_id,
            document_type: "purchase_return",
            step: 1,
          });
          await PurchaseService.postNextStatus(documentInput);
          enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
            variant: "success",
          });
          dispatch(
            purchaseReturnActions.loadedPurchaseReturn({
              ...formatPurchaseReturn,
              render_status: "waitApprove",
            })
          );
        } else {
          const updatedPurchaseReturn = await PurchaseService.postNextStatus(
            documentInput
          );

          if (
            updatedPurchaseReturn?.approval_progress_list
              ?.map((approval) => approval?.approval_status)
              .includes("PENDING")
          ) {
            dispatch(
              purchaseReturnActions.updateApprovalStatus({
                approval_list: updatedPurchaseReturn.approval_progress_list,
                user,
              })
            );
            const sendToApproveActivityLog = createActivityLogStatusPayload(
              activityLogPayload,
              "purchase_return",
              "draft",
              "wait_approve"
            );
            dispatch(
              purchaseReturnActions.loadedPurchaseReturn({
                ...formatPurchaseReturn,
                render_status: "waitApprove",
              })
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: sendToApproveActivityLog,
            });
            enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("อัพเดทใบส่งคืนสำเร็จ", {
              variant: "success",
            });

            dispatch(
              purchaseReturnActions.loadedPurchaseReturn({
                ...formatPurchaseReturn,
                render_status: updatedPurchaseReturn.status,
              })
            );
          }
        }
      } else {
        if (status === "notApproved") {
          const setDocumentStep = await GlobalService.setDocumentStep({
            document_id: payload.document_id,
            document_type: "purchase_return",
            step: 1,
          });

          const sendToDraftActivityLog = createActivityLogStatusPayload(
            activityLogPayload,
            "purchase_return",
            "not_approved",
            "draft"
          );

          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToDraftActivityLog,
          });

          dispatch(
            purchaseReturnActions.loadedPurchaseReturn({
              ...formatPurchaseReturn,
              render_status: setDocumentStep.status,
            })
          );
          enqueueSnackbar("บันทึกร่างสำเร็จ", {
            variant: "success",
          });
        } else {
          if (status === "approved") {
            const createActivityLog = createActivityLogPayload(
              activityLogPayload,
              "purchase_return",
              "บันทึกใบเพิ่มหนี้"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: createActivityLog,
            });

            const sendToApproveActivityLog = createActivityLogStatusPayload(
              activityLogPayload,
              "purchase_return",
              "approved",
              "finished"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: sendToApproveActivityLog,
            });
          }

          const editActivityLog = createActivityLogEditPayload(
            activityLogPayload,
            "purchase_return"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: editActivityLog,
          });
          const { addedEmployeeList, deletedEmployeeList } =
            findDifferenceEmployee(payload, existing);
          if (addedEmployeeList && addedEmployeeList.length > 0) {
            // add related employee
            const addedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "add_related_employee",
              addedEmployeeList,
              "purchase_return"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: addedEmployeeListLog,
            });
          }
          if (deletedEmployeeList && deletedEmployeeList.length > 0) {
            // delete related employee
            const deletedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "delete_related_employee",
              deletedEmployeeList,
              "purchase_return"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: deletedEmployeeListLog,
            });
          }
          switch (status) {
            case "draft":
              dispatch(
                purchaseReturnActions.loadedPurchaseReturn(formatPurchaseReturn)
              );
              enqueueSnackbar("บันทึกร่างสำเร็จ", {
                variant: "success",
              });
              break;
            case "approved":
              dispatch(
                purchaseReturnActions.loadedPurchaseReturn({
                  ...formatPurchaseReturn,
                  render_status: "finished",
                })
              );
              enqueueSnackbar("บันทึกใบเพิ่มหนี้", {
                variant: "success",
              });
              break;
            default:
              dispatch(
                purchaseReturnActions.loadedPurchaseReturn({
                  ...formatPurchaseReturn,
                  render_status: status,
                })
              );
              enqueueSnackbar("แก้ไขใบส่งคืนสำเร็จ", {
                variant: "success",
              });
          }
        }
      }
    } catch (err) {
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "purchaseReturn",
        })
      );
      switch (status) {
        case "draft":
          enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
            variant: "error",
          });
          break;
        default:
          enqueueSnackbar("อัพเดทใบส่งคืนไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const approvePurchaseReturn =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("purchaseReturn"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };

      const approvalTemplates =
        await PurchaseService.getApprovalTemplatesByDocumentType(
          "purchase_return"
        );

      const { approval_progress_list } =
        await PurchaseService.postApproveStatus(documentInput);

      dispatch(
        purchaseReturnActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      const sortedApprovalProgress = approval_progress_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      if (
        sortedApprovalProgress
          ?.map((approval) => approval?.approval_status)
          .includes("PENDING")
      ) {
        dispatch(
          purchaseReturnActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        dispatch(
          purchaseReturnActions.updatePurchaseReturnStatus("waitApprove")
        );
        const approvedActivityLog = createActivityLogApprovePayload(
          activityLogPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "purchase_return"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      } else {
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        const approvedNextActivityLog = createActivityLogApprovePayload(
          activityLogPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "purchase_return"
        );

        dispatch(
          purchaseReturnActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        dispatch(purchaseReturnActions.updatePurchaseReturnStatus("approved"));
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedNextActivityLog,
        });
        const approvedActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "purchase_return",
          "wait_approve",
          "approved"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      }
      enqueueSnackbar("อนุมัติสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "purchaseReturn",
        })
      );
      enqueueSnackbar("อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const declinePurchaseReturn =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("purchaseReturn"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const notApprovedActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "purchase_return",
        "wait_approve",
        "not_approved"
      );
      const { approval_progress_list } =
        await PurchaseService.postDeclineStatus(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: notApprovedActivityLog,
      });
      enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
        variant: "success",
      });

      const approvalTemplates =
        await PurchaseService.getApprovalTemplatesByDocumentType(
          "purchase_return"
        );

      dispatch(
        purchaseReturnActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      dispatch(
        purchaseReturnActions.updateApprovalStatus({
          approval_list: approval_progress_list,
          user,
        })
      );

      dispatch(purchaseReturnActions.updatePurchaseReturnStatus("notApproved"));
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "purchaseReturn",
        })
      );
      enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const cancelPurchaseReturn =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReturnActions.onLoading("purchaseReturn"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const cancelActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "purchase_return",
        null,
        "cancelled"
      );
      await PurchaseService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(purchaseReturnActions.updatePurchaseReturnStatus("cancelled"));
    } catch (err) {
      console.error("error", err);
      dispatch(
        purchaseReturnActions.rejectedActions({
          ...err,
          name: "purchaseReturn",
        })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
