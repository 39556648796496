import InventoryService from "../../../services/Inventory";
import { goodsReceiveActions } from "./goodsReceive-slice";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogEditPayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
} from "../../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import { createLog } from "../../../services/Logs/firebase";

const checkRefFromType = (type) => {
  switch (type) {
    case "ซื้อ":
      return "purchase_order";
    case "ผลิต":
    case "แปรรูป":
      return "manufacture_order";
    case "รับคืนจากลูกค้า":
      return "sales_return";
    default:
      break;
  }
};

export const getAllGoodsReceive =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllGRAggrid(input);
      dispatch(goodsReceiveActions.loadedAllItems(allItems.results));
      dispatch(goodsReceiveActions.loadedAllCount(allItems.count));
      if (params) params.successCallback(allItems.results, allItems.count);
    } catch (err) {
      if (params) params.failCallback();
      dispatch(
        goodsReceiveActions.rejectedActions({ ...err, name: "allItems" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllGoodsReceiveNoItem =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllGRAggridNoItem(input);
      dispatch(goodsReceiveActions.loadedAllItems(allItems.results));
      dispatch(goodsReceiveActions.loadedAllCount(allItems.count));
      if (params) params.successCallback(allItems.results, allItems.count);
    } catch (err) {
      if (params) params.failCallback();
      dispatch(
        goodsReceiveActions.rejectedActions({ ...err, name: "allItems" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllGoodsReceiveReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("allItemsExport"));
    try {
      const allItems = await InventoryService.getAllGRReportAggrid(input);
      dispatch(goodsReceiveActions.loadedAllItems(allItems.results));
      dispatch(goodsReceiveActions.loadedAllCount(allItems.count));
      dispatch(goodsReceiveActions.onLoaded("allItemsExport"));
      if (params) params.successCallback(allItems.results, allItems.count);
    } catch (err) {
      if (params) params.failCallback();
      dispatch(
        goodsReceiveActions.rejectedActions({ ...err, name: "allItemsExport" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getGoodsReceiveById =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("item"));
    try {
      const Item = await InventoryService.getGR(input);
      dispatch(goodsReceiveActions.loadedItem(Item));
    } catch (err) {
      dispatch(goodsReceiveActions.rejectedActions({ ...err, name: "item" }));
    }
  };

export const createGoodsReceive =
  (input, enqueueSnackbar, navigate, user, location) => async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("item"));
    try {
      const item = await InventoryService.createGR(input);
      dispatch(goodsReceiveActions.loadedItem(item));

      const createActivityLog = createActivityLogPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_receive",
        "สร้างใบนำเข้า"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      if (item.reference_document_id) {
        const createActivityLog = createActivityLogPayload(
          {
            document_id: item.reference_document_id,
            creator_document_id: user.document_id,
          },
          checkRefFromType(item.type),
          "สร้างใบนำเข้า"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createActivityLog,
        });
      }
      if (navigate)
        navigate("/inventory/receive/" + encodeURIComponent(item.document_id));
      if (enqueueSnackbar)
        enqueueSnackbar("สร้างเอกสารสำเร็จ", {
          variant: "success",
        });
      createLog(
        "info",
        "GoodsReceiveCreate",
        location,
        user?.email,
        input,
        item
      );
    } catch (err) {
      dispatch(goodsReceiveActions.rejectedActions({ ...err, name: "item" }));
      if (enqueueSnackbar)
        err.response.errors.forEach((error) => {
          if (errorMessageHandler(error.message)) {
            enqueueSnackbar(
              errorMessageHandler(
                error.message,
                "รหัสเอกสารนี้มีในระบบแล้ว กรุณาเรียกรหัสเอกสารใหม่"
              ),
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar("สร้างเอกสารนำเข้าไม่สำเร็จ", {
              variant: "error",
            });
          }
        });
      createLog(
        "error",
        "GoodsReceiveCreate",
        location,
        user?.email,
        input,
        err
      );
    }
  };

export const createGoodsReceiveWithApprove =
  (input, enqueueSnackbar, navigate, user, receive, location) =>
  async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("item"));
    try {
      const Item = await InventoryService.createGRWithApprove(input);
      dispatch(goodsReceiveActions.loadedItem(Item));

      const createActivityLog = createActivityLogPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_receive",
        "สร้างใบนำเข้า"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      if (enqueueSnackbar)
        enqueueSnackbar("สร้างเอกสารสำเร็จ", {
          variant: "success",
        });
      if (navigate)
        navigate(
          "/inventory/" +
            (receive ? "receive/" : "return/") +
            encodeURIComponent(Item.document_id)
        );
      createLog(
        "info",
        "goodsReceiveCreateWithApprove",
        location,
        user?.email,
        input,
        Item
      );
    } catch (err) {
      dispatch(goodsReceiveActions.rejectedActions({ ...err, name: "item" }));
      if (enqueueSnackbar)
        err.response.errors.forEach((error) => {
          if (errorMessageHandler(error.message)) {
            enqueueSnackbar(
              errorMessageHandler(
                error.message,
                "รหัสเอกสารนี้มีในระบบแล้ว กรุณาเรียกรหัสเอกสารใหม่"
              ),
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar("สร้างเอกสารนำเข้าไม่สำเร็จ", {
              variant: "error",
            });
          }
        });
      createLog(
        "error",
        "goodsReceiveCreateWithApprove",
        location,
        user?.email,
        input,
        err
      );
    }
  };

export const updateGoodsReceive =
  (input, enqueueSnackbar, user, location) => async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("item"));
    try {
      const Item = await InventoryService.updateGR(input);
      dispatch(goodsReceiveActions.loadedItem(Item));
      const editActivityLog = createActivityLogEditPayload(
        {
          ...input.uniqueInput,
          ...input.updateInput,
          creator_document_id: user.document_id,
        },
        "goods_receive"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: editActivityLog,
      });
      if (enqueueSnackbar)
        enqueueSnackbar("แก้ไขเอกสารสำเร็จ", {
          variant: "success",
        });
      createLog(
        "info",
        "goodsReceiveUpdate",
        location,
        user?.email,
        input,
        Item
      );
      // setDocumentStage((prev) => prev + 1);
    } catch (err) {
      dispatch(goodsReceiveActions.rejectedActions({ ...err, name: "item" }));
      if (enqueueSnackbar)
        enqueueSnackbar("แก้ไขเอกสารไม่สำเร็จ", {
          variant: "error",
        });
      createLog(
        "error",
        "goodsReceiveUpdate",
        location,
        user?.email,
        input,
        err
      );
    }
  };

export const approveGoodsReceive =
  (payload, input, enqueueSnackbar, navigate, user, location) =>
  async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("item"));
    try {
      await InventoryService.updateGR(input);
      const item = await InventoryService.updateApproveGR(payload);
      // dispatch(goodsReceiveActions.loadedItem(Item));
      const createActivityLogApprove = createActivityLogStatusPayload(
        {
          ...input.uniqueInput,
          ...input.updateInput,
          creator_document_id: user.document_id,
        },
        "goods_receive",
        null,
        "finished"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLogApprove,
      });
      if (enqueueSnackbar)
        enqueueSnackbar("นำเข้าสำเร็จ", {
          variant: "success",
        });
      createLog(
        "info",
        "GoodsReceiveApprove",
        location,
        user?.email,
        input,
        item
      );
      window.location.reload();
      // setDocumentStage((prev) => prev + 1);
    } catch (err) {
      dispatch(goodsReceiveActions.rejectedActions({ ...err, name: "item" }));
      if (enqueueSnackbar)
        enqueueSnackbar("แก้ไขเอกสารไม่สำเร็จ", {
          variant: "error",
        });
      createLog(
        "error",
        "GoodsReceiveApprove",
        location,
        user?.email,
        input,
        err
      );
    }
  };

export const cancelGoodsReceive =
  (input, enqueueSnackbar, user) => async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("item"));
    try {
      await InventoryService.cancelGR(input);

      const cancelActivityLog = createActivityLogStatusPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_receive",
        null,
        "cancelled"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      if (enqueueSnackbar)
        enqueueSnackbar("ยกเลิกสำเร็จ", {
          variant: "success",
        });
      window.location.reload();

      // setDocumentStage((prev) => prev + 1);
    } catch (err) {
      dispatch(goodsReceiveActions.rejectedActions({ ...err, name: "item" }));
      if (enqueueSnackbar)
        enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
          variant: "error",
        });
    }
  };
