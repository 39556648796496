import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import CustomizedEventContent from "./CustomizedEventContent";
import thLocale from "@fullcalendar/core/locales/th";
import { useLocation } from "react-router-dom";
import { dateToUnix } from "../../../utils/date-converter";

const Calendar = ({ events, setStartDate, setEndDate }) => {
  const eventDidMountHandler = (info) => {
    // info.el.style.borderRadius = "100px";
    info.el.style.padding = "4px 8px";
    info.el.style.marginBottom = "4px";
  };

  const { pathname } = useLocation();

  const eventClickHandler = (info) => {
    const id = info.event.extendedProps.id;
    window.open(`${pathname}/${encodeURIComponent(id)}`, "_blank");
  };

  const datesSetHandler = (info) => {
    const { start, end } = info;
    setStartDate(dateToUnix(start));
    setEndDate(dateToUnix(end));
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin]}
      headerToolbar={{
        left: "today",
        center: "prev title next",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      initialView="dayGridMonth"
      editable={true}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={true}
      buttonText={{
        today: "วันนี้",
        month: "เดือน",
        week: "สัปดาห์",
        day: "วัน",
        list: "ลิสต์",
      }}
      events={events}
      eventDidMount={eventDidMountHandler}
      eventContent={(props) => <CustomizedEventContent {...props} />}
      eventClick={eventClickHandler}
      eventBorderColor="#1E276C"
      eventBackgroundColor="#FFFFFF"
      locale={thLocale}
      dayMaxEventRows={true} // for all non-TimeGrid views
      views={{
        week: {
          eventMaxStack: 2,
        },
        day: {
          eventMaxStack: 4,
        },
      }}
      datesSet={datesSetHandler}
    />
  );
};

export default Calendar;
