import { gql } from "graphql-request";

export const DOCUMENT_NEXT_STATUS = gql`
  mutation DocumentNextStatus($documentInput: DocumentInput!) {
    documentNextStatus(documentInput: $documentInput) {
      approval_progress_list {
        approval_template_id
        approval_template {
          id
          step_number
          step_name
          action
          workflow_step_number
          workflow_document_type
          reviewer_document_id_list
          reviewer_list {
            document_id
            first_name
            last_name
            email
            img_url
          }
          is_required_approve
        }
        step_number
        approval_status
        created_date
        created_by_document_id
        approved_by_document_id
        approved_by {
          document_id
          first_name
          last_name
        }
      }
      status
    }
  }
`;

export const DOCUMENT_APPROVE = gql`
  mutation DocumentApprove($documentInput: DocumentInput!) {
    documentApprove(documentInput: $documentInput) {
      document_id
      document_type
      approval_progress_list {
        approval_template_id
        approval_template {
          id
          step_number
          step_name
          action
          workflow_step_number
          workflow_document_type
          reviewer_document_id_list
          reviewer_list {
            document_id
            first_name
            last_name
            email
            img_url
          }
          is_required_approve
        }
        step_number
        approval_status
        created_date
        created_by_document_id
        approved_by_document_id
        approved_by {
          document_id
          first_name
          last_name
        }
      }
    }
  }
`;

export const DOCUMENT_DECLINE = gql`
  mutation DocumentDecline($documentInput: DocumentInput!) {
    documentDecline(documentInput: $documentInput) {
      document_id
      document_type
      approval_progress_list {
        approval_template_id
        approval_template {
          id
          step_number
          step_name
          action
          workflow_step_number
          workflow_document_type
          reviewer_document_id_list
          reviewer_list {
            document_id
            first_name
            last_name
            email
            img_url
          }
          is_required_approve
        }
        step_number
        approval_status
        created_date
        created_by_document_id
        approved_by_document_id
        approved_by {
          document_id
          first_name
          last_name
        }
      }
    }
  }
`;

export const DOCUMENT_CANCEL = gql`
  mutation DocumentCancel($documentInput: DocumentInput!) {
    documentCancel(documentInput: $documentInput) {
      document_id
      document_type
      is_canceled
    }
  }
`;

export const CREATE_QUOTATION = gql`
  mutation CreateQuotation($createQuotationInput: CreateQuotationInput!) {
    createQuotation(createQuotationInput: $createQuotationInput) {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      creator_document_id
      accepted_date
      accepted_remark
      customer {
        fax
        phone
        email
        billing_address {
          sub_district
          address
          district
          province
          postal_code
          country
        }
        delivery_address {
          is_same_as_default_address
          address_type
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      attachment_list {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      price_vat_type
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom {
          id
          document_id
          name
        }
        uom_id
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
    }
  }
`;

export const CREATE_QUOTATIONS = gql`
  mutation CreateQuotations($createQuotationsInput: [CreateQuotationInput!]!) {
    createQuotations(createQuotationsInput: $createQuotationsInput) {
      errors {
        index
        document_id
        errorMessage
        field
      }
      results {
        id
        document_id
        external_ref_id
      }
    }
  }
`;

export const UPDATE_QUOTATION = gql`
  mutation UpdateQuotation($updateQuotationInput: UpdateQuotationInput!) {
    updateQuotation(updateQuotationInput: $updateQuotationInput) {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      creator_document_id
      accepted_date
      accepted_remark
      customer {
        fax
        phone
        email
        billing_address {
          sub_district
          address
          district
          province
          postal_code
          country
        }
        delivery_address {
          is_same_as_default_address
          address_type
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      attachment_list {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom {
          id
          document_id
          name
        }
        uom_id
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      price_vat_type
      template_remark_id
      remark
      target_status
      render_status
      is_late
    }
  }
`;

export const CANCEL_QUOTATION = gql`
  mutation CancelQuotation($uniqueInput: QuotationWhereUniqueInput!) {
    cancelQuotation(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const CREATE_SALES_ORDER = gql`
  mutation CreateSalesOrder($createSalesOrderInput: CreateSalesOrderInput!) {
    createSalesOrder(createSalesOrderInput: $createSalesOrderInput) {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      delivery_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        bom_description
        qty
        uom {
          id
          document_id
          name
        }
        uom_id
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
        qty_to_ship
        qty_shipped
        qty_invoiced
        qty_returned
      }
      template_remark_id
      remark
      target_status
      render_status
      price_vat_type
    }
  }
`;

export const UPDATE_SALES_ORDER = gql`
  mutation UpdateSalesOrder($updateSalesOrderInput: UpdateSalesOrderInput!) {
    updateSalesOrder(updateSalesOrderInput: $updateSalesOrderInput) {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      delivery_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        bom_description
        qty
        uom {
          id
          document_id
          name
        }
        uom_id
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
        qty_to_ship
        qty_shipped
        qty_invoiced
        qty_returned
      }
      template_remark_id
      remark
      target_status
      render_status
      price_vat_type
      is_late
    }
  }
`;

export const CREATE_SALES_INVOICE = gql`
  mutation CreateSalesInvoice(
    $createSalesInvoiceInput: CreateSalesInvoiceInput!
  ) {
    createSalesInvoice(createSalesInvoiceInput: $createSalesInvoiceInput) {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom {
          id
          document_id
          name
        }
        uom_id
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
    }
  }
`;

export const UPDATE_SALES_INVOICE = gql`
  mutation UpdateSalesInvoice(
    $updateSalesInvoiceInput: UpdateSalesInvoiceInput!
  ) {
    updateSalesInvoice(updateSalesInvoiceInput: $updateSalesInvoiceInput) {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom_id
        uom {
          id
          document_id
          name
        }
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
    }
  }
`;

export const CREATE_DEPOSIT_INVOICE = gql`
  mutation CreateDepositInvoice(
    $createDepositInvoiceInput: CreateDepositInvoiceInput!
  ) {
    createDepositInvoice(
      createDepositInvoiceInput: $createDepositInvoiceInput
    ) {
      id
      document_id
      external_ref_id
      issue_date
      credit_day
      contact_document_id
      pre_vat_amount
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        description
        vat_type
        pre_vat_amount
      }
      template_remark_id
      remark
      target_status
      render_status
    }
  }
`;

export const UPDATE_DEPOSIT_INVOICE = gql`
  mutation UpdateDepositInvoice(
    $updateDepositInvoiceInput: UpdateDepositInvoiceInput!
  ) {
    updateDepositInvoice(
      updateDepositInvoiceInput: $updateDepositInvoiceInput
    ) {
      id
      document_id
      external_ref_id
      issue_date
      credit_day
      contact_document_id
      pre_vat_amount
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        description
        vat_type
        pre_vat_amount
      }
      template_remark_id
      remark
      target_status
      render_status
    }
  }
`;

export const CREATE_SALES_RETURN = gql`
  mutation CreateSalesReturn($createSalesReturnInput: CreateSalesReturnInput!) {
    createSalesReturn(createSalesReturnInput: $createSalesReturnInput) {
      id
      document_id
      external_ref_id
      issue_date
      reason_to_return
      is_effect_stock
      due_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        qty
        uom_id
        uom {
          id
          document_id
          name
        }
        qty_return
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
      price_vat_type
    }
  }
`;

export const UPDATE_SALES_RETURN = gql`
  mutation UpdateSalesReturn($updateSalesReturnInput: UpdateSalesReturnInput!) {
    updateSalesReturn(updateSalesReturnInput: $updateSalesReturnInput) {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      credit_day
      reason_to_return
      is_effect_stock
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        qty
        qty_return
        uom_id
        uom {
          id
          document_id
          name
        }
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
      price_vat_type
    }
  }
`;

export const CREATE_CREDIT_NOTE = gql`
  mutation CreateCreditNote($createCreditNoteInput: CreateCreditNoteInput!) {
    createCreditNote(createCreditNoteInput: $createCreditNoteInput) {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      credit_day
      credit_description
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom_id
        uom {
          id
          document_id
          name
        }
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
    }
  }
`;

export const UPDATE_CREDIT_NOTE = gql`
  mutation UpdateCreditNote($updateCreditNoteInput: UpdateCreditNoteInput!) {
    updateCreditNote(updateCreditNoteInput: $updateCreditNoteInput) {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      credit_day
      credit_description
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      template_remark_id
      remark
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom_id
        uom {
          id
          document_id
          name
        }
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
    }
  }
`;
