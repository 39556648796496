import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import React, { forwardRef } from "react";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  ...{
    "& .MuiOutlinedInput-root": {
      overflow: "hidden",
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: error && theme.palette.error.main,
    },
  },
}));

const CustomizedTextField = forwardRef(
  (
    {
      id,
      name,
      label,
      value,
      onChange,
      onBlur,
      disabled,
      multiline,
      rows,
      error,
      helperText,
      sx,
      InputProps,
      inputProps,
      InputLabelProps,
      required,
      placeholder,
      margin,
      maxRows,
      minRows,
      type,
      FormHelperTextProps,
      autoFocus,
      onKeyDown,
      onKeyPress,
      size,
      testId,
    },
    ref
  ) => {
    return (
      <StyledTextField
        ref={ref}
        placeholder={placeholder}
        autoComplete="off"
        size={size || "small"}
        data-test-id={testId}
        autoFocus={autoFocus}
        fullWidth
        label={label}
        sx={sx}
        InputProps={InputProps}
        inputProps={inputProps}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        type={type ? type : "text"}
        id={id}
        name={name}
        multiline={multiline}
        rows={rows}
        error={error}
        helperText={helperText}
        margin={margin}
        maxRows={maxRows}
        minRows={minRows}
        InputLabelProps={InputLabelProps}
        required={required}
        FormHelperTextProps={FormHelperTextProps}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
      />
    );
  }
);

export default CustomizedTextField;
