import ManufactureService from "../../../services/Manufacture";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogEditPayload,
  createActivityLogStatusPayload,
} from "../../../utils/activityLogsPayloadFormatter";
import { workOrderActions } from "./work-order-slice";
import {
  formatExportedWorkOrderDurationUnit,
  workOrdersExportFormatter,
} from "../../../utils/manufacturePayloadFormatter";
import { filterStatusValueFormatter } from "../../../utils/filterparams";
import {
  formatDateTimeWithCalendar,
  unixToDateTimeWithFormatNoSecond,
  unixToDateWithFormatCalendarWithMinute,
} from "../../../utils/date-converter";
import moment from "moment";

export const getAllWorkOrders =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(workOrderActions.onLoading("allWorkOrders"));
    try {
      const allWorkOrders = await ManufactureService.getAllWorkOrders(input);
      params.successCallback(allWorkOrders.results, allWorkOrders.count);
      dispatch(workOrderActions.loadedAllWorkOrders(allWorkOrders.results));
    } catch (err) {
      console.error(err);
      dispatch(
        workOrderActions.rejectedActions({
          ...err,
          name: "allWorkOrders",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getWorkOrderById = (id, enqueueSnackbar) => async (dispatch) => {
  dispatch(workOrderActions.onLoading("workOrder"));
  try {
    const workOrder = await ManufactureService.getWorkOrderById(id);
    dispatch(workOrderActions.loadedWorkOrder(workOrder));
  } catch (err) {
    console.error(err);
    dispatch(workOrderActions.rejectedActions({ ...err, name: "workOrder" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const createWorkOrder = (data, enqueueSnackbar) => async (dispatch) => {
  dispatch(workOrderActions.onLoading("workOrder"));
  try {
    await ManufactureService.createWorkOrder(data);
    enqueueSnackbar("สร้างใบสั่งงานสำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    console.error(err);
    dispatch(workOrderActions.rejectedActions({ ...err, name: "bom" }));
    enqueueSnackbar("สร้างใบสั่งงานไม่สำเร็จ", {
      variant: "error",
    });
  }
};

export const updateWorkOrder =
  (payload, enqueueSnackbar, user) => async (dispatch) => {
    dispatch(workOrderActions.onLoading("workOrder"));
    try {
      const updatedWorkOrder = await ManufactureService.updateWorkOrder(
        payload
      );
      const editActivityLog = createActivityLogEditPayload(
        {
          document_id: updatedWorkOrder.id.toString(),
          creator_document_id: user.document_id,
        },
        "work_order"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: editActivityLog,
      });
      dispatch(workOrderActions.loadedWorkOrder(updatedWorkOrder));
      enqueueSnackbar("แก้ไขใบสั่งงานสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      dispatch(workOrderActions.rejectedActions({ ...err, name: "workOrder" }));
      enqueueSnackbar("แก้ไขใบสั่งงานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const deleteWorkOrder =
  (id, enqueueSnackbar, navigate, user) => async (dispatch) => {
    dispatch(workOrderActions.onLoading("workOrder"));
    try {
      await ManufactureService.deleteWorkOrder(id);
      if (navigate) navigate("/manufacture/work-order");
    } catch (err) {
      console.error(err);
      dispatch(workOrderActions.rejectedActions({ ...err, name: "workOrder" }));
      enqueueSnackbar("ลบใบสั่งงานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const changeStatusWorkOrder =
  (input, enqueueSnackbar, user) => async (dispatch) => {
    dispatch(workOrderActions.onLoading("workOrder"));
    try {
      const { prevStatus, ...otherInput } = input;
      const workOrder = await ManufactureService.changeStatusWorkOrder(
        otherInput
      );
      dispatch(workOrderActions.loadedWorkOrder(workOrder));
      enqueueSnackbar("เปลี่ยนสถานะใบสั่งงานสำเร็จ", {
        variant: "success",
      });
      const changeStatusActivityLog = createActivityLogStatusPayload(
        {
          document_id: workOrder.id.toString(),
          creator_document_id: user.document_id,
        },
        "work_order",
        prevStatus,
        otherInput.statusInput
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: changeStatusActivityLog,
      });
    } catch (err) {
      console.error(err);
      dispatch(workOrderActions.rejectedActions({ ...err, name: "workOrder" }));
      enqueueSnackbar("เปลี่ยนสถานะใบสั่งงานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const getAllWorkOrdersExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(workOrderActions.onLoading("allWorkOrdersExport"));
    try {
      const { results } = await ManufactureService.getAllWorkOrders(input);
      let workOrders = await workOrdersExportFormatter(results);
      dispatch(workOrderActions.loadedAllWorkOrdersExport(workOrders));
    } catch (err) {
      console.error(err);
      dispatch(
        workOrderActions.rejectedActions({
          ...err,
          name: "allWorkOrdersExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllWorkOrdersExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(workOrderActions.onLoading("allWorkOrdersExport"));
    try {
      const { results } = await ManufactureService.getAllWorkOrdersReport(
        input
      );
      let workOrders = await workOrdersExportFormatter(results);
      dispatch(workOrderActions.loadedAllWorkOrdersExport(workOrders));
    } catch (err) {
      console.error(err);
      dispatch(
        workOrderActions.rejectedActions({
          ...err,
          name: "allWorkOrdersExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllWorkOrdersCalendarView =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(workOrderActions.onLoading("allWorkOrders"));
    try {
      const { results } = await ManufactureService.getAllWorkOrders(input);
      const formatResult = results.map(
        ({
          id,
          name,
          plant,
          machine,
          manufacture_order_document_id,
          start_date_time,
          duration,
          duration_unit,
          description,
          status,
        }) => {
          const formatPlant = plant ? plant : null;
          const formatMachine = machine ? machine : null;
          const formatDuration = duration ? duration : null;
          const formatDescription = description ? description : null;
          const formatStartDateTime =
            unixToDateTimeWithFormatNoSecond(start_date_time);
          const formatDurationUnit =
            formatExportedWorkOrderDurationUnit(duration_unit);
          const formatStatus = filterStatusValueFormatter(status);
          const formatTitle = [
            name,
            formatPlant,
            formatMachine,
            manufacture_order_document_id,
            formatStartDateTime,
            formatDuration,
            formatDurationUnit,
            formatDescription,
            formatStatus,
          ]
            .filter((title) => title !== null)
            .join(" - ");

          const formatStartDate =
            unixToDateWithFormatCalendarWithMinute(start_date_time);
          const formatEndDate = formatDateTimeWithCalendar(
            moment(formatStartDate).add(parseInt(duration), `${duration_unit}s`)
          );
          return {
            title: formatTitle,
            start: formatStartDate,
            end: formatEndDate,
            extendedProps: {
              id: id,
              status,
            },
          };
        }
      );
      dispatch(workOrderActions.loadedAllWorkOrdersCalendar(formatResult));
    } catch (err) {
      console.error(err);
      dispatch(
        workOrderActions.rejectedActions({
          ...err,
          name: "allWorkOrders",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
