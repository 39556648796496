import { useCallback, useEffect, useState } from "react";
import GlobalService from "../../../services/Global";

export const useGroupOptions = () => {
  const [groupLoading, setGroupLoading] = useState(false);
  const [groupOptions, setGroupOptions] = useState([]);
  const [openGroup, setOpenGroup] = useState(false);

  const getAllGroups = useCallback(async () => {
    setGroupLoading(true);
    const loadedOptions = await GlobalService.getTagListByEntity(
      "manufacture_order"
    );

    const formatGroupOptions = loadedOptions.map((option) => ({
      id: option.id,
      value: option.name,
      label: option.name,
    }));
    setGroupOptions(formatGroupOptions);
    setGroupLoading(false);
  }, []);

  useEffect(() => {
    if (openGroup) {
      getAllGroups();
    }
  }, [getAllGroups, openGroup]);

  const onOpenGroup = () => {
    setOpenGroup(true);
  };

  const onCloseGroup = () => {
    setOpenGroup(false);
    setGroupOptions([]);
  };
  return {
    groupLoading,
    groupOptions,
    onOpenGroup,
    onCloseGroup,
  };
};
