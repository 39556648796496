import { Box, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";

const Logistic = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.report.index"),
    },
  ];

  const menuList = [
    // {
    //   title: t("logistic.deliveryOrder.index") + " (เก่า)",
    //   navigateTo: "delivery-order-old",
    // },
    {
      title: t("logistic.deliveryOrder.index") + " (ใหม่)",
      navigateTo: "delivery-order",
    },
    // {
    //   title: t("logistic.deliveryTrip.index") + " (เก่า)",
    //   navigateTo: "delivery-trip-old",
    // },
    {
      title: t("logistic.deliveryTrip.index") + " (ใหม่)",
      navigateTo: "delivery-trip",
    },
  ];

  const renderMenuList = () =>
    menuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          linkTo={`${pathname}/${menu.navigateTo}`}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default Logistic;
