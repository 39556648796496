import { Box, IconButton, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Controller, useWatch } from "react-hook-form";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";

const TransferDetail = ({
  control,
  errors,
  getValues,
  viewOnly,
  isInventoryPage,
  reset,
  setValueField,
  generateDocumentId,
}) => {
  const { t } = useTranslation();
  const { allLocation } = useSelector((state) => state.location);

  const currentSourceWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const currentDestinationWarehouse = useWatch({
    control,
    name: "destination_warehouse_document_id",
  });

  const warehouseOption = allLocation?.map((location) => {
    return {
      id: location.document_id,
      label: location.thai_name,
      value: location.document_id,
    };
  });

  const mapWarehouseIdToName = (type) => {
    const currentWarehouse =
      type === "source" ? currentSourceWarehouse : currentDestinationWarehouse;
    const findId = allLocation?.find(
      (location) => location.document_id === currentWarehouse
    );
    if (findId) return findId.thai_name;
    else return "";
  };

  return (
    <CustomizedBox margin={0}>
      <Typography sx={{ fontWeight: 700, mb: 3 }}>
        {t("description")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={!viewOnly ? 10 : 12}
              sm={!viewOnly ? 10 : 12}
              md={6}
              lg={4}
              xl={4}
            >
              <Controller
                control={control}
                name="document_id"
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    label={t("inventory.transfer.documentId")}
                    error={Boolean(errors.document_id)}
                    helperText={errors.document_id?.message}
                    disabled={!isInventoryPage || viewOnly}
                    required
                  />
                )}
              />
            </Grid>
            {!viewOnly && (
              <Grid item xs={2}>
                <Box sx={{ ml: -1 }}>
                  <CustomizedTooltip title="เรียกเลขที่เอกสารใหม่">
                    <IconButton
                      onClick={generateDocumentId}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <RestartAltOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name="source_warehouse_document_id"
            render={({ field }) => (
              <CustomizedComboBox
                options={warehouseOption}
                label={t("inventory.list.sourceWarehouse")}
                error={Boolean(errors.source_warehouse_document_id)}
                helperText={errors.source_warehouse_document_id?.message}
                onChange={(_, newValue) => {
                  const currValue = getValues();
                  if (newValue && newValue.value) {
                    field.onChange(newValue.value);
                    reset({
                      ...currValue,
                      source_warehouse_document_id: newValue.value,
                      goods_transfer_list: [],
                    });
                  } else {
                    field.onChange("");
                    reset({
                      ...currValue,
                      source_warehouse_document_id: "",
                      goods_transfer_list: [],
                    });
                  }
                  setValueField("destination_bin_location_document_id", "");
                  setValueField("barcode", "");
                }}
                value={mapWarehouseIdToName("source")}
                disabled={!isInventoryPage || viewOnly}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name="destination_warehouse_document_id"
            render={({ field }) => (
              <CustomizedComboBox
                options={warehouseOption}
                label={t("inventory.list.destinationWarehouse")}
                error={Boolean(errors.destination_warehouse_document_id)}
                helperText={errors.destination_warehouse_document_id?.message}
                onChange={(_, newValue) => {
                  const currValue = getValues();
                  if (newValue && newValue.value) {
                    field.onChange(newValue.value);
                    reset({
                      ...currValue,
                      destination_warehouse_document_id: newValue.value,
                      goods_transfer_list: [],
                    });
                  } else {
                    field.onChange("");
                    reset({
                      ...currValue,
                      destination_warehouse_document_id: "",
                      goods_transfer_list: [],
                    });
                  }
                  setValueField("destination_bin_location_document_id", "");
                  setValueField("barcode", "");
                }}
                value={mapWarehouseIdToName("destination")}
                disabled={!isInventoryPage || viewOnly}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name="remark"
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.remark")}
                error={Boolean(errors.remark)}
                helperText={errors.remark?.message}
                disabled={!isInventoryPage || viewOnly}
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default TransferDetail;
