import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllRemarkTemplate } from "../../../features/Setting/RemarkTemplate/remark-template-actions";
import {
  calcSummary,
  formatNumber,
  roundDecimal,
} from "../../../utils/dataTransformer";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import CustomizedNumberTextField from "../../Custom/CustomizedNumberTextField";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import ControlledSelect from "../../Controlled/ControlledSelect";

const additionalDiscountTypeOptions = [
  {
    label: "บาท",
    value: "baht",
  },
  {
    label: "%",
    value: "percent",
  },
];

const Summary = ({
  control,
  invoiceControl,
  setValue,
  viewOnly,
  getValues,
  withholdingTaxType,
  documentType,
  onlyTemplate,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const itemList = useWatch({
    control: documentType === "rt" ? invoiceControl : control,
    name: documentType === "rt" ? "sales_invoice_list" : "item_list",
  });
  const shippingCost = useWatch({ control, name: "shipping_cost" });
  const additionalDiscount = useWatch({ control, name: "additional_discount" });
  const { allRemarkTemplate } = useSelector((state) => state.remarkTemplate);

  const priceVatType = useWatch({ control, name: "price_vat_type" });

  const additionalDiscountType = useWatch({
    control,
    name: "additional_discount_type",
  });

  const [additionalDiscountBaht, setAdditionalDiscountBaht] = useState(0);

  useEffect(() => {
    if (additionalDiscountType === "percent") {
      const total_pre_vat_amount = roundDecimal(
        itemList.reduce(
          (acc, item) => acc + roundDecimal(item.pre_vat_amount),
          0
        )
      );
      const additional_discount =
        total_pre_vat_amount * (additionalDiscount / 100);
      setAdditionalDiscountBaht(additional_discount);
    } else {
      setAdditionalDiscountBaht(additionalDiscount);
    }
  }, [additionalDiscount, additionalDiscountType, itemList]);

  const summary = calcSummary(
    priceVatType,
    itemList,
    shippingCost || 0,
    additionalDiscountBaht,
    documentType,
    withholdingTaxType
  );

  const mapDocumentType = useCallback(() => {
    switch (documentType) {
      case "qa":
        return "quotation";
      case "so":
        return "sales_order";
      case "di":
        return "deposit_invoice";
      case "si":
        return "sales_invoice";
      case "rt":
        return "payment_receive";
      case "sr":
        return "sales_return";
      case "cn":
        return "credit_note";
      case "do":
        return "delivery_order";
      case "pr":
        return "purchase_request";
      case "po":
        return "purchase_order";
      case "pi":
        return "purchase_invoice";
      case "pm":
        return "payment_made";
      case "rs":
        return "purchase_return";
      default:
        break;
    }
  }, [documentType]);

  const fetchRemark = useCallback(() => {
    dispatch(getAllRemarkTemplate({}, mapDocumentType()));
  }, [dispatch, mapDocumentType]);

  useEffect(() => {
    fetchRemark();
  }, [fetchRemark]);

  useEffect(() => {
    setValue("summary", summary);
  }, [setValue, summary]);

  const mapTemplateRemarkIdToRemark = useCallback(
    (id) => {
      if (id) {
        const findById = allRemarkTemplate.remarkTemplate.find(
          (template) => template.id === id
        );
        if (findById) setValue("remark", findById.remark_text);
      } else {
        setValue("remark", "");
      }
    },
    [allRemarkTemplate.remarkTemplate, setValue]
  );

  const mapTemplateToValue = useCallback(
    (id) => {
      if (id) {
        const findById = allRemarkTemplate.remarkTemplate.find(
          (template) => template.id === parseInt(id)
        );
        if (findById) {
          return findById.name;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    [allRemarkTemplate.remarkTemplate]
  );

  const defaultSummaryList = useMemo(
    () => [
      {
        name: "additional_discount",
        label: `${t("sales.additionalDiscount")} / Additional Discount`,
        amount: formatNumber(getValues("additional_discount") ?? 0),
      },
      {
        label: `${t("sales.subTotal")} / Sub Total`,
        amount: summary.sub_total,
      },
      {
        label: `${t("sales.vatExemptedAmount")} / VAT Exempted Amount`,
        amount: summary.vat_exempted_amount,
      },
      {
        label: `${t("sales.vat0%Amount")} / VAT 0% Amount`,
        amount: summary.vat_0_amount,
      },
      {
        label: `${t("sales.vat7%Amount")} / VAT 7% Amount`,
        amount: summary.vat_7_amount,
      },
      {
        label: `${t("sales.totalVatAmount")} / VAT Amount`,
        amount: summary.vat_amount,
      },
      {
        label: `${t("sales.totalNetAmount")} / Net Amount`,
        amount: summary.net_amount,
      },
      {
        label: `${t("sales.withholdingTax")} / Withholding Tax`,
        amount: summary.withholding_tax_amount,
      },
      {
        name: "shipping_cost",
        label: `${t("sales.shippingCost")} / Shipping Cost`,
        amount: formatNumber(getValues("shipping_cost") ?? 0),
      },
      {
        label: `${t("sales.totalAmount")} / Total Amount`,
        amount: summary.total_amount,
      },
    ],
    [
      getValues,
      summary.net_amount,
      summary.sub_total,
      summary.total_amount,
      summary.vat_0_amount,
      summary.vat_7_amount,
      summary.vat_amount,
      summary.vat_exempted_amount,
      summary.withholding_tax_amount,
      t,
    ]
  );

  const [summaryList, setSummaryList] = useState(defaultSummaryList);

  useEffect(() => {
    if (additionalDiscountType === "percent") {
      setSummaryList([
        {
          name: "additional_discount",
          label: `${t("sales.additionalDiscount")} / Additional Discount`,
          amount: formatNumber(getValues("additional_discount") ?? 0),
        },
        {
          label: "",
          amount: formatNumber(additionalDiscountBaht),
        },
        {
          label: `${t("sales.subTotal")} / Sub Total`,
          amount: summary.sub_total,
        },
        {
          label: `${t("sales.vatExemptedAmount")} / VAT Exempted Amount`,
          amount: summary.vat_exempted_amount,
        },
        {
          label: `${t("sales.vat0%Amount")} / VAT 0% Amount`,
          amount: summary.vat_0_amount,
        },
        {
          label: `${t("sales.vat7%Amount")} / VAT 7% Amount`,
          amount: summary.vat_7_amount,
        },
        {
          label: `${t("sales.totalVatAmount")} / VAT Amount`,
          amount: summary.vat_amount,
        },
        {
          label: `${t("sales.totalNetAmount")} / Net Amount`,
          amount: summary.net_amount,
        },
        {
          label: `${t("sales.withholdingTax")} / Withholding Tax`,
          amount: summary.withholding_tax_amount,
        },
        {
          name: "shipping_cost",
          label: `${t("sales.shippingCost")} / Shipping Cost`,
          amount: formatNumber(getValues("shipping_cost") ?? 0),
        },
        {
          label: `${t("sales.totalAmount")} / Total Amount`,
          amount: summary.total_amount,
        },
      ]);
    } else {
      setSummaryList(defaultSummaryList);
    }
  }, [
    additionalDiscountBaht,
    additionalDiscountType,
    defaultSummaryList,
    getValues,
    summary.net_amount,
    summary.sub_total,
    summary.total_amount,
    summary.vat_0_amount,
    summary.vat_7_amount,
    summary.vat_amount,
    summary.vat_exempted_amount,
    summary.withholding_tax_amount,
    t,
  ]);

  const filterSummaryList = () => {
    switch (documentType) {
      case "rs":
        if (additionalDiscountType === "percent") {
          if (summaryList.length === 11)
            return [...summaryList.slice(0, 9), summaryList[10]];
          return [...summaryList.slice(0, 8), summaryList[9]];
        } else {
          return [...summaryList.slice(0, 8), summaryList[9]];
        }

      default:
        return summaryList;
    }
  };

  const renderSummaryList = () => {
    return filterSummaryList().map((item, index) => (
      <Box key={index} sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="subtitle1">{item.label}</Typography>
          {!["shipping_cost", "additional_discount"].includes(item.name) ||
          viewOnly ? (
            <Typography
              variant="subtitle1"
              sx={{ width: "170px", textAlign: "right", mx: 3 }}
            >
              {item.amount}
            </Typography>
          ) : (
            <CustomizedNumberTextField
              name={item.name}
              control={control}
              sx={{
                width: 170,
                ml: 3,
                mr: 1.5,
              }}
            />
          )}
          {item.name === "additional_discount" ? (
            <>
              {viewOnly ? (
                additionalDiscountType === "percent" ? (
                  <Typography variant="subtitle1" sx={{ ml: 2.5, mr: 6 }}>
                    %
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" sx={{ ml: 2.5, mr: 4 }}>
                    บาท
                  </Typography>
                )
              ) : (
                <Box
                  sx={{
                    width: 75,
                  }}
                >
                  <ControlledSelect
                    control={control}
                    name="additional_discount_type"
                    options={additionalDiscountTypeOptions}
                  />
                </Box>
              )}
            </>
          ) : (
            <Typography variant="subtitle1" sx={{ ml: 2.5, mr: 4 }}>
              บาท
            </Typography>
          )}
        </Box>
        {index === filterSummaryList().length - 2 && <Divider sx={{ mb: 3 }} />}
        {index === filterSummaryList().length - 1 && (
          <>
            <Divider sx={{ mb: 1 }} />
            <Divider sx={{ mb: 2 }} />
          </>
        )}
      </Box>
    ));
  };

  return (
    <CustomizedBox padding="1rem">
      <Grid container spacing={2}>
        <Grid
          item
          container
          rowSpacing={2}
          xs={12}
          md={5}
          // lg={5}
          direction="column"
        >
          <Grid item>
            {viewOnly ? (
              <Typography sx={{ fontWeight: "bold" }}>หมายเหตุ</Typography>
            ) : (
              <Controller
                name="template_remark_id"
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label="หมายเหตุ"
                    options={allRemarkTemplate.remarkTemplate.map(
                      (template) => {
                        return {
                          id: template.id,
                          label: template.name,
                          value: template.id,
                        };
                      }
                    )}
                    value={mapTemplateToValue(field.value)}
                    onChange={(e, value) => {
                      if (value) {
                        mapTemplateRemarkIdToRemark(e.target.value);
                        field.onChange(value.value);
                      } else {
                        mapTemplateRemarkIdToRemark(e.target.value);
                        field.onChange(null);
                      }
                    }}
                    sx={{ width: "70%" }}
                    disabled={viewOnly}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item>
            <Controller
              name="remark"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  multiline
                  rows={4}
                  {...field}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
        </Grid>
        {!onlyTemplate && (
          <Grid
            item
            container
            xs
            justifyContent="flex-start"
            direction="column"
            alignItems="flex-end"
          >
            {renderSummaryList()}
          </Grid>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default Summary;
