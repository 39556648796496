import { Box, DialogContentText } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";

const ExpiredConfirmation = ({ open, logout }) => {
  return (
    <ModalUI
      title="เซสชันหมดอายุ"
      open={open}
      handleClose={null}
      maxWidth="xs"
      width={300}
      centered
    >
      <DialogContentText sx={{ textAlign: "center" }}>
        โปรดเข้าสู่ระบบอีกครั้ง
      </DialogContentText>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 3,
        }}
      >
        <CustomizedButton
          title="ตกลง"
          variant="contained"
          onClick={logout}
          fullWidth
        />
      </Box>
    </ModalUI>
  );
};

export default ExpiredConfirmation;
