import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { unixToDateWithFormat } from "../../../../utils/date-converter";
import { formatNumber } from "../../../../utils/dataTransformer";

const BomPDFInfo = ({ manufactureOrder, t }) => {
  const leftHeader = [
    t("manufacture.order.customer"),
    t("manufacture.bom.item_document_id"),
    t("manufacture.bom.item_name"),
    t("manufacture.bom.mfg_qty"),
    t("manufacture.bom.stock_uom"),
    t("manufacture.bom.bom_description"),
    t("manufacture.order.item_remark"),
    t("manufacture.order.type"),
    t("manufacture.order.bom"),
  ];

  const leftInfo = [
    manufactureOrder?.customer || "-",
    manufactureOrder?.item_document_id || "-",
    manufactureOrder?.item_name || "-",
    formatNumber(manufactureOrder?.production_qty) || "-",
    manufactureOrder?.stock_uom || "-",
    manufactureOrder?.item_description || "-",
    manufactureOrder?.item_remark || "-",
    manufactureOrder?.type || "-",
    manufactureOrder?.bom_name || "-",
  ];

  const rightHeader = [
    t("manufacture.order.document_id"),
    t("manufacture.order.reference_document_list"),
    t("manufacture.order.external_ref_id"),
    t("manufacture.bom.created_date"),
    t("manufacture.order.issue_date"),
    t("manufacture.order.expect_date"),
    t("manufacture.order.delivery_date"),
    t("manufacture.order.employee_list"),
  ];

  const rightInfo = [
    manufactureOrder?.document_id || "-",
    manufactureOrder?.reference_document_list
      ?.map((list) => list.reference_document_id)
      .join(",") || "-",
    manufactureOrder?.external_ref_id || "-",
    unixToDateWithFormat(manufactureOrder?.created_date) || "-",
    unixToDateWithFormat(manufactureOrder?.issue_date) || "-",
    unixToDateWithFormat(manufactureOrder?.expect_date) || "-",
    unixToDateWithFormat(manufactureOrder?.delivery_date) || "-",
    manufactureOrder?.employee_list?.map(
      (employee) => employee.first_name + " " + employee.last_name
    )[0] || "-",
  ];

  const secondLeftHeader = [
    t("manufacture.order.width_tolerance_positive_negative"),
    "",
    t("manufacture.order.positive"),
    t("manufacture.order.negative"),
  ];

  const secondLeftInfo = [
    "",
    "",
    formatNumber(manufactureOrder?.width_tolerance_positive) || "-",
    formatNumber(manufactureOrder?.width_tolerance_negative) || "-",
  ];

  const secondRightHeader = [
    t("manufacture.order.thickness_tolerance_positive_negative"),
    "",
    t("manufacture.order.positive"),
    t("manufacture.order.negative"),
  ];

  const secondRightInfo = [
    "",
    "",
    formatNumber(manufactureOrder?.thickness_tolerance_positive) || "-",
    formatNumber(manufactureOrder?.thickness_tolerance_negative) || "-",
  ];

  const defaultSx = {
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "0.15px",
  };

  return (
    <>
      <Grid container sx={{ my: 1 }}>
        <Grid item xs={6}>
          {leftHeader.map((name, index) => (
            <Box sx={{ display: "flex" }} key={index}>
              <Typography
                sx={{
                  ...defaultSx,
                  fontWeight: 600,
                  color: "#333333",
                  flex: 1,
                }}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  ...defaultSx,
                  flex: 1,
                  width: "100%",
                  wordBreak: "break-word",
                }}
              >
                {leftInfo[index]}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid item xs={6}>
          {rightHeader.map((name, index) => (
            <Box sx={{ display: "flex" }} key={index}>
              <Typography
                sx={{
                  ...defaultSx,
                  fontWeight: 600,
                  color: "#333333",
                  flex: 1,
                }}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  ...defaultSx,
                  flex: 1,
                  width: "100%",
                  wordBreak: "break-word",
                }}
              >
                {rightInfo[index]}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
      <Divider />
      <Grid container sx={{ my: 1 }}>
        <Grid item xs={6}>
          {secondLeftHeader.map((name, index) => (
            <Box sx={{ display: "flex" }} key={index}>
              <Typography
                sx={{
                  ...defaultSx,
                  fontWeight: 600,
                  color: "#333333",
                  flex: 1,
                }}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  ...defaultSx,
                  flex: 1,
                  width: "100%",
                  wordBreak: "break-word",
                }}
              >
                {secondLeftInfo[index]}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid item xs={6}>
          {secondRightHeader.map((name, index) => (
            <Box sx={{ display: "flex" }} key={index}>
              <Typography
                sx={{
                  ...defaultSx,
                  fontWeight: 600,
                  color: "#333333",
                  flex: 1,
                }}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  ...defaultSx,
                  flex: 1,
                  width: "100%",
                  wordBreak: "break-word",
                }}
              >
                {secondRightInfo[index]}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default BomPDFInfo;
