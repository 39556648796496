import { Avatar } from "@mui/material";
import CustomizedLetterAvatar from "../../Custom/CustomizedLetterAvatar";
import CustomizedStatus from "../../Custom/CustomizedStatus";

export const userColumnDef = (t) => {
  const columnDefs = [
    {
      field: "document_id",
      headerName: t("user.account.employeeId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "img_url",
      headerName: t("user.account.picture"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data.img_url) {
          return <Avatar alt="img_url" src={params.data.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.data.first_name + " " + params.data.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "first_name",
      headerName: t("user.account.firstname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_name",
      headerName: t("user.account.lastname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "บริหาร",
          "คลังสินค้า",
          "รายรับ",
          "จัดซื้อ",
          "บัญชี",
          "ขนส่ง",
          "ผลิต",
          "ดูแลระบบ",
        ],
      },
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("user.account.status"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];
  return columnDefs;
};
