import React, { useEffect, useState } from "react";
import AgGrid from "./AgGrid";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../Custom/CustomizedStatus";
import { Avatar, IconButton } from "@mui/material";
import CustomizedLetterAvatar from "../Custom/CustomizedLetterAvatar";
import { CustomizedTooltip } from "../Custom/CustomizedTooltip";
import CloseIcon from "@mui/icons-material/Close";
import GlobalService from "../../services/Global";
// import { departmentEngToThai } from "../../utils/dataTransformer";

const UserTable = ({
  gridRef,
  onGridReady,
  onFirstDataRendered,
  rowSelection,
  height,
  searchBox,
  enableCheckbox,
  rowData,
  onRowDoubleClicked,
  headerCheckboxSelection,
  enableRangeSelection,
  handleDelete,
  path,
}) => {
  const { t } = useTranslation();

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "document_id",
      headerName: t("user.account.employeeId"),
      filter: "agTextColumnFilter",
      checkboxSelection: enableCheckbox,
      headerCheckboxSelection: headerCheckboxSelection,
    },
    {
      field: "img_url",
      headerName: t("user.account.picture"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data.img_url) {
          return (
            <CustomizedTooltip
              title={params.data.first_name + " " + params.data.last_name}
            >
              <Avatar alt="img_url" src={params.data.img_url} />
            </CustomizedTooltip>
          );
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.data.first_name + " " + params.data.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "email",
      headerName: t("user.account.email"),
      filter: "agTextColumnFilter",
    },
    {
      field: "first_name",
      headerName: t("user.account.firstname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_name",
      headerName: t("user.account.lastname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          const loadedOptions = await GlobalService.getCreatables({
            usage_field_type: "employee",
            usage_field_name: "department",
          });
          const formatType = loadedOptions.map((option) => option.name);
          params.success([
            "บริหาร",
            "คลังสินค้า",
            "รายรับ",
            "จัดซื้อ",
            "บัญชี",
            "ขนส่ง",
            "ผลิต",
            "ดูแลระบบ",
            ...formatType,
          ]);
        },
      },
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("user.account.status"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ]);

  useEffect(() => {
    if (handleDelete) {
      setColumnDefs([
        {
          field: "document_id",
          headerName: t("user.account.employeeId"),
          filter: "agTextColumnFilter",
        },
        {
          field: "img_url",
          headerName: t("user.account.picture"),
          filter: false,
          floatingFilter: false,
          cellRenderer: (params) => {
            if (params.data.img_url) {
              return (
                <CustomizedTooltip
                  title={params.data.first_name + " " + params.data.last_name}
                >
                  <Avatar alt="img_url" src={params.data.img_url} />
                </CustomizedTooltip>
              );
            } else {
              return (
                <CustomizedLetterAvatar
                  name={params.data.first_name + " " + params.data.last_name}
                />
              );
            }
          },
          cellStyle: {
            display: "flex",
            alignItems: "center",
          },
        },
        {
          field: "email",
          headerName: t("user.account.email"),
          filter: "agTextColumnFilter",
        },
        {
          field: "first_name",
          headerName: t("user.account.firstname"),
          filter: "agTextColumnFilter",
        },
        {
          field: "last_name",
          headerName: t("user.account.lastname"),
          filter: "agTextColumnFilter",
        },
        {
          field: "department",
          headerName: t("user.account.department"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "บริหาร",
              "คลังสินค้า",
              "รายรับ",
              "จัดซื้อ",
              "บัญชี",
              "ขนส่ง",
              "ผลิต",
              "ดูแลระบบ",
            ],
          },
        },
        {
          field: "position",
          headerName: t("user.account.position"),
          filter: "agTextColumnFilter",
        },
        {
          field: "status",
          headerName: t("user.account.status"),
          filter: "agTextColumnFilter",
          cellRenderer: (params) => {
            return <CustomizedStatus status={params.value} />;
          },
          cellStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
        {
          field: "",
          filter: false,
          cellRenderer: (params) => {
            if (params.data.document_id === "EP-ADMIN") {
              return;
            }
            return (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                onClick={() => handleDelete(params.node.rowIndex)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            );
          },
          width: 80,
          cellStyle: {
            display: "flex",
            alignItems: "center",
          },
        },
      ]);
    }
  }, [handleDelete, t]);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={height}
      onGridReady={onGridReady}
      onFirstDataRendered={onFirstDataRendered}
      searchBox={searchBox}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
      path={path}
    />
  );
};

export default UserTable;
