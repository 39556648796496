import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CheckedItemModal from "../../../Table/CheckedItemModal";
import ControlledRadioGroup from "../../../Controlled/ControlledRadioGroup";
import ControlledServerSideCreatable from "../../../Controlled/ControlledSSCreatable";
import { useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "../../../../pages/Inventory/Items/schema";
import ModalUI from "../../../UI/ModalUI";
import ItemInfo from "../../../../pages/Inventory/Items/ItemInfo";
import {
  formatNumber,
  formatVatOption,
} from "../../../../utils/dataTransformer";
import { getItem } from "../../../../features/Inventory/ItemMaster/itemMaster-actions";
import { itemMasterActions } from "../../../../features/Inventory/ItemMaster/itemMaster-slice";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import styled from "@emotion/styled";
import CustomizedNumberTextField from "../../../Custom/CustomizedNumberTextField";

// const exceptThisSymbols = ["e", "E", "+", "-", "."];

const BomDetailForm = ({
  control,
  errors,
  setValue,
  reset,
  disabled,
  minimumStockQty,
  isMainPage,
  isItemMasterPage,
}) => {
  const { t } = useTranslation();
  const { item } = useSelector((state) => state.itemMaster);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSelectItem, setShowSelectItem] = useState(false);
  const [showItemForm, setShowItemForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [itemIds, setItemIds] = useState([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState([]);

  const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  }));

  const {
    control: itemInfoControl,
    handleSubmit: handleSubmitItemInfo,
    getValues: getItemInfoValues,
    setValue: setItemInfoValue,
    formState: { errors: itemInfoErrors },
    reset: resetItemInfo,
    clearErrors: clearItemInfoErrors,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  const openItemTable = () => {
    setShowSelectItem(true);
  };

  const closeItemTable = () => {
    setShowSelectItem(false);
  };

  const openItemForm = () => {
    setShowItemForm(true);
    dispatch(getItem({ document_id: watchItemId }, enqueueSnackbar));
  };

  const closeItemForm = () => {
    searchParams.delete("itemtab");
    setSearchParams(searchParams);
    clearItemInfoErrors();
    setShowItemForm(false);
    if (!isItemMasterPage) dispatch(itemMasterActions.resetItem());
  };

  const radioLists = [
    {
      value: "active",
      label: "ใช้งาน",
    },
    { value: "inactive", label: "หยุดใช้งาน" },
  ];

  const watchItemId = useWatch({ control, name: "item_document_id" });
  const watchItemName = useWatch({ control, name: "item_name" });

  useEffect(() => {
    if (item.document_id !== "") {
      Object.entries(item).forEach(([key, value]) => {
        if (key === "sales_vat_type")
          setItemInfoValue(key, formatVatOption(value));
        else if (key === "attribute_list")
          setItemInfoValue(
            key,
            value.map((item) => item.attribute)
          );
        else setItemInfoValue(key, value);
      });
      setItemIds(item.document_id);
      setItemIdsSnapshot(item.document_id);
    }
  }, [item, setItemInfoValue]);

  const finishItemSelectHandler = (data) => {
    if (data) {
      setValue("item_document_id", data.document_id);
      setValue("item_name", data.name);
      setValue("type", data.manufacture_type);
      setValue("stock_uom_document_id", data.base_uom.document_id);
      setValue("stock_uom", data.base_uom.name);
      setValue("minimum_stock_qty", data.manufacture_minimum_qty ?? 0);
    } else {
      reset((prev) => ({
        ...prev,
        item_document_id: "",
        item_name: "",
        type: "",
        stock_uom_document_id: "",
        stock_uom: "",
        minimun_stock_qty: 0,
      }));
    }
    closeItemTable();
  };
  return (
    <CustomizedBox
      boxShadow={!isMainPage ? "none" : undefined}
      margin={!isMainPage ? 0 : "2rem 0 2rem 0"}
      padding={!isMainPage ? "0 2rem 2rem 2rem" : "2rem"}
    >
      <Typography sx={{ ml: 1, fontWeight: "bold" }}>
        {t("manufacture.bom.detail")}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="name"
            label={t("manufacture.bom.bom_name")}
            control={control}
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors?.name.message}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="description"
            label={t("manufacture.bom.bom_description")}
            control={control}
            error={Boolean(errors?.description)}
            helperText={errors?.description && errors?.description.message}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", gap: 1, mt: 3, alignItems: "center" }}>
        <Typography sx={{ ml: 1 }}>{t("manufacture.bom.item")}</Typography>
        {!disabled && (
          <CustomizedTooltip title="เลือกลินค้า">
            <IconButton
              onClick={openItemTable}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_document_id"
            label={t("manufacture.order.item_document_id")}
            control={control}
            error={Boolean(errors?.item_document_id)}
            helperText={
              errors?.item_document_id && errors?.item_document_id.message
            }
            fullWidth
            disabled
            required
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={openItemForm}>
                    {watchItemId || t("manufacture.order.item_document_id")}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && watchItemId && (
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
                      <IconButton
                        onClick={openItemForm}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_name"
            label={t("manufacture.bom.item_name")}
            control={control}
            error={Boolean(errors?.item_name)}
            helperText={errors?.item_name && errors?.item_name.message}
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={openItemForm}>
                    {watchItemName || t("manufacture.bom.item_name")}
                  </StyledLink>
                </InputAdornment>
              ),
            }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <CustomizedNumberTextField
            name="mfg_qty"
            control={control}
            label={t("manufacture.bom.mfg_qty")}
            helperText={`สั่งผลิตขั้นต่ำ ${formatNumber(minimumStockQty)}`}
            error={Boolean(errors?.mfg_qty)}
            disabled={disabled}
            // decimalScale={3}
            // onKeyDown={(e) =>
            //   exceptThisSymbols.includes(e.key) && e.preventDefault()
            // }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="stock_uom"
            label={t("manufacture.bom.stock_uom")}
            control={control}
            error={Boolean(errors?.stock_uom)}
            helperText={errors?.stock_uom && errors?.stock_uom.message}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            title={t("inventory.items.manufactureType")}
            control={control}
            name="type"
            dataName="manufacture_type"
            documentType="item"
            defaultOptions={[]}
            error={Boolean(errors.manufacture_type)}
            helperText={errors.manufacture_type?.message}
            setValue={setValue}
            viewOnly={disabled}
          />
        </Grid>
      </Grid>
      {isMainPage && (
        <Box sx={{ ml: 1, mt: 3 }}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {t("manufacture.bom.status")}
          </Typography>
          <ControlledRadioGroup
            control={control}
            name="status"
            radioLists={radioLists}
            row
            disabled={disabled}
          />
        </Box>
      )}
      <CheckedItemModal
        showSelectItem={showSelectItem}
        closeItemTable={closeItemTable}
        setValue={setValue}
        reset={reset}
        finishItemSelectHandler={finishItemSelectHandler}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
      />
      <ModalUI
        open={showItemForm}
        navigateTo={`/inventory/items/${watchItemId}`}
        handleClose={closeItemForm}
        fullWidth
        title="สินค้า"
        maxWidth="lg"
      >
        <ItemInfo
          control={itemInfoControl}
          errors={itemInfoErrors}
          getValues={getItemInfoValues}
          handleSubmit={handleSubmitItemInfo}
          reset={resetItemInfo}
          setValue={setItemInfoValue}
          onCancel={closeItemForm}
          viewOnly={true}
        />
      </ModalUI>
    </CustomizedBox>
  );
};

export default BomDetailForm;
