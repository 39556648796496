import Avatar from "@mui/material/Avatar";
import { CustomizedTooltip } from "./CustomizedTooltip";

const CustomizedToolTipAvatar = ({ avatar, sx }) => {
  return (
    <CustomizedTooltip title={`${avatar.first_name} ${avatar.last_name}`}>
      <Avatar alt="img_url" src={avatar.img_url} sx={sx} />
    </CustomizedTooltip>
  );
};

export default CustomizedToolTipAvatar;
