import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useSelector } from "react-redux";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";

const AdjustmentDetail = ({
  isCreate,
  control,
  reset,
  resetFieldBarcode,
  getValues,
  errors,
  disabled,
  generateDocumentId,
}) => {
  const { t } = useTranslation();
  const { allLocation } = useSelector((state) => state.location);

  const warehouseOption = allLocation?.map((location) => {
    return {
      id: location.document_id,
      label: location.thai_name,
      value: location.document_id,
    };
  });

  const currentWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const mapWarehouseIdToName = () => {
    const findId = allLocation?.find(
      (location) => location.document_id === currentWarehouse
    );
    if (findId) return findId.thai_name;
    else return "";
  };

  return (
    <CustomizedBox margin={0}>
      <Typography sx={{ fontWeight: 700, mb: 3 }}>
        {t("description")}
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={disabled ? 12 : 10}
          sm={disabled ? 12 : 10}
          md={6}
          lg={4}
          xl={4}
          display="flex"
          justifyContent="center"
        >
          <Controller
            control={control}
            name="document_id"
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.adjustment.documentId")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                required
                disabled={!isCreate}
              />
            )}
          />
        </Grid>
        {disabled ? null : (
          <Grid item xs={2}>
            <Box sx={{ ml: -1 }}>
              <CustomizedTooltip title="เรียกเลขที่เอกสารใหม่">
                <IconButton
                  onClick={() => generateDocumentId()}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"source_warehouse_document_id"}
            render={({ field }) => (
              <CustomizedComboBox
                options={warehouseOption}
                label={t("inventory.warehouse")}
                onChange={(_, newValue) => {
                  const currValue = getValues();
                  if (newValue && newValue.value) {
                    reset({
                      ...currValue,
                      source_warehouse_document_id: newValue.value,
                      goods_adjustment_list: [],
                    });
                    field.onChange(newValue.value);
                  } else {
                    reset({
                      ...currValue,
                      source_warehouse_document_id: "",
                      goods_adjustment_list: [],
                    });

                    field.onChange("");
                  }
                  resetFieldBarcode("barcode");
                }}
                error={Boolean(errors.source_warehouse_document_id)}
                helperText={errors.source_warehouse_document_id?.message}
                value={mapWarehouseIdToName()}
                disabled={!isCreate}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"remark"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.remark")}
                error={Boolean(errors.remark)}
                helperText={errors.remark?.message}
                disabled={!isCreate}
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default AdjustmentDetail;
