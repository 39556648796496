import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { filteredRow } from "../../../utils/dataTransformer";
import AgGrid from "../../Table/AgGrid";

const UserPermission = ({ columnDefs, priviledgeList, resource }) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "ACCOUNT",
        name: t("user.account.index"),
        view: "view",
        create: "create",
        edit: "edit",
      },
      {
        functionName: "RBAC",
        name: t("user.rbac.index"),
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
      },
    ];
    return filteredRow(rows, priviledgeList, resource);
  }, [priviledgeList, resource, t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      isClientSide
    />
  );
};

export default UserPermission;
