import { useEffect, useMemo, useState } from "react";
import { filterParamsOptions } from "../../utils/filterparams";
import { formatNumberWithValidate } from "../../utils/dataTransformer";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

const useItemCurrentStockColumnDef = (
  isReport,
  allWarehouse,
  getAllItemGroupLevel1
) => {
  const { t } = useTranslation();

  const defaultColumn = useMemo(
    () => [
      {
        field: "item_document_id",
        headerName: t("inventory.items.itemId"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_name",
        headerName: t("inventory.items.itemName"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_description",
        headerName: t("inventory.items.itemDescription"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_group_sub_level_1_name",
        headerName: t("inventory.items.itemGroup") + " " + 1,
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params) => {
            // fetch values from server
            const values = await getAllItemGroupLevel1();
            const formatValues = values.map((group) => group.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "sum_qty",
        headerName: "ทั้งหมด",
        filter: false,
        valueFormatter: (params) => formatNumberWithValidate(params.value),
      },
      {
        field: "uom_name",
        headerName: t("inventory.unit"),
        sortable: false,
        filter: false,
      },
    ],
    [getAllItemGroupLevel1, t]
  );

  const [columnDefs, setColumnDefs] = useState(defaultColumn);

  const appendWarehouseName = useCallback(() => {
    const newColumn = [...defaultColumn];
    allWarehouse.forEach((warehouse) => {
      if (warehouse.document_id === "WH02") {
        newColumn.splice(4, 0, {
          field: "warehouse_2_qty",
          headerName: warehouse.thai_name,
          filter: false,
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        });
      }
      if (warehouse.document_id === "WH01") {
        newColumn.splice(4, 0, {
          field: "warehouse_1_qty",
          headerName: warehouse.thai_name,
          filter: false,
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        });
      }
    });
    setColumnDefs(newColumn);
  }, [allWarehouse, defaultColumn]);

  useEffect(() => {
    if (isReport) {
      appendWarehouseName();
    }
  }, [isReport, appendWarehouseName]);

  // {
  //   field: "WH01",
  //   headerName: "คลังที่ 1",
  //   filter: false,
  //   valueFormatter: (params) => formatNumberWithValidate(params.value),
  // },
  // {
  //   field: "WH02",
  //   headerName: "คลังที่ 2",
  //   filter: false,
  //   valueFormatter: (params) => formatNumberWithValidate(params.value),
  // },

  return columnDefs;
};

export default useItemCurrentStockColumnDef;
