import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useRef, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getAllItemsAggrid } from "../../../../features/Inventory/ItemMaster/itemMaster-actions";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";
import BomItemTable from "../../../Table/DocumentTable/BomItemTable";
import ItemTable from "../../../Table/ItemTable";
import ModalUI from "../../../UI/ModalUI";
import { v4 as uuidv4 } from "uuid";

const BomTableForm = ({ control, errors, disabled, isMainPage }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const selectItemListRef = useRef();
  const [openItemListModal, setOpenItemListModal] = useState(false);

  const openItemListModalHandler = () => {
    setOpenItemListModal(true);
  };

  const closeItemListModalHandler = () => {
    setOpenItemListModal(false);
  };

  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { document_id, name, is_active, item_group_sub_level_1, tag_list } =
        request.filterModel;
      const formatFilterModal = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        name: name && {
          ...name,
          mode: "insensitive",
        },
        tag_list: tag_list
          ? {
              filterType: "objectArray",
              type: "some",
              filter: {
                name: {
                  filterType: "set",
                  values: tag_list.values,
                },
              },
            }
          : {},
        item_group_sub_level_1: item_group_sub_level_1 && {
          filterType: "object",
          filter: {
            name: {
              ...item_group_sub_level_1,
              mode: "insensitive",
            },
          },
        },
        is_active: is_active &&
          is_active.values.length > 0 && {
            filterType: "boolean",
            type: "equals",
            filter: is_active.values[0],
          },
        is_manufactuable: {
          filterType: "boolean",
          type: "equals",
          filter: "true",
        },
      };

      dispatch(
        getAllItemsAggrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModal,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    const instance = params.api.getFilterInstance("is_active");
    instance.setModel({
      filterType: "set",
      values: ["true"],
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "ingredient_list",
  });

  const handleSelectItems = () => {
    const selectedItems = selectItemListRef.current.api.getSelectedRows();
    selectedItems.forEach((item) => {
      const formatItem = {
        uid: uuidv4(),
        item_document_id: item?.document_id ?? "",
        item_name: item?.name ?? "",
        item_description: item?.description ?? "",
        qty: 1,
        uom: item?.base_uom.name,
        current_ordered_purchase_qty: item?.current_ordered_purchase_qty ?? 0,
        current_ordered_manufacture_qty:
          item?.current_ordered_manufacture_qty ?? 0,
        current_committed_sales_qty: item?.current_committed_sales_qty ?? 0,
        current_committed_manufacture_qty:
          item?.current_committed_manufacture_qty ?? 0,
        current_stock_qty: item?.current_stock_qty ?? 0,
        current_available_qty: item?.current_available_qty ?? 0,
      };
      append(formatItem);
    });
    closeItemListModalHandler();
  };

  return (
    <CustomizedBox
      boxShadow={!isMainPage ? "none" : undefined}
      margin={!isMainPage ? 0 : "2rem 0 2rem 0"}
    >
      <Typography ml={1} fontWeight="bold">
        {t("manufacture.bom.ingredient")}
      </Typography>
      <BomItemTable
        control={control}
        errors={errors}
        fields={fields}
        remove={remove}
        disabled={disabled}
        name="ingredient_list"
      />
      {!disabled && (
        <>
          <CustomizedButton
            color="secondary"
            variant="outlined"
            title="เพิ่มส่วนประกอบ"
            onClick={openItemListModalHandler}
          />
          <ModalUI
            open={openItemListModal}
            handleClose={closeItemListModalHandler}
            title="สินค้า"
            width={1500}
          >
            <ItemTable
              gridRef={selectItemListRef}
              height={450}
              onGridReady={onGridReady}
              rowSelection="multiple"
              enableCheckbox
              getRowId={getRowId}
            />
            <CustomizedButton
              title="เพิ่ม"
              variant="contained"
              onClick={handleSelectItems}
              sx={{ mt: 2 }}
            />
          </ModalUI>
        </>
      )}
    </CustomizedBox>
  );
};

export default BomTableForm;
