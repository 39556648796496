import { Avatar, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import AgGrid from "../../../components/Table/AgGrid";
import { getGoodReceiveTab } from "../../../features/Sales/SalesReturn/sales-return-actions";
import { salesReturnActions } from "../../../features/Sales/SalesReturn/sales-return-slice";
import {
  dateComparator,
  filterParamsOptions,
} from "../../../utils/filterparams";

const GoodReceiveTab = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { goodReceiveTab } = useSelector((state) => state.salesReturn);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getGoodReceiveTab(id, enqueueSnackbar));
    }
    return () => dispatch(salesReturnActions.resetGoodReceiveTab());
  }, [dispatch, enqueueSnackbar, id]);

  const onRowDoubleClicked = (params) => {
    navigate(
      `/inventory/receive/${encodeURIComponent(params.data.document_id)}`
    );
  };

  const columnDefs = [
    {
      field: "created_date",
      headerName: t("inventory.documentDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
      valueFormatter: (params) => {
        if (!params.data.created_date) return "";
        return params.data.created_date;
      },
    },
    {
      field: "document_date",
      headerName: t("inventory.return.returnDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
      valueFormatter: (params) => {
        if (!params.data.document_date) return "";
        return params.data.document_date;
      },
    },
    {
      field: "document_id",
      headerName: t("inventory.return.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "reference_document_id",
      headerName: t("inventory.referenceNo"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      // hide: true,
    },

    {
      field: "source_warehouse.thai_name",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "type",
      headerName: t("inventory.return.returnType"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["รับคืนจากลูกค้า", "รับคืนจากผลิต"],
      },
    },
    {
      field: "created_by",
      headerName: t("inventory.return.importedBy"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.value.img_url) {
          return <Avatar alt="img_url" src={params.value.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.value.first_name + " " + params.value.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "remark",
      headerName: t("inventory.remark"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("inventory.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["draft", "finished", "cancelled"],
        valueFormatter: (params) => {
          if (params.value === "draft") {
            return "ร่าง";
          }
          if (params.value === "finished") {
            return "เสร็จสิ้น";
          }
          return "ยกเลิก";
        },
      },
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.data.status} />;
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getGoodReceiveTab(id, enqueueSnackbar));
    }
  }, [dispatch, enqueueSnackbar, id]);
  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        ใบ{t("inventory.receive.index")}
      </Typography>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={goodReceiveTab}
        height={650}
        onRowDoubleClicked={onRowDoubleClicked}
        path={`/inventory/receive`}
      />
    </>
  );
};

export default GoodReceiveTab;
