import { Grid, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedTextField from "../../Custom/CustomizedTextField";

const InternalRemark = ({ control, disabled }) => {
  const { t } = useTranslation();
  return (
    <CustomizedBox margin="0 0 2rem 0">
      <Typography sx={{ fontWeight: "bold", mb: 2 }}>
        {t("logistic.internalRemark")}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
          <Controller
            name="internal_remark"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                multiline
                rows={4}
                {...field}
                disabled={disabled}
              />
            )}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default InternalRemark;
