import { createSlice } from "@reduxjs/toolkit";
import { bomInitialState as initialState } from "./bom-initial";

const bomSlice = createSlice({
  name: "bom",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllBoms(state, action) {
      state.allBoms = action.payload;
      state.isLoading.allBoms = false;
    },
    loadedAllBomsExport(state, action) {
      state.allBomsExport = action.payload;
      state.isLoading.allBomsExport = false;
    },
    loadedBom(state, action) {
      state.bom = action.payload;
      state.isLoading.bom = false;
    },
    resetAllBoms(state) {
      state.allBoms = initialState.allBoms;
      state.error = null;
    },
    resetAllBomsExport(state) {
      state.allBomsExport = initialState.allBomsExport;
      state.error = null;
    },
    resetBom(state) {
      state.bom = initialState.bom;
      state.isLoading.bom = false;
      state.error = null;
    },
  },
});

export const bomActions = bomSlice.actions;

export default bomSlice.reducer;
