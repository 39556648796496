import { useEffect, useState } from "react";
import { permissionAction } from "../../utils/userInfo";

const allPermissionList = permissionAction();

const formatAction = (formatList) => {
  return formatList.reduce((a, v) => {
    const action = v.split("__")[2];
    return { ...a, [action]: v };
  }, {});
};

export const useCompanyInfoPermission = () => {
  const [settingPermission, setSettingPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__COMPANY_INFO")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);

  return settingPermission;
};

export const useApprovalSettingPermission = () => {
  const [settingPermission, setSettingPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__APPROVAL")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);

  return settingPermission;
};

export const useSalesRemarkPermission = () => {
  const [settingPermission, setSettingPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__SALES_REMARK")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);

  return settingPermission;
};

export const usePurchaseRemarkPermission = () => {
  const [settingPermission, setSettingPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__PURCHASE_REMARK")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);

  return settingPermission;
};

export const useLogisticRemarkPermission = () => {
  const [settingPermission, setSettingPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__LOGISTIC_REMARK")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);

  return settingPermission;
};

export const useManufactureRemarkPermission = () => {
  const [settingPermission, setSettingPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__MANUFACTURE_REMARK")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);

  return settingPermission;
};

export const useLocationPermission = () => {
  const [settingPermission, setSettingPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__LOCATION")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);

  return settingPermission;
};

export const useCategoryPermission = () => {
  const [settingPermission, setSettingPermission] = useState();
  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__CATEGORY")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);
  return settingPermission;
};

export const useUomPermission = () => {
  const [settingPermission, setSettingPermission] = useState();
  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__UOM")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);
  return settingPermission;
};

export const useAttributePermission = () => {
  const [settingPermission, setSettingPermission] = useState();
  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__ATTRIBUTE")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);
  return settingPermission;
};

export const useManufactureSettingPermission = () => {
  const [settingPermission, setSettingPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__MANUFACTURE")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);

  return settingPermission;
};

export const useLogisticSettingPermission = () => {
  const [settingPermission, setSettingPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONFIG__LOGISTIC")
    );
    const actions = formatAction(formatList);
    setSettingPermission(actions);
  }, []);

  return settingPermission;
};
