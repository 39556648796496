import { useEffect, useState } from "react";
import { filterParamsOptions } from "../../utils/filterparams";
import { formatNumberWithValidate } from "../../utils/dataTransformer";
import { useTranslation } from "react-i18next";

const useItemCurrentStockSNColumnDef = (isReport, getAllItemGroupLevel1) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (isReport) {
      setColumnDefs([
        {
          field: "item_group_sub_level_1_name",
          headerName: t("inventory.items.itemGroup") + " " + 1,
          filter: "agSetColumnFilter",
          filterParams: {
            values: async (params) => {
              // fetch values from server
              const values = await getAllItemGroupLevel1();
              const formatValues = values.map((group) => group.name);
              params.success(formatValues);
            },
          },
        },
        {
          field: "item_document_id",
          headerName: t("inventory.items.itemId"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_name",
          headerName: t("inventory.items.itemName"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_description",
          headerName: t("inventory.items.itemDescription"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "current_quantity",
          headerName: t("inventory.itemCurrentStock.currentQuantity"),
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("number"),
          },
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        },
        {
          field: "serial_number",
          headerName: t("inventory.serialNumber"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "barcode",
          headerName: "Barcode",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "warehouse_thai_name",
          headerName: t("inventory.warehouse"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "bin_location_document_id",
          headerName: t("inventory.location"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
      ]);
    }
  }, [getAllItemGroupLevel1, isReport, t]);
  return columnDefs;
};

export default useItemCurrentStockSNColumnDef;
