import React, { useRef } from "react";
import AgGrid from "../../Table/AgGrid";

const ContactPermission = ({ columnDefs, contactRow }) => {
  const gridRef = useRef();

  return (
    <AgGrid
      rowData={contactRow}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      isClientSide
    />
  );
};

export default ContactPermission;
