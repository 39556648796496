import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PDFFooter from "../../../../components/UI/PDFFooter";
import PDFHeader from "../../../../components/UI/PDFHeader";
import DeliveryOrderPDFInfo from "./Info";
import { useReactToPrint } from "react-to-print";
import PDFSigner from "../../../../components/UI/PDFSigner";
import { useDispatch, useSelector } from "react-redux";
import { getDeliveryOrderById } from "../../../../features/Logistic/DeliveryOrder/delivery-order-actions";
import DeliveryOrderPdf from "../../../../components/Table/Pdf/DeliveryOrderPdf";
import CustomizedScrollbar from "../../../../components/Custom/CustomizedScrollbar";
import { deliveryOrderActions } from "../../../../features/Logistic/DeliveryOrder/delivery-order-slice";
import { useSnackbar } from "notistack";

const DeliveryOrderPDF = () => {
  const dispatch = useDispatch();
  const { deliveryOrder } = useSelector((state) => state.deliveryOrder);
  const { enqueueSnackbar } = useSnackbar();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: deliveryOrder?.document_id,
  });
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getDeliveryOrderById(
        {
          document_id: id,
        },
        enqueueSnackbar
      )
    );
    return () => {
      dispatch(deliveryOrderActions.resetDeliveryOrder());
    };
  }, [dispatch, enqueueSnackbar, id]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">ใบส่งของ/Delivery Order</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>

      <Box sx={{ m: "10px auto", width: "fit-content" }}>
        <Box
          sx={{
            width: "210mm",
            height: "297mm",
            border: "1px solid #eee",
            borderRadius: "5px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <CustomizedScrollbar
            sx={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                p: 3,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              ref={componentRef}
            >
              <Box>
                <PDFHeader documentType="ใบส่งของ/Delivery Order" />
                <Divider />
                <DeliveryOrderPDFInfo deliveryOrder={deliveryOrder} />
                <Divider />
                <DeliveryOrderPdf itemList={deliveryOrder.item_list} />
                <PDFFooter data={deliveryOrder} documentType="delivery_order" />
                <Divider sx={{ mt: 2 }} />
              </Box>
              <Box sx={{ py: 3, breakInside: "avoid" }}>
                <PDFSigner documentType="delivery_order" />
              </Box>
            </Box>
          </CustomizedScrollbar>
        </Box>
      </Box>
    </Box>
  );
};

export default DeliveryOrderPDF;
