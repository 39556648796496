import SalesService from "../../../services/Sales";
import { depositInvoiceActions } from "./deposit-invoice-slice";
import { depositInvoiceQueryFormatter } from "../../../utils/salesPayloadFormatter";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import { filterStatusValueFormatter } from "../../../utils/filterparams";

export const getAllDepositInvoices =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(depositInvoiceActions.onLoading("allDepositInvoices"));
    try {
      const allDepositInvoices = await SalesService.getAllDepositInvoices(
        input
      );
      params.successCallback(
        allDepositInvoices.results,
        allDepositInvoices.count
      );
      dispatch(
        depositInvoiceActions.loadedAllDepositInvoices(
          allDepositInvoices.results
        )
      );
    } catch (err) {
      dispatch(
        depositInvoiceActions.rejectedActions({
          ...err,
          name: "allDepositInvoices",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllDepositInvoicesExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(depositInvoiceActions.onLoading("allDepositInvoices"));
    try {
      const { results } = await SalesService.getAllDepositInvoices(input);
      const formatAllDepositInvoices = results.map((depositInvoice) => ({
        ...depositInvoice,
        issue_date: unixToDateWithFormat(depositInvoice.issue_date),
        created_date: unixToDateWithFormat(depositInvoice.created_date),
        updated_date: unixToDateWithFormat(depositInvoice.updated_date),
        render_status: filterStatusValueFormatter(depositInvoice.render_status),
      }));
      dispatch(
        depositInvoiceActions.loadedAllDepositInvoices(formatAllDepositInvoices)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        depositInvoiceActions.rejectedActions({
          ...err,
          name: "allDepositInvoices",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllDepositInvoicesExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(depositInvoiceActions.onLoading("allDepositInvoices"));
    try {
      const { results } = await SalesService.getAllDepositInvoicesReport(input);
      const formatAllDepositInvoices = results.map((depositInvoice) => ({
        ...depositInvoice,
        issue_date: unixToDateWithFormat(depositInvoice.issue_date),
        created_date: unixToDateWithFormat(depositInvoice.created_date),
        updated_date: unixToDateWithFormat(depositInvoice.updated_date),
        render_status: filterStatusValueFormatter(depositInvoice.render_status),
      }));
      dispatch(
        depositInvoiceActions.loadedAllDepositInvoices(formatAllDepositInvoices)
      );
    } catch (err) {
      console.log(err);
      dispatch(
        depositInvoiceActions.rejectedActions({
          ...err,
          name: "allDepositInvoices",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewDepositInvoice =
  (payload, isSendApprove, state, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    try {
      const result = await SalesService.createDepositInvoice(payload);
      const createActivityLog = createActivityLogPayload(
        payload,
        "deposit_invoice",
        "สร้างใบแจ้งหนี้มัดจำ"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });

      if (state && state?.isCopied) {
        const copiedActivityLog = createActivityLogCopiedPayload(
          state,
          "deposit_invoice"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }

      if (isSendApprove) {
        const documentInput = {
          document_type: "deposit_invoice",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          payload,
          "deposit_invoice",
          null,
          "wait_approve"
        );
        await SalesService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
          variant: "success",
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          payload,
          "deposit_invoice",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(
        `/sales/deposit-invoice/${encodeURIComponent(result.document_id)}`
      );
    } catch (err) {
      dispatch(
        depositInvoiceActions.rejectedActions({
          ...err,
          name: "depositInvoice",
        })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบแจ้งหนี้มัดจำไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getDepositInvoiceById =
  (uniqueInput, enqueueSnackbar) => async (dispatch) => {
    dispatch(depositInvoiceActions.onLoading("depositInvoice"));
    try {
      const depositInvoice = await SalesService.getDepositInvoiceById(
        uniqueInput
      );
      const formatDepositInvoice = await depositInvoiceQueryFormatter(
        depositInvoice
      );

      // const approvalProgress = await SalesService.getApprovalProgress({
      //   reference_document_type: "deposit_invoice",
      //   reference_document_id: uniqueInput.document_id,
      // });

      // dispatch(
      //   depositInvoiceActions.loadedApprovalList({
      //     approvalProgress,
      //   })
      // );

      dispatch(
        depositInvoiceActions.loadedDepositInvoice(formatDepositInvoice)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        depositInvoiceActions.rejectedActions({
          ...err,
          name: "depositInvoice",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const updateDepositInvoice =
  (payload, status, isChangedStatus, existing, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(depositInvoiceActions.onLoading("depositInvoice"));
    try {
      const depositInvoice = await SalesService.updateDepositInvoice(payload);
      const formatDepositInvoice = await depositInvoiceQueryFormatter(
        depositInvoice
      );
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "deposit_invoice",
        };
        const updatedDepositInvoice = await SalesService.postNextStatus(
          documentInput
        );
        if (
          updatedDepositInvoice?.approval_progress_list
            ?.map((approval) => approval?.approval_status)
            .includes("PENDING")
        ) {
          const sendToApproveActivityLog = createActivityLogStatusPayload(
            payload,
            "deposit_invoice",
            "draft",
            "wait_approve"
          );
          dispatch(
            depositInvoiceActions.loadedDepositInvoice({
              ...formatDepositInvoice,
              render_status: "waitApprove",
            })
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToApproveActivityLog,
          });
          enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
            variant: "success",
          });
        } else {
          dispatch(
            depositInvoiceActions.loadedDepositInvoice({
              ...formatDepositInvoice,
              render_status: updatedDepositInvoice.status,
            })
          );
          enqueueSnackbar("อัพเดทใบแจ้งหนี้มัดจำสำเร็จ", {
            variant: "success",
          });
        }
      } else {
        const editActivityLog = createActivityLogEditPayload(
          payload,
          "deposit_invoice"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
        const { addedEmployeeList, deletedEmployeeList } =
          findDifferenceEmployee(payload, existing);
        if (addedEmployeeList && addedEmployeeList.length > 0) {
          // add related employee
          const addedEmployeeListLog = createActivityLogEmployeePayload(
            payload,
            "add_related_employee",
            addedEmployeeList,
            "deposit_invoice"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: addedEmployeeListLog,
          });
        }
        if (deletedEmployeeList && deletedEmployeeList.length > 0) {
          // delete related employee
          const deletedEmployeeListLog = createActivityLogEmployeePayload(
            payload,
            "delete_related_employee",
            deletedEmployeeList,
            "deposit_invoice"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: deletedEmployeeListLog,
          });
        }
        switch (status) {
          case "draft":
            dispatch(
              depositInvoiceActions.loadedDepositInvoice(formatDepositInvoice)
            );
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
            break;
          default:
            dispatch(
              depositInvoiceActions.loadedDepositInvoice({
                ...formatDepositInvoice,
                render_status: status,
              })
            );
            enqueueSnackbar("แก้ไขใบแจ้งหนี้มัดจำสำเร็จ", {
              variant: "success",
            });
        }
      }
    } catch (err) {
      dispatch(
        depositInvoiceActions.rejectedActions({
          ...err,
          name: "depositInvoice",
        })
      );
      switch (status) {
        case "draft":
          if (isChangedStatus) {
            err.response.errors.forEach((error) => {
              if (errorMessageHandler(error.message)) {
                enqueueSnackbar(errorMessageHandler(error.message), {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("แก้ไขใบแจ้งหนี้มัดจำไม่สำเร็จ", {
                  variant: "error",
                });
              }
            });
          } else {
            enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        default:
          enqueueSnackbar("แก้ไขใบแจ้งหนี้มัดจำไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const approveDepositInvoice =
  (documentInput, payload, enqueueSnackbar) => async (dispatch) => {
    dispatch(depositInvoiceActions.onLoading("depositInvoice"));
    try {
      const approvedActivityLog = createActivityLogStatusPayload(
        payload,
        "deposit_invoice",
        "wait_approve",
        "wait_payment"
      );
      await SalesService.postApproveStatus(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: approvedActivityLog,
      });
      enqueueSnackbar("อนุมัติสำเร็จ", {
        variant: "success",
      });
      dispatch(depositInvoiceActions.updateDepositInvoiceStatus("waitPayment"));
    } catch (err) {
      console.error(err);
      dispatch(
        depositInvoiceActions.rejectedActions({
          ...err,
          name: "depositInvoice",
        })
      );
      enqueueSnackbar("อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const declineDepositInvoice =
  (documentInput, payload, enqueueSnackbar) => async (dispatch) => {
    dispatch(depositInvoiceActions.onLoading("depositInvoice"));
    try {
      const notApprovedActivityLog = createActivityLogStatusPayload(
        payload,
        "deposit_invoice",
        "wait_approve",
        "not_approved"
      );
      await SalesService.postDeclineStatus(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: notApprovedActivityLog,
      });
      enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
        variant: "success",
      });
      dispatch(depositInvoiceActions.updateDepositInvoiceStatus("notApproved"));
    } catch (err) {
      console.error(err);
      dispatch(
        depositInvoiceActions.rejectedActions({
          ...err,
          name: "depositInvoice",
        })
      );
      enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const cancelDepositInvoice =
  (documentInput, payload, enqueueSnackbar) => async (dispatch) => {
    dispatch(depositInvoiceActions.onLoading("depositInvoice"));
    try {
      const cancelActivityLog = createActivityLogStatusPayload(
        payload,
        "deposit_invoice",
        null,
        "cancelled"
      );
      await SalesService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(depositInvoiceActions.updateDepositInvoiceStatus("cancelled"));
    } catch (err) {
      dispatch(
        depositInvoiceActions.rejectedActions({
          ...err,
          name: "depositInvoice",
        })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
