import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../Table/AgGrid";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import TotalPreVatAmount from "../TotalPreVatAmount";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ModalUI from "../../../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllItemsAggrid,
  getItem,
} from "../../../../features/Inventory/ItemMaster/itemMaster-actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { diColumnDefs } from "../ColumnDefs/diColumnDefs";
import { rtColumnDefs } from "../ColumnDefs/rtColumnDefs";
import { columnDefs } from "../ColumnDefs/columnDefs";
import { soColumnDefs } from "../ColumnDefs/soColumnDefs";
import { srColumnDefs } from "../ColumnDefs/srColumnDefs";
import { doColumnDefs } from "../ColumnDefs/doColumnDefs";
import { useLocation, useSearchParams } from "react-router-dom";
import { validation } from "../../../../pages/Inventory/Items/schema";
import { formatVatOption } from "../../../../utils/dataTransformer";
import { getAllUom } from "../../../../features/Setting/Uom/uom-actions";
import { itemMasterActions } from "../../../../features/Inventory/ItemMaster/itemMaster-slice";
import ItemInfo from "../../../../pages/Inventory/Items/ItemInfo";
import { v4 as uuidv4 } from "uuid";
import DeleteItemConfirmation from "../../../UI/Confirmation/DeleteItemConfirmation";
import ItemListTable from "../../../Table/DocumentTable/ItemListTable";
import { getQuotationPriceList } from "../../../../features/Sales/Quotation/quotation-actions";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { Box } from "@mui/material";
import CustomizedTab from "../../../Custom/CustomizedTab";
import { quotationActions } from "../../../../features/Sales/Quotation/quotation-slice";
import { dtColumndefs } from "../ColumnDefs/dtColumnDefs";

const AddItemListForm = ({
  control,
  contactUseForm,
  setValue,
  getValues,
  errors,
  gridRef,
  viewOnly,
  documentType,
  itemListRef,
  isFooter,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { item } = useSelector((state) => state.itemMaster);
  const { quotationPriceList, orderPriceList, isLoading } = useSelector(
    (state) => state.quotation
  );
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openItemsModal, setOpenItemsModal] = useState(false);
  const [openItemHistoryModal, setItemHistoryModal] = useState(false);
  const [openItemDetailModal, setOpenItemDetailModal] = useState(false);
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);
  const [selectedPriceIndex, setSelectedPriceIndex] = useState(null);
  const [currentTab, setCurrentTab] = useState(pathname);

  const itemTableGridRef = useRef();
  const itemHistoryRef = useRef();
  const subtab = searchParams.get("subtab");

  const { control: contactControl } = contactUseForm;

  const contact_document_id = useWatch({
    control: contactControl,
    name: "document_id",
  });

  // const additional_discount = useWatch({
  //   control,
  //   name: "additional_discount",
  // });

  // const { pre_vat_amount } = useWatch({ control, name: "summary" });

  const watchStatus = useWatch({
    control,
    name: "render_status",
  });

  const tabs = [
    {
      label: t("sales.quotation.index"),
      path: `${pathname}?subtab=quotation-list`,
    },
    {
      label: t("sales.order.index"),
      path: `${pathname}?subtab=order-list`,
    },
  ];

  useEffect(() => {
    switch (subtab) {
      case "quotation-list":
        setCurrentTab(pathname + "?subtab=quotation-list");
        break;
      case "order-list":
        setCurrentTab(pathname + "?subtab=order-list");
        break;
      default:
        setCurrentTab(pathname + "?subtab=quotation-list");
        break;
    }
  }, [pathname, subtab]);

  const {
    control: itemInfoControl,
    handleSubmit: handleSubmitItemInfo,
    getValues: getItemInfoValues,
    setValue: setItemInfoValue,
    formState: { errors: itemInfoErrors },
    reset: resetItemInfo,
    clearErrors: clearItemInfoErrors,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    if (item.document_id !== "") {
      Object.entries(item).forEach(([key, value]) => {
        if (key === "sales_vat_type")
          setItemInfoValue(key, formatVatOption(value));
        else if (key === "attribute_list")
          setItemInfoValue(
            key,
            value.map((item) => item.attribute)
          );
        else setItemInfoValue(key, value);
      });
    }
  }, [item, setItemInfoValue]);

  const { fields, append, remove } = useFieldArray({
    name: "item_list",
    control,
  });

  const headerClass = ["center"];

  const handleSelectItems = () => {
    const selectedItems = itemTableGridRef.current.api.getSelectedRows();
    selectedItems.forEach((item) => {
      if (item.item_document_id) {
        const existingItemList = getValues("item_list");
        if (
          existingItemList.findIndex((oldItem) => oldItem.uid === item.uid) < 0
        ) {
          if (documentType === "do") {
            const formatItem = {
              uid: item.uid,
              item_document_id: item.item_document_id,
              item_name: item.item_name,
              item_description: item.item_description,
              item_remark: item.item_remark,
              qty: item.qty,
              qty_sales: item.qty,
              qty_sales_delivered: item.qty,
              uom_id: item.uom_id,
              uom: item.uom,
              sales_uom: item.uom,
              qty_uom: item.qty_uom,
              qty_sales_uom: item.qty_uom,
              price_per_unit: item.price_per_unit,
              discount_amount: item.discount_amount,
              vat_type: item.vat_type,
              withholding_tax: item.withholding_tax,
              pre_vat_amount: item.pre_vat_amount,
              uom_group: item.uom_group,
              current_ordered_purchase_qty: item.current_ordered_purchase_qty,
              current_ordered_manufacture_qty:
                item.current_ordered_manufacture_qty,
              current_committed_sales_qty: item.current_committed_sales_qty,
              current_stock_qty: item.current_stock_qty,
              current_available_qty: item.current_available_qty,
              volume: item.volume,
              weight: item.weight,
              is_active: item.is_active,
            };
            append(formatItem);
          } else if (documentType === "sr") {
            append({
              ...item,
              qty_return: 1,
            });
          } else {
            append(item);
          }
        }
      } else {
        let formatItem = {
          uid: uuidv4(),
          item_document_id: (item?.document_id || item?.item_document_id) ?? "",
          item_name: (item?.name || item?.item_name) ?? "",
          item_description: (item?.description || item?.item_description) ?? "",
          qty: 1,
          uom: {
            document_id: item?.uom?.document_id ?? item?.base_uom.document_id,
            id: item?.uom?.id ?? item?.base_uom.id,
            name: item?.uom?.name ?? item?.base_uom.name,
          },
          uom_id: item?.uom_id ?? item?.base_uom.id,
          qty_uom: 1,
          bom_description: "",
          width_length_tolerance_positive: 0,
          width_length_tolerance_negative: 0,
          thickness_tolerance_positive: 0,
          thickness_tolerance_negative: 0,
          price_per_unit: item?.sales_standard_price ?? 0,
          discount_amount: 0,
          vat_type: item?.sales_vat_type ?? "ไม่มี",
          pre_vat_amount: item?.sales_standard_price ?? 0,
          withholding_tax: {
            type: "ยังไม่ระบุ",
            amount: 0,
          },
          item_remark: item?.item_remark ?? "",
          purchase_standard_price: item?.purchase_standard_price,
          uom_group: item?.uom_group,
          current_ordered_purchase_qty: item?.current_ordered_purchase_qty ?? 0,
          current_ordered_manufacture_qty:
            item?.current_ordered_manufacture_qty ?? 0,
          current_committed_sales_qty: item?.current_committed_sales_qty ?? 0,
          current_committed_manufacture_qty:
            item?.current_committed_manufacture_qty ?? 0,
          current_stock_qty: item?.current_stock_qty ?? 0,
          current_available_qty: item?.current_available_qty ?? 0,
          is_active: item.is_active,
        };
        append(formatItem);
      }
    });
    setOpenItemsModal(false);
  };

  const addRowHandler = () => {
    const newRow = {
      item_document_id: uuidv4(),
      description: "",
      vat_type: "ไม่มี",
      pre_vat_amount: 0,
    };
    append(newRow);
  };

  const openDeleteItemConfirmationHandler = (index) => {
    setDeletedIndex(index);
    setOpenDeleteItemModal(true);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setDeletedIndex(null);
    setOpenDeleteItemModal(false);
  };

  const handleRemoveItems = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const handleShowItemModal = (data) => {
    dispatch(getItem({ document_id: data.item_document_id }, enqueueSnackbar));
    dispatch(getAllUom());
    setOpenItemDetailModal(true);
  };

  const handleCloseItemDetailModal = () => {
    searchParams.delete("itemtab");
    setSearchParams(searchParams);
    clearItemInfoErrors();
    setOpenItemDetailModal(false);
    dispatch(itemMasterActions.resetItem());
  };

  const handleShowItemHistoryModal = (params) => {
    dispatch(
      getQuotationPriceList(
        params.data.item_document_id,
        contact_document_id,
        enqueueSnackbar
      )
    );
    setSelectedPriceIndex(params.node.rowIndex);
    setSearchParams({ subtab: "quotation-list" });
    setItemHistoryModal(true);
  };

  const handleCloseItemHistoryModal = () => {
    setSearchParams();
    setItemHistoryModal(false);
    dispatch(quotationActions.resetQuotationPriceList());
  };

  const handleItemHistorySelect = () => {
    const [selectedItem] = itemHistoryRef.current.api.getSelectedRows();

    setValue(
      `item_list[${selectedPriceIndex}].price_per_unit`,
      selectedItem.price_per_unit
    );
    const newPreVatAmount =
      getValues(`item_list[${selectedPriceIndex}].qty`) *
        selectedItem.price_per_unit -
      getValues(`item_list[${selectedPriceIndex}].discount_amount`);

    setValue(
      `item_list[${selectedPriceIndex}].width_length_tolerance_positive`,
      selectedItem.width_length_tolerance_positive
    );
    setValue(
      `item_list[${selectedPriceIndex}].width_length_tolerance_negative`,
      selectedItem.width_length_tolerance_negative
    );
    setValue(
      `item_list[${selectedPriceIndex}].thickness_tolerance_positive`,
      selectedItem.thickness_tolerance_positive
    );
    setValue(
      `item_list[${selectedPriceIndex}].thickness_tolerance_negative`,
      selectedItem.thickness_tolerance_negative
    );
    setValue(
      `item_list[${selectedPriceIndex}].item_remark`,
      selectedItem.item_remark
    );

    if (selectedItem.bom_description) {
      setValue(
        `item_list[${selectedPriceIndex}].bom_description`,
        selectedItem.bom_description
      );
    }
    const rowNode =
      gridRef.current.api.getDisplayedRowAtIndex(selectedPriceIndex);

    setValue(
      `item_list[${selectedPriceIndex}].pre_vat_amount`,
      newPreVatAmount
    );
    rowNode.setDataValue("pre_vat_amount", newPreVatAmount);

    const withholding_tax = getValues(
      `item_list[${selectedPriceIndex}].withholding_tax`
    );
    const pre_vat_amount = getValues(
      `item_list[${selectedPriceIndex}].pre_vat_amount`
    );
    if (withholding_tax) {
      const withholding_tax_amount =
        (pre_vat_amount *
          parseFloat(
            withholding_tax.type === "ยังไม่ระบุ" ||
              withholding_tax.type === "ไม่มี" ||
              withholding_tax.type === ""
              ? 0
              : withholding_tax.type
          )) /
        100;
      setValue(`item_list[${selectedPriceIndex}].withholding_tax`, {
        type: withholding_tax.type,
        amount: withholding_tax_amount,
      });
      rowNode.setDataValue("withholding_tax", {
        type: withholding_tax.type,
        amount: withholding_tax_amount,
      });
    }
    handleCloseItemHistoryModal();
  };

  const switchColDef = () => {
    switch (documentType) {
      case "di":
        return diColumnDefs(
          t,
          control,
          errors,
          viewOnly,
          openDeleteItemConfirmationHandler
        );
      case "rt":
        return rtColumnDefs(t, control, errors, viewOnly);
      case "sr":
        return srColumnDefs(
          t,
          control,
          errors,
          getValues,
          setValue,
          handleShowItemModal,
          openDeleteItemConfirmationHandler,
          viewOnly
        );
      case "do":
        return doColumnDefs(
          t,
          control,
          errors,
          getValues,
          setValue,
          handleShowItemModal,
          openDeleteItemConfirmationHandler,
          viewOnly,
          handleShowItemHistoryModal
        );
      case "so":
        return soColumnDefs(
          t,
          watchStatus,
          control,
          errors,
          getValues,
          setValue,
          handleShowItemModal,
          openDeleteItemConfirmationHandler,
          viewOnly,
          documentType,
          handleShowItemHistoryModal
        );
      case "dt":
        return dtColumndefs(
          t,
          watchStatus,
          control,
          errors,
          getValues,
          setValue,
          handleShowItemModal,
          openDeleteItemConfirmationHandler,
          viewOnly
        );
      default:
        return columnDefs(
          t,
          watchStatus,
          control,
          errors,
          getValues,
          setValue,
          handleShowItemModal,
          openDeleteItemConfirmationHandler,
          viewOnly,
          documentType,
          handleShowItemHistoryModal
        );
    }
  };

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { document_id, name, is_active, item_group_sub_level_1, tag_list } =
        request.filterModel;
      const formatFilterModal = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        name: name && {
          ...name,
          mode: "insensitive",
        },
        tag_list: tag_list
          ? {
              filterType: "objectArray",
              type: "some",
              filter: {
                name: {
                  filterType: "set",
                  values: tag_list.values,
                },
              },
            }
          : {},
        item_group_sub_level_1: item_group_sub_level_1 && {
          filterType: "object",
          filter: {
            name: {
              ...item_group_sub_level_1,
              mode: "insensitive",
            },
          },
        },
        is_active: is_active &&
          is_active.values.length > 0 && {
            filterType: "boolean",
            type: "equals",
            filter: is_active.values[0],
          },
      };

      dispatch(
        getAllItemsAggrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModal,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    const instance = params.api.getFilterInstance("is_active");
    instance.setModel({
      filterType: "set",
      values: ["true"],
    });

    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const onRowDragMove = useCallback(
    (event) => {
      const movingNode = event.node;
      const overNode = event.overNode;
      const rowNeedsToMove = movingNode !== overNode;
      const itemList = getValues("item_list");

      const moveInArray = (arr, fromIndex, toIndex) => {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      };

      if (rowNeedsToMove) {
        // the list of rows we have is data, not row nodes, so extract the data
        const movingData = movingNode.data;
        const overData = overNode.data;
        const fromIndex = itemList
          .map((item) => item.uid)
          .indexOf(movingData.uid);
        const toIndex = itemList.map((item) => item.uid).indexOf(overData.uid);
        moveInArray(itemList, fromIndex, toIndex);
        setValue("item_list", itemList);
        gridRef.current.api.clearFocusedCell();
      }
    },
    [getValues, gridRef, setValue]
  );

  const getRowId = useCallback((params) => {
    return params.data.uid;
  }, []);

  const getItemId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  return (
    <>
      <CustomizedBox
        margin="0"
        radius="0"
        padding="0"
        boxShadow="0px 2px 7px #E5E5E5"
        clipPath="inset(0 -15px -2px -15px)"
        ref={itemListRef}
      >
        <AgGrid
          ref={gridRef}
          columnDefs={switchColDef()}
          rowData={fields}
          onRowDragMove={onRowDragMove}
          rowDragManaged={true}
          getRowId={getRowId}
          suppressMenu
          disabledSidebar={true}
          disableFloatingFilter
          suppressContextMenu
          autoHeight
          headerClass={headerClass}
          isClientSide
          disableResized
        />
      </CustomizedBox>
      <ModalUI
        open={openItemDetailModal}
        handleClose={handleCloseItemDetailModal}
        navigateTo={`/inventory/items/${item.document_id}`}
        title="รายละเอียดสินค้า"
        fullWidth
      >
        <ItemInfo
          control={itemInfoControl}
          errors={itemInfoErrors}
          getValues={getItemInfoValues}
          handleSubmit={handleSubmitItemInfo}
          reset={resetItemInfo}
          setValue={setItemInfoValue}
          onCancel={handleCloseItemDetailModal}
          viewOnly={true}
        />
      </ModalUI>
      <TotalPreVatAmount
        itemTableGridRef={itemTableGridRef}
        onGridReady={onGridReady}
        handleSelectItems={handleSelectItems}
        control={control}
        openModal={openItemsModal}
        setOpenModal={setOpenItemsModal}
        viewOnly={viewOnly}
        documentType={documentType}
        getRowId={getItemId}
        addRowHandler={addRowHandler}
      />
      <DeleteItemConfirmation
        documentType={documentType}
        openDeleteItemConfirmation={openDeleteItemModal}
        closeDeleteItemConfirmationHandler={closeDeleteItemConfirmationHandler}
        deleteItemConfirmationAction={handleRemoveItems}
      />
      <ModalUI
        open={openItemHistoryModal}
        handleClose={handleCloseItemHistoryModal}
        title="ประวัติราคาขายของสินค้า"
        width={1380}
        isLoading={isLoading.quotationPriceList}
      >
        <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
        {subtab === "quotation-list" && (
          <ItemListTable
            gridRef={itemHistoryRef}
            itemList={quotationPriceList}
            documentType="qa"
            viewOnly={viewOnly}
          />
        )}
        {subtab === "order-list" && (
          <ItemListTable
            gridRef={itemHistoryRef}
            itemList={orderPriceList}
            documentType="so"
            viewOnly={viewOnly}
          />
        )}
        {!viewOnly && (
          <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              onClick={handleCloseItemHistoryModal}
            />
            <CustomizedButton
              title="เลือกใช้รายการ"
              variant="contained"
              onClick={handleItemHistorySelect}
            />
          </Box>
        )}
      </ModalUI>
    </>
  );
};

export default AddItemListForm;
