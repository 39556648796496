import { gql } from "graphql-request";

export const CREATE_DELIVERY_ORDER = gql`
  mutation CreateDeliveryOrder(
    $createDeliveryOrderInput: CreateDeliveryOrderInput!
  ) {
    createDeliveryOrder(createDeliveryOrderInput: $createDeliveryOrderInput) {
      id
      document_id
      issue_date
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      creator_document_id
      price_vat_type
      attachment_list {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      attachment_list {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        qty_delivery
        delivery_uom_id
        qty_sales_delivered
        qty_uom
        weight
        volume
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
        delivery_uom {
          document_id
          id
          name
        }
        qty_sales
        sales_uom {
          document_id
          id
          name
        }
        item_remark
      }
      template_remark_id
      remark
      reference_document_id
    }
  }
`;

export const UPDATE_DELIVERY_ORDER = gql`
  mutation UpdateDeliveryOrder(
    $updateDeliveryOrderInput: UpdateDeliveryOrderInput!
  ) {
    updateDeliveryOrder(updateDeliveryOrderInput: $updateDeliveryOrderInput) {
      id
      document_id
      external_ref_id
      reference_document_id
      created_date
      issue_date
      delivery_date
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      updated_date
      template_remark_id
      creator_document_id
      remark
      internal_remark
      recipient_name
      delivery_reference
      delivery_detail
      price_vat_type
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        qty_delivery
        qty_sales_delivered
        delivery_uom_id
        qty_uom
        weight
        volume
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
        delivery_uom {
          document_id
          id
          name
        }
        qty_sales
        sales_uom {
          document_id
          id
          name
        }
        item_remark
      }
      attachment_list {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      target_status
      render_status
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
    }
  }
`;

export const CREATE_DELIVERY_TRIP = gql`
  mutation CreateDeliveryTrip(
    $createDeliveryTripInput: CreateDeliveryTripInput!
  ) {
    createDeliveryTrip(createDeliveryTripInput: $createDeliveryTripInput) {
      id
      document_id
      external_ref_id
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      created_date
      issue_date
      delivery_date
      delivery_type
      goods_deliver_list {
        document_id
        first_name
        last_name
        img_url
      }
      vehicle {
        id
        license_plate
        remark
        remark_status
        status
        vehicle_type
        volume
        weight
      }
      trip
      in_date
      out_date
      internal_remark
      do_document_id_list
      total_volume
      total_weight
      target_status
      render_status
      item_list {
        uid
        item_document_id
        item_name
        item_description
        qty
        uom
        uom_id
        qty_uom
        dt_reference_document_id
        do_reference_document_id
        so_reference_document_id
        item_remark
        customer_name
        volume
        weight
      }
    }
  }
`;

export const UPDATE_DELIVERY_TRIP = gql`
  mutation UpdateDeliveryTrip(
    $updateDeliveryTripInput: UpdateDeliveryTripInput!
  ) {
    updateDeliveryTrip(updateDeliveryTripInput: $updateDeliveryTripInput) {
      id
      document_id
      external_ref_id
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      created_date
      issue_date
      delivery_date
      delivery_type
      total_volume
      total_weight
      goods_deliver_list {
        document_id
        first_name
        last_name
        img_url
      }
      vehicle {
        id
        license_plate
        remark
        remark_status
        status
        vehicle_type
        volume
        weight
      }
      trip
      in_date
      out_date
      internal_remark
      do_document_id_list
      target_status
      render_status
      item_list {
        uid
        item_document_id
        item_name
        item_description
        qty
        uom
        uom_id
        qty_uom
        dt_reference_document_id
        do_reference_document_id
        so_reference_document_id
        item_remark
        customer_name
        volume
        weight
      }
    }
  }
`;
