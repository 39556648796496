import { Controller } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import CustomizedTextField from "./CustomizedTextField";

const ControlledDatePicker = ({
  name,
  control,
  error,
  isOpen,
  onClose,
  onOpen,
  label,
  required,
  disabled,
  disabledPast,
  sx,
  inputFormat,
  minDate,
  maxDate,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            open={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            inputFormat={inputFormat ? inputFormat : "dd/MM/yyyy"}
            label={label}
            onChange={onChange}
            value={value}
            ref={ref}
            minDate={minDate}
            maxDate={maxDate}
            renderInput={(params) => (
              <CustomizedTextField
                {...params}
                ref={params.inputRef}
                error={Boolean(error)}
                helperText={error?.message}
                required={required}
                sx={sx}
              />
            )}
            disablePast={disabledPast}
            disabled={disabled}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default ControlledDatePicker;
