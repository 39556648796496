import moment from "moment";
import { localeStringToFloat, roundDecimal } from "./dataTransformer";
import {
  dateToUnix,
  unixToDate,
  unixToDateWithFormat,
  unixToTimeWithFormat,
  unixToDateTimeWithFormat,
} from "./date-converter";
import InventoryService from "../services/Inventory";
// import GlobalService from "../services/Global";
import LogisticService from "../services/Logistic";
import { filterStatusValueFormatter } from "./filterparams";

export const logisticPayload = (
  headerValues,
  tempValues,
  mainValues,
  transformedEmployeeList,
  user,
  contactSnapshot,
  summary,
  customerDescValues,
  id,
  documentType,
  deliveryDetails
) => {
  const formatItemList = mainValues.item_list.map((item) => {
    return {
      uid: item.uid,
      item_document_id: item.item_document_id,
      item_name: item.item_name,
      item_description: item.item_description,
      qty_delivery: item.qty,
      qty_sales: item.qty_sales,
      qty_sales_delivered: item.qty_sales_delivered,
      delivery_uom_id: item.uom_id.toString(),
      delivery_uom: item.uom,
      sales_uom: item.sales_uom,
      qty_uom: item.qty_uom,
      item_remark: item.item_remark,
      price_per_unit: item.price_per_unit,
      purchase_standard_price: item.purchase_standard_price,
      discount_amount: item.discount_amount,
      vat_type: item.vat_type,
      weight: item.weight,
      volume: item.volume,
      withholding_tax:
        item.withholding_tax.type === "ยังไม่ระบุ"
          ? -1
          : item.withholding_tax.type === "ไม่มี"
          ? 0
          : roundDecimal(item.withholding_tax.type) / 100,
    };
  });

  const payload = {
    ...headerValues,
    ...mainValues,
    ...tempValues,
    ...deliveryDetails,
    template_remark_id: mainValues.template_remark_id
      ? mainValues.template_remark_id.toString()
      : null,
    employee_list: transformedEmployeeList,
    creator_document_id: user.document_id,
    contact_document_id: contactSnapshot.document_id,
    created_date: moment(headerValues.created_date).unix(),
    issue_date: moment(headerValues.issue_date).unix(),
    shipping_cost: roundDecimal(mainValues.shipping_cost),
    additional_discount: roundDecimal(mainValues.additional_discount),
    pre_vat_amount: localeStringToFloat(summary.pre_vat_amount),
    vat_exempted_amount: localeStringToFloat(summary.vat_exempted_amount),
    vat_0_amount: localeStringToFloat(summary.vat_0_amount),
    vat_7_amount: localeStringToFloat(summary.vat_7_amount),
    vat_amount: localeStringToFloat(summary.vat_amount),
    net_amount: localeStringToFloat(summary.net_amount),
    withholding_tax_amount: localeStringToFloat(summary.withholding_tax_amount),
    total_amount: localeStringToFloat(summary.total_amount),
    customer: { ...customerDescValues },
    item_list: formatItemList,
  };
  if (!id) {
    delete payload.id;
  } else {
    delete payload.creator_document_id;
  }
  payload.reference_document_id =
    payload.reference_document_id && payload.reference_document_id.length > 0
      ? payload.reference_document_id[0].reference_document_id
      : null;

  payload.item_list.forEach((item) => {
    delete item.purchase_standard_price;
  });

  if (documentType === "do") {
    payload.delivery_date = moment(headerValues.delivery_date).unix();
  }
  // delete payload.delivery_status;
  delete payload.target_status;
  delete payload.render_status;
  delete payload.created_by;
  delete payload.created_date;

  return payload;
};

export const setLogisticValueFormatter = (
  headerSchema,
  customerSchema,
  mainSchema,
  deliveryDetailSchema,
  setHeaderValue,
  setCustomerDescValue,
  setValue,
  setDeliveryDetailValue,
  value,
  isCreateOrCopy
) => {
  try {
    Object.entries(headerSchema).forEach(([key]) => {
      if (
        (isCreateOrCopy && key === "document_id") ||
        (isCreateOrCopy && key === "created_date") ||
        (isCreateOrCopy && key === "issue_date") ||
        (isCreateOrCopy && key === "due_date") ||
        (isCreateOrCopy && key === "delivery_date" && value.isCopied)
      ) {
        return;
      } else {
        if (key === "reference_document_id") {
          if (isCreateOrCopy) {
            if (!value.isCopied) {
              setHeaderValue(key, [
                {
                  reference_document_type: value["reference_document_type"],
                  reference_document_id: value["document_id"],
                },
              ]);
            } else {
              setHeaderValue(key, value["reference_document_id"]);
            }
          } else {
            setHeaderValue(key, value["reference_document_id"]);
          }
        } else {
          setHeaderValue(key, value[key]);
        }
      }
    });
    Object.entries(customerSchema).forEach(([key]) => {
      if (key === "customer" || key === "identity_no") {
        return;
      } else {
        setCustomerDescValue(key, value["customer"][key]);
      }
    });
    Object.entries(mainSchema).forEach(([key]) => {
      if (
        key === "summary" ||
        (isCreateOrCopy && key === "render_status") ||
        (isCreateOrCopy && key === "created_by") ||
        (isCreateOrCopy && key === "creator_document_id")
      ) {
        return;
      } else {
        if (key === "item_list") {
          if (isCreateOrCopy) {
            let formatItemList;
            if (value?.isCopied) {
              formatItemList = value[key].map((item) => ({
                ...item,
                qty: item.qty,
              }));
            } else {
              formatItemList = value[key].map((item) => {
                let formatQtyUom;
                let formatQtySalesDelivered;
                const formatPreVatAmount =
                  item.qty_to_ship * item.price_per_unit - item.discount_amount;
                const unit = item.uom_id;
                if (unit) {
                  const salesUomId = item.sales_uom.id;
                  if (item.uom_group) {
                    const baseUomId = item.uom_group?.base_uom?.id;
                    if (unit === baseUomId) {
                      formatQtyUom = item.qty_uom;
                      formatQtySalesDelivered = item.qty_uom;
                    } else {
                      const conversionUom =
                        item.uom_group.uom_conversion_list.find(
                          (conversion) => conversion.target_uom.id === unit
                        );
                      const targetUomQty =
                        conversionUom.base_uom_rate /
                        conversionUom.target_uom_rate;
                      const newQtyUom = item.qty_to_ship * targetUomQty;

                      if (salesUomId === baseUomId) {
                        formatQtySalesDelivered = newQtyUom;
                      } else {
                        const conversionSalesUom =
                          item.uom_group.uom_conversion_list.find(
                            (conversion) =>
                              conversion.target_uom.id === salesUomId
                          );

                        const targetSalesUomQty =
                          conversionSalesUom.base_uom_rate /
                          conversionSalesUom.target_uom_rate;

                        const newSalesDeliveredQty =
                          newQtyUom / targetSalesUomQty;
                        formatQtySalesDelivered = newSalesDeliveredQty;
                      }
                      formatQtyUom = newQtyUom;
                    }
                  }
                }
                return {
                  ...item,
                  pre_vat_amount: formatPreVatAmount,
                  qty: item.qty_to_ship,
                  qty_uom: formatQtyUom,
                  qty_sales_delivered: formatQtySalesDelivered,
                };
              });
            }
            setValue(key, formatItemList);
          } else {
            setValue(key, value[key]);
          }
        } else {
          setValue(key, value[key]);
        }
      }
    });
    if (deliveryDetailSchema && setDeliveryDetailValue) {
      Object.entries(deliveryDetailSchema).forEach(([key]) => {
        if (isCreateOrCopy) {
          return;
        } else {
          if (key === "delivery_status") {
            if (value["render_status"] === "waitDeliver") {
              return;
            } else {
              setDeliveryDetailValue(key, value["render_status"]);
            }
          } else {
            setDeliveryDetailValue(key, value[key]);
          }
        }
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const logisticQueryFormatter = async (logistic) => {
  const formatLogistic = await {
    ...logistic,
    reference_document_id: [
      {
        reference_document_type: "sales_order",
        reference_document_id: logistic.reference_document_id,
      },
    ],
    created_date: unixToDate(logistic.created_date),
    issue_date: unixToDate(logistic.issue_date),
    delivery_date: unixToDate(logistic.delivery_date),
  };

  const allItemsDocumentId = formatLogistic.item_list.map(
    (item) => item.item_document_id
  );

  const items = await InventoryService.getAllItems({
    findManyInput: {
      where: {
        document_id: {
          in: allItemsDocumentId,
        },
      },
    },
  });

  const formatItemList = [];
  formatLogistic.item_list.forEach((item) => {
    const foundItemIndex = items.findIndex(
      (realItem) => realItem.document_id === item.item_document_id
    );

    const pre_vat_amount =
      item.qty_delivery * item.price_per_unit - item.discount_amount;

    formatItemList.push({
      ...item,
      qty_sales: item.qty_sales,
      sales_uom: item.sales_uom,
      qty_sales_delivered: item.qty_sales_delivered,
      qty: item.qty_delivery,
      uom: item.delivery_uom,
      uom_id: parseInt(item.delivery_uom_id),
      pre_vat_amount: pre_vat_amount,
      withholding_tax: {
        type:
          item.withholding_tax === -1
            ? "ยังไม่ระบุ"
            : item.withholding_tax === 0
            ? "ไม่มี"
            : (item.withholding_tax * 100).toString(),
        amount:
          item.withholding_tax === -1
            ? 0
            : pre_vat_amount * item.withholding_tax,
      },
      uom_group: items[foundItemIndex]?.uom_group,
      current_ordered_purchase_qty:
        items[foundItemIndex]?.current_ordered_purchase_qty,
      current_ordered_manufacture_qty:
        items[foundItemIndex]?.current_ordered_manufacture_qty,
      current_committed_sales_qty:
        items[foundItemIndex]?.current_committed_sales_qty,
      current_committed_manufacture_qty:
        items[foundItemIndex]?.current_committed_manufacture_qty,
      current_stock_qty: items[foundItemIndex]?.current_stock_qty,
      current_available_qty: items[foundItemIndex]?.current_available_qty,
      purchase_standard_price: items[foundItemIndex]?.purchase_standard_price,
      is_active: items[foundItemIndex]?.is_active,
    });
  });

  formatLogistic.item_list = formatItemList;
  return formatLogistic;
};

export const getItemsWithWeightVolume = async (itemList) => {
  const allItemsDocumentId = itemList.map((item) => item.item_document_id);

  const items = await InventoryService.getAllItems({
    findManyInput: {
      where: {
        document_id: {
          in: allItemsDocumentId,
        },
      },
    },
  });

  const formatItemList = [];

  itemList.forEach((item) => {
    const foundItemIndex = items.findIndex(
      (realItem) => realItem.document_id === item.item_document_id
    );

    const pre_vat_amount =
      item.qty * item.price_per_unit - item.discount_amount;

    formatItemList.push({
      ...item,
      qty_sales: item.qty,
      sales_uom: item.uom,
      qty_sales_delivered: item.qty,
      volume:
        (items[foundItemIndex]?.package_height || 0) *
        (items[foundItemIndex]?.package_length || 0) *
        (items[foundItemIndex]?.package_width || 0),
      weight: items[foundItemIndex]?.package_weight || 0,
      cubic_metre: items[foundItemIndex]?.package_weight,
      uom_id: parseInt(item.uom_id),
      pre_vat_amount: pre_vat_amount,
      uom_group: items[foundItemIndex]?.uom_group,
      current_ordered_purchase_qty:
        items[foundItemIndex]?.current_ordered_purchase_qty,
      current_ordered_manufacture_qty:
        items[foundItemIndex]?.current_ordered_manufacture_qty,
      current_committed_sales_qty:
        items[foundItemIndex]?.current_committed_sales_qty,
      current_stock_qty: items[foundItemIndex]?.current_stock_qty,
      current_available_qty: items[foundItemIndex]?.current_available_qty,
      is_active: items[foundItemIndex]?.is_active,
    });
  });

  return formatItemList;
};

export const formatDeliveryTripFromOrder = (payload) => {
  let itemList = [];
  const formatItemList = payload.item_list.map((item) => {
    const volumeToM2WithQty = (item.volume * item.qty_delivery) / 1000000;

    const matchingAvailableItem = payload.available_item_list?.find(
      (availableItem) =>
        item.item_document_id === availableItem.item_document_id
    );

    const formatItem = {
      uid: item.uid,
      item_document_id: item.item_document_id,
      item_name: item.item_name,
      item_description: item.item_description,
      qty: matchingAvailableItem
        ? Math.max(
            item.qty_delivery - matchingAvailableItem.accumulate_delivery,
            0
          )
        : item.qty_delivery - (item.qty_confirmed_delivery || 0),
      uom: item.delivery_uom.name,
      uom_id: parseInt(item.delivery_uom_id),
      qty_uom: item.qty_uom,
      do_reference_document_id: payload.document_id,
      so_reference_document_id:
        payload.reference_document_id[0].reference_document_id,
      item_remark: item.item_remark,
      customer_name: payload.contact?.contact_name_1 || "",
      volume: item.volume,
      weight: item.weight,
      uom_group: item?.uom_group,
      current_ordered_purchase_qty: item?.current_ordered_purchase_qty,
      current_ordered_manufacture_qty: item?.current_ordered_manufacture_qty,
      current_committed_sales_qty: item?.current_committed_sales_qty,
      current_committed_manufacture_qty:
        item?.current_committed_manufacture_qty,
      current_stock_qty: item?.current_stock_qty,
      current_available_qty: item?.current_available_qty,
      is_active: item?.is_active,
    };

    if (formatItem.qty !== 0) {
      itemList.push(formatItem);
    }

    const weightWithQty = item.weight * item.qty_delivery;
    return {
      ...item,
      volume_with_qty: volumeToM2WithQty,
      weight_with_qty: weightWithQty,
    };
  });
  const reference_document_id = payload?.reference_document_type
    ? [
        {
          reference_document_type: payload?.reference_document_type,
          reference_document_id: payload?.document_id,
        },
      ]
    : [];

  const formatDeliveryTrip = {
    reference_document_id,
    external_ref_id: payload?.external_ref_id,
    employee_list: payload?.employee_list ? [...payload?.employee_list] : null,
    created_date: null,
    issue_date: null,
    delivery_date: payload?.delivery_date,
    delivery_type: "",
    vehicle: null,
    goods_deliver: null,
    trip: 1,
    in_date: null,
    out_date: null,
    delivery_order_list: [
      {
        ...payload,
        reference_document_id:
          payload.reference_document_id[0].reference_document_id,
        item_list: formatItemList,
        issue_date: dateToUnix(payload?.issue_date),
        delivery_date: dateToUnix(payload?.delivery_date),
      },
    ],
    item_list: itemList,
    internal_remark: "",
  };
  return formatDeliveryTrip;
};

export const formatDeliveryTripById = async (payload) => {
  try {
    const allItemsDocumentId = payload.item_list.map(
      ({ item_document_id }) => item_document_id
    );

    const items = await InventoryService.getAllItems({
      findManyInput: {
        where: {
          document_id: {
            in: allItemsDocumentId,
          },
        },
      },
    });

    let formatItemList = [];

    payload.item_list.forEach((item) => {
      const foundItemIndex = items.findIndex(
        (realItem) => realItem.document_id === item.item_document_id
      );
      formatItemList.push({
        ...item,
        uom_group: items[foundItemIndex]?.uom_group,
        current_ordered_purchase_qty:
          items[foundItemIndex]?.current_ordered_purchase_qty,
        current_ordered_manufacture_qty:
          items[foundItemIndex]?.current_ordered_manufacture_qty,
        current_committed_sales_qty:
          items[foundItemIndex]?.current_committed_sales_qty,
        current_committed_manufacture_qty:
          items[foundItemIndex]?.current_committed_manufacture_qty,
        current_stock_qty: items[foundItemIndex]?.current_stock_qty,
        current_available_qty: items[foundItemIndex]?.current_available_qty,
        is_active: items[foundItemIndex]?.is_active,
      });
    });

    const { results } = await LogisticService.getAllDeliveryOrders({
      startRow: 0,
      endRow: Math.pow(10, 10),
      filterModel: {
        document_id: {
          filterType: "set",
          values: payload.do_document_id_list,
        },
      },
      sortModel: [],
    });

    const formatDeliveryOrderList = results.map((deliveryOrder) => {
      const formatItemList = deliveryOrder.item_list.map((item) => {
        const volumeToM2WithQty = (item.volume * item.qty_delivery) / 1000000;
        const weightWithQty = item.weight * item.qty_delivery;
        return {
          ...item,
          volume_with_qty: volumeToM2WithQty,
          weight_with_qty: weightWithQty,
        };
      });
      return {
        ...deliveryOrder,
        item_list: formatItemList,
      };
    });

    const formatDeliveryTrip = {
      ...payload,
      reference_document_id: payload.do_document_id_list.map((document) => ({
        reference_document_type: "delivery_order",
        reference_document_id: document,
      })),
      external_ref_id: payload?.external_ref_id,
      employee_list: payload?.employee_list
        ? [...payload?.employee_list]
        : null,
      created_date: unixToDate(payload?.created_date) ?? null,
      issue_date: unixToDate(payload?.issue_date) ?? null,
      delivery_date: unixToDate(payload?.delivery_date) ?? null,
      delivery_type: payload?.delivery_type ?? "",
      vehicle: payload?.vehicle ?? null,
      goods_deliver: payload?.goods_deliver
        ? [...payload?.goods_deliver]
        : null,
      trip: payload?.trip ?? 0,
      in_date: payload?.in_date ? unixToDate(payload?.in_date) : null,
      out_date: payload?.out_date ? unixToDate(payload?.out_date) : null,
      internal_remark: payload?.internal_remark ?? "",
      item_list: formatItemList,
      delivery_order_list: formatDeliveryOrderList,
    };

    return await formatDeliveryTrip;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const setDeliveryTripValue = (
  headerSchema,
  deliveryPlanSchema,
  mainSchema,
  setHeaderValue,
  setDeliveryPlanValue,
  setValue,
  value,
  isCreateOrCopy
) => {
  if (isCreateOrCopy) {
    Object.entries(headerSchema).forEach(([key]) => {
      if (
        key === "document_id" ||
        key === "created_date" ||
        key === "issue_date" ||
        key === "id"
      ) {
        return;
      } else {
        setHeaderValue(key, value[key]);
      }
    });
    Object.entries(deliveryPlanSchema).forEach(([key]) => {
      if (key === "in_date" || key === "out_date") {
        setDeliveryPlanValue(key, moment());
      } else {
        setDeliveryPlanValue(key, value[key]);
      }
    });
    Object.entries(mainSchema).forEach(([key]) => {
      if (key === "render_status") {
        return;
      } else {
        setValue(key, value[key]);
      }
    });
  } else {
    Object.entries(headerSchema).forEach(([key]) => {
      setHeaderValue(key, value[key]);
    });
    Object.entries(deliveryPlanSchema).forEach(([key]) => {
      setDeliveryPlanValue(key, value[key]);
    });
    Object.entries(mainSchema).forEach(([key]) => {
      setValue(key, value[key]);
    });
  }
};

export const logisticExportFormatter = async (payload, documentType) => {
  let preformatted = [];
  let formattedPayload = [];

  let allDODocumentId = [];

  for (const document of payload) {
    const newDeliveryOrder = { ...document };
    if (documentType === "deliveryOrder") {
      preformatted.push(newDeliveryOrder);
    } else {
      const { do_document_id_list } = document;
      allDODocumentId.push(...do_document_id_list);
      preformatted.push(newDeliveryOrder);
    }
  }

  let uniqueDODocumentId = [...new Set(allDODocumentId)];

  const { results } = await LogisticService.getAllDeliveryOrders({
    startRow: 0,
    endRow: Math.pow(10, 10),
    filterModel: {
      document_id: {
        filterType: "set",
        values: uniqueDODocumentId,
      },
    },
    sortModel: [],
  });

  const mappedPreFormat = preformatted.map((pre) => {
    const do_document_list = results.filter((deliveryOrder) =>
      pre.do_document_id_list.includes(deliveryOrder.document_id)
    );

    if (documentType === "deliveryOrder") {
      return pre;
    } else {
      return {
        ...pre,
        do_document_list,
      };
    }
  });

  mappedPreFormat.forEach((document) => {
    if (documentType === "deliveryOrder") {
      const {
        price_vat_type,
        additional_discount,
        additional_discount_type,
        pre_vat_amount,
      } = document;
      const actualAdditionalDiscount =
        additional_discount_type === "percent"
          ? pre_vat_amount * (additional_discount / 100)
          : additional_discount;
      const sum_pre_vat_amount_exclude_discount = document.item_list.reduce(
        (prev, item) => {
          const pre_vat_amount_exclude_discount =
            prev + item.qty_delivery * item.price_per_unit;

          return pre_vat_amount_exclude_discount;
        },
        0
      );

      document.item_list?.forEach((item) => {
        const pre_vat_amount_exclude_discount =
          item.qty_delivery * item.price_per_unit;

        // Calculate pre vat amount exclude discount

        const item_additional_discount =
          actualAdditionalDiscount *
          (pre_vat_amount_exclude_discount /
            sum_pre_vat_amount_exclude_discount);

        const pre_vat_amount_include_discount =
          item.qty_delivery * item.price_per_unit -
          (item.discount_amount + item_additional_discount);

        const item_vat_exempted_amount =
          item.vat_type === "ไม่มี" ? pre_vat_amount_include_discount : 0;

        const item_vat_0_amount =
          item.vat_type === "0" ? pre_vat_amount_include_discount : 0;

        const item_vat_7_amount =
          item.vat_type === "7" && price_vat_type === "included_vat"
            ? pre_vat_amount_include_discount / 1.07
            : item.vat_type === "7" && price_vat_type === "excluded_vat"
            ? pre_vat_amount_include_discount
            : 0;

        const item_vat_amount = item_vat_7_amount * 0.07;

        const item_withholding_tax_type =
          item.withholding_tax === -1
            ? "ยังไม่ระบุ"
            : item.withholding_tax === 0
            ? "ไม่มี"
            : (item.withholding_tax * 100).toString();

        const item_net_amount =
          item_vat_exempted_amount +
          item_vat_0_amount +
          item_vat_7_amount +
          item_vat_amount;

        let item_withholding_tax;

        if (price_vat_type === "excluded_vat") {
          item_withholding_tax =
            pre_vat_amount_include_discount *
            (item.withholding_tax === -1 ? 0 : item.withholding_tax);
        } else {
          if (item.vat_type === "7") {
            item_withholding_tax =
              (pre_vat_amount_include_discount / 1.07) *
              (item.withholding_tax === -1 ? 0 : item.withholding_tax);
          } else {
            item_withholding_tax =
              pre_vat_amount_include_discount *
              (item.withholding_tax === -1 ? 0 : item.withholding_tax);
          }
        }

        const item_total_amount = item_net_amount - item_withholding_tax;
        const result = {
          document_id: document.document_id,
          issue_date: unixToDateWithFormat(document.issue_date),
          delivery_date: unixToDateWithFormat(document.delivery_date),
          render_status: filterStatusValueFormatter(document.render_status),
          contact_document_id: document.contact.document_id,
          contact_name: `${document.contact.contact_name_1}${
            document.contact.contact_name_2
              ? " " + document.contact.contact_name_2
              : ""
          }`,
          price_vat_type:
            price_vat_type === "included_vat" ? "ราคารวมภาษี" : "ราคาแยกภาษี",
          item_document_id: item.item_document_id,
          item_name: item.item_name,
          qty: item.qty_delivery,
          uom_name: item.delivery_uom.name,
          price_per_unit: item.price_per_unit,
          vat_type: item.vat_type,
          pre_vat_amount_exclude_discount,
          item_discount: item.discount_amount,
          item_additional_discount,
          pre_vat_amount_include_discount,
          item_vat_exempted_amount,
          item_vat_0_amount,
          item_vat_7_amount,
          item_vat_amount,
          item_net_amount,
          item_withholding_tax_type,
          item_withholding_tax,
          item_total_amount,
          shipping_cost: document.shipping_cost,
          total_amount: document.total_amount,
          additional_discount: actualAdditionalDiscount,
          credit_day: document.credit_day,
          remark: document.remark,
          is_late: document.is_late ? "เกินเวลาแล้ว" : "ยังไม่เกินเวลา",
          external_ref_id: document.external_ref_id,
          reference_document_id: document.reference_document_id,
          item_description: item.item_description,
          item_remark: item.item_remark,
          created_date: unixToDateTimeWithFormat(document.created_date),
          updated_date: unixToDateWithFormat(document.updated_date),
          created_by:
            document.created_by.first_name +
            " " +
            document.created_by.last_name,
          employee_list: document.employee_list
            .map((employee) => employee?.first_name + " " + employee?.last_name)
            .join(","),
          email: document.customer.email,
          phone: document.customer.phone,
          fax: document.customer.fax,
          address_contact_name:
            document.customer.delivery_address.address_contact_name,
          address_contact_phone:
            document.customer.delivery_address.address_contact_phone,
          delivery_address: document.customer.delivery_address.address,
          delivery_sub_district:
            document.customer.delivery_address.sub_district,
          delivery_district: document.customer.delivery_address.district,
          delivery_province: document.customer.delivery_address.province,
          delivery_postal_code: document.customer.delivery_address.postal_code,
          delivery_country: document.customer.delivery_address.country,
          delivery_status: document.is_incompleted ? "ไม่สำเร็จ" : "สำเร็จ",
          recipient_name: document.recipient_name,
          delivery_reference: document.delivery_reference,
          internal_remark: document.internal_remark,
          item_group_level_1_name_list:
            document.item_group_level_1_name_list.join(", "),
        };
        formattedPayload.push(result);
      });
    } else {
      if (document.do_document_list.length > 0) {
        document.do_document_list.forEach((deliveryOrder) => {
          const salesQty = deliveryOrder.item_list
            .map((item) => item.qty_sales)
            .reduce((a, b) => a + b, 0);
          const deliveryQty = deliveryOrder.item_list
            .map((item) => item.qty_delivery)
            .reduce((a, b) => a + b, 0);
          const result = {
            document_id: document.document_id,
            issue_date: unixToDateWithFormat(document.issue_date),
            delivery_date: unixToDateWithFormat(document.delivery_date),
            vehicle_license_plate: document.vehicle?.license_plate,
            render_status: filterStatusValueFormatter(document.render_status),
            reference_document_id: document.do_document_id_list.join(", "),
            delivery_type: document.delivery_type,
            goods_deliver_list: document.goods_deliver_list
              .map(
                (employee) => employee?.first_name + " " + employee?.last_name
              )
              .join(","),
            trip: document.trip,
            in_date: document.in_date
              ? unixToDateTimeWithFormat(document.in_date)
              : null,
            out_date: document.out_date
              ? unixToDateTimeWithFormat(document.out_date)
              : null,
            vehicle_type: document.vehicle?.vehicle_type,
            do_document_id: deliveryOrder.document_id,
            do_customer: `${deliveryOrder.contact.contact_name_1}${
              deliveryOrder.contact.contact_name_2
                ? " " + deliveryOrder.contact.contact_name_2
                : ""
            }`,
            do_issue_date: unixToDateWithFormat(deliveryOrder.issue_date),
            do_delivery_date: unixToDateWithFormat(deliveryOrder.delivery_date),
            do_address: deliveryOrder.customer.delivery_address.address,
            do_sub_district:
              deliveryOrder.customer.delivery_address.sub_district,
            do_district: deliveryOrder.customer.delivery_address.district,
            do_province: deliveryOrder.customer.delivery_address.province,
            total_volume: document.total_volume,
            total_weight: document.total_weight,
            do_qty_sales: salesQty ?? 0,
            do_qty_delivery: deliveryQty ?? 0,
            do_employee_list: deliveryOrder.employee_list
              ?.map(
                (employee) => employee?.first_name + " " + employee?.last_name
              )
              .join(","),
            do_status: filterStatusValueFormatter(deliveryOrder.render_status),
            delivery_remark: document.delivery_remark,
            created_date: unixToDateTimeWithFormat(document.created_date),
            updated_date: document.updated_date
              ? unixToDateWithFormat(document.updated_date)
              : "",
            created_by:
              document.created_by.first_name +
              " " +
              document.created_by.last_name,
            employee_list: document.employee_list
              ?.map(
                (employee) => employee?.first_name + " " + employee?.last_name
              )
              .join(","),
            internal_remark: document.internal_remark,
          };
          formattedPayload.push(result);
        });
      } else {
        const result = {
          document_id: document.document_id,
          issue_date: unixToDateWithFormat(document.issue_date),
          delivery_date: unixToDateWithFormat(document.delivery_date),
          vehicle_license_plate: document.vehicle?.license_plate,
          render_status: filterStatusValueFormatter(document.render_status),
          reference_document_id: document.do_document_id_list.join(", "),
          delivery_type: document.delivery_type,
          goods_deliver_list: document.goods_deliver_list
            .map((employee) => employee?.first_name + " " + employee?.last_name)
            .join(","),
          trip: document.trip,
          in_date: unixToTimeWithFormat(document.in_date),
          out_date: unixToTimeWithFormat(document.out_date),
          vehicle_type: document.vehicle?.vehicle_type,
          total_volume: document.total_volume,
          total_weight: document.total_weight,
          created_date: unixToDateTimeWithFormat(document.created_date),
          updated_date: document.last_updated_date
            ? unixToDateWithFormat(document.last_updated_date)
            : "",
          created_by:
            document.created_by.first_name +
            " " +
            document.created_by.last_name,
          employee_list: document.employee_list
            ?.map(
              (employee) => employee?.first_name + " " + employee?.last_name
            )
            .join(","),
          internal_remark: document.internal_remark,
        };
        formattedPayload.push(result);
      }
    }
  });
  return formattedPayload;
};
