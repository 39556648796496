import { createSlice } from "@reduxjs/toolkit";
export const REDIRECT = "REDIRECT";

const activityLogsSlice = createSlice({
  name: "activityLogs",
  initialState: {
    logs: [],
    type: "ใหม่ไปเก่า",
    error: null,
  },
  reducers: {
    loadedActivityLogs(state, action) {
      state.logs = action.payload.logs
        .filter((log) => log.document_type === action.payload.documentType)
        .sort((a, b) => b.created_date - a.created_date);
    },
    addActivityLogs(state, action) {
      if (state.type === "เก่าไปใหม่")
        state.logs = [...state.logs, action.payload];
      if (state.type === "ใหม่ไปเก่า")
        state.logs = [action.payload, ...state.logs];
    },
    activitySort(state, action) {
      state.type = action.payload;
    },
    reverseActivity(state, action) {
      state.logs = [...state.logs].reverse();
    },
    resetActivity(state, action) {
      state.logs = [];
    },
    rejectedActions(state, action) {
      state.error = action.payload;
    },
  },
});

export const activityLogsActions = activityLogsSlice.actions;

export default activityLogsSlice.reducer;
