import SalesService from "../../../services/Sales";
import { salesExportFormatter } from "../../../utils/salesPayloadFormatter";
// import { salesExportFormatter } from "../../../utils/salesPayloadFormatter";
import { salesReportActions } from "./sales-report-slice";

// export const getSalesReportByCustomer =
//   (enqueueSnackbar) => async (dispatch) => {
//     dispatch(salesReportActions.onLoading("salesReportByCustomer"));
//     try {
//       const salesReportByCustomer =
//         await SalesService.getSalesReportByCustomer();
//       dispatch(
//         salesReportActions.loadedSalesReportByCustomer(salesReportByCustomer)
//       );
//     } catch (err) {
//       console.error(err);
//       dispatch(
//         salesReportActions.rejectedActions({
//           ...err,
//           name: "salesReportByCustomer",
//         })
//       );
//       enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
//         variant: "error",
//       });
//     }
//   };

export const getSalesReportByCustomer =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReportActions.onLoading("salesReportByCustomer"));
    try {
      const { results } = await SalesService.getAllSalesOrders(input);
      dispatch(salesReportActions.loadedSalesReportByCustomer(results));
    } catch (err) {
      console.error(err);
      dispatch(
        salesReportActions.rejectedActions({
          ...err,
          name: "salesReportByCustomer",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getSalesReportByCustomerReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReportActions.onLoading("salesReportByCustomer"));
    try {
      const { results } = await SalesService.getAllSalesOrdersReport(input);
      dispatch(salesReportActions.loadedSalesReportByCustomer(results));
    } catch (err) {
      console.log(err);
      dispatch(
        salesReportActions.rejectedActions({
          ...err,
          name: "salesReportByCustomer",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getSalesReportByProduct =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReportActions.onLoading("salesReportByProduct"));
    try {
      const { results } = await SalesService.getAllSalesOrders(input);
      const salesOrder = salesExportFormatter(results, "sales_order");
      dispatch(salesReportActions.loadedSalesReportByProduct(salesOrder));
    } catch (err) {
      console.error(err);
      dispatch(
        salesReportActions.rejectedActions({
          ...err,
          name: "salesReportByProduct",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getSalesReportByProductReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReportActions.onLoading("salesReportByProduct"));
    try {
      const { results } = await SalesService.getAllSalesOrdersReport(input);
      const salesOrder = salesExportFormatter(results, "sales_order");
      dispatch(salesReportActions.loadedSalesReportByProduct(salesOrder));
    } catch (err) {
      console.log(err);
      dispatch(
        salesReportActions.rejectedActions({
          ...err,
          name: "salesReportByProduct",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

// export const getSalesReportByProduct =
//   (enqueueSnackbar) => async (dispatch) => {
//     dispatch(salesReportActions.onLoading("salesReportByProduct"));
//     try {
//       const salesReportByProduct = await SalesService.getSalesReportByProduct();
//       dispatch(
//         salesReportActions.loadedSalesReportByProduct(salesReportByProduct)
//       );
//     } catch (err) {
//       dispatch(
//         salesReportActions.rejectedActions({
//           ...err,
//           name: "salesReportByProduct",
//         })
//       );
//       enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
//         variant: "error",
//       });
//     }
//   };
