import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useWatch } from "react-hook-form";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const container = {
  display: "block",
  position: "relative",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 200,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "auto",
  objectFit: "contain",
};

const GeneralImageDropzone = ({
  control,
  setValue,
  valueToSet,
  manualRevoke,
  filesLimit,
  disabled,
}) => {
  const { contactSnapshot } = useSelector((state) => state.contact);
  const initValue = [];
  const [files, setFiles] = useState(initValue);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxFiles: filesLimit ? filesLimit : 0,
    onDrop: (acceptedFiles) => {
      if (filesLimit && files.length >= filesLimit) {
        return;
      }
      setFiles((prev) => [
        ...prev,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    disabled: disabled,
  });

  useEffect(() => {
    if (contactSnapshot?.img_url) {
      setFiles([contactSnapshot.img_url]);
    } else {
      setFiles([]);
    }
  }, [contactSnapshot]);

  const watchImgUrl = useWatch({ control, name: "img_url" });

  useEffect(() => {
    if (typeof watchImgUrl === "string" && watchImgUrl.length > 0) {
      setFiles([watchImgUrl]);
    }
    if (watchImgUrl?.length === 0) {
      setFiles([]);
    }
  }, [watchImgUrl]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    if (newFiles.length === 0) {
      setValue(valueToSet, []);
    }
  };

  const thumbs = files.map((file) => (
    <div style={container} key={file.name || file}>
      <div style={thumb}>
        <div style={thumbInner}>
          <img
            alt={file.name || ""}
            src={file.preview || file}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={
              !manualRevoke && typeof file === "object"
                ? () => {
                    URL.revokeObjectURL(file.preview);
                  }
                : () => {}
            }
          />
        </div>
      </div>
      {!disabled && (
        <IconButton
          aria-label="cancel"
          onClick={removeFile(file)}
          sx={{ position: "absolute", top: -20, right: -12 }}
          color="primary"
        >
          <CancelIcon />
        </IconButton>
      )}
    </div>
  ));

  useEffect(() => {
    if (files.length !== 0) {
      setValue(valueToSet, files);
    }
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      if (!manualRevoke && typeof file === "object") {
        files.forEach((file) => URL.revokeObjectURL(file.preview));
      }
    };
  }, [files, setValue, valueToSet, manualRevoke]);

  return (
    <section className="container">
      {!disabled && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>ลากมาวางเพื่ออัปโหลด</p>
        </div>
      )}
      {files.length > 0 && <aside style={thumbsContainer}>{thumbs}</aside>}
    </section>
  );
};

export default GeneralImageDropzone;
