import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PurchaseReturnTable from "../../../components/Table/DocumentTable/PurchaseReturnTable";
import { getPurchaseReturnTab } from "../../../features/Purchase/PurchaseOrder/purchase-order-actions";
import { purchaseOrderActions } from "../../../features/Purchase/PurchaseOrder/purchase-order-slice";

const ReturnTab = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { purchaseReturnTab } = useSelector((state) => state.purchaseOrder);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(getPurchaseReturnTab(id, enqueueSnackbar));
    }
    return () => dispatch(purchaseOrderActions.resetPurchaseReturnTab());
  }, [dispatch, enqueueSnackbar, id]);

  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        {t("purchase.return.index")}
      </Typography>
      <PurchaseReturnTable
        rowData={purchaseReturnTab}
        gridRef={gridRef}
        height={450}
      />
    </>
  );
};

export default ReturnTab;
