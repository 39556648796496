import { userPermission } from "../../../../utils/userInfo";

export const modifyOptions = (page, status, permissions) => {
  const options = [
    {
      value: "ยกเลิก",
      disabled: userPermission(permissions, "sales", page, "cancel"),
    },
    {
      value: "คัดลอก",
      disabled: userPermission(permissions, "sales", page, "create"),
    },
    {
      value: "แก้ไข",
      disabled: userPermission(permissions, "sales", page, "edit"),
    },
  ];

  const optionsLogistic = [
    {
      value: "ยกเลิก",
      disabled: userPermission(permissions, "logistic", page, "cancel"),
    },
    {
      value: "คัดลอก",
      disabled: userPermission(permissions, "logistic", page, "create"),
    },
    {
      value: "แก้ไข",
      disabled: userPermission(permissions, "logistic", page, "edit"),
    },
  ];

  const extraOption = [
    {
      value: "บันทึกใบแจ้งหนี้",
      disabled: userPermission(permissions, "sales", page, "edit"),
    },
  ];

  if (!status) {
    return options;
  }
  switch (page) {
    case "quotation":
      switch (status) {
        case "draft":
        case "ร่าง":
          return [options[0], options[1]];
        case "waitApprove":
        case "รออนุมัตื":
          return options;
        case "waitAccept":
        case "รอตอบรับ":
          return options;
        case "accepted":
        case "ตอบรับแล้ว":
          return [options[1]];
        case "finished":
        case "เสร็จสิ้น":
          return [options[1]];
        case "expired":
        case "เกินเวลา":
          return [options[0], options[1]];
        case "notApproved":
        case "DECLINED":
          return [options[0], options[1]];
        case "cancelled":
        case "ยกเลิก":
          return [options[1]];
        default:
          return [];
      }
    case "order":
      switch (status) {
        case "draft":
        case "ร่าง":
          return [options[0], options[1]];
        case "waitApprove":
        case "รออนุมัตื":
          return options;
        case "notApproved":
        case "DECLINED":
          return [options[0], options[1]];
        case "approved":
          return [extraOption[0], ...options];
        case "finished":
          return [options[0], options[1]];
        case "expired":
        case "เกินเวลา":
          return [options[0], options[1]];
        case "cancelled":
        case "ยกเลิก":
          return [options[1]];
        default:
          return [];
      }
    case "deposit-invoice":
      switch (status) {
        case "draft":
          return [options[0], options[1]];
        case "waitApprove":
        case "รออนุมัตื":
          return options;
        case "notApproved":
        case "DECLINED":
          return [options[0], options[1]];
        case "waitPayment":
          return options;
        case "fullyPaid":
          return [options[1]];
        case "expired":
        case "เกินเวลา":
          return [options[0], options[1]];
        case "cancelled":
        case "ยกเลิก":
          return [options[1]];
        default:
          break;
      }
      break;
    case "invoice":
      switch (status) {
        case "draft":
          return [options[0], options[1]];
        case "waitApprove":
        case "รออนุมัตื":
          return options;
        case "notApproved":
        case "DECLINED":
          return [options[0], options[1]];
        case "waitPayment":
          return options;
        case "fullyPaid":
          return [options[1]];
        case "expired":
        case "เกินเวลา":
          return [options[0], options[1]];
        case "cancelled":
        case "ยกเลิก":
          return [options[1]];
        default:
          break;
      }
      break;
    case "payment-receipt":
      switch (status) {
        case "draft":
          return [options[0], options[1]];
        case "approved":
          return [options[1]];
        case "fullyPaid":
          return [options[1]];
        default:
          break;
      }
      break;
    case "return":
      switch (status) {
        case "draft":
          return [options[0]];
        case "waitApprove":
        case "รออนุมัตื":
          return [options[0], options[2]];
        case "notApproved":
        case "DECLINED":
          return [options[0]];
        case "approved":
          return [];
        case "finished":
          return [];
        case "expired":
        case "เกินเวลา":
          return [options[0]];
        case "cancelled":
        case "ยกเลิก":
          return [];
        default:
          break;
      }
      break;
    case "credit-note":
      switch (status) {
        case "draft":
          return [options[0], options[1]];
        case "waitApprove":
        case "รออนุมัตื":
          return options;
        case "notApproved":
        case "DECLINED":
          return [options[0], options[1]];
        case "approved":
          return [options[1]];
        case "finished":
          return [options[1]];
        case "expired":
        case "เกินเวลา":
          return [options[0], options[1]];
        case "cancelled":
        case "ยกเลิก":
          return [options[1]];
        default:
          break;
      }
      break;
    case "delivery-order":
      switch (status) {
        case "draft":
          return [optionsLogistic[0], optionsLogistic[1]];
        case "waitDeliver":
          return optionsLogistic;
        case "notCompleted":
          return [optionsLogistic[1], optionsLogistic[2]];
        case "completed":
        case "cancelled":
          return [optionsLogistic[1]];
        default:
          break;
      }
      break;
    case "delivery-trip":
      switch (status) {
        case "draft":
        case "completed":
        case "notCompleted":
          return [optionsLogistic[0], optionsLogistic[1]];
        case "delivering":
        case "waitDeliver":
          return optionsLogistic;
        case "expired":
        case "เกินเวลา":
          return [optionsLogistic[0], optionsLogistic[1]];
        case "cancelled":
          return [optionsLogistic[1]];
        default:
          break;
      }
      break;
    default:
      return options;
  }
};
