import { useSnackbar } from "notistack";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getAllBoms } from "../../features/Manufacture/Bom/bom-actions";
import { filterDateWithUnix } from "../../utils/filterparams";
import CheckboxModalTable from "./CheckboxModalTable";
import { bomColumnDef } from "./ColumnDefs/bomColumnDef";

const CheckedBomModal = ({
  showSelectBom,
  closeBomTable,
  finishBomSelectHandler,
  watchItemId,
  bomIds,
  setBomIds,
  bomIdsSnapshot,
  setBomIdsSnapshot,
  btnTitle,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectBomGridRef = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { start_date, end_date, ...otherFilterModal } = request.filterModel;

      const formatFilterModel = {
        ...otherFilterModal,
        start_date: start_date && filterDateWithUnix(start_date),
        end_date: end_date && filterDateWithUnix(end_date),
      };
      dispatch(
        getAllBoms(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const instance = params.api.getFilterInstance("status");
    const itemDocumentId = params.api.getFilterInstance("item_document_id");
    instance.setModel({
      filterType: "set",
      values: ["active"],
    });
    itemDocumentId.setModel({
      filterType: "text",
      type: "contains",
      filter: watchItemId,
    });
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <CheckboxModalTable
      getRowId={getRowId}
      modalTitle={t("manufacture.bom.index")}
      btnTitle={btnTitle ?? t("button.add")}
      gridRef={selectBomGridRef}
      height={450}
      columnDefs={bomColumnDef(t, true)}
      rowSelection="single"
      onFinishEditing={finishBomSelectHandler}
      modalIsOpen={showSelectBom}
      closeModal={closeBomTable}
      onGridReady={onGridReady}
      selectedIds={bomIds}
      setSelectedIds={setBomIds}
      idsSnapshot={bomIdsSnapshot}
      setIdsSnapshot={setBomIdsSnapshot}
      // onFirstDataRendered={onFirstDataRendered}
    />
  );
};

export default CheckedBomModal;
