import React from "react";
import {
  formatDateTime,
  unixToDateTimeWithFormat,
} from "../../../../utils/date-converter";
import { Avatar, Box, IconButton, TableCell, TableRow } from "@mui/material";
import { formatNumber } from "../../../../utils/dataTransformer";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import ReplayIcon from "@mui/icons-material/Replay";

const TraceEntryScanner = ({
  row,
  checkConditionRender,
  index,
  renderBoxMaxContent,
  documentStage,
  disabled,
  resetScanHandler,
}) => {
  return row.stock_entry_list.trace_entry_list?.map((subRow, subIndex) =>
    checkConditionRender(subRow.is_scanned) ? (
      <React.Fragment
        key={row.stock_entry_list.item?.document_id + index + subIndex}
      >
        <TableRow
          sx={{
            "&:last-child td, &:last-child th": {
              border: 0,
            },
          }}
        >
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            {subRow.is_scanned
              ? renderBoxMaxContent(
                  subRow.scanned_date
                    ? typeof subRow.scanned_date === "object"
                      ? formatDateTime(subRow.scanned_date)
                      : unixToDateTimeWithFormat(subRow.scanned_date)
                    : null
                )
              : null}
          </TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(formatNumber(subRow.posted_quantity))}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(row.stock_entry_list.uom?.name)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(subRow.serial_number)}
          </TableCell>
          <TableCell align="center">
            {subRow.is_scanned
              ? renderBoxMaxContent(subRow.source_bin_location_document_id)
              : null}
          </TableCell>
          <TableCell align="center">
            <Box>
              {subRow.is_scanned ? (
                <CustomizedStatus minWidth={85} status="isScan" />
              ) : (
                <CustomizedStatus minWidth={85} status="notScan" />
              )}
            </Box>
          </TableCell>
          {documentStage === 1 ? (
            <TableCell align="center">
              <Box display={"flex"} justifyContent={"end"}>
                {subRow.is_scanned ? (
                  <CustomizedTooltip title="สแกนใหม่">
                    <IconButton
                      disabled={disabled}
                      onClick={() => resetScanHandler(index, subIndex)}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </CustomizedTooltip>
                ) : null}
              </Box>
            </TableCell>
          ) : null}
          <TableCell align="center">
            {subRow.is_scanned
              ? renderBoxMaxContent(
                  subRow.created_by?.img_url ? (
                    <Avatar alt="img_url" src={subRow.created_by?.img_url} />
                  ) : (
                    <CustomizedLetterAvatar
                      name={
                        subRow.created_by?.first_name +
                        " " +
                        subRow.created_by?.last_name
                      }
                    />
                  )
                )
              : null}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(subRow.batch_number)}
          </TableCell>
        </TableRow>
      </React.Fragment>
    ) : null
  );
};

export default TraceEntryScanner;
