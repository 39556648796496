import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import AgGrid from "../../../components/Table/AgGrid";
import { manufactureOrderColumnDef } from "../../../components/Table/ColumnDefs/manufactureOrderColumnDef";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { getAllManufactureOrders } from "../../../features/Manufacture/ManufactureOrder/manufacture-order-actions";
import { useSearchParams } from "react-router-dom";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { manufactureOrderActions } from "../../../features/Manufacture/ManufactureOrder/manufacture-order-slice";
import { filterDateWithUnix } from "../../../utils/filterparams";
import ManufactureOrderCalendar from "./ManufactureOrderCalendar";
import { usePermission } from "../../../hooks/use-auth";

const ManufacturingOrder = () => {
  const { t } = useTranslation();
  const gridRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const { createPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.order.index"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const onRowDoubleClicked = (params) => {
    navigate(`${pathname}/${encodeURIComponent(params.data.document_id)}`);
  };

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.pendingManu"),
      path: `${pathname}?filter=pendingManu`,
    },
    {
      label: t("status.manufacturing"),
      path: `${pathname}?filter=inProgress`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const {
        issue_date,
        expect_date,
        expect_product_date,
        delivery_date,
        created_date,
        customer_detail,
        group,
        employee_list,
        created_by,
        item_group_level_1_name_list,
        ...otherFilterModal
      } = request.filterModel;

      const formatFilterModel = {
        ...otherFilterModal,
        issue_date: issue_date && filterDateWithUnix(issue_date),
        expect_date: expect_date && filterDateWithUnix(expect_date),
        expect_product_date:
          expect_product_date && filterDateWithUnix(expect_product_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        created_date: created_date && filterDateWithUnix(created_date),
        customer_detail: customer_detail && {
          filterType: "object",
          filter: {
            contact_name_1: customer_detail,
          },
        },
        group: group && {
          filterType: "objectArray",
          type: "some",
          filter: {
            name: {
              filterType: "set",
              values: group.values,
            },
          },
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: created_by,
          },
        },
        item_group_level_1_name_list: item_group_level_1_name_list && {
          filterType: "array",
          type: "hasSome",
          values: item_group_level_1_name_list.values,
        },
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: employee_list,
          },
        },
      };

      dispatch(
        getAllManufactureOrders(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("render_status");
      switch (isFilter) {
        case "draft":
          instance.setModel({ values: ["draft"] });
          break;
        case "pendingManu":
          instance.setModel({ values: ["pendingManu"] });
          break;
        case "inProgress":
          instance.setModel({ values: ["inProgress"] });
          break;
        case "finished":
          instance.setModel({ values: ["finished"] });
          break;
        case "cancelled":
          instance.setModel({ values: ["cancelled"] });
          break;
        default:
          instance.setModel({
            values: ["draft", "pendingManu", "inProgress", "finished"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef?.current?.api) {
      onFilterChanged(gridRef?.current);
    }
    return () => {
      dispatch(manufactureOrderActions.resetAllManufactureOrders());
    };
  }, [dispatch, onFilterChanged]);

  const [view, setView] = useState("table");

  const handleView = (_, newView) => {
    setView(newView);
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("manufacture.order.index")}</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ToggleButtonGroup
            color="primary"
            value={view}
            size="small"
            exclusive
            onChange={handleView}
            aria-label="view"
          >
            <ToggleButton value="table" aria-label="table view">
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton value="calendar" aria-label="calendar view">
              <CalendarTodayIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          {createPermission && (
            <CustomizedButton
              title={`สร้าง${t("manufacture.order.index")}`}
              variant="contained"
              onClick={() => navigate(`/manufacture/order/add`)}
            />
          )}
        </Box>
      </Box>
      {view === "table" && (
        <>
          <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
          <AgGrid
            ref={gridRef}
            columnDefs={manufactureOrderColumnDef(t)}
            height={680}
            onRowDoubleClicked={onRowDoubleClicked}
            onGridReady={onGridReady}
            path={pathname}
          />
        </>
      )}
      {view === "calendar" && <ManufactureOrderCalendar />}
    </>
  );
};

export default ManufacturingOrder;
