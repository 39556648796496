import { useSnackbar } from "notistack";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllItemsAggrid } from "../../features/Inventory/ItemMaster/itemMaster-actions";
import GlobalService from "../../services/Global";
import SettingService from "../../services/Setting";
import CheckboxModalTable from "./CheckboxModalTable";
import { ItemColumnDef } from "./ItemTable/ItemColumnDef";

const CheckedItemModal = ({
  showSelectItem,
  closeItemTable,
  finishItemSelectHandler,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const selectItemGridRef = useRef();

  const getAllTag = useCallback(async () => {
    const { results } = await GlobalService.getTagList({
      startRow: 0,
      endRow: 9999,
    });
    const mapTagValue = results.map((tag) => {
      return {
        ...tag,
        value: tag.name,
        label: tag.name,
      };
    });
    return mapTagValue;
  }, []);

  const getCreables = useCallback(async () => {
    const data = await GlobalService.getCreatables({
      usage_field_type: "item",
      usage_field_name: "item_type",
    });
    return data;
  }, []);

  const getAllItemGroupLevel1 = useCallback(async () => {
    const data = await SettingService.getAllCategory();
    return data;
  }, []);

  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const onClickTagListHandler = (tagName) => {
    navigate(`/inventory/items?filterTag=` + tagName);
  };

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { document_id, name, is_active, item_group_sub_level_1, tag_list } =
        request.filterModel;
      const formatFilterModal = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        name: name && {
          ...name,
          mode: "insensitive",
        },
        tag_list: tag_list
          ? {
              filterType: "objectArray",
              type: "some",
              filter: {
                name: {
                  filterType: "set",
                  values: tag_list.values,
                },
              },
            }
          : {},
        item_group_sub_level_1: item_group_sub_level_1 && {
          filterType: "object",
          filter: {
            name: {
              ...item_group_sub_level_1,
              mode: "insensitive",
            },
          },
        },
        is_active: is_active &&
          is_active.values.length > 0 && {
            filterType: "boolean",
            type: "equals",
            filter: is_active.values[0],
          },
      };

      dispatch(
        getAllItemsAggrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModal,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    const instance = params.api.getFilterInstance("is_active");
    instance.setModel({
      filterType: "set",
      values: ["true"],
    });

    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <CheckboxModalTable
      getRowId={getRowId}
      modalTitle={t("inventory.index")}
      btnTitle={t("button.add")}
      gridRef={selectItemGridRef}
      height={450}
      columnDefs={ItemColumnDef(
        t,
        false,
        null,
        onClickTagListHandler,
        getAllTag,
        getCreables,
        getAllItemGroupLevel1
      )}
      rowSelection="single"
      onFinishEditing={finishItemSelectHandler}
      modalIsOpen={showSelectItem}
      closeModal={closeItemTable}
      onGridReady={onGridReady}
      selectedIds={itemIds}
      setSelectedIds={setItemIds}
      idsSnapshot={itemIdsSnapshot}
      setIdsSnapshot={setItemIdsSnapshot}
      // onFirstDataRendered={onFirstDataRendered}
    />
  );
};

export default CheckedItemModal;
