import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import CustomizedNumberTextField from "../../../../Custom/CustomizedNumberTextField";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import ControlledTextField from "../../../../Controlled/ControlledTextField";
import styled from "@emotion/styled";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "../../../../../pages/Inventory/Items/schema";
import { useDispatch, useSelector } from "react-redux";
import ModalUI from "../../../../UI/ModalUI";
import ItemInfo from "../../../../../pages/Inventory/Items/ItemInfo";
import CheckedItemModal from "../../../../Table/CheckedItemModal";
import { getItem } from "../../../../../features/Inventory/ItemMaster/itemMaster-actions";
import { useSnackbar } from "notistack";
import { formatVatOption } from "../../../../../utils/dataTransformer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { itemMasterActions } from "../../../../../features/Inventory/ItemMaster/itemMaster-slice";
import { formatBom } from "../../../../../features/Manufacture/Bom/bom-actions";

const exceptThisSymbols = ["e", "E", "+", "-", "."];

const ItemDetail = ({
  control,
  errors,
  setValue,
  disabled,
  reset,
  roundedProductionQty,
}) => {
  const { item } = useSelector((state) => state.itemMaster);
  const dispatch = useDispatch();
  const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  }));

  const watchReferenceList = useWatch({
    control,
    name: "reference_document_list",
  });

  const { enqueueSnackbar } = useSnackbar();

  const {
    control: itemInfoControl,
    handleSubmit: handleSubmitItemInfo,
    getValues: getItemInfoValues,
    setValue: setItemInfoValue,
    formState: { errors: itemInfoErrors },
    reset: resetItemInfo,
    clearErrors: clearItemInfoErrors,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [showSelectItem, setShowSelectItem] = useState(false);
  const [showItemForm, setShowItemForm] = useState(false);

  const [itemIds, setItemIds] = useState([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState([]);

  const openItemTable = () => {
    setShowSelectItem(true);
  };

  const closeItemTable = () => {
    setShowSelectItem(false);
  };

  const openItemForm = () => {
    setShowItemForm(true);
  };

  const closeItemForm = () => {
    searchParams.delete("itemtab");
    setSearchParams(searchParams);
    clearItemInfoErrors();
    setShowItemForm(false);
  };

  const watchItemId = useWatch({ control, name: "item_document_id" });

  useEffect(() => {
    if (watchItemId) {
      setItemIds([watchItemId]);
      setItemIdsSnapshot([watchItemId]);
      dispatch(getItem({ document_id: watchItemId }, enqueueSnackbar));
    }
    return () => dispatch(itemMasterActions.resetItem());
  }, [dispatch, enqueueSnackbar, watchItemId]);

  useEffect(() => {
    if (item.document_id !== "") {
      Object.entries(item).forEach(([key, value]) => {
        if (key === "sales_vat_type")
          setItemInfoValue(key, formatVatOption(value));
        else if (key === "attribute_list")
          setItemInfoValue(
            key,
            value.map((item) => item.attribute)
          );
        else setItemInfoValue(key, value);
      });
    }
  }, [item, setItemInfoValue]);

  const finishItemSelectHandler = async (data) => {
    if (data) {
      setValue("item_document_id", data.document_id);
      setValue("item_name", data.name);
      setValue("item_description", data.description);
      setValue("stock_uom", data.base_uom.name);
      setValue("type", data?.manufacture_type);

      if (data.main_bom) {
        setValue("bom_id", data.main_bom.id);
        setValue("bom_name", data.main_bom.name);
        const bom_detail = await formatBom(data.main_bom);
        setValue("bom_detail", bom_detail);
        setValue("ingredient_list", bom_detail.ingredient_list);
      } else {
        setValue("bom_id", "");
        setValue("bom_name", "");
        setValue("bom_detail", "");
        setValue("ingredient_list", []);
      }
    } else {
      reset((prev) => ({
        ...prev,
        item_document_id: "",
        item_name: "",
        stock_uom: "",
        production_qty: 0,
        item_remark: "",
        item_description: "",
        bom_id: "",
        bom_name: "",
        type: "",
        ingredient_list: [],
        bom_detail: null,
      }));
    }
    closeItemTable();
  };

  return (
    <Box mt={3}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography ml={1} fontWeight="bold">
          {t("manufacture.order.item_detail")}
        </Typography>
        {!disabled && (
          <CustomizedTooltip
            title={
              watchReferenceList && watchReferenceList.length > 0
                ? "เลือกสินค้าใหม่ ที่อ้างอิงกับใบสั่งขายใบนี้"
                : "เลือกสินค้า"
            }
          >
            <IconButton
              onClick={openItemTable}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_document_id"
            label={t("manufacture.order.item_document_id")}
            control={control}
            error={Boolean(errors?.item_document_id)}
            helperText={
              errors?.item_document_id && errors?.item_document_id.message
            }
            fullWidth
            disabled
            required
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={openItemForm}>
                    {watchItemId || t("manufacture.order.item_document_id")}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && watchItemId && (
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
                      <IconButton
                        onClick={openItemForm}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_name"
            label={t("manufacture.order.item_name")}
            control={control}
            error={Boolean(errors?.item_name)}
            helperText={errors?.item_name && errors?.item_name.message}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <CustomizedNumberTextField
            type="number"
            name="production_qty"
            onKeyDown={(e) =>
              exceptThisSymbols.includes(e.key) && e.preventDefault()
            }
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {t("manufacture.order.production_qty")}
                <CustomizedTooltip
                  placement="top"
                  title={`จำนวนสั่งผลิตขั้นต่ำเพื่อเบิกวัตถุดิบที่พอดี = ${roundedProductionQty}`}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </CustomizedTooltip>
              </Box>
            }
            control={control}
            error={Boolean(errors?.production_qty)}
            helperText={
              errors?.production_qty && errors?.production_qty.message
            }
            disabled={disabled}
            InputLabelProps={{ style: { pointerEvents: "auto" } }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="stock_uom"
            label={t("manufacture.order.stock_uom")}
            control={control}
            error={Boolean(errors?.stock_uom)}
            helperText={errors?.stock_uom && errors?.stock_uom.message}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_remark"
            label={t("manufacture.order.item_remark")}
            control={control}
            error={Boolean(errors?.item_remark)}
            helperText={errors?.item_remark && errors?.item_remark.message}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="item_description"
            label={t("manufacture.order.item_description")}
            control={control}
            error={Boolean(errors?.item_description)}
            helperText={
              errors?.item_description && errors?.item_description.message
            }
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <CheckedItemModal
        showSelectItem={showSelectItem}
        closeItemTable={closeItemTable}
        setValue={setValue}
        reset={reset}
        finishItemSelectHandler={finishItemSelectHandler}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
      />
      <ModalUI
        open={showItemForm}
        navigateTo={`/inventory/items/${watchItemId}`}
        handleClose={closeItemForm}
        fullWidth
        title="สินค้า"
        maxWidth="lg"
      >
        <ItemInfo
          control={itemInfoControl}
          errors={itemInfoErrors}
          getValues={getItemInfoValues}
          handleSubmit={handleSubmitItemInfo}
          reset={resetItemInfo}
          setValue={setItemInfoValue}
          onCancel={closeItemForm}
          viewOnly={true}
        />
      </ModalUI>
    </Box>
  );
};

export default ItemDetail;
