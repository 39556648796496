import {
  //   Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {
  formatNumber,
  formatPriceNumber,
} from "../../../utils/dataTransformer";

const PurchaseOrderPdf = ({ itemList }) => {
  const header = [
    {
      label: "No.",
      thaiLabel: "รายการ",
      width: 55,
    },
    {
      label: "Name",
      thaiLabel: "ชื่อสินค้า",
      width: 130,
    },
    {
      label: "PO Qty",
      thaiLabel: "จำนวนสั่งซื้อ",
      width: 70,
    },
    {
      label: "Unit",
      thaiLabel: "หน่วย",
      width: 60,
    },
    {
      label: "Price/Unit",
      thaiLabel: "ราคา/หน่วย",
      width: 80,
    },
    {
      label: "Discount",
      thaiLabel: "ส่วนลด",
      width: 80,
    },
    // {
    //   label: "Vat",
    //   thaiLabel: "ภาษี",
    //   width: 40,
    // },
    {
      label: "Pre-vat Amount",
      thaiLabel: "จำนวนเงิน",
      width: 90,
    },
    // {
    //   label: "PR Qty",
    //   thaiLabel: "ใบขอซื้อ",
    //   width: 80,
    // },
  ];
  return (
    <TableContainer>
      <Table sx={{ overflow: "scroll" }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#e8f1e8" }}>
          <TableRow>
            {header.map((header, index) => (
              <TableCell
                align="center"
                key={index}
                width={header.width}
                sx={{
                  px: 0.25,
                  py: 1,
                }}
              >
                <Typography fontSize={12} fontWeight={600}>
                  {header.thaiLabel}
                </Typography>
                <Typography fontSize={12} fontWeight={600}>
                  {header.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {itemList.map((item, index) => (
            <TableRow key={item.uid} sx={{ breakInside: "avoid" }}>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{index + 1}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12} fontWeight={600}>
                  {item.item_name}
                </Typography>
                {/* <Divider sx={{ my: 1 }} /> */}
                <Typography fontSize={12} whiteSpace="pre-line">
                  {item.item_description}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{formatNumber(item.qty)}</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{item.uom.name}</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {formatPriceNumber(item.price_per_unit)}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {formatPriceNumber(item.discount_amount)}
                </Typography>
              </TableCell>
              {/* <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {item.vat_type !== "ไม่มี"
                    ? item.vat_type + "%"
                    : item.vat_type}
                </Typography>
              </TableCell> */}
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {formatNumber(
                    item.qty * item.price_per_unit - item.discount_amount
                  )}
                </Typography>
              </TableCell>
              {/* <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {item.ref_document_id || "-"}
                </Typography>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PurchaseOrderPdf;
