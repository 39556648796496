import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useForm } from "react-hook-form";
import moment from "moment";
// import { Grid } from "@mui/material";
import { useAuth } from "../../hooks/use-auth";
import { Grid } from "@mui/material";
import FilePreviewActivityLogs from "./FilePreviewActivityLogs";
import { useWatch } from "react-hook-form";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const AttachmentDropzone = ({
  setValue,
  getValues,
  valueToSet,
  manualRevoke,
  disabled,
  mainControl,
  open,
  reset,
}) => {
  const { user } = useAuth();
  const {
    control,
    setValue: setDocValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { files: getValues("attachment_list") } });
  const { append, remove } = useFieldArray({ control, name: "files" });
  const watchFieldArray = watch("files");
  const [files, setFiles] = useState(watchFieldArray || []);
  const watchState = useWatch({
    control: mainControl,
    name: "attachment_list",
  });

  useEffect(() => {
    if (watchState?.length === 0) {
      setFiles([]);
    }
  }, [watchState]);

  useEffect(() => {
    return () => {
      reset();
      setFiles([]);
    };
  }, [reset]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles((prev) => [
        ...prev,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            fileName: file.name,
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
      acceptedFiles.forEach((file) => {
        append({
          ...file,
          fileName: file.name,
          name: file.name,
          type: file.type,
        });
      });
    },
    disabled: disabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (index) => () => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    remove(index);
  };

  const updateFileName = (index) => {
    let newFiles = [...files];
    const updatedFileName = watchFieldArray[index].name;
    setDocValue(`files[${index}].fileName`, updatedFileName);
    newFiles[index].fileName = updatedFileName;
    setFiles(newFiles);
  };

  const uploadedDate = moment().format("DD/MM/YYYY HH:mm");

  const thumbs = files.map((file, index) => (
    <Grid item xs={6} mt={1}>
      <div key={file.name}>
        <FilePreviewActivityLogs
          control={control}
          errors={errors}
          fileName={file.fileName}
          full_name={user.first_name + " " + user.last_name}
          index={index}
          date={uploadedDate}
          onRemove={removeFile(index)}
          onUpdate={updateFileName}
          viewOnly={disabled}
          file={file}
        />
      </div>
    </Grid>
  ));

  useEffect(() => {
    if (files.length !== 0) setValue(valueToSet, files);
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      if (!manualRevoke) {
        files.forEach((file) => URL.revokeObjectURL(file.preview));
      }
    };
  }, [files, manualRevoke, setValue, valueToSet]);

  return (
    <>
      <section className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>ลากมาวางเพื่ออัปโหลด</p>
        </div>
        <Grid container spacing={2}>
          {thumbs}
        </Grid>
      </section>
    </>
  );
};

export default AttachmentDropzone;
