import { gql } from "graphql-request";

export const GET_ACTIVITY_LOGS = gql`
  query ActivityLogs($documentId: String!) {
    activityLogs(document_id: $documentId) {
      id
      document_id
      activity_details {
        message
        curr_status
        prev_status
        deleted_related_employee {
          document_id
          first_name
          last_name
        }
        added_related_employee {
          document_id
          first_name
          last_name
        }
      }
      activity_type
      created_date
      created_by {
        id
        document_id
        first_name
        last_name
      }
      document_type
      attachment_list
    }
  }
`;
