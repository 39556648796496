import { gql } from "graphql-request";

export const CREATE_ACTIVITY_LOGS = gql`
  mutation CreateActivityLog($createActivityLogInput: CreateActivityLog) {
    createActivityLog(createActivityLogInput: $createActivityLogInput) {
      id
      document_id
      activity_details {
        message
        curr_status
        prev_status
        deleted_related_employee {
          document_id
          first_name
          last_name
        }
        added_related_employee {
          document_id
          first_name
          last_name
        }
      }
      activity_type
      created_date
      created_by {
        id
        document_id
        first_name
        last_name
      }
      document_type
      attachment_list
    }
  }
`;
