import GlobalService from "../../../services/Global";
import { formatNumber } from "../../../utils/dataTransformer";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import CustomizedStatus from "../../Custom/CustomizedStatus";

export const bomColumnDef = (t, disabled, isReport) => {
  if (isReport) {
    return [
      {
        field: "created_date",
        headerName: t("manufacture.bom.created_date"),
        filter: "agDateColumnFilter",
        width: 175,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "start_date",
        headerName: t("manufacture.bom.start_date"),
        filter: "agDateColumnFilter",
        width: 175,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "end_date",
        headerName: t("manufacture.bom.end_date"),
        filter: "agDateColumnFilter",
        width: 175,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "type",
        headerName: t("manufacture.bom.type"),
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: (params) => {
            return filterStatusValueFormatter(params.value);
          },
          values: async (params) => {
            const loadedOptions = await GlobalService.getCreatables({
              usage_field_type: "item",
              usage_field_name: "manufacture_type",
            });
            const formatType = loadedOptions.map((option) => option.name);
            params.success(formatType);
          },
        },
      },
      {
        field: "name",
        headerName: t("manufacture.bom.bom_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_document_id",
        headerName: t("manufacture.bom.item_document_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_name",
        headerName: t("manufacture.bom.item_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "ingredient_name",
        headerName: t("manufacture.bom.ingredient_name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "ingredient_qty",
        headerName: t("manufacture.bom.ingredient_qty"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => formatNumber(params.value),
      },
      {
        field: "ingredient_uom",
        headerName: t("manufacture.bom.ingredient_uom"),
        filter: false,
        sortable: false,
      },
      {
        field: "mfg_qty",
        headerName: t("manufacture.bom.mfg_qty_report"),
        filter: false,
        sortable: false,
        valueFormatter: (params) => formatNumber(params.value),
      },
      {
        field: "stock_uom",
        headerName: t("manufacture.bom.stock_uom_report"),
        filter: false,
        sortable: false,
      },
      {
        field: "internal_remark",
        headerName: t("manufacture.bom.internal_remark"),
        filter: false,
        sortable: false,
        disableFloatingFilter: true,
      },
      {
        field: "status",
        headerName: t("manufacture.bom.status"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ใช้งาน", "ไม่ใช้งาน"],
        },
        cellRenderer: (params) => <CustomizedStatus status={params.value} />,
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
      },
      {
        field: "description",
        headerName: t("manufacture.bom.bom_description"),
        filter: false,
        sortable: false,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "is_main_bom",
        headerName: t("manufacture.bom.is_main_bom"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["ใช่", "ไม่ใช่"],
        },
      },
    ];
  }

  const formatColumnDef = [
    {
      field: "name",
      headerName: t("manufacture.bom.bom_name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "item_document_id",
      headerName: t("manufacture.bom.item_document_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        readOnly: disabled,
      },
    },

    {
      field: "item_name",
      headerName: t("manufacture.bom.item_name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "type",
      headerName: t("manufacture.bom.type"),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: async (params) => {
          const loadedOptions = await GlobalService.getCreatables({
            usage_field_type: "item",
            usage_field_name: "manufacture_type",
          });
          const formatType = loadedOptions.map((option) => option.name);
          params.success(formatType);
        },
      },
    },
    {
      field: "created_date",
      headerName: t("sales.createdDate"),
      sort: "desc",
      hide: true,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.created_date),
      width: 200,
      suppressColumnsToolPanel: true,
    },
    {
      field: "start_date",
      headerName: t("manufacture.bom.start_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => {
        if (!params.value) return "";
        return unixToDateWithFormat(params.value);
      },
    },
    {
      field: "end_date",
      headerName: t("manufacture.bom.end_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => {
        if (!params.value) return "";
        return unixToDateWithFormat(params.value);
      },
    },
    {
      field: "internal_remark",
      headerName: t("manufacture.bom.internal_remark"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "status",
      headerName: t("manufacture.bom.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: ["active", "inactive"],
      },
      cellRenderer: (params) => <CustomizedStatus status={params.value} />,
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
    {
      field: "is_main_bom",
      headerName: t("manufacture.bom.is_main_bom"),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: ["TRUE", "FALSE"],
      },
      valueFormatter: (params) => {
        if (params.value === "TRUE") {
          return "ใช่";
        } else {
          return "ไม่";
        }
      },
    },
  ];

  return formatColumnDef;
};
