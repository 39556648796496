import UserService from "../../../services/User";
import GlobalService from "../../../services/Global";
import { accountActions } from "./account-slice";
import LogisticService from "../../../services/Logistic";
import { errorMessageHandler } from "../../../utils/dataTransformer";

export const getAllUsers =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(accountActions.onLoading("allUsers"));
    try {
      const allUsers = await UserService.getAllUsers(input);
      params.successCallback(allUsers.results, allUsers.count);
      dispatch(accountActions.loadedAllUsers(allUsers.results));
    } catch (err) {
      console.error(err);
      dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllUserWithDT =
  (input, params, enqueueSnackbar, date) => async (dispatch) => {
    dispatch(accountActions.onLoading("allUsersModal"));
    try {
      const allUsers = await UserService.getAllUsers(input);
      const allUserDocumentId = allUsers.results.map(
        (user) => user.document_id
      );
      const deliveryTrips = await LogisticService.getAllDeliveryTripsByEmployee(
        allUserDocumentId,
        date
      );
      allUsers.results.forEach((user, index) => {
        const allDeliveryCount = deliveryTrips.results.filter((dt) =>
          dt.goods_deliver_list
            .map((goods) => goods.document_id)
            .includes(user.document_id)
        ).length;
        allUsers.results[index].total_delivery_trip = allDeliveryCount;
      });
      params.successCallback(allUsers.results, allUsers.count);
      dispatch(accountActions.loadedAllUsers(allUsers.results));
    } catch (err) {
      console.error("error", err);
      dispatch(
        accountActions.rejectedActions({ ...err, name: "allUsersModal" })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllUsersNoAggrid = () => async (dispatch) => {
  dispatch(accountActions.onLoading("allUsers"));
  try {
    const allUsers = await UserService.getAllUsersNoAggrid();
    dispatch(accountActions.loadedAllUsers(allUsers));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
  }
};

export const postNewUser =
  (documentType, createUserInput, navigate, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(accountActions.onLoading("user"));
    try {
      const document_id = await GlobalService.getRunningDocumentId(
        documentType
      );
      const payload = {
        document_id,
        ...createUserInput,
      };
      const newUser = await UserService.postNewUser(payload);
      dispatch(accountActions.appendedNewUser(newUser));
      enqueueSnackbar("เพิ่มผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      navigate("/user/account");
    } catch (err) {
      dispatch(accountActions.rejectedActions({ ...err, name: "user" }));
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(
            errorMessageHandler(
              error.message,
              "อีเมลนี้มีในระบบแล้ว กรุณาะบุใหม่"
            ),
            {
              variant: "error",
            }
          );
        } else {
          enqueueSnackbar("เพิ่มผู้ใช้งานไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getUser = (uniqueInput, enqueueSnackbar) => async (dispatch) => {
  dispatch(accountActions.onLoading("user"));
  try {
    const user = await UserService.getUser(uniqueInput);
    dispatch(accountActions.loadedUser(user));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "user" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const updateUser =
  (uniqueInput, updateInput, isSetting, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(accountActions.onLoading("allUsers"));
    try {
      const updateduser = await UserService.updateUser(
        uniqueInput,
        updateInput
      );
      dispatch(accountActions.updatedUser(updateduser));
      if (isSetting) {
        enqueueSnackbar("แก้ไขข้อมูลสำเร็จ", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
          variant: "success",
        });
        navigate("/user/account");
      }
    } catch (err) {
      dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
      if (isSetting) {
        enqueueSnackbar("แก้ไขข้อมูลไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("แก้ไขผู้ใช้งานไม่สำเร็จ", {
          variant: "error",
        });
      }
      console.error(err);
    }
  };

export const disableUser = (uniqueInput) => async (dispatch) => {
  dispatch(accountActions.onLoading("allUsers"));
  try {
    const disabledUser = await UserService.disableUser(uniqueInput);
    dispatch(accountActions.disabledUser(disabledUser));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
  }
};
