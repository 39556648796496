import SalesService from "../../../services/Sales";
import { salesInvoiceActions } from "./sales-invoice-slice";
import { salesQueryFormatter } from "../../../utils/salesPayloadFormatter";
import GlobalService from "../../../services/Global";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import { filterStatusValueFormatter } from "../../../utils/filterparams";

export const getAllSalesInvoices =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("allSalesInvoices"));
    try {
      const allSalesInvoices = await SalesService.getAllSalesInvoices(input);
      params.successCallback(allSalesInvoices.results, allSalesInvoices.count);
      dispatch(
        salesInvoiceActions.loadedAllSalesInvoices(allSalesInvoices.results)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        salesInvoiceActions.rejectedActions({
          ...err,
          name: "allSalesInvoices",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllSalesInvoicesExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("allSalesInvoices"));
    try {
      const { results } = await SalesService.getAllSalesInvoices(input);
      const formatAllSalesInvoices = results.map((salesInvoice) => {
        let item_list = [];
        salesInvoice.item_list.forEach((item) => {
          const pre_vat_amount =
            item.qty * item.price_per_unit - item.discount_amount;
          const withholding_tax =
            item.withholding_tax === -1
              ? "ยังไม่ระบุ"
              : item.withholding_tax === 0
              ? "ไม่มี"
              : (item.withholding_tax * 100).toString();
          const formatItemList = {
            ...item,
            pre_vat_amount,
            withholding_tax,
          };
          item_list.push(formatItemList);
        });
        return {
          ...salesInvoice,
          item_list,
          issue_date: unixToDateWithFormat(salesInvoice.issue_date),
          due_date: unixToDateWithFormat(salesInvoice.due_date),
          created_date: unixToDateWithFormat(salesInvoice.created_date),
          updated_date: unixToDateWithFormat(salesInvoice.updated_date),
          render_status: filterStatusValueFormatter(salesInvoice.render_status),
        };
      });
      dispatch(
        salesInvoiceActions.loadedAllSalesInvoices(formatAllSalesInvoices)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        salesInvoiceActions.rejectedActions({
          ...err,
          name: "allSalesInvoices",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllSalesInvoicesExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("allSalesInvoices"));
    try {
      const { results } = await SalesService.getAllSalesInvoicesReport(input);
      const formatAllSalesInvoices = results.map((salesInvoice) => {
        let item_list = [];
        salesInvoice.item_list.forEach((item) => {
          const pre_vat_amount =
            item.qty * item.price_per_unit - item.discount_amount;
          const withholding_tax =
            item.withholding_tax === -1
              ? "ยังไม่ระบุ"
              : item.withholding_tax === 0
              ? "ไม่มี"
              : (item.withholding_tax * 100).toString();
          const formatItemList = {
            ...item,
            pre_vat_amount,
            withholding_tax,
          };
          item_list.push(formatItemList);
        });
        return {
          ...salesInvoice,
          item_list,
          issue_date: unixToDateWithFormat(salesInvoice.issue_date),
          due_date: unixToDateWithFormat(salesInvoice.due_date),
          created_date: unixToDateWithFormat(salesInvoice.created_date),
          updated_date: unixToDateWithFormat(salesInvoice.updated_date),
          render_status: filterStatusValueFormatter(salesInvoice.render_status),
        };
      });
      dispatch(
        salesInvoiceActions.loadedAllSalesInvoices(formatAllSalesInvoices)
      );
    } catch (err) {
      console.log(err);
      dispatch(
        salesInvoiceActions.rejectedActions({
          ...err,
          name: "allSalesInvoices",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewSalesInvoice =
  (payload, isSendApprove, state, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    try {
      const result = await SalesService.createSalesInvoice(payload);
      const createActivityLog = createActivityLogPayload(
        payload,
        "sales_invoice",
        "สร้างใบแจ้งหนี้"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });

      if (payload.reference_document_id) {
        const createRefActivityLog = createActivityLogPayload(
          {
            ...payload,
            document_id: payload.reference_document_id,
          },
          "sales_order",
          "สร้างใบแจ้งหนี้"
        );
        const finishedActivityLog = createActivityLogStatusPayload(
          {
            ...payload,
            document_id: payload.reference_document_id,
          },
          "sales_order",
          "approved",
          "finished"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createRefActivityLog,
        });
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: finishedActivityLog,
        });
      }

      if (state && state?.isCopied) {
        const copiedActivityLog = createActivityLogCopiedPayload(
          state,
          "sales_invoice"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }

      if (isSendApprove) {
        const documentInput = {
          document_type: "sales_invoice",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          payload,
          "sales_invoice",
          null,
          "wait_approve"
        );
        await SalesService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
          variant: "success",
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          payload,
          "sales_invoice",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(`/sales/invoice/${encodeURIComponent(result.document_id)}`);
    } catch (err) {
      dispatch(
        salesInvoiceActions.rejectedActions({ ...err, name: "salesInvoice" })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบแจ้งหนี้ไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getSalesInvoiceById =
  (uniqueInput, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("salesInvoice"));
    try {
      const salesInvoice = await SalesService.getSalesInvoiceById(uniqueInput);
      const formatSalesInvoice = await salesQueryFormatter(
        salesInvoice,
        "sales_invoice"
      );

      // const approvalProgress = await SalesService.getApprovalProgress({
      //   reference_document_type: "sales_invoice",
      //   reference_document_id: uniqueInput.document_id,
      // });

      // dispatch(
      //   salesInvoiceActions.loadedApprovalList({
      //     approvalProgress,
      //   })
      // );

      dispatch(salesInvoiceActions.loadedSalesInvoice(formatSalesInvoice));
    } catch (err) {
      console.error(err);
      dispatch(
        salesInvoiceActions.rejectedActions({ ...err, name: "salesInvoice" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getPaymentReceiptTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("salesInvoice"));
    try {
      const paymentReceiptTab = [];
      // const referenceDocument =
      //   await GlobalService.getReferenceDocumentRelations(documentId);
      // const filteredPaymentReceipt = referenceDocument.filter(
      //   (document) => document.document_type === "payment_receipt"
      // );
      // for (const [index, value] of filteredPaymentReceipt.entries()) {
      //   const paymentReceipt = await SalesService.getPaymentReceiptById({
      //     document_id: value.document_id,
      //   });
      //   paymentReceiptTab[index] = paymentReceipt;
      // }
      dispatch(salesInvoiceActions.loadedPaymentReceiptTab(paymentReceiptTab));
    } catch (err) {
      dispatch(
        salesInvoiceActions.rejectedActions({ ...err, name: "saleInvoice" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getCreditNoteTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("salesInvoice"));
    try {
      const creditNoteTab = [];
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredcreditNote = referenceDocument.filter(
        (document) => document.document_type === "credit_note"
      );
      for (const [index, value] of filteredcreditNote.entries()) {
        const creditNote = await SalesService.getCreditNoteById({
          document_id: value.document_id,
        });
        creditNoteTab[index] = creditNote;
      }
      dispatch(salesInvoiceActions.loadedCreditNoteTab(creditNoteTab));
    } catch (err) {
      dispatch(
        salesInvoiceActions.rejectedActions({ ...err, name: "saleInvoice" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const updateSalesInvoice =
  (payload, status, isChangedStatus, existing, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("salesInvoice"));
    try {
      const salesInvoice = await SalesService.updateSalesInvoice(payload);
      const formatSalesInvoice = await salesQueryFormatter(
        salesInvoice,
        "sales_invoice"
      );
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "sales_invoice",
        };
        const updatedSalesInvoice = await SalesService.postNextStatus(
          documentInput
        );
        if (
          updatedSalesInvoice?.approval_progress_list
            ?.map((approval) => approval?.approval_status)
            .includes("PENDING")
        ) {
          const sendToApproveActivityLog = createActivityLogStatusPayload(
            payload,
            "sales_invoice",
            "draft",
            "wait_approve"
          );
          dispatch(
            salesInvoiceActions.loadedSalesInvoice({
              ...formatSalesInvoice,
              render_status: "waitApprove",
            })
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToApproveActivityLog,
          });
          enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
            variant: "success",
          });
        } else {
          dispatch(
            salesInvoiceActions.loadedSalesInvoice({
              ...formatSalesInvoice,
              render_status: updatedSalesInvoice.status,
            })
          );
          enqueueSnackbar("อัพเดทใบแจ้งหนี้สำเร็จ", {
            variant: "success",
          });
        }
      } else {
        const editActivityLog = createActivityLogEditPayload(
          payload,
          "sales_invoice"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
        const { addedEmployeeList, deletedEmployeeList } =
          findDifferenceEmployee(payload, existing);
        if (addedEmployeeList && addedEmployeeList.length > 0) {
          // add related employee
          const addedEmployeeListLog = createActivityLogEmployeePayload(
            payload,
            "add_related_employee",
            addedEmployeeList,
            "sales_invoice"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: addedEmployeeListLog,
          });
        }
        if (deletedEmployeeList && deletedEmployeeList.length > 0) {
          // delete related employee
          const deletedEmployeeListLog = createActivityLogEmployeePayload(
            payload,
            "delete_related_employee",
            deletedEmployeeList,
            "sales_invoice"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: deletedEmployeeListLog,
          });
        }
        switch (status) {
          case "draft":
            dispatch(
              salesInvoiceActions.loadedSalesInvoice(formatSalesInvoice)
            );
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
            break;
          default:
            dispatch(
              salesInvoiceActions.loadedSalesInvoice({
                ...formatSalesInvoice,
                render_status: status,
              })
            );
            enqueueSnackbar("แก้ไขใบแจ้งหนี้สำเร็จ", {
              variant: "success",
            });
        }
      }
    } catch (err) {
      dispatch(
        salesInvoiceActions.rejectedActions({ ...err, name: "salesInvoice" })
      );
      switch (status) {
        case "draft":
          if (isChangedStatus) {
            err.response.errors.forEach((error) => {
              if (errorMessageHandler(error.message)) {
                enqueueSnackbar(errorMessageHandler(error.message), {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("แก้ไขใบแจ้งหนี้ไม่สำเร็จ", {
                  variant: "error",
                });
              }
            });
          } else {
            enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        default:
          enqueueSnackbar("แก้ไขใบแจ้งหนี้ไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const approveSalesInvoice =
  (documentInput, payload, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("salesInvoice"));
    try {
      const approvedActivityLog = createActivityLogStatusPayload(
        payload,
        "sales_invoice",
        "wait_approve",
        "wait_payment"
      );
      await SalesService.postApproveStatus(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: approvedActivityLog,
      });
      enqueueSnackbar("อนุมัติสำเร็จ", {
        variant: "success",
      });
      dispatch(salesInvoiceActions.updateSalesInvoiceStatus("waitPayment"));
    } catch (err) {
      console.error(err);
      dispatch(
        salesInvoiceActions.rejectedActions({ ...err, name: "salesInvoice" })
      );
      enqueueSnackbar("อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const declineSalesInvoice =
  (documentInput, payload, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("salesInvoice"));
    try {
      const notApprovedActivityLog = createActivityLogStatusPayload(
        payload,
        "sales_invoice",
        "wait_approve",
        "not_approved"
      );
      await SalesService.postDeclineStatus(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: notApprovedActivityLog,
      });
      enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
        variant: "success",
      });
      dispatch(salesInvoiceActions.updateSalesInvoiceStatus("notApproved"));
    } catch (err) {
      console.error(err);
      dispatch(
        salesInvoiceActions.rejectedActions({ ...err, name: "salesInvoice" })
      );
      enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const cancelSalesInvoice =
  (documentInput, payload, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesInvoiceActions.onLoading("salesInvoice"));
    try {
      const cancelActivityLog = createActivityLogStatusPayload(
        payload,
        "sales_invoice",
        null,
        "cancelled"
      );
      await SalesService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(salesInvoiceActions.updateSalesInvoiceStatus("cancelled"));
    } catch (err) {
      dispatch(
        salesInvoiceActions.rejectedActions({ ...err, name: "salesInvoice" })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
