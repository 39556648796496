import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { usePermission } from "../../../hooks/use-auth";
import { filteredRow } from "../../../utils/dataTransformer";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedCheckboxes from "../../Custom/CustomizedCheckboxes";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import CustomizedTab from "../../Custom/CustomizedTab";
import ConfigPermission from "./Config";
import ContactPermission from "./Contact";
import InventoryPermission from "./Inventory";
import LogisticPermission from "./Logistic";
import ManufacturePermission from "./Manufacture";
import PurchasePermission from "./Purchase";
import RevenuePermission from "./Revenue";
import UserPermission from "./User";

const PermissionForm = ({
  onClick,
  control,
  errors,
  viewOnly,
  addable,
  setting,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const subtab = searchParams.get("subtab");
  const { priviledgeList } = useSelector((state) => state.rbac);

  // const [disabledRowIndex, setDisabledRowIndex] = useState({
  //   inventory: [],
  //   contact: [],
  //   sales: [],
  //   purchase: [],
  //   logistic: [],
  //   manufacture: [],
  //   user: [],
  //   config: [],
  // });
  const { createPermission, editPermission } = usePermission();

  const [columnDefs, setColumnDefs] = useState();
  const [contactRow, setContactRow] = useState([]);

  const tabs = [
    {
      label: t("inventory.index"),
      path: `${pathname}?step=permission&subtab=inventory`,
    },
    {
      label: t("contact.index"),
      path: `${pathname}?step=permission&subtab=contact`,
    },
    {
      label: t("sales.index"),
      path: `${pathname}?step=permission&subtab=sales`,
    },
    {
      label: t("purchase.index"),
      path: `${pathname}?step=permission&subtab=purchase`,
    },
    {
      label: t("logistic.index"),
      path: `${pathname}?step=permission&subtab=logistic`,
    },
    {
      label: t("manufacture.index"),
      path: `${pathname}?step=permission&subtab=manufacture`,
    },
    {
      label: t("user.index"),
      path: `${pathname}?step=permission&subtab=user`,
    },
    {
      label: t("setting.index"),
      path: `${pathname}?step=permission&subtab=config`,
    },
  ];

  useEffect(() => {
    const rows = [
      {
        functionName: "ALL",
        name: t("contact.index"),
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
      },
      {
        functionName: "CUSTOMER",
        name: t("contact.index") + " (ลูกค้า)",
        edit: "edit",
        delete: "delete",
      },
      {
        functionName: "VENDOR",
        name: t("contact.index") + " (ผู้ขาย)",
        edit: "edit",
        delete: "delete",
      },
      {
        functionName: "REPORT",
        name: t("report"),
        view: "view",
      },
    ];
    const filtered = filteredRow(
      rows,
      (addable || viewOnly || setting) && priviledgeList,
      subtab.toUpperCase()
    );

    setContactRow(filtered);
  }, [addable, priviledgeList, setting, subtab, t, viewOnly]);

  const RenderCustomizedSelect = ({
    params,
    name,
    viewOnly,
    addable,
    setting,
  }) => {
    const [showSelect, setShowSelect] = useState(false);
    const [defaultOptions, setDefaultOptions] = useState([
      {
        id: 1,
        label: "ปิด",
        value: "CLOSE",
      },
      {
        id: 2,
        label: "ดู",
        value: "VIEW",
      },
    ]);

    const [formatName, setFormatName] = useState(null);

    useEffect(() => {
      if (params.value) {
        const formatName =
          `${subtab}.${params.data.functionName}.${name}`.toUpperCase();
        setFormatName(formatName);
        setShowSelect(true);
        if (formatName.includes("PURCHASE.REQUEST")) {
          setDefaultOptions((prev) => [
            ...prev,
            {
              id: 3,
              label: "ดูของตัวเองเท่านั้น",
              value: "VIEW_SELF",
            },
          ]);
        }
      }
    }, [name, params.data.functionName, params.value]);

    return (
      showSelect && (
        <Controller
          control={control}
          name={formatName}
          render={({ field }) => (
            <CustomizedSelect
              {...field}
              options={defaultOptions}
              errors={`${errors}.${formatName}`}
              disabled={viewOnly || addable || setting}
            />
          )}
        />
      )
    );
  };

  const RenderCustomizedCheckBoxes = ({
    params,
    name,
    viewOnly,
    addable,
    setting,
  }) => {
    const [showSelect, setShowSelect] = useState(false);
    const [formatName, setFormatName] = useState(null);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      if (params.value) {
        const formatName =
          `${subtab}.${params.data.functionName}.${name}`.toUpperCase();
        setFormatName(formatName);
        setShowSelect(true);
      }
    }, [name, params.data.functionName, params.value]);

    const currentWatch = useWatch({
      control,
      name: `${subtab}.${
        params.data.functionName === "VENDOR" ||
        params.data.functionName === "CUSTOMER"
          ? "ALL"
          : params.data.functionName
      }`.toUpperCase(),
    });

    const watchCustomer = useWatch({
      control,
      name: "CONTACT.CUSTOMER",
    });

    const watchVendor = useWatch({
      control,
      name: "CONTACT.VENDOR",
    });

    useEffect(() => {
      if (currentWatch.VIEW && currentWatch.VIEW === "CLOSE") {
        setDisabled(true);
      } else {
        if (subtab === "contact") {
          if (
            (currentWatch.EDIT || currentWatch.DELETE) &&
            (params.data.functionName === "CUSTOMER" ||
              params.data.functionName === "VENDOR") &&
            name !== "create"
          ) {
            setDisabled(true);
          } else if (
            (watchCustomer.EDIT || watchCustomer.DELETE) &&
            (params.data.functionName === "ALL" ||
              params.data.functionName === "VENDOR") &&
            name !== "create"
          ) {
            setDisabled(true);
          } else if (
            (watchVendor.EDIT || watchVendor.DELETE) &&
            (params.data.functionName === "ALL" ||
              params.data.functionName === "CUSTOMER") &&
            name !== "create"
          ) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        } else {
          setDisabled(false);
        }
      }
    }, [
      currentWatch.DELETE,
      currentWatch.EDIT,
      currentWatch.VIEW,
      name,
      params.data.functionName,
      watchCustomer.DELETE,
      watchCustomer.EDIT,
      watchVendor.DELETE,
      watchVendor.EDIT,
    ]);

    return (
      showSelect && (
        <Controller
          control={control}
          name={formatName}
          render={({ field }) => {
            return (
              <CustomizedCheckboxes
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
                isDisabled={viewOnly || disabled || addable || setting}
              />
            );
          }}
        />
      )
    );
  };

  useEffect(() => {
    switch (subtab) {
      case "inventory":
      case "manufacture":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedSelect
                params={params}
                name="view"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
          {
            field: "create",
            headerName: "สร้าง",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="create"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "edit",
            headerName: "แก้ไข",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="edit"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "delete",
            headerName: "ลบ",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="delete"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "cancel",
            headerName: "ยกเลิก",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="cancel"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
        ]);
        break;
      case "contact":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedSelect
                params={params}
                name="view"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
          {
            field: "create",
            headerName: "สร้าง",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="create"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "edit",
            headerName: "แก้ไข",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="edit"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "delete",
            headerName: "ลบ",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="delete"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
        ]);
        break;
      case "sales":
      case "purchase":
      case "logistic":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedSelect
                params={params}
                name="view"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
          {
            field: "create",
            headerName: "สร้าง",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="create"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "edit",
            headerName: "แก้ไข",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="edit"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "cancel",
            headerName: "ยกเลิก",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="cancel"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
        ]);
        break;
      case "user":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedSelect
                params={params}
                name="view"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
          {
            field: "create",
            headerName: "สร้าง",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="create"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "edit",
            headerName: "แก้ไข",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="edit"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "delete",
            headerName: "ลบ",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="delete"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
        ]);
        break;
      case "config":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedSelect
                params={params}
                name="view"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
          {
            field: "create",
            headerName: "สร้าง",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="create"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
          {
            field: "edit",
            headerName: "แก้ไข / ลบ",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
              <RenderCustomizedCheckBoxes
                params={params}
                name="edit"
                viewOnly={viewOnly}
                addable={addable}
                setting={setting}
              />
            ),
          },
        ]);
        break;
      default:
        return;
    }
  }, [addable, setting, subtab, viewOnly]);

  const renderTab = (subtab) => {
    switch (subtab) {
      case "inventory":
        return (
          <InventoryPermission
            columnDefs={columnDefs}
            priviledgeList={(addable || viewOnly || setting) && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      case "contact":
        return (
          <ContactPermission columnDefs={columnDefs} contactRow={contactRow} />
        );
      case "sales":
        return (
          <RevenuePermission
            columnDefs={columnDefs}
            priviledgeList={(addable || viewOnly || setting) && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      case "purchase":
        return (
          <PurchasePermission
            columnDefs={columnDefs}
            priviledgeList={(addable || viewOnly || setting) && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      case "logistic":
        return (
          <LogisticPermission
            columnDefs={columnDefs}
            priviledgeList={(addable || viewOnly || setting) && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      case "manufacture":
        return (
          <ManufacturePermission
            columnDefs={columnDefs}
            priviledgeList={(addable || viewOnly || setting) && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      case "user":
        return (
          <UserPermission
            columnDefs={columnDefs}
            priviledgeList={(addable || viewOnly || setting) && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      case "config":
        return (
          <ConfigPermission
            columnDefs={columnDefs}
            priviledgeList={(addable || viewOnly || setting) && priviledgeList}
            resource={subtab.toUpperCase()}
          />
        );
      default:
        return;
    }
  };

  const currentTab =
    pathname + (subtab ? `?step=permission&subtab=${subtab}` : "");

  return (
    <>
      <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
      {renderTab(subtab)}
      {onClick
        ? !viewOnly && (
            <CustomizedButton
              onClick={onClick}
              title="ดำเนินการต่อ"
              variant="contained"
              sx={{ my: 2 }}
            />
          )
        : ((((editPermission && id) || (createPermission && !id)) &&
            pathname.includes("account")) ||
            setting) && (
            <CustomizedButton
              title="บันทึก"
              variant="contained"
              sx={{ mt: 2 }}
              type="submit"
            />
          )}
    </>
  );
};

export default PermissionForm;
