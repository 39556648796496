import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useLocation, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import AgGrid from "../../../../components/Table/AgGrid";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ModalUI from "../../../../components/UI/ModalUI";
import WorkCenterForm from "../../../../components/Form/Setting/Manufacture";
import CustomizedIconOption from "../../../../components/Custom/CustomizedIconOption";
import {
  deleteWorkCenter,
  getAllWorkCenter,
  getWorkCenterById,
} from "../../../../features/Setting/Manufacture/manufacture-actions";
import { manufactureActions } from "../../../../features/Setting/Manufacture/manufacture-slice";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { filterParamsOptions } from "../../../../utils/filterparams";
import DeleteItemConfirmation from "../../../../components/UI/Confirmation/DeleteItemConfirmation";
import { usePermission } from "../../../../hooks/use-auth";

const selectOptions = ["แก้ไข", "ลบ"];

const WorkCenterSetting = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState({ value: false, type: "create" });
  const [deleteModal, setDeleteModal] = useState(false);
  const { allWorkCenter } = useSelector((state) => state.workCenter);
  const [data, setData] = useState();
  const { createPermission, editPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.manufacture.index"), to: "/setting/manufacture" },
    { name: t("setting.manufacture.workCenter") },
  ];

  const handleOpenDeleteModal = (input) => {
    setData(input);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleClickDelete = () => {
    try {
      dispatch(deleteWorkCenter({ name: data.name }, enqueueSnackbar));
      setDeleteModal(false);
    } catch (error) {
      setDeleteModal(false);
    }
  };

  const columnDefs = [
    {
      field: "plant",
      headerName: t("setting.manufacture.factory"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "name",
      headerName: t("setting.manufacture.workCenter"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "description",
      headerName: t("setting.manufacture.description"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "remark",
      headerName: t("setting.manufacture.remark"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "",
      headerName: "",
      filter: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      cellRenderer: (params) =>
        editPermission && (
          <CustomizedIconOption
            fullWidth
            options={selectOptions}
            onSelect={(e) => {
              switch (e.target.innerText) {
                case "แก้ไข":
                  handleOpenModal(true, params.data.name);
                  break;
                case "ลบ":
                  handleOpenDeleteModal(params.data);

                  break;
                default:
                  break;
              }
            }}
          >
            <EditOutlinedIcon />
          </CustomizedIconOption>
        ),
    },
  ];

  useEffect(() => {
    dispatch(getAllWorkCenter(enqueueSnackbar));
    return () => {
      dispatch(manufactureActions.resetAllWorkCenter());
    };
  }, [dispatch, enqueueSnackbar]);

  const handleOpenModal = async (edit, name) => {
    if (edit) {
      dispatch(getWorkCenterById({ name }));
    }
    setModal({ value: true, type: edit ? "edit" : "create" });
  };

  const handleCloseModal = async () => {
    setTimeout(() => {
      dispatch(manufactureActions.resetWorkCenter());
    }, 200);
    setModal({ value: false, type: "create" });
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container my={2}>
        <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
          <Typography variant="h5" mt={2}>
            {t("setting.manufacture.workCenter")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1}
          xl={1}
          alignSelf="center"
          textAlign={"end"}
        >
          {createPermission && (
            <CustomizedButton
              fullWidth
              title={t("setting.manufacture.addWorkCenter")}
              variant="contained"
              onClick={() => handleOpenModal(false)}
            />
          )}
        </Grid>
      </Grid>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        height={648.5}
        // onGridReady={onGridReady}
        rowData={allWorkCenter}
      />
      <ModalUI
        open={modal.value && modal.type === "create"}
        handleClose={handleCloseModal}
        title={t("setting.manufacture.addWorkCenter")}
        width={720}
      >
        <WorkCenterForm
          open={modal.value}
          handleClose={handleCloseModal}
          type={modal.type}
        />
      </ModalUI>
      <ModalUI
        open={modal.value && modal.type === "edit"}
        handleClose={handleCloseModal}
        title={t("setting.manufacture.editWorkCenter")}
        width={720}
      >
        <WorkCenterForm
          open={modal.value}
          handleClose={handleCloseModal}
          type={modal.type}
        />
      </ModalUI>
      <DeleteItemConfirmation
        title="ยืนยันการศูนย์ผลิตใช่หรือไม่"
        openDeleteItemConfirmation={deleteModal}
        deleteItemConfirmationAction={handleClickDelete}
        closeDeleteItemConfirmationHandler={handleCloseDeleteModal}
      />
    </>
  );
};

export default WorkCenterSetting;
