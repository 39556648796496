import { gql } from "graphql-request";

export const CREATE_ITEM = gql`
  mutation ItemCreate($createInput: ItemCreateInput!) {
    itemCreate(createInput: $createInput) {
      id
      document_id
      name
      attachment_list {
        id
        url
        name
      }
      tag_list {
        id
        name
        entity
      }
      sku
      barcode
      type
      description
      is_purchasable
      is_salable
      is_stockable
      is_manufactuable
      is_active
      width_uom_document_id
      length_uom_document_id
      thickness_uom_document_id
      height_uom_document_id
      volume_uom_document_id
      weight_uom_document_id
      width_uom {
        id
        document_id
        name
      }
      length_uom {
        id
        document_id
        name
      }
      thickness_uom {
        id
        document_id
        name
      }
      height_uom {
        id
        document_id
        name
      }
      volume_uom {
        id
        document_id
        name
      }
      weight_uom {
        id
        document_id
        name
      }
      width
      length
      thickness
      height
      volume
      weight
      package_width_uom {
        id
        document_id
        name
      }
      package_length_uom {
        id
        document_id
        name
      }
      package_height_uom {
        id
        document_id
        name
      }
      package_weight_uom {
        id
        document_id
        name
      }
      package_width
      package_length
      package_height
      package_weight
      package_width_uom_document_id
      package_height_uom_document_id
      package_length_uom_document_id
      package_weight_uom_document_id
      purchase_standard_price
      purchase_minimum_qty
      purchase_vat_type
      sales_standard_price
      sales_maximum_discount
      sales_vat_type
      uom_group_document_id
      base_uom_document_id
      sales_uom_document_id
      purchase_uom_document_id
      deliver_uom_document_id
      uom_group {
        name
        description
        document_id
        id
        base_uom {
          name
          id
        }
        convertable_uom_list {
          document_id
          id
          name
        }
        uom_conversion_list {
          id
          base_uom_rate
          target_uom_document_id
          target_uom {
            document_id
            id
            name
          }
          target_uom_rate
        }
      }
      base_uom {
        id
        document_id
        name
      }
      sales_uom {
        id
        document_id
        name
      }
      purchase_uom {
        id
        document_id
        name
      }
      deliver_uom {
        id
        document_id
        name
      }
      mrp_default_method
      manufacture_type
      manufacture_minimum_qty
      created_date
      created_by {
        first_name
        last_name
        document_id
        id
      }
      current_stock_qty
      current_committed_sales_qty
      current_committed_manufacture_qty
      current_ordered_purchase_qty
      current_ordered_manufacture_qty
      current_available_qty
      remark_status
      remark_staff
      remark_datetime
      attribute_list {
        attribute_name
        attribute_value
        attribute {
          id
          acronym
          data_type
        }
      }
    }
  }
`;

export const CREATE_ITEMS = gql`
  mutation ItemCreateMany($createManyInput: [ItemCreateImporterInput!]!) {
    itemCreateMany(createManyInput: $createManyInput) {
      results {
        document_id
        id
      }
      errors {
        index
        document_id
        errorMessage
        field
      }
    }
  }
`;

export const CREATE_OR_UPDATE_ITEMS = gql`
  mutation ItemCreateOrUpdateMany($dataInput: [ItemCreateImporterInput!]!) {
    itemCreateOrUpdateMany(dataInput: $dataInput) {
      results {
        document_id
        id
      }
      errors {
        index
        document_id
        errorMessage
        field
      }
    }
  }
`;

export const CREATE_ITEM_WITH_UOM = gql`
  mutation ItemCreate($createInput: ItemCreateImporterInput!) {
    ItemCreateWithUOM(createInput: $createInput) {
      id
      document_id
      name
      attachment_list {
        id
        url
        name
      }
      tag_list {
        id
        name
        entity
      }
      sku
      barcode
      type
      description
      is_purchasable
      is_salable
      is_stockable
      is_manufactuable
      is_active
      width_uom_document_id
      length_uom_document_id
      thickness_uom_document_id
      height_uom_document_id
      volume_uom_document_id
      weight_uom_document_id
      width_uom {
        id
        document_id
        name
      }
      length_uom {
        id
        document_id
        name
      }
      thickness_uom {
        id
        document_id
        name
      }
      height_uom {
        id
        document_id
        name
      }
      volume_uom {
        id
        document_id
        name
      }
      weight_uom {
        id
        document_id
        name
      }
      width
      length
      thickness
      height
      volume
      weight
      package_width_uom {
        id
        document_id
        name
      }
      package_length_uom {
        id
        document_id
        name
      }
      package_height_uom {
        id
        document_id
        name
      }
      package_weight_uom {
        id
        document_id
        name
      }
      package_width
      package_length
      package_height
      package_weight
      package_width_uom_document_id
      package_height_uom_document_id
      package_length_uom_document_id
      package_weight_uom_document_id
      purchase_standard_price
      purchase_minimum_qty
      purchase_vat_type
      sales_standard_price
      sales_maximum_discount
      sales_vat_type
      uom_group_document_id
      base_uom_document_id
      sales_uom_document_id
      purchase_uom_document_id
      deliver_uom_document_id
      uom_group {
        id
        document_id
        name
        base_uom {
          id
          document_id
          name
        }
        convertable_uom_list {
          id
          document_id
          name
        }
        uom_conversion_list {
          base_uom_rate
          target_uom {
            name
          }
          uom_group {
            name
          }
          target_uom_rate
        }
      }
      base_uom {
        id
        document_id
        name
      }
      sales_uom {
        id
        document_id
        name
      }
      purchase_uom {
        id
        document_id
        name
      }
      deliver_uom {
        id
        document_id
        name
      }
      mrp_default_method
      created_date
      created_by {
        first_name
        last_name
        document_id
        id
      }
      current_stock_qty
      current_committed_sales_qty
      current_committed_manufacture_qty
      current_ordered_purchase_qty
      current_ordered_manufacture_qty
      current_available_qty
      remark_status
      remark_staff
      remark_datetime
      attribute_list {
        attribute_name
        attribute_value
        attribute {
          id
          acronym
          data_type
        }
      }
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation ItemUpdate(
    $uniqueInput: ItemWhereUniqueInput!
    $updateInput: ItemUpdateInput!
  ) {
    itemUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      document_id
      name
      attachment_list {
        id
        url
        name
      }
      tag_list {
        id
        name
        entity
      }
      sku
      barcode
      type
      description
      is_purchasable
      is_salable
      is_stockable
      is_manufactuable
      is_active
      item_group_sub_level_1 {
        id
        name
        document_id
        sub_level_2_list {
          id
          name
          document_id
          sub_level_3_list {
            id
            name
            document_id
          }
        }
      }
      item_group_sub_level_1_document_id
      item_group_sub_level_2_document_id
      item_group_sub_level_3_document_id
      width_uom_document_id
      length_uom_document_id
      thickness_uom_document_id
      height_uom_document_id
      volume_uom_document_id
      weight_uom_document_id
      width_uom {
        id
        document_id
        name
      }
      length_uom {
        id
        document_id
        name
      }
      thickness_uom {
        id
        document_id
        name
      }
      height_uom {
        id
        document_id
        name
      }
      volume_uom {
        id
        document_id
        name
      }
      weight_uom {
        id
        document_id
        name
      }
      width
      length
      thickness
      height
      volume
      weight
      package_width_uom {
        id
        document_id
        name
      }
      package_length_uom {
        id
        document_id
        name
      }
      package_height_uom {
        id
        document_id
        name
      }
      package_weight_uom {
        id
        document_id
        name
      }
      package_width
      package_length
      package_height
      package_weight
      package_width_uom_document_id
      package_height_uom_document_id
      package_length_uom_document_id
      package_weight_uom_document_id
      purchase_standard_price
      purchase_minimum_qty
      purchase_vat_type
      sales_standard_price
      sales_maximum_discount
      sales_vat_type
      uom_group_document_id
      base_uom_document_id
      sales_uom_document_id
      purchase_uom_document_id
      deliver_uom_document_id
      uom_group {
        name
        description
        document_id
        id
        base_uom {
          name
          id
        }
        convertable_uom_list {
          document_id
          id
          name
        }
        uom_conversion_list {
          id
          base_uom_rate
          target_uom_document_id
          target_uom {
            document_id
            id
            name
          }
          target_uom_rate
        }
      }
      base_uom {
        id
        document_id
        name
      }
      sales_uom {
        id
        document_id
        name
      }
      purchase_uom {
        id
        document_id
        name
      }
      deliver_uom {
        id
        document_id
        name
      }
      mrp_default_method
      manufacture_type
      manufacture_minimum_qty
      created_date
      created_by {
        first_name
        last_name
        document_id
        id
      }
      current_stock_qty
      current_committed_sales_qty
      current_committed_manufacture_qty
      current_ordered_purchase_qty
      current_ordered_manufacture_qty
      current_available_qty
      remark_status
      remark_staff
      remark_datetime
      attribute_list {
        attribute_name
        attribute_value
        attribute {
          id
          name
          acronym
          data_type
        }
      }
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation ItemDelete($documentId: String!) {
    itemDelete(documentId: $documentId) {
      document_id
      name
      sku
      barcode
      type
      is_active
      id
    }
  }
`;

export const CREATE_GOODS_RECEIVE = gql`
  mutation GoodsReceiveCreate($createInput: GoodsReceiveCreateInput!) {
    goodsReceiveCreate(createInput: $createInput) {
      id
      document_id
      document_date
      type
      lot
      reference_document_id
      source_warehouse {
        id
        document_id
        thai_name
        eng_name
      }
      source_warehouse_document_id
      remark
      status
      created_by_document_id
      created_by {
        id
        document_id
        email
        first_name
        last_name
      }
      created_date
      goods_receive_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          destination_warehouse_document_id
          is_active
          posted_quantity
          posted_value
          uom_document_id
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
          }
          uom {
            id
            document_id
            name
          }
          item {
            id
            document_id
            name
            sku
            barcode
            type
            base_uom {
              id
              document_id
              name
            }
          }
          item_document_id
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            destination_bin_location_document_id
            is_active
            is_scanned
            posted_date
            created_date
            posted_quantity
            posted_value
            batch_number
            serial_number
            item_document_id
            uom_document_id
            creator_document_id
            stock_entry_document_id
            previous_quantity
            item_uid
            item {
              id
              document_id
              name
              sku
              base_uom {
                id
                document_id
                name
              }
            }
            created_by {
              id
              document_id
              first_name
              last_name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_GOODS_RECEIVE_WITH_APPROVE = gql`
  mutation GoodsReceiveCreateWithApprove(
    $createInput: GoodsReceiveCreateInput!
  ) {
    goodsReceiveCreateWithApprove(createInput: $createInput) {
      id
      document_id
      document_date
      type
      lot
      reference_document_id
      source_warehouse {
        id
        document_id
        thai_name
        eng_name
      }
      source_warehouse_document_id
      remark
      status
      created_by_document_id
      created_by {
        id
        document_id
        email
        first_name
        last_name
      }
      created_date
      goods_receive_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          destination_warehouse_document_id
          is_active
          posted_quantity
          posted_value
          uom_document_id
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
          }
          uom {
            id
            document_id
            name
          }
          item {
            id
            document_id
            name
            sku
            barcode
            type
            base_uom {
              id
              document_id
              name
            }
          }
          item_document_id
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            destination_bin_location_document_id
            is_active
            is_scanned
            posted_date
            created_date
            posted_quantity
            posted_value
            batch_number
            serial_number
            item_document_id
            uom_document_id
            creator_document_id
            stock_entry_document_id
            previous_quantity
            item_uid
            item {
              id
              document_id
              name
              sku
              base_uom {
                id
                document_id
                name
              }
            }
            created_by {
              id
              document_id
              first_name
              last_name
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_GOODS_RECEIVE = gql`
  mutation GoodsReceiveUpdate(
    $uniqueInput: GoodsReceiveUniqueInput!
    $updateInput: GoodsReceiveUpdateInput!
  ) {
    goodsReceiveUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      document_id
      document_date
      type
      lot
      reference_document_id
      source_warehouse {
        id
        document_id
        thai_name
        eng_name
      }
      source_warehouse_document_id
      remark
      status
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
      }
      created_date
      goods_receive_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          destination_warehouse_document_id
          is_active
          posted_quantity
          posted_value
          uom_document_id
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
          }
          uom {
            id
            document_id
            name
          }
          item {
            id
            document_id
            name
            sku
            barcode
            type
          }
          item_document_id
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            destination_bin_location_document_id
            is_active
            is_scanned
            posted_date
            created_date
            scanned_date
            posted_quantity
            posted_value
            previous_quantity
            batch_number
            serial_number
            item_document_id
            uom_document_id
            creator_document_id
            stock_entry_document_id
            item_uid
            item {
              id
              document_id
              name
              sku
            }
            created_by {
              id
              document_id
              first_name
              last_name
            }
            scanned_by {
              id
              document_id
              first_name
              last_name
            }
          }
        }
      }
    }
  }
`;

export const APPROVE_GOODS_RECEIVE = gql`
  mutation GoodsReceiveApprove($uniqueInput: GoodsReceiveUniqueInput!) {
    goodsReceiveApprove(uniqueInput: $uniqueInput)
  }
`;

export const CANCEL_GOODS_RECEIVE = gql`
  mutation Mutation($uniqueInput: GoodsReceiveUniqueInput!) {
    goodsReceiveCancel(uniqueInput: $uniqueInput)
  }
`;

export const CREATE_GOODS_ISSUE = gql`
  mutation GoodsIssueCreate($createInput: GoodsIssueCreateInput!) {
    goodsIssueCreate(createInput: $createInput) {
      id
      document_id
      source_warehouse_document_id
      source_warehouse {
        id
        document_id
        type
        thai_name
      }
      source_warehouse_thai_name
      source_warehouse_eng_name
      document_date
      type
      reference_document_id
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
        img_url
      }
      remark
      status
      goods_issue_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          source_warehouse {
            id
            document_id
            type
            thai_name
          }
          destination_warehouse_document_id
          destination_warehouse {
            id
            document_id
            type
            thai_name
          }
          is_active
          posted_quantity
          posted_value
          uom {
            id
            document_id
            name
          }
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
            img_url
          }
          item {
            id
            document_id
            name
            sku
            barcode
            type
          }
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            source_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            destination_bin_location_document_id
            destination_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            item_uid
            is_active
            is_scanned
            posted_date
            created_date
            posted_quantity
            posted_value
            tracability
            batch_number
            serial_number
            item {
              id
              document_id
              name
              sku
              barcode
              type
            }
            uom {
              id
              document_id
              name
            }
            creator_document_id
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            stock_entry_document_id
          }
        }
      }
    }
  }
`;

export const CREATE_GOODS_ISSUE_WITH_APPROVE = gql`
  mutation GoodsIssueCreateWithApprove($createInput: GoodsIssueCreateInput!) {
    goodsIssueCreateWithApprove(createInput: $createInput) {
      id
      document_id
      source_warehouse_document_id
      source_warehouse {
        id
        document_id
        type
        thai_name
      }
      source_warehouse_thai_name
      source_warehouse_eng_name
      document_date
      type
      reference_document_id
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
        img_url
      }
      remark
      status
      goods_issue_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          source_warehouse {
            id
            document_id
            type
            thai_name
          }
          destination_warehouse_document_id
          destination_warehouse {
            id
            document_id
            type
            thai_name
          }
          is_active
          posted_quantity
          posted_value
          uom {
            id
            document_id
            name
          }
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
            img_url
          }
          item {
            id
            document_id
            name
            sku
            barcode
            type
          }
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            source_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            destination_bin_location_document_id
            destination_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            is_active
            is_scanned
            posted_date
            created_date
            posted_quantity
            posted_value
            tracability
            batch_number
            serial_number
            item_uid
            item {
              id
              document_id
              name
              sku
              barcode
              type
            }
            uom {
              id
              document_id
              name
            }
            creator_document_id
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            stock_entry_document_id
          }
        }
      }
    }
  }
`;

export const UPDATE_GOODS_ISSUE = gql`
  mutation GoodsIssueUpdate(
    $uniqueInput: GoodsIssueUniqueInput!
    $updateInput: GoodsIssueUpdateInput!
  ) {
    goodsIssueUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      document_id
      source_warehouse_document_id
      source_warehouse {
        id
        document_id
        type
        thai_name
      }
      source_warehouse_thai_name
      source_warehouse_eng_name
      document_date
      type
      reference_document_id
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
        img_url
      }
      remark
      status
      goods_issue_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          source_warehouse {
            id
            document_id
            type
            thai_name
          }
          destination_warehouse_document_id
          destination_warehouse {
            id
            document_id
            type
            thai_name
          }
          is_active
          posted_quantity
          posted_value
          uom {
            id
            document_id
            name
          }
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
            img_url
          }
          item {
            id
            document_id
            name
            sku
            barcode
            type
          }
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            source_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            destination_bin_location_document_id
            destination_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            is_active
            is_scanned
            posted_date
            created_date
            posted_quantity
            posted_value
            tracability
            batch_number
            serial_number
            item_uid
            item {
              id
              document_id
              name
              sku
              barcode
              type
            }
            uom {
              id
              document_id
              name
            }
            creator_document_id
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            stock_entry_document_id
          }
        }
      }
    }
  }
`;

export const CANCEL_GOODS_ISSUE = gql`
  mutation GoodsIssueCancel($uniqueInput: GoodsIssueUniqueInput!) {
    goodsIssueCancel(uniqueInput: $uniqueInput)
  }
`;

export const CREATE_GOODS_TRANSFER_WITH_APPROVE = gql`
  mutation GoodsTransferCreateWithApprove(
    $createInput: GoodsTransferCreateInput!
  ) {
    goodsTransferCreateWithApprove(createInput: $createInput) {
      id
      document_id
      document_date
      source_warehouse_document_id
      source_warehouse {
        id
        document_id
        type
        thai_name
        eng_name
      }
      destination_warehouse_document_id
      destination_warehouse {
        id
        document_id
        type
        thai_name
        eng_name
      }
      created_date
      created_by {
        id
        document_id
        last_name
        first_name
      }
      created_by_document_id
      remark
      status
      goods_transfer_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          source_warehouse {
            id
            document_id
            type
            thai_name
            eng_name
          }
          destination_warehouse_document_id
          destination_warehouse {
            id
            document_id
            type
            thai_name
            eng_name
          }
          is_active
          posted_quantity
          posted_value
          uom {
            id
            document_id
            name
          }
          uom_document_id
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
            img_url
          }
          item_document_id
          item {
            id
            document_id
            name
            sku
            barcode
          }
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location {
              id
              name
              warehouse_document_id
              document_id
              warehouse {
                id
                document_id
                type
                thai_name
                eng_name
              }
            }
            destination_bin_location_document_id
            destination_bin_location {
              id
              document_id
              name
              warehouse_document_id
              warehouse {
                id
                document_id
                type
                eng_name
                thai_name
              }
            }
            is_active
            is_scanned
            posted_date
            created_date
            posted_quantity
            posted_value
            tracability
            batch_number
            serial_number
            scanned_date
            scanned_by_document_id
            scanned_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            item_document_id
            item {
              id
              document_id
              name
              sku
              barcode
            }
            uom {
              id
              document_id
              name
            }
            uom_document_id
            creator_document_id
            created_by {
              id
              document_id
              first_name
              email
              last_name
            }
            stock_entry_document_id
          }
        }
      }
    }
  }
`;

export const CREATE_GOODS_ADJUSTMENT_WITH_APPROVE = gql`
  mutation Mutation($createInput: GoodsAdjustmentCreateInput!) {
    goodsAdjustmentCreateWithApprove(createInput: $createInput) {
      created_by_document_id
      created_date
      document_date
      document_id
      created_by {
        id
        first_name
        email
        img_url
        last_name
      }
      goods_adjustment_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          created_by {
            email
            first_name
            id
            last_name
            img_url
          }
          created_date
          destination_warehouse_document_id
          destination_warehouse {
            id
            thai_name
            document_id
            eng_name
            is_active
            created_date
            description
          }
          document_id
          entry_type
          id
          is_active
          item {
            id
            document_id
            name
            sku
            barcode
            type
          }
          posted_quantity
          posted_value
          uom {
            id
            document_id
            name
          }
          posted_date
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            source_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            destination_bin_location_document_id
            destination_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            is_active
            is_scanned
            posted_date
            created_date
            posted_quantity
            posted_value
            tracability
            batch_number
            serial_number
            scanned_date
            scanned_by_document_id
            scanned_by {
              id
              document_id
              first_name
              last_name
              email
              img_url
            }
            item_document_id
            item {
              id
              document_id
              name
              sku
              barcode
              type
              is_active
            }
            uom {
              id
              document_id
              name
            }
            uom_document_id
            creator_document_id
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
              email
            }
            stock_entry_document_id
          }
        }
      }
      id
      remark
      remark_datetime
      remark_staff
      source_warehouse {
        created_date
        description
        document_id
        eng_name
        id
        is_active
        thai_name
        type
      }
      source_warehouse_document_id
      source_warehouse_eng_name
      source_warehouse_thai_name
      status
    }
  }
`;

export const CREATE_SERIAL_NUMBER = gql`
  mutation Mutation($createInput: SerialCreateInput!) {
    serialCreate(createInput: $createInput) {
      serial_number
    }
  }
`;

export const CREATE_MANY_SERIAL_NUMBER = gql`
  mutation SerialCreateMany($createManyInput: [SerialCreateInput!]!) {
    serialCreateMany(createManyInput: $createManyInput)
  }
`;

export const CREATE_BATCH_NUMBER = gql`
  mutation BatchCreate($createInput: BatchCreateInput!) {
    batchCreate(createInput: $createInput) {
      batch_number
      item_document_id
      expiration_date
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
      }
      item {
        id
        document_id
        name
      }
    }
  }
`;

export const CREATE_MANY_BATCH_NUMBER = gql`
  mutation BatchCreateMany($createManyInput: [BatchCreateInput!]!) {
    batchCreateMany(createManyInput: $createManyInput)
  }
`;

export const UPDATE_ITEM_QUANTITY = gql`
  mutation ItemUpdateQuantity(
    $uniqueInput: ItemWhereUniqueInput!
    $updateInput: ItemUpdateQuantityInput!
  ) {
    itemUpdateQuantity(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      document_id
      current_available_qty
      current_committed_manufacture_qty
      current_committed_sales_qty
      current_ordered_manufacture_qty
      current_ordered_purchase_qty
      current_stock_qty
    }
  }
`;
