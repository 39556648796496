import {
  graphQLClientWithHeader,
  graphQLClientReportWithHeader,
} from "../graphQL";
import {
  DOCUMENT_APPROVE,
  DOCUMENT_DECLINE,
  DOCUMENT_NEXT_STATUS,
  DOCUMENT_CANCEL,
  CREATE_PURCHASE_REQUEST,
  UPDATE_PURCHASE_REQUEST,
  CREATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  CREATE_PURCHASE_RETURN,
  UPDATE_PURCHASE_RETURN,
} from "./mutation";
import {
  GET_APPROVAL_PROGRESS,
  GET_APPROVAL_TEMPLATES,
  GET_PURCHASE_REQUEST_ID,
  GET_ALL_PURCHASE_REQUEST,
  GET_PURCHASE_REQUEST,
  GET_PURCHASE_ORDER_ID,
  GET_ALL_PURCHASE_ORDER,
  GET_PURCHASE_ORDER,
  GET_PURCHASE_RETURN_ID,
  GET_ALL_PURCHASE_RETURN,
  GET_ALL_PURCHASE_RETURN_IDS,
  GET_PURCHASE_RETURN,
  GET_ALL_PURCHASE_ORDER_WITHOUT_AGGRID,
  GET_ALL_PURCHASE_ORDER_ITEM_LIST,
  GET_ALL_PURCHASE_RETURN_WITHOUT_AGGRID,
  GET_ALL_PURCHASE_REQUEST_SELF,
  GET_PURCHASE_ORDER_PRICE_LIST,
} from "./queries";

class PurchaseSurvice {
  //next status
  async postNextStatus(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentNextStatus } = await graphQLClient.request(
      DOCUMENT_NEXT_STATUS,
      {
        documentInput,
      }
    );
    return documentNextStatus;
  }

  //approve
  async postApproveStatus(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentApprove } = await graphQLClient.request(DOCUMENT_APPROVE, {
      documentInput,
    });
    return documentApprove;
  }

  //decline
  async postDeclineStatus(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentDecline } = await graphQLClient.request(DOCUMENT_DECLINE, {
      documentInput,
    });
    return documentDecline;
  }

  //cancel
  async cancelDocument(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentCancel } = await graphQLClient.request(DOCUMENT_CANCEL, {
      documentInput,
    });
    return documentCancel;
  }

  async getApprovalTemplatesByDocumentType(documentType) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalTemplatesByDocumentType } = await graphQLClient.request(
      GET_APPROVAL_TEMPLATES,
      { documentType }
    );
    return approvalTemplatesByDocumentType;
  }

  async getApprovalProgress(referenceDocumentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalProgressByDocument } = await graphQLClient.request(
      GET_APPROVAL_PROGRESS,
      {
        referenceDocumentInput,
      }
    );
    return approvalProgressByDocument;
  }

  //purchaseRequest
  async getPurchaseRequestId(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseRequest } = await graphQLClient.request(
      GET_PURCHASE_REQUEST_ID,
      uniqueInput
    );
    return purchaseRequest;
  }

  async getAllPurchaseRequestSelf(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseRequestsViewSelfAggrid } = await graphQLClient.request(
      GET_ALL_PURCHASE_REQUEST_SELF,
      {
        aggridInput,
      }
    );
    return purchaseRequestsViewSelfAggrid;
  }

  async getAllPurchaseRequest(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseRequestsAggrid } = await graphQLClient.request(
      GET_ALL_PURCHASE_REQUEST,
      {
        aggridInput,
      }
    );
    return purchaseRequestsAggrid;
  }

  async getAllPurchaseRequestReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { purchaseRequestsAggrid } = await graphQLClient.request(
      GET_ALL_PURCHASE_REQUEST,
      {
        aggridInput,
      }
    );
    return purchaseRequestsAggrid;
  }

  async getPurchaseRequestById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseRequest } = await graphQLClient.request(
      GET_PURCHASE_REQUEST,
      {
        uniqueInput,
      }
    );
    return purchaseRequest;
  }

  async createPurchaseRequest(createPurchaseRequestInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createPurchaseRequest } = await graphQLClient.request(
      CREATE_PURCHASE_REQUEST,
      {
        createPurchaseRequestInput,
      }
    );
    return createPurchaseRequest;
  }

  async updatePurchaseRequest(uniqueInput, updatePurchaseRequestInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updatePurchaseRequest } = await graphQLClient.request(
      UPDATE_PURCHASE_REQUEST,
      {
        uniqueInput,
        updatePurchaseRequestInput,
      }
    );
    return updatePurchaseRequest;
  }

  //purchaseOrder
  async getPurchaseOrderId(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseOrder } = await graphQLClient.request(
      GET_PURCHASE_ORDER_ID,
      {
        uniqueInput,
      }
    );
    return purchaseOrder;
  }

  async getAllPurchaseOrder(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseOrdersAggrid } = await graphQLClient.request(
      GET_ALL_PURCHASE_ORDER,
      {
        aggridInput,
      }
    );
    return purchaseOrdersAggrid;
  }

  async getAllPurchaseOrderReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { purchaseOrdersAggrid } = await graphQLClient.request(
      GET_ALL_PURCHASE_ORDER,
      {
        aggridInput,
      }
    );
    return purchaseOrdersAggrid;
  }

  async getAllPurchaseOrderItemList(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseOrdersAggrid } = await graphQLClient.request(
      GET_ALL_PURCHASE_ORDER_ITEM_LIST,
      {
        aggridInput,
      }
    );
    return purchaseOrdersAggrid;
  }

  async getPurchaseOrderPriceList(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseOrdersAggrid } = await graphQLClient.request(
      GET_PURCHASE_ORDER_PRICE_LIST,
      {
        aggridInput,
      }
    );
    return purchaseOrdersAggrid;
  }

  async getAllPurchaseOrderWithoutAggrid() {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseOrders } = await graphQLClient.request(
      GET_ALL_PURCHASE_ORDER_WITHOUT_AGGRID
    );
    return purchaseOrders;
  }

  async getPurchaseOrderById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseOrder } = await graphQLClient.request(GET_PURCHASE_ORDER, {
      uniqueInput,
    });
    return purchaseOrder;
  }

  async createPurchaseOrder(createPurchaseOrderInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createPurchaseOrder } = await graphQLClient.request(
      CREATE_PURCHASE_ORDER,
      {
        createPurchaseOrderInput,
      }
    );
    return createPurchaseOrder;
  }

  async updatePurchaseOrder(uniqueInput, updatePurchaseOrderInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updatePurchaseOrder } = await graphQLClient.request(
      UPDATE_PURCHASE_ORDER,
      {
        uniqueInput,
        updatePurchaseOrderInput,
      }
    );
    return updatePurchaseOrder;
  }

  //purchase Return
  async getPurchaseReturnId(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseReturn } = await graphQLClient.request(
      GET_PURCHASE_RETURN_ID,
      {
        uniqueInput,
      }
    );
    return purchaseReturn;
  }

  async getAllPurchaseReturnIds(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseReturnsAggrid } = await graphQLClient.request(
      GET_ALL_PURCHASE_RETURN_IDS,
      {
        aggridInput,
      }
    );
    return purchaseReturnsAggrid;
  }

  async getAllPurchaseReturns(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseReturnsAggrid } = await graphQLClient.request(
      GET_ALL_PURCHASE_RETURN,
      {
        aggridInput,
      }
    );
    return purchaseReturnsAggrid;
  }

  async getAllPurchaseReturnsReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { purchaseReturnsAggrid } = await graphQLClient.request(
      GET_ALL_PURCHASE_RETURN,
      {
        aggridInput,
      }
    );
    return purchaseReturnsAggrid;
  }

  async getPurchaseReturnById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseReturn } = await graphQLClient.request(
      GET_PURCHASE_RETURN,
      {
        uniqueInput,
      }
    );
    return purchaseReturn;
  }

  async getAllPurchaseReturnWithoutAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { purchaseReturns } = await graphQLClient.request(
      GET_ALL_PURCHASE_RETURN_WITHOUT_AGGRID
    );
    return purchaseReturns;
  }

  async createPurchaseReturn(createPurchaseReturnInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createPurchaseReturn } = await graphQLClient.request(
      CREATE_PURCHASE_RETURN,
      {
        createPurchaseReturnInput,
      }
    );
    return createPurchaseReturn;
  }

  async updatePurchaseReturn(uniqueInput, updatePurchaseReturnInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updatePurchaseReturn } = await graphQLClient.request(
      UPDATE_PURCHASE_RETURN,
      {
        uniqueInput,
        updatePurchaseReturnInput,
      }
    );
    return updatePurchaseReturn;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PurchaseSurvice();
