import { useSnackbar } from "notistack";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import AgGrid from "../../../components/Table/AgGrid";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
// import { exportAsExcel } from "../../../utils/exporter";
import InventoryService from "../../../services/Inventory";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
// import { formatValueItemCurrentStockByStockEntry } from "./formatValue";
import {
  exportCSVParams,
  filterParamsOptions,
} from "../../../utils/filterparams";
import TotalBox from "../../../components/UI/TotalBox";
// import { unixToDateWithFormat } from "../../../utils/date-converter";
import moment from "moment";
import { formatDateExport } from "../../../utils/date-converter";
import { formatNumber } from "../../../utils/dataTransformer";

import SettingService from "../../../services/Setting";
import { useDispatch } from "react-redux";
import { inventoryReportActions } from "../../../features/Inventory/Report/report-slice";

const ItemCurrentStock = () => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [newData, setNewData] = useState([]);
  const [sum, setSum] = useState(0);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const dispatch = useDispatch();

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.itemCurrentStock.index"),
    },
  ];

  const getAllItemGroupLevel1 = useCallback(async () => {
    const data = await SettingService.getAllCategory();
    return data;
  }, []);

  let columnDefs = useMemo(
    () => [
      {
        field: "item_document_id",
        headerName: t("inventory.items.itemId"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_name",
        headerName: t("inventory.items.itemName"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_description",
        headerName: t("inventory.items.itemDescription"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "item_group_sub_level_1_name",
        headerName: t("inventory.items.itemGroup") + " " + 1,
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params) => {
            // fetch values from server
            const values = await getAllItemGroupLevel1();
            const formatValues = values.map((group) => group.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "sum",
        headerName: "ทั้งหมด",
        filter: false,
        valueFormatter: (params) => formatNumber(params.value),
      },
      {
        field: "uom_name",
        headerName: t("inventory.unit"),
        sortable: false,
        filter: false,
      },
    ],
    [getAllItemGroupLevel1, t]
  );

  const exportHandler = async () => {
    setIsLoadingExport(true);
    try {
      const params = exportCSVParams(
        t("inventory.itemCurrentStock.index") +
          "_" +
          formatDateExport(new Date(moment().endOf("day")))
      );
      gridRef.current.api.exportDataAsCsv(params);
      enqueueSnackbar("นำออกรายงานสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("นำออกรายงานไม่สำเร็จ", {
        variant: "error",
      });
    } finally {
      setIsLoadingExport(false);
    }
  };

  // const datasource = {
  //   getRows(params) {
  //     const request = params.request;
  //     const sortbyId = [
  //       ...request.sortModel,
  //       { colId: "item_document_id", sort: "asc" },
  //       { colId: "warehouse_thai_name", sort: "asc" },
  //     ];
  //     dispatch(
  //       getItemsCurrentStock(
  //         {
  //           startRow: request.startRow,
  //           endRow: request.endRow,
  //           filterModel: request.filterModel,
  //           sortModel: sortbyId,
  //         },
  //         params,
  //         enqueueSnackbar
  //       )
  //     );
  //   },
  // };

  // const onGridReady = (params) => {
  //   params.api.setServerSideDatasource(datasource);
  // };

  const changePayloadData = useCallback(
    (allData) => {
      let itemData = [];
      let checkArray = [];
      let allWarehouse = [];
      const allQuantity = allData.reduce(
        (prev, curr) => prev + curr.current_quantity,
        0
      );
      allData.forEach((item) => {
        if (!checkArray.includes(item.item_document_id)) {
          checkArray.push(item.item_document_id);
          itemData.push({
            item_document_id: item.item_document_id,
            item_description: item.item_description,
            item_name: item.item_name,
            item_group_sub_level_1_name: item.item_group_sub_level_1_name,
            uom_name: item.base_uom_name,
            sum: 0,
          });
        }
        let findItem = itemData.find(
          (x) => x.item_document_id === item.item_document_id
        );
        let myValue = item["current_quantity"];
        if (typeof myValue === "number") myValue = parseFloat(myValue);
        findItem[item["warehouse_thai_name"]] = myValue;
        if (!isNaN(myValue)) findItem.sum += myValue;
        if (!allWarehouse.includes(item["warehouse_thai_name"]))
          allWarehouse.push(item["warehouse_thai_name"]);
      });
      allWarehouse.forEach((warehouse) =>
        columnDefs.splice(4, 0, {
          field: warehouse,
          headerName: warehouse,
          filter: false,
          valueFormatter: (params) => {
            if (params.value) return formatNumber(params.value);
            else return "";
          },
        })
      );
      setNewData(itemData);
      setSum(allQuantity);
    },
    [columnDefs]
  );

  const onFilterChanged = () => {
    let newValue = 0;
    gridRef.current.api.forEachNodeAfterFilter(
      (data) => (newValue += data.data.sum)
    );
    setSum(newValue);
  };

  const getData = useCallback(async () => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStock"));
    const { results } = await InventoryService.getAllReportICS({
      startRow: 0,
      endRow: 9999999,
    });
    changePayloadData(results);
    dispatch(inventoryReportActions.onLoaded("itemCurrentStock"));
  }, [changePayloadData, dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={4} md={3}>
          <TotalBox title="ยอดรวม" total={formatNumber(sum)} />
        </Grid>
      </Grid>
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          {t("inventory.itemCurrentStock.index")}
        </Typography>
        <CustomizedButton
          sx={{ mr: 2 }}
          title={t("inventory.exportReport")}
          variant="contained"
          onClick={exportHandler}
          disabled={isLoadingExport}
        />
      </Box>
      <Box mb={2} mr={2} alignSelf="flex-end">
        <CustomizedButton
          title={t("button.resetFilter")}
          variant="outlined"
          onClick={onFilterReset}
        />
      </Box>
      {columnDefs.length > 6 ? (
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          // onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          rowData={newData}
          height={649}
        />
      ) : null}
    </>
  );
};

export default ItemCurrentStock;
