export const initialState = {
  isLoading: {
    allUomGroup: false,
    uomGroup: false,
    allUoms: false,
  },
  allUomGroup: [],
  uomIndex: "",
  uomGroup: {
    name: "",
    base_uom_document_id: "",
    description: "",
  },
  allUoms: [],
  uom: {
    name: "",
    document_id: "",
  },
  modal: {
    allUomGroup: false,
    uomGroup: false,
    uom: false,
  },
  error: null,
};
