import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./sales-return-initial";

const salesReturnSlice = createSlice({
  name: "salesReturn",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllSalesReturns(state, action) {
      state.allSalesReturns = action.payload;
      state.isLoading.allSalesReturns = false;
    },
    loadedAllSalesReturnsExport(state, action) {
      state.allSalesReturnsExport = action.payload;
      state.isLoading.allSalesReturns = false;
    },
    loadedApprovalList(state, action) {
      const { approvalTemplates, approvalProgress } = action.payload;
      const sortedApprovalTemplates = approvalTemplates.sort(
        (a, b) => a.step_number - b.step_number
      );

      const sortedApprovalProgress =
        approvalProgress &&
        approvalProgress.length > 0 &&
        approvalProgress.sort((a, b) => a.created_date - b.created_date);

      let formatApprovalList = [];
      sortedApprovalTemplates.forEach((approvalTemplate, index) => {
        if (approvalTemplate.is_required_approve) {
          const formatApprovalWithStatus = {
            id: approvalTemplate.id,
            step_number: approvalTemplate.step_number,
            reviewer_list: approvalTemplate.reviewer_list,
            approval_status:
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                sortedApprovalProgress[index].approval_status) ||
              null,
            created_date:
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                sortedApprovalProgress[index].created_date) ||
              null,
            approved_by:
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                approvalTemplate.reviewer_list.find(
                  (approve) =>
                    approve.document_id ===
                    sortedApprovalProgress[index].approved_by_document_id
                )) ||
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                sortedApprovalProgress[index].approved_by) ||
              null,
          };
          formatApprovalList.push(formatApprovalWithStatus);
        }
      });

      state.approvalList = formatApprovalList;
    },
    loadedSalesReturn(state, action) {
      state.salesReturn = action.payload;
      state.isLoading.salesReturn = false;
    },
    loadedSalesOrderItem(state, action) {
      state.salesOrderItemList = action.payload;
    },
    loadedCreditNoteTab(state, action) {
      state.creditNoteTab = action.payload;
      state.isLoading.salesReturn = false;
    },
    loadedGoodReceiveTab(state, action) {
      state.goodReceiveTab = action.payload;
      state.isLoading.salesReturn = false;
    },
    updateSalesReturnStatus(state, action) {
      state.salesReturn.render_status = action.payload;
      state.isLoading.salesReturn = false;
    },
    updateApprovalStatus(state, action) {
      const { approval_list, user } = action.payload;

      const sortedApprovalProgress = approval_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      const findApprover =
        sortedApprovalProgress &&
        sortedApprovalProgress.length > 0 &&
        sortedApprovalProgress?.map((approval) => ({
          approval_status: approval?.approval_status,
          reviewer_document_id_list:
            approval?.approval_template &&
            approval?.approval_template?.reviewer_document_id_list,
          created_date: approval?.created_date,
        }));

      const isHaveApproval = sortedApprovalProgress?.every(
        (approval) =>
          approval?.approval_template &&
          approval?.approval_template?.is_required_approve === true
      );

      const formatApprovalList =
        findApprover &&
        findApprover.filter((approval) => approval.reviewer_list !== null);

      const foundWaitApprove =
        formatApprovalList &&
        formatApprovalList.find(
          (approval) => approval.approval_status === "PENDING"
        );

      const isHaveApprovalPermission =
        foundWaitApprove &&
        foundWaitApprove?.reviewer_document_id_list?.includes(user.document_id);

      state.isHaveApprovalPermission = isHaveApprovalPermission;
      state.isHaveApproval = isHaveApproval;

      if (formatApprovalList && formatApprovalList.length > 0) {
        formatApprovalList.forEach((approval, index) => {
          if (state.approvalList[index]) {
            state.approvalList[index].approval_status =
              approval.approval_status;
          }
        });
      }
    },
    resetAllSalesReturns(state) {
      state.allSalesReturns = initialState.allSalesReturns;
      state.error = initialState.error;
    },
    resetAllSalesReturnsExport(state) {
      state.allSalesReturnsExport = initialState.allSalesReturnsExport;
      state.error = initialState.error;
    },
    resetGoodReceiveTab(state) {
      state.goodReceiveTab = initialState.goodReceiveTab;
      state.error = initialState.error;
    },
    resetSalesReturn(state) {
      state.salesReturn = initialState.salesReturn;
      state.creditNoteTab = initialState.creditNoteTab;
      state.goodReceiveTab = initialState.goodReceiveTab;
      state.error = initialState.error;
    },
    resetSalesReturnError(state) {
      state.error = initialState.error;
    },
  },
});

export const salesReturnActions = salesReturnSlice.actions;

export default salesReturnSlice.reducer;
