import React, { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@mui/material";
import { uomActions } from "../../../features/Setting/Uom/uom-slice";
import {
  getAllUom,
  getUomGroupById,
} from "../../../features/Setting/Uom/uom-actions";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ModalUI from "../../../components/UI/ModalUI";
import ConversionForm from "../../../components/Form/Conversion";
import AG_GRID_LOCALE_TH from "../../../locale.th";

const Conversion = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const { modal, uomGroup } = useSelector((state) => state.uom);

  useEffect(() => {
    dispatch(getAllUom());
    dispatch(getUomGroupById({ uniqueInput: { id: parseInt(id) } }));
    return () => {
      dispatch(uomActions.resetUomGroup());
    };
  }, [dispatch, id]);

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.warehouseSetting"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.uom.group"),
      to: "/setting/inventory/uomgroup",
    },
    {
      name: uomGroup.name,
    },
  ];

  const columnDefs = [
    {
      field: "target_uom_rate",
      headerName: t("setting.inventory.conversion.quantity"),
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      field: "target_uom",
      headerName: t("setting.inventory.uom.index"),
      cellRenderer: (params) => {
        return (
          <Box alignItems={"center"}>{params.data.target_uom.name || ""}</Box>
        );
      },
    },
    {
      field: "base_uom_rate",
      headerName: t("setting.inventory.conversion.quantity"),
      filter: "agTextColumnFilter",
    },
    {
      field: "base_uom",
      headerName: t("setting.inventory.uom.baseUom"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return <Box alignItems={"center"}>{uomGroup.base_uom.name || ""}</Box>;
      },
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      floatingFilter: true,
      filter: true,
      resizable: true,
      sortable: true,
    };
  }, []);

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_TH;
  }, []);

  const CustomizedNoRowsOverlay = ({ noRowsMessageFunc }) => (
    <Typography>{noRowsMessageFunc()}</Typography>
  );

  const noRowsOverlayComponent = useMemo(() => {
    return CustomizedNoRowsOverlay;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => "ไม่มีข้อมูล",
    };
  }, []);

  const handleOpen = () => {
    dispatch(uomActions.openModal("uomGroup"));
  };

  const handleClose = () => {
    dispatch(uomActions.closeModal("uomGroup"));
  };

  const baseConversion = [
    {
      base_uom_rate: 1,
      base_uom: uomGroup.base_uom,
      target_uom: uomGroup.base_uom,
      target_uom_rate: 1,
    },
  ];

  const newData = () => {
    if (uomGroup && uomGroup.uom_conversion_list?.length > 0) {
      const newValue = [...baseConversion, ...uomGroup.uom_conversion_list];
      return newValue;
    } else if (uomGroup && uomGroup.base_uom) return baseConversion;
    else return [];
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container my={2}>
        <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
          <Typography variant="h5">
            {t("setting.inventory.conversion.index")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1}
          xl={1}
          alignSelf="center"
          textAlign={"end"}
        >
          <CustomizedButton
            fullWidth
            title={t("setting.edit")}
            variant="contained"
            onClick={handleOpen}
          />
        </Grid>
      </Grid>
      <ModalUI
        open={modal.uomGroup}
        handleClose={handleClose}
        title={t("setting.inventory.conversion.edit")}
      >
        <ConversionForm handleClose={handleClose} />
      </ModalUI>
      <Box sx={{ my: 2 }}>
        <div
          id="myGrid"
          className="ag-theme-material"
          style={{ height: 450, width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={newData()}
            columnDefs={columnDefs}
            localeText={localeText}
            defaultColDef={defaultColDef}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            enableRangeSelection={false}
          />
        </div>
      </Box>
    </>
  );
};

export default Conversion;
