import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./delivery-order-initial";

const deliveryOrderSlice = createSlice({
  name: "deliveryOrder",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    onLoaded(state, action) {
      state.isLoading[action.payload] = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllDeliveryOrders(state, action) {
      state.allDeliveryOrders = action.payload;
      state.isLoading.allDeliveryOrders = false;
    },
    loadedAllDeliveryOrdersModal(state, action) {
      state.allDeliveryOrders = action.payload;
      state.isLoading.allDeliveryOrdersModal = false;
    },
    loadedAllDeliveryOrdersExport(state, action) {
      state.allDeliveryOrdersExport = action.payload;
      state.isLoading.allDeliveryOrders = false;
    },
    loadedDeliveryOrder(state, action) {
      state.deliveryOrder = action.payload;
      state.isLoading.deliveryOrder = false;
    },
    loadedSalesOrderItem(state, action) {
      state.salesOrderItemList = action.payload;
    },
    updateDeliveryOrderStatus(state, action) {
      state.deliveryOrder.render_status = action.payload;
      state.isLoading.deliveryOrder = false;
    },
    resetAllDeliveryOrders(state) {
      state.allDeliveryOrders = initialState.allDeliveryOrders;
      state.error = null;
    },
    resetAllDeliveryOrdersExport(state) {
      state.allDeliveryOrdersExport = initialState.allDeliveryOrdersExport;
      state.error = null;
    },
    resetDeliveryOrder(state) {
      state.deliveryOrder = initialState.deliveryOrder;
      state.error = null;
    },
    loadedDeliveryTripTab(state, action) {
      state.deliveryTripTab = action.payload;
      state.isLoading.deliveryOrder = false;
    },
    resetDeliveryTripTab(state) {
      state.deliveryTripTab = initialState.deliveryTripTab;
    },
  },
});

export const deliveryOrderActions = deliveryOrderSlice.actions;

export default deliveryOrderSlice.reducer;
