import { useState, useRef } from "react";
import { Grid, Box, Typography, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../../../utils/dataTransformer";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ImporterDropzoneUI from "../../../components/UI/ImporterDropzoneUI";
import UserService from "../../../services/User";
import SalesService from "../../../services/Sales";
import ContactService from "../../../services/Contact";
import InventoryService from "../../../services/Inventory";
import ModalUI from "../../../components/UI/ModalUI";
import AgGrid from "../../../components/Table/AgGrid";
import { useAuth } from "../../../hooks/use-auth";
import moment from "moment";
import { calcSummary, formatFloat } from "../../../utils/dataTransformer";
import { v4 as uuidv4 } from "uuid";

const keys = ["item_list", "employee_list", "attachment_list"];

const requiredKeys = ["document_id"];

const stringOrDateToMoment = (date) => {
  if (typeof date === "string") {
    return moment(date?.trim(), "DD/MM/YYYY");
  }
  return moment(date);
};

const QuotationImporter = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const gridRef = useRef();
  const { user } = useAuth();
  const [formattedData, setFormattedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [createErrors, setCreateErrors] = useState([]);

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("sales.documentId"),
      filter: false,
      width: 250,
    },
    {
      field: "contact_document_id",
      headerName: t("sales.report.customerId"),
      sortable: false,
      filter: false,
      width: 225,
    },
    {
      field: "issue_date",
      headerName: t("sales.issueDate"),
      filter: false,
      valueFormatter: (params) => unixToDateWithFormat(params.data.issue_date),
      width: 200,
    },
    {
      field: "due_date",
      headerName: t("sales.dueDate"),
      filter: false,
      valueFormatter: (params) => unixToDateWithFormat(params.data.due_date),
      width: 200,
    },
    {
      field: "total_amount",
      headerName: t("sales.totalAmountInTable"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.data.total_amount),
    },
  ];

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.quotation.index"),
      to: "/sales/quotation",
    },
    {
      name: "นำเข้าใบเสนอราคา",
    },
  ];

  const formatQuotationData = async (data) => {
    const isAllNull = (item) => {
      if (typeof item === "object" && item !== null) {
        for (const val of Object.values(item)) {
          if (!isAllNull(val)) {
            return false;
          }
        }
      } else if (item !== null) {
        return false;
      }
      return true;
    };
    const formatEmployeeList = async (employeeList) => {
      const formattedEmployeeList = await Promise.all(
        employeeList?.length > 0
          ? employeeList.map(async (employee) => {
              try {
                const {
                  created_date,
                  last_login_date,
                  role_list,
                  status,
                  ...employeeDetail
                } = await UserService.getUser({
                  document_id: employee,
                });
                return employeeDetail;
              } catch (err) {
                return null;
              }
            })
          : []
      );
      return formattedEmployeeList.filter((employee) => employee !== null);
    };
    const formatItemList = async (itemList) => {
      return await Promise.all(
        itemList
          .map(async (item) => {
            try {
              const { base_uom_document_id, base_uom } =
                await InventoryService.getItem({
                  document_id: item.item_document_id,
                });
              return {
                ...item,
                uid: uuidv4(),
                item_document_id: item.item_document_id?.toString().trim(),
                item_name: item.item_name?.toString().trim(),
                item_description: item.item_description?.toString().trim(),
                item_remark: item.item_remark?.toString().trim(),
                uom_id: base_uom_document_id,
                qty: !isNaN(parseInt(item.qty)) ? parseInt(item.qty) : 0,
                qty_uom: !isNaN(parseInt(item.qty)) ? parseInt(item.qty) : 0,
                uom: {
                  id: base_uom?.id,
                  document_id: base_uom?.document_id,
                  name: base_uom?.name,
                },
                width_length_tolerance_positive:
                  item.width_length_tolerance_positive ?? undefined,
                width_length_tolerance_negative:
                  item.width_length_tolerance_negative ?? undefined,
                thickness_tolerance_positive:
                  item.thickness_tolerance_positive ?? undefined,
                thickness_tolerance_negative:
                  item.thickness_tolerance_negative ?? undefined,
                vat_type: item.vat_type?.toString().trim(),
                price_per_unit: !isNaN(parseFloat(item.price_per_unit))
                  ? formatFloat(item.price_per_unit)
                  : 0,
                discount_amount: !isNaN(parseFloat(item.discount_amount))
                  ? formatFloat(item.discount_amount)
                  : 0,
                withholding_tax: !isNaN(parseFloat(item.withholding_tax))
                  ? formatFloat(item.withholding_tax)
                  : 0,
              };
            } catch (err) {
              console.error(
                JSON.stringify(err, Object.getOwnPropertyNames(err))
              );
              return undefined;
            }
          })
          .filter((item) => item !== undefined)
      );
    };
    const formatAttachmentList = (attachmentList) => {
      return attachmentList
        ? attachmentList
            .map((attachment) => {
              return {
                name: attachment?.name?.toString().trim(),
                url: attachment?.url?.toString().trim(),
                creator_document_id: attachment?.creator_document_id
                  ?.toString()
                  .trim(),
              };
            })
            .filter((attachment) => isAllNull(attachment))
        : undefined;
    };
    try {
      const formattedQuotationData = await Promise.all(
        data.map(async (quotation) => {
          const formattedEmployeeList = await formatEmployeeList(
            quotation.employee_list
          );
          const existingCustomer = await ContactService.getContact({
            document_id: quotation.contact_document_id?.toString().trim(),
          });

          const { is_same_as_default_address, ...delivery_address } =
            quotation.customer.delivery_address;

          const formattedDeliveryAddress = isAllNull(delivery_address)
            ? {
                ...existingCustomer?.address_list[1],
                is_same_as_default_address:
                  existingCustomer?.address_list[1]?.same_as_default_address,
                same_as_default_address: undefined,
                default_address: undefined,
              }
            : {
                address_type: delivery_address.address_type?.toString().trim(),
                is_same_as_default_address: is_same_as_default_address || false,
                address_contact_name: delivery_address.address_contact_name
                  ?.toString()
                  .trim(),
                address_contact_phone: delivery_address?.address_contact_phone
                  ?.toString()
                  .trim(),
                address: delivery_address?.address?.toString().trim(),
                sub_district: delivery_address?.sub_district?.toString().trim(),
                district: delivery_address?.district?.toString().trim(),
                province: delivery_address?.province?.toString().trim(),
                postal_code: delivery_address?.postal_code?.toString().trim(),
                country: delivery_address?.country?.toString().trim(),
              };

          const formattedItemList = await formatItemList(quotation.item_list);
          const shippingCost = !isNaN(parseFloat(quotation.shipping_cost))
            ? formatFloat(quotation.shipping_cost)
            : 0;
          const additionalDiscount = !isNaN(
            parseFloat(quotation.additional_discount)
          )
            ? formatFloat(quotation.additional_discount)
            : 0;

          const calculatedSummary = calcSummary(
            formattedItemList,
            shippingCost,
            additionalDiscount,
            "qa"
          );
          const withHoldingTaxAmount = formattedItemList
            .filter((item) => ![-1, 0].includes(item?.withholding_tax))
            .reduce(
              (acc, item) =>
                acc +
                ((parseFloat(item?.qty || 0) *
                  parseFloat(item?.price_per_unit || 0) -
                  parseFloat(item?.discount_amount || 0)) *
                  parseFloat(item?.withholding_tax)) /
                  100,
              0
            );
          const totalAmount =
            calculatedSummary.net_amount + withHoldingTaxAmount;

          return {
            ...quotation,
            document_id: quotation.document_id?.toString().trim(),
            external_ref_id: quotation.external_ref_id?.toString().trim() ?? "",
            issue_date: quotation.issue_date
              ? stringOrDateToMoment(quotation.issue_date).add(1, "days").unix()
              : undefined,
            due_date: quotation.due_date
              ? stringOrDateToMoment(quotation.due_date).add(1, "days").unix()
              : undefined,
            credit_day: !isNaN(parseInt(quotation.credit_day))
              ? parseInt(quotation.credit_day)
              : undefined,
            contact_document_id: quotation.contact_document_id
              ?.toString()
              .trim(),
            pre_vat_amount: formatFloat(calculatedSummary.pre_vat_amount),
            shipping_cost: shippingCost,
            additional_discount: additionalDiscount,
            vat_exempted_amount: formatFloat(
              calculatedSummary.vat_exempted_amount
            ),
            vat_0_amount: formatFloat(calculatedSummary.vat_0_amount),
            vat_7_amount: formatFloat(calculatedSummary.vat_7_amount),
            vat_amount: formatFloat(calculatedSummary.vat_amount),
            net_amount: formatFloat(calculatedSummary.net_amount),
            withholding_tax_amount: formatFloat(withHoldingTaxAmount),
            total_amount: formatFloat(totalAmount),
            created_date: quotation.created_date
              ? stringOrDateToMoment(quotation.created_date)
                  .add(1, "days")
                  .unix()
              : stringOrDateToMoment().unix(),
            updated_date: quotation.updated_date
              ? stringOrDateToMoment(quotation.updated_date)
                  .add(1, "days")
                  .unix()
              : quotation.created_date
              ? stringOrDateToMoment(quotation.created_date)
                  .add(1, "days")
                  .unix()
              : undefined,
            template_remark_id:
              quotation.template_remark_id?.toString().trim() ?? "",
            remark: quotation.remark?.toString().trim(),
            creator_document_id: user.document_id,
            accepted_date: quotation.accepted_date
              ? stringOrDateToMoment(quotation.accepted_date)
                  .add(1, "days")
                  .unix()
              : undefined,
            accepted_remark: quotation.accepted_remark?.toString().trim() || "",
            employee_list: formattedEmployeeList,
            item_list: formattedItemList,
            attachment_list: formatAttachmentList(quotation.attachment_list),
            customer: {
              email: quotation.customer?.email?.toString().trim(),
              phone: quotation.customer?.phone?.toString().trim(),
              fax: quotation.customer?.fax?.toString().trim(),
              billing_address: {
                address:
                  quotation.customer?.billing_address?.address
                    ?.toString()
                    .trim() ||
                  existingCustomer?.address_list[0]?.billing_address?.address,
                sub_district:
                  quotation.customer?.billing_address?.sub_district
                    ?.toString()
                    .trim() || existingCustomer?.address_list[0]?.sub_district,
                district:
                  quotation.customer?.billing_address?.district
                    ?.toString()
                    .trim() || existingCustomer?.address_list[0]?.district,
                province:
                  quotation.customer?.billing_address?.province
                    ?.toString()
                    .trim() || existingCustomer?.address_list[0]?.province,
                postal_code:
                  quotation.customer?.billing_address?.postal_code
                    ?.toString()
                    .trim() || existingCustomer?.address_list[0]?.postal_code,
                country:
                  quotation.customer?.billing_address?.country
                    ?.toString()
                    .trim() || existingCustomer?.address_list[0]?.country,
              },
              delivery_address: formattedDeliveryAddress,
            },
          };
        })
      );
      return formattedQuotationData;
    } catch (err) {
      console.error(err);
      setCreateErrors([
        {
          type: "template",
        },
      ]);
      setHasCreateError(true);
      return [];
    }
  };

  const importFormattedDocuments = async () => {
    setCreateErrors([]);
    if (formattedData.length === 0) {
      return;
    }
    const formattedDataSnapshot = [...formattedData];
    const requiredFieldErrors = [];
    for (let i = 0; i < formattedDataSnapshot.length; i++) {
      for (let j = 0; j < requiredKeys.length; j++) {
        if (Array.isArray(formattedDataSnapshot[i][requiredKeys[j]])) {
          let hasError;
          for (
            let k = 0;
            k < formattedDataSnapshot[i][requiredKeys[j]].length;
            k++
          ) {
            if (
              Object.values(formattedDataSnapshot[i][requiredKeys[j]][k]).some(
                (item) => item === null || typeof item === "undefined"
              )
            ) {
              hasError = true;
              break;
            }
          }
          if (hasError) {
            requiredFieldErrors.push({
              document_id: formattedDataSnapshot[i].document_id,
              type: "required",
            });
            formattedDataSnapshot.splice(i, 1);
            i--;
            break;
          }
        }
        if (
          formattedDataSnapshot[i][requiredKeys[j]] !== 0 &&
          !formattedDataSnapshot[i][requiredKeys[j]]
        ) {
          requiredFieldErrors.push({
            document_id: formattedDataSnapshot[i].document_id,
            type: "required",
          });
          formattedDataSnapshot.splice(i, 1);
          i--;
          break;
        }
      }
    }
    if (requiredFieldErrors.length > 0) {
      setCreateErrors(requiredFieldErrors);
      setHasCreateError(true);
    } else {
      try {
        const data = await SalesService.createQuotations(formattedDataSnapshot);
        if (data.errors.length === 0) {
          enqueueSnackbar("นำเข้าใบเสนอราคาสำเร็จ", {
            variant: "success",
          });
          navigate("/sales/quotation");
        } else {
          setCreateErrors((prevErrors) => [...prevErrors, ...data.errors]);
          setHasCreateError(true);
          throw new Error();
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar("ไม่สามารถนำเข้าใบเสนอราคา", {
          variant: "error",
        });
      }
    }
  };

  const renderCreateErrors = createErrors.map((error, index) => {
    if (error.type === "template") {
      return (
        <Typography key={index} variant="body1" color="error">
          Template ไม่ถูกต้อง กรุณาอัปโหลดใหม่
        </Typography>
      );
    }
    let errorMessage;

    if (error.type === "required") {
      errorMessage = ", โปรดใส่ Required Field ให้ครบ";
    } else if (error.errorMessages?.includes("No quotation record found.")) {
      errorMessage = ", ไม่พบรหัสของพน่วย";
    } else if (error.errorMessage === "Contact document_id is not found.") {
      errorMessage = ", ไม่พบรหัสผู้ติดต่อของลูกค้า";
    } else if (error.errorMessage === "Dependency field not found.") {
      errorMessage = `, เนื่องจากไม่พบ Field ที่เกี่ยวข้องในระบบ (${error.field})`;
    } else if (
      error.errorMessage === "Quotation document_id is already in use."
    ) {
      errorMessage = " เนื่องจากมีอยู่แล้วในระบบ";
    } else {
      errorMessage = "";
    }

    return (
      <Typography
        key={error.document_id || index}
        sx={{ mb: 2 }}
        variant="body2"
        color="error"
      >
        {`ไม่สามารถสร้าง ${error.document_id}${errorMessage}`}
      </Typography>
    );
  });

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้าใบเสนอราคา"}</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <ImporterDropzoneUI
            keys={keys}
            setImportedDoc={setFormattedData}
            dataFormatter={formatQuotationData}
            setLoading={setIsLoading}
            setErrors={setCreateErrors}
            setHasError={setHasCreateError}
            hasLabelHeader
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <CustomizedButton
              title="นำเข้า"
              variant="contained"
              onClick={importFormattedDocuments}
              disabled={formattedData.length === 0 || isLoading}
            />
            <CustomizedButton
              title="ดาวน์โหลด Template"
              variant="contained"
              onClick={() =>
                window.open("/static/qa_import_template.xlsx", "_blank")
              }
            />
          </Box>
        </Grid>
      </Grid>
      {isLoading && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {formattedData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <AgGrid
            ref={gridRef}
            columnDefs={columnDefs}
            height={649}
            rowData={formattedData}
            groupDefaultExpanded={1}
          />
        </Box>
      )}
      <ModalUI
        open={hasCreateError}
        handleClose={() => setHasCreateError(false)}
        fullWidth
        maxWidth="xs"
      >
        {renderCreateErrors}
      </ModalUI>
    </>
  );
};

export default QuotationImporter;
