import * as Yup from "yup";

export const validation = Yup.object().shape({
  uom_conversion_list: Yup.array().of(
    Yup.object().shape({
      base_uom_rate: Yup.number()
        .required("กรุณากรอก")
        .min(1, "กรุณาระบุมากกว่า 1")
        .typeError("กรุณากรอกเป็นตัวเลข"),
      target_uom_document_id: Yup.string()
        .required("กรุณากรอก")
        .min(1, "กรุณากรอก"),
      target_uom_rate: Yup.number()
        .required("กรุณากรอก")
        .min(1, "กรุณาระบุมากกว่า 1")
        .typeError("กรุณากรอกเป็นตัวเลข"),
    })
  ),
});
