import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { formatDate } from "../../../../utils/date-converter";

const DeliveryTripPDFInfo = ({ deliveryTrip, contact }) => {
  const leftHeader = [
    "รูปแบบการจัดส่ง / Delivery Type",
    "ผู้จัดส่ง / Delivery Person",
    "ทะเบียนรถ / Vehicle License",
    "รอบ / Trip",
    "เวลาเข้า / In date",
    "เวลาออก / Out date",
  ];

  const formatRerenceDocumentId = deliveryTrip.delivery_order_list.map(
    (doc) => doc.reference_document_id
  );

  const leftInfo = [
    deliveryTrip?.delivery_type || "-",
    deliveryTrip?.goods_deliver_list &&
    deliveryTrip?.goods_deliver_list.length > 0
      ? deliveryTrip?.goods_deliver_list.map(
          (good) => good.first_name + " " + good.last_name
        )
      : "-",
    deliveryTrip?.vehicle?.license_plate || "-",
    deliveryTrip?.trip || "-",
    deliveryTrip?.in_date
      ? moment(deliveryTrip.in_date).format("DD/MM/YYY HH:mm")
      : "-",
    deliveryTrip?.out_date
      ? moment(deliveryTrip.out_date).format("DD/MM/YYY HH:mm")
      : "-",
  ];

  const rightHeader = [
    "เลขที่เอกสาร / Document No.",
    "อ้างอิงถึง / Ref. Document",
    "วันที่ออกเอกสาร / Issue date",
    "วันที่ส่งของ / Delivery Date",
  ];

  const rightInfo = [
    deliveryTrip?.document_id || "-",
    deliveryTrip?.reference_document_id && formatRerenceDocumentId.join(", "),
    formatDate(deliveryTrip?.issue_date) || "-",
    formatDate(deliveryTrip?.delivery_date) || "-",
  ];

  return (
    <Grid container sx={{ my: 1 }}>
      <Grid item xs={6}>
        {leftHeader.map((name, index) => (
          <Box sx={{ display: "flex" }} key={index}>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 1,
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[index]}
            </Typography>
          </Box>
        ))}
      </Grid>
      <Grid item xs={6}>
        {rightHeader.map((name, index) => (
          <Box sx={{ display: "flex" }} key={index}>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 1,
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {rightInfo[index]}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default DeliveryTripPDFInfo;
