import { Controller, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import CustomizedTextField from "../../../Custom/CustomizedTextField";

const defaultContactChannelValues = {
  contact_channel: "เบอร์โทรศัพท์",
  contact_info_1: "",
  contact_info_2: "",
};

const ContactChannelForm = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contact_channel_list",
  });

  const watchFieldArray = useWatch({ control, name: "contact_channel_list" });
  const controlledFieldArray = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const contactChannels = [
    "เบอร์โทรศัพท์",
    "แฟกซ์",
    "Email",
    "Line",
    "Facebook",
    "Website",
    "Instagram",
    "อื่นๆ",
  ];

  const renderContactChannels = () => {
    return controlledFieldArray.map((item, index) => {
      return (
        <Grid item xs={12} key={item.id}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              order={{ xs: 2, md: 1 }}
            >
              <Controller
                control={control}
                error={errors}
                name={`contact_channel_list.${index}.contact_channel`}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label={t("contact.contact_channel.type")}
                    isOptionEqualToValue={(option, value) => {
                      if (value === "") {
                        return true;
                      }
                      return option === value;
                    }}
                    renderValue={(value) => {
                      return value;
                    }}
                    onChange={(e, value) => field.onChange(value)}
                    options={contactChannels}
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={2}
              order={{ xs: 2, md: 3 }}
            >
              <Controller
                name={`contact_channel_list.${index}.contact_info_1`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(
                      errors.contact_channel_list?.[index]?.contact_info_1
                    )}
                    helperText={
                      errors.contact_channel_list?.[index]?.contact_info_1
                        ?.message
                    }
                    label={t("contact.contact_channel.info")}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={1} order={{ xs: 1, md: 4 }}>
                <IconButton
                  aria-label="delete"
                  onClick={() => remove(index)}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      {!disabled && (
        <Grid item xs={12}>
          <CustomizedButton
            title={"เพิ่มช่องทางติดต่อ"}
            variant="outlined"
            size="medium"
            onClick={() => append({ ...defaultContactChannelValues })}
          />
        </Grid>
      )}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {renderContactChannels()}
      </Grid>
    </>
  );
};

export default ContactChannelForm;
