import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import OrderTable from "../../../components/Table/DocumentTable/OrderTable";
import { getAllSalesOrders } from "../../../features/Sales/SalesOrder/sales-order-actions";
import { salesOrderActions } from "../../../features/Sales/SalesOrder/sales-order-slice";
import { usePermission } from "../../../hooks/use-auth";
import { filterDateWithUnix } from "../../../utils/filterparams";

const SalesOrder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const navigate = useNavigate();
  const { createPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.order.index"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.waitApprove"),
      path: `${pathname}?filter=waitApprove`,
    },
    {
      label: t("status.notApproved"),
      path: `${pathname}?filter=notApproved`,
    },
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.expired"),
      path: `${pathname}?filter=expired`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const {
        document_id,
        contact_name,
        issue_date,
        delivery_date,
        created_by,
        employee_list,
        is_late,
        do_document_id_list,
        item_group_level_1_name_list,
      } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        contact_name: contact_name && {
          ...contact_name,
          mode: "insensitive",
        },
        issue_date: issue_date && filterDateWithUnix(issue_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...employee_list, mode: "insensitive" },
          },
        },
        item_group_level_1_name_list: item_group_level_1_name_list && {
          filterType: "array",
          type: "hasSome",
          values: item_group_level_1_name_list.values,
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: {
              ...created_by,
              mode: "insensitive",
            },
          },
        },
        is_late: is_late
          ? {
              filterType: "boolean",
              type: "equals",
              filter: "true",
            }
          : undefined,
        do_document_id_list: do_document_id_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: do_document_id_list.values,
            }
          : undefined,
      };

      dispatch(
        getAllSalesOrders(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("render_status");
      const isExpired = params.api.getFilterInstance("is_late");
      switch (isFilter) {
        case "draft":
          instance.setModel({ values: ["draft"] });
          isExpired.setModel({});
          break;
        case "waitApprove":
          instance.setModel({ values: ["PENDING"] });
          isExpired.setModel({});
          break;
        case "notApproved":
          instance.setModel({ values: ["DECLINED"] });
          isExpired.setModel({});
          break;
        case "approved":
          instance.setModel({ values: ["approved"] });
          isExpired.setModel({});
          break;
        case "expired":
          instance.setModel({});
          isExpired.setModel({ values: ["เกินเวลาแล้ว"] });
          break;
        case "finished":
          instance.setModel({ values: ["finished"] });
          isExpired.setModel({});
          break;
        case "cancelled":
          instance.setModel({ values: ["cancelled"] });
          isExpired.setModel({});
          break;
        default:
          instance.setModel({
            values: [
              "draft",
              "PENDING",
              "DECLINED",
              "approved",
              "เกินเวลาแล้ว",
              "finished",
            ],
          });
          isExpired.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    return () => {
      dispatch(salesOrderActions.resetAllSalesOrders());
    };
  }, [dispatch, onFilterChanged]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("sales.order.index")}</Typography>
        {createPermission && (
          <CustomizedButton
            title={"สร้างใบสั่งขาย"}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
        )}
      </Box>
      <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
      <OrderTable gridRef={gridRef} onGridReady={onGridReady} />
    </>
  );
};

export default SalesOrder;
