import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box, InputAdornment } from "@mui/material";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";
import CustomizedChips from "../../../Custom/CustomizedChips";

const SpecificTab = ({ control, errors, viewOnly }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "estimate_sales_item_group",
  });

  const { t } = useTranslation();

  const renderProductChips = () => {
    return fields.map((item, index) => (
      <Controller
        key={item.id}
        name={`estimate_sales_item_group.${index}`}
        control={control}
        render={({ field }) => (
          <Box sx={{ display: "inline-block", mr: 1 }}>
            <CustomizedChips
              onDelete={!viewOnly ? () => remove(index) : null}
              {...field}
            />
          </Box>
        )}
      />
    ));
  };
  return (
    <>
      <Typography fontWeight="bold" sx={{ ml: 1, mb: 2 }}>
        {t("contact.specific_info.purchase_preference")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <CustomizedCreatable
            options={[]}
            label={t("contact.specific_info.purchase_preference")}
            placeholder={t("contact.specific_info.purchase_preference")}
            onChange={(e, newValue) => {
              if (typeof newValue === "string") {
                return;
              } else if (newValue && newValue.inputValue) {
                append(newValue.inputValue);
              } else {
                if (newValue) append(newValue.value);
              }
            }}
            readOnly={viewOnly}
            disabled={viewOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          {renderProductChips()}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="estimate_sales_volume"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">&#3647;</InputAdornment>
                  ),
                }}
                error={Boolean(errors.estimate_sales_volume)}
                helperText={errors.estimate_sales_volume?.message}
                label={t("contact.specific_info.amount_per_month")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SpecificTab;
