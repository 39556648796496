import { Box, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import React from "react";

const PDFSigner = ({ documentType }) => {
  return (
    <>
      {documentType === "delivery_trip" ? (
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexWrap: "nowrap",
            width: "197.3mm",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <QRCodeSVG value={window.location.href} size={72} />
            <Typography sx={{ fontSize: 12 }}>สแกนดูเอกสารออนไลน์</Typography>
          </Box>
        </Box>
      ) : documentType === "bom" ? (
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexWrap: "nowrap",
            width: "197.3mm",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <QRCodeSVG value={window.location.href} size={72} />
            <Typography sx={{ fontSize: 12 }}>สแกนดูเอกสารออนไลน์</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              ผู้ออกเอกสาร
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              _________________
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              _____/_____/_____
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexWrap: "nowrap",
            width: "197.3mm",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <QRCodeSVG value={window.location.href} size={72} />
            <Typography sx={{ fontSize: 12 }}>สแกนดูเอกสารออนไลน์</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              {documentType !== "GT" ? "ผู้ออกเอกสาร" : "ผู้โอน"}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              _________________
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              _____/_____/_____
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              {documentType === "delivery_order"
                ? "สโตร์"
                : documentType === "GT"
                ? "ผู้จัดส่ง"
                : "ผู้ตรวจสอบ"}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              _________________
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              _____/_____/_____
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              {documentType === "delivery_order"
                ? "จัดส่ง"
                : documentType === "GT"
                ? "ผู้รับ"
                : "ผู้อนุมัติ"}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              _________________
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              _____/_____/_____
            </Typography>
          </Box>
          {documentType === "GT" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                ผู้ตรวจสอบ
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                _________________
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                _____/_____/_____
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default PDFSigner;
