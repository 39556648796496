import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ControlledDatePicker from "../../../Custom/ControlledDatePicker";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import AttachmentDropzone from "./AttachmentDropzone";

// TODO rename to proof form and change titles accoridng to the pages
const ProofForm = ({
  control,
  setValue,
  getValues,
  errors,
  onSubmit,
  type,
  hideButtons,
  viewOnly,
  open,
}) => {
  const { t } = useTranslation();
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

  const title = () => {
    switch (type) {
      case "accepted":
        return "ยอมรับใบเสนอราคา";
      case "payment":
        return "หลักฐานการรับชำระ";
      default:
        return "";
    }
  };

  const dateLabel = () => {
    switch (type) {
      case "accepted":
        return "ยอมรับเมื่อ";
      case "payment":
        return "ชำระเมื่อ";
      default:
        return "";
    }
  };

  return (
    <>
      {open ? (
        <>
          <Grid container spacing={2} sx={{ mb: 4, mt: 0.01 }}>
            <Grid item xs={3} alignSelf="center">
              <Typography sx={{ ml: -1 }}>{dateLabel()}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ControlledDatePicker
                key={`${type}_date`}
                name={`${type}_date`}
                control={control}
                error={errors[`${type}_date`]?.error}
                isOpen={datePickerIsOpen}
                onClose={() => setDatePickerIsOpen(false)}
                onOpen={() => setDatePickerIsOpen(true)}
                label="วันที่"
                disabled={viewOnly}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight="bold" sx={{ ml: -1 }}>
                หลักฐานอ้างอิง
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!viewOnly ? (
                <Controller
                  key={`${type}_remark`}
                  name={`${type}_remark`}
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      sx={{ ml: -1 }}
                      fullWidth
                      multiline
                      rows={4}
                      error={Boolean(errors[`${type}_remark`])}
                      helperText={errors[`${type}_remark`]?.message}
                      {...field}
                      disabled={viewOnly}
                    />
                  )}
                />
              ) : (
                <Typography sx={{ ml: -1 }}>
                  {getValues(`${type}_remark`) || "-"}
                </Typography>
              )}
            </Grid>
            {!viewOnly && (
              <Grid item xs={12}>
                <AttachmentDropzone
                  setValue={setValue}
                  getValues={getValues}
                  valueToSet={"attachment_list"}
                  disabled={viewOnly}
                  open={open}
                />
              </Grid>
            )}
          </Grid>
          {!viewOnly && !hideButtons && (
            <Box sx={{ mr: 1, mt: 4 }}>
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={onSubmit}
              />
            </Box>
          )}
        </>
      ) : (
        <CustomizedBox margin={0}>
          <Typography fontWeight="bold" sx={{ ml: 1, mb: 4 }}>
            {title()}
          </Typography>
          <Grid container spacing={4} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ alignSelf: "center" }}
                  >
                    <Typography sx={{ ml: 1 }}>{dateLabel()}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControlledDatePicker
                      key={`${type}_date`}
                      name={`${type}_date`}
                      control={control}
                      error={errors[`${type}_date`]?.error}
                      isOpen={datePickerIsOpen}
                      onClose={() => setDatePickerIsOpen(false)}
                      onOpen={() => setDatePickerIsOpen(true)}
                      label="วันที่"
                      disabledPast
                      disabled={viewOnly}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight="bold" sx={{ ml: 1 }}>
                {"หลักฐานอ้างอิง"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {!viewOnly ? (
                <Controller
                  key={`${type}_remark`}
                  name={`${type}_remark`}
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      multiline
                      rows={4}
                      error={Boolean(errors[`${type}_remark`])}
                      helperText={errors[`${type}_remark`]?.message}
                      {...field}
                      disabled={viewOnly}
                    />
                  )}
                />
              ) : (
                <Typography sx={{ ml: 1 }}>
                  {getValues(`${type}_remark`) || "-"}
                </Typography>
              )}
            </Grid>
          </Grid>
          {!viewOnly && (
            <AttachmentDropzone
              setValue={setValue}
              getValues={getValues}
              valueToSet={"attachment_list"}
              disabled={viewOnly}
            />
          )}

          {!viewOnly && !hideButtons && (
            <Box sx={{ mr: 1, mt: 4 }}>
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={onSubmit}
              />
            </Box>
          )}
        </CustomizedBox>
      )}
    </>
  );
};

export default ProofForm;
