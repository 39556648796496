import { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  List,
  ListItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useForm, Controller, useWatch } from "react-hook-form";
import moment from "moment";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import CustomizedCheckboxes from "../../components/Custom/CustomizedCheckboxes";
import CustomizedTab from "../../components/Custom/CustomizedTab";
import { CustomizedTooltip } from "../../components/Custom/CustomizedTooltip";
import ImporterDropzoneUI from "../../components/UI/ImporterDropzoneUI";
import ModalUI from "../../components/UI/ModalUI";
import ContactService from "../../services/Contact";
import AgGrid from "../../components/Table/AgGrid";
import { useAuth } from "../../hooks/use-auth";
import {
  formatFloat,
  formatStringArray,
  sortArrayOfObjectByValue,
} from "../../utils/dataTransformer";
import UserService from "../../services/User";
import ImportErrorTable from "../../components/Table/ImportErrorTable";

const keys = [
  "finance.bank_list",
  "contact_channel_list",
  "contact_person_list",
  "address_list",
  "contact_group",
  "estimate_sales_item_group",
  ["contact_person_list", "contact_channel_list"],
];

const columns = [
  "document_id",
  "is_vendor",
  "is_customer",
  "contact_type_1",
  "contact_type_2",
  "initial",
  "contact_name_1",
  "contact_name_2",
  "identity_no",
  "registered_capital",
  "contact_source",
  "contact_channel_list",
  "contact_status",
  "contact_group",
  "contact_status_remark",
  "address_list",
  "remark",
  "contact_person_list",
  "finance",
  "estimate_sales_item_group",
  "estimate_sales_volume",
];

const requiredKeys = ["document_id", "contact_name_1"];

const ContactImporterPage = () => {
  //keys need to be defined in order
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useAuth();
  const gridRef = useRef();
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState([]);
  const [formattedCreateOnlyData, setFormattedCreateOnlyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [createErrors, setCreateErrors] = useState([]);
  const [createData, setCreateData] = useState([]);
  const [updateOrSkipData, setUpdateOrSkipData] = useState([]);
  const [idsWithWhitespace, setIdsWithWhitespace] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  const { control } = useForm({
    defaultValues: { is_upsert: true },
  });
  const watchIsUpsert = useWatch({ control, name: "is_upsert" });

  useEffect(() => {
    switch (tab) {
      case "updates":
        if (watchIsUpsert) {
          setDisplayedData(updateOrSkipData);
        } else {
          setDisplayedData([]);
        }
        break;
      case "skips":
        if (!watchIsUpsert) {
          setDisplayedData(updateOrSkipData);
        } else {
          setDisplayedData([]);
        }
        break;
      default:
        setDisplayedData(createData);
    }
    // setDisplayedData(flattenedSkuList);
  }, [watchIsUpsert, tab, createData, updateOrSkipData]);

  const errorCount = idsWithWhitespace?.length > 0 ? 1 : 0;

  const tabs = [
    {
      label: `สร้าง (${createData.length})`,
      path: `${pathname}`,
    },
    {
      label: `แก้ไข (${watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=updates`,
    },
    {
      label: `ข้าม (${!watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=skips`,
    },
    {
      label: `Error (${errorCount})`,
      path: `${pathname}?tab=error`,
      errors: errorCount > 0,
    },
  ];

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("contact.document_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "contact_type_1",
      headerName: t("contact.info.business_type"),
      filter: "agTextColumnFilter",
    },
    {
      field: "is_vendor",
      headerName: t("contact.contact_type.index"),
      filter: false,
      cellRenderer: ({ data }) => {
        if (data.is_vendor && data.is_customer) {
          return "ลูกค้า, ผู้ขาย";
        }
        if (data.is_vendor) {
          return "ผู้ขาย";
        }
        if (data.is_customer) {
          return "ลูกค้า";
        }
        return "";
      },
    },
    {
      field: "is_customer",
      filter: "agTextColumnFilter",
      filterParams: {
        textMatcher: ({ data, filterText }) => {
          return data.is_customer.toString() === filterText;
        },
      },
      hide: true,
    },
    {
      field: "contact_name_1",
      headerName: "ชื่อกิจการ/ชื่อ นามสกุล",
      cellRenderer: ({ data }) => {
        if (data.contact_type_1 === "นิติบุคคล") {
          return data.contact_name_1;
        }
        return `${data.contact_name_1} ${data.contact_name_2}`;
      },
    },
    {
      field: "contact_status",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["active", "inactive"],
      },
      cellRenderer: (params) => {
        if (params.value === "active") {
          return <CustomizedStatus status="active" />;
        }
        return <CustomizedStatus status="in_active" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  const breadcrumbs = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: "นำเข้าผู้ติดต่อ",
    },
  ];

  const formatContactData = async (data) => {
    setIdsWithWhitespace([]);
    const isAllNull = (item) => {
      if (typeof item === "object" && item !== null) {
        for (const val of Object.values(item)) {
          if (!isAllNull(val)) {
            return false;
          }
        }
      } else if (item !== null) {
        return false;
      }
      return true;
    };
    const formatAddressList = (addressList) => {
      const result = addressList?.map((address, index) => {
        return {
          ...address,
          default_address: index === 0 ? true : false,
          same_as_default_address: index === 0 ? undefined : false,
          sub_district: address.sub_district?.toString().trim() ?? "",
          district: address.district?.toString().trim() ?? "",
          province: address.province?.toString().trim() ?? "",
          address: address.address?.toString().trim() ?? "",
          address_contact_name: address.address_contact_name ?? "",
          address_contact_phone: address.address_contact_phone ?? "",
          postal_code: address.postal_code?.toString() ?? "",
          country: address.country?.replace("ประเทศ", "").trim() ?? "",
        };
      });
      return result;
    };

    const formatContactChannelList = (contactChannelList) => {
      const result = contactChannelList
        ?.map((channel) => {
          return {
            contact_channel: channel.contact_channel?.toString().trim() ?? "",
            contact_info_1: channel.contact_info_1?.toString().trim() ?? "",
            contact_info_2: channel.contact_info_2?.toString().trim() ?? "",
          };
        })
        .filter((channel) => {
          return channel.contact_channel && channel.contact_info_1;
        });
      return result;
    };

    const formatContactPersonList = (contactPersonList) => {
      const result = contactPersonList
        ?.map((person) => {
          const formattedContactChannelList = formatContactChannelList(
            person.contact_channel_list
          );
          return {
            contact_channel_list: formattedContactChannelList,
            img_url: person.img_url?.trim() ? person.img_url?.trim() : "",
            initial: person.initial?.trim() ? person.initial?.trim() : "",
            name_1: person.name_1?.trim() ? person.name_1?.trim() : "",
            name_2: person.name_2?.trim() ? person.name_2?.trim() : "",
            name_3: person.name_3?.trim() ? person.name_3?.trim() : "",
            position: person.position?.trim() ? person.position?.trim() : "",
            remark: person.remark?.trim() ? person.remark?.trim() : "",
          };
        })
        .filter((person) => {
          return !Object.values(person).every(
            (item) => typeof item === "undefined" || typeof item === "object"
          );
        });
      return result;
    };

    const formatBankList = (bankList) => {
      const result = bankList
        ?.map((account) => {
          return {
            bank_name: account.bank_name?.toString().trim() ?? "",
            bank_account_name:
              account.bank_account_name?.toString().trim() ?? "",
            bank_account_no: account.bank_account_no?.toString().trim() ?? "",
            bank_account_branch:
              account.bank_account_branch?.toString().trim() ?? "",
            bank_account_type:
              account.bank_account_type?.toString().trim() ?? "",
            description: account.description?.toString().trim() ?? "",
          };
        })
        .filter((account) => {
          return !Object.values(account).every(
            (item) => typeof item === "undefined"
          );
        });
      return result;
    };

    const formatAttachmentList = (attachmentList) => {
      return attachmentList
        ? attachmentList
            .map((attachment) => {
              return {
                name: attachment?.name?.toString().trim() ?? "",
                url: attachment?.url?.toString().trim() ?? "",
                creator_document_id:
                  attachment?.creator_document_id?.toString().trim() ?? "",
              };
            })
            .filter((attachment) => isAllNull(attachment))
        : [];
    };

    const formatSalesPerson = async (salesPersonList) => {
      const allUserDocId = salesPersonList
        ? salesPersonList.split(",").map((list) => list.trim())
        : [];
      let res = await UserService.getUsersWithDocumentId({
        findManyInput: {
          where: {
            document_id: {
              in: allUserDocId,
            },
          },
        },
      });
      const sortedRes = sortArrayOfObjectByValue(
        res,
        allUserDocId,
        "document_id"
      );
      return sortedRes;
    };

    let missingCols;

    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const formattedData = await Promise.all(
        data.map(async (item) => {
          const formattedSalesPerson = await formatSalesPerson(
            item.sales_person
          );
          return {
            ...item,
            created_date: moment().unix(),
            document_id: item.document_id?.toString().trim(),
            creator_document_id: user.document_id,
            is_vendor:
              item.is_vendor && item.is_vendor !== null
                ? item.is_vendor
                : false,
            is_customer:
              item.is_customer && item.is_customer !== null
                ? item.is_customer
                : false,
            identity_no: item.identity_no?.toString().trim() ?? "",
            contact_name_1: item.contact_name_1
              ?.replace("หจก.", "")
              .replace("บริษัท", "")
              .replace("คุณ", "")
              .trim(),
            contact_status:
              item.contact_status === "inactive" ? "inactive" : "active",
            contact_status_remark:
              item.contact_status_remark?.toString().trim() ?? "",
            finance: {
              ...item.finance,
              is_cash:
                item.finance.is_cash && item.finance.is_cash !== null
                  ? item.finance.is_cash
                  : false,
              is_cheque:
                item.finance.is_cheque && item.finance.is_cheque !== null
                  ? item.finance.is_cheque
                  : false,
              is_transfer:
                item.finance.is_transfer && item.finance.is_transfer !== null
                  ? item.finance.is_transfer
                  : false,
              bank_list: formatBankList(item.finance.bank_list),
              account_receivable_id:
                item.finance.account_receivable_id?.toString() ?? "",
              account_payable_id:
                item.finance.account_payable_id?.toString() ?? "",
              request_credit: {
                credit_limit_day: isNaN(
                  parseInt(item.finance.request_credit.credit_limit_day)
                )
                  ? 0
                  : parseInt(item.finance.request_credit.credit_limit_day),
                credit_limit_value: isNaN(
                  parseInt(item.finance.request_credit.credit_limit_value)
                )
                  ? 0
                  : parseInt(item.finance.request_credit.credit_limit_value),
              },
              billing_contact_name:
                item.finance.billing_contact_name?.toString().trim() ?? "",
              billing_contact_phone:
                item.finance.billing_contact_phone?.toString().trim() ?? "",
              payment_contact_phone:
                item.finance.payment_contact_phone?.toString().trim() ?? "",
              billing_day: !isNaN(parseInt(item.finance.billing_day))
                ? parseInt(item.finance.billing_day)
                : undefined,
              credit_count: item.finance.credit_count?.toString().trim() ?? "",
              payment_day: !isNaN(parseInt(item.finance.payment_day))
                ? parseInt(item.finance.payment_day)
                : undefined,
            },
            img_url: item.img_url?.toString().trim() ?? "",
            contact_source: item.contact_source?.toString().trim() ?? "",
            contact_group: formatStringArray(item.tag_list),
            tag_list: formatStringArray(item.tag_list),
            estimate_sales_item_group: formatStringArray(
              item.estimate_sales_item_group
            ),
            estimate_sales_volume: !isNaN(
              parseFloat(item.estimate_sales_volume)
            )
              ? formatFloat(item.estimate_sales_volume)
              : 0,
            registered_capital: !isNaN(parseFloat(item.registered_capital))
              ? formatFloat(item.registered_capital)
              : 0,
            last_updator_document_id:
              item.last_updator_document_id ?? undefined,
            remark: item.remark?.toString().trim() ?? "",
            address_list: formatAddressList(item.address_list),
            contact_channel_list: formatContactChannelList(
              item.contact_channel_list
            ),
            contact_person_list: formatContactPersonList(
              item.contact_person_list
            ),
            attachment_list: formatAttachmentList(item.attachment_list),
            sales_list: formattedSalesPerson,
            sales_person: undefined,
          };
        })
      );

      const existingContacts = await ContactService.getAllContactIds();
      const existingContactIds = existingContacts.map(
        (contact) => contact.document_id
      );
      const contactsToCreate = [];
      const contactsToSkipOrUpdate = [];

      formattedData.forEach((contact) => {
        if (existingContactIds.includes(contact.document_id)) {
          contactsToSkipOrUpdate.push(contact);
        } else {
          contactsToCreate.push(contact);
        }
      });
      setCreateData(contactsToCreate);
      setUpdateOrSkipData(contactsToSkipOrUpdate);
      setFormattedCreateOnlyData(
        formattedData.filter(
          (contact) => !existingContactIds.includes(contact.document_id)
        )
      );
      return formattedData;
    } catch (err) {
      console.error(err);
      if (err.message === "template") {
        enqueueSnackbar(
          `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`,
          {
            variant: "error",
          }
        );
      }

      return [];
    }
  };

  const importFormattedDocuments = async () => {
    setIsLoading(true);
    setCreateErrors([]);
    if (formattedData.length === 0) {
      return;
    }
    const formattedDataSnapshot = [...formattedData];
    const requiredFieldErrors = [];
    for (let i = 0; i < formattedDataSnapshot.length; i++) {
      for (let j = 0; j < requiredKeys.length; j++) {
        if (Array.isArray(formattedDataSnapshot[i][requiredKeys[j]])) {
          let hasError;
          for (
            let k = 0;
            k < formattedDataSnapshot[i][requiredKeys[j]].length;
            k++
          ) {
            if (
              Object.values(formattedDataSnapshot[i][requiredKeys[j]][k]).some(
                (item) => item === null || typeof item === "undefined"
              )
            ) {
              hasError = true;
              break;
            }
          }
          if (hasError) {
            requiredFieldErrors.push({
              document_id: formattedDataSnapshot[i].document_id,
              type: "required",
            });
            formattedDataSnapshot.splice(i, 1);
            i--;
            break;
          }
        }
        if (
          formattedDataSnapshot[i][requiredKeys[j]] !== 0 &&
          !formattedDataSnapshot[i][requiredKeys[j]]
        ) {
          requiredFieldErrors.push({
            document_id: formattedDataSnapshot[i].document_id,
            type: "required",
            field: requiredKeys[j],
          });
          formattedDataSnapshot.splice(i, 1);
          i--;
          break;
        }
      }
    }
    if (requiredFieldErrors.length > 0) {
      setCreateErrors(requiredFieldErrors);
      setHasCreateError(true);
      setIsLoading(false);
    } else {
      let idsWithSpace = [];
      try {
        let data;
        if (watchIsUpsert) {
          const dataWithFormattedId = formattedData.filter((data) => {
            if (data.document_id.includes(" ")) {
              idsWithSpace.push(data.document_id);
              return false;
            }
            return true;
          });
          data = await ContactService.createOrUpdateContacts({
            createManyInput: dataWithFormattedId,
          });
        } else {
          const dataWithFormattedId = formattedCreateOnlyData.filter((data) => {
            if (data.document_id.includes(" ")) {
              idsWithSpace.push(data.document_id);
              return false;
            }
            return true;
          });
          data = await ContactService.createContacts({
            createManyInput: dataWithFormattedId,
          });
        }
        if (idsWithSpace.length > 0) {
          throw new Error("id_whitespace");
        }
        if (data.errors?.length === 0 && idsWithSpace.length === 0) {
          let message = "";
          if (
            watchIsUpsert &&
            createData.length > 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "นำเข้าและแก้ไขผู้ติดต่อสำเร็จ";
          } else if (
            watchIsUpsert &&
            createData.length === 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "แก้ไขผู้ติดต่อสำเร็จ";
          } else if (
            (watchIsUpsert &&
              createData.length > 0 &&
              updateOrSkipData.length === 0) ||
            (!watchIsUpsert && createData.length > 0)
          ) {
            message = "นำเข้าสำเร็จ";
          }
          enqueueSnackbar(message, {
            variant: "success",
          });
          navigate("/contact/contacts");
        } else {
          setCreateErrors((prevErrors) => [...prevErrors, ...data.errors]);
          setHasCreateError(true);
          throw new Error();
        }
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        if (err.message === "id_whitespace") {
          setIdsWithWhitespace(idsWithSpace);
          setHasCreateError(true);
          setCreateErrors((prevErrors) => [
            ...prevErrors,
            { type: "id_whitespace", ids: idsWithSpace },
          ]);
        }
        enqueueSnackbar("รูปแบบข้อมูลไม่ถูกต้อง กรุณาตรวจสอบไฟล์", {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };

  const renderCreateErrors = () => {
    const whiteSpaceError = createErrors.find(
      (error) => error.type === "id_whitespace"
    );
    if (createErrors.find((error) => error.type === "template_from_importer")) {
      enqueueSnackbar("Template ไม่ตรง", {
        variant: "error",
      });
    }
    const requiredFieldErrors = [
      ...new Set(
        createErrors
          .filter((error) => error.type === "required")
          .map((error) => error.field)
      ),
    ];
    if (requiredFieldErrors.length > 0) {
      enqueueSnackbar(
        `ไม่พบข้อมูลใน column\n${requiredFieldErrors.join(", ")}`,
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
    if (whiteSpaceError) {
      return (
        <ModalUI
          open={hasCreateError}
          handleClose={() => setHasCreateError(false)}
          fullWidth
          title="ไม่สามารถสร้างได้ เนื่องจากรหัสสินค้ามีการเว้นวรรค"
          titleSize="16px"
          maxWidth="xs"
        >
          <Typography fontWeight="bold">รายการที่ไม่สามารถสร้างได้:</Typography>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            {whiteSpaceError.ids.map((id) => (
              <ListItem
                key={id}
                sx={{
                  display: "list-item",
                }}
              >
                <Typography
                  sx={{
                    display: "inline",
                    color: (theme) => theme.palette.warning.main,
                  }}
                >
                  {id}
                </Typography>
              </ListItem>
            ))}
          </List>
        </ModalUI>
      );
    }
    return <></>;
  };

  const renderErrorTab = (
    <>
      {idsWithWhitespace?.length > 0 && (
        <ImportErrorTable
          sx={{ mt: 2 }}
          data={idsWithWhitespace}
          keyName={"contact-whitespace-row"}
          title={"รหัสผู้ติดต่อ"}
          errorDescription={"รหัสผู้ติดต่อมีการเว้นวรรค"}
          columnName={"document_id"}
          documentType={"contact"}
        />
      )}
    </>
  );

  const currentTab = tab ? pathname + `?tab=${tab}` : pathname;

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้าผู้ติดต่อ"}</Typography>
      </Box>
      <Box
        sx={{ mt: 1, mb: 2, display: "flex", justifyContent: "space-between" }}
      >
        <CustomizedButton
          title="ดาวน์โหลด Template"
          variant="outlined"
          onClick={() =>
            window.open("/static/contact_import_template.xlsx", "_blank")
          }
        />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <ImporterDropzoneUI
            keys={keys}
            setImportedDoc={setFormattedData}
            dataFormatter={formatContactData}
            setLoading={setIsLoading}
            setErrors={setCreateErrors}
            setHasError={setHasCreateError}
            hasLabelHeader
          />
        </Grid>
      </Grid>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Controller
              name={"is_upsert"}
              control={control}
              render={({ field }) => (
                <CustomizedCheckboxes label="อัปเดตรายการปัจจุบัน" {...field} />
              )}
            />
            <CustomizedTooltip
              title={
                "กรณีที่มีรหัสผู้ติดต่อเดิมอยู่แล้ว จะเป็นการเลือกเพื่ออัปเดตข้อมูลใหม่ในรายการผู้ติดต่อเดิม"
              }
              noMaxWidth
            >
              <ErrorOutlineOutlinedIcon sx={{ fontSize: "14px" }} />
            </CustomizedTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomizedButton
              title="นำเข้า"
              variant="contained"
              onClick={importFormattedDocuments}
              disabled={
                formattedData.length === 0 ||
                isLoading ||
                (!watchIsUpsert && formattedCreateOnlyData.length === 0)
              }
            />
          </Box>
        </Grid>
      </Grid>

      {isLoading && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {formattedData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ maxWidth: "100%", overflow: "scroll", mt: 4 }}>
            <CustomizedTab
              tabs={tabs}
              currentTab={currentTab}
              scrollable
              table
            />
          </Box>
          {!currentTab.includes("error") && (
            <AgGrid
              ref={gridRef}
              columnDefs={columnDefs}
              height={649}
              rowData={displayedData}
              groupDefaultExpanded={1}
              disableFloatingFilter
            />
          )}
          {currentTab.includes("renderErrorTab")}
        </Box>
      )}
      {errorCount > 0 && currentTab.includes("error") && renderErrorTab}
      {hasCreateError && renderCreateErrors()}
    </>
  );
};

export default ContactImporterPage;
