import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { filteredRow } from "../../../utils/dataTransformer";
import AgGrid from "../../Table/AgGrid";

const LogisticPermission = ({ columnDefs, priviledgeList, resource }) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "DELIVERY_ORDER",
        name: t("logistic.deliveryOrder.index"),
        view: "view",
        create: "create",
        edit: "edit",
        cancel: "cancel",
      },
      {
        functionName: "DELIVERY_TRIP",
        name: t("logistic.deliveryTrip.index"),
        view: "view",
        create: "create",
        edit: "edit",
        cancel: "cancel",
      },
      {
        functionName: "REPORT",
        name: t("report"),
        view: "view",
      },
    ];
    return filteredRow(rows, priviledgeList, resource);
  }, [priviledgeList, resource, t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      isClientSide
    />
  );
};

export default LogisticPermission;
