import { gql } from "graphql-request";

export const DOCUMENT_NEXT_STATUS = gql`
  mutation DocumentNextStatus($documentInput: DocumentInput!) {
    documentNextStatus(documentInput: $documentInput) {
      approval_progress_list {
        approval_template_id
        approval_template {
          id
          step_number
          step_name
          action
          workflow_step_number
          workflow_document_type
          reviewer_document_id_list
          reviewer_list {
            document_id
            first_name
            last_name
            email
            img_url
          }
          is_required_approve
        }
        step_number
        approval_status
        created_date
        created_by_document_id
        approved_by_document_id
        approved_by {
          document_id
          first_name
          last_name
        }
      }
      status
    }
  }
`;

export const DOCUMENT_APPROVE = gql`
  mutation DocumentApprove($documentInput: DocumentInput!) {
    documentApprove(documentInput: $documentInput) {
      document_id
      document_type
      approval_progress_list {
        approval_template_id
        approval_template {
          id
          step_number
          step_name
          action
          workflow_step_number
          workflow_document_type
          reviewer_document_id_list
          reviewer_list {
            document_id
            first_name
            last_name
            email
            img_url
          }
          is_required_approve
        }
        step_number
        approval_status
        created_date
        created_by_document_id
        approved_by_document_id
        approved_by {
          document_id
          first_name
          last_name
        }
      }
    }
  }
`;

export const DOCUMENT_DECLINE = gql`
  mutation DocumentDecline($documentInput: DocumentInput!) {
    documentDecline(documentInput: $documentInput) {
      document_id
      document_type
      approval_progress_list {
        approval_template_id
        approval_template {
          id
          step_number
          step_name
          action
          workflow_step_number
          workflow_document_type
          reviewer_document_id_list
          reviewer_list {
            document_id
            first_name
            last_name
            email
            img_url
          }
          is_required_approve
        }
        step_number
        approval_status
        created_date
        created_by_document_id
        approved_by_document_id
        approved_by {
          document_id
          first_name
          last_name
        }
      }
    }
  }
`;

export const DOCUMENT_CANCEL = gql`
  mutation DocumentCancel($documentInput: DocumentInput!) {
    documentCancel(documentInput: $documentInput) {
      document_id
      document_type
      is_canceled
    }
  }
`;

export const CREATE_PURCHASE_REQUEST = gql`
  mutation CreatePurchaseRequest(
    $createPurchaseRequestInput: CreatePurchaseRequestInput!
  ) {
    createPurchaseRequest(
      createPurchaseRequestInput: $createPurchaseRequestInput
    ) {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      so_document_id_list
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      created_date
      updated_date
      creator_document_id
      created_by {
        id
        document_id
        first_name
        last_name
        email
        phone
        img_url
      }
      issue_date
      due_date
      requester_document_id
      requester_name
      requester_department
      requester_position
      item_list {
        uid
        ref_document_id
        item_document_id
        item_name
        item_description
        uom_id
        qty
        item_remark
        ordered_qty
        uom {
          id
          document_id
          name
        }
      }
      template_remark_id
      remark
      is_partial_po
      is_fully_po
      is_late
      is_canceled
      render_status
      target_status
      workflow_progress_list {
        id
        workflow_step_number
        workflow_document_type
        workflow {
          step_number
          document_type
          status_type
          initial_status
          condition
          target_status
          is_editable
          remark
        }
        reference_document_id
        reference_document_type
        status
        employee_document_id
        changed_date
        payload
        approval_progress_list {
          id
          approval_template_id
          step_number
          workflow_progress_id
          approval_status
          created_by_document_id
          created_date
        }
      }
    }
  }
`;

export const UPDATE_PURCHASE_REQUEST = gql`
  mutation UpdatePurchaseRequest(
    $uniqueInput: PurchaseRequestWhereUniqueInput!
    $updatePurchaseRequestInput: UpdatePurchaseRequestInput!
  ) {
    updatePurchaseRequest(
      uniqueInput: $uniqueInput
      updatePurchaseRequestInput: $updatePurchaseRequestInput
    ) {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      so_document_id_list
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      created_date
      updated_date
      creator_document_id
      created_by {
        id
        document_id
        first_name
        last_name
        email
        phone
        img_url
      }
      issue_date
      due_date
      requester_document_id
      requester_name
      requester_department
      requester_position
      item_list {
        uid
        ref_document_id
        item_document_id
        item_name
        item_description
        uom_id
        qty
        item_remark
        ordered_qty
        uom {
          id
          document_id
          name
        }
      }
      template_remark_id
      remark
      is_partial_po
      is_fully_po
      is_late
      is_canceled
      render_status
      target_status
    }
  }
`;

// export const CANCEL_PURCHASE_REQUEST = gql``;

export const CREATE_PURCHASE_ORDER = gql`
  mutation CreatePurchaseOrder(
    $createPurchaseOrderInput: CreatePurchaseOrderInput!
  ) {
    createPurchaseOrder(createPurchaseOrderInput: $createPurchaseOrderInput) {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      created_date
      issue_date
      expect_date
      due_date
      credit_day
      price_vat_type
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      template_remark_id
      remark
      updated_date
      creator_document_id
      created_by {
        id
        first_name
        img_url
        last_name
        email
        document_id
        department
      }
      delivery_address {
        address_type
        is_same_as_default_address
        address_contact_name
        address_contact_phone
        address
        sub_district
        district
        province
        postal_code
        country
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        uom_id
        uom {
          document_id
          id
          name
        }
        qty
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        pre_vat_amount
        ref_document_id
        withholding_tax
        received_qty
        returned_qty
      }
      vendor {
        email
        fax
        identify_no
        phone
        name
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      target_status
      render_status
    }
  }
`;

export const UPDATE_PURCHASE_ORDER = gql`
  mutation UpdatePurchaseOrder(
    $uniqueInput: PurchaseOrderWhereUniqueInput!
    $updatePurchaseOrderInput: UpdatePurchaseOrderInput!
  ) {
    updatePurchaseOrder(
      uniqueInput: $uniqueInput
      updatePurchaseOrderInput: $updatePurchaseOrderInput
    ) {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      created_date
      issue_date
      expect_date
      due_date
      credit_day
      price_vat_type
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      template_remark_id
      remark
      updated_date
      creator_document_id
      created_by {
        id
        first_name
        img_url
        last_name
        email
        document_id
        department
      }
      delivery_address {
        address_type
        is_same_as_default_address
        address_contact_name
        address_contact_phone
        address
        sub_district
        district
        province
        postal_code
        country
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        uom_id
        uom {
          document_id
          id
          name
        }
        qty
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        pre_vat_amount
        ref_document_id
        withholding_tax
        received_qty
        returned_qty
      }
      vendor {
        email
        fax
        identify_no
        phone
        name
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      target_status
      render_status
    }
  }
`;

// export const CANCEL_PURCHASE_ORDER = gql``;

export const CREATE_PURCHASE_RETURN = gql`
  mutation CreatePurchaseReturn(
    $createPurchaseReturnInput: CreatePurchaseReturnInput!
  ) {
    createPurchaseReturn(
      createPurchaseReturnInput: $createPurchaseReturnInput
    ) {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      contact_document_id
      creator_document_id
      contact {
        document_id
        identity_no
        contact_type_1
        contact_type_2
        contact_name_1
        contact_name_2
      }
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      created_by {
        id
        first_name
        img_url
        last_name
        email
        document_id
        department
      }
      created_date
      issue_date
      delivery_date
      credit_day
      reason_to_return
      price_vat_type
      pre_vat_amount
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      template_remark_id
      remark
      updated_date
      external_debit_note_id
      vendor {
        name
        identify_no
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        uom_id
        uom {
          document_id
          id
          name
        }
        qty
        qty_return
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        pre_vat_amount
        ref_document_id
        withholding_tax
      }
      render_status
      target_status
      is_canceled
    }
  }
`;

export const UPDATE_PURCHASE_RETURN = gql`
  mutation UpdatePurchaseReturn(
    $uniqueInput: PurchaseReturnWhereUniqueInput!
    $updatePurchaseReturnInput: UpdatePurchaseReturnInput!
  ) {
    updatePurchaseReturn(
      uniqueInput: $uniqueInput
      updatePurchaseReturnInput: $updatePurchaseReturnInput
    ) {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      contact_document_id
      creator_document_id
      contact {
        document_id
        identity_no
        contact_type_1
        contact_type_2
        contact_name_1
        contact_name_2
      }
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      created_by {
        id
        first_name
        img_url
        last_name
        email
        document_id
        department
      }
      created_date
      issue_date
      delivery_date
      credit_day
      reason_to_return
      price_vat_type
      pre_vat_amount
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      template_remark_id
      remark
      updated_date
      external_debit_note_id
      vendor {
        name
        identify_no
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        uom_id
        uom {
          document_id
          id
          name
        }
        qty
        qty_return
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        pre_vat_amount
        ref_document_id
        withholding_tax
      }
      is_canceled
      target_status
      render_status
    }
  }
`;

// export const CANCEL_PURCHASE_RETURN = gql``;
