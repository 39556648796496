import { useState, useRef } from "react";
import { Grid, Box, Typography, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import SettingService from "../../../services/Setting";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedChips from "../../../components/Custom/CustomizedChips";
import ImporterDropzoneUI from "../../../components/UI/ImporterDropzoneUI";
import ModalUI from "../../../components/UI/ModalUI";
import AgGrid from "../../../components/Table/AgGrid";

const keys = ["uom_conversion_list"];

const requiredKeys = ["document_id", "name", "uom_conversion_list"];

const UomGroupImporterPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const gridRef = useRef();
  const [formattedData, setFormattedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [createErrors, setCreateErrors] = useState([]);

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("setting.inventory.uom.groupID"),
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      width: 200,
    },
    {
      field: "name",
      headerName: t("setting.inventory.uom.groupName"),
      filter: "agTextColumnFilter",
    },
    {
      field: "description",
      headerName: t("description"),
      filter: "agTextColumnFilter",
    },
    {
      field: "base_uom_document_id",
      headerName: t("setting.inventory.uom.baseUomID"),
      filter: "agTextColumnFilter",
    },
    {
      field: "convertable_uom_list",
      headerName: t("setting.inventory.uom.convertableUomList"),
      filter: false,
      cellRenderer: (params) => {
        return params.value.map((id, index) => (
          <Box sx={{ display: "inline-block", mr: 1 }} key={`${id}-${index}`}>
            <CustomizedChips value={id} />
          </Box>
        ));
      },
      flex: 1,
    },
  ];

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.warehouseSetting"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.uom.group"),
      to: "/setting/inventory/uomgroup",
    },
    {
      name: "นำเข้ากลุ่มของหน่วย",
    },
  ];

  const formatUomGroupdata = (data) => {
    const UomConvertableDocumentIdList = (data) => {
      if (data.length === 0) {
        return [];
      }
      return data
        .map((item) => item.target_uom_document_id)
        .filter((id) => id !== null);
    };
    const formattedUomConversionList = (data) => {
      if (data.length === 0) {
        return undefined;
      }
      return data.map((item) => ({
        target_uom_document_id: item.target_uom_document_id?.toString().trim(),
        base_uom_rate: !isNaN(parseFloat(item.base_uom_rate))
          ? parseFloat(item.base_uom_rate)
          : undefined,
        target_uom_rate: !isNaN(parseFloat(item.target_uom_rate))
          ? parseFloat(item.target_uom_rate)
          : undefined,
      }));
    };
    try {
      const formattedUomGroupData = data.map((uomGroup) => {
        return {
          ...uomGroup,
          document_id: uomGroup.document_id?.toString().trim(),
          name: uomGroup.name?.toString().trim(),
          description: uomGroup.description ?? undefined,
          base_uom_document_id: uomGroup.base_uom_document_id
            ?.toString()
            .trim(),
          convertable_uom_list: UomConvertableDocumentIdList(
            uomGroup.uom_conversion_list
          ),
          uom_conversion_list: formattedUomConversionList(
            uomGroup.uom_conversion_list
          ),
        };
      });
      return formattedUomGroupData;
    } catch (err) {
      setCreateErrors([
        {
          type: "template",
        },
      ]);
      setHasCreateError(true);
      return [];
    }
  };

  const importFormattedDocuments = async () => {
    setCreateErrors([]);
    if (formattedData.length === 0) {
      return;
    }
    const formattedDataSnapshot = [...formattedData];
    const requiredFieldErrors = [];
    for (let i = 0; i < formattedDataSnapshot.length; i++) {
      for (let j = 0; j < requiredKeys.length; j++) {
        if (Array.isArray(formattedDataSnapshot[i][requiredKeys[j]])) {
          let hasError;
          for (
            let k = 0;
            k < formattedDataSnapshot[i][requiredKeys[j]].length;
            k++
          ) {
            if (
              Object.values(formattedDataSnapshot[i][requiredKeys[j]][k]).some(
                (item) => item === null || typeof item === "undefined"
              )
            ) {
              hasError = true;
              break;
            }
          }
          if (hasError) {
            requiredFieldErrors.push({
              document_id: formattedDataSnapshot[i].document_id,
              type: "required",
            });
            formattedDataSnapshot.splice(i, 1);
            i--;
            break;
          }
        }
        if (!formattedDataSnapshot[i][requiredKeys[j]]) {
          requiredFieldErrors.push({
            document_id: formattedDataSnapshot[i].document_id,
            type: "required",
          });
          formattedDataSnapshot.splice(i, 1);
          i--;
          break;
        }
      }
    }
    if (requiredFieldErrors.length > 0) {
      setCreateErrors(requiredFieldErrors);
      setHasCreateError(true);
    } else {
      try {
        const data = await SettingService.postCreateUOMGroups({
          createManyInput: formattedDataSnapshot,
        });
        if (data.errors.length === 0) {
          enqueueSnackbar("นำเข้ากลุ่มของหน่วยสำเร็จ", {
            variant: "success",
          });
          navigate("/setting/inventory/uomgroup");
        } else {
          setCreateErrors((prevErrors) => [...prevErrors, ...data.errors]);
          setHasCreateError(true);
          throw new Error();
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar("ไม่สามารถนำเข้ากลุ่มของหน่วย", {
          variant: "error",
        });
      }
    }
  };

  const renderCreateErrors = createErrors.map((error, index) => {
    if (error.type === "template") {
      return (
        <Typography key={index} variant="body1" color="error">
          Template ไม่ถูกต้อง กรุณาอัปโหลดใหม่
        </Typography>
      );
    }
    let errorMessage;

    if (error.type === "required") {
      errorMessage = ", โปรดใส่ Required Field ให้ครบ";
    } else if (error.errorMessages?.includes("No uom record found.")) {
      errorMessage = ", ไม่พบรหัสของพน่วย";
    } else {
      errorMessage = " เนื่องจากมีอยู่แล้วในระบบ";
    }

    return (
      <Typography
        key={error.document_id || index}
        sx={{ mb: 2 }}
        variant="body2"
        color="error"
      >
        {`ไม่สามารถสร้าง ${error.document_id}${errorMessage}`}
      </Typography>
    );
  });

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้ากลุ่มของหน่วย"}</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <ImporterDropzoneUI
            keys={keys}
            setImportedDoc={setFormattedData}
            dataFormatter={formatUomGroupdata}
            setLoading={setIsLoading}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <CustomizedButton
          title="นำเข้า"
          variant="contained"
          onClick={importFormattedDocuments}
        />
      </Box>
      {isLoading && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {formattedData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <AgGrid
            ref={gridRef}
            columnDefs={columnDefs}
            height={649}
            rowData={formattedData}
            groupDefaultExpanded={1}
          />
        </Box>
      )}
      <ModalUI
        open={hasCreateError}
        handleClose={() => setHasCreateError(false)}
        fullWidth
        maxWidth="xs"
      >
        {renderCreateErrors}
      </ModalUI>
    </>
  );
};

export default UomGroupImporterPage;
