import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import EmployeeTab from "./EmployeeTab";
import PermissionTab from "./PermissionTab";
import { useSelector, useDispatch } from "react-redux";
import {
  mapObjectToEnum,
  mapRolesToObject,
} from "../../../utils/dataTransformer";
import {
  postNewRole,
  getRole,
  setPriviledgeList,
  updateRole,
  deleteRole,
} from "../../../features/User/Rbac/rbac-action";
import { useForm, useFieldArray } from "react-hook-form";
import { permissionValidator } from "./validator";
import { rbacActions } from "../../../features/User/Rbac/rbac-slice";
import { useSnackbar } from "notistack";

const RbacContainer = ({ viewOnly }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { roleId } = useParams();
  const step = searchParams.get("step");
  const { role } = useSelector((state) => state.rbac);
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: role,
    resolver: yupResolver(permissionValidator),
  });

  const { fields, append, remove, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "employee_list", // unique name for your Field Array
  });

  // const admin = user?.role_list?.some((role) =>
  //   role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  // );

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.rbac.index"),
      to: "/user/rbac",
    },
    {
      name: viewOnly ? role.name : t("user.rbac.addNewRole"),
    },
  ];

  const tabs = [
    {
      label: t("user.rbac.index"),
      path: `${pathname}?step=permission&subtab=inventory`,
    },
    {
      label: t("user.rbac.employee"),
      path: `${pathname}?step=employee`,
    },
  ];

  const currentTab =
    pathname +
    (step
      ? `?step=${step}${step === "permission" ? "&subtab=inventory" : ""}`
      : "");

  const onRoleSubmit = (data, type) => {
    if (type !== "triggerRevalidate") {
      const serializedData = Object.assign({}, data);
      delete serializedData.name;
      delete serializedData.description;
      delete serializedData.employee_list;
      delete serializedData.document_id;
      delete serializedData.permission_list;
      const permission_list = mapObjectToEnum(serializedData);
      const payload = {
        name: data.name,
        description: data.description,
        permission_list: permission_list,
        employee_list: data.employee_list.map(
          (employee) => employee.document_id
        ),
      };

      if (viewOnly) {
        //case : update existing role
        const updateRolePayload = {
          document_id: data.document_id,
          ...payload,
        };

        if (type === "updateEmployee") {
          //update employee before delete role
          dispatch(
            updateRole(
              { document_id: data.document_id },
              updateRolePayload,
              enqueueSnackbar
            )
          );
        } else {
          //normal update role details
          dispatch(
            updateRole(
              { document_id: data.document_id },
              updateRolePayload,
              enqueueSnackbar,
              navigate
            )
          );
        }
      } else {
        //case : create new role
        dispatch(postNewRole("role", payload, navigate, enqueueSnackbar));
      }
    }
  };

  const onRoleDelete = (deletedRoleId) => {
    dispatch(
      deleteRole({ document_id: deletedRoleId }, navigate, enqueueSnackbar)
    );
  };

  useEffect(() => {
    //get data of selected role
    if (roleId) {
      const getRolePayload = {
        document_id: roleId,
      };
      dispatch(getRole(getRolePayload));
    }
  }, [dispatch, roleId]);

  useEffect(() => {
    if (role) {
      //replace default values of form hook with new value from redux
      Object.entries(role).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [dispatch, role, setValue]);

  useEffect(() => {
    //filter row and show permission in the table

    if (roleId) {
      const priviledgeListPayload = mapRolesToObject([role], role.name);
      dispatch(setPriviledgeList(priviledgeListPayload));
    }
  }, [dispatch, role, roleId]);

  useEffect(() => {
    //clear redux state
    return () => dispatch(rbacActions.resetRole());
  }, [dispatch]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      <form onSubmit={handleSubmit(onRoleSubmit)}>
        {step === "permission" ? (
          <PermissionTab
            control={control}
            errors={errors}
            trigger={trigger}
            viewOnly={viewOnly}
            fields={fields}
            onRoleDelete={onRoleDelete}
            replace={replace}
            onTriggerRevalidate={handleSubmit((data) =>
              onRoleSubmit(data, "triggerRevalidate")
            )}
          />
        ) : (
          <EmployeeTab
            viewOnly={viewOnly}
            getValues={getValues}
            fields={fields}
            append={append}
            replace={replace}
            remove={remove}
            onEmployeeUpdate={handleSubmit((data) =>
              onRoleSubmit(data, "updateEmployee")
            )}
          />
        )}
      </form>
    </>
  );
};

export default RbacContainer;
