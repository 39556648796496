import { Box, DialogContentText } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schema as contactSchema,
  validation as contactValidation,
} from "../../../components/Form/Contact/schema";
import {
  schema as customerDescSchema,
  validation as customerDescValidation,
} from "../../../components/Form/Sales/CustomerDescription/schema";
import SalesHeaderForm from "../../../components/Form/Sales/Header/index";
import {
  confirmationValidation,
  saleOrderSchema,
  saleOrderValidation,
} from "../../../components/Form/Sales/Header/schema";
// import InvoiceTab from "./InvoiceTab";
import PurchaseRequestTab from "./PurchaseRequestTab";
import ManufactureOrderTab from "./ManufactureOrderTab";
import DeliveryOrderTab from "./DeliveryOrderTab";
import ReturnTab from "./ReturnTab";
import moment from "moment";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { useApproval } from "../../../hooks/use-approval";
import { useDispatch, useSelector } from "react-redux";
import {
  approveSalesOrder,
  createNewSalesOrder,
  declineSalesOrder,
  getSalesOrderById,
  updateSalesOrder,
} from "../../../features/Sales/SalesOrder/sales-order-actions";
import {
  salesPayload,
  setSalesValueFormatter,
} from "../../../utils/salesPayloadFormatter";
import { salesOrderActions } from "../../../features/Sales/SalesOrder/sales-order-slice";
import {
  salesPrimaryButtonHandler,
  salesSecondaryButtonHandler,
} from "../../../utils/salesButtonHandler";
import { validationItemList } from "../../../components/Form/Sales/AddItemListForm/schema";
import ModalUI from "../../../components/UI/ModalUI";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import GoodIssueTab from "./GoodIssueTab";

const defaultValues = {
  render_status: null,
  target_status: null,
  price_vat_type: "excluded_vat",
  contact_document_id: "",
  creator_document_id: "",
  created_by: {},
  item_list: [],
  shipping_cost: 0,
  additional_discount: 0,
  additional_discount_type: "baht",
  summary: {},
  attachment_list: [],
  template_remark_id: "",
  remark: "",
  is_late: false,
};

const OrderContainer = () => {
  const [viewOnly, setViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [primaryButtonTitle, setPrimaryButtonTitle] = useState("ส่ง");
  const [secondaryButtonTitle, setSecondaryButtonTitle] =
    useState("บันทึกร่าง");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { contactSnapshot } = useSelector((state) => state.contact);
  const { createPermission, editPermission } = usePermission();
  const {
    salesOrder,
    isHaveApproval,
    isHaveApprovalPermission,
    approvalList,
    isLoading,
  } = useSelector((state) => state.salesOrder);
  const { user } = useAuth();
  const { refreshApprovals } = useApproval();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const headerRef = useRef();
  const customerDescRef = useRef();
  const itemListRef = useRef();
  const gridRef = useRef();
  const {
    control,
    setValue,
    getValues,
    trigger: validateItemList,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationItemList),
  });

  const {
    control: confirmationControl,
    getValues: getConfirmationValues,
    trigger: validateConfirmation,
    formState: { errors: confirmationErrors },
  } = useForm({
    defaultValues: {
      external_ref_id_confirmation: "",
    },
    resolver: yupResolver(confirmationValidation),
  });

  const [currentTab, setCurrentTab] = useState(pathname);
  const [openSalesInvoiceConfirmation, setOpenSalesInvoiceConfirmation] =
    useState(false);

  const watchStatus = useWatch({ control, name: "render_status" });
  const targetStatus = useWatch({ control, name: "target_status" });

  const customerDescUseForm = useForm({
    defaultValues: { ...customerDescSchema },
    resolver: yupResolver(customerDescValidation),
  });

  const {
    trigger: validateCustomerDescription,
    setValue: setCustomerDescValue,
    getValues: getCustomerDescValues,
    getFieldState: getCustomerDescFieldState,
  } = customerDescUseForm;

  const contactUseForm = useForm({
    defaultValues: { ...contactSchema },
    resolver: yupResolver(contactValidation),
  });

  const {
    control: headerControl,
    setValue: setHeaderValue,
    getValues: getHeaderValues,
    trigger: validateHeader,
    formState: { errors: headerErrors },
    getFieldState: getHeaderFieldState,
  } = useForm({
    defaultValues: { ...saleOrderSchema },
    resolver: yupResolver(saleOrderValidation),
  });

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.order.index"),
      to: "/sales/order",
    },
    {
      name: id ?? `สร้าง${t("sales.order.index")}`,
    },
  ];

  const tabs = [
    {
      label: t("sales.documentInfo"),
      path: `${pathname}`,
    },
    // {
    //   label: t("sales.invoice.index"),
    //   path: `${pathname}?tab=invoice`,
    // },
    {
      label: t("purchase.request.index"),
      path: `${pathname}?tab=purchase-request`,
    },
    {
      label: t("manufacture.order.index"),
      path: `${pathname}?tab=manufacture-order`,
    },
    {
      label: t("logistic.deliveryOrder.index"),
      path: `${pathname}?tab=delivery-order`,
    },
    {
      label: "ใบ" + t("inventory.issue.index"),
      path: `${pathname}?tab=goods-issue`,
    },
    {
      label: t("sales.return.index"),
      path: `${pathname}?tab=return`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      // case "invoice":
      //   setCurrentTab(pathname + "?tab=invoice");
      //   break;
      case "purchase-request":
        setCurrentTab(pathname + "?tab=purchase-request");
        break;
      case "manufacture-order":
        setCurrentTab(pathname + "?tab=manufacture-order");
        break;
      case "delivery-order":
        setCurrentTab(pathname + "?tab=delivery-order");
        break;
      case "goods-issue":
        setCurrentTab(pathname + "?tab=goods-issue");
        break;
      case "return":
        setCurrentTab(pathname + "?tab=return");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const status = watchStatus === "expired" ? targetStatus : watchStatus;

  useEffect(() => {
    switch (status) {
      case "PENDING":
      case "waitApprove":
      case "APPROVED":
      case "DECLINED":
      case "approved":
      case "finished":
      case "cancelled":
      case "expired":
        setViewOnly(true);
        break;
      default:
        setViewOnly(false);
    }
  }, [status]);

  useEffect(() => {
    if (state) {
      dispatch(salesOrderActions.loadedSalesOrder(state));
    } else if (id) {
      dispatch(
        getSalesOrderById(
          {
            document_id: id,
          },
          user,
          enqueueSnackbar
        )
      );
    }
    return () => {
      dispatch(salesOrderActions.resetSalesOrder());
    };
  }, [dispatch, enqueueSnackbar, id, state, user]);

  useEffect(() => {
    if (state) {
      setSalesValueFormatter(
        saleOrderSchema,
        customerDescSchema,
        defaultValues,
        null,
        setHeaderValue,
        setCustomerDescValue,
        setValue,
        null,
        state,
        true,
        "sales_order"
      );
    } else if (id) {
      setSalesValueFormatter(
        saleOrderSchema,
        customerDescSchema,
        defaultValues,
        null,
        setHeaderValue,
        setCustomerDescValue,
        setValue,
        null,
        salesOrder,
        false,
        "sales_order"
      );
    }
  }, [salesOrder, setValue, setHeaderValue, id, state, setCustomerDescValue]);

  const submitContactForm = (data) => {
    setCustomerDescValue("contact", data);
  };

  const submitForm = async (isEdit) => {
    const customerDescIsValid = await validateCustomerDescription();
    const headerIsValid = await validateHeader();
    const itemListIsValid = await validateItemList();

    const documentIdError = getHeaderFieldState("document_id").error;
    const issueDateError = getHeaderFieldState("issue_date").error;
    const dueDateError = getHeaderFieldState("due_date").error;
    const deliveryDateError = getHeaderFieldState("delivery_date").error;
    const customerError = getCustomerDescFieldState("customer").error;
    const itemListError = getFieldState("item_list").error;

    const errorsCount =
      (documentIdError ? 1 : 0) +
      (issueDateError ? 1 : 0) +
      (dueDateError ? 1 : 0) +
      (customerError ? 1 : 0) +
      (deliveryDateError ? 1 : 0) +
      (itemListError ? 1 : 0);

    if (errorsCount > 1) {
      enqueueSnackbar("กรุณาตรวจสอบข้อมูลให้ครบถ้วน", {
        variant: "error",
      });
    }

    if (itemListError?.length > 0) {
      if (itemListError.some((item) => item.is_active)) {
        enqueueSnackbar("กรุณาระบุสินค้าใหม่ เนื่องจากมีสินค้าที่หยุดใช้งาน", {
          variant: "error",
        });
      }
      if (itemListError.some((item) => item.price_per_unit)) {
        enqueueSnackbar("กรุณากรอกจำนวนเงินที่มากกว่า 0", {
          variant: "error",
        });
      }
    } else if (itemListError?.message) {
      enqueueSnackbar(itemListError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(documentIdError)) {
      enqueueSnackbar(documentIdError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(issueDateError)) {
      enqueueSnackbar(issueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(dueDateError)) {
      enqueueSnackbar(dueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(deliveryDateError)) {
      enqueueSnackbar(deliveryDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(customerError)) {
      enqueueSnackbar("กรุณาเลือกลูกค้า", {
        variant: "error",
      });
    }
    if (!headerIsValid) {
      headerRef.current.scrollIntoView();
      return;
    }
    if (!customerDescIsValid) {
      customerDescRef.current.scrollIntoView();
      return;
    }
    if (!itemListIsValid && itemListError) {
      itemListRef.current.scrollIntoView();
      return;
    }
    if (customerDescIsValid && headerIsValid && itemListIsValid) {
      const { render_status, summary, ...mainValues } = getValues();
      const headerValues = getHeaderValues();
      const { customer, identity_no, ...customerDescValues } =
        getCustomerDescValues();
      const tempValues = {
        updated_date: moment().unix(),
      };
      const transformedEmployeeList = headerValues.employee_list.map(
        ({
          id,
          status,
          jwt_modifier,
          exp,
          iat,
          created_date,
          last_login_date,
          role_list,
          ...employee
        }) => employee
      );
      const { external_ref_id_confirmation } = getConfirmationValues();

      if (render_status === "approved" && !isEdit) {
        const confirmationIsValid = await validateConfirmation();
        if (!confirmationIsValid) {
          return;
        }
        if (external_ref_id_confirmation.trim().length > 0) {
          if (headerValues.external_ref_id.trim().length > 0) {
            const externalToArray = headerValues.external_ref_id
              .trim()
              .split(",");
            const newExternalConfirmation = [
              ...externalToArray,
              external_ref_id_confirmation,
            ];
            const formatNewExternal = newExternalConfirmation.join(", ");
            headerValues.external_ref_id = formatNewExternal;
          } else {
            headerValues.external_ref_id = external_ref_id_confirmation;
          }
        }
      }

      const payload = salesPayload(
        headerValues,
        tempValues,
        mainValues,
        transformedEmployeeList,
        user,
        contactSnapshot,
        summary,
        customerDescValues,
        id,
        "so"
      );

      if (isEdit) {
        const { item_list } = mainValues;
        const formatPayload = {
          ...payload,
          item_list: item_list.map((item) => ({
            uid: item.uid,
            item_document_id: item.item_document_id,
            item_name: item.item_name,
            item_description: item.item_description,
            qty: item.qty,
            bom_description: item.bom_description,
            uom_id: item.uom_id.toString(),
            uom: item.uom,
            qty_uom: item.qty_uom,
            width_length_tolerance_positive: parseFloat(
              item.width_length_tolerance_positive
            ),
            width_length_tolerance_negative: parseFloat(
              item.width_length_tolerance_negative
            ),
            thickness_tolerance_positive: parseFloat(
              item.thickness_tolerance_positive
            ),
            thickness_tolerance_negative: parseFloat(
              item.thickness_tolerance_negative
            ),
            item_remark: item.item_remark,
            price_per_unit: parseFloat(item.price_per_unit),
            discount_amount: parseFloat(item.discount_amount),
            vat_type: item.vat_type,
            qty_invoiced: item.qty_invoiced,
            qty_returned: item.qty_returned,
            qty_shipped: item.qty_shipped,
            qty_to_ship: item.qty - item.qty_shipped,
            withholding_tax:
              item.withholding_tax.type === "ยังไม่ระบุ"
                ? -1
                : item.withholding_tax.type === "ไม่มี"
                ? 0
                : parseFloat(item.withholding_tax.type) / 100,
          })),
        };
        return formatPayload;
      } else {
        return payload;
      }
    }
  };

  const saveDraftHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      if (!id) {
        dispatch(
          createNewSalesOrder(
            payload,
            false,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        delete payload.reference_document_id;
        dispatch(
          updateSalesOrder(
            payload,
            status,
            false,
            salesOrder,
            user,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const sendApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      if (!id) {
        dispatch(
          createNewSalesOrder(
            payload,
            true,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        delete payload.reference_document_id;
        dispatch(
          updateSalesOrder(
            payload,
            status,
            true,
            salesOrder,
            user,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const notApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        declineSalesOrder(
          {
            document_id,
            document_type: "sales_order",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const approveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        approveSalesOrder(
          {
            document_id,
            document_type: "sales_order",
          },
          payload,
          user,
          enqueueSnackbar,
          getValues("item_list"),
          setValue,
          gridRef
        )
      );
      refreshApprovals();
    }
  };

  const createSalesInvoiceHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      delete payload.reference_document_id;
      delete payload.item_list;
      dispatch(
        updateSalesOrder(
          payload,
          status,
          true,
          salesOrder,
          user,
          enqueueSnackbar
        )
      );
      setOpenSalesInvoiceConfirmation(false);
    }
  };

  const editSubmitHandler = async () => {
    const payload = await submitForm(true);
    if (payload) {
      delete payload.reference_document_id;
      dispatch(
        updateSalesOrder(
          payload,
          status,
          false,
          salesOrder,
          user,
          enqueueSnackbar
        )
      );
      setIsEdit(false);
      setViewOnly(true);
    }
  };

  const editCancelHandler = () => {
    navigate("/sales/order");
  };

  const shouldRenderButtons = () => {
    if (isEdit) return true;
    if (isLoading.salesOrder) {
      return false;
    } else {
      if (pathname.includes("add")) {
        return true;
      }
      if (status) {
        switch (status) {
          case "draft":
            // case "notApproved":
            return true;
          case "waitApprove":
            if (isHaveApproval) {
              if (isHaveApprovalPermission) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          default:
            return false;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setPrimaryButtonTitle("บันทึก");
      setSecondaryButtonTitle("ยกเลิก");
    } else {
      switch (status) {
        case "draft":
        case "notApproved":
        case "DECLINED":
          setPrimaryButtonTitle("ส่ง");
          setSecondaryButtonTitle("บันทึกร่าง");
          break;
        case "PENDING":
        case "waitApprove":
          setPrimaryButtonTitle("อนุมัติ");
          setSecondaryButtonTitle("ไม่อนุมัติ");
          break;
        default:
      }
    }
  }, [isEdit, status]);

  const primarySubmitHandler = () => {
    salesPrimaryButtonHandler(
      status,
      isEdit,
      sendApproveHandler,
      approveHandler,
      editSubmitHandler
    );
  };

  const secondarySubmitHandler = async () => {
    salesSecondaryButtonHandler(
      status,
      isEdit,
      saveDraftHandler,
      notApproveHandler,
      editCancelHandler
    );
  };

  const editClickHandler = () => {
    setViewOnly(false);
    setIsEdit(true);
  };

  const renderTab = (tab) => {
    switch (tab) {
      // case "invoice":
      //   return <InvoiceTab />;
      case "purchase-request":
        return <PurchaseRequestTab />;
      case "manufacture-order":
        return <ManufactureOrderTab />;
      case "delivery-order":
        return <DeliveryOrderTab />;
      case "goods-issue":
        return <GoodIssueTab />;
      case "return":
        return <ReturnTab />;
      default:
        return (
          <>
            <SalesHeaderForm
              ref={headerRef}
              gridRef={gridRef}
              control={headerControl}
              errors={headerErrors}
              getValues={getHeaderValues}
              setValue={setHeaderValue}
              getMainValues={getValues}
              setMainValue={setValue}
              mainFormControl={control}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              documentType="sales_order"
              editClickHandler={editClickHandler}
              currentState={state?.id ?? salesOrder}
              isEdit={isEdit}
              approvalList={approvalList}
              setOpenSalesInvoiceConfirmation={setOpenSalesInvoiceConfirmation}
              setIsEdit={setIsEdit}
            />
            <DocumentInfoTab
              itemListRef={itemListRef}
              gridRef={gridRef}
              ref={customerDescRef}
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              submitCustomerDesc={submitContactForm}
              customerDescUseForm={customerDescUseForm}
              contactUseForm={contactUseForm}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              setHeaderValue={setHeaderValue}
              getHeaderValues={getHeaderValues}
            />
            <ModalUI
              title="บันทึกใบแจ้งหนี้"
              open={openSalesInvoiceConfirmation}
              handleClose={() => setOpenSalesInvoiceConfirmation(false)}
              maxWidth="sm"
            >
              <DialogContentText sx={{ mb: 2 }}>
                หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
              </DialogContentText>
              <Controller
                name="external_ref_id_confirmation"
                control={confirmationControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    label={t("sales.externalRefDocumentId")}
                    error={Boolean(
                      confirmationErrors.external_ref_id_confirmation
                    )}
                    helperText={
                      confirmationErrors.external_ref_id_confirmation?.message
                    }
                    required
                    {...field}
                  />
                )}
              />
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: ".5rem",
                }}
              >
                <CustomizedButton
                  title="ยกเลิก"
                  variant="outlined"
                  onClick={() => setOpenSalesInvoiceConfirmation(false)}
                />
                <CustomizedButton
                  title="ยืนยัน"
                  variant="contained"
                  onClick={createSalesInvoiceHandler}
                />
              </Box>
            </ModalUI>
            {shouldRenderButtons() &&
              ((createPermission && !id) || (editPermission && id)) && (
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ mr: 1 }}>
                    <CustomizedButton
                      title={secondaryButtonTitle}
                      variant="outlined"
                      type="reset"
                      onClick={secondarySubmitHandler}
                    />
                  </Box>
                  <CustomizedButton
                    title={primaryButtonTitle}
                    variant="contained"
                    onClick={primarySubmitHandler}
                  />
                </Box>
              )}
          </>
        );
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </>
  );
};

export default OrderContainer;
