import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    isLoading: {
      allCategory: false,
      selectedCategory: false,
    },
    allCategory: [],
    selectedCategory: {},
    title: {
      header: "",
      name: "",
    },
    modal: false,
    error: null,
  },
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload] = false;
      state.error = action.payload;
    },
    loadedAllCategory(state, action) {
      state.allCategory = action.payload;
      state.isLoading.allCategory = false;
    },
    loadedSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    changeTitle(state, action) {
      state.title.header = action.payload.header;
      state.title.name = action.payload.name;
    },
    openModal(state, action) {
      state.modal = true;
    },
    closeModal(state, action) {
      state.modal = false;
    },
    resetTitle(state) {
      state.title = {
        header: "",
        name: "",
      };
    },
    resetAllCategory(state, action) {
      state.allCategory = [];
    },
    resetSelectedCategory(state, action) {
      state.selectedCategory = {};
    },
  },
});

export const categoryActions = categorySlice.actions;

export default categorySlice.reducer;
