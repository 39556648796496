import { Box, DialogContentText } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";

const CancelConfirmation = ({
  title,
  message,
  openConfirmation,
  confirmationAction,
  closeConfirmationHandler,
}) => {
  return (
    <ModalUI
      title={title}
      open={openConfirmation}
      handleClose={closeConfirmationHandler}
      maxWidth="sm"
    >
      <DialogContentText>{message}</DialogContentText>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={confirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default CancelConfirmation;
