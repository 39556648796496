import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { filterUom } from "../../../utils/dataTransformer";

export default function Delivery({
  control,
  errors,
  setValue,
  getValues,
  viewOnly,
  isInventoryPage,
}) {
  const { t } = useTranslation();

  const { allUoms } = useSelector((state) => state.uom);

  const lengthOption = filterUom(allUoms, [
    "ไมครอน",
    "มิลลิเมตร",
    "เซนติเมตร",
    "เมตร",
    "หลา",
    "นิ้ว",
    "ฟุต",
    "กรัม",
  ]);

  const weightOption = filterUom(allUoms, ["กรัม", "กิโลกรัม"]);
  // const volumeOption = filterUom(allUoms, ["มิลลิลิตร", "ลิตร"]);

  const dimensionList = [
    {
      testId: "item-package-width-text-field",
      label: t("inventory.items.width"),
      name: "package_width",
      uom: "package_width_uom",
      option: lengthOption,
      error: errors.package_width,
      uomError: errors.package_width_uom,
      disabledUom: true,
    },
    {
      testId: "item-package-length-text-field",
      label: t("inventory.items.length"),
      name: "package_length",
      uom: "package_length_uom",
      option: lengthOption,
      error: errors.package_length,
      uomError: errors.package_length_uom,
      disabledUom: true,
    },
    {
      testId: "item-package-height-text-field",
      label: t("inventory.items.height"),
      name: "package_height",
      uom: "package_height_uom",
      option: lengthOption,
      error: errors.package_height,
      uomError: errors.package_height_uom,
      disabledUom: true,
    },
    {
      testId: "item-package-weight-text-field",
      label: t("inventory.items.weight"),
      name: "package_weight",
      uom: "package_weight_uom",
      option: weightOption,
      error: errors.package_weight,
      uomError: errors.package_weight_uom,
      disabledUom: true,
    },
  ];

  return (
    <Box>
      <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.deliveryDimension")}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          {dimensionList.map((dimension, index) => (
            <Grid container spacing={2} key={index} mb={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={dimension.name}
                  render={({ field }) => (
                    <CustomizedTextField
                      {...field}
                      label={dimension.label}
                      error={Boolean(dimension.error)}
                      helperText={dimension.error?.message}
                      disabled={!isInventoryPage || viewOnly}
                      testId={dimension.testId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`${dimension.uom}.name`}
                  control={control}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      label={t("inventory.unit")}
                      options={dimension.option}
                      onChange={(_, newValue) =>
                        field.onChange(newValue ? newValue.label : null)
                      }
                      error={Boolean(dimension.uomError)}
                      helperText={dimension.uomError?.message}
                      disabled={!isInventoryPage || dimension.disabledUom}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
