import { Avatar, Box, IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  formatDateTime,
  unixToDateTimeWithFormat,
} from "../../../../utils/date-converter";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedNumberTextField from "../../../Custom/CustomizedNumberTextField";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { formatNumber } from "../../../../utils/dataTransformer";

const TraceEntryList = ({ nestIndex, control, disabled, errors, setValue }) => {
  const { fields, remove } = useFieldArray({
    control,
    name: `goods_adjustment_list[${nestIndex}].stock_entry_list.trace_entry_list`,
    keyName: "genId",
  });

  const renderBoxMaxContent = (content) => (
    <Box sx={{ width: "max-content" }}>{content}</Box>
  );

  const PreviousQuantity = ({ control, nestIndex, index }) => {
    const watchPreviousQuantity = useWatch({
      control,
      name: `goods_adjustment_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].previous_quantity`,
    });

    return (
      <TableCell align="center">
        {formatNumber(watchPreviousQuantity)}
      </TableCell>
    );
  };

  return (
    <>
      {fields.map((trace, index) => (
        <TableRow key={trace.genId}>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              trace.posted_date
                ? typeof trace.posted_date === "string"
                  ? formatDateTime(trace.posted_date)
                  : unixToDateTimeWithFormat(trace.posted_date)
                : null
            )}
          </TableCell>
          <TableCell align="center"></TableCell>
          <PreviousQuantity
            control={control}
            nestIndex={nestIndex}
            index={index}
          />
          <TableCell align="center">
            {disabled ? (
              formatNumber(trace.posted_quantity)
            ) : (
              <Box width={125}>
                <CustomizedNumberTextField
                  control={control}
                  name={`goods_adjustment_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].posted_quantity`}
                  error={
                    errors &&
                    errors?.goods_adjustment_list &&
                    errors?.goods_adjustment_list[nestIndex]?.stock_entry_list
                      ?.trace_entry_list[index]?.posted_quantity
                      ? Boolean(
                          errors?.goods_adjustment_list[nestIndex]
                            ?.stock_entry_list?.trace_entry_list[index]
                            ?.posted_quantity
                        )
                      : false
                  }
                  helperText={
                    errors &&
                    errors?.goods_adjustment_list &&
                    errors?.goods_adjustment_list[nestIndex]?.stock_entry_list
                      ?.trace_entry_list[index]?.posted_quantity
                      ? errors?.goods_adjustment_list[nestIndex]
                          ?.stock_entry_list?.trace_entry_list[index]
                          ?.posted_quantity.message
                      : false
                  }
                  allowNegative={true}
                />
              </Box>
            )}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.uom.name)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.serial_number)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              disabled || trace.location_list.length === 1 ? (
                trace.source_bin_location_document_id
              ) : (
                <Box width={220}>
                  <Controller
                    key="ga-source_bin_location_document_id-field"
                    name={`goods_adjustment_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].source_bin_location_document_id`}
                    control={control}
                    render={({ field }) => (
                      <CustomizedComboBox
                        {...field}
                        options={trace.location_list || []}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? newValue.value : "");
                          setValue(
                            `goods_adjustment_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].previous_quantity`,
                            newValue.current_quantity
                          );
                        }}
                        error={
                          errors &&
                          errors?.goods_adjustment_list &&
                          errors?.goods_adjustment_list[nestIndex]
                            ?.stock_entry_list?.trace_entry_list[index]
                            ?.source_bin_location_document_id &&
                          Boolean(
                            errors?.goods_adjustment_list[nestIndex]
                              ?.stock_entry_list?.trace_entry_list[index]
                              ?.source_bin_location_document_id
                          )
                        }
                        helperText={
                          errors &&
                          errors?.goods_adjustment_list &&
                          errors?.goods_adjustment_list[nestIndex]
                            ?.stock_entry_list?.trace_entry_list[index]
                            ?.source_bin_location_document_id &&
                          errors?.goods_adjustment_list[nestIndex]
                            ?.stock_entry_list?.trace_entry_list[index]
                            ?.source_bin_location_document_id.message
                        }
                        // title={t("sales.paymentReceipt.eWalletType")}
                        // readOnly={viewOnly}
                        required
                      />
                    )}
                  />
                </Box>
              )
            )}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              trace.created_by?.img_url ? (
                <Avatar alt="img_url" src={trace.created_by?.img_url} />
              ) : (
                <CustomizedLetterAvatar
                  name={
                    trace.created_by?.first_name +
                    " " +
                    trace.created_by?.last_name
                  }
                />
              )
            )}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.batch_number)}
          </TableCell>
          <TableCell align="center">
            {disabled ? null : (
              <IconButton onClick={() => remove(index)} disabled={false}>
                <CloseIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TraceEntryList;
