import * as Yup from "yup";

const schema = {
  qty_return: Yup.number()
    .when(
      "qty",
      (qty, schema) =>
        qty &&
        schema.max(qty, "กรุณาระบุจำนวนคืนที่น้อยกว่าหรือเท่ากับจำนวนสั่งซื้อ")
    )
    .required("กรุณาระบุจำนวนคืน"),
  is_active: Yup.bool().oneOf(
    [true],
    "กรุณาระบุสินค้าใหม่ เนื่องจากมีสินค้าที่หยุดใช้งาน"
  ),
};

export const validationReturnItemList = Yup.object().shape({
  item_list: Yup.array()
    .of(Yup.object().shape(schema))
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
  reason_to_return: Yup.string().required("กรุณาระบุเหตุผลการคืนของ"),
});
