import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedChips from "../../Custom/CustomizedChips";
import DropzoneUI from "../../UI/DropzoneUI";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { schema } from "./schema";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";
import moment from "moment";
import { useSelector } from "react-redux";
// import CustomizedComboBox from "../../Custom/CustomizedComboBox";
// import { filterPositionByDepartment } from "../../../utils/dataTransformer";
import { departmentType, positionType } from "../../../utils/userInfo";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ControlledServerSideCreatable from "../../Custom/ControlledSSCreatable";

const UserAccountForm = ({
  control,
  errors,
  setValue,
  trigger,
  viewOnly,
  option,
  onTriggerRevalidate,
  setting,
  getValues,
  addable,
  createPermission,
  editPermission,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const formDisableState = option === "แก้ไข" ? false : viewOnly;
  const { user } = useSelector((state) => state.account);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  // const [position, setPosition] = useState(positionType);
  const [files, setFiles] = useState([]);

  const chipData = [
    `วันที่สร้าง: ${
      user.created_date
        ? moment.unix(user.created_date).format("DD/MM/YYYY")
        : "-"
    }`,
    `วันที่ใช้งานล่าสุด:  ${
      user?.last_login_date
        ? moment.unix(user?.last_login_date).format("DD/MM/YYYY")
        : "-"
    }`,
  ];

  const radioList = [
    { value: "active", label: "ใช้งาน" },
    { value: "inactive", label: "หยุดใช้งาน" },
  ];

  const onButtonClicked = async () => {
    onTriggerRevalidate();
    const validInput = await trigger(Object.keys(schema));
    if (validInput) navigate(`${pathname}?step=permission&subtab=inventory`);
  };

  // const onDepartmentChange = (value) => {
  //   filterPositionByDepartment(value, setPosition);
  // };

  useEffect(() => {
    if (user.img_url) {
      setFiles([user.img_url]);
    }
  }, [user.img_url]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <CustomizedBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
              ข้อมูลทั่วไป
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.first_name)}
                      helperText={errors.first_name?.message}
                      label={t("user.account.firstname")}
                      {...field}
                      disabled={formDisableState}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.last_name)}
                      helperText={errors.last_name?.message}
                      label={t("user.account.lastname")}
                      {...field}
                      disabled={formDisableState}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      label={t("user.account.email")}
                      {...field}
                      disabled={formDisableState}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.phone)}
                      helperText={errors.phone?.message}
                      label={t("user.account.phone")}
                      {...field}
                      disabled={formDisableState}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              จัดการหน้าที่
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {/* <Controller
                  name="department"
                  control={control}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      error={Boolean(errors.department)}
                      helperText={errors.department?.message}
                      label={t("user.account.department")}
                      options={departmentType}
                      onChange={(_, newValue) => {
                        if (newValue !== null) {
                          field.onChange(newValue.label);
                          onDepartmentChange(newValue.value);
                        } else {
                          field.onChange(null);
                          onDepartmentChange(null);
                        }
                        setValue("position", null);
                      }}
                      disabled={formDisableState}
                      required
                    />
                  )}
                /> */}
                <ControlledServerSideCreatable
                  title={t("user.account.department")}
                  control={control}
                  name="department"
                  documentType="employee"
                  defaultOptions={departmentType}
                  error={Boolean(errors.department)}
                  helperText={errors.department?.message}
                  setValue={setValue}
                  viewOnly={formDisableState}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {/* <Controller
                  name="position"
                  control={control}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      error={Boolean(errors.position)}
                      helperText={errors.position?.message}
                      label={t("user.account.position")}
                      options={position}
                      onChange={(_, newValue) => {
                        if (newValue !== null) {
                          field.onChange(newValue.label);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      disabled={formDisableState}
                      required
                    />
                  )}
                /> */}
                <ControlledServerSideCreatable
                  title={t("user.account.position")}
                  control={control}
                  name="position"
                  documentType="employee"
                  defaultOptions={positionType}
                  error={Boolean(errors.position)}
                  helperText={errors.position?.message}
                  setValue={setValue}
                  viewOnly={formDisableState}
                />
              </Grid>
            </Grid>
            {!setting &&
              ((editPermission && id) || (createPermission && !id)) && (
                <>
                  <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                    ตั้งรหัสผ่าน
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Controller
                        name="new_password"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            error={Boolean(errors.new_password)}
                            disabled={formDisableState}
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <InputLabel htmlFor="outlined-adornment-password1">
                              {t("user.account.password")}
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password1"
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    disabled={formDisableState}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t("user.account.password")}
                              {...field}
                            />
                            <FormHelperText
                              id="helper-text-password1"
                              sx={{ whiteSpace: "pre-wrap" }}
                            >
                              {errors.new_password?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Controller
                        name="confirm_new_password"
                        control={control}
                        render={({ field }) => (
                          <CustomizedTextField
                            type="password"
                            fullWidth
                            error={Boolean(errors.confirm_new_password)}
                            helperText={errors.confirm_new_password?.message}
                            label={t("user.account.confirm_password")}
                            disabled={formDisableState}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            {setting && (
              <>
                <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                  เปลี่ยนรหัสผ่าน
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Controller
                          name="old_password"
                          control={control}
                          render={({ field }) => (
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                              disabled={formDisableState}
                            >
                              <InputLabel htmlFor="outlined-adornment-password3">
                                {t("user.account.old_password")}
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password3"
                                type={showOldPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowOldPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showOldPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label={t("user.account.old_password")}
                                {...field}
                              />
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Controller
                          name="new_password"
                          control={control}
                          render={({ field }) => (
                            <FormControl
                              error={Boolean(errors.new_password)}
                              variant="outlined"
                              fullWidth
                              size="small"
                              disabled={formDisableState}
                            >
                              <InputLabel htmlFor="outlined-adornment-password2">
                                {t("user.account.new_password")}
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password2"
                                type={showNewPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowNewPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showNewPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label={t("user.account.new_password")}
                                {...field}
                              />
                              <FormHelperText
                                id="helper-text-password2"
                                sx={{ whiteSpace: "pre-wrap" }}
                              >
                                {errors.new_password?.message}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Controller
                          name="confirm_new_password"
                          control={control}
                          render={({ field }) => (
                            <CustomizedTextField
                              type="password"
                              fullWidth
                              error={Boolean(errors.confirm_new_password)}
                              helperText={errors.confirm_new_password?.message}
                              label={t("user.account.confirm_new_password")}
                              disabled={formDisableState}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <>
              <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                สถานะ
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <CustomizedRadioGroup
                        {...field}
                        radioList={radioList}
                        row
                        disabled={formDisableState || setting}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="remark"
                    control={control}
                    render={({ field }) => (
                      <CustomizedTextField
                        fullWidth
                        error={Boolean(errors.remark)}
                        label={t("user.account.remark")}
                        {...field}
                        disabled={formDisableState || setting}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </>
            {(viewOnly || setting) && (
              <>
                <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
                  ข้อมูลอื่นๆ
                </Typography>
                {chipData.map((chip) => (
                  <CustomizedChips
                    sx={{ mr: 1 }}
                    key={chip}
                    value={chip}
                    color="secondary"
                    variant="outlined"
                    spacing={1}
                  />
                ))}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1.5} xl={1.5} />
          {(user?.img_url || option === "แก้ไข" || setting || addable) && (
            <Grid item xs={12} sm={12} md={12} lg={2.5} xl={2.5}>
              <Typography fontWeight="bold" sx={{ mb: 2, ml: 1 }}>
                รูปภาพ
              </Typography>
              {option !== "แก้ไข" && !setting && !addable ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: 200,
                    p: 2,
                    border: "1px solid #C8C8C8",
                    borderRadius: "5px",
                    objectFit: "contain",
                  }}
                >
                  <img
                    src={user?.img_url}
                    alt="user profile"
                    style={{ height: "100%" }}
                  />
                </Box>
              ) : (
                <DropzoneUI
                  valueToSet="img_url"
                  setValue={setValue}
                  files={files}
                  setFiles={setFiles}
                  filesLimit={1}
                  acceptedFile="image"
                />
              )}
            </Grid>
          )}
        </Grid>
      </CustomizedBox>
      {(!viewOnly || option === "แก้ไข") && (
        <CustomizedButton
          title={"ดำเนินการต่อ"}
          variant="contained"
          onClick={onButtonClicked}
        />
      )}
    </>
  );
};

export default UserAccountForm;
