import { unixToDateWithFormat } from "../../../utils/date-converter";
import {
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import CustomizedStatus from "../../Custom/CustomizedStatus";

export const purchaseRequestColumnDefs = (t) => {
  return [
    {
      field: "document_id",
      headerName: t("sales.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 200,
    },
    {
      field: "created_date",
      headerName: t("sales.createdDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.created_date),
      hide: true,
      sort: "desc",
      suppressColumnsToolPanel: true,
    },
    {
      field: "issue_date",
      headerName: t("sales.issueDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.data.issue_date),
    },
    {
      field: "due_date",
      headerName: t("purchase.request.dueDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.data.due_date),
    },
    {
      field: "requester_name",
      headerName: t("purchase.request.employee"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      floatingFilter: false,
    },
    {
      field: "render_status",
      headerName: t("sales.status"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: [
          "draft",
          "PENDING",
          "DECLINED",
          "approved",
          "cancelled",
          "partiallyOrdered",
          "fullyOrdered",
        ],
      },
      cellRenderer: (params) => (
        <CustomizedStatus status={params.data.render_status} />
      ),
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
    {
      field: "created_by",
      headerName: t("purchase.createdBy"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => (
        <CustomizedAvatar avatars={[params.data.created_by]} />
      ),
      valueGetter: (params) =>
        params.data.created_by.first_name +
        " " +
        params.data.created_by.last_name,
    },
    {
      field: "employee_list",
      headerName: t("sales.employeeList"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <CustomizedAvatar avatars={params.data.employee_list} />
      ),
    },
  ];
};
