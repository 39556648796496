import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Typography, Box, IconButton, Menu, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedTextField from "../../../Custom/CustomizedTextField";

const AttachmentCard = ({
  control,
  errors,
  onRemove,
  index,
  fileName,
  full_name,
  date,
  onUpdate,
  navigateTo,
  viewOnly,
}) => {
  const { t } = useTranslation();
  const [anchorPos, setAnchorPos] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const showActions = Boolean(anchorPos);

  const openActionsHandler = (event) => {
    if (viewOnly) {
      return;
    }
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const startEditingHandler = () => {
    closeActionsHandler();
    setIsEditing(true);
  };

  const finishEditingHandler = () => {
    onUpdate(index);
    setIsEditing(false);
  };

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        <MenuItem onClick={() => onRemove(index)}>
          {t("contact.utility.delete")}
        </MenuItem>
        <MenuItem onClick={startEditingHandler}>
          {t("contact.utility.name_change")}
        </MenuItem>
      </Menu>
      <CustomizedBox
        sx={{ cursor: viewOnly ? "pointer" : "default" }}
        padding="1.5rem 2rem"
        onClick={() => {
          if (navigateTo && viewOnly) {
            window.open(navigateTo, "_blank", "noopener,noreferrer");
          }
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isEditing ? (
              <Controller
                name={`files.${index}.tempName`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.files?.[index]?.tempName)}
                    helperText={errors.files?.[index]?.tempName?.message}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            ) : (
              <Typography variant="body1">{fileName}</Typography>
            )}
          </Box>
          {isEditing && (
            <IconButton
              onClick={finishEditingHandler}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CheckIcon />
            </IconButton>
          )}
          {!viewOnly && !isEditing && (
            <IconButton
              onClick={openActionsHandler}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>

        {full_name && (
          <Typography
            variant="body2"
            sx={{ my: 2, color: (theme) => theme.palette.grey[600] }}
          >
            {full_name}
          </Typography>
        )}
        {date && (
          <Typography
            variant="body2"
            sx={{ my: 2, color: (theme) => theme.palette.grey[600] }}
          >
            วันที่ {date}
          </Typography>
        )}
      </CustomizedBox>
    </>
  );
};

export default AttachmentCard;
