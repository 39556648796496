import React, { useEffect, useState } from "react";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../utils/filterparams";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import {
  formatNumber,
  formatNumberWithValidate,
} from "../../utils/dataTransformer";
import { useTranslation } from "react-i18next";
import { unixToDateWithFormat } from "../../utils/date-converter";
import CustomizedAvatar from "../../components/Custom/CustomizedAvatar";
import Logistic from "../../services/Logistic";
import InventoryService from "../../services/Inventory";

const useSalesOrderColumnDef = (isReport, newReport) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "document_id",
      headerName: t("sales.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 250,
    },
    {
      field: "created_date",
      headerName: t("sales.createdDate"),
      sort: "desc",
      hide: true,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: isReport ? dateComparator : undefined,
      },
      valueFormatter: (params) =>
        isReport
          ? params.data.created_date
          : unixToDateWithFormat(params.data.created_date),
      width: 200,
      suppressColumnsToolPanel: true,
    },
    {
      field: "issue_date",
      headerName: t("sales.issueDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: isReport ? dateComparator : undefined,
      },
      valueFormatter: (params) =>
        isReport
          ? params.data.issue_date
          : unixToDateWithFormat(params.data.issue_date),
      width: 200,
    },
    {
      field: "delivery_date",
      headerName: t("sales.order.deliveryDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: isReport ? dateComparator : undefined,
      },
      valueFormatter: (params) =>
        isReport
          ? params.data.delivery_date
          : unixToDateWithFormat(params.data.delivery_date),
      width: 200,
    },
    {
      field: "render_status",
      headerName: t("sales.status"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: [
          "draft",
          "PENDING",
          "DECLINED",
          "approved",
          "finished",
          "cancelled",
        ],
      },
      cellRenderer: (params) => (
        <CustomizedStatus status={params.data.render_status} />
      ),
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
    {
      field: "contact_document_id",
      headerName: t("reports.contact_document_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "contact_name",
      headerName: t("sales.customer"),
      sortable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      width: 225,
    },
    {
      field: "item_group_level_1_name_list",
      headerName: "หมวดหมู่สินค้า",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          const data = await InventoryService.getAllItemLevel1();
          const itemLevel1 = data.map((warehouse) => warehouse.name);
          params.success(itemLevel1);
        },
      },
      valueFormatter: (params) => {
        if (params.value) {
          return params.value.name;
        } else {
          return "";
        }
      },
    },
    {
      field: "total_amount",
      headerName: t("sales.totalAmountInTable"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      valueFormatter: (params) => formatNumber(params.data.total_amount),
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "created_by",
      headerName: t("sales.createdBy"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => (
        <CustomizedAvatar avatars={[params.data.created_by]} />
      ),
      valueGetter: (params) =>
        params.data.created_by.first_name +
        " " +
        params.data.created_by.last_name,
      sortable: false,
      width: 150,
    },
    {
      field: "employee_list",
      headerName: t("sales.employeeList"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.data.employee_list) {
          return <CustomizedAvatar avatars={params.data.employee_list} />;
        }
      },
      valueGetter: (params) =>
        params.data.employee_list.map(
          (employee) => employee.first_name + " " + employee.last_name
        ),
      sortable: false,
    },

    {
      field: "do_document_id_list",
      headerName: "หมายเลขอ้างอิง DO",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          const loadedOptions = await Logistic.getAllDeliverOrdersIds();
          const formatType = loadedOptions.map((option) => option.document_id);
          params.success(formatType);
        },
      },
    },
    {
      field: "is_late",
      headerName: t("status.expired"),
      sortable: false,
      hide: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["เกินเวลาแล้ว", "ยังไม่เกินเวลา"],
      },
      suppressColumnsToolPanel: true,
    },
  ]);

  useEffect(() => {
    if (isReport) {
      if (newReport)
        setColumnDefs([
          {
            field: "document_id",
            headerName: t("sales.documentId"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
            width: 250,
          },
          {
            field: "issue_date",
            headerName: t("sales.issueDate"),
            filter: "agDateColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("date"),
            },
            width: 200,
            valueFormatter: (params) => unixToDateWithFormat(params.value),
          },
          {
            field: "delivery_date",
            headerName: t("reports.delivery_date"),
            filter: "agDateColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("date"),
            },
            width: 200,
            valueFormatter: (params) => unixToDateWithFormat(params.value),
          },
          {
            field: "render_status",
            headerName: t("sales.status"),
            sortable: false,
            filter: "agSetColumnFilter",
            filterParams: {
              valueFormatter: (params) => {
                return filterStatusValueFormatter(params.value);
              },
              values: [
                "draft",
                "PENDING",
                "DECLINED",
                "approved",
                "finished",
                "cancelled",
              ],
            },

            cellRenderer: (params) => {
              return <CustomizedStatus status={params.value} />;
            },
            cellStyle: {
              display: "flex",
              justifycontent: "center",
              alignItems: "center",
            },
          },
          {
            field: "contact_document_id",
            headerName: t("reports.contact_document_id"),
            hide: true,
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "contact_name",
            headerName: t("sales.customer"),
            enableRowGroup: true,
            pivot: true,
            sortable: false,
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
              suppressAndOrCondition: true,
            },
            width: 225,
          },
          {
            field: "item_group_level_1_name_list",
            headerName: "หมวดหมู่สินค้า",
            filter: "agSetColumnFilter",
            filterParams: {
              values: async (params) => {
                const data = await InventoryService.getAllItemLevel1();
                const itemLevel1 = data.map((warehouse) => warehouse.name);
                params.success(itemLevel1);
              },
            },
            valueFormatter: (params) => {
              if (params.value) {
                return params.value.name;
              } else {
                return "";
              }
            },
          },
          {
            field: "price_vat_type",
            headerName: t("reports.price_vat_type"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
            hide: true,
          },
          {
            field: "item_document_id",
            headerName: t("reports.item_document_id"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "item_name",
            headerName: t("reports.item_name"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "item_bom_description",
            headerName: t("reports.item_bom_description"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "qty",
            headerName: t("reports.qty"),
            filter: false,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "uom",
            headerName: t("reports.uom"),
            filter: false,
          },
          {
            field: "price_per_unit",
            headerName: t("reports.price_per_unit"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "vat_type",
            headerName: t("reports.vat_type"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "pre_vat_amount_exclude_discount",
            headerName: t("reports.pre_vat_amount_exclude_discount"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_discount",
            headerName: t("reports.item_discount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_additional_discount",
            headerName: t("reports.item_additional_discount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "pre_vat_amount_include_discount",
            headerName: t("reports.pre_vat_amount_include_discount"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_vat_exempted_amount",
            headerName: t("reports.item_vat_exempted_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_vat_0_amount",
            headerName: t("reports.item_vat_0_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_vat_7_amount",
            headerName: t("reports.item_vat_7_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_vat_amount",
            headerName: t("reports.item_vat_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_net_amount",
            headerName: t("reports.item_net_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_withholding_tax_type",
            headerName: t("reports.item_withholding_tax_type"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_withholding_tax",
            headerName: t("reports.item_withholding_tax"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_total_amount",
            headerName: t("reports.item_total_amount"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "shipping_cost",
            headerName: t("reports.shipping_cost"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "total_amount",
            headerName: t("reports.total_amount"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "additional_discount",
            headerName: t("reports.additional_discount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "credit_day",
            headerName: t("reports.credit_day"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "qty_uom",
            headerName: t("reports.qty_uom"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_qty_to_ship",
            headerName: t("reports.item_qty_to_ship"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "item_qty_shipped",
            headerName: t("reports.item_qty_shipped"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "item_qty_invoiced",
            headerName: t("reports.item_qty_invoiced"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "item_qty_returned",
            headerName: t("reports.item_qty_returned"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "is_late",
            headerName: t("reports.is_late"),
            hide: true,
            filter: "agSetColumnFilter",
            filterParams: {
              values: ["ยังไม่เกินเวลา", "เกินเวลาแล้ว"],
            },
          },
          {
            field: "reference_document_id",
            headerName: t("reports.reference_document_id"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
            hide: true,
          },
          {
            field: "external_ref_id",
            headerName: t("reports.external_ref_id"),
            hide: true,
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "due_date",
            headerName: t("reports.due_date"),
            filter: "agDateColumnFilter",
            hide: true,
            filterParams: {
              filterOptions: filterParamsOptions("date"),
            },
            width: 200,
            valueFormatter: (params) => unixToDateWithFormat(params.value),
          },
          {
            field: "item_description",
            headerName: t("reports.item_description"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "item_remark",
            headerName: t("reports.item_remark"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "width_length_tolerance_positive",
            headerName: t("reports.width_length_tolerance_positive"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "width_length_tolerance_negative",
            headerName: t("reports.width_length_tolerance_negative"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "thickness_tolerance_positive",
            headerName: t("reports.thickness_tolerance_positive"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "thickness_tolerance_negative",
            headerName: t("reports.thickness_tolerance_negative"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "created_date",
            headerName: t("reports.created_date"),
            filter: "agDateColumnFilter",
            hide: true,
            filterParams: {
              filterOptions: filterParamsOptions("date"),
              comparator: dateComparator,
            },
            width: 200,
            valueFormatter: (params) => unixToDateWithFormat(params.value),
          },
          {
            field: "updated_date",
            headerName: t("reports.updated_date"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => unixToDateWithFormat(params.value),
          },
          {
            field: "created_by",
            headerName: t("reports.created_by"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "employee_list",
            headerName: t("reports.employee_list"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "email",
            headerName: t("reports.email"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "phone",
            headerName: t("reports.phone"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "fax",
            headerName: t("reports.fax"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_address",
            headerName: t("reports.billing_address"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_sub_district",
            headerName: t("reports.billing_sub_district"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_district",
            headerName: t("reports.billing_district"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_province",
            headerName: t("reports.billing_province"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_postal_code",
            headerName: t("reports.billing_postal_code"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_country",
            headerName: t("reports.billing_country"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "is_same_as_default_address",
            headerName: t("reports.is_same_as_default_address"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_address_contact_name",
            headerName: t("reports.delivery_address_contact_name"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_address_contact_phone",
            headerName: t("reports.delivery_address_contact_phone"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_address",
            headerName: t("reports.delivery_address"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_sub_district",
            headerName: t("reports.delivery_sub_district"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_district",
            headerName: t("reports.delivery_district"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_province",
            headerName: t("reports.delivery_province"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_postal_code",
            headerName: t("reports.delivery_postal_code"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_country",
            headerName: t("reports.delivery_country"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
        ]);
      else
        setColumnDefs([
          {
            field: "document_id",
            headerName: t("sales.documentId"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
            width: 250,
          },
          {
            field: "issue_date",
            headerName: t("sales.issueDate"),
            filter: "agDateColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("date"),
              comparator: dateComparator,
            },
            width: 200,
          },
          {
            field: "delivery_date",
            headerName: t("reports.delivery_date"),
            filter: "agDateColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("date"),
              comparator: dateComparator,
            },
            width: 200,
          },
          {
            field: "render_status",
            headerName: t("sales.status"),
            sortable: false,
            filter: "agSetColumnFilter",
            filterParams: {
              values: [
                "ร่าง",
                "รออนุมัติ",
                "ไม่อนุมัติ",
                "อนุมัติแล้ว",
                "เสร็จสิ้น",
                "ยกเลิก",
              ],
            },
            cellRenderer: (params) => {
              return <CustomizedStatus status={params.data.render_status} />;
            },
            cellStyle: {
              display: "flex",
              justifycontent: "center",
              alignItems: "center",
            },
          },
          {
            field: "contact_document_id",
            headerName: t("reports.contact_document_id"),
            hide: true,
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "contact_name",
            headerName: t("sales.customer"),
            enableRowGroup: true,
            pivot: true,
            sortable: false,
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
              suppressAndOrCondition: true,
            },
            width: 225,
          },
          {
            field: "item_group_level_1_name_list",
            headerName: "หมวดหมู่สินค้า",
            filter: "agSetColumnFilter",
            filterParams: {
              values: async (params) => {
                const data = await InventoryService.getAllItemLevel1();
                const itemLevel1 = data.map((warehouse) => warehouse.name);
                params.success(itemLevel1);
              },
            },
            valueFormatter: (params) => {
              if (params.value) {
                return params.value.name;
              } else {
                return "";
              }
            },
          },
          {
            field: "price_vat_type",
            headerName: t("reports.price_vat_type"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
            hide: true,
          },
          {
            field: "item_document_id",
            headerName: t("reports.item_document_id"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "item_name",
            headerName: t("reports.item_name"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "qty",
            headerName: t("reports.qty"),
            filter: false,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "uom",
            headerName: t("reports.uom"),
            filter: false,
          },
          {
            field: "price_per_unit",
            headerName: t("reports.price_per_unit"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "vat_type",
            headerName: t("reports.vat_type"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "pre_vat_amount_exclude_discount",
            headerName: t("reports.pre_vat_amount_exclude_discount"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_discount",
            headerName: t("reports.item_discount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_additional_discount",
            headerName: t("reports.item_additional_discount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "pre_vat_amount_include_discount",
            headerName: t("reports.pre_vat_amount_include_discount"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_vat_exempted_amount",
            headerName: t("reports.item_vat_exempted_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_vat_0_amount",
            headerName: t("reports.item_vat_0_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_vat_7_amount",
            headerName: t("reports.item_vat_7_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_vat_amount",
            headerName: t("reports.item_vat_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_net_amount",
            headerName: t("reports.item_net_amount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_withholding_tax_type",
            headerName: t("reports.item_withholding_tax_type"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_withholding_tax",
            headerName: t("reports.item_withholding_tax"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_total_amount",
            headerName: t("reports.item_total_amount"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "shipping_cost",
            headerName: t("reports.shipping_cost"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "total_amount",
            headerName: t("reports.total_amount"),
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "additional_discount",
            headerName: t("reports.additional_discount"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "credit_day",
            headerName: t("reports.credit_day"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "qty_uom",
            headerName: t("reports.qty_uom"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => formatNumberWithValidate(params.value),
          },
          {
            field: "item_qty_to_ship",
            headerName: t("reports.item_qty_to_ship"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "item_qty_shipped",
            headerName: t("reports.item_qty_shipped"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "item_qty_invoiced",
            headerName: t("reports.item_qty_invoiced"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "item_qty_returned",
            headerName: t("reports.item_qty_returned"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "is_late",
            headerName: t("reports.is_late"),
            hide: true,
            filter: "agSetColumnFilter",
            filterParams: {
              values: ["ยังไม่เกินเวลา", "เกินเวลาแล้ว"],
            },
          },
          {
            field: "reference_document_id",
            headerName: t("reports.reference_document_id"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
            hide: true,
          },
          {
            field: "external_ref_id",
            headerName: t("reports.external_ref_id"),
            hide: true,
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "due_date",
            headerName: t("reports.due_date"),
            filter: "agDateColumnFilter",
            hide: true,
            filterParams: {
              filterOptions: filterParamsOptions("date"),
            },
            width: 200,
            valueFormatter: (params) => unixToDateWithFormat(params.value),
          },
          {
            field: "item_description",
            headerName: t("reports.item_description"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "item_remark",
            headerName: t("reports.item_remark"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "width_length_tolerance_positive",
            headerName: t("reports.width_length_tolerance_positive"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "width_length_tolerance_negative",
            headerName: t("reports.width_length_tolerance_negative"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "thickness_tolerance_positive",
            headerName: t("reports.thickness_tolerance_positive"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "thickness_tolerance_negative",
            headerName: t("reports.thickness_tolerance_negative"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "created_date",
            headerName: t("reports.created_date"),
            filter: "agDateColumnFilter",
            hide: true,
            filterParams: {
              filterOptions: filterParamsOptions("date"),
              comparator: dateComparator,
            },
            width: 200,
            valueFormatter: (params) => unixToDateWithFormat(params.value),
          },
          {
            field: "updated_date",
            headerName: t("reports.updated_date"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
            valueFormatter: (params) => unixToDateWithFormat(params.value),
          },
          {
            field: "created_by",
            headerName: t("reports.created_by"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "employee_list",
            headerName: t("reports.employee_list"),
            filter: "agTextColumnFilter",
            filterParams: {
              filterOptions: filterParamsOptions("string"),
            },
          },
          {
            field: "email",
            headerName: t("reports.email"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "phone",
            headerName: t("reports.phone"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "fax",
            headerName: t("reports.fax"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_address",
            headerName: t("reports.billing_address"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_sub_district",
            headerName: t("reports.billing_sub_district"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_district",
            headerName: t("reports.billing_district"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_province",
            headerName: t("reports.billing_province"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_postal_code",
            headerName: t("reports.billing_postal_code"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "billing_country",
            headerName: t("reports.billing_country"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "is_same_as_default_address",
            headerName: t("reports.is_same_as_default_address"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_address_contact_name",
            headerName: t("reports.delivery_address_contact_name"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_address_contact_phone",
            headerName: t("reports.delivery_address_contact_phone"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_address",
            headerName: t("reports.delivery_address"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_sub_district",
            headerName: t("reports.delivery_sub_district"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_district",
            headerName: t("reports.delivery_district"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_province",
            headerName: t("reports.delivery_province"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_postal_code",
            headerName: t("reports.delivery_postal_code"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: "delivery_country",
            headerName: t("reports.delivery_country"),
            filter: false,
            hide: true,
            suppressColumnsToolPanel: true,
          },
        ]);
    }
  }, [isReport, newReport, t]);
  return columnDefs;
};

export default useSalesOrderColumnDef;
