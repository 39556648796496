import React, { useCallback } from "react";
import AgGrid from "../AgGrid";

import SettingService from "../../../services/Setting";

import useItemCurrentStockLocationColumnDef from "../../../hooks/Inventory/useItemCurrentStockLocationColumnDef";

const ItemCurrentStockLocationTable = ({
  gridRef,
  onGridReady,
  rowData,
  isReport,
}) => {
  const getAllItemGroupLevel1 = useCallback(async () => {
    const data = await SettingService.getAllCategory();
    return data;
  }, []);

  const columnDefs = useItemCurrentStockLocationColumnDef(
    isReport,
    getAllItemGroupLevel1
  );

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      onGridReady={onGridReady}
      rowData={rowData}
    />
  );
};

export default ItemCurrentStockLocationTable;
