import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomizedTextField from "./CustomizedTextField";
import { Box } from "@mui/material";

const CustomizedDate = ({ dateOption }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", gap: ".5rem" }}>
        {dateOption.map((option) => (
          <DesktopDatePicker
            key={option.name}
            name={option.name}
            disabled={option.disabled}
            label={option.label}
            inputFormat="dd/MM/yyyy"
            value={option.value}
            disablePast={option.disabledPast}
            onChange={option.onChange}
            renderInput={(params) => (
              <CustomizedTextField
                sx={{ width: 155 }}
                {...params}
                ref={params.inputRef}
              />
            )}
          />
        ))}
      </Box>
    </LocalizationProvider>
  );
};

export default CustomizedDate;
