import * as Yup from "yup";

export const schema = {
  first_name: "",
  last_name: "",
  email: "",
  department: "",
  position: "",
  phone: "",
  img_url: [],
  new_password: "",
  confirm_new_password: "",
  old_password: "",
};

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const validation = Yup.object().shape({
  first_name: Yup.string().required("กรุณากรอก"),
  last_name: Yup.string().required("กรุณากรอก"),
  email: Yup.string().email("กรุณากรอกอีเมลให้ถูกต้อง").required("กรุณากรอก"),
  department: Yup.string().required("กรุณากรอก").nullable(),
  position: Yup.string().required("กรุณากรอก").nullable(),
  phone: Yup.string().matches(phoneRegExp, "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง"),
  // img_url: Yup.string().required("กรุณากรอก"),
  // new_password: Yup.string().matches(
  //   // eslint-disable-next-line no-useless-escape
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   `• กรุณาระบุมากกว่า 8 ตัวอักษร\n• ตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร\n• ตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัวอักษร\n• ตัวเลขอย่างน้อย 1 ตัวอักษร\n• อักขระพิเศษ (!@#$%^&*()_+|~-=\`{}[]:";'<>?,./) อย่างน้อย 1 ตัวอักษร`
  // ),
  new_password: Yup.string()
    .min(8, "กรุณาระบุมากกว่า 8 ตัว")
    .matches(/[a-z]+/, "ตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัว")
    .matches(/[A-Z]+/, "ตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัว")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
      `อักขระพิเศษ (!@#$%^&*()_+|~-=\`{}[]:";'<>?,./) อย่างน้อย 1 ตัว`
    )
    .matches(/\d+/, "ตัวเลขอย่างน้อย 1 ตัว"),
  // is_active: Yup.boolean().required("กรุณากรอก"),
  confirm_new_password: Yup.string().oneOf(
    [Yup.ref("new_password"), null],
    "รหัสผ่านไม่ตรงกัน"
  ),
});
