import styled from "@emotion/styled";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import ModalUI from "../../../../UI/ModalUI";
import { useForm, useWatch } from "react-hook-form";
import {
  formatBom,
  getBomById,
} from "../../../../../features/Manufacture/Bom/bom-actions";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import CheckedBomModal from "../../../../Table/CheckedBomModal";
import {
  bomInitialState,
  bomValidation,
} from "../../../../../features/Manufacture/Bom/bom-initial";
import { unixToDate } from "../../../../../utils/date-converter";
import { yupResolver } from "@hookform/resolvers/yup";
import BomDetailForm from "../../Bom/Detail";
import BomTableForm from "../../Bom/Table";
import ControlledTextField from "../../../../Controlled/ControlledTextField";
// import InventoryService from "../../../../../services/Inventory";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useParams } from "react-router-dom";
import { bomActions } from "../../../../../features/Manufacture/Bom/bom-slice";

const BomDetail = ({
  control,
  errors,
  setValue,
  disabled,
  reset,
  ingredientReplace,
  ingredientQtyCalculation,
}) => {
  const { bom } = useSelector((state) => state.bom);

  const [bomIds, setBomIds] = useState([]);
  const [bomIdsSnapshot, setBomIdsSnapshot] = useState([]);

  const { id } = useParams();

  const {
    control: bomControl,
    formState: { errors: bomErrors },
    reset: bomReset,
  } = useForm({
    defaultValues: {
      ...bomInitialState.bom,
      created_date: unixToDate(bomInitialState.bom.created_date),
      start_date: unixToDate(bomInitialState.bom.start_date),
      end_date: unixToDate(bomInitialState.bom.end_date),
    },
    resolver: yupResolver(bomValidation),
  });

  const watchMinimunStockQty = useWatch({
    control: bomControl,
    name: "minimum_stock_qty",
  });

  const watchItemId = useWatch({
    control,
    name: "item_document_id",
  });

  const watchStatus = useWatch({
    control,
    name: "render_status",
  });

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  }));

  const [showSelectBom, setShowSelectBom] = useState(false);
  const [showBomForm, setShowBomForm] = useState(false);

  const watchBomId = useWatch({ control, name: "bom_id" });
  const watchBomName = useWatch({ control, name: "bom_name" });
  // const watchProductionQty = useWatch({ control, name: "production_qty" });

  const watchBomDetail = useWatch({ control, name: "bom_detail" });

  const openBomTable = () => {
    setShowSelectBom(true);
  };

  const closeBomTable = () => {
    setShowSelectBom(false);
  };

  const openBomForm = () => {
    setShowBomForm(true);
    if (!watchStatus && watchBomId) {
      dispatch(getBomById(watchBomId, enqueueSnackbar));
    }
  };

  const closeBomForm = () => {
    setShowBomForm(false);
  };

  useEffect(() => {
    if (!watchStatus && watchBomId) {
      dispatch(getBomById(watchBomId, enqueueSnackbar));
    }
    return () => {
      dispatch(bomActions.resetBom());
    };
  }, [dispatch, enqueueSnackbar, watchBomId, id, watchStatus]);

  useEffect(() => {
    if (!watchStatus && watchBomName) {
      setBomIds([watchBomName]);
      setBomIdsSnapshot([watchBomName]);
    } else if (watchBomDetail) {
      setBomIds([watchBomDetail.name]);
      setBomIdsSnapshot([watchBomDetail.name]);
    }
  }, [watchBomDetail, watchBomName, watchStatus]);

  useEffect(() => {
    if (!watchStatus && bom.id) {
      bomReset({
        ...bom,
        created_date: unixToDate(bom.created_date),
        start_date: unixToDate(bom.start_date),
        end_date: unixToDate(bom.end_date),
      });
    } else if (watchBomDetail) {
      bomReset({
        ...watchBomDetail,
        created_date: unixToDate(watchBomDetail.created_date),
        start_date: unixToDate(watchBomDetail.start_date),
        end_date: unixToDate(watchBomDetail.end_date),
      });
    }
  }, [bom, bomReset, watchBomDetail, watchStatus]);

  const finishBomSelectHandler = async (data) => {
    if (data) {
      setValue("bom_id", data.id);
      setValue("bom_name", data.name);

      const bom_detail = await formatBom(data);

      setValue("bom_detail", bom_detail);

      // const allItemsDocumentId = data.ingredient_list.map(
      //   (item) => item.item_document_id
      // );

      // const items = await InventoryService.getAllItems({
      //   findManyInput: {
      //     where: {
      //       document_id: {
      //         in: allItemsDocumentId,
      //       },
      //     },
      //   },
      // });

      // const newIngredientList = [];

      // data.ingredient_list.forEach((item) => {
      //   const foundItemIndex = items.findIndex(
      //     (realItem) => realItem.document_id === item.item_document_id
      //   );
      //   const formatIngredient = {
      //     ...item,
      //     current_ordered_purchase_qty:
      //       items[foundItemIndex]?.current_ordered_purchase_qty,
      //     current_ordered_manufacture_qty:
      //       items[foundItemIndex]?.current_ordered_manufacture_qty,
      //     current_committed_sales_qty:
      //       items[foundItemIndex]?.current_committed_sales_qty,
      //     current_committed_manufacture_qty:
      //       items[foundItemIndex]?.current_committed_manufacture_qty,
      //     current_stock_qty: items[foundItemIndex]?.current_stock_qty,
      //     current_available_qty: items[foundItemIndex]?.current_available_qty,
      //   };
      //   newIngredientList.push(formatIngredient);
      // });
      ingredientReplace(bom_detail.ingredient_list);
      // ingredientQtyCalculation();
    } else {
      reset((prev) => ({
        ...prev,
        bom_id: "",
        bom_name: "",
        bom_detail: null,
      }));
    }
    closeBomTable();
  };

  return (
    <Box mt={3}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography ml={1} fontWeight="bold">
          {t("manufacture.order.bom")}
        </Typography>
        {!disabled && watchItemId && (
          <CustomizedTooltip title="เลือกสูตรผลิต">
            <IconButton
              onClick={openBomTable}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="bom_name"
            label={t("manufacture.order.bom_name")}
            control={control}
            error={Boolean(errors?.bom_name)}
            helperText={errors?.bom_name && errors?.bom_name.message}
            fullWidth
            disabled
            required
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={openBomForm}>
                    {watchBomName || t("manufacture.order.bom_name")}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && watchBomName && (
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดสูตรการผลิต">
                      <IconButton
                        onClick={openBomForm}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {!disabled && (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <CustomizedTooltip title="คำนวณ BOM ใหม่">
              <IconButton
                onClick={ingredientQtyCalculation}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <RestartAltIcon />
              </IconButton>
            </CustomizedTooltip>
          </Grid>
        )}
      </Grid>
      <CheckedBomModal
        showSelectBom={showSelectBom}
        closeBomTable={closeBomTable}
        finishBomSelectHandler={finishBomSelectHandler}
        watchItemId={watchItemId}
        bomIds={bomIds}
        setBomIds={setBomIds}
        bomIdsSnapshot={bomIdsSnapshot}
        setBomIdsSnapshot={setBomIdsSnapshot}
        btnTitle={t("button.choose")}
      />
      <ModalUI
        open={showBomForm}
        navigateTo={`/manufacture/bom/${watchBomId}`}
        handleClose={closeBomForm}
        fullWidth
        title={bom.name ?? ""}
        maxWidth="lg"
      >
        <BomDetailForm
          control={bomControl}
          errors={bomErrors}
          reset={bomReset}
          disabled={true}
          minimumStockQty={watchMinimunStockQty}
        />
        <Divider />
        <BomTableForm control={bomControl} errors={bomErrors} disabled={true} />
      </ModalUI>
    </Box>
  );
};

export default BomDetail;
