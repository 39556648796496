import moment from "moment";
import * as Yup from "yup";

export const manufactureOrderInitialState = {
  isLoading: {
    allManufactureOrders: false,
    allManufactureOrdersExport: false,
    allUnderqualityItemsExport: false,
    manufactureOrder: false,
  },
  allManufactureOrders: [],
  allManufactureOrdersCalendar: [],
  allManufactureOrdersExport: [],
  allUnderqualityItemsExport: [],
  goodReceiveTab: [],
  goodIssueTab: [],
  manufactureOrder: {
    created_date: moment().startOf("day").unix(),
    issue_date: moment().startOf("day").unix(),
    expect_date: moment().endOf("day").unix(),
    expect_product_date: moment().endOf("day").unix(),
    delivery_date: null,
    document_id: "",
    reference_document_list: [],
    external_ref_id: "",
    employee_list: [],
    bom_id: "",
    bom_name: "",
    bom_detail: null,
    item_document_id: "",
    item_name: "",
    item_description: "",
    item_remark: "",
    production_qty: 0,
    actual_production_qty: 0,
    stock_uom: "",
    type: "",
    group: [],
    creator_document_id: "",
    contact_document_id: "",
    created_by: {},
    customer: "",
    width_tolerance_positive: 0,
    width_tolerance_negative: 0,
    thickness_tolerance_positive: 0,
    thickness_tolerance_negative: 0,
    ingredient_list: [],
    under_quality_item_list: [],
    work_order_list: [],
    template_remark_id: "",
    remark: "",
  },
};

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

const ingredientValidation = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวน")
    .required("กรุณาระบุจำนวน"),
});

const workOrderValidation = Yup.object().shape({
  machine: Yup.string().required("กรุณาระบุศูนย์การผลิต"),
});

export const manufactureOrderValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  production_qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนสั่งผลิต")
    .required("กรุณาระบุจำนวนสั่งผลิต"),
  customer: Yup.string().required("กรุณาระบุลูกค้า"),
  item_document_id: Yup.string().required("กรุณาระบุสินค้า"),
  bom_name: Yup.string().required("กรุณาระบุสูตรการผลิต"),
  ingredient_list: Yup.array()
    .of(ingredientValidation)
    .min(1, "กรุณาระบุส่วนประกอบ")
    .nullable(),
  work_order_list: Yup.array().of(workOrderValidation).nullable(),
});
