import React, { forwardRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { departmentEngToThai } from "../../utils/dataTransformer";
import { CircularProgress } from "@mui/material";

const CustomizedComboBox = forwardRef(
  (
    {
      options,
      label,
      sx,
      name,
      onChange,
      value,
      disabled,
      error,
      helperText,
      InputProps,
      required,
      isOptionEqualToValue,
      testId,
      ListboxProps,
      loading,
      onOpen,
      onClose,
    },
    ref
  ) => {
    return (
      <Autocomplete
        loading={loading}
        id="combo-box-demo"
        value={departmentEngToThai(value) || null}
        options={options}
        onChange={onChange}
        ListboxProps={ListboxProps}
        data-test-id={testId}
        onOpen={onOpen}
        onClose={onClose}
        getOptionLabel={(option) => option.label || option}
        isOptionEqualToValue={
          isOptionEqualToValue
            ? isOptionEqualToValue
            : (option, value) => {
                if (option.label)
                  return option.id === value.id || option.label === value;
                else return option === value;
              }
        }
        sx={sx}
        ref={ref}
        renderOption={(props, option) => {
          if (option.id || option.label) {
            return (
              <li
                {...props}
                data-test-id={option.value}
                value={option.value}
                key={option.id}
              >
                {option.label}
              </li>
            );
          } else {
            return (
              <li {...props} data-test-id={option} value={option} key={option}>
                {option}
              </li>
            );
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            name={name}
            error={error}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            size="small"
            required={required}
            helperText={helperText}
          />
        )}
        autoComplete
        includeInputInList
        disabled={disabled}
      />
    );
  }
);

export default CustomizedComboBox;
