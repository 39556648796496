import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DeliveryTripTable from "../../../components/Table/DocumentTable/DeliveryTripTable";
import { getDeliveryTripTab } from "../../../features/Logistic/DeliveryOrder/delivery-order-actions";
import { deliveryOrderActions } from "../../../features/Logistic/DeliveryOrder/delivery-order-slice";

const DeliveryTripTab = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { deliveryTripTab } = useSelector((state) => state.deliveryOrder);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(getDeliveryTripTab(id, enqueueSnackbar));
    }
    return () => dispatch(deliveryOrderActions.resetDeliveryTripTab());
  }, [dispatch, enqueueSnackbar, id]);

  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        {t("logistic.deliveryTrip.index")}
      </Typography>
      <DeliveryTripTable gridRef={gridRef} rowData={deliveryTripTab} />
    </>
  );
};

export default DeliveryTripTab;
