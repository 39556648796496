import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DeliveryOrderTable from "../../../components/Table/DocumentTable/DeliveryOrderTable";
import { getDeliveryOrderTab } from "../../../features/Sales/SalesOrder/sales-order-actions";
import { salesOrderActions } from "../../../features/Sales/SalesOrder/sales-order-slice";

const DeliveryOrderTab = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { deliveryOrderTab } = useSelector((state) => state.salesOrder);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(getDeliveryOrderTab(id, enqueueSnackbar));
    }
    return () => dispatch(salesOrderActions.resetDeliveryOrderTab());
  }, [dispatch, enqueueSnackbar, id]);
  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        {t("logistic.deliveryOrder.index")}
      </Typography>
      <DeliveryOrderTable gridRef={gridRef} rowData={deliveryOrderTab} />
    </>
  );
};

export default DeliveryOrderTab;
