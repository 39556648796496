import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CustomizedMenuOption from "../../../Custom/CustomizedMenuOption";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RightDrawer from "../../../UI/RightDrawer";
import ControlledDatePicker from "../../../Custom/ControlledDatePicker";
import { useSelector } from "react-redux";
import { useWatch } from "react-hook-form";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
// import CancelConfirmation from "../../../UI/Confirmation/CancelConfirmation";
import DeleteConfirmation from "../../../UI/Confirmation/DeleteConfirmation";
import CopyConfirmation from "../../../UI/Confirmation/CopyConfirmation";
import { usePermission } from "../../../../hooks/use-auth";

const printOptions = ["พิมพ์สูตรการผลิต"];

const BomHeaderForm = ({
  control,
  errors,
  disabled,
  editButtonClickHandler,
  mainBomSubmitHandler,
  deleteBomHandler,
  copyBomHandler,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [createdDateisOpen, setCreatedDateisOpen] = useState(false);
  const [startDateisOpen, setStartDateisOpen] = useState(false);
  const [endDateisOpen, setEndDateisOpen] = useState(false);
  const { bom } = useSelector((state) => state.bom);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [copyConfirmation, setCopyConfirmation] = useState(false);

  // const selectModifyOptions = ["ใช้เป็นสูตรหลัก", "แก้ไข", "คัดลอก", "ลบ"];
  const { createPermission, deletePermission, editPermission } =
    usePermission();

  const selectModifyOptions = [
    {
      value: "ใช้เป็นสูตรหลัก",
      disabled: !editPermission,
    },
    {
      value: "แก้ไข",
      disabled: !editPermission,
    },
    {
      value: "คัดลอก",
      disabled: !createPermission,
    },
    {
      value: "ลบ",
      disabled: !deletePermission,
    },
  ];

  const closeDeleteConfirmationHandler = () => {
    setDeleteConfirmation(false);
  };

  const deleteConfirmationAction = () => {
    // TODO: Cancel API
    closeDeleteConfirmationHandler();
    deleteBomHandler();

    // changeStatusHandler("canceled");
  };

  const closeCopyConfirmationHandler = () => {
    setCopyConfirmation(false);
  };

  const DocumentNameSection = ({ control }) => {
    const watchIsMainBom = useWatch({ control, name: "is_main_bom" });
    const isMainBom = watchIsMainBom === "TRUE" && "mainBom";
    return (
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography variant="h5">
          {bom.name || `สร้าง${t("manufacture.bom.index")}`}
        </Typography>
        <CustomizedStatus status={isMainBom} />
      </Box>
    );
  };

  return (
    <>
      <Grid container my={3} alignItems="center">
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} mb={3}>
          <DocumentNameSection control={control} />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={5.5} sm={5.5} md={4} lg={3.5} xl={2}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  if (e.target.innerText === "พิมพ์สูตรการผลิต") {
                    navigate(`/manufacture/bom/${encodeURIComponent(id)}/pdf`);
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={3.5} lg={3.5} xl={2}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  switch (e.target.innerText) {
                    case "ใช้เป็นสูตรหลัก":
                      mainBomSubmitHandler();
                      break;
                    case "แก้ไข":
                      editButtonClickHandler();
                      break;
                    case "คัดลอก":
                      setCopyConfirmation(true);
                      break;
                    case "ลบ":
                      setDeleteConfirmation(true);
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
        <RightDrawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
          title={t("inventory.activity")}
          documentId={id}
          documentType="bom"
        />
      </Grid>
      <Grid container justifyContent={isMobile ? "flex-start" : "flex-end"}>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                isOpen={createdDateisOpen}
                onClose={() => setCreatedDateisOpen(false)}
                onOpen={() => setCreatedDateisOpen(true)}
                label={t("manufacture.bom.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="start_date"
                control={control}
                error={errors.start_date}
                isOpen={startDateisOpen}
                onClose={() => setStartDateisOpen(false)}
                onOpen={() => setStartDateisOpen(true)}
                label={t("manufacture.bom.start_date")}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="end_date"
                control={control}
                error={errors.end_date}
                isOpen={endDateisOpen}
                onClose={() => setEndDateisOpen(false)}
                onOpen={() => setEndDateisOpen(true)}
                label={t("manufacture.bom.end_date")}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CopyConfirmation
        openCopyConfirmation={copyConfirmation}
        copyConfirmationAction={copyBomHandler}
        closeCopyConfirmationHandler={closeCopyConfirmationHandler}
      />
      <DeleteConfirmation
        documentName="สูตรการผลิต"
        dataName={bom.name}
        deleteConfirmation={deleteConfirmation}
        closeDeleteConfirmationHandler={closeDeleteConfirmationHandler}
        deleteConfirmationAction={deleteConfirmationAction}
      />
    </>
  );
};

export default BomHeaderForm;
