import { Box } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";

const DeleteConfirmation = ({
  dataName,
  documentName,
  deleteConfirmation,
  deleteConfirmationAction,
  closeDeleteConfirmationHandler,
}) => {
  return (
    <ModalUI
      title={`ต้องการลบ${documentName} ${dataName} ใช่หรือไม่`}
      open={deleteConfirmation}
      handleClose={closeDeleteConfirmationHandler}
      maxWidth="sm"
    >
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeDeleteConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={deleteConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default DeleteConfirmation;
