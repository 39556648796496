import styled from "@emotion/styled";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const CustomizedTooltip = styled(
  ({ className, noMaxWidth, isCalendar, isDisabled, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ noMaxWidth, isCalendar, isDisabled }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: isDisabled ? "#666666" : isCalendar ? "#32418F" : "#333333",
    boxShadow: "0px 2px 10px #E5E5E5",
    fontSize: 14,
    fontWeight: isCalendar ? 500 : 400,
    maxWidth: noMaxWidth && "none",
    border: isCalendar ? "1px solid #32418F" : "none",
    borderRadius: 16,
  },
}));
