import {
  graphQLClientWithHeader,
  graphQLClientReportWithHeader,
} from "../graphQL";
import {
  BIN_LOCATIONS,
  GET_ALL_BATCH_NUMBER,
  GET_ALL_GOODS_ADJUSTMENT_AGGRID,
  GET_ALL_GOODS_ISSUE_AGGRID,
  GET_ALL_GOODS_RECEIPT_AGGRID,
  GET_ALL_GOODS_TRANFERS_AGGRID,
  GET_ALL_ITEMS,
  GET_ALL_ITEM_IDS,
  GET_ALL_ITEMS_AGGRID,
  GET_ALL_SERIAL_NUMBER,
  GET_ALL_ITEMS_WEIGHT_VOLUME,
  GET_ALL_WAREHOUSES,
  GET_BATCH_NUMBER,
  GET_GOODS_ADJUSTMENT,
  GET_GOODS_ISSUE,
  GET_GOODS_RECEIPT,
  GET_GOODS_TRANFERS,
  GET_ITEM,
  GET_ITEM_CURRENT_STOCK,
  GET_ITEM_CURRENT_STOCK_BY_SN,
  GET_ITEM_CURRENT_STOCK_LOCATION,
  GET_SERIAL_NUMBER,
  GET_STOCK_ENTRYS_AGGRID,
  GET_TRACE_ENTRYS_AGGRID,
  SCAN_BARCODE,
  GET_BATCHES,
  GET_ITEMS_BY_ENTRY_DATE_RANGE,
  CHECK_CAN_DELETE_ITEM,
  CHECK_BARCODE_IS_RECEIVE,
  GET_ALL_ITEMS_AGGRID_DOCUMENT_ID_NAME,
  GET_NEW_ITEM_CURRENT_STOCK,
  GET_NEW_ITEM_CURRENT_STOCK_BY_LOCATION,
  GET_NEW_ITEM_CURRENT_STOCK_BY_SN,
  GET_ITEM_CURRENT_STOCK_BY_SN_SCAN,
  GET_NEW_GOODS_RECEIVE,
  GET_NEW_GOODS_ISSUE,
  GET_NEW_STOCK_ENTRY,
  GET_NEW_TRACE_ENTRY,
  GET_NEW_IN_ACTIVE_SERIAL_NUMBER_REPORT,
  GET_NEW_ITEM_NO_ENTRY_REPORT,
  GET_ALL_LEVEL_1,
  GET_ALL_GOODS_RECEIPT_AGGRID_NO_ITEM,
  GET_ALL_GOODS_ISSUE_AGGRID_NO_ITEM,
  GET_ALL_GOODS_ADJUSTMENT_AGGRID_NO_ITEM,
  GET_ALL_GOODS_TRANFERS_AGGRID_NO_ITEM,
} from "./queries";
import {
  UPDATE_ITEM,
  CREATE_ITEM,
  CREATE_ITEMS,
  CREATE_OR_UPDATE_ITEMS,
  CREATE_GOODS_RECEIVE,
  CREATE_GOODS_RECEIVE_WITH_APPROVE,
  CREATE_SERIAL_NUMBER,
  CREATE_MANY_SERIAL_NUMBER,
  CREATE_BATCH_NUMBER,
  CREATE_MANY_BATCH_NUMBER,
  UPDATE_GOODS_RECEIVE,
  APPROVE_GOODS_RECEIVE,
  CANCEL_GOODS_RECEIVE,
  CREATE_GOODS_ISSUE,
  CREATE_GOODS_ISSUE_WITH_APPROVE,
  UPDATE_GOODS_ISSUE,
  CANCEL_GOODS_ISSUE,
  CREATE_GOODS_TRANSFER_WITH_APPROVE,
  CREATE_GOODS_ADJUSTMENT_WITH_APPROVE,
  CREATE_ITEM_WITH_UOM,
  DELETE_ITEM,
  UPDATE_ITEM_QUANTITY,
} from "./mutation";

class InventoryService {
  async checkBarcodeParcial(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { stockEntryBarcodeCheck } = await graphQLClient.request(
      CHECK_BARCODE_IS_RECEIVE,
      input
    );
    return stockEntryBarcodeCheck;
  }
  async checkDeleteItem(documentId) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemDeleteCheck } = await graphQLClient.request(
      CHECK_CAN_DELETE_ITEM,
      {
        documentId: documentId,
      }
    );
    return itemDeleteCheck;
  }
  async getAllItems(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { items } = await graphQLClient.request(GET_ALL_ITEMS, input);
    return items;
  }
  async getAllItemsIds() {
    const graphQLClient = graphQLClientWithHeader();
    const { items } = await graphQLClient.request(GET_ALL_ITEM_IDS);
    return items;
  }
  async getAllItemsAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemsAggrid } = await graphQLClient.request(GET_ALL_ITEMS_AGGRID, {
      aggridInput,
    });
    return itemsAggrid;
  }
  async getAllItemsAggridReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemsAggrid } = await graphQLClient.request(GET_ALL_ITEMS_AGGRID, {
      aggridInput,
    });
    return itemsAggrid;
  }
  async getAllItemsByEntryDateRange(findEntryInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemsByEntryDateRange } = await graphQLClient.request(
      GET_ITEMS_BY_ENTRY_DATE_RANGE,
      {
        findEntryInput,
      }
    );
    return itemsByEntryDateRange;
  }
  async getAllItemsByEntryDateRangeReport(findEntryInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemsByEntryDateRange } = await graphQLClient.request(
      GET_ITEMS_BY_ENTRY_DATE_RANGE,
      {
        findEntryInput,
      }
    );
    return itemsByEntryDateRange;
  }
  async getAllItemsWeightVolume(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { items } = await graphQLClient.request(
      GET_ALL_ITEMS_WEIGHT_VOLUME,
      input
    );
    return items;
  }
  async getItem(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { item } = await graphQLClient.request(GET_ITEM, { uniqueInput });
    return item;
  }
  async getItemCurrentStock(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCurrentStock } = await graphQLClient.request(
      GET_ITEM_CURRENT_STOCK,
      input
    );
    return itemCurrentStock;
  }
  async getAllWarehouses(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouses } = await graphQLClient.request(
      GET_ALL_WAREHOUSES,
      input
    );
    return warehouses;
  }
  async createItem(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCreate } = await graphQLClient.request(CREATE_ITEM, input);
    return itemCreate;
  }
  async createItems(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCreateMany } = await graphQLClient.request(
      CREATE_ITEMS,
      createManyInput
    );
    return itemCreateMany;
  }
  async createOrUpdateItems(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCreateOrUpdateMany } = await graphQLClient.request(
      CREATE_OR_UPDATE_ITEMS,
      createManyInput
    );
    return itemCreateOrUpdateMany;
  }
  async createItemWithUom(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { ItemCreateWithUOM } = await graphQLClient.request(
      CREATE_ITEM_WITH_UOM,
      { createInput: input }
    );
    return ItemCreateWithUOM;
  }
  async updateItem(uniqueInput, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemUpdate } = await graphQLClient.request(UPDATE_ITEM, {
      uniqueInput,
      updateInput,
    });
    return itemUpdate;
  }
  async deleteItem(documentId) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemDelete } = await graphQLClient.request(DELETE_ITEM, {
      documentId: documentId,
    });
    return itemDelete;
  }

  async getAllGRAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsReceivesAggrid } = await graphQLClient.request(
      GET_ALL_GOODS_RECEIPT_AGGRID,
      { aggridInput }
    );
    return goodsReceivesAggrid;
  }

  async getAllGRAggridNoItem(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsReceivesAggridNoItem } = await graphQLClient.request(
      GET_ALL_GOODS_RECEIPT_AGGRID_NO_ITEM,
      { aggridInput }
    );
    return goodsReceivesAggridNoItem;
  }

  async getAllGRReportAggrid(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { goodsReceivesAggrid } = await graphQLClient.request(
      GET_ALL_GOODS_RECEIPT_AGGRID,
      { aggridInput }
    );
    return goodsReceivesAggrid;
  }

  async getGR(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsReceive } = await graphQLClient.request(
      GET_GOODS_RECEIPT,
      input
    );
    return goodsReceive;
  }
  async createGR(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsReceiveCreate } = await graphQLClient.request(
      CREATE_GOODS_RECEIVE,
      {
        createInput: input,
      }
    );
    return goodsReceiveCreate;
  }
  async createGRWithApprove(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsReceiveCreateWithApprove } = await graphQLClient.request(
      CREATE_GOODS_RECEIVE_WITH_APPROVE,
      {
        createInput: input,
      }
    );
    return goodsReceiveCreateWithApprove;
  }
  async updateGR(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsReceiveUpdate } = await graphQLClient.request(
      UPDATE_GOODS_RECEIVE,
      input
    );
    return goodsReceiveUpdate;
  }
  async updateApproveGR(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsReceiveApprove } = await graphQLClient.request(
      APPROVE_GOODS_RECEIVE,
      input
    );
    return goodsReceiveApprove;
  }
  async cancelGR(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsReceiveCancel } = await graphQLClient.request(
      CANCEL_GOODS_RECEIVE,
      { uniqueInput: input }
    );
    return goodsReceiveCancel;
  }
  async getAllGIAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsIssuesAggrid } = await graphQLClient.request(
      GET_ALL_GOODS_ISSUE_AGGRID,
      {
        aggridInput,
      }
    );
    return goodsIssuesAggrid;
  }

  async getAllGIAggridNoItem(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsIssuesAggridNoItem } = await graphQLClient.request(
      GET_ALL_GOODS_ISSUE_AGGRID_NO_ITEM,
      {
        aggridInput,
      }
    );
    return goodsIssuesAggridNoItem;
  }

  async getAllGIReportAggrid(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { goodsIssuesAggrid } = await graphQLClient.request(
      GET_ALL_GOODS_ISSUE_AGGRID,
      {
        aggridInput,
      }
    );
    return goodsIssuesAggrid;
  }

  async getGI(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsIssue } = await graphQLClient.request(GET_GOODS_ISSUE, input);
    return goodsIssue;
  }
  async createGI(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsIssueCreate } = await graphQLClient.request(
      CREATE_GOODS_ISSUE,
      {
        createInput: input,
      }
    );
    return goodsIssueCreate;
  }
  async createGIWithApprove(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsIssueCreateWithApprove } = await graphQLClient.request(
      CREATE_GOODS_ISSUE_WITH_APPROVE,
      {
        createInput: input,
      }
    );
    return goodsIssueCreateWithApprove;
  }
  async updateGI(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsIssueUpdate } = await graphQLClient.request(
      UPDATE_GOODS_ISSUE,
      input
    );
    return goodsIssueUpdate;
  }
  async cancelGI(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsIssueCancel } = await graphQLClient.request(
      CANCEL_GOODS_ISSUE,
      input
    );
    return goodsIssueCancel;
  }

  async getAllGTAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsTransfersAggrid } = await graphQLClient.request(
      GET_ALL_GOODS_TRANFERS_AGGRID,
      {
        aggridInput,
      }
    );
    return goodsTransfersAggrid;
  }

  async getAllGTAggridNoItem(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsTransfersAggridNoItem } = await graphQLClient.request(
      GET_ALL_GOODS_TRANFERS_AGGRID_NO_ITEM,
      {
        aggridInput,
      }
    );
    return goodsTransfersAggridNoItem;
  }
  async getAllGTReportAggrid(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { goodsTransfersAggrid } = await graphQLClient.request(
      GET_ALL_GOODS_TRANFERS_AGGRID,
      {
        aggridInput,
      }
    );
    return goodsTransfersAggrid;
  }
  async createGTWithApprove(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsTransferCreateWithApprove } = await graphQLClient.request(
      CREATE_GOODS_TRANSFER_WITH_APPROVE,
      {
        createInput: input,
      }
    );
    return goodsTransferCreateWithApprove;
  }
  async getGT(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsTransfer } = await graphQLClient.request(
      GET_GOODS_TRANFERS,
      input
    );
    return goodsTransfer;
  }

  async getAllGAAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsAdjustmentsAggrid } = await graphQLClient.request(
      GET_ALL_GOODS_ADJUSTMENT_AGGRID,
      {
        aggridInput,
      }
    );
    return goodsAdjustmentsAggrid;
  }

  async getAllGAAggridNoItem(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsAdjustmentsAggridNoItem } = await graphQLClient.request(
      GET_ALL_GOODS_ADJUSTMENT_AGGRID_NO_ITEM,
      {
        aggridInput,
      }
    );
    return goodsAdjustmentsAggridNoItem;
  }

  async getAllGAReportAggrid(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { goodsAdjustmentsAggrid } = await graphQLClient.request(
      GET_ALL_GOODS_ADJUSTMENT_AGGRID,
      {
        aggridInput,
      }
    );
    return goodsAdjustmentsAggrid;
  }
  async createGAWithApprove(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsAdjustmentCreateWithApprove } = await graphQLClient.request(
      CREATE_GOODS_ADJUSTMENT_WITH_APPROVE,
      {
        createInput: input,
      }
    );
    return goodsAdjustmentCreateWithApprove;
  }
  async getGA(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { goodsAdjustment } = await graphQLClient.request(
      GET_GOODS_ADJUSTMENT,
      input
    );
    return goodsAdjustment;
  }

  async getAllSEAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { stockEntriesAggrid } = await graphQLClient.request(
      GET_STOCK_ENTRYS_AGGRID,
      {
        aggridInput,
      }
    );
    return stockEntriesAggrid;
  }

  async getAllSEAggridReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { stockEntriesAggrid } = await graphQLClient.request(
      GET_STOCK_ENTRYS_AGGRID,
      {
        aggridInput,
      }
    );
    return stockEntriesAggrid;
  }

  async getAllTEAggrid(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { traceEntriesAggrid } = await graphQLClient.request(
      GET_TRACE_ENTRYS_AGGRID,
      {
        aggridInput,
      }
    );
    return traceEntriesAggrid;
  }
  async getAllTEAggridReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { traceEntriesAggrid } = await graphQLClient.request(
      GET_TRACE_ENTRYS_AGGRID,
      {
        aggridInput,
      }
    );
    return traceEntriesAggrid;
  }

  async getAllICS(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCurrentStockByStockEntryAggrid } = await graphQLClient.request(
      GET_ITEM_CURRENT_STOCK,
      {
        aggridInput,
      }
    );
    return itemCurrentStockByStockEntryAggrid;
  }

  async getAllReportICS(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemCurrentStockByStockEntryAggrid } = await graphQLClient.request(
      GET_ITEM_CURRENT_STOCK,
      {
        aggridInput,
      }
    );
    return itemCurrentStockByStockEntryAggrid;
  }

  async getAllICSL(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCurrentStockByTraceEntryAggrid } = await graphQLClient.request(
      GET_ITEM_CURRENT_STOCK_LOCATION,
      {
        aggridInput,
      }
    );
    return itemCurrentStockByTraceEntryAggrid;
  }
  async getAllReportICSL(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemCurrentStockByTraceEntryAggrid } = await graphQLClient.request(
      GET_ITEM_CURRENT_STOCK_LOCATION,
      {
        aggridInput,
      }
    );
    return itemCurrentStockByTraceEntryAggrid;
  }
  async getAllBatches() {
    const graphQLClient = graphQLClientWithHeader();
    const { batches } = await graphQLClient.request(GET_BATCHES);
    return batches;
  }
  async scanBarcode(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { batch } = await graphQLClient.request(SCAN_BARCODE, uniqueInput);
    return batch;
  }
  async findBinLocations(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { binLocations } = await graphQLClient.request(BIN_LOCATIONS, {
      findManyInput: input,
    });
    return binLocations;
  }
  async getSerialNumber(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { serial } = await graphQLClient.request(GET_SERIAL_NUMBER, {
      uniqueInput: input,
    });
    return serial;
  }
  async getAllSerialNumber(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { serials } = await graphQLClient.request(GET_ALL_SERIAL_NUMBER, {
      uniqueInput: input,
    });
    return serials;
  }
  async createSerialNumber(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { serialCreate } = await graphQLClient.request(CREATE_SERIAL_NUMBER, {
      createInput: input,
    });
    return serialCreate;
  }

  async createManySerialNumber(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { serialCreateMany } = await graphQLClient.request(
      CREATE_MANY_SERIAL_NUMBER,
      { createManyInput }
    );
    return serialCreateMany;
  }

  async getItemCurrentStockSerialNumber(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCurrentStockByTraceEntrySerialNumberAggrid } =
      await graphQLClient.request(GET_ITEM_CURRENT_STOCK_BY_SN, {
        aggridInput,
      });
    return itemCurrentStockByTraceEntrySerialNumberAggrid;
  }

  async getItemCurrentStockSerialNumberScan(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemCurrentStockByTraceEntrySerialNumberScan } =
      await graphQLClient.request(GET_ITEM_CURRENT_STOCK_BY_SN_SCAN, {
        aggridInput,
      });
    return itemCurrentStockByTraceEntrySerialNumberScan; 
  }

  async getItemCurrentStockSerialNumberReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemCurrentStockByTraceEntrySerialNumberAggrid } =
      await graphQLClient.request(GET_ITEM_CURRENT_STOCK_BY_SN, {
        aggridInput,
      });
    return itemCurrentStockByTraceEntrySerialNumberAggrid;
  }

  async getAllBatchNumber(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { batches } = await graphQLClient.request(GET_ALL_BATCH_NUMBER, {
      uniqueInput: input,
    });
    return batches;
  }
  async getBatchNumber(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { batch } = await graphQLClient.request(GET_BATCH_NUMBER, {
      uniqueInput: input,
    });
    return batch;
  }
  async createBatchNumber(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { batchCreate } = await graphQLClient.request(CREATE_BATCH_NUMBER, {
      createInput: input,
    });
    return batchCreate;
  }
  async createManyBatchNumber(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { BatchCreateMany } = await graphQLClient.request(
      CREATE_MANY_BATCH_NUMBER,
      { createManyInput }
    );
    return BatchCreateMany;
  }

  async updateItemQuantity(document_id, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemUpdateQuantity } = await graphQLClient.request(
      UPDATE_ITEM_QUANTITY,
      {
        uniqueInput: {
          document_id,
        },
        updateInput,
      }
    );
    return itemUpdateQuantity;
  }

  async getAllItemsAggridDocumentIdName(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemsAggrid } = await graphQLClient.request(
      GET_ALL_ITEMS_AGGRID_DOCUMENT_ID_NAME,
      {
        aggridInput,
      }
    );
    return itemsAggrid;
  }

  async getAllNewICSReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemCurrentStockByWarehouseReport } = await graphQLClient.request(
      GET_NEW_ITEM_CURRENT_STOCK,
      {
        aggridInput,
      }
    );
    return itemCurrentStockByWarehouseReport;
  }

  async getAllNewReportICSL(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemCurrentStockByLocationReport } = await graphQLClient.request(
      GET_NEW_ITEM_CURRENT_STOCK_BY_LOCATION,
      {
        aggridInput,
      }
    );
    return itemCurrentStockByLocationReport;
  }

  async getAllNEWICSSNReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemCurrentStockBySNReport } = await graphQLClient.request(
      GET_NEW_ITEM_CURRENT_STOCK_BY_SN,
      {
        aggridInput,
      }
    );
    return itemCurrentStockBySNReport;
  }

  async getAllNewGoodsReceiveReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { goodsReceiveReport } = await graphQLClient.request(
      GET_NEW_GOODS_RECEIVE,
      {
        aggridInput,
      }
    );
    return goodsReceiveReport;
  }

  async getAllNewGoodsIssueReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { goodsIssueReport } = await graphQLClient.request(
      GET_NEW_GOODS_ISSUE,
      {
        aggridInput,
      }
    );
    return goodsIssueReport;
  }

  async getAllNewStockEntryReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { stockEntryReport } = await graphQLClient.request(
      GET_NEW_STOCK_ENTRY,
      {
        aggridInput,
      }
    );
    return stockEntryReport;
  }
  async getAllNewTraceEntryReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { traceEntryReport } = await graphQLClient.request(
      GET_NEW_TRACE_ENTRY,
      {
        aggridInput,
      }
    );
    return traceEntryReport;
  }

  async getAllItemLevel1() {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemGroupSubLevel1s } = await graphQLClient.request(
      GET_ALL_LEVEL_1
    );
    return itemGroupSubLevel1s;
  }

  async getNewInactiveSerialNumberReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { inactiveSerialNumberReport } = await graphQLClient.request(
      GET_NEW_IN_ACTIVE_SERIAL_NUMBER_REPORT,
      {
        aggridInput,
      }
    );
    return inactiveSerialNumberReport;
  }

  async getNewItemNoEntryReport(aggridInput, findEntryInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { itemNoEntryReport } = await graphQLClient.request(
      GET_NEW_ITEM_NO_ENTRY_REPORT,
      {
        aggridInput,
        findEntryInput,
      }
    );
    return itemNoEntryReport;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InventoryService();
