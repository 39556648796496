import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./credit-note-initial";

const creditNoteSlice = createSlice({
  name: "creditNote",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllCreditNotes(state, action) {
      state.allCreditNotes = action.payload;
      state.isLoading.allCreditNotes = false;
    },
    loadedApprovalList(state, action) {
      const { approvalTemplates, approvalProgress } = action.payload;
      const sortedApprovalTemplates = approvalTemplates.sort(
        (a, b) => a.step_number - b.step_number
      );
      const sortedApprovalProgress =
        approvalProgress &&
        approvalProgress.length > 0 &&
        approvalProgress.sort((a, b) => a.created_date - b.created_date);

      let formatApprovalList = [];
      sortedApprovalTemplates.forEach((approvalTemplate, index) => {
        const formatApprovalWithStatus = {
          id: approvalTemplate.id,
          step_number: approvalTemplate.step_number,
          reviewer_list: approvalTemplate.reviewer_list,
          approval_status:
            (sortedApprovalProgress &&
              sortedApprovalProgress[index] &&
              sortedApprovalProgress[index].approval_status) ||
            null,
          created_date:
            (sortedApprovalProgress &&
              sortedApprovalProgress[index] &&
              sortedApprovalProgress[index].created_date) ||
            null,
        };
        formatApprovalList.push(formatApprovalWithStatus);
      });
      state.approvalList = formatApprovalList;
    },
    loadedCreditNote(state, action) {
      state.creditNote = action.payload;
      state.isLoading.creditNote = false;
    },
    updateCreditNoteStatus(state, action) {
      state.creditNote.render_status = action.payload;
      state.isLoading.creditNote = false;
    },
    updateApprovalStatus(state, action) {
      const { approval_list, user } = action.payload;
      const sortedApprovalProgress = approval_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      const formatApprovalList =
        sortedApprovalProgress &&
        sortedApprovalProgress.length > 0 &&
        sortedApprovalProgress?.map((approval) => ({
          approval_status: approval?.approval_status,
          reviewer_document_id_list:
            approval?.approval_template &&
            approval?.approval_template?.reviewer_document_id_list,
          created_date: approval?.created_date,
        }));

      const foundWaitApprove =
        formatApprovalList &&
        formatApprovalList.find(
          (approval) => approval.approval_status === "PENDING"
        );

      const isHaveApproval =
        foundWaitApprove &&
        foundWaitApprove?.reviewer_document_id_list.includes(user.document_id);

      state.isHaveApproval = isHaveApproval;

      if (formatApprovalList) {
        formatApprovalList.forEach((approval, index) => {
          state.approvalList[index].approval_status = approval.approval_status;
        });
      }
    },
    resetAllCreditNotes(state) {
      state.allCreditNotes = initialState.allCreditNotes;
      state.error = initialState.error;
    },
    resetCreditNote(state) {
      state.creditNote = initialState.creditNote;
      state.error = initialState.error;
    },
    resetCreditNoteError(state) {
      state.error = initialState.error;
    },
  },
});

export const creditNoteActions = creditNoteSlice.actions;

export default creditNoteSlice.reducer;
