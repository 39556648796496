import React from "react";
import { useNavigate } from "react-router-dom";
import AgGrid from "../AgGrid";
import useSalesOrderColumnDef from "../../../hooks/Sales/useSalesOrderColumnDef";

const OrderTable = ({ gridRef, onGridReady, rowData, isReport, newReport }) => {
  const navigate = useNavigate();

  const columnDefs = useSalesOrderColumnDef(isReport, newReport);

  const onRowDoubleClicked = (params) => {
    navigate(`/sales/order/${encodeURIComponent(params.data.document_id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      onGridReady={onGridReady}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
      path={`/sales/order`}
    />
  );
};

export default OrderTable;
