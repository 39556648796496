import { useCallback, useEffect, useState } from "react";
import UserService from "../../../services/User";

export const useEmployeeOptions = () => {
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [openEmployee, setOpenEmployee] = useState(false);

  const getAllEmployees = useCallback(async () => {
    setEmployeeLoading(true);
    const { results } = await UserService.getAllUsersDocumentIdName({
      startRow: 0,
      endRow: Math.pow(10, 5),
      filterModel: {},
      sortModel: [],
    });

    const formatEmployeeOptions = results.map((result) => ({
      id: result.document_id,
      label: `${result.first_name} ${result.last_name}`,
      value: result.document_id,
    }));

    setEmployeeOptions(formatEmployeeOptions);
    setEmployeeLoading(false);
  }, []);

  useEffect(() => {
    if (openEmployee) {
      getAllEmployees();
    }
  }, [getAllEmployees, openEmployee]);

  const onOpenEmployee = () => {
    setOpenEmployee(true);
  };

  const onCloseEmployee = () => {
    setOpenEmployee(false);
    setEmployeeOptions([]);
  };
  return {
    employeeLoading,
    employeeOptions,
    onOpenEmployee,
    onCloseEmployee,
  };
};
