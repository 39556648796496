import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllUsers } from "../../features/User/Account/account-actions";
import { useAuth } from "../../hooks/use-auth";
import CheckboxModalTable from "./CheckboxModalTable";
import { userColumnDef } from "./ColumnDefs/userColumnDef";

const CheckedEmployeeModal = ({
  showSelectEmployees,
  closeEmployeeTable,
  finishEmployeesSelect,
  watchCreatorDocumentId,
  employeeIds,
  setEmployeeIds,
  employeeIdsSnapshot,
  setEmployeeIdsSnapshot,
}) => {
  const { t } = useTranslation();
  const selectEmployeesGridRef = useRef();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useAuth();

  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const datasource = {
    getRows(params) {
      const request = params.request;
      dispatch(
        getAllUsers(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: request.filterModel,
            sortModel: request.sortModel,
          },
          params
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const creatorRow =
    pathname.split("/").at(-1) === "add"
      ? user
        ? [user.document_id]
        : []
      : [watchCreatorDocumentId];

  return (
    user && (
      <CheckboxModalTable
        modalTitle="ผู้เกี่ยวข้อง"
        btnTitle={t("button.add")}
        gridRef={selectEmployeesGridRef}
        height={450}
        columnDefs={userColumnDef(t)}
        rowSelection="multiple"
        onFinishEditing={finishEmployeesSelect}
        modalIsOpen={showSelectEmployees}
        getRowId={getRowId}
        closeModal={closeEmployeeTable}
        onGridReady={onGridReady}
        selectedIds={employeeIds}
        setSelectedIds={setEmployeeIds}
        idsSnapshot={employeeIdsSnapshot}
        setIdsSnapshot={setEmployeeIdsSnapshot}
        lockRows={creatorRow}
      />
    )
  );
};

export default CheckedEmployeeModal;
