import { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  List,
  ListItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useForm, Controller, useWatch } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import CustomizedCheckboxes from "../../../components/Custom/CustomizedCheckboxes";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import ImporterDropzoneUI from "../../../components/UI/ImporterDropzoneUI";
import ModalUI from "../../../components/UI/ModalUI";
import ManufactureService from "../../../services/Manufacture";
import InventoryService from "../../../services/Inventory";
import GlobalService from "../../../services/Global";
import AgGrid from "../../../components/Table/AgGrid";
import { dateToUnix } from "../../../utils/date-converter";
import { formatFloat } from "../../../utils/dataTransformer";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import ImportErrorTable from "../../../components/Table/ImportErrorTable";

const keys = ["ingredient_list"];

const columns = [
  "name",
  "is_main_bom",
  "item_document_id",
  "description",
  "type",
  "mfg_qty",
  "stock_uom_document_id",
  "start_date",
  "end_date",
  "status",
  "internal_remark",
  "ingredient_list",
];

const requiredKeys = ["name", "is_main_bom", "item_document_id", "mfg_qty"];

const BomImporterPage = () => {
  //keys need to be defined in order
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const gridRef = useRef();
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState([]);
  const [formattedCreateOnlyData, setFormattedCreateOnlyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreateError, setHasCreateError] = useState(false);
  const [createErrors, setCreateErrors] = useState([]);
  const [createData, setCreateData] = useState([]);
  const [updateOrSkipData, setUpdateOrSkipData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [missingItems, setMissingItems] = useState([]);
  const [missingIngredients, setmissingIngredients] = useState([]);
  const [isImported, setIsImported] = useState(false);
  const [invalidStockUoms, setInvalidStockUoms] = useState([]);
  const [errorStep, setErrorStep] = useState(null);

  const { control } = useForm({
    defaultValues: { is_upsert: true },
  });
  const watchIsUpsert = useWatch({ control, name: "is_upsert" });

  useEffect(() => {
    switch (tab) {
      case "updates":
        if (watchIsUpsert) {
          setDisplayedData(updateOrSkipData);
        } else {
          setDisplayedData([]);
        }
        break;
      case "skips":
        if (!watchIsUpsert) {
          setDisplayedData(updateOrSkipData);
        } else {
          setDisplayedData([]);
        }
        break;
      default:
        setDisplayedData(createData);
    }
    // setDisplayedData(flattenedSkuList);
  }, [watchIsUpsert, tab, createData, updateOrSkipData]);

  const errorCount = isImported
    ? (missingItems?.length > 0 ? 1 : 0) +
      (missingIngredients?.length > 0 ? 1 : 0)
    : 0;

  const tabs = [
    {
      label: `สร้าง (${createData.length})`,
      path: `${pathname}`,
    },
    {
      label: `แก้ไข (${watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=updates`,
    },
    {
      label: `ข้าม (${!watchIsUpsert ? updateOrSkipData.length : 0})`,
      path: `${pathname}?tab=skips`,
    },
    {
      label: `Error (${errorCount})`,
      path: `${pathname}?tab=error`,
      errors: errorCount > 0,
    },
  ];

  const columnDefs = [
    {
      field: "name",
      headerName: t("manufacture.bom.bom_name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_document_id",
      headerName: t("manufacture.bom.item_document_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_name",
      headerName: t("manufacture.bom.item_name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "type",
      headerName: t("manufacture.bom.type"),
      filter: "agTextColumnFilter",
    },
    {
      field: "start_date",
      headerName: t("manufacture.bom.start_date"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? unixToDateWithFormat(params.value) : "";
      },
    },
    {
      field: "end_date",
      headerName: t("manufacture.bom.end_date"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? unixToDateWithFormat(params.value) : "";
      },
    },
    {
      field: "internal_remark",
      headerName: t("manufacture.bom.internal_remark"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("manufacture.bom.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["active", "inactive"],
      },
      cellRenderer: (params) => {
        if (params.value === "active") {
          return <CustomizedStatus status="active" />;
        }
        return <CustomizedStatus status="in_active" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.bom.index"),
      to: "/manufacture/bom",
    },
    {
      name: "นำเข้าสูตรการผลิต",
    },
  ];

  const formatBomData = async (data) => {
    setIsImported(false);
    let missingCols;
    const currentItemIds = data.map((bom) =>
      bom.item_document_id?.toString().trim()
    );
    // .filter((item_id) => item_id);
    const currentIngredientIds = data
      .map((bom) =>
        bom.ingredient_list?.map((item) =>
          item.item_document_id?.toString().trim()
        )
      )
      .flat()
      .filter((item_id) => item_id);

    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const existingItems = await InventoryService.getAllItems({
        findManyInput: {
          where: {
            document_id: { in: [...currentItemIds, ...currentIngredientIds] },
          },
        },
      });
      const existingItemIds = existingItems.map((item) => item.document_id);

      const missingItemIds = currentItemIds.filter(
        (item_id) => !existingItemIds.includes(item_id)
      );
      const missingIngredientIds = currentIngredientIds.filter(
        (item_id) => !existingItemIds.includes(item_id)
      );

      setMissingItems(missingItemIds);
      setmissingIngredients(missingIngredientIds);
      const foundInvalidStockUoms = [];

      const formatIngredientList = (list, bom_name) => {
        if (!list) {
          return undefined;
        }
        const formattedList = list?.map((item) => {
          const foundItem = existingItems.find(
            (currentItem) =>
              currentItem.document_id ===
              item.item_document_id?.toString().trim()
          );

          const baseUom = item.uom?.toString().trim();
          if (
            foundItem &&
            baseUom &&
            baseUom !== foundItem.base_uom_document_id
          ) {
            foundInvalidStockUoms.push({
              name: bom_name,
              item_id: foundItem.document_id,
              stock_uom: baseUom,
            });
          }
          return {
            uom: foundItem ? foundItem.base_uom_document_id : baseUom,
            uid: item.uid ? item.uid?.toString().trim() : uuidv4(),
            qty: !isNaN(parseFloat(item.qty))
              ? formatFloat(item.qty)
              : undefined,
            item_name: foundItem ? foundItem.name : undefined,
            item_document_id: item.item_document_id?.toString().trim(),
            item_description: foundItem ? foundItem.description : undefined,
          };
        });

        return formattedList;
      };

      const formatBomStatus = (status) => {
        const trimmedStatus = status?.toString().trim();
        if (trimmedStatus === "หยุดใช้งาน") {
          return "inactive";
        }
        return "active";
      };

      const formattedData = data.map((bom) => {
        const item = existingItems.find(
          (item) => item.document_id === bom.item_document_id
        );
        const stockUom = bom.stock_uom_document_id?.toString().trim();
        if (item && stockUom && stockUom !== item.base_uom_document_id) {
          foundInvalidStockUoms.push({
            name: bom.name?.toString().trim(),
            item_id: item.document_id,
            stock_uom: stockUom,
          });
        }
        return {
          name: bom.name?.toString().trim(),
          is_main_bom: bom.is_main_bom ? "TRUE" : "FALSE",
          item_document_id: bom.item_document_id?.toString().trim(),
          description: bom.description?.toString().trim(),
          type: bom.type?.toString().trim(),
          mfg_qty: !isNaN(parseInt(bom.mfg_qty))
            ? parseInt(bom.mfg_qty)
            : undefined,
          stock_uom_document_id: item ? item.base_uom_document_id : undefined,
          start_date: bom.start_date ? dateToUnix(bom.start_date) : undefined,
          end_date: bom.end_date ? dateToUnix(bom.end_date) : undefined,
          status: formatBomStatus(bom.status),
          internal_remark: bom.internal_remark?.toString().trim(),
          ingredient_list: formatIngredientList(
            bom.ingredient_list,
            bom.name?.toString().trim()
          ),
        };
      });

      const existingBoms = await ManufactureService.getAllBomNames();
      const existingBomNames = existingBoms.map((bom) => bom.name);
      const bomsToCreate = [];
      const bomsToSkipOrUpdate = [];

      formattedData.forEach((bom) => {
        if (existingBomNames.includes(bom.name)) {
          bomsToSkipOrUpdate.push(bom);
        } else {
          bomsToCreate.push(bom);
        }
      });
      setCreateData(bomsToCreate);
      setUpdateOrSkipData(bomsToSkipOrUpdate);
      setFormattedCreateOnlyData(
        formattedData.filter((bom) => !existingBomNames.includes(bom.name))
      );
      setInvalidStockUoms(foundInvalidStockUoms);

      return formattedData;
    } catch (err) {
      console.error(err);
      if (err.message === "template") {
        enqueueSnackbar(
          `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`,
          {
            variant: "error",
          }
        );
      }
      return [];
    }
  };

  const importFormattedDocuments = async () => {
    setIsLoading(true);
    setHasCreateError(false);
    setCreateErrors([]);
    if (formattedData.length === 0) {
      return;
    }
    const formattedDataSnapshot = [...formattedData];
    const requiredFieldErrors = [];
    for (let i = 0; i < formattedDataSnapshot.length; i++) {
      for (let j = 0; j < requiredKeys.length; j++) {
        if (Array.isArray(formattedDataSnapshot[i][requiredKeys[j]])) {
          let hasError;
          for (
            let k = 0;
            k < formattedDataSnapshot[i][requiredKeys[j]].length;
            k++
          ) {
            if (
              Object.values(formattedDataSnapshot[i][requiredKeys[j]][k]).some(
                (item) => item === null || typeof item === "undefined"
              )
            ) {
              hasError = true;
              break;
            }
          }
          if (hasError) {
            requiredFieldErrors.push({
              document_id: formattedDataSnapshot[i].document_id,
              type: "required",
            });
            formattedDataSnapshot.splice(i, 1);
            i--;
            break;
          }
        }
        if (
          formattedDataSnapshot[i][requiredKeys[j]] !== 0 &&
          !formattedDataSnapshot[i][requiredKeys[j]]
        ) {
          requiredFieldErrors.push({
            document_id: formattedDataSnapshot[i].document_id,
            type: "required",
            field: requiredKeys[j],
          });
          formattedDataSnapshot.splice(i, 1);
          i--;
          break;
        }
      }
    }
    const allIngredientsList = formattedData
      .map((bom) => bom.ingredient_list)
      .flat();
    const emptyIngredientQty = allIngredientsList.find(
      (ingredient) => !ingredient.qty
    );
    if (emptyIngredientQty) {
      requiredFieldErrors.push({
        document_id: undefined,
        type: "required",
        field: "ingredient_list.qty",
      });
    }
    if (requiredFieldErrors.length > 0) {
      setCreateErrors(requiredFieldErrors);
      setHasCreateError(true);
      setIsLoading(false);
      return;
    } else {
      const bomsWithInvalidUom = invalidStockUoms.map((bom) => bom.name);
      const filteredData = formattedData.filter((bom) => {
        const ingredientIdsList = bom.ingredient_list.map(
          (item) => item.item_document_id
        );

        return (
          !bomsWithInvalidUom.includes(bom.name) &&
          bom.ingredient_list?.length > 0 &&
          !missingItems.includes(bom.item_document_id) &&
          !missingIngredients.includes(ingredientIdsList) &&
          !missingIngredients.some((missingIngredient) =>
            ingredientIdsList.includes(missingIngredient)
          )
        );
      });
      try {
        let data;
        if (watchIsUpsert) {
          data = await ManufactureService.createOrUpdateBoms({
            upsertManyInput: filteredData,
          });
        } else {
          data = await ManufactureService.createBoms({
            createManyInput: filteredData,
          });
        }

        const loadedManufactureTypes = await GlobalService.getCreatables({
          usage_field_type: "item",
          usage_field_name: "manufacture_type",
        });
        const existingManufactureTypes = loadedManufactureTypes.map(
          (creatable) => creatable.name
        );
        const manufactureTypesToCreate = [
          ...new Set(data.results.map((item) => item.type)),
        ];
        const filteredManufactureTypesToCreate =
          manufactureTypesToCreate.filter(
            (item) => item && !existingManufactureTypes.includes(item)
          );
        const createdManufactureTypes = [];
        filteredManufactureTypesToCreate.forEach(async (item_type) => {
          try {
            const createdManufactureType = await GlobalService.createCreatable({
              usage_field_type: "item",
              usage_field_name: "manufacture_type",
              name: item_type,
            });
            createdManufactureTypes.push(createdManufactureType);
          } catch (err) {
            console.error(err);
          }
        });

        if (
          data.errors?.length === 0 &&
          invalidStockUoms.length === 0 &&
          data.results?.length > 0
        ) {
          let message = "";
          if (
            watchIsUpsert &&
            createData.length > 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "นำเข้าและแก้ไขสูตรการผลิตสำเร็จ";
          } else if (
            watchIsUpsert &&
            createData.length === 0 &&
            updateOrSkipData.length > 0
          ) {
            message = "แก้ไขสูตรการผลิตสำเร็จ";
          } else if (
            (watchIsUpsert &&
              createData.length > 0 &&
              updateOrSkipData.length === 0) ||
            (!watchIsUpsert && createData.length > 0)
          ) {
            message = "นำเข้าสำเร็จ";
          }
          enqueueSnackbar(message, {
            variant: "success",
          });
          navigate("/manufacture/bom");
        } else {
          throw new Error();
        }
        console.log(data);
      } catch (err) {
        console.error(err);
        if (missingItems.length > 0) {
          setErrorStep("1");
        } else if (missingIngredients.length > 0) {
          setErrorStep("2");
        } else if (invalidStockUoms.length > 0) {
          setErrorStep("3");
        } else {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
      setIsImported(true);
      setHasCreateError(true);
      setIsLoading(false);
    }
  };

  const handleErrorNextStep = (currentStep) => {
    switch (currentStep) {
      case "1":
        if (missingIngredients.length > 0) {
          setErrorStep("2");
        } else if (invalidStockUoms.length > 0) {
          setErrorStep("3");
        } else {
          setHasCreateError(false);
        }
        break;
      case "2":
        if (invalidStockUoms.length > 0) {
          setErrorStep("3");
        } else {
          setHasCreateError(false);
        }
        break;
      case "3":
        setHasCreateError(false);
        break;
      default:
    }
  };

  const renderCreateErrors = () => {
    if (createErrors.find((error) => error.type === "template_from_importer")) {
      enqueueSnackbar("Template ไม่ตรง", {
        variant: "error",
      });
    }
    const requiredFieldErrors = [
      ...new Set(
        createErrors
          .filter((error) => error.type === "required")
          .map((error) => error.field)
      ),
    ];
    if (requiredFieldErrors.length > 0) {
      enqueueSnackbar(
        `ไม่พบข้อมูลใน column\n${requiredFieldErrors.join(", ")}`,
        {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
    switch (errorStep) {
      case "1":
        return (
          <ModalUI
            open={hasCreateError}
            handleClose={() => handleErrorNextStep("1")}
            fullWidth
            title="ไม่สามารถสร้างได้ เนื่องจากไม่พบสินค้าที่ถูกบันทึกในระบบ"
            titleSize="16px"
            maxWidth="xs"
          >
            <Typography fontWeight="bold">สินคัาที่ไม่มีในระบบ:</Typography>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
            >
              {missingItems.map((itemDocId) => (
                <ListItem
                  key={`uom-${itemDocId}`}
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography
                    sx={{
                      display: "inline",
                      color: (theme) => theme.palette.warning.main,
                    }}
                  >
                    {itemDocId}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </ModalUI>
        );
      case "2":
        return (
          <ModalUI
            open={hasCreateError}
            handleClose={() => handleErrorNextStep("2")}
            fullWidth
            title="ไม่สามารถสร้างได้ เนื่องจากไม่พบสินค้าที่ใช้เป็นส่วนประกอบในระบบ"
            titleSize="16px"
            maxWidth="xs"
          >
            <Typography fontWeight="bold">สินคัาที่ไม่มีในระบบ:</Typography>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
            >
              {missingIngredients.map((itemDocId) => (
                <ListItem
                  key={`uom-${itemDocId}`}
                  sx={{
                    display: "list-item",
                  }}
                >
                  <Typography
                    sx={{
                      display: "inline",
                      color: (theme) => theme.palette.warning.main,
                    }}
                  >
                    {itemDocId}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </ModalUI>
        );
      case "3":
        return (
          <ModalUI
            open={hasCreateError}
            handleClose={() => handleErrorNextStep("3")}
            fullWidth
            title="ไม่สามารถสร้างได้ เนื่องจากหน่วยสต๊อกไม่ตรงกับสินค้าที่บันทึกในระบบ"
            titleSize="16px"
            maxWidth="sm"
          >
            <Typography fontWeight="bold">หน่วยสต๊อกที่ไม่ตรง:</Typography>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
            >
              {invalidStockUoms.map((invalidUom) => {
                return (
                  <ListItem
                    key={`uom-${invalidUom.stock_uom}`}
                    sx={{
                      display: "list-item",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "inline",
                        color: (theme) => theme.palette.warning.main,
                      }}
                    >
                      {`${invalidUom.stock_uom} (สูตรการผลิต: ${invalidUom.name}, รหัสสินค้า: ${invalidUom.item_id})`}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </ModalUI>
        );
      default:
    }
    return <></>;
  };

  const renderErrorTab = (
    <>
      {missingItems?.length > 0 && (
        <ImportErrorTable
          sx={{ mt: 2, mb: 2 }}
          data={missingItems}
          keyName={"bom-item-row"}
          title={"รหัสสินค้า"}
          errorDescription={"สินค้าที่ไม่มีในระบบ"}
          columnName={"item_document_id"}
          documentType={"manufacture"}
        />
      )}
      {missingIngredients?.length > 0 && (
        <ImportErrorTable
          sx={{ mt: 2, mb: 2 }}
          data={missingIngredients}
          keyName={"bom-ingredient-row"}
          title={"รหัสสินค้าส่วนประกอบ"}
          errorDescription={"ส่วนประกอบที่ไม่มีในระบบ"}
          columnName={"ingredient_item_document_id"}
          documentType={"manufacture"}
        />
      )}
    </>
  );

  const currentTab = tab ? pathname + `?tab=${tab}` : pathname;

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Typography variant="h5">{"นำเข้าสูตรการผลิต"}</Typography>
      </Box>
      <Box
        sx={{ mt: 1, mb: 2, display: "flex", justifyContent: "space-between" }}
      >
        <CustomizedButton
          title="ดาวน์โหลด Template"
          variant="outlined"
          onClick={() =>
            window.open("/static/bom_import_template.xlsx", "_blank")
          }
        />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <ImporterDropzoneUI
            keys={keys}
            setImportedDoc={setFormattedData}
            dataFormatter={formatBomData}
            setLoading={setIsLoading}
            setErrors={setCreateErrors}
            setHasError={setHasCreateError}
            hasLabelHeader
          />
        </Grid>
      </Grid>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Controller
              name={"is_upsert"}
              control={control}
              render={({ field }) => (
                <CustomizedCheckboxes label="อัปเดตรายการปัจจุบัน" {...field} />
              )}
            />
            <CustomizedTooltip
              title={
                "กรณีที่มีชื่อสูตรการผลิตเดิมอยู่แล้ว จะเป็นการเลือกเพื่ออัปเดตข้อมูลใหม่ในรายการสูตรการผลิตเดิม"
              }
              noMaxWidth
            >
              <ErrorOutlineOutlinedIcon sx={{ fontSize: "14px" }} />
            </CustomizedTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} mt={2}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomizedButton
              title="นำเข้า"
              variant="contained"
              onClick={importFormattedDocuments}
              disabled={
                formattedData.length === 0 ||
                isLoading ||
                (!watchIsUpsert && formattedCreateOnlyData.length === 0)
              }
            />
          </Box>
        </Grid>
      </Grid>
      {isLoading && (
        <Box sx={{ mt: 3, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {formattedData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ maxWidth: "100%", overflow: "scroll", mt: 4 }}>
            <CustomizedTab
              tabs={tabs}
              currentTab={currentTab}
              scrollable
              table
            />
          </Box>
          {!currentTab.includes("error") && (
            <AgGrid
              ref={gridRef}
              columnDefs={columnDefs}
              height={649}
              rowData={displayedData}
              groupDefaultExpanded={1}
              disableFloatingFilter
            />
          )}
        </Box>
      )}{" "}
      {errorCount > 0 && currentTab.includes("error") && renderErrorTab}
      {hasCreateError && renderCreateErrors()}
    </>
  );
};

export default BomImporterPage;
