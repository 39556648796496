import React, { useCallback, useEffect, useState } from "react";
import CustomizedCreatable from "../Custom/CustomizedCreatable";
import GlobalService from "../../services/Global";
import { Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useFieldArray } from "react-hook-form";
import CustomizedChips from "../Custom/CustomizedChips";
import { useSnackbar } from "notistack";
import DeleteTagListConfirmation from "./Confirmation/DeleteTagListConfirmation";

const TagList = ({
  control,
  entity,
  viewOnly,
  name,
  title,
  onClickTagListHandler,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tagList, setTagList] = useState();
  const [modalDelete, setModalDelete] = useState({
    value: false,
    document_type: "",
    document_id_list: [],
    optionToDelete: "",
    uniqueInput: null,
  });
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: name ? name : "tag_list", // unique name for your Field Array
  });

  const mapEntity = (entity) => {
    switch (entity) {
      case "item":
        return "สินค้า";
      case "contact":
        return "ผู้ติดต่อ";
      case "manufacture_order":
        return "ใบสั่งผลิต";
      default:
        break;
    }
  };

  const getAllTag = useCallback(async () => {
    const { results } = await GlobalService.getTagList({
      startRow: 0,
      endRow: 9999,
    });
    const mapTagValue = results
      .map((tag) => {
        return {
          ...tag,
          value: tag.name,
          label: tag.name,
          isDeletable: true,
        };
      })
      .filter((tag) => tag.entity === entity);
    setTagList(mapTagValue);
  }, [entity]);

  const createOptionHandler = async (createInput) => {
    try {
      const res = await GlobalService.createTagList({ createInput });
      setTagList((prev) => [
        ...prev,
        {
          ...res,
          value: res.name,
          label: res.name,
          isDeletable: true,
        },
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteVerify = async (optionToDelete) => {
    const findId = tagList.find((tag) => tag.value === optionToDelete);
    const uniqueInput = {
      id: parseInt(findId.id),
    };
    try {
      const results = await GlobalService.deleteTagVerify({ uniqueInput });
      if (results.length === 0)
        await deleteOptionHandler(optionToDelete, uniqueInput);
      else
        setModalDelete({
          value: true,
          document_type: mapEntity(entity),
          document_id_list: results,
          optionToDelete,
          uniqueInput,
        });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่มีกลุ่มนี้อยู่ในระบบ", {
        variant: "error",
      });
    }
  };

  const deleteOptionHandler = async (optionToDelete, uniqueInput) => {
    try {
      await GlobalService.deleteTagList({ uniqueInput });
    } catch (err) {
      console.error(err);
    }
    setTagList((prevOptions) =>
      prevOptions.filter((option) => option.value !== optionToDelete)
    );
  };

  const closeModalHandler = () => {
    setModalDelete((prev) => ({
      ...prev,
      value: false,
    }));
    setTimeout(() => {
      setModalDelete((prev) => ({
        ...prev,
        document_type: "",
        document_id_list: [],
        optionToDelete: "",
        uniqueInput: null,
      }));
    }, 700);
  };

  const comfirmationDeleteAction = () => {
    const { optionToDelete, uniqueInput } = modalDelete;
    deleteOptionHandler(optionToDelete, uniqueInput);
    closeModalHandler();
  };

  useEffect(() => {
    getAllTag();
  }, [getAllTag]);

  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <CustomizedCreatable
          testId="item-tag-list-combobox-field"
          options={tagList}
          title={title ? title : "การจัดกลุ่ม"}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              const createInput = {
                name: newValue.inputValue,
                entity: entity,
              };
              createOptionHandler(createInput);
              append({ name: newValue.inputValue });
            } else {
              if (newValue) append({ name: newValue.value });
            }
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter" && e.target.value) {
              e.preventDefault();
            }
          }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                value={option.value ?? option}
                key={option.label ?? option}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {option.label ?? option}
                {!option?.label?.includes("เพิ่ม ") && option.isDeletable && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      deleteVerify(option.value ?? option);
                      e.stopPropagation();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </li>
            );
          }}
          readOnly={viewOnly}
          disabled={viewOnly}
        />
      </Grid>
      <Grid
        item
        xs={entity === "item" || entity === "contact" ? 8 : 4}
        container
        spacing={1}
      >
        {fields.map((data, index) => (
          <Grid item key={index + Math.random()}>
            <Controller
              name={`${name}[${index}].name`}
              control={control}
              render={({ field }) => (
                <CustomizedChips
                  onDelete={!viewOnly ? () => remove(index) : null}
                  onClick={
                    onClickTagListHandler
                      ? () => onClickTagListHandler(data.name)
                      : null
                  }
                  {...field}
                />
              )}
            />
          </Grid>
        ))}
      </Grid>
      <DeleteTagListConfirmation
        payload={modalDelete}
        openDeleteConfirmation={modalDelete.value}
        comfirmationDeleteAction={comfirmationDeleteAction}
        closeModalHandler={closeModalHandler}
      />
    </>
  );
};

export default TagList;
