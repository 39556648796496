import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getAllItemsAggrid } from "../../../../features/Inventory/ItemMaster/itemMaster-actions";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedTab from "../../../Custom/CustomizedTab";
import ManuItemListTable from "../../../Table/DocumentTable/ManuItemListTable";
import WorkInfoTable from "../../../Table/DocumentTable/WorkInfoTable";
import ItemTable from "../../../Table/ItemTable";
import ModalUI from "../../../UI/ModalUI";
import { v4 as uuidv4 } from "uuid";
import BomItemTable from "../../../Table/DocumentTable/BomItemTable";
import AddIcon from "@mui/icons-material/Add";

const dafaultWOValue = {
  start_date_time: new Date(),
  duration: 0,
  duration_unit: "minute",
  name: "",
  description: "",
  machine: "",
  plant: "",
};

const ManufactureOrderTableForm = ({
  control,
  errors,
  setValue,
  ingredientFields,
  ingredientAppend,
  ingredientRemove,
  workOrderFields,
  workOrderAppend,
  workOrderRemove,
  workOrderUpdate,
  disabled,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const selectUnderQuantityRef = useRef();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("subtab");
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const status = useWatch({
    control,
    name: "render_status",
  });

  const [currentTab, setCurrentTab] = useState(pathname);

  const tabs = [
    {
      label: t("manufacture.bom.ingredient"),
      path: `${pathname}`,
    },
    {
      label: t("manufacture.workOrder.name"),
      path: `${pathname}?subtab=work-info`,
    },
    {
      label: t("manufacture.bom.manufacture_item"),
      path: `${pathname}?subtab=manufacture-item`,
      disabled: id && status !== "draft" ? false : true,
    },
    {
      label: t("manufacture.bom.under_quantity_item_list"),
      path: `${pathname}?subtab=under-quantity-item-list`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "work-info":
        setCurrentTab(pathname + "?subtab=work-info");
        break;
      case "manufacture-item":
        setCurrentTab(pathname + "?subtab=manufacture-item");
        break;
      case "under-quantity-item-list":
        setCurrentTab(pathname + "?subtab=under-quantity-item-list");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const [openItemListModal, setOpenItemListModal] = useState({ value: false });

  const openItemListModalHandler = (type) => {
    setOpenItemListModal({ value: true, type });
  };

  const closeItemListModalHandler = () => {
    setOpenItemListModal((prev) => ({ ...prev, value: false }));
  };

  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { document_id, name, is_active, tag_list } = request.filterModel;
      const formatFilterModal = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        name: name && {
          ...name,
          mode: "insensitive",
        },
        tag_list: tag_list
          ? {
              filterType: "objectArray",
              type: "some",
              filter: {
                name: {
                  filterType: "set",
                  values: tag_list.values,
                },
              },
            }
          : {},
        // item_group_sub_level_1: item_group_sub_level_1 && {
        //   filterType: "object",
        //   filter: {
        //     name: {
        //       ...item_group_sub_level_1,
        //       mode: "insensitive",
        //     },
        //   },
        // },
        is_active: is_active &&
          is_active.values.length > 0 && {
            filterType: "boolean",
            type: "equals",
            filter: is_active.values[0],
          },
      };

      dispatch(
        getAllItemsAggrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModal,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    const instance = params.api.getFilterInstance("is_active");
    instance.setModel({
      filterType: "set",
      values: ["true"],
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const {
    fields: underQuantityFields,
    append: underQuantityAppend,
    remove: underQuantityRemove,
  } = useFieldArray({
    control,
    name: "under_quality_item_list",
  });

  const handleSelectItems = () => {
    const selectedItems = selectUnderQuantityRef.current.api.getSelectedRows();
    selectedItems.forEach((item) => {
      const formatItem = {
        uid: uuidv4(),
        item_document_id: item?.document_id ?? "",
        item_name: item?.name ?? "",
        item_description: item?.description ?? "",
        qty: 1,
        uom: item?.base_uom.name,
        current_ordered_purchase_qty: item?.current_ordered_purchase_qty ?? 0,
        current_ordered_manufacture_qty:
          item?.current_ordered_manufacture_qty ?? 0,
        current_committed_sales_qty: item?.current_committed_sales_qty ?? 0,
        current_committed_manufacture_qty:
          item?.current_committed_manufacture_qty ?? 0,
        current_stock_qty: item?.current_stock_qty ?? 0,
        current_available_qty: item?.current_available_qty ?? 0,
      };
      if (openItemListModal.type === "ingrediant") {
        ingredientAppend(formatItem);
      } else if (openItemListModal.type === "underQuantity") {
        const formatUnderItem = {
          ...formatItem,
          item_remark: "",
        };
        underQuantityAppend(formatUnderItem);
      }
    });
    closeItemListModalHandler();
  };

  const renderTab = (tab) => {
    switch (tab) {
      case "work-info":
        return (
          <WorkInfoTable
            control={control}
            errors={errors}
            setValue={setValue}
            fields={workOrderFields}
            remove={workOrderRemove}
            update={workOrderUpdate}
            disabled={(id && status !== "draft") || disabled}
          />
        );
      case "manufacture-item":
        return (
          <ManuItemListTable
            control={control}
            errors={errors}
            disabled={disabled}
          />
        );
      case "under-quantity-item-list":
        return (
          <BomItemTable
            isUnderQuality
            control={control}
            errors={errors}
            fields={underQuantityFields}
            remove={underQuantityRemove}
            name="under_quality_item_list"
            disabled={disabled}
          />
        );
      default:
        return (
          <BomItemTable
            control={control}
            errors={errors}
            fields={ingredientFields}
            remove={ingredientRemove}
            name="ingredient_list"
            isIngredient
            disabled={(id && status !== "draft") || disabled}
            isTooltip
          />
        );
    }
  };

  const renderButton = (tab) => {
    switch (tab) {
      case "work-info":
        if ((id && status !== "draft") || disabled) {
          return;
        } else {
          return (
            <CustomizedButton
              color="secondary"
              title="เพิ่มการทำงาน"
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => workOrderAppend(dafaultWOValue)}
            />
          );
        }
      case "under-quantity-item-list":
        if ((disabled && status !== "draft") || disabled) {
          return;
        } else {
          return (
            <CustomizedButton
              color="secondary"
              title="เพิ่มสินค้า"
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => openItemListModalHandler("underQuantity")}
            />
          );
        }
      case "manufacture-item":
        return;
      default:
        if ((id && status !== "draft") || disabled) {
          return;
        } else {
          return (
            <CustomizedButton
              color="secondary"
              title="เพิ่มส่วนประกอบ"
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => openItemListModalHandler("ingrediant")}
            />
          );
        }
    }
  };

  return (
    <CustomizedBox>
      <CustomizedTab subtab tabs={tabs} currentTab={currentTab} />
      {renderTab(tab)}
      {renderButton(tab)}
      <ModalUI
        open={openItemListModal.value}
        handleClose={closeItemListModalHandler}
        title="สินค้า"
        width={1500}
      >
        <ItemTable
          gridRef={selectUnderQuantityRef}
          height={450}
          onGridReady={onGridReady}
          rowSelection="multiple"
          enableCheckbox
          getRowId={getRowId}
        />
        <CustomizedButton
          title="เพิ่ม"
          variant="contained"
          onClick={handleSelectItems}
          sx={{ mt: 2 }}
        />
      </ModalUI>
    </CustomizedBox>
  );
};

export default ManufactureOrderTableForm;
