import * as Yup from "yup";

export const schema = {
  last_name: "",
  email: "",
  phone: "",
  img_url: null,
  requester_document_id: "",
  requester_name: "",
  requester_department: "",
  requester_position: "",
  status: "",
  created_date: null,
  last_login_date: null,
  role_list: [],
};

export const validation = Yup.object().shape({
  requester_document_id: Yup.string().required("กรุณาเลือกผู้ขอ"),
  requester_name: Yup.string().required("กรุณาเลือกผู้ขอ"),
  requester_department: Yup.string().required("กรุณาระบุแผนก"),
});
