import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { formatDate } from "../../../../utils/date-converter";

const GoodsTransferPDFInfo = ({ goodsTransfer }) => {
  const mappingStatus = (status) => {
    switch (status) {
      case "draft":
        return "ร่าง";
      case "cancelled":
        return "ยกเลิก";
      case "finished":
        return "เสร็จสิ้น";
      default:
        break;
    }
  };

  const leftHeader = ["เลขที่เอกสาร", "คลังต้นทาง", "คลังปลายทาง", "หมายเหตุ"];
  const leftInfo = [
    goodsTransfer?.document_id || "-",
    goodsTransfer?.source_warehouse?.thai_name || "-",
    goodsTransfer?.destination_warehouse?.thai_name || "-",
    goodsTransfer?.remark || "-",
  ];

  const rightHeader = [
    "สถานะเอกสาร",
    "วันที่สร้าง",
    "วันที่โอนย้าย",
    "ผู้สร้าง",
  ];
  const rightInfo = [
    mappingStatus(goodsTransfer?.status) || "-",
    formatDate(goodsTransfer?.created_date) || "-",
    formatDate(goodsTransfer?.posted_date) || "-",
    goodsTransfer?.created_by
      ? [goodsTransfer?.created_by]
          .map((employee) => employee?.first_name + " " + employee?.last_name)
          .join(",")
      : "-",
  ];

  return (
    <Grid container sx={{ my: 1 }}>
      <Grid item xs={6}>
        {leftHeader.map((name, index) => (
          <Box sx={{ display: "flex" }} key={index}>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 1,
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {leftInfo[index]}
            </Typography>
          </Box>
        ))}
      </Grid>
      <Grid item xs={6}>
        {rightHeader.map((name, index) => (
          <Box sx={{ display: "flex" }} key={index}>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 600,
                color: "#333333",
                flex: 1,
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                flex: 1,
                width: "100%",
                wordBreak: "break-word",
              }}
            >
              {rightInfo[index]}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default GoodsTransferPDFInfo;
