import { Grid } from "@mui/material";
import DocDropzone from "./DocDropzone";

const AttachmentTab = ({ control, setValue, getValues, viewOnly }) => {
  return (
    <>
      <Grid container spacing={2}>
        <DocDropzone
          mainControl={control}
          setValue={setValue}
          getValues={getValues}
          disabled={viewOnly}
        />
      </Grid>
    </>
  );
};

export default AttachmentTab;
