import {
  unixToDateWithFormat,
  unixToDateTimeWithFormatNoSeconds,
  mapStatusToThai,
  unixToDateTimeWithFormat,
} from "./date-converter";

export const formatExportedWorkOrderDurationUnit = (status) => {
  switch (status) {
    case "day":
      return "วัน";
    case "hour":
      return "ชั่วโมง";
    case "minute":
      return "นาที";
    default:
      return "";
  }
};

export const bomsExportFormatter = (data) => {
  let formatted = [];

  data.forEach((bom) => {
    if (bom.ingredient_list.length > 0) {
      bom.ingredient_list.forEach((ingredient) => {
        const formattedIngredient = {
          created_date: unixToDateWithFormat(bom.created_date),
          start_date: bom.start_date
            ? unixToDateWithFormat(bom.start_date)
            : "",
          end_date: bom.end_date ? unixToDateWithFormat(bom.end_date) : "",
          type: bom.type,
          name: bom.name,
          item_document_id: bom.item_document_id,
          item_name: bom.item_name,
          ingredient_name: ingredient.item_name,
          ingredient_qty: ingredient.qty,
          ingredient_uom: ingredient.uom,
          mfg_qty: bom.mfg_qty,
          stock_uom: bom.stock_uom.name,
          internal_remark: bom.internal_remark,
          status: bom.status === "active" ? "ใช้งาน" : "ไม่ใช้งาน",
          description: bom.description,
          is_main_bom: bom.is_main_bom === "TRUE" ? "ใช่" : "ไม่ใช่",
        };
        formatted.push(formattedIngredient);
      });
    } else {
      formatted.push({
        created_date: unixToDateWithFormat(bom.created_date),
        start_date: bom.start_date ? unixToDateWithFormat(bom.start_date) : "",
        end_date: bom.end_date ? unixToDateWithFormat(bom.end_date) : "",
        type: bom.type,
        name: bom.name,
        item_document_id: bom.item_document_id,
        item_name: bom.item_name,
        mfg_qty: bom.mfg_qty,
        stock_uom: bom.stock_uom.name,
        internal_remark: bom.internal_remark,
        status: bom.status === "active" ? "ใช้งาน" : "ไม่ใช้งาน",
        description: bom.description,
        is_main_bom: bom.is_main_bom === "TRUE" ? "ใช่" : "ไม่ใช่",
      });
    }
  });

  return formatted;
};

export const manufactureOrdersExportFormatter = (data) => {
  const newData = [];

  data.forEach((doc) => {
    const {
      name: bom_name,
      type: bom_type,
      thickness_tolerance_negative,
      thickness_tolerance_positive,
      width_tolerance_negative,
      width_tolerance_positive,
    } = doc.bom_detail;

    let {
      created_date,
      updated_date,
      delivery_date,
      expect_date,
      expect_product_date,
      issue_date,
      render_status,
      group,
      reference_document_list,
      ...otherDoc
    } = doc;

    created_date = unixToDateTimeWithFormat(created_date);
    updated_date = unixToDateWithFormat(updated_date);
    delivery_date = unixToDateWithFormat(delivery_date);
    expect_date = unixToDateWithFormat(expect_date);
    expect_product_date = unixToDateWithFormat(expect_product_date);
    issue_date = unixToDateWithFormat(issue_date);
    render_status = mapStatusToThai(render_status);
    reference_document_list = reference_document_list
      .map((list) => list.reference_document_id)
      .join(",");
    group = group?.map((list) => list.name);

    if (doc.ingredient_list.length > 0)
      return doc.ingredient_list.map((list) => {
        let {
          item_name: ingredient_name,
          item_document_id: ingredient_document_id,
          item_description: ingredient_description,
          goods_issue_qty: ingredient_issue_qty,
          qty: ingredient_qty,
          uom: ingredient_uom,
        } = list;

        ingredient_issue_qty = ingredient_issue_qty ?? 0;

        return newData.push({
          ...otherDoc,
          created_date,
          updated_date,
          delivery_date,
          expect_date,
          expect_product_date,
          issue_date,
          bom_name,
          bom_type,
          render_status,
          thickness_tolerance_negative,
          thickness_tolerance_positive,
          width_tolerance_negative,
          width_tolerance_positive,
          ingredient_name,
          ingredient_document_id,
          ingredient_description,
          ingredient_issue_qty,
          ingredient_qty,
          ingredient_uom,
          group,
          reference_document_list,
        });
      });
    else
      return newData.push({
        ...otherDoc,
        created_date,
        updated_date,
        delivery_date,
        expect_date,
        expect_product_date,
        issue_date,
        render_status,
        bom_name,
        bom_type,
        thickness_tolerance_negative,
        thickness_tolerance_positive,
        width_tolerance_negative,
        width_tolerance_positive,
        group,
      });
  });
  return newData;
};

const formatExportedWorkOrderStatus = (status) => {
  switch (status) {
    case "draft":
      return "ร่าง";
    case "in_progress":
      return "กำลังผลิต";
    case "finished":
      return "เสร็จสิ้น";
    case "canceled":
      return "ยกเลิก";
    default:
      return "";
  }
};

export const workOrdersExportFormatter = (data) => {
  const formatted = data.map((workOrder) => {
    const paddedDuration = workOrder.duration?.toString().padStart(2, "0");
    let formattedDuration;
    switch (workOrder.duration_unit) {
      case "day":
        formattedDuration = `${paddedDuration}:00:00`;
        break;
      case "hour":
        formattedDuration = `00:${paddedDuration}:00`;
        break;
      case "minute":
        formattedDuration = `00:00:${paddedDuration}`;
        break;
      default:
        formattedDuration = "";
    }
    return {
      manufacture_order_document_id: workOrder.manufacture_order_document_id,
      created_date: unixToDateWithFormat(workOrder.created_date),
      name: workOrder.name,
      plant: workOrder.plant,
      wo_group: workOrder.manufacture_order?.group.map((tag) => tag.name),
      machine: workOrder.machine,
      start_date_time: unixToDateTimeWithFormatNoSeconds(
        workOrder.start_date_time
      ),
      status: formatExportedWorkOrderStatus(workOrder.status),
      duration: formattedDuration,
      description: workOrder.description,
    };
  });

  return formatted;
};

export const underqualityItemsExportFormatter = (data) => {
  const formatted = [];
  data.forEach((mo) => {
    if (mo.under_quality_item_list.length > 0) {
      mo.under_quality_item_list.forEach((item) => {
        const formattedItem = {
          manufacture_order_document_id: mo.document_id,
          issue_date: mo.issue_date ? unixToDateWithFormat(mo.issue_date) : "",
          expect_product_date: mo.expect_product_date
            ? unixToDateWithFormat(mo.expect_product_date)
            : "",
          item_document_id: item.item_document_id,
          item_name: item.item_name,
          qty: item.qty,
          uom: item.uom,
          item_description: item.item_description,
          type: mo.type,
          group: mo.group?.map((group) => group.name),
          internal_remark: mo.internal_remark,
        };
        formatted.push(formattedItem);
      });
    }
  });
  return formatted;
};
