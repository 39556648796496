import { Box } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { deliveryOrderActions } from "../../../features/Logistic/DeliveryOrder/delivery-order-slice";
import {
  logisticPayload,
  setLogisticValueFormatter,
  getItemsWithWeightVolume,
} from "../../../utils/logisticPayloadFormatter";
import {
  schema as contactSchema,
  validation as contactValidation,
} from "../../../components/Form/Contact/schema";
import {
  schema as customerDescSchema,
  validation as customerDescValidation,
} from "../../../components/Form/Sales/CustomerDescription/schema";
import SalesHeaderForm from "../../../components/Form/Sales/Header/index";
import {
  deliveryOrderSchema,
  deliveryOrderValidation,
} from "../../../components/Form/Sales/Header/schema";
import DeliveryTripTab from "./DeliveryTripTab";
import {
  createNewDeliveryOrder,
  updateDeliveryOrder,
  getDeliveryOrderById,
} from "../../../features/Logistic/DeliveryOrder/delivery-order-actions";
import {
  logisticPrimaryButtonHandler,
  logisticSecondaryButtonHandler,
} from "../../../utils/salesButtonHandler";
import moment from "moment";
import { validationItemList } from "../../../components/Form/Sales/AddItemListForm/schema";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import {
  detailsDefaultValues,
  detailsValidation,
} from "../../../components/Form/Logistic/DeliveryDetails/schema";
import { uploadFileToS3 } from "../../../utils/s3";
import { contactActions } from "../../../features/Contact/contact-slice";
import GlobalService from "../../../services/Global";

const defaultValues = {
  render_status: null,
  price_vat_type: "excluded_vat",
  contact_document_id: "",
  creator_document_id: "",
  created_by: {},
  item_list: [],
  shipping_cost: 0,
  additional_discount: 0,
  additional_discount_type: "baht",
  summary: {},
  attachment_list: [],
  template_remark_id: "",
  remark: "",
  internal_remark: "",
};

const DeliveryOrderContainer = () => {
  const [viewOnly, setViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [primaryButtonTitle, setPrimaryButtonTitle] = useState("ยืนยัน");
  const [secondaryButtonTitle, setSecondaryButtonTitle] =
    useState("บันทึกร่าง");
  const [openDeliveryDetail, setOpenDeliveryDetail] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { contactSnapshot, isInit: contactIsInit } = useSelector(
    (state) => state.contact
  );
  const { createPermission, editPermission } = usePermission();
  const { deliveryOrder, isLoading } = useSelector(
    (state) => state.deliveryOrder
  );
  const [showButton, setShowButton] = useState(false);
  const { user } = useAuth();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const headerRef = useRef();
  const customerDescRef = useRef();
  const itemListRef = useRef();
  const {
    control,
    setValue,
    getValues,
    trigger: validateItemList,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationItemList),
  });
  const [currentTab, setCurrentTab] = useState(pathname);
  const [itemsWeightVolume, setItemsWeightVolume] = useState([]);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(contactActions.uninitialize());
  }, [dispatch]);

  const watchStatus = useWatch({ control, name: "render_status" });

  const customerDescUseForm = useForm({
    defaultValues: { ...customerDescSchema },
    resolver: yupResolver(customerDescValidation),
  });

  const {
    trigger: validateCustomerDescription,
    setValue: setCustomerDescValue,
    getValues: getCustomerDescValues,
    getFieldState: getCustomerDescFieldState,
  } = customerDescUseForm;

  const contactUseForm = useForm({
    defaultValues: { ...contactSchema },
    resolver: yupResolver(contactValidation),
  });

  const deliveryDetailsUseForm = useForm({
    defaultValues: detailsDefaultValues,
    resolver: yupResolver(detailsValidation),
  });

  const {
    getValues: getDeliveryDetailsValues,
    reset,
    setValue: setDeliveryDetailsValues,
    trigger: validateDeliveryDetails,
  } = deliveryDetailsUseForm;

  const {
    control: headerControl,
    setValue: setHeaderValue,
    getValues: getHeaderValues,
    trigger: validateHeader,
    formState: { errors: headerErrors },
    getFieldState: getHeaderFieldState,
  } = useForm({
    defaultValues: { ...deliveryOrderSchema },
    resolver: yupResolver(deliveryOrderValidation),
  });
  const [itemQtySnapshot, setItemQtySnapshot] = useState([]);

  const breadcrumbs = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.deliveryOrder.index"),
      to: "/logistic/delivery-order",
    },
    {
      name: id ?? t("logistic.deliveryOrder.create"),
    },
  ];

  const tabs = [
    {
      label: t("documentInfo"),
      path: `${pathname}`,
    },
    {
      label: t("logistic.deliveryTrip.index"),
      path: `${pathname}?tab=delivery-trip`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "delivery-trip":
        setCurrentTab(pathname + "?tab=delivery-trip");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  useEffect(() => {
    switch (watchStatus) {
      case "waitDeliver":
      case "notCompleted":
      case "completed":
      case "cancelled":
        setViewOnly(true);
        break;
      default:
        setViewOnly(false);
    }
  }, [watchStatus]);

  useEffect(() => {
    if (state && state.item_list) {
      const loadDeliveryOrdersWithItemsWeightVolume = async () => {
        const itemsWeightVolume = await getItemsWithWeightVolume(
          state.item_list
        );
        setItemsWeightVolume(itemsWeightVolume);
      };
      loadDeliveryOrdersWithItemsWeightVolume();
    }
  }, [state]);

  useEffect(() => {
    if (state) {
      dispatch(
        deliveryOrderActions.loadedDeliveryOrder({
          ...state,
          item_list: itemsWeightVolume,
        })
      );
    } else if (id) {
      dispatch(
        getDeliveryOrderById(
          {
            document_id: id,
          },
          enqueueSnackbar
        )
      );
    }
    return () => {
      dispatch(deliveryOrderActions.resetDeliveryOrder());
    };
  }, [dispatch, enqueueSnackbar, state, id, user, itemsWeightVolume]);

  useEffect(() => {
    if (state) {
      setLogisticValueFormatter(
        deliveryOrderSchema,
        customerDescSchema,
        defaultValues,
        null,
        setHeaderValue,
        setCustomerDescValue,
        setValue,
        null,
        { ...state, item_list: itemsWeightVolume },
        true,
        "do"
      );
    } else if (id) {
      setLogisticValueFormatter(
        deliveryOrderSchema,
        customerDescSchema,
        defaultValues,
        detailsDefaultValues,
        setHeaderValue,
        setCustomerDescValue,
        setValue,
        setDeliveryDetailsValues,
        deliveryOrder,
        false
      );
    }
  }, [
    deliveryOrder,
    setValue,
    setHeaderValue,
    state,
    setCustomerDescValue,
    id,
    setDeliveryDetailsValues,
    itemsWeightVolume,
  ]);

  const submitContactForm = (data) => {
    setCustomerDescValue("contact", data);
  };

  //TODO consider validating individual field for specific error message
  const submitForm = async () => {
    const customerDescIsValid = await validateCustomerDescription();
    const headerIsValid = await validateHeader();
    const itemListIsValid = await validateItemList();
    const deliveryDetailsIsValid = await validateDeliveryDetails();

    const documentIdError = getHeaderFieldState("document_id").error;
    const issueDateError = getHeaderFieldState("issue_date").error;
    const dueDateError = getHeaderFieldState("due_date").error;
    const deliveryDateError = getHeaderFieldState("delivery_date").error;
    const customerError = getCustomerDescFieldState("customer").error;
    const itemListError = getFieldState("item_list").error;

    const errorsCount =
      (documentIdError ? 1 : 0) +
      (issueDateError ? 1 : 0) +
      (dueDateError ? 1 : 0) +
      (customerError ? 1 : 0) +
      (deliveryDateError ? 1 : 0) +
      (itemListError ? 1 : 0);

    if (errorsCount > 1) {
      enqueueSnackbar("กรุณาตรวจสอบข้อมูลให้ครบถ้วน", {
        variant: "error",
      });
    }

    if (itemListError?.length > 0) {
      if (itemListError.some((item) => item.is_active)) {
        enqueueSnackbar("กรุณาระบุสินค้าใหม่ เนื่องจากมีสินค้าที่หยุดใช้งาน", {
          variant: "error",
        });
      }
      if (itemListError.some((item) => item.price_per_unit)) {
        enqueueSnackbar("กรุณากรอกจำนวนเงินที่มากกว่า 0", {
          variant: "error",
        });
      }
    } else if (itemListError?.message) {
      enqueueSnackbar(itemListError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(documentIdError)) {
      enqueueSnackbar(documentIdError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(issueDateError)) {
      enqueueSnackbar(issueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(dueDateError)) {
      enqueueSnackbar(dueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(deliveryDateError)) {
      enqueueSnackbar(deliveryDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(customerError)) {
      enqueueSnackbar("กรุณาเลือกลูกค้า", {
        variant: "error",
      });
    }
    if (!headerIsValid) {
      headerRef.current.scrollIntoView();
      return;
    }
    if (!customerDescIsValid) {
      customerDescRef.current.scrollIntoView();
      return;
    }
    if (!itemListIsValid && itemListError) {
      itemListRef.current.scrollIntoView();
      return;
    }
    if (!deliveryDetailsIsValid && watchStatus === "waitDeliver" && !isEdit) {
      return;
    }
    if (customerDescIsValid && headerIsValid && itemListIsValid) {
      const { status, summary, ...mainValues } = getValues();
      const headerValues = getHeaderValues();
      const { customer, identity_no, ...customerDescValues } =
        getCustomerDescValues();
      const deliveryDetails = getDeliveryDetailsValues();
      const {
        recipient_name,
        delivery_reference,
        delivery_detail,
        attachment_list,
      } = deliveryDetails;

      const { reference_document_id } = headerValues;

      let isExceed = false;
      if (!watchStatus || watchStatus === "draft" || isEdit) {
        const salesOrderItemList = await GlobalService.getDocumentRelationQty({
          document_id: reference_document_id[0].reference_document_id,
          document_type: "sales_order",
          reference_document_type: "delivery_order",
        });
        const itemList = getValues("item_list");

        salesOrderItemList.forEach((item) => {
          //Find the actual index compare with current item if the index have adjusted
          const findIndex = itemList.findIndex(
            (doItem) => doItem.uid === item.uid
          );
          if (findIndex !== -1) {
            if (isEdit) {
              //Find the actual index compare with item snapshot if if the index have adjusted
              const findSnapshotIndex = itemQtySnapshot.findIndex(
                (doItem) => doItem.uid === item.uid
              );
              if (findSnapshotIndex !== -1) {
                //Lastest qty from current document
                const currentMaximum =
                  item.qty -
                  (item.accumulate_delivery -
                    itemQtySnapshot[findSnapshotIndex].qty);
                if (itemList[findIndex].qty > currentMaximum) {
                  isExceed = true;
                }
              }
            } else {
              if (
                itemList[findIndex].qty >
                item.qty - item.accumulate_delivery
              ) {
                isExceed = true;
              }
            }
          }
        });
      }

      if (isExceed) {
        enqueueSnackbar("มีรายการสินค้าเกินจำนวน กรุณาระบุใหม่อีกครั้ง", {
          variant: "error",
        });
        return;
      } else {
        if (attachment_list.length > 0) {
          for (const [index, value] of attachment_list.entries()) {
            if (value && value instanceof File) {
              const { Location } = await uploadFileToS3(
                value,
                "deliveryOrder",
                user.document_id
              );
              attachment_list[index].url = Location;
            }
          }
        }

        const formatDeliveryDetails = {
          recipient_name,
          delivery_reference,
          delivery_detail,
          attachment_list:
            attachment_list.length > 0
              ? attachment_list.map((attachment) => ({
                  name: attachment.fileName ?? attachment.name,
                  url: attachment.url,
                  creator_document_id: user.document_id,
                }))
              : [],
        };

        const tempValues = {
          updated_date: moment().unix(),
        };
        const transformedEmployeeList =
          headerValues.employee_list?.map(
            ({
              id,
              status,
              jwt_modifier,
              exp,
              iat,
              created_date,
              last_login_date,
              role_list,
              ...employee
            }) => employee
          ) ?? [];
        const payload = logisticPayload(
          headerValues,
          tempValues,
          mainValues,
          transformedEmployeeList,
          user,
          contactSnapshot,
          summary,
          customerDescValues,
          id,
          "do",
          formatDeliveryDetails
        );
        return payload;
      }
    }
  };

  const saveDraftHandler = async () => {
    // setShowButton(false);
    setDisabled(true);
    const payload = await submitForm();
    setDisabled(false);
    if (payload) {
      if (!id) {
        dispatch(
          createNewDeliveryOrder(
            payload,
            false,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        dispatch(
          updateDeliveryOrder(
            payload,
            user,
            watchStatus,
            false,
            deliveryOrder,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const sendDeliverHandler = async () => {
    // setShowButton(false);
    setDisabled(true);
    const payload = await submitForm();
    setDisabled(false);
    if (payload) {
      const sendDeliverPayload = {
        ...payload,
        update_item_list: true,
      };
      if (!id) {
        dispatch(
          createNewDeliveryOrder(
            payload,
            true,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        dispatch(
          updateDeliveryOrder(
            sendDeliverPayload,
            user,
            watchStatus,
            true,
            deliveryOrder,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const editSubmitHandler = async () => {
    // setShowButton(false);
    setDisabled(true);
    const payload = await submitForm();
    setDisabled(false);
    if (payload) {
      delete payload.reference_document_id;
      dispatch(
        updateDeliveryOrder(
          payload,
          user,
          watchStatus,
          false,
          deliveryOrder,
          enqueueSnackbar
        )
      );
      setIsEdit(false);
      setViewOnly(true);
    }
  };

  const editCancelHandler = () => {
    navigate("/logistic/delivery-order");
  };

  useEffect(() => {
    if (isLoading.deliveryOrder || !contactIsInit) {
      setShowButton(false);
    } else {
      if (isEdit) {
        setShowButton(true);
      } else {
        switch (watchStatus) {
          case "draft":
          case "":
          case null:
          case undefined:
            setShowButton(true);
            break;
          default:
            setShowButton(false);
        }
      }
    }
  }, [contactIsInit, isEdit, isLoading.deliveryOrder, watchStatus]);

  useEffect(() => {
    if (isEdit) {
      setPrimaryButtonTitle("บันทึก");
      setSecondaryButtonTitle("ยกเลิก");
    }
  }, [isEdit]);

  const primarySubmitHandler = () => {
    logisticPrimaryButtonHandler(
      watchStatus,
      isEdit,
      sendDeliverHandler,
      editSubmitHandler
    );
  };

  const secondarySubmitHandler = async () => {
    logisticSecondaryButtonHandler(
      watchStatus,
      isEdit,
      saveDraftHandler,
      editCancelHandler
    );
  };

  const editClickHandler = () => {
    setViewOnly(false);
    setIsEdit(true);
    setItemQtySnapshot(deliveryOrder.item_list);
  };

  const onDeliveryDetailOpenHandler = () => {
    setOpenDeliveryDetail(true);
  };

  const onDeliveryDetailCloseHandler = () => {
    if (watchStatus !== "notCompleted" && watchStatus !== "completed") {
      reset(detailsDefaultValues);
    }
    setOpenDeliveryDetail(false);
  };

  const submitDeliveryDetail = async () => {
    setIsEdit(false);
    setDisabled(true);
    const payload = await submitForm();
    setDisabled(false);
    if (payload) {
      delete payload.reference_document_id;
      let step;
      const delivery_status = getDeliveryDetailsValues("delivery_status");
      if (delivery_status === "completed") step = 4;
      else if (delivery_status === "notCompleted") step = 3;
      onDeliveryDetailCloseHandler();
      dispatch(
        updateDeliveryOrder(
          payload,
          user,
          watchStatus,
          false,
          deliveryOrder,
          enqueueSnackbar,
          true,
          step
        )
      );
    }
  };

  const renderTab = (tab) => {
    switch (tab) {
      case "delivery-trip":
        return <DeliveryTripTab />;
      default:
        return (
          <>
            <SalesHeaderForm
              ref={headerRef}
              control={headerControl}
              errors={headerErrors}
              getValues={getHeaderValues}
              getMainValues={getValues}
              setValue={setHeaderValue}
              setMainValue={setValue}
              mainFormControl={control}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              documentType="delivery_order"
              editClickHandler={editClickHandler}
              currentState={state?.id || deliveryOrder}
              isEdit={isEdit}
              onDeliveryDetailOpenHandler={onDeliveryDetailOpenHandler}
              setIsEdit={setIsEdit}
            />
            <DocumentInfoTab
              ref={customerDescRef}
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              submitCustomerDesc={submitContactForm}
              customerDescUseForm={customerDescUseForm}
              contactUseForm={contactUseForm}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              status={watchStatus}
              deliveryDetailsUseForm={deliveryDetailsUseForm}
              itemListRef={itemListRef}
              openDeliveryDetail={openDeliveryDetail}
              onDeliveryDetailCloseHandler={onDeliveryDetailCloseHandler}
              submitDeliveryDetail={submitDeliveryDetail}
              submitForm={submitForm}
              reset={reset}
            />
            {showButton &&
              ((createPermission && !id) || (editPermission && id)) && (
                <Box sx={{ display: "flex" }}>
                  {secondaryButtonTitle && (
                    <Box sx={{ mr: 1 }}>
                      <CustomizedButton
                        title={secondaryButtonTitle}
                        variant="outlined"
                        type="reset"
                        onClick={secondarySubmitHandler}
                        disabled={isLoading.deliveryOrder || disabled}
                      />
                    </Box>
                  )}
                  <CustomizedButton
                    title={primaryButtonTitle}
                    variant="contained"
                    onClick={primarySubmitHandler}
                    disabled={isLoading.deliveryOrder || disabled}
                  />
                </Box>
              )}
          </>
        );
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </>
  );
};

export default DeliveryOrderContainer;
