import { useState, useEffect } from "react";
import { Controller, useForm, useFieldArray, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { contactActions } from "../../../../features/Contact/contact-slice";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";
import ContactCard from "../Shared/ContactCard";
import ModalUI from "../../../UI/ModalUI";
import DropzoneUI from "../../../UI/DropzoneUI";

const defaultContactChannelValues = {
  contact_channel: "เบอร์โทรศัพท์",
  contact_info_1: "",
  contact_info_2: "",
};

const defaultNewContactState = {
  position: "",
  initial: "",
  name_1: "",
  name_2: "",
  name_3: "",
  contact_channel_list: [],
  remark: "",
  img_url: "",
};

const NewContactTab = ({ control, errors, getValues, viewOnly }) => {
  const { isReset, contactPersonListIsInit, contactSnapshot } = useSelector(
    (state) => state.contact
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isInit, setIsInit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [files, setFiles] = useState([]);
  const [tempImageUrl, setTempImageUrl] = useState(null);

  const {
    append: appendContactPerson,
    update: updatedContactPerson,
    remove: removeContactPerson,
  } = useFieldArray({
    control,
    name: "contact_person_list",
  });

  const {
    control: newContactControl,
    getValues: getNewContactValues,
    setValue: setNewContactValue,
    reset: resetNewContactForm,
  } = useForm({
    defaultValues: { ...defaultNewContactState },
  });

  const {
    fields: contactChannelFields,
    append: appendContactChannel,
    remove: removeContactChannel,
  } = useFieldArray({
    control: newContactControl,
    name: "contact_channel_list",
  });

  const watchNewContactChannelList = useWatch({
    control: newContactControl,
    name: "contact_channel_list",
  });

  const controlledChannelFields = contactChannelFields.map((field, index) => {
    return { ...field, ...watchNewContactChannelList[index] };
  });

  const {
    control: displayCardsControl,
    setValue: setDisplayCardsValue,
    getValues: getDisplayCardsValues,
    watch: watchDisplayCards,
    formState: { errors: displayCardsErrors },
    reset: resetDisplayCards,
  } = useForm({
    defaultValues: { contact_person_list: getValues("contact_person_list") },
  });

  const {
    fields: displayCardsFields,
    append: appendDisplayCard,
    update: updateDisplayCard,
    remove: removeDisplayCard,
  } = useFieldArray({
    control: displayCardsControl,
    name: "contact_person_list",
  });

  const watchedDisplayCards = watchDisplayCards("contact_person_list");

  const controlledDisplayCards = displayCardsFields.map((field, index) => {
    return {
      ...field,
      ...watchedDisplayCards[index],
    };
  });

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const contactChannels = [
    "เบอร์โทรศัพท์",
    "Email",
    "แฟกซ์",
    "Line",
    "Facebook",
    "Website",
    "Instagram",
    "อื่นๆ",
  ];

  const updateDisplayCards = (contactPersons) => {
    const updatedDisplayCards = contactPersons?.map((item) => {
      if (!Array.isArray(item.img_url) || item.img_url.length === 0) {
        return item;
      }
      const image = { ...item.img_url };
      image[0].preview = URL.createObjectURL(image[0]);
      const updatedItem = {
        ...item,
        img_url: image,
      };
      return updatedItem;
    });
    return updatedDisplayCards;
  };

  useEffect(() => {
    if (!contactPersonListIsInit && contactSnapshot) {
      const contactPersons = contactSnapshot.contact_person_list ?? [];
      const updatedDisplayCards = updateDisplayCards(contactPersons);
      resetDisplayCards({ contact_person_list: updatedDisplayCards });
    }
    setIsInit(true);
    dispatch(contactActions.initalizeContactPersonList());
  }, [
    contactPersonListIsInit,
    contactSnapshot,
    resetDisplayCards,
    displayCardsFields,
    dispatch,
  ]);

  useEffect(() => {
    if (isReset) {
      if (contactSnapshot) {
        const snapshotContacts = contactSnapshot.contact_person_list ?? [];
        resetDisplayCards({ contact_person_list: snapshotContacts });
      } else {
        resetDisplayCards({ contact_person_list: [] });
      }
      displayCardsFields.forEach((field) => {
        if (Array.isArray(field.img_url)) {
          URL.revokeObjectURL(field.img_url[0].preview);
        }
      });
    }
  }, [
    isReset,
    contactSnapshot,
    resetDisplayCards,
    dispatch,
    displayCardsFields,
  ]);

  useEffect(() => {
    if (!isInit) {
      const updatedDisplayCards = updateDisplayCards(
        getDisplayCardsValues("contact_person_list")
      );
      setDisplayCardsValue("contact_person_list", updatedDisplayCards);
      setIsInit(true);
    }

    return () => {
      displayCardsFields.forEach((field) => {
        if (Array.isArray(field.img_url)) {
          URL.revokeObjectURL(field.img_url[0]?.preview);
        }
      });
    };
    // eslint-disable-next-line
  }, []);

  const stopEditingHandler = () => {
    setFiles([]);
    setIsEditing(false);
    setEditingIndex(null);
  };

  const startEditingHandler = () => {
    resetNewContactForm({ ...defaultNewContactState });
    setIsEditing(true);
  };

  const editContactCardHandler = (index) => {
    const contactPerson = getValues("contact_person_list")[index];
    const contactChannelList = contactPerson.contact_channel_list ?? [];
    setIsEditing(true);
    resetNewContactForm({
      ...contactPerson,
      contact_channel_list: contactChannelList,
    });
    setFiles([contactPerson.img_url]);
    setTempImageUrl(contactPerson.img_url);
    setEditingIndex(index);
  };

  const submitContactHandler = () => {
    const newContactValue = getNewContactValues();
    if (
      typeof newContactValue.img_url !== "string" &&
      newContactValue.img_url.length > 0 &&
      newContactValue.img_url[0]
    ) {
      newContactValue.img_url[0].preview = URL.createObjectURL(
        newContactValue.img_url[0]
      );
    }
    let updatedImage;
    if (
      Array.isArray(newContactValue.img_url) &&
      newContactValue.img_url.length > 0
    ) {
      updatedImage = newContactValue.img_url[0];
    } else if (newContactValue.img_url.length === 0) {
      updatedImage = "";
    } else {
      updatedImage = tempImageUrl;
    }

    if (newContactValue.name_1 || newContactValue.name_3) {
      if (editingIndex !== null) {
        updateDisplayCard(editingIndex, {
          ...newContactValue,
          img_url: updatedImage,
        });
        updatedContactPerson(editingIndex, {
          ...newContactValue,
          img_url: updatedImage,
        });
      } else {
        appendDisplayCard({
          ...newContactValue,
          img_url: updatedImage,
        });
        appendContactPerson({
          ...newContactValue,
          img_url: updatedImage,
        });
      }
    }
    setFiles([]);
    setIsEditing(false);
    setEditingIndex(null);
    setTempImageUrl(null);
    resetNewContactForm({ ...defaultNewContactState });
  };

  const removeContactPersonHandler = (index) => {
    removeDisplayCard(index);
    removeContactPerson(index);
  };

  const renderContactsList = () => {
    if (!controlledDisplayCards) {
      return null;
    }
    return controlledDisplayCards
      .filter((contact) => {
        return contact.name_1 || contact.name_3;
      })
      .map((item, index) => {
        const image =
          item.img_url !== null
            ? item.img_url[0]?.preview || item.img_url
            : null;
        const imageAlt =
          item.img_url !== null
            ? item.img_url[0]?.name || `img-${index}`
            : "image";
        const contactChannel = item.contact_channel_list
          ? item.contact_channel_list[0]?.contact_channel ?? ""
          : "";
        const contactInfo = item.contact_channel_list
          ? item.contact_channel_list[0]?.contact_info_1 ?? ""
          : "";
        const subcontent =
          contactChannel || contactInfo
            ? contactChannel + ": " + contactInfo
            : "";
        return (
          <Grid key={item.id} item xs={12} sm={6} md={3} lg={3} xl={2}>
            <ContactCard
              control={control}
              errors={displayCardsErrors}
              img={image}
              imgAlt={imageAlt}
              title={item.position}
              initial={item.initial}
              first_name={item.name_1}
              last_name={item.name_2}
              nickname={item.name_3}
              subcontent={subcontent}
              index={index}
              onEdit={editContactCardHandler}
              onRemove={removeContactPersonHandler}
              viewOnly={viewOnly}
            />
          </Grid>
        );
      });
  };

  const renderContactChannels = () => {
    return controlledChannelFields.map((item, index) => {
      return (
        <Grid item xs={12} key={item.id}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              order={{ xs: 2, md: 1 }}
            >
              <Controller
                control={newContactControl}
                error={errors}
                name={`contact_channel_list.${index}.contact_channel`}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label={t("contact.contact_channel.type")}
                    isOptionEqualToValue={(option, value) => {
                      if (value === "") {
                        return true;
                      }
                      return option === value;
                    }}
                    onChange={(e, value) => field.onChange(value)}
                    options={contactChannels}
                    disabled={viewOnly}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              order={{ xs: 2, md: 3 }}
            >
              <Controller
                name={`contact_channel_list.${index}.contact_info_1`}
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(
                      errors.contact_channel_list?.[index]?.contact_info_1
                    )}
                    helperText={
                      errors.contact_channel_list?.[index]?.contact_info_1
                        ?.message
                    }
                    label={t("contact.contact_channel.info")}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Grid>
            {!viewOnly && (
              <Grid item xs={1} order={{ xs: 1, md: 4 }}>
                <IconButton
                  aria-label="delete"
                  onClick={() => removeContactChannel(index)}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      <CustomizedButton
        title={"เพิ่มบุคคลติดต่อ"}
        variant="outlined"
        size="medium"
        onClick={startEditingHandler}
        disabled={viewOnly}
      />
      <Grid container spacing={2}>
        {isInit && renderContactsList()}
      </Grid>

      <ModalUI
        open={isEditing}
        handleClose={stopEditingHandler}
        fullWidth
        maxWidth="sm"
      >
        <Typography fontWeight={"bold"} sx={{ ml: 1, my: 2 }}>
          {t("contact.contact_person.index")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="position"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedCreatable
                    {...field}
                    options={[]}
                    title={t("contact.contact_person.position")}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      if (option.value) {
                        return field.onChange(option.value);
                      }
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    readOnly={viewOnly}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="initial"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedSelect
                    fullWidth
                    error={Boolean(errors.initial)}
                    helperText={errors.initial?.message}
                    label={t("contact.contact_person.prefix.index")}
                    options={prefixes}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="name_1"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.name_1)}
                    helperText={errors.name_1?.message}
                    label={t("contact.contact_person.name.first_name")}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="name_2"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.name_2)}
                    helperText={errors.name_2?.message}
                    label={t("contact.contact_person.name.last_name")}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="name_3"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.name_3)}
                    helperText={errors.name_3?.message}
                    label={t("contact.contact_person.name.nickname")}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            {viewOnly && getNewContactValues("img_url") && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: 200,
                  p: 2,
                  border: "1px solid #C8C8C8",
                  borderRadius: "5px",
                  objectFit: "contain",
                  overflow: "hidden",
                }}
              >
                <img
                  src={getNewContactValues("img_url")}
                  alt="user profile"
                  style={{ height: "100%", width: "auto" }}
                />
              </Box>
            )}
            {!viewOnly && (
              <DropzoneUI
                setValue={setNewContactValue}
                valueToSet={"img_url"}
                manualRevoke
                filesLimit={1}
                files={files}
                setFiles={setFiles}
                disabled={viewOnly}
              />
            )}
          </Grid>
        </Grid>
        <Box sx={{ my: 4 }}>
          <Divider variant="fullWidth" />
        </Box>
        <Typography fontWeight={"bold"} sx={{ ml: 1, mb: 2 }}>
          {t("contact.contact_channel.index")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomizedButton
              title={"เพิ่มช่องทางการติดต่อ"}
              variant="outlined"
              size="medium"
              onClick={() =>
                appendContactChannel({ ...defaultContactChannelValues })
              }
              disabled={viewOnly}
            />
          </Grid>
          {renderContactChannels()}
        </Grid>
        <Box sx={{ my: 4 }}>
          <Divider variant="fullWidth" />
        </Box>
        <Typography fontWeight={"bold"} sx={{ ml: 1, mb: 2 }}>
          หมายเหตุ
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="remark"
              control={newContactControl}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  multiline
                  rows={4}
                  error={Boolean(errors.remark)}
                  helperText={errors.remark}
                  {...field}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
        </Grid>
        {!viewOnly && (
          <Box sx={{ my: 2 }}>
            <CustomizedButton
              title={editingIndex !== null ? "บันทึก" : "เพิ่มบุคคล"}
              variant="contained"
              size="medium"
              onClick={submitContactHandler}
              disabled={viewOnly}
            />
          </Box>
        )}
      </ModalUI>
    </>
  );
};

export default NewContactTab;
