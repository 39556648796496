import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./itemMaster-initials";

const itemMasterSlice = createSlice({
  name: "itemMaster",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    onLoaded(state, action) {
      state.isLoading[action.payload] = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllItems(state, action) {
      state.allItems = action.payload.allItems;
      state.count = action.payload.count;
      state.isLoading.allItems = false;
    },
    loadedAllCategory(state, action) {
      state.categoryItem = action.payload;
    },
    loadedAllProperty(state, action) {
      state.property = action.payload;
    },
    loadedItem(state, action) {
      state.item = action.payload;
      state.isLoading.item = false;
    },
    resetItem(state) {
      state.item = initialState.item;
    },
    appendedNewItem(state, action) {
      state.allItems = [...state.allItems, action.payload];
    },
  },
});

export const itemMasterActions = itemMasterSlice.actions;

export default itemMasterSlice.reducer;
