import SalesService from "../../../services/Sales";
import { quotationActions } from "./quotation-slice";
import {
  salesExportFormatter,
  salesQueryFormatter,
} from "../../../utils/salesPayloadFormatter";
import GlobalService from "../../../services/Global";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogApprovePayload,
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import moment from "moment";
import Inventory from "../../../services/Inventory";

export const getAllQuotations =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(quotationActions.onLoading("allQuotations"));
    try {
      const allQuotations = await SalesService.getAllQuotations(input);
      params.successCallback(allQuotations.results, allQuotations.count);
      dispatch(quotationActions.loadedAllQuotations(allQuotations.results));
    } catch (err) {
      console.error(err);
      dispatch(
        quotationActions.rejectedActions({ ...err, name: "allQuotations" })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllQuotationsExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(quotationActions.onLoading("allQuotations"));
    try {
      const { results } = await SalesService.getAllQuotations(input);
      let quotations = salesExportFormatter(results, "quotation");
      dispatch(quotationActions.loadedAllQuotationsExport(quotations));
    } catch (err) {
      console.error(err);
      dispatch(
        quotationActions.rejectedActions({ ...err, name: "allQuotations" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllQuotationsExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(quotationActions.onLoading("allQuotationsExport"));
    try {
      const { results } = await SalesService.getAllQuotationsReport(input);
      let quotations = salesExportFormatter(results, "quotation");
      dispatch(quotationActions.loadedAllQuotationsExport(quotations));
    } catch (err) {
      console.log(err);
      dispatch(
        quotationActions.rejectedActions({
          ...err,
          name: "allQuotationsExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewQuotation =
  (payload, isSendApprove, state, user, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(quotationActions.onLoading("quotation"));
    try {
      const result = await SalesService.createQuotaion(payload);
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const createActivityLog = createActivityLogPayload(
        activityLogsPayload,
        "quotation",
        "สร้างใบเสนอราคา"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      if (state && state?.isCopied) {
        const activityLogsPayload = {
          document_id: state.document_id,
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogsPayload,
          "quotation"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }
      if (isSendApprove) {
        const documentInput = {
          document_type: "quotation",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          activityLogsPayload,
          "quotation",
          null,
          "wait_approve"
        );
        await SalesService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
          variant: "success",
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          activityLogsPayload,
          "quotation",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(`/sales/quotation/${encodeURIComponent(result.document_id)}`);
    } catch (err) {
      console.error(err);
      dispatch(quotationActions.rejectedActions({ ...err, name: "quotation" }));
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบเสนอราคาไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getQuotationById =
  (uniqueInput, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(quotationActions.onLoading("quotation"));
    try {
      const quotation = await SalesService.getQuotationById(uniqueInput);
      const formatQuotation = await salesQueryFormatter(quotation, "quotation");

      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("quotation");

      const approvalProgress = await SalesService.getApprovalProgress({
        reference_document_type: "quotation",
        reference_document_id: uniqueInput.document_id,
      });

      dispatch(
        quotationActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress,
        })
      );

      dispatch(
        quotationActions.updateApprovalStatus({
          approval_list: approvalProgress,
          user,
        })
      );

      dispatch(quotationActions.loadedQuotation(formatQuotation));
    } catch (err) {
      console.error(err);
      dispatch(quotationActions.rejectedActions({ ...err, name: "quotation" }));
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getQuotationPriceList =
  (item_document_id, contact_document_id, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(quotationActions.onLoading("quotationPriceList"));
    try {
      const formatQuotationAggrid = {
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          item_document_id_list: {
            filterType: "array",
            type: "has",
            values: item_document_id,
          },
          contact_document_id: {
            filterType: "text",
            type: "contains",
            filter: contact_document_id ?? "",
            mode: "insensitive",
          },
          render_status: {
            values: ["accepted", "finished", "waitAccept"],
            filterType: "set",
          },
        },
        sortModel: [
          {
            sort: "desc",
            colId: "issue_date",
          },
        ],
      };

      const formatSalesOrderAggrid = {
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          item_document_id_list: {
            filterType: "array",
            type: "has",
            values: item_document_id,
          },
          contact_document_id: {
            filterType: "text",
            type: "contains",
            filter: contact_document_id ?? "",
            mode: "insensitive",
          },
          render_status: {
            values: ["approved", "finished"],
            filterType: "set",
          },
        },
        sortModel: [
          {
            sort: "desc",
            colId: "issue_date",
          },
        ],
      };
      const allQuotationPriceLists = await SalesService.getQuotationPriceList(
        formatQuotationAggrid
      );

      const itemById = await Inventory.getItem({
        document_id: item_document_id,
      });

      let quotation_item_list = [];
      let sales_order_item_list = [];

      for (let quotation of allQuotationPriceLists.results) {
        const formatItemList = quotation.item_list.filter(
          (item) => item.item_document_id === item_document_id
        );
        for (let item of formatItemList) {
          const formatItem = {
            ...item,
            document_id: quotation.document_id,
            issue_date: quotation.issue_date,
            uom_id: parseInt(item.uom_id),
            uom_group: itemById?.uom_group,
            current_ordered_purchase_qty:
              itemById?.current_ordered_purchase_qty,
            current_ordered_manufacture_qty:
              itemById?.current_ordered_manufacture_qty,
            current_committed_sales_qty: itemById?.current_committed_sales_qty,
            current_stock_qty: itemById?.current_stock_qty,
            current_available_qty: itemById?.current_available_qty,
            purchase_standard_price: itemById?.purchase_standard_price,
          };
          quotation_item_list.push(formatItem);
        }
      }

      const allOrderPriceLists = await SalesService.getOrderPriceList(
        formatSalesOrderAggrid
      );

      for (let salesOrder of allOrderPriceLists.results) {
        const formatItemList = salesOrder.item_list.filter(
          (item) => item.item_document_id === item_document_id
        );
        for (let item of formatItemList) {
          const formatItem = {
            ...item,
            document_id: salesOrder.document_id,
            issue_date: salesOrder.issue_date,
            uom_id: parseInt(item.uom_id),
            uom_group: itemById?.uom_group,
            current_ordered_purchase_qty:
              itemById?.current_ordered_purchase_qty,
            current_ordered_manufacture_qty:
              itemById?.current_ordered_manufacture_qty,
            current_committed_sales_qty: itemById?.current_committed_sales_qty,
            current_stock_qty: itemById?.current_stock_qty,
            current_available_qty: itemById?.current_available_qty,
            purchase_standard_price: itemById?.purchase_standard_price,
          };
          sales_order_item_list.push(formatItem);
        }
      }

      dispatch(
        quotationActions.loadedQuotationPriceList({
          item_document_id,
          quotation: quotation_item_list,
          order: sales_order_item_list,
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(
        quotationActions.rejectedActions({ ...err, name: "quotationPriceList" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getSalesOrderTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(quotationActions.onLoading("quotation"));
    try {
      const salesOrderTab = [];
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredSalesOrder = referenceDocument.filter(
        (document) => document.document_type === "sales_order"
      );
      for (const [index, value] of filteredSalesOrder.entries()) {
        const salesOrder = await SalesService.getSalesOrderById({
          document_id: value.document_id,
        });
        salesOrderTab[index] = salesOrder;
      }
      dispatch(quotationActions.loadedSalesOrderTab(salesOrderTab));
    } catch (err) {
      dispatch(quotationActions.rejectedActions({ ...err, name: "quotation" }));
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const updateQuotation =
  (payload, status, isChangedStatus, existing, user, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(quotationActions.onLoading("quotation"));
    try {
      const quotation = await SalesService.updateQuotation(payload);
      const formatQuotation = await salesQueryFormatter(quotation, "quotation");
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "quotation",
        };
        const updatedQuotation = await SalesService.postNextStatus(
          documentInput
        );
        if (
          updatedQuotation?.approval_progress_list
            ?.map((approval) => approval?.approval_status)
            .includes("PENDING")
        ) {
          dispatch(
            quotationActions.updateApprovalStatus({
              approval_list: updatedQuotation.approval_progress_list,
              user,
            })
          );
          const sendToApproveActivityLog = createActivityLogStatusPayload(
            activityLogsPayload,
            "quotation",
            "draft",
            "wait_approve"
          );
          dispatch(
            quotationActions.loadedQuotation({
              ...formatQuotation,
              render_status: "waitApprove",
            })
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToApproveActivityLog,
          });
          enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
            variant: "success",
          });
        } else {
          dispatch(
            quotationActions.loadedQuotation({
              ...formatQuotation,
              render_status: updatedQuotation.status,
            })
          );
          switch (status) {
            case "waitAccept":
              enqueueSnackbar("ตอบรับสำเร็จ", {
                variant: "success",
              });
              const acceptedActivityLogs = createActivityLogStatusPayload(
                activityLogsPayload,
                "quotation",
                "wait_accept",
                "accepted"
              );
              await ActivityLogsService.createActivityLogs({
                createActivityLogInput: acceptedActivityLogs,
              });
              break;
            default:
              break;
          }
        }
      } else {
        // if (status === "notApproved") {
        //   const setDocumentStep = await GlobalService.setDocumentStep({
        //     document_id: payload.document_id,
        //     document_type: "quotation",
        //     step: 1,
        //   });

        //   const sendToDraftActivityLog = createActivityLogStatusPayload(
        //     payload,
        //     "quotation",
        //     "not_approved",
        //     "draft"
        //   );

        //   await ActivityLogsService.createActivityLogs({
        //     createActivityLogInput: sendToDraftActivityLog,
        //   });

        //   dispatch(
        //     quotationActions.loadedQuotation({
        //       ...formatQuotation,
        //       render_status: setDocumentStep.status,
        //     })
        //   );
        //   enqueueSnackbar("บันทึกร่างสำเร็จ", {
        //     variant: "success",
        //   });
        // } else {
        const editActivityLog = createActivityLogEditPayload(
          activityLogsPayload,
          "quotation"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
        if (findDifferenceEmployee(payload, existing)) {
          const { addedEmployeeList, deletedEmployeeList } =
            findDifferenceEmployee(payload, existing);
          if (addedEmployeeList && addedEmployeeList.length > 0) {
            // add related employee
            const addedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogsPayload,
              "add_related_employee",
              addedEmployeeList,
              "quotation"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: addedEmployeeListLog,
            });
          }
          if (deletedEmployeeList && deletedEmployeeList.length > 0) {
            // delete related employee
            const deletedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogsPayload,
              "delete_related_employee",
              deletedEmployeeList,
              "quotation"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: deletedEmployeeListLog,
            });
          }
        }

        switch (status) {
          case "draft":
            dispatch(quotationActions.loadedQuotation(formatQuotation));
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
            break;
          default:
            dispatch(
              quotationActions.loadedQuotation({
                ...formatQuotation,
                render_status: status,
              })
            );
            enqueueSnackbar("แก้ไขใบเสนอราคาสำเร็จ", {
              variant: "success",
            });
        }
        // }
      }
      if (moment(formatQuotation.due_date).unix() > moment().unix()) {
        await GlobalService.updateIsLate();
        dispatch(quotationActions.updateQuotationIsLate(false));
      }
    } catch (err) {
      console.error(err);
      dispatch(quotationActions.rejectedActions({ ...err, name: "quotation" }));
      switch (status) {
        case "draft":
          if (isChangedStatus) {
            err.response.errors.forEach((error) => {
              if (errorMessageHandler(error.message)) {
                enqueueSnackbar(errorMessageHandler(error.message), {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("แก้ไขใบเสนอราคาไม่สำเร็จ", {
                  variant: "error",
                });
              }
            });
          } else {
            enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        default:
          enqueueSnackbar("แก้ไขใบเสนอราคาไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const approveQuotation =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(quotationActions.onLoading("quotation"));
    try {
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };

      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("quotation");

      const { approval_progress_list } = await SalesService.postApproveStatus(
        documentInput
      );

      dispatch(
        quotationActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );
      const sortedApprovalProgress = approval_progress_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      if (
        sortedApprovalProgress
          ?.map((approval) => approval?.approval_status)
          .includes("PENDING")
      ) {
        dispatch(
          quotationActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );

        dispatch(quotationActions.updateQuotationStatus("waitApprove"));

        const approvedActivityLog = createActivityLogApprovePayload(
          activityLogsPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "quotation"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      } else {
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        const approvedNextActivityLog = createActivityLogApprovePayload(
          activityLogsPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "quotation"
        );

        dispatch(
          quotationActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        dispatch(quotationActions.updateQuotationStatus("waitAccept"));
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedNextActivityLog,
        });
        const approvedActivityLog = createActivityLogStatusPayload(
          activityLogsPayload,
          "quotation",
          "wait_approve",
          "wait_accept"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      }
      enqueueSnackbar("อนุมัติสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      dispatch(quotationActions.rejectedActions({ ...err, name: "quotation" }));
      enqueueSnackbar("อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const declineQuotation =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(quotationActions.onLoading("quotation"));
    try {
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const notApprovedActivityLog = createActivityLogStatusPayload(
        activityLogsPayload,
        "quotation",
        "wait_approve",
        "not_approved"
      );
      const { approval_progress_list } = await SalesService.postDeclineStatus(
        documentInput
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: notApprovedActivityLog,
      });
      enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
        variant: "success",
      });
      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("quotation");

      dispatch(
        quotationActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      dispatch(
        quotationActions.updateApprovalStatus({
          approval_list: approval_progress_list,
          user,
        })
      );
      dispatch(quotationActions.updateQuotationStatus("notApproved"));
    } catch (err) {
      console.error(err);
      dispatch(quotationActions.rejectedActions({ ...err, name: "quotation" }));
      enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const cancelQuotation =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(quotationActions.onLoading("quotation"));
    try {
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const cancelActivityLog = createActivityLogStatusPayload(
        activityLogsPayload,
        "quotation",
        null,
        "cancelled"
      );
      await SalesService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(quotationActions.updateQuotationStatus("cancelled"));
    } catch (err) {
      dispatch(quotationActions.rejectedActions({ ...err, name: "quotation" }));
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
