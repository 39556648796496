import ActivityLogsService from "../../../services/ActivityLogs";
import GlobalService from "../../../services/Global";
import LogisticService from "../../../services/Logistic";
import {
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import {
  formatDeliveryTripById,
  logisticExportFormatter,
} from "../../../utils/logisticPayloadFormatter";
import { deliveryTripActions } from "./delivery-trip-slice";

export const getAllDeliveryTrips =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryTripActions.onLoading("allDeliveryTrips"));
    try {
      const allDeliveryTrips = await LogisticService.getAllDeliveryTrips(input);
      params.successCallback(allDeliveryTrips.results, allDeliveryTrips.count);
      dispatch(
        deliveryTripActions.loadedAllDeliveryTrips(allDeliveryTrips.results)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryTripActions.rejectedActions({
          ...err,
          name: "allDeliveryTrips",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getDeliveryTripById =
  (uniqueInput, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryTripActions.onLoading("deliveryTrip"));
    try {
      const deliveryTrip = await LogisticService.getDeliveryTripById(
        uniqueInput
      );
      const formatDeliveryTrip = await formatDeliveryTripById(deliveryTrip);
      dispatch(deliveryTripActions.loadedDeliveryTrip(formatDeliveryTrip));
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryTripActions.rejectedActions({
          ...err,
          name: "deliveryTrip",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllDeliveryTripsExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryTripActions.onLoading("allDeliveryTrips"));
    try {
      const { results } = await LogisticService.getAllDeliveryTrips(input);
      let deliveryTrips = await logisticExportFormatter(
        results,
        "deliveryTrip"
      );
      dispatch(deliveryTripActions.loadedAllDeliveryTripsExport(deliveryTrips));
      return deliveryTrips;
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryTripActions.rejectedActions({
          ...err,
          name: "allDeliveryTrips",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllDeliveryTripsExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryTripActions.onLoading("allDeliveryTrips"));
    try {
      const { results } = await LogisticService.getAllDeliveryTripsReport(
        input
      );
      let deliveryTrips = await logisticExportFormatter(
        results,
        "deliveryTrip"
      );
      dispatch(deliveryTripActions.loadedAllDeliveryTripsExport(deliveryTrips));
      return deliveryTrips;
    } catch (err) {
      console.log(err);
      dispatch(
        deliveryTripActions.rejectedActions({
          ...err,
          name: "allDeliveryTrips",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getNewDeliveryTripReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryTripActions.onLoading("deliveryTripReports"));
    try {
      const allDeliveryTrips =
        await LogisticService.getAllNewDeliveryTripsReport(input);
      params.successCallback(allDeliveryTrips.results, allDeliveryTrips.count);
      dispatch(deliveryTripActions.onLoaded("deliveryTripReports"));
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryTripActions.rejectedActions({
          ...err,
          name: "deliveryTripReports",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewDeliveryTrip =
  (payload, isSendDeliver, state, user, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(deliveryTripActions.onLoading("deliveryTrip"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const result = await LogisticService.createDeliveryTrip(payload);
      const createActivityLog = createActivityLogPayload(
        activityLogPayload,
        "delivery_trip",
        "สร้างรอบจัดส่ง"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      if (state && state?.isCopied) {
        const activityLogPayload = {
          document_id: state.document_id,
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogPayload,
          "delivery_trip"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }
      if (isSendDeliver) {
        const documentInput = {
          document_type: "delivery_trip",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "delivery_trip",
          null,
          "wait_deliver"
        );
        await LogisticService.updateDeliveryTrip({
          ...payload,
          id: result.id,
          update_item_list: true,
        });
        await LogisticService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("บันทึกรอบจัดส่งสำเร็จ", {
          variant: "success",
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "delivery_trip",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(`/logistic/delivery-trip/${result.document_id}`);
    } catch (err) {
      console.error(err);
      dispatch(
        deliveryTripActions.rejectedActions({ ...err, name: "deliveryTrip" })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างรอบจัดส่งไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const updateDeliveryTrip =
  (
    payload,
    user,
    status,
    isChangedStatus,
    existing,
    enqueueSnackbar,
    isManualChanged,
    step
  ) =>
  async (dispatch) => {
    dispatch(deliveryTripActions.onLoading("deliveryTrip"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const deliveryTrip = await LogisticService.updateDeliveryTrip(payload);
      const formatDeliveryTrip = await formatDeliveryTripById(deliveryTrip);
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "delivery_trip",
        };
        const updatedDeliveryTrip = await LogisticService.postNextStatus(
          documentInput
        );
        //TODO: activity logs
        const acceptedActivityLogs = createActivityLogStatusPayload(
          activityLogPayload,
          "delivery_trip",
          "draft",
          "wait_deliver"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: acceptedActivityLogs,
        });
        dispatch(
          deliveryTripActions.loadedDeliveryTrip({
            ...formatDeliveryTrip,
            render_status: updatedDeliveryTrip.status,
          })
        );
        enqueueSnackbar("ยืนยันรอบจัดส่งสำเร็จ", {
          variant: "success",
        });
      } else if (isManualChanged) {
        const updatedDeliveryTrip = await GlobalService.setDocumentStep({
          document_id: payload.document_id,
          document_type: "delivery_trip",
          step: step,
        });
        if (step === 3) {
          const changedActivityLogs = createActivityLogStatusPayload(
            activityLogPayload,
            "delivery_trip",
            "wait_deliver",
            "delivering"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: changedActivityLogs,
          });
        } else if (step === 4) {
          const changedActivityLogs = createActivityLogStatusPayload(
            activityLogPayload,
            "delivery_trip",
            "wait_deliver",
            "not_completed"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: changedActivityLogs,
          });
        } else if (step === 5) {
          const changedActivityLogs = createActivityLogStatusPayload(
            activityLogPayload,
            "delivery_trip",
            "wait_deliver",
            "completed"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: changedActivityLogs,
          });
        }
        dispatch(
          deliveryTripActions.loadedDeliveryTrip({
            ...formatDeliveryTrip,
            render_status: updatedDeliveryTrip.status,
          })
        );
        enqueueSnackbar("บันทึกการจัดส่งสำเร็จ", {
          variant: "success",
        });
      } else {
        const editActivityLog = createActivityLogEditPayload(
          activityLogPayload,
          "delivery_trip"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
        if (findDifferenceEmployee(payload, existing)) {
          const { addedEmployeeList, deletedEmployeeList } =
            findDifferenceEmployee(payload, existing);
          if (addedEmployeeList && addedEmployeeList.length > 0) {
            // add related employee
            const addedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "add_related_employee",
              addedEmployeeList,
              "delivery_trip"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: addedEmployeeListLog,
            });
          }
          if (deletedEmployeeList && deletedEmployeeList.length > 0) {
            // delete related employee
            const deletedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "delete_related_employee",
              deletedEmployeeList,
              "delivery_trip"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: deletedEmployeeListLog,
            });
          }
        }
        switch (status) {
          case "draft":
            dispatch(
              deliveryTripActions.loadedDeliveryTrip(formatDeliveryTrip)
            );
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
            break;
          default:
            dispatch(
              deliveryTripActions.loadedDeliveryTrip({
                ...formatDeliveryTrip,
                render_status: status,
              })
            );
            enqueueSnackbar("แก้ไขรอบจัดส่งสำเร็จ", {
              variant: "success",
            });
        }
      }
    } catch (err) {
      dispatch(
        deliveryTripActions.rejectedActions({ ...err, name: "deliveryTrip" })
      );
      switch (status) {
        case "draft":
          if (isChangedStatus) {
            err.response.errors.forEach((error) => {
              if (errorMessageHandler(error.message)) {
                enqueueSnackbar(errorMessageHandler(error.message), {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("ยืนยันรอบจัดส่งไม่สำเร็จ", {
                  variant: "error",
                });
              }
            });
          } else {
            enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        case "wait_deliver":
          if (isManualChanged) {
            enqueueSnackbar("เปลี่ยนสถานะไม่สำเร็จ", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("แก้ไขรอบจัดส่งไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        default:
          enqueueSnackbar("แก้ไขรอบจัดส่งไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const cancelDeliveryTrip =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(deliveryTripActions.onLoading("deliveryTrip"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const cancelActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "delivery_trip",
        null,
        "cancelled"
      );
      await GlobalService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(deliveryTripActions.updateDeliveryTripStatus("cancelled"));
    } catch (err) {
      dispatch(
        deliveryTripActions.rejectedActions({ ...err, name: "deliveryTrip" })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
