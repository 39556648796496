import * as Yup from "yup";

export const validation = Yup.object().shape({
  document_id: Yup.string().required("กรุณากรอก"),
  source_warehouse_document_id: Yup.string().required("กรุณากรอก"),
  remark: Yup.string().required("กรุณาระบุหมายเหตุ"),
  goods_adjustment_list: Yup.array()
    .of(
      Yup.object().shape({
        stock_entry_list: Yup.object().shape({
          trace_entry_list: Yup.array()
            .of(
              Yup.object().shape({
                source_bin_location_document_id:
                  Yup.string().required("กรุณาระบุสถานที่"),
              })
            )
            .min(1, "กรุณาเพิ่ม SN ในรายการสินค้า"),
        }),
      })
    )
    .min(1, "กรุณาเพิ่มรายการสินค้า"),
  // barcode: Yup.string().required("กรุณากรอก"),
  // description: Yup.string().required("กรุณากรอก"),
  // sales_standard_price: positiveNumberValidate,
  // maximum_discount: positiveNumberValidate,
  // purchase_standard_price: positiveNumberValidate,
  // sales_vat_type: Yup.string().required("กรุณากรอก"),
});
