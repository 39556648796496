import { useCallback, useEffect, useState } from "react";
import GlobalService from "../../../services/Global";

export const useNameOptions = () => {
  const [nameLoading, setNameLoading] = useState(false);
  const [nameOptions, setNameOptions] = useState([]);
  const [openName, setOpenName] = useState(false);

  const getAllNames = useCallback(async () => {
    setNameLoading(true);
    const loadedOptions = await GlobalService.getCreatables({
      usage_field_type: "work_order",
      usage_field_name: "work_order_list_name",
    });

    const formatNameOptions = loadedOptions.map((option) => ({
      id: option.name,
      value: option.name,
      label: option.name,
    }));
    setNameOptions(formatNameOptions);
    setNameLoading(false);
  }, []);

  useEffect(() => {
    if (openName) {
      getAllNames();
    }
  }, [getAllNames, openName]);

  const onOpenName = () => {
    setOpenName(true);
  };

  const onCloseName = () => {
    setOpenName(false);
    setNameOptions([]);
  };
  return {
    nameLoading,
    nameOptions,
    onOpenName,
    onCloseName,
  };
};
