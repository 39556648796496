import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { forwardRef } from "react";
import ControlledServerSideCreatable from "../../Custom/ControlledSSCreatable";

const ReturnReasonForm = forwardRef(
  ({ control, errors, viewOnly, setValue }, ref) => {
    const { t } = useTranslation();

    return (
      <CustomizedBox ref={ref} margin="2rem 0 0 0">
        <Box display={"flex"} gap={0.5} ml={1} mb={4}>
          <Typography fontWeight="bold">
            {t("purchase.return.reasonToReturn")}
          </Typography>
          <Typography sx={{ color: "red" }}>*</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledServerSideCreatable
              sx={{ width: 300 }}
              control={control}
              name="reason_to_return"
              documentType="purchase_return"
              defaultOptions={[]}
              error={Boolean(errors.reason_to_return)}
              helperText={errors.reason_to_return?.message}
              setValue={setValue}
              viewOnly={viewOnly}
              required
            />
          </Grid>
        </Grid>
      </CustomizedBox>
    );
  }
);

export default ReturnReasonForm;
