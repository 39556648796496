import { gql } from "graphql-request";

export const GET_APPROVAL_TEMPLATES = gql`
  query ApprovalTemplatesByDocumentType($documentType: DocumentType!) {
    approvalTemplatesByDocumentType(documentType: $documentType) {
      id
      step_number
      reviewer_document_id_list
      reviewer_list {
        document_id
        first_name
        last_name
        email
        img_url
      }
      is_required_approve
    }
  }
`;

export const GET_APPROVAL_PROGRESS = gql`
  query ApprovalProgressByDocument(
    $referenceDocumentInput: ReferenceDocumentInput!
  ) {
    approvalProgressByDocument(
      referenceDocumentInput: $referenceDocumentInput
    ) {
      approval_template_id
      approval_template {
        id
        step_number
        step_name
        action
        workflow_step_number
        workflow_document_type
        reviewer_document_id_list
        reviewer_list {
          document_id
          first_name
          last_name
          email
          img_url
        }
        is_required_approve
      }
      step_number
      approval_status
      created_date
      created_by_document_id
      approved_by_document_id
      approved_by {
        document_id
        first_name
        last_name
      }
    }
  }
`;

export const GET_QUOTATION_ID = gql`
  query QuotationDocumentId($uniqueInput: QuotationWhereUniqueInput!) {
    quotation(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const GET_QUOTATION_PRICE_LIST = gql`
  query QuotationsAggrid($aggridInput: AnyAggridInput) {
    quotationsAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        issue_date
        item_list {
          uid
          item_document_id
          item_name
          item_description
          qty
          uom_id
          qty_uom
          price_per_unit
          discount_amount
          uom {
            id
            document_id
            name
          }
          width_length_tolerance_positive
          width_length_tolerance_negative
          thickness_tolerance_positive
          thickness_tolerance_negative
          item_remark
          withholding_tax
        }
      }
      count
    }
  }
`;

export const GET_ALL_QUOTATIONS = gql`
  query QuotationsAggrid($aggridInput: AnyAggridInput) {
    quotationsAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        contact_name
        external_ref_id
        issue_date
        due_date
        credit_day
        contact_document_id
        pre_vat_amount
        shipping_cost
        additional_discount
        additional_discount_type
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
        created_date
        updated_date
        creator_document_id
        accepted_date
        accepted_remark
        attachment_list {
          id
          name
          url
          uploaded_by {
            document_id
            first_name
            last_name
          }
          uploaded_date
        }
        customer {
          email
          phone
          fax
          billing_address {
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        created_by {
          document_id
          first_name
          last_name
          img_url
        }
        employee_list {
          document_id
          first_name
          last_name
          img_url
        }
        attachment_list {
          id
          name
          url
          uploaded_by {
            document_id
            first_name
            last_name
          }
          uploaded_date
        }
        item_list {
          item_document_id
          item_name
          item_description
          item_remark
          qty
          uom {
            document_id
            name
          }
          qty_uom
          width_length_tolerance_positive
          width_length_tolerance_negative
          thickness_tolerance_positive
          thickness_tolerance_negative
          price_per_unit
          discount_amount
          vat_type
          withholding_tax
        }
        template_remark_id
        remark
        render_status
        contact_name
        is_late
        price_vat_type
      }
      count
    }
  }
`;

export const GET_QUOTATION = gql`
  query Quotation($uniqueInput: QuotationWhereUniqueInput!) {
    quotation(uniqueInput: $uniqueInput) {
      id
      document_id
      contact_name
      external_ref_id
      issue_date
      due_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      creator_document_id
      accepted_date
      accepted_remark
      attachment_list {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      attachment_list {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom {
          id
          document_id
          name
        }
        uom_id
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
      is_late
      price_vat_type
    }
  }
`;

export const GET_SALES_ORDER_ID = gql`
  query SalesOrderDocumentId($uniqueInput: SalesOrderWhereUniqueInput!) {
    salesOrder(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const GET_SALES_ORDER_LIST = gql`
  query SalesOrdersAggrid($aggridInput: AnyAggridInput) {
    salesOrdersAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        issue_date
        item_list {
          uid
          item_document_id
          item_name
          item_description
          qty
          uom_id
          qty_uom
          bom_description
          price_per_unit
          discount_amount
          width_length_tolerance_positive
          width_length_tolerance_negative
          thickness_tolerance_positive
          thickness_tolerance_negative
          item_remark
          uom {
            id
            document_id
            name
          }
          withholding_tax
        }
      }
      count
    }
  }
`;

export const GET_SALES_ORDER_NO_AG_GRID = gql`
  query salesOrders {
    salesOrders {
      id
      document_id
      item_list {
        item_document_id
        item_name
        item_description
        bom_description
        item_remark
        qty
        uom {
          id
          document_id
          name
        }
        qty_uom
        qty_shipped
        qty_to_ship
        qty_invoiced
        qty_returned
      }
    }
  }
`;

export const GET_SALES_ORDER_LOGISTIC_REPORT = gql`
  query salesOrders {
    salesOrders {
      document_id
      remark
    }
  }
`;
export const GET_ALL_SALES_ORDER_IDS = gql`
  query SalesOrdersIds($aggridInput: AnyAggridInput) {
    salesOrdersIds(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
      }
    }
  }
`;

export const GET_ALL_SALES_ORDERS = gql`
  query SalesOrdersAggrid($aggridInput: AnyAggridInput) {
    salesOrdersAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        contact_name
        external_ref_id
        issue_date
        delivery_date
        due_date
        credit_day
        contact_document_id
        pre_vat_amount
        shipping_cost
        additional_discount
        additional_discount_type
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
        created_date
        updated_date
        creator_document_id
        do_document_id_list
        item_group_level_1_name_list
        customer {
          email
          phone
          fax
          billing_address {
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        created_by {
          document_id
          first_name
          last_name
          img_url
        }
        employee_list {
          document_id
          first_name
          last_name
          img_url
        }
        item_list {
          uid
          item_document_id
          item_name
          item_description
          item_remark
          qty
          uom {
            id
            document_id
            name
          }
          qty_uom
          bom_description
          width_length_tolerance_positive
          width_length_tolerance_negative
          thickness_tolerance_positive
          thickness_tolerance_negative
          price_per_unit
          discount_amount
          vat_type
          withholding_tax
          qty_shipped
          qty_to_ship
          qty_invoiced
          qty_returned
        }
        template_remark_id
        remark
        render_status
        is_late
        price_vat_type
      }
      count
    }
  }
`;

export const GET_SALES_ORDER = gql`
  query SalesOrderDocumentId($uniqueInput: SalesOrderWhereUniqueInput!) {
    salesOrder(uniqueInput: $uniqueInput) {
      id
      document_id
      contact_name
      external_ref_id
      issue_date
      delivery_date
      due_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      creator_document_id
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom_id
        uom {
          id
          document_id
          name
        }
        qty_uom
        bom_description
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
        qty_shipped
        qty_to_ship
        qty_invoiced
        qty_returned
      }
      template_remark_id
      remark
      target_status
      render_status
      is_late
      price_vat_type
    }
  }
`;

export const GET_INVOICE_PRICE_LIST = gql`
  query SalesInvoicesAggrid($aggridInput: AnyAggridInput) {
    salesInvoicesAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        issue_date
        item_list {
          uid
          item_document_id
          item_name
          item_description
          qty
          uom_id
          qty_uom
          price_per_unit
          discount_amount
          uom {
            id
            document_id
            name
          }
          withholding_tax
        }
      }
      count
    }
  }
`;

export const GET_SALES_INVOICE_ID = gql`
  query SalesInvoiceDocumentId($uniqueInput: SalesInvoiceWhereUniqueInput!) {
    salesInvoice(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const GET_ALL_SALES_INVOICES = gql`
  query SalesInvoicesAggrid($aggridInput: AnyAggridInput) {
    salesInvoicesAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        contact_name
        external_ref_id
        issue_date
        due_date
        credit_day
        contact_document_id
        pre_vat_amount
        shipping_cost
        additional_discount
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
        created_date
        updated_date
        creator_document_id
        customer {
          email
          phone
          fax
          billing_address {
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        created_by {
          document_id
          first_name
          last_name
          img_url
        }
        employee_list {
          document_id
          first_name
          last_name
          img_url
        }
        item_list {
          item_document_id
          item_name
          item_description
          item_remark
          qty
          uom {
            document_id
            name
          }
          qty_uom
          width_length_tolerance_positive
          width_length_tolerance_negative
          thickness_tolerance_positive
          thickness_tolerance_negative
          price_per_unit
          discount_amount
          vat_type
          withholding_tax
        }
        template_remark_id
        remark
        render_status
      }
      count
    }
  }
`;

export const GET_SALES_INVOICE = gql`
  query SalesInvoiceDocumentId($uniqueInput: SalesInvoiceWhereUniqueInput!) {
    salesInvoice(uniqueInput: $uniqueInput) {
      id
      document_id
      contact_name
      external_ref_id
      issue_date
      due_date
      credit_day
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      creator_document_id
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom_id
        uom {
          id
          document_id
          name
        }
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
      is_late
    }
  }
`;

export const GET_DEPOSIT_INVOICE_ID = gql`
  query DepositInvoiceDocumentId(
    $uniqueInput: DepositInvoiceWhereUniqueInput!
  ) {
    depositInvoice(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const GET_ALL_DEPOSIT_INVOICES = gql`
  query DepositInvoicesAggrid($aggridInput: AnyAggridInput) {
    depositInvoicesAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        contact_name
        external_ref_id
        issue_date
        credit_day
        contact_document_id
        pre_vat_amount
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        total_amount
        created_date
        updated_date
        creator_document_id
        customer {
          email
          phone
          fax
          billing_address {
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        created_by {
          document_id
          first_name
          last_name
          img_url
        }
        employee_list {
          document_id
          first_name
          last_name
          img_url
        }
        item_list {
          description
          vat_type
          pre_vat_amount
        }
        template_remark_id
        remark
        render_status
      }
      count
    }
  }
`;

export const GET_DEPOSIT_INVOICE = gql`
  query DepositInvoiceDocumentId(
    $uniqueInput: DepositInvoiceWhereUniqueInput!
  ) {
    depositInvoice(uniqueInput: $uniqueInput) {
      id
      document_id
      contact_name
      external_ref_id
      issue_date
      credit_day
      contact_document_id
      pre_vat_amount
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      total_amount
      created_date
      updated_date
      creator_document_id
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        description
        vat_type
        pre_vat_amount
      }
      template_remark_id
      remark
      target_status
      render_status
    }
  }
`;

export const GET_SALES_RETURN_ID = gql`
  query SalesReturnDocumentId($uniqueInput: SalesReturnWhereUniqueInput!) {
    salesReturn(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const GET_SALES_RETURN_NO_AG_GRID = gql`
  query SalesReturns {
    salesReturns {
      id
      document_id
      external_ref_id
      issue_date
      due_date
      credit_day
      reason_to_return
      item_list {
        item_document_id
        item_name
        item_description
        qty
        uom {
          id
          document_id
          name
        }
        qty_uom
        qty_return
        price_per_unit
        discount_amount
      }
    }
  }
`;

export const GET_ALL_SALES_RETURNS = gql`
  query SalesReturnsAggrid($aggridInput: AnyAggridInput) {
    salesReturnsAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        contact_name
        external_ref_id
        issue_date
        credit_day
        contact_document_id
        is_effect_stock
        reason_to_return
        pre_vat_amount
        shipping_cost
        additional_discount
        additional_discount_type
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
        created_date
        updated_date
        creator_document_id
        customer {
          email
          phone
          fax
          billing_address {
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        created_by {
          document_id
          first_name
          last_name
          img_url
        }
        employee_list {
          document_id
          first_name
          last_name
          img_url
        }
        item_list {
          item_document_id
          item_name
          item_description
          qty
          qty_return
          uom {
            document_id
            name
          }
          qty_uom
          price_per_unit
          discount_amount
          vat_type
          withholding_tax
        }
        template_remark_id
        remark
        render_status
        price_vat_type
      }
      count
    }
  }
`;

export const GET_ALL_SALES_RETURN_IDS = gql`
  query SalesReturnsAggrid($aggridInput: AnyAggridInput) {
    salesReturnsAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
      }
      count
    }
  }
`;

export const GET_SALES_RETURN = gql`
  query SalesReturnDocumentId($uniqueInput: SalesReturnWhereUniqueInput!) {
    salesReturn(uniqueInput: $uniqueInput) {
      id
      document_id
      contact_name
      external_ref_id
      issue_date
      due_date
      credit_day
      contact_document_id
      is_effect_stock
      reason_to_return
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      creator_document_id
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        qty
        qty_return
        uom_id
        uom {
          id
          document_id
          name
        }
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
      price_vat_type
    }
  }
`;

export const GET_CREDIT_NOTE_ID = gql`
  query CreditNoteDocumentId($uniqueInput: CreditNoteWhereUniqueInput!) {
    creditNote(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const GET_ALL_CREDIT_NOTES = gql`
  query CreditNotesAggrid($aggridInput: AnyAggridInput) {
    creditNotesAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        contact_name
        external_ref_id
        issue_date
        credit_day
        contact_document_id
        credit_description
        pre_vat_amount
        shipping_cost
        additional_discount
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
        created_date
        updated_date
        creator_document_id
        customer {
          email
          phone
          fax
          billing_address {
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        created_by {
          document_id
          first_name
          last_name
          img_url
        }
        employee_list {
          document_id
          first_name
          last_name
          img_url
        }
        item_list {
          item_document_id
          item_name
          item_description
          item_remark
          qty
          uom {
            document_id
            name
          }
          qty_uom
          width_length_tolerance_positive
          width_length_tolerance_negative
          thickness_tolerance_positive
          thickness_tolerance_negative
          price_per_unit
          discount_amount
          vat_type
          withholding_tax
        }
        template_remark_id
        remark
        render_status
      }
      count
    }
  }
`;

export const GET_CREDIT_NOTE = gql`
  query CreditNoteDocumentId($uniqueInput: CreditNoteWhereUniqueInput!) {
    creditNote(uniqueInput: $uniqueInput) {
      id
      document_id
      contact_name
      external_ref_id
      issue_date
      credit_day
      contact_document_id
      credit_description
      pre_vat_amount
      shipping_cost
      additional_discount
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      created_date
      updated_date
      creator_document_id
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        item_remark
        qty
        uom_id
        uom {
          id
          document_id
          name
        }
        qty_uom
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
      }
      template_remark_id
      remark
      target_status
      render_status
    }
  }
`;

export const GET_SALES_REPORT_BY_CUSTOMER = gql`
  query SalesReportByCustomer {
    salesReportByCustomer {
      contact_detail {
        contact_name_1
        contact_name_2
        document_id
      }
      sales_report {
        sales_order {
          _count {
            document_id
          }
          _sum {
            pre_vat_amount
            vat_amount
            shipping_cost
            additional_discount
            vat_exempted_amount
            withholding_tax_amount
            total_amount
          }
        }
      }
    }
  }
`;

export const GET_SALES_REPORT_BY_PRODUCT = gql`
  query SalesReportByProduct {
    salesReportByCustomerProduct {
      sales_report {
        sales_order {
          item_document_id
          item_name
          item_sales_detail_list {
            price_per_unit
            discount_amount
            withholding_tax
            qty
            vat_type
          }
        }
      }
    }
  }
`;

export const GET_ALL_NEW_SALES_ORDER = gql`
  query SalesOrderReport($aggridInput: AnyAggridInput!) {
    salesOrderReport(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
        issue_date
        delivery_date
        render_status
        contact_document_id
        contact_name
        price_vat_type
        item_document_id
        item_name
        item_bom_description
        qty
        uom
        price_per_unit
        vat_type
        pre_vat_amount_exclude_discount
        item_discount
        item_additional_discount
        pre_vat_amount_include_discount
        item_vat_exempted_amount
        item_vat_0_amount
        item_vat_7_amount
        item_vat_amount
        item_net_amount
        item_withholding_tax_type
        item_withholding_tax
        item_total_amount
        shipping_cost
        total_amount
        additional_discount
        credit_day
        qty_uom
        item_qty_to_ship
        item_qty_shipped
        item_qty_invoiced
        item_qty_returned
        is_late
        reference_document_id
        external_ref_id
        due_date
        item_description
        item_remark
        width_length_tolerance_positive
        width_length_tolerance_negative
        thickness_tolerance_positive
        thickness_tolerance_negative
        created_date
        updated_date
        created_by
        employee_list
        email
        phone
        fax
        billing_address
        billing_sub_district
        billing_district
        billing_province
        billing_postal_code
        billing_country
        is_same_as_default_address
        delivery_address_contact_name
        delivery_address_contact_phone
        delivery_address
        delivery_sub_district
        delivery_district
        delivery_province
        delivery_postal_code
        delivery_country
        item_group_level_1_name_list
      }
    }
  }
`;
