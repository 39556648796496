export const initialState = {
  isLoading: {
    allWorkCenter: false,
    workCenter: false,
  },
  allWorkCenter: [],
  workCenter: {
    name: "",
    plant: "",
    description: "",
    remark: "",
  },
  error: null,
};
