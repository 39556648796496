// import React from "react";
import {
  formatDate,
  unixToDateTimeWithFormat,
  unixToDateWithFormat,
} from "../../../utils/date-converter";

const mapStatus = (status) => {
  switch (status) {
    case "draft":
      return "ร่าง";
    case "finished":
      return "เสร็จสิ้น";
    case "cancelled":
      return "ยกเลิก";
    case "approve":
    case "approved":
      return "อนุมัติแล้ว";
    default:
      break;
  }
};

const mapDocType = (type) => {
  switch (type) {
    case "receive":
      return "นำเข้า";
    case "issue":
      return "นำออก";
    case "transfer":
      return "โอนย้าย";
    case "adjustment":
      return "ปรับยอด";
    default:
      break;
  }
};

const mapVatType = (vat) => {
  switch (vat) {
    case "0":
      return "0%";
    case "7":
      return "7%";
    case "VAT_0":
      return "0 %";
    case "VAT_7":
      return "7 %";
    default:
      break;
  }
};

const getFullName = (object) => {
  if (object) {
    return object.first_name + " " + object.last_name;
  } else return "";
};

const getValueFromDoc = (object, name) => {
  if (!object) return "";
  if (object.goods_receive) {
    if (name === "lot") return object.goods_receive.lot;
    else if (name === "status") return mapStatus(object.goods_receive.status);
    else if (name === "type") return object.goods_receive.type;
    else return object.goods_receive.remark;
  } else if (object.goods_issue) {
    if (name === "status") return mapStatus(object.goods_issue.status);
    if (name === "type") return object.goods_issue.type;
    else return object.goods_issue.remark;
  } else if (object.goods_transfer) {
    if (name === "status") return mapStatus(object.goods_transfer.status);
    if (name === "type") return "";
    else return object.goods_transfer.remark;
  } else if (object.goods_adjustment) {
    if (name === "status") return mapStatus(object.goods_adjustment.status);
    if (name === "type") return "";
    else return object.goods_adjustment.remark;
  }
  return "";
};

export const formatValueItems = (value) => {
  const mapMRP = (key) => {
    switch (key) {
      case "no_setting":
        return "ไม่ได้ตั้งค่า";
      case "produce":
        return "ผลิต";
      case "buy":
        return "สั่งซื้อ";
      default:
        return "ไม่ได้ตั้งค่า";
    }
  };
  return {
    รหัสสินค้า: value.document_id,
    ชื่อสินค้า: value.name,
    รายละเอียดสินค้า: value.description,
    ประเภทสินค้า: value.type,
    ราคาซื้อต่อหน่วย: value.purchase_standard_price,
    จำนวนที่กำลังจัดซื้อ: value.current_ordered_purchase_qty,
    "จำนวนถูกจอง(ขาย)": value.current_committed_sales_qty,
    "จำนวนถูกจอง(ผลิต)": value.current_ordered_manufacture_qty,
    จำนวนคงคลัง: value.current_stock_qty,
    จำนวนคงเหลือสุทธิ: value.current_available_qty,
    จำนวนสั่งซื้อขั้นต่ำ: value.purchase_minimum_qty,
    ประเภทภาษีซื้อ: mapVatType(value.purchase_vat_type),
    ราคาขายต่อหน่วย: value.sales_standard_price,
    // ส่วนลดสูงสุด: value.sales_maximum_discount,
    ประเภทภาษีขาย: mapVatType(value.sales_vat_type),
    หน่วยสต๊อก: value.base_uom?.name,
    SKU: value.sku,
    บาร์โค้ด: value.barcode,
    ซื้อได้: value.is_purchasable ? "ได้" : "ไม่ได้",
    ขายได้: value.is_salable ? "ได้" : "ไม่ได้",
    สต๊อกได้: value.is_stockable ? "ได้" : "ไม่ได้",
    ผลิตได้: value.is_manufactuable ? "ได้" : "ไม่ได้",
    "หมวดหมู่สินค้า 1": value.item_group_sub_level_1.name,
    "หมวดหมู่สินค้า 2": value.item_group_sub_level_2?.name,
    "หมวดหมู่สินค้า 3": value.item_group_sub_level_3?.name,
    การจัดกลุ่ม: value.tag_list?.map((tag) => tag.name).join(","),
    สถานะ: value.is_active ? "ใช้งาน" : "ไม่ใช้งาน",
    "กว้าง(สินค้า)": value.width,
    "หน่วยกว้าง(สินค้า)": value.width_uom?.name,
    "ยาว(สินค้า)": value.length,
    "หน่วยยาว(สินค้า)": value.length_uom?.name,
    "สูง(สินค้า)": value.height,
    "หน่วยสูง(สินค้า)": value.height_uom?.name,
    "หนา(สินค้า)": value.thickness,
    "หน่วยหนา(สินค้า)": value.thickness_uom?.name,
    "กว้าง(ขนส่ง)": value.package_width,
    "หน่วยกว้าง(ขนส่ง)": value.package_width_uom?.name,
    "ยาว(ขนส่ง)": value.package_length,
    "หน่วยยาว(ขนส่ง)": value.package_length_uom?.name,
    "สูง(ขนส่ง)": value.package_height,
    "หน่วยความสูง(ขนส่ง)": value.package_height_uom?.name,
    "น้ำหนัก(ขนส่ง)": value.package_weight,
    "หน่วยน้ำหนัก(ขนส่ง)": value.package_weight_uom?.name,
    // "กว้าง(ขนส่ง)": value.weight,
    // "หน่วยกว้าง(ขนส่ง)": value.weight_uom?.name,
    // "ยาว(ขนส่ง)": value.weight,
    // "หน่วยยาว(ขนส่ง)": value.weight_uom?.name,
    // "สูง(ขนส่ง)": value.weight,
    // "หน่วยสูง(ขนส่ง)": value.weight_uom?.name,
    // ปริมาตร: value.volume,
    // หน่วยปริมาตร: value.volume_uom?.name,
    หน่วยขาย: value.sales_uom?.name,
    หน่วยซื้อ: value.purchase_uom?.name,
    หน่วยส่ง: value.deliver_uom?.name,
    สูตรการผลิต: value.main_bom?.name,
    ประเภทการผลิต: value.manufacture_type,
    จำนวนสั่งผลิตขั้นต่ำ: value.manufacture_minimum_qty,
    การจัดหา: mapMRP(value.mrp_default_method),
    วันที่สร้าง: unixToDateWithFormat(value.created_date),
    สร้างโดย: getFullName(value.created_by),
  };
};

export const formatValueGoodsReceive = (value) => {
  return {
    เลขที่เอกสาร: value.document_id,
    วันที่ออกเอกสาร: unixToDateWithFormat(value.created_date),
    วันที่นำเข้า: unixToDateWithFormat(value.document_date),
    รหัสสินค้า: value.stock_entry_list[0].item?.document_id,
    ชื่อสินค้า: value.stock_entry_list[0].item?.name,
    รายละเอียดสินค้า: value.stock_entry_list[0].item?.description,
    จำนวน: value.posted_quantity,
    หน่วย: value.stock_entry_list[0].uom?.name,
    ประเภทนำเข้า: value.type,
    คลัง: value.source_warehouse?.thai_name,
    Lot: formatDate(value.lot),
    หมายเหตุ: value.remark,
    // เลขที่เอกสารอ้างอิง: value.reference_document_id,
    ผู้สร้างเอกสาร: getFullName(value.created_by),
    สถานะ: mapStatus(value.status),
  };
};

export const formatValueGoodsIssue = (value) => {
  return {
    เลขที่เอกสาร: value.document_id,
    วันที่ออกเอกสาร: unixToDateWithFormat(value.created_date),
    วันที่นำออก: unixToDateWithFormat(value.document_date),
    รหัสสินค้า: value.stock_entry_list[0].item?.document_id,
    ชื่อสินค้า: value.stock_entry_list[0].item?.name,
    รายละเอียดสินค้า: value.stock_entry_list[0].item?.description,
    จำนวน: value.posted_quantity,
    หน่วย: value.stock_entry_list[0].uom?.name,
    ประเภทนำออก: value.type,
    คลัง: value.source_warehouse?.thai_name,
    หมายเหตุ: value.remark,
    ผู้สร้างเอกสาร: getFullName(value.created_by),
    สถานะ: mapStatus(value.status),
  };
};

export const formatValueGoodsTransfer = (value) => {
  return {
    เลขที่เอกสาร: value.document_id,
    วันที่ออกเอกสาร: unixToDateWithFormat(value.created_date),
    วันที่โอนย้าย: unixToDateWithFormat(value.document_date),
    รหัสสินค้า: value.stock_entry_list[0].item?.document_id,
    ชื่อสินค้า: value.stock_entry_list[0].item?.name,
    รายละเอียดสินค้า: value.stock_entry_list[0].item?.description,
    จำนวน: value.posted_quantity,
    หน่วย: value.stock_entry_list[0].uom?.name,
    คลังตั้งต้น: value.source_warehouse?.thai_name,
    คลังปลายทาง: value.destination_warehouse?.thai_name,
    หมายเหตุ: value.remark,
    ผู้สร้างเอกสาร: getFullName(value.created_by),
    สถานะ: mapStatus(value.status),
  };
};

export const formatValueGoodsAdjustment = (value) => {
  return {
    เลขที่เอกสาร: value.document_id,
    วันที่ออกเอกสาร: unixToDateWithFormat(value.created_date),
    วันที่ปรับปรุง: unixToDateWithFormat(value.document_date),
    รหัสสินค้า: value.stock_entry_list[0].item?.document_id,
    ชื่อสินค้า: value.stock_entry_list[0].item?.name,
    รายละเอียดสินค้า: value.stock_entry_list[0].item?.description,
    จำนวน: value.posted_quantity,
    หน่วย: value.stock_entry_list[0].uom?.name,
    คลัง: value.source_warehouse?.thai_name,
    หมายเหตุ: value.remark,
    ผู้สร้างเอกสาร: getFullName(value.created_by),
    สถานะ: mapStatus(value.status),
  };
};

export const formatValueStockEntry = (value) => {
  return {
    วันที่สร้าง: unixToDateWithFormat(value.created_date),
    เอกสารอ้างอิง: value.reference_document_id,
    "หมวดหมู่สินค้า 1": value.item?.item_group_sub_level_1?.name,
    รหัสสินค้า: value.item?.document_id,
    ชื่อสินค้า: value.item?.name,
    รายละเอียดสินค้า: value.item?.description,
    "จำนวนที่รับ/จ่ายจริง": value.posted_quantity,
    หน่วย: value.uom?.name,
    ประเภทเอกสารการเคลื่อนไหว: mapDocType(value.reference_document_type),
    ประเภทการเคลื่อนไหว: getValueFromDoc(value.inventory_object_item, "type"),
    Lot: getValueFromDoc(value.inventory_object_item, "lot")
      ? formatDate(getValueFromDoc(value.inventory_object_item, "lot"))
      : "",
    รหัสคลังต้นทาง: value.source_warehouse?.document_id,
    คลังต้นทาง: value.source_warehouse?.thai_name,
    รหัสคลังปลายทาง: value.destination_warehouse?.document_id,
    คลังปลายทาง: value.destination_warehouse?.thai_name,
    หมายเหตุ: getValueFromDoc(value.inventory_object_item, "remark"),
    ผู้สร้างเอกสาร: getFullName(value.created_by),
  };
};

export const formatValueTraceEntry = (value) => {
  return {
    วันที่สแกน: unixToDateTimeWithFormat(value.scanned_date),
    วันที่สร้าง: unixToDateWithFormat(value.created_date),
    เอกสารอ้างอิง: value.stock_entry.reference_document_id,
    "หมวดหมู่สินค้า 1": value.item?.item_group_sub_level_1?.name,
    รหัสสินค้า: value.item?.document_id,
    ชื่อสินค้า: value.item?.name,
    รายละเอียดสินค้า: value.item?.description,
    "จำนวนที่รับ/จ่ายจริง": value.posted_quantity,
    หน่วย: value.uom?.name,
    ประเภทเอกสารการเคลื่อนไหว: mapDocType(
      value.stock_entry.reference_document_type
    ),
    ประเภทการเคลื่อนไหว: getValueFromDoc(
      value.stock_entry.inventory_object_item,
      "type"
    ),
    SN: value.serial_number,
    รหัสคลังต้นทาง: value.stock_entry.source_warehouse?.document_id,
    คลังต้นทาง: value.stock_entry.source_warehouse?.thai_name,
    รหัสสถานที่ต้นทาง: value.source_bin_location?.document_id,
    สถานที่ต้นทาง: value.source_bin_location?.name,
    รหัสคลังปลายทาง: value.stock_entry.destination_warehouse?.document_id,
    คลังปลายทาง: value.stock_entry.destination_warehouse?.thai_name,
    รหัสสถานที่ปลายทาง: value.destination_bin_location?.document_id,
    สถานที่ปลายทาง: value.destination_bin_location?.name,
    Lot: getValueFromDoc(value.stock_entry.inventory_object_item, "lot")
      ? formatDate(
          getValueFromDoc(value.stock_entry.inventory_object_item, "lot")
        )
      : "",
    หมายเหตุ: getValueFromDoc(
      value.stock_entry.inventory_object_item,
      "remark"
    ),
    ผู้สร้างเอกสาร: getFullName(value.created_by),
    ผู้สแกน: getFullName(value.scanned_by),
    Barcode: value.batch_number,
  };
};

export const formatValueItemNoEntry = (value) => {
  return {
    วันที่สร้าง: value.created_date,
    รหัสสินค้า: value.document_id,
    ชื่อสินค้า: value.name,
    รายละเอียดสินค้า: value.description,
    จำนวนที่กำลังจัดซื้อ: value.current_ordered_purchase_qty,
    "จำนวนถูกจอง(ขาย)": value.current_committed_sales_qty,
    "จำนวนถูกจอง(ผลิต)": value.current_ordered_manufacture_qty,
    จำนวนคงคลัง: value.current_stock_qty,
    จำนวนคงเหลือสุทธิ: value.current_available_qty,
    "หมวดหมู่สินค้า 1": value.item_group_sub_level_1?.name,
    "หมวดหมู่สินค้า 2": value.item_group_sub_level_1?.name,
    "หมวดหมู่สินค้า 3": value.item_group_sub_level_1?.name,
    การจัดกลุ่ม: value.tag_list?.map((tag) => tag.name).join(","),
    สถานะ: value.is_active ? "ใช้งาน" : "ไม่ใช้งาน",
    สร้างโดย: getFullName(value.created_by),
  };
};

export const formatValueItemCurrentStockByStockEntry = (oldData) => {
  const checkValue = [
    "item_document_id",
    "item_name",
    "item_description",
    "item_group_sub_level_1_name",
    "sum",
    "uom_name",
  ];

  const testData = {
    รหัสสินค้า: oldData.item_document_id,
    ชื่อสินค้า: oldData.item_name,
    รายละเอียดสินค้า: oldData.item_description,
    "หมวดหมู่สินค้า 1": oldData.item_group_sub_level_1_name,
  };

  for (const [key, value] of Object.entries(oldData)) {
    if (!checkValue.includes(key)) Object.assign(testData, { [key]: value });
  }

  Object.assign(testData, { ทั้งหมด: oldData.sum });
  Object.assign(testData, { หน่วย: oldData.uom_name });

  return testData;
};

export const formatValueItemCurrentStockByTraceEntry = (value) => {
  return {
    รหัสสินค้า: value.item_document_id,
    ชื่อสินค้า: value.item_name,
    // SKU: value.item_sku,
    รายละเอียดสินค้า: value.item_description,
    "หมวดหมู่สินค้า 1": value.item_group_sub_level_1_name,
    จำนวนคงคลัง: value.current_quantity,
    หน่วย: value.base_uom_name,
    รหัสคลัง: value.warehouse_document_id,
    คลัง: value.warehouse_thai_name,
    รหัสสถานที่: value.bin_location_document_id,
    สถานที่: value.bin_location_name,
  };
};

export const formatValueItemCurrentStockSerialNumber = (value) => {
  return {
    "หมวดหมู่สินค้า 1": value.item_group_sub_level_1_name,
    รหัสสินค้า: value.item_document_id,
    ชื่อสินค้า: value.item_name,
    // SKU: value.item_sku,
    รายละเอียดสินค้า: value.item_description,
    จำนวนคงคลัง: value.current_quantity,
    SN: value.serial_number,
    Barcode: value.batch_number,
    รหัสคลัง: value.warehouse_document_id,
    คลัง: value.warehouse_thai_name,
    รหัสสถานที่: value.bin_location_document_id,
    สถานที่: value.bin_location_name,
  };
};

export const formatValueNotActiveSerial = (value) => {
  return {
    วันที่สร้าง: unixToDateWithFormat(value.created_date),
    SN: value.serial_number,
    "หมวดหมู่สินค้า 1": value.item?.item_group_sub_level_1?.name,
    รหัสสินค้า: value.item?.document_id,
    ชื่อสินค้า: value.item?.name,
    รายละเอียดสินค้า: value.item?.description,
    "จำนวนที่รับ/จ่ายจริง": value.posted_quantity,
    หน่วย: value.uom?.name,
    ประเภทเอกสารการเคลื่อนไหว: mapDocType(
      value.stock_entry.reference_document_type
    ),
    รหัสคลังต้นทาง: value.stock_entry.source_warehouse?.document_id,
    คลังต้นทาง: value.stock_entry.source_warehouse?.thai_name,
    Barcode: value.batch_number,
    เอกสารอ้างอิง: value.stock_entry.reference_document_id,
    สถานะเอกสาร: getValueFromDoc(
      value.stock_entry.inventory_object_item,
      "status"
    ),
    ผู้สร้างเอกสาร: getFullName(value.created_by),
  };
};
