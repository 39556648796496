const defaultDisabledState = {
  printOption: false,
  modifyOption: false,
  createOption: false,
  document_id: false,
  created_date: true,
  issue_date: false,
  due_date: false,
  expect_date: false,
  credit_day: false,
  delivery_date: false,
  payment: false,
  external_ref_document_id: false,
  reference_document: false,
  activity_log: false,
  related_employees: false,
  destination_warehouse_document_id: false,
  accept_proof: "hide",
  approval: false,
};

export const shouldDisable = (page, status) => {
  switch (page) {
    case "request":
      const purchaseRequestState = {
        ...defaultDisabledState,
        delivery_date: "hide",
        expect_date: "hide",
        credit_day: "hide",
        payment: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...purchaseRequestState,
            document_id: true,
            createOption: true,
          };
        case "waitApprove":
          return {
            ...purchaseRequestState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            related_employees: true,
            destination_warehouse_document_id: true,
          };
        case "notApproved":
          return {
            ...purchaseRequestState,
            printOption: true,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            related_employees: true,
            destination_warehouse_document_id: true,
          };
        case "approved":
        case "partiallyOrdered":
        case "fullyOrdered":
          return {
            ...purchaseRequestState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            related_employees: true,
            destination_warehouse_document_id: true,
          };
        case "cancelled":
          return {
            ...purchaseRequestState,
            printOption: true,
            createOption: true,
            modifyOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            related_employees: true,
            destination_warehouse_document_id: true,
          };
        default:
          return {
            ...purchaseRequestState,
            printOption: true,
            createOption: true,
            modifyOption: true,
            approval: "hide",
          };
      }
    case "order":
      const orderState = {
        ...defaultDisabledState,
        payment: "hide",
        delivery_date: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...orderState,
            createOption: true,
            document_id: true,
          };
        case "waitApprove":
          return {
            ...orderState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            expect_date: true,
            credit_day: true,
            related_employees: true,
            destination_warehouse_document_id: true,
          };
        case "notApproved":
          return {
            ...orderState,
            printOption: true,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            expect_date: true,
            credit_day: true,
            related_employees: true,
            destination_warehouse_document_id: true,
          };
        case "approved":
        case "partiallyImported":
        case "fullyImported":
          return {
            ...orderState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            expect_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
            destination_warehouse_document_id: true,
          };
        case "cancelled":
          return {
            ...orderState,
            printOption: true,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            expect_date: true,
            credit_day: true,
            due_date: true,
            related_employees: true,
            destination_warehouse_document_id: true,
          };
        default:
          return {
            ...orderState,
            printOption: true,
            modifyOption: true,
            createOption: true,
            approval: "hide",
          };
      }
    case "return":
      const returnState = {
        ...defaultDisabledState,
        printOption: "hide",
        due_date: "hide",
        expect_date: "hide",
        payment: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...returnState,
            createOption: true,
            document_id: true,
          };
        case "waitApprove":
          return {
            ...returnState,
            document_id: true,
            external_ref_document_id: true,
            createOption: true,
            issue_date: true,
            delivery_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "notApproved":
        case "approved":
        case "finished":
          return {
            ...returnState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            delivery_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "cancelled":
          return {
            ...returnState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            delivery_date: true,
            credit_day: true,
            related_employees: true,
            modifyOption: true,
          };
        default:
          return {
            ...returnState,
            modifyOption: true,
            createOption: true,
            approval: "hide",
          };
      }
    default:
      return defaultDisabledState;
  }
};

export default defaultDisabledState;
