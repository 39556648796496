import { gql } from "graphql-request";

export const GET_ALL_DELIVERY_ORDER_IDS = gql`
  query DeliveryOrders {
    deliveryOrders {
      document_id
    }
  }
`;

export const GET_ALL_DELIVERY_ORDERS = gql`
  query DeliveryOrdersAggrid($aggridInput: AnyAggridInput) {
    deliveryOrdersAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        external_ref_id
        reference_document_id
        dt_document_id_list
        contact {
          document_id
          contact_name_1
          contact_name_2
        }
        created_date
        issue_date
        delivery_date
        updated_date
        is_incompleted
        price_vat_type
        additional_discount
        additional_discount_type
        shipping_cost
        total_amount
        item_group_level_1_name_list
        customer {
          delivery_address {
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
          email
          phone
          fax
        }
        created_by {
          document_id
          first_name
          last_name
          img_url
        }
        employee_list {
          document_id
          first_name
          last_name
          img_url
        }
        item_list {
          uid
          item_document_id
          item_name
          item_description
          qty_delivery
          delivery_uom_id
          qty_uom
          weight
          volume
          price_per_unit
          discount_amount
          vat_type
          withholding_tax
          delivery_uom {
            document_id
            id
            name
          }
          qty_sales
          sales_uom {
            document_id
            id
            name
          }
          item_remark
          qty_confirmed_delivery
        }
        recipient_name
        remark
        internal_remark
        target_status
        render_status
      }
      count
    }
  }
`;

export const GET_DELIVERY_ORDER = gql`
  query DeliveryOrder($uniqueInput: DeliveryOrderWhereUniqueInput!) {
    deliveryOrder(uniqueInput: $uniqueInput) {
      id
      document_id
      external_ref_id
      reference_document_id
      contact {
        document_id
        contact_name_1
        contact_name_2
        identity_no
      }
      created_date
      issue_date
      delivery_date
      contact_document_id
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      price_vat_type
      updated_date
      template_remark_id
      creator_document_id
      remark
      internal_remark
      recipient_name
      delivery_reference
      delivery_detail
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      customer {
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        qty_delivery
        delivery_uom_id
        qty_sales_delivered
        qty_uom
        weight
        volume
        price_per_unit
        discount_amount
        vat_type
        withholding_tax
        delivery_uom {
          document_id
          id
          name
        }
        qty_sales
        sales_uom {
          document_id
          id
          name
        }
        item_remark
        qty_confirmed_delivery
      }
      attachment_list {
        id
        name
        url
        uploaded_by {
          document_id
          first_name
          last_name
        }
        uploaded_date
      }
      target_status
      render_status
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
    }
  }
`;

export const GET_ALL_DELIVERY_TRIPS = gql`
  query DeliveryTripsAggrid($aggridInput: AnyAggridInput) {
    deliveryTripsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
        external_ref_id
        created_by {
          document_id
          first_name
          last_name
          img_url
        }
        employee_list {
          document_id
          first_name
          last_name
          img_url
        }
        created_date
        issue_date
        delivery_date
        last_updated_date
        delivery_type
        goods_deliver_list {
          document_id
          first_name
          last_name
          img_url
        }
        vehicle {
          created_date
          id
          license_plate
          remark
          remark_status
          status
          vehicle_type
          volume
          weight
        }
        do_document_id_list
        total_volume
        total_weight
        trip
        in_date
        out_date
        internal_remark
        target_status
        render_status
      }
    }
  }
`;

export const GET_ALL_DELIVERY_TRIPS_VEHICLE = gql`
  query DeliveryTripsAggrid($aggridInput: AnyAggridInput) {
    deliveryTripsAggrid(aggridInput: $aggridInput) {
      count
      results {
        document_id
        vehicle {
          created_date
          license_plate
          remark
          remark_status
          status
          vehicle_type
          volume
          weight
        }
        issue_date
        internal_remark
        goods_deliver_list {
          document_id
        }
        internal_remark
        target_status
        render_status
      }
    }
  }
`;

export const GET_DELIVERY_TRIP = gql`
  query DeliveryTrip($uniqueInput: DeliveryTripWhereUniqueInput!) {
    deliveryTrip(uniqueInput: $uniqueInput) {
      id
      document_id
      external_ref_id
      created_by {
        document_id
        first_name
        last_name
        img_url
      }
      employee_list {
        document_id
        first_name
        last_name
        img_url
      }
      do_document_id_list
      created_date
      issue_date
      delivery_date
      delivery_type
      goods_deliver_list {
        document_id
        first_name
        last_name
        img_url
      }
      vehicle {
        created_date
        id
        license_plate
        remark
        remark_status
        status
        vehicle_type
        volume
        weight
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        qty
        uom
        uom_id
        qty_uom
        dt_reference_document_id
        do_reference_document_id
        so_reference_document_id
        item_remark
        customer_name
        volume
        weight
      }
      total_volume
      total_weight
      trip
      in_date
      out_date
      internal_remark
      target_status
      render_status
    }
  }
`;

export const GET_DELIVERY_TRIP_REPORT = gql`
  query DeliveryTripReport($aggridInput: AnyAggridInput!) {
    deliveryTripReport(aggridInput: $aggridInput) {
      count
      results {
        document_id
        issue_date
        delivery_date
        vehicle_license_plate
        render_status
        reference_document_id
        external_ref_id
        delivery_type
        goods_deliver_list
        trip
        in_date
        out_date
        vehicle_type
        do_document_id
        do_customer
        do_issue_date
        do_delivery_date
        do_address
        do_sub_district
        do_district
        do_province
        total_volume
        total_weight
        do_qty_sales
        do_qty_delivery
        do_employee_list
        do_status
        delivery_remark
        created_date
        updated_date
        created_by
        employee_list
        internal_remark
      }
    }
  }
`;

export const GET_ALL_DELIVERY_ORDER_REPORT = gql`
  query DeliveryOrderReport($aggridInput: AnyAggridInput!) {
    deliveryOrderReport(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
        issue_date
        delivery_date
        render_status
        contact_document_id
        contact_name
        price_vat_type
        item_document_id
        item_name
        qty
        uom_name
        price_per_unit
        vat_type
        pre_vat_amount_exclude_discount
        item_discount
        item_additional_discount
        pre_vat_amount_include_discount
        item_vat_exempted_amount
        item_vat_0_amount
        item_vat_7_amount
        item_vat_amount
        item_net_amount
        item_withholding_tax_type
        item_withholding_tax
        item_total_amount
        shipping_cost
        total_amount
        additional_discount
        remark
        reference_document_id
        external_ref_id
        item_description
        item_remark
        created_date
        updated_date
        created_by
        employee_list
        email
        phone
        fax
        address_contact_name
        address_contact_phone
        delivery_address
        delivery_sub_district
        delivery_district
        delivery_province
        delivery_postal_code
        delivery_country
        delivery_status
        recipient_name
        delivery_reference
        internal_remark
        item_group_level_1_name_list
      }
    }
  }
`;
