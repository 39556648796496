import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schema as userDescSchema,
  validation as userDescValidation,
} from "../../../components/Form/Purchase/UserDescription/schema";
import {
  purchaseRequestSchema,
  purchaseRequestValidation,
} from "../../../components/Form/Purchase/Header/schema";
import OrderTab from "./OrderTab";
import {
  createNewPurchaseRequest,
  getPurchaseRequestById,
  updatePurchaseRequest,
  declinePurchaseRequest,
  approvePurchaseRequest,
} from "../../../features/Purchase/PurchaseRequest/purchase-request-actions";
import {
  purchaseRequestPayload,
  setPurchaseValueFormatter,
} from "../../../utils/purchasePayloadFormatter";
import { purchaseRequestActions } from "../../../features/Purchase/PurchaseRequest/purchase-request-slice";
import {
  purchasePrimaryButtonHandler,
  purchaseSecondaryButtonHandler,
} from "../../../utils/purchaseButtonHandler";
import PurchaseHeaderForm from "../../../components/Form/Purchase/Header";
import { getAllLocation } from "../../../features/Setting/Location/location-actions";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { useApproval } from "../../../hooks/use-approval";
// import { getAllSalesOrders } from "../../../features/Sales/SalesOrder/sales-order-actions";
import { validationItemListPR } from "../../../components/Form/Purchase/AddItemListForm/schema";
// import { getAllPurchaseOrderItem } from "../../../features/Purchase/PurchaseOrder/purchase-order-actions";

const defaultValues = {
  render_status: null,
  user_id: "",
  item_list: [],
  summary: {},
  creator_document_id: "",
  created_by: {},
  template_remark_id: "",
  remark: "",
};

const PurchaseRequestContainer = () => {
  const { user } = useAuth();
  const { refreshApprovals } = useApproval();
  const [viewOnly, setViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [primaryButtonTitle, setPrimaryButtonTitle] = useState("ส่ง");
  const [secondaryButtonTitle, setSecondaryButtonTitle] =
    useState("บันทึกร่าง");
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    isLoading,
    purchaseRequest,
    approvalList,
    isHaveApproval,
    isHaveApprovalPermission,
  } = useSelector((state) => state.purchaseRequest);
  const { createPermission, editPermission } = usePermission();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const headerRef = useRef();
  const userDescRef = useRef();
  const itemListRef = useRef();
  const gridRef = useRef();
  const {
    control,
    setValue,
    getValues,
    getFieldState,
    trigger: validateItemList,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationItemListPR),
  });
  const dispatch = useDispatch();

  const watchStatus = useWatch({ control, name: "render_status" });

  const [salesOrderIds, setSalesOrderIds] = useState([]);
  const [salesOrderIdsSnapshot, setSalesOrderIdsSnapshot] = useState([]);

  const userDescUseForm = useForm({
    defaultValues: { ...userDescSchema },
    resolver: yupResolver(userDescValidation),
  });

  const {
    trigger: validateUserDescription,
    setValue: setUserDescValue,
    getValues: getUserDescValues,
    getFieldState: getUserDescFieldState,
  } = userDescUseForm;

  const {
    control: headerControl,
    setValue: setHeaderValue,
    getValues: getHeaderValues,
    trigger: validateHeader,
    formState: { errors: headerErrors },
    getFieldState: getHeaderFieldState,
  } = useForm({
    defaultValues: { ...purchaseRequestSchema },
    resolver: yupResolver(purchaseRequestValidation),
  });

  useEffect(() => {
    dispatch(getAllLocation());
    if (state) {
      dispatch(purchaseRequestActions.loadedPurchaseRequest(state));
    } else if (id) {
      dispatch(
        getPurchaseRequestById(
          {
            document_id: id,
          },
          user,
          enqueueSnackbar
        )
      );
    }
    return () => {
      dispatch(purchaseRequestActions.resetPurchaseRequest());
    };
  }, [dispatch, enqueueSnackbar, id, state, user]);

  useEffect(() => {
    if (state && state.isCopied) {
      setPurchaseValueFormatter(
        purchaseRequestSchema,
        userDescSchema,
        defaultValues,
        setHeaderValue,
        setUserDescValue,
        setValue,
        state,
        true,
        "purchase_request"
      );
    } else if (state && !state.isCopied) {
      const formatItemList = state["item_list"].map((item) => ({
        ...item,
        po_qty: 0,
        ref_document_id: state["document_id"],
      }));
      const formatPurchaseReturn = {
        ...state,
        item_list: formatItemList,
      };
      setSalesOrderIds([state["document_id"]]);
      setSalesOrderIdsSnapshot([state["document_id"]]);
      setPurchaseValueFormatter(
        purchaseRequestSchema,
        userDescSchema,
        defaultValues,
        setHeaderValue,
        setUserDescValue,
        setValue,
        formatPurchaseReturn,
        true,
        "purchase_request"
      );
    } else if (id) {
      setPurchaseValueFormatter(
        purchaseRequestSchema,
        userDescSchema,
        defaultValues,
        setHeaderValue,
        setUserDescValue,
        setValue,
        purchaseRequest,
        false,
        "purchase_request"
      );
    }
  }, [purchaseRequest, setValue, setHeaderValue, id, state, setUserDescValue]);

  const currentTab = pathname + (tab ? `?tab=order` : "");

  const breadcrumbs = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.request.index"),
      to: "/purchase/request",
    },
    {
      name: id ?? t("purchase.request.add"),
    },
  ];

  const tabs = [
    {
      label: t("purchase.documentInfo"),
      path: `${pathname}`,
    },
    {
      label: t("purchase.order.index"),
      path: `${pathname}?tab=order`,
    },
  ];

  useEffect(() => {
    switch (watchStatus) {
      case "waitApprove":
      case "approved":
      case "notApproved":
      case "partiallyOrdered":
      case "fullyOrdered":
      case "cancelled":
      case "expired":
        setViewOnly(true);
        break;
      default:
        setViewOnly(false);
    }
  }, [watchStatus]);

  const submitUserForm = (data) => {
    setUserDescValue("contact", data);
  };

  const submitForm = async () => {
    const userDescIsValid = await validateUserDescription();
    const headerIsValid = await validateHeader();
    const itemListIsValid = await validateItemList();

    const documentIdError = getHeaderFieldState("document_id").error;
    const issueDateError = getHeaderFieldState("issue_date").error;
    const dueDateError = getHeaderFieldState("due_date").error;
    const userError = getUserDescFieldState("user").error;
    const itemListError = getFieldState("item_list").error;

    const errorsCount =
      (documentIdError ? 1 : 0) +
      (issueDateError ? 1 : 0) +
      (dueDateError ? 1 : 0) +
      (userError ? 1 : 0);

    if (errorsCount > 1) {
      enqueueSnackbar("กรุณาตรวจสอบข้อมูลให้ครบถ้วน", {
        variant: "error",
      });
    }

    if (itemListError?.length > 0) {
      if (itemListError.some((item) => item.is_active)) {
        enqueueSnackbar("กรุณาระบุสินค้าใหม่ เนื่องจากมีสินค้าที่หยุดใช้งาน", {
          variant: "error",
        });
      }
    } else if (itemListError?.message) {
      enqueueSnackbar(itemListError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(documentIdError)) {
      enqueueSnackbar(documentIdError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(issueDateError)) {
      enqueueSnackbar(issueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(dueDateError)) {
      enqueueSnackbar(dueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(userDescIsValid)) {
      enqueueSnackbar("กรุณาเลือกผู้ขอ", {
        variant: "error",
      });
    }
    if (!headerIsValid) {
      headerRef.current.scrollIntoView();
      return;
    }
    if (!userDescIsValid) {
      userDescRef.current.scrollIntoView();
      return;
    }

    if (!itemListIsValid && itemListError) {
      itemListRef.current.scrollIntoView();
      return;
    }
    if (userDescIsValid && headerIsValid) {
      const { render_status, summary, ...mainValues } = getValues();
      const headerValues = getHeaderValues();
      const userDescValues = getUserDescValues();

      const transformedEmployeeList = headerValues.employee_list.map(
        ({
          id,
          is_active,
          jwt_modifier,
          exp,
          iat,
          created_date,
          last_login_date,
          role_list,
          status,
          ...employee
        }) => employee
      );
      const payload = purchaseRequestPayload(
        headerValues,
        mainValues,
        transformedEmployeeList,
        userDescValues,
        user,
        id
      );
      return payload;
    }
  };

  const saveDraftHandler = async () => {
    const payload = await submitForm();
    if (!id) {
      dispatch(
        createNewPurchaseRequest(
          payload,
          false,
          state,
          user,
          enqueueSnackbar,
          navigate
        )
      );
    } else {
      dispatch(
        updatePurchaseRequest(
          payload,
          watchStatus,
          false,
          purchaseRequest,
          user,
          enqueueSnackbar
        )
      );
    }
  };

  const sendApproveHandler = async () => {
    const payload = await submitForm();
    if (!id) {
      dispatch(
        createNewPurchaseRequest(
          payload,
          true,
          state,
          user,
          enqueueSnackbar,
          navigate
        )
      );
    } else {
      dispatch(
        updatePurchaseRequest(
          payload,
          watchStatus,
          true,
          purchaseRequest,
          user,
          enqueueSnackbar
        )
      );
    }
  };

  const notApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        declinePurchaseRequest(
          {
            document_id,
            document_type: "purchase_request",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const approveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        approvePurchaseRequest(
          {
            document_id,
            document_type: "purchase_request",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const editSubmitHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      delete payload.reference_document_id;
      dispatch(
        updatePurchaseRequest(
          payload,
          watchStatus,
          false,
          purchaseRequest,
          user,
          enqueueSnackbar
        )
      );
      setIsEdit(false);
      setViewOnly(true);
    }
    setIsEdit(false);
    setViewOnly(true);
  };

  const editCancelHandler = () => {
    navigate("/purchase/request");
  };

  const shouldRenderButtons = () => {
    if (isEdit) return true;
    if (isLoading.purchaseRequest) {
      return false;
    } else {
      if (pathname.includes("add")) {
        return true;
      }
      if (watchStatus) {
        switch (watchStatus) {
          case "draft":
            // case "notApproved":
            return true;
          case "waitApprove":
            if (isHaveApproval) {
              if (isHaveApprovalPermission) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          default:
            return false;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setPrimaryButtonTitle("บันทึก");
      setSecondaryButtonTitle("ยกเลิก");
    } else {
      switch (watchStatus) {
        case "waitApprove":
          setPrimaryButtonTitle("อนุมัติ");
          setSecondaryButtonTitle("ไม่อนุมัติ");
          break;
        default:
      }
    }
  }, [isEdit, watchStatus]);

  const primarySubmitHandler = () => {
    purchasePrimaryButtonHandler(
      watchStatus,
      isEdit,
      sendApproveHandler,
      approveHandler,
      editSubmitHandler
    );
  };

  const secondarySubmitHandler = async () => {
    purchaseSecondaryButtonHandler(
      watchStatus,
      isEdit,
      saveDraftHandler,
      notApproveHandler,
      editCancelHandler
    );
  };

  const editClickHandler = () => {
    setViewOnly(false);
    setIsEdit(true);
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {!tab ? (
        <>
          <PurchaseHeaderForm
            ref={headerRef}
            gridRef={gridRef}
            control={headerControl}
            errors={headerErrors}
            setValue={setHeaderValue}
            setMainValue={setValue}
            getValues={getHeaderValues}
            getMainValues={getValues}
            mainFormControl={control}
            viewOnly={viewOnly || Boolean(!editPermission && id)}
            documentType="purchase_request"
            editClickHandler={editClickHandler}
            isEdit={isEdit}
            approvalList={approvalList}
            currentState={state?.id ?? purchaseRequest}
            setSalesOrderIds={setSalesOrderIds}
            setSalesOrderIdsSnapshot={setSalesOrderIdsSnapshot}
            setIsEdit={setIsEdit}
          />
          <DocumentInfoTab
            itemListRef={itemListRef}
            gridRef={gridRef}
            ref={userDescRef}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            setHeaderValue={setHeaderValue}
            getHeaderValues={getHeaderValues}
            submitUserDesc={submitUserForm}
            userDescUseForm={userDescUseForm}
            viewOnly={viewOnly || Boolean(!editPermission && id)}
            salesOrderIds={salesOrderIds}
            setSalesOrderIds={setSalesOrderIds}
            salesOrderIdsSnapshot={salesOrderIdsSnapshot}
            setSalesOrderIdsSnapshot={setSalesOrderIdsSnapshot}
          />
          {shouldRenderButtons() &&
            ((createPermission && !id) || (editPermission && id)) && (
              <Box sx={{ display: "flex" }} mt={2}>
                <Box sx={{ mr: 1 }}>
                  <CustomizedButton
                    title={secondaryButtonTitle}
                    variant="outlined"
                    type="reset"
                    onClick={secondarySubmitHandler}
                  />
                </Box>
                <CustomizedButton
                  title={primaryButtonTitle}
                  variant="contained"
                  onClick={primarySubmitHandler}
                />
              </Box>
            )}
        </>
      ) : (
        <OrderTab />
      )}
    </>
  );
};

export default PurchaseRequestContainer;
