import { createSlice } from "@reduxjs/toolkit";

const attributeSlice = createSlice({
  name: "attribute",
  initialState: {
    isLoading: {
      allAttribute: false,
      attribute: false,
    },
    attributeIndex: null,
    allAttribute: [],
    attribute: {
      name: "",
      acronym: "",
      data_type: "text",
      description: "",
    },
    modal: false,
    error: null,
  },
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload] = false;
      state.error = action.payload;
    },
    loadedAllAttribute(state, action) {
      state.allAttribute = action.payload;
      state.isLoading.allAttribute = false;
    },
    loadedAttributeIndex(state, action) {
      state.attributeIndex = action.payload;
    },
    loadedAttribute(state, action) {
      state.attribute = action.payload.result;
      if (action.payload.index === 0 || action.payload.index)
        state.allAttribute[action.payload.index] = action.payload.result;
      if (action.payload.create)
        state.allAttribute = [...state.allAttribute, action.payload.result];
      state.isLoading.attribute = false;
    },
    removeAttribute(state, action) {
      const newState = state.allAttribute.filter((attribute) => {
        return attribute.id !== action.payload.result.id;
      });
      state.allAttribute = newState;
    },
    openModal(state) {
      state.modal = true;
    },
    closeModal(state) {
      state.modal = false;
    },
    resetAllAttribute(state) {
      state.allAttribute = [];
    },
    resetAttribute(state) {
      state.attribute = {
        name: "",
        acronym: "",
        data_type: "number",
        description: "",
      };
    },
  },
});

export const attributeActions = attributeSlice.actions;

export default attributeSlice.reducer;
