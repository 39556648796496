import { useEffect, useState } from "react";
import { permissionAction } from "../../utils/userInfo";

const allPermissionList = permissionAction();

const formatAction = (formatList) => {
  return formatList.reduce((a, v) => {
    const action = v.split("__")[2];
    return { ...a, [action]: v };
  }, {});
};

export const useQuotationPermission = () => {
  const [salesPermission, setSalesPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("SALES__QUOTATION")
    );
    const actions = formatAction(formatList);
    setSalesPermission(actions);
  }, []);

  return salesPermission;
};

export const useSalesOrderPermission = () => {
  const [salesPermission, setSalesPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("SALES__ORDER")
    );
    const actions = formatAction(formatList);
    setSalesPermission(actions);
  }, []);

  return salesPermission;
};

export const useSalesReturnPermission = () => {
  const [salesPermission, setSalesPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("SALES__RETURN")
    );
    const actions = formatAction(formatList);
    setSalesPermission(actions);
  }, []);

  return salesPermission;
};

export const useSalesReportPermission = () => {
  const [salesPermission, setSalesPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("SALES__REPORT")
    );
    const actions = formatAction(formatList);
    setSalesPermission(actions);
  }, []);

  return salesPermission;
};
