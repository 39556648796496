export const initialState = {
  isLoading: {
    allDeliveryTrips: false,
    deliveryTripReports: false,
    deliveryTrip: false,
  },
  render_status: null,
  allDeliveryTrips: [],
  allDeliveryTripsExport: [],
  deliveryTrip: {
    document_id: "",
    reference_document_id: [],
    external_ref_id: "",
    created_by: null,
    employee_list: [],
    created_date: null,
    issue_date: null,
    delivery_date: null,
    delivery_type: "",
    vehicle: null,
    goods_deliver_list: [],
    trip: 0,
    in_date: null,
    out_date: null,
    delivery_order_list: [],
    internal_remark: "",
    item_list: [],
  },
  error: null,
};
