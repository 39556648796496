import { useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import CustomizedButton from "../Custom/CustomizedButton";
import CustomizedLetterAvatar from "../Custom/CustomizedLetterAvatar";
import CheckboxModalTable from "../Table/CheckboxModalTable";
import CustomizedStatus from "../Custom/CustomizedStatus";
import AgGrid from "../Table/AgGrid";
import { getAllUsers } from "../../features/User/Account/account-actions";
import { departmentEngToThai } from "../../utils/dataTransformer";

const ApprovalSettingAccordion = ({
  index,
  onRemove,
  setApproversValue,
  getApproversValues,
  stepsSelectedRowIds,
  viewOnly,
}) => {
  const dispatch = useDispatch();
  const gridRef = useRef();
  const modalGridRef = useRef();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const startingEmployeesValue =
    getApproversValues(`approvers`)[index]?.selectedEmployees || [];
  const startingEmployeesIds =
    startingEmployeesValue?.map((item) => item.document_id) || [];
  const [employeeIds, setEmployeeIds] = useState(startingEmployeesIds);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] =
    useState(startingEmployeesIds);

  const { control, setValue } = useForm({
    defaultValues: {
      selectedEmployees: startingEmployeesValue,
    },
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "selectedEmployees",
  });

  const watchSelectedEmployees = useWatch({
    control,
    name: "selectedEmployees",
  });

  const removeApproverHandler = (rowIndex) => {
    remove(rowIndex);
    const updatedEmployeesId = [
      ...employeeIds.slice(0, rowIndex),
      ...employeeIds.slice(rowIndex + 1),
    ];
    const currentRowEmployees = getApproversValues("approvers")[
      index
    ].selectedEmployees.filter((employee) =>
      updatedEmployeesId.includes(employee.document_id)
    );
    setApproversValue(`approvers.${index}`, {
      selectedEmployees: currentRowEmployees,
    });
    setEmployeeIds(updatedEmployeesId);
    setEmployeeIdsSnapshot(updatedEmployeesId);
  };

  const closeModalTable = () => {
    setOpenModal(false);
  };

  const finishSelectEmployeesHandler = (data) => {
    let filteredEmployees = fields.filter((employee) =>
      employeeIds.includes(employee.document_id)
    );

    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.document_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.document_id)) {
        filteredEmployees.push(employee);
      }
    });

    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        employeeIds.indexOf(a.document_id) - employeeIds.indexOf(b.document_id)
      );
    });
    setValue("selectedEmployees", filteredEmployees);
    setApproversValue(`approvers.${index}`, {
      selectedEmployees: filteredEmployees,
    });
    setEmployeeIds(employeeIds);
    setEmployeeIdsSnapshot(employeeIds);
    setOpenModal(false);
  };

  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("user.account.employeeId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "img_url",
      headerName: t("user.account.picture"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data.img_url) {
          return <Avatar alt="img_url" src={params.data.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.data.first_name + " " + params.data.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "first_name",
      headerName: t("user.account.firstname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_name",
      headerName: t("user.account.lastname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "sales",
          "inventory",
          "account",
          "purchase",
          "admin",
          "manufacture",
          "logistic",
        ],
        valueFormatter: (params) => {
          return departmentEngToThai(params.value);
        },
      },
      valueFormatter: (params) => {
        return departmentEngToThai(params.value);
      },
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: "",
      filter: false,
      cellRenderer: (params) =>
        !viewOnly && (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => removeApproverHandler(params.rowIndex)}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
    },
  ];

  const modalColumnDefs = [
    {
      field: "document_id",
      headerName: t("user.account.employeeId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "img_url",
      headerName: t("user.account.picture"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data.img_url) {
          return <Avatar alt="img_url" src={params.data.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.data.first_name + " " + params.data.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "first_name",
      headerName: t("user.account.firstname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_name",
      headerName: t("user.account.lastname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "sales",
          "inventory",
          "account",
          "purchase",
          "admin",
          "manufacture",
          "logistic",
        ],
        valueFormatter: (params) => {
          return departmentEngToThai(params.value);
        },
      },
      valueFormatter: (params) => {
        return departmentEngToThai(params.value);
      },
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("user.account.status"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      dispatch(
        getAllUsers(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: request.filterModel,
            sortModel: request.sortModel,
          },
          params
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const rowsToDisable = stepsSelectedRowIds
    .reduce((prevRows, currentRow, currentIndex) => {
      if (currentIndex === index) {
        return prevRows;
      }
      return [...prevRows, [...currentRow]];
    }, [])
    .flat();

  return (
    <>
      <Accordion sx={{ mt: 3 }} defaultExpanded elevation={0}>
        <AccordionSummary
          sx={{ flexDirection: "row-reverse" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>{`ลำดับที่ ${index + 1}`}</Typography>
            {index !== 0 && (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={onRemove}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: "0.5px solid #BEBEBE" }}>
          {!viewOnly && (
            <CustomizedButton
              onClick={() => setOpenModal(true)}
              size="medium"
              title={"เพิ่มผู้มีสิทธิ์อนุมัติ"}
              variant="outlined"
              color="secondary"
              sx={{ my: 1, mr: 2 }}
            />
          )}
          {watchSelectedEmployees?.length > 0 && (
            <Box
              sx={{
                padding: 2,
                backgroundColor: "#E8F1E859",
                overflow: "hidden",
              }}
            >
              <AgGrid
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={watchSelectedEmployees}
                height={375}
              />
            </Box>
          )}
          <Divider sx={{ mx: "auto", my: 4, width: "100%" }} />
        </AccordionDetails>
      </Accordion>
      <CheckboxModalTable
        modalTitle={t("user.account.index")}
        btnTitle={t("button.add")}
        gridRef={modalGridRef}
        height={450}
        columnDefs={modalColumnDefs}
        rowSelection="multiple"
        onFinishEditing={finishSelectEmployeesHandler}
        modalIsOpen={openModal}
        closeModal={closeModalTable}
        onGridReady={onGridReady}
        selectedIds={employeeIds}
        setSelectedIds={setEmployeeIds}
        idsSnapshot={employeeIdsSnapshot}
        getRowId={getRowId}
        setIdsSnapshot={setEmployeeIdsSnapshot}
        // selectionLimit={5}
        rowsToDisable={rowsToDisable}
      />
    </>
  );
};

export default ApprovalSettingAccordion;
