import { gql } from "graphql-request";

export const GET_APPROVAL_TEMPLATES = gql`
  query ApprovalTemplatesByDocumentType($documentType: DocumentType!) {
    approvalTemplatesByDocumentType(documentType: $documentType) {
      id
      step_number
      reviewer_document_id_list
      reviewer_list {
        document_id
        first_name
        last_name
        email
        img_url
      }
      is_required_approve
    }
  }
`;

export const GET_APPROVAL_PROGRESS = gql`
  query ApprovalProgressByDocument(
    $referenceDocumentInput: ReferenceDocumentInput!
  ) {
    approvalProgressByDocument(
      referenceDocumentInput: $referenceDocumentInput
    ) {
      approval_template_id
      approval_template {
        id
        step_number
        step_name
        action
        workflow_step_number
        workflow_document_type
        reviewer_document_id_list
        reviewer_list {
          document_id
          first_name
          last_name
          email
          img_url
        }
        is_required_approve
      }
      step_number
      approval_status
      created_date
      created_by_document_id
      approved_by_document_id
      approved_by {
        document_id
        first_name
        last_name
      }
    }
  }
`;

export const GET_PURCHASE_REQUEST_ID = gql`
  query PurchaseRequest($uniqueInput: PurchaseRequestWhereUniqueInput!) {
    purchaseRequest(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const GET_ALL_PURCHASE_REQUEST_SELF = gql`
  query PurchaseRequestsViewSelfAggrid($aggridInput: AnyAggridInput) {
    purchaseRequestsViewSelfAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        external_ref_id
        destination_warehouse_document_id
        destination_warehouse {
          document_id
          thai_name
          description
          is_active
        }
        reference_document_list {
          reference_document_id
          reference_document_type
        }
        so_document_id_list
        employee_list {
          id
          document_id
          first_name
          last_name
          img_url
        }
        created_date
        updated_date
        creator_document_id
        created_by {
          id
          first_name
          img_url
          last_name
          email
          document_id
          department
        }
        issue_date
        due_date
        requester_document_id
        requester_name
        requester_department
        requester_position
        item_list {
          uid
          ref_document_id
          item_document_id
          item_name
          item_description
          uom_id
          qty
          item_remark
          ordered_qty
          uom {
            id
            document_id
            name
          }
        }
        template_remark_id
        remark
        is_partial_po
        is_fully_po
        is_late
        is_canceled
        render_status
        target_status
        workflow_progress_list {
          id
          workflow_step_number
          workflow_document_type
          workflow {
            step_number
            document_type
            status_type
            initial_status
            condition
            target_status
            is_editable
            remark
          }
          reference_document_id
          reference_document_type
          status
          employee_document_id
          changed_date
          payload
          approval_progress_list {
            id
            approval_template_id
            step_number
            workflow_progress_id
            approval_status
            created_by_document_id
            created_date
          }
        }
      }
      count
    }
  }
`;

export const GET_ALL_PURCHASE_REQUEST = gql`
  query PurchaseRequestsAggrid($aggridInput: AnyAggridInput) {
    purchaseRequestsAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        external_ref_id
        destination_warehouse_document_id
        destination_warehouse {
          document_id
          thai_name
          description
          is_active
        }
        reference_document_list {
          reference_document_id
          reference_document_type
        }
        so_document_id_list
        employee_list {
          id
          document_id
          first_name
          last_name
          img_url
        }
        created_date
        updated_date
        creator_document_id
        created_by {
          id
          first_name
          img_url
          last_name
          email
          document_id
          department
        }
        issue_date
        due_date
        requester_document_id
        requester_name
        requester_department
        requester_position
        item_list {
          uid
          ref_document_id
          item_document_id
          item_name
          item_description
          uom_id
          qty
          item_remark
          ordered_qty
          uom {
            id
            document_id
            name
          }
        }
        template_remark_id
        remark
        is_partial_po
        is_fully_po
        is_late
        is_canceled
        render_status
        target_status
        workflow_progress_list {
          id
          workflow_step_number
          workflow_document_type
          workflow {
            step_number
            document_type
            status_type
            initial_status
            condition
            target_status
            is_editable
            remark
          }
          reference_document_id
          reference_document_type
          status
          employee_document_id
          changed_date
          payload
          approval_progress_list {
            id
            approval_template_id
            step_number
            workflow_progress_id
            approval_status
            created_by_document_id
            created_date
          }
        }
      }
      count
    }
  }
`;

export const GET_PURCHASE_REQUEST = gql`
  query Results($uniqueInput: PurchaseRequestWhereUniqueInput!) {
    purchaseRequest(uniqueInput: $uniqueInput) {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      so_document_id_list
      employee_list {
        id
        document_id
        first_name
        last_name
        img_url
      }
      created_date
      updated_date
      creator_document_id
      created_by {
        id
        document_id
        first_name
        last_name
        email
        phone
        img_url
      }
      issue_date
      due_date
      requester_document_id
      requester_name
      requester_department
      requester_position
      item_list {
        uid
        ref_document_id
        item_document_id
        item_name
        item_description
        uom_id
        qty
        item_remark
        ordered_qty
        uom {
          id
          document_id
          name
        }
      }
      template_remark_id
      remark
      is_partial_po
      is_fully_po
      is_late
      is_canceled
      render_status
      target_status
      workflow_progress_list {
        id
        workflow_step_number
        workflow_document_type
        workflow {
          step_number
          document_type
          status_type
          initial_status
          condition
          target_status
          is_editable
          remark
        }
        reference_document_id
        reference_document_type
        status
        employee_document_id
        changed_date
        payload
        approval_progress_list {
          id
          approval_template_id
          step_number
          workflow_progress_id
          approval_status
          created_by_document_id
          created_date
        }
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_ID = gql`
  query PurchaseOrder($uniqueInput: PurchaseOrderWhereUniqueInput!) {
    purchaseOrder(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const GET_ALL_PURCHASE_ORDER = gql`
  query purchaseOrdersAggrid($aggridInput: AnyAggridInput) {
    purchaseOrdersAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
        external_ref_id
        destination_warehouse_document_id
        reference_document_list {
          reference_document_id
          reference_document_type
        }
        gr_document_id_list
        destination_warehouse {
          document_id
          thai_name
          description
          is_active
        }
        contact_document_id
        contact {
          document_id
          identity_no
          contact_type_1
          contact_type_2
          contact_name_1
          contact_name_2
        }
        employee_list {
          first_name
          last_name
          img_url
          document_id
          department
          email
        }
        created_date
        issue_date
        expect_date
        due_date
        credit_day
        pre_vat_amount
        price_vat_type
        shipping_cost
        additional_discount
        additional_discount_type
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
        template_remark_id
        remark
        updated_date
        creator_document_id
        created_by {
          document_id
          first_name
          last_name
          img_url
          email
          department
        }
        contact {
          contact_name_1
          contact_name_2
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
        item_list {
          uid
          item_document_id
          item_name
          item_description
          uom_id
          uom {
            document_id
            id
            name
          }
          qty
          qty_uom
          price_per_unit
          discount_amount
          vat_type
          pre_vat_amount
          ref_document_id
          withholding_tax
          received_qty
          returned_qty
        }
        is_partial_gr
        is_fully_gr
        is_canceled
        render_status
        target_status
        vendor {
          email
          fax
          identify_no
          phone
          name
          delivery_address {
            address_type
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
          billing_address {
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        render_status
        target_status
      }
    }
  }
`;

export const GET_ALL_PURCHASE_ORDER_ITEM_LIST = gql`
  query PurchaseOrdersAggrid($aggridInput: AnyAggridInput) {
    purchaseOrdersAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        item_list {
          uid
          qty
        }
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_PRICE_LIST = gql`
  query PurchaseOrdersAggrid($aggridInput: AnyAggridInput) {
    purchaseOrdersAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        issue_date
        item_list {
          uid
          item_document_id
          item_name
          item_description
          qty
          uom_id
          qty_uom
          price_per_unit
          discount_amount
          uom {
            id
            document_id
            name
          }
          withholding_tax
        }
      }
      count
    }
  }
`;

export const GET_ALL_PURCHASE_ORDER_WITHOUT_AGGRID = gql`
  query purchaseOrders {
    purchaseOrders {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      gr_document_id_list
      contact_document_id
      contact {
        document_id
        identity_no
        contact_type_1
        contact_type_2
        contact_name_1
        contact_name_2
      }
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      created_date
      issue_date
      expect_date
      due_date
      credit_day
      pre_vat_amount
      price_vat_type
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      template_remark_id
      remark
      updated_date
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
        email
        department
      }
      contact {
        contact_name_1
        contact_name_2
      }
      delivery_address {
        address_type
        is_same_as_default_address
        address_contact_name
        address_contact_phone
        address
        sub_district
        district
        province
        postal_code
        country
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        uom_id
        uom {
          document_id
          id
          name
        }
        qty
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        pre_vat_amount
        ref_document_id
        withholding_tax
        received_qty
        returned_qty
      }
      # is_partial_gr
      # is_fully_gr
      # is_canceled
      # render_status
      # target_status
      vendor {
        email
        fax
        identify_no
        phone
        name
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      # render_status
      # target_status
    }
  }
`;

export const GET_PURCHASE_ORDER = gql`
  query PurchaseOrder($uniqueInput: PurchaseOrderWhereUniqueInput!) {
    purchaseOrder(uniqueInput: $uniqueInput) {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      gr_document_id_list
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      contact_document_id
      contact {
        document_id
        identity_no
        contact_type_1
        contact_type_2
        contact_name_1
        contact_name_2
      }
      created_date
      issue_date
      expect_date
      due_date
      credit_day
      price_vat_type
      pre_vat_amount
      shipping_cost
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      template_remark_id
      remark
      updated_date
      creator_document_id
      created_by {
        id
        first_name
        img_url
        last_name
        email
        document_id
        department
      }
      vendor {
        name
        identify_no
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      delivery_address {
        address_type
        is_same_as_default_address
        address_contact_name
        address_contact_phone
        address
        sub_district
        district
        province
        postal_code
        country
      }
      item_list {
        uid
        item_document_id
        item_name
        item_description
        uom_id
        uom {
          document_id
          id
          name
        }
        qty
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        pre_vat_amount
        ref_document_id
        withholding_tax
        received_qty
        returned_qty
      }
      render_status
      target_status
    }
  }
`;

export const GET_PURCHASE_RETURN_ID = gql`
  query PurchaseReturn($uniqueInput: PurchaseReturnWhereUniqueInput!) {
    purchaseReturn(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const GET_ALL_PURCHASE_RETURN_IDS = gql`
  query PurchaseReturnsAggrid($aggridInput: AnyAggridInput) {
    purchaseReturnsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
      }
    }
  }
`;

export const GET_ALL_PURCHASE_RETURN = gql`
  query PurchaseReturnsAggrid($aggridInput: AnyAggridInput) {
    purchaseReturnsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
        external_ref_id
        destination_warehouse_document_id
        destination_warehouse {
          document_id
          thai_name
          description
          is_active
        }
        reference_document_list {
          reference_document_id
          reference_document_type
        }
        contact_document_id
        contact {
          document_id
          identity_no
          contact_type_1
          contact_type_2
          contact_name_1
          contact_name_2
        }
        vendor {
          name
          identify_no
          email
          phone
          fax
          billing_address {
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        employee_list {
          first_name
          last_name
          img_url
          document_id
          department
          email
        }
        creator_document_id
        created_by {
          id
          first_name
          img_url
          last_name
          email
          document_id
          department
        }
        created_date
        issue_date
        delivery_date
        credit_day
        reason_to_return
        price_vat_type
        pre_vat_amount
        additional_discount
        additional_discount_type
        vat_exempted_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        net_amount
        withholding_tax_amount
        total_amount
        template_remark_id
        remark
        updated_date
        external_debit_note_id
        item_list {
          uid
          item_document_id
          item_name
          item_description
          uom_id
          uom {
            document_id
            id
            name
          }
          qty
          qty_return
          qty_uom
          price_per_unit
          discount_amount
          vat_type
          pre_vat_amount
          ref_document_id
          withholding_tax
        }
        is_canceled
        is_finished
        target_status
        render_status
        gr_document_id_list
      }
    }
  }
`;

export const GET_ALL_PURCHASE_RETURN_WITHOUT_AGGRID = gql`
  query PurchaseReturns {
    purchaseReturns {
      id
      document_id
      external_ref_id
      destination_warehouse_document_id
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      contact_document_id
      contact {
        document_id
        identity_no
        contact_type_1
        contact_type_2
        contact_name_1
        contact_name_2
      }
      vendor {
        name
        identify_no
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      creator_document_id
      created_by {
        id
        first_name
        img_url
        last_name
        email
        document_id
        department
      }
      created_date
      issue_date
      delivery_date
      credit_day
      reason_to_return
      price_vat_type
      pre_vat_amount
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      template_remark_id
      remark
      updated_date
      external_debit_note_id
      item_list {
        uid
        item_document_id
        item_name
        item_description
        uom_id
        uom {
          document_id
          id
          name
        }
        qty
        qty_return
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        pre_vat_amount
        ref_document_id
        withholding_tax
      }
      is_canceled
      is_finished
      gr_document_id_list
    }
  }
`;

export const GET_PURCHASE_RETURN = gql`
  query PurchaseReturn($uniqueInput: PurchaseReturnWhereUniqueInput!) {
    purchaseReturn(uniqueInput: $uniqueInput) {
      id
      document_id
      external_ref_id
      contact_document_id
      reference_document_list {
        reference_document_id
        reference_document_type
      }
      destination_warehouse_document_id
      destination_warehouse {
        document_id
        thai_name
        description
        is_active
      }
      vendor {
        name
        identify_no
        email
        phone
        fax
        billing_address {
          address
          sub_district
          district
          province
          postal_code
          country
        }
        delivery_address {
          address_type
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
      }
      employee_list {
        first_name
        last_name
        img_url
        document_id
        department
        email
      }
      creator_document_id
      contact {
        document_id
        identity_no
        contact_type_1
        contact_type_2
        contact_name_1
        contact_name_2
      }
      created_by {
        id
        first_name
        img_url
        last_name
        email
        document_id
        department
      }
      created_date
      issue_date
      delivery_date
      credit_day
      reason_to_return
      price_vat_type
      pre_vat_amount
      additional_discount
      additional_discount_type
      vat_exempted_amount
      vat_0_amount
      vat_7_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      template_remark_id
      remark
      updated_date
      external_debit_note_id
      item_list {
        uid
        item_document_id
        item_name
        item_description
        uom_id
        uom {
          document_id
          id
          name
        }
        qty
        qty_return
        qty_uom
        price_per_unit
        discount_amount
        vat_type
        pre_vat_amount
        ref_document_id
        withholding_tax
      }
      render_status
      target_status
      is_canceled
      is_finished
    }
  }
`;
