import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
// import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import AgGrid from "../../../components/Table/AgGrid";
// import RightDrawer from "../../../components/UI/RightDrawer";
import TotalBox from "../../../components/UI/TotalBox";
import { getAllGoodsReceiveNoItem } from "../../../features/Inventory/GoodsReceive/goodsReceive-actions";
import {
  formatDate,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import { goodsReceiveActions } from "../../../features/Inventory/GoodsReceive/goodsReceive-slice";
import {
  filterDateWithUnix,
  filterParamsOptions,
} from "../../../utils/filterparams";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import { useCallback } from "react";
import CustomizedScrollbar from "../../../components/Custom/CustomizedScrollbar";
import CustomizedToolTipAvatar from "../../../components/Custom/CustomizedToolTipAvatar";
import { usePermission } from "../../../hooks/use-auth";

const Return = () => {
  const scrollRef = useRef();
  const theme = useTheme();
  const gridRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const { enqueueSnackbar } = useSnackbar();
  const { count } = useSelector((state) => state.goodsReceive);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showCamera, setShowCamera] = useState(false);
  const { allItems } = useSelector((state) => state.goodsReceive);
  const { createPermission } = usePermission();
  // const [openDrawer, setOpenDrawer] = useState(false);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.return.index"),
    },
  ];

  const columnDefs = [
    {
      field: "created_date",
      headerName: t("inventory.documentDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => {
        if (!params.data.created_date) return "";
        return unixToDateWithFormat(params.data.created_date);
      },
    },
    {
      field: "document_date",
      headerName: t("inventory.return.returnDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => {
        if (!params.data.document_date) return "";
        return unixToDateWithFormat(params.data.document_date);
      },
    },
    {
      field: "document_id",
      headerName: t("inventory.return.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "reference_document_id",
      headerName: t("inventory.referenceNo"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      // hide: true,
    },

    {
      field: "source_warehouse",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      valueFormatter: (params) => params.value.thai_name,
    },
    {
      field: "type",
      headerName: t("inventory.return.returnType"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["รับคืนจากลูกค้า", "รับคืนจากผลิต"],
      },
    },
    {
      field: "created_by",
      headerName: t("inventory.return.importedBy"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.value.img_url) {
          return <CustomizedToolTipAvatar avatar={params.value} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.value.first_name + " " + params.value.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "remark",
      headerName: t("inventory.remark"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("inventory.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["draft", "finished", "cancelled"],
        valueFormatter: (params) => {
          if (params.value === "draft") {
            return "ร่าง";
          }
          if (params.value === "finished") {
            return "เสร็จสิ้น";
          }
          return "ยกเลิก";
        },
      },
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.data.status} />;
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  const tabs = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    // {
    //   label: t("status.draft"),
    //   path: `${pathname}?status=draft`,
    // },
    // {
    //   label: t("status.finished"),
    //   path: `${pathname}?status=finished`,
    // },
    // {
    //   label: t("status.cancelled"),
    //   path: `${pathname}?status=cancelled`,
    // },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const { created_date, document_date, source_warehouse } = filterModel;
      const formatFilterModel = {
        ...request.filterModel,
        created_date: created_date && filterDateWithUnix(created_date),
        document_date: document_date && filterDateWithUnix(document_date),
        type: {
          filterType: "text",
          operator: "OR",
          condition1: {
            filterType: "text",
            type: "equals",
            filter: "รับคืนจากลูกผลิต",
          },
          condition2: {
            filterType: "text",
            type: "equals",
            filter: "รับคืนจากลูกค้า",
          },
          ...request.filterModel.type,
        },
        source_warehouse: source_warehouse && {
          filterType: "object",
          filter: {
            thai_name: source_warehouse,
          },
        },
      };
      const sortbyId = [
        ...request.sortModel,
        { colId: "created_date", sort: "desc" },
      ];
      dispatch(
        getAllGoodsReceiveNoItem(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: sortbyId,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const updateTableFilter = useCallback((filter) => {
    const instance = gridRef.current.api.getFilterInstance("status");
    switch (filter) {
      case "draft":
        instance.setModel({ values: ["draft"] });
        break;
      case "finished":
        instance.setModel({ values: ["finished"] });
        break;
      case "cancelled":
        instance.setModel({ values: ["cancelled"] });
        break;
      default:
        instance.setModel({});
        break;
    }
    gridRef.current.api.onFilterChanged();
  }, []);

  const updateTableFilterRef = useCallback(
    (filter) => {
      try {
        const splitValue = filter.split("#")[2];
        if (
          splitValue &&
          allItems.filter((item) => item.document_id === splitValue).length > 0
        )
          return navigate(`${splitValue}`);
        else
          return enqueueSnackbar("QRCode นี้ไม่อยู่ในระบบ กรุณาสแกนใหม่", {
            variant: "error",
          });
      } catch (error) {
        enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
          variant: "error",
        });
      }
    },
    [allItems, enqueueSnackbar, navigate]
  );

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const onRowClicked = (params) => {
    return navigate(`${encodeURIComponent(params.data.document_id)}`);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      updateTableFilter(status);
    }
    return () => {
      dispatch(goodsReceiveActions.resetAllItems());
    };
  }, [gridRef?.current?.api, status, dispatch, updateTableFilter]);

  const currentTab = pathname + (status ? `?status=${status}` : "");

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  useEffect(() => {
    const autoCameraCloseHandler = () => {
      let scrolled = window.scrollY;

      if (scrolled >= 450) {
        setShowCamera(false);
      }
    };

    if (scrollRef && scrollRef.current) {
      window.addEventListener("scroll", autoCameraCloseHandler, false);
      return () => {
        window.removeEventListener("scroll", autoCameraCloseHandler, false);
      };
    }
  }, []);

  return (
    <Box ref={scrollRef}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TotalBox
            title="ทั้งหมด"
            total={count}
            date={formatDate(new Date())}
          />
        </Grid>
      </Grid>
      <Box
        sx={{ mt: 3, mb: 1, display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h5">{t("inventory.return.index")}</Typography>
        {createPermission && (
          <Box display={"flex"} gap={1}>
            <CustomizedButton
              size={isSmallScreen ? "large" : "small"}
              title={t("inventory.return.add")}
              variant="contained"
              onClick={() => navigate(`${pathname}/add`)}
            />
          </Box>
        )}
      </Box>
      {isSmallScreen ? (
        <Box display="flex" gap={1} my={1} alignItems="center">
          <CustomizedTextField
            label={t("inventory.scanBarcode")}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.shiftKey === false) {
                const data = e.target.value;
                updateTableFilterRef(data);
              }
            }}
            type="text"
          />
          <IconButton onClick={() => setShowCamera(!showCamera)}>
            <QrCodeScannerIcon />
          </IconButton>
        </Box>
      ) : null}
      {isSmallScreen ? (
        <Box display={showCamera ? "block" : "none"} marginY={2}>
          {showCamera ? (
            <QrReader
              constraints={{ facingMode: "environment" }}
              onResult={(result, error) => {
                if (!!result) {
                  updateTableFilterRef(result.text);
                }
                if (!!error) {
                  // console.log("error", error);
                  // console.info(error);
                }
              }}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : null}
        </Box>
      ) : null}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box width={isSmallScreen ? 170 : 360}>
          <CustomizedScrollbar>
            <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
          </CustomizedScrollbar>
        </Box>
        <CustomizedButton
          title={t("button.resetFilter")}
          variant="outlined"
          onClick={onFilterReset}
          sx={{ height: "33px" }}
        />
      </Box>
      {!isSmallScreen ? (
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={649}
          onGridReady={onGridReady}
          onRowDoubleClicked={onRowClicked}
          path={pathname}
        />
      ) : (
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={649}
          onGridReady={onGridReady}
          onRowClicked={onRowClicked}
          path={pathname}
        />
      )}
    </Box>
  );
};

export default Return;
