import { Box, DialogContentText } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";

const QtyConfirmation = ({
  openQtyConfirmation,
  qtyConfirmationAction,
  closeQtyConfirmationHandler,
  minimumStockQty,
}) => {
  return (
    <ModalUI
      title="จำนวนต่ำกว่าสั่งผลิตขั้นต่ำ ต้องการบันทึกสูตรการผลิตหรือไม่"
      open={openQtyConfirmation}
      handleClose={closeQtyConfirmationHandler}
      maxWidth="sm"
    >
      <DialogContentText>
        สั่งผลิตขั้นต่ำคือ {minimumStockQty}
      </DialogContentText>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeQtyConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={qtyConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default QtyConfirmation;
