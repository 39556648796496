import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import AgGrid from "../../../components/Table/AgGrid";
import { getAllItemsAggridReport } from "../../../features/Inventory/ItemMaster/itemMaster-actions";
import { exportAsExcel } from "../../../utils/exporter";
import InventoryService from "../../../services/Inventory";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { formatValueItems } from "./formatValue";
import GlobalService from "../../../services/Global";
import SettingService from "../../../services/Setting";
import CustomizedChips from "../../../components/Custom/CustomizedChips";
import { filterParamsOptions } from "../../../utils/filterparams";
import { formatNumber } from "../../../utils/dataTransformer";

const ItemMasterReport = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [filterModel, setFilterModel] = useState(null);

  const getAllTag = useCallback(async () => {
    const { results } = await GlobalService.getTagList({
      startRow: 0,
      endRow: 9999,
    });
    const mapTagValue = results.map((tag) => {
      return {
        ...tag,
        value: tag.name,
        label: tag.name,
      };
    });
    // setTagList(mapTagValue);
    return mapTagValue;
  }, []);

  const getCreables = useCallback(async () => {
    const data = await GlobalService.getCreatables({
      usage_field_type: "item",
      usage_field_name: "item_type",
    });
    return data;
  }, []);

  const getAllItemGroupLevel1 = useCallback(async () => {
    const data = await SettingService.getAllCategory();
    return data;
  }, []);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.items.index"),
    },
  ];

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("inventory.items.itemId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 200,
    },
    {
      field: "name",
      headerName: t("inventory.items.itemName"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "description",
      headerName: t("inventory.items.itemDescription"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "type",
      headerName: t("inventory.items.itemType"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          const defaultValues = ["สินค้า", "บริการ"];
          // fetch values from server
          const values = await getCreables();
          const formatValues = values.map((value) => value.name);
          const newData = [...defaultValues, ...formatValues];
          params.success(newData);
        },
      },
      hide: true,
    },
    {
      field: "purchase_standard_price",
      headerName: t("inventory.items.purchasePrice"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      hide: true,
    },
    {
      field: "current_ordered_purchase_qty",
      headerName: t("inventory.quantities.currentOrderedPurchaseQty"),
      filter: false,
      cellRenderer: (params) => {
        return (
          <NumericFormat
            value={params.value}
            thousandSeparator=","
            displayType="text"
          />
        );
      },
    },
    {
      field: "current_committed_sales_qty",
      headerName: t("inventory.quantities.currentCommittedSalesQty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "current_ordered_manufacture_qty",
      headerName: t("inventory.quantities.currentOrderedManufactureQty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "current_stock_qty",
      headerName: t("inventory.quantities.currentStockQty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "current_available_qty",
      headerName: t("inventory.quantities.currentAvailableQty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "sales_standard_price",
      headerName: t("inventory.items.pricePerUnit"),
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "item_group_sub_level_1",
      headerName: t("inventory.items.itemGroup") + " 1",
      filter: "agSetColumnFilter",
      sortable: false,
      filterParams: {
        values: async (params) => {
          // fetch values from server
          const values = await getAllItemGroupLevel1();
          const formatValues = values.map((group) => group.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params) => {
        if (params.value) return <div>{params.value.name}</div>;
      },
    },
    {
      field: "item_group_sub_level_2",
      headerName: t("inventory.items.itemGroup") + " 2",
      filter: "agSetColumnFilter",
      sortable: false,
      filterParams: {
        values: async (params) => {
          // fetch values from server
          const values = await getAllItemGroupLevel1();
          const newValue = [];
          values &&
            values.length > 0 &&
            values.forEach((group) =>
              group.sub_level_2_list.forEach((group2) => newValue.push(group2))
            );
          const formatValues = newValue.map((group) => group.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params) => {
        if (params.value) return <div>{params.value.name}</div>;
      },
    },
    {
      field: "item_group_sub_level_3",
      headerName: t("inventory.items.itemGroup") + " 3",
      filter: "agSetColumnFilter",
      sortable: false,
      filterParams: {
        values: async (params) => {
          // fetch values from server
          const values = await getAllItemGroupLevel1();
          const newValue = [];
          values &&
            values.length > 0 &&
            values.forEach((group) =>
              group.sub_level_2_list.forEach((group2) =>
                group2.sub_level_3_list.forEach((group3) =>
                  newValue.push(group3)
                )
              )
            );
          const formatValues = newValue.map((group) => group.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params) => {
        if (params.value) return <div>{params.value.name}</div>;
      },
    },
    {
      field: "tag_list",
      headerName: t("inventory.items.groupTagList"),
      filter: "agSetColumnFilter",
      sortable: false,
      filterParams: {
        values: async (params) => {
          // fetch values from server
          const values = await getAllTag();
          const formatValues = values
            .filter((tag) => tag.entity === "item")
            .map((tag) => tag.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: "inline-flex", mr: 1, gap: 1 }}>
            {params.value.map((tag) => (
              <CustomizedChips key={tag.name} value={`${tag.name}`} />
            ))}
          </Box>
        );
      },
    },
    {
      field: "is_active",
      headerName: t("inventory.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["true", "false"],
        valueFormatter: (params) => {
          if (params.value === "true") {
            return "ใช้งาน";
          }
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: (params) => (
        <CustomizedStatus status={params.value ? "active" : "inactive"} />
      ),
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];

  const exportHandler = async () => {
    setIsLoadingExport(true);
    try {
      const { results: itemsToExport } =
        await InventoryService.getAllItemsAggridReport({
          startRow: 0,
          endRow: 9999,
          filterModel: filterModel,
        });
      const formattedItems = itemsToExport.map((item) => {
        const formatValue = formatValueItems(item);
        return formatValue;
      });
      exportAsExcel(formattedItems, t("inventory.items.index"));
      enqueueSnackbar("นำออกสินค้าสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("นำออกสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    } finally {
      setIsLoadingExport(false);
    }
  };

  const datasource = {
    getRows(params) {
      const request = params.request;
      const {
        is_active,
        item_group_sub_level_1,
        item_group_sub_level_2,
        item_group_sub_level_3,
        tag_list,
      } = request.filterModel;
      const filterTagList = {
        ...request.filterModel,
        tag_list: tag_list
          ? {
              filterType: "objectArray",
              type: "some",
              filter: {
                name: {
                  filterType: "set",
                  values: tag_list.values,
                },
              },
            }
          : {},
        item_group_sub_level_1: item_group_sub_level_1 && {
          filterType: "object",
          filter: {
            name: {
              ...item_group_sub_level_1,
              mode: "insensitive",
            },
          },
        },
        item_group_sub_level_2: item_group_sub_level_2 && {
          filterType: "object",
          filter: {
            name: {
              ...item_group_sub_level_2,
              mode: "insensitive",
            },
          },
        },
        item_group_sub_level_3: item_group_sub_level_3 && {
          filterType: "object",
          filter: {
            name: {
              ...item_group_sub_level_3,
              mode: "insensitive",
            },
          },
        },
        is_active: is_active &&
          is_active.values.length > 0 && {
            filterType: "boolean",
            type: "equals",
            filter: is_active.values[0],
          },
      };
      setFilterModel(filterTagList);
      dispatch(
        getAllItemsAggridReport(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterTagList,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5"> {t("inventory.items.index")}</Typography>
        <CustomizedButton
          sx={{ mr: 2 }}
          title={t("inventory.exportReport")}
          variant="contained"
          onClick={exportHandler}
          disabled={isLoadingExport}
        />
      </Box>
      <Box mb={2} mr={2} alignSelf="flex-end">
        <CustomizedButton
          title={t("button.resetFilter")}
          variant="outlined"
          onClick={onFilterReset}
        />
      </Box>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        height={649}
        onGridReady={onGridReady}
      />
    </>
  );
};

export default ItemMasterReport;
