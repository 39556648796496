import { gql } from "graphql-request";

export const GET_ALL_ITEMS = gql`
  query Items($findManyInput: ItemFindManyInput) {
    items(findManyInput: $findManyInput) {
      id
      document_id
      name
      attachment_list {
        id
        url
        name
      }
      tag_list {
        id
        name
        entity
      }
      sku
      barcode
      type
      description
      is_purchasable
      is_salable
      is_stockable
      is_manufactuable
      is_active
      item_group_sub_level_1 {
        id
        name
        document_id
      }
      item_group_sub_level_2 {
        id
        document_id
        name
      }
      item_group_sub_level_3 {
        id
        name
        document_id
      }
      item_group_sub_level_1_document_id
      item_group_sub_level_2_document_id
      item_group_sub_level_3_document_id
      width
      length
      thickness
      height
      volume
      weight
      width_uom {
        id
        document_id
        name
      }
      length_uom {
        id
        document_id
        name
      }
      thickness_uom {
        id
        document_id
        name
      }
      height_uom {
        id
        document_id
        name
      }
      volume_uom {
        id
        document_id
        name
      }
      weight_uom {
        id
        document_id
        name
      }
      package_width_uom {
        id
        document_id
        name
      }
      package_length_uom {
        id
        document_id
        name
      }
      package_height_uom {
        id
        document_id
        name
      }
      package_weight_uom {
        id
        document_id
        name
      }
      package_width
      package_length
      package_height
      package_weight
      package_width_uom_document_id
      package_height_uom_document_id
      package_length_uom_document_id
      package_weight_uom_document_id
      purchase_standard_price
      purchase_minimum_qty
      purchase_vat_type
      sales_standard_price
      sales_maximum_discount
      sales_vat_type
      uom_group_document_id
      base_uom_document_id
      sales_uom_document_id
      purchase_uom_document_id
      deliver_uom_document_id
      base_uom {
        id
        document_id
        name
      }
      sales_uom {
        id
        document_id
        name
      }
      purchase_uom {
        id
        document_id
        name
      }
      deliver_uom {
        id
        document_id
        name
      }
      mrp_default_method
      manufacture_type
      manufacture_minimum_qty
      created_date
      created_by {
        first_name
        last_name
        document_id
        id
      }
      current_stock_qty
      current_committed_sales_qty
      current_committed_manufacture_qty
      current_ordered_purchase_qty
      current_ordered_manufacture_qty
      current_available_qty
      uom_group {
        name
        description
        document_id
        id
        base_uom {
          document_id
          name
          id
        }
        convertable_uom_list {
          document_id
          id
          name
        }
        uom_conversion_list {
          id
          base_uom_rate
          target_uom_document_id
          target_uom {
            document_id
            id
            name
          }
          target_uom_rate
        }
      }
      base_uom {
        id
        document_id
        name
      }
    }
  }
`;

export const GET_ALL_ITEM_IDS = gql`
  query Items {
    items {
      document_id
    }
  }
`;

export const GET_ALL_ITEMS_AGGRID = gql`
  query Results($aggridInput: AnyAggridInput) {
    itemsAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        name
        attachment_list {
          id
          url
          name
        }
        tag_list {
          id
          name
          entity
        }
        sku
        barcode
        type
        description
        is_purchasable
        is_salable
        is_stockable
        is_manufactuable
        is_active
        item_group_sub_level_1 {
          id
          name
          document_id
        }
        item_group_sub_level_2 {
          id
          document_id
          name
        }
        item_group_sub_level_3 {
          id
          name
          document_id
        }
        item_group_sub_level_1_document_id
        item_group_sub_level_2_document_id
        item_group_sub_level_3_document_id
        width
        length
        thickness
        height
        volume
        weight
        width_uom {
          id
          document_id
          name
        }
        length_uom {
          id
          document_id
          name
        }
        thickness_uom {
          id
          document_id
          name
        }
        height_uom {
          id
          document_id
          name
        }
        volume_uom {
          id
          document_id
          name
        }
        weight_uom {
          id
          document_id
          name
        }
        package_width_uom {
          id
          document_id
          name
        }
        package_length_uom {
          id
          document_id
          name
        }
        package_height_uom {
          id
          document_id
          name
        }
        package_weight_uom {
          id
          document_id
          name
        }
        package_width
        package_length
        package_height
        package_weight
        package_width_uom_document_id
        package_height_uom_document_id
        package_length_uom_document_id
        package_weight_uom_document_id
        purchase_standard_price
        purchase_minimum_qty
        purchase_vat_type
        sales_standard_price
        sales_maximum_discount
        sales_vat_type
        uom_group_document_id
        uom_group {
          name
          description
          base_uom {
            document_id
            name
            id
          }
          convertable_uom_list {
            document_id
            id
            name
          }
          uom_conversion_list {
            id
            base_uom_rate
            target_uom_document_id
            target_uom {
              document_id
              id
              name
            }
            target_uom_rate
          }
        }
        base_uom {
          id
          document_id
          name
        }
        base_uom_document_id
        sales_uom_document_id
        purchase_uom_document_id
        deliver_uom_document_id
        base_uom {
          id
          document_id
          name
        }
        sales_uom {
          id
          document_id
          name
        }
        purchase_uom {
          id
          document_id
          name
        }
        deliver_uom {
          id
          document_id
          name
        }
        mrp_default_method
        manufacture_type
        manufacture_minimum_qty
        main_bom {
          id
          name
          description
          created_date
          start_date
          end_date
          status
          internal_remark
          is_main_bom
          item_document_id
          item_name
          mfg_qty
          stock_uom_document_id
          stock_uom {
            document_id
            id
            name
          }
          type
          ingredient_list {
            uid
            item_document_id
            item_name
            qty
            uom
            item_description
          }
          creator_document_id
          created_by {
            document_id
            email
            first_name
            last_name
            img_url
          }
        }
        created_date
        created_by {
          first_name
          last_name
          document_id
          id
        }
        current_stock_qty
        current_committed_sales_qty
        current_committed_manufacture_qty
        current_ordered_purchase_qty
        current_ordered_manufacture_qty
        current_available_qty
      }
      count
    }
  }
`;

export const GET_ITEMS_BY_ENTRY_DATE_RANGE = gql`
  query ItemsByEntryDateRange($findEntryInput: ItemFindEntryInput) {
    itemsByEntryDateRange(findEntryInput: $findEntryInput) {
      id
      document_id
      name
      barcode
      type
      description
      sku
      item_group_sub_level_1_document_id
      item_group_sub_level_2_document_id
      item_group_sub_level_3_document_id
      item_group_sub_level_1 {
        id
        document_id
        name
      }
      item_group_sub_level_2 {
        id
        document_id
        name
      }
      item_group_sub_level_3 {
        id
        document_id
        name
      }
      is_active
      current_stock_qty
      current_committed_sales_qty
      current_committed_manufacture_qty
      current_ordered_purchase_qty
      current_ordered_manufacture_qty
      current_available_qty
      created_by {
        id
        document_id
        first_name
        last_name
        email
        img_url
      }
      tag_list {
        id
        name
      }
      created_date
      stock_entry_list {
        id
        document_id
        source_warehouse_document_id
        source_warehouse {
          id
          document_id
          type
          thai_name
          eng_name
        }
        destination_warehouse_document_id
        destination_warehouse {
          id
          document_id
          type
          thai_name
          eng_name
        }
        item {
          id
          document_id
          name
        }
        reference_document_type
        reference_document_id
        inventory_object_item {
          goods_receive {
            type
          }
          goods_issue {
            type
          }
        }
      }
      trace_entry_list {
        id
        document_id
        source_bin_location_document_id
        destination_bin_location_document_id
      }
    }
  }
`;

export const GET_ITEM = gql`
  query Item($uniqueInput: ItemWhereUniqueInput!) {
    item(uniqueInput: $uniqueInput) {
      id
      document_id
      name
      attachment_list {
        id
        url
        name
      }
      tag_list {
        id
        name
        entity
      }
      sku
      barcode
      type
      description
      is_purchasable
      is_salable
      is_stockable
      is_manufactuable
      is_active
      item_group_sub_level_1 {
        id
        name
        document_id
      }
      item_group_sub_level_2 {
        id
        document_id
        name
      }
      item_group_sub_level_3 {
        id
        name
        document_id
      }
      item_group_sub_level_1_document_id
      item_group_sub_level_2_document_id
      item_group_sub_level_3_document_id
      width_uom_document_id
      length_uom_document_id
      thickness_uom_document_id
      height_uom_document_id
      volume_uom_document_id
      weight_uom_document_id
      width_uom {
        id
        document_id
        name
      }
      length_uom {
        id
        document_id
        name
      }
      thickness_uom {
        id
        document_id
        name
      }
      height_uom {
        id
        document_id
        name
      }
      volume_uom {
        id
        document_id
        name
      }
      weight_uom {
        id
        document_id
        name
      }
      width
      length
      thickness
      height
      volume
      weight
      package_width_uom {
        id
        document_id
        name
      }
      package_length_uom {
        id
        document_id
        name
      }
      package_height_uom {
        id
        document_id
        name
      }
      package_weight_uom {
        id
        document_id
        name
      }
      package_width
      package_length
      package_height
      package_weight
      package_width_uom_document_id
      package_height_uom_document_id
      package_length_uom_document_id
      package_weight_uom_document_id
      purchase_standard_price
      purchase_minimum_qty
      purchase_vat_type
      sales_standard_price
      sales_maximum_discount
      sales_vat_type
      uom_group_document_id
      base_uom_document_id
      sales_uom_document_id
      purchase_uom_document_id
      deliver_uom_document_id
      uom_group {
        name
        description
        document_id
        id
        base_uom {
          document_id
          name
          id
        }
        convertable_uom_list {
          document_id
          id
          name
        }
        uom_conversion_list {
          id
          base_uom_rate
          target_uom_document_id
          target_uom {
            document_id
            id
            name
          }
          target_uom_rate
        }
      }
      base_uom {
        id
        document_id
        name
      }
      sales_uom {
        id
        document_id
        name
      }
      purchase_uom {
        id
        document_id
        name
      }
      deliver_uom {
        id
        document_id
        name
      }
      mrp_default_method
      manufacture_type
      manufacture_minimum_qty
      main_bom_name
      main_bom {
        id
        name
        description
        created_date
        start_date
        end_date
        status
        internal_remark
        is_main_bom
        item_document_id
        item_name
        mfg_qty
        stock_uom_document_id
        stock_uom {
          document_id
          id
          name
        }
        type
        ingredient_list {
          uid
          item_document_id
          item_name
          qty
          uom
          item_description
        }
        creator_document_id
        created_by {
          document_id
          email
          first_name
          last_name
          img_url
        }
      }
      created_date
      created_by {
        first_name
        last_name
        document_id
        id
      }
      current_stock_qty
      current_committed_sales_qty
      current_committed_manufacture_qty
      current_ordered_purchase_qty
      current_ordered_manufacture_qty
      current_available_qty
      remark_status
      remark_staff
      remark_datetime
      attribute_list {
        attribute_name
        attribute_value
        attribute {
          id
          name
          acronym
          data_type
        }
      }
    }
  }
`;

export const GET_ALL_GOODS_RECEIPT_AGGRID = gql`
  query GoodsReceives($aggridInput: AnyAggridInput!) {
    goodsReceivesAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        document_date
        type
        lot
        reference_document_id
        source_warehouse {
          id
          document_id
          thai_name
          eng_name
        }
        source_warehouse_document_id
        remark
        status
        created_by_document_id
        created_by {
          id
          document_id
          email
          first_name
          last_name
          img_url
        }
        created_date
        goods_receive_list {
          id
          initial_quantity
          posted_quantity
          posted_value
          stock_entry_list {
            id
            document_id
            source_warehouse_document_id
            destination_warehouse_document_id
            is_active
            posted_quantity
            posted_value
            uom_document_id
            posted_date
            created_date
            created_by {
              id
              document_id
              first_name
              last_name
            }
            uom {
              id
              document_id
              name
            }
            item {
              id
              document_id
              name
              description
              sku
              barcode
              type
            }
            item_document_id
            entry_type
            reference_document_type
            reference_document_id
            trace_entry_list {
              id
              document_id
              source_bin_location_document_id
              destination_bin_location_document_id
              is_active
              is_scanned
              posted_date
              created_date
              posted_quantity
              previous_quantity
              posted_value
              batch_number
              serial_number
              item_document_id
              item_uid
              uom_document_id
              creator_document_id
              stock_entry_document_id
              item {
                id
                document_id
                name
                sku
              }
              created_by {
                id
                document_id
                first_name
                last_name
              }
              scanned_date
              scanned_by {
                id
                document_id
                first_name
                last_name
              }
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_ALL_GOODS_RECEIPT_AGGRID_NO_ITEM = gql`
  query GoodsReceivesAggridNoItem($aggridInput: AnyAggridInput!) {
    goodsReceivesAggridNoItem(aggridInput: $aggridInput) {
      results {
        id
        document_id
        document_date
        type
        lot
        reference_document_id
        source_warehouse {
          id
          document_id
          thai_name
          eng_name
        }
        source_warehouse_document_id
        remark
        status
        created_by_document_id
        created_by {
          id
          document_id
          email
          first_name
          last_name
          img_url
        }
        created_date
      }
      count
    }
  }
`;

export const GET_GOODS_RECEIPT = gql`
  query GoodsReceive($uniqueInput: GoodsReceiveUniqueInput!) {
    goodsReceive(uniqueInput: $uniqueInput) {
      id
      document_id
      document_date
      type
      lot
      reference_document_id
      source_warehouse {
        id
        document_id
        thai_name
        eng_name
      }
      source_warehouse_document_id
      remark
      status
      created_by_document_id
      created_by {
        id
        document_id
        email
        first_name
        last_name
        img_url
      }
      created_date
      goods_receive_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          destination_warehouse_document_id
          is_active
          posted_quantity
          posted_value
          uom_document_id
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
          }
          uom {
            id
            document_id
            name
          }
          item {
            id
            document_id
            name
            sku
            barcode
            type
          }
          item_document_id
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            destination_bin_location_document_id
            is_active
            is_scanned
            posted_date
            created_date
            posted_quantity
            previous_quantity
            posted_value
            batch_number
            serial_number
            item_document_id
            item_uid
            uom_document_id
            creator_document_id
            stock_entry_document_id
            item {
              id
              document_id
              name
              sku
            }
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            scanned_date
            scanned_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_GOODS_ISSUE_AGGRID = gql`
  query GoodsIssues($aggridInput: AnyAggridInput!) {
    goodsIssuesAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        source_warehouse_document_id
        source_warehouse {
          id
          document_id
          type
          thai_name
        }
        source_warehouse_thai_name
        source_warehouse_eng_name
        document_date
        type
        reference_document_id
        created_date
        created_by_document_id
        created_by {
          id
          document_id
          first_name
          last_name
          img_url
        }
        remark
        status
        goods_issue_list {
          id
          initial_quantity
          posted_quantity
          posted_value
          stock_entry_list {
            id
            document_id
            source_warehouse_document_id
            source_warehouse {
              id
              document_id
              type
              thai_name
            }
            destination_warehouse_document_id
            destination_warehouse {
              id
              document_id
              type
              thai_name
            }
            is_active
            posted_quantity
            posted_value
            uom {
              id
              document_id
              name
            }
            posted_date
            created_date
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            item {
              id
              document_id
              description
              name
              sku
              barcode
              type
            }
            entry_type
            reference_document_type
            reference_document_id
            trace_entry_list {
              id
              document_id
              source_bin_location_document_id
              source_bin_location {
                id
                document_id
                name
                warehouse_document_id
              }
              destination_bin_location_document_id
              destination_bin_location {
                id
                document_id
                name
                warehouse_document_id
              }
              is_active
              is_scanned
              posted_date
              created_date
              posted_quantity
              posted_value
              tracability
              batch_number
              serial_number
              item_uid
              item {
                id
                document_id
                name
                sku
                barcode
                type
              }
              uom {
                id
                document_id
                name
              }
              creator_document_id
              created_by {
                id
                document_id
                first_name
                last_name
                img_url
              }
              stock_entry_document_id
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_ALL_GOODS_ISSUE_AGGRID_NO_ITEM = gql`
  query GoodsIssuesNoItem($aggridInput: AnyAggridInput!) {
    goodsIssuesAggridNoItem(aggridInput: $aggridInput) {
      results {
        id
        document_id
        source_warehouse_document_id
        source_warehouse {
          id
          document_id
          type
          thai_name
        }
        source_warehouse_thai_name
        source_warehouse_eng_name
        document_date
        type
        reference_document_id
        created_date
        created_by_document_id
        created_by {
          id
          document_id
          first_name
          last_name
          img_url
        }
        remark
        status
      }
      count
    }
  }
`;

export const GET_GOODS_ISSUE = gql`
  query GoodsIssue($uniqueInput: GoodsIssueUniqueInput!) {
    goodsIssue(uniqueInput: $uniqueInput) {
      id
      document_id
      source_warehouse_document_id
      source_warehouse {
        id
        document_id
        type
        thai_name
      }
      source_warehouse_thai_name
      source_warehouse_eng_name
      document_date
      type
      reference_document_id
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
        img_url
      }
      remark
      status
      goods_issue_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          source_warehouse {
            id
            document_id
            type
            thai_name
          }
          destination_warehouse_document_id
          destination_warehouse {
            id
            document_id
            type
            thai_name
          }
          is_active
          posted_quantity
          posted_value
          uom {
            id
            document_id
            name
          }
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
            img_url
          }
          item {
            id
            document_id
            name
            sku
            barcode
            type
          }
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            source_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            destination_bin_location_document_id
            destination_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            is_active
            is_scanned
            posted_date
            created_date
            scanned_date
            posted_quantity
            posted_value
            tracability
            batch_number
            serial_number
            item_uid
            item {
              id
              document_id
              name
              sku
              barcode
              type
            }
            uom {
              id
              document_id
              name
            }
            creator_document_id
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            stock_entry_document_id
          }
        }
      }
    }
  }
`;

export const GET_ALL_GOODS_TRANFERS_AGGRID = gql`
  query GoodsTransfers($aggridInput: AnyAggridInput!) {
    goodsTransfersAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        source_warehouse_document_id
        source_warehouse {
          id
          document_id
          type
          thai_name
        }
        source_warehouse_thai_name
        source_warehouse_eng_name
        destination_warehouse_document_id
        destination_warehouse {
          id
          document_id
          type
          thai_name
        }
        destination_warehouse_thai_name
        destination_warehouse_eng_name
        document_date
        created_date
        created_by_document_id
        created_by {
          id
          document_id
          first_name
          last_name
          img_url
        }
        remark
        status
        goods_transfer_list {
          id
          initial_quantity
          posted_quantity
          posted_value
          stock_entry_list {
            id
            document_id
            source_warehouse_document_id
            source_warehouse {
              id
              document_id
              type
              thai_name
            }
            destination_warehouse_document_id
            destination_warehouse {
              id
              document_id
              type
              thai_name
            }
            is_active
            posted_quantity
            posted_value
            uom {
              id
              document_id
              name
            }
            posted_date
            created_date
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            item {
              id
              document_id
              description
              name
              sku
              barcode
              type
            }
            entry_type
            reference_document_type
            reference_document_id
            trace_entry_list {
              id
              document_id
              source_bin_location_document_id
              source_bin_location {
                id
                document_id
                name
                warehouse_document_id
              }
              destination_bin_location_document_id
              destination_bin_location {
                id
                document_id
                name
                warehouse_document_id
              }
              is_active
              is_scanned
              posted_date
              created_date
              posted_quantity
              posted_value
              tracability
              batch_number
              serial_number
              item {
                id
                document_id
                name
                sku
                barcode
                type
              }
              uom {
                id
                document_id
                name
              }
              creator_document_id
              created_by {
                id
                document_id
                first_name
                last_name
                img_url
              }
              stock_entry_document_id
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_ALL_GOODS_TRANFERS_AGGRID_NO_ITEM = gql`
  query GoodsTransfersNoItem($aggridInput: AnyAggridInput!) {
    goodsTransfersAggridNoItem(aggridInput: $aggridInput) {
      results {
        id
        document_id
        source_warehouse_document_id
        source_warehouse {
          id
          document_id
          type
          thai_name
        }
        source_warehouse_thai_name
        source_warehouse_eng_name
        destination_warehouse_document_id
        destination_warehouse {
          id
          document_id
          type
          thai_name
        }
        destination_warehouse_thai_name
        destination_warehouse_eng_name
        document_date
        created_date
        created_by_document_id
        created_by {
          id
          document_id
          first_name
          last_name
          img_url
        }
        remark
        status
      }
      count
    }
  }
`;

export const GET_GOODS_TRANFERS = gql`
  query GoodsTransfer($uniqueInput: GoodsTransferUniqueInput!) {
    goodsTransfer(uniqueInput: $uniqueInput) {
      id
      document_id
      source_warehouse_document_id
      source_warehouse {
        id
        document_id
        type
        thai_name
      }
      source_warehouse_thai_name
      source_warehouse_eng_name
      destination_warehouse_document_id
      destination_warehouse {
        id
        document_id
        type
        thai_name
      }
      destination_warehouse_thai_name
      destination_warehouse_eng_name
      document_date
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
        img_url
      }
      remark
      status
      goods_transfer_list {
        id
        initial_quantity
        posted_quantity
        posted_value
        stock_entry_list {
          id
          document_id
          source_warehouse_document_id
          source_warehouse {
            id
            document_id
            type
            thai_name
          }
          destination_warehouse_document_id
          destination_warehouse {
            id
            document_id
            type
            thai_name
          }
          is_active
          posted_quantity
          posted_value
          uom {
            id
            document_id
            name
          }
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
            img_url
          }
          item {
            id
            document_id
            name
            sku
            barcode
            type
          }
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            source_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            destination_bin_location_document_id
            destination_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            is_active
            is_scanned
            posted_date
            created_date
            scanned_date
            posted_quantity
            posted_value
            tracability
            batch_number
            serial_number
            item {
              id
              document_id
              name
              sku
              barcode
              type
            }
            uom {
              id
              document_id
              name
            }
            creator_document_id
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            stock_entry_document_id
          }
        }
      }
    }
  }
`;

export const GET_ALL_GOODS_ADJUSTMENT_AGGRID = gql`
  query GoodsAdjustments($aggridInput: AnyAggridInput!) {
    goodsAdjustmentsAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_date
        document_id
        remark_staff
        created_date
        source_warehouse_document_id
        source_warehouse {
          id
          document_id
          thai_name
          eng_name
        }
        created_by {
          id
          document_id
          first_name
          last_name
          img_url
        }
        remark
        status
        goods_adjustment_list {
          id
          initial_quantity
          posted_quantity
          stock_entry_list {
            id
            source_warehouse_document_id
            destination_warehouse_document_id
            source_warehouse {
              id
              document_id
              thai_name
              eng_name
            }
            destination_warehouse {
              id
              document_id
              thai_name
              eng_name
            }
            posted_value
            posted_quantity
            uom {
              name
              id
            }
            posted_date
            created_date
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            item {
              id
              document_id
              description
              name
              sku
            }
            entry_type
            reference_document_type
            reference_document_id
            trace_entry_list {
              id
              document_id
              source_bin_location_document_id
              source_bin_location {
                id
                document_id
                name
                warehouse_document_id
              }
              destination_bin_location_document_id
              destination_bin_location {
                id
                document_id
                name
                warehouse_document_id
              }
              is_active
              is_scanned
              posted_date
              created_date
              previous_quantity
              posted_quantity
              posted_value
              tracability
              batch_number
              serial_number
              item {
                id
                document_id
                name
                sku
                barcode
                type
              }
              uom {
                id
                document_id
                name
              }
              creator_document_id
              created_by {
                id
                document_id
                first_name
                last_name
                img_url
              }
              stock_entry_document_id
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_ALL_GOODS_ADJUSTMENT_AGGRID_NO_ITEM = gql`
  query GoodsAdjustmentsNoItem($aggridInput: AnyAggridInput!) {
    goodsAdjustmentsAggridNoItem(aggridInput: $aggridInput) {
      results {
        id
        document_date
        document_id
        remark_staff
        created_date
        source_warehouse_document_id
        source_warehouse {
          id
          document_id
          thai_name
          eng_name
        }
        created_by {
          id
          document_id
          first_name
          last_name
          img_url
        }
        remark
        status
      }
      count
    }
  }
`;

export const GET_GOODS_ADJUSTMENT = gql`
  query GoodsAdjustment($uniqueInput: GoodsAdjustmentUniqueInput!) {
    goodsAdjustment(uniqueInput: $uniqueInput) {
      id
      document_date
      document_id
      remark_staff
      created_date
      source_warehouse_document_id
      source_warehouse {
        id
        document_id
        thai_name
        eng_name
      }
      created_by {
        id
        document_id
        first_name
        last_name
        img_url
      }
      remark
      status
      goods_adjustment_list {
        id
        initial_quantity
        posted_quantity
        stock_entry_list {
          id
          source_warehouse_document_id
          destination_warehouse_document_id
          source_warehouse {
            id
            document_id
            thai_name
            eng_name
          }
          destination_warehouse {
            id
            document_id
            thai_name
            eng_name
          }
          posted_value
          posted_quantity
          uom {
            name
            id
          }
          posted_date
          created_date
          created_by {
            id
            document_id
            first_name
            last_name
            img_url
          }
          item {
            id
            document_id
            name
            sku
          }
          entry_type
          reference_document_type
          reference_document_id
          trace_entry_list {
            id
            document_id
            source_bin_location_document_id
            source_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            destination_bin_location_document_id
            destination_bin_location {
              id
              document_id
              name
              warehouse_document_id
            }
            is_active
            is_scanned
            posted_date
            created_date
            scanned_date
            previous_quantity
            posted_quantity
            posted_value
            tracability
            batch_number
            serial_number
            item {
              id
              document_id
              name
              sku
              barcode
              type
            }
            uom {
              id
              document_id
              name
            }
            creator_document_id
            created_by {
              id
              document_id
              first_name
              last_name
              img_url
            }
            stock_entry_document_id
          }
        }
      }
    }
  }
`;

export const GET_ALL_UOM_GROUPS = gql`
  query Uoms($findManyInput: UomGroupFindManyInput) {
    uomGroups(findManyInput: $findManyInput) {
      id
      name
      description
      base_uom {
        name
      }
      convertable_uom_list {
        name
      }
      uom_conversion_list {
        base_uom_rate
        target_uom {
          name
        }
        target_uom_rate
        uom_group {
          name
        }
      }
    }
  }
`;

export const GET_UOM_GROUP = gql`
  query UomGroup($uniqueInput: UomGroupWhereUniqueInput!) {
    uomGroup(uniqueInput: $uniqueInput) {
      id
      name
      description
      base_uom {
        name
      }
      convertable_uom_list {
        name
      }
      uom_conversion_list {
        base_uom_rate
        target_uom {
          name
        }
        target_uom_rate
        uom_group {
          name
        }
      }
    }
  }
`;

export const GET_ALL_WAREHOUSES = gql`
  query Warehouses($findManyInput: WarehouseFindManyInput) {
    warehouses(findManyInput: $findManyInput) {
      id
      document_id
      type
      thai_name
      eng_name
      description
      created_date
      is_active
      sub_level_1_list {
        name
      }
      bin_location_list {
        name
      }
    }
  }
`;

export const GET_WAREHOUSE = gql`
  query Warehouse($uniqueInput: WarehouseWhereUniqueInput!) {
    warehouse(uniqueInput: $uniqueInput) {
      id
      document_id
      type
      thai_name
      eng_name
      description
      created_date
      is_active
      sub_level_1_list {
        name
      }
      bin_location_list {
        name
      }
    }
  }
`;

export const GET_STOCK_ENTRYS_AGGRID = gql`
  query StockEntriesAggrid($aggridInput: AnyAggridInput) {
    stockEntriesAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        is_active
        uom {
          id
          name
        }
        posted_quantity
        posted_value
        created_date
        posted_date
        created_by {
          id
          document_id
          last_name
          first_name
          img_url
        }
        entry_type
        reference_document_type
        reference_document_id
        item_document_id
        item {
          id
          document_id
          description
          name
          item_group_sub_level_1 {
            id
            name
            document_id
          }
        }
        destination_warehouse_document_id
        source_warehouse_document_id
        source_warehouse {
          id
          document_id
          thai_name
          eng_name
        }
        destination_warehouse {
          id
          document_id
          thai_name
          eng_name
        }
        trace_entry_list {
          item_uid
        }
        inventory_object_item {
          goods_receive {
            lot
            type
            remark
          }
          goods_issue {
            remark
            type
          }
          goods_adjustment {
            remark
          }
          goods_transfer {
            remark
          }
        }
      }
      count
    }
  }
`;

export const GET_TRACE_ENTRYS_AGGRID = gql`
  query TraceEntriesAggrid($aggridInput: AnyAggridInput) {
    traceEntriesAggrid(aggridInput: $aggridInput) {
      results {
        id
        document_id
        source_bin_location_document_id
        destination_bin_location_document_id
        source_bin_location {
          id
          document_id
          name
          warehouse {
            id
            document_id
            type
            thai_name
            eng_name
          }
        }
        destination_bin_location {
          id
          document_id
          name
        }
        stock_entry_document_id
        creator_document_id
        uom {
          id
          name
          document_id
        }
        item {
          id
          document_id
          description
          name
          sku
          current_stock_qty
          item_group_sub_level_1 {
            id
            name
            document_id
          }
        }
        item_document_id
        batch_number
        serial_number
        tracability
        created_by {
          id
          document_id
          last_name
          first_name
          img_url
        }
        posted_quantity
        posted_value
        created_date
        posted_date
        is_active
        is_scanned
        stock_entry {
          source_warehouse {
            document_id
            thai_name
          }
          destination_warehouse {
            thai_name
            document_id
          }
          reference_document_type
          reference_document_id
          inventory_object_item {
            goods_receive {
              lot
              remark
              type
              status
            }
            goods_issue {
              remark
              type
              status
            }
            goods_adjustment {
              remark
              status
            }
            goods_transfer {
              remark
              status
            }
          }
        }
        scanned_by {
          document_id
          first_name
          last_name
        }
        scanned_by_document_id
        scanned_date
      }
      count
    }
  }
`;

export const GET_ITEM_CURRENT_STOCK = gql`
  query ItemCurrentStockByStockEntryAggrid($aggridInput: AnyAggridInput) {
    itemCurrentStockByStockEntryAggrid(aggridInput: $aggridInput) {
      results {
        source_warehouse_document_id
        item_document_id
        current_quantity
        current_value
        item_name
        item_sku
        item_type
        item_description
        item_group_sub_level_1_document_id
        item_group_sub_level_1_name
        item_group_sub_level_2_document_id
        item_group_sub_level_2_name
        item_group_sub_level_3_document_id
        item_group_sub_level_3_name
        warehouse_document_id
        warehouse_type
        warehouse_thai_name
        warehouse_eng_name
        base_uom_document_id
        base_uom_name
      }
      count
    }
  }
`;

export const GET_ITEM_CURRENT_STOCK_LOCATION = gql`
  query ItemCurrentStockByTraceEntryAggrid($aggridInput: AnyAggridInput) {
    itemCurrentStockByTraceEntryAggrid(aggridInput: $aggridInput) {
      results {
        source_bin_location_document_id
        item_document_id
        current_quantity
        current_value
        item_name
        item_sku
        item_type
        item_description
        item_group_sub_level_1_document_id
        item_group_sub_level_1_name
        item_group_sub_level_2_document_id
        item_group_sub_level_2_name
        item_group_sub_level_3_document_id
        item_group_sub_level_3_name
        warehouse_document_id
        warehouse_type
        warehouse_thai_name
        warehouse_eng_name
        bin_location_document_id
        bin_location_name
        base_uom_document_id
        base_uom_name
      }
      count
    }
  }
`;

export const GET_BATCHES = gql`
  query Batches {
    batches {
      batch_number
      item_document_id
      expiration_date
      created_date
      created_by_document_id
    }
  }
`;

export const SCAN_BARCODE = gql`
  query Batch($uniqueInput: BatchUniqueInput!) {
    batch(uniqueInput: $uniqueInput) {
      batch_number
      expiration_date
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
      }
      item {
        id
        document_id
        name
        sku
        barcode
      }
    }
  }
`;

export const BIN_LOCATIONS = gql`
  query BinLocations($findManyInput: BinLocationFindManyInput) {
    binLocations(findManyInput: $findManyInput) {
      id
      document_id
      name
      warehouse_document_id
    }
  }
`;

export const GET_SERIAL_NUMBER = gql`
  query Serial($uniqueInput: SerialUniqueInput!) {
    serial(uniqueInput: $uniqueInput) {
      serial_number
      item_document_id
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
      }
      item {
        id
        document_id
        name
        sku
      }
    }
  }
`;

export const GET_ITEM_CURRENT_STOCK_BY_SN = gql`
  query ItemCurrentStockByTraceEntrySerialNumberAggrid(
    $aggridInput: AnyAggridInput
  ) {
    itemCurrentStockByTraceEntrySerialNumberAggrid(aggridInput: $aggridInput) {
      count
      results {
        base_uom_document_id
        base_uom_name
        bin_location_document_id
        bin_location_name
        current_quantity
        current_value
        item_document_id
        item_name
        item_sku
        item_type
        item_description
        item_is_active
        item_group_sub_level_1_name
        serial_number
        batch_number
        source_bin_location_document_id
        warehouse_document_id
        warehouse_eng_name
        warehouse_thai_name
        warehouse_type
      }
    }
  }
`;

export const GET_ITEM_CURRENT_STOCK_BY_SN_SCAN = gql`
  query ItemCurrentStockByTraceEntrySerialNumberScan(
    $aggridInput: AnyAggridInput
  ) {
    itemCurrentStockByTraceEntrySerialNumberScan(aggridInput: $aggridInput) {
      count
      results {
        base_uom_document_id
        base_uom_name
        bin_location_document_id
        bin_location_name
        current_quantity
        current_value
        item_document_id
        item_name
        item_sku
        item_type
        item_description
        item_is_active
        item_group_sub_level_1_name
        serial_number
        batch_number
        source_bin_location_document_id
        warehouse_document_id
        warehouse_eng_name
        warehouse_thai_name
        warehouse_type
      }
    }
  }
`;

export const GET_BATCH_NUMBER = gql`
  query Batch($uniqueInput: BatchUniqueInput!) {
    batch(uniqueInput: $uniqueInput) {
      batch_number
      item_document_id
      expiration_date
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
      }
      item {
        id
        document_id
        name
        sku
        barcode
      }
    }
  }
`;

export const GET_ALL_SERIAL_NUMBER = gql`
  query Serial {
    serials {
      serial_number
      item_document_id
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
      }
      item {
        id
        document_id
        name
        sku
      }
    }
  }
`;

export const GET_ALL_BATCH_NUMBER = gql`
  query Batch {
    batches {
      batch_number
      item_document_id
      expiration_date
      created_date
      created_by_document_id
      created_by {
        id
        document_id
        first_name
        last_name
      }
      item {
        id
        document_id
        name
        sku
        barcode
        type
      }
    }
  }
`;

export const GET_ALL_ITEMS_WEIGHT_VOLUME = gql`
  query Items {
    items {
      document_id
      weight
      volume
    }
  }
`;

export const CHECK_CAN_DELETE_ITEM = gql`
  query ItemDeleteCheck($documentId: String!) {
    itemDeleteCheck(documentId: $documentId) {
      quotation
      sales_order
      sales_return
      purchase_request
      purchase_order
      purchase_return
      delivery_order
      goods_receive
      goods_issue
      goods_transfer
      goods_adjustment
    }
  }
`;

export const GET_ALL_ITEMS_AGGRID_DOCUMENT_ID_NAME = gql`
  query Results($aggridInput: AnyAggridInput) {
    itemsAggrid(aggridInput: $aggridInput) {
      results {
        document_id
        name
      }
      count
    }
  }
`;

export const CHECK_BARCODE_IS_RECEIVE = gql`
  query StockEntryBarcodeCheck($barcode: [String!]!) {
    stockEntryBarcodeCheck(barcode: $barcode) {
      id
      document_id
      source_warehouse_document_id
      source_warehouse {
        id
        document_id
        thai_name
        eng_name
      }
      destination_warehouse_document_id
      destination_warehouse {
        id
        document_id
        thai_name
        eng_name
      }
      is_active
      posted_quantity
      posted_value
      uom {
        document_id
        name
      }
      uom_document_id
      uom_present {
        document_id
        name
      }
      posted_date
      created_date
      created_by {
        first_name
        last_name
        email
        document_id
      }
      item_document_id
      item {
        document_id
        name
        type
      }
      item_present {
        document_id
        name
        type
      }
      entry_type
      reference_document_type
      reference_document_id
      trace_entry_list {
        document_id
        source_bin_location_document_id
        source_bin_location {
          document_id
          name
        }
        destination_bin_location_document_id
        destination_bin_location {
          document_id
          name
        }
        is_active
        is_scanned
        posted_date
        created_date
        previous_quantity
        posted_quantity
        posted_value
        tracability
        batch_number
        serial_number
        scanned_date
        scanned_by_document_id
        scanned_by {
          document_id
          first_name
          last_name
        }
        item_uid
        item_document_id
        item {
          document_id
          name
          type
        }
        item_present {
          document_id
          name
          type
        }
        uom_document_id
        uom_present {
          document_id
          name
        }
        uom {
          document_id
          name
        }
        creator_document_id
        created_by {
          document_id
          first_name
          last_name
          email
          img_url
        }
        stock_entry_document_id
      }
      inventory_object_item {
        initial_quantity
        posted_quantity
        posted_value
        goods_receive {
          document_id
          lot
          type
          status
          reference_document_id
        }
        goods_issue {
          document_id
          type
          status
          reference_document_id
        }
        goods_adjustment {
          document_id
          remark
          status
        }
        goods_transfer {
          document_id
          remark
          status
        }
      }
    }
  }
`;

export const GET_NEW_ITEM_CURRENT_STOCK = gql`
  query ItemCurrentStockByWarehouseReport($aggridInput: AnyAggridInput!) {
    itemCurrentStockByWarehouseReport(aggridInput: $aggridInput) {
      results {
        id
        item_document_id
        item_name
        item_description
        item_group_sub_level_1_name
        warehouse_1_qty
        warehouse_2_qty
        sum_qty
        uom_name
      }
      count
    }
  }
`;

export const GET_NEW_ITEM_CURRENT_STOCK_BY_SN = gql`
  query ItemCurrentStockBySNReport($aggridInput: AnyAggridInput!) {
    itemCurrentStockBySNReport(aggridInput: $aggridInput) {
      count
      results {
        id
        item_group_sub_level_1_name
        item_document_id
        item_name
        item_description
        current_quantity
        serial_number
        barcode
        warehouse_document_id
        warehouse_thai_name
        bin_location_document_id
        bin_location_name
      }
    }
  }
`;

export const GET_NEW_ITEM_CURRENT_STOCK_BY_LOCATION = gql`
  query ItemCurrentStockByLocationReport($aggridInput: AnyAggridInput!) {
    itemCurrentStockByLocationReport(aggridInput: $aggridInput) {
      count
      results {
        id
        item_document_id
        item_name
        item_description
        item_group_sub_level_1_name
        current_quantity
        base_uom_name
        warehouse_document_id
        warehouse_thai_name
        bin_location_document_id
        bin_location_name
      }
    }
  }
`;

export const GET_NEW_GOODS_RECEIVE = gql`
  query GoodsReceiveReport($aggridInput: AnyAggridInput!) {
    goodsReceiveReport(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
        reference_document_id
        created_date
        document_date
        item_document_id
        name
        item_description
        posted_quantity
        uom_name
        type
        source_warehouse_thai_name
        lot
        remark
        created_by
        created_by_img_url
        status
      }
    }
  }
`;

export const GET_NEW_GOODS_ISSUE = gql`
  query GoodsIssueReport($aggridInput: AnyAggridInput!) {
    goodsIssueReport(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
        reference_document_id
        created_date
        document_date
        item_document_id
        item_name
        item_description
        posted_quantity
        uom_name
        type
        source_warehouse_thai_name
        remark
        created_by
        created_by_img_url
        status
      }
    }
  }
`;

export const GET_NEW_STOCK_ENTRY = gql`
  query StockEntryReport($aggridInput: AnyAggridInput!) {
    stockEntryReport(aggridInput: $aggridInput) {
      count
      results {
        id
        created_date
        reference_document_id
        item_item_group
        item_document_id
        item_name
        item_description
        posted_quantity
        uom_name
        reference_document_type
        document_type
        lot
        source_warehouse_name
        destination_warehouse_name
        remark
        created_by
      }
    }
  }
`;

export const GET_NEW_TRACE_ENTRY = gql`
  query TraceEntryReport($aggridInput: AnyAggridInput!) {
    traceEntryReport(aggridInput: $aggridInput) {
      count
      results {
        id
        scanned_date
        created_date
        reference_document_id
        item_item_group
        item_document_id
        item_name
        item_description
        posted_quantity
        uom_name
        reference_document_type
        document_type
        serial_number
        source_warehouse_name
        source_bin_location_document_id
        destination_warehouse_name
        destination_bin_location_document_id
        lot
        remark
        created_by
        scanned_by
        batch_number
      }
    }
  }
`;

export const GET_ALL_LEVEL_1 = gql`
  query ItemGroupSubLevel1s {
    itemGroupSubLevel1s {
      name
    }
  }
`;

export const GET_NEW_IN_ACTIVE_SERIAL_NUMBER_REPORT = gql`
  query InactiveSerialNumberReport($aggridInput: AnyAggridInput!) {
    inactiveSerialNumberReport(aggridInput: $aggridInput) {
      count
      results {
        id
        created_date
        serial_number
        item_group_sub_level_1_name
        item_document_id
        item_name
        item_description
        posted_quantity
        uom_name
        reference_document_type_thai_name
        source_warehouse_thai_name
        batch_number
        reference_document_id
        status
        created_by
      }
    }
  }
`;

export const GET_NEW_ITEM_NO_ENTRY_REPORT = gql`
  query ItemNoEntryReport(
    $aggridInput: AnyAggridInput!
    $findEntryInput: ItemNoEntryReportFindEntryInput!
  ) {
    itemNoEntryReport(
      aggridInput: $aggridInput
      findEntryInput: $findEntryInput
    ) {
      results {
        id
        created_date
        document_id
        name
        description
        current_ordered_purchase_qty
        current_committed_sales_qty
        current_ordered_manufacture_qty
        current_stock_qty
        current_available_qty
        item_group_sub_level_1_name
        item_group_sub_level_2_name
        item_group_sub_level_3_name
        tag_list
        is_active
        created_by
        stock_entry_posted_date_list
      }
      count
    }
  }
`;
