import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const Footer = ({ data }) => {
  const defaultSx = { fontSize: 12 };

  return (
    <>
      <Box sx={{ display: "flex", gap: 0.75, mt: 2 }}>
        <Typography sx={{ ...defaultSx, fontWeight: 600 }}>
          หมายเหตุภายใน/Internal Remark:
        </Typography>
        <Typography sx={defaultSx}>{data.remark}</Typography>
      </Box>
      <Divider sx={{ mt: 2 }} />
      <Grid container mt={2}>
        <Grid item xs={5.75}>
          <Typography
            sx={{
              ...defaultSx,
              lineHeight: "10px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
              flex: 1,
              mb: 2,
            }}
          >
            เบิกสินค้า/วัตถุดิบ
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexWrap: "nowrap",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 6 }}>
                ผู้เบิก
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 0.5 }}>
                _________________
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600 }}>
                _____/_____/_____
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 6 }}>
                ผู้ตรวจ/ฝ่ายบัญชี
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 0.5 }}>
                _________________
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600 }}>
                _____/_____/_____
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 6 }}>
                ผู้อนุมัติ
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 0.5 }}>
                _________________
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600 }}>
                _____/_____/_____
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={0.5} padding="0 15px 0 15px">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5.75}>
          <Typography
            sx={{
              ...defaultSx,
              lineHeight: "10px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
              flex: 1,
              mb: 2,
            }}
          >
            ผลิตสินค้า/วัตถุดิบ
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexWrap: "nowrap",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 6 }}>
                ผู้จ่ายงาน
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 0.5 }}>
                ____________
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600 }}>
                ___/___/___
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 6 }}>
                ผู้ผลิต
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 0.5 }}>
                _____________
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600 }}>
                ___/___/___
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 6 }}>
                ผู้ตรวจสอบ
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 0.5 }}>
                _____________
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600 }}>
                ___/___/___
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 6 }}>
                ผู้อนุมัติ
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600, mb: 0.5 }}>
                _____________
              </Typography>
              <Typography sx={{ ...defaultSx, fontWeight: 600 }}>
                ___/___/___
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
