import { categoryActions } from "./category-slice";
// import { showSnackbar } from "../../UI/ui-actions";
import SettingService from "../../../services/Setting/index";

export const getAllCategory = (input, params) => async (dispatch) => {
  dispatch(categoryActions.onLoading("allCategory"));
  try {
    const allCategory = await SettingService.getAllCategory(input);
    dispatch(categoryActions.loadedAllCategory(allCategory));
  } catch (err) {
    dispatch(categoryActions.rejectedActions({ ...err, name: "allCategory" }));
  }
};

export const getCategory = (input, params) => async (dispatch) => {
  dispatch(categoryActions.onLoading("allCategory"));
  try {
    const allCategory = await SettingService.getAllCategory(input);
    dispatch(categoryActions.loadedAllCategory(allCategory));
  } catch (err) {
    dispatch(categoryActions.rejectedActions({ ...err, name: "allCategory" }));
  }
};

export const createCategory = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(categoryActions.onLoading("allCategory"));
  try {
    await SettingService.postCreateItemGroupLv1(input);
    enqueueSnackbar("สร้างหมวดหมู่สำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    enqueueSnackbar("สร้างหมวดหมู่ไม่สำเร็จ", {
      variant: "error",
    });
    dispatch(categoryActions.rejectedActions({ ...err, name: "allCategory" }));
  }
};

export const updateCategory = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(categoryActions.onLoading("allCategory"));
  try {
    await SettingService.postUpdateItemGroupLv1(input);
    enqueueSnackbar("แก้ไขหมวดหมู่สำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    enqueueSnackbar("แก้ไขหมวดหมู่ไม่สำเร็จ", {
      variant: "success",
    });
    dispatch(categoryActions.rejectedActions({ ...err, name: "allCategory" }));
  }
};

export const deleteCategory = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(categoryActions.onLoading("allCategory"));
  try {
    await SettingService.postDeleteItemGroupLv1(input);
    enqueueSnackbar("ลบหมวดหมู่สำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    enqueueSnackbar("ลบหมวดหมู่ไม่สำเร็จ", {
      variant: "error",
    });
    dispatch(categoryActions.rejectedActions({ ...err, name: "allCategory" }));
  }
};

export const createCategoryLv1 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(categoryActions.onLoading("allCategory"));
    try {
      await SettingService.postCreateItemGroupLv2(input);
      enqueueSnackbar("สร้างหมวดหมู่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("สร้างหมวดหมู่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        categoryActions.rejectedActions({ ...err, name: "allCategory" })
      );
    }
  };

export const updateCategoryLv1 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(categoryActions.onLoading("allCategory"));
    try {
      await SettingService.postUpdateItemGroupLv2(input);
      enqueueSnackbar("แก้ไขหมวดหมู่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("แก้ไขหมวดหมู่ไม่สำเร็จ", {
        variant: "success",
      });
      dispatch(
        categoryActions.rejectedActions({ ...err, name: "allCategory" })
      );
    }
  };

export const deleteCategoryLv1 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(categoryActions.onLoading("allCategory"));
    try {
      await SettingService.postDeleteItemGroupLv2(input);
      enqueueSnackbar("ลบหมวดหมู่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ลบหมวดหมู่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        categoryActions.rejectedActions({ ...err, name: "allCategory" })
      );
    }
  };

export const createCategoryLv2 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(categoryActions.onLoading("allCategory"));
    try {
      await SettingService.postCreateItemGroupLv3(input);
      enqueueSnackbar("สร้างหมวดหมู่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("สร้างหมวดหมู่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        categoryActions.rejectedActions({ ...err, name: "allCategory" })
      );
    }
  };

export const updateCategoryLv2 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(categoryActions.onLoading("allCategory"));
    try {
      await SettingService.postUpdateItemGroupLv3(input);
      enqueueSnackbar("แก้ไขหมวดหมู่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("แก้ไขหมวดหมู่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        categoryActions.rejectedActions({ ...err, name: "allCategory" })
      );
    }
  };

export const deleteCategoryLv2 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(categoryActions.onLoading("allCategory"));
    try {
      await SettingService.postDeleteItemGroupLv3(input);
      enqueueSnackbar("ลบหมวดหมู่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ลบหมวดหมู่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        categoryActions.rejectedActions({ ...err, name: "allCategory" })
      );
    }
  };
