import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./manufacture-initials";

const manufactureSlice = createSlice({
  name: "manufacture",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    onLoaded(state) {
      state.isLoading.workCenter = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllWorkCenter(state, action) {
      state.allWorkCenter = action.payload;
      state.isLoading.allWorkCenter = false;
    },
    loadedWorkCenter(state, action) {
      state.workCenter = action.payload;
      state.isLoading.workCenter = false;
    },
    addWorkCenter(state, action) {
      state.allWorkCenter = [...state.allWorkCenter, action.payload];
      state.isLoading.allWorkCenter = false;
    },
    editWorkCenter(state, action) {
      const findIndex = state.allWorkCenter.findIndex(
        (item) => item.name === action.payload.name
      );
      state.allWorkCenter[findIndex] = action.payload;
      state.isLoading.allWorkCenter = false;
    },
    removeWorkCenter(state, action) {
      const filterName = state.allWorkCenter.filter(
        (item) => item.name !== action.payload.name
      );
      state.allWorkCenter = filterName;
      state.isLoading.allWorkCenter = false;
    },
    resetAllWorkCenter(state) {
      state.allWorkCenter = initialState.allWorkCenter;
      state.error = null;
    },
    resetWorkCenter(state) {
      state.workCenter = initialState.workCenter;
      state.error = null;
    },
  },
});

export const manufactureActions = manufactureSlice.actions;

export default manufactureSlice.reducer;
