import { useState, useRef } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Avatar, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ContactCard from "../Shared/ContactCard";
import { getAllUsers } from "../../../../features/User/Account/account-actions";
import CheckboxModalTable from "../../../Table/CheckboxModalTable";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import { departmentEngToThai } from "../../../../utils/dataTransformer";
import CustomizedStatus from "../../../Custom/CustomizedStatus";

const SalesListTab = ({ control, setValue, viewOnly }) => {
  const dispatch = useDispatch();
  const selectEmployeeGridRef = useRef();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const { fields, remove } = useFieldArray({
    control,
    name: "sales_list",
  });
  const selectedEmployeeIds = fields.map((field) => field.document_id);
  const [selectedIdsSnapshot, setSelectedIdsSnapshot] =
    useState(selectedEmployeeIds);
  const [selectedIds, setSelectedIds] = useState(selectedEmployeeIds);

  const datasource = {
    getRows(params) {
      const request = params.request;
      dispatch(
        getAllUsers(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: request.filterModel,
            sortModel: request.sortModel,
          },
          params
        )
      );
    },
  };

  const closeModalHandler = () => {
    setOpenModal(false);
    setSelectedIds(selectedIdsSnapshot);
  };

  const finishSelectEmployeesHandler = (data) => {
    // filter out employees not selected in current modal session
    let filteredEmployees = fields.filter((employee) =>
      selectedIds.includes(employee.document_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.document_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.document_id)) {
        filteredEmployees.push(employee);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        selectedIds.indexOf(a.document_id) - selectedIds.indexOf(b.document_id)
      );
    });
    setValue("sales_list", filteredEmployees);
    setSelectedIdsSnapshot(selectedIds);
    setOpenModal(false);
  };

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("user.account.employeeId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "img_url",
      headerName: t("user.account.picture"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data.img_url) {
          return <Avatar alt="img_url" src={params.data.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.data.first_name + " " + params.data.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "first_name",
      headerName: t("user.account.firstname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_name",
      headerName: t("user.account.lastname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "sales",
          "inventory",
          "account",
          "purchase",
          "admin",
          "manufacture",
          "logistic",
        ],
        valueFormatter: (params) => {
          return departmentEngToThai(params.value);
        },
      },
      valueFormatter: (params) => {
        return departmentEngToThai(params.value);
      },
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("user.account.status"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];
  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const removeEmployeeHandler = (index) => {
    remove(index);
    setSelectedIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setSelectedIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const renderEmployeesList = () => {
    return fields.map((item, index) => {
      return (
        <Grid key={item.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ContactCard
            title={
              index === 0
                ? t("contact.sale_list.responsible_person.main")
                : t("contact.sale_list.responsible_person.sub")
            }
            first_name={item.first_name || "-"}
            last_name={item.last_name || "-"}
            subcontent={item.position || "-"}
            img={item.img_url}
            imgAlt={`img-${index}`}
            index={index}
            onRemove={removeEmployeeHandler}
            isSalesList
            viewOnly={viewOnly}
          />
        </Grid>
      );
    });
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <CustomizedButton
            title={"เพิ่มพนักงานขาย"}
            variant="outlined"
            size="medium"
            onClick={openModalHandler}
            disabled={viewOnly}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {renderEmployeesList()}
      </Grid>
      <CheckboxModalTable
        modalTitle="เพิ่มพนักงาน"
        btnTitle="เลือกพนักงาน"
        gridRef={selectEmployeeGridRef}
        height={450}
        columnDefs={columnDefs}
        rowSelection="multiple"
        onFinishEditing={finishSelectEmployeesHandler}
        modalIsOpen={openModal}
        closeModal={closeModalHandler}
        onGridReady={onGridReady}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        idsSnapshot={selectedIdsSnapshot}
        setIdsSnapshot={setSelectedIdsSnapshot}
      />
    </>
  );
};

export default SalesListTab;
