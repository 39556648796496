import InventoryService from "../../../services/Inventory";
import { inventoryReportActions } from "./report-slice";
import { goodsReceiveActions } from "../GoodsReceive/goodsReceive-slice";
import { goodsIssueActions } from "../GoodsIssue/goodsIssue-slice";

export const getAllStockEntries =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("stockEntries"));
    try {
      const stockEntries = await InventoryService.getAllSEAggrid(input);
      dispatch(inventoryReportActions.loadedStockEntries(stockEntries.results));
      if (params)
        params.successCallback(stockEntries.results, stockEntries.count);
    } catch (err) {
      if (params) params.failCallback();
      dispatch(
        inventoryReportActions.rejectedActions({ ...err, name: "stockEntries" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllStockEntriesReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("stockEntries"));
    try {
      const stockEntries = await InventoryService.getAllSEAggridReport(input);
      dispatch(inventoryReportActions.loadedStockEntries(stockEntries.results));
      if (params)
        params.successCallback(stockEntries.results, stockEntries.count);
    } catch (err) {
      if (params) params.failCallback();
      dispatch(
        inventoryReportActions.rejectedActions({ ...err, name: "stockEntries" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllTraceEntry =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("traceEntry"));
    try {
      const traceEntry = await InventoryService.getAllTEAggrid(input);
      dispatch(inventoryReportActions.loadedTraceEntry(traceEntry.results));
      if (params) params.successCallback(traceEntry.results, traceEntry.count);
    } catch (err) {
      if (params) params.failCallback();
      dispatch(
        inventoryReportActions.rejectedActions({ ...err, name: "traceEntry" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllTraceEntryReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("traceEntry"));
    try {
      const traceEntry = await InventoryService.getAllTEAggridReport(input);
      dispatch(inventoryReportActions.loadedTraceEntry(traceEntry.results));
      if (params) params.successCallback(traceEntry.results, traceEntry.count);
    } catch (err) {
      if (params) params.failCallback();
      dispatch(
        inventoryReportActions.rejectedActions({ ...err, name: "traceEntry" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getItemsCurrentStock =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStock"));
    try {
      const itemCurrentStock = await InventoryService.getAllICS(input);
      params.successCallback(itemCurrentStock.results, itemCurrentStock.count);
      dispatch(
        inventoryReportActions.loadedItemCurrentStock(itemCurrentStock.results)
      );
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStock",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getItemsCurrentStockLocation =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStockLocation"));
    try {
      const itemCurrentStockLocation = await InventoryService.getAllICSL(input);
      params.successCallback(
        itemCurrentStockLocation.results,
        itemCurrentStockLocation.count
      );
      dispatch(
        inventoryReportActions.loadedItemCurrentStockLocation(
          itemCurrentStockLocation.results
        )
      );
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStockLocation",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getItemsCurrentStockSerialNumber =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStockSerialNumber"));
    try {
      const itemCurrentStockSerial =
        await InventoryService.getItemCurrentStockSerialNumber(input);
      // const newItem = itemCurrentStockSerial.results.filter(
      //   (result) => result.current_quantity !== 0
      // );
      // if (notReport) params.successCallback(newItem, newItem.length);
      // else
      params.successCallback(
        itemCurrentStockSerial.results,
        itemCurrentStockSerial.count
      );
      dispatch(
        inventoryReportActions.loadedItemCurrentStockSerialNumber(
          itemCurrentStockSerial.results
        )
      );
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStockSerialNumber",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getItemsCurrentStockSerialNumberReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStockSerialNumber"));
    try {
      const itemCurrentStockSerial =
        await InventoryService.getItemCurrentStockSerialNumberReport(input);
      // const newItem = itemCurrentStockSerial.results.filter(
      //   (result) => result.current_quantity !== 0
      // );
      // if (notReport) params.successCallback(newItem, newItem.length);
      // else
      params.successCallback(
        itemCurrentStockSerial.results,
        itemCurrentStockSerial.count
      );
      dispatch(inventoryReportActions.onLoaded("itemCurrentStockSerialNumber"));
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStockSerialNumber",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getItemNoEntryDateRange =
  (input, otherValue, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemNoEntry"));
    try {
      const result = await InventoryService.getAllItemsByEntryDateRange(input);
      const newResult = result.filter((old) => {
        if (
          otherValue.getRefTypeValue === "ทั้งหมด" &&
          otherValue.getTypeValue === "ทั้งหมด"
        ) {
          return old.stock_entry_list.length === 0;
        } else if (
          otherValue.getRefTypeValue !== "ทั้งหมด" &&
          otherValue.getTypeValue === "ทั้งหมด"
        ) {
          return (
            old.stock_entry_list.filter((list) => {
              return (
                list.reference_document_type === otherValue.getRefTypeValue
              );
            }).length <= 0
          );
        } else if (
          otherValue.getRefTypeValue === "ทั้งหมด" &&
          otherValue.getTypeValue !== "ทั้งหมด"
        ) {
          return (
            old.stock_entry_list.filter((list) => {
              if (!list.inventory_object_item) return false;
              if (list.inventory_object_item.goods_receive)
                return (
                  list.inventory_object_item.goods_receive.type ===
                  otherValue.getTypeValue
                );
              if (list.inventory_object_item.goods_issue)
                return (
                  list.inventory_object_item.goods_issue.type ===
                  otherValue.getTypeValue
                );
              else return list;
            }).length <= 0
          );
        } else if (
          otherValue.getRefTypeValue !== "ทั้งหมด" &&
          otherValue.getTypeValue !== "ทั้งหมด"
        ) {
          return (
            old.stock_entry_list
              .filter((list) => {
                return (
                  list.reference_document_type === otherValue.getRefTypeValue
                );
              })
              .filter((list) => {
                if (!list.inventory_object_item) return false;
                if (list.inventory_object_item.goods_receive)
                  return (
                    list.inventory_object_item.goods_receive.type ===
                    otherValue.getTypeValue
                  );
                if (list.inventory_object_item.goods_issue)
                  return (
                    list.inventory_object_itemgoods_issue.type ===
                    otherValue.getTypeValue
                  );
                else return list;
              }).length <= 0
          );
        } else return null;
      });
      dispatch(inventoryReportActions.loadedItemNoEntry(newResult));
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemNoEntry",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getItemNoEntryDateRangeReport =
  (input, otherValue, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemNoEntry"));
    try {
      const result = await InventoryService.getAllItemsByEntryDateRangeReport(
        input
      );
      const newResult = result.filter((old) => {
        if (
          otherValue.getRefTypeValue === "ทั้งหมด" &&
          otherValue.getTypeValue === "ทั้งหมด"
        ) {
          return old.stock_entry_list.length === 0;
        } else if (
          otherValue.getRefTypeValue !== "ทั้งหมด" &&
          otherValue.getTypeValue === "ทั้งหมด"
        ) {
          return (
            old.stock_entry_list.filter((list) => {
              return (
                list.reference_document_type === otherValue.getRefTypeValue
              );
            }).length <= 0
          );
        } else if (
          otherValue.getRefTypeValue === "ทั้งหมด" &&
          otherValue.getTypeValue !== "ทั้งหมด"
        ) {
          return (
            old.stock_entry_list.filter((list) => {
              if (!list.inventory_object_item) return false;
              if (list.inventory_object_item.goods_receive)
                return (
                  list.inventory_object_item.goods_receive.type ===
                  otherValue.getTypeValue
                );
              if (list.inventory_object_item.goods_issue)
                return (
                  list.inventory_object_item.goods_issue.type ===
                  otherValue.getTypeValue
                );
              else return list;
            }).length <= 0
          );
        } else if (
          otherValue.getRefTypeValue !== "ทั้งหมด" &&
          otherValue.getTypeValue !== "ทั้งหมด"
        ) {
          return (
            old.stock_entry_list
              .filter((list) => {
                return (
                  list.reference_document_type === otherValue.getRefTypeValue
                );
              })
              .filter((list) => {
                if (!list.inventory_object_item) return false;
                if (list.inventory_object_item.goods_receive)
                  return (
                    list.inventory_object_item.goods_receive.type ===
                    otherValue.getTypeValue
                  );
                if (list.inventory_object_item.goods_issue)
                  return (
                    list.inventory_object_item.goods_issue.type ===
                    otherValue.getTypeValue
                  );
                else return list;
              }).length <= 0
          );
        } else return null;
      });
      dispatch(inventoryReportActions.loadedItemNoEntry(newResult));
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemNoEntry",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getNewItemsCurrentStockReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStock"));
    try {
      const itemCurrentStock = await InventoryService.getAllNewICSReport(input);
      if (params)
        params.successCallback(
          itemCurrentStock.results,
          itemCurrentStock.count
        );
      dispatch(inventoryReportActions.onLoaded("itemCurrentStock"));
    } catch (err) {
      console.error(err);
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStock",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getNewItemsCurrentStockLocationReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStockLocation"));
    try {
      const itemCurrentStockLocation =
        await InventoryService.getAllNewReportICSL(input);
      if (params)
        params.successCallback(
          itemCurrentStockLocation.results,
          itemCurrentStockLocation.count
        );
      dispatch(inventoryReportActions.onLoaded("itemCurrentStockLocation"));
    } catch (err) {
      console.error(err);
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStockLocation",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getNewItemsCurrentStockSerialNumberReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemCurrentStockSerialNumber"));
    try {
      const itemCurrentStockSerial =
        await InventoryService.getAllNEWICSSNReport(input);
      console.log("itemCurrentStockSerial", itemCurrentStockSerial);
      params.successCallback(
        itemCurrentStockSerial.results,
        itemCurrentStockSerial.count
      );
      dispatch(inventoryReportActions.onLoaded("itemCurrentStockSerialNumber"));
    } catch (err) {
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemCurrentStockSerialNumber",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getNewGoodsReceiveReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsReceiveActions.onLoading("allItems"));
    try {
      const goodsReceives = await InventoryService.getAllNewGoodsReceiveReport(
        input
      );
      if (params)
        params.successCallback(goodsReceives.results, goodsReceives.count);
      dispatch(goodsReceiveActions.onLoaded("allItems"));
    } catch (err) {
      console.error(err);
      dispatch(
        goodsReceiveActions.rejectedActions({
          ...err,
          name: "allItems",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getNewGoodsIssueReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsIssueActions.onLoading("allItems"));
    try {
      const goodsIssue = await InventoryService.getAllNewGoodsIssueReport(
        input
      );
      if (params) params.successCallback(goodsIssue.results, goodsIssue.count);
      dispatch(goodsIssueActions.onLoaded("allItems"));
    } catch (err) {
      console.error(err);
      dispatch(
        goodsIssueActions.rejectedActions({
          ...err,
          name: "allItems",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getNewStockEntryReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("stockEntries"));
    try {
      const stockEntry = await InventoryService.getAllNewStockEntryReport(
        input
      );
      if (params) params.successCallback(stockEntry.results, stockEntry.count);
      dispatch(inventoryReportActions.onLoaded("stockEntries"));
    } catch (err) {
      console.error(err);
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "stockEntries",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getNewTraceEntryReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("traceEntry"));
    try {
      const traceENtry = await InventoryService.getAllNewTraceEntryReport(
        input
      );
      if (params) params.successCallback(traceENtry.results, traceENtry.count);
      dispatch(inventoryReportActions.onLoaded("traceEntry"));
    } catch (err) {
      console.error(err);
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "traceEntry",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getInActiveSerialReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("inActiveSerial"));
    try {
      const inActiveSerial =
        await InventoryService.getNewInactiveSerialNumberReport(input);
      if (params)
        params.successCallback(inActiveSerial.results, inActiveSerial.count);
      dispatch(inventoryReportActions.onLoaded("inActiveSerial"));
    } catch (err) {
      console.error(err);
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "inActiveSerial",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getItemNoEntryReport =
  (input, params, enqueueSnackbar, findEntryInput) => async (dispatch) => {
    dispatch(inventoryReportActions.onLoading("itemNoEntry"));
    try {
      const itemNoEntry = await InventoryService.getNewItemNoEntryReport(
        input,
        findEntryInput
      );
      if (params)
        params.successCallback(itemNoEntry.results, itemNoEntry.count);
      dispatch(inventoryReportActions.onLoaded("itemNoEntry"));
    } catch (err) {
      console.error(err);
      dispatch(
        inventoryReportActions.rejectedActions({
          ...err,
          name: "itemNoEntry",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
