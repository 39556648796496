import * as Yup from "yup";

export const detailsDefaultValues = {
  delivery_status: "",
  recipient_name: "",
  delivery_reference: "",
  delivery_detail: "",
  attachment_list: [],
};

export const detailsValidation = Yup.object().shape({
  delivery_status: Yup.string().required("กรุณาระบุสถานะจัดส่ง"),
});
