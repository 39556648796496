import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schema as contactSchema,
  validation as contactValidation,
} from "../../../components/Form/Contact/schema";
import {
  schema as customerDescSchema,
  validation as customerDescValidation,
} from "../../../components/Form/Sales/CustomerDescription/schema";
import SalesHeaderForm from "../../../components/Form/Sales/Header/index";
import {
  saleReturnSchema,
  saleReturnValidation,
} from "../../../components/Form/Sales/Header/schema";
import GoodReceiveTab from "./GoodReceiveTab";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { useApproval } from "../../../hooks/use-approval";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { salesReturnActions } from "../../../features/Sales/SalesReturn/sales-return-slice";
import {
  approveSalesReturn,
  createNewSalesReturn,
  declineSalesReturn,
  getSalesReturnById,
  updateSalesReturn,
} from "../../../features/Sales/SalesReturn/sales-return-actions";
import {
  salesPayload,
  setSalesValueFormatter,
} from "../../../utils/salesPayloadFormatter";
import moment from "moment";
import {
  salesPrimaryButtonHandler,
  salesSecondaryButtonHandler,
} from "../../../utils/salesButtonHandler";
import { validationReturnItemList } from "../../../components/Form/Sales/AddItemListForm/returnSchema";
import GlobalService from "../../../services/Global";

const defaultValues = {
  render_status: null,
  target_status: null,
  contact_document_id: "",
  creator_document_id: "",
  created_by: {},
  item_list: [],
  shipping_cost: 0,
  additional_discount: 0,
  additional_discount_type: "baht",
  summary: {},
  reason_to_return: "",
  is_effect_stock: true,
  template_remark_id: "",
  remark: "",
  price_vat_type: "",
};

const ReturnContainer = () => {
  const [viewOnly, setViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [primaryButtonTitle, setPrimaryButtonTitle] = useState("ส่ง");
  const [secondaryButtonTitle, setSecondaryButtonTitle] =
    useState("บันทึกร่าง");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { contactSnapshot } = useSelector((state) => state.contact);
  const { createPermission, editPermission } = usePermission();
  const {
    salesReturn,
    isHaveApproval,
    isHaveApprovalPermission,
    approvalList,
    isLoading,
  } = useSelector((state) => state.salesReturn);
  const { user } = useAuth();
  const { refreshApprovals } = useApproval();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const headerRef = useRef();
  const customerDescRef = useRef();
  const reasonRef = useRef();
  const itemListRef = useRef();
  const {
    control,
    setValue,
    getValues,
    trigger: validateItemList,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationReturnItemList),
  });
  const [currentTab, setCurrentTab] = useState(pathname);
  const [itemQtySnapshot, setItemQtySnapshot] = useState([]);

  const watchStatus = useWatch({ control, name: "render_status" });

  const customerDescUseForm = useForm({
    defaultValues: { ...customerDescSchema },
    resolver: yupResolver(customerDescValidation),
  });

  const {
    trigger: validateCustomerDescription,
    setValue: setCustomerDescValue,
    getValues: getCustomerDescValues,
    getFieldState: getCustomerDescFieldState,
  } = customerDescUseForm;

  const contactUseForm = useForm({
    defaultValues: { ...contactSchema },
    resolver: yupResolver(contactValidation),
  });

  const {
    control: headerControl,
    setValue: setHeaderValue,
    getValues: getHeaderValues,
    trigger: validateHeader,
    formState: { errors: headerErrors },
    getFieldState: getHeaderFieldState,
  } = useForm({
    defaultValues: { ...saleReturnSchema },
    resolver: yupResolver(saleReturnValidation),
  });

  useEffect(() => {
    switch (tab) {
      case "good-receive":
        setCurrentTab(pathname + "?tab=good-receive");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.return.index"),
      to: "/sales/return",
    },
    {
      name: id ?? `สร้าง${t("sales.return.index")}`,
    },
  ];

  const tabs = [
    {
      label: t("sales.documentInfo"),
      path: `${pathname}`,
    },
    {
      label: "ใบ" + t("inventory.receive.index"),
      path: `${pathname}?tab=good-receive`,
    },
  ];

  // const status =
  //   watchStatus === "expired"
  //     ? approvalList.some((approval) => approval.approval_status === "PENDING")
  //       ? "waitApprove"
  //       : targetStatus
  //     : watchStatus;

  useEffect(() => {
    switch (watchStatus) {
      case "PENDING":
      case "APPROVED":
      case "DECLINED":
      case "waitApprove":
      case "approved":
      case "finished":
      case "cancelled":
      case "expired":
        setViewOnly(true);
        break;
      default:
        setViewOnly(false);
    }
  }, [watchStatus]);

  useEffect(() => {
    if (state) {
      const formatItemList = state["item_list"].map((item) => {
        let formatQtyUom;
        const unit = item.uom_id;
        if (unit) {
          if (item.uom_group) {
            if (unit === item.uom_group?.base_uom?.id) {
              formatQtyUom = 1;
            } else {
              const conversionUom = item.uom_group.uom_conversion_list.find(
                (conversion) => conversion.target_uom.id === unit
              );
              const targetUomQty =
                conversionUom.base_uom_rate / conversionUom.target_uom_rate;
              formatQtyUom = 1 * targetUomQty;
            }
          }
        }
        return {
          ...item,
          qty_uom: formatQtyUom,
          qty_return: 1,
          pre_vat_amount: 1 * item.price_per_unit - item.discount_amount,
        };
      });
      const formatSalesReturn = {
        ...state,
        item_list: formatItemList,
        additional_discount: 0,
        shipping_cost: 0,
      };
      dispatch(salesReturnActions.loadedSalesReturn(formatSalesReturn));
      dispatch(salesReturnActions.loadedSalesOrderItem(state.item_list));
    } else if (id) {
      dispatch(
        getSalesReturnById(
          {
            document_id: id,
          },
          user,
          enqueueSnackbar
        )
      );
    }
    return () => {
      dispatch(salesReturnActions.resetSalesReturn());
    };
  }, [dispatch, enqueueSnackbar, id, state, user]);

  useEffect(() => {
    if (state) {
      const formatItemList = state["item_list"].map((item) => {
        let formatQtyUom;
        const unit = item.uom_id;
        if (unit) {
          if (item.uom_group) {
            if (unit === item.uom_group?.base_uom?.id) {
              formatQtyUom = 1;
            } else {
              const conversionUom = item.uom_group.uom_conversion_list.find(
                (conversion) => conversion.target_uom.id === unit
              );
              const targetUomQty =
                conversionUom.base_uom_rate / conversionUom.target_uom_rate;
              formatQtyUom = 1 * targetUomQty;
            }
          }
        }
        return {
          ...item,
          qty_uom: formatQtyUom,
          qty_return: 1,
          pre_vat_amount: 1 * item.price_per_unit - item.discount_amount,
        };
      });
      const formatSalesReturn = {
        ...state,
        item_list: formatItemList,
        additional_discount: 0,
        shipping_cost: 0,
      };
      setSalesValueFormatter(
        saleReturnSchema,
        customerDescSchema,
        defaultValues,
        null,
        setHeaderValue,
        setCustomerDescValue,
        setValue,
        null,
        formatSalesReturn,
        true,
        "sales_return"
      );
    } else if (id) {
      setSalesValueFormatter(
        saleReturnSchema,
        customerDescSchema,
        defaultValues,
        null,
        setHeaderValue,
        setCustomerDescValue,
        setValue,
        null,
        salesReturn,
        false,
        "sales_return"
      );
    }
  }, [salesReturn, setValue, setHeaderValue, id, state, setCustomerDescValue]);

  const submitContactForm = (data) => {
    setCustomerDescValue("contact", data);
  };

  const submitForm = async () => {
    const customerDescIsValid = await validateCustomerDescription();
    const headerIsValid = await validateHeader();
    const itemListIsValid = await validateItemList();

    const documentIdError = getHeaderFieldState("document_id").error;
    const issueDateError = getHeaderFieldState("issue_date").error;
    const customerError = getCustomerDescFieldState("customer").error;
    const reasonError = getFieldState("reason_to_return").error;
    const itemListError = getFieldState("item_list").error;

    const errorsCount =
      (documentIdError ? 1 : 0) +
      (issueDateError ? 1 : 0) +
      (customerError ? 1 : 0) +
      (itemListError ? 1 : 0);

    if (errorsCount > 1) {
      enqueueSnackbar("กรุณาตรวจสอบข้อมูลให้ครบถ้วน", {
        variant: "error",
      });
    }

    if (itemListError?.length > 0) {
      if (itemListError.some((item) => item.is_active)) {
        enqueueSnackbar("กรุณาระบุสินค้าใหม่ เนื่องจากมีสินค้าที่หยุดใช้งาน", {
          variant: "error",
        });
      }
      if (itemListError.some((item) => item.price_per_unit)) {
        enqueueSnackbar("กรุณากรอกจำนวนเงินที่มากกว่า 0", {
          variant: "error",
        });
      }
      if (itemListError.some((item) => item.qty_return)) {
        enqueueSnackbar("กรุณาระบุจำนวนคืนที่น้อยกว่าหรือเท่ากับจำนวนขาย", {
          variant: "error",
        });
      }
    } else if (itemListError?.message) {
      enqueueSnackbar(itemListError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(documentIdError)) {
      enqueueSnackbar(documentIdError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(issueDateError)) {
      enqueueSnackbar(issueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(customerError)) {
      enqueueSnackbar("กรุณาเลือกลูกค้า", {
        variant: "error",
      });
    }
    if (!headerIsValid) {
      headerRef.current.scrollIntoView();
      return;
    }
    if (!customerDescIsValid) {
      customerDescRef.current.scrollIntoView();
      return;
    }
    if (!itemListIsValid && reasonError) {
      reasonRef.current.scrollIntoView();
      return;
    }
    if (!itemListIsValid && itemListError) {
      itemListRef.current.scrollIntoView();
      return;
    }

    if (customerDescIsValid && headerIsValid && itemListIsValid) {
      const headerValues = getHeaderValues();
      const { reference_document_id } = headerValues;
      let isExceed = false;
      if (!watchStatus || watchStatus === "draft") {
        const salesOrderItemList = await GlobalService.getDocumentRelationQty({
          document_id: reference_document_id[0].reference_document_id,
          document_type: "sales_order",
          reference_document_type: "sales_return",
        });
        const itemList = getValues("item_list");
        salesOrderItemList.forEach((item) => {
          const findIndex = itemList.findIndex(
            (srItem) => srItem.uid === item.uid
          );
          if (findIndex !== -1) {
            if (isEdit) {
              const findSnapshotIndex = itemQtySnapshot.findIndex(
                (srItem) => srItem.uid === item.uid
              );
              if (findSnapshotIndex !== -1) {
                const currentMaximum =
                  item.qty -
                  (item.accumulate_return -
                    itemQtySnapshot[findSnapshotIndex].qty_return);
                if (itemList[findIndex].qty_return > currentMaximum) {
                  isExceed = true;
                }
              }
            } else {
              if (
                itemList[findIndex].qty_return >
                item.qty - item.accumulate_return
              ) {
                isExceed = true;
              }
            }
          }
        });
      }

      if (isExceed) {
        enqueueSnackbar("มีรายการสินค้าเกินจำนวน กรุณาระบุใหม่อีกครั้ง", {
          variant: "error",
        });
        return;
      } else {
        const { status, summary, ...mainValues } = getValues();
        const { customer, identity_no, ...customerDescValues } =
          getCustomerDescValues();
        const tempValues = {
          updated_date: moment().unix(),
        };
        const transformedEmployeeList = headerValues.employee_list.map(
          ({
            id,
            status,
            jwt_modifier,
            exp,
            iat,
            created_date,
            last_login_date,
            role_list,
            ...employee
          }) => employee
        );
        const payload = salesPayload(
          headerValues,
          tempValues,
          mainValues,
          transformedEmployeeList,
          user,
          contactSnapshot,
          summary,
          customerDescValues,
          id,
          "sr"
        );
        return payload;
      }
    }
  };

  const saveDraftHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      if (!id) {
        dispatch(
          createNewSalesReturn(
            payload,
            false,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        delete payload.reference_document_id;
        dispatch(
          updateSalesReturn(
            payload,
            watchStatus,
            false,
            salesReturn,
            user,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const sendApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      if (!id) {
        dispatch(
          createNewSalesReturn(
            payload,
            true,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        delete payload.reference_document_id;
        dispatch(
          updateSalesReturn(
            payload,
            watchStatus,
            true,
            salesReturn,
            user,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const notApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        declineSalesReturn(
          {
            document_id,
            document_type: "sales_return",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const approveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        approveSalesReturn(
          {
            document_id,
            document_type: "sales_return",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const editSubmitHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      delete payload.reference_document_id;
      dispatch(
        updateSalesReturn(
          payload,
          watchStatus,
          false,
          salesReturn,
          user,
          enqueueSnackbar
        )
      );
      setIsEdit(false);
      setViewOnly(true);
    }
  };

  const editCancelHandler = () => {
    navigate("/purchase/return");
  };

  const shouldRenderButtons = () => {
    if (isEdit) return true;
    if (isLoading.salesReturn) {
      return false;
    } else {
      if (pathname.includes("add")) {
        return true;
      }
      if (watchStatus) {
        switch (watchStatus) {
          case "draft":
            return true;
          case "waitApprove":
            if (isHaveApproval) {
              if (isHaveApprovalPermission) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          default:
            return false;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setPrimaryButtonTitle("บันทึก");
      setSecondaryButtonTitle("ยกเลิก");
    } else {
      switch (watchStatus) {
        case "PENDING":
        case "waitApprove":
          setPrimaryButtonTitle("อนุมัติ");
          setSecondaryButtonTitle("ไม่อนุมัติ");
          break;
        default:
      }
    }
  }, [isEdit, watchStatus]);

  const primarySubmitHandler = () => {
    salesPrimaryButtonHandler(
      watchStatus,
      isEdit,
      sendApproveHandler,
      approveHandler,
      editSubmitHandler
    );
  };

  const secondarySubmitHandler = async () => {
    salesSecondaryButtonHandler(
      watchStatus,
      isEdit,
      saveDraftHandler,
      notApproveHandler,
      editCancelHandler
    );
  };

  const editClickHandler = () => {
    setViewOnly(false);
    setIsEdit(true);
    setItemQtySnapshot(salesReturn.item_list);
  };

  const renderTab = (tab) => {
    switch (tab) {
      case "good-receive":
        return <GoodReceiveTab />;
      default:
        return (
          <>
            <SalesHeaderForm
              ref={headerRef}
              control={headerControl}
              errors={headerErrors}
              getValues={getHeaderValues}
              getMainValues={getValues}
              setValue={setHeaderValue}
              setMainValue={setValue}
              mainFormControl={control}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              documentType="sales_return"
              editClickHandler={editClickHandler}
              currentState={state?.id ?? salesReturn}
              isEdit={isEdit}
              approvalList={approvalList}
              setIsEdit={setIsEdit}
            />
            <DocumentInfoTab
              ref={customerDescRef}
              reasonRef={reasonRef}
              itemListRef={itemListRef}
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              submitCustomerDesc={submitContactForm}
              customerDescUseForm={customerDescUseForm}
              contactUseForm={contactUseForm}
              viewOnly={viewOnly || Boolean(!editPermission && id)}
              setHeaderValue={setHeaderValue}
            />
            {shouldRenderButtons() &&
              ((createPermission && !id) || (editPermission && id)) && (
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ mr: 1 }}>
                    <CustomizedButton
                      title={secondaryButtonTitle}
                      variant="outlined"
                      type="reset"
                      onClick={secondarySubmitHandler}
                    />
                  </Box>
                  <CustomizedButton
                    title={primaryButtonTitle}
                    variant="contained"
                    onClick={primarySubmitHandler}
                  />
                </Box>
              )}
          </>
        );
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </>
  );
};

export default ReturnContainer;
