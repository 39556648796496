import { createSlice } from "@reduxjs/toolkit";
import { roundDecimal } from "../../../utils/dataTransformer";

const salesReportSlice = createSlice({
  name: "salesReport",
  initialState: {
    isLoading: {
      salesReportByCustomer: false,
      salesReportByProduct: false,
    },
    salesReportByCustomer: [],
    salesReportByProduct: [],
  },
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    // loadedSalesReportByCustomer(state, action) {
    //   const formatCustomerReport = action.payload.map((customer) => ({
    //     contact_document_id: customer.contact_detail.document_id,
    //     contact_name: `${customer.contact_detail.contact_name_1} ${
    //       customer.contact_detail.contact_name_2 ?? ""
    //     }`,
    //     total_sales_order:
    //       customer.sales_report?.sales_order?._count.document_id || 0,
    //     sum_pre_vat_amount:
    //       customer.sales_report?.sales_order?._sum.pre_vat_amount || 0,
    //     sum_include_vat_amount:
    //       customer.sales_report?.sales_order?._sum.pre_vat_amount +
    //         customer?.sales_report?.sales_order?._sum.vat_amount || 0,
    //     sum_shipping_cost:
    //       customer.sales_report?.sales_order?._sum.shipping_cost || 0,
    //     sum_additional_discount:
    //       customer.sales_report?.sales_order?._sum.additional_discount || 0,
    //     sum_withholding_tax_amount:
    //       customer.sales_report?.sales_order?._sum.withholding_tax_amount || 0,
    //     sum_total_amount:
    //       customer.sales_report?.sales_order?._sum.total_amount || 0,
    //   }));
    //   state.salesReportByCustomer = formatCustomerReport;
    //   state.isLoading.salesReportByCustomer = false;
    // },
    loadedSalesReportByCustomer(state, action) {
      let result = [];
      action.payload.reduce(function (res, value) {
        if (!res[value.contact_document_id]) {
          res[value.contact_document_id] = {
            contact_document_id: value.contact_document_id,
            contact_name: value.contact_name,
            total_sales_order: 0,
            sum_pre_vat_amount_exclude_discount: 0,
            sum_item_discount: 0,
            sum_additional_discount: 0,
            sum_pre_vat_amount_include_discount: 0,
            sum_vat_amount: 0,
            sum_net_amount: 0,
            sum_withholding_tax: 0,
            sum_shipping_cost: 0,
            sum_total_amount: 0,
          };
          result.push(res[value.contact_document_id]);
        }

        const sum_pre_vat_amount_exclude_discount =
          res[value.contact_document_id].sum_pre_vat_amount_exclude_discount +
          value.item_list.reduce(
            (prev, curr) => roundDecimal(prev + curr.qty * curr.price_per_unit),
            0
          );

        const sum_item_discount =
          res[value.contact_document_id].sum_item_discount +
          value.item_list.reduce(
            (prev, curr) => prev + curr.discount_amount,
            0
          );

        const actualAdditionalDiscount =
          value.additional_discount_type === "percent"
            ? value.pre_vat_amount * (value.additional_discount / 100)
            : value.additional_discount;

        const sum_additional_discount =
          res[value.contact_document_id].sum_additional_discount +
          actualAdditionalDiscount;

        const sum_pre_vat_amount_include_discount =
          sum_pre_vat_amount_exclude_discount -
          (sum_item_discount + sum_additional_discount);

        const sum_vat_amount =
          res[value.contact_document_id].sum_vat_amount + value.vat_amount;

        const sum_net_amount = (res[value.contact_document_id].sum_net_amount =
          res[value.contact_document_id].sum_net_amount + value.net_amount);

        const sum_withholding_tax =
          res[value.contact_document_id].sum_withholding_tax +
          value.withholding_tax_amount;

        const sum_shipping_cost =
          res[value.contact_document_id].sum_shipping_cost +
          value.shipping_cost;

        const sum_total_amount =
          res[value.contact_document_id].sum_total_amount + value.total_amount;

        res[value.contact_document_id].total_sales_order =
          res[value.contact_document_id].total_sales_order + 1;
        res[value.contact_document_id].sum_pre_vat_amount_exclude_discount =
          sum_pre_vat_amount_exclude_discount;
        res[value.contact_document_id].sum_item_discount = sum_item_discount;
        res[value.contact_document_id].sum_additional_discount =
          sum_additional_discount;
        res[value.contact_document_id].sum_pre_vat_amount_include_discount =
          sum_pre_vat_amount_include_discount;
        res[value.contact_document_id].sum_vat_amount = sum_vat_amount;
        res[value.contact_document_id].sum_net_amount = sum_net_amount;
        res[value.contact_document_id].sum_withholding_tax =
          sum_withholding_tax;
        res[value.contact_document_id].sum_shipping_cost = sum_shipping_cost;
        res[value.contact_document_id].sum_total_amount = sum_total_amount;
        return res;
      }, {});

      state.salesReportByCustomer = result;
      state.isLoading.salesReportByCustomer = false;
    },
    // loadedSalesReportByProduct(state, action) {
    //   try {
    //     const allProducts = [];
    //     action.payload.forEach((report) => {
    //       report.sales_report.sales_order.forEach((product) => {
    //         allProducts.push(product);
    //       });
    //     });

    //     const groupedProduct = groupBy(
    //       allProducts,
    //       (product) => product.item_document_id
    //     );

    //     const formatProduct = [];
    //     for (const [, value] of Object.entries(groupedProduct)) {
    //       const formatList = [];
    //       value.forEach((item) => {
    //         item.item_sales_detail_list.forEach((detail) => {
    //           formatList.push(detail);
    //         });
    //       });
    //       let product = {
    //         item_document_id: value[0].item_document_id,
    //         item_name: value[0].item_name,
    //         item_sales_detail_list: formatList,
    //       };
    //       formatProduct.push(product);
    //     }

    //     const productReport = formatProduct.map((product) => {
    //       const allSalesOrderItems = product.item_sales_detail_list;
    //       const sum_pre_vat_amount = allSalesOrderItems.reduce(
    //         (prev, curr) =>
    //           prev + curr.price_per_unit * curr.qty - curr.discount_amount,
    //         0
    //       );

    //       const sum_include_vat_amount = allSalesOrderItems.reduce(
    //         (prev, curr) => {
    //           const pre_vat_amount =
    //             curr.price_per_unit * curr.qty - curr.discount_amount;
    //           if (curr.vat_type === "7") {
    //             const item_vat_7 = pre_vat_amount + pre_vat_amount * 0.07;
    //             return prev + item_vat_7;
    //           } else {
    //             return prev + pre_vat_amount;
    //           }
    //         },
    //         0
    //       );

    //       const sum_withholding_tax_amount = allSalesOrderItems.reduce(
    //         (prev, curr) => {
    //           const pre_vat_amount =
    //             curr.price_per_unit * curr.qty - curr.discount_amount;
    //           if (curr.withholding_tax !== -1) {
    //             return prev + pre_vat_amount * curr.withholding_tax;
    //           } else {
    //             return prev;
    //           }
    //         },
    //         0
    //       );
    //       const sum_discount_amount = allSalesOrderItems.reduce(
    //         (prev, curr) => prev + curr.discount_amount,
    //         0
    //       );
    //       return {
    //         item_document_id: product.item_document_id,
    //         item_name: product.item_name,
    //         sum_pre_vat_amount,
    //         sum_include_vat_amount,
    //         sum_withholding_tax_amount,
    //         sum_discount_amount,
    //       };
    //     });
    //     state.salesReportByProduct = productReport;
    //     state.isLoading.salesReportByProduct = false;
    //   } catch (err) {
    //     console.error(err);
    //   }
    // },
    loadedSalesReportByProduct(state, action) {
      let result = [];
      action.payload.reduce(function (res, value) {
        if (!res[value.item_document_id]) {
          res[value.item_document_id] = {
            item_document_id: value.item_document_id,
            item_name: value.item_name,
            sum_qty: 0,
            uom: value.uom,
            sum_pre_vat_amount_exclude_discount: 0,
            sum_item_discount: 0,
            sum_item_additional_discount: 0,
            sum_pre_vat_amount_include_discount: 0,
            sum_item_vat_amount: 0,
            sum_item_net_amount: 0,
            sum_item_withholding_tax: 0,
            sum_item_total_amount: 0,
          };
          result.push(res[value.item_document_id]);
        }

        const sum_qty = res[value.item_document_id].sum_qty + value.qty;

        const sum_pre_vat_amount_exclude_discount =
          res[value.item_document_id].sum_pre_vat_amount_exclude_discount +
          value.pre_vat_amount_exclude_discount;

        const sum_item_discount =
          res[value.item_document_id].sum_item_discount + value.item_discount;

        const sum_item_additional_discount =
          res[value.item_document_id].sum_item_additional_discount +
          value.item_additional_discount;

        const sum_pre_vat_amount_include_discount =
          res[value.item_document_id].sum_pre_vat_amount_include_discount +
          value.pre_vat_amount_include_discount;

        const sum_item_vat_amount =
          res[value.item_document_id].sum_item_vat_amount +
          value.item_vat_amount;

        const sum_item_net_amount =
          res[value.item_document_id].sum_item_net_amount +
          value.item_net_amount;

        const sum_item_withholding_tax =
          res[value.item_document_id].sum_item_withholding_tax +
          value.item_withholding_tax;

        const sum_item_total_amount =
          res[value.item_document_id].sum_item_total_amount +
          value.item_total_amount;

        res[value.item_document_id].sum_qty = sum_qty;
        res[value.item_document_id].sum_pre_vat_amount_exclude_discount =
          sum_pre_vat_amount_exclude_discount;
        res[value.item_document_id].sum_item_discount = sum_item_discount;
        res[value.item_document_id].sum_item_additional_discount =
          sum_item_additional_discount;
        res[value.item_document_id].sum_pre_vat_amount_include_discount =
          sum_pre_vat_amount_include_discount;
        res[value.item_document_id].sum_item_vat_amount = sum_item_vat_amount;
        res[value.item_document_id].sum_item_net_amount = sum_item_net_amount;
        res[value.item_document_id].sum_item_withholding_tax =
          sum_item_withholding_tax;
        res[value.item_document_id].sum_item_total_amount =
          sum_item_total_amount;
        return res;
      }, {});

      state.salesReportByProduct = result;
      state.isLoading.salesReportByProduct = false;
    },
    resetSalesByCustomer(state) {
      state.salesReportByCustomer = [];
    },
    resetSalesByProduct(state) {
      state.salesReportByProduct = [];
    },
  },
});

export const salesReportActions = salesReportSlice.actions;

export default salesReportSlice.reducer;
