import { Grid, Typography } from "@mui/material";
import React from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ControlledServerSideCreatable from "../../../../Controlled/ControlledSSCreatable";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import CustomizedNumberTextField from "../../../../Custom/CustomizedNumberTextField";
import TagList from "../../../../UI/TagList";
import BomDetail from "./Bom";
import CustomerDetail from "./Customer";
import ItemDetail from "./Item";

const ManufactureOrderDetailForm = ({
  control,
  errors,
  setValue,
  reset,
  ingredientReplace,
  roundedProductionQty,
  ingredientQtyCalculation,
  disabled,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const status = useWatch({
    control,
    name: "render_status",
  });
  return (
    <CustomizedBox>
      <CustomerDetail
        control={control}
        errors={errors}
        disabled={(id && status !== "draft") || disabled}
        setValue={setValue}
      />
      <ItemDetail
        control={control}
        errors={errors}
        disabled={(id && status !== "draft") || disabled}
        setValue={setValue}
        reset={reset}
        roundedProductionQty={roundedProductionQty}
      />
      <BomDetail
        control={control}
        errors={errors}
        disabled={(id && status !== "draft") || disabled}
        setValue={setValue}
        reset={reset}
        ingredientReplace={ingredientReplace}
        ingredientQtyCalculation={ingredientQtyCalculation}
      />
      <Typography ml={1} my={2}>
        {t("manufacture.order.width_tolerance_positive_negative")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <CustomizedNumberTextField
            name="width_tolerance_positive"
            label={t("manufacture.order.positive")}
            control={control}
            error={Boolean(errors?.width_tolerance_positive)}
            helperText={
              errors?.width_tolerance_positive &&
              errors?.width_tolerance_positive.message
            }
            disabled={(id && status !== "draft") || disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <CustomizedNumberTextField
            name="width_tolerance_negative"
            label={t("manufacture.order.negative")}
            control={control}
            error={Boolean(errors?.width_tolerance_negative)}
            helperText={
              errors?.width_tolerance_negative &&
              errors?.width_tolerance_negative.message
            }
            disabled={(id && status !== "draft") || disabled}
          />
        </Grid>
      </Grid>
      <Typography ml={1} my={2}>
        {t("manufacture.order.thickness_tolerance_positive_negative")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <CustomizedNumberTextField
            name="thickness_tolerance_positive"
            label={t("manufacture.order.positive")}
            control={control}
            error={Boolean(errors?.thickness_tolerance_positive)}
            helperText={
              errors?.thickness_tolerance_positive &&
              errors?.thickness_tolerance_positive.message
            }
            disabled={(id && status !== "draft") || disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <CustomizedNumberTextField
            name="thickness_tolerance_negative"
            label={t("manufacture.order.negative")}
            control={control}
            error={Boolean(errors?.thickness_tolerance_negative)}
            helperText={
              errors?.thickness_tolerance_negative &&
              errors?.thickness_tolerance_negative.message
            }
            disabled={(id && status !== "draft") || disabled}
          />
        </Grid>
      </Grid>
      <Typography ml={1} my={2} fontWeight="bold">
        {t("manufacture.order.group")}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            title={t("inventory.items.manufactureType")}
            control={control}
            name="type"
            dataName="manufacture_type"
            documentType="item"
            defaultOptions={[]}
            error={Boolean(errors.manufacture_type)}
            helperText={errors.manufacture_type?.message}
            setValue={setValue}
            viewOnly={status !== "finished" ? disabled : true}
          />
        </Grid>
        <TagList
          control={control}
          name="group"
          entity="manufacture_order"
          viewOnly={status !== "finished" ? disabled : true}
          // onClickTagListHandler={onClickTagListHandler}
        />
      </Grid>
    </CustomizedBox>
  );
};

export default ManufactureOrderDetailForm;
