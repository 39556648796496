import PurchaseService from "../../../services/Purchase";
import { purchaseExportFormatter } from "../../../utils/purchasePayloadFormatter";
import { purchaseReportActions } from "./purchase-report-slice";

export const getPurchaseReportByVendor =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReportActions.onLoading("purchaseReportByVendor"));
    try {
      const { results } = await PurchaseService.getAllPurchaseOrder(input);
      dispatch(purchaseReportActions.loadedPurchaseReportByVendor(results));
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseReportActions.rejectedActions({
          ...err,
          name: "purchaseReportByVendor",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getPurchaseReportByVendorReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReportActions.onLoading("purchaseReportByVendor"));
    try {
      const { results } = await PurchaseService.getAllPurchaseOrderReport(
        input
      );
      dispatch(purchaseReportActions.loadedPurchaseReportByVendor(results));
    } catch (err) {
      console.log(err);
      dispatch(
        purchaseReportActions.rejectedActions({
          ...err,
          name: "purchaseReportByVendor",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getPurchaseReportByProduct =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReportActions.onLoading("purchaseReportByProduct"));
    try {
      const { results } = await PurchaseService.getAllPurchaseOrder(input);
      const purchaseOrder = purchaseExportFormatter(results, "purchase_order");
      dispatch(
        purchaseReportActions.loadedPurchaseReportByProduct(purchaseOrder)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseReportActions.rejectedActions({
          ...err,
          name: "purchaseReportByProduct",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getPurchaseReportByProductReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseReportActions.onLoading("purchaseReportByProduct"));
    try {
      const { results } = await PurchaseService.getAllPurchaseOrderReport(
        input
      );
      const purchaseOrder = purchaseExportFormatter(results, "purchase_order");
      dispatch(
        purchaseReportActions.loadedPurchaseReportByProduct(purchaseOrder)
      );
    } catch (err) {
      console.log(err);
      dispatch(
        purchaseReportActions.rejectedActions({
          ...err,
          name: "purchaseReportByProduct",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
