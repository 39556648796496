import React from "react";
import { Box } from "@mui/system";
import { Divider, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Controller, useFieldArray } from "react-hook-form";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import ClearIcon from "@mui/icons-material/Clear";

const RemarkTemplateForm = ({
  control,
  errors,
  setValue,
  setDeleteData,
  createPermission,
  editPermission,
}) => {
  const { t } = useTranslation();

  const { fields, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "remarkTemplate", // unique name for your Field Array
    genId: "uuid",
  });

  const removeArray = (index, template) => {
    const deleteKey = {
      name: template.name,
      document_type: template.document_type,
    };
    if (template.document_type)
      setDeleteData((prevArray) => [...prevArray, deleteKey]);
    const cloneValue = fields;
    const filterValue = cloneValue.filter((_, ind) => index !== ind);
    setValue("remarkTemplate", filterValue);
  };

  return (
    <>
      {createPermission && (
        <CustomizedButton
          variant={"outlined"}
          color="secondary"
          title={t("setting.remark.add")}
          sx={{ marginBottom: "1rem" }}
          onClick={() =>
            append({
              name: "",
              remark_text: "",
            })
          }
        />
      )}
      {fields.map((template, index) => (
        <Box key={template.id}>
          <Box display={"flex"} alignItems={"flex-start"}>
            <Controller
              name={`remarkTemplate.${index}.name`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("setting.remark.name")}
                  sx={{ marginBottom: "1rem", width: 400 }}
                  // onChange={(e) =>
                  //   setValue(`remarkTemplate[${index}].name`, e.target.value)
                  // }
                  disabled={Boolean(
                    (!editPermission && template.refId) ||
                      (!createPermission && !template.refId)
                  )}
                  error={Boolean(errors.remarkTemplate?.[index]?.name)}
                  helperText={errors.remarkTemplate?.[index]?.name?.message}
                />
              )}
            />
            {((editPermission && template?.refId) ||
              (createPermission && !template?.refId)) && (
              <IconButton onClick={() => removeArray(index, template)}>
                <ClearIcon />
              </IconButton>
            )}
          </Box>
          <Controller
            name={`remarkTemplate.${index}.remark_text`}
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                multiline
                minRows={3}
                sx={{ marginBottom: "1rem" }}
                // onChange={(e) =>
                //   setValue(
                //     `remarkTemplate[${index}].remark_text`,
                //     e.target.value
                //   )
                // }
                disabled={Boolean(
                  (!editPermission && template.refId) ||
                    (!createPermission && !template.refId)
                )}
                error={Boolean(errors.remarkTemplate?.[index]?.remark_text)}
                helperText={
                  errors.remarkTemplate?.[index]?.remark_text?.message
                }
              />
            )}
          />
          <Divider sx={{ marginBottom: "1rem" }} />
        </Box>
      ))}
    </>
  );
};

export default RemarkTemplateForm;
