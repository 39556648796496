import { v4 as uuidV4 } from "uuid";
import moment from "moment";
import { localeStringToFloat } from "./dataTransformer";
import {
  unixToDate,
  unixToDateWithFormat,
  unixToDateTimeWithFormat,
} from "./date-converter";
import InventoryService from "../services/Inventory";
import GlobalService from "../services/Global";
import { filterStatusValueFormatter } from "./filterparams";

export const salesPayload = (
  headerValues,
  tempValues,
  mainValues,
  transformedEmployeeList,
  user,
  contactSnapshot,
  summary,
  customerDescValues,
  id,
  documentType,
  acceptedProof
) => {
  const payload = {
    ...headerValues,
    ...mainValues,
    ...tempValues,
    ...acceptedProof,
    template_remark_id: mainValues.template_remark_id
      ? mainValues.template_remark_id.toString()
      : null,
    credit_day: headerValues.credit_day
      ? parseInt(headerValues.credit_day)
      : null,
    employee_list: transformedEmployeeList,
    creator_document_id: user.document_id,
    contact_document_id: contactSnapshot.document_id,
    created_date: moment(headerValues.created_date).unix(),
    issue_date: moment(headerValues.issue_date).startOf("day").unix(),
    due_date: moment(headerValues.due_date).endOf("day").unix(),
    accepted_date: acceptedProof?.accepted_date
      ? moment(acceptedProof.accepted_date).unix()
      : null,
    shipping_cost: parseFloat(mainValues.shipping_cost),
    additional_discount: parseFloat(mainValues.additional_discount),
    pre_vat_amount: localeStringToFloat(summary.pre_vat_amount),
    vat_exempted_amount: localeStringToFloat(summary.vat_exempted_amount),
    vat_0_amount: localeStringToFloat(summary.vat_0_amount),
    vat_7_amount: localeStringToFloat(summary.vat_7_amount),
    vat_amount: localeStringToFloat(summary.vat_amount),
    net_amount: localeStringToFloat(summary.net_amount),
    withholding_tax_amount: localeStringToFloat(summary.withholding_tax_amount),
    total_amount: localeStringToFloat(summary.total_amount),
    customer: { ...customerDescValues },
    item_list: mainValues.item_list.map((item) => ({
      uid: item.uid,
      item_document_id: item.item_document_id,
      item_name: item.item_name,
      item_description: item.item_description,
      qty: item.qty,
      uom_id: item.uom_id.toString(),
      uom: item.uom,
      bom_description: item.bom_description,
      qty_uom: item.qty_uom,
      width_length_tolerance_positive: parseFloat(
        item.width_length_tolerance_positive
      ),
      width_length_tolerance_negative: parseFloat(
        item.width_length_tolerance_negative
      ),
      thickness_tolerance_positive: parseFloat(
        item.thickness_tolerance_positive
      ),
      thickness_tolerance_negative: parseFloat(
        item.thickness_tolerance_negative
      ),
      item_remark: item.item_remark,
      price_per_unit: parseFloat(item.price_per_unit),
      purchase_standard_price: parseFloat(item.purchase_standard_price),
      discount_amount:
        item.discount_amount && item.discount_amount > 0
          ? parseFloat(item.discount_amount)
          : 0,
      vat_type: item.vat_type,
      withholding_tax:
        item.withholding_tax.type === "ยังไม่ระบุ"
          ? -1
          : item.withholding_tax.type === "ไม่มี"
          ? 0
          : parseFloat(item.withholding_tax.type) / 100,
    })),
  };
  if (!id) {
    delete payload.id;
  } else {
    delete payload.creator_document_id;
    delete payload.created_date;
  }

  if (documentType !== "qa") {
    payload.reference_document_id =
      payload.reference_document_id && payload.reference_document_id.length > 0
        ? payload.reference_document_id[0].reference_document_id
        : null;
    payload.item_list.forEach((item) => {
      delete item.purchase_standard_price;
    });
    delete payload.attachment_list;
    delete payload.accepted_date;
    delete payload.accepted_remark;
  }
  if (documentType === "so") {
    payload.item_list.forEach((item) => {
      delete item.purchase_standard_price;
    });
    payload.delivery_date = moment(headerValues.delivery_date)
      .endOf("day")
      .unix();
    payload.attachment_list = [];
    payload.accepted_date = null;
    payload.accepted_remark = "";
  } else if (documentType === "si") {
    payload.item_list.forEach((item) => {
      delete item.purchase_standard_price;
    });
    payload.attachment_list = [];
    payload.accepted_date = null;
    payload.accepted_remark = "";
  } else if (documentType === "sr") {
    delete payload.attachment_list;
    delete payload.accepted_date;
    delete payload.accepted_remark;
    payload.is_effect_stock =
      mainValues.is_effect_stock === undefined
        ? true
        : Boolean(mainValues.is_effect_stock);
    payload.item_list = mainValues.item_list.map((item) => ({
      uid: item.uid,
      item_document_id: item.item_document_id,
      item_name: item.item_name,
      item_description: item.item_description,
      qty: item.qty,
      uom_id: item.uom_id.toString(),
      uom: item.uom,
      qty_uom: item.qty_uom,
      qty_return: item.qty_return,
      price_per_unit: parseFloat(item.price_per_unit),
      discount_amount:
        item.discount_amount && item.discount_amount > 0
          ? parseFloat(item.discount_amount)
          : 0,
      vat_type: item.vat_type,
      withholding_tax:
        item.withholding_tax.type === "ยังไม่ระบุ"
          ? -1
          : item.withholding_tax.type === "ไม่มี"
          ? 0
          : parseFloat(item.withholding_tax.type) / 100,
    }));
  }
  delete payload.target_status;
  delete payload.render_status;
  delete payload.is_late;
  delete payload.created_by;
  return payload;
};

export const salesQueryFormatter = async (sales, documentType) => {
  const formatSales = await {
    ...sales,
    // employee_list: sales.employee_list.reverse(),
    credit_day: sales.credit_day === null ? "" : sales.credit_day,
    created_date: unixToDate(sales.created_date),
    issue_date: unixToDate(sales.issue_date),
    due_date: unixToDate(sales.due_date),
    accepted_date: sales.accepted_date ? unixToDate(sales.accepted_date) : null,
  };
  if (sales.render_status === "PENDING") {
    formatSales.render_status = "waitApprove";
  } else if (sales.render_status === "DECLINED") {
    formatSales.render_status = "notApproved";
  } else if (sales.render_status === "APPROVED") {
    if (documentType === "quotation") {
      formatSales.render_status = "waitAccept";
    } else if (documentType === "sales_invoice") {
      formatSales.render_status = "waitPayment";
    } else {
      formatSales.render_status = "approved";
    }
  }
  if (documentType === "sales_order") {
    formatSales.delivery_date = unixToDate(sales.delivery_date);
  }

  const allItemsDocumentId = formatSales.item_list.map(
    (item) => item.item_document_id
  );

  const items = await InventoryService.getAllItems({
    findManyInput: {
      where: {
        document_id: {
          in: allItemsDocumentId,
        },
      },
    },
  });

  const formatItemList = [];
  formatSales.item_list.forEach((item) => {
    const foundItemIndex = items.findIndex(
      (realItem) => realItem.document_id === item.item_document_id
    );

    let pre_vat_amount = 0;

    if (documentType === "sales_return") {
      pre_vat_amount =
        item.qty_return * item.price_per_unit - item.discount_amount;
    } else {
      pre_vat_amount = item.qty * item.price_per_unit - item.discount_amount;
    }
    formatItemList.push({
      ...item,
      pre_vat_amount,
      uom_id: parseInt(item.uom_id),
      withholding_tax: {
        type:
          item.withholding_tax === -1
            ? "ยังไม่ระบุ"
            : item.withholding_tax === 0
            ? "ไม่มี"
            : (item.withholding_tax * 100).toString(),
        amount:
          item.withholding_tax === -1
            ? 0
            : pre_vat_amount * item.withholding_tax,
      },
      uom_group: items[foundItemIndex]?.uom_group,
      current_ordered_purchase_qty:
        items[foundItemIndex]?.current_ordered_purchase_qty,
      current_ordered_manufacture_qty:
        items[foundItemIndex]?.current_ordered_manufacture_qty,
      current_committed_sales_qty:
        items[foundItemIndex]?.current_committed_sales_qty,
      current_committed_manufacture_qty:
        items[foundItemIndex]?.current_committed_manufacture_qty,
      current_stock_qty: items[foundItemIndex]?.current_stock_qty,
      current_available_qty: items[foundItemIndex]?.current_available_qty,
      purchase_standard_price: items[foundItemIndex]?.purchase_standard_price,
      is_active: items[foundItemIndex]?.is_active,
    });
  });

  formatSales.item_list = formatItemList;

  const documentRelations = await GlobalService.getDocumentRelations(
    sales.document_id
  );
  formatSales.reference_document_id = documentRelations.map((document) => ({
    reference_document_type: document.reference_document_type,
    reference_document_id: document.reference_document_id,
  }));

  return formatSales;
};

export const depositInvoicePayload = (
  headerValues,
  tempValues,
  mainValues,
  transformedEmployeeList,
  user,
  contactSnapshot,
  summary,
  customerDescValues,
  id
) => {
  const payload = {
    ...headerValues,
    ...mainValues,
    ...tempValues,
    template_remark_id: mainValues.template_remark_id.toString(),
    credit_day: headerValues.credit_day
      ? parseInt(headerValues.credit_day)
      : null,
    employee_list: transformedEmployeeList,
    creator_document_id: user.document_id,
    contact_document_id: contactSnapshot.document_id,
    created_date: moment(headerValues.created_date).unix(),
    issue_date: moment(headerValues.issue_date).unix(),
    pre_vat_amount: localeStringToFloat(summary.pre_vat_amount),
    vat_exempted_amount: localeStringToFloat(summary.vat_exempted_amount),
    vat_0_amount: localeStringToFloat(summary.vat_0_amount),
    vat_7_amount: localeStringToFloat(summary.vat_7_amount),
    vat_amount: localeStringToFloat(summary.vat_amount),
    total_amount: localeStringToFloat(summary.total_amount),
    customer: { ...customerDescValues },
    attachment_list: [],
    item_list: mainValues.item_list.map((item) => ({
      description: item.description,
      vat_type: item.vat_type,
      pre_vat_amount: parseFloat(item.pre_vat_amount),
    })),
  };
  if (!id) {
    delete payload.id;
  }
  delete payload.render_status;
  delete payload.created_by;
  return payload;
};

export const depositInvoiceQueryFormatter = async (depositInvoice) => {
  const formatSales = await {
    ...depositInvoice,
    item_list: depositInvoice.item_list.map((item) => ({
      item_document_id: uuidV4(),
      ...item,
    })),

    credit_day:
      depositInvoice.credit_day === null ? "" : depositInvoice.credit_day,
    created_date: unixToDate(depositInvoice.created_date),
    issue_date: unixToDate(depositInvoice.issue_date),
  };
  if (depositInvoice.render_status === "PENDING") {
    formatSales.render_status = "waitApprove";
  } else if (depositInvoice.render_status === "DECLINED") {
    formatSales.render_status = "notApproved";
  } else if (depositInvoice.render_status === "APPROVED") {
    formatSales.render_status = "waitPayment";
  }

  return formatSales;
};

export const setSalesValueFormatter = (
  headerSchema,
  customerSchema,
  mainSchema,
  acceptProofSchema,
  setHeaderValue,
  setCustomerDescValue,
  setValue,
  setAcceptProofValue,
  value,
  isCreateOrCopy,
  documentType
) => {
  Object.entries(headerSchema).forEach(([key]) => {
    if (
      (isCreateOrCopy && key === "document_id") ||
      (isCreateOrCopy && key === "created_date") ||
      (isCreateOrCopy && key === "issue_date") ||
      (isCreateOrCopy && key === "due_date") ||
      (isCreateOrCopy &&
        documentType === "sales_order" &&
        key === "delivery_date")
    ) {
      return;
    } else {
      if (key === "employee_list") {
        if (value["employee_list"]) {
          setHeaderValue(key, value["employee_list"]);
        } else {
          return;
        }
      }
      if (key === "reference_document_id") {
        if (isCreateOrCopy) {
          if (!value.isCopied) {
            setHeaderValue(key, [
              {
                reference_document_type: value["reference_document_type"],
                reference_document_id: value["document_id"],
              },
            ]);
          } else {
            setHeaderValue(key, value["reference_document_id"]);
          }
        } else {
          setHeaderValue(key, value["reference_document_id"]);
        }
      } else {
        setHeaderValue(key, value[key]);
      }
    }
  });
  Object.entries(customerSchema).forEach(([key]) => {
    if (key === "customer" || key === "identity_no") {
      return;
    } else {
      setCustomerDescValue(key, value["customer"][key]);
    }
  });
  Object.entries(mainSchema).forEach(([key]) => {
    if (
      key === "summary" ||
      (isCreateOrCopy && key === "render_status") ||
      (isCreateOrCopy && key === "created_by") ||
      (isCreateOrCopy && key === "creator_document_id") ||
      (isCreateOrCopy && key === "is_late")
    ) {
      return;
    } else {
      setValue(key, value[key]);
    }
  });
  if (acceptProofSchema && setAcceptProofValue) {
    Object.entries(acceptProofSchema).forEach(([key]) => {
      if (isCreateOrCopy) {
        return;
      } else {
        setAcceptProofValue(key, value[key]);
      }
    });
  }
};

export const salesExportFormatter = (payload, documentType) => {
  let formattedPayload = [];
  payload.forEach((document) => {
    const {
      price_vat_type,
      additional_discount,
      additional_discount_type,
      pre_vat_amount,
    } = document;
    const actualAdditionalDiscount =
      additional_discount_type === "percent"
        ? pre_vat_amount * (additional_discount / 100)
        : additional_discount;
    const sum_pre_vat_amount_exclude_discount = document.item_list.reduce(
      (prev, item) => {
        const pre_vat_amount_exclude_discount =
          documentType === "sales_return"
            ? prev + item.qty_return * item.price_per_unit
            : prev + item.qty * item.price_per_unit;

        return pre_vat_amount_exclude_discount;
      },
      0
    );

    document.item_list.forEach((item) => {
      const pre_vat_amount_exclude_discount =
        documentType === "sales_return"
          ? item.qty_return * item.price_per_unit
          : item.qty * item.price_per_unit;

      // Calculate pre vat amount exclude discount

      const item_additional_discount =
        actualAdditionalDiscount *
        (pre_vat_amount_exclude_discount / sum_pre_vat_amount_exclude_discount);

      const pre_vat_amount_include_discount =
        documentType === "sales_return"
          ? item.qty * item.price_per_unit -
            (item.discount_amount + item_additional_discount)
          : item.qty * item.price_per_unit -
            (item.discount_amount + item_additional_discount);

      const item_vat_exempted_amount =
        item.vat_type === "ไม่มี" ? pre_vat_amount_include_discount : 0;

      const item_vat_0_amount =
        item.vat_type === "0" ? pre_vat_amount_include_discount : 0;

      const item_vat_7_amount =
        item.vat_type === "7" && price_vat_type === "included_vat"
          ? pre_vat_amount_include_discount / 1.07
          : item.vat_type === "7" && price_vat_type === "excluded_vat"
          ? pre_vat_amount_include_discount
          : 0;

      const item_vat_amount = item_vat_7_amount * 0.07;

      const item_withholding_tax_type =
        item.withholding_tax === -1
          ? "ยังไม่ระบุ"
          : item.withholding_tax === 0
          ? "ไม่มี"
          : (item.withholding_tax * 100).toString();

      const item_net_amount =
        item_vat_exempted_amount +
        item_vat_0_amount +
        item_vat_7_amount +
        item_vat_amount;

      let item_withholding_tax;

      if (price_vat_type === "excluded_vat") {
        item_withholding_tax =
          pre_vat_amount_include_discount *
          (item.withholding_tax === -1 ? 0 : item.withholding_tax);
      } else {
        if (item.vat_type === "7") {
          item_withholding_tax =
            (pre_vat_amount_include_discount / 1.07) *
            (item.withholding_tax === -1 ? 0 : item.withholding_tax);
        } else {
          item_withholding_tax =
            pre_vat_amount_include_discount *
            (item.withholding_tax === -1 ? 0 : item.withholding_tax);
        }
      }

      const item_total_amount = item_net_amount - item_withholding_tax;

      let formatDocument;
      if (documentType === "quotation") {
        formatDocument = {
          document_id: document.document_id,
          issue_date: unixToDateWithFormat(document.issue_date),
          due_date: unixToDateWithFormat(document.due_date),
          render_status: filterStatusValueFormatter(document.render_status),
          contact_document_id: document.contact_document_id,
          contact_name: document.contact_name,
          price_vat_type:
            price_vat_type === "included_vat" ? "ราคารวมภาษี" : "ราคาแยกภาษี",
          item_document_id: item.item_document_id,
          item_name: item.item_name,
          qty: item.qty,
          uom: item.uom.name,
          price_per_unit: item.price_per_unit,
          vat_type: item.vat_type,
          pre_vat_amount_exclude_discount,
          item_discount: item.discount_amount,
          item_additional_discount,
          pre_vat_amount_include_discount,
          item_vat_exempted_amount,
          item_vat_0_amount,
          item_vat_7_amount,
          item_vat_amount,
          item_net_amount,
          item_withholding_tax_type,
          item_withholding_tax,
          item_total_amount,
          shipping_cost: document.shipping_cost,
          total_amount: document.total_amount, //Recheck again
          additional_discount: actualAdditionalDiscount,
          credit_day: document.credit_day,
          remark: document.remark,
          qty_uom: item.qty_uom,
          is_late: document.is_late ? "เกินเวลาแล้ว" : "ยังไม่เกินเวลา",
          external_ref_id: document.external_ref_id,
          accepted_date: document.accepted_date
            ? unixToDateWithFormat(document.accepted_date)
            : null,
          accepted_remark: document.accepted_remark,
          item_description: item.item_description,
          item_remark: item.item_remark,
          width_length_tolerance_positive: item.width_length_tolerance_positive,
          width_length_tolerance_negative: item.width_length_tolerance_negative,
          thickness_tolerance_positive: item.thickness_tolerance_positive,
          thickness_tolerance_negative: item.thickness_tolerance_negative,
          created_date: unixToDateTimeWithFormat(document.created_date),
          updated_date: unixToDateWithFormat(document.updated_date),
          created_by:
            document.created_by.first_name +
            " " +
            document.created_by.last_name,
          employee_list: document.employee_list
            .map((employee) => employee?.first_name + " " + employee?.last_name)
            .join(","),
          email: document.customer.email,
          phone: document.customer.phone,
          fax: document.customer.fax,
          billing_address: document.customer.billing_address.address,
          billing_sub_district: document.customer.billing_address.sub_district,
          billing_district: document.customer.billing_address.district,
          billing_province: document.customer.billing_address.province,
          billing_postal_code: document.customer.billing_address.postal_code,
          billing_country: document.customer.billing_address.country,
          is_same_as_default_address:
            document.customer.delivery_address.is_same_as_default_address,
          delivery_address_contact_name:
            document.customer.delivery_address.address_contact_name,
          delivery_address_contact_phone:
            document.customer.delivery_address.address_contact_phone,
          delivery_address: document.customer.delivery_address.address,
          delivery_sub_district:
            document.customer.delivery_address.sub_district,
          delivery_district: document.customer.delivery_address.district,
          delivery_province: document.customer.delivery_address.province,
          delivery_postal_code: document.customer.delivery_address.postal_code,
          delivery_country: document.customer.delivery_address.country,
        };
      } else if (documentType === "sales_order") {
        formatDocument = {
          document_id: document.document_id,
          issue_date: unixToDateWithFormat(document.issue_date),
          delivery_date: unixToDateWithFormat(document.delivery_date),
          render_status: filterStatusValueFormatter(document.render_status),
          contact_document_id: document.contact_document_id,
          contact_name: document.contact_name,
          price_vat_type:
            price_vat_type === "included_vat" ? "ราคารวมภาษี" : "ราคาแยกภาษี",
          item_document_id: item.item_document_id,
          item_name: item.item_name,
          qty: item.qty,
          uom: item.uom.name,
          price_per_unit: item.price_per_unit,
          vat_type: item.vat_type,
          pre_vat_amount_exclude_discount,
          item_discount: item.discount_amount,
          item_additional_discount,
          pre_vat_amount_include_discount,
          item_vat_exempted_amount,
          item_vat_0_amount,
          item_vat_7_amount,
          item_vat_amount,
          item_net_amount,
          item_withholding_tax_type,
          item_withholding_tax,
          item_total_amount,
          shipping_cost: document.shipping_cost,
          total_amount: document.total_amount, //Recheck again
          additional_discount: actualAdditionalDiscount,
          credit_day: document.credit_day,
          remark: document.remark,
          qty_uom: item.qty_uom,
          item_qty_to_ship: item.qty_to_ship,
          item_qty_shipped: item.qty_shipped,
          item_qty_invoiced: item.qty_invoiced,
          item_qty_returned: item.qty_returned,
          is_late: document.is_late ? "เกินเวลาแล้ว" : "ยังไม่เกินเวลา",
          reference_document_id: document.reference_document_id,
          external_ref_id: document.external_ref_id,
          due_date: unixToDateWithFormat(document.due_date),
          item_description: item.item_description,
          item_remark: item.item_remark,
          width_length_tolerance_positive: item.width_length_tolerance_positive,
          width_length_tolerance_negative: item.width_length_tolerance_negative,
          thickness_tolerance_positive: item.thickness_tolerance_positive,
          thickness_tolerance_negative: item.thickness_tolerance_negative,
          created_date: unixToDateTimeWithFormat(document.created_date),
          updated_date: unixToDateWithFormat(document.updated_date),
          created_by:
            document.created_by.first_name +
            " " +
            document.created_by.last_name,
          employee_list: document.employee_list
            .map((employee) => employee?.first_name + " " + employee?.last_name)
            .join(","),
          email: document.customer.email,
          phone: document.customer.phone,
          fax: document.customer.fax,
          billing_address: document.customer.billing_address.address,
          billing_sub_district: document.customer.billing_address.sub_district,
          billing_district: document.customer.billing_address.district,
          billing_province: document.customer.billing_address.province,
          billing_postal_code: document.customer.billing_address.postal_code,
          billing_country: document.customer.billing_address.country,
          is_same_as_default_address:
            document.customer.delivery_address.is_same_as_default_address,
          delivery_address_contact_name:
            document.customer.delivery_address.address_contact_name,
          delivery_address_contact_phone:
            document.customer.delivery_address.address_contact_phone,
          delivery_address: document.customer.delivery_address.address,
          delivery_sub_district:
            document.customer.delivery_address.sub_district,
          delivery_district: document.customer.delivery_address.district,
          delivery_province: document.customer.delivery_address.province,
          delivery_postal_code: document.customer.delivery_address.postal_code,
          delivery_country: document.customer.delivery_address.country,
          item_group_level_1_name_list:
            document.item_group_level_1_name_list.join(", "),
        };
      } else {
        formatDocument = {
          document_id: document.document_id,
          issue_date: unixToDateWithFormat(document.issue_date),
          render_status: filterStatusValueFormatter(document.render_status),
          contact_document_id: document.contact_document_id,
          contact_name: document.contact_name,
          price_vat_type:
            price_vat_type === "included_vat" ? "ราคารวมภาษี" : "ราคาแยกภาษี",
          item_document_id: item.item_document_id,
          item_name: item.item_name,
          qty: item.qty,
          uom: item.uom.name,
          qty_return: item.qty_return,
          price_per_unit: item.price_per_unit,
          vat_type: item.vat_type,
          pre_vat_amount_exclude_discount,
          item_discount: item.discount_amount,
          item_additional_discount,
          pre_vat_amount_include_discount,
          item_vat_exempted_amount:
            item.vat_type === "ไม่มี" ? pre_vat_amount_include_discount : 0,
          item_vat_0_amount:
            item.vat_type === "0" ? pre_vat_amount_include_discount : 0,
          item_vat_7_amount,
          item_vat_amount,
          item_net_amount,
          item_withholding_tax_type,
          item_withholding_tax,
          item_total_amount,
          shipping_cost: document.shipping_cost,
          total_amount: document.total_amount, //Recheck again
          additional_discount: actualAdditionalDiscount,
          credit_day: document.credit_day,
          remark: document.remark,
          qty_uom: item.qty_uom,
          reference_document_id: document.reference_document_id,
          external_ref_id: document.external_ref_id,
          reason_to_return: document.reason_to_return,
          is_effect_stock: document.is_effect_stock,
          item_description: item.item_description,
          item_remark: item.item_remark,
          created_date: unixToDateTimeWithFormat(document.created_date),
          updated_date: unixToDateWithFormat(document.updated_date),
          created_by:
            document.created_by.first_name +
            " " +
            document.created_by.last_name,
          employee_list: document.employee_list
            .map((employee) => employee?.first_name + " " + employee?.last_name)
            .join(","),
          email: document.customer.email,
          phone: document.customer.phone,
          fax: document.customer.fax,
          billing_address: document.customer.billing_address.address,
          billing_sub_district: document.customer.billing_address.sub_district,
          billing_district: document.customer.billing_address.district,
          billing_province: document.customer.billing_address.province,
          billing_postal_code: document.customer.billing_address.postal_code,
          billing_country: document.customer.billing_address.country,
          is_same_as_default_address:
            document.customer.delivery_address.is_same_as_default_address,
          delivery_address_contact_name:
            document.customer.delivery_address.address_contact_name,
          delivery_address_contact_phone:
            document.customer.delivery_address.address_contact_phone,
          delivery_address: document.customer.delivery_address.address,
          delivery_sub_district:
            document.customer.delivery_address.sub_district,
          delivery_district: document.customer.delivery_address.district,
          delivery_province: document.customer.delivery_address.province,
          delivery_postal_code: document.customer.delivery_address.postal_code,
          delivery_country: document.customer.delivery_address.country,
        };
      }
      formattedPayload.push(formatDocument);
    });
  });
  return formattedPayload;
};
