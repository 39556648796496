import React, { forwardRef } from "react";
import AddItemListForm from "../../../components/Form/Purchase/AddItemListForm";
import Summary from "../../../components/Form/Purchase/Summary";
import UserDescriptionForm from "../../../components/Form/Purchase/UserDescription";

const DocumentInfoTab = forwardRef(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      setHeaderValue,
      getHeaderValues,
      submitUserDesc,
      userDescUseForm,
      itemListRef,
      viewOnly,
      gridRef,
      salesOrderIds,
      setSalesOrderIds,
      salesOrderIdsSnapshot,
      setSalesOrderIdsSnapshot,
    },
    ref
  ) => {
    const {
      control: userDescControl,
      setValue: setUserDescValue,
      getValues: getUserDescValues,
      formState: { errors: userDescErrors },
    } = userDescUseForm;

    return (
      <>
        <UserDescriptionForm
          ref={ref}
          mainControl={control}
          control={userDescControl}
          onSubmitContact={submitUserDesc}
          setValue={setUserDescValue}
          getValues={getUserDescValues}
          getMainValues={getValues}
          errors={userDescErrors}
          viewOnly={viewOnly}
        />
        <AddItemListForm
          itemListRef={itemListRef}
          gridRef={gridRef}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          setHeaderValue={setHeaderValue}
          getHeaderValues={getHeaderValues}
          salesOrderIds={salesOrderIds}
          setSalesOrderIds={setSalesOrderIds}
          salesOrderIdsSnapshot={salesOrderIdsSnapshot}
          setSalesOrderIdsSnapshot={setSalesOrderIdsSnapshot}
          documentType="pr"
          draggable
        />
        <Summary
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          onlyTemplate
          documentType="pr"
        />
      </>
    );
  }
);

export default DocumentInfoTab;
