import { Box } from "@mui/material";
import GlobalService from "../../../services/Global";
import {
  unixToDateTimeWithFormatNoSecond,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import CustomizedChips from "../../Custom/CustomizedChips";
import CustomizedStatus from "../../Custom/CustomizedStatus";

export const woColumnDef = (t, isReport) => {
  if (isReport) {
    return [
      {
        field: "manufacture_order_document_id",
        headerName: t("reports.reference_ducoment_id"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "created_date",
        headerName: t("sales.issueDate"),
        filter: "agDateColumnFilter",
        width: 175,
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "name",
        headerName: t("manufacture.workOrder.name"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "plant",
        headerName: t("manufacture.workOrder.plant"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "wo_group",
        headerName: t("manufacture.order.group"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params) => {
            const loadedOptions = await GlobalService.getTagListByEntity(
              "manufacture_order"
            );
            const formatType = loadedOptions.map((option) => option.name);
            params.success(formatType);
          },
        },
        cellRenderer: (params) => {
          return params.value.map((group, index) => (
            <Box sx={{ display: "inline-block", mr: 1, gap: 1 }}>
              <CustomizedChips key={group + index} value={`${group}`} />
            </Box>
          ));
        },
      },
      {
        field: "machine",
        headerName: t("manufacture.workOrder.machine"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "start_date_time",
        headerName: t("manufacture.workOrder.start_date"),
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("date"),
          comparator: dateComparator,
        },
      },
      {
        field: "status",
        headerName: t("manufacture.bom.status"),
        filter: "agSetColumnFilter",
        filterParams: {
          // values: ["draft", "in_progress", "finished", "canceled"],
          values: ["ร่าง", "กำลังผลิต", "เสร็จสิ้น", "ยกเลิก"],
        },
        cellRenderer: (params) => {
          return <CustomizedStatus status={params.value} />;
        },
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        sortable: true,
      },
      {
        field: "duration",
        headerName: t("manufacture.workOrder.duration"),
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: filterParamsOptions("string"),
        },
      },
      {
        field: "description",
        headerName: t("manufacture.workOrder.description"),
        hide: true,
        filter: false,
        sortable: false,
        disableFloatingFilter: true,
      },
    ];
  }

  const formatColumnDef = [
    {
      field: "manufacture_order_document_id",
      headerName: t("reports.reference_ducoment_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      // sortable: true,
    },
    {
      field: "created_date",
      headerName: t("sales.issueDate"),
      filter: "agDateColumnFilter",
      sort: "desc",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.created_date),
    },
    {
      field: "name",
      headerName: t("manufacture.workOrder.name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "plant",
      headerName: t("manufacture.workOrder.plant"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "group",
      headerName: t("manufacture.order.group"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          const loadedOptions = await GlobalService.getTagListByEntity(
            "manufacture_order"
          );
          const formatType = loadedOptions.map((option) => option.name);
          params.success(formatType);
        },
      },
      valueFormatter: (params) =>
        params.data.manufacture_order.group.map((value) => value.name),
      cellRenderer: (params) => {
        return params.data.manufacture_order.group.map((group, index) => (
          <Box sx={{ display: "inline-block", mr: 1, gap: 1 }}>
            <CustomizedChips key={group.name + index} value={`${group.name}`} />
          </Box>
        ));
      },
    },
    {
      field: "machine",
      headerName: t("manufacture.workOrder.machine"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "start_date_time",
      headerName: t("manufacture.workOrder.start_date"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        unixToDateTimeWithFormatNoSecond(params.data.start_date_time),
    },
    {
      field: "status",
      headerName: t("manufacture.bom.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["draft", "in_progress", "finished", "canceled"],
        valueFormatter: (params) => filterStatusValueFormatter(params.value),
      },
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
      sortable: true,
    },
    {
      field: "duration",
      headerName: t("manufacture.workOrder.duration"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "description",
      headerName: t("manufacture.workOrder.description"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      // hide: true,
    },
  ];

  return formatColumnDef;
};
