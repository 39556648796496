import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedChips from "../../../components/Custom/CustomizedChips";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import { Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  mapRolesToObject,
  extractRoleOptions,
} from "../../../utils/dataTransformer";
import {
  getAllRolesNoAggrid,
  removePriviledge,
  setPriviledgeList,
} from "../../../features/User/Rbac/rbac-action";
import PermissionForm from "../../../components/Form/Permission";
import CustomizedMenuOption from "../../../components/Custom/CustomizedMenuOption";
import { userPermission } from "../../../utils/userInfo";
import { useAuth } from "../../../hooks/use-auth";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const PermissionTab = ({
  control,
  errors,
  setValue,
  getValues,
  viewOnly,
  addable,
  setting,
}) => {
  const { t } = useTranslation();
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const { role, allRoles } = useSelector((state) => state.rbac);
  const { user } = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();

  const { user: userAuth, permissions } = useAuth();

  const disabledEditPermission = userPermission(
    permissions,
    "user",
    "rbac",
    "edit"
  );

  const { fields, append, remove, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "group_role_list", // unique name for your Field Array
  });

  const chipOptions = extractRoleOptions(allRoles);

  const [option, setOption] = useState("");

  const handleAddRole = (newValue) => {
    const roleName = fields.map((role) => role.label);
    if (newValue && !roleName.includes(newValue.label)) {
      append(newValue);
      const priviledgeListPayload = mapRolesToObject(allRoles, [
        ...roleName,
        newValue.label,
      ]);
      dispatch(setPriviledgeList(priviledgeListPayload));
    }
  };

  const handleDeleteRole = (roleIndex, data) => {
    remove(roleIndex);
    const selectedRoleNames = getValues("group_role_list");
    const removePriviledgePayload = allRoles.find(
      (role) => role.name === data.label
    ).permission_list;
    dispatch(removePriviledge(removePriviledgePayload));
    const roleName = selectedRoleNames.map((role) => role.label);
    const priviledgeListPayload = mapRolesToObject(allRoles, roleName);
    dispatch(setPriviledgeList(priviledgeListPayload));
  };

  const handleChipClicked = (chipIndex) => {
    const roleId = fields[chipIndex].value;
    return `/user/rbac/${roleId}?step=permission&subtab=inventory`;
  };

  useEffect(() => {
    if (option === "แก้ไข" && !disabledEditPermission) {
      dispatch(getAllRolesNoAggrid({}, enqueueSnackbar));
    }
  }, [disabledEditPermission, dispatch, enqueueSnackbar, option]);

  useEffect(() => {
    if (user) {
      const formatRole = extractRoleOptions(user.role_list);
      replace(formatRole);
      const roleName = user.role_list.map(({ name }) => name);
      const priviledgeListPayload = mapRolesToObject(user.role_list, roleName);
      dispatch(setPriviledgeList(priviledgeListPayload));
    }
  }, [user, replace, allRoles, dispatch]);

  useEffect(() => {
    //set new default value for inputs in the table if role change
    if (role) {
      Object.entries(role).forEach(([key, value]) => setValue(key, value));
    }
  }, [role, setValue]);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5">{t("user.account.permission")}</Typography>
        </Grid>
        {viewOnly && employeeId !== "EP-ADMIN" && (
          <Grid item>
            <CustomizedMenuOption
              label={"ตัวเลือก"}
              options={[{ value: "แก้ไข", disabled: disabledEditPermission }]}
              onSelect={(e) => {
                setOption(e.target.innerText);
              }}
            />
          </Grid>
        )}
      </Grid>
      <CustomizedBox>
        <Typography fontWeight="bold" ml={1} mb={2}>
          {t("user.account.permission")}
        </Typography>
        <Grid container spacing={1}>
          {((employeeId && employeeId !== "EP-ADMIN") ||
            userAuth.document_id !== "EP-ADMIN") && (
            <Grid item xs={12} md={4}>
              <CustomizedComboBox
                label={t("user.rbac.permission")}
                options={chipOptions}
                onChange={(_, newValue) => {
                  handleAddRole(newValue);
                }}
                disabled={
                  (!setting && !addable && option !== "แก้ไข") ||
                  (disabledEditPermission && setting)
                }
              />
            </Grid>
          )}
          <Grid item xs={12} md={8} container spacing={1} alignItems="center">
            {fields.map((data, index) => (
              <Controller
                key={index + Math.random()}
                name={`group_role_list[${index}]`}
                control={control}
                render={({ field }) => (
                  <Grid item>
                    <CustomizedChips
                      {...field}
                      value={field.value.label}
                      onDelete={
                        (!setting && !addable && option !== "แก้ไข") ||
                        (setting &&
                          ((employeeId && employeeId === "EP-ADMIN") ||
                            userAuth.document_id === "EP-ADMIN")) ||
                        (disabledEditPermission && setting)
                          ? undefined
                          : () => handleDeleteRole(index, data)
                      }
                      onClick={() =>
                        window.open(
                          handleChipClicked(index),
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    />
                  </Grid>
                )}
              />
            ))}
          </Grid>
        </Grid>
      </CustomizedBox>
      <PermissionForm
        control={control}
        errors={errors}
        viewOnly={viewOnly}
        addable={addable}
        setting={setting}
      />
    </>
  );
};

export default PermissionTab;
