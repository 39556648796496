import SalesService from "../../../services/Sales";
import { creditNoteActions } from "./credit-note-slice";
import { salesQueryFormatter } from "../../../utils/salesPayloadFormatter";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogApprovePayload,
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import { filterStatusValueFormatter } from "../../../utils/filterparams";

export const getAllCreditNotes =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(creditNoteActions.onLoading("allCreditNotes"));
    try {
      const allCreditNotes = await SalesService.getAllCreditNotes(input);
      params.successCallback(allCreditNotes.results, allCreditNotes.count);
      dispatch(creditNoteActions.loadedAllCreditNotes(allCreditNotes.results));
    } catch (err) {
      dispatch(
        creditNoteActions.rejectedActions({ ...err, name: "allCreditNotes" })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllCreditNotesExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(creditNoteActions.onLoading("allCreditNotes"));
    try {
      const { results } = await SalesService.getAllCreditNotes(input);
      const formatAllCreditNotes = results.map((creditNote) => {
        let item_list = [];
        creditNote.item_list.forEach((item) => {
          const pre_vat_amount =
            item.qty * item.price_per_unit - item.discount_amount;
          const withholding_tax =
            item.withholding_tax === -1
              ? "ยังไม่ระบุ"
              : item.withholding_tax === 0
              ? "ไม่มี"
              : (item.withholding_tax * 100).toString();
          const formatItemList = {
            ...item,
            pre_vat_amount,
            withholding_tax,
          };
          item_list.push(formatItemList);
        });
        return {
          ...creditNote,
          item_list,
          issue_date: unixToDateWithFormat(creditNote.issue_date),
          created_date: unixToDateWithFormat(creditNote.created_date),
          updated_date: unixToDateWithFormat(creditNote.updated_date),
          render_status: filterStatusValueFormatter(creditNote.render_status),
        };
      });
      dispatch(creditNoteActions.loadedAllCreditNotes(formatAllCreditNotes));
    } catch (err) {
      console.error(err);
      dispatch(
        creditNoteActions.rejectedActions({ ...err, name: "allCreditNotes" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllCreditNotesExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(creditNoteActions.onLoading("allCreditNotes"));
    try {
      const { results } = await SalesService.getAllCreditNotesReport(input);
      const formatAllCreditNotes = results.map((creditNote) => {
        let item_list = [];
        creditNote.item_list.forEach((item) => {
          const pre_vat_amount =
            item.qty * item.price_per_unit - item.discount_amount;
          const withholding_tax =
            item.withholding_tax === -1
              ? "ยังไม่ระบุ"
              : item.withholding_tax === 0
              ? "ไม่มี"
              : (item.withholding_tax * 100).toString();
          const formatItemList = {
            ...item,
            pre_vat_amount,
            withholding_tax,
          };
          item_list.push(formatItemList);
        });
        return {
          ...creditNote,
          item_list,
          issue_date: unixToDateWithFormat(creditNote.issue_date),
          created_date: unixToDateWithFormat(creditNote.created_date),
          updated_date: unixToDateWithFormat(creditNote.updated_date),
          render_status: filterStatusValueFormatter(creditNote.render_status),
        };
      });
      dispatch(creditNoteActions.loadedAllCreditNotes(formatAllCreditNotes));
    } catch (err) {
      console.log(err);
      dispatch(
        creditNoteActions.rejectedActions({ ...err, name: "allCreditNotes" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewCreditNote =
  (payload, isSendApprove, state, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    try {
      const result = await SalesService.createCreditNote(payload);
      const createActivityLog = createActivityLogPayload(
        payload,
        "credit_note",
        "สร้างใบลดหนี้"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });

      if (payload.reference_document_id) {
        const createRefActivityLog = createActivityLogPayload(
          {
            ...payload,
            document_id: payload.reference_document_id,
          },
          "sales_return",
          "สร้างใบลดหนี้"
        );
        const finishedActivityLog = createActivityLogStatusPayload(
          {
            ...payload,
            document_id: payload.reference_document_id,
          },
          "sales_return",
          "approved",
          "finished"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createRefActivityLog,
        });
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: finishedActivityLog,
        });
      }

      if (state && state?.isCopied) {
        const copiedActivityLog = createActivityLogCopiedPayload(
          state,
          "credit_note"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }

      if (isSendApprove) {
        const documentInput = {
          document_type: "credit_note",
          document_id: result.document_id,
        };
        await SalesService.postNextStatus(documentInput);
        enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(`/sales/credit-note/${encodeURIComponent(result.document_id)}`);
    } catch (err) {
      dispatch(
        creditNoteActions.rejectedActions({ ...err, name: "creditNote" })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบลดหนี้ไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getCreditNoteById =
  (uniqueInput, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(creditNoteActions.onLoading("creditNote"));
    try {
      const creditNote = await SalesService.getCreditNoteById(uniqueInput);
      const formatCreditNote = await salesQueryFormatter(creditNote);

      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("credit_note");

      const approvalProgress = await SalesService.getApprovalProgress({
        reference_document_type: "credit_note",
        reference_document_id: uniqueInput.document_id,
      });

      dispatch(
        creditNoteActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress,
        })
      );

      dispatch(
        creditNoteActions.updateApprovalStatus({
          approval_list: approvalProgress,
          user,
        })
      );

      dispatch(creditNoteActions.loadedCreditNote(formatCreditNote));
    } catch (err) {
      console.error(err);
      dispatch(
        creditNoteActions.rejectedActions({ ...err, name: "creditNote" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const updateCreditNote =
  (payload, status, isChangedStatus, existing, user, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(creditNoteActions.onLoading("creditNote"));
    try {
      const creditNote = await SalesService.updateCreditNote(payload);
      const formatCreditNote = await salesQueryFormatter(creditNote);
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "credit_note",
        };
        const updatedCreditNote = await SalesService.postNextStatus(
          documentInput
        );
        if (
          updatedCreditNote?.approval_progress_list
            ?.map((approval) => approval?.approval_status)
            .includes("PENDING")
        ) {
          dispatch(
            creditNoteActions.updateApprovalStatus({
              approval_list: updatedCreditNote.approval_progress_list,
              user,
            })
          );
          const sendToApproveActivityLog = createActivityLogStatusPayload(
            payload,
            "credit_note",
            "draft",
            "wait_approve"
          );
          dispatch(
            creditNoteActions.loadedCreditNote({
              ...formatCreditNote,
              render_status: "waitApprove",
            })
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToApproveActivityLog,
          });
          enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
            variant: "success",
          });
        } else {
          dispatch(
            creditNoteActions.loadedCreditNote({
              ...formatCreditNote,
              render_status: updatedCreditNote.status,
            })
          );
          enqueueSnackbar("อัพเดทใบลดหนี้สำเร็จ", {
            variant: "success",
          });
        }
      } else {
        const editActivityLog = createActivityLogEditPayload(
          payload,
          "credit_note"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
        const { addedEmployeeList, deletedEmployeeList } =
          findDifferenceEmployee(payload, existing);
        if (addedEmployeeList && addedEmployeeList.length > 0) {
          // add related employee
          const addedEmployeeListLog = createActivityLogEmployeePayload(
            payload,
            "add_related_employee",
            addedEmployeeList,
            "credit_note"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: addedEmployeeListLog,
          });
        }
        if (deletedEmployeeList && deletedEmployeeList.length > 0) {
          // delete related employee
          const deletedEmployeeListLog = createActivityLogEmployeePayload(
            payload,
            "delete_related_employee",
            deletedEmployeeList,
            "credit_note"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: deletedEmployeeListLog,
          });
        }

        switch (status) {
          case "draft":
            dispatch(creditNoteActions.loadedCreditNote(formatCreditNote));
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
            break;
          default:
            dispatch(
              creditNoteActions.loadedCreditNote({
                ...formatCreditNote,
                render_status: status,
              })
            );
            enqueueSnackbar("แก้ไขใบลดหนี้สำเร็จ", {
              variant: "success",
            });
        }
      }
    } catch (err) {
      dispatch(
        creditNoteActions.rejectedActions({ ...err, name: "creditNote" })
      );
      switch (status) {
        case "draft":
          if (isChangedStatus) {
            err.response.errors.forEach((error) => {
              if (errorMessageHandler(error.message)) {
                enqueueSnackbar(errorMessageHandler(error.message), {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("แก้ไขใบลดหนี้ไม่สำเร็จ", {
                  variant: "error",
                });
              }
            });
          } else {
            enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        default:
          enqueueSnackbar("แก้ไขใบลดหนี้ไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const approveCreditNote =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(creditNoteActions.onLoading("creditNote"));
    try {
      const { approval_progress_list } = await SalesService.postApproveStatus(
        documentInput
      );
      const sortedApprovalProgress = approval_progress_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      if (
        sortedApprovalProgress
          ?.map((approval) => approval?.approval_status)
          .includes("PENDING")
      ) {
        dispatch(
          creditNoteActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );

        //TODO: Recheck logic
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );

        dispatch(creditNoteActions.updateCreditNoteStatus("waitApprove"));
        // TODO: Approval step logs
        const approvedActivityLog = createActivityLogApprovePayload(
          payload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "credit_note"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      } else {
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        const approvedNextActivityLog = createActivityLogApprovePayload(
          payload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "credit_note"
        );

        dispatch(
          creditNoteActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        dispatch(creditNoteActions.updateCreditNoteStatus("approved"));
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedNextActivityLog,
        });
        const approvedActivityLog = createActivityLogStatusPayload(
          payload,
          "credit_note",
          "wait_approve",
          "approved"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      }
      enqueueSnackbar("อนุมัติสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      dispatch(
        creditNoteActions.rejectedActions({ ...err, name: "creditNote" })
      );
      enqueueSnackbar("อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const declineCreditNote =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(creditNoteActions.onLoading("creditNote"));
    try {
      const notApprovedActivityLog = createActivityLogStatusPayload(
        payload,
        "credit_note",
        "wait_approve",
        "not_approved"
      );
      const { approval_progress_list } = await SalesService.postDeclineStatus(
        documentInput
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: notApprovedActivityLog,
      });
      enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
        variant: "success",
      });
      dispatch(
        creditNoteActions.updateApprovalStatus({
          approval_list: approval_progress_list,
          user,
        })
      );
      dispatch(creditNoteActions.updateCreditNoteStatus("notApproved"));
    } catch (err) {
      console.error(err);
      dispatch(
        creditNoteActions.rejectedActions({ ...err, name: "creditNote" })
      );
      enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const cancelCreditNote =
  (documentInput, payload, enqueueSnackbar) => async (dispatch) => {
    dispatch(creditNoteActions.onLoading("creditNote"));
    try {
      const cancelActivityLog = createActivityLogStatusPayload(
        payload,
        "credit_note",
        null,
        "cancelled"
      );
      await SalesService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(creditNoteActions.updateCreditNoteStatus("cancelled"));
    } catch (err) {
      dispatch(
        creditNoteActions.rejectedActions({ ...err, name: "creditNote" })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
