import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedStatus from "../../../../components/Custom/CustomizedStatus";
import AgGrid from "../../../../components/Table/AgGrid";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import { useDispatch, useSelector } from "react-redux";
import { getAllVehicles } from "../../../../features/Setting/Vehicle/vehicle-actions";
import { useSnackbar } from "notistack";
import { usePermission } from "../../../../hooks/use-auth";
import { filterParamsOptions } from "../../../../utils/filterparams";
import { unixToDateWithFormat } from "../../../../utils/date-converter";

const VehicleSetting = () => {
  const gridRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { allVehicles } = useSelector((state) => state.vehicle);
  const { enqueueSnackbar } = useSnackbar();
  const { createPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.logisticSetting"), to: "/setting/logistic" },
    { name: t("setting.vehicleSetting") },
  ];

  const columnDefs = [
    {
      field: "license_plate",
      headerName: t("setting.logistic.licensePlate"),
      filter: "agSetColumnFilter",
    },
    {
      field: "vehicle_type",
      headerName: t("setting.logistic.vehicleType"),
      filter: "agTextColumnFilter",
    },
    {
      field: "weight",
      headerName: t("setting.logistic.weight"),
      filter: "agTextColumnFilter",
    },
    {
      field: "volume",
      headerName: t("setting.logistic.volume"),
      filter: "agTextColumnFilter",
    },
    {
      field: "remark",
      headerName: t("setting.logistic.remark"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("inventory.status"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <CustomizedStatus status={params.value} />,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "created_date",
      headerName: t("sales.createdDate"),
      sort: "desc",
      hide: true,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.created_date),
      width: 200,
      suppressColumnsToolPanel: true,
    },
  ];

  useEffect(() => {
    dispatch(getAllVehicles(enqueueSnackbar));
  }, [dispatch, enqueueSnackbar]);

  const onRowDoubleClicked = (params) => {
    navigate(`${pathname}/${params.data.id}`);
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container my={2}>
        <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
          <Typography variant="h5" mt={2}>
            {t("setting.vehicleSetting")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1}
          xl={1}
          alignSelf="center"
          textAlign={"end"}
        >
          {createPermission && (
            <CustomizedButton
              fullWidth
              title={t("setting.logistic.addVehicle")}
              variant="contained"
              onClick={() => navigate(`${pathname}/add`)}
            />
          )}
        </Grid>
      </Grid>
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        height={648.5}
        rowData={allVehicles}
        onRowDoubleClicked={onRowDoubleClicked}
        path={pathname}
      />
    </>
  );
};

export default VehicleSetting;
