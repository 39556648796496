import { useSnackbar } from "notistack";
import React, { useCallback, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getAllSalesOrders } from "../../features/Sales/SalesOrder/sales-order-actions";
import CheckboxModalTable from "./CheckboxModalTable";
// import { salesOrderColumnDefs } from "./ColumnDefs/salesOrderColumnDefs";
import InventoryService from "../../services/Inventory";
import useSalesOrderColumnDef from "../../hooks/Sales/useSalesOrderColumnDef";
import { filterDateWithUnix } from "../../utils/filterparams";

const CheckedSalesOrderTable = ({
  control,
  setHeaderValue,
  setValue,
  openModal,
  setOpenModal,
  salesOrderIds,
  setSalesOrderIds,
  salesOrderIdsSnapshot,
  setSalesOrderIdsSnapshot,
}) => {
  const selectSalesOrderGridRef = useRef();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const columnDefs = useSalesOrderColumnDef();

  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const dispatch = useDispatch();

  const watchItemList = useWatch({ control, name: "item_list" });

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const {
        document_id,
        contact_name,
        issue_date,
        delivery_date,
        created_by,
        employee_list,
        is_late,
        do_document_id_list,
        item_group_level_1_name_list,
      } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        contact_name: contact_name && {
          ...contact_name,
          mode: "insensitive",
        },
        issue_date: issue_date && filterDateWithUnix(issue_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...employee_list, mode: "insensitive" },
          },
        },
        item_group_level_1_name_list: item_group_level_1_name_list && {
          filterType: "array",
          type: "hasSome",
          values: item_group_level_1_name_list.values,
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: {
              ...created_by,
              mode: "insensitive",
            },
          },
        },
        is_late: is_late
          ? {
              filterType: "boolean",
              type: "equals",
              filter: "true",
            }
          : undefined,
        do_document_id_list: do_document_id_list
          ? {
              filterType: "array",
              type: "hasSome",
              values: do_document_id_list.values,
            }
          : undefined,
      };

      dispatch(
        getAllSalesOrders(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const instance = params.api.getFilterInstance("render_status");
    instance.setModel({
      filterType: "set",
      values: ["approved", "finished"],
    });
    params.api.onFilterChanged();
    params.api.setServerSideDatasource(datasource);
  };

  const finishSalesOrdersSelect = async (data) => {
    // filter out employees not selected in current modal session
    let filteredSalesOrder = watchItemList.filter((salesOrder) =>
      salesOrderIds.includes(salesOrder.document_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredSalesOrderId = filteredSalesOrder.map(
      (salesOrder) => salesOrder.document_id
    );

    data.forEach((salesOrder) => {
      if (!filteredSalesOrderId.includes(salesOrder.document_id)) {
        filteredSalesOrder.push(salesOrder);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredSalesOrder = filteredSalesOrder.sort((a, b) => {
      return (
        salesOrderIds.indexOf(a.document_id) -
        salesOrderIds.indexOf(b.document_id)
      );
    });

    const newItemList = [];

    const allItemsDocumentId = [];
    filteredSalesOrder.forEach((refDoc) =>
      refDoc.item_list.map((item) =>
        allItemsDocumentId.push(item.item_document_id)
      )
    );

    const items = await InventoryService.getAllItems({
      findManyInput: {
        where: {
          document_id: {
            in: allItemsDocumentId,
          },
        },
      },
    });

    filteredSalesOrder.forEach((order) => {
      order.item_list.forEach((list) => {
        const findItem = items.find(
          (item) => list.item_document_id === item.document_id
        );
        newItemList.push({
          ...list,
          uom_id: list.uom.id,
          ref_document_id: order.document_id,
          ref_document_type: "sales_order",
          po_qty: 0,
          uom_group: findItem?.uom_group || [],
          current_ordered_purchase_qty:
            findItem?.current_ordered_purchase_qty ?? 0,
          current_ordered_manufacture_qty:
            findItem?.current_ordered_manufacture_qty ?? 0,
          current_committed_sales_qty:
            findItem?.current_committed_sales_qty ?? 0,
          current_stock_qty: findItem?.current_stock_qty ?? 0,
          current_available_qty: findItem?.current_available_qty ?? 0,
        });
      });
    });
    const refList = [];

    newItemList.forEach((list) => {
      if (
        refList.some(
          (value) => value.reference_document_id === list.ref_document_id
        )
      ) {
        return null;
      } else
        refList.push({
          reference_document_id: list.ref_document_id,
          reference_document_type: list.ref_document_type,
        });
    });

    setValue("item_list", newItemList);
    setHeaderValue("reference_document_list", refList);
    setOpenModal(false);
  };

  return (
    <CheckboxModalTable
      modalTitle="นำเข้าใบสั่งขาย"
      btnTitle={t("button.add")}
      gridRef={selectSalesOrderGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection="multiple"
      onFinishEditing={finishSalesOrdersSelect}
      modalIsOpen={openModal}
      closeModal={() => setOpenModal(false)}
      onGridReady={onGridReady}
      selectedIds={salesOrderIds}
      setSelectedIds={setSalesOrderIds}
      idsSnapshot={salesOrderIdsSnapshot}
      setIdsSnapshot={setSalesOrderIdsSnapshot}
      getRowId={getRowId}
      disabledSidebar
    />
  );
};

export default CheckedSalesOrderTable;
