import { Box, Typography } from "@mui/material";
import React from "react";

const PDFHeader = ({ documentType, noCompany, imgSize }) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Box sx={{ display: "flex" }}>
          <img width={imgSize ?? 150} alt="vpi-logo" src="/static/logo.png" />
          {!noCompany && (
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                บริษัท วีพีไอ อินดัสตรี จำกัด
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                สำนักงานใหญ่ : 99 ม.5 ถ.กาญจนาภิเษก ต.เสาธงหิน อ.บางใหญ่
                จ.นนทบุรี 11140
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  textIndent: 78,
                }}
              >
                โทรศัพท์: 02-9033322-5, 088-2553232 แฟกซ์: 0-2903-3326
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                สาขา 00002 &nbsp;: 88/8-88/12 ม.1 ต.ขุนแก้ว อ.นครชัยศรี จ.นครปฐม
                73120
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  textIndent: 78,
                }}
              >
                โทรศัพท์: 034-900297, 034-900298 แฟกซ์: 034-900299
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                เลขประจำตัวผู้เสียภาษีอากร 0105535169381
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          {documentType !== "โอนย้าย" && documentType !== "สูตรการผลิต/BOM" && (
            <Typography
              sx={{ fontSize: 9, fontWeight: 600, textAlign: "right" }}
            >
              (ต้นฉบับ/Original)
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            {documentType}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PDFHeader;
