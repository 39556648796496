import { Typography, Grid, Box, DialogContentText } from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlledServerSideCreatable from "../../../Custom/ControlledSSCreatable";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import ModalUI from "../../../UI/ModalUI";
import AttachmentDropzone from "./AttachmentDropzone";

const DeliveryDetails = ({
  viewOnly,
  control,
  errors,
  getValues,
  setValue,
  onSubmit,
  open,
  onDeliveryDetailCloseHandler,
  status,
  submitForm,
  reset,
}) => {
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = useState(false);
  const deliveryStatusOptions = [
    { id: 1, label: t("status.completed"), value: "completed" },
    { id: 2, label: t("status.notCompleted"), value: "notCompleted" },
  ];

  const deliveryRemarkType = [
    { id: 1, label: "ลายเซ็น", value: "ลายเซ็น" },
    { id: 2, label: "รูปถ่าย", value: "รูปถ่าย" },
  ];

  const mappedStatusToThai = (status) => {
    if (status === "waitDeliver") {
      return "รอจัดส่ง";
    } else if (status === "notCompleted") {
      return "ไม่สำเร็จ";
    } else if (status === "completed") {
      return "สำเร็จ";
    } else {
      return "";
    }
  };

  const delivery_status = getValues("delivery_status");

  return (
    <>
      {open ? (
        <>
          <Grid container>
            <Grid xs={12}>
              <Grid container sx={{ alignItems: "center", mb: 1 }}>
                <Grid xs={3}>
                  <Typography sx={{ ml: -1 }}>
                    {t("logistic.deliveryOrder.deliveryStatus")}
                  </Typography>
                </Grid>
                <Grid xs={9} sx={{ ml: -1 }}>
                  <Controller
                    name="delivery_status"
                    control={control}
                    render={({ field }) => (
                      <CustomizedSelect
                        {...field}
                        fullWidth
                        error={Boolean(errors?.delivery_status)}
                        helperText={errors?.delivery_status?.message}
                        options={deliveryStatusOptions}
                        disabled={viewOnly}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ alignItems: "center", mb: 1 }}>
                <Grid xs={3}>
                  <Typography sx={{ ml: -1 }}>
                    {t("logistic.deliveryOrder.receivedBy")}
                  </Typography>
                </Grid>
                <Grid xs={9} sx={{ ml: -1 }}>
                  <Controller
                    name="recipient_name"
                    control={control}
                    render={({ field }) => (
                      <CustomizedTextField
                        {...field}
                        disabled={viewOnly}
                        error={Boolean(errors && errors?.recipient_name)}
                        helperText={errors?.recipient_name?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography sx={{ my: 2, fontWeight: "bold", ml: -1 }}>
            หลักฐานอ้างอิง
          </Typography>
          <Grid container>
            <Grid xs={12}>
              <Grid container sx={{ alignItems: "center", mb: 1 }}>
                <Grid xs={3}>
                  <Typography sx={{ ml: -1 }}>
                    {t("logistic.deliveryOrder.remarkType")}
                  </Typography>
                </Grid>
                <Grid xs={9} sx={{ ml: -1 }}>
                  <ControlledServerSideCreatable
                    control={control}
                    name="delivery_reference"
                    documentType="delivery_order"
                    defaultOptions={deliveryRemarkType}
                    error={Boolean(errors && errors?.delivery_reference)}
                    helperText={errors?.delivery_reference?.message}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ ml: -1, mt: 1 }}>
            <Grid xs={12}>
              <Controller
                key={"delivery_detail"}
                name={"delivery_detail"}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors["delivery_detail"])}
                    helperText={errors["delivery_detail"]?.message}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12}>
              <Grid container sx={{ alignItems: "center", ml: -1 }}>
                <AttachmentDropzone
                  setValue={setValue}
                  getValues={getValues}
                  valueToSet={"attachment_list"}
                  disabled={viewOnly}
                />
              </Grid>
            </Grid>
          </Grid>
          {!viewOnly && (
            <Box sx={{ ml: -1, mt: 1, display: "flex", gap: 1 }}>
              <CustomizedButton
                title={t("button.cancel")}
                variant="outlined"
                onClick={() => {
                  onDeliveryDetailCloseHandler();
                }}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={async () => {
                  const payload = await submitForm();
                  if (payload) {
                    if (status === "waitDeliver" || status === "notCompleted") {
                      setConfirmation(true);
                    } else {
                      onSubmit();
                    }
                  }
                }}
              />
            </Box>
          )}
        </>
      ) : (
        <CustomizedBox margin="0 0 2rem 0">
          <Typography sx={{ fontWeight: "bold", mb: 2 }}>
            {t("logistic.deliveryOrder.deliveryDetails")}
          </Typography>
          <Grid container>
            <Grid xs={12} sm={12} md={6} lg={5} xl={5}>
              <Grid container sx={{ alignItems: "center", mb: 1 }}>
                <Grid xs={3}>
                  <Typography>
                    {t("logistic.deliveryOrder.deliveryStatus")}
                  </Typography>
                </Grid>
                <Grid xs={9}>
                  <Controller
                    name="delivery_status"
                    control={control}
                    render={({ field }) => (
                      <CustomizedSelect
                        {...field}
                        fullWidth
                        error={Boolean(errors?.delivery_status)}
                        helperText={errors?.delivery_status?.message}
                        options={deliveryStatusOptions}
                        disabled={viewOnly}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ alignItems: "center", mb: 1 }}>
                <Grid xs={3}>
                  <Typography>
                    {t("logistic.deliveryOrder.receivedBy")}
                  </Typography>
                </Grid>
                <Grid xs={9}>
                  <Controller
                    name="recipient_name"
                    control={control}
                    render={({ field }) => (
                      <CustomizedTextField
                        {...field}
                        disabled={viewOnly}
                        error={Boolean(errors?.recipient_name)}
                        helperText={errors?.recipient_name?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography sx={{ my: 2, fontWeight: "bold" }}>
            หลักฐานอ้างอิง
          </Typography>
          <Grid container>
            <Grid xs={12} sm={12} md={6} lg={5} xl={5}>
              <Grid container sx={{ alignItems: "center", mb: 1 }}>
                <Grid xs={3}>
                  <Typography>
                    {t("logistic.deliveryOrder.remarkType")}
                  </Typography>
                </Grid>
                <Grid xs={9}>
                  <ControlledServerSideCreatable
                    control={control}
                    name="delivery_reference"
                    documentType="delivery_order"
                    defaultOptions={deliveryRemarkType}
                    error={Boolean(errors && errors?.delivery_reference)}
                    helperText={errors?.delivery_reference?.message}
                    setValue={setValue}
                    viewOnly={viewOnly}
                  />
                </Grid>
                <Grid xs={12} sx={{ mt: 3, mb: viewOnly ? 2 : 0 }}>
                  <Controller
                    key={"delivery_detail"}
                    name={"delivery_detail"}
                    control={control}
                    render={({ field }) => (
                      <CustomizedTextField
                        fullWidth
                        multiline
                        rows={4}
                        error={Boolean(errors["delivery_detail"])}
                        helperText={errors["delivery_detail"]?.message}
                        {...field}
                        disabled={viewOnly}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12} sm={12} md={6} lg={5} xl={5}>
              <Grid container sx={{ alignItems: "center", mb: 1 }}>
                <AttachmentDropzone
                  setValue={setValue}
                  getValues={getValues}
                  valueToSet={"attachment_list"}
                  disabled={viewOnly}
                />
              </Grid>
            </Grid>
          </Grid>
        </CustomizedBox>
      )}
      <ModalUI
        open={confirmation}
        handleClose={() => setConfirmation(false)}
        title={`ยืนยันการปรับสถานะจัดส่งจาก ${mappedStatusToThai(
          status
        )} เป็น ${mappedStatusToThai(delivery_status)}`}
        fullWidth
        maxWidth="xs"
      >
        {delivery_status === "completed" && (
          <DialogContentText>
            หากยืนยันแล้วจะไม่สามารถแก้ไขได้
          </DialogContentText>
        )}
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={() => setConfirmation(false)}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={onSubmit}
          />
        </Box>
      </ModalUI>
    </>
  );
};

export default DeliveryDetails;
