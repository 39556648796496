import { gql } from "graphql-request";

export const CREATE_NEW_USER = gql`
  mutation EmployeeCreate($createInput: CreateEmployeeInput!) {
    employeeCreate(createInput: $createInput) {
      document_id
      first_name
      last_name
      email
      phone
      img_url
      department
      position
      status
      created_date
      last_login_date
      role_list {
        document_id
        name
        description
        permission_list
      }
    }
  }
`;

export const CREATE_NEW_ROLE = gql`
  mutation RoleCreate($createInput: RoleCreateInput!) {
    roleCreate(createInput: $createInput) {
      document_id
      name
      description
      permission_list
      employee_list {
        document_id
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation RoleUpdate(
    $uniqueInput: RoleWhereUniqueInput!
    $updateInput: RoleUpdateInput!
  ) {
    roleUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      document_id
      description
      name
      permission_list
      employee_list {
        document_id
        first_name
        last_name
        email
        phone
        img_url
        department
        position
        status
        created_date
        last_login_date
      }
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation RoleDelete($uniqueInput: RoleWhereUniqueInput!) {
    roleDelete(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation EmployeeUpdate(
    $uniqueInput: EmployeeWhereUniqueInput!
    $updateInput: EmployeeUpdateInput!
  ) {
    employeeUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      document_id
      first_name
      last_name
      email
      phone
      img_url
      department
      position
      status
      created_date
      last_login_date
      role_list {
        document_id
        name
        description
        permission_list
      }
    }
  }
`;

export const DISABLE_USER = gql`
  mutation EmployeeDisable($uniqueInput: EmployeeWhereUniqueInput!) {
    employeeDisable(uniqueInput: $uniqueInput) {
      document_id
    }
  }
`;
