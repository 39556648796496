import styled from "@emotion/styled";
import { Avatar, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { filterParamsOptions } from "../../utils/filterparams";
import {
  unixToDateWithFormat,
  unixToDateTimeWithFormatNoComma,
} from "../../utils/date-converter";
import {
  entryTypeToThai,
  formatNumberWithValidate,
} from "../../utils/dataTransformer";

import CustomizedLetterAvatar from "../../components/Custom/CustomizedLetterAvatar";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const useTraceEntryColumnDef = (isReport, getAllItemGroupLevel1) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState([]);

  const navigateToGoodPage = (type, document_id) => {
    window.open(
      `/inventory/${type}/${encodeURIComponent(document_id)}`,
      "_blank"
    );
  };

  useEffect(() => {
    if (isReport) {
      setColumnDefs([
        {
          field: "scanned_date",
          headerName: "วันที่สแกน",
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
          },
          valueFormatter: (params) =>
            unixToDateTimeWithFormatNoComma(params.value),
        },
        {
          field: "scanned_by",
          headerName: "ผู้สแกน",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "created_date",
          headerName: t("inventory.stockEntry.createdDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
          },
          valueFormatter: (params) => unixToDateWithFormat(params.value),
        },
        {
          field: "reference_document_id",
          headerName: t("inventory.stockEntry.referenceDocument"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          cellRenderer: (params) => {
            const { reference_document_type, reference_document_id } =
              params.data;
            if (!reference_document_type || !reference_document_id) {
              return "-";
            }
            return (
              <StyledLink
                onClick={() =>
                  navigateToGoodPage(
                    reference_document_type,
                    reference_document_id
                  )
                }
              >
                {reference_document_id}
              </StyledLink>
            );
          },
        },
        {
          field: "item_item_group",
          headerName: t("inventory.items.itemGroup") + " " + 1,
          filter: "agSetColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
            values: async (params) => {
              // fetch values from server
              const values = await getAllItemGroupLevel1();
              const formatValues = values.map((group) => group.name);
              params.success(formatValues);
            },
          },
        },
        {
          field: "item_document_id",
          headerName: t("inventory.items.itemId"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_name",
          headerName: t("inventory.items.itemName"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_description",
          headerName: t("inventory.items.itemDescription"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "posted_quantity",
          headerName: t("inventory.list.quantityDifference"),
          sortable: false,
          filter: false,
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        },
        {
          field: "uom_name",
          headerName: t("inventory.unit"),
          filter: false,
          sortable: false,
        },
        {
          field: "reference_document_type",
          headerName: t("inventory.stockEntry.entryDocumentType"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["นำเข้า", "นำออก", "โอนย้าย", "ปรับปรุง"],
          },
          valueFormatter: (params) => entryTypeToThai(params.value),
        },
        {
          field: "document_type",
          headerName: t("inventory.stockEntry.documentType"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "ซื้อ",
              "ผลิต",
              "แปรรูป",
              "รับคืนจากลูกค้า",
              "รับคืนจากผลิต",
              "ขาย",
              "ขายออนไลน์",
              "ผลิตวัตถุดิบ",
              "ผลิตแปรรูป",
              "ผลิตแปรรูป - PT",
              "ส่งคืน",
              "สินค้าเสีย",
              "อื่นๆ",
            ],
          },
        },
        {
          field: "serial_number",
          headerName: t("inventory.serialNumber"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "source_warehouse_name",
          headerName: t("inventory.list.sourceWarehouse"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "source_bin_location_document_id",
          headerName: t("inventory.list.sourceBinLocation"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "destination_warehouse_name",
          headerName: t("inventory.list.destinationWarehouse"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "destination_bin_location_document_id",
          headerName: t("inventory.list.destinationBinLocation"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "lot",
          headerName: t("inventory.list.lotNumber"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
          },
          valueFormatter: (params) => unixToDateWithFormat(params.value),
        },
        {
          field: "remark",
          headerName: t("inventory.remark"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          sortable: false,
        },
        {
          field: "created_by",
          headerName: t("inventory.stockEntry.createdBy"),
          sortable: false,
          filter: "agSetColumnFilter",
          cellRenderer: (params) => {
            if (params.data.created_by_img_url) {
              return (
                <Avatar alt="img_url" src={params.data.created_by_img_url} />
              );
            } else {
              return <CustomizedLetterAvatar name={params.value} />;
            }
          },
        },
      ]);
    }
  }, [getAllItemGroupLevel1, isReport, t]);
  return columnDefs;
};

export default useTraceEntryColumnDef;
