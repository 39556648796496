import moment from "moment";
import { Box, Typography } from "@mui/material";
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import ItemCurrentStockSNTable from "../../../components/Table/DocumentTable/ItemCurrentStockSNTable";

import GlobalService from "../../../services/Global";
import InventoryService from "../../../services/Inventory";

import { getNewItemsCurrentStockSerialNumberReport } from "../../../features/Inventory/Report/report-actions";
import { inventoryReportActions } from "../../../features/Inventory/Report/report-slice";
import useItemCurrentStockSNColumnDef from "../../../hooks/Inventory/useItemCurrentStockSNColumnDef";

import TimeStampReportLayout from "../../../components/UI/TimeStampReportLayout";
import { formatExportQuotes } from "../../../utils/exporter";

const ItemCurrentStockSerialNumberView = () => {
  const { isLoading } = useSelector((state) => state.inventoryReport);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const [filename, setFilename] = useState("");
  const [data, setData] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [fetchTimestamp, setFetchTimestamp] = useState(null);
  const csvInstance = useRef(null);
  const [showTable, setShowTable] = useState(false);
  const [fetching, setFetching] = useState(false);

  const columnDef = useItemCurrentStockSNColumnDef(true);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.itemCurrentStockSerialNumber.index"),
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;

      dispatch(
        getNewItemsCurrentStockSerialNumberReport(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      params.api.onFilterChanged();
      setFilename(`${t("inventory.itemCurrentStockSerialNumber.index")}.csv`);
    },
    [t]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const getFiltered = async () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    const { timestamp, in_queue_timestamp } =
      await GlobalService.getReportViewTimestamp("ItemCurrentStockBySN");
    setLastTimestamp(timestamp);
    setFetchTimestamp(
      in_queue_timestamp ? in_queue_timestamp.timestamp * 1000 : null
    );
  };

  const exportAllDataAsAgGridCsv = async () => {
    try {
      const filterModel = gridRef.current.api.getFilterModel();
      const sortModel = gridRef.current.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      dispatch(
        inventoryReportActions.onLoading("itemCurrentStockSerialNumber")
      );
      const { results: itemCurrentStockBySNReport } =
        await InventoryService.getAllNEWICSSNReport({
          startRow: 0,
          endRow: Math.pow(10, 5),
          filterModel: filterModel,
          sortModel,
        });

      setData(formatExportQuotes(itemCurrentStockBySNReport));
      dispatch(inventoryReportActions.onLoaded("itemCurrentStockSerialNumber"));
      setTimeout(() => {
        if (csvInstance.current) {
          csvInstance.current.link.click();
        }
      });
      enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error exporting CSV:", error);
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const columnDefHeader = columnDef.map((col) => ({
    label: col.headerName,
    key: col.field,
  }));

  const generateReportHandler = async () => {
    setFetching(true);
    const { status, timestamp } = await GlobalService.generateReport(
      "ItemCurrentStockBySN"
    );
    setTimeout(() => {
      setFetching(false);
    }, [5000]);

    if (status === "success") {
      setFetchTimestamp(timestamp);
      enqueueSnackbar("ส่งคำขอสำเร็จ กรุณารอสักครู่", {
        variant: "success",
      });
    } else if (status === "error") {
      enqueueSnackbar(
        `ระบบกำลังดำเนินการ กรุณารอสักครู่\n(ขอล่าสุด ${moment(
          fetchTimestamp
        ).fromNow()})`,
        {
          variant: "warning",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
  };

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          {t("inventory.itemCurrentStockSerialNumber.index")}
        </Typography>
        <Box>
          <CustomizedButton
            title="นำออกข้อมูล"
            variant="contained"
            onClick={exportAllDataAsAgGridCsv}
            disabled={isLoading.itemCurrentStockSerialNumber}
          />
          <CSVLink
            headers={columnDefHeader}
            data={data}
            filename={filename}
            ref={csvInstance}
          />
        </Box>
      </Box>
      <Box display={"flex"} my={2} gap={2} justifyContent="flex-end">
        <CustomizedButton
          title={t("button.callData")}
          variant="contained"
          onClick={getFiltered}
          disabled={isLoading.itemCurrentStockSerialNumber}
        />
        <CustomizedButton
          title={t("button.resetFilter")}
          variant="outlined"
          onClick={onFilterReset}
        />
      </Box>
      {showTable && (
        <TimeStampReportLayout
          fetching={fetching}
          generateReportHandler={generateReportHandler}
          lastTimestamp={lastTimestamp}
          fetchTimestamp={fetchTimestamp}
        />
      )}
      {showTable && (
        <ItemCurrentStockSNTable
          gridRef={gridRef}
          onGridReady={onGridReady}
          isReport
        />
      )}
    </>
  );
};

export default ItemCurrentStockSerialNumberView;
