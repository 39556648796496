import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledStatus = styled(Box)(({ bgcolor, variant, minWidth }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "3px",
  backgroundColor: bgcolor,
  padding: variant !== "logStatus" && ".25rem 0",
  minWidth: variant !== "logStatus" ? (!minWidth ? 120 : minWidth) : 95,
}));

const CustomizedStatus = ({ status, variant, sx, minWidth }) => {
  const { t } = useTranslation();
  const renderStatus = (status) => {
    switch (status) {
      case "draft":
      case "ร่าง":
        return (
          <StyledStatus bgcolor="#E6E6E6" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{ ...sx, color: "#333333" }}
            >
              {t("status.draft")}
            </Typography>
          </StyledStatus>
        );
      case "isScan":
        return (
          <StyledStatus minWidth={minWidth} bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{ ...sx, color: "#246527" }}
            >
              สแกนแล้ว
            </Typography>
          </StyledStatus>
        );
      case "notScan":
        return (
          <StyledStatus minWidth={minWidth} bgcolor="#FCE69F" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              รอสแกน
            </Typography>
          </StyledStatus>
        );
      case "finished":
      case "เสร็จสิ้น":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.finished")}
            </Typography>
          </StyledStatus>
        );
      case "ready":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.ready")}
            </Typography>
          </StyledStatus>
        );
      case "fullyPaid":
      case "fully_paid":
      case "ชำระแล้ว":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.fullyPaid")}
            </Typography>
          </StyledStatus>
        );
      case "active":
      case "ใช้งาน":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
                textAlign: "center",
                width: 60,
              }}
            >
              {t("status.active")}
            </Typography>
          </StyledStatus>
        );
      case "inactive":
      case "ไม่ใช้งาน":
        return (
          <StyledStatus bgcolor="#FFA396" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#B54839",
                textAlign: "center",
                width: 60,
              }}
            >
              {t("status.inActive")}
            </Typography>
          </StyledStatus>
        );
      case "สำเร็จ":
      case "completed":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.completed")}
            </Typography>
          </StyledStatus>
        );
      case "สั่งซื้อแล้ว":
      case "fullyOrdered":
      case "fully_ordered":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.fullyOrdered")}
            </Typography>
          </StyledStatus>
        );
      case "นำเข้าแล้ว":
      case "fullyImported":
      case "fully_imported":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.fullyImported")}
            </Typography>
          </StyledStatus>
        );
      case "ชำระแล้วบางส่วน":
      case "partiallyPaid":
      case "partially_paid":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.partiallyPaid")}
            </Typography>
          </StyledStatus>
        );
      case "สั่งซื้อแล้วบางส่วน":
      case "partiallyOrdered":
      case "partially_ordered":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.partiallyOrdered")}
            </Typography>
          </StyledStatus>
        );
      case "นำเข้าแล้วบางส่วน":
      case "partiallyImported":
      case "partially_imported":
        return (
          <StyledStatus bgcolor="#C8EDDC" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#246527",
              }}
            >
              {t("status.partiallyImported")}
            </Typography>
          </StyledStatus>
        );
      case "ตอบรับแล้ว":
      case "accepted":
        return (
          <StyledStatus bgcolor="#C4DBFD" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#1F5BB2",
              }}
            >
              {t("status.accepted")}
            </Typography>
          </StyledStatus>
        );
      case "รออนุมัติ":
      case "PENDING":
      case "waitApprove":
      case "wait_approve":
        return (
          <StyledStatus bgcolor="#FCE69F" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.waitApprove")}
            </Typography>
          </StyledStatus>
        );
      case "APPROVED":
      case "approved":
      case "อนุมัติแล้ว":
        return (
          <StyledStatus bgcolor="#E5F0FF" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#1F5BB2",
              }}
            >
              {t("status.approved")}
            </Typography>
          </StyledStatus>
        );
      case "notApproved":
      case "DECLINED":
      case "ไม่อนุมัติ":
      case "not_approved":
        return (
          <StyledStatus bgcolor="#FEECEB" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#B54839",
              }}
            >
              {t("status.notApproved")}
            </Typography>
          </StyledStatus>
        );
      case "ไม่สำเร็จ":
      case "notCompleted":
      case "not_completed":
        return (
          <StyledStatus bgcolor="#FEECEB" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#B54839",
              }}
            >
              {t("status.notCompleted")}
            </Typography>
          </StyledStatus>
        );
      case "รอตอบรับ":
      case "waitAccept":
      case "wait_accept":
        return (
          <StyledStatus bgcolor="#FCE69F" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.waitAccept")}
            </Typography>
          </StyledStatus>
        );
      case "เกินเวลา":
      case "late":
      case "expired":
        return (
          <StyledStatus bgcolor="#FFF2EB" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#CC6200",
              }}
            >
              {t("status.expired")}
            </Typography>
          </StyledStatus>
        );
      case "ยกเลิก":
      case "cancelled":
      case "canceled":
        return (
          <StyledStatus bgcolor="#D3D3D3" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                color: "#FFFFFF",
              }}
            >
              {t("status.cancelled")}
            </Typography>
          </StyledStatus>
        );
      case "รอชำระ":
      case "waitPayment":
      case "wait_payment":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.waitPayment")}
            </Typography>
          </StyledStatus>
        );
      case "รอส่ง":
      case "รอจัดส่ง":
      case "waitDeliver":
      case "wait_deliver":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.waitDeliver")}
            </Typography>
          </StyledStatus>
        );
      case "กำลังจัดส่ง":
      case "delivering":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.delivering")}
            </Typography>
          </StyledStatus>
        );
      //wait for data dict to be update more status
      case "mainBom":
      case "is_main_bom":
        return (
          <StyledStatus bgcolor="#E5F0FF" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#1F5BB2",
              }}
            >
              {t("status.mainBom")}
            </Typography>
          </StyledStatus>
        );
      case "inProgress":
      case "in_progress":
      case "กำลังผลิต":
        return (
          <StyledStatus bgcolor="#E5F0FF" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#1F5BB2",
              }}
            >
              {t("status.manufacturing")}
            </Typography>
          </StyledStatus>
        );
      case "pendingManu":
      case "รอผลิต":
      case "pending_manu":
        return (
          <StyledStatus bgcolor="#FFF1C5" variant={variant}>
            <Typography
              variant={variant || "button"}
              sx={{
                ...sx,
                color: "#C3762E",
              }}
            >
              {t("status.pendingManu")}
            </Typography>
          </StyledStatus>
        );
      default:
        return;
    }
  };

  return renderStatus(status);
};

export default CustomizedStatus;
