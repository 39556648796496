import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// import PDFFooter from "../../../../components/UI/PDFFooter";
import PDFHeader from "../../../../components/UI/PDFHeader";
import DeliveryTripPDFInfo from "./Info";
import { useReactToPrint } from "react-to-print";
import PDFSigner from "../../../../components/UI/PDFSigner";
import { useDispatch, useSelector } from "react-redux";
import { getDeliveryTripById } from "../../../../features/Logistic/DeliveryTrip/delivery-trip-actions";
import PDFFooter from "../../../../components/UI/PDFFooter";
import DeliveryShortPdf from "../../../../components/Table/Pdf/DeliveryShortPdf";
// import DeliveryGroupPdf from "../../../../components/Table/Pdf/DeliveryGroupPdf";
import CustomizedScrollbar from "../../../../components/Custom/CustomizedScrollbar";
import { deliveryTripActions } from "../../../../features/Logistic/DeliveryTrip/delivery-trip-slice";
import DeliveryTripTablePdf from "../../../../components/Table/Pdf/DeliveryTripTablePdf";

const DeliveryTripPDF = () => {
  const dispatch = useDispatch();
  const { deliveryTrip } = useSelector((state) => state.deliveryTrip);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: deliveryTrip?.document_id,
  });
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getDeliveryTripById({
        document_id: id,
      })
    );
    return () => {
      dispatch(deliveryTripActions.resetDeliveryTrip());
    };
  }, [dispatch, id]);

  const flattedItemList = [];

  deliveryTrip?.delivery_order_list?.forEach((field) => {
    const formatField =
      field?.item_list?.map((item) => ({
        document_id: field.document_id,
        ...item,
      })) ?? [];
    flattedItemList.push(...formatField);
  });

  const formatDeliveryTripShort = deliveryTrip?.delivery_order_list.map(
    (deliveryTripShort) => ({
      ...deliveryTripShort,
      contact_document_id: deliveryTripShort?.contact?.document_id,
      contact: `${deliveryTripShort?.contact?.contact_name_1 ?? ""}  ${
        deliveryTripShort?.contact?.contact_name_2 ?? ""
      }`,
    })
  );

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">รอบจัดส่ง/Delivery Trip</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>
      <Box sx={{ m: "10px auto", width: "fit-content" }}>
        <div className="page">
          <Box
            sx={{
              width: "210mm",
              height: "297mm",
              overflowY: "auto",
              position: "relative",
              border: "1px solid #eee",
              borderRadius: "5px",
            }}
          >
            <CustomizedScrollbar
              sx={{
                height: "100%",
              }}
            >
              <Box
                sx={{
                  p: 3,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                ref={componentRef}
              >
                <Box>
                  <PDFHeader documentType="รอบจัดส่ง/Delivery Trip" />
                  <Divider />
                  <DeliveryTripPDFInfo deliveryTrip={deliveryTrip} />
                  <Divider />
                  <Typography
                    sx={{
                      fontSize: 12,
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      fontWeight: 600,
                      color: "#333333",
                      my: 2,
                    }}
                  >
                    ใบส่งของ
                  </Typography>
                  <DeliveryShortPdf deliveryList={formatDeliveryTripShort} />
                  <Typography
                    sx={{
                      fontSize: 12,
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      fontWeight: 600,
                      color: "#333333",
                      my: 2,
                    }}
                  >
                    สินค้า
                  </Typography>
                  <DeliveryTripTablePdf
                    itemList={deliveryTrip?.item_list || []}
                  />
                  {/* <DeliveryGroupPdf
                    deliveryList={deliveryTrip?.delivery_order_list}
                    vehicle={deliveryTrip?.vehicle}
                  /> */}
                  <PDFFooter data={deliveryTrip} documentType="delivery_trip" />
                  <Divider sx={{ my: 2 }} />
                </Box>

                <Box sx={{ pb: 2 }}>
                  <PDFSigner documentType="delivery_order" />
                </Box>
              </Box>
            </CustomizedScrollbar>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default DeliveryTripPDF;
