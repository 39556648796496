import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Typography, Box, IconButton, Menu, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomizedTextField from "../Custom/CustomizedTextField";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const thumb = {
  display: "inline-flex",
  borderRadius: 10,
  border: "1px solid #eaeaea",
  marginRight: 8,
  width: 72,
  height: 70,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
};

const thumbPDF = {
  display: "inline-flex",
  borderRadius: "50%",
  // border: "1px solid #eaeaea",
  marginRight: 8,
  backgroundColor: "#F5F5F5",
  width: 72,
  height: 70,
  boxSizing: "border-box",
};

const thumbInnerPDF = {
  display: "flex",
  minWidth: 20,
  overflow: "hidden",
  margin: "auto",
};

const img = {
  width: 70,
  height: "auto",
  borderRadius: 10,
  objectFit: "cover",
};

const FilePreviewActivityLogs = ({
  control,
  errors,
  onRemove,
  index,
  fileName,
  manualRevoke,
  onUpdate,
  viewOnly,
  file,
}) => {
  const { t } = useTranslation();
  const [anchorPos, setAnchorPos] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const showActions = Boolean(anchorPos);

  const openActionsHandler = (event) => {
    if (viewOnly) {
      return;
    }
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const startEditingHandler = () => {
    closeActionsHandler();
    setIsEditing(true);
  };

  const finishEditingHandler = () => {
    onUpdate(index);
    setIsEditing(false);
  };

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        <MenuItem onClick={() => onRemove(index)}>
          {t("contact.utility.delete")}
        </MenuItem>
        <MenuItem onClick={startEditingHandler}>
          {t("contact.utility.name_change")}
        </MenuItem>
      </Menu>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {file.type.split("/")[0] === "image" ? (
            <div style={thumb}>
              <div style={thumbInner}>
                <img
                  alt={file?.name || file}
                  src={file?.preview || file}
                  style={img}
                  // Revoke data uri after image is loaded
                  onLoad={() =>
                    !manualRevoke
                      ? () => {
                          URL.revokeObjectURL(file.preview);
                        }
                      : () => {}
                  }
                />
              </div>
            </div>
          ) : (
            <div style={thumbPDF}>
              <div style={thumbInnerPDF}>
                <DescriptionOutlinedIcon sx={{ width: 35, height: 35 }} />
              </div>
            </div>
          )}
          {isEditing ? (
            <Controller
              name={`files.${index}.name`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.files?.[index]?.name)}
                  helperText={errors.files?.[index]?.name?.message}
                  {...field}
                  disabled={viewOnly}
                />
              )}
            />
          ) : (
            <Typography>{fileName}</Typography>
          )}
        </Box>
        {isEditing && (
          <IconButton
            onClick={finishEditingHandler}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CheckIcon />
          </IconButton>
        )}
        {!viewOnly && !isEditing && (
          <IconButton
            onClick={openActionsHandler}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default FilePreviewActivityLogs;
