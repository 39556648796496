import { InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import {
  formatNumber,
  localeStringToFloat,
  roundDecimal,
} from "../../../../utils/dataTransformer";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";

const withholdingTaxOption = [
  { value: "ยังไม่ระบุ", label: "ยังไม่ระบุ" },
  { value: "ไม่มี", label: "ไม่มี" },
  { value: "0.75", label: "0.75 %" },
  { value: "1", label: "1 %" },
  { value: "1.5", label: "1.5 %" },
  { value: "2", label: "2 %" },
  { value: "3", label: "3 %" },
  { value: "5", label: "5 %" },
  { value: "10", label: "10 %" },
  { value: "15", label: "15 %" },
];

const WithholdingTax = ({ control, params, getValues, setValue, disabled }) => {
  const [withholdingTaxAmount, setWithHoldingTaxAmount] = useState();
  const additional_discount = useWatch({
    control,
    name: "additional_discount",
  });

  const additional_discount_type = useWatch({
    control,
    name: "additional_discount_type",
  });

  const { pre_vat_amount } = useWatch({
    control,
    name: "summary",
  });

  const actualAdditionalDiscount =
    additional_discount_type === "percent"
      ? pre_vat_amount * (additional_discount / 100)
      : additional_discount;

  const vat_type = useWatch({
    control,
    name: `item_list[${params.node.rowIndex}].vat_type`,
  });

  const item_pre_vat_amount = useWatch({
    control,
    name: `item_list[${params.node.rowIndex}].pre_vat_amount`,
  });

  const withholding_tax_type = useWatch({
    control,
    name: `item_list[${params.node.rowIndex}].withholding_tax.type`,
  });

  const item_additional_discount = roundDecimal(
    roundDecimal(actualAdditionalDiscount) *
      (roundDecimal(item_pre_vat_amount) /
        roundDecimal(localeStringToFloat(pre_vat_amount))) || 0
  );

  const price_vat_type = useWatch({
    control,
    name: "price_vat_type",
  });

  useEffect(() => {
    if (price_vat_type === "excluded_vat") {
      setWithHoldingTaxAmount(
        ((roundDecimal(item_pre_vat_amount) -
          roundDecimal(item_additional_discount)) *
          parseFloat(
            withholding_tax_type === "ยังไม่ระบุ" ||
              withholding_tax_type === "ไม่มี" ||
              withholding_tax_type === "" ||
              withholding_tax_type === undefined
              ? 0
              : withholding_tax_type
          )) /
          100
      );
    } else {
      if (vat_type === "7") {
        setWithHoldingTaxAmount(
          (((roundDecimal(item_pre_vat_amount) -
            roundDecimal(item_additional_discount)) /
            1.07) *
            parseFloat(
              withholding_tax_type === "ยังไม่ระบุ" ||
                withholding_tax_type === "ไม่มี" ||
                withholding_tax_type === "" ||
                withholding_tax_type === undefined
                ? 0
                : withholding_tax_type
            )) /
            100
        );
      } else {
        setWithHoldingTaxAmount(
          ((roundDecimal(item_pre_vat_amount) -
            roundDecimal(item_additional_discount)) *
            parseFloat(
              withholding_tax_type === "ยังไม่ระบุ" ||
                withholding_tax_type === "ไม่มี" ||
                withholding_tax_type === "" ||
                withholding_tax_type === undefined
                ? 0
                : withholding_tax_type
            )) /
            100
        );
      }
    }
  }, [
    item_additional_discount,
    item_pre_vat_amount,
    price_vat_type,
    vat_type,
    withholding_tax_type,
  ]);

  return (
    <Controller
      control={control}
      name={`item_list[${params.node.rowIndex}].withholding_tax`}
      render={({ field }) => (
        <CustomizedCreatable
          {...field}
          disabled={disabled}
          options={withholdingTaxOption}
          onChange={(_, option) => {
            const rowNode = params.api.getRowNode(
              params.data.uid + (params.data.ref_document_id ?? "")
            );

            if (!option) {
              return field.onChange("");
            }
            if (option.value) {
              const item_additional_discount =
                roundDecimal(actualAdditionalDiscount) *
                (roundDecimal(
                  getValues(`item_list[${params.node.rowIndex}].pre_vat_amount`)
                ) /
                  roundDecimal(localeStringToFloat(pre_vat_amount)));

              const withholding_tax_amount =
                roundDecimal(
                  (roundDecimal(
                    getValues(
                      `item_list[${params.node.rowIndex}].pre_vat_amount`
                    )
                  ) -
                    roundDecimal(item_additional_discount)) *
                    parseFloat(
                      option.value === "ยังไม่ระบุ" ||
                        option.value === "ไม่มี" ||
                        option.value === ""
                        ? 0
                        : option.value
                    )
                ) / 100;
              setValue(`item_list[${params.node.rowIndex}].withholding_tax`, {
                type: option.value,
                amount: withholding_tax_amount,
              });
              rowNode.setDataValue("withholding_tax", {
                type: option.value,
                amount: withholding_tax_amount,
              });

              return field.onChange({
                type: option.value,
                amount: withholding_tax_amount,
              });
            }
            if (option.inputValue) {
              const withholding_tax_amount =
                roundDecimal(
                  roundDecimal(
                    getValues(
                      `item_list[${params.node.rowIndex}].pre_vat_amount`
                    )
                  ) * parseFloat(option.inputValue)
                ) / 100;
              setValue(`item_list[${params.node.rowIndex}].withholding_tax`, {
                type: option.inputValue,
                amount: withholding_tax_amount,
              });
              rowNode.setDataValue("withholding_tax", {
                type: option.inputValue,
                amount: withholding_tax_amount,
              });
              return field.onChange({
                type: option.inputValue,
                amount: withholding_tax_amount,
              });
            }
            return field.onChange(option);
          }}
          helperText={formatNumber(withholdingTaxAmount)}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      )}
    />
  );
};

export default WithholdingTax;
