export const initialState = {
  isLoading: {
    allCreditNotes: false,
    creditNote: false,
  },
  allCreditNotes: [],
  isHaveApproval: false,
  creditNote: {
    target_status: null,
    render_status: null,
    id: null,
    document_id: "",
    external_ref_id: "",
    issue_date: null,
    due_date: null,
    credit_day: "",
    contact_document_id: "",
    pre_vat_amount: 0,
    shipping_cost: 0,
    additional_discount: 0,
    vat_exempted_amount: 0,
    vat_0_amount: 0,
    vat_7_amount: 0,
    vat_amount: 0,
    net_amount: 0,
    withholding_tax_amount: 0,
    total_amount: 0,
    created_date: null,
    updated_date: null,
    creator_document_id: "",
    accepted_date: null,
    accepted_remark: null,
    customer: {
      email: "",
      phone: "",
      fax: "",
      billing_address: {
        address: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      },
      delivery_address: {
        address_type: "",
        is_same_as_default_address: false,
        address_contact_name: "",
        address_contact_phone: "",
        address: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      },
    },
    employee_list: [],
    attachment_list: [],
    item_list: [],
    template_remark_id: "",
    remark: "",
  },
  approvalList: [],
  error: null,
};
