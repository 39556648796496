import moment from "moment";
import * as Yup from "yup";
// import SalesService from "../../../../services/Sales";

export const quotationSchema = {
  id: null,
  document_id: "",
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  due_date: moment().add(730, "days"),
  credit_day: "",
  employee_list: [],
};

export const saleOrderSchema = {
  id: null,
  document_id: "",
  reference_document_id: null,
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  due_date: moment(),
  delivery_date: moment(),
  credit_day: "",
  employee_list: [],
};

export const depositInvoiceSchema = {
  id: null,
  document_id: "",
  reference_document_id: null,
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  credit_day: "",
  employee_list: [],
};

export const saleInvoiceSchema = {
  id: null,
  document_id: "",
  reference_document_id: null,
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  due_date: moment().add(7, "days"),
  credit_day: "",
  employee_list: [],
};

export const paymentReceiptSchema = {
  id: null,
  document_id: "",
  reference_document_id: null,
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  credit_day: "",
  employee_list: [],
};

export const saleReturnSchema = {
  id: null,
  document_id: "",
  reference_document_id: null,
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  credit_day: "",
  employee_list: [],
};

export const creditNoteSchema = {
  id: null,
  document_id: "",
  reference_document_id: null,
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  credit_day: "",
  employee_list: [],
};

export const deliveryOrderSchema = {
  id: null,
  document_id: "",
  reference_document_id: null,
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  delivery_date: moment().add(7, "days"),
  employee_list: [],
};

export const deliveryTripSchema = {
  id: null,
  document_id: "",
  reference_document_id: null,
  external_ref_id: "",
  created_date: moment(),
  issue_date: moment(),
  delivery_date: moment().add(7, "days"),
  employee_list: [],
};

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

export const quotationValidation = Yup.object().shape({
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  due_date: Yup.date()
    .when("issue_date", (issue_date, schema) => {
      if (issue_date) {
        return schema.min(
          moment(issue_date).startOf("day").toDate(),
          "กรุณาระบุวันที่ที่มากกว่าหรือเท่ากับวันที่ออกเอกสาร"
        );
      } else {
        return schema;
      }
    })
    .required("กรุณาระบุวันที่ใช้ได้ถึง")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const saleOrderValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  due_date: Yup.date()
    .when("issue_date", (issue_date, schema) => {
      if (issue_date) {
        return schema.min(
          moment(issue_date).startOf("day").toDate(),
          "กรุณาระบุวันที่ที่มากกว่าหรือเท่ากับวันที่ออกเอกสาร"
        );
      } else {
        return schema;
      }
    })
    .required("กรุณาระบุวันที่ใช้ได้ถึง")
    .nullable()
    .transform((value) => dateIsValid(value)),
  delivery_date: Yup.date()
    .when("issue_date", (issue_date, schema) => {
      if (issue_date) {
        return schema.min(
          moment(issue_date).startOf("day").toDate(),
          "กรุณาระบุวันที่ที่มากกว่าหรือเท่ากับวันที่ออกเอกสาร"
        );
      } else {
        return schema;
      }
    })
    .required("กรุณาระบุวันกำหนดส่งของ")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const depositInvoiceValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const saleInvoiceValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  due_date: Yup.date()
    .when(
      "issue_date",
      (issue_date, schema) =>
        issue_date &&
        schema.min(
          moment(issue_date).startOf("day").toDate(),
          "กรุณาระบุวันที่ที่มากกว่าหรือเท่ากับวันที่ออกเอกสาร"
        )
    )
    .required("กรุณาระบุวันที่ใช้ได้ถึง")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const paymentReceiptValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const saleReturnValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const creditNoteValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
});

export const deliveryOrderValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  delivery_date: Yup.date()
    .when("issue_date", (issue_date, schema) => {
      if (issue_date) {
        return schema.min(
          moment(issue_date).startOf("day").toDate(),
          "กรุณาระบุวันที่ที่มากกว่าหรือเท่ากับวันที่ออกเอกสาร"
        );
      } else {
        return schema;
      }
    })
    .required("กรุณาระบุวันกำหนดส่งของ")
    .nullable()
    .transform((value) => dateIsValid(value)),
});

export const deliveryTripValidation = Yup.object().shape({
  document_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .nullable()
    .transform((value) => dateIsValid(value)),
  delivery_date: Yup.date()
    .when("issue_date", (issue_date, schema) => {
      if (issue_date) {
        return schema.min(
          moment(issue_date).startOf("day").toDate(),
          "กรุณาระบุวันที่ที่มากกว่าหรือเท่ากับวันที่ออกเอกสาร"
        );
      } else {
        return schema;
      }
    })
    .required("กรุณาระบุวันกำหนดส่งของ")
    .nullable()
    .transform((value) => dateIsValid(value)),
});

export const confirmationValidation = Yup.object().shape({
  external_ref_id_confirmation: Yup.string().required(
    "กรุณาระบุหมายเลขอ้างอิง"
  ),
});
