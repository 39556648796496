import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../../hooks/use-auth";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import CheckedEmployeeModal from "../../../../Table/CheckedContactModal";
import RelatedEmployeeIcon from "../../../Purchase/Header/RelatedEmployeeIcon";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

const Employee = ({ control, setValue, getValues, disabled }) => {
  const [showSelectEmployees, setShowSelectEmployees] = useState(false);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState([]);

  const { pathname } = useLocation();
  const { user } = useAuth();

  const watchRelatedEmployees = useWatch({
    control,
    name: "employee_list",
  });

  const watchCreatorDocumentId = useWatch({
    control,
    name: "creator_document_id",
  });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "employee_list",
  });

  const removeEmployee = (index) => {
    remove(index);
    setEmployeeIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setEmployeeIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const renderAvatars = () => {
    return fields.map((item, index) => {
      return (
        <RelatedEmployeeIcon
          key={item.id}
          first_name={item.first_name}
          last_name={item.last_name}
          index={index}
          avatar={item.img_url}
          remove={removeEmployee}
          viewOnly={disabled}
        />
      );
    });
  };

  const renderCreatorAvatar = () => {
    if (watchCreatorDocumentId) {
      return (
        <RelatedEmployeeIcon
          isMain={watchCreatedBy && fields.length > 0}
          first_name={watchCreatedBy?.first_name}
          last_name={watchCreatedBy?.last_name}
          avatar={watchCreatedBy?.img_url}
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (!watchCreatorDocumentId) {
      if (user) {
        setValue("creator_document_id", user.document_id);
        setValue("created_by", {
          document_id: user.document_id,
          first_name: user.first_name,
          last_name: user.last_name,
          img_url: user.img_url,
        });
      }
    }
  }, [watchCreatorDocumentId, setValue, user]);

  useEffect(() => {
    if (
      pathname.split("/").at(-1) === "add" &&
      watchRelatedEmployees &&
      watchRelatedEmployees.length === 0
    ) {
      // setValue("employee_list", [{ ...user }]);
      if (user) {
        setEmployeeIds([user.document_id]);
        setEmployeeIdsSnapshot([user.document_id]);
      }
    } else {
      if (watchCreatorDocumentId) {
        const mappedEmployeeIds =
          getValues("employee_list")?.map(
            (employee) => employee?.document_id
          ) ?? [];
        setEmployeeIds([watchCreatorDocumentId, ...mappedEmployeeIds]);
        setEmployeeIdsSnapshot([watchCreatorDocumentId, ...mappedEmployeeIds]);
      }
    }
  }, [
    pathname,
    watchRelatedEmployees,
    watchCreatorDocumentId,
    user,
    setValue,
    getValues,
  ]);

  const finishEmployeesSelect = (data) => {
    // filter out employees not selected in current modal session
    let filteredEmployees = fields.filter((employee) =>
      employeeIds.includes(employee.document_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.document_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.document_id)) {
        filteredEmployees.push(employee);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        employeeIds.indexOf(a.document_id) - employeeIds.indexOf(b.document_id)
      );
    });
    setValue(
      "employee_list",
      filteredEmployees.filter(
        (employee) => employee.document_id !== watchCreatorDocumentId
      )
    );
    setShowSelectEmployees(false);
  };

  const openEmployeeTable = () => {
    setShowSelectEmployees(true);
  };

  const closeEmployeeTable = () => {
    setShowSelectEmployees(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        {renderCreatorAvatar()}
        {renderAvatars()}
        {!disabled && (
          <Grid item>
            <CustomizedTooltip title="เพิ่มผู้เกี่ยวข้อง" enterNextDelay={200}>
              <IconButton
                onClick={openEmployeeTable}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ControlPointOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          </Grid>
        )}
      </Grid>
      <CheckedEmployeeModal
        showSelectEmployees={showSelectEmployees}
        closeEmployeeTable={closeEmployeeTable}
        finishEmployeesSelect={finishEmployeesSelect}
        watchCreatorDocumentId={watchCreatorDocumentId}
        employeeIds={employeeIds}
        setEmployeeIds={setEmployeeIds}
        employeeIdsSnapshot={employeeIdsSnapshot}
        setEmployeeIdsSnapshot={setEmployeeIdsSnapshot}
      />
    </>
  );
};

export default Employee;
