import { Typography } from "@mui/material";
import React, { useCallback } from "react";
import BomPdf from "../../../../components/Table/Pdf/BomPdf";
import WorkOrderPdf from "../../../../components/Table/Pdf/WorkOrderPdf";

const Table = ({ manufactureOrder }) => {
  const defaultSx = {
    fontSize: 12,
    lineHeight: "10px",
    letterSpacing: "0.15px",
    fontWeight: 600,
    color: "#333333",
    flex: 1,
    my: 2,
  };

  const ingredientQtyCalculation = useCallback(() => {
    if (manufactureOrder) {
      if (manufactureOrder.bom_detail) {
        const { bom_detail } = manufactureOrder;
        const { ingredient_list, mfg_qty } = bom_detail;
        const res = [];
        if (mfg_qty && mfg_qty > 0) {
          const currentProductionQty =
            parseFloat(manufactureOrder.production_qty) ?? 0;
          const ratio = currentProductionQty / mfg_qty;

          ingredient_list.forEach((ingredient) => {
            const foundIndex = manufactureOrder.ingredient_list.findIndex(
              (bomIngredient) => bomIngredient.uid === ingredient.uid
            );

            if (foundIndex !== -1) {
              const qty = ratio * ingredient.qty;
              return res.push(qty);
            }
          });
          return res;
        }
      }
    }
  }, [manufactureOrder]);

  return (
    <>
      <Typography sx={defaultSx}>ส่วนประกอบ</Typography>
      <BomPdf
        itemList={manufactureOrder.ingredient_list}
        qty={ingredientQtyCalculation()}
      />
      <Typography sx={defaultSx}>การทำงาน</Typography>
      <WorkOrderPdf itemList={manufactureOrder.work_order_list} />
    </>
  );
};

export default Table;
