import { locationActions } from "./location-slice";
// import { showSnackbar } from "../../UI/ui-actions";
import SettingService from "../../../services/Setting/index";

export const getAllLocation = (input, params) => async (dispatch) => {
  dispatch(locationActions.onLoading("allLocation"));
  try {
    const allLocation = await SettingService.getAllLocation(input);
    dispatch(locationActions.loadedAllLocation(allLocation));
  } catch (err) {
    dispatch(locationActions.rejectedActions({ ...err, name: "allLocation" }));
  }
};

export const createWarehouse = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(locationActions.onLoading("allLocation"));
  try {
    await SettingService.postCreateWarehouse(input);
    enqueueSnackbar("สร้างสถานที่สำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    enqueueSnackbar("สร้างสถานที่ไม่สำเร็จ", {
      variant: "error",
    });
    dispatch(locationActions.rejectedActions({ ...err, name: "allLocation" }));
  }
};

export const updateWarehouse = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(locationActions.onLoading("allLocation"));
  try {
    await SettingService.postUpdateWarehouse(input);
    enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
      variant: "error",
    });
    dispatch(locationActions.rejectedActions({ ...err, name: "allLocation" }));
  }
};

export const deleteWarehouse = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(locationActions.onLoading("allLocation"));
  try {
    const allLocation = await SettingService.postDeleteWarehouse(input);
    dispatch(locationActions.loadedAllLocation(allLocation));
    enqueueSnackbar("ลบสถานที่สำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    enqueueSnackbar("ลบสถานที่ไม่สำเร็จ", {
      variant: "error",
    });
    dispatch(locationActions.rejectedActions({ ...err, name: "allLocation" }));
  }
};

export const createLocationLv1 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("selectedLocation"));
    try {
      await SettingService.postCreateWarehouseLv1(input);
      enqueueSnackbar("สร้างสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("สร้างสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "selectedLocation" })
      );
    }
  };

export const updateLocationLv1 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("selectedLocation"));
    try {
      await SettingService.postUpdateWarehouseLv1(input);
      enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("แก้ไขสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "selectedLocation" })
      );
    }
  };

export const deleteLocationLv1 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("selectedLocation"));
    try {
      await SettingService.postDeleteWarehouseLv1(input);
      enqueueSnackbar("ลบสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ลบสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "selectedLocation" })
      );
    }
  };

export const createLocationLv2 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("selectedLocation"));
    try {
      await SettingService.postCreateWarehouseLv2(input);
      enqueueSnackbar("สร้างสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("สร้างสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "selectedLocation" })
      );
    }
  };

export const updateLocationLv2 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("selectedLocation"));
    try {
      await SettingService.postUpdateWarehouseLv2(input);
      enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("แก้ไขสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "selectedLocation" })
      );
    }
  };

export const deleteLocationLv2 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("selectedLocation"));
    try {
      await SettingService.postDeleteWarehouseLv2(input);
      enqueueSnackbar("ลบสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ลบสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "selectedLocation" })
      );
    }
  };

export const createLocationLv3 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("selectedLocation"));
    try {
      await SettingService.postCreateWarehouseLv3(input);
      enqueueSnackbar("สร้างสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("สร้างสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "selectedLocation" })
      );
    }
  };

export const updateLocationLv3 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("selectedLocation"));
    try {
      await SettingService.postUpdateWarehouseLv3(input);
      enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("แก้ไขสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "selectedLocation" })
      );
    }
  };

export const deleteLocationLv3 =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("selectedLocation"));
    try {
      await SettingService.postDeleteWarehouseLv3(input);
      enqueueSnackbar("ลบสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ลบสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "selectedLocation" })
      );
    }
  };

export const createBinLocation =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("allLocation"));
    try {
      await SettingService.postCreateBinLocation(input);
      enqueueSnackbar("สร้างสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("สร้างสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "allLocation" })
      );
    }
  };

export const updateBinLocation =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("allLocation"));
    try {
      await SettingService.postUpdateBinLocation(input);
      enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("แก้ไขสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "allLocation" })
      );
    }
  };

export const deleteBinLocation =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(locationActions.onLoading("allLocation"));
    try {
      await SettingService.postDeleteBinLocation(input);
      enqueueSnackbar("ลบสถานที่สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ลบสถานที่ไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(
        locationActions.rejectedActions({ ...err, name: "allLocation" })
      );
    }
  };
