import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import GoodsIssueTab from "./GoodsIssueTab";
import GoodsReceiveTab from "./GoodsReceiveTab";

const ManufactureOrderContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);

  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.order.index"),
      to: "/manufacture/order",
    },
    {
      name: id ?? `สร้าง${t("manufacture.order.index")}`,
    },
  ];

  const tabs = [
    {
      label: t("purchase.documentInfo"),
      path: `${pathname}`,
    },
    {
      label: "ใบ" + t("inventory.issue.index"),
      path: `${pathname}?tab=goods-issue`,
    },
    {
      label: "ใบ" + t("inventory.receive.index"),
      path: `${pathname}?tab=goods-receive`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "goods-receive":
        setCurrentTab(pathname + "?tab=goods-receive");
        break;
      case "goods-issue":
        setCurrentTab(pathname + "?tab=goods-issue");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab) => {
    switch (tab) {
      case "goods-receive":
        return <GoodsReceiveTab />;
      case "goods-issue":
        return <GoodsIssueTab />;
      default:
        return <DocumentInfoTab />;
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </>
  );
};

export default ManufactureOrderContainer;
