import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box, InputAdornment } from "@mui/material";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import CustomizedCheckboxes from "../../../Custom/CustomizedCheckboxes";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";
import BankAccountCard from "./BankAccountCard";
import ModalUI from "../../../UI/ModalUI";

const defaultBankAccountState = {
  bank_name: "",
  bank_account_name: "",
  bank_account_no: "",
  bank_account_branch: "",
  bank_account_type: "",
  description: "",
};

const FinancialTab = ({ control, errors, getValues, setValue, viewOnly }) => {
  const { t } = useTranslation();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "finance.bank_list",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [bankAccountSnapshot, setBankAccountSnapshot] = useState({});

  const {
    control: newAccountControl,
    getValues: getNewAccountValues,
    reset: resetNewAccountForm,
    formState: { errors: newAccountErrors },
  } = useForm({
    defaultValues: { ...defaultBankAccountState },
  });

  const watchFieldArray = useWatch({ control, name: "finance.bank_list" });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const checkBoxField = [
    {
      name: "finance.is_cheque",
      label: "เช็ค",
      xs: 12,
    },
    {
      name: "finance.is_transfer",
      label: "โอนเงิน",
      xs: 12,
    },
    {
      name: "finance.is_cash",
      label: "เงินสด",
      xs: 12,
    },
  ];

  const banks = [
    {
      id: 1,
      label: "ธ.กรุงเทพ",
      value: "กรุงเทพ",
    },
    {
      id: 2,
      label: "ธ.กรุงไทย",
      value: "กรุงไทย",
    },
    {
      id: 3,
      label: "ธ.กรุงศรีอยุธยา",
      value: "กรุงศรีอยุธยา",
    },
    {
      id: 4,
      label: "ธ.กสิกรไทย",
      value: "กสิกรไทย",
    },
    {
      id: 5,
      label: "ธ.ทหารไทยธนชาต",
      value: "ทหารไทยธนชาต",
    },
    {
      id: 6,
      label: "ธ.ออมสิน",
      value: "ออมสิน",
    },
    {
      id: 7,
      label: "ธ.ซีไอเอ็มบี ไทย",
      value: "ซีไอเอ็มบี ไทย",
    },
    {
      id: 8,
      label: "ธ.ไทยพาณิชย์",
      value: "ไทยพาณิชย์",
    },
    {
      id: 9,
      label: "ธ.สแตนดาร์ดชาร์เตอร์ด",
      value: "สแตนดาร์ดชาร์เตอร์ด",
    },
    {
      id: 10,
      label: "ธ.ยูโอบี",
      value: "ยูโอบี",
    },
    {
      id: 11,
      label: "ธ.ทิสโก้",
      value: "ทิสโก้",
    },
    {
      id: 12,
      label: "ธ.เกียรตินาคินภัทร",
      value: "เกียรตินาคินภัทร",
    },
    {
      id: 13,
      label: "ธ.แลนด์ แอนด์ เฮ้าส์",
      value: "แลนด์ แอนด์ เฮ้าส์",
    },
    {
      id: 14,
      label: "ธ.ไอซีบีซี (ไทย)",
      value: "ไอซีบีซี (ไทย)",
    },
    {
      id: 15,
      label: "ธ.อิสลามแห่งประเทศไทย",
      value: "อิสลามแห่งประเทศไทย",
    },
    {
      id: 16,
      label: "ธ.อื่นๆ",
      value: "อื่นๆ",
    },
  ];

  const accountTypes = [
    {
      id: 1,
      label: "บัญชีกระแสรายวัน",
      value: "บัญชีกระแสรายวัน",
    },
    { id: 2, label: "บัญชีออมทรัพย์", value: "บัญชีออมทรัพย์" },
  ];

  const stopEditingHandler = () => {
    setIsEditing(false);
  };

  const startEditingHandler = () => {
    setIsEditing(true);
  };

  const submitNewAccountHandler = () => {
    const newAccount = getNewAccountValues();
    if (!Object.values(newAccount).every((item) => item === "")) {
      append(getNewAccountValues());
    }
    setIsEditing(false);
    resetNewAccountForm();
  };

  const updateBankAccountSnapshot = (index) => {
    setBankAccountSnapshot({ ...getValues("finance.bank_list")[index] });
  };

  const restoreBankAccountSnapshot = (index) => {
    update(index, { ...bankAccountSnapshot });
  };

  const renderCheckBox = (name, label) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedCheckboxes label={label} {...field} isDisabled={viewOnly} />
      )}
    />
  );

  const renderAccountCards = () => {
    return controlledFields.map((item, index) => {
      return (
        <Grid key={item.id} item xs={12} sm={6} md={3} lg={3} xl={2}>
          <BankAccountCard
            title={item.bank_name}
            accountNum={item.bank_account_no}
            accountName={item.bank_account_name}
            index={index}
            control={control}
            getValues={getValues}
            onRemove={remove}
            errors={errors}
            onOpenModal={updateBankAccountSnapshot}
            onRestoreSnapshot={restoreBankAccountSnapshot}
            bankOptions={banks}
            viewOnly={viewOnly}
          />
        </Grid>
      );
    });
  };

  return (
    <>
      <Typography fontWeight="bold" sx={{ ml: 1, mb: 2 }}>
        การชำระเงิน
      </Typography>
      <Box sx={{ display: "flex" }}>
        {checkBoxField.map((field) => (
          <Box sx={{ ml: 1 }} key={field.name + field.label}>
            {renderCheckBox(field.name, field.label)}
          </Box>
        ))}
      </Box>
      <Typography
        fontWeight="bold"
        marginBottom={2}
        marginLeft={1}
        marginTop={2}
      >
        {t("contact.financial.payment.detail")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.payment_day"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">วัน</InputAdornment>
                  ),
                }}
                error={Boolean(errors.finance?.payment_day)}
                helperText={errors.finance?.payment_day?.message}
                label={t("contact.financial.payment.payment_date")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.payment_contact_name"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.finance?.payment_contact_name)}
                helperText={errors.finance?.payment_contact_name?.message}
                label={t("contact.financial.payment.contact_person")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.payment_contact_phone"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.finance?.payment_contact_phone)}
                helperText={errors.finance?.payment_contact_phone?.message}
                label={t("contact.financial.payment.phone_number")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.credit_count"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.finance?.credit_count)}
                helperText={errors.finance?.credit_count?.message}
                label={t("contact.financial.payment.credit_count")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography
        fontWeight="bold"
        marginBottom={2}
        marginLeft={1}
        marginTop={2}
      >
        {t("contact.financial.billing.index")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.billing_day"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">วัน</InputAdornment>
                  ),
                }}
                error={Boolean(errors.finance?.billing_day)}
                helperText={errors.finance?.billing_day?.message}
                label={t("contact.financial.billing.billing_date")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.billing_contact_name"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.finance?.billing_contact_name)}
                helperText={errors.finance?.billing_contact_name?.message}
                label={t("contact.financial.billing.contact_person")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.billing_contact_phone"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.finance?.billing_contact_phone)}
                helperText={errors.finance?.billing_contact_phone?.message}
                label={t("contact.financial.billing.phone_number")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
      </Grid>

      <Typography
        fontWeight="bold"
        marginBottom={2}
        marginLeft={1}
        marginTop={2}
      >
        {t("contact.financial.bank_info.index")}
      </Typography>
      <CustomizedButton
        title={"เพิ่มธนาคาร"}
        variant="outlined"
        size="medium"
        onClick={startEditingHandler}
        disabled={viewOnly}
      />
      <Grid container spacing={2}>
        {renderAccountCards()}
      </Grid>
      <Typography
        fontWeight="bold"
        marginBottom={2}
        marginLeft={1}
        marginTop={4}
      >
        {t("contact.financial.request_credit.index")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.request_credit.credit_limit_value"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">&#3647;</InputAdornment>
                  ),
                }}
                error={Boolean(
                  errors.finance?.request_credit?.credit_limit_value
                )}
                helperText={
                  errors.finance?.request_credit?.credit_limit_value?.message
                }
                label={t("contact.financial.request_credit.requested_credit")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.request_credit.credit_limit_day"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">วัน</InputAdornment>
                  ),
                }}
                error={Boolean(
                  errors.finance?.request_credit?.credit_limit_day
                )}
                helperText={
                  errors.finance?.request_credit?.credit_limit_day?.message
                }
                label={t("contact.financial.request_credit.date")}
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography
        fontWeight="bold"
        marginBottom={2}
        marginLeft={1}
        marginTop={4}
      >
        {t("contact.financial.accounting.index")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.account_payable_id"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={[]}
                title={t("contact.financial.accounting.account_payable")}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  if (option.value) {
                    return field.onChange(option.value);
                  }
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="finance.account_receivable_id"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={[]}
                title={t("contact.financial.accounting.account_receivable")}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  if (option.value) {
                    return field.onChange(option.value);
                  }
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
      </Grid>

      <ModalUI
        open={isEditing}
        handleClose={stopEditingHandler}
        fullWidth
        maxWidth="md"
      >
        <Typography fontWeight={"bold"} sx={{ ml: 1, my: 2 }}>
          {t("contact.financial.bank_info.bank")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ mb: 2 }}>
              <Controller
                name="bank_name"
                control={newAccountControl}
                render={({ field }) => (
                  <CustomizedSelect
                    fullWidth
                    error={Boolean(newAccountErrors.bank_name)}
                    helperText={newAccountErrors.bank_name?.message}
                    label={t("contact.financial.bank_info.bank_name")}
                    options={banks}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Controller
                name="bank_account_name"
                control={newAccountControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(newAccountErrors.bank_account_name)}
                    helperText={newAccountErrors.bank_account_name?.message}
                    label={t("contact.financial.bank_info.account_name")}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Controller
                name="bank_account_branch"
                control={newAccountControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(newAccountErrors.bank_account_branch)}
                    helperText={newAccountErrors.bank_account_branch?.message}
                    label={t("contact.financial.bank_info.branch")}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ mb: 2 }}>
              <Controller
                name="bank_account_type"
                control={newAccountControl}
                render={({ field }) => (
                  <CustomizedCreatable
                    {...field}
                    options={accountTypes}
                    title={t("contact.financial.bank_info.type")}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      if (option.value) {
                        return field.onChange(option.value);
                      }
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    readOnly={viewOnly}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Controller
                name="bank_account_no"
                control={newAccountControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(newAccountErrors.account_number)}
                    helperText={newAccountErrors.account_number?.message}
                    label={t("contact.financial.bank_info.account_number")}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Controller
                name="description"
                control={newAccountControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(newAccountErrors.description)}
                    helperText={newAccountErrors.description?.message}
                    label={t("contact.financial.bank_info.description")}
                    {...field}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <CustomizedButton
          title={"เพิ่มธนาคาร"}
          variant="contained"
          size="medium"
          onClick={submitNewAccountHandler}
        />
      </ModalUI>
    </>
  );
};

export default FinancialTab;
