import React, { useEffect, useMemo } from "react";
import * as Yup from "yup";
import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ModalUI from "../../../components/UI/ModalUI";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { categoryActions } from "../../../features/Setting/Category/category-slice";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createCategory,
  getAllCategory,
  updateCategory,
  deleteCategory,
  createCategoryLv1,
  createCategoryLv2,
  updateCategoryLv2,
  updateCategoryLv1,
  deleteCategoryLv1,
  deleteCategoryLv2,
} from "../../../features/Setting/Category/category-actions";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSnackbar } from "notistack";
import { usePermission } from "../../../hooks/use-auth";

const validation = Yup.object().shape({
  // value: Yup.string().required("กรุณากรอก"),
});

const Category = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal, title, selectedCategory, allCategory } = useSelector(
    (state) => state.category
  );
  const [expanded, setExpanded] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { createPermission, editPermission } = usePermission();

  const findInput = useMemo(() => {
    return {
      findManyInput: {
        include: {
          sub_level_2_list: {
            include: {
              sub_level_3_list: null,
            },
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    dispatch(getAllCategory(findInput));
  }, [dispatch, findInput]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      value: "",
      document_id: "",
    },
    resolver: yupResolver(validation),
  });

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.warehouseSetting"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.category.index"),
    },
  ];

  const getMergePrefixDocumentId = (selectedCategory = [], value = "") => {
    if (selectedCategory.length === 0) {
      return value;
    } else if (selectedCategory.length === 1) {
      return selectedCategory[0].document_id + "-" + value;
    } else if (selectedCategory.length === 2) {
      return selectedCategory[1].document_id + "-" + value;
    } else if (selectedCategory.length === 3) {
      return selectedCategory[2].document_id + "-" + value;
    } else if (selectedCategory.length === 4) {
      return selectedCategory[3].document_id + "-" + value;
    }
    return value;
  };

  const handleOpen = ({ title, category }) => {
    if (title.header === "addCategory") {
      setValue("value", "");
      setValue("document_id", getMergePrefixDocumentId(category));
    } else {
      setValue("document_id", category[category.length - 1].document_id);
      setValue("value", category[category.length - 1].name);
    }
    if (category) dispatch(categoryActions.loadedSelectedCategory(category));
    dispatch(categoryActions.changeTitle(title));
    dispatch(categoryActions.openModal());
  };

  const handleClose = () => {
    dispatch(getAllCategory(findInput));
    dispatch(categoryActions.closeModal());
    setTimeout(() => {
      dispatch(categoryActions.resetTitle());
      dispatch(categoryActions.resetSelectedCategory());
    }, 150);
  };

  const resetForm = () => {
    handleClose();
    reset();
  };

  const onSubmit = (data) => {
    switch (title.header) {
      case "addCategory":
        handleSubmitCreate({ data });
        break;
      case "editCategory":
        handleSubmitUpdate({ data });
        break;
      case "deleteCategory":
        handleSubmitDelete();
        break;
      default:
        break;
    }
  };

  const handleSubmitCreate = async ({ data }) => {
    let createInput = {
      document_id: data.document_id,
      name: data.value,
    };
    if (selectedCategory.length === 1) {
      createInput = {
        ...createInput,
        name: data.value,
        sub_level_1_document_id: selectedCategory[0].document_id,
      };
      await dispatch(createCategoryLv1({ createInput }, enqueueSnackbar));
    } else if (selectedCategory.length === 2) {
      createInput = {
        ...createInput,
        name: data.value,
        sub_level_2_document_id: selectedCategory[1].document_id,
      };
      await dispatch(createCategoryLv2({ createInput }, enqueueSnackbar));
    } else {
      await dispatch(createCategory({ createInput }, enqueueSnackbar));
    }
    await handleClose();
  };

  const handleSubmitUpdate = async ({ data }) => {
    let input = {
      uniqueInput: {
        document_id: null,
      },
      updateInput: {
        name: null,
      },
    };
    if (selectedCategory.length === 1) {
      input = {
        uniqueInput: {
          document_id: selectedCategory[0].document_id,
        },
        updateInput: {
          name: data.value,
        },
      };
      await dispatch(updateCategory(input, enqueueSnackbar));
    } else if (selectedCategory.length === 2) {
      input = {
        uniqueInput: {
          document_id: selectedCategory[1].document_id,
        },
        updateInput: {
          name: data.value,
          sub_level_1_document_id: selectedCategory[0].document_id,
        },
      };
      await dispatch(updateCategoryLv1(input, enqueueSnackbar));
    } else if (selectedCategory.length === 3) {
      input = {
        uniqueInput: {
          document_id: selectedCategory[2].document_id,
        },
        updateInput: {
          name: data.value,
          sub_level_2_document_id: selectedCategory[1].document_id,
        },
      };
      await dispatch(updateCategoryLv2(input, enqueueSnackbar));
    }
    await handleClose();
  };

  const handleSubmitDelete = async () => {
    let input = {};
    if (selectedCategory.length === 1) {
      input = {
        uniqueInput: {
          document_id: selectedCategory[0].document_id,
        },
      };
      await dispatch(deleteCategory(input, enqueueSnackbar));
    } else if (selectedCategory.length === 2) {
      input = {
        uniqueInput: {
          document_id: selectedCategory[1].document_id,
        },
      };
      await dispatch(deleteCategoryLv1(input, enqueueSnackbar));
    } else if (selectedCategory.length === 3) {
      input = {
        uniqueInput: {
          document_id: selectedCategory[2].document_id,
        },
      };
      await dispatch(deleteCategoryLv2(input, enqueueSnackbar));
    }
    await handleClose();
  };

  const handleExpandClick = () => {
    const allId = [];
    allCategory.forEach((categoryLv2) => {
      if (categoryLv2.sub_level_2_list.length > 0) {
        allId.push(categoryLv2.document_id);
        categoryLv2.sub_level_2_list.forEach((categoryLv3) => {
          if (categoryLv3.sub_level_3_list.length > 0) {
            allId.push(categoryLv3.document_id);
          }
        });
      }
    });
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? allId : []));
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const buttonSx = { padding: "2px 10px", height: "fit-content" };

  const checkConditionRender = () => {
    const render = createPermission || editPermission;
    return render;
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container my={2}>
        <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
          <Typography variant="h5" my={2}>
            {t("setting.inventory.category.index")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1}
          xl={1}
          alignSelf="center"
          textAlign={"end"}
        >
          {createPermission && (
            <CustomizedButton
              fullWidth
              title={t("setting.inventory.category.addCategory")}
              variant="contained"
              onClick={() => handleOpen({ title: { header: "addCategory" } })}
            />
          )}
        </Grid>
      </Grid>
      <CustomizedBox>
        <Box
          onClick={handleExpandClick}
          sx={{ cursor: "pointer", width: "fit-content", marginBottom: "40px" }}
          display={"flex"}
        >
          {expanded.length === 0 ? (
            <KeyboardArrowRightIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
          <Typography>
            {expanded.length === 0 ? "เปิดทั้งหมด" : "ปิดทั้งหมด"}
          </Typography>
        </Box>
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={["root"]}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={handleToggle}
          sx={{ flexGrow: 1 }}
        >
          {allCategory &&
            allCategory.map((firstCategory) => {
              return (
                <TreeItem
                  key={firstCategory.document_id}
                  nodeId={firstCategory.document_id}
                  label={<>{firstCategory.name}</>}
                >
                  {checkConditionRender() && (
                    <ToggleButtonGroup aria-label="text alignment">
                      {createPermission && (
                        <ToggleButton
                          sx={buttonSx}
                          value="left"
                          aria-label="left aligned"
                          onClick={() =>
                            handleOpen({
                              title: {
                                header: "addCategory",
                                name: firstCategory.name,
                              },
                              category: [firstCategory],
                            })
                          }
                        >
                          เพิ่ม
                        </ToggleButton>
                      )}
                      {editPermission && (
                        <ToggleButton
                          sx={buttonSx}
                          value="center"
                          aria-label="centered"
                          onClick={() =>
                            handleOpen({
                              title: {
                                header: "editCategory",
                                name: firstCategory.name,
                              },
                              category: [firstCategory],
                            })
                          }
                        >
                          เปลี่ยนชื่อ
                        </ToggleButton>
                      )}
                      {editPermission && (
                        <ToggleButton
                          sx={buttonSx}
                          value="right"
                          aria-label="right aligned"
                          onClick={() =>
                            handleOpen({
                              title: {
                                header: "deleteCategory",
                                name: firstCategory.name,
                              },
                              category: [firstCategory],
                            })
                          }
                        >
                          ลบ
                        </ToggleButton>
                      )}
                    </ToggleButtonGroup>
                  )}
                  {firstCategory.sub_level_2_list &&
                    firstCategory.sub_level_2_list.map((secondCategory) => (
                      <TreeItem
                        key={secondCategory.document_id}
                        nodeId={secondCategory.document_id}
                        label={<>{secondCategory.name} </>}
                      >
                        {checkConditionRender() && (
                          <ToggleButtonGroup aria-label="text alignment">
                            {createPermission && (
                              <ToggleButton
                                sx={buttonSx}
                                value="left"
                                aria-label="left aligned"
                                onClick={() =>
                                  handleOpen({
                                    title: {
                                      header: "addCategory",
                                      name: secondCategory.name,
                                    },
                                    category: [firstCategory, secondCategory],
                                  })
                                }
                              >
                                เพิ่ม
                              </ToggleButton>
                            )}
                            {editPermission && (
                              <ToggleButton
                                sx={buttonSx}
                                value="center"
                                aria-label="centered"
                                onClick={() =>
                                  handleOpen({
                                    title: {
                                      header: "editCategory",
                                      name: secondCategory.name,
                                    },
                                    category: [firstCategory, secondCategory],
                                  })
                                }
                              >
                                เปลี่ยนชื่อ
                              </ToggleButton>
                            )}
                            {editPermission && (
                              <ToggleButton
                                sx={buttonSx}
                                value="right"
                                aria-label="right aligned"
                                onClick={() =>
                                  handleOpen({
                                    title: {
                                      header: "deleteCategory",
                                      name: secondCategory.name,
                                    },
                                    category: [firstCategory, secondCategory],
                                  })
                                }
                              >
                                ลบ
                              </ToggleButton>
                            )}
                          </ToggleButtonGroup>
                        )}
                        {secondCategory.sub_level_3_list &&
                          secondCategory.sub_level_3_list.map(
                            (thirdCategory) => (
                              <TreeItem
                                key={thirdCategory.document_id}
                                nodeId={thirdCategory.document_id}
                                label={<>{thirdCategory.name} </>}
                              >
                                {checkConditionRender() && (
                                  <ToggleButtonGroup aria-label="text alignment">
                                    {editPermission && (
                                      <ToggleButton
                                        sx={buttonSx}
                                        value="center"
                                        aria-label="centered"
                                        onClick={() =>
                                          handleOpen({
                                            title: {
                                              header: "editCategory",
                                              name: thirdCategory.name,
                                            },
                                            category: [
                                              firstCategory,
                                              secondCategory,
                                              thirdCategory,
                                            ],
                                          })
                                        }
                                      >
                                        เปลี่ยนชื่อ
                                      </ToggleButton>
                                    )}
                                    {editPermission && (
                                      <ToggleButton
                                        sx={buttonSx}
                                        value="right"
                                        aria-label="right aligned"
                                        onClick={() =>
                                          handleOpen({
                                            title: {
                                              header: "deleteCategory",
                                              name: thirdCategory.name,
                                            },
                                            category: [
                                              firstCategory,
                                              secondCategory,
                                              thirdCategory,
                                            ],
                                          })
                                        }
                                      >
                                        ลบ
                                      </ToggleButton>
                                    )}
                                  </ToggleButtonGroup>
                                )}
                              </TreeItem>
                            )
                          )}
                      </TreeItem>
                    ))}
                </TreeItem>
              );
            })}
        </TreeView>
      </CustomizedBox>
      <ModalUI
        open={modal}
        handleClose={handleClose}
        title={
          t(`setting.inventory.category.${title.header}`) +
            " " +
            (title.name || "") || ""
        }
        width={620}
      >
        {title.header !== "deleteCategory" ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={6}>
                <Controller
                  name={"document_id"}
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      label={t("setting.inventory.category.documentId")}
                      error={Boolean(errors.value)}
                      helperText={errors.value?.message}
                      {...field}
                      disabled={true}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={"value"}
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      label={t("setting.inventory.category.name")}
                      error={Boolean(errors.value)}
                      helperText={errors.value?.message}
                      {...field}
                      onChange={(e) => {
                        setValue("value", e.target.value);
                        title.header === "addCategory" &&
                          setValue(
                            "document_id",
                            getMergePrefixDocumentId(
                              selectedCategory,
                              e.target.value
                            )
                          );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <CustomizedButton
              type="reset"
              onClick={resetForm}
              title={t("button.cancel")}
              variant="outlined"
              sx={{ mt: 2, mr: 2 }}
            />
            <CustomizedButton
              type="submit"
              title={t("button.save")}
              variant="contained"
              sx={{ mt: 2 }}
            />
          </form>
        ) : (
          <Box width={200}>
            <CustomizedButton
              type="reset"
              onClick={resetForm}
              title={t("button.cancel")}
              variant="outlined"
              sx={{ mr: 2 }}
            />
            <CustomizedButton
              type="submit"
              onClick={onSubmit}
              title={t("button.save")}
              variant="contained"
            />
          </Box>
        )}
      </ModalUI>
    </>
  );
};

export default Category;
