import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import BomDetailForm from "../../../components/Form/Manufacture/Bom/Detail";
import BomHeaderForm from "../../../components/Form/Manufacture/Bom/Header";
import BomRemarkForm from "../../../components/Form/Manufacture/Bom/Remark";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bomInitialState,
  bomValidation,
} from "../../../features/Manufacture/Bom/bom-initial";
import {
  createBom,
  deleteBom,
  getBomById,
  updateBom,
} from "../../../features/Manufacture/Bom/bom-actions";
import { unixToDate } from "../../../utils/date-converter";
import BomTableForm from "../../../components/Form/Manufacture/Bom/Table";
import { bomActions } from "../../../features/Manufacture/Bom/bom-slice";
import QtyConfirmation from "../../../components/UI/Confirmation/QtyConfirmation";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../hooks/use-auth";

const BomContainer = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const { bom } = useSelector((state) => state.bom);
  const [openQtyConfirmation, setOpenQtyConfirmation] = useState(false);
  const { state } = useLocation();

  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.bom.index"),
      to: "/manufacture/bom",
    },
    {
      name: bom.name || `สร้าง${t("manufacture.bom.index")}`,
    },
  ];

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ...bomInitialState.bom,
      created_date: unixToDate(bomInitialState.bom.created_date),
    },
    resolver: yupResolver(bomValidation),
  });

  useEffect(() => {
    if (id) {
      dispatch(getBomById(id, enqueueSnackbar));
    }
    return () => {
      dispatch(bomActions.resetBom());
    };
  }, [dispatch, id, enqueueSnackbar]);

  useEffect(() => {
    if (id) {
      reset({
        ...bom,
        created_date: bom.created_date
          ? unixToDate(bom.created_date)
          : undefined,
        start_date: bom.start_date ? unixToDate(bom.start_date) : undefined,
        end_date: bom.end_date ? unixToDate(bom.end_date) : undefined,
      });
      setDisabled(true);
    } else if (state) {
      if (state.isCopied) {
        const {
          id,
          is_main_bom,
          creator_document_id,
          created_by,
          isCopied,
          ingredient_list,
          ...payload
        } = state;

        const formatIngredientList = ingredient_list.map((ingredient) => ({
          ...ingredient,
          uid: uuidv4(),
        }));

        reset({
          ...payload,
          is_main_bom: "FALSE",
          created_date: moment(),
          ingredient_list: formatIngredientList,
        });
        setDisabled(false);
      }
    }
  }, [bom, id, reset, state]);

  const editButtonClickHandler = () => {
    setDisabled(false);
  };

  const openQtyConfirmationHandler = () => {
    setOpenQtyConfirmation(true);
  };

  const closeQtyConfirmationHandler = () => {
    setOpenQtyConfirmation(false);
  };

  const mainBomSubmitHandler = () => {
    const data = getValues();
    const formatData = {
      ...data,
      is_main_bom: "TRUE",
    };
    dispatch(updateBom(id, formatData, enqueueSnackbar, user, true));
  };

  const submitBomHandler = (data, isAcceptQty) => {
    const { mfg_qty, minimum_stock_qty } = data;
    if (isAcceptQty || parseInt(mfg_qty) >= parseInt(minimum_stock_qty)) {
      closeQtyConfirmationHandler();
      if (!id) {
        dispatch(createBom(data, enqueueSnackbar, navigate, user, state));
      } else {
        dispatch(updateBom(id, data, enqueueSnackbar, user));
      }
    } else {
      openQtyConfirmationHandler();
    }
  };

  const deleteBomHandler = () => {
    dispatch(deleteBom(id, enqueueSnackbar, navigate, user));
  };

  const copyBomHandler = () => {
    navigate("/manufacture/bom/add", {
      state: { ...bom, isCopied: true },
    });
    enqueueSnackbar("คัดลอกสำเร็จ", {
      variant: "success",
    });
  };

  const watchMinimunStockQty = useWatch({ control, name: "minimum_stock_qty" });

  useEffect(() => {
    if (errors.name) {
      enqueueSnackbar(errors.name.message, {
        variant: "error",
      });
    }
    if (errors.item_document_id) {
      enqueueSnackbar(errors.item_document_id.message, {
        variant: "error",
      });
    }
    if (errors.mfg_qty) {
      enqueueSnackbar(errors.mfg_qty.message, {
        variant: "error",
      });
    }
    if (errors.ingredient_list) {
      if (errors.ingredient_list.message) {
        enqueueSnackbar(errors?.ingredient_list?.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(errors?.ingredient_list[0]?.qty?.message, {
          variant: "error",
        });
      }
    }
  }, [
    enqueueSnackbar,
    errors.ingredient_list,
    errors.item_document_id,
    errors.name,
    errors.mfg_qty,
  ]);

  return (
    <form
      onSubmit={handleSubmit((data) => submitBomHandler(data, false))}
      onKeyDown={(e) => {
        if (e.code === "Enter") e.preventDefault();
      }}
    >
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <BomHeaderForm
        control={control}
        errors={errors}
        disabled={disabled}
        editButtonClickHandler={editButtonClickHandler}
        mainBomSubmitHandler={mainBomSubmitHandler}
        deleteBomHandler={deleteBomHandler}
        copyBomHandler={copyBomHandler}
      />
      <BomDetailForm
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
        minimumStockQty={watchMinimunStockQty}
        isMainPage
      />
      <BomTableForm
        control={control}
        errors={errors}
        disabled={disabled}
        isMainPage
      />
      <BomRemarkForm control={control} errors={errors} disabled={disabled} />
      {!disabled && (
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            variant="outlined"
            title={t("button.cancel")}
            onClick={() => navigate("/manufacture/bom")}
          />
          <CustomizedButton
            type="submit"
            variant="contained"
            title={t("button.save")}
          />
        </Box>
      )}
      <QtyConfirmation
        openQtyConfirmation={openQtyConfirmation}
        qtyConfirmationAction={handleSubmit((data) =>
          submitBomHandler(data, true)
        )}
        closeQtyConfirmationHandler={closeQtyConfirmationHandler}
        minimumStockQty={watchMinimunStockQty}
      />
    </form>
  );
};

export default BomContainer;
