import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import ItemTable from "../../../components/Table/ItemTable";
import TotalBox from "../../../components/UI/TotalBox";
import { getAllItemsAggrid } from "../../../features/Inventory/ItemMaster/itemMaster-actions";
import { usePermission } from "../../../hooks/use-auth";
import { formatDate } from "../../../utils/date-converter";

const Items = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isFilter = searchParams.get("filter");
  const isFilterTag = searchParams.get("filterTag");
  const { count } = useSelector((state) => state.itemMaster);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { createPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    // {
    //   label: t("inventory.items.index"),
    //   path: `${pathname}?filter=items`,
    // },
    // {
    //   label: t("inventory.service"),
    //   path: `${pathname}?filter=service`,
    // },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { is_active, item_group_sub_level_1, tag_list } =
        request.filterModel;
      const filterTagList = {
        ...request.filterModel,
        document_id: {
          ...request.filterModel.document_id,
          mode: "insensitive",
        },
        name: {
          ...request.filterModel.name,
          mode: "insensitive",
        },
        tag_list: tag_list
          ? {
              filterType: "objectArray",
              type: "some",
              filter: {
                name: {
                  filterType: "set",
                  values: tag_list.values,
                },
              },
            }
          : {},
        item_group_sub_level_1: item_group_sub_level_1 && {
          filterType: "object",
          filter: {
            name: {
              ...item_group_sub_level_1,
              mode: "insensitive",
            },
          },
        },
        is_active: is_active &&
          is_active.values.length > 0 && {
            filterType: "boolean",
            type: "equals",
            filter: is_active.values[0],
          },
      };
      dispatch(
        getAllItemsAggrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterTagList,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("tag_list");
      if (isFilterTag)
        instance.setModel({
          filterType: "set",
          values: [isFilterTag.replace(/\s/g, " ")],
        });
      else instance.setModel({});
      params.api.onFilterChanged();
    },
    [isFilterTag]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    setTimeout(() => {
      params.api.setServerSideDatasource(datasource);
    }, 250);
  };

  const onRowDoubleClicked = (params) => {
    navigate(
      `${pathname}/${encodeURIComponent(
        params.data.document_id
      )}?tab=item&subtab=general`
    );
  };

  const onRowClicked = (params) => {
    return navigate(`${encodeURIComponent(params.data.document_id)}`);
  };

  // useEffect(() => {
  //   if (gridRef.current.api) {
  //     const instance = gridRef.current.api.getFilterInstance("type");
  //     switch (isFilter) {
  //       case "items":
  //         instance.setModel({ values: ["สินค้า"] });
  //         break;
  //       case "service":
  //         instance.setModel({ values: ["บริการ"] });
  //         break;
  //       default:
  //         instance.setModel({});
  //         break;
  //     }
  //     gridRef.current.api.onFilterChanged();
  //   }
  // }, [isFilter]);

  useEffect(() => {
    if (gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [onFilterChanged]);

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TotalBox
            title="ทั้งหมด"
            total={count}
            date={formatDate(new Date())}
          />
        </Grid>
      </Grid>
      <Box
        sx={{ mt: 3, mb: 1, display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h5">{t("inventory.items.index")}</Typography>
        {createPermission && (
          <Box display={"flex"} gap={1}>
            <CustomizedButton
              testId="items-create-btn"
              title="สร้างสินค้า"
              variant="contained"
              onClick={() =>
                navigate(`${pathname}/add?tab=item&subtab=general`)
              }
            />
            <CustomizedButton
              title="นำเข้าสินค้า"
              variant="contained"
              onClick={() => navigate(`${pathname}/importer`)}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent={"space-between"} alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
        <CustomizedButton
          title={t("button.resetFilter")}
          variant="outlined"
          onClick={onFilterReset}
          sx={{ height: "33px" }}
        />
      </Box>
      {!isSmallScreen ? (
        <ItemTable
          gridRef={gridRef}
          height={649}
          onGridReady={onGridReady}
          onRowDoubleClicked={onRowDoubleClicked}
        />
      ) : (
        <ItemTable
          gridRef={gridRef}
          height={649}
          onGridReady={onGridReady}
          onRowClicked={onRowClicked}
        />
      )}
    </>
  );
};

export default Items;
