import React from "react";
import { Controller } from "react-hook-form";
import CustomizedRadioGroup from "../Custom/CustomizedRadioGroup";

const ControlledRadioGroup = ({ name, row, control, radioLists, disabled }) => {
  return (
    <Controller
      key={`${name}-radio-group`}
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedRadioGroup
          {...field}
          radioList={radioLists}
          row={row}
          disabled={disabled}
        />
      )}
    />
  );
};

export default ControlledRadioGroup;
