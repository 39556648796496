import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./uom-initials";

const uomSlice = createSlice({
  name: "uom",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload] = false;
      state.error = action.payload;
    },
    loadedAllUomGroup(state, action) {
      state.allUomGroup = action.payload;
      state.isLoading.allUomGroup = false;
    },
    loadedAllUom(state, action) {
      state.allUoms = action.payload;
    },
    loadedUomIndex(state, action) {
      state.uomIndex = action.payload;
    },
    loadedUomGroupIndex(state, action) {
      state.uomGroupIndex = action.payload;
    },
    loadedUom(state, action) {
      state.uom = action.payload.result;
      if (action.payload.index === 0 || action.payload.index)
        state.allUoms[action.payload.index] = action.payload.result;
      if (action.payload.create)
        state.allUoms = [...state.allUoms, action.payload.result];
      state.isLoading.uom = false;
    },
    loadedUomGroup(state, action) {
      state.uomGroup = action.payload.result;
      if (action.payload.index === 0 || action.payload.index)
        state.allUomGroup[action.payload.index] = action.payload.result;
      if (action.payload.create)
        state.allUomGroup = [...state.allUomGroup, action.payload.result];
      state.isLoading.uomGroup = false;
    },
    removeUom(state, action) {
      const newState = state.allUoms.filter((uom) => {
        return uom.id !== action.payload.result.id;
      });
      state.allUoms = newState;
    },
    openModal(state, action) {
      state.modal[action.payload] = true;
    },
    closeModal(state, action) {
      state.modal[action.payload] = false;
    },
    resetAllUomGroup(state, action) {
      state.allUomGroup = [];
    },
    resetUomGroup(state, action) {
      state.uomGroup = initialState.uomGroup;
    },
    resetAllUoms(state, action) {
      state.allUoms = initialState.allUoms;
    },
    resetUom(state, action) {
      state.uom = initialState.uom;
    },
  },
});

export const uomActions = uomSlice.actions;

export default uomSlice.reducer;
