import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./rbac-initial";

const rbacSlice = createSlice({
  name: "rbac",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllRoles(state, action) {
      state.allRoles = action.payload;
      state.isLoading.allRoles = false;
    },
    appendedNewRole(state, action) {
      state.allRoles = [...state.allRoles, action.payload];
      state.isLoading.role = false;
    },
    loadedPriviledgeToRole(state, action) {
      const { payload } = action;

      if (["VIEW", "VIEW_SELF"].includes(payload["PRIVILEDGE"])) {
        state.role[payload["RESOURCE"]][payload["FUNCTION"]]["VIEW"] =
          payload["PRIVILEDGE"];
      }
      if (
        ["CREATE", "EDIT", "DELETE", "CANCEL"].includes(payload["PRIVILEDGE"])
      ) {
        state.role[payload["RESOURCE"]][payload["FUNCTION"]][
          payload["PRIVILEDGE"]
        ] = true;
      }
    },
    removedPriviledgeFromRole(state, action) {
      const payload = action.payload;
      if (["VIEW", "VIEW_SELF"].includes(payload["PRIVILEDGE"])) {
        state.role[payload["RESOURCE"]][payload["FUNCTION"]][
          payload["PRIVILEDGE"]
        ] = "CLOSE";
      }
      if (
        ["CREATE", "EDIT", "DELETE", "CANCEL"].includes(payload["PRIVILEDGE"])
      ) {
        state.role[payload["RESOURCE"]][payload["FUNCTION"]][
          payload["PRIVILEDGE"]
        ] = false;
      }
    },
    loadedPriviledgeList(state, action) {
      state.priviledgeList = action.payload;
      state.isLoading.priviledgeList = false;
    },
    resetRole(state) {
      state.role = initialState.role;
      state.priviledgeList = initialState.priviledgeList;
    },
    loadedRole(state, action) {
      state.role = { ...state.role, ...action.payload };
      state.isLoading.role = false;
    },
    updatedRole(state, action) {
      const updatedRole = action.payload;
      const updatedIndex = state.allRoles.findIndex(
        (role) => role.doument_id === updatedRole.document_id
      );
      state.allRoles[updatedIndex] = updatedRole;
      state.isLoading.allRoles = false;
    },
    deletedRole(state, action) {
      state.allRoles = state.allRoles.filter(
        (role) => role.document_id !== action.payload.document_id
      );
      state.isLoading.role = false;
    },
  },
});

export const rbacActions = rbacSlice.actions;

export default rbacSlice.reducer;
