import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./purchase-order-initial";

const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllPurchaseOrder(state, action) {
      state.allPurchaseOrder = action.payload;
      state.isLoading.allPurchaseOrder = false;
    },
    loadedAllPurchaseOrdersExports(state, action) {
      state.allPurchaseOrdersExport = action.payload;
      state.isLoading.allPurchaseOrder = false;
    },
    loadedPurchaseOrder(state, action) {
      state.purchaseOrder = action.payload;
      state.isLoading.purchaseOrder = false;
    },
    loadedApprovalList(state, action) {
      const { approvalTemplates, approvalProgress } = action.payload;
      const sortedApprovalTemplates = approvalTemplates.sort(
        (a, b) => a.step_number - b.step_number
      );
      const sortedApprovalProgress =
        approvalProgress &&
        approvalProgress.length > 0 &&
        approvalProgress.sort((a, b) => a.created_date - b.created_date);

      // 1.) check current approval list compare with approval template
      // 2.) if they not have people in that particular template then use people form approval list instead
      // 3.) else if they still have approval and not deleted yet then use approval template as usual

      let formatApprovalList = [];
      sortedApprovalTemplates.forEach((approvalTemplate, index) => {
        if (approvalTemplate.is_required_approve) {
          const formatApprovalWithStatus = {
            id: approvalTemplate.id,
            step_number: approvalTemplate.step_number,
            reviewer_list: approvalTemplate.reviewer_list,
            approval_status:
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                sortedApprovalProgress[index].approval_status) ||
              null,
            created_date:
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                sortedApprovalProgress[index].created_date) ||
              null,
            approved_by:
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                approvalTemplate.reviewer_list.find(
                  (approve) =>
                    approve.document_id ===
                    sortedApprovalProgress[index].approved_by_document_id
                )) ||
              (sortedApprovalProgress &&
                sortedApprovalProgress[index] &&
                sortedApprovalProgress[index].approved_by) ||
              null,
            is_required_approve: approvalTemplate.is_required_approve,
          };
          formatApprovalList.push(formatApprovalWithStatus);
        }
      });

      state.approvalList = formatApprovalList;
    },
    loadedGoodReceiveTab(state, action) {
      state.goodReceiveTab = action.payload;
      state.isLoading.purchaseOrder = false;
    },
    loadedPurchaseReturnTab(state, action) {
      state.purchaseReturnTab = action.payload;
      state.isLoading.purchaseOrder = false;
    },
    updatePurchaseOrderStatus(state, action) {
      state.purchaseOrder.render_status = action.payload;
      state.isLoading.purchaseOrder = false;
    },
    updateApprovalStatus(state, action) {
      const { approval_list, user } = action.payload;

      const sortedApprovalProgress = approval_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      const findApprover =
        sortedApprovalProgress &&
        sortedApprovalProgress.length > 0 &&
        sortedApprovalProgress?.map((approval) => {
          return {
            approval_status: approval?.approval_status,
            reviewer_document_id_list:
              approval?.approval_template &&
              approval?.approval_template?.reviewer_document_id_list,
            reviewer_list:
              approval?.approval_template &&
              approval?.approval_template?.reviewer_list,
            created_date: approval?.created_date,
          };
        });

      const isHaveApproval = sortedApprovalProgress?.every(
        (approval) =>
          approval?.approval_template &&
          approval?.approval_template?.is_required_approve === true
      );

      const formatApprovalList =
        findApprover &&
        findApprover.filter((approval) => approval.reviewer_list !== null);

      const foundWaitApprove =
        formatApprovalList &&
        formatApprovalList.find(
          (approval) => approval.approval_status === "PENDING"
        );

      const isHaveApprovalPermission =
        foundWaitApprove &&
        foundWaitApprove?.reviewer_document_id_list?.includes(user.document_id);

      state.isHaveApprovalPermission = isHaveApprovalPermission;
      state.isHaveApproval = isHaveApproval;

      // 1.) check current approval list compare with approval template
      // 2.) if they not have people in that particular template then use people form approval list instead
      // 3.) else if they still have approval and not deleted yet then use approval template as usual

      if (formatApprovalList && formatApprovalList.length > 0) {
        formatApprovalList.forEach((approval, index) => {
          if (state.approvalList[index]) {
            state.approvalList[index].approval_status =
              approval.approval_status;
          }
        });
      }
    },
    loadedPurchaseOrderPriceList(state, action) {
      state.purchaseOrderPriceList = action.payload;
      state.isLoading.purchaseOrderPriceList = false;
    },

    resetGoodReceiveTab(state) {
      state.goodReceiveTab = initialState.goodReceiveTab;
      state.error = initialState.error;
    },
    resetPurchaseReturnTab(state) {
      state.purchaseReturnTab = initialState.purchaseReturnTab;
      state.error = initialState.error;
    },
    resetPurchaseOrder(state) {
      state.purchaseOrder = initialState.purchaseOrder;
      state.error = initialState.error;
    },
    resetAllPurchaseOrdersExport(state) {
      state.allPurchaseOrdersExport = initialState.allPurchaseOrdersExport;
      state.error = initialState.error;
    },
    resetPurchaseOrderPriceList(state) {
      state.purchaseOrderPriceList = initialState.purchaseOrderPriceList;
    },
  },
});

export const purchaseOrderActions = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;
