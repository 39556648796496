import moment from "moment";
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCallback, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import NewReportDateFilter from "../../../components/UI/NewReportDateFilter";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import TraceEntryReportTable from "../../../components/Table/DocumentTable/TraceEntryReportTable";
import TimeStampReportLayout from "../../../components/UI/TimeStampReportLayout";

import GlobalService from "../../../services/Global";
import InventoryService from "../../../services/Inventory";

import { getNewTraceEntryReport } from "../../../features/Inventory/Report/report-actions";
import { inventoryReportActions } from "../../../features/Inventory/Report/report-slice";
import useTraceEntryColumnDef from "../../../hooks/Inventory/useTraceEntryColumnDef";

import {
  dateToUnix,
  formatDateAgGrid,
  formatDateExport,
  unixToDateWithFormat,
  unixToDateTimeWithFormatNoComma,
} from "../../../utils/date-converter";
import { filterDateWithUnix } from "../../../utils/filterparams";
import { entryTypeToThai } from "../../../utils/dataTransformer";
import { formatExportQuotes } from "../../../utils/exporter";

const defaultDate = {
  dateType: "lastWeek",
  date: new Date(moment().startOf("day").subtract(6, "day")),
  dateTo: new Date(moment().endOf("day")),
};

const TraceEntryReportView = () => {
  const { isLoading } = useSelector((state) => state.inventoryReport);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const [filename, setFilename] = useState("");
  const [data, setData] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [fetchTimestamp, setFetchTimestamp] = useState(null);
  const csvInstance = useRef(null);
  const [showTable, setShowTable] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [detail, setDetail] = useState(null);
  const [genDetail, setGenDetail] = useState(null);

  const columnDef = useTraceEntryColumnDef(true);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.traceEntry.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: defaultDate,
  });

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const { created_date, scanned_date, lot } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        created_date: created_date && filterDateWithUnix(created_date),
        scanned_date: scanned_date && filterDateWithUnix(scanned_date),
        lot: lot && filterDateWithUnix(lot),
      };

      dispatch(
        getNewTraceEntryReport(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      let startDate = getValues("date");
      let endDate = getValues("dateTo");

      const created_date = params.api.getFilterInstance("created_date");

      created_date?.setModel({
        filterType: "date",
        type: "inRange",
        dateFrom: formatDateAgGrid(startDate),
        dateTo: formatDateAgGrid(endDate),
      });
      params.api.onFilterChanged();
    },
    [getValues]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const getFiltered = async () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
    const { timestamp, in_queue_timestamp, detail } =
      await GlobalService.getReportViewTimestamp("TraceEntry");
    setDetail(detail);
    setLastTimestamp(timestamp);
    setFetchTimestamp(
      in_queue_timestamp ? in_queue_timestamp.timestamp * 1000 : null
    );
    setGenDetail(null);
  };

  const exportAllDataAsAgGridCsv = async () => {
    try {
      const filterModel = gridRef.current.api.getFilterModel();
      const sortModel = gridRef.current.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const { created_date, scanned_date, lot } = filterModel;

      const formatFilterModel = {
        ...filterModel,
        created_date: created_date && filterDateWithUnix(created_date),
        scanned_date: scanned_date && filterDateWithUnix(scanned_date),
        lot: lot && filterDateWithUnix(lot),
      };

      dispatch(inventoryReportActions.onLoading("traceEntry"));
      const { results: traceEntryReport } =
        await InventoryService.getAllNewTraceEntryReport({
          startRow: 0,
          endRow: Math.pow(10, 5),
          filterModel: formatFilterModel,
          sortModel,
        });
      let startDate = getValues("date");
      let endDate = getValues("dateTo");
      const formatValue = await traceEntryReport.map(
        ({
          scanned_date,
          created_date,
          lot,
          reference_document_type,
          ...otherValue
        }) => ({
          ...otherValue,
          scanned_date: scanned_date
            ? unixToDateTimeWithFormatNoComma(scanned_date)
            : "-",
          created_date: created_date ? unixToDateWithFormat(created_date) : "-",
          lot: lot ? unixToDateWithFormat(lot) : "-",
          reference_document_type: reference_document_type
            ? entryTypeToThai(reference_document_type)
            : "-",
        })
      );
      setData(formatExportQuotes(formatValue));
      setFilename(
        `${t("inventory.traceEntry.index")}_${formatDateExport(
          startDate
        )}_${formatDateExport(endDate)}`
      );
      dispatch(inventoryReportActions.onLoaded("traceEntry"));
      setTimeout(() => {
        if (csvInstance.current) {
          csvInstance.current.link.click();
        }
      });
      enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error exporting CSV:", error);
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const columnDefHeader = columnDef.map((col) => ({
    label: col.headerName,
    key: col.field,
  }));

  const generateReportHandler = async () => {
    setFetching(true);
    let endDate = getValues("dateTo");
    let startDate = getValues("date");
    const { status, timestamp, detail } = await GlobalService.generateReport(
      "TraceEntry",
      {
        end_date: dateToUnix(endDate),
        start_date: dateToUnix(startDate),
      }
    );
    setGenDetail(detail);
    setTimeout(() => {
      setFetching(false);
    }, [5000]);

    if (status === "success") {
      setFetchTimestamp(timestamp);
      enqueueSnackbar("ส่งคำขอสำเร็จ กรุณารอสักครู่", {
        variant: "success",
      });
    } else if (status === "error") {
      enqueueSnackbar(
        `ระบบกำลังดำเนินการ กรุณารอสักครู่\n(ขอล่าสุด ${moment(
          fetchTimestamp
        ).fromNow()})`,
        {
          variant: "warning",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
  };

  const onFilterReset = () => {
    reset(defaultDate);
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("inventory.traceEntry.index")}</Typography>
        <Box>
          <CustomizedButton
            title={t("inventory.exportReport")}
            variant="contained"
            onClick={exportAllDataAsAgGridCsv}
            disabled={isLoading.traceEntry}
          />
          <CSVLink
            headers={columnDefHeader}
            data={data}
            filename={filename}
            ref={csvInstance}
          />
        </Box>
      </Box>
      <CustomizedBox>
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFiltered}
                disabled={isLoading.traceEntry}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.traceEntry}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </CustomizedBox>
      {showTable && (
        <TimeStampReportLayout
          detail={detail}
          genDetail={genDetail}
          fetching={fetching}
          generateReportHandler={generateReportHandler}
          lastTimestamp={lastTimestamp}
          fetchTimestamp={fetchTimestamp}
          documentType="entry"
        />
      )}
      {showTable && (
        <TraceEntryReportTable
          gridRef={gridRef}
          onGridReady={onGridReady}
          isReport
        />
      )}
    </>
  );
};

export default TraceEntryReportView;
