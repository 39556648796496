import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { Controller } from "react-hook-form";
import CustomizedNumberTextField from "../../Custom/CustomizedNumberTextField";

export default function Sales({ control, errors, viewOnly, isInventoryPage }) {
  const { t } = useTranslation();

  const salesVatTypeOption = [
    { id: 0, value: "NO_VAT", label: "ไม่มี" },
    { id: 1, value: "VAT_0", label: "0 %" },
    { id: 2, value: "VAT_7", label: "7 %" },
  ];

  return (
    <Box sx={{ overflow: "visible" }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <CustomizedNumberTextField
            control={control}
            name={"sales_standard_price"}
            testId="item-sales-price-text-field"
            label={t("inventory.items.pricePerUnit")}
            error={Boolean(errors.sales_standard_price)}
            helperText={errors.sales_standard_price?.message}
            disabled={!isInventoryPage || viewOnly}
            textAlign="left"
            decimalScale={4}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            error={errors}
            name={"sales_maximum_discount"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                testId="item-sales-max-discount-text-field"
                type="number"
                label={t("inventory.items.maximumDiscount")}
                error={Boolean(errors.sales_maximum_discount)}
                helperText={errors.sales_maximum_discount?.message}
                disabled={!isInventoryPage}
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            error={errors}
            name={"sales_vat_type"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                testId="item-sales-vat-text-field"
                label={t("inventory.items.salesVatType")}
                options={salesVatTypeOption}
                onChange={(_, newValue) => {
                  field.onChange(newValue ? newValue.label : "");
                }}
                error={Boolean(errors.sales_vat_type)}
                helperText={errors.sales_vat_type?.message}
                disabled={!isInventoryPage || viewOnly}
              />
            )}
          />
        </Grid>
      </Grid>
      {/* <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.accounting")}
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            error={errors}
            name={"sales_account"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                label={t("inventory.items.salesAccount")}
                options={salesVatTypeOption}
                disabled={true}
              />
            )}
          />
        </Grid>
      </Grid> */}
    </Box>
  );
}
