const defaultDisabledState = {
  printOption: false,
  modifyOption: false,
  createOption: false,
  document_id: false,
  created_date: true,
  issue_date: false,
  due_date: false,
  credit_day: false,
  delivery_date: false,
  payment: false,
  external_ref_document_id: false,
  reference_document: false,
  activity_log: false,
  related_employees: false,
  accept_proof: "hide",
  delivery_detail: "hide",
  delivery_status: "hide",
  approval: false,
};

export const shouldDisable = (page, status) => {
  switch (page) {
    case "quotation":
      const quotationState = {
        ...defaultDisabledState,
        delivery_date: "hide",
        reference_document: "hide",
        payment: "hide",
      };
      switch (status) {
        case "draft":
          return { ...quotationState, createOption: true, document_id: true };
        case "PENDING":
        case "waitApprove":
          return {
            ...quotationState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "APPROVED":
        case "waitAccept":
          return {
            ...quotationState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
            accept_proof: false,
          };
        case "accepted":
          return {
            ...quotationState,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "expired":
          return {
            ...quotationState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "notApproved":
        case "DECLINED":
        case "cancelled":
          return {
            ...quotationState,
            printOption: true,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "finished":
          return {
            ...quotationState,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
          };
        default:
          return {
            ...quotationState,
            printOption: true,
            createOption: true,
            modifyOption: true,
            activity_log: "hide",
            approval: "hide",
          };
      }
    case "order":
      const orderState = {
        ...defaultDisabledState,
        payment: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...orderState,
            createOption: true,
            document_id: true,
          };
        case "PENDING":
        case "waitApprove":
          return {
            ...orderState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            delivery_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "expired":
          return {
            ...orderState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
            delivery_date: true,
          };
        case "notApproved":
        case "DECLINED":
        case "cancelled":
          return {
            ...orderState,
            printOption: true,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
            delivery_date: true,
          };
        case "APPROVED":
        case "approved":
        case "finished":
          return {
            ...orderState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            delivery_date: true,
            credit_day: true,
            related_employees: true,
          };
        default:
          return {
            ...orderState,
            printOption: true,
            modifyOption: true,
            createOption: true,
            activity_log: "hide",
            approval: "hide",
          };
      }
    case "deposit-invoice":
      const depositInvoiceState = {
        ...defaultDisabledState,
        reference_document: true,
        due_date: "hide",
        delivery_date: "hide",
        createOption: "hide",
        printOption: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...depositInvoiceState,
            payment: true,
            document_id: true,
          };
        case "PENDING":
        case "waitApprove":
          return {
            ...depositInvoiceState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
            payment: true,
          };
        case "expired":
        case "notApproved":
        case "DECLINED":
        case "cancelled":
          return {
            ...depositInvoiceState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
            payment: true,
          };
        case "waitPayment":
          return {
            ...depositInvoiceState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "fullyPaid":
          return {
            ...depositInvoiceState,
            document_id: true,
            external_ref_document_id: true,
            payment: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
          };
        default:
          return {
            ...depositInvoiceState,
            printOption: true,
            modifyOption: true,
            payment: true,
            activity_log: "hide",
            approval: "hide",
          };
      }
    case "invoice":
      const invoiceState = {
        ...defaultDisabledState,
        delivery_date: "hide",
        printOption: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...invoiceState,
            createOption: true,
            payment: true,
            document_id: true,
          };
        case "PENDING":
        case "waitApprove":
          return {
            ...invoiceState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
            payment: true,
          };
        case "expired":
        case "notApproved":
        case "DECLINED":
        case "cancelled":
          return {
            ...invoiceState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
            payment: true,
          };
        case "waitPayment":
          return {
            ...invoiceState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "fullyPaid":
          return {
            ...invoiceState,
            document_id: true,
            external_ref_document_id: true,
            payment: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
          };
        default:
          return {
            ...invoiceState,
            printOption: true,
            modifyOption: true,
            createOption: true,
            payment: true,
            activity_log: "hide",
            approval: "hide",
          };
      }
    case "payment-receipt":
      const paymentReceiptState = {
        ...defaultDisabledState,
        due_date: "hide",
        delivery_date: "hide",
        payment: "hide",
        createOption: "hide",
        printOption: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...paymentReceiptState,
            document_id: true,
          };
        case "fullyPaid":
          return {
            ...paymentReceiptState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
          };
        default:
          return {
            ...paymentReceiptState,
            printOption: true,
            modifyOption: true,
            activity_log: "hide",
            approval: "hide",
          };
      }
    case "return":
      const returnState = {
        ...defaultDisabledState,
        due_date: "hide",
        delivery_date: "hide",
        payment: "hide",
        printOption: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...returnState,
            createOption: true,
            document_id: true,
          };
        case "PENDING":
        case "waitApprove":
          return {
            ...returnState,
            document_id: true,
            external_ref_document_id: true,
            createOption: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "expired":
        case "notApproved":
        case "DECLINED":
          return {
            ...returnState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "cancelled":
          return {
            ...returnState,
            createOption: true,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            due_date: true,
            credit_day: true,
            related_employees: true,
            modifyOption: true,
          };

        case "APPROVED":
        case "approved":
        case "finished":
          return {
            ...returnState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
            modifyOption: true,
          };
        default:
          return {
            ...returnState,
            printOption: true,
            modifyOption: true,
            createOption: true,
            activity_log: "hide",
            approval: "hide",
          };
      }
    case "credit-note":
      const creditNoteState = {
        ...defaultDisabledState,
        createOption: "hide",
        due_date: "hide",
        delivery_date: "hide",
        payment: "hide",
        printOption: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...creditNoteState,
            document_id: true,
          };
        case "PENDING":
        case "waitApprove":
          return {
            ...creditNoteState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "expired":
        case "notApproved":
        case "DECLINED":
        case "cancelled":
          return {
            ...creditNoteState,
            document_id: true,
            external_ref_document_id: true,
            reference_document: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
          };
        case "APPROVED":
        case "approved":
        case "finished":
          return {
            ...creditNoteState,
            document_id: true,
            external_ref_document_id: true,
            issue_date: true,
            credit_day: true,
            related_employees: true,
          };
        default:
          return {
            ...creditNoteState,
            printOption: true,
            modifyOption: true,
            activity_log: "hide",
            approval: "hide",
          };
      }
    case "delivery-order":
      const deliveryOrderState = {
        ...defaultDisabledState,
        credit_day: "hide",
        payment: "hide",
        due_date: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...deliveryOrderState,
            document_id: true,
            createOption: true,
          };
        case "waitDeliver":
        case "notCompleted":
          return {
            ...deliveryOrderState,
            external_ref_document_id: true,
            document_id: true,
            delivery_detail: false,
            issue_date: true,
            delivery_date: true,
          };
        case "completed":
          return {
            ...deliveryOrderState,
            external_ref_document_id: true,
            document_id: true,
            issue_date: true,
            delivery_detail: "hide",
            delivery_date: true,
          };
        case "cancelled":
          return {
            ...deliveryOrderState,
            createOption: true,
            printOption: true,
          };
        default:
          return {
            ...deliveryOrderState,
            printOption: true,
            modifyOption: true,
            activity_log: "hide",
            createOption: true,
            approval: "hide",
          };
      }
    case "delivery-trip":
      const deliveryTripState = {
        ...defaultDisabledState,
        payment: "hide",
        due_date: "hide",
        credit_day: "hide",
        createOption: "hide",
      };
      switch (status) {
        case "draft":
          return {
            ...deliveryTripState,
            document_id: true,
          };
        case "waitDeliver":
          return {
            ...deliveryTripState,
            external_ref_document_id: true,
            document_id: true,
            delivery_status: false,
            issue_date: true,
            delivery_date: true,
          };
        case "notCompleted":
        case "completed":
        case "delivering":
          return {
            ...deliveryTripState,
            external_ref_document_id: true,
            document_id: true,
            issue_date: true,
            delivery_date: true,
            delivery_status: false,
          };
        case "cancelled":
          return {
            ...deliveryTripState,
            createOption: true,
            printOption: true,
          };
        default:
          return {
            ...deliveryTripState,
            printOption: true,
            modifyOption: true,
            activity_log: "hide",
            approval: "hide",
          };
      }
    default:
      return defaultDisabledState;
  }
};

export default defaultDisabledState;
