import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    isLoading: {
      allLocation: false,
      selectedLocation: false,
    },
    allLocation: [],
    selectedLocation: [],
    title: "",
    modal: false,
    error: null,
  },
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload] = false;
      state.error = action.payload;
    },
    loadedAllLocation(state, action) {
      state.allLocation = action.payload;
      state.isLoading.allLocation = false;
    },
    loadedSelectedLocation(state, action) {
      state.selectedLocation = action.payload;
      state.isLoading.selectedLocation = false;
    },
    openModal(state) {
      state.modal = true;
    },
    closeModal(state) {
      state.modal = false;
    },
    changeTitle(state, action) {
      state.title = action.payload;
    },
    resetTitle(state) {
      state.title = "";
    },
    resetAllLocation(state, action) {
      state.allLocation = [];
    },
    resetSelectedLocation(state, action) {
      state.selectedLocation = [];
    },
  },
});

export const locationActions = locationSlice.actions;

export default locationSlice.reducer;
