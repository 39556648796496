import moment from "moment";
import { dateToUnix } from "../../utils/date-converter";
import {
  graphQLClientWithHeader,
  graphQLClientReportWithHeader,
} from "../graphQL";
import { DOCUMENT_NEXT_STATUS } from "../Sales/mutation";
import {
  CREATE_DELIVERY_ORDER,
  CREATE_DELIVERY_TRIP,
  UPDATE_DELIVERY_ORDER,
  UPDATE_DELIVERY_TRIP,
} from "./mutation";
import {
  GET_ALL_DELIVERY_ORDERS,
  GET_ALL_DELIVERY_ORDER_IDS,
  GET_ALL_DELIVERY_ORDER_REPORT,
  GET_ALL_DELIVERY_TRIPS,
  GET_ALL_DELIVERY_TRIPS_VEHICLE,
  GET_DELIVERY_ORDER,
  GET_DELIVERY_TRIP,
  GET_DELIVERY_TRIP_REPORT,
} from "./queries";

class LogisticService {
  async postNextStatus(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentNextStatus } = await graphQLClient.request(
      DOCUMENT_NEXT_STATUS,
      {
        documentInput,
      }
    );
    return documentNextStatus;
  }

  // Delivery Order
  async getAllDeliverOrdersIds() {
    const graphQLClient = graphQLClientWithHeader();
    const { deliveryOrders } = await graphQLClient.request(
      GET_ALL_DELIVERY_ORDER_IDS
    );
    return deliveryOrders;
  }
  async getAllDeliveryOrders(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { deliveryOrdersAggrid } = await graphQLClient.request(
      GET_ALL_DELIVERY_ORDERS,
      {
        aggridInput,
      }
    );
    return deliveryOrdersAggrid;
  }

  async getAllNewDeliveryOrdersReport(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { deliveryOrderReport } = await graphQLClient.request(
      GET_ALL_DELIVERY_ORDER_REPORT,
      {
        aggridInput,
      }
    );
    return deliveryOrderReport;
  }

  async getAllDeliveryOrdersReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { deliveryOrdersAggrid } = await graphQLClient.request(
      GET_ALL_DELIVERY_ORDERS,
      {
        aggridInput,
      }
    );
    return deliveryOrdersAggrid;
  }

  async getDeliveryOrderById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { deliveryOrder } = await graphQLClient.request(GET_DELIVERY_ORDER, {
      uniqueInput,
    });
    return deliveryOrder;
  }

  async createDeliveryOrder(createDeliveryOrderInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createDeliveryOrder } = await graphQLClient.request(
      CREATE_DELIVERY_ORDER,
      {
        createDeliveryOrderInput,
      }
    );
    return createDeliveryOrder;
  }

  async updateDeliveryOrder(updateDeliveryOrderInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateDeliveryOrder } = await graphQLClient.request(
      UPDATE_DELIVERY_ORDER,
      {
        updateDeliveryOrderInput,
      }
    );
    return updateDeliveryOrder;
  }

  async getAllDeliveryTrips(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { deliveryTripsAggrid } = await graphQLClient.request(
      GET_ALL_DELIVERY_TRIPS,
      {
        aggridInput,
      }
    );
    return deliveryTripsAggrid;
  }
  async getAllDeliveryTripsReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { deliveryTripsAggrid } = await graphQLClient.request(
      GET_ALL_DELIVERY_TRIPS,
      {
        aggridInput,
      }
    );
    return deliveryTripsAggrid;
  }

  async getAllNewDeliveryTripsReport(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { deliveryTripReport } = await graphQLClient.request(
      GET_DELIVERY_TRIP_REPORT,
      {
        aggridInput,
      }
    );
    return deliveryTripReport;
  }

  async getAllDeliveryTripsByEmployee(document_id_list, issue_date) {
    const graphQLClient = graphQLClientWithHeader();
    const { deliveryTripsAggrid } = await graphQLClient.request(
      GET_ALL_DELIVERY_TRIPS_VEHICLE,
      {
        aggridInput: {
          startRow: 0,
          endRow: 10,
          filterModel: {
            goods_deliver_list: {
              type: "some",
              filterType: "objectArray",
              filter: {
                document_id: {
                  filterType: "set",
                  values: document_id_list,
                },
              },
            },
            issue_date: issue_date && {
              filter: dateToUnix(moment(issue_date).startOf("day")),
              filterTo: dateToUnix(moment(issue_date).endOf("day")),
              filterType: "number",
              type: "inRange",
            },
          },
          sortModel: [],
        },
      }
    );
    return deliveryTripsAggrid;
  }

  async getAllDeliveryTripsByVehicle(license_plate_list, issue_date) {
    const graphQLClient = graphQLClientWithHeader();
    const { deliveryTripsAggrid } = await graphQLClient.request(
      GET_ALL_DELIVERY_TRIPS_VEHICLE,
      {
        aggridInput: {
          startRow: 0,
          endRow: 10,
          filterModel: {
            vehicle: {
              filterType: "object",
              filter: {
                license_plate: {
                  filterType: "set",
                  values: license_plate_list,
                },
              },
            },
            issue_date: issue_date && {
              filterType: "number",
              type: "inRange",
              filter: dateToUnix(moment(issue_date).startOf("day")),
              filterTo: dateToUnix(moment(issue_date).endOf("day")),
            },
          },
          sortModel: [],
        },
      }
    );
    return deliveryTripsAggrid;
  }

  async getDeliveryTripById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { deliveryTrip } = await graphQLClient.request(GET_DELIVERY_TRIP, {
      uniqueInput,
    });
    return deliveryTrip;
  }

  async createDeliveryTrip(createDeliveryTripInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createDeliveryTrip } = await graphQLClient.request(
      CREATE_DELIVERY_TRIP,
      {
        createDeliveryTripInput,
      }
    );
    return createDeliveryTrip;
  }

  async updateDeliveryTrip(updateDeliveryTripInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateDeliveryTrip } = await graphQLClient.request(
      UPDATE_DELIVERY_TRIP,
      {
        updateDeliveryTripInput,
      }
    );
    return updateDeliveryTrip;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LogisticService();
