import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";

function LinkTab(props) {
  return (
    <Tab
      sx={{
        color: props.errors ? "#F44336 !important" : "",
        textTransform: props.notransform ? "none" : "uppercase",
      }}
      component={Link}
      {...props}
    />
  );
}

export default function CustomizedTab({
  tabs,
  currentTab,
  centered,
  divider,
  table,
  subtab,
  scrollable,
}) {
  //table is remove margin when tab on top table
  //subtab is tab inside tab for layout remove margin as table
  return (
    <Box sx={{ textAlign: centered ? "-webkit-center" : 0 }}>
      <Tabs
        variant={"scrollable"}
        // centered={centered}
        value={currentTab}
        aria-label="nav tabs example"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: divider ? "100%" : "fit-content",
          margin: table || subtab ? "" : "2rem 0",
        }}
        TabIndicatorProps={{
          sx: {
            backgroundColor: "#419644",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <LinkTab
            key={index}
            data-test-id={tab.testId}
            label={tab.label}
            value={tab.path}
            to={tab.path}
            errors={tab.errors}
            notransform={tab.notransform}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>
    </Box>
  );
}
