import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import UserTable from "../../../components/Table/UserTable";
import { useNavigate } from "react-router-dom";
import { usePermission } from "../../../hooks/use-auth";
import CheckedEmployeeModal from "../../../components/Table/CheckedEmployeeTable";

const EmployeeTab = ({ fields, replace, remove, viewOnly, getValues }) => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState([]);
  const { createPermission, editPermission } = usePermission();

  useEffect(() => {
    const employeeDocumentId = fields.map((emp) => emp.document_id);

    setEmployeeIds(employeeDocumentId);
    setEmployeeIdsSnapshot(employeeDocumentId);
  }, [fields]);

  const handleCloseModal = () => setOpenModal(false);

  const handleSelectEmployees = (data) => {
    let filteredEmployees = fields.filter((employee) =>
      employeeIds.includes(employee.document_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.document_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.document_id)) {
        filteredEmployees.push(employee);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        employeeIds.indexOf(a.document_id) - employeeIds.indexOf(b.document_id)
      );
    });
    replace(filteredEmployees);
    setOpenModal(false);
  };

  const handleDelete = (index) => {
    remove(index);
    setEmployeeIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setEmployeeIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const onRowClicked = (e) => {
    navigate(`../../account/${e.data.document_id}`, { replace: true });
  };

  return (
    <>
      <Typography variant="h5" sx={{ mt: 3, mb: viewOnly && 3 }}>
        {t("user.rbac.permittedEmployee")}
      </Typography>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        sx={{ my: 3 }}
        spacing={2}
      >
        <Grid item>
          {((createPermission && !viewOnly) ||
            (editPermission && viewOnly)) && (
            <CustomizedButton
              title="เพิ่มพนักงาน"
              variant="contained"
              onClick={() => setOpenModal(true)}
            />
          )}
        </Grid>
      </Grid>
      <CheckedEmployeeModal
        showSelectEmployees={openModal}
        closeEmployeeTable={handleCloseModal}
        finishEmployeesSelect={handleSelectEmployees}
        employeeIds={employeeIds}
        setEmployeeIds={setEmployeeIds}
        employeeIdsSnapshot={employeeIdsSnapshot}
        setEmployeeIdsSnapshot={setEmployeeIdsSnapshot}
      />
      <UserTable
        gridRef={gridRef}
        height={650}
        rowData={fields || []}
        onRowClicked={onRowClicked}
        handleDelete={handleDelete}
      />
      {((createPermission && !viewOnly) || (editPermission && viewOnly)) && (
        <CustomizedButton
          title="บันทึก"
          variant="contained"
          type="submit"
          sx={{ my: 2 }}
        />
      )}
    </>
  );
};

export default EmployeeTab;
