import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ControlledTextField from "../../../../Controlled/ControlledTextField";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import ModalUI from "../../../../UI/ModalUI";
import ContactForm from "../../../Contact";
import {
  schema as contactSchema,
  validation as contactValidation,
} from "../../../Contact/schema";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import CheckboxModalTable from "../../../../Table/CheckboxModalTable";
import { contactColumnDefs } from "../../../../Table/ColumnDefs/Contact";
import ContactService from "../../../../../services/Contact";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { contactActions } from "../../../../../features/Contact/contact-slice";

const CustomerDetail = ({ control, errors, disabled, setValue }) => {
  const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  }));
  const dispatch = useDispatch();
  const { contactSnapshot } = useSelector((state) => state.contact);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showContactForm, setShowContactForm] = useState(false);

  const selectCustomerGridRef = useRef();

  const [showSelectCustomer, setShowSelectCustomer] = useState(false);

  const [customerIds, setCustomerIds] = useState([]);
  const [customerIdsSnapshot, setCustomerIdsSnapshot] = useState([]);

  const watchCustomer = useWatch({ control, name: "customer" });
  const watchCustomerId = useWatch({ control, name: "contact_document_id" });

  const [contactsGroupsList, setContactsGroupsList] = useState([]);

  const openCustomerTable = () => {
    setShowSelectCustomer(true);
  };

  const closeCustomerTable = () => {
    setShowSelectCustomer(false);
  };

  const openContactForm = async () => {
    setShowContactForm(true);
  };

  const closeContactForm = () => {
    setShowContactForm(false);
    searchParams.delete("contactTab");
    setSearchParams(searchParams);
    dispatch(contactActions.uninitialize());
  };

  const {
    control: contactControl,
    setValue: setContactValue,
    getValues: getContactValues,
    watch: watchContact,
    formState: { errors: contactErrors },
    reset: resetContact,
  } = useForm({
    defaultValues: { ...contactSchema },
    resolver: yupResolver(contactValidation),
  });

  useEffect(() => {
    const getAllContactsGroupsList = async () => {
      const contactsGroupsList = await ContactService.getAllContactsGroups();
      setContactsGroupsList(contactsGroupsList);
    };
    getAllContactsGroupsList();
  }, []);

  useEffect(() => {
    if (watchCustomerId) {
      setCustomerIds([watchCustomerId]);
      setCustomerIdsSnapshot([watchCustomerId]);
      const getContact = async () => {
        const contactData = await ContactService.getContact({
          document_id: watchCustomerId,
        });
        setValue(
          "customer",
          `${contactData.document_id} - ${contactData.contact_name_1}${
            contactData.contact_name_2 ? ` ${contactData.contact_name_2}` : ""
          }`
        );
        dispatch(contactActions.initialize(contactData));
      };
      getContact();
    }
  }, [watchCustomerId, dispatch, setValue]);

  useEffect(() => {
    if (contactSnapshot) {
      resetContact({
        ...contactSnapshot,
        created_date: moment.unix(contactSnapshot.created_date).toDate(),
        contact_type_2: "",
        registered_capital: contactSnapshot.registered_capital ?? "",
        estimate_sales_volume: contactSnapshot.estimate_sales_volume ?? "",
        finance: {
          ...contactSnapshot.finance,
          billing_day: contactSnapshot.finance.billing_day ?? "",
          payment_day: contactSnapshot.finance.payment_day ?? "",
          request_credit: {
            credit_limit_day:
              contactSnapshot.finance.request_credit.credit_limit_day ?? "",
            credit_limit_value:
              contactSnapshot.finance.request_credit.credit_limit_value ?? "",
          },
        },
      });
    }
  }, [contactSnapshot, resetContact]);

  const contactGroupClickHandler = (group) => {
    if (selectCustomerGridRef.current.api) {
      const instance =
        selectCustomerGridRef.current.api.getFilterInstance("contact_group");
      instance.setModel({ filterType: "set", values: [group] });
      selectCustomerGridRef.current.api.onFilterChanged();
    }
  };

  const datasource = {
    async getRows(params) {
      const request = params.request;
      try {
        const allContacts = await ContactService.getAllContactsAgGrid(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: {
              ...request.filterModel,
              is_customer: {
                filterType: "boolean",
                type: "equals",
                filter: "true",
              },
              contact_group: request.filterModel.contact_group
                ? {
                    filterType: "array",
                    type: "hasSome",
                    values: request.filterModel.contact_group?.values || [],
                  }
                : undefined,
            },
            sortModel: request.sortModel,
          },
          params
        );
        params.successCallback(allContacts.results, allContacts.count);
      } catch (err) {
        console.error(err);
        params.failCallback();
      }
    },
  };

  const onFirstDataRendered = () => {
    const instance =
      selectCustomerGridRef.current.api.getFilterInstance("contact_status");
    instance.setModel({ filterType: "set", values: ["active"] });
    selectCustomerGridRef.current.api.onFilterChanged();
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const finishCustomerSelectHandler = (data) => {
    if (data) {
      setValue("contact_document_id", data.document_id);
      setValue(
        "customer",
        `${data.document_id} - ${data.contact_name_1}${
          data.contact_name_2 ? ` ${data.contact_name_2}` : ""
        }`
      );
    } else {
      setValue("contact_document_id", "");
      setValue("customer", "");
    }
    dispatch(contactActions.uninitialize());
    setShowSelectCustomer(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography ml={1} fontWeight="bold">
          {t("manufacture.order.customer_detail")}
        </Typography>
        {!disabled && (
          <CustomizedTooltip title="เลือกลูกค้า">
            <IconButton
              onClick={openCustomerTable}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="customer"
            label={t("manufacture.order.customer")}
            control={control}
            error={Boolean(errors?.customer)}
            helperText={errors?.customer && errors?.customer.message}
            fullWidth
            disabled
            required
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={openContactForm}>
                    {watchCustomer || t("manufacture.order.customer")}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && watchCustomerId && (
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดลูกค้า">
                      <IconButton
                        onClick={openContactForm}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <CheckboxModalTable
        modalTitle={t("sales.customer")}
        btnTitle={t("button.add")}
        gridRef={selectCustomerGridRef}
        height={450}
        columnDefs={contactColumnDefs(
          t,
          contactsGroupsList,
          contactGroupClickHandler
        )}
        rowSelection="single"
        onFinishEditing={finishCustomerSelectHandler}
        modalIsOpen={showSelectCustomer}
        closeModal={closeCustomerTable}
        onGridReady={onGridReady}
        selectedIds={customerIds}
        setSelectedIds={setCustomerIds}
        idsSnapshot={customerIdsSnapshot}
        setIdsSnapshot={setCustomerIdsSnapshot}
        onFirstDataRendered={onFirstDataRendered}
      />
      <ModalUI
        open={showContactForm}
        navigateTo={`/contact/edit/${watchCustomerId}`}
        handleClose={closeContactForm}
        fullWidth
        title="ลูกค้า"
        maxWidth="lg"
      >
        <ContactForm
          control={contactControl}
          setValue={setContactValue}
          getValues={getContactValues}
          watch={watchContact}
          errors={contactErrors}
          disableModes={true}
          reset={resetContact}
          isContactPage={false}
        />
      </ModalUI>
    </>
  );
};

export default CustomerDetail;
