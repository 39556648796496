import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import UserTable from "../../../components/Table/UserTable";
import { getAllUsers } from "../../../features/User/Account/account-actions";
import { usePermission } from "../../../hooks/use-auth";

const UserAccount = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isFilter = searchParams.get("filter");
  const { t } = useTranslation();
  const { createPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
    },
  ];

  const tabs = [
    {
      label: t("user.account.tab.index"),
      path: `${pathname}`,
    },
    {
      label: t("user.account.tab.sales"),
      path: `${pathname}?filter=sales`,
    },
    {
      label: t("user.account.tab.inventory"),
      path: `${pathname}?filter=inventory`,
    },
    {
      label: t("user.account.tab.accounting"),
      path: `${pathname}?filter=accounting`,
    },
    {
      label: t("user.account.tab.purchase"),
      path: `${pathname}?filter=purchase`,
    },
    {
      label: t("user.account.tab.management"),
      path: `${pathname}?filter=management`,
    },
    {
      label: t("user.account.tab.logistic"),
      path: `${pathname}?filter=logistic`,
    },
    {
      label: t("user.account.tab.manufacture"),
      path: `${pathname}?filter=manufacture`,
    },
    {
      label: t("user.account.tab.engineer"),
      path: `${pathname}?filter=engineer`,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      dispatch(
        getAllUsers(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: request.filterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const onRowDoubleClicked = (params) => {
    navigate(`${pathname}/${params.data.document_id}`);
  };

  // To be continued with onChange
  const updateTableFilter = (filter) => {
    const instance = gridRef.current.api.getFilterInstance("department");
    switch (filter) {
      case "sales":
        instance.setModel({ values: ["รายรับ"] });
        break;
      case "inventory":
        instance.setModel({ values: ["คลังสินค้า"] });
        break;
      case "accounting":
        instance.setModel({ values: ["บัญชี"] });
        break;
      case "purchase":
        instance.setModel({ values: ["จัดซื้อ"] });
        break;
      case "management":
        instance.setModel({ values: ["บริหาร"] });
        break;
      case "logistic":
        instance.setModel({ values: ["ขนส่ง"] });
        break;
      case "manufacture":
        instance.setModel({ values: ["ผลิต"] });
        break;
      case "engineer":
        instance.setModel({ values: ["ดูแลระบบ"] });
        break;
      default:
        instance.setModel({});
        break;
    }
    gridRef.current.api.onFilterChanged();
  };

  useEffect(() => {
    if (gridRef.current.api) {
      updateTableFilter(isFilter);
    }
  }, [isFilter]);

  const onFirstDataRendered = () => {
    updateTableFilter(isFilter);
  };

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("user.account.index")}</Typography>
        {createPermission && (
          <CustomizedButton
            title={t("user.account.addNewAccount")}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
        )}
      </Box>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <UserTable
        gridRef={gridRef}
        height={650}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        onRowDoubleClicked={onRowDoubleClicked}
        enableRangeSelection={true}
        rowSelection={"multiple"}
        path={pathname}
      />
    </>
  );
};

export default UserAccount;
