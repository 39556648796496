import { useState, useCallback, useRef, useEffect, forwardRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  // InputAdornment,
  Avatar,
} from "@mui/material";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useTranslation } from "react-i18next";
import ControlledDatePicker from "../../../Custom/ControlledDatePicker";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedMenuOption from "../../../Custom/CustomizedMenuOption";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CheckboxModalTable from "../../../Table/CheckboxModalTable";
import { useAuth, usePermission } from "../../../../hooks/use-auth";
import GlobalService from "../../../../services/Global/index";
import RelatedEmployeeIcon from "./RelatedEmployeeIcon";
import { getAllUsers } from "../../../../features/User/Account/account-actions";
import { modifyOptions } from "./modifyOptions";
import { createOptions } from "./createOptions";
import { shouldDisable } from "./shouldDisable";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import CopyConfirmation from "../../../UI/Confirmation/CopyConfirmation";
import CancelConfirmation from "../../../UI/Confirmation/CancelConfirmation";
import { cancelQuotation } from "../../../../features/Sales/Quotation/quotation-actions";
import { useSnackbar } from "notistack";
import { cancelSalesOrder } from "../../../../features/Sales/SalesOrder/sales-order-actions";
import { cancelSalesInvoice } from "../../../../features/Sales/SalesInvoice/sales-invoice-actions";
import { cancelDepositInvoice } from "../../../../features/Sales/DepositInvoice/deposit-invoice-actions";
import { cancelSalesReturn } from "../../../../features/Sales/SalesReturn/sales-return-actions";
import { cancelCreditNote } from "../../../../features/Sales/CreditNote/credit-note-actions";
import RightDrawer from "../../../UI/RightDrawer";
import { getDocumentName } from "../../../../utils/dataTransformer";
import CustomizedMuliChip from "../../../Custom/CustomizedMuliChip";
import { cancelDeliveryOrder } from "../../../../features/Logistic/DeliveryOrder/delivery-order-actions";
import { cancelDeliveryTrip } from "../../../../features/Logistic/DeliveryTrip/delivery-trip-actions";
import ModalUI from "../../../UI/ModalUI";
import ItemListTable from "../../../Table/DocumentTable/ItemListTable";
import InventoryService from "../../../../services/Inventory";

const SalesHeaderForm = forwardRef(
  (
    {
      control,
      errors,
      getValues,
      setValue,
      getMainValues,
      setMainValue,
      mainFormControl,
      viewOnly,
      documentType,
      editClickHandler,
      currentState,
      onAcceptProofOpenHandler,
      onDeliveryDetailOpenHandler,
      isEdit,
      approvalList,
      setOpenSalesInvoiceConfirmation,
      referenceDeleteHandler,
      setStep,
      setOpenChangeStatus,
      gridRef,
      setIsEdit,
    },
    ref
  ) => {
    const itemTableGridRef = useRef();
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const { user, permissions } = useAuth();
    const selectEmployeesGridRef = useRef();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { salesOrder } = useSelector((state) => state.salesOrder);
    const { editPermission } = usePermission();
    const watchStatus = useWatch({
      control: mainFormControl,
      name: "render_status",
    });
    const targetStatus = useWatch({
      control: mainFormControl,
      name: "target_status",
    });
    const [disabledCreateOption, setDisabledCreateOption] = useState({
      sales_return: false,
      delivery_order: false,
      delivery_trip: false,
      goods_receive: false,
      goods_issue: false,
    });

    const isExpired = useWatch({
      control: mainFormControl,
      name: "is_late",
    });

    const deliveryStatusOptions = [
      { value: t("status.delivering"), disabled: !editPermission },
      { value: t("status.completed"), disabled: !editPermission },
      { value: t("status.notCompleted"), disabled: !editPermission },
    ];

    const status = watchStatus === "expired" ? targetStatus : watchStatus;

    const { enqueueSnackbar } = useSnackbar();

    const watchRelatedEmployees = useWatch({
      control,
      name: "employee_list",
    });

    const watchReferenceDocumentId = useWatch({
      control,
      name: "reference_document_id",
    });

    const watchCreatorDocumentId = useWatch({
      control: mainFormControl,
      name: "creator_document_id",
    });

    const watchCreatedBy = useWatch({
      control: mainFormControl,
      name: "created_by",
    });

    const [showSelectEmployees, setShowSelectEmployees] = useState(false);
    const [employeeIds, setEmployeeIds] = useState([]);
    const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState([]);

    const [createdAtDateisOpen, setCreatedAtDateisOpen] = useState(false);
    const [issueDateIsOpen, setIssueDateIsOpen] = useState(false);
    const [dueDateIsOpen, setDueDateIsOpen] = useState(false);
    const [deliveryDateIsOpen, setDeliveryDateIsOpen] = useState(false);
    const [isApproval, setIsApproval] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(false);

    const currentPage = pathname.split("/")[2];

    const [fieldsToDisable, setFieldsToDisable] = useState(
      shouldDisable(currentPage, status)
    );
    const [selectCreateOptions, setSelectCreateOptions] = useState(
      createOptions(currentPage, status, permissions, disabledCreateOption)
    );
    const [selectModifyOptions, setSelectModifyOptions] = useState(
      modifyOptions(currentPage, status, permissions)
    );

    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [copyConfirmation, setCopyConfirmation] = useState(false);

    const { fields, remove } = useFieldArray({
      control,
      name: "employee_list",
    });

    const printOptions = ["พิมพ์เอกสาร"];

    useEffect(() => {
      if (fieldsToDisable.due_date === "hide" || fieldsToDisable.due_date) {
        return;
      }
      if (fieldsToDisable.issue_date === "hide" || fieldsToDisable.issue_date) {
        return;
      }
    }, [fieldsToDisable]);

    useEffect(() => {
      if (!watchCreatorDocumentId) {
        if (user) {
          setMainValue("creator_document_id", user.document_id);
          setMainValue("created_by", {
            document_id: user.document_id,
            first_name: user.first_name,
            last_name: user.last_name,
            img_url: user.img_url,
          });
        }
      }
    }, [watchCreatorDocumentId, setMainValue, user]);

    useEffect(() => {
      if (
        pathname.split("/").at(-1) === "add" &&
        watchRelatedEmployees &&
        watchRelatedEmployees.length === 0
      ) {
        // setValue("employee_list", [{ ...user }]);
        if (user) {
          setEmployeeIds([user.document_id]);
          setEmployeeIdsSnapshot([user.document_id]);
        }
      } else {
        if (watchCreatorDocumentId) {
          const mappedEmployeeIds =
            getValues("employee_list")?.map(
              (employee) => employee?.document_id
            ) ?? [];
          setEmployeeIds([watchCreatorDocumentId, ...mappedEmployeeIds]);
          setEmployeeIdsSnapshot([
            watchCreatorDocumentId,
            ...mappedEmployeeIds,
          ]);
        }
      }
    }, [
      pathname,
      watchRelatedEmployees,
      watchCreatorDocumentId,
      user,
      setValue,
      getValues,
    ]);

    useEffect(() => {
      if (
        documentType === "sales_order" &&
        (status === "approved" || status === "finished")
      ) {
        const getDisabledOnQty = async () => {
          const totalItemListQty = getMainValues("item_list").reduce(
            (prev, curr) => prev + curr.qty,
            0
          );

          const itemListToSalesReturn =
            await GlobalService.getDocumentRelationQty({
              document_id: id,
              document_type: "sales_order",
              reference_document_type: "sales_return",
            });
          const totalSalesReturnQty = itemListToSalesReturn.reduce(
            (prev, curr) => prev + curr.accumulate_return,
            0
          );
          if (totalSalesReturnQty >= totalItemListQty) {
            setDisabledCreateOption((prev) => ({
              ...prev,
              sales_return: true,
            }));
          }

          const itemListToDeliveryOrder =
            await GlobalService.getDocumentRelationQty({
              document_id: id,
              document_type: "sales_order",
              reference_document_type: "delivery_order",
            });
          const totalDeliveryOrderQty = itemListToDeliveryOrder.reduce(
            (prev, curr) => prev + curr.accumulate_delivery,
            0
          );
          if (totalDeliveryOrderQty >= totalItemListQty) {
            setDisabledCreateOption((prev) => ({
              ...prev,
              delivery_order: true,
            }));
          }

          const itemListToGoodsIssue =
            await GlobalService.getDocumentRelationQty({
              document_id: id,
              document_type: "sales_order",
              reference_document_type: "goods_issue",
            });

          const totalGoodsIssueQty = itemListToGoodsIssue.reduce(
            (prev, curr) => prev + curr.accumulate_issue,
            0
          );
          if (totalGoodsIssueQty >= totalItemListQty) {
            setDisabledCreateOption((prev) => ({
              ...prev,
              goods_issue: true,
            }));
          }
        };
        getDisabledOnQty();
      } else if (
        documentType === "sales_return" &&
        (status === "approved" || status === "finished")
      ) {
        const getDisabledOnQty = async () => {
          const totalItemListQty = getMainValues("item_list").reduce(
            (prev, curr) => prev + curr.qty_return,
            0
          );

          const itemListToGoodsReceive =
            await GlobalService.getDocumentRelationQty({
              document_id: id,
              document_type: "sales_return",
              reference_document_type: "goods_receive",
            });
          const totalGoodsReceiveQty = itemListToGoodsReceive.reduce(
            (prev, curr) => prev + curr.accumulate_receive,
            0
          );
          if (totalGoodsReceiveQty >= totalItemListQty) {
            setDisabledCreateOption((prev) => ({
              ...prev,
              goods_receive: true,
            }));
          }
        };
        getDisabledOnQty();
      } else if (
        documentType === "delivery_order" &&
        (status === "waitDeliver" ||
          status === "completed" ||
          status === "notCompleted")
      ) {
        const getDisabledOnQty = async () => {
          const totalItemListQty = getMainValues("item_list").reduce(
            (prev, curr) => prev + curr.qty,
            0
          );

          const itemListToDeliveryTrip =
            await GlobalService.getDocumentRelationQty({
              document_id: id,
              document_type: "delivery_order",
              reference_document_type: "delivery_trip",
            });
          const totalDeliveryTripQty = itemListToDeliveryTrip.reduce(
            (prev, curr) => prev + curr.accumulate_delivery,
            0
          );
          if (totalDeliveryTripQty >= totalItemListQty) {
            setDisabledCreateOption((prev) => ({
              ...prev,
              delivery_trip: true,
            }));
          }
        };
        getDisabledOnQty();
      }
      return () => {
        setDisabledCreateOption({
          sales_return: false,
          delivery_order: false,
          delivery_trip: false,
          goods_receive: false,
          goods_issue: false,
        });
      };
    }, [documentType, getMainValues, id, status]);

    useEffect(() => {
      setFieldsToDisable(shouldDisable(currentPage, status));
      setSelectCreateOptions(
        createOptions(currentPage, status, permissions, disabledCreateOption)
      );
      setSelectModifyOptions(modifyOptions(currentPage, status, permissions));
    }, [currentPage, status, permissions, disabledCreateOption]);

    const generateDocumentId = useCallback(async () => {
      if (viewOnly) {
        return;
      }
      try {
        setValue("document_id", "");
        const document_id = await GlobalService.getRunningDocumentId(
          documentType
        );
        setValue("document_id", document_id);
      } catch (err) {
        console.error(err);
      }
    }, [documentType, setValue, viewOnly]);

    useEffect(() => {
      if (pathname.split("/").at(-1) === "add") {
        generateDocumentId();
      }
    }, [pathname, generateDocumentId]);

    const openEmployeeTable = () => {
      setShowSelectEmployees(true);
    };

    const closeEmployeeTable = () => {
      setShowSelectEmployees(false);
    };

    const getRowId = useCallback((params) => {
      return params.data.document_id;
    }, []);

    const finishEmployeesSelect = (data) => {
      // filter out employees not selected in current modal session
      let filteredEmployees = fields.filter((employee) =>
        employeeIds.includes(employee.document_id)
      );

      // get current employees id to prevent duplication when appending newly selected employees
      const filteredEmployeesId = filteredEmployees.map(
        (employee) => employee.document_id
      );

      data.forEach((employee) => {
        if (!filteredEmployeesId.includes(employee.document_id)) {
          filteredEmployees.push(employee);
        }
      });

      // sort for proerly render order (and for role assignment when submitting)
      filteredEmployees = filteredEmployees.sort((a, b) => {
        return (
          employeeIds.indexOf(a.document_id) -
          employeeIds.indexOf(b.document_id)
        );
      });
      setValue(
        "employee_list",
        filteredEmployees.filter(
          (employee) => employee.document_id !== watchCreatorDocumentId
        )
      );
      setShowSelectEmployees(false);
    };

    const getDueDateLabel = () => {
      if (currentPage === "order" && status !== "draft") {
        return "วันครบกำหนด";
      }
      return "ใช้ได้ถึง";
    };

    const removeEmployee = (index) => {
      remove(index);
      setEmployeeIds((prevIds) => [
        ...prevIds.slice(0, index),
        ...prevIds.slice(index + 1),
      ]);
      setEmployeeIdsSnapshot((prevIds) => [
        ...prevIds.slice(0, index),
        ...prevIds.slice(index + 1),
      ]);
    };

    const renderAvatars = () => {
      return fields.map((item, index) => {
        return (
          <RelatedEmployeeIcon
            key={item.id}
            first_name={item.first_name}
            last_name={item.last_name}
            index={index}
            avatar={item.img_url}
            remove={removeEmployee}
            viewOnly={viewOnly}
          />
        );
      });
    };

    const renderCreatorAvatar = () => {
      if (watchCreatorDocumentId && watchCreatedBy) {
        return (
          <RelatedEmployeeIcon
            isMain={watchCreatedBy && fields.length > 0}
            first_name={watchCreatedBy?.first_name}
            last_name={watchCreatedBy?.last_name}
            avatar={watchCreatedBy?.img_url}
          />
        );
      } else {
        return null;
      }
    };

    const columnDefs = [
      {
        field: "document_id",
        headerName: t("user.account.employeeId"),
        filter: "agTextColumnFilter",
      },
      {
        field: "img_url",
        headerName: t("user.account.picture"),
        filter: false,
        floatingFilter: false,
        cellRenderer: (params) => {
          if (params.data.img_url) {
            return <Avatar alt="img_url" src={params.data.img_url} />;
          } else {
            return (
              <CustomizedLetterAvatar
                name={params.data.first_name + " " + params.data.last_name}
              />
            );
          }
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
      },
      {
        field: "first_name",
        headerName: t("user.account.firstname"),
        filter: "agTextColumnFilter",
      },
      {
        field: "last_name",
        headerName: t("user.account.lastname"),
        filter: "agTextColumnFilter",
      },
      {
        field: "department",
        headerName: t("user.account.department"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "บริหาร",
            "คลังสินค้า",
            "รายรับ",
            "จัดซื้อ",
            "บัญชี",
            "ขนส่ง",
            "ผลิต",
            "ดูแลระบบ",
          ],
        },
      },
      {
        field: "position",
        headerName: t("user.account.position"),
        filter: "agTextColumnFilter",
      },
      {
        field: "status",
        headerName: t("user.account.status"),
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return <CustomizedStatus status={params.value} />;
        },
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    ];

    const datasource = {
      getRows(params) {
        const request = params.request;
        dispatch(
          getAllUsers(
            {
              startRow: request.startRow,
              endRow: request.endRow,
              filterModel: {
                ...request.filterModel,
                document_id: {
                  ...request.filterModel.document_id,
                  mode: "insensitive",
                },
                first_name: {
                  ...request.filterModel.first_name,
                  mode: "insensitive",
                },
                last_name: {
                  ...request.filterModel.last_name,
                  mode: "insensitive",
                },
              },
              sortModel: request.sortModel,
            },
            params
          )
        );
      },
    };

    const onGridReady = (params) => {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
      params.api.setServerSideDatasource(datasource);
    };

    const creatorRow =
      pathname.split("/").at(-1) === "add"
        ? user
          ? [user.document_id]
          : []
        : [watchCreatorDocumentId];

    const closeCancelConfirmationHandler = () => {
      setCancelConfirmation(false);
    };

    const cancelConfirmationAction = () => {
      // TODO: Cancel API
      closeCancelConfirmationHandler();
      const payload = {
        document_id: id,
        creator_document_id: user.document_id,
        render_status: status,
      };
      switch (currentPage) {
        case "quotation":
          dispatch(
            cancelQuotation(
              {
                document_type: "quotation",
                document_id: id,
              },
              payload,
              user,
              enqueueSnackbar
            )
          );
          break;
        case "order":
          dispatch(
            cancelSalesOrder(
              {
                document_type: "sales_order",
                document_id: id,
              },
              payload,
              user,
              enqueueSnackbar,
              getMainValues("render_status"),
              getMainValues("item_list"),
              setMainValue,
              gridRef
            )
          );
          break;
        case "invoice":
          dispatch(
            cancelSalesInvoice(
              {
                document_type: "sales_invoice",
                document_id: id,
              },
              payload,
              enqueueSnackbar
            )
          );
          break;
        case "deposit-invoice":
          dispatch(
            cancelDepositInvoice(
              {
                document_type: "deposit_invoice",
                document_id: id,
              },
              payload,
              enqueueSnackbar
            )
          );
          break;
        case "return":
          dispatch(
            cancelSalesReturn(
              {
                document_type: "sales_return",
                document_id: id,
              },
              payload,
              user,
              enqueueSnackbar
            )
          );
          break;
        case "credit-note":
          dispatch(
            cancelCreditNote(
              {
                document_type: "credit_note",
                document_id: id,
              },
              payload,
              enqueueSnackbar
            )
          );
          break;
        case "delivery-order":
          dispatch(
            cancelDeliveryOrder(
              {
                document_type: "delivery_order",
                document_id: id,
              },
              payload,
              user,
              enqueueSnackbar
            )
          );
          break;
        case "delivery-trip":
          dispatch(
            cancelDeliveryTrip(
              {
                document_type: "delivery_trip",
                document_id: id,
              },
              payload,
              user,
              enqueueSnackbar
            )
          );
          break;
        default:
          break;
      }
    };

    const closeConfirmationHandler = () => {
      setCopyConfirmation(false);
    };

    const copyConfirmationAction = () => {
      switch (documentType) {
        case "quotation":
          navigate("/sales/quotation/add", {
            state: { ...currentState, isCopied: true },
          });
          break;
        case "sales_order":
          navigate("/sales/order/add", {
            state: {
              ...currentState,
              isCopied: true,
            },
          });
          break;
        case "sales_invoice":
          navigate("/sales/invoice/add", {
            state: {
              ...currentState,
              isCopied: true,
            },
          });
          break;
        case "deposit_invoice":
          navigate("/sales/deposit-invoice/add", {
            state: { ...currentState, isCopied: true },
          });
          break;
        case "sales_return":
          navigate("/sales/return/add", {
            state: {
              ...currentState,
              isCopied: true,
            },
          });
          break;
        case "credit_note":
          navigate("/sales/credit-note/add", {
            state: {
              ...currentState,
              isCopied: true,
            },
          });
          break;
        case "delivery_order":
          navigate("/logistic/delivery-order/add", {
            state: {
              ...currentState,
              isCopied: true,
            },
          });
          break;
        case "delivery_trip":
          navigate("/logistic/delivery-trip/add", {
            state: {
              ...currentState,
              isCopied: true,
            },
          });
          break;
        default:
      }
      enqueueSnackbar("คัดลอกสำเร็จ", {
        variant: "success",
      });
    };

    const openApprovalLogHandler = () => {
      setOpenDrawer(true);
      setIsApproval(true);
    };

    const referenceNavigation = (currentDocument, currentDocumentId) => {
      switch (currentDocument) {
        case "quotation":
          window.open(
            `/sales/quotation/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "sales_order":
          window.open(
            `/sales/order/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "deposit_invoice":
          window.open(
            `/sales/deposit-invoice/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "sales_invoice":
          window.open(
            `/sales/invoice/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "payment_receive":
          window.open(
            `/sales/payment-receipt/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "sales_return":
          window.open(
            `/sales/return/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "credit_note":
          window.open(
            `/sales/credit-note/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        case "delivery_order":
          window.open(
            `/logistic/delivery-order/${encodeURIComponent(currentDocumentId)}`,
            "_blank"
          );
          break;
        default:
          return "";
      }
    };

    const selectItemHandler = async () => {
      const selectedItems = itemTableGridRef.current.api.getSelectedRows();

      const { delivery_date, contact_document_id, document_id } = currentState;
      const {
        item_document_id,
        width_length_tolerance_negative,
        width_length_tolerance_positive,
        thickness_tolerance_negative,
        thickness_tolerance_positive,
        qty_uom,
        item_name,
        item_remark,
        item_description,
        uom_group,
      } = selectedItems[0];

      const item = await InventoryService.getItem({
        document_id: item_document_id,
      });

      let bom_id = "";
      let bom_name = "";
      let bom_detail = null;
      let formatItemList = [];

      if (item.main_bom) {
        bom_id = item.main_bom.id;
        bom_name = item.main_bom.name;
        bom_detail = {
          ...item.main_bom,
          item: {
            manufacture_minimum_qty: item.manufacture_minimum_qty,
          },
        };

        const allItemsDocumentId = item?.main_bom?.ingredient_list?.map(
          (item) => item.item_document_id
        );

        const items = await InventoryService.getAllItems({
          findManyInput: {
            where: {
              document_id: {
                in: allItemsDocumentId,
              },
            },
          },
        });

        item.main_bom.ingredient_list?.forEach((item) => {
          const foundItemIndex = items.findIndex(
            (realItem) => realItem.document_id === item.item_document_id
          );
          formatItemList.push({
            ...item,
            current_ordered_purchase_qty:
              items[foundItemIndex]?.current_ordered_purchase_qty,
            current_ordered_manufacture_qty:
              items[foundItemIndex]?.current_ordered_manufacture_qty,
            current_committed_sales_qty:
              items[foundItemIndex]?.current_committed_sales_qty,
            current_committed_manufacture_qty:
              items[foundItemIndex]?.current_committed_manufacture_qty,
            current_stock_qty: items[foundItemIndex]?.current_stock_qty,
            current_available_qty: items[foundItemIndex]?.current_available_qty,
          });
        });
      }

      //TODO: fix here
      const formatPayload = {
        delivery_date,
        contact_document_id,
        item_document_id,
        width_tolerance_negative: width_length_tolerance_negative,
        width_tolerance_positive: width_length_tolerance_positive,
        thickness_tolerance_negative,
        thickness_tolerance_positive,
        production_qty: qty_uom,
        actual_production_qty: qty_uom,
        item_name,
        item_remark,
        item_description,
        stock_uom: uom_group.base_uom.name,
        bom_id,
        bom_name,
        bom_detail,
        type: item?.main_bom?.type || "",
        ingredient_list: formatItemList || [],
        work_order_list: [],
        under_quality_item_list: [],
        reference_document_list: [
          {
            reference_document_id: document_id,
            reference_document_type: "sales_order",
          },
        ],
      };

      navigate("/manufacture/order/add", {
        state: {
          ...formatPayload,
          isCopied: false,
          reference_document_type: "sales_order",
        },
      });
    };

    const formatApprovalList =
      approvalList && approvalList.length > 0
        ? approvalList.filter((approval) => approval.approval_status)
        : [];

    return (
      <>
        <Grid container spacing={2} justifyContent="space-between" ref={ref}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
                {getDocumentName(t, currentPage)}
              </Typography>
              <Box sx={{ ml: 2 }}>
                {isExpired ? (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <CustomizedStatus status={status} />
                    <CustomizedStatus status="expired" />
                  </Box>
                ) : (
                  <CustomizedStatus status={status} />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Grid
              container
              spacing={2}
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
            >
              {!fieldsToDisable.printOption && (
                <Grid item xs={6} sm={6} md={3} lg={2.5} xl={2.5}>
                  <CustomizedMenuOption
                    sx={{ padding: "5px 0" }}
                    fullWidth
                    size="medium"
                    label={"พิมพ์เอกสาร"}
                    options={printOptions}
                    onSelect={(e) => {
                      if (e.target.innerText === "พิมพ์เอกสาร") {
                        switch (documentType) {
                          case "quotation":
                            navigate(
                              `/sales/quotation/${encodeURIComponent(id)}/pdf`
                            );
                            break;
                          case "sales_order":
                            navigate(
                              `/sales/order/${encodeURIComponent(id)}/pdf`
                            );
                            break;
                          case "delivery_order":
                            navigate(
                              `/logistic/delivery-order/${encodeURIComponent(
                                id
                              )}/pdf`
                            );
                            break;
                          case "delivery_trip":
                            navigate(
                              `/logistic/delivery-trip/${encodeURIComponent(
                                id
                              )}/pdf`
                            );
                            break;
                          default:
                            break;
                        }
                        if (setIsEdit) {
                          setIsEdit(false);
                        }
                      }
                    }}
                    disabled={fieldsToDisable.printOption}
                  />
                </Grid>
              )}
              <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
                <CustomizedMenuOption
                  sx={{ padding: "5px 0" }}
                  fullWidth
                  size="medium"
                  label={"ตัวเลือก"}
                  options={selectModifyOptions}
                  onSelect={(e) => {
                    switch (e.target.innerText) {
                      case "ยกเลิก":
                        setCancelConfirmation(true);
                        if (setIsEdit) {
                          setIsEdit(false);
                        }
                        break;
                      case "คัดลอก":
                        setCopyConfirmation(true);
                        if (setIsEdit) {
                          setIsEdit(false);
                        }
                        break;
                      case "แก้ไข":
                        editClickHandler();
                        break;
                      case "บันทึกใบแจ้งหนี้":
                        setOpenSalesInvoiceConfirmation(true);
                        if (setIsEdit) {
                          setIsEdit(false);
                        }
                        break;
                      default:
                        break;
                    }
                  }}
                  disabled={fieldsToDisable.modifyOption}
                />
              </Grid>
              {!(fieldsToDisable.createOption === "hide") && (
                <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
                  <CustomizedMenuOption
                    sx={{ padding: "5px 0" }}
                    fullWidth
                    size="medium"
                    label={"สร้าง"}
                    options={selectCreateOptions}
                    onSelect={(e) => {
                      switch (e.target.innerText) {
                        case "ใบสั่งขาย":
                          navigate("/sales/order/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "quotation",
                            },
                          });
                          break;
                        case "ใบขอซื้อ":
                          // TODO: Awaiting document related
                          navigate("/purchase/request/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_list: [
                                {
                                  reference_document_id:
                                    currentState["document_id"],
                                  reference_document_type: "sales_order",
                                },
                              ],
                              reference_document_type: "sales_order",
                            },
                          });
                          break;
                        case "ใบสั่งผลิต":
                          setOpenModal(true);
                          break;
                        case "ใบนำออก":
                          navigate("/inventory/issue/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "sales_order",
                            },
                          });
                          break;
                        case "ใบนำเข้า":
                          navigate("/inventory/return/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "sales_return",
                            },
                          });
                          break;
                        case "ใบส่งของ":
                          navigate("/logistic/delivery-order/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "sales_order",
                            },
                          });
                          break;
                        case "รอบจัดส่ง":
                          navigate("/logistic/delivery-trip/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "delivery_order",
                            },
                          });
                          break;
                        case "ใบรับคืน":
                          navigate("/sales/return/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "sales_order",
                            },
                          });
                          break;
                        case "ใบลดหนี้":
                          navigate("/sales/credit-note/add", {
                            state: {
                              ...currentState,
                              isCopied: false,
                              reference_document_type: "sales_return",
                            },
                          });
                          break;
                        default:
                      }
                    }}
                    disabled={fieldsToDisable.createOption}
                  />
                </Grid>
              )}
              {!(fieldsToDisable.accept_proof === "hide") &&
                Boolean(editPermission) && (
                  <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                    <CustomizedButton
                      fullWidth
                      size="medium"
                      title="ยอมรับใบเสนอราคา"
                      variant="contained"
                      onClick={onAcceptProofOpenHandler}
                      disabled={fieldsToDisable.accept_proof}
                      sx={{ padding: "6px 0" }}
                    />
                  </Grid>
                )}
              {!(fieldsToDisable.delivery_detail === "hide") &&
                Boolean(editPermission) && (
                  <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                    <CustomizedButton
                      fullWidth
                      size="medium"
                      title="บันทึกการจัดส่ง"
                      variant="contained"
                      onClick={onDeliveryDetailOpenHandler}
                      disabled={fieldsToDisable.delivery_detail || isEdit}
                      sx={{ padding: "6px 0" }}
                    />
                  </Grid>
                )}
              {!(fieldsToDisable.delivery_status === "hide") && (
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <CustomizedMenuOption
                    sx={{ padding: "5px 0" }}
                    fullWidth
                    variant="contained"
                    size="medium"
                    label="สถานะรอบจัดส่ง"
                    options={deliveryStatusOptions}
                    onSelect={(e) => {
                      switch (e.target.innerText) {
                        case t("status.delivering"):
                          setStep(3);
                          break;
                        case t("status.completed"):
                          setStep(5);
                          break;
                        case t("status.notCompleted"):
                          setStep(4);
                          break;
                        default:
                          break;
                      }
                      if (setIsEdit) {
                        setIsEdit(false);
                      }
                      setOpenChangeStatus(true);
                    }}
                    disabled={fieldsToDisable.modifyOption || isEdit}
                  />
                </Grid>
              )}
              {!(fieldsToDisable.payment === "hide") && (
                <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
                  <CustomizedButton
                    title={"รับชำระเงิน"}
                    variant="contained"
                    size="medium"
                    onClick={() => {}}
                    disabled={fieldsToDisable.payment}
                  />
                </Grid>
              )}
              {!(fieldsToDisable.activity_log === "hide") && (
                <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
                  <CustomizedTooltip
                    title="ดูความเคลื่อนไหว"
                    enterNextDelay={200}
                  >
                    <IconButton
                      onClick={() => {
                        setOpenDrawer(true);
                      }}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <RestoreOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              {!(fieldsToDisable.approval === "hide") &&
                approvalList &&
                approvalList.length > 0 && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      xl={3}
                      alignSelf="center"
                    >
                      <Typography fontWeight="bold" sx={{ ml: 1 }}>
                        ผู้อนุมัติ
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={viewOnly || fieldsToDisable.document_id ? 12 : 10}
                      sm={viewOnly || fieldsToDisable.document_id ? 12 : 10}
                      md={9}
                      lg={9}
                      xl={9}
                    >
                      <Box sx={{ display: "flex", gap: 1 }}>
                        {approvalList &&
                          approvalList.length > 0 &&
                          approvalList.map((approval, index) => (
                            <CustomizedMuliChip
                              approval={approval}
                              key={index}
                            />
                          ))}
                      </Box>
                      {approvalList &&
                        approvalList.length > 0 &&
                        approvalList[0].approval_status && (
                          <Typography
                            variant="button"
                            sx={{ color: "#328F35", cursor: "pointer" }}
                            onClick={openApprovalLogHandler}
                          >
                            ดูประวัติ
                          </Typography>
                        )}
                    </Grid>
                  </>
                )}

              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={3}
                xl={3}
                alignSelf="center"
              >
                <Typography fontWeight="bold" sx={{ ml: 1 }}>
                  {t("sales.documentId")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={viewOnly || fieldsToDisable.document_id ? 12 : 10}
                sm={viewOnly || fieldsToDisable.document_id ? 12 : 10}
                md={6}
                lg={6}
                xl={6}
              >
                <Controller
                  name="document_id"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.document_id)}
                      helperText={errors.document_id?.message}
                      {...field}
                      disabled={viewOnly || fieldsToDisable.document_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                {!viewOnly && !fieldsToDisable.document_id && (
                  <CustomizedTooltip
                    title="เรียกเลขที่เอกสารใหม่"
                    enterNextDelay={200}
                  >
                    <IconButton
                      onClick={generateDocumentId}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <RestartAltOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={3}
                xl={3}
                alignSelf="center"
              >
                <Typography fontWeight="bold" sx={{ ml: 1 }}>
                  {fieldsToDisable.reference_document
                    ? t("sales.externalRefDocumentId")
                    : "อ้างอิงถึง"}
                </Typography>
              </Grid>

              {!fieldsToDisable.reference_document && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={6.01} xl={6.01}>
                    {
                      // Step 1: Document Relation will return as array
                      watchReferenceDocumentId &&
                      watchReferenceDocumentId?.length > 0 ? (
                        // Step 2: From state(useLocation) should format as array too
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexWrap: "wrap",
                            width: "65vw",
                          }}
                        >
                          {watchReferenceDocumentId.map((reference, index) => (
                            <CustomizedChips
                              key={reference.reference_document_id}
                              onClick={() =>
                                referenceNavigation(
                                  reference.reference_document_type,
                                  reference.reference_document_id
                                )
                              }
                              handleDelete={
                                !viewOnly && documentType === "delivery_trip"
                                  ? () => referenceDeleteHandler(index)
                                  : null
                              }
                              value={reference.reference_document_id}
                              color="primary"
                            />
                          ))}
                        </Box>
                      ) : (
                        // Step 3: When both document relation and state were connected, the pipeline should be worked
                        "-"
                      )
                    }
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={3}
                    xl={3}
                    alignSelf="center"
                  >
                    <Typography fontWeight="bold" sx={{ ml: 1 }}>
                      {t("sales.externalRefDocumentId")}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="external_ref_id"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.external_ref_id)}
                      helperText={errors.external_ref_id?.message}
                      {...field}
                      disabled={viewOnly || fieldsToDisable.external_ref_id}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              container
              spacing={2}
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
            >
              {!(fieldsToDisable.created_date === "hide") && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={!(fieldsToDisable.delivery_date === "hide") ? 3 : 3.5}
                  xl={3}
                >
                  <ControlledDatePicker
                    name="created_date"
                    control={control}
                    error={errors.created_date}
                    isOpen={createdAtDateisOpen}
                    onClose={() => setCreatedAtDateisOpen(false)}
                    onOpen={() => setCreatedAtDateisOpen(true)}
                    label="วันที่สร้าง"
                    disabled={fieldsToDisable.created_date}
                  />
                </Grid>
              )}
              {!(fieldsToDisable.issue_date === "hide") && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={!(fieldsToDisable.delivery_date === "hide") ? 3 : 3.5}
                  xl={3}
                >
                  <ControlledDatePicker
                    name="issue_date"
                    control={control}
                    error={errors.issue_date}
                    isOpen={issueDateIsOpen}
                    onClose={() => setIssueDateIsOpen(false)}
                    onOpen={() => setIssueDateIsOpen(true)}
                    label="วันที่ออกเอกสาร"
                    required
                    disabled={
                      (!isEdit && fieldsToDisable.issue_date) || viewOnly
                    }
                  />
                </Grid>
              )}
              {!(fieldsToDisable.due_date === "hide") && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={!(fieldsToDisable.delivery_date === "hide") ? 3 : 3.5}
                  xl={3}
                >
                  <ControlledDatePicker
                    name="due_date"
                    control={control}
                    error={errors.due_date}
                    isOpen={dueDateIsOpen}
                    onClose={() => setDueDateIsOpen(false)}
                    onOpen={() => setDueDateIsOpen(true)}
                    label={getDueDateLabel()}
                    required
                    disabledPast
                    disabled={(!isEdit && fieldsToDisable.due_date) || viewOnly}
                  />
                </Grid>
              )}
              {!(fieldsToDisable.delivery_date === "hide") && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={!(fieldsToDisable.due_date === "hide") ? 3 : 3.5}
                  xl={3}
                >
                  <ControlledDatePicker
                    name="delivery_date"
                    control={control}
                    error={errors.delivery_date}
                    isOpen={deliveryDateIsOpen}
                    onClose={() => setDeliveryDateIsOpen(false)}
                    onOpen={() => setDeliveryDateIsOpen(true)}
                    label={t("sales.order.deliveryDate")}
                    required
                    disabledPast
                    disabled={
                      (!isEdit && fieldsToDisable.delivery_date) || viewOnly
                    }
                  />
                </Grid>
              )}
            </Grid>
            {!(fieldsToDisable.credit_day === "hide") && (
              <Grid
                container
                spacing={2}
                sx={{ mt: 0.5 }}
                justifyContent="flex-end"
              >
                <Grid item xs={12} sm={12} md={4} lg={3.5} xl={3}>
                  <Controller
                    name="credit_day"
                    control={control}
                    render={({ field }) => (
                      <CustomizedTextField
                        type="number"
                        fullWidth
                        error={Boolean(errors.credit_day)}
                        helperText={errors.credit_day?.message}
                        label="เครดิต (วัน)"
                        {...field}
                        disabled={
                          (!isEdit && fieldsToDisable.credit_day) || viewOnly
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={1.5}>
            <Typography fontWeight="bold" sx={{ ml: 1, mt: { xs: 0, md: 1 } }}>
              {t("sales.employeeList")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={4} xl={3}>
            <Grid container spacing={1}>
              {renderCreatorAvatar()}
              {renderAvatars()}
              {!viewOnly && !fieldsToDisable.employee_list && (
                <Grid item>
                  <CustomizedTooltip
                    title="เพิ่มผู้เกี่ยวข้อง"
                    enterNextDelay={200}
                  >
                    <IconButton
                      onClick={openEmployeeTable}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <ControlPointOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <CheckboxModalTable
          modalTitle="ผู้เกี่ยวข้อง"
          btnTitle={t("button.add")}
          gridRef={selectEmployeesGridRef}
          height={450}
          columnDefs={columnDefs}
          rowSelection="multiple"
          onFinishEditing={finishEmployeesSelect}
          modalIsOpen={showSelectEmployees}
          getRowId={getRowId}
          closeModal={closeEmployeeTable}
          onGridReady={onGridReady}
          selectedIds={employeeIds}
          setSelectedIds={setEmployeeIds}
          idsSnapshot={employeeIdsSnapshot}
          setIdsSnapshot={setEmployeeIdsSnapshot}
          lockRows={creatorRow}
        />
        <CancelConfirmation
          openCancelConfirmation={cancelConfirmation}
          cancelConfirmationAction={cancelConfirmationAction}
          closeCancelConfirmationHandler={closeCancelConfirmationHandler}
        />
        <CopyConfirmation
          openCopyConfirmation={copyConfirmation}
          copyConfirmationAction={copyConfirmationAction}
          closeCopyConfirmationHandler={closeConfirmationHandler}
        />
        <RightDrawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
            setIsApproval(false);
          }}
          title={isApproval ? "ประวัติการอนุมัติ" : t("inventory.activity")}
          documentId={id}
          isApproval={isApproval}
          approvalLogs={formatApprovalList}
          documentType={documentType}
        />
        <ModalUI
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title="เลือกสินค้าเพื่อนำไปผลิต"
          maxWidth="lg"
        >
          <ItemListTable
            gridRef={itemTableGridRef}
            itemList={salesOrder.item_list ?? []}
            getRowId={getRowId}
            documentType="mo"
          />
          <CustomizedButton
            title="เลือก"
            variant="contained"
            onClick={selectItemHandler}
            sx={{ mt: 2 }}
          />
        </ModalUI>
      </>
    );
  }
);

export default SalesHeaderForm;
