import { gql } from "graphql-request";

export const CREATE_BOM = gql`
  mutation BomCreate($createInput: BomCreateInput!) {
    bomCreate(createInput: $createInput) {
      id
      name
      description
      created_date
      start_date
      end_date
      status
      internal_remark
      is_main_bom
      item_document_id
      item_name
      mfg_qty
      stock_uom_document_id
      stock_uom {
        document_id
        id
        name
      }
      type
      ingredient_list {
        uid
        item_document_id
        item_name
        qty
        uom
        item_description
      }
      creator_document_id
      created_by {
        document_id
        email
        first_name
        last_name
        img_url
      }
      item {
        manufacture_minimum_qty
      }
    }
  }
`;

export const CREATE_BOMS = gql`
  mutation BomCreateMany($createManyInput: [BomCreateInput!]) {
    bomCreateMany(createManyInput: $createManyInput) {
      results {
        id
        name
        description
        created_date
        start_date
        end_date
        status
        internal_remark
        is_main_bom
        item_document_id
        item_name
        mfg_qty
        stock_uom_document_id
        stock_uom {
          document_id
          id
          name
        }
        type
        ingredient_list {
          uid
          item_document_id
          item_name
          qty
          uom
          item_description
        }
        creator_document_id
        created_by {
          document_id
          email
          first_name
          last_name
          img_url
        }
        item {
          manufacture_minimum_qty
        }
      }
      errors {
        index
        name
        errorMessage
        field
      }
    }
  }
`;

export const CREATE_OR_UPDATE_BOMS = gql`
  mutation BomUpsertMany($upsertManyInput: [BomUpsertInput!]) {
    bomUpsertMany(upsertManyInput: $upsertManyInput) {
      results {
        id
        name
        description
        created_date
        start_date
        end_date
        status
        internal_remark
        is_main_bom
        item_document_id
        item_name
        mfg_qty
        stock_uom_document_id
        stock_uom {
          document_id
          id
          name
        }
        type
        ingredient_list {
          uid
          item_document_id
          item_name
          qty
          uom
          item_description
        }
        creator_document_id
        created_by {
          document_id
          email
          first_name
          last_name
          img_url
        }
        item {
          manufacture_minimum_qty
        }
      }
      errors {
        index
        name
        errorMessage
        field
      }
    }
  }
`;

export const UPDATE_BOM = gql`
  mutation BomUpdate(
    $uniqueInput: BomUniqueInput!
    $updateInput: BomUpdateInput!
  ) {
    bomUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      name
      description
      created_date
      start_date
      end_date
      status
      internal_remark
      is_main_bom
      item_document_id
      item_name
      mfg_qty
      stock_uom_document_id
      stock_uom {
        document_id
        id
        name
      }
      type
      ingredient_list {
        uid
        item_document_id
        item_name
        qty
        uom
        item_description
      }
      creator_document_id
      created_by {
        document_id
        email
        first_name
        last_name
        img_url
      }
      item {
        manufacture_minimum_qty
      }
    }
  }
`;

export const DELETE_BOM = gql`
  mutation BomDelete($uniqueInput: BomUniqueInput!) {
    bomDelete(uniqueInput: $uniqueInput) {
      id
      is_main_bom
    }
  }
`;

export const CREATE_WORK_ORDER = gql`
  mutation WorkOrderCreate($createInput: WorkOrderCreateInput!) {
    workOrderCreate(createInput: $createInput) {
      id
      name
      status
      external_ref_id
      plant
      machine
      duration
      duration_unit
      description
      manufacture_order_document_id
      manufacture_order {
        document_id
        group {
          id
          name
        }
      }
      start_date_time
      created_by {
        document_id
        last_name
        first_name
        img_url
      }
    }
  }
`;

export const UPDATE_WORK_ORDER = gql`
  mutation WorkOrderUpdate(
    $uniqueInput: WorkOrderUniqueInput!
    $updateInput: WorkOrderUpdateInput!
  ) {
    workOrderUpdate(uniqueInput: $uniqueInput, updateInput: $updateInput) {
      id
      name
      status
      external_ref_id
      plant
      machine
      start_date_time
      duration
      duration_unit
      description
      manufacture_order_document_id
      manufacture_order {
        document_id
        group {
          id
          name
        }
      }
      created_date
      created_by {
        id
        document_id
        first_name
        last_name
        img_url
      }
    }
  }
`;

export const DELETE_WORK_ORDER = gql`
  mutation WorkOrderDelete($uniqueInput: WorkOrderUniqueInput!) {
    workOrderDelete(uniqueInput: $uniqueInput) {
      id
      name
      status
      external_ref_id
      plant
      machine
      duration
      duration_unit
      description
      start_date_time
      manufacture_order_document_id
      manufacture_order {
        document_id
        group {
          id
          name
        }
      }
      created_by {
        document_id
        last_name
        first_name
        img_url
      }
    }
  }
`;

export const SET_STATUS_WORK_ORDER = gql`
  mutation WorkOrderSetStatus(
    $uniqueInput: WorkOrderUniqueInput!
    $statusInput: WorkOrderStatus!
  ) {
    workOrderSetStatus(uniqueInput: $uniqueInput, statusInput: $statusInput) {
      id
      name
      status
      external_ref_id
      plant
      machine
      duration
      duration_unit
      description
      start_date_time
      manufacture_order_document_id
      manufacture_order {
        document_id
        group {
          id
          name
        }
      }
      created_by {
        document_id
        last_name
        first_name
        img_url
      }
    }
  }
`;

export const CREATE_MANUFACTURE_ORDER = gql`
  mutation ManufactureOrderCreate($createInput: ManufactureOrderCreateInput!) {
    manufactureOrderCreate(createInput: $createInput) {
      id
      document_id
      created_date
      issue_date
      expect_date
      expect_product_date
      delivery_date
      external_ref_id
      employee_list {
        document_id
        first_name
        last_name
        email
        img_url
      }
      internal_remark_id
      internal_remark
      customer_contact_document_id
      customer_contact_name
      customer_detail {
        contact_name_1
        contact_name_2
        document_id
      }
      item_document_id
      item_name
      item_remark
      item_description
      production_qty
      actual_production_qty
      uom
      bom_id
      bom_detail
      type
      group {
        id
        name
        entity
      }
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
        email
      }
      ingredient_list
      work_order_list {
        id
        indexing
        name
        status
        external_ref_id
        plant
        machine
        start_date_time
        duration_unit
        duration
        description
      }
      goods_receive_qty
      under_quality_item_list
      target_status
      render_status
      reference_document_list {
        reference_document_id
        reference_document_type
      }
    }
  }
`;

export const UPDATE_MANUFACTURE_ORDER = gql`
  mutation ManufactureOrderUpdate(
    $uniqueInput: ManufactureOrderWhereUniqueInput!
    $updateInput: ManufactureOrderUpdateInput!
  ) {
    manufactureOrderUpdate(
      uniqueInput: $uniqueInput
      updateInput: $updateInput
    ) {
      id
      document_id
      created_date
      issue_date
      expect_date
      expect_product_date
      delivery_date
      external_ref_id
      employee_list {
        document_id
        first_name
        last_name
        email
        img_url
      }
      internal_remark_id
      internal_remark
      customer_contact_document_id
      customer_contact_name
      customer_detail {
        contact_name_1
        contact_name_2
        document_id
      }
      item_document_id
      item_name
      item_remark
      item_description
      production_qty
      actual_production_qty
      uom
      bom_id
      bom_detail
      type
      group {
        id
        name
        entity
      }
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
        email
      }
      ingredient_list
      work_order_list {
        id
        indexing
        name
        status
        external_ref_id
        plant
        machine
        start_date_time
        duration_unit
        duration
        description
      }
      goods_receive_qty
      under_quality_item_list
      target_status
      render_status
      reference_document_list {
        reference_document_id
        reference_document_type
      }
    }
  }
`;
