import { graphQLClientWithHeader } from "../graphQL";
import {
  CREATE_UOM_GROUP,
  CREATE_UOM_GROUPS,
  UPDATE_UOM_GROUP,
  CREATE_WAREHOUSE,
  UPDATE_WAREHOUSE,
  CREATE_WAREHOUSE_LV1,
  UPDATE_WAREHOUSE_LV1,
  CREATE_WAREHOUSE_LV2,
  UPDATE_WAREHOUSE_LV2,
  CREATE_WAREHOUSE_LV3,
  UPDATE_WAREHOUSE_LV3,
  CREATE_BIN_LOCATION,
  UPDATE_BIN_LOCATION,
  // GET_ITEM_GROUP_LV1_IDS,
  CREATE_ITEM_GROUP_LV1,
  UPDATE_ITEM_GROUP_LV1,
  // GET_ITEM_GROUP_LV2_IDS,
  CREATE_ITEM_GROUP_LV2,
  UPDATE_ITEM_GROUP_LV2,
  // GET_ITEM_GROUP_LV3_IDS,
  CREATE_ITEM_GROUP_LV3,
  UPDATE_ITEM_GROUP_LV3,
  CREATE_ATTRUBUTE,
  UPDATE_ATTRUBUTE,
  DELETE_ATTRUBUTE,
  CREATE_PAYMENT_CHANNEL,
  UPDATE_PAYMENT_CHANNEL,
  REMOVE_PAYMENT_CHANNEL,
  CREATE_UOM,
  UPDATE_UOM,
  CREATE_COMPANY_SETTING,
  UPDATE_COMPANY_SETTING,
  DELETE_ITEM_GROUP_LV1,
  DELETE_ITEM_GROUP_LV2,
  DELETE_ITEM_GROUP_LV3,
  DELETE_BIN_LOCATION,
  DELETE_WAREHOUSE_LV3,
  DELETE_WAREHOUSE_LV2,
  DELETE_WAREHOUSE_LV1,
  DELETE_WAREHOUSE,
  DELETE_UOM,
  CREATE_REMARK_TEMPLATE,
  UPDATE_REMARK_TEMPLATE,
  REMOVE_REMARK_TEMPLATE,
  CREATE_APPROVAL_TEMPLATE,
  UPDATE_APPROVAL_TEMPLATE,
  DELETE_APPROVAL_TEMPLATE,
  DELETE_APPROVAL_TEMPLATE_WITH_NEXT_STEP,
  CREATE_VEHICLE,
  UPDATE_VEHICLE,
  DELETE_VEHICLE,
  CREATE_WORK_CENTER,
  UPDATE_WORK_CENTER,
  DELETE_WORK_CENTER,
} from "./mutation";
import {
  GET_UOM,
  GET_ALL_LOCATION,
  GET_ALL_CATEGORY,
  GET_ALL_UOM_GROUP,
  GET_ALL_UOM,
  GET_UOM_GROUP,
  GET_ALL_ATTRIBUTES,
  GET_ATTRIBUTE_BY_ID,
  GET_ALL_PAYMENT_CHANNELS,
  GET_PAYMENT_CHANNEL_BY_ID,
  GET_COMPANY_SETTING,
  GET_COMPANY_SETTINGS,
  GET_REMARK_TEMPLATES,
  GET_APPROVAL_TEMPLATES_BY_DOCUMENT_TYPE,
  GET_ALL_VEHICLE,
  GET_VEHICLE,
  GET_WAREHOUSE,
  GET_WORK_CENTER,
  GET_ALL_WORK_CENTER,
  GET_ALL_WORK_CENTER_WITHOUT_AGGRID,
} from "./queries";

class SettingService {
  async getAllUom(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uoms } = await graphQLClient.request(GET_ALL_UOM, input);
    return uoms;
  }
  async getUom(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uom } = await graphQLClient.request(GET_UOM, input);
    return uom;
  }
  async getUomGroup(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroup } = await graphQLClient.request(GET_UOM_GROUP, input);
    return uomGroup;
  }
  async getAllUomGroup(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroups } = await graphQLClient.request(GET_ALL_UOM_GROUP, input);
    return uomGroups;
  }
  async getWarehouse(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(GET_WAREHOUSE, {
      uniqueInput,
    });
    return warehouse;
  }
  async getAllLocation(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouses } = await graphQLClient.request(GET_ALL_LOCATION, input);
    return warehouses;
  }
  async getAllCategory(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel1s } = await graphQLClient.request(
      GET_ALL_CATEGORY,
      input
    );
    return itemGroupSubLevel1s;
  }
  async getItemGroupLevel1Ids() {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel1s } = await graphQLClient.request(
      GET_ALL_CATEGORY
    );
    return itemGroupSubLevel1s;
  }

  async getItemGroupLevel2Ids() {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel2s } = await graphQLClient.request(
      GET_ALL_CATEGORY
    );
    return itemGroupSubLevel2s;
  }

  async getItemGroupLevel3Ids() {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel3s } = await graphQLClient.request(
      GET_ALL_CATEGORY
    );
    return itemGroupSubLevel3s;
  }

  async getAllAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attributes } = await graphQLClient.request(
      GET_ALL_ATTRIBUTES,
      input
    );
    return attributes;
  }
  async getAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attribute } = await graphQLClient.request(
      GET_ATTRIBUTE_BY_ID,
      input
    );
    return attribute;
  }
  async postCreateAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attributeCreate } = await graphQLClient.request(
      CREATE_ATTRUBUTE,
      input
    );
    return attributeCreate;
  }
  async postUpdateAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attributeUpdate } = await graphQLClient.request(
      UPDATE_ATTRUBUTE,
      input
    );
    return attributeUpdate;
  }
  async postDeleteAttribute(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { attributeDelete } = await graphQLClient.request(
      DELETE_ATTRUBUTE,
      input
    );
    return attributeDelete;
  }
  async postCreateUOM(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomCreate } = await graphQLClient.request(CREATE_UOM, input);
    return uomCreate;
  }
  async postUpdateUOM(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomUpdate } = await graphQLClient.request(UPDATE_UOM, input);
    return uomUpdate;
  }
  async postDeleteUOM(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomDelete } = await graphQLClient.request(DELETE_UOM, input);
    return uomDelete;
  }
  async postCreateUOMGroup(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroupCreate } = await graphQLClient.request(
      CREATE_UOM_GROUP,
      input
    );
    return uomGroupCreate;
  }
  async postCreateUOMGroups(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroupCreateMany } = await graphQLClient.request(
      CREATE_UOM_GROUPS,
      createManyInput
    );
    return uomGroupCreateMany;
  }
  async postUpdateUOMGroup(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { uomGroupUpdate } = await graphQLClient.request(
      UPDATE_UOM_GROUP,
      input
    );
    return uomGroupUpdate;
  }
  async postCreateWarehouse(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouseCreate } = await graphQLClient.request(
      CREATE_WAREHOUSE,
      input
    );
    return warehouseCreate;
  }
  async postUpdateWarehouse(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouseUpdate } = await graphQLClient.request(
      UPDATE_WAREHOUSE,
      input
    );
    return warehouseUpdate;
  }
  async postDeleteWarehouse(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(DELETE_WAREHOUSE, input);
    return warehouse;
  }
  async postCreateWarehouseLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_WAREHOUSE_LV1,
      input
    );
    return warehouse;
  }
  async postUpdateWarehouseLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_WAREHOUSE_LV1,
      input
    );
    return warehouse;
  }
  async postDeleteWarehouseLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      DELETE_WAREHOUSE_LV1,
      input
    );
    return warehouse;
  }
  async postCreateWarehouseLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_WAREHOUSE_LV2,
      input
    );
    return warehouse;
  }
  async postUpdateWarehouseLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_WAREHOUSE_LV2,
      input
    );
    return warehouse;
  }
  async postDeleteWarehouseLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      DELETE_WAREHOUSE_LV2,
      input
    );
    return warehouse;
  }
  async postCreateWarehouseLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_WAREHOUSE_LV3,
      input
    );
    return warehouse;
  }
  async postUpdateWarehouseLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_WAREHOUSE_LV3,
      input
    );
    return warehouse;
  }
  async postDeleteWarehouseLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      DELETE_WAREHOUSE_LV3,
      input
    );
    return warehouse;
  }
  async postCreateBinLocation(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_BIN_LOCATION,
      input
    );
    return warehouse;
  }
  async postUpdateBinLocation(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_BIN_LOCATION,
      input
    );
    return warehouse;
  }
  async postDeleteBinLocation(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      DELETE_BIN_LOCATION,
      input
    );
    return warehouse;
  }
  async postCreateItemGroupLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_ITEM_GROUP_LV1,
      input
    );
    return warehouse;
  }
  async postUpdateItemGroupLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_ITEM_GROUP_LV1,
      input
    );
    return warehouse;
  }
  async postDeleteItemGroupLv1(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel1Delete } = await graphQLClient.request(
      DELETE_ITEM_GROUP_LV1,
      input
    );
    return itemGroupSubLevel1Delete;
  }
  async postCreateItemGroupLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_ITEM_GROUP_LV2,
      input
    );
    return warehouse;
  }
  async postUpdateItemGroupLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_ITEM_GROUP_LV2,
      input
    );
    return warehouse;
  }
  async postDeleteItemGroupLv2(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel2Delete } = await graphQLClient.request(
      DELETE_ITEM_GROUP_LV2,
      input
    );
    return itemGroupSubLevel2Delete;
  }
  async postCreateItemGroupLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      CREATE_ITEM_GROUP_LV3,
      input
    );
    return warehouse;
  }
  async postUpdateItemGroupLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { warehouse } = await graphQLClient.request(
      UPDATE_ITEM_GROUP_LV3,
      input
    );
    return warehouse;
  }
  async postDeleteItemGroupLv3(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { itemGroupSubLevel3Delete } = await graphQLClient.request(
      DELETE_ITEM_GROUP_LV3,
      input
    );
    return itemGroupSubLevel3Delete;
  }
  // async getAllGR(input) {
  //   const { employeesAggrid } = await graphQLClient.request(
  //     GET_ALL_ITEM,
  //     input
  //   );
  //   return employeesAggrid;
  // }
  // async getAllGI(input) {
  //   const { employeesAggrid } = await graphQLClient.request(
  //     GET_ALL_ITEM,
  //     input
  //   );
  //   return employeesAggrid;
  // }
  // async getAllGT(input) {
  //   const { employeesAggrid } = await graphQLClient.request(
  //     GET_ALL_ITEM,
  //     input
  //   );
  //   return employeesAggrid;
  // }
  async getAllPaymentChannels() {
    const graphQLClient = graphQLClientWithHeader();
    const { paymentChannels } = await graphQLClient.request(
      GET_ALL_PAYMENT_CHANNELS
    );
    return paymentChannels;
  }
  async getPaymentChannelById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { paymentChannel } = await graphQLClient.request(
      GET_PAYMENT_CHANNEL_BY_ID,
      { uniqueInput }
    );
    return paymentChannel;
  }
  async createPaymentChannel(createPaymentChannelInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createPaymentChannel } = await graphQLClient.request(
      CREATE_PAYMENT_CHANNEL,
      { createPaymentChannelInput }
    );
    return createPaymentChannel;
  }
  async updatePaymentChannel(updatePaymentChannelInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updatePaymentChannel } = await graphQLClient.request(
      UPDATE_PAYMENT_CHANNEL,
      { updatePaymentChannelInput }
    );
    return updatePaymentChannel;
  }
  async removePaymentChannel(removePaymentChannelId) {
    const graphQLClient = graphQLClientWithHeader();
    const { removePaymentChannel } = await graphQLClient.request(
      REMOVE_PAYMENT_CHANNEL,
      { removePaymentChannelId }
    );
    return removePaymentChannel;
  }
  async getCompanySettings() {
    const graphQLClient = graphQLClientWithHeader();
    const { companySettings } = await graphQLClient.request(
      GET_COMPANY_SETTINGS
    );
    return companySettings;
  }
  async getCompanySetting(companySettingId) {
    const graphQLClient = graphQLClientWithHeader();
    const { companySetting } = await graphQLClient.request(
      GET_COMPANY_SETTING,
      { companySettingId }
    );
    return companySetting;
  }
  async createCompanySetting(createCompanySettingInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createCompanySetting } = await graphQLClient.request(
      CREATE_COMPANY_SETTING,
      { createCompanySettingInput }
    );
    return createCompanySetting;
  }
  async updateCompanySetting(updateCompanySettingInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateCompanySetting } = await graphQLClient.request(
      UPDATE_COMPANY_SETTING,
      { updateCompanySettingInput }
    );
    return updateCompanySetting;
  }
  async getRemarkTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplate } = await graphQLClient.request(
      GET_COMPANY_SETTING,
      input
    );
    return remarkTemplate;
  }
  async getRemarkTemplates(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplates } = await graphQLClient.request(
      GET_REMARK_TEMPLATES,
      input
    );
    return remarkTemplates;
  }
  async createRemarkTemplate(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplateCreate } = await graphQLClient.request(
      CREATE_REMARK_TEMPLATE,
      { createInput }
    );
    return remarkTemplateCreate;
  }
  async updateRemarkTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplate } = await graphQLClient.request(
      UPDATE_REMARK_TEMPLATE,
      input
    );
    return remarkTemplate;
  }
  async deleteRemarkTemplate(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { remarkTemplateDelete } = await graphQLClient.request(
      REMOVE_REMARK_TEMPLATE,
      input
    );
    return remarkTemplateDelete;
  }

  async getApprovalTemplatesByDocumentType(documentType) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalTemplatesByDocumentType } = await graphQLClient.request(
      GET_APPROVAL_TEMPLATES_BY_DOCUMENT_TYPE,
      { documentType }
    );
    return approvalTemplatesByDocumentType;
  }
  async createApprovalTemplate(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createApprovalTemplate } = await graphQLClient.request(
      CREATE_APPROVAL_TEMPLATE,
      { createInput }
    );
    return createApprovalTemplate;
  }
  async updateApprovalTemplate(updateApprovalTemplateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateApprovalTemplate } = await graphQLClient.request(
      UPDATE_APPROVAL_TEMPLATE,
      updateApprovalTemplateInput
    );
    return updateApprovalTemplate;
  }

  async deleteApprovalTemplate(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalTemplateDelete } = await graphQLClient.request(
      DELETE_APPROVAL_TEMPLATE,
      { uniqueInput }
    );
    return approvalTemplateDelete;
  }

  async deleteApprovalTemplateWithNextStep(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalTemplateDeleteNextStep } = await graphQLClient.request(
      DELETE_APPROVAL_TEMPLATE_WITH_NEXT_STEP,
      { uniqueInput }
    );
    return approvalTemplateDeleteNextStep;
  }

  async getAllVehicles() {
    const graphQLClient = graphQLClientWithHeader();
    const { vehicles } = await graphQLClient.request(GET_ALL_VEHICLE);
    return vehicles;
  }

  async getVehicleById(vehicleId) {
    const graphQLClient = graphQLClientWithHeader();
    const { vehicle } = await graphQLClient.request(GET_VEHICLE, { vehicleId });
    return vehicle;
  }

  async createVehicle(createVehicleInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createVehicle } = await graphQLClient.request(CREATE_VEHICLE, {
      createVehicleInput,
    });
    return createVehicle;
  }

  async updateVehicle(updateVehicleInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateVehicle } = await graphQLClient.request(UPDATE_VEHICLE, {
      updateVehicleInput,
    });
    return updateVehicle;
  }

  async deleteVehicle(removeVehicleId) {
    const graphQLClient = graphQLClientWithHeader();
    await graphQLClient.request(DELETE_VEHICLE, {
      removeVehicleId,
    });
  }

  async getAllWorkCenter(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { WorkCentersAggrid } = await graphQLClient.request(
      GET_ALL_WORK_CENTER,
      { aggridInput: input }
    );
    return WorkCentersAggrid;
  }

  async getAllWorkCenterWithoutAggrid() {
    const graphQLClient = graphQLClientWithHeader();
    const { WorkCenters } = await graphQLClient.request(
      GET_ALL_WORK_CENTER_WITHOUT_AGGRID
    );
    return WorkCenters;
  }

  async getWorkCenterById(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { WorkCenter } = await graphQLClient.request(GET_WORK_CENTER, {
      uniqueInput: input,
    });
    return WorkCenter;
  }

  async createWorkCenter(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { workCenterCreate } = await graphQLClient.request(
      CREATE_WORK_CENTER,
      {
        createInput: input,
      }
    );
    return workCenterCreate;
  }
  async updateWorkCenter(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { workCenterUpdate } = await graphQLClient.request(
      UPDATE_WORK_CENTER,
      input
    );
    return workCenterUpdate;
  }
  async deleteWorkCenter(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { workCenterDelete } = await graphQLClient.request(
      DELETE_WORK_CENTER,
      {
        uniqueInput: input,
      }
    );
    return workCenterDelete;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SettingService();
