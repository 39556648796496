import { useState } from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Box,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Chip,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useNavigate } from "react-router-dom";
import CustomizedLetterAvatar, {
  stringAvatar,
} from "../Custom/CustomizedLetterAvatar";
import { departmentEngToThai } from "../../utils/dataTransformer";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/use-auth";

const StyledMenu = styled(Menu)(() => ({
  // boxShadow: "0px 2px 5px #E5E5E5",
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  "&": {
    // borderColor: "rgba(50, 143, 53, 0.6)",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  "&.MuiChip-avatar": {
    color: "#FFFFFF",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.Mui-disabled": {
    opacity: 1,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: "-1px",
      left: "-1px",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const UserBadge = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorPos, setAnchorPos] = useState(null);
  const showActions = Boolean(anchorPos);
  const { isInitialized, isAuthenticated, user, logout } = useAuth();

  const navigate = useNavigate();
  const fullName =
    isInitialized && isAuthenticated && user
      ? `${user?.first_name} ${user?.last_name}`
      : "ไม่พบข้อมูล";

  const openActionsHandler = (event) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const navigateToEditUser = () => {
    closeActionsHandler();
    navigate(`/user/setting`);
  };

  const logoutHandler = () => {
    logout();
    navigate("/", { replace: true });
    closeActionsHandler();
  };

  return (
    <>
      <StyledMenu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
        disableAutoFocusItem
        MenuListProps={{
          "data-testid": "user-menu-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <StyledMenuItem onClick={navigateToEditUser}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isInitialized && isAuthenticated && user && user?.img_url ? (
              <Avatar
                src={user.img_url}
                sx={{ width: 44, height: 44, mr: 2 }}
              />
            ) : (
              <CustomizedLetterAvatar
                name={fullName}
                sx={{ width: 44, height: 44, fontSize: "1.1rem", mr: 2 }}
              />
            )}
            <Box>
              <Typography variant="body1">{fullName}</Typography>
              <Typography variant="body2">
                {departmentEngToThai(user?.department)}
              </Typography>
            </Box>
          </Box>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={navigateToEditUser}>
          <ListItemIcon>
            <ManageAccountsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{t("user.setting.index")}</Typography>
          </ListItemText>
        </StyledMenuItem>
        <StyledMenuItem data-testid="user-menu-logout" onClick={logoutHandler}>
          <ListItemIcon>
            <FileUploadOutlinedIcon
              sx={{ transform: "rotate(90deg)" }}
              fontSize="small"
            />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{"ออกจากระบบ"}</Typography>
          </ListItemText>
        </StyledMenuItem>
      </StyledMenu>

      {isSmallScreen ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          onClick={openActionsHandler}
        >
          {isInitialized && isAuthenticated && user && user?.img_url ? (
            <Avatar alt={fullName} src={user?.img_url} />
          ) : (
            <StyledAvatar {...stringAvatar(fullName)} />
          )}
        </StyledBadge>
      ) : (
        <StyledChip
          avatar={
            isInitialized && isAuthenticated && user && user?.img_url ? (
              <Avatar alt={fullName} src={user?.img_url} />
            ) : (
              <StyledAvatar {...stringAvatar(fullName)} />
            )
          }
          label={fullName}
          variant="outlined"
          onClick={openActionsHandler}
          data-testid="user-menu"
        />
      )}
    </>
  );
};

export default UserBadge;
