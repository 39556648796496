import React, { useEffect, useMemo } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { locationActions } from "../../../features/Setting/Location/location-slice";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import {
  createWarehouse,
  updateWarehouse,
  deleteWarehouse,
  createLocationLv1,
  updateLocationLv1,
  deleteLocationLv1,
  createLocationLv2,
  updateLocationLv2,
  deleteLocationLv2,
  createLocationLv3,
  updateLocationLv3,
  deleteLocationLv3,
  createBinLocation,
  updateBinLocation,
  deleteBinLocation,
  getAllLocation,
} from "../../../features/Setting/Location/location-actions";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ModalUI from "../../../components/UI/ModalUI";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSnackbar } from "notistack";
import { usePermission } from "../../../hooks/use-auth";

const validation = Yup.object().shape({
  value: Yup.string().required("กรุณากรอก"),
  document_id: Yup.string().required("กรุณากรอก"),
});

const Location = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal, title, allLocation, selectedLocation } = useSelector(
    (state) => state.location
  );
  const [expanded, setExpanded] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { createPermission, editPermission } = usePermission();

  const findInput = useMemo(() => {
    return {
      findManyInput: {
        include: {
          sub_level_1_list: {
            include: {
              sub_level_2_list: {
                include: {
                  sub_level_3_list: {
                    include: {
                      bin_location_list: null,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    dispatch(getAllLocation(findInput));
  }, [dispatch, findInput]);

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.warehouseSetting"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.location.index"),
    },
  ];

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      value: "",
      document_id: "",
    },
    resolver: yupResolver(validation),
  });

  const getMergePrefixDocumentId = (selectedLocation = [], value = "") => {
    if (selectedLocation.length === 0) {
      return;
    } else if (selectedLocation.length === 1) {
      return selectedLocation[0].document_id + "-" + value;
    } else if (selectedLocation.length === 2) {
      return selectedLocation[1].document_id + "-" + value;
    } else if (selectedLocation.length === 3) {
      return selectedLocation[2].document_id + "-" + value;
    } else if (selectedLocation.length === 4) {
      return selectedLocation[3].document_id + "-" + value;
    }
    return;
  };

  const handleOpen = async ({ title, location }) => {
    if (title === "addLocation") {
      setValue("value", "");
      setValue("document_id", getMergePrefixDocumentId(location));
    } else {
      setValue("document_id", location[location.length - 1].document_id);
      setValue(
        "value",
        location[location.length - 1].thai_name ||
          location[location.length - 1].name
      );
    }
    if (location) dispatch(locationActions.loadedSelectedLocation(location));
    dispatch(locationActions.changeTitle(title));
    dispatch(locationActions.openModal());
  };

  const handleClose = async () => {
    await dispatch(locationActions.closeModal());
    await dispatch(getAllLocation(findInput));
    setTimeout(() => {
      dispatch(locationActions.resetTitle());
      dispatch(locationActions.resetSelectedLocation());
    }, 150);
  };

  const onSubmit = (data) => {
    switch (title) {
      case "addLocation":
        handleSubmitCreate({ data });
        break;
      case "editLocation":
        handleSubmitUpdate({ data });
        break;
      case "deleteLocation":
        handleSubmitDelete();
        break;
      default:
        break;
    }
  };

  const resetForm = () => {
    handleClose();
    reset();
  };

  const handleSubmitCreate = async ({ data }) => {
    let input = {
      document_id: data.document_id,
      type: data.value,
      thai_name: data.value,
      eng_name: data.value,
      description: data.value,
      is_active: true,
    };
    if (selectedLocation.length === 0) {
      await dispatch(createWarehouse({ createInput: input }, enqueueSnackbar));
    } else if (selectedLocation.length === 1) {
      input = {
        name: data.value,
        document_id: data.document_id,
        warehouse: selectedLocation[0].document_id,
      };
      await dispatch(
        createLocationLv1({ createInput: input }, enqueueSnackbar)
      );
    } else if (selectedLocation.length === 2) {
      input = {
        name: data.value,
        document_id: data.document_id,
        sub_level_1: selectedLocation[1].document_id,
      };
      await dispatch(
        createLocationLv2({ createInput: input }, enqueueSnackbar)
      );
    } else if (selectedLocation.length === 3) {
      input = {
        name: data.value,
        document_id: data.document_id,
        sub_level_2: selectedLocation[2].document_id,
      };
      await dispatch(
        createLocationLv3({ createInput: input }, enqueueSnackbar)
      );
    } else if (selectedLocation.length === 4) {
      input = {
        name: data.value,
        document_id: data.document_id,
        sub_level_3: selectedLocation[3].document_id,
      };
      await dispatch(
        createBinLocation({ createInput: input }, enqueueSnackbar)
      );
    }
    await handleClose();
  };

  const handleSubmitUpdate = async ({ data }) => {
    let input = {
      updateInput: {
        document_id: null,
        name: data.value,
      },
      uniqueInput: {
        document_id: null,
      },
    };
    if (selectedLocation.length === 1) {
      input = {
        updateInput: {
          thai_name: data.value,
          eng_name: data.value,
        },
        uniqueInput: {
          document_id: selectedLocation[0].document_id,
        },
      };
      await dispatch(updateWarehouse(input, enqueueSnackbar));
    } else if (selectedLocation.length === 2) {
      input = {
        updateInput: {
          name: data.value,
        },
        uniqueInput: {
          document_id: selectedLocation[1].document_id,
        },
      };
      await dispatch(updateLocationLv1(input, enqueueSnackbar));
    } else if (selectedLocation.length === 3) {
      input = {
        updateInput: {
          name: data.value,
        },
        uniqueInput: {
          document_id: selectedLocation[2].document_id,
        },
      };
      await dispatch(updateLocationLv2(input, enqueueSnackbar));
    } else if (selectedLocation.length === 4) {
      input = {
        updateInput: {
          name: data.value,
        },
        uniqueInput: {
          document_id: selectedLocation[3].document_id,
        },
      };
      await dispatch(updateLocationLv3(input, enqueueSnackbar));
    } else if (selectedLocation.length === 5) {
      input = {
        updateInput: {
          name: data.value,
        },
        uniqueInput: {
          document_id: selectedLocation[4].document_id,
        },
      };
      await dispatch(updateBinLocation(input, enqueueSnackbar));
    }
    await handleClose();
  };

  const handleSubmitDelete = async () => {
    let input = {
      uniqueInput: {
        document_id: null,
      },
    };
    if (selectedLocation.length === 1) {
      input = {
        uniqueInput: {
          document_id: selectedLocation[0].document_id,
        },
      };
      await dispatch(deleteWarehouse(input, enqueueSnackbar));
    } else if (selectedLocation.length === 2) {
      input = {
        uniqueInput: {
          document_id: selectedLocation[1].document_id,
        },
      };
      await dispatch(deleteLocationLv1(input, enqueueSnackbar));
    } else if (selectedLocation.length === 3) {
      input = {
        uniqueInput: {
          document_id: selectedLocation[2].document_id,
        },
      };
      await dispatch(deleteLocationLv2(input, enqueueSnackbar));
    } else if (selectedLocation.length === 4) {
      input = {
        uniqueInput: {
          document_id: selectedLocation[3].document_id,
        },
      };
      await dispatch(deleteLocationLv3(input, enqueueSnackbar));
    } else if (selectedLocation.length === 5) {
      input = {
        uniqueInput: {
          document_id: selectedLocation[4].document_id,
        },
      };
      await dispatch(deleteBinLocation(input, enqueueSnackbar));
    }
    await handleClose();
  };

  const deleteTitle = ["deleteLocation"];
  const buttonSx = { padding: "2px 10px", height: "fit-content" };

  const handleExpandClick = () => {
    const allId = [];
    allLocation.forEach((warehouse) => {
      if (warehouse.sub_level_1_list.length > 0) {
        allId.push(warehouse.document_id);
        warehouse.sub_level_1_list.forEach((locationLv1) => {
          if (locationLv1.sub_level_2_list.length > 0) {
            allId.push(warehouse.document_id + locationLv1.document_id);
            locationLv1.sub_level_2_list.forEach((locationLv2) => {
              if (locationLv2.sub_level_3_list.length > 0) {
                allId.push(
                  warehouse.document_id +
                    locationLv1.document_id +
                    locationLv2.document_id
                );
                locationLv2.sub_level_3_list.forEach((locationLv3) => {
                  if (locationLv3.bin_location_list.length > 0)
                    allId.push(
                      warehouse.document_id +
                        locationLv1.document_id +
                        locationLv2.document_id +
                        locationLv3.document_id
                    );
                });
              }
            });
          }
        });
      }
    });
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? allId : []));
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const checkConditionRender = () => {
    const render = createPermission || editPermission;
    return render;
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container mt={2}>
        <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
          <Typography variant="h5" my={2}>
            {t("setting.inventory.location.index")}
          </Typography>
        </Grid>
        {createPermission && (
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            lg={1}
            xl={1}
            alignSelf="center"
            textAlign={"end"}
          >
            <CustomizedButton
              fullWidth
              title={t("setting.inventory.location.addLocation")}
              variant="contained"
              onClick={() => handleOpen({ title: "addLocation" })}
            />
          </Grid>
        )}
      </Grid>
      <CustomizedBox>
        <Box
          onClick={handleExpandClick}
          sx={{ cursor: "pointer", width: "fit-content", marginBottom: "40px" }}
          display={"flex"}
        >
          {expanded.length === 0 ? (
            <KeyboardArrowRightIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
          <Typography>
            {expanded.length === 0 ? "เปิดทั้งหมด" : "ปิดทั้งหมด"}
          </Typography>
        </Box>
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={handleToggle}
          sx={{ flexGrow: 1 }}
        >
          {allLocation &&
            allLocation.map((warehouse) => {
              return (
                <TreeItem
                  key={warehouse.document_id}
                  nodeId={`${warehouse.document_id}`}
                  label={
                    <>
                      {warehouse.thai_name}{" "}
                      <label style={{ fontSize: "0.7rem" }}>
                        (รหัส: {warehouse.document_id})
                      </label>
                    </>
                  }
                >
                  {checkConditionRender() && (
                    <ToggleButtonGroup aria-label="text alignment">
                      {createPermission && (
                        <ToggleButton
                          sx={buttonSx}
                          value="left"
                          aria-label="left aligned"
                          onClick={() =>
                            handleOpen({
                              title: "addLocation",
                              location: [warehouse],
                            })
                          }
                        >
                          เพิ่ม
                        </ToggleButton>
                      )}
                      {editPermission && (
                        <ToggleButton
                          sx={buttonSx}
                          value="center"
                          aria-label="centered"
                          onClick={() =>
                            handleOpen({
                              title: "editLocation",
                              location: [warehouse],
                            })
                          }
                        >
                          เปลี่ยนชื่อ
                        </ToggleButton>
                      )}
                      {editPermission && (
                        <ToggleButton
                          sx={buttonSx}
                          value="right"
                          aria-label="right aligned"
                          onClick={() =>
                            handleOpen({
                              title: "deleteLocation",
                              location: [warehouse],
                            })
                          }
                        >
                          ลบ
                        </ToggleButton>
                      )}
                    </ToggleButtonGroup>
                  )}
                  {warehouse.sub_level_1_list &&
                    warehouse.sub_level_1_list.map((locationLv2) => (
                      <TreeItem
                        key={locationLv2.document_id}
                        nodeId={`${
                          warehouse.document_id + locationLv2.document_id
                        }`}
                        label={<>{locationLv2.name} </>}
                      >
                        {checkConditionRender() && (
                          <ToggleButtonGroup aria-label="text alignment">
                            {createPermission && (
                              <ToggleButton
                                sx={buttonSx}
                                value="left"
                                aria-label="left aligned"
                                onClick={() =>
                                  handleOpen({
                                    title: "addLocation",
                                    location: [warehouse, locationLv2],
                                  })
                                }
                              >
                                เพิ่ม
                              </ToggleButton>
                            )}
                            {editPermission && (
                              <ToggleButton
                                sx={buttonSx}
                                value="center"
                                aria-label="centered"
                                onClick={() =>
                                  handleOpen({
                                    title: "editLocation",
                                    location: [warehouse, locationLv2],
                                  })
                                }
                              >
                                เปลี่ยนชื่อ
                              </ToggleButton>
                            )}
                            {editPermission && (
                              <ToggleButton
                                sx={buttonSx}
                                value="right"
                                aria-label="right aligned"
                                onClick={() =>
                                  handleOpen({
                                    title: "deleteLocation",
                                    location: [warehouse, locationLv2],
                                  })
                                }
                              >
                                ลบ
                              </ToggleButton>
                            )}
                          </ToggleButtonGroup>
                        )}
                        {locationLv2.sub_level_2_list &&
                          locationLv2.sub_level_2_list.map((locationLv3) => (
                            <TreeItem
                              key={locationLv3.document_id}
                              nodeId={`${
                                warehouse.document_id +
                                locationLv2.document_id +
                                locationLv3.document_id
                              }`}
                              label={<>{locationLv3.name} </>}
                            >
                              {checkConditionRender() && (
                                <ToggleButtonGroup aria-label="text alignment">
                                  {createPermission && (
                                    <ToggleButton
                                      sx={buttonSx}
                                      value="left"
                                      aria-label="left aligned"
                                      onClick={() =>
                                        handleOpen({
                                          title: "addLocation",
                                          location: [
                                            warehouse,
                                            locationLv2,
                                            locationLv3,
                                          ],
                                        })
                                      }
                                    >
                                      เพิ่ม
                                    </ToggleButton>
                                  )}
                                  {editPermission && (
                                    <ToggleButton
                                      sx={buttonSx}
                                      value="center"
                                      aria-label="centered"
                                      onClick={() =>
                                        handleOpen({
                                          title: "editLocation",
                                          location: [
                                            warehouse,
                                            locationLv2,
                                            locationLv3,
                                          ],
                                        })
                                      }
                                    >
                                      เปลี่ยนชื่อ
                                    </ToggleButton>
                                  )}
                                  {editPermission && (
                                    <ToggleButton
                                      sx={buttonSx}
                                      value="right"
                                      aria-label="right aligned"
                                      onClick={() =>
                                        handleOpen({
                                          title: "deleteLocation",
                                          location: [
                                            warehouse,
                                            locationLv2,
                                            locationLv3,
                                          ],
                                        })
                                      }
                                    >
                                      ลบ
                                    </ToggleButton>
                                  )}
                                </ToggleButtonGroup>
                              )}
                              {locationLv3.sub_level_3_list &&
                                locationLv3.sub_level_3_list.map(
                                  (locationLv4) => (
                                    <TreeItem
                                      key={locationLv4.document_id}
                                      nodeId={`${
                                        warehouse.document_id +
                                        locationLv2.document_id +
                                        locationLv3.document_id +
                                        locationLv4.document_id
                                      }`}
                                      label={<>{locationLv4.name} </>}
                                    >
                                      {checkConditionRender() && (
                                        <ToggleButtonGroup aria-label="text alignment">
                                          {createPermission && (
                                            <ToggleButton
                                              sx={buttonSx}
                                              value="left"
                                              aria-label="left aligned"
                                              onClick={() =>
                                                handleOpen({
                                                  title: "addLocation",
                                                  location: [
                                                    warehouse,
                                                    locationLv2,
                                                    locationLv3,
                                                    locationLv4,
                                                  ],
                                                })
                                              }
                                            >
                                              เพิ่ม
                                            </ToggleButton>
                                          )}
                                          {editPermission && (
                                            <ToggleButton
                                              sx={buttonSx}
                                              value="center"
                                              aria-label="centered"
                                              onClick={() =>
                                                handleOpen({
                                                  title: "editLocation",
                                                  location: [
                                                    warehouse,
                                                    locationLv2,
                                                    locationLv3,
                                                    locationLv4,
                                                  ],
                                                })
                                              }
                                            >
                                              เปลี่ยนชื่อ
                                            </ToggleButton>
                                          )}
                                          {editPermission && (
                                            <ToggleButton
                                              sx={buttonSx}
                                              value="right"
                                              aria-label="right aligned"
                                              onClick={() =>
                                                handleOpen({
                                                  title: "deleteLocation",
                                                  location: [
                                                    warehouse,
                                                    locationLv2,
                                                    locationLv3,
                                                    locationLv4,
                                                  ],
                                                })
                                              }
                                            >
                                              ลบ
                                            </ToggleButton>
                                          )}
                                        </ToggleButtonGroup>
                                      )}
                                      {locationLv4.bin_location_list &&
                                        locationLv4.bin_location_list.map(
                                          (binLocation) => (
                                            <TreeItem
                                              key={binLocation.document_id}
                                              nodeId={`${binLocation.document_id}`}
                                              label={
                                                <>
                                                  {binLocation.name}{" "}
                                                  <label
                                                    style={{
                                                      fontSize: "0.7rem",
                                                    }}
                                                  >
                                                    (รหัส:{" "}
                                                    {binLocation.document_id})
                                                  </label>
                                                </>
                                              }
                                            >
                                              {checkConditionRender() && (
                                                <ToggleButtonGroup aria-label="text alignment">
                                                  {editPermission && (
                                                    <ToggleButton
                                                      sx={buttonSx}
                                                      value="center"
                                                      aria-label="centered"
                                                      onClick={() =>
                                                        handleOpen({
                                                          title: "editLocation",
                                                          location: [
                                                            warehouse,
                                                            locationLv2,
                                                            locationLv3,
                                                            locationLv4,
                                                            binLocation,
                                                          ],
                                                        })
                                                      }
                                                    >
                                                      เปลี่ยนชื่อ
                                                    </ToggleButton>
                                                  )}
                                                  {editPermission && (
                                                    <ToggleButton
                                                      sx={buttonSx}
                                                      value="right"
                                                      aria-label="right aligned"
                                                      onClick={() =>
                                                        handleOpen({
                                                          title:
                                                            "deleteLocation",
                                                          location: [
                                                            warehouse,
                                                            locationLv2,
                                                            locationLv3,
                                                            locationLv4,
                                                            binLocation,
                                                          ],
                                                        })
                                                      }
                                                    >
                                                      ลบ
                                                    </ToggleButton>
                                                  )}
                                                </ToggleButtonGroup>
                                              )}
                                            </TreeItem>
                                          )
                                        )}
                                    </TreeItem>
                                  )
                                )}
                            </TreeItem>
                          ))}
                      </TreeItem>
                    ))}
                </TreeItem>
              );
            })}
        </TreeView>
      </CustomizedBox>
      <ModalUI
        open={modal}
        handleClose={handleClose}
        title={t(`setting.inventory.location.${title}`) || ""}
        width={620}
      >
        {!deleteTitle.includes(title) ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={6}>
                <Controller
                  name={"document_id"}
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      label={t("setting.inventory.location.documentId")}
                      error={Boolean(errors.value)}
                      helperText={errors.value?.message}
                      {...field}
                      disabled={
                        title !== "addLocation" || selectedLocation.length !== 0
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={"value"}
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      label={t("setting.inventory.location.name")}
                      error={Boolean(errors.value)}
                      helperText={errors.value?.message}
                      {...field}
                      onChange={(e) => {
                        setValue("value", e.target.value);
                        title === "addLocation" &&
                          selectedLocation.length > 0 &&
                          setValue(
                            "document_id",
                            getMergePrefixDocumentId(
                              selectedLocation,
                              e.target.value
                            )
                          );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <CustomizedButton
              type="reset"
              onClick={resetForm}
              title={t("button.cancel")}
              variant="outlined"
              sx={{ mt: 2, mr: 2 }}
            />
            <CustomizedButton
              type="submit"
              title={t("button.save")}
              variant="contained"
              sx={{ mt: 2 }}
            />
          </form>
        ) : (
          <Box width={200}>
            <CustomizedButton
              type="reset"
              onClick={resetForm}
              title={t("button.cancel")}
              variant="outlined"
              sx={{ mr: 2 }}
            />
            <CustomizedButton
              type="submit"
              onClick={onSubmit}
              title={t("button.save")}
              variant="contained"
            />
          </Box>
        )}
      </ModalUI>
    </>
  );
};

export default Location;
