import { createSlice } from "@reduxjs/toolkit";
import { unixToDate } from "../../../utils/date-converter";
import { initialState } from "./goodsReceive-initials";

const goodsReceiveSlice = createSlice({
  name: "goodsReceive",
  initialState: initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    onLoaded(state, action) {
      state.isLoading[action.payload] = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllItems(state, action) {
      state.allItems = action.payload;
      state.isLoading.allItems = false;
    },
    loadedAllCount(state, action) {
      state.count = action.payload;
    },
    loadedItem(state, action) {
      let counter = 0; // Initialize the counter

      const formatItem = {
        ...action.payload,
        created_date: unixToDate(action.payload.created_date),
        document_date: unixToDate(action.payload.document_date),
        goods_receive_list: action.payload.goods_receive_list.map((list) => {
          const entryAtIndex0 = list.stock_entry_list[0]; // Take only the entry at index 0
          return {
            ...list,
            stock_entry_list: {
              ...entryAtIndex0,
              trace_entry_list: entryAtIndex0?.trace_entry_list.map(
                (traceEntry) => {
                  counter++; // Increment the counter for each entry
                  const padSNNumber = String(counter).padStart(5, "0");
                  return {
                    ...traceEntry,
                    padSNNumber: padSNNumber,
                  };
                }
              ),
            },
          };
        }),
      };
      state.item = formatItem;
      state.isLoading.item = false;
    },
    resetAllItems(state) {
      state.allItems = [];
    },
    resetItem(state) {
      state.item = initialState.item;
    },
  },
});

export const goodsReceiveActions = goodsReceiveSlice.actions;

export default goodsReceiveSlice.reducer;
