import { Box } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";

const CopyConfirmation = ({
  openCopyConfirmation,
  copyConfirmationAction,
  closeCopyConfirmationHandler,
}) => {
  return (
    <ModalUI
      title="ยืนยันการคัดลอก"
      open={openCopyConfirmation}
      handleClose={closeCopyConfirmationHandler}
      maxWidth="sm"
    >
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeCopyConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={copyConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default CopyConfirmation;
