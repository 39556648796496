import { useEffect, useState } from "react";

export const usePrintOptions = (status) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    switch (status) {
      case "cancelled":
        setDisabled(true);
        break;
      default:
        setDisabled(false);
    }
  }, [status]);
  return disabled;
};
