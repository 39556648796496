import { yupResolver } from "@hookform/resolvers/yup";
import { DialogContentText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import CustomizedMenuOption from "../../../../components/Custom/CustomizedMenuOption";
import VehicleForm from "../../../../components/Form/Logistic/VehicleForm";
import ModalUI from "../../../../components/UI/ModalUI";
import {
  createVehicle,
  deleteVehicle,
  getVehicleById,
  updateVehicle,
} from "../../../../features/Setting/Vehicle/vehicle-actions";
import {
  initialState,
  vehicleValidation,
} from "../../../../features/Setting/Vehicle/vehicle-initials";
import { vehicleActions } from "../../../../features/Setting/Vehicle/vehicle-slice";
import { useAuth, usePermission } from "../../../../hooks/use-auth";

const VehicleInfo = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const [open, setOpen] = useState();
  const { vehicle } = useSelector((state) => state.vehicle);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);

  const { editPermission } = usePermission();

  const options = [
    { value: "แก้ไข", disabled: !editPermission },
    { value: "ลบ", disabled: !editPermission },
  ];

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    { name: t("setting.logisticSetting"), to: "/setting/logistic" },
    { name: t("setting.vehicleSetting"), to: "/setting/logistic/vehicle" },
    { name: id ? vehicle.license_plate : t("setting.logistic.addVehicle") },
  ];

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: initialState.vehicle,
    resolver: yupResolver(vehicleValidation),
  });

  useEffect(() => {
    if (id) {
      dispatch(getVehicleById(parseInt(id)));
    }
    return () => dispatch(vehicleActions.resetVehicle());
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset(vehicle);
      setDisabled(true);
    }
  }, [id, reset, vehicle]);

  const onVehicleSubmitHandler = (data) => {
    if (id) {
      const payload = { ...data };
      delete payload.created_by;
      dispatch(updateVehicle(payload, enqueueSnackbar));
    } else {
      const payload = {
        ...data,
        creator_document_id: user.document_id,
      };
      dispatch(createVehicle(payload, navigate, enqueueSnackbar));
    }
  };

  const optionSelectHandler = (e) => {
    if (e.target.innerText === "แก้ไข") {
      setDisabled(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" mt={2}>
          {id
            ? `ทะเบียนรถ ${vehicle.license_plate}`
            : t("setting.logistic.addVehicle")}
        </Typography>
        {id ? (
          <CustomizedMenuOption
            label="ตัวเลือก"
            options={options}
            onSelect={optionSelectHandler}
          />
        ) : null}
      </Box>
      <form onSubmit={handleSubmit(onVehicleSubmitHandler)}>
        <VehicleForm
          control={control}
          errors={errors}
          disabled={disabled}
          setValue={setValue}
        />
        {!disabled && (
          <Box display="flex" gap={1}>
            {id ? (
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={() => {
                  reset(vehicle);
                  setDisabled(true);
                }}
              />
            ) : null}
            <CustomizedButton
              type="submit"
              variant="contained"
              title={t("button.save")}
            />
          </Box>
        )}
      </form>
      <ModalUI
        title="ยืนยันการลบข้อมูลรถ"
        open={open}
        handleClose={() => setOpen(false)}
        maxWidth="xs"
      >
        <DialogContentText>
          หากลบแล้วจะไม่สามารถเปลี่ยนแปลงรายการได้
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            mt: 2,
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={() => setOpen(false)}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={() =>
              dispatch(deleteVehicle(parseInt(id), navigate, enqueueSnackbar))
            }
          />
        </Box>
      </ModalUI>
    </>
  );
};

export default VehicleInfo;
