import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

export default function Warehouse({
  control,
  errors,
  reset,
  getValues,
  setValue,
  viewOnly,
  watchBaseUom,
  watchConversion,
  isInventoryPage,
}) {
  const { t } = useTranslation();
  const { allUoms } = useSelector((state) => state.uom);

  const uomConversion =
    watchConversion?.map((conversion) => conversion.target_uom?.document_id) ||
    [];

  const warehouseUom = useMemo(
    () => ["purchase_uom", "sales_uom", "deliver_uom"],
    []
  );

  const newUomList = allUoms
    ?.map((uom) => {
      return {
        ...uom,
        id: uom.document_id,
        label: uom.name,
        value: uom.document_id,
      };
    })
    .filter(
      (uom) => uom.name === watchBaseUom.name || uomConversion.includes(uom.id)
    );

  const checkUomList = newUomList.map((uom) => uom.name);

  useEffect(() => {
    warehouseUom.forEach((uom) => {
      const uomValue = getValues(`${uom}.name`);
      if (!checkUomList.includes(uomValue)) {
        setValue(`${uom}.name`, "");
      }
    });
  }, [getValues, setValue, checkUomList, warehouseUom]);

  return (
    <>
      <Box>
        {/* <Typography fontWeight="bold" my={2} ml={1}>
          {t("inventory.items.accounting")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              error={errors}
              name={"inventory_account"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("inventory.items.inventoryAccount")}
                  options={[]}
                  disabled={true}
                />
              )}
            />
          </Grid>
        </Grid> */}
        <Box display={"flex"}>
          <Typography fontWeight="bold" my={2} ml={1}>
            {t("inventory.unit")}
          </Typography>
        </Box>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              error={errors}
              name={"base_uom.name"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  testId="item-base-uom-field"
                  label={t("inventory.items.stockUom")}
                  options={allUoms?.map((uom) => {
                    return {
                      ...uom,
                      id: uom.document_id,
                      label: uom.name,
                      value: uom.document_id,
                    };
                  })}
                  onChange={(_, newValue) =>
                    field.onChange(newValue ? newValue.name : "")
                  }
                  error={Boolean(errors.base_uom)}
                  helperText={errors.base_uom?.message}
                  disabled={!isInventoryPage || viewOnly}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              error={errors}
              name={"sales_uom.name"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  testId="item-sales-uom-field"
                  label={t("inventory.items.salesUom")}
                  options={newUomList}
                  onChange={(_, newValue) =>
                    field.onChange(newValue ? newValue.label : "")
                  }
                  error={Boolean(errors.sales_uom)}
                  helperText={errors.sales_uom?.message}
                  disabled={!isInventoryPage || viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              error={errors}
              name={"purchase_uom.name"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  testId="item-purchase-uom-field"
                  label={t("inventory.items.purchaseUom")}
                  options={newUomList}
                  onChange={(_, newValue) =>
                    field.onChange(newValue ? newValue.label : "")
                  }
                  error={Boolean(errors.purchase_uom)}
                  helperText={errors.purchase_uom?.message}
                  disabled={!isInventoryPage || viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              error={errors}
              name={"deliver_uom.name"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  testId="item-deliver-uom-field"
                  label={t("inventory.items.deliveryUom")}
                  options={newUomList}
                  onChange={(_, newValue) =>
                    field.onChange(newValue ? newValue.label : "")
                  }
                  error={Boolean(errors.deliver_uom)}
                  helperText={errors.deliver_uom?.message}
                  disabled={!isInventoryPage || viewOnly}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
