import { useEffect, useState } from "react";
import {
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../utils/filterparams";
import { formatNumberWithValidate } from "../../utils/dataTransformer";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import CustomizedLetterAvatar from "../../components/Custom/CustomizedLetterAvatar";
import { Avatar } from "@mui/material";
import { unixToDateWithFormat } from "../../utils/date-converter";

const useGoodsReceiveColumnDef = (isReport) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (isReport) {
      setColumnDefs([
        {
          field: "document_id",
          headerName: t("inventory.receive.documentId"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "reference_document_id",
          headerName: t("inventory.referenceNo"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "created_date",
          headerName: t("inventory.documentDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
          },
          valueFormatter: (params) => unixToDateWithFormat(params.value),
        },
        {
          field: "document_date",
          headerName: t("inventory.receive.receiveDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
          },
          valueFormatter: (params) => unixToDateWithFormat(params.value),
        },
        {
          field: "item_document_id",
          headerName: t("inventory.items.itemId"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          sortable: false,
        },
        {
          field: "name",
          headerName: t("inventory.items.itemName"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          sortable: false,
        },
        {
          field: "item_description",
          headerName: t("inventory.items.itemDescription"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          sortable: false,
        },
        {
          field: "posted_quantity",
          headerName: t("inventory.quantity"),
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("number"),
          },
          sortable: false,
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        },
        {
          field: "uom_name",
          headerName: t("inventory.unit"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          sortable: false,
        },
        {
          field: "type",
          headerName: t("inventory.receive.receiveType"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["ซื้อ", "ผลิต", "แปรรูป", "อื่นๆ"],
          },
        },
        {
          field: "source_warehouse_thai_name",
          headerName: t("inventory.warehouse"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "lot",
          headerName: t("inventory.list.lotNumber"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          valueFormatter: (params) => unixToDateWithFormat(params.value),
        },
        {
          field: "remark",
          headerName: t("inventory.remark"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          sortable: false,
        },
        {
          field: "created_by",
          headerName: t("inventory.receive.importedBy"),
          filter: "agSetColumnFilter",
          sortable: false,
          cellRenderer: (params) => {
            if (params.data.created_by_img_url) {
              return (
                <Avatar alt="img_url" src={params.data.created_by_img_url} />
              );
            } else {
              return <CustomizedLetterAvatar name={params.value} />;
            }
          },
          cellStyle: {
            display: "flex",
            alignItems: "center",
          },
        },
        {
          field: "status",
          headerName: t("inventory.status"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params) => {
              return filterStatusValueFormatter(params.value);
            },
            values: ["draft", "finished", "cancelled"],
          },
          cellRenderer: (params) => {
            return <CustomizedStatus status={params.value} />;
          },
          cellStyle: {
            display: "flex",
            alignItems: "center",
          },
        },
      ]);
    }
  }, [isReport, t]);
  return columnDefs;
};

export default useGoodsReceiveColumnDef;
