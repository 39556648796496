import { createSlice } from "@reduxjs/toolkit";
import { manufactureOrderInitialState as initialState } from "./manufacture-order-initial";

const manufactureOrderSlice = createSlice({
  name: "manufactureOrder",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    loaded(state, action) {
      state.isLoading[action.payload] = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllManufactureOrders(state, action) {
      state.allManufactureOrders = action.payload;
      state.isLoading.allManufactureOrders = false;
    },
    loadedAllManufactureOrdersCalendar(state, action) {
      state.allManufactureOrdersCalendar = action.payload;
      state.isLoading.allManufactureOrders = false;
    },
    loadedAllManufactureOrdersExport(state, action) {
      state.allManufactureOrdersExport = action.payload;
      state.isLoading.allManufactureOrdersExport = false;
    },
    loadedAllUnderqualityItemsExport(state, action) {
      state.allUnderqualityItemsExport = action.payload;
      state.isLoading.allUnderqualityItemsExport = false;
    },
    loadedManufactureOrder(state, action) {
      state.manufactureOrder = action.payload;
      state.isLoading.manufactureOrder = false;
    },
    loadedGoodReceiveTab(state, action) {
      state.goodReceiveTab = action.payload;
      state.isLoading.manufactureOrder = false;
    },
    loadedGoodIssueTab(state, action) {
      state.goodIssueTab = action.payload;
      state.isLoading.manufactureOrder = false;
    },
    updateManufactureOrderStatus(state, action) {
      state.manufactureOrder.render_status = action.payload;
      state.isLoading.manufactureOrder = false;
    },
    resetAllManufactureOrdersItemsExport(state) {
      state.allManufactureOrdersExport =
        initialState.allManufactureOrdersExport;
      state.error = null;
    },
    resetAllUnderqualityItemsExport(state) {
      state.allUnderqualityItemsExport =
        initialState.allUnderqualityItemsExport;
      state.error = null;
    },
    resetAllManufactureOrders(state) {
      state.allManufactureOrders = initialState.allManufactureOrders;
      state.error = null;
    },
    resetManufactureOrder(state) {
      state.manufactureOrder = initialState.manufactureOrder;
      state.isLoading.manufactureOrder = false;
      state.error = null;
    },
    resetGoodReceiveTab(state) {
      state.goodReceiveTab = initialState.goodReceiveTab;
      state.error = initialState.error;
    },
    resetGoodIssueTab(state) {
      state.goodIssueTab = initialState.goodIssueTab;
      state.error = initialState.error;
    },
    resetAllManufactureOrdersCalendar(state) {
      state.allManufactureOrdersCalendar =
        initialState.allManufactureOrdersCalendar;
      state.error = null;
    },
  },
});

export const manufactureOrderActions = manufactureOrderSlice.actions;

export default manufactureOrderSlice.reducer;
