import moment from "moment";
import { dateToUnix, formatDateExport } from "./date-converter";

export const filterParamsOptions = (type) => {
  if (type === "string") {
    return ["contains", "equals", "startsWith", "endsWith"];
  } else if (type === "date") {
    return [
      "equals",
      "greaterThan",
      "lessThan",
      {
        displayKey: "inRange",
        displayName: "inRange",
        predicate: ([fv1, fv2], cellValue) => {
          const dateParts = cellValue.split("/");
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );
          const dateValue = new Date(cellDate);
          let formatFv2 = new Date(moment(fv2).endOf("day"));
          return (
            dateValue == null || (dateValue >= fv1 && dateValue <= formatFv2)
          );
        },
        numberOfInputs: 2,
      },
    ];
  } else if (type === "number") {
    return [
      "equals",
      "lessThan",
      "lessThanOrEqual",
      "greaterThan",
      "greaterThanOrEqual",
      "inRange",
    ];
  }
};

export const filterDateWithUnix = (date) => {
  switch (date?.type) {
    case "equals":
      return {
        filterType: "number",
        type: "inRange",
        filter: dateToUnix(date.dateFrom),
        filterTo: dateToUnix(moment(date.dateFrom).endOf("day")),
      };
    case "greaterThan":
      return {
        filterType: "number",
        type: "greaterThan",
        filter: dateToUnix(moment(date.dateFrom).add(1, "day")),
      };
    case "lessThan":
      return {
        filterType: "number",
        type: "lessThan",
        filter: dateToUnix(moment(date.dateFrom).subtract(1, "minute")),
      };
    case "inRange":
      return {
        filter: dateToUnix(date.dateFrom),
        filterTo: dateToUnix(moment(date.dateTo).endOf("day")),
        filterType: "number",
        type: "inRange",
      };
    default:
      return;
  }
};

export const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  const dateAsString = cellValue;
  if (dateAsString == null) return -1;
  const dateWithoutTime = dateAsString.split(",")[0];
  const dateParts = dateWithoutTime.split("/");
  const cellDate = new Date(
    Number(dateParts[2].substring(0, 4)),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );
  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
};

export const filterStatusValueFormatter = (status) => {
  switch (status) {
    case "active":
      return "ใช้งาน";
    case "inactive":
      return "ไม่ใช้งาน";
    case "TRUE":
      return "ใช่";
    case "FALSE":
      return "ไม่";
    case "draft":
      return "ร่าง";
    case "waitDeliver":
      return "รอจัดส่ง";
    case "delivering":
      return "กำลังจัดส่ง";
    case "completed":
      return "สำเร็จ";
    case "notCompleted":
      return "ไม่สำเร็จ";
    case "PENDING":
      return "รออนุมัติ";
    case "DECLINED":
      return "ไม่อนุมัติ";
    case "waitAccept":
      return "รอตอบรับ";
    case "accepted":
      return "ตอบรับแล้ว";
    case "approved":
      return "อนุมัติแล้ว";
    case "waitPayment":
      return "รอชำระ";
    case "partiallyPaid":
      return "ชำระแล้วบางส่วน";
    case "partiallyImported":
      return "นำเข้าแล้วบางส่วน";
    case "partiallyOrdered":
      return "สั่งซื้อแล้วบางส่วน";
    case "fullyOrdered":
      return "สั่งซื้อแล้ว";
    case "fullyImported":
      return "นำเข้าแล้ว";
    case "fullyPaid":
      return "ชำระแล้ว";
    case "expired":
      return "เกินเวลา";
    case "finished":
      return "เสร็จสิ้น";
    case "cancelled":
    case "canceled":
      return "ยกเลิก";
    case "pendingManu":
      return "รอผลิต";
    case "inProgress":
    case "in_progress":
      return "กำลิงผลิต";
    case "RAW":
      return "วัตถุดิบ/สำเร็จรูป";
    case "PREPROCESSED":
      return "แปรรูป";
    default:
      return;
  }
};

export const exportCSVParams = (fileName, startDate, endDate) => {
  let formatFileName;
  if (startDate && endDate) {
    formatFileName = `${fileName}_${formatDateExport(
      startDate
    )}_${formatDateExport(endDate)}`;
  } else {
    formatFileName = fileName;
  }
  return {
    fileName: formatFileName,
    allColumns: true,
    processCellCallback: (params) => {
      if (params.column.colId === "created_date")
        return params.value.split(",")[0];
      else if (params.column.colId === "group")
        return params.value.map((tag) => tag.name).join(",");
      else if (params.column.colId === "wo_group")
        return params.value.join(",");
      return params.value;
    },
    // processCellCallback: (params) => {
    //   if (params.column.colId === "render_status") {
    //     return filterStatusValueFormatter(params.value);
    //   } else {
    //     return params.value;
    //   }
    // },
  };
};
