import {
  Box,
  IconButton,
  Toolbar,
  Badge,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import MenuIcon from "@mui/icons-material/Menu";
import CustomizedButton from "../Custom/CustomizedButton";
import CustomizedDashboardNavbar from "../Custom/CustomizedDashboardNavbar";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { useNavbar } from "../../hooks/use-navbar";
import { useApproval } from "../../hooks/use-approval";
import { useSelector } from "react-redux";
import UserBadge from "./UserBadge";

const TopNavbar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sidebar } = useNavbar();
  const { pendingsCount, refreshApprovals } = useApproval();
  const { isLoading: quotationLoading } = useSelector(
    (state) => state.quotation
  );
  const { isLoading: salesOrderLoading } = useSelector(
    (state) => state.salesOrder
  );
  // const { isLoading: salesInvoiceLoading } = useSelector(
  //   (state) => state.salesInvoice
  // );
  // const { isLoading: depositInvoiceLoading } = useSelector(
  //   (state) => state.depositInvoice
  // );
  const { isLoading: salesReturnLoading } = useSelector(
    (state) => state.salesReturn
  );
  // const { isLoading: creditNoteLoading } = useSelector(
  //   (state) => state.creditNote
  // );
  const { isLoading: purchaseRequestLoading } = useSelector(
    (state) => state.purchaseRequest
  );
  const { isLoading: purchaseOrderLoading } = useSelector(
    (state) => state.purchaseOrder
  );
  const { isLoading: purchaseReturnLoading } = useSelector(
    (state) => state.purchaseReturn
  );
  const { isLoading: itemLoading } = useSelector((state) => state.itemMaster);
  const { isLoading: goodsReceiveLoading } = useSelector(
    (state) => state.goodsReceive
  );
  const { isLoading: goodsIssueLoading } = useSelector(
    (state) => state.goodsIssue
  );
  const { isLoading: goodsTransferLoading } = useSelector(
    (state) => state.goodsTransfer
  );
  const { isLoading: goodsAdjustmentLoading } = useSelector(
    (state) => state.goodsAdjustment
  );
  const { isLoading: inventoryReportLoading } = useSelector(
    (state) => state.inventoryReport
  );
  const { isLoading: rbacLoading } = useSelector((state) => state.rbac);
  const { isLoading: accountLoading } = useSelector((state) => state.account);
  const { isLoading: salesReportLoading } = useSelector(
    (state) => state.salesReport
  );
  const { isLoading: deliveryOrderLoading } = useSelector(
    (state) => state.deliveryOrder
  );
  const { isLoading: vehicleLoading } = useSelector((state) => state.vehicle);
  const { isLoading: deliveryTripLoading } = useSelector(
    (state) => state.deliveryTrip
  );
  const { isLoading: purchaseReportLoading } = useSelector(
    (state) => state.purchaseReport
  );
  const { isLoading: bomIsLoading } = useSelector((state) => state.bom);
  const { isLoading: manufactureOrderIsLoading } = useSelector(
    (state) => state.manufactureOrder
  );
  const { isLoading: workOrderLoading } = useSelector(
    (state) => state.workOrder
  );

  const goToApprovalProgress = () => {
    refreshApprovals();
    navigate("/approval");
  };

  return (
    <>
      <CustomizedDashboardNavbar open={!isSmallScreen && sidebar.isSidebarOpen}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          {!sidebar.isSidebarOpen && (
            <IconButton onClick={() => sidebar.setIsSidebarOpen(true)}>
              <MenuIcon fontSize="small" />
            </IconButton>
          )}
          <Box sx={{ ml: sidebar.isSidebarOpen ? 0 : 4 }}>
            {!isSmallScreen && (
              <CustomizedButton
                variant="outlined"
                title={t("button.back")}
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIcon fontSize="small" />}
              />
            )}
            {isSmallScreen && (
              <Box
                sx={{
                  border: "1px solid rgba(50, 143, 53, 0.6)",
                  borderRadius: "4px",
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon
                    sx={{
                      color: "#328F35",
                      fontSize: "0.75rem",
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Badge badgeContent={pendingsCount} color="primary" sx={{ mr: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton size="small" onClick={() => goToApprovalProgress()}>
                <DoneAllIcon />
              </IconButton>
            </Box>
          </Badge>
          <UserBadge />
        </Toolbar>
        {(quotationLoading.quotation ||
          quotationLoading.allQuotationsExport ||
          salesOrderLoading.salesOrderReports ||
          salesOrderLoading.salesOrder ||
          // salesInvoiceLoading.allSalesInvoices ||
          // salesInvoiceLoading.salesInvoice ||
          // depositInvoiceLoading.allDepositInvoices ||
          // depositInvoiceLoading.depositInvoice ||
          salesReturnLoading.allSalesReturnsExport ||
          salesReturnLoading.salesReturn ||
          // creditNoteLoading.allCreditNotes ||
          // creditNoteLoading.creditNote ||
          itemLoading.item ||
          goodsReceiveLoading.item ||
          goodsReceiveLoading.allItemsExport ||
          goodsIssueLoading.item ||
          goodsIssueLoading.allItemsExport ||
          goodsTransferLoading.item ||
          goodsTransferLoading.allItemsExport ||
          goodsAdjustmentLoading.item ||
          goodsAdjustmentLoading.allItemsExport ||
          inventoryReportLoading.stockEntries ||
          inventoryReportLoading.traceEntry ||
          inventoryReportLoading.itemNoEntry ||
          inventoryReportLoading.itemCurrentStock ||
          inventoryReportLoading.itemCurrentStockLocation ||
          inventoryReportLoading.itemCurrentStockSerialNumber ||
          inventoryReportLoading.inActiveSerial ||
          // rbacLoading.allRoles ||
          rbacLoading.role ||
          rbacLoading.priviledgeList ||
          // accountLoading.allUsers ||
          accountLoading.user ||
          salesReportLoading.salesReportByCustomer ||
          salesReportLoading.salesReportByProduct ||
          // deliveryOrderLoading.allDeliveryOrders ||
          deliveryOrderLoading.deliveryOrder ||
          deliveryOrderLoading.deliveryOrderReports ||
          vehicleLoading.allVehicles ||
          vehicleLoading.vehicle ||
          // deliveryTripLoading.allDeliveryTrips ||
          deliveryTripLoading.deliveryTripReports ||
          deliveryTripLoading.deliveryTrip ||
          purchaseOrderLoading.allPurchaseOrderExport ||
          purchaseOrderLoading.purchaseOrder ||
          purchaseRequestLoading.allPurchaseRequestExport ||
          purchaseRequestLoading.purchaseRequest ||
          purchaseReturnLoading.allPurchaseReturnsExport ||
          purchaseReturnLoading.purchaseReturn ||
          purchaseReportLoading.purchaseReportByVendor ||
          purchaseReportLoading.purchaseReportByProduct ||
          // bomIsLoading.allBoms ||
          bomIsLoading.bom ||
          bomIsLoading.allBomsExport ||
          // workOrderLoading.allWorkOrders ||
          workOrderLoading.workOrder ||
          workOrderLoading.allWorkOrdersExport ||
          // manufactureOrderIsLoading.allManufactureOrders ||
          manufactureOrderIsLoading.manufactureOrder ||
          manufactureOrderIsLoading.allManufactureOrdersExport ||
          manufactureOrderIsLoading.allUnderqualityItemsExport) && (
          <LinearProgress />
        )}
      </CustomizedDashboardNavbar>
    </>
  );
};

export default TopNavbar;
