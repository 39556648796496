import * as Yup from "yup";

export const schema = {
  email: null,
  password: null,
};

export const validation = Yup.object().shape({
  email: Yup.string().required("กรุณากรอก"),
  password: Yup.string().required("กรุณากรอก"),
});
