import React from "react";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { useDispatch, useSelector } from "react-redux";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Divider, useMediaQuery } from "@mui/material";
import Information from "../../../components/Form/ItemMaster/Information";
import General from "../../../components/Form/ItemMaster/General";
import Warehouse from "../../../components/Form/ItemMaster/Warehouse";
import Sales from "../../../components/Form/ItemMaster/Sales";
import Purchase from "../../../components/Form/ItemMaster/Purchase";
import SpecificInfo from "../../../components/Form/ItemMaster/SpecificInfo";
import MRP from "../../../components/Form/ItemMaster/MRP";
import Manufacture from "../../../components/Form/ItemMaster/Manufacture";
import CustomizedScrollbar from "../../../components/Custom/CustomizedScrollbar";
import {
  createItemWithUom,
  updateItem,
} from "../../../features/Inventory/ItemMaster/itemMaster-actions";
import { useSnackbar } from "notistack";
import { formatUpdateItemPayload } from "../../../utils/dataTransformer";
import { useAuth } from "../../../hooks/use-auth";
import ConversionForm from "../../../components/Form/Setting/UomGroup/Conversion";
import { useWatch } from "react-hook-form";
import Delivery from "../../../components/Form/ItemMaster/Delivery";

export default function ItemInfo({
  control,
  errors,
  getValues,
  setValue,
  handleSubmit,
  reset,
  onCancel,
  isInventoryPage,
  viewOnly,
  getID,
}) {
  const { t } = useTranslation();
  const { allUoms } = useSelector((state) => state.uom);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get(isInventoryPage ? "subtab" : "itemtab");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const watchBaseUom = useWatch({ control, name: "base_uom" });
  const watchConversion = useWatch({ control, name: "uom_conversion_list" });

  const tabs = [
    {
      testId: "item-tab-general",
      label: t("inventory.items.general"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=general`
        : `${pathname}?itemtab=general`,
      errors:
        errors.length ||
        errors.width ||
        errors.thickness ||
        errors.weight ||
        errors.height ||
        errors.volume ||
        errors.length_uom ||
        errors.width_uom ||
        errors.thickness_uom ||
        errors.weight_uom ||
        errors.height_uom ||
        errors.volume_uom,
    },
    {
      testId: "item-tab-delivery",
      label: t("inventory.items.delivery"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=delivery`
        : `${pathname}?itemtab=delivery`,
    },
    {
      testId: "item-tab-purchase",
      label: t("inventory.items.purchase"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=purchase`
        : `${pathname}?itemtab=purchase`,
      errors:
        errors.purchase_standard_price ||
        errors.purchase_minimum_qty ||
        errors.purchase_vat_type,
    },
    {
      testId: "item-tab-sales",
      label: t("inventory.items.sales"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=sales`
        : `${pathname}?itemtab=sales`,
      errors: errors.sales_standard_price || errors.sales_vat_type,
    },
    {
      testId: "item-tab-warehouse",
      label: t("inventory.warehouse"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=warehouse`
        : `${pathname}?itemtab=warehouse`,
      errors:
        errors.purchase_uom ||
        errors.deliver_uom ||
        errors.sales_uom ||
        errors.stock_uom,
    },
    {
      testId: "item-tab-manufacture",
      label: t("inventory.items.manufacture"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=manufacture`
        : `${pathname}?itemtab=manufacture`,
    },
    {
      testId: "item-tab-mrp",
      label: t("inventory.items.mrp"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=mrp`
        : `${pathname}?itemtab=mrp`,
    },
    {
      testId: "item-tab-specific",
      label: t("inventory.items.specificInformation"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=specific`
        : `${pathname}?itemtab=specific`,
      errors: errors.attribute_list,
    },
  ];

  const currentTab =
    pathname +
    (tab
      ? isInventoryPage
        ? `?tab=item&subtab=${tab}`
        : `?itemtab=${tab}`
      : isInventoryPage
      ? "?tab=item&subtab=general"
      : "?itemtab=general");

  const renderTab = () => {
    switch (tab) {
      case "general":
        return (
          <General
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            viewOnly={viewOnly}
          />
        );
      case "delivery":
        return (
          <Delivery
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            reset={reset}
            isInventoryPage={isInventoryPage}
            viewOnly={viewOnly}
          />
        );
      case "warehouse":
        return (
          <Warehouse
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            reset={reset}
            watchBaseUom={watchBaseUom}
            watchConversion={watchConversion}
            isInventoryPage={isInventoryPage}
            viewOnly={viewOnly}
          />
        );
      case "sales":
        return (
          <Sales
            control={control}
            errors={errors}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            viewOnly={viewOnly}
          />
        );
      case "purchase":
        return (
          <Purchase
            control={control}
            errors={errors}
            isInventoryPage={isInventoryPage}
            viewOnly={viewOnly}
          />
        );
      case "specific":
        return (
          <SpecificInfo
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            isInventoryPage={isInventoryPage}
            viewOnly={viewOnly}
          />
        );
      case "mrp":
        return (
          <MRP
            control={control}
            errors={errors}
            isInventoryPage={isInventoryPage}
            viewOnly={viewOnly}
          />
        );
      case "manufacture":
        return (
          <Manufacture
            control={control}
            errors={errors}
            setValue={setValue}
            isInventoryPage={isInventoryPage}
            viewOnly={viewOnly}
          />
        );
      default:
        return (
          <General
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            viewOnly={viewOnly}
          />
        );
    }
  };

  const onSubmit = async (data) => {
    const serializedData = await formatUpdateItemPayload(
      data,
      allUoms,
      user,
      !id ? true : false
    );
    if (serializedData) {
      if (
        serializedData.uom_group.uom_conversion_list &&
        serializedData.uom_group.uom_conversion_list.length > 0
      ) {
        const { uom_group } = serializedData;
        const { uom_conversion_list } = uom_group;
        if (
          uom_conversion_list.some(
            (list) => list.target_uom_rate > list.base_uom_rate
          )
        ) {
          return enqueueSnackbar("จำนวนหน่วยแปลงมากกว่าหน่วยหลัก", {
            variant: "error",
          });
        }
      }
    }
    if (!id)
      dispatch(
        createItemWithUom(serializedData, enqueueSnackbar, navigate, user)
      );
    else
      dispatch(
        updateItem(
          { document_id: id },
          serializedData,
          enqueueSnackbar,
          navigate,
          user
        )
      );
  };

  const onError = (err) => {
    console.error(err);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
        <CustomizedBox boxShadow={!isInventoryPage ? "none" : undefined}>
          <Information
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            viewOnly={viewOnly}
            isInventoryPage={isInventoryPage}
            getID={getID}
          />
        </CustomizedBox>
        {!isInventoryPage && <Divider />}
        <CustomizedBox boxShadow={!isInventoryPage ? "none" : undefined}>
          <CustomizedScrollbar>
            <CustomizedTab subtab tabs={tabs} currentTab={currentTab} />
          </CustomizedScrollbar>
          <CustomizedScrollbar>{renderTab()}</CustomizedScrollbar>
        </CustomizedBox>
        {tab === "warehouse" && (
          <CustomizedBox boxShadow={!isInventoryPage ? "none" : undefined}>
            <ConversionForm
              control={control}
              setValueForm={setValue}
              getValues={getValues}
              errors={errors}
              watchBaseUom={watchBaseUom}
              watchConversion={watchConversion}
              disabled={!isInventoryPage || viewOnly}
            />
          </CustomizedBox>
        )}
        {isInventoryPage && !viewOnly && (
          <Box display="flex" gap={1}>
            <CustomizedButton
              onClick={
                isInventoryPage ? () => navigate("/inventory/items") : onCancel
              }
              title="ยกเลิก"
              variant="outlined"
              fullWidth={isSmallScreen}
              size={isSmallScreen && "large"}
            />
            <CustomizedButton
              testId="item-submit-btn"
              title="บันทึก"
              variant="contained"
              type="submit"
              fullWidth={isSmallScreen}
              size={isSmallScreen && "large"}
            />
          </Box>
        )}
      </form>
    </>
  );
}
