import React, { useState } from "react";
import ModalUI from "../components/UI/ModalUI";
import { Box, DialogContentText } from "@mui/material";
import CustomizedButton from "../components/Custom/CustomizedButton";

const ServerDownVerify = () => {
  const [openModal, setOpenModal] = useState(false);

  // const handleOpen = () => {
  //   setOpenModal(true);
  // };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <ModalUI
      title="ขออภัยกำลังปรับปรุงระบบ"
      handleClose={handleClose}
      open={openModal}
      maxWidth="xs"
      centered
    >
      <DialogContentText sx={{ textAlign: "center" }}>
        ไม่สามารถเชื่อมต่อกับฐานข้อมูลได้ (Service is not avaiable)
      </DialogContentText>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 3,
        }}
      >
        <CustomizedButton
          title="ตกลง"
          variant="contained"
          onClick={handleClose}
          fullWidth
        />
      </Box>
    </ModalUI>
  );
};

export default ServerDownVerify;
