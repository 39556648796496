import { Box } from "@mui/material";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import CustomizedChips from "../../Custom/CustomizedChips";
import GlobalService from "../../../services/Global";
import { formatNumber } from "../../../utils/dataTransformer";

export const underqualityItemsColumnDef = (t, groupOptions) => {
  return [
    {
      field: "manufacture_order_document_id",
      headerName: t("manufacture.order.document_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "issue_date",
      headerName: t("manufacture.order.issue_date"),
      filter: "agDateColumnFilter",
      width: 175,
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
    },
    {
      field: "expect_product_date",
      headerName: t("manufacture.order.expect_product_date"),
      filter: "agDateColumnFilter",
      width: 175,
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: dateComparator,
      },
    },
    {
      field: "item_document_id",
      headerName: t("manufacture.order.item_document_id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "item_name",
      headerName: t("manufacture.order.item_name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "qty",
      headerName: t("manufacture.order.item_qty"),
      filter: false,
      disableFloatingFilter: true,
      sortable: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "uom",
      headerName: t("manufacture.order.stock_uom"),
      filter: false,
      disableFloatingFilter: true,
      sortable: false,
    },
    {
      field: "item_description",
      headerName: t("manufacture.workOrder.detail"),
      filter: false,
      disableFloatingFilter: true,
      sortable: false,
    },
    {
      field: "type",
      headerName: t("manufacture.order.type"),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: async (params) => {
          const loadedOptions = await GlobalService.getCreatables({
            usage_field_type: "item",
            usage_field_name: "manufacture_type",
          });
          const formatType = loadedOptions.map((option) => option.name);
          params.success(formatType);
        },
      },
    },
    {
      field: "group",
      headerName: t("manufacture.order.group"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          const loadedOptions = await GlobalService.getTagListByEntity(
            "manufacture_order"
          );
          const formatType = loadedOptions.map((option) => option.name);
          params.success(formatType);
        },
      },
      cellRenderer: (params) => {
        return params.value.map((group, index) => (
          <Box sx={{ display: "inline-block", mr: 1, gap: 1 }}>
            <CustomizedChips key={group + index} value={`${group}`} />
          </Box>
        ));
      },
    },
    {
      field: "internal_remark",
      headerName: t("manufacture.order.remark"),
      filter: false,
      disableFloatingFilter: true,
      sortable: false,
    },
  ];
};
