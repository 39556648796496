import { Box, DialogContentText } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";

const DirtyConfirmation = ({
  openDirtyConfirmation,
  dirtyConfirmationAction,
  closeConfirmationHandler,
}) => {
  return (
    <ModalUI
      title="ต้องการออกจากหน้านี้ใช่หรือไม่"
      open={openDirtyConfirmation}
      handleClose={closeConfirmationHandler}
      maxWidth="sm"
    >
      <DialogContentText>
        ข้อมูลที่ถูกแก้ไขแล้วจะไม่ถูกบันทุกในระบบ
      </DialogContentText>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
          width: 220,
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={dirtyConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default DirtyConfirmation;
