import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { validation } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import {
  createUomGroup,
  updateUomGroup,
} from "../../../features/Setting/Uom/uom-actions";
import { getAllUom } from "../../../features/Setting/Uom/uom-actions";
import { uomActions } from "../../../features/Setting/Uom/uom-slice";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";

const UomGroupForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { uomGroup, allUoms, uomGroupIndex } = useSelector(
    (state) => state.uom
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: uomGroup,
    resolver: yupResolver(validation),
  });

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllUom());
  }, [dispatch]);

  const onSubmit = (data) => {
    const input = {
      ...data,
      document_id: data.name,
      base_uom_document_id: data.base_uom_document_id,
    };
    try {
      if (!uomGroup.id) {
        dispatch(createUomGroup({ createInput: input }));
      } else if (uomGroup.id) {
        delete input.id;
        delete input.document_id;
        delete input.convertable_uom_list;
        delete input.uom_conversion_list;
        delete input.base_uom;

        dispatch(
          updateUomGroup({
            input: {
              uniqueInput: { document_id: uomGroup.document_id },
              updateInput: input,
            },
            index: uomGroupIndex,
          })
        );
      }
      handleClose();
    } catch (error) {
      handleClose();
    }
  };

  const resetForm = () => {
    handleClose();
    setTimeout(() => {
      dispatch(uomActions.resetUomGroup());
    }, 300);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container gap={1} mt={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container gap={1}>
            {/* <Grid item xs={12}>
              <Controller
                name={"document_id"}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    disabled
                    label={t("setting.inventory.uom.groupID")}
                    {...field}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    label={t("setting.inventory.uom.groupName")}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"base_uom_document_id"}
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    error={Boolean(errors.base_uom_document_id)}
                    helperText={errors.base_uom_document_id?.message}
                    label={t("setting.inventory.uom.baseUom")}
                    options={
                      allUoms?.map((uom) => {
                        return {
                          id: uom.document_id,
                          value: uom.document_id,
                          label: uom.name,
                        };
                      }) || []
                    }
                    {...field}
                    onChange={(_, newValue) => {
                      field.onChange(newValue ? newValue.label : "");
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"description"}
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                multiline={true}
                rows={3}
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
                label={t("setting.description")}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <CustomizedButton
        type="reset"
        onClick={resetForm}
        title={t("button.cancel")}
        variant="outlined"
        sx={{ mt: 3, mr: 2 }}
      />
      <CustomizedButton
        type="submit"
        title={t("button.save")}
        variant="contained"
        sx={{ mt: 3 }}
      />
    </form>
  );
};

export default UomGroupForm;
