import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HistoryIcon from "@mui/icons-material/History";
import ReturnDetail from "./Detail";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  createGoodsReceiveWithApprove,
  // getAllGoodsReceive,
  getGoodsReceiveById,
} from "../../../features/Inventory/GoodsReceive/goodsReceive-actions";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RightDrawer from "../../../components/UI/RightDrawer";
import GlobalService from "../../../services/Global";
import GoodsReceiveReturnTable from "../../../components/Table/DocumentTable/GoodsReceiveReturnTable";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";
import { goodsReceiveActions } from "../../../features/Inventory/GoodsReceive/goodsReceive-slice";
import { getAllLocation } from "../../../features/Setting/Location/location-actions";
import { useAuth } from "../../../hooks/use-auth";
import { validation } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { formatGRReturnPayload } from "../../../utils/dataTransformer";
import InventoryService from "../../../services/Inventory";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import moment from "moment";
import ControlledDatePicker from "../../../components/Custom/ControlledDatePicker";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import { dateToUnix } from "../../../utils/date-converter";
import { v4 as uuidv4 } from "uuid";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ReturnContainer = ({ status }) => {
  // const gridRef = useRef();
  const scrollRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { item, isLoading } = useSelector((state) => state.goodsReceive);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [createDateIsOpen, setCreateDateIsOpen] = useState(false);
  const [returnDateIsOpen, setReturnDateIsOpen] = useState(false);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [optionsLocation, setOptionsLocation] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [totalError, setTotalError] = useState(false);
  const { state, location } = useLocation();
  const [showError, setShowError] = useState({
    type: "success",
    value: true,
    text: "awdascascawdaw dapowdk a; mdl",
  });

  useEffect(() => {
    if (currentTab) {
      setShowCamera(false);
    }
  }, [currentTab]);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  const {
    control: barcodeControl,
    handleSubmit: handleBarcodeSubmit,
    reset: resetFieldBarcode,
    setValue: setValueBarcode,
  } = useForm({
    defaultValues: {
      barcode: "",
      source_bin_location_document_id: "",
    },
  });

  const watchBinLocation = useWatch({
    control: barcodeControl,
    name: "source_bin_location_document_id",
  });

  const watchType = useWatch({ control, name: "type" });
  const watchRefDoc = useWatch({ control, name: "reference_document_id" });

  const watchWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "goods_receive_list",
    keyName: "genId",
  });

  useEffect(() => {
    if (state) {
      if (state.reference_document_type === "sales_return") {
        const checkIfAlreadyReturn = async (documentId) => {
          const formatFilterModel = {
            reference_document_id: {
              filterType: "text",
              type: "equals",
              filter: documentId,
            },
          };
          const allItems = await InventoryService.getAllGRAggrid({
            startRow: 0,
            endRow: 999,
            filterModel: formatFilterModel,
          });
          return allItems;
        };
        const setFormatValue = async () => {
          const { results } = await checkIfAlreadyReturn(state.document_id);
          const formatValue = state.item_list.map((item) => {
            const newItem = [];
            for (let i = 0; i < results.length; i++) {
              results[i].goods_receive_list.forEach((list) => {
                list.stock_entry_list[0]?.trace_entry_list?.forEach((teList) =>
                  newItem.push(teList)
                );
              });
            }
            const prevQuantity = newItem
              .filter((result) => result.item_uid === item.uid)
              .reduce((prev, curr) => prev + curr.posted_quantity, 0);
            return {
              initial_quantity: item.qty_return,
              posted_quantity: item.qty_return,
              posted_value: 0,
              already_posted_quantity: prevQuantity,
              stock_entry_list: {
                item_uid: item.uid || uuidv4(),
                destination_warehouse: "",
                entry_type: "",
                is_active: true,
                item: {
                  document_id: item.item_document_id,
                  name: item.item_name,
                  description: item.item_description,
                  quantity: item.qty,
                  ...item,
                },
                posted_date: dateToUnix(new Date()),
                posted_quantity: 0,
                posted_value: 0,
                reference_document_id: "",
                reference_document_type: "",
                source_warehouse_document_id: null,
                trace_entry_list: [],
                created_by: user,
                uom: item.uom,
              },
            };
          });
          setValue("type", "รับคืนจากลูกค้า");
          setValue("reference_document_id", state.document_id);
          setValue("goods_receive_list", formatValue);
        };
        setFormatValue();
      }
    }
  }, [setValue, state, user]);

  useEffect(() => {
    dispatch(getAllLocation());
    if (id) {
      dispatch(
        getGoodsReceiveById({
          uniqueInput: { document_id: id },
        })
      );
    }
    return () => dispatch(goodsReceiveActions.resetItem());
  }, [dispatch, id]);

  const generateDocumentId = useCallback(async () => {
    setValue("document_id", "");
    const newDocId = await GlobalService.getRunningDocumentId("goods_receive");
    setValue("document_id", newDocId);
  }, [setValue]);

  const checkIfAlreadyReturn = useCallback(async () => {
    const formatFilterModel = {
      reference_document_id: {
        filterType: "text",
        type: "equals",
        filter: item.reference_document_id,
      },
    };
    const allItems = await InventoryService.getAllGRAggrid({
      startRow: 0,
      endRow: 999,
      filterModel: formatFilterModel,
    });
    return allItems;
  }, [item.reference_document_id]);

  const getGRList = useCallback(
    async (value) => {
      await item.goods_receive_list.forEach(async (grList) => {
        const { results } = await checkIfAlreadyReturn();
        let allItems = [];
        const findIndex = results.findIndex(
          (result) => result.document_id === item.document_id
        );
        for (let i = 0; i < findIndex; i++) {
          results[i].goods_receive_list.forEach((list) => {
            list.stock_entry_list[0].trace_entry_list.forEach((teList) =>
              allItems.push(teList)
            );
          });
        }
        const prevQuantity = allItems
          .filter(
            (te) =>
              grList.stock_entry_list.item_document_id ===
                te.item_document_id && item.document_id === id
          )
          .reduce((prev, curr) => prev + curr.posted_quantity, 0);
        setValue(
          "goods_receive_list",
          value.map((list) => {
            return { ...list, already_posted_quantity: prevQuantity };
          })
        );
      });
    },
    [
      checkIfAlreadyReturn,
      id,
      item.document_id,
      item.goods_receive_list,
      setValue,
    ]
  );

  useEffect(() => {
    //replace default values of form hook with new value from redux
    if (id) {
      Object.entries(item).forEach(async ([key, value], index) => {
        if (key === "goods_receive_list") return await getGRList(value);
        setValue(key, value);
      });
    } else {
      setValue("lot", new Date());
      setValue("type", "รับคืนจากลูกค้า");
      setValue("created_date", moment());
      setValue("document_date", moment().endOf("day"));
      generateDocumentId();
    }
  }, [generateDocumentId, getGRList, id, item, setValue]);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.return.index"),
      to: "/inventory/return",
    },
    {
      name: id ?? t("inventory.return.add"),
    },
  ];

  const createDoc = async (data) => {
    const serializedData = await formatGRReturnPayload(data);
    dispatch(
      createGoodsReceiveWithApprove(
        serializedData,
        enqueueSnackbar,
        navigate,
        user,
        false,
        location
      )
    );
  };

  const onSubmit = async (data, e) => {
    if (
      data.goods_receive_list.some(
        (list) => list.stock_entry_list.trace_entry_list.length > 0
      ) &&
      !totalError
    ) {
      await createDoc(data);
    } else {
      if (
        data.goods_receive_list.every(
          (list) => list.stock_entry_list.trace_entry_list.length === 0
        )
      ) {
        enqueueSnackbar("กรุณาเพิ่ม SN ในรายการสินค้าอย่างน้อย 1 ตัว", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("กรุณาระบุจำนวนรับคืนไม่เกินจำนวนรอรับคืน", {
          variant: "error",
        });
      }
    }
  };

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const checkSmallScreenError = useCallback(
    (type, text) => {
      return !isSmallScreen
        ? enqueueSnackbar(text, {
            variant: type,
          })
        : setShowError({ type: type, value: true, text });
    },
    [enqueueSnackbar, isSmallScreen]
  );

  const onBarcodeSubmitHandler = async (data) => {
    if (data.trim().length > 0) {
      try {
        await InventoryService.scanBarcode({
          uniqueInput: {
            batch_number: data,
          },
        });
      } catch (error) {
        return checkSmallScreenError("error", "ไม่พบ Barcode นี้อยู่ในระบบ");
      }
      const serialNumber = data
        .split("#", 4)
        .filter((_, index) => index !== 0)
        .join("#");
      const serialQuantity = data.split("#")[4];
      //A00002#WH01#GR2211090013#00001#5
      const { results } =
        await InventoryService.getItemCurrentStockSerialNumberScan({
          startRow: 0,
          endRow: 999,
          filterModel: {
            batch_number: {
              filter: data,
              filterType: "text",
              type: "equals",
            },
          },
        });
      const checkItemIsActive = results.some((te) => !te.item_is_active);
      if (checkItemIsActive)
        return checkSmallScreenError(
          "error",
          "ไม่สามารถสแกนได้ เนื่องจากสินค้านี้มีสถานะหยุดการใช้งานอยู่"
        );
      const traceEntry = results[results.length - 1];
      if (traceEntry) {
        const returnList = getValues("goods_receive_list");
        const foundMatchItem = returnList.findIndex(
          (item) =>
            item.stock_entry_list.item.document_id ===
            traceEntry.item_document_id
        );
        //1MT10.0-L-10M#1#GR2211020010#00001#10
        const formatTraceEntry = {
          ...traceEntry,
          uom: {
            name: traceEntry.base_uom_name,
            document_id: traceEntry.base_uom_document_id,
          },
          item: {
            name: traceEntry.item_name,
            document_id: traceEntry.item_document_id,
            sku: traceEntry.item_sku,
          },
          warehouse: {
            document_id: traceEntry.warehouse_document_id,
            name: traceEntry.warehouse_thai_name,
          },
          source_bin_location: {
            document_id: watchBinLocation,
          },
          source_bin_location_document_id: watchBinLocation,
          batch_number: data,
          is_scanned: true,
          posted_quantity: serialQuantity,
          created_by: user,
          scanned_by: user,
          scanned_date: new Date(),
          posted_date: moment().unix(),
        };
        if (foundMatchItem >= 0) {
          const currTraceEntry = getValues(
            `goods_receive_list[${foundMatchItem}].stock_entry_list.trace_entry_list`
          );
          if (currTraceEntry.some((teList) => teList.batch_number === data)) {
            checkSmallScreenError(
              "error",
              "QR/Barcode สินค้านี้ถูกสแกนและบันทึกลงรายการแล้ว"
            );
          } else {
            setValue(
              `goods_receive_list[${foundMatchItem}].stock_entry_list.trace_entry_list`,
              [...currTraceEntry, formatTraceEntry]
            );
            checkSmallScreenError("success", `สแกน SN: ${serialNumber} สำเร็จ`);
          }
        } else {
          checkSmallScreenError(
            "error",
            "QR/Barcode สินค้านี้ไม่อยู่ในรายการกรุณาสแกนใหม่"
          );
        }
      } else {
        checkSmallScreenError(
          "error",
          "QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่"
        );
      }
    }
    setValueBarcode("barcode", "");
  };

  useEffect(() => {
    if (showError.value) {
      setTimeout(() => {
        setShowError({ type: "success", value: false, text: "" });
      }, 1.5 * 1000);
    }
  }, [showError]);

  const getBinLocations = useCallback(async () => {
    const input = {
      where: {
        warehouse: {
          document_id: {
            equals: watchWarehouse,
          },
        },
      },
    };
    const result = await InventoryService.findBinLocations(input);
    const newValue = result.map((res) => {
      return {
        ...res,
        label: res.document_id,
        value: res.document_id,
      };
    });
    setOptionsLocation(newValue);
  }, [watchWarehouse]);

  useEffect(() => {
    getBinLocations();
  }, [getBinLocations]);

  const tabError =
    errors &&
    (errors?.document_id ||
      errors?.type ||
      errors?.reference_document_id ||
      errors?.lot ||
      errors?.source_warehouse_document_id ||
      errors?.remark);

  useEffect(() => {
    const autoCameraCloseHandler = () => {
      let scrolled = window.scrollY;

      if (scrolled >= 450) {
        setShowCamera(false);
      }
    };

    if (scrollRef && scrollRef.current) {
      window.addEventListener("scroll", autoCameraCloseHandler, false);
      return () => {
        window.removeEventListener("scroll", autoCameraCloseHandler, false);
      };
    }
  }, []);

  return (
    <>
      {!isSmallScreen ? (
        <>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
          <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
              <Typography variant="h5">
                {t("inventory.return.goodsReceiveList")}
              </Typography>
              <CustomizedStatus status={item.status} />
            </Box>
            <CustomizedTooltip title="ดูการเคลื่อนไหว">
              <IconButton onClick={() => setOpenDrawer(true)}>
                <HistoryIcon fontSize="small" color=" rgba(0, 0, 0, 0.54)" />
              </IconButton>
            </CustomizedTooltip>
            <RightDrawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              title={t("inventory.activity")}
              documentId={id}
              documentType="goods_receive"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: "2rem",
            }}
          >
            <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }}>
              <Typography>{t("inventory.return.importedBy")}</Typography>
              <CustomizedAvatar
                avatars={
                  item.created_by ? [{ ...item.created_by }] : [{ ...user }]
                }
              />
            </Box>
            <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                isOpen={createDateIsOpen}
                onClose={() => setCreateDateIsOpen(false)}
                onOpen={() => setCreateDateIsOpen(true)}
                label={t("inventory.stockEntry.createdDate")}
                sx={{ width: { sx: "auto", md: 155 } }}
                disabled
              />
              <ControlledDatePicker
                name="document_date"
                control={control}
                error={errors.document_date}
                isOpen={returnDateIsOpen}
                onClose={() => setReturnDateIsOpen(false)}
                onOpen={() => setReturnDateIsOpen(true)}
                label={t("inventory.return.returnDate")}
                disabled={Boolean(id)}
                sx={{ width: { sx: "auto", md: 155 } }}
                required
              />
            </Box>
          </Box>
          <ReturnDetail
            control={control}
            errors={errors}
            reset={reset}
            replace={replace}
            setValue={setValue}
            getValues={getValues}
            viewOnly={isLoading.item}
            watchType={watchType}
            watchRefDoc={watchRefDoc}
            generateDocumentId={generateDocumentId}
            resetFieldBarcode={resetFieldBarcode}
            isInventoryPage
          />
          <CustomizedBox>
            {!id && (
              <Grid container spacing={2} mb={2}>
                <Grid item xs={!isSmallScreen ? 3 : 12}>
                  <Controller
                    control={barcodeControl}
                    name="source_bin_location_document_id"
                    render={({ field }) => (
                      <CustomizedComboBox
                        {...field}
                        options={optionsLocation}
                        label={t("inventory.chooseLocation")}
                        onChange={(_, newValue) =>
                          field.onChange(newValue ? newValue.value : "")
                        }
                        disabled={
                          isLoading.item ||
                          watchRefDoc === "" ||
                          watchWarehouse === ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={barcodeControl}
                    name="barcode"
                    render={({ field }) => (
                      <CustomizedTextField
                        {...field}
                        label={t("inventory.scanBarcode")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.shiftKey === false) {
                            const data = e.target.value;
                            handleBarcodeSubmit(onBarcodeSubmitHandler(data));
                          }
                        }}
                        disabled={isLoading.item || watchBinLocation === ""}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <GoodsReceiveReturnTable
              control={control}
              fields={fields}
              append={append}
              remove={remove}
              disabled={id}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              totalError={totalError}
              setTotalError={setTotalError}
              watchWarehouse={watchWarehouse}
            />
          </CustomizedBox>
          {!id && (
            <Box display="flex" gap={1}>
              <CustomizedButton
                id="create"
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                title="รับคืน"
                // type="submit"
                disabled={isLoading.item}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
          <Box my={3}>
            <Tabs
              centered
              value={currentTab}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                sx: {
                  backgroundColor:
                    tabError && currentTab === 0 ? "#F44336" : "#419644",
                },
              }}
            >
              <Tab
                label="ข้อมูลนำเข้า"
                {...a11yProps(0)}
                sx={{
                  "&.Mui-selected": {
                    color: tabError ? "#F44336" : "#419644",
                  },
                  color: tabError ? "#F44336" : "#419644",
                }}
              />
              <Tab label="สแกน Barcode" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={currentTab} index={0}>
            <Box
              sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                <Typography variant="h5">
                  {t("inventory.receive.goodsReceiveList")}
                </Typography>
                <CustomizedStatus status={item.status} />
              </Box>
              <IconButton onClick={() => setOpenDrawer(true)}>
                <HistoryIcon fontSize="small" color=" rgba(0, 0, 0, 0.54)" />
              </IconButton>
              <RightDrawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                title={t("inventory.activity")}
                documentId={id}
                documentType="goods_receive"
              />
            </Box>
            <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }} mt={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                isOpen={createDateIsOpen}
                onClose={() => setCreateDateIsOpen(false)}
                onOpen={() => setCreateDateIsOpen(true)}
                label={t("inventory.stockEntry.createdDate")}
                sx={{ width: { sx: "auto", md: 155 } }}
                disabled
              />
              <ControlledDatePicker
                name="document_date"
                control={control}
                error={errors.document_date}
                isOpen={returnDateIsOpen}
                onClose={() => setReturnDateIsOpen(false)}
                onOpen={() => setReturnDateIsOpen(true)}
                label={t("inventory.receive.receiveDate")}
                disabled={Boolean(id)}
                sx={{ width: { sx: "auto", md: 155 } }}
                required
              />
            </Box>
            <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }} my={3}>
              <Typography>ผู้สร้าง</Typography>
              <CustomizedAvatar
                avatars={
                  item.created_by ? [{ ...item.created_by }] : [{ ...user }]
                }
              />
            </Box>
            <ReturnDetail
              control={control}
              errors={errors}
              reset={reset}
              setValue={setValue}
              getValues={getValues}
              replace={replace}
              viewOnly={isLoading.item}
              watchType={watchType}
              watchRefDoc={watchRefDoc}
              generateDocumentId={generateDocumentId}
              resetFieldBarcode={resetFieldBarcode}
              isInventoryPage
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <Box mt={2}>
              {!id && (
                <Grid container spacing={2}>
                  <Grid item xs={!isSmallScreen ? 3 : 12}>
                    <Controller
                      control={barcodeControl}
                      name="source_bin_location_document_id"
                      render={({ field }) => (
                        <CustomizedComboBox
                          {...field}
                          options={optionsLocation}
                          label={t("inventory.chooseLocation")}
                          onChange={(_, newValue) =>
                            field.onChange(newValue ? newValue.value : "")
                          }
                          disabled={isLoading.item}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={!isSmallScreen ? 3 : 12}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Controller
                        control={barcodeControl}
                        name="barcode"
                        render={({ field }) => (
                          <CustomizedTextField
                            {...field}
                            label={t("inventory.scanBarcode")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && e.shiftKey === false) {
                                const data = e.target.value;
                                handleBarcodeSubmit(
                                  onBarcodeSubmitHandler(data)
                                );
                              }
                            }}
                            disabled={isLoading.item || watchBinLocation === ""}
                          />
                        )}
                      />
                      {isSmallScreen ? (
                        <IconButton
                          disabled={isLoading.item || watchBinLocation === ""}
                          onClick={() => setShowCamera(!showCamera)}
                        >
                          <QrCodeScannerIcon />
                        </IconButton>
                      ) : null}
                    </Box>
                    {showError.value && (
                      <Box
                        sx={(theme) => ({
                          backgroundColor:
                            showError.type === "success"
                              ? theme.palette.success.light
                              : theme.palette.error.light,
                          width: "maxContent",
                          height: 47,
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        })}
                        mt={1}
                        display="flex"
                        justifyContent="center"
                        textAlign="center"
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color:
                              showError.type === "success"
                                ? "#1E4620"
                                : "#621B16",
                          }}
                        >
                          {showError.text}
                        </Typography>
                      </Box>
                    )}
                    {isSmallScreen ? (
                      <Box display={showCamera ? "block" : "none"} marginY={2}>
                        {showCamera && (
                          <QrReader
                            constraints={{ facingMode: "environment" }}
                            onResult={(result, error) => {
                              if (!!result) {
                                onBarcodeSubmitHandler(result.text);
                              }
                              if (!!error) {
                                // console.log("error", error);
                                // console.info(error);
                              }
                            }}
                            scanDelay={2000}
                            style={{
                              width: "100%",
                              height: "100%",
                              contentVisibility: "auto",
                            }}
                          />
                        )}
                      </Box>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box margin={showCamera ? "0 0 2rem 0" : "2rem 0"}>
              <GoodsReceiveReturnTable
                control={control}
                fields={fields}
                append={append}
                remove={remove}
                disabled={id}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                totalError={totalError}
                setTotalError={setTotalError}
                watchWarehouse={watchWarehouse}
                isSmallScreen
              />
            </Box>
            {!id && (
              <Box display="flex">
                <CustomizedButton
                  id="updateWithApprove"
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  title="รับคืน"
                  disabled={isLoading.item}
                  // type="submit"
                  fullWidth
                  size="medium"
                />
              </Box>
            )}
          </TabPanel>
        </>
      )}
    </>
  );
};

export default ReturnContainer;
