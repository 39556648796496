import { useState, useEffect } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@mui/material";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";
import NewAddress from "./NewAddress";
import addressData from "../../../../data/addressWithZone.json";

const defaultNewAddressValues = {
  address_type: "",
  default_address: false,
  same_as_default_address: false,
  address_contact_name: "",
  address_contact_phone: "",
  address: "",
  sub_district: "",
  district: "",
  province: "",
  postal_code: "",
  country: "",
};

const AddressTab = ({
  control,
  errors,
  setValue,
  getValues,
  watch,
  viewOnly,
}) => {
  const { t } = useTranslation();
  const [subdistrictOptions, setSubdistrictOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [searchSubdistrict, setSearchSubdistrict] = useState();
  const [searchDistrict, setSearchDistrict] = useState();
  const [searchProvince, setSearchProvince] = useState();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "address_list",
  });

  const watchFieldArray = useWatch({ control, name: "address_list" });
  const isSameAddress = watchFieldArray?.map((field) => {
    return field.same_as_default_address;
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const renderNewAddress = controlledFields
    .slice(1)
    .map((item, index) => (
      <NewAddress
        key={item.id}
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        remove={remove}
        viewOnly={viewOnly}
        setSubdistrict={setSearchSubdistrict}
        setDistrict={setSearchDistrict}
        setProvince={setSearchProvince}
        setSubdistrictOptions={setSubdistrictOptions}
        subdistrictOptions={subdistrictOptions}
        districtOptions={districtOptions}
        provinceOptions={provinceOptions}
        itemId={item.id}
        index={index + 1}
        isSameAddress={isSameAddress}
      />
    ));

  useEffect(() => {
    let filteredAddressData = addressData;
    if (searchSubdistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.sub_district.includes(searchSubdistrict)
      );
    }
    if (searchDistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.district.includes(searchDistrict)
      );
    }
    if (searchProvince) {
      filteredAddressData = addressData.filter((item) =>
        item.province.includes(searchProvince)
      );
    }
    let filteredProvinces = [];
    filteredAddressData.forEach((item) => {
      if (!filteredProvinces.includes(item.province)) {
        filteredProvinces = [...filteredProvinces, item.province];
      }
    });
    setProvinceOptions(filteredProvinces);

    let filteredDistricts = [];
    filteredAddressData.forEach((item) => {
      if (!filteredDistricts.includes(item.district)) {
        filteredDistricts = [...filteredDistricts, item.district];
      }
    });
    setDistrictOptions(filteredDistricts);

    let filteredSubDistricts = [];
    filteredAddressData.forEach((item) => {
      if (!filteredSubDistricts.includes(item.sub_district)) {
        filteredSubDistricts = [...filteredSubDistricts, item.sub_district];
      }
    });
    setSubdistrictOptions(filteredSubDistricts);
  }, [searchDistrict, searchSubdistrict, searchProvince]);

  const countryOptions = ["ไทย"];

  const addNewAddressHandler = () => {
    setSearchSubdistrict();
    setSearchDistrict();
    setSearchProvince();
    append({ ...defaultNewAddressValues });
  };

  const updateAdditionalAddressHandler = (name, newValue) => {
    let additionalAdresses = getValues("address_list").slice(1);
    additionalAdresses.forEach((item, index) => {
      if (item.same_as_default_address && item[name] !== newValue) {
        const updatedItem = { ...item, [name]: newValue };
        setValue(`address_list.${index + 1}`, updatedItem);
      }
    });
  };

  return (
    <>
      {!viewOnly && (
        <Box sx={{ mb: 2 }}>
          <CustomizedButton
            title={"เพิ่มที่อยู่"}
            variant="outlined"
            size="medium"
            onClick={addNewAddressHandler}
            disabled={viewOnly}
          />
        </Box>
      )}
      <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
        ที่อยู่จดทะเบียน
      </Typography>
      {controlledFields.slice(0, 1).map((item) => (
        <Grid container spacing={2} key={item.id}>
          <Grid item xs={12}>
            <Controller
              key={`${item.id}-address_main`}
              name={"address_list.0.address"}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.address_list?.["0"]?.address)}
                  helperText={errors.address_list?.["0"]?.address?.message}
                  label={t("contact.address.index")}
                  {...field}
                  onBlur={(e) => {
                    updateAdditionalAddressHandler("address", e.target.value);
                  }}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-subdistrict`}
              name={"address_list.0.sub_district"}
              control={control}
              render={({ field }) => (
                <CustomizedCreatable
                  {...field}
                  options={subdistrictOptions}
                  title={t("contact.address.sub_district")}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    } else {
                      setSearchSubdistrict();
                      setSearchDistrict();
                      setSearchProvince();
                      return;
                    }
                  }}
                  onChange={(e, option) => {
                    if (!option) {
                      updateAdditionalAddressHandler("sub_district", "");
                      return field.onChange("");
                    }
                    setSearchSubdistrict(option);
                    const filter = addressData.filter((adddress) => {
                      return `${adddress.sub_district}` === `${option}`;
                    });
                    if (filter && filter.length !== 0) {
                      const fieldsValue = {
                        ...getValues("address_list")[0],
                        district: filter[0].district,
                        province: filter[0].province,
                        postal_code: filter[0].postal_code,
                        country: "ไทย",
                      };
                      setValue("address_list.0", fieldsValue);
                      updateAdditionalAddressHandler(
                        "district",
                        filter[0].district
                      );
                      updateAdditionalAddressHandler(
                        "province",
                        filter[0].province
                      );
                      updateAdditionalAddressHandler(
                        "postal_code",
                        filter[0].postal_code
                      );
                      updateAdditionalAddressHandler("country", "ไทย");
                    } else if (option.inputValue) {
                      updateAdditionalAddressHandler(
                        "sub_district",
                        option.inputValue
                      );
                      return field.onChange(option.inputValue);
                    }
                    updateAdditionalAddressHandler("sub_district", option);
                    return field.onChange(option);
                  }}
                  readOnly={viewOnly}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-district`}
              name={"address_list.0.district"}
              control={control}
              render={({ field }) => (
                <CustomizedCreatable
                  {...field}
                  options={districtOptions}
                  title={t("contact.address.district")}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    } else {
                      setSearchSubdistrict();
                      setSearchDistrict();
                      setSearchProvince();
                      return;
                    }
                  }}
                  onChange={(e, option) => {
                    if (!option) {
                      updateAdditionalAddressHandler("district", "");
                      return field.onChange("");
                    }
                    const filter = addressData.filter((adddress) => {
                      return `${adddress.district}` === `${option}`;
                    });
                    if (filter && filter.length !== 0) {
                      const filteredSubdistricts = filter.map(
                        (address) => address.sub_district
                      );
                      setSubdistrictOptions(filteredSubdistricts);
                      setValue("address_list.0.province", filter[0].province);
                      setValue(
                        "address_list.0.postal_code",
                        filter[0].postal_code
                      );
                      setValue("address_list.0.country", filter[0].country);
                      updateAdditionalAddressHandler(
                        "province",
                        filter[0].province
                      );
                      updateAdditionalAddressHandler("country", "ไทย");
                    } else if (option.inputValue) {
                      updateAdditionalAddressHandler(
                        "district",
                        option.inputValue
                      );
                      return field.onChange(option.inputValue);
                    }
                    updateAdditionalAddressHandler("district", option);
                    return field.onChange(option);
                  }}
                  readOnly={viewOnly}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-province`}
              name={"address_list.0.province"}
              control={control}
              render={({ field }) => (
                <CustomizedCreatable
                  {...field}
                  options={provinceOptions}
                  title={t("contact.address.province")}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    } else {
                      setSearchSubdistrict();
                      setSearchDistrict();
                      setSearchProvince();
                      return;
                    }
                  }}
                  onChange={(e, option) => {
                    if (!option) {
                      updateAdditionalAddressHandler("province", "");
                      return field.onChange("");
                    }
                    setSearchProvince(option);
                    if (option.inputValue) {
                      updateAdditionalAddressHandler(
                        "province",
                        option.inputValue
                      );
                      return field.onChange(option.inputValue);
                    }
                    updateAdditionalAddressHandler("province", option);
                    setValue("address_list.0.country", "ไทย");
                    updateAdditionalAddressHandler("country", "ไทย");
                    return field.onChange(option);
                  }}
                  readOnly={viewOnly}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-postal_code`}
              name={"address_list.0.postal_code"}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.address?.postal_code)}
                  helperText={errors.address?.postal_code?.message}
                  label={t("contact.address.postal_code")}
                  {...field}
                  onBlur={(e) => {
                    updateAdditionalAddressHandler(
                      "postal_code",
                      e.target.value
                    );
                  }}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-country`}
              name="address_list.0.country"
              control={control}
              render={({ field }) => (
                <CustomizedCreatable
                  {...field}
                  options={countryOptions}
                  title={t("contact.address.country")}
                  onChange={(e, option) => {
                    if (!option) {
                      updateAdditionalAddressHandler("country", "");
                      return field.onChange("");
                    }
                    if (option.value) {
                      updateAdditionalAddressHandler("country", option.value);
                      return field.onChange(option.value);
                    }
                    if (option.inputValue) {
                      updateAdditionalAddressHandler(
                        "country",
                        option.inputValue
                      );
                      return field.onChange(option.inputValue);
                    }
                    updateAdditionalAddressHandler("country", option);
                    return field.onChange(option);
                  }}
                  readOnly={viewOnly}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
        </Grid>
      ))}

      {renderNewAddress}
    </>
  );
};

export default AddressTab;
