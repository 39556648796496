import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./account-initial";

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllUsers(state, action) {
      state.allUsers = action.payload;
      state.isLoading.allUsers = false;
      state.isLoading.allUsersModal = false;
    },
    appendedNewUser(state, action) {
      state.allUsers = [...state.allUsers, action.payload];
      state.isLoading.allUsers = false;
    },
    loadedUser(state, action) {
      state.user = action.payload;
      state.isLoading.user = false;
    },
    resetUser(state) {
      state.user = initialState.user;
      state.isLoading.user = false;
    },
    updatedUser(state, action) {
      const updatedUser = action.payload;
      const updatedIndex = state.allUsers.findIndex(
        (user) => user.document_id === updatedUser.document_id
      );
      state.allUsers[updatedIndex] = updatedUser;
      state.isLoading.allUsers = false;
    },
    disabledUser(state, action) {
      state.allUsers = state.allUsers.filter(
        (user) => user.document_id !== action.payload.document_id
      );
      state.isLoading.allUsers = false;
    },
  },
});

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;
