import { Typography, Grid } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllRemarkTemplate } from "../../../../features/Setting/RemarkTemplate/remark-template-actions";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import CustomizedTextField from "../../../Custom/CustomizedTextField";

const ManufactureOrderRemarkForm = ({
  control,
  errors,
  setValue,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allRemarkTemplate } = useSelector((state) => state.remarkTemplate);

  const fetchRemark = useCallback(() => {
    dispatch(getAllRemarkTemplate({}, "manufacture_order"));
  }, [dispatch]);

  useEffect(() => {
    fetchRemark();
  }, [fetchRemark]);

  const mapTemplateRemarkIdToRemark = useCallback(
    (id) => {
      if (id) {
        const findById = allRemarkTemplate.remarkTemplate.find(
          (template) => template.id === id
        );
        if (findById) setValue("remark", findById.remark_text);
      } else {
        setValue("remark", "");
      }
    },
    [allRemarkTemplate.remarkTemplate, setValue]
  );

  const mapTemplateToValue = useCallback(
    (id) => {
      if (id) {
        const findById = allRemarkTemplate.remarkTemplate.find(
          (template) => template.id === parseInt(id)
        );
        if (findById) {
          return findById.name;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    [allRemarkTemplate.remarkTemplate]
  );

  return (
    <CustomizedBox>
      <Typography fontWeight="bold" mb={2}>
        {t("manufacture.bom.internal_remark")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item container rowSpacing={2} xs={12} md={5} direction="column">
          <Grid item>
            {!disabled && (
              <Controller
                name="template_remark_id"
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label="หมายเหตุ"
                    options={allRemarkTemplate.remarkTemplate.map(
                      (template) => {
                        return {
                          id: template.id,
                          label: template.name,
                          value: template.id,
                        };
                      }
                    )}
                    value={mapTemplateToValue(field.value)}
                    onChange={(e, value) => {
                      if (value) {
                        mapTemplateRemarkIdToRemark(e.target.value);
                        field.onChange(value.value);
                      } else {
                        mapTemplateRemarkIdToRemark(e.target.value);
                        field.onChange(null);
                      }
                    }}
                    sx={{ width: "70%" }}
                    disabled={disabled}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item>
            <Controller
              name="remark"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  multiline
                  rows={4}
                  {...field}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ManufactureOrderRemarkForm;
