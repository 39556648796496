import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AgGrid from "../AgGrid";
import { deliveryOrderColumnDefs } from "../ColumnDefs/deliveryOrderColumnDefs";

const DeliveryOrderTable = ({
  gridRef,
  onGridReady,
  data,
  node,
  api,
  isDetail,
  isClient,
  isReport,
  rowData,
  referenceDeleteHandler,
  viewOnly,
  isDeliveryTrip,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onRowDoubleClicked = (params) => {
    if (isReport) {
      return;
    }
    const { document_id } = params.data;
    navigate(`/logistic/delivery-order/${encodeURIComponent(document_id)}`);
  };

  const onGridDetailReady = (params) => {
    const gridInfo = {
      id: node.id,
      api: params.api,
      columnApi: params.columnApi,
    };
    api.addDetailGridInfo(node.id, gridInfo);
  };

  return (
    <AgGrid
      autoHeight={!isReport && (isDetail || isClient)}
      ref={gridRef}
      columnDefs={deliveryOrderColumnDefs(
        t,
        referenceDeleteHandler,
        isClient,
        viewOnly,
        isDeliveryTrip,
        isReport
      )}
      height={!isDetail && !isClient && 680}
      onGridReady={isDetail ? onGridDetailReady : !isClient && onGridReady}
      masterDetail={isDetail}
      rowData={rowData}
      disabledSidebar={isDetail || isClient}
      isClientSide={isDetail || isClient}
      disableFloatingFilter={isDeliveryTrip}
      onRowDoubleClicked={onRowDoubleClicked}
      path={`/logistic/delivery-order`}
    />
  );
};

export default DeliveryOrderTable;
