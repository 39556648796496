import {
  //   Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { unixToDateWithFormat } from "../../../utils/date-converter";

const DeliveryShortPdf = ({ deliveryList }) => {
  const header = [
    {
      thaiLabel: "เลขที่เอกสาร",
      width: 100,
    },
    {
      thaiLabel: "ลูกค้า",
      width: 100,
    },
    {
      thaiLabel: "รหัสลูกค้า",
      width: 90,
    },
    {
      thaiLabel: "วันที่ส่งของ",
      width: 90,
    },
    {
      thaiLabel: "ที่อยู่",
      width: 110,
    },
    {
      thaiLabel: "หมายเหตุ",
      width: 130,
    },
  ];

  const renderAddressString = (addressValues) => {
    let result = "";

    if (Array.isArray(addressValues)) {
      addressValues.forEach((item) => {
        if (item.length > 0) {
          result = result + item + ", ";
        }
      });
    } else {
      const keys = Object.keys(addressValues);
      keys.forEach((key) => {
        if (addressValues[key].length > 0) {
          if (key !== "address_type") {
            result = result + addressValues[key] + ", ";
          }
        }
      });
    }
    if (result.trim().length === 0) {
      return "-";
    }
    // remove whitespace and last comma
    return result.trim().slice(0, -1);
  };

  return (
    <TableContainer>
      <Table sx={{ overflow: "scroll" }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#e8f1e8" }}>
          <TableRow>
            {header.map((header, index) => (
              <TableCell
                align="center"
                key={index}
                sx={{
                  px: 0.25,
                  py: 1,
                }}
              >
                <Typography width={header.width} fontSize={12} fontWeight={600}>
                  {header.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {deliveryList.map((delivery) => (
            <TableRow key={delivery.document_id} sx={{ breakInside: "avoid" }}>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{delivery.document_id}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  py: 1,
                  px: 0.25,
                }}
                align="center"
              >
                <Typography fontSize={12}>{delivery.contact}</Typography>
                {/* <Divider sx={{ my: 1 }} /> */}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{delivery.contact_document_id}</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {unixToDateWithFormat(delivery.delivery_date)}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {renderAddressString(
                    delivery?.customer?.delivery_address || {}
                  )}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{delivery.remark || "-"}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeliveryShortPdf;
