import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AgGrid from "../AgGrid";
import {
  formatNumber,
  formatPriceNumber,
} from "../../../utils/dataTransformer";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { unixToDateWithFormat } from "../../../utils/date-converter";

const PurchaseReturnTable = ({ gridRef, onGridReady, rowData, isReport }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "document_id",
      headerName: t("sales.documentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 250,
    },
    {
      field: "created_date",
      headerName: t("sales.createdDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.created_date),
      hide: true,
      sort: "desc",
      suppressColumnsToolPanel: true,
    },
    {
      field: "issue_date",
      headerName: t("sales.issueDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.data.issue_date),
    },
    {
      field: "delivery_date",
      headerName: t("purchase.return.deliveryDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.delivery_date),
    },
    {
      field: "contact_document_id",
      headerName: t("purchase.order.vendorDocumentId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      cellRenderer: (params) => params.data.contact_document_id,
      hide: true,
    },
    {
      field: "contact_name",
      headerName: t("purchase.order.vendorName"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      cellRenderer: (params) => params.data.contact.contact_name_1,
    },
    {
      field: "total_amount",
      headerName: t("purchase.request.totalNetAmount"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      valueFormatter: (params) => formatNumber(params.data.total_amount),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "render_status",
      headerName: t("sales.status"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: [
          "draft",
          "PENDING",
          "DECLINED",
          "approved",
          "finished",
          "cancelled",
        ],
      },
      cellRenderer: (params) => (
        <CustomizedStatus status={params.data.render_status} />
      ),
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
    {
      field: "created_by",
      headerName: t("sales.createdBy"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => (
        <CustomizedAvatar avatars={[params.data.created_by]} />
      ),
      valueGetter: (params) =>
        params.data.created_by.first_name +
        " " +
        params.data.created_by.last_name,
      sortable: false,
      width: 150,
    },
    {
      field: "employee_list",
      headerName: t("sales.employeeList"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => (
        <CustomizedAvatar avatars={params.data.employee_list} />
      ),
      sortable: false,
    },
  ]);

  useEffect(() => {
    if (isReport) {
      setColumnDefs([
        {
          field: "document_id",
          headerName: t("reports.document_id"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          width: 250,
        },
        {
          field: "issue_date",
          headerName: t("reports.issue_date"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
          width: 200,
        },
        {
          field: "delivery_date",
          headerName: t("reports.delivery_date"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
          width: 200,
        },
        {
          field: "render_status",
          headerName: t("sales.status"),
          sortable: false,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "ร่าง",
              "รออนุมัติ",
              "ไม่อนุมัติ",
              "อนุมัติแล้ว",
              "เสร็จสิ้น",
              "ยกเลิก",
            ],
          },
          cellRenderer: (params) => {
            return <CustomizedStatus status={params.data.render_status} />;
          },
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
        },
        {
          field: "contact_document_id",
          headerName: t("reports.vendor_document_id"),
          hide: true,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "contact_name",
          headerName: t("reports.vendor_name"),
          sortable: false,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
            suppressAndOrCondition: true,
          },
          width: 225,
        },
        {
          field: "price_vat_type",
          headerName: t("reports.price_vat_type"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          hide: true,
        },
        {
          field: "item_document_id",
          headerName: t("reports.item_document_id"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_name",
          headerName: t("reports.item_name"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "qty",
          headerName: t("reports.po_qty"),
          filter: false,
          valueGetter: (params) => formatNumber(params.data.qty),
        },
        {
          field: "uom",
          headerName: t("reports.uom"),
          filter: false,
        },
        {
          field: "qty_return",
          headerName: t("reports.qty_return"),
          filter: false,
          valueGetter: (params) => formatNumber(params.data.qty_return),
        },
        {
          field: "price_per_unit",
          headerName: t("reports.price_per_unit"),
          filter: false,
          valueGetter: (params) =>
            formatPriceNumber(params.data.price_per_unit),
          cellStyle: {
            display: "flex",
            justifyContent: "flex-end",
          },
        },
        {
          field: "vat_type",
          headerName: t("reports.vat_type"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "pre_vat_amount_exclude_discount",
          headerName: t("reports.pre_vat_amount_exclude_discount"),
          filter: false,
          valueGetter: (params) =>
            formatNumber(params.data.pre_vat_amount_exclude_discount),
          cellStyle: {
            display: "flex",
            justifyContent: "flex-end",
          },
        },
        {
          field: "item_discount",
          headerName: t("reports.item_discount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) => formatPriceNumber(params.data.item_discount),
        },
        {
          field: "item_additional_discount",
          headerName: t("reports.item_additional_discount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) =>
            formatNumber(params.data.item_additional_discount),
        },
        {
          field: "pre_vat_amount_include_discount",
          headerName: t("reports.pre_vat_amount_include_discount"),
          filter: false,
          valueGetter: (params) =>
            formatNumber(params.data.pre_vat_amount_include_discount),
          cellStyle: {
            display: "flex",
            justifyContent: "flex-end",
          },
        },
        {
          field: "item_vat_exempted_amount",
          headerName: t("reports.item_vat_exempted_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) =>
            formatNumber(params.data.item_vat_exempted_amount),
        },
        {
          field: "item_vat_0_amount",
          headerName: t("reports.item_vat_0_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) => formatNumber(params.data.item_vat_0_amount),
        },
        {
          field: "item_vat_7_amount",
          headerName: t("reports.item_vat_7_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) => formatNumber(params.data.item_vat_7_amount),
        },
        {
          field: "item_vat_amount",
          headerName: t("reports.item_vat_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) => formatNumber(params.data.item_vat_amount),
        },
        {
          field: "item_net_amount",
          headerName: t("reports.item_net_amount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) => formatNumber(params.data.item_net_amount),
        },
        {
          field: "item_withholding_tax_type",
          headerName: t("reports.item_withholding_tax_type"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "item_withholding_tax",
          headerName: t("reports.item_withholding_tax"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) =>
            formatNumber(params.data.item_withholding_tax),
        },
        {
          field: "item_total_amount",
          headerName: t("reports.item_total_amount"),
          filter: false,
          valueGetter: (params) => formatNumber(params.data.item_total_amount),
          cellStyle: {
            display: "flex",
            justifyContent: "flex-end",
          },
        },
        {
          field: "total_amount",
          headerName: t("reports.total_amount"),
          filter: false,
          valueGetter: (params) => formatNumber(params.data.total_amount),
          cellStyle: {
            display: "flex",
            justifyContent: "flex-end",
          },
        },
        {
          field: "additional_discount",
          headerName: t("reports.additional_discount"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) =>
            formatNumber(params.data.additional_discount),
        },
        {
          field: "credit_day",
          headerName: t("reports.credit_day"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "remark",
          headerName: t("reports.remark"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "qty_uom",
          headerName: t("reports.qty_uom"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
          valueGetter: (params) => formatNumber(params.data.qty_uom),
        },
        {
          field: "reference_document_list",
          headerName: t("reports.reference_document_id"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "gr_document_id_list",
          headerName: t("reports.receive_ref_document_id_list"),
          filter: "agSetColumnFilter",
        },
        {
          field: "external_ref_id",
          headerName: t("reports.external_ref_id"),
          hide: true,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "reason_to_return",
          headerName: t("reports.reason_to_return"),
          hide: true,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_description",
          headerName: t("reports.item_description"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "created_date",
          headerName: t("reports.created_date"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
          hide: true,
          width: 200,
        },
        {
          field: "updated_date",
          headerName: t("reports.updated_date"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },

        {
          field: "created_by",
          headerName: t("reports.created_by"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "employee_list",
          headerName: t("reports.employee_list"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "email",
          headerName: t("reports.email"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "phone",
          headerName: t("reports.phone"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "fax",
          headerName: t("reports.fax"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "billing_address",
          headerName: t("reports.billing_address"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "billing_sub_district",
          headerName: t("reports.billing_sub_district"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "billing_district",
          headerName: t("reports.billing_district"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "billing_province",
          headerName: t("reports.billing_province"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "billing_postal_code",
          headerName: t("reports.billing_postal_code"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "billing_country",
          headerName: t("reports.billing_country"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "is_same_as_default_address",
          headerName: t("reports.is_same_as_default_address"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "delivery_address_contact_name",
          headerName: t("reports.delivery_address_contact_name"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "delivery_address_contact_phone",
          headerName: t("reports.delivery_address_contact_phone"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "delivery_address",
          headerName: t("reports.delivery_address"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "delivery_sub_district",
          headerName: t("reports.delivery_sub_district"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "delivery_district",
          headerName: t("reports.delivery_district"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "delivery_province",
          headerName: t("reports.delivery_province"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "delivery_postal_code",
          headerName: t("reports.delivery_postal_code"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "delivery_country",
          headerName: t("reports.delivery_country"),
          filter: false,
          hide: true,
          suppressColumnsToolPanel: true,
        },
      ]);
    }
  }, [isReport, t]);

  const onRowDoubleClicked = (params) => {
    navigate(`/purchase/return/${encodeURIComponent(params.data.document_id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      onGridReady={onGridReady}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
      path={`/purchase/return`}
    />
  );
};

export default PurchaseReturnTable;
