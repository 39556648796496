import moment from "moment";
import * as Yup from "yup";

export const workOrderInitialState = {
  isLoading: {
    allWorkOrders: false,
    allWorkOrdersExport: false,
    workOrder: false,
  },
  allWorkOrders: [],
  allWorkOrdersExport: [],
  allWorkOrdersCalendar: [],
  workOrder: {
    created_date: moment().startOf("day").unix(),
    start_date_time: moment().startOf("day").unix(),
    duration: 0,
    duration_unit: "minute",
    name: "",
    description: "",
    machine: "",
    plant: "",
    group: [],
  },
};

export const workOrderValidation = Yup.object().shape({
  name: Yup.string().required("กรุณากรอก"),
  duration_unit: Yup.string().required("กรุณากรอก"),
});
