import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import CustomizedTextField from "./CustomizedTextField";

const CustomizedNumberTextField = ({
  disabled,
  control,
  label,
  name,
  error,
  sx,
  onChange,
  helperText,
  pricePerUom,
  onKeyDown,
  textAlign,
  InputLabelProps,
  decimalScale,
  helperTextAlign,
  allowNegative,
}) => {
  const [warn, setWarn] = useState(false);
  const showWarning = () => setWarn(true);
  const hideWarning = () => setWarn(false);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { onChange: fieldOnChange, otherField } = field;
        return (
          <NumericFormat
            {...otherField}
            label={label}
            value={field.value}
            allowNegative={allowNegative ? allowNegative : false}
            thousandSeparator=","
            decimalScale={decimalScale ? decimalScale : 2}
            // onBlur={(e) => {
            //   const value = e.target.value.replace(",", "");
            //   const trailingValue = parseFloat(Number(value)).toLocaleString(
            //     undefined,
            //     {
            //       minimumFractionDigits: decimalScale || 2,
            //       maximumFractionDigits: decimalScale || 2,
            //     }
            //   );

            //   fieldOnChange(trailingValue);
            // }}
            onValueChange={(v) => {
              fieldOnChange(v.floatValue || "");
              onChange && onChange(v.floatValue || 0);
              if (pricePerUom) {
                const value = v.floatValue || 0;
                if (pricePerUom > value) {
                  showWarning();
                } else {
                  hideWarning();
                }
              }
            }}
            disabled={disabled}
            customInput={CustomizedTextField}
            helperText={helperText}
            error={error || warn}
            inputProps={{
              style: { textAlign: textAlign ? textAlign : "right" },
            }}
            InputLabelProps={InputLabelProps}
            FormHelperTextProps={{
              style: {
                fontSize: "10px",
                textAlign: helperTextAlign ? "right" : "left",
              },
            }}
            onKeyDown={onKeyDown}
            sx={sx}
            fixedDecimalScale
            // fixedDecimalScale={!isOnChange}
          />
        );
      }}
    />
  );
};

export default CustomizedNumberTextField;
