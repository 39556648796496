import {
  //   Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatNumber } from "../../../utils/dataTransformer";

const BomPdf = ({ itemList, isBom, qty }) => {
  const [header, setHeader] = useState([]);

  useEffect(() => {
    if (isBom) {
      setHeader([
        {
          thaiLabel: "รายการ",
          width: 70,
        },
        {
          thaiLabel: "วัตถุดิบ",
          width: 275,
        },
        {
          thaiLabel: "จำนวน",
          width: 200,
        },
        {
          thaiLabel: "หน่วย",
          width: 150,
        },
      ]);
    } else {
      setHeader([
        {
          thaiLabel: "รายการ",
          width: 70,
        },
        {
          thaiLabel: "วัตถุดิบ",
          width: 200,
        },
        {
          thaiLabel: "จำนวนที่ต้องใช้",
          width: 130,
        },
        {
          thaiLabel: "จำนวนที่นำไปใช้",
          width: 130,
        },
        {
          thaiLabel: "หน่วย",
          width: 150,
        },
      ]);
    }
  }, [isBom]);

  return (
    <TableContainer>
      <Table sx={{ overflow: "scroll" }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#e8f1e8" }}>
          <TableRow>
            {header.map((header, index) => (
              <TableCell
                align="center"
                key={index}
                sx={{
                  px: 0.25,
                  py: 1,
                }}
                width={header.width}
              >
                <Typography fontSize={12} fontWeight={600}>
                  {header.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {itemList.map((item, index) => (
            <TableRow key={item.id} sx={{ breakInside: "avoid" }}>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{index + 1}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12} fontWeight={600}>
                  {item.item_name}
                </Typography>
                <Typography fontSize={12} whiteSpace="pre-line">
                  {item.item_description}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{formatNumber(item.qty)}</Typography>
                {qty && qty.length > 0 && (
                  <Typography color="rgba(0, 0, 0, 0.38)" fontSize={6}>
                    {formatNumber(qty[index])}
                  </Typography>
                )}
              </TableCell>
              {!isBom && (
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>
                    {formatNumber(item.goods_issue_qty ?? 0)}
                  </Typography>
                </TableCell>
              )}
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{item.uom}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BomPdf;
