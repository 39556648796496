import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { useApproval } from "../../../hooks/use-approval";
import moment from "moment";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schema as contactSchema,
  validation as contactValidation,
} from "../../../components/Form/Contact/schema";
import {
  schema as customerDescSchema,
  validation as customerDescValidation,
} from "../../../components/Form/Sales/CustomerDescription/schema";
import SalesHeaderForm from "../../../components/Form/Sales/Header/index";
import {
  quotationSchema,
  quotationValidation,
} from "../../../components/Form/Sales/Header/schema";
import OrderTab from "./OrderTab";
import {
  approveQuotation,
  createNewQuotation,
  declineQuotation,
  getQuotationById,
  updateQuotation,
} from "../../../features/Sales/Quotation/quotation-actions";
import {
  salesPayload,
  setSalesValueFormatter,
} from "../../../utils/salesPayloadFormatter";
import { quotationActions } from "../../../features/Sales/Quotation/quotation-slice";
import {
  salesPrimaryButtonHandler,
  salesSecondaryButtonHandler,
} from "../../../utils/salesButtonHandler";
import DirtyConfirmation from "../../../components/UI/Confirmation/DirtyConfirmation";
import { uploadFileToS3 } from "../../../utils/s3";
// import useUnload from "../../../hooks/use-unload";
import { validationItemList } from "../../../components/Form/Sales/AddItemListForm/schema";
import ItemPriceConfirmation from "../../../components/UI/Confirmation/ItemPriceConfirmation";
import {
  acceptProofSchema,
  acceptProofValidation,
} from "../../../components/Form/Sales/Proof/schema";
// import { useUnload } from "../../../hooks/use-unload";

const defaultValues = {
  render_status: null,
  target_status: null,
  price_vat_type: "excluded_vat",
  creator_document_id: "",
  created_by: {},
  contact_document_id: "",
  item_list: [],
  shipping_cost: 0,
  additional_discount: 0,
  additional_discount_type: "baht",
  summary: {},
  attachment_list: [],
  template_remark_id: "",
  remark: "",
  is_late: false,
};

const QuotationContainer = () => {
  const [openAcceptProof, setOpenAcceptProof] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [primaryButtonTitle, setPrimaryButtonTitle] = useState("ส่ง");
  const [secondaryButtonTitle, setSecondaryButtonTitle] =
    useState("บันทึกร่าง");
  const navigate = useNavigate();
  const { id } = useParams();
  const { contactSnapshot } = useSelector((state) => state.contact);
  const { createPermission, editPermission } = usePermission();
  const {
    quotation,
    isHaveApproval,
    isHaveApprovalPermission,
    approvalList,
    isLoading,
  } = useSelector((state) => state.quotation);

  const { user } = useAuth();
  const { refreshApprovals } = useApproval();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const headerRef = useRef();
  const customerDescRef = useRef();
  const itemListRef = useRef();
  const {
    control,
    setValue,
    getValues,
    trigger: validateItemList,
    getFieldState,
    formState: {
      errors,
      // isDirty: mainFormIsdirty
    },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationItemList),
  });
  const dispatch = useDispatch();
  const [dirtyConfirmation, setDirtyConfirmation] = useState(false);
  const [openItemPriceConfirmation, setOpenItemPriceConfirmation] = useState({
    display: false,
    items: [],
    action: null,
    payload: null,
  });

  const watchStatus = useWatch({ control, name: "render_status" });
  const targetStatus = useWatch({ control, name: "target_status" });

  const customerDescUseForm = useForm({
    defaultValues: { ...customerDescSchema },
    resolver: yupResolver(customerDescValidation),
  });

  const {
    trigger: validateCustomerDescription,
    setValue: setCustomerDescValue,
    getValues: getCustomerDescValues,
    getFieldState: getCustomerDescFieldState,
  } = customerDescUseForm;

  const contactUseForm = useForm({
    defaultValues: { ...contactSchema },
    resolver: yupResolver(contactValidation),
  });

  const acceptProofUseForm = useForm({
    defaultValues: { ...acceptProofSchema },
    resolver: yupResolver(acceptProofValidation),
  });
  const {
    trigger: validateAcceptProof,
    getValues: getAcceptProofValues,
    setValue: setAcceptProofValue,
    getFieldState: getAcceptProofFieldState,
    // isDirty: acceptProofIsDirty
  } = acceptProofUseForm;

  const {
    control: headerControl,
    setValue: setHeaderValue,
    getValues: getHeaderValues,
    trigger: validateHeader,
    formState: {
      errors: headerErrors,
      // isDirty: headerIsDirty
    },
    getFieldState: getHeaderFieldState,
  } = useForm({
    defaultValues: { ...quotationSchema },
    resolver: yupResolver(quotationValidation),
  });

  const status = watchStatus === "expired" ? targetStatus : watchStatus;

  useEffect(() => {
    if (state) {
      dispatch(quotationActions.loadedQuotation(state));
    } else if (id) {
      dispatch(
        getQuotationById(
          {
            document_id: id,
          },
          user,
          enqueueSnackbar
        )
      );
    }
    return () => {
      dispatch(quotationActions.resetQuotation());
    };
  }, [dispatch, enqueueSnackbar, id, state, user]);

  const closeConfirmationHandler = () => {
    setDirtyConfirmation(false);
  };

  const dirtyConfirmationAction = () => {
    navigate("/sales/quotation");
  };

  // useEffect(() => {

  //   return () => {
  //     if (
  //       mainFormIsdirty ||
  //       customerDescIsDirty ||
  //       acceptProofIsDirty ||
  //       headerIsDirty
  //     ) {
  //       setDirtyConfirmation(true);
  //     }
  //   };
  // }, [acceptProofIsDirty, customerDescIsDirty, headerIsDirty, mainFormIsdirty]);

  // useUnload((e) => {
  //   e.preventDefault();
  //   if (
  //     mainFormIsdirty ||
  //     customerDescIsDirty ||
  //     acceptProofIsDirty ||
  //     headerIsDirty
  //   ) {
  //     setDirtyConfirmation(true);
  //   }
  // });

  useEffect(() => {
    if (state) {
      setSalesValueFormatter(
        quotationSchema,
        customerDescSchema,
        defaultValues,
        acceptProofSchema,
        setHeaderValue,
        setCustomerDescValue,
        setValue,
        setAcceptProofValue,
        state,
        true,
        "quotation"
      );
    } else if (id) {
      setSalesValueFormatter(
        quotationSchema,
        customerDescSchema,
        defaultValues,
        acceptProofSchema,
        setHeaderValue,
        setCustomerDescValue,
        setValue,
        setAcceptProofValue,
        quotation,
        false,
        "quotation"
      );
    }
  }, [
    quotation,
    setValue,
    setHeaderValue,
    id,
    state,
    setCustomerDescValue,
    setAcceptProofValue,
  ]);

  const currentTab = pathname + (tab ? `?tab=order` : "");

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.quotation.index"),
      to: "/sales/quotation",
    },
    {
      name: id ?? t("sales.addNewQuotation"),
    },
  ];

  const tabs = [
    {
      label: t("sales.documentInfo"),
      path: `${pathname}`,
    },
    {
      label: t("sales.order.index"),
      path: `${pathname}?tab=order`,
    },
  ];

  useEffect(() => {
    switch (status) {
      case "waitApprove":
      case "notApproved":
      case "accepted":
      case "finished":
      case "cancelled":
      case "expired":
        setViewOnly(true);
        break;
      case "waitAccept":
        setAcceptProofValue("accepted_date", moment());
        setViewOnly(true);
        break;
      default:
        setViewOnly(false);
    }
  }, [setAcceptProofValue, status]);

  const submitContactForm = (data) => {
    setCustomerDescValue("contact", data);
  };

  const submitForm = async () => {
    const customerDescIsValid = await validateCustomerDescription();
    const headerIsValid = await validateHeader();
    const itemListIsValid = await validateItemList();

    const documentIdError = getHeaderFieldState("document_id").error;
    const issueDateError = getHeaderFieldState("issue_date").error;
    const dueDateError = getHeaderFieldState("due_date").error;
    const customerError = getCustomerDescFieldState("customer").error;
    const itemListError = getFieldState("item_list").error;

    const errorsCount =
      (documentIdError ? 1 : 0) +
      (issueDateError ? 1 : 0) +
      (dueDateError ? 1 : 0) +
      (customerError ? 1 : 0) +
      (itemListError ? 1 : 0);

    if (errorsCount > 1) {
      enqueueSnackbar("กรุณาตรวจสอบข้อมูลให้ครบถ้วน", {
        variant: "error",
      });
    }

    if (itemListError?.length > 0) {
      if (itemListError.some((item) => item.is_active)) {
        enqueueSnackbar("กรุณาระบุสินค้าใหม่ เนื่องจากมีสินค้าที่หยุดใช้งาน", {
          variant: "error",
        });
      }
      if (itemListError.some((item) => item.price_per_unit)) {
        enqueueSnackbar("กรุณากรอกจำนวนเงินที่มากกว่า 0", {
          variant: "error",
        });
      }
    } else if (itemListError?.message) {
      enqueueSnackbar(itemListError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(documentIdError)) {
      enqueueSnackbar(documentIdError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(issueDateError)) {
      enqueueSnackbar(issueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(dueDateError)) {
      enqueueSnackbar(dueDateError.message, {
        variant: "error",
      });
    }
    if (errorsCount === 1 && Boolean(customerError)) {
      enqueueSnackbar("กรุณาเลือกลูกค้า", {
        variant: "error",
      });
    }
    if (status && openAcceptProof) {
      const acceptedProofIsValid = await validateAcceptProof();
      const acceptedProofError =
        getAcceptProofFieldState("accepted_date").error;

      const currIssueDate = getHeaderValues("issue_date");
      const currAcceptedDate = getAcceptProofValues("accepted_date");

      if (Boolean(acceptedProofError)) {
        enqueueSnackbar(acceptedProofError.message, {
          variant: "error",
        });
      } else if (
        currAcceptedDate < new Date(moment(currIssueDate).startOf("day"))
      ) {
        enqueueSnackbar("กรุณาระบุวันที่ที่มากกว่าหรือเท่ากับวันที่ออกเอกสาร", {
          variant: "error",
        });
      }
      if (
        !acceptedProofIsValid ||
        currAcceptedDate < new Date(moment(currIssueDate).startOf("day"))
      ) {
        return;
      }
    }

    if (!headerIsValid) {
      headerRef.current.scrollIntoView();
      return;
    }
    if (!customerDescIsValid) {
      customerDescRef.current.scrollIntoView();
      return;
    }
    if (!itemListIsValid && itemListError) {
      itemListRef.current.scrollIntoView();
      return;
    }

    if (customerDescIsValid && headerIsValid && itemListIsValid) {
      const { status, summary, ...mainValues } = getValues();
      const headerValues = getHeaderValues();
      const { customer, identity_no, ...customerDescValues } =
        getCustomerDescValues();
      const acceptedProof = getAcceptProofValues();
      const { accepted_date, accepted_remark, attachment_list } = acceptedProof;

      if (attachment_list.length > 0) {
        for (const [index, value] of attachment_list.entries()) {
          if (value && typeof value === "object") {
            const { Location } = await uploadFileToS3(
              value,
              "user",
              user.document_id
            );
            attachment_list[index].url = Location;
          }
        }
      }

      const formatAcceptedProof = {
        accepted_date,
        accepted_remark,
        attachment_list:
          attachment_list.length > 0
            ? attachment_list.map((attachment) => ({
                name: attachment.fileName,
                url: attachment.url,
                creator_document_id: user.document_id,
              }))
            : [],
      };

      const tempValues = {
        updated_date: moment().unix(),
      };
      const transformedEmployeeList = headerValues.employee_list.map(
        ({
          id,
          status,
          jwt_modifier,
          exp,
          iat,
          created_date,
          last_login_date,
          role_list,
          ...employee
        }) => employee
      );
      const payload = await salesPayload(
        headerValues,
        tempValues,
        mainValues,
        transformedEmployeeList,
        user,
        contactSnapshot,
        summary,
        customerDescValues,
        id,
        "qa",
        formatAcceptedProof
      );
      return payload;
    }
  };

  const saveDraftHandler = async (acceptPrice) => {
    const payload = await submitForm();
    if (payload) {
      const itemList = payload.item_list.map((item) => ({
        item_name: item.item_name,
        price_per_unit: item.price_per_unit,
        purchase_standard_price: item.purchase_standard_price,
      }));

      const itemListPriceError = itemList.filter(
        (item) => item.price_per_unit < item.purchase_standard_price
      );
      if (itemListPriceError.length > 0 && !acceptPrice) {
        setOpenItemPriceConfirmation({
          display: true,
          items: itemListPriceError,
          action: "saveDraft",
          payload: payload,
        });
        return;
      }
      payload.item_list.forEach((item) => {
        delete item.purchase_standard_price;
      });
      if (!id) {
        if (acceptPrice) {
          dispatch(
            createNewQuotation(
              acceptPrice,
              false,
              state,
              user,
              enqueueSnackbar,
              navigate
            )
          );
        } else {
          dispatch(
            createNewQuotation(
              payload,
              false,
              state,
              user,
              enqueueSnackbar,
              navigate
            )
          );
        }
      } else {
        if (acceptPrice) {
          dispatch(
            updateQuotation(
              acceptPrice,
              status,
              false,
              quotation,
              user,
              enqueueSnackbar
            )
          );
        } else {
          dispatch(
            updateQuotation(
              payload,
              status,
              false,
              quotation,
              user,
              enqueueSnackbar
            )
          );
        }
      }
    }
  };

  const sendApproveHandler = async (acceptPrice) => {
    const payload = await submitForm();
    if (payload) {
      const itemList = payload.item_list.map((item) => ({
        item_name: item.item_name,
        price_per_unit: item.price_per_unit,
        purchase_standard_price: item.purchase_standard_price,
      }));

      const itemListPriceError = itemList.filter(
        (item) => item.price_per_unit < item.purchase_standard_price
      );
      if (itemListPriceError.length > 0 && !acceptPrice) {
        setOpenItemPriceConfirmation({
          display: true,
          items: itemListPriceError,
          action: "sendApprove",
          payload: payload,
        });
        return;
      }
      payload.item_list.forEach((item) => {
        delete item.purchase_standard_price;
      });

      if (!id) {
        if (acceptPrice) {
          dispatch(
            createNewQuotation(
              acceptPrice,
              true,
              state,
              user,
              enqueueSnackbar,
              navigate
            )
          );
        } else {
          dispatch(
            createNewQuotation(
              payload,
              true,
              state,
              user,
              enqueueSnackbar,
              navigate
            )
          );
        }
      } else {
        if (acceptPrice) {
          dispatch(
            updateQuotation(
              acceptPrice,
              status,
              true,
              quotation,
              user,
              enqueueSnackbar
            )
          );
        } else {
          dispatch(
            updateQuotation(
              payload,
              status,
              true,
              quotation,
              user,
              enqueueSnackbar
            )
          );
        }
      }
    }
  };

  const notApproveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        declineQuotation(
          {
            document_id,
            document_type: "quotation",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const approveHandler = async () => {
    const payload = await submitForm();
    if (payload) {
      const { document_id } = await payload;
      dispatch(
        approveQuotation(
          {
            document_id,
            document_type: "quotation",
          },
          payload,
          user,
          enqueueSnackbar
        )
      );
      refreshApprovals();
    }
  };

  const editSubmitHandler = async (acceptPrice) => {
    const payload = await submitForm();
    if (payload) {
      const itemList = payload.item_list.map((item) => ({
        item_name: item.item_name,
        price_per_unit: item.price_per_unit,
        purchase_standard_price: item.purchase_standard_price,
      }));

      const itemListPriceError = itemList.filter(
        (item) => item.price_per_unit < item.purchase_standard_price
      );
      if (itemListPriceError.length > 0 && !acceptPrice) {
        setOpenItemPriceConfirmation({
          display: true,
          items: itemListPriceError,
          action: "edit",
          payload: payload,
        });
        return;
      }

      payload.item_list.forEach((item) => {
        delete item.purchase_standard_price;
      });

      if (acceptPrice) {
        dispatch(
          updateQuotation(
            acceptPrice,
            status,
            false,
            quotation,
            user,
            enqueueSnackbar
          )
        );
      } else {
        dispatch(
          updateQuotation(
            payload,
            status,
            false,
            quotation,
            user,
            enqueueSnackbar
          )
        );
      }
      setIsEdit(false);
      setViewOnly(true);
    }
  };

  const submitAcceptProof = async () => {
    const payload = await submitForm();
    if (payload) {
      payload.item_list.forEach((item) => {
        delete item.purchase_standard_price;
      });
      onAcceptProofCloseHandler();
      dispatch(
        updateQuotation(payload, status, true, quotation, user, enqueueSnackbar)
      );
    }
    setIsEdit(false);
  };

  const editCancelHandler = () => {
    navigate("/sales/quotation");
  };

  //TODO: check approval state logic

  const shouldRenderButtons = () => {
    if (isEdit) return true;
    if (isLoading.quotation) {
      return false;
    } else {
      if (pathname.includes("add")) {
        return true;
      }
      if (status) {
        switch (status) {
          case "draft":
            // case "notApproved":
            return true;
          case "waitApprove":
            if (isHaveApproval) {
              if (isHaveApprovalPermission) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          default:
            return false;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setPrimaryButtonTitle("บันทึก");
      setSecondaryButtonTitle("ยกเลิก");
    } else {
      switch (status) {
        // case "draft":
        // case "notApproved":
        //   setPrimaryButtonTitle("ส่ง");
        //   setSecondaryButtonTitle("บันทึกร่าง");
        //   break;
        case "waitApprove":
          setPrimaryButtonTitle("อนุมัติ");
          setSecondaryButtonTitle("ไม่อนุมัติ");
          break;
        default:
      }
    }
  }, [isEdit, status]);

  const primarySubmitHandler = () => {
    salesPrimaryButtonHandler(
      status,
      isEdit,
      sendApproveHandler,
      approveHandler,
      editSubmitHandler
    );
  };

  const secondarySubmitHandler = async () => {
    salesSecondaryButtonHandler(
      status,
      isEdit,
      saveDraftHandler,
      notApproveHandler,
      editCancelHandler
    );
  };

  const editClickHandler = () => {
    setViewOnly(false);
    setIsEdit(true);
  };

  const onAcceptProofOpenHandler = () => {
    setOpenAcceptProof(true);
  };

  const onAcceptProofCloseHandler = () => {
    // reset();
    setOpenAcceptProof(false);
  };

  const closeItemPriceConfirmationHandler = () => {
    setOpenItemPriceConfirmation({
      display: false,
      items: [],
      action: null,
      payload: null,
    });
  };

  const itemPriceConfirmationAction = () => {
    setOpenItemPriceConfirmation({
      display: false,
      items: [],
      action: null,
      payloal: null,
    });
    if (openItemPriceConfirmation.action === "saveDraft") {
      openItemPriceConfirmation.payload.item_list.forEach((item) => {
        delete item.purchase_standard_price;
      });
      saveDraftHandler(openItemPriceConfirmation.payload);
    } else if (openItemPriceConfirmation.action === "sendApprove") {
      openItemPriceConfirmation.payload.item_list.forEach((item) => {
        delete item.purchase_standard_price;
      });
      sendApproveHandler(openItemPriceConfirmation.payload);
    } else if (openItemPriceConfirmation.action === "edit") {
      openItemPriceConfirmation.payload.item_list.forEach((item) => {
        delete item.purchase_standard_price;
      });
      editSubmitHandler(openItemPriceConfirmation.payload);
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {!tab ? (
        <>
          <SalesHeaderForm
            ref={headerRef}
            control={headerControl}
            errors={headerErrors}
            getValues={getHeaderValues}
            setValue={setHeaderValue}
            setMainValue={setValue}
            mainFormControl={control}
            viewOnly={viewOnly || Boolean(!editPermission && id)}
            documentType="quotation"
            editClickHandler={editClickHandler}
            currentState={quotation}
            onAcceptProofOpenHandler={onAcceptProofOpenHandler}
            isEdit={isEdit}
            approvalList={approvalList}
            setIsEdit={setIsEdit}
          />
          <DocumentInfoTab
            ref={customerDescRef}
            itemListRef={itemListRef}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            submitCustomerDesc={submitContactForm}
            customerDescUseForm={customerDescUseForm}
            contactUseForm={contactUseForm}
            acceptProofUseForm={acceptProofUseForm}
            onSubmitAcceptProof={submitAcceptProof}
            viewOnly={viewOnly || Boolean(!editPermission && id)}
            openAcceptProof={openAcceptProof}
            onAcceptProofCloseHandler={onAcceptProofCloseHandler}
            setHeaderValue={setHeaderValue}
          />
          {shouldRenderButtons() &&
            ((createPermission && !id) || (editPermission && id)) && (
              <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: 1 }}>
                  <CustomizedButton
                    title={secondaryButtonTitle}
                    variant="outlined"
                    type="reset"
                    onClick={secondarySubmitHandler}
                  />
                </Box>
                <CustomizedButton
                  title={primaryButtonTitle}
                  variant="contained"
                  onClick={primarySubmitHandler}
                />
              </Box>
            )}
        </>
      ) : (
        <OrderTab />
      )}
      <DirtyConfirmation
        openDirtyConfirmation={dirtyConfirmation}
        dirtyConfirmationAction={dirtyConfirmationAction}
        closeConfirmationHandler={closeConfirmationHandler}
      />

      <ItemPriceConfirmation
        openItemPriceConfirmation={openItemPriceConfirmation.display}
        closeItemPriceConfirmationHandler={closeItemPriceConfirmationHandler}
        itemPriceConfirmationAction={itemPriceConfirmationAction}
        items={openItemPriceConfirmation.items}
      />
    </>
  );
};

export default QuotationContainer;
