import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useSelector } from "react-redux";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import PurchaseService from "../../../services/Purchase";
import { useParams } from "react-router-dom";
import { dateToUnix } from "../../../utils/date-converter";
import { useAuth } from "../../../hooks/use-auth";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
// import InventoryService from "../../../services/Inventory";
import ManufactureService from "../../../services/Manufacture";
import { v4 as uuidv4 } from "uuid";
import ControlledDatePicker from "../../../components/Custom/ControlledDatePicker";

const defaultOptions = {
  data: [],
  type: "",
};

const ReceiveDetail = ({
  control,
  errors,
  getValues,
  setValue,
  viewOnly,
  isInventoryPage,
  documentStage,
  watchType,
  watchRefDoc,
  reset,
  disabled,
  generateDocumentId,
  isPartial,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { id } = useParams();
  // const { item } = useSelector((state) => state.goodsReceive);
  const { allLocation } = useSelector((state) => state.location);
  const [referenceDocumentOptions, setReferenceDocumentOptions] =
    useState(defaultOptions);
  const [receiveDateIsOpen, setReceiveDateIsOpen] = useState(false);

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const warehouseOption = allLocation?.map((location) => {
    return {
      id: location.id,
      label: location.thai_name,
      value: location.document_id,
    };
  });

  const currentWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const mapWarehouseIdToName = () => {
    const findId = allLocation?.find(
      (location) => location.document_id === currentWarehouse
    );
    if (findId) return findId.thai_name;
    else return "";
  };

  const typeOption = [
    { label: "ซื้อ", value: "ซื้อ" }, //PO
    { label: "ผลิต", value: "ผลิต" }, //MO
    { label: "แปรรูป", value: "แปรรูป" }, //MO
    { label: "อื่นๆ", value: "อื่นๆ" },
  ];

  const fetchDocument = useCallback(async () => {
    setReferenceDocumentOptions({ ...defaultOptions, data: [] });
    setIsLoadingOptions(true);
    let data = null;
    const type = getValues("type");
    if (type === "ซื้อ") {
      const input = {
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          render_status: {
            values: ["approved", "partiallyImported"],
            filterType: "set",
          },
        },
      };
      const { results } = await PurchaseService.getAllPurchaseOrder(input);
      data = await results;
      setIsLoadingOptions(false);
    } else if (type === "ผลิต" || type === "แปรรูป") {
      const input = {
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          render_status: {
            values: ["finished", "inProgress"],
            filterType: "set",
          },
        },
      };
      const { results } = await ManufactureService.getAllManufactureOrderIds(
        input
      );
      data = await results;
      setIsLoadingOptions(false);
    }

    setReferenceDocumentOptions({
      data: data || [],
      type: type,
    });
  }, [getValues]);

  const resetValue = useCallback(() => {
    if (!Boolean(id)) {
      setValue("reference_document_id", "");
      setValue("goods_receive_list", []);
    }
  }, [id, setValue]);

  const checkTypeIfSameDoc = useCallback(async () => {
    const type = getValues("type");
    const refDoc = getValues("reference_document_id");
    if (refDoc)
      if (refDoc.slice(0, 2) === "PO" && type !== "ซื้อ") {
        resetValue();
      } else if (
        refDoc.slice(0, 2) === "MO" &&
        type !== "ผลิต" &&
        type !== "แปรรูป"
      ) {
        resetValue();
      } else if (type === "อื่นๆ") {
        resetValue();
      }
  }, [getValues, resetValue]);

  useEffect(() => {
    if (
      !id &&
      ((["ซื้อ"].includes(watchType) &&
        !["ซื้อ"].includes(referenceDocumentOptions.type)) ||
        (["ผลิต", "แปรรูป"].includes(watchType) &&
          !["ผลิต", "แปรรูป"].includes(referenceDocumentOptions.type)))
    )
      fetchDocument();
  }, [watchType, referenceDocumentOptions.type, fetchDocument, id]);

  const selectedDocumentHandler = async (field, newValue) => {
    let findItemFromRef = undefined;
    const currValue = getValues();
    if (newValue && newValue.value) {
      if (currValue.reference_document_id !== newValue.value) {
        reset({
          ...currValue,
          reference_document_id: newValue.value,
          goods_receive_list: [],
        });
      }
      if (currValue.type === "ซื้อ") {
        const purchaseOrder = await PurchaseService.getPurchaseOrderById({
          document_id: newValue.value,
        });
        findItemFromRef = await await {
          ...purchaseOrder,
          item_list: purchaseOrder.item_list.map((item) => {
            return {
              ...item,
              document_id: item.item_document_id,
              name: item.item_name,
              description: item.item_description,
              quantity: item.qty,
              received_qty: item.received_qty,
              item_uid: item.uid,
              uom: item.uom,
            };
          }),
        };
      }
      if (currValue.type === "ผลิต" || currValue.type === "แปรรูป") {
        const manufactureOrder =
          await ManufactureService.getManufactureOrderById(newValue.value);
        findItemFromRef = await {
          ...manufactureOrder,
          item_list: [
            {
              document_id: manufactureOrder.item_document_id,
              name: manufactureOrder.item_name,
              description: manufactureOrder.item_description,
              quantity: manufactureOrder.production_qty,
              received_qty: manufactureOrder.goods_receive_qty || 0,
              item_uid: uuidv4(),
              uom: {
                document_id: manufactureOrder.uom,
                name: manufactureOrder.uom,
              },
            },
          ],
        };
      }
      if (findItemFromRef) {
        const formatValue = findItemFromRef.item_list.map((item) => {
          return {
            initial_quantity: item.quantity,
            posted_quantity: item.quantity,
            posted_value: 0,
            already_posted_quantity:
              item.received_qty || item.quantity_return || 0,
            stock_entry_list: {
              destination_warehouse: "",
              entry_type: "",
              is_active: true,
              item: item,
              item_uid: item.item_uid,
              posted_date: dateToUnix(new Date()),
              posted_quantity: 0,
              posted_value: 0,
              reference_document_id: "",
              reference_document_type: "issue",
              source_warehouse_document_id: getValues(
                "source_warehouse_document_id"
              ),
              trace_entry_list: [],
              created_by: user,
              uom: item.uom,
            },
          };
        });
        setValue(`goods_receive_list`, formatValue);
      }
      field.onChange(newValue.value);
    } else {
      reset({
        ...currValue,
        reference_document_id: "",
        goods_receive_list: [],
      });
      field.onChange("");
    }
    field.onChange(newValue ? newValue.value : "");
  };

  const checkStage = (documentStage === 0 || isPartial) && !id;

  return (
    <CustomizedBox margin={0}>
      <Typography sx={{ fontWeight: 700, mb: 3 }}>
        {t("description")}
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={checkStage ? 10 : 12}
          sm={checkStage ? 10 : 12}
          md={6}
          lg={4}
          xl={4}
        >
          <Controller
            control={control}
            name={"document_id"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.receive.documentId")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                disabled={
                  (!isInventoryPage || viewOnly || id) && !isPartial
                    ? true
                    : false
                }
              />
            )}
          />
        </Grid>
        {(documentStage === 0 || isPartial) && !id && (
          <Grid item xs={2}>
            <Box sx={{ ml: -1 }}>
              <CustomizedTooltip title="เรียกเลขที่เอกสารใหม่">
                <IconButton
                  onClick={generateDocumentId}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"type"}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                options={typeOption}
                label={t("inventory.receive.receiveType")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                disabled={!isInventoryPage || viewOnly || id ? true : false}
                onChange={(e) => {
                  field.onChange(e);
                  checkTypeIfSameDoc();
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          {watchType === "อื่นๆ" ? (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.referenceNo")}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  disabled={
                    !isInventoryPage || viewOnly || watchType === "อื่นๆ" || id
                      ? true
                      : false
                  }
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  loading={isLoadingOptions}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  options={referenceDocumentOptions?.data?.map((doc) => {
                    return {
                      id: doc.id,
                      value: doc.document_id,
                      label: doc.document_id,
                    };
                  })}
                  label={t("inventory.referenceNo")}
                  onChange={(_, newValue) =>
                    selectedDocumentHandler(field, newValue)
                  }
                  disabled={
                    !isInventoryPage || viewOnly || Boolean(id) ? true : false
                  }
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledDatePicker
            name="lot"
            control={control}
            error={errors.document_date}
            isOpen={receiveDateIsOpen}
            onClose={() => setReceiveDateIsOpen(false)}
            onOpen={() => setReceiveDateIsOpen(true)}
            label="Lot"
            disabled={documentStage === 2 || disabled}
            // sx={{ width: { sx: "auto", md: 155 } }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"source_warehouse_document_id"}
            render={({ field }) => (
              <CustomizedComboBox
                options={warehouseOption}
                label={t("inventory.warehouse")}
                onChange={(_, newValue) =>
                  field.onChange(newValue ? newValue.value : "")
                }
                error={Boolean(errors.source_warehouse_document_id)}
                helperText={errors.source_warehouse_document_id?.message}
                value={mapWarehouseIdToName()}
                disabled={!isInventoryPage || viewOnly || id ? true : false}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"remark"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.remark")}
                error={Boolean(errors.remark)}
                helperText={errors.remark?.message}
                disabled={documentStage === 2 || disabled}
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ReceiveDetail;
