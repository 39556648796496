import { graphQLClientWithHeader } from "../graphQL";
import { CREATE_ACTIVITY_LOGS } from "./mutation";
import { GET_ACTIVITY_LOGS } from "./queries";

class ActivityLogsService {
  async getActivityLogs(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { activityLogs } = await graphQLClient.request(
      GET_ACTIVITY_LOGS,
      input
    );
    return activityLogs;
  }
  async createActivityLogs(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { createActivityLog } = await graphQLClient.request(
      CREATE_ACTIVITY_LOGS,
      input
    );
    return createActivityLog;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ActivityLogsService();
