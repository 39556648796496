import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import React from "react";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedButton from "../../Custom/CustomizedButton";
import ItemTable from "../../Table/ItemTable";
import ModalUI from "../../UI/ModalUI";
import AddIcon from "@mui/icons-material/Add";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ItemListTable from "../../Table/DocumentTable/ItemListTable";

const TotalPreVatAmount = ({
  itemTableGridRef,
  onGridReady,
  handleSelectItems,
  control,
  openModal,
  setOpenModal,
  viewOnly,
  documentType,
  getRowId,
  addRowHandler,
}) => {
  const { t } = useTranslation();
  const { pre_vat_amount } = useWatch({ control, name: "summary" });
  const { salesOrderItemList } = useSelector((state) => state.salesReturn);
  const { salesOrderItemList: deliveryItemList } = useSelector(
    (state) => state.deliveryOrder
  );

  return (
    <>
      <CustomizedBox margin="0" radius="0 0 15px 15px" padding="1rem">
        {!viewOnly && documentType !== "di" && documentType !== "rt" && (
          <CustomizedButton
            title="เพิ่มสินค้า"
            startIcon={<AddIcon />}
            variant="outlined"
            color="secondary"
            onClick={() => setOpenModal(true)}
          />
        )}
        {!viewOnly && documentType === "di" && documentType !== "rt" && (
          <CustomizedButton
            title="เพิ่มแถว"
            startIcon={<AddIcon />}
            variant="outlined"
            color="secondary"
            onClick={addRowHandler}
          />
        )}
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Typography variant="subtitle1">{`${t(
            "sales.totalPreVatAmount"
          )}`}</Typography>
          <Typography
            variant="subtitle1"
            sx={{ width: "180px", textAlign: "right", mx: 2 }}
          >
            {pre_vat_amount ?? 0}
          </Typography>
          <Typography variant="subtitle1">บาท</Typography>
        </Box>
      </CustomizedBox>
      {documentType !== "rt" && (
        <ModalUI
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title="สินค้า"
          fullWidth
          maxWidth={
            documentType === "sr" || documentType === "do" ? "lg" : "xl"
          }
        >
          {documentType === "sr" ? (
            <ItemListTable
              gridRef={itemTableGridRef}
              itemList={salesOrderItemList ?? []}
              getRowId={getRowId}
              documentType={documentType}
            />
          ) : documentType === "do" ? (
            <ItemListTable
              gridRef={itemTableGridRef}
              itemList={deliveryItemList ?? []}
              documentType={documentType}
            />
          ) : (
            <ItemTable
              gridRef={itemTableGridRef}
              height={450}
              onGridReady={onGridReady}
              rowSelection="multiple"
              enableCheckbox
              getRowId={getRowId}
            />
          )}
          <CustomizedButton
            title="เพิ่ม"
            variant="contained"
            onClick={handleSelectItems}
            sx={{ mt: 2 }}
          />
        </ModalUI>
      )}
    </>
  );
};

export default TotalPreVatAmount;
