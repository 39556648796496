import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import ModalUI from "../../../UI/ModalUI";
import addressData from "../../../../data/address.json";
import { useTranslation } from "react-i18next";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import CustomizedButton from "../../../Custom/CustomizedButton";
import SettingService from "../../../../services/Setting";

const CompanyDeliveryAddressModal = ({
  control,
  errors,
  disabled,
  subdistrictOptions,
  districtOptions,
  provinceOptions,
  setSubdistrict,
  setDistrict,
  setProvince,
  setValue,
  isOpen,
  onClose,
  reset,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [companyInfo, setCompanyInfo] = useState(null);
  const [addressOptions, setAddressOptions] = useState([]);

  const watchAddressType = useWatch({ control, name: "address_type" });

  const countryOptions = ["ไทย"];

  useEffect(() => {
    const getCompanyInfo = async () => {
      const companyInfoData = await SettingService.getCompanySetting(1);
      setCompanyInfo(companyInfoData);
      const addressOptions = companyInfoData.address_list.map(
        (address) => address.address_type
      );
      setAddressOptions(addressOptions);
    };
    getCompanyInfo();
  }, []);

  const autofillDeliveryAddress = (option) => {
    const selectedAddress =
      companyInfo.address_list?.filter(
        (address) => address.address_type === option
      )[0] || null;

    if (selectedAddress) {
      const formattedDeliveryAddress = {
        address_type: selectedAddress.address_type,
        is_same_as_default_address: false,
        address_contact_name: selectedAddress.address_contact_name ?? "",
        address_contact_phone: selectedAddress.address_contact_phone ?? "",
        address: selectedAddress.address ?? "",
        sub_district: selectedAddress.sub_district ?? "",
        district: selectedAddress.district ?? "",
        province: selectedAddress.province ?? "",
        postal_code: selectedAddress.postal_code ?? "",
        country: selectedAddress.country ?? "",
      };
      reset({ ...formattedDeliveryAddress });
    }
  };

  const renderAddressDetail = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-address_contact_name"
            name="address_contact_name"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.address_contact_name)}
                helperText={errors.address_contact_name?.message}
                label="ชื่อผู้ติดต่อ"
                {...field}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-address_contact_phone"
            name="address_contact_phone"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.address_contact_phone)}
                helperText={errors.address_contact_phone?.message}
                label={t("user.account.phone")}
                {...field}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            key="delivery-address"
            name="address"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.address)}
                helperText={errors.address?.message}
                label="ที่อยู่"
                {...field}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-sub_district"
            name="sub_district"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={subdistrictOptions}
                title="แขวง/ตำบล"
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSubdistrict();
                    setDistrict();
                    setProvince();
                    return;
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setSubdistrict(option);
                  const filter = addressData.filter((adddress) => {
                    return `${adddress.sub_district}` === `${option}`;
                  });
                  if (filter && filter.length !== 0) {
                    setValue("district", filter[0].district);
                    setValue("province", filter[0].province);
                    setValue("postal_code", filter[0].postal_code);
                  } else if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={disabled}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-district"
            name="district"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={districtOptions}
                title="เขต/อำเภอ"
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSubdistrict();
                    setDistrict();
                    setProvince();
                    return;
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setDistrict(option);
                  const filter = addressData.filter((adddress) => {
                    return `${adddress.district}` === `${option}`;
                  });
                  if (filter && filter.length !== 0) {
                    setValue("province", filter[0].province);
                  } else if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={disabled}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-province"
            name="province"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={provinceOptions}
                title="จังหวัด"
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSubdistrict();
                    setDistrict();
                    setProvince();
                    return;
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setProvince(option);
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={disabled}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-postal_code"
            name="postal_code"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.postal_code)}
                helperText={errors.postal_code?.message}
                label="รหัสไปรษณีย์"
                {...field}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-country"
            name="country"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={countryOptions}
                title="ประเทศ"
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  if (option.value) {
                    return field.onChange(option.value);
                  }
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={disabled}
                disabled={disabled}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalUI
      title={
        <Typography fontWeight="bold" sx={{ ml: 1 }}>
          ที่อยู่จัดส่ง
        </Typography>
      }
      open={isOpen}
      handleClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-address_type"
            name="address_type"
            control={control}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                fullWidth
                error={Boolean(errors.address_type)}
                helperText={errors.address_type?.message}
                label="เลือกที่อยู่"
                options={addressOptions}
                onChange={(e) => {
                  autofillDeliveryAddress(e.target.value);
                  return field.onChange(e.target.value);
                }}
                disabled={disabled}
              />
            )}
          />
        </Grid>
      </Grid>
      {watchAddressType && renderAddressDetail()}
      <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
        <CustomizedButton
          title={t("button.cancel")}
          variant="outlined"
          size="medium"
          onClick={onClose}
          disabled={disabled}
        />
        <CustomizedButton
          title={t("button.save")}
          onClick={onSubmit}
          type="submit"
          variant="contained"
          size="medium"
          disabled={disabled}
        />
      </Box>
    </ModalUI>
  );
};

export default CompanyDeliveryAddressModal;
