import * as Yup from "yup";

export const schema = {
  created_date: "",
  is_customer: false,
  is_vendor: false,
  document_id: "",
  created_by: null,
  creator_document_id: "",
  img_url: "",
  contact_type_1: "",
  contact_type_2: "",
  contact_name_1: "",
  contact_name_2: "",
  initial: "",
  identity_no: "",
  registered_capital: "",
  contact_source: "",
  contact_channel_list: [],
  contact_status: "active",
  contact_status_remark: "",
  contact_group: [],
  estimate_sales_item_group: [],
  estimate_sales_volume: "",
  address_list: [
    {
      address_type: "ที่อยู่จดทะเบียน",
      default_address: true,
      same_as_default_address: null,
      address_contact_name: "",
      address_contact_phone: "",
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  ],
  contact_person_list: [],
  finance: {
    is_cheque: false,
    is_transfer: false,
    is_cash: false,
    payment_day: "",
    payment_contact_name: "",
    payment_contact_phone: "",
    credit_count: "",
    billing_day: "",
    billing_contact_name: "",
    billing_contact_phone: "",
    request_credit: {
      credit_limit_value: "",
      credit_limit_day: "",
    },
    account_payable_id: "",
    account_receivable_id: "",
    bank_list: [],
  },
  attachment_list: [],
  sales_list: [],
  remark: "",
};

export const validation = Yup.object().shape(
  {
    document_id: Yup.string().required("กรุณาระบุรหัสผู้ติดต่อ"),
    is_customer: Yup.boolean().when("is_vendor", {
      is: (is_vendor) => is_vendor === false,
      then: Yup.boolean()
        .required("กรุณาเลือกประเภทผู้ติดต่อ")
        .oneOf([true], "กรุณาเลือกประเภทผู้ติดต่อ"),
      otherwise: Yup.boolean(),
    }),
    is_vendor: Yup.boolean().when("is_customer", {
      is: (is_customer) => is_customer === false,
      then: Yup.boolean()
        .required("กรุณาเลือกประเภทผู้ติดต่อ")
        .oneOf([true], "กรุณาเลือกประเภทผู้ติดต่อ"),
      otherwise: Yup.boolean(),
    }),
    contact_type_1: Yup.string().required("กรุณาเลือกประเภทกิจการ"),
    contact_name_1: Yup.string().when("contact_type_1", {
      is: (contact_type) => contact_type !== "",
      then: Yup.string().when("contact_type_1", {
        is: (contact_type) => contact_type === "นิติบุคคล",
        then: Yup.string().required("กรุณาระบุชื่อกิจการ"),
        otherwise: Yup.string().required("กรุณาระบุชื่อ"),
      }),
      otherwise: Yup.string(),
    }),
    contact_name_2: Yup.string().when("contact_type_1", {
      is: (contact_type) => contact_type === "บุคคลธรรมดา",
      then: Yup.string().required("กรุณาระบุนามสกุล"),
      otherwise: Yup.string(),
    }),
    identity_no: Yup.string()
      .nullable()
      .test(
        "len",
        'เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"',
        (val) => !val || (val && val.length <= 13)
      ),
    registered_capital: Yup.lazy((value) =>
      value === "" ? Yup.string() : Yup.number()
    ),
    estimate_sales_volume: Yup.lazy((value) =>
      value === "" ? Yup.string() : Yup.number()
    ),
    finance: Yup.object().shape({
      payment_day: Yup.lazy((value) =>
        value === ""
          ? Yup.string()
          : Yup.number().max(4000, "กรุณาระบุเครดิตวันไม่เกิน 4000 วัน")
      ),
      billing_day: Yup.lazy((value) =>
        value === "" ? Yup.string() : Yup.number()
      ),
      request_credit: Yup.object().shape({
        credit_limit_value: Yup.lazy((value) =>
          value === "" ? Yup.string() : Yup.number()
        ),
        credit_limit_day: Yup.lazy((value) =>
          value === "" ? Yup.string() : Yup.number()
        ),
      }),
    }),
    address_list: Yup.array().of(
      Yup.object().shape({
        default_address: Yup.boolean(),
        same_as_default_address: Yup.boolean().nullable(),
        address: Yup.string().nullable(),
        address_contact_name: Yup.string().nullable(),
        address_contact_phone: Yup.string().nullable(),
        address_type: Yup.string().nullable(),
        country: Yup.string().nullable(),
        sub_district: Yup.string().nullable(),
        district: Yup.string().nullable(),
        province: Yup.string().nullable(),
        postal_code: Yup.string().nullable(),
      })
    ),
  },
  ["is_customer", "is_vendor"]
);
