import { useEffect, useState } from "react";
import { permissionAction } from "../../utils/userInfo";

const allPermissionList = permissionAction();

const formatAction = (formatList) => {
  return formatList.reduce((a, v) => {
    const action = v.split("__")[2];
    return { ...a, [action]: v };
  }, {});
};

export const useContactPermission = () => {
  const [contactPermission, setContactPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONTACT__ALL")
    );
    const actions = formatAction(formatList);
    setContactPermission(actions);
  }, []);

  return contactPermission;
};

export const useCustomerPermission = () => {
  const [contactPermission, setContactPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONTACT__CUSTOMER")
    );
    const actions = formatAction(formatList);
    setContactPermission(actions);
  }, []);

  return contactPermission;
};

export const useVendorPermission = () => {
  const [contactPermission, setContactPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONTACT__VENDOR")
    );
    const actions = formatAction(formatList);
    setContactPermission(actions);
  }, []);

  return contactPermission;
};

export const useContactReport = () => {
  const [contactPermission, setContactPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("CONTACT__REPORT")
    );
    const actions = formatAction(formatList);
    setContactPermission(actions);
  }, []);

  return contactPermission;
};
