import { IconButton, Typography } from "@mui/material";
import {
  customHeaderColRender,
  RenderCustomizedSelect,
  RenderCustomizedTextField,
} from "../../../../utils/customCellRenderer";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedNumberTextField from "../../../Custom/CustomizedNumberTextField";

export const diColumnDefs = (
  t,
  control,
  errors,
  viewOnly,
  openDeleteItemConfirmationHandler
) => {
  const customCellStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px ",
  };
  const vatOption = [
    { value: "ไม่มี", label: "ไม่มี" },
    { value: "0", label: "0 %" },
    { value: "7", label: "7 %" },
  ];
  return [
    {
      field: "no",
      rowDrag: viewOnly ? false : true,
      headerComponentParams: {
        template: customHeaderColRender("No.", "รายการ"),
      },
      valueGetter: (params) => params.node.rowIndex + 1,
      cellStyle: customCellStyle,
      width: 90,
    },
    {
      field: "description",
      headerComponentParams: {
        template: customHeaderColRender("Description", t("sales.description")),
      },
      cellRenderer: (params) => (
        <>
          {viewOnly ? (
            <Typography variant="body2" align="left">
              {params.data.description}
            </Typography>
          ) : (
            <RenderCustomizedTextField
              error={Boolean(
                errors?.item_list &&
                  errors?.item_list[params.node.rowIndex] &&
                  errors?.item_list[params.node.rowIndex]?.description
              )}
              helperText={
                errors?.item_list &&
                errors?.item_list[params.node.rowIndex] &&
                errors?.item_list[params.node.rowIndex]?.description &&
                errors?.item_list[params.node.rowIndex]?.description?.message
              }
              control={control}
              name={`item_list[${params.node.rowIndex}].description`}
              onChange={(e) => {
                const rowNode = params.api.getRowNode(params.data.uid);
                rowNode.setDataValue("description", e.target.value);
              }}
              multiline
            />
          )}
        </>
      ),
      cellStyle: { padding: "10px 20px" },
      autoHeight: true,
      width: 750,
    },
    {
      field: "vat_type",
      headerComponentParams: {
        template: customHeaderColRender("Vat (%)", `${t("sales.vat")} (%)`),
      },
      cellRenderer: (params) => (
        <>
          {viewOnly ? (
            <Typography variant="body2" align="center">
              {params.data.vat_type}
            </Typography>
          ) : (
            <RenderCustomizedSelect
              control={control}
              name={`item_list[${params.node.rowIndex}].vat_type`}
              options={vatOption}
            />
          )}
        </>
      ),
      cellStyle: customCellStyle,
      width: 150,
    },
    {
      field: "pre_vat_amount",
      headerComponentParams: {
        template: customHeaderColRender(
          "Pre-vat Amount",
          t("sales.preVatAmount")
        ),
      },
      cellRenderer: (params) => (
        <>
          {viewOnly ? (
            <Typography variant="body2" align="center">
              {params.data.pre_vat_amount}
            </Typography>
          ) : (
            <>
              <CustomizedNumberTextField
                error={Boolean(
                  errors?.item_list &&
                    errors?.item_list[params.node.rowIndex] &&
                    errors?.item_list[params.node.rowIndex]?.pre_vat_amount
                )}
                helperText={
                  errors?.item_list &&
                  errors?.item_list[params.node.rowIndex] &&
                  errors?.item_list[params.node.rowIndex]?.pre_vat_amount &&
                  errors?.item_list[params.node.rowIndex]?.pre_vat_amount
                    ?.message
                }
                control={control}
                name={`item_list[${params.node.rowIndex}].pre_vat_amount`}
                onChange={(value) => {
                  const rowNode = params.api.getRowNode(params.data.uid);
                  rowNode.setDataValue("pre_vat_amount", value);
                }}
              />
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                onClick={() =>
                  openDeleteItemConfirmationHandler(params.node.rowIndex)
                }
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </>
      ),
      cellStyle: customCellStyle,
      width: 200,
    },
  ];
};
