import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";
// import Cookies from "universal-cookie";
// import jwtDecode from "jwt-decode";
import ExpiredConfirmation from "../components/UI/Confirmation/ExpiredConfirmation";

const AuthVerify = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { isRefreshTokenExpired, logout, verify, refresh } = useAuth();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const verifyToken = async () => {
      await refresh();
      await verify();
    };
    if (location.pathname !== "/login" && location.pathname !== "/")
      verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (
      isRefreshTokenExpired &&
      location.pathname !== "/login" &&
      location.pathname !== "/"
    ) {
      setOpen(true);
    }
  }, [isRefreshTokenExpired, location.pathname]);

  return (
    <ExpiredConfirmation
      open={open}
      logout={() => {
        logout();
        navigate("/login", { replace: true });
        setOpen(false);
      }}
    />
  );
};

export default AuthVerify;
