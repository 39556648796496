import { Box } from "@mui/material";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { unixToDateWithFormat } from "../../../../utils/date-converter";
import {
  dateComparator,
  filterParamsOptions,
} from "../../../../utils/filterparams";

export const contactColumnDefs = (t, getAllTag, isReport) => {
  const contactChannelAction = (type, info) => {
    switch (type) {
      case "เบอร์โทรศัพท์":
      case "แฟกซ์":
        window.open(`tel:${info}`, "_self");
        return;
      case "Email":
        window.open("mailto:" + info, "_self");
        return;
      case "Line":
        window.open(
          "https://line.me/R/ti/p/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "Facebook":
        window.open(
          "https://facebook.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "Website":
        window.open(`https://${info}`, "_blank", "noopener,noreferrer");
        return;
      case "Instagram":
        window.open(
          "https://instagram.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      default:
        return;
    }
  };

  return [
    {
      field: "document_id",
      headerName: t("contact.document_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "contact_type_1",
      headerName: t("contact.info.business_type"),
      filter: "agTextColumnFilter",
    },
    {
      field: "is_vendor",
      headerName: t("contact.contact_type.index"),
      filter: false,
      cellRenderer: ({ data }) => {
        if (data.is_vendor && data.is_customer) {
          return "ลูกค้า, ผู้ขาย";
        }
        if (data.is_vendor) {
          return "ผู้ขาย";
        }
        if (data.is_customer) {
          return "ลูกค้า";
        }
        return "";
      },
    },
    {
      field: "is_customer",
      filter: "agTextColumnFilter",
      filterParams: {
        textMatcher: ({ data, filterText }) => {
          return data.is_customer.toString() === filterText;
        },
      },
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "contact_name_1",
      headerName: "ชื่อกิจการ/ชื่อ นามสกุล",
      cellRenderer: ({ data }) => {
        if (data.contact_type_1 === "นิติบุคคล") {
          return data.contact_name_1;
        }
        return `${data.contact_name_1} ${data.contact_name_2}`;
      },
      filter: "agTextColumnFilter",
    },
    {
      field: "created_date",
      headerName: t("sales.createdDate"),
      sort: "desc",
      hide: true,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
        comparator: isReport ? dateComparator : undefined,
      },
      valueFormatter: (params) =>
        unixToDateWithFormat(params.data.created_date),
      width: 200,
    },
    {
      field: "contact_channel_list",
      headerName: t("contact.contact_channel.index"),
      filter: false,
      // filterParams: {
      //   textMatcher: ({ data, filterText }) => {
      //     const includesText = data.contact_channel_list.some((channel) => {
      //       return (
      //         channel.contact_channel.includes(filterText) ||
      //         channel.contact_info_1.includes(filterText)
      //       );
      //     });
      //     return includesText;
      //   },
      // },
      cellRenderer: (params) => {
        return params.value.map((channel, index) => (
          <Box
            sx={{ display: "inline-block", mr: 1 }}
            key={`${channel.contact_info_1}-${index}`}
          >
            <CustomizedChips
              onClick={() =>
                contactChannelAction(
                  channel.contact_channel,
                  channel.contact_info_1
                )
              }
              value={`${channel.contact_channel}: ${channel.contact_info_1}`}
            />
          </Box>
        ));
      },
    },
    {
      field: "tag_list",
      headerName: "การจัดกลุ่ม",
      filter: "agSetColumnFilter",
      sortable: false,
      filterParams: {
        values: async (params) => {
          // fetch values from server
          const values = await getAllTag();
          const formatValues = values
            .filter((tag) => tag.entity === "contact")
            .map((tag) => tag.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params) => {
        return params.value.map((tag) => (
          <Box sx={{ display: "inline-block", mr: 1, gap: 1 }}>
            <CustomizedChips key={tag.name} value={`${tag.name}`} />
          </Box>
        ));
      },
    },
    {
      field: "contact_status",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["active", "inactive"],
        valueFormatter: (params) => {
          if (params.value === "active") {
            return "ใช้งาน";
          }
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: (params) => {
        if (params.value === "active") {
          return <CustomizedStatus status="active" />;
        }
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];
};
