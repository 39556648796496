import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controlled/ControlledTextField";
import { CustomizedBox } from "../../../Custom/CustomizedBox";

const BomRemarkForm = ({ control, errors, disabled }) => {
  const { t } = useTranslation();
  return (
    <CustomizedBox>
      <Typography ml={1} mb={3} fontWeight="bold">
        {t("manufacture.bom.internal_remark")}
      </Typography>
      <ControlledTextField
        name="internal_remark"
        control={control}
        error={Boolean(errors?.internal_remark)}
        helperText={errors?.internal_remark && errors?.internal_remark.message}
        disabled={disabled}
        rows={4}
        multiline
      />
    </CustomizedBox>
  );
};

export default BomRemarkForm;
