import { gql } from "graphql-request";

export const VERIFY = gql`
  query Verify {
    verify {
      id
      document_id
      first_name
      last_name
      email
      phone
      department
      position
      status
      line_uid
      img_url
      last_login_date
      role_list {
        id
        name
        permission_list
      }
    }
  }
`;
