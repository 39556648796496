import moment from "moment";
import { localeStringToFloat } from "./dataTransformer";
import {
  unixToDate,
  unixToDateWithFormat,
  unixToDateTimeWithFormat,
} from "./date-converter";
import InventoryService from "../services/Inventory";
import GlobalService from "../services/Global";
import { filterStatusValueFormatter } from "./filterparams";

export const purchaseRequestPayload = (
  headerValues,
  mainValues,
  transformedEmployeeList,
  userDescValues,
  user,
  id
) => {
  const { reference_document_id, created_date, ...otherHeaderValues } =
    headerValues;

  const payload = {
    ...otherHeaderValues,
    creator_document_id: user.document_id,
    employee_list: transformedEmployeeList,
    requester_document_id: userDescValues.requester_document_id,
    requester_name: userDescValues.requester_name,
    requester_department: userDescValues.requester_department,
    requester_position: userDescValues.requester_position,
    remark: mainValues.remark,
    template_remark_id: mainValues.template_remark_id
      ? parseInt(mainValues.template_remark_id)
      : null,
    issue_date: moment(headerValues.issue_date).startOf("day").unix(),
    due_date: moment(headerValues.due_date).endOf("day").unix(),
    item_list: mainValues.item_list.map((item) => ({
      uid: item.uid,
      ref_document_id: item.ref_document_id || "",
      item_document_id: item.item_document_id,
      item_name: item.item_name,
      item_description: item.item_description,
      qty: item.qty,
      uom_id: item.uom_id,
      uom: item.uom,
      item_remark: item.item_remark,
    })),
  };
  delete payload.id;
  delete payload.target_status;
  delete payload.render_status;
  delete payload.created_by;

  if (id) {
    delete payload.creator_document_id;
  }

  return payload;
};

export const purchasePayload = (
  headerValues,
  mainValues,
  transformedEmployeeList,
  contactSnapshot,
  summary,
  vendorDescValues,
  documentType,
  id
) => {
  const { reference_document_id, created_date, ...otherHeaderValues } =
    headerValues;

  const payload = {
    ...otherHeaderValues,
    ...mainValues,
    credit_day: headerValues.credit_day
      ? parseInt(headerValues.credit_day)
      : null,
    employee_list: transformedEmployeeList,
    contact_document_id: contactSnapshot.document_id,
    issue_date: moment(headerValues.issue_date).startOf("day").unix(),
    due_date: headerValues.due_date
      ? moment(headerValues.due_date).endOf("day").unix()
      : null,
    shipping_cost: parseFloat(mainValues.shipping_cost),
    additional_discount: parseFloat(
      mainValues.additional_discount === "" ? 0 : mainValues.additional_discount
    ),
    pre_vat_amount: localeStringToFloat(summary.pre_vat_amount),
    vat_exempted_amount: localeStringToFloat(summary.vat_exempted_amount),
    vat_0_amount: localeStringToFloat(summary.vat_0_amount),
    vat_7_amount: localeStringToFloat(summary.vat_7_amount),
    vat_amount: localeStringToFloat(summary.vat_amount),
    net_amount: localeStringToFloat(summary.net_amount),
    withholding_tax_amount: localeStringToFloat(summary.withholding_tax_amount),
    total_amount: localeStringToFloat(summary.total_amount),
    template_remark_id: parseInt(mainValues.template_remark_id),
    item_list: mainValues.item_list.map((item) => ({
      uid: item.uid,
      item_document_id: item.item_document_id,
      item_name: item.item_name,
      item_description: item.item_description,
      qty: item.qty,
      uom_id: item.uom_id,
      uom: item.uom,
      qty_uom: item.qty_uom,
      ref_document_id: item.ref_document_id,
      price_per_unit: parseFloat(item.price_per_unit),
      discount_amount:
        item.discount_amount && item.discount_amount > 0
          ? parseFloat(item.discount_amount)
          : 0,
      pre_vat_amount:
        item.qty * parseFloat(item.price_per_unit) -
        (item.discount_amount && item.discount_amount > 0
          ? parseFloat(item.discount_amount)
          : 0),
      vat_type: item.vat_type,
      withholding_tax:
        item.withholding_tax.type === "ยังไม่ระบุ"
          ? -1
          : item.withholding_tax.type === "ไม่มี"
          ? 0
          : parseFloat(item.withholding_tax.type) / 100,
    })),
  };
  delete payload.id;
  if (documentType === "po") {
    const { billing_address, delivery_address, email, fax, phone } =
      vendorDescValues;
    payload.expect_date = headerValues.expect_date
      ? moment(headerValues.expect_date).endOf("day").unix()
      : null;
    payload.vendor = { billing_address, email, fax, phone };
    payload.delivery_address = delivery_address;
  } else if (documentType === "rs") {
    delete payload.due_date;
    delete payload.shipping_cost;
    payload.delivery_date = headerValues.delivery_date
      ? moment(headerValues.delivery_date).endOf("day").unix()
      : null;
    payload.reference_document_list = reference_document_id;
    payload.vendor = vendorDescValues;
    payload.item_list = mainValues.item_list.map((item) => ({
      uid: item.uid,
      item_document_id: item.item_document_id,
      item_name: item.item_name,
      item_description: item.item_description,
      qty: item.qty,
      qty_return: item.qty_return,
      ref_document_id: item.ref_document_id,
      uom_id: item.uom_id,
      uom: item.uom,
      qty_uom: item.qty_uom,
      price_per_unit: parseFloat(item.price_per_unit),
      discount_amount:
        item.discount_amount && item.discount_amount > 0
          ? parseFloat(item.discount_amount)
          : 0,
      pre_vat_amount:
        item.qty_return * parseFloat(item.price_per_unit) -
        (item.discount_amount && item.discount_amount > 0
          ? parseFloat(item.discount_amount)
          : 0),
      vat_type: item.vat_type,
      withholding_tax:
        item.withholding_tax.type === "ยังไม่ระบุ"
          ? -1
          : item.withholding_tax.type === "ไม่มี"
          ? 0
          : parseFloat(item.withholding_tax.type) / 100,
    }));
  }
  delete payload.target_status;
  delete payload.render_status;
  delete payload.created_by;

  if (id) {
    delete payload.creator_document_id;
  }
  return payload;
};

export const purchaseQueryFormatter = async (purchase, documentType) => {
  const formatPurchase = await {
    ...purchase,
    template_remark_id:
      purchase.template_remark_id === null ? "" : purchase.template_remark_id,
    credit_day: purchase.credit_day === null ? "" : purchase.credit_day,
    created_date: unixToDate(purchase.created_date),
    issue_date: unixToDate(purchase.issue_date),
    due_date: purchase.due_date ? unixToDate(purchase.due_date) : null,
    delivery_date: purchase.delivery_date
      ? unixToDate(purchase.delivery_date)
      : null,
  };
  if (purchase.render_status === "PENDING") {
    formatPurchase.render_status = "waitApprove";
  } else if (purchase.render_status === "DECLINED") {
    formatPurchase.render_status = "notApproved";
  } else if (purchase.render_status === "APPROVED") {
    formatPurchase.render_status = "approved";
  }
  if (documentType === "purchase_order") {
    formatPurchase.expect_date = formatPurchase.expect_date
      ? unixToDate(purchase.expect_date)
      : null;
  }

  const allItemsDocumentId = formatPurchase.item_list.map(
    (item) => item.item_document_id
  );

  const items = await InventoryService.getAllItems({
    findManyInput: {
      where: {
        document_id: {
          in: allItemsDocumentId,
        },
      },
    },
  });

  const formatItemList = [];
  formatPurchase.item_list.forEach((item) => {
    const foundItemIndex = items.findIndex(
      (realItem) => realItem.document_id === item.item_document_id
    );
    formatItemList.push({
      ...item,
      uom_id: parseInt(item.uom_id),
      withholding_tax: {
        type:
          item.withholding_tax === -1
            ? "ยังไม่ระบุ"
            : item.withholding_tax === 0
            ? "ไม่มี"
            : (item.withholding_tax * 100).toString(),
        amount:
          item.withholding_tax === -1
            ? 0
            : item.pre_vat_amount * item.withholding_tax,
      },
      uom_group: items[foundItemIndex]?.uom_group,
      current_ordered_purchase_qty:
        items[foundItemIndex]?.current_ordered_purchase_qty,
      current_ordered_manufacture_qty:
        items[foundItemIndex]?.current_ordered_manufacture_qty,
      current_committed_sales_qty:
        items[foundItemIndex]?.current_committed_sales_qty,
      current_committed_manufacture_qty:
        items[foundItemIndex]?.current_committed_manufacture_qty,
      current_stock_qty: items[foundItemIndex]?.current_stock_qty,
      current_available_qty: items[foundItemIndex]?.current_available_qty,
      is_active: items[foundItemIndex]?.is_active,
    });
  });

  formatPurchase.item_list = formatItemList;

  const documentRelations = await GlobalService.getDocumentRelations(
    purchase.document_id
  );
  formatPurchase.reference_document_id = documentRelations.map((document) => ({
    reference_document_type: document.reference_document_type,
    reference_document_id: document.reference_document_id,
  }));

  return formatPurchase;
};

export const setPurchaseValueFormatter = (
  headerSchema,
  vendorSchema,
  mainSchema,
  setHeaderValue,
  setVendorDescValue,
  setValue,
  value,
  isCreateOrCopy,
  documentType
) => {
  Object.entries(headerSchema).forEach(([key]) => {
    if (
      (isCreateOrCopy && key === "document_id") ||
      (isCreateOrCopy && key === "created_date") ||
      (isCreateOrCopy && key === "issue_date") ||
      (isCreateOrCopy && key === "due_date") ||
      (isCreateOrCopy &&
        documentType === "purchase_order" &&
        key === "expect_date")
    ) {
      return;
    } else {
      if (key === "employee_list") {
        if (value["employee_list"]) {
          setHeaderValue(key, value["employee_list"]);
        } else {
          return;
        }
      }
      if (
        key === "reference_document_id" ||
        key === "reference_document_list"
      ) {
        if (isCreateOrCopy) {
          if (!value.isCopied) {
            setHeaderValue(key, [
              {
                reference_document_type: value["reference_document_type"],
                reference_document_id: value["document_id"],
              },
            ]);
          } else {
            if (documentType === "purchase_return")
              setHeaderValue(key, value["reference_document_id"]);
            else setHeaderValue(key, value["reference_document_list"]);
          }
        } else {
          if (documentType === "purchase_return")
            setHeaderValue(key, value["reference_document_id"]);
          else setHeaderValue(key, value["reference_document_list"]);
        }
      } else {
        setHeaderValue(key, value[key]);
      }
    }
  });

  Object.entries(vendorSchema).forEach(([key]) => {
    if (key === "vendor" || key === "identity_no") {
      return;
    } else {
      if (documentType === "purchase_order") {
        if (key === "delivery_address") {
          setVendorDescValue(key, value[key]);
        } else {
          if (value["vendor"]) setVendorDescValue(key, value["vendor"][key]);
        }
      } else if (documentType === "purchase_request") {
        setVendorDescValue(key, value[key]);
      } else {
        setVendorDescValue(key, value["vendor"][key]);
      }
    }
  });
  Object.entries(mainSchema).forEach(([key]) => {
    if (
      key === "summary" ||
      (isCreateOrCopy && key === "render_status") ||
      (isCreateOrCopy && key === "created_by") ||
      (isCreateOrCopy && key === "creator_document_id")
      // (!value.isCopied && key === "template_remark_id")
    ) {
      return;
    } else {
      setValue(key, value[key]);
    }
  });
};

// export const setPurchaseRequestFormatToPurchaseOrder = (
//   headerSchema,
//   vendorSchema,
//   mainSchema,
//   setHeaderValue,
//   setVendorDescValue,
//   setValue,
//   state
// ) => {
//   Object.entries(headerSchema).forEach(([key, value]) => {
//     if (
//       key === "document_id" ||
//       key === "created_date" ||
//       key === "issue_date" ||
//       key === "expect_date" ||
//       key === "due_date"
//     ) {
//       return;
//     }
//     if (key === "employee_list") {
//       if (state["employee_list"]) {
//         setHeaderValue(key, state["employee_list"]);
//       } else return;
//     }
//     if (key === "reference_document_list") {
//       setHeaderValue(key, [
//         {
//           reference_document_type: state["reference_document_type"],
//           reference_document_id: state["document_id"],
//         },
//       ]);
//     } else {
//       setHeaderValue(key, state[key]);
//     }
//   });

//   Object.entries(vendorSchema).forEach(([key]) => {
//     if (key === "vendor" || key === "identity_no") {
//       return;
//     } else {
//       setVendorDescValue(key, state[key]);
//     }
//   });
//   Object.entries(mainSchema).forEach(([key]) => {
//     setValue(key, state[key]);
//   });
// };

export const purchaseExportFormatter = (payload, documentType) => {
  let formattedPayload = [];
  payload.forEach((document) => {
    const {
      price_vat_type,
      additional_discount,
      additional_discount_type,
      pre_vat_amount,
    } = document;
    const actualAdditionalDiscount =
      additional_discount_type === "percent"
        ? pre_vat_amount * (additional_discount / 100)
        : additional_discount;
    const sum_pre_vat_amount_exclude_discount = document.item_list.reduce(
      (prev, item) => {
        const pre_vat_amount_exclude_discount =
          documentType === "purchase_return"
            ? prev + item.qty_return * item.price_per_unit
            : prev + item.qty * item.price_per_unit;

        return pre_vat_amount_exclude_discount;
      },
      0
    );

    document.item_list.forEach((item) => {
      const pre_vat_amount_exclude_discount =
        documentType === "purchase_return"
          ? item.qty_return * item.price_per_unit
          : item.qty * item.price_per_unit;

      const item_additional_discount =
        actualAdditionalDiscount *
        (pre_vat_amount_exclude_discount / sum_pre_vat_amount_exclude_discount);

      const pre_vat_amount_include_discount =
        documentType === "purchase_return"
          ? item.qty * item.price_per_unit -
            (item.discount_amount + item_additional_discount)
          : item.qty * item.price_per_unit -
            (item.discount_amount + item_additional_discount);

      const item_vat_exempted_amount =
        item.vat_type === "ไม่มี" ? pre_vat_amount_include_discount : 0;

      const item_vat_0_amount =
        item.vat_type === "0" ? pre_vat_amount_include_discount : 0;

      const item_vat_7_amount =
        item.vat_type === "7" && price_vat_type === "included_vat"
          ? pre_vat_amount_include_discount / 1.07
          : item.vat_type === "7" && price_vat_type === "excluded_vat"
          ? pre_vat_amount_include_discount
          : 0;

      const item_vat_amount = item_vat_7_amount * 0.07;

      const item_withholding_tax_type =
        item.withholding_tax === -1
          ? "ยังไม่ระบุ"
          : item.withholding_tax === 0
          ? "ไม่มี"
          : (item.withholding_tax * 100).toString();

      const item_net_amount =
        item_vat_exempted_amount +
        item_vat_0_amount +
        item_vat_7_amount +
        item_vat_amount;

      let item_withholding_tax;

      if (price_vat_type === "excluded_vat") {
        item_withholding_tax =
          pre_vat_amount_include_discount *
          (item.withholding_tax === -1 ? 0 : item.withholding_tax);
      } else {
        if (item.vat_type === "7") {
          item_withholding_tax =
            (pre_vat_amount_include_discount / 1.07) *
            (item.withholding_tax === -1 ? 0 : item.withholding_tax);
        } else {
          item_withholding_tax =
            pre_vat_amount_include_discount *
            (item.withholding_tax === -1 ? 0 : item.withholding_tax);
        }
      }

      const item_total_amount = item_net_amount - item_withholding_tax;

      let formatDocument;
      if (documentType === "purchase_request") {
        formatDocument = {
          document_id: document.document_id,
          issue_date: unixToDateWithFormat(document.issue_date),
          due_date: unixToDateWithFormat(document.due_date),
          render_status: filterStatusValueFormatter(document.render_status),
          requester_document_id: document.requester_document_id,
          requester_name: document.requester_name,
          requester_position: document.requester_position,
          requester_department: document.requester_department,
          item_document_id: item.item_document_id,
          item_name: item.item_name,
          qty: item.qty,
          uom: item.uom.name,
          qty_uom: item.qty_uom,
          external_ref_id: document.external_ref_id,
          accepted_date: document.accepted_date
            ? unixToDateWithFormat(document.accepted_date)
            : null,
          item_description: item.item_description,
          item_remark: item.item_remark,
          created_date: unixToDateTimeWithFormat(document.created_date),
          updated_date: unixToDateWithFormat(document.updated_date),
          created_by:
            document.created_by.first_name +
            " " +
            document.created_by.last_name,
          employee_list: document?.employee_list
            .map((employee) => employee?.first_name + " " + employee?.last_name)
            .join(","),
        };
      } else if (documentType === "purchase_order") {
        formatDocument = {
          document_id: document.document_id,
          issue_date: unixToDateWithFormat(document.issue_date),
          due_date: unixToDateWithFormat(document.due_date),
          gr_document_id_list: document.gr_document_id_list,
          render_status: filterStatusValueFormatter(document.render_status),
          contact_document_id: document.contact_document_id,
          contact_name: document.contact.contact_name_1,
          price_vat_type:
            price_vat_type === "included_vat" ? "ราคารวมภาษี" : "ราคาแยกภาษี",
          item_document_id: item.item_document_id,
          item_name: item.item_name,
          qty: item.qty,
          item_returned_qty: item.returned_qty,
          uom: item.uom.name,
          price_per_unit: item.price_per_unit,
          vat_type: item.vat_type,
          pre_vat_amount_exclude_discount,
          item_discount: item.discount_amount,
          item_additional_discount,
          pre_vat_amount_include_discount,
          item_vat_exempted_amount,
          item_vat_0_amount,
          item_vat_7_amount,
          item_vat_amount,
          item_net_amount,
          item_withholding_tax_type,
          item_withholding_tax,
          item_total_amount,
          shipping_cost: document.shipping_cost,
          total_amount: document.total_amount,
          additional_discount: actualAdditionalDiscount,
          credit_day: document.credit_day,
          remark: document.remark,
          qty_uom: item.qty_uom,
          item_pr_document_id: item.ref_document_id,
          item_received_qty: item.received_qty,
          warehouse_document_id: document.destination_warehouse_document_id,
          reference_document_list: document.reference_document_list.map(
            (ref) => ref.reference_document_id
          ),
          external_ref_id: document.external_ref_id,
          expect_date: unixToDateWithFormat(document.expect_date),
          item_description: item.item_description,
          item_remark: item.item_remark,
          width_length_tolerance_positive: item.width_length_tolerance_positive,
          width_length_tolerance_negative: item.width_length_tolerance_negative,
          thickness_tolerance_positive: item.thickness_tolerance_positive,
          thickness_tolerance_negative: item.thickness_tolerance_negative,
          created_date: unixToDateTimeWithFormat(document.created_date),
          updated_date: unixToDateWithFormat(document.updated_date),
          created_by:
            document.created_by.first_name +
            " " +
            document.created_by.last_name,
          employee_list: document?.employee_list
            .map((employee) => employee?.first_name + " " + employee?.last_name)
            .join(","),
          email: document.vendor.email,
          phone: document.vendor.phone,
          fax: document.vendor.fax,
          billing_address: document.vendor.billing_address.address,
          billing_sub_district: document.vendor.billing_address.sub_district,
          billing_district: document.vendor.billing_address.district,
          billing_province: document.vendor.billing_address.province,
          billing_postal_code: document.vendor.billing_address.postal_code,
          billing_country: document.vendor.billing_address.country,
          is_same_as_default_address:
            document.delivery_address.is_same_as_default_address,
          delivery_address_contact_name:
            document.delivery_address.address_contact_name,
          delivery_address_contact_phone:
            document.delivery_address.address_contact_phone,
          delivery_address: document.delivery_address.address,
          delivery_sub_district: document.delivery_address.sub_district,
          delivery_district: document.delivery_address.district,
          delivery_province: document.delivery_address.province,
          delivery_postal_code: document.delivery_address.postal_code,
          delivery_country: document.delivery_address.country,
        };
      } else {
        formatDocument = {
          document_id: document.document_id,
          issue_date: unixToDateWithFormat(document.issue_date),
          delivery_date: unixToDateWithFormat(document.delivery_date),
          render_status: filterStatusValueFormatter(document.render_status),
          gr_document_id_list: document.gr_document_id_list,
          contact_document_id: document.contact_document_id,
          contact_name: document.contact.contact_name_1,
          price_vat_type:
            price_vat_type === "included_vat" ? "ราคารวมภาษี" : "ราคาแยกภาษี",
          item_document_id: item.item_document_id,
          item_name: item.item_name,
          qty: item.qty,
          uom: item.uom.name,
          qty_return: item.qty_return,
          price_per_unit: item.price_per_unit,
          vat_type: item.vat_type,
          pre_vat_amount_exclude_discount,
          item_discount: item.discount_amount,
          item_additional_discount,
          pre_vat_amount_include_discount,
          item_vat_exempted_amount:
            item.vat_type === "ไม่มี" ? pre_vat_amount_include_discount : 0,
          item_vat_0_amount:
            item.vat_type === "0" ? pre_vat_amount_include_discount : 0,
          item_vat_7_amount,
          item_vat_amount,
          item_net_amount,
          item_withholding_tax_type,
          item_withholding_tax,
          item_total_amount,
          total_amount: document.total_amount, //Recheck again
          additional_discount: actualAdditionalDiscount,
          credit_day: document.credit_day,
          remark: document.remark,
          qty_uom: item.qty_uom,
          reference_document_list: document.reference_document_list.map(
            (ref) => ref.reference_document_id
          ),
          external_ref_id: document.external_ref_id,
          reason_to_return: document.reason_to_return,
          item_description: item.item_description,
          item_remark: item.item_remark,
          created_date: unixToDateTimeWithFormat(document.created_date),
          updated_date: unixToDateWithFormat(document.updated_date),
          created_by:
            document.created_by.first_name +
            " " +
            document.created_by.last_name,
          employee_list: document.employee_list
            .map((employee) => employee?.first_name + " " + employee?.last_name)
            .join(","),
          email: document.vendor.email,
          phone: document.vendor.phone,
          fax: document.vendor.fax,
          billing_address: document.vendor.billing_address.address,
          billing_sub_district: document.vendor.billing_address.sub_district,
          billing_district: document.vendor.billing_address.district,
          billing_province: document.vendor.billing_address.province,
          billing_postal_code: document.vendor.billing_address.postal_code,
          billing_country: document.vendor.billing_address.country,
          is_same_as_default_address:
            document.vendor.delivery_address?.is_same_as_default_address,
          delivery_address_contact_name:
            document.vendor.delivery_address?.address_contact_name,
          delivery_address_contact_phone:
            document.vendor.delivery_address?.address_contact_phone,
          delivery_address: document.vendor?.delivery_address?.address,
          delivery_sub_district:
            document.vendor?.delivery_address?.sub_district,
          delivery_district: document.vendor?.delivery_address?.district,
          delivery_province: document.vendor?.delivery_address?.province,
          delivery_postal_code: document.vendor?.delivery_address?.postal_code,
          delivery_country: document.vendor?.delivery_address?.country,
        };
      }
      formattedPayload.push(formatDocument);
    });
  });
  return formattedPayload;
};

export const purchaseRequestExportFormatter = (payload) => {
  let formattedPayload = [];
  payload.forEach((document) => {
    document.item_list.forEach((item) => {
      let formatDocument;
      formatDocument = {
        document_id: document.document_id,
        issue_date: unixToDateWithFormat(document.issue_date),
        due_date: unixToDateWithFormat(document.due_date),
        render_status: filterStatusValueFormatter(document.render_status),
        requester_document_id: document.requester_document_id,
        requester_name: document.requester_name,
        requester_position: document.requester_position,
        requester_department: document.requester_department,
        item_document_id: item.item_document_id,
        item_name: item.item_name,
        qty: item.qty,
        uom: item.uom.name,
        qty_uom: item.qty_uom,
        ordered_qty: item.ordered_qty ?? 0,
        pending_qty: item.qty - item.ordered_qty,
        external_ref_id: document.external_ref_id,
        accepted_date: document.accepted_date
          ? unixToDateWithFormat(document.accepted_date)
          : null,
        item_description: item.item_description,
        item_remark: item.item_remark,
        created_date: unixToDateWithFormat(document.created_date),
        updated_date: unixToDateWithFormat(document.updated_date),
        destination_warehouse_name: document.destination_warehouse?.thai_name,
        reference_document_id: document.reference_document_list.map(
          (list) => list.reference_document_id
        ),
        created_by:
          document.created_by.first_name + " " + document.created_by.last_name,
        employee_list:
          document &&
          document.employee_list &&
          document.employee_list.map(
            (employee) => employee?.first_name + " " + employee?.last_name
          ),
      };
      formattedPayload.push(formatDocument);
    });
  });
  return formattedPayload;
};
