import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import AgGrid from "../../../components/Table/AgGrid";
import { bomColumnDef } from "../../../components/Table/ColumnDefs/bomColumnDef";
import { getAllBoms } from "../../../features/Manufacture/Bom/bom-actions";
import { usePermission } from "../../../hooks/use-auth";
import { filterDateWithUnix } from "../../../utils/filterparams";

const Bom = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { createPermission } = usePermission();
  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.bom.index"),
    },
  ];

  const onRowDoubleClicked = (params) => {
    navigate(`/manufacture/bom/${encodeURIComponent(params.data.id)}`);
  };

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { start_date, end_date, ...otherFilterModal } = request.filterModel;

      const formatFilterModel = {
        ...otherFilterModal,
        start_date: start_date && filterDateWithUnix(start_date),
        end_date: end_date && filterDateWithUnix(end_date),
      };
      dispatch(
        getAllBoms(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("manufacture.bom.index")}</Typography>
        {createPermission && (
          <Box>
            <CustomizedButton
              sx={{ mr: 2 }}
              title={`สร้าง${t("manufacture.bom.index")}`}
              variant="contained"
              onClick={() => navigate(`/manufacture/bom/add`)}
            />
            <CustomizedButton
              title={`นำเข้า${t("manufacture.bom.index")}`}
              variant="contained"
              onClick={() => navigate(`/manufacture/bom/importer`)}
            />
          </Box>
        )}
      </Box>
      <AgGrid
        ref={gridRef}
        columnDefs={bomColumnDef(t, false)}
        height={680}
        onGridReady={onGridReady}
        onRowDoubleClicked={onRowDoubleClicked}
        path={pathname}
      />
    </>
  );
};

export default Bom;
