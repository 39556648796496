import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./deposit-invoice-initial";

const depositInvoiceSlice = createSlice({
  name: "depositInvoice",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllDepositInvoices(state, action) {
      state.allDepositInvoices = action.payload;
      state.isLoading.allDepositInvoices = false;
    },
    loadedDepositInvoice(state, action) {
      state.depositInvoice = action.payload;
      state.isLoading.depositInvoice = false;
    },
    // loadedApprovalList(state, action) {
    //   const { approvalProgress } = action.payload;
    //   const sortedApprovalProgress =
    //     approvalProgress &&
    //     approvalProgress.length > 0 &&
    //     approvalProgress.sort((a, b) => a.created_date - b.created_date);

    //   state.approvalList = sortedApprovalProgress;
    // },
    updateDepositInvoiceStatus(state, action) {
      state.depositInvoice.render_status = action.payload;
      state.isLoading.depositInvoice = false;
    },
    resetAllDepositInvoices(state) {
      state.allDepositInvoices = initialState.allDepositInvoices;
      state.error = initialState.error;
    },
    resetDepositInvoice(state) {
      state.depositInvoice = initialState.depositInvoice;
      state.error = initialState.error;
    },
    resetDepositInvoiceError(state) {
      state.error = initialState.error;
    },
  },
});

export const depositInvoiceActions = depositInvoiceSlice.actions;

export default depositInvoiceSlice.reducer;
