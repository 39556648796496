import { Box, DialogContentText } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";

const AcceptConfirmation = ({
  openAcceptConfirmation,
  acceptConfirmationAction,
  closeAcceptConfirmationHandler,
}) => {
  return (
    <ModalUI
      title="ต้องการยืนยันใบสั่งผลิตใช่หรือไม่"
      open={openAcceptConfirmation}
      handleClose={closeAcceptConfirmationHandler}
      maxWidth="sm"
    >
      <DialogContentText>
        เมื่อกด "ยืนยัน" แล้ว สถานะใบสั่งผลิตจะถูกเปลี่ยนเป็น "รอผลิต"
        และไม่สามารถแก้ไขรายละเอียดสินค้าและสูตรการผลิตได้
      </DialogContentText>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeAcceptConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={acceptConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default AcceptConfirmation;
