import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { Controller, useForm, useWatch } from "react-hook-form";
import ControlledServerSideCreatable from "../../Controlled/ControlledSSCreatable";
import CheckedBomModal from "../../Table/CheckedBomModal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import ControlledTextField from "../../Controlled/ControlledTextField";
import ModalUI from "../../UI/ModalUI";
import BomDetailForm from "../Manufacture/Bom/Detail";
import BomTableForm from "../Manufacture/Bom/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  bomInitialState,
  bomValidation,
} from "../../../features/Manufacture/Bom/bom-initial";
import { unixToDate } from "../../../utils/date-converter";
import { yupResolver } from "@hookform/resolvers/yup";
import { getBomById } from "../../../features/Manufacture/Bom/bom-actions";
import { useSnackbar } from "notistack";
import { bomActions } from "../../../features/Manufacture/Bom/bom-slice";

export default function Manufacture({
  control,
  errors,
  setValue,
  viewOnly,
  isInventoryPage,
}) {
  // const StyledLink = styled(Link)(({ theme }) => ({
  //   color: theme.palette.primary.main,
  //   textDecoration: "none",
  //   cursor: "pointer",
  // }));
  const { t } = useTranslation();
  const { bom } = useSelector((state) => state.bom);
  const [showSelectBom, setShowSelectBom] = useState(false);
  const [bomIds, setBomIds] = useState([]);
  const [bomIdsSnapshot, setBomIdsSnapshot] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const {
    control: bomControl,
    formState: { errors: bomErrors },
    reset: bomReset,
  } = useForm({
    defaultValues: {
      ...bomInitialState.bom,
      created_date: unixToDate(bomInitialState.bom.created_date),
      start_date: unixToDate(bomInitialState.bom.start_date),
      end_date: unixToDate(bomInitialState.bom.end_date),
    },
    resolver: yupResolver(bomValidation),
  });

  const watchItemId = useWatch({ control, name: "document_id" });
  const watchBomId = useWatch({ control, name: "main_bom.id" });
  const watchBomName = useWatch({ control, name: "main_bom.name" });
  const watchMinimunStockQty = useWatch({
    control,
    name: "manufacture_minimum_qty",
  });

  useEffect(() => {
    if (watchBomId) {
      bomReset({
        ...bom,
        created_date: unixToDate(bom.created_date),
        start_date: unixToDate(bom.start_date),
        end_date: unixToDate(bom.end_date),
      });
    }
  }, [bom, bomReset, watchBomId]);

  const [showBomForm, setShowBomForm] = useState(false);

  const openBomForm = () => {
    setShowBomForm(true);
    dispatch(getBomById(watchBomId, enqueueSnackbar));
  };

  const closeBomForm = () => {
    setShowBomForm(false);
    dispatch(bomActions.resetBom());
  };

  const openBomTable = () => {
    setShowSelectBom(true);
  };

  const closeBomTable = () => {
    setShowSelectBom(false);
  };

  const finishBomSelectHandler = async (data) => {
    if (data) {
      const { id, name } = data;
      setValue("main_bom", { id, name });
    } else {
      setValue("main_bom", { id: "", name: "" });
    }
    closeBomTable();
  };

  useEffect(() => {
    if (watchBomName) {
      setBomIds([watchBomName]);
      setBomIdsSnapshot([watchBomName]);
    }
  }, [watchBomName]);

  return (
    <Box>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            title={t("inventory.items.manufactureType")}
            control={control}
            name="manufacture_type"
            dataName="manufacture_type"
            documentType="item"
            defaultOptions={[]}
            error={Boolean(errors.manufacture_type)}
            helperText={errors.manufacture_type?.message}
            setValue={setValue}
            viewOnly={!isInventoryPage || viewOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            error={errors}
            name={"manufacture_minimum_qty"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                type="number"
                testId="item-menu-minimun-qty-text-field"
                label={t("inventory.items.manufactureMinimum")}
                disabled={!isInventoryPage || viewOnly}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box my={2} ml={1} sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography fontWeight="bold">
          {t("inventory.items.formula")}
        </Typography>
        {isInventoryPage && !viewOnly && (
          <CustomizedTooltip title="เลือกสูตรผลิต">
            <IconButton
              onClick={openBomTable}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name={"main_bom.name"}
            label={t("inventory.items.formula")}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isInventoryPage && watchBomId && (
                    <IconButton
                      onClick={openBomForm}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <LaunchOutlinedIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {/* <Controller
            control={control}
            error={errors}
            name={"main_bom.name"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.items.formula")}
                disabled
              />
            )}
          /> */}
        </Grid>
      </Grid>
      <CheckedBomModal
        showSelectBom={showSelectBom}
        closeBomTable={closeBomTable}
        finishBomSelectHandler={finishBomSelectHandler}
        watchItemId={watchItemId}
        bomIds={bomIds}
        setBomIds={setBomIds}
        bomIdsSnapshot={bomIdsSnapshot}
        setBomIdsSnapshot={setBomIdsSnapshot}
      />
      <ModalUI
        open={showBomForm}
        navigateTo={`/manufacture/bom/${watchBomId}`}
        handleClose={closeBomForm}
        fullWidth
        title={watchBomName ?? ""}
        maxWidth="lg"
      >
        <BomDetailForm
          control={bomControl}
          errors={bomErrors}
          reset={bomReset}
          disabled={true}
          minimumStockQty={watchMinimunStockQty}
          isItemMasterPage
        />
        <Divider />
        <BomTableForm control={bomControl} errors={bomErrors} disabled={true} />
      </ModalUI>
      {/* <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.accounting")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            error={errors}
            name={""}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.items.manufactureAccount")}
                disabled={true}
              />
            )}
          />
        </Grid>
      </Grid> */}
    </Box>
  );
}
