import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedDateTime from "../../../Custom/CustomizedDateTime";
import { stringAvatar } from "../../../Custom/CustomizedLetterAvatar";
import GoodsDeliverTable from "../../../Table/DocumentTable/GoodsDeliverTable";
import VehicleDTTable from "../../../Table/DocumentTable/VehicleDTTable";
import { useLocation, useParams } from "react-router-dom";
import ControlledServerSideCreatable from "../../../Custom/ControlledSSCreatable";
import CustomizedChips from "../../../Custom/CustomizedChips";

const deliveryType = [
  { id: 1, label: "จัดส่งเอง", value: "จัดส่งเอง" },
  { id: 2, label: "ลูกค้ามารับ", value: "ลูกค้ามารับ" },
];

const DeliveryPlan = ({
  control,
  viewOnly,
  errors,
  getValues,
  setValue,
  currentRowData,
  setMainValue,
}) => {
  const { t } = useTranslation();
  const [showSelectVehicle, setShowSelectVehicle] = useState(false);
  const [showSelectEmployees, setShowSelectEmployees] = useState(false);
  const { state } = useLocation();
  const { id } = useParams();
  const { fields, remove } = useFieldArray({
    control,
    name: "goods_deliver_list",
  });
  const [employeeIds, setEmployeeIds] = useState([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState([]);

  const [vehicleIds, setVehicleIds] = useState([]);
  const [vehicleIdsSnapshot, setVehicleIdsSnapshot] = useState([]);

  const vehicle = getValues("vehicle");

  useEffect(() => {
    if (state || id) {
      const goodsDeliverIds = fields.map((goods) => goods.document_id);
      setEmployeeIds([...goodsDeliverIds]);
      setEmployeeIdsSnapshot([...goodsDeliverIds]);
      if (vehicle) {
        setVehicleIds([vehicle.license_plate]);
        setVehicleIdsSnapshot([vehicle.license_plate]);
      }
    }
  }, [fields, id, state, vehicle]);

  return (
    <>
      <CustomizedBox margin={0}>
        <Typography sx={{ fontWeight: "bold", mb: 2 }}>
          {t("logistic.deliveryTrip.deliveryPlan")}
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <ControlledServerSideCreatable
              control={control}
              name="delivery_type"
              documentType="delivery_trip"
              defaultOptions={deliveryType}
              error={Boolean(errors.delivery_type)}
              helperText={errors.delivery_type?.message}
              setValue={setValue}
              viewOnly={viewOnly}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
          <Grid item xs={6} sm={6} md={4} lg={1} xl={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {t("logistic.vehicleId")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={11} xl={11}>
            <Box display="flex" gap={1}>
              {vehicle && (
                <Box
                  sx={{
                    border: `1px solid #d6cfcf`,
                    borderRadius: "16px",
                    cursor: "pointer",
                    alignSelf: "center",
                    padding: "8px",
                  }}
                  onClick={() =>
                    window.open(
                      `/setting/logistic/vehicle/${vehicle.id}`,
                      "_blank"
                    )
                  }
                >
                  <Typography>{vehicle.license_plate}</Typography>
                </Box>
              )}
              {!viewOnly && (
                <IconButton onClick={() => setShowSelectVehicle(true)}>
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
          <Grid item xs={12} sm={12} md={4} lg={1} xl={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              {t("logistic.goodsDeliver")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={11} xl={11}>
            <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
              {fields.map((employee, index) => (
                <CustomizedChips
                  color="default"
                  key={employee.document_id}
                  onDelete={
                    viewOnly
                      ? null
                      : () => {
                          remove(index);
                          setEmployeeIds((prevIds) => [
                            ...prevIds.slice(0, index),
                            ...prevIds.slice(index + 1),
                          ]);
                          setEmployeeIdsSnapshot((prevIds) => [
                            ...prevIds.slice(0, index),
                            ...prevIds.slice(index + 1),
                          ]);
                        }
                  }
                  avatar={
                    employee && employee.img_url ? (
                      <Avatar
                        key={employee.document_id}
                        alt={employee.document_id}
                        src={employee.img_url}
                      />
                    ) : (
                      <Avatar
                        {...stringAvatar(
                          employee.first_name + " " + employee.last_name,
                          {
                            "&.MuiChip-avatar": {
                              color: "#FFFFFF !important",
                            },
                          }
                        )}
                        key={employee.document_id}
                      />
                    )
                  }
                  label={employee.first_name + " " + employee.last_name}
                  variant="outlined"
                />
              ))}
              {!viewOnly && (
                <IconButton onClick={() => setShowSelectEmployees(true)}>
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
        <Typography sx={{ fontWeight: "bold", mb: 2 }}>
          {t("logistic.deliverTime")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Controller
              name="trip"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  label="รอบ"
                  error={Boolean(errors.trip)}
                  helperText={errors.trip?.message}
                  type="number"
                  disabled={viewOnly}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Controller
              name="in_date"
              control={control}
              render={({ field }) => (
                <CustomizedDateTime
                  label="เวลาเข้า"
                  error={Boolean(errors.in_date)}
                  helperText={errors.in_date?.message}
                  disabled={viewOnly}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Controller
              name="out_date"
              control={control}
              render={({ field }) => (
                <CustomizedDateTime
                  label="เวลาออก"
                  error={Boolean(errors.out_date)}
                  helperText={errors.out_date?.message}
                  disabled={viewOnly}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <GoodsDeliverTable
        fields={fields}
        setValue={setValue}
        showSelectEmployees={showSelectEmployees}
        setShowSelectEmployees={setShowSelectEmployees}
        employeeIds={employeeIds}
        setEmployeeIds={setEmployeeIds}
        employeeIdsSnapshot={employeeIdsSnapshot}
        setEmployeeIdsSnapshot={setEmployeeIdsSnapshot}
      />
      <VehicleDTTable
        setValue={setValue}
        showSelectVehicle={showSelectVehicle}
        setShowSelectVehicle={setShowSelectVehicle}
        setVehicleIds={setVehicleIds}
        setVehicleIdsSnapshot={setVehicleIdsSnapshot}
        vehicleIds={vehicleIds}
        vehicleIdsSnapshot={vehicleIdsSnapshot}
        currentRowData={currentRowData}
        setMainValue={setMainValue}
      />
    </>
  );
};

export default DeliveryPlan;
