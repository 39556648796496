import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import RightDrawer from "../../../../UI/RightDrawer";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CustomizedMenuOption from "../../../../Custom/CustomizedMenuOption";
import ControlledDatePicker from "../../../../Custom/ControlledDatePicker";
import ControlledTextField from "../../../../Controlled/ControlledTextField";
import GlobalService from "../../../../../services/Global";
import { useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import CustomizedStatus from "../../../../Custom/CustomizedStatus";
import {
  cancelManufacture,
  updateManufactureOrderStep,
} from "../../../../../features/Manufacture/ManufactureOrder/manufacture-order-actions";
import { useSnackbar } from "notistack";
import Employee from "./Employee";
import { useModifyOptions } from "../../../../../hooks/ManufactureOrder/use-modify-options";
import { useCreateOptions } from "../../../../../hooks/ManufactureOrder/use-create-options";
import { useStatusOptions } from "../../../../../hooks/ManufactureOrder/use-status-options";
import { usePrintOptions } from "../../../../../hooks/ManufactureOrder/use-print-options";
import CustomizedChips from "../../../../Custom/CustomizedChips";
import { useAuth, usePermission } from "../../../../../hooks/use-auth";
import CancelConfirmation from "../../../../UI/Confirmation/CancelConfirmation";
import ChangeStatusConfirmation from "../../../../UI/Confirmation/ChangeStatusConfirmation";
import CopyConfirmation from "../../../../UI/Confirmation/CopyConfirmation";
import { mapStatusToThai } from "../../../../../utils/date-converter";
import { userPermission } from "../../../../../utils/userInfo";

const printOptions = ["พิมพ์ใบสั่งผลิต"];

const mapOption = (option) => {
  switch (option) {
    case "in_progress":
    case "inProgress":
      return "กำลังผลิต";
    default:
      break;
  }
};

const ManufactureOrderHeaderForm = ({
  control,
  errors,
  disabled,
  setValue,
  getValues,
  currentState,
  editClickHandler,
  copyManufactureHandler,
  isEdit,
}) => {
  const { user, permissions } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currStatus, setCurrStatus] = useState(null);

  const { editPermission } = usePermission();

  const [disabledItemQty, setDisableditemQty] = useState({
    goods_receive: false,
    goods_issue: false,
  });

  const selectCreateOptions = [
    {
      value: "ใบนำออก",
      disabled:
        userPermission(permissions, "inventory", "issue", "create") ||
        disabledItemQty.goods_issue,
    },
    {
      value: "ใบนำเข้า",
      disabled:
        userPermission(permissions, "inventory", "receive", "create") ||
        disabledItemQty.goods_receive,
    },
  ];

  const selectStatusOptions = [
    {
      value: "กำลังผลิต",
      disabled: !editPermission || currStatus === "in_progress",
    },
    {
      value: "เสร็จสิ้น",
      disabled: !editPermission,
    },
  ];

  const watchStatus = useWatch({
    control,
    name: "render_status",
  });

  const watchReferenceDocumentId = useWatch({
    control,
    name: "reference_document_list",
  });

  useEffect(() => {
    switch (watchStatus) {
      case "pendingManu":
        setCurrStatus("pending_manu");
        break;
      case "inProgress":
        setCurrStatus("in_progress");
        break;
      default:
        setCurrStatus(watchStatus);
    }
  }, [watchStatus]);

  const selectModifyOptions = useModifyOptions(watchStatus);
  const disabledCreateOptions = useCreateOptions(watchStatus);
  const disabledStatusOptions = useStatusOptions(watchStatus);
  const disabledPrintOptions = usePrintOptions(watchStatus);

  const [openDrawer, setOpenDrawer] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [createdDateIsOpen, setCreatedDateIsOpen] = useState(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState(false);
  const [expectDateIsOpen, setExpectDateIsOpen] = useState(false);
  const [expectProductDateIsOpen, setExpectProductDateIsOpen] = useState(false);
  const [deliveryDateIsOpen, setDeliveryDateIsOpen] = useState(false);

  const changeToInProgressHandler = () => {
    dispatch(
      updateManufactureOrderStep(id, 3, currStatus, user, enqueueSnackbar)
    );
  };

  const changeToFinishedHandler = () => {
    dispatch(
      updateManufactureOrderStep(id, 4, currStatus, user, enqueueSnackbar)
    );
    closeConfirmationHandler();
  };

  const cancelHandler = () => {
    const payload = getValues();
    dispatch(cancelManufacture(id, currStatus, user, enqueueSnackbar, payload));
    closeConfirmationHandler();
  };

  const generateDocumentId = useCallback(async () => {
    if (disabled) {
      return;
    }
    try {
      setValue("document_id", "");
      const document_id = await GlobalService.getRunningDocumentId(
        "manufacture_order"
      );
      setValue("document_id", document_id);
    } catch (err) {
      console.error(err);
    }
  }, [setValue, disabled]);

  useEffect(() => {
    if (pathname.split("/").at(-1) === "add") {
      generateDocumentId();
    }
  }, [pathname, generateDocumentId]);

  const [modal, setModal] = useState({
    value: false,
    currStatus: "",
    nextStatus: "",
  });
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [copyConfirmation, setCopyConfirmation] = useState(false);

  const closeCancelConfirmationHandler = () => {
    setCancelConfirmation(false);
  };

  const cancelConfirmationAction = () => {
    closeCancelConfirmationHandler();
    cancelHandler();
  };

  const closeCopyConfirmationHandler = () => {
    setCopyConfirmation(false);
  };

  const copyConfirmationAction = () => {
    closeCopyConfirmationHandler();
    copyManufactureHandler();
    enqueueSnackbar("คัดลอกสำเร็จ", {
      variant: "success",
    });
  };

  const openConfirmationHandler = (nextStatus) => {
    setModal({ value: true, currStatus: currStatus, nextStatus });
  };

  const closeConfirmationHandler = () => {
    setModal((prev) => ({
      ...prev,
      value: false,
    }));
    setTimeout(() => {
      setModal((prev) => ({
        ...prev,
        currStatus: "",
        nextStatus: "",
      }));
    }, 700);
  };

  const confirmationAction = () => {
    if (modal.nextStatus === "in_progress") changeToInProgressHandler();
    else changeToFinishedHandler();
    closeConfirmationHandler();
  };

  const checkConditionStatus = () => {
    const woList = getValues("work_order_list");
    if (!woList || woList === undefined) return false;
    const checkStatusIsFinished = woList.every(
      (wo) => wo.status === "finished"
    );
    if (!checkStatusIsFinished)
      if (modal.nextStatus === "in_progress") return false;
      else return true;
    else return false;
  };

  useEffect(() => {
    if (
      watchStatus === "pendingManu" ||
      watchStatus === "inProgress" ||
      watchStatus === "finished"
    ) {
      const getDisabledOnQty = async () => {
        const totalItemListQty = getValues("ingredient_list").reduce(
          (prev, curr) => prev + (curr.qty - curr.goods_issue_qty),
          0
        );

        const itemListToGoodsIssue = await GlobalService.getDocumentRelationQty(
          {
            document_id: id,
            document_type: "manufacture_order",
            reference_document_type: "goods_issue",
          }
        );
        const totalGoodsIssueQty = itemListToGoodsIssue.reduce(
          (prev, curr) => prev + curr.accumulate_issue,
          0
        );
        if (totalGoodsIssueQty === totalItemListQty) {
          setDisableditemQty((prev) => ({
            ...prev,
            goods_issue: true,
          }));
        }

        const production_qty = getValues("production_qty");
        const goods_receive_qty = getValues("goods_receive_qty");
        if (goods_receive_qty === production_qty) {
          setDisableditemQty((prev) => ({
            ...prev,
            goods_receive: true,
          }));
        }
      };
      getDisabledOnQty();
    }
    return () => {
      setDisableditemQty({
        goods_issue: false,
        goods_receive: false,
      });
    };
  }, [getValues, id, watchStatus]);

  return (
    <>
      <Grid container my={3}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={3}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{t("manufacture.order.index")}</Typography>
            <CustomizedStatus status={watchStatus} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1}>
            <Grid item xs={5.5} sm={5.5} md={3} lg={3} xl={3}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  if (e.target.innerText === "พิมพ์ใบสั่งผลิต") {
                    navigate(
                      `/manufacture/order/${encodeURIComponent(id)}/pdf`
                    );
                  }
                }}
                disabled={!id || disabledPrintOptions}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={3} lg={3} xl={3}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  switch (e.target.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "คัดลอก":
                      setCopyConfirmation(true);
                      break;
                    case "ยกเลิก":
                      setCancelConfirmation(true);
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={2} lg={2} xl={2}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={(e) => {
                  // const { work_order_list, ...otherState } = currentState;
                  switch (e.target.innerText) {
                    case "ใบนำเข้า":
                      navigate("/inventory/receive/add", {
                        state: {
                          ...currentState,
                          isCopied: false,
                          reference_document_type: "manufacture_order",
                        },
                      });
                      break;
                    case "ใบนำออก":
                      navigate("/inventory/issue/add", {
                        state: {
                          ...currentState,
                          isCopied: false,
                          reference_document_type: "manufacture_order",
                        },
                      });
                      break;
                    default:
                  }
                }}
                disabled={disabledCreateOptions}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={3} lg={3} xl={3}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"สถานะการผลิต"}
                options={selectStatusOptions.map(({ value, disabled }) => ({
                  value: value,
                  disabled:
                    disabled ?? value === mapOption(watchStatus) ? true : false,
                }))}
                onSelect={(e) => {
                  switch (e.target.innerText) {
                    case "กำลังผลิต":
                      openConfirmationHandler("in_progress");
                      break;
                    case "เสร็จสิ้น":
                      openConfirmationHandler("finished");
                      break;
                    default:
                  }
                }}
                disabled={disabledStatusOptions || isEdit}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item sx={12} sm={12} md={2} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.document_id")}
              </Typography>
            </Grid>
            <Grid item sx={11} sm={11} md={9} lg={6} xl={6}>
              <ControlledTextField
                name="document_id"
                control={control}
                error={Boolean(errors?.document_id)}
                helperText={errors?.document_id && errors?.document_id.message}
                disabled={id}
              />
            </Grid>
            <Grid item sx={1} sm={1} md={1} lg={3} xl={3}>
              {!id && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateDocumentId}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item sx={12} sm={12} md={2} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.reference_document_list")}
              </Typography>
            </Grid>
            <Grid item sx={11} sm={11} md={10} lg={9} xl={9}>
              {watchReferenceDocumentId && watchReferenceDocumentId.length > 0
                ? watchReferenceDocumentId.map((reference) => (
                    <CustomizedChips
                      onClick={() =>
                        window.open(
                          `/sales/order/${encodeURIComponent(
                            reference.reference_document_id
                          )}`,
                          "_blank"
                        )
                      }
                      value={reference.reference_document_id}
                      color="primary"
                    />
                  ))
                : "-"}
            </Grid>
            <Grid item sx={12} sm={12} md={2} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.external_ref_id")}
              </Typography>
            </Grid>
            <Grid item sx={11} sm={11} md={9} lg={7} xl={7}>
              <ControlledTextField
                name="external_ref_id"
                // label={t("manufacture.order.external_ref_id")}
                control={control}
                error={Boolean(errors?.external_ref_id)}
                helperText={
                  errors?.external_ref_id && errors?.external_ref_id.message
                }
                disabled={watchStatus !== "finished" ? disabled : true}
              />
            </Grid>
            <Grid item sx={12} sm={12} md={2} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("manufacture.order.employee_list")}
              </Typography>
            </Grid>
            <Grid item sx={11} sm={11} md={10} lg={9} xl={9}>
              <Employee
                control={control}
                setValue={setValue}
                getValues={getValues}
                disabled={watchStatus !== "finished" ? disabled : true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                isOpen={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("manufacture.bom.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                error={errors.issue_date}
                isOpen={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("manufacture.order.issue_date")}
                disabled={watchStatus !== "finished" ? disabled : true}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="expect_date"
                control={control}
                error={errors.expect_date}
                isOpen={expectDateIsOpen}
                onClose={() => setExpectDateIsOpen(false)}
                onOpen={() => setExpectDateIsOpen(true)}
                label={t("manufacture.order.expect_date")}
                disabled={watchStatus !== "finished" ? disabled : true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="expect_product_date"
                control={control}
                error={errors.expect_product_date}
                isOpen={expectProductDateIsOpen}
                onClose={() => setExpectProductDateIsOpen(false)}
                onOpen={() => setExpectProductDateIsOpen(true)}
                label={t("manufacture.order.expect_product_date")}
                disabled={watchStatus !== "finished" ? disabled : true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="delivery_date"
                control={control}
                error={errors.delivery_date}
                isOpen={deliveryDateIsOpen}
                onClose={() => setDeliveryDateIsOpen(false)}
                onOpen={() => setDeliveryDateIsOpen(true)}
                label={t("manufacture.order.delivery_date")}
                disabled={watchStatus !== "finished" ? disabled : true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <RightDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        title={t("inventory.activity")}
        documentId={id}
        documentType="manufacture_order"
      />
      <ChangeStatusConfirmation
        documentName="การผลิต"
        title={
          checkConditionStatus() ? "สถานะของการทำงานยังไม่เสร็จสิ้น" : null
        }
        content={
          checkConditionStatus()
            ? "กดยืนยันหากต้องการปรับสถานะการผลิตและการทำงาน เป็น เสร็จสิ้น"
            : null
        }
        currStatus={mapStatusToThai(currStatus)}
        nextStatus={mapStatusToThai(modal.nextStatus)}
        openConfirmation={modal.value}
        closeConfirmationHandler={closeConfirmationHandler}
        confirmationAction={confirmationAction}
      />
      <CopyConfirmation
        openCopyConfirmation={copyConfirmation}
        copyConfirmationAction={copyConfirmationAction}
        closeCopyConfirmationHandler={closeCopyConfirmationHandler}
      />
      <CancelConfirmation
        openCancelConfirmation={cancelConfirmation}
        cancelConfirmationAction={cancelConfirmationAction}
        closeCancelConfirmationHandler={closeCancelConfirmationHandler}
      />
    </>
  );
};

export default ManufactureOrderHeaderForm;
