import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import ReturnTable from "../../../components/Table/DocumentTable/ReturnTable";
import { getAllSalesReturns } from "../../../features/Sales/SalesReturn/sales-return-actions";
import { salesReturnActions } from "../../../features/Sales/SalesReturn/sales-return-slice";
import { filterDateWithUnix } from "../../../utils/filterparams";

const SalesReturn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.return.index"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.waitApprove"),
      path: `${pathname}?filter=waitApprove`,
    },
    {
      label: t("status.notApproved"),
      path: `${pathname}?filter=notApproved`,
    },
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const {
        document_id,
        contact_name,
        issue_date,
        created_by,
        employee_list,
      } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        contact_name: contact_name && {
          ...contact_name,
          mode: "insensitive",
        },
        issue_date: issue_date && filterDateWithUnix(issue_date),
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...employee_list, mode: "insensitive" },
          },
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: {
              ...created_by,
              mode: "insensitive",
            },
          },
        },
      };

      dispatch(
        getAllSalesReturns(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("render_status");
      switch (isFilter) {
        case "draft":
          instance.setModel({ values: ["draft"] });
          break;
        case "waitApprove":
          instance.setModel({ values: ["PENDING"] });
          break;
        case "notApproved":
          instance.setModel({ values: ["DECLINED"] });
          break;
        case "approved":
          instance.setModel({ values: ["approved"] });
          break;
        case "finished":
          instance.setModel({ values: ["finished"] });
          break;
        case "cancelled":
          instance.setModel({ values: ["cancelled"] });
          break;
        default:
          instance.setModel({
            values: ["draft", "PENDING", "DECLINED", "approved", "finished"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    return () => {
      dispatch(salesReturnActions.resetAllSalesReturns());
    };
  }, [dispatch, onFilterChanged]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3 }}>
        <Typography variant="h5">{t("sales.return.index")}</Typography>
      </Box>
      <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
      <ReturnTable gridRef={gridRef} onGridReady={onGridReady} />
    </>
  );
};

export default SalesReturn;
