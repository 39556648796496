import { uomActions } from "./uom-slice";
import SettingService from "../../../services/Setting/index";

export const getAllUom = (input) => async (dispatch) => {
  dispatch(uomActions.onLoading("allUoms"));
  try {
    const allUom = await SettingService.getAllUom(input);
    dispatch(uomActions.loadedAllUom(allUom));
  } catch (err) {
    dispatch(uomActions.rejectedActions({ ...err, name: "allUoms" }));
  }
};

export const getUomById = (input) => async (dispatch) => {
  dispatch(uomActions.onLoading("uom"));
  try {
    const result = await SettingService.getUom(input);
    dispatch(uomActions.loadedUom({ result }));
  } catch (err) {
    dispatch(uomActions.rejectedActions({ ...err, name: "uom" }));
  }
};

export const getUomGroupById = (input) => async (dispatch) => {
  dispatch(uomActions.onLoading("uomGroup"));
  try {
    const result = await SettingService.getUomGroup(input);
    dispatch(uomActions.loadedUomGroup({ result }));
  } catch (err) {
    dispatch(uomActions.rejectedActions({ ...err, name: "allUomGroup" }));
  }
};

export const getAllUomGroup = () => async (dispatch) => {
  dispatch(uomActions.onLoading("allUomGroup"));
  try {
    const allUomGroup = await SettingService.getAllUomGroup();
    dispatch(uomActions.loadedAllUomGroup(allUomGroup));
  } catch (err) {
    dispatch(uomActions.rejectedActions({ ...err, name: "allUomGroup" }));
  }
};

export const createUom = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(uomActions.onLoading("uom"));
  try {
    const result = await SettingService.postCreateUOM(input);
    dispatch(uomActions.loadedUom({ result, create: true }));
    enqueueSnackbar("สร้างหน่วยสำเร็จ", {
      variant: "success",
    });
  } catch (err) {
    enqueueSnackbar("สร้างหน่วยไม่สำเร็จ", {
      variant: "error",
    });
    dispatch(uomActions.rejectedActions({ ...err, name: "uom" }));
  }
};

export const updateUom =
  ({ input, index, enqueueSnackbar }) =>
  async (dispatch) => {
    dispatch(uomActions.onLoading("uom"));
    try {
      const result = await SettingService.postUpdateUOM(input);
      dispatch(uomActions.loadedUom({ result, index }));
      enqueueSnackbar("แก้ไขหน่วยสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      dispatch(uomActions.rejectedActions({ ...err, name: "uom" }));
      enqueueSnackbar("แก้ไขหน่วยไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const deleteUom =
  ({ uniqueInput, enqueueSnackbar }) =>
  async (dispatch) => {
    dispatch(uomActions.onLoading("uom"));
    try {
      const result = await SettingService.postDeleteUOM({ uniqueInput });
      dispatch(uomActions.removeUom({ result }));
      enqueueSnackbar("ลบหน่วยสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ลบหน่วยไม่สำเร็จ", {
        variant: "error",
      });
      dispatch(uomActions.rejectedActions({ ...err, name: "uom" }));
    }
  };

export const createUomGroup = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(uomActions.onLoading("uomGroup"));
  try {
    const result = await SettingService.postCreateUOMGroup(input);
    dispatch(uomActions.loadedUomGroup({ result, create: true }));
    if (enqueueSnackbar)
      enqueueSnackbar("สร้างกลุ่มของหน่วยสำเร็จ", {
        variant: "success",
      });
  } catch (err) {
    if (enqueueSnackbar)
      enqueueSnackbar("สร้างกลุ่มของหน่วยไม่สำเร็จ", {
        variant: "error",
      });
    dispatch(uomActions.rejectedActions({ ...err, name: "uomGroup" }));
  }
};

export const updateUomGroup =
  ({ input, index }, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(uomActions.onLoading("uomGroup"));
    try {
      const result = await SettingService.postUpdateUOMGroup(input);
      dispatch(uomActions.loadedUomGroup({ result, index }));
      if (enqueueSnackbar)
        enqueueSnackbar("แก้ไขกลุ่มของหน่วยสำเร็จ", {
          variant: "success",
        });
    } catch (err) {
      if (enqueueSnackbar)
        enqueueSnackbar("แก้ไขกลุ่มของหน่วยไม่สำเร็จ", {
          variant: "error",
        });
      dispatch(uomActions.rejectedActions({ ...err, name: "uomGroup" }));
    }
  };

// export const deleteUomGroup = (input) => async (dispatch) => {
//   dispatch(uomActions.onLoading("uomGroup"));
//   try {
//     const uomGroup = await SettingService.postUOMGroup(input);
//     dispatch(uomActions.loadedUomGroup(uomGroup));
//   } catch (err) {
//     dispatch(uomActions.rejectedActions({ ...err, name: "uomGroup" }));
//   }
// };
