import moment from "moment";
import { Box, Grid, Typography } from "@mui/material";
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import OrderTable from "../../../components/Table/DocumentTable/OrderTable";
import NewReportDateFilter from "../../../components/UI/NewReportDateFilter";
import ControlledSelect from "../../../components/Controlled/ControlledSelect";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import TimeStampReportLayout from "../../../components/UI/TimeStampReportLayout";

import GlobalService from "../../../services/Global";
import SalesService from "../../../services/Sales";

import { getNewSalesOrderReport } from "../../../features/Sales/SalesOrder/sales-order-actions";
import { salesOrderActions } from "../../../features/Sales/SalesOrder/sales-order-slice";

import {
  formatDateExport,
  formatDateAgGrid,
  unixToDateWithFormat,
  dateToUnix,
} from "../../../utils/date-converter";
import {
  filterDateWithUnix,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import useSalesOrderColumnDef from "../../../hooks/Sales/useSalesOrderColumnDef";
import { formatExportQuotes } from "../../../utils/exporter";

const typeFilter = ["วันที่ออกเอกสาร", "วันที่กำหนดส่งของ"];

const defaultDate = {
  type: typeFilter[0],
  dateType: "lastWeek",
  date: new Date(moment().startOf("day").subtract(6, "day")),
  dateTo: new Date(moment().endOf("day")),
};

const SalesOrderReportView = () => {
  const { isLoading } = useSelector((state) => state.salesOrder);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const [filename, setFilename] = useState("");
  const [data, setData] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [fetchTimestamp, setFetchTimestamp] = useState(null);
  const csvInstance = useRef(null);
  const [showTable, setShowTable] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [detail, setDetail] = useState(null);
  const [genDetail, setGenDetail] = useState(null);

  const columnDef = useSalesOrderColumnDef(true, true);

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.report.index"),
      to: "/sales/report",
    },
    {
      name: "รายการ" + t("sales.order.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: defaultDate,
  });

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const { issue_date, delivery_date, item_group_level_1_name_list } =
        filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        issue_date: issue_date && filterDateWithUnix(issue_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        item_group_level_1_name_list: item_group_level_1_name_list && {
          filterType: "array",
          type: "hasSome",
          values: item_group_level_1_name_list.values,
        },
      };

      dispatch(
        getNewSalesOrderReport(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      let type = getValues("type");
      let endDate = getValues("dateTo");
      let startDate = getValues("date");

      const issue_date = params.api.getFilterInstance("issue_date");
      const delivery_date = params.api.getFilterInstance("delivery_date");
      if (type === "วันที่กำหนดส่งของ")
        delivery_date?.setModel({
          filterType: "date",
          type: "inRange",
          dateFrom: formatDateAgGrid(startDate),
          dateTo: formatDateAgGrid(endDate),
        });
      if (type === "วันที่ออกเอกสาร")
        issue_date?.setModel({
          filterType: "date",
          type: "inRange",
          dateFrom: formatDateAgGrid(startDate),
          dateTo: formatDateAgGrid(endDate),
        });
      params.api.onFilterChanged();
      setFilename(
        `${t("sales.order.index")}_${formatDateExport(
          startDate
        )}_${formatDateExport(endDate)}.csv`
      );
    },
    [getValues, t]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const getFiltered = async () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
    const { timestamp, in_queue_timestamp, detail } =
      await GlobalService.getReportViewTimestamp("SalesOrder");
    setDetail(detail);
    setLastTimestamp(timestamp);
    setFetchTimestamp(
      in_queue_timestamp ? in_queue_timestamp.timestamp * 1000 : null
    );
    setGenDetail(null);
  };

  const exportAllDataAsAgGridCsv = async () => {
    try {
      const filterModel = gridRef.current.api.getFilterModel();
      const sortModel = gridRef.current.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const {
        issue_date,
        delivery_date,
        due_date,
        item_group_level_1_name_list,
      } = filterModel;

      const formatFilterModel = {
        ...filterModel,
        issue_date: issue_date && filterDateWithUnix(issue_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        due_date: due_date && filterDateWithUnix(delivery_date),
        item_group_level_1_name_list: item_group_level_1_name_list && {
          filterType: "array",
          type: "hasSome",
          values: item_group_level_1_name_list.values,
        },
      };
      dispatch(salesOrderActions.onLoading("salesOrderReports"));
      const { results: salesOrderReport } =
        await SalesService.getAllNewSalesOrderReport({
          startRow: 0,
          endRow: Math.pow(10, 5),
          filterModel: formatFilterModel,
          sortModel,
        });

      const formatValue = await salesOrderReport.map(
        ({
          issue_date,
          delivery_date,
          created_date,
          updated_date,
          render_status,
          ...other
        }) => ({
          ...other,
          issue_date: issue_date ? unixToDateWithFormat(issue_date) : "-",
          delivery_date: delivery_date
            ? unixToDateWithFormat(delivery_date)
            : "-",
          created_date: created_date ? unixToDateWithFormat(created_date) : "-",
          updated_date: updated_date ? unixToDateWithFormat(updated_date) : "-",
          render_status: filterStatusValueFormatter(render_status),
        })
      );
      setData(formatExportQuotes(formatValue));
      dispatch(salesOrderActions.onLoaded("salesOrderReports"));
      setTimeout(() => {
        if (csvInstance.current) {
          csvInstance.current.link.click();
        }
      });
      enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error exporting CSV:", error);
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const onFilterReset = () => {
    reset(defaultDate);
    if (gridRef) {
      onFilterChanged(gridRef.current);
    }
  };

  function moveElement(array, fromIndex, toIndex) {
    // Ensure fromIndex and toIndex are within the bounds of the array
    if (
      fromIndex < 0 ||
      fromIndex >= array.length ||
      toIndex < 0 ||
      toIndex >= array.length
    ) {
      return array;
    }

    // Remove the element from the fromIndex
    const elementToMove = array.splice(fromIndex, 1)[0];

    // Insert the element at the toIndex
    array.splice(toIndex, 0, elementToMove);

    return array;
  }

  const columnDefHeader = columnDef.map((col) => ({
    label: col.headerName,
    key: col.field,
  }));

  moveElement(columnDefHeader, 6, 7);

  const generateReportHandler = async () => {
    setFetching(true);
    let type = getValues("type");
    let endDate = getValues("dateTo");
    let startDate = getValues("date");

    const { status, timestamp, detail } = await GlobalService.generateReport(
      "SalesOrder",
      {
        date_field_name:
          type === "วันที่ออกเอกสาร" ? "issue_date" : "delivery_date",
        end_date: dateToUnix(endDate),
        start_date: dateToUnix(startDate),
      }
    );
    setGenDetail(detail);
    setTimeout(() => {
      setFetching(false);
    }, [5000]);

    if (status === "success") {
      setFetchTimestamp(timestamp);
      enqueueSnackbar("ส่งคำขอสำเร็จ กรุณารอสักครู่", {
        variant: "success",
      });
    } else if (status === "error") {
      enqueueSnackbar(
        `ระบบกำลังดำเนินการ กรุณารอสักครู่\n(ขอล่าสุด ${moment(
          fetchTimestamp
        ).fromNow()})`,
        {
          variant: "warning",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />

      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("sales.order.index")}</Typography>
        <Box>
          <CustomizedButton
            title="นำออกข้อมูล"
            variant="contained"
            onClick={exportAllDataAsAgGridCsv}
            disabled={isLoading.salesOrderReports}
          />
          <CSVLink
            headers={columnDefHeader}
            data={data}
            filename={filename}
            ref={csvInstance}
          />
        </Box>
      </Box>
      <CustomizedBox>
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2.5}>
              <ControlledSelect
                control={control}
                name="type"
                options={typeFilter}
                label={"ชื่อตัวกรอง"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1.5} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFiltered}
                disabled={isLoading.salesOrderReports}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1.5} alignSelf="center">
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.salesOrderReports}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </CustomizedBox>
      {showTable && (
        <TimeStampReportLayout
          detail={detail}
          genDetail={genDetail}
          fetching={fetching}
          generateReportHandler={generateReportHandler}
          lastTimestamp={lastTimestamp}
          fetchTimestamp={fetchTimestamp}
        />
      )}
      {showTable && (
        <OrderTable
          gridRef={gridRef}
          onGridReady={onGridReady}
          isReport
          newReport
        />
      )}
    </>
  );
};

export default SalesOrderReportView;
