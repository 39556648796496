import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  SvgIcon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as NewTabIcon } from "../../assets/newTabIcon.svg";
import { CustomizedTooltip } from "../Custom/CustomizedTooltip";

const ModalUI = ({
  open,
  handleClose,
  fullWidth,
  maxWidth,
  title,
  navigateTo,
  children,
  width,
  isLoading,
  isRed,
  centered,
}) => {
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: width ?? "auto",
          },
        },
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth || "100%"}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isLoading && <LinearProgress />}
      <Box sx={{ display: "flex" }}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            m: 0,
            p: "16px 16px 8px 16px",
            color: isRed ?? "inherit",
            textAlign: centered ? "center" : "left",
            flex: 1,
          }}
        >
          {title}
        </DialogTitle>
        <Box sx={{ display: "flex" }}>
          {navigateTo && (
            <CustomizedTooltip title="กดเพื่อเปิดหน้าต่างใหม่">
              <IconButton
                onClick={() => {
                  window.open(navigateTo, "_blank");
                }}
                sx={{
                  top: 8,
                  right: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <SvgIcon
                  inheritViewBox={true}
                  component={NewTabIcon}
                  sx={{
                    width: 18,
                    height: 18,
                    color: (theme) => theme.palette.grey[500],
                  }}
                />
              </IconButton>
            </CustomizedTooltip>
          )}
          {handleClose ? (
            <Box>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  top: 8,
                  right: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Box>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default ModalUI;
