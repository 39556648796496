import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PDFFooter from "../../../../components/UI/PDFFooter";
import PDFHeader from "../../../../components/UI/PDFHeader";
import QuotationPDFInfo from "./Info";
import { useReactToPrint } from "react-to-print";
import PDFSigner from "../../../../components/UI/PDFSigner";
import { useDispatch, useSelector } from "react-redux";
import { getQuotationById } from "../../../../features/Sales/Quotation/quotation-actions";
import { useState } from "react";
import ContactService from "../../../../services/Contact";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../hooks/use-auth";
import SalesPdf from "../../../../components/Table/Pdf/SalesPdf";
import CustomizedScrollbar from "../../../../components/Custom/CustomizedScrollbar";
import { quotationActions } from "../../../../features/Sales/Quotation/quotation-slice";

const QuotationPDF = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { quotation } = useSelector((state) => state.quotation);
  const [contact, setContact] = useState({
    contact_name: "",
    identity_no: "",
  });
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: quotation?.document_id,
  });
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getQuotationById(
        {
          document_id: id,
        },
        user,
        enqueueSnackbar
      )
    );
    return () => {
      dispatch(quotationActions.resetQuotation());
    };
  }, [dispatch, enqueueSnackbar, id, user]);

  useEffect(() => {
    const getContact = async () => {
      if (quotation && quotation.contact_document_id) {
        const contactData = await ContactService.getContact({
          document_id: quotation.contact_document_id,
        });
        setContact({
          contact_name:
            contactData.contact_name_1 +
            (contactData.contact_name_2
              ? " " + contactData.contact_name_2
              : ""),
          identity_no: contactData.identity_no,
        });
      }
    };
    getContact();
  }, [quotation]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">ใบเสนอราคา/Quotation</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>
      <Box sx={{ m: "10px auto", width: "fit-content" }}>
        <Box
          sx={{
            width: "210mm",
            height: "297mm",
            border: "1px solid #eee",
            borderRadius: "5px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <CustomizedScrollbar
            sx={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 3,
              }}
              ref={componentRef}
            >
              <Box>
                <PDFHeader documentType="ใบเสนอราคา/Quotation" />
                <Divider />
                <QuotationPDFInfo quotation={quotation} contact={contact} />
                <Divider />
                <SalesPdf itemList={quotation.item_list} />
                <PDFFooter data={quotation} />
                <Divider sx={{ mt: 2 }} />
              </Box>
              <Box sx={{ py: 3, breakInside: "avoid" }}>
                <PDFSigner />
              </Box>
            </Box>
          </CustomizedScrollbar>
        </Box>
      </Box>
    </Box>
  );
};

export default QuotationPDF;
