import React, { useRef, useEffect, useMemo, useState } from "react";
import { uomActions } from "../../../features/Setting/Uom/uom-slice";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ModalUI from "../../../components/UI/ModalUI";
import UomForm from "../../../components/Form/Uom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import AG_GRID_LOCALE_TH from "../../../locale.th";
import {
  deleteUom,
  getAllUom,
  getUomById,
} from "../../../features/Setting/Uom/uom-actions";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import DeleteItemConfirmation from "../../../components/UI/Confirmation/DeleteItemConfirmation";
import { filterParamsOptions } from "../../../utils/filterparams";
import { usePermission } from "../../../hooks/use-auth";

const Uom = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal, allUoms, uom } = useSelector((state) => state.uom);
  const [deleteModal, setDeleteModal] = useState();
  const [data, setData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { createPermission, editPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.warehouseSetting"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.uom.index"),
    },
  ];

  const handleOpenModal = (input) => {
    setData({ id: input.id });
    setDeleteModal(true);
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  };

  const handleClickDelete = () => {
    try {
      dispatch(deleteUom({ uniqueInput: data, enqueueSnackbar }));
      setDeleteModal(false);
    } catch (error) {
      setDeleteModal(false);
    }
  };

  const notShowDelete = ["cm", "m", "g", "kg"];

  const columnDefs = [
    {
      field: "id",
      headerName: t("setting.id"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      width: 200,
    },
    {
      field: "name",
      headerName: t("setting.name"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sort: "asc",
    },
    {
      field: "",
      headerName: "",
      filter: false,
      sortable: false,
      width: 150,
      cellRenderer: (params) => (
        <Box
          display={"flex"}
          flex={"end"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            {editPermission && (
              <IconButton
                onClick={() => {
                  handleOpen(params, params.node.rowIndex);
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            )}
            {editPermission &&
              !notShowDelete.includes(params.data.document_id) && (
                <IconButton onClick={() => handleOpenModal(params.data)}>
                  <DeleteIcon />
                </IconButton>
              )}
          </Box>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllUom());
  }, [dispatch]);

  const handleOpen = async (params, index) => {
    await dispatch(uomActions.loadedUomIndex(index));
    if (params && params.data && params.data.document_id) {
      await dispatch(
        getUomById({ uniqueInput: { document_id: params.data.document_id } })
      );
    }
    await dispatch(uomActions.openModal("uom"));
  };

  const handleClose = () => {
    dispatch(uomActions.closeModal("uom"));
    setTimeout(() => {
      dispatch(uomActions.resetUomGroup());
    }, 500);
  };

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_TH;
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      floatingFilter: true,
      filter: true,
      sortable: true,
    };
  }, []);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container my={2}>
        <Grid item xs={12} sm={9} md={9} lg={10} xl={10}>
          <Typography variant="h5">
            {t("setting.inventory.uom.index")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          lg={2}
          xl={2}
          alignSelf="center"
          textAlign={"end"}
        >
          {createPermission && (
            <CustomizedButton
              title={t("setting.inventory.uom.addUom")}
              variant="contained"
              onClick={handleOpen}
            />
          )}
        </Grid>
      </Grid>
      <ModalUI
        open={Boolean(modal.uom)}
        handleClose={handleClose}
        title={
          !uom.document_id
            ? t("setting.inventory.uom.addUom")
            : t("setting.edit")
        }
        width={720}
      >
        <UomForm handleClose={handleClose} />
      </ModalUI>
      <DeleteItemConfirmation
        title="ยืนยันการลบหน่วยใช่หรือไม่"
        openDeleteItemConfirmation={deleteModal}
        deleteItemConfirmationAction={handleClickDelete}
        closeDeleteItemConfirmationHandler={handleCloseModal}
      />
      <Box sx={{ my: 2 }}>
        <div
          id="myGrid"
          className="ag-theme-material"
          style={{ height: 590, width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={allUoms}
            localeText={localeText}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
            suppressContextMenu
          />
        </div>
      </Box>
    </>
  );
};

export default Uom;
