import { Box } from "@mui/system";
// import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import WorkOrderDetailForm from "../../../components/Form/Manufacture/WorkOrder/Detail";
import WorkOrderHeaderForm from "../../../components/Form/Manufacture/WorkOrder/Header";
import { dateToUnix, unixToDate } from "../../../utils/date-converter";
import {
  workOrderInitialState,
  workOrderValidation,
} from "../../../features/Manufacture/WorkOrder/work-order-initial";
import {
  changeStatusWorkOrder,
  getWorkOrderById,
  updateWorkOrder,
} from "../../../features/Manufacture/WorkOrder/work-order-actions";
import { workOrderActions } from "../../../features/Manufacture/WorkOrder/work-order-slice";
import { manufactureActions } from "../../../features/Setting/Manufacture/manufacture-slice";
import { useSnackbar } from "notistack";
import { getAllWorkCenter } from "../../../features/Setting/Manufacture/manufacture-actions";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { yupResolver } from "@hookform/resolvers/yup";

const WorkCenterContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const { workOrder } = useSelector((state) => state.workOrder);
  const { createPermission, editPermission } = usePermission();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.workOrder.index"),
      to: "/manufacture/work-order",
    },
    {
      name: id ? workOrder.name : `สร้าง${t("manufacture.workOrder.index")}`,
    },
  ];

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      ...workOrderInitialState.workOrder,
      created_date: unixToDate(workOrderInitialState.workOrder.created_date),
      start_date_time: unixToDate(
        workOrderInitialState.workOrder.start_date_time
      ),
    },
    resolver: yupResolver(workOrderValidation),
  });

  useEffect(() => {
    dispatch(getAllWorkCenter());
    if (id) {
      dispatch(getWorkOrderById(id));
    }
    return () => {
      dispatch(workOrderActions.resetWorkOrder());
      dispatch(manufactureActions.resetAllWorkCenter());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({
        ...workOrder,
        created_date: unixToDate(workOrder.created_date),
        start_date_time: unixToDate(workOrder.start_date_time),
      });
      setDisabled(workOrder.status !== "draft" ? true : false);
    }
  }, [workOrder, id, reset]);

  const submitHandler = (data) => {
    updateWorkOrderHandler(data);
  };

  const updateWorkOrderHandler = (payload) => {
    const {
      id,
      created_date,
      created_by,
      start_date_time,
      duration,
      status,
      manufacture_order,
      ...otherPayload
    } = payload;
    const input = {
      uniqueInput: { id: id },
      updateInput: {
        start_date_time: dateToUnix(start_date_time),
        duration: parseInt(duration),
        ...otherPayload,
      },
    };
    dispatch(updateWorkOrder(input, enqueueSnackbar, user));
  };

  const editButtonClickHandler = () => {
    setDisabled(false);
  };

  const changeStatusHandler = (status) => {
    const input = {
      uniqueInput: { id: parseInt(id) },
      statusInput: status,
      prevStatus: workOrder.status,
    };
    dispatch(changeStatusWorkOrder(input, enqueueSnackbar, user));
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <WorkOrderHeaderForm
        control={control}
        errors={errors}
        disabled={disabled || Boolean(!editPermission && id)}
        editButtonClickHandler={editButtonClickHandler}
        changeStatusHandler={changeStatusHandler}
      />
      <WorkOrderDetailForm
        control={control}
        errors={errors}
        setValue={setValue}
        reset={reset}
        disabled={disabled || Boolean(!editPermission && id)}
        setDisabled={setDisabled}
      />
      {!disabled && ((createPermission && !id) || (editPermission && id)) && (
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            variant="outlined"
            title={t("button.cancel")}
            onClick={() => navigate("/manufacture/work-order")}
          />
          <CustomizedButton
            type="submit"
            variant="contained"
            title={t("button.save")}
          />
        </Box>
      )}
    </form>
  );
};

export default WorkCenterContainer;
