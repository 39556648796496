import styled from "@emotion/styled";
import { alpha, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const ColorIconBox = styled(Box)(
  ({ theme, padding, isGridItem, isMobile }) => ({
    display: "flex",
    flexDirection: !isMobile ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${!isMobile ? "#d6cfcf" : "#328F35"}`,
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      border: `1px solid ${theme.palette.primary.main}`,
    },
    cursor: "pointer",
    padding: isMobile ? "1.5rem" : padding ? padding : ".5rem 3rem .5rem 3rem",
    width: isGridItem ? "100%" : isMobile ? "230px" : "fit-content",
    gap: "1rem",
    height: "100%",
  })
);

const CustomizedMenuBox = ({
  icon,
  title,
  onClick,
  isGridItem,
  width,
  padding,
  testId,
  isMobile,
  linkTo,
}) => {
  return (
    <ColorIconBox
      onClick={onClick}
      width={width}
      padding={padding}
      isGridItem={isGridItem}
      data-test-id={testId}
      isMobile={isMobile}
      component={Link}
      to={linkTo}
      style={{ textDecoration: "none", color: "#333333" }}
    >
      {icon}
      <Typography variant="body2" sx={isGridItem && { textAlign: "center" }}>
        {title}
      </Typography>
    </ColorIconBox>
  );
};

export default CustomizedMenuBox;
