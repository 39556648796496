import { Box, Typography } from "@mui/material";
import React from "react";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedButton from "../../Custom/CustomizedButton";
import ItemTable from "../../Table/ItemTable";
import ModalUI from "../../UI/ModalUI";
import AddIcon from "@mui/icons-material/Add";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ItemListTable from "../../Table/DocumentTable/ItemListTable";
// import OrderTable from "../../Table/DocumentTable/OrderTable";
import CheckedSalesOrderTable from "../../Table/CheckedSalesOrderTable";
import CheckedPurchaseRequestTable from "../../Table/CheckedPurchaseRequestTable";

const TotalPreVatAmount = ({
  itemTableGridRef,
  onGridReady,
  handleSelectItems,
  setHeaderValue,
  control,
  openModal,
  setOpenModal,
  viewOnly,
  documentType,
  getRowId,
  setValue,
  openModalPurchase,
  setOpenModalPurchase,
  salesOrderIds,
  setSalesOrderIds,
  salesOrderIdsSnapshot,
  setSalesOrderIdsSnapshot,
  purchaseRequestIds,
  setPurchaseRequestIds,
  purchaseRequestIdsSnapshot,
  setPurchaseRequestIdsSnapshot,
  openPurchaseRequestModal,
  setOpenPurchaseRequestModal,
}) => {
  const { t } = useTranslation();
  const { pre_vat_amount } = useWatch({ control, name: "summary" });
  const { purchaseOrderItemList } = useSelector(
    (state) => state.purchaseReturn
  );

  return (
    <>
      <CustomizedBox margin="0" radius="0 0 15px 15px" padding="1rem">
        <Box sx={{ display: "flex", gap: 1 }}>
          {!viewOnly && (
            <CustomizedButton
              title="เพิ่มสินค้า"
              startIcon={<AddIcon />}
              variant="outlined"
              color="secondary"
              onClick={() => setOpenModal(true)}
            />
          )}
          {!viewOnly && documentType === "pr" && (
            <CustomizedButton
              title="นำเข้าใบสั่งขาย"
              startIcon={<AddIcon />}
              variant="outlined"
              color="secondary"
              onClick={() => setOpenModalPurchase(true)}
            />
          )}
          {!viewOnly && documentType === "po" && (
            <CustomizedButton
              title="นำเข้าใบขอซื้อ"
              startIcon={<AddIcon />}
              variant="outlined"
              color="secondary"
              onClick={() => setOpenPurchaseRequestModal(true)}
            />
          )}
        </Box>
        {documentType !== "pr" && (
          <Box
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="subtitle1">{`${t(
              "sales.preVatAmount"
            )} Pre-vat Amount`}</Typography>
            <Typography
              variant="subtitle1"
              sx={{ width: "180px", textAlign: "right", mx: 2 }}
            >
              {pre_vat_amount}
            </Typography>
            <Typography variant="subtitle1">บาท</Typography>
          </Box>
        )}
      </CustomizedBox>
      <ModalUI
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="สินค้า"
        width={documentType === "rs" ? "lg" : "xl"}
      >
        {documentType === "rs" ? (
          <ItemListTable
            gridRef={itemTableGridRef}
            itemList={purchaseOrderItemList ?? []}
            documentType={documentType}
          />
        ) : (
          <ItemTable
            gridRef={itemTableGridRef}
            height={450}
            onGridReady={onGridReady}
            rowSelection="multiple"
            enableCheckbox
            getRowId={getRowId}
          />
        )}
        <CustomizedButton
          title="เพิ่ม"
          variant="contained"
          onClick={handleSelectItems}
          sx={{ mt: 2 }}
        />
      </ModalUI>
      <CheckedSalesOrderTable
        control={control}
        setValue={setValue}
        setHeaderValue={setHeaderValue}
        openModal={openModalPurchase}
        setOpenModal={setOpenModalPurchase}
        salesOrderIds={salesOrderIds}
        setSalesOrderIds={setSalesOrderIds}
        salesOrderIdsSnapshot={salesOrderIdsSnapshot}
        setSalesOrderIdsSnapshot={setSalesOrderIdsSnapshot}
      />
      <CheckedPurchaseRequestTable
        control={control}
        setValue={setValue}
        setHeaderValue={setHeaderValue}
        openPurchaseRequestModal={openPurchaseRequestModal}
        setOpenPurchaseRequestModal={setOpenPurchaseRequestModal}
        purchaseRequestIds={purchaseRequestIds}
        setPurchaseRequestIds={setPurchaseRequestIds}
        purchaseRequestIdsSnapshot={purchaseRequestIdsSnapshot}
        setPurchaseRequestIdsSnapshot={setPurchaseRequestIdsSnapshot}
      />
    </>
  );
};

export default TotalPreVatAmount;
