import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { formatNumber } from "../../utils/dataTransformer";
import { useTranslation } from "react-i18next";
import {
  RenderCustomizedSelect,
  RenderCustomizedTextField,
  RenderItemNameCell,
  customHeaderColRender,
} from "../../utils/customCellRenderer";
import CustomizedNumberTextField from "../../components/Custom/CustomizedNumberTextField";
import CloseIcon from "@mui/icons-material/Close";
import { useWatch } from "react-hook-form";

const useDeliveryTripColumnDef = (
  control,
  errors,
  getValues,
  setValue,
  handleShowItemModal,
  openDeleteItemConfirmationHandler,
  viewOnly,
  isFooter,
  vehicle
) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState();

  const status = useWatch({
    control,
    name: "render_status",
  });

  useEffect(() => {
    const customCellStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 6px",
    };

    const newColumn = [
      {
        field: "no",
        rowDrag: viewOnly || isFooter ? false : true,
        headerComponentParams: {
          template: customHeaderColRender("No.", "รายการ"),
        },
        valueGetter: (params) => (isFooter ? "" : params.node.rowIndex + 1),
        cellStyle: customCellStyle,
        width: 90,
      },
      {
        field: "item_name",
        headerComponentParams: {
          template: customHeaderColRender("Name", "ชื่อสินค้า", true),
        },
        cellRenderer: (params) => {
          if (isFooter) {
            return "รวม";
          }
          return (
            <RenderItemNameCell
              data={params.node.data}
              index={params.node.rowIndex}
              control={control}
              errors={errors}
              viewOnly={viewOnly}
              handleShowItemModal={handleShowItemModal}
              status={status}
              name="item_list"
            />
          );
        },
        autoHeight: true,
        wrapText: true,
        cellStyle: {
          padding: "0 6px",
          fontWeight: isFooter ? 600 : 400,
        },
        width: 280,
      },
      {
        field: "qty",
        headerComponentParams: {
          template: customHeaderColRender("Qty", "จำนวน", true),
        },
        cellRenderer: (params) => {
          if (isFooter) {
            return formatNumber(params.data.qty);
          }
          return (
            <>
              {viewOnly ? (
                <Typography variant="body2" align="center">
                  {formatNumber(params.data.qty)}
                </Typography>
              ) : (
                <CustomizedNumberTextField
                  control={control}
                  error={Boolean(
                    errors?.item_list &&
                      errors?.item_list[params.node.rowIndex] &&
                      errors?.item_list[params.node.rowIndex]?.qty
                  )}
                  helperText={
                    errors?.item_list &&
                    errors?.item_list[params.node.rowIndex] &&
                    errors?.item_list[params.node.rowIndex]?.qty &&
                    errors?.item_list[params.node.rowIndex]?.qty?.message
                  }
                  name={`item_list[${params.node.rowIndex}].qty`}
                  onChange={(value) => {
                    //update data in pre_vat_amount cell
                    const rowNode = params.api.getRowNode(
                      params.data.uid +
                        (params.data.do_reference_document_id ?? "")
                    );
                    rowNode.setDataValue("qty", value);
                    const unit = getValues(
                      `item_list[${params.node.rowIndex}].uom_id`
                    );
                    if (unit) {
                      if (params.data.uom_group) {
                        if (unit === params.data.uom_group?.base_uom?.id) {
                          setValue(
                            `item_list[${params.node.rowIndex}].qty_uom`,
                            value
                          );
                          rowNode.setDataValue("qty_uom", value);
                        } else {
                          const conversionUom =
                            params.data.uom_group.uom_conversion_list.find(
                              (conversion) => conversion.target_uom.id === unit
                            );
                          const targetUomQty =
                            conversionUom.base_uom_rate /
                            conversionUom.target_uom_rate;
                          setValue(
                            `item_list[${params.node.rowIndex}].qty_uom`,
                            value * targetUomQty
                          );
                          rowNode.setDataValue("qty_uom", value * targetUomQty);
                        }
                      }
                    }
                  }}
                />
              )}
            </>
          );
        },
        cellStyle: isFooter
          ? {
              ...customCellStyle,
              fontWeight: 600,
            }
          : customCellStyle,
        width: 135,
      },
      {
        field: "uom_id",
        headerComponentParams: {
          template: customHeaderColRender("Unit", "หน่วย", true),
        },
        cellRenderer: (params) => {
          let uomOptions = [];
          if (params.data.uom_group) {
            uomOptions = [
              {
                label: params.data.uom_group?.base_uom?.name,
                value: params.data.uom_group?.base_uom?.id,
              },
              ...params.data.uom_group?.convertable_uom_list?.map((uom) => ({
                label: uom.name,
                value: uom.id,
              })),
            ];
          }
          if (isFooter) {
            return null;
          }
          return (
            <>
              {viewOnly ? (
                <Typography variant="body2" align="center">
                  {params.data.uom}
                </Typography>
              ) : (
                <RenderCustomizedSelect
                  control={control}
                  error={Boolean(
                    errors?.item_list &&
                      errors?.item_list[params.node.rowIndex] &&
                      errors?.item_list[params.node.rowIndex]?.uom_id
                  )}
                  helperText={
                    errors?.item_list &&
                    errors?.item_list[params.node.rowIndex] &&
                    errors?.item_list[params.node.rowIndex]?.uom_id &&
                    errors?.item_list[params.node.rowIndex]?.uom_id?.message
                  }
                  name={`item_list[${params.node.rowIndex}].uom_id`}
                  options={uomOptions}
                  params={params}
                  getValues={getValues}
                  setValue={setValue}
                  isDeliveryTrip
                />
              )}
            </>
          );
        },
        cellStyle: customCellStyle,
        width: 130,
      },
      {
        field: "qty_uom",
        headerComponentParams: {
          template: customHeaderColRender("Stock Qty", "จำนวนคลัง"),
        },
        cellRenderer: (params) => {
          if (params.data.uom_group) {
            if (isFooter) {
              return null;
            }
            return (
              <>
                {viewOnly ? (
                  <Box>
                    <Typography variant="body2" align="center">
                      {formatNumber(params.data.qty_uom)}
                    </Typography>
                    <Typography
                      align="center"
                      sx={{ fontSize: "10px", whiteSpace: "normal" }}
                    >
                      {params.data.uom_group?.base_uom?.name}
                    </Typography>
                  </Box>
                ) : (
                  <Box mt={"22px"}>
                    <CustomizedNumberTextField
                      textAlign="center"
                      control={control}
                      name={`item_list[${params.node.rowIndex}].qty_uom`}
                      disabled={true}
                      helperText={params.data.uom_group?.base_uom?.name}
                    />
                  </Box>
                )}
              </>
            );
          }
        },
        cellStyle: customCellStyle,
        width: 120,
      },
      {
        field: "customer_name",
        headerComponentParams: {
          template: customHeaderColRender("Customer", "ลูกค้า"),
        },
        cellRenderer: (params) => {
          if (isFooter) {
            return null;
          }
          return (
            <Typography variant="body2" align="center">
              {params.data.customer_name}
            </Typography>
          );
        },
        cellStyle: customCellStyle,
        width: 225,
      },
      {
        field: "do_reference_document_id",
        headerComponentParams: {
          template: customHeaderColRender("DO Ref", "ใบส่งของ"),
        },
        cellRenderer: (params) => {
          if (isFooter) {
            return null;
          }
          return (
            <Typography variant="body2" align="center">
              {params.data.do_reference_document_id}
            </Typography>
          );
        },
        cellStyle: customCellStyle,
        width: 150,
      },
      {
        field: "so_reference_document_id",
        headerComponentParams: {
          template: customHeaderColRender("SO Ref", "ใบสั่งขาย"),
        },
        cellRenderer: (params) => {
          if (isFooter) {
            return null;
          }
          return (
            <Typography variant="body2" align="center">
              {params.data.so_reference_document_id}
            </Typography>
          );
        },
        cellStyle: customCellStyle,
        width: 150,
      },
      {
        field: "item_remark",
        headerComponentParams: {
          template: customHeaderColRender(
            "Product Remark",
            t("sales.itemRemark")
          ),
        },
        cellRenderer: (params) => {
          if (isFooter) {
            return null;
          }
          return (
            <>
              {viewOnly ? (
                <Typography variant="body2" align="center">
                  {params.data.item_remark}
                </Typography>
              ) : (
                <RenderCustomizedTextField
                  control={control}
                  name={`item_list[${params.node.rowIndex}].item_remark`}
                  onChange={(e) => {
                    const rowNode = params.api.getRowNode(
                      params.data.uid +
                        (params.data.do_reference_document_id ?? "")
                    );
                    rowNode.setDataValue("item_remark", e.target.value);
                  }}
                />
              )}
            </>
          );
        },
        cellStyle: customCellStyle,
        wrapText: true,
        autoHeight: true,
        width: 200,
      },
      {
        field: "volume",
        headerComponentParams: {
          template: customHeaderColRender("Volumn (sq m.)", "ปริมาตร (ลบ.ม.)"),
        },
        cellRenderer: (params) => {
          const currentQty = getValues(
            `item_list[${params.node.rowIndex}].qty`
          );
          if (isFooter) {
            if (vehicle && vehicle.volume) {
              return `${formatNumber(params.data.volume)}/${formatNumber(
                vehicle.volume
              )} (${(params.data.volume / vehicle.volume) * 100}%)`;
            }
            return `${formatNumber(params.data.volume)}`;
          }
          return (
            <Typography variant="body2" align="center">
              {formatNumber((params.data.volume * currentQty) / 1000000)}
            </Typography>
          );
        },
        cellStyle: isFooter
          ? {
              ...customCellStyle,
              fontWeight: 600,
            }
          : customCellStyle,
        width: 150,
      },
      {
        field: "weight",
        headerComponentParams: {
          template: customHeaderColRender("Weight (kg)", "น้ำหนัก (กก.)"),
        },
        cellRenderer: (params) => {
          const currentQty = getValues(
            `item_list[${params.node.rowIndex}].qty`
          );
          if (isFooter) {
            if (vehicle && vehicle.weight) {
              return `${formatNumber(params.data.weight)}/${formatNumber(
                vehicle.weight
              )} (${(params.data.weight / vehicle.weight) * 100}%)`;
            }
            return `${formatNumber(params.data.weight)}`;
          }
          return (
            <Typography
              variant="body2"
              align="center"
              justifyContent="space-between"
            >
              {formatNumber(params.data.weight * currentQty)}
            </Typography>
          );
        },
        cellStyle: isFooter
          ? {
              ...customCellStyle,
              fontWeight: 600,
            }
          : customCellStyle,
        width: 150,
        autoHeight: true,
      },
      {
        field: "",
        cellRenderer: (params) => {
          if (viewOnly || isFooter) {
            return null;
          } else {
            return (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                onClick={() =>
                  openDeleteItemConfirmationHandler(params.node.rowIndex)
                }
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            );
          }
        },
        cellStyle: customCellStyle,
        width: 80,
      },
    ];
    setColumnDefs(newColumn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    control,
    errors,
    getValues,
    // handleShowItemModal,
    isFooter,
    // openDeleteItemConfirmationHandler,
    setValue,
    t,
    vehicle,
    viewOnly,
    status,
  ]);
  return columnDefs;
};

export default useDeliveryTripColumnDef;
