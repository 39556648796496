import { useCallback, useEffect, useState } from "react";
import GlobalService from "../../../services/Global";

export const useTypeOptions = () => {
  const [typeLoading, setTypeLoading] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [openType, setOpenType] = useState(false);

  const getAllTypes = useCallback(async () => {
    setTypeLoading(true);
    const loadedOptions = await GlobalService.getCreatables({
      usage_field_type: "item",
      usage_field_name: "manufacture_type",
    });

    const formatTypeOptions = loadedOptions.map((option) => ({
      id: option.name,
      value: option.name,
      label: option.name,
    }));
    setTypeOptions(formatTypeOptions);
    setTypeLoading(false);
  }, []);

  useEffect(() => {
    if (openType) {
      getAllTypes();
    }
  }, [getAllTypes, openType]);

  const onOpenType = () => {
    setOpenType(true);
  };

  const onCloseType = () => {
    setOpenType(false);
    setTypeOptions([]);
  };
  return {
    typeLoading,
    typeOptions,
    onOpenType,
    onCloseType,
  };
};
