import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import Calendar from "../../../components/UI/Calendar";
import { getAllWorkOrdersCalendarView } from "../../../features/Manufacture/WorkOrder/work-order-actions";
import { workOrderActions } from "../../../features/Manufacture/WorkOrder/work-order-slice";
import { useNameOptions } from "../../../hooks/WorkOrder/Calendar/use-name-options";
import { useWorkCenterOptions } from "../../../hooks/WorkOrder/Calendar/use-work-center-options";

const statusOptions = [
  {
    id: 1,
    label: "ร่าง",
    value: "draft",
  },
  {
    id: 2,
    label: "กำลังผลิต",
    value: "in_progress",
  },
  {
    id: 3,
    label: "เสร็จสิ้น",
    value: "finished",
  },
  {
    id: 4,
    label: "ยกเลิก",
    value: "canceled",
  },
];

const WorkOrderCalendar = () => {
  const dispatch = useDispatch();

  const { allWorkOrdersCalendar } = useSelector((state) => state.workOrder);

  const { nameLoading, nameOptions, onOpenName, onCloseName } =
    useNameOptions();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const {
    plantLoading,
    plantOptions,
    onOpenPlant,
    onClosePlant,
    machineLoading,
    machineOptions,
    onOpenMachine,
    onCloseMachine,
  } = useWorkCenterOptions();

  const { control } = useForm({
    defaultValues: {
      name: null,
      plant: null,
      machine: null,
      status: null,
    },
  });

  const name = useWatch({
    control,
    name: "name",
  });

  const plant = useWatch({
    control,
    name: "plant",
  });

  const machine = useWatch({
    control,
    name: "machine",
  });

  const status = useWatch({
    control,
    name: "status",
  });

  useEffect(() => {
    dispatch(
      getAllWorkOrdersCalendarView({
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          start_date_time:
            startDate && endDate
              ? {
                  filter: startDate,
                  filterTo: endDate,
                  filterType: "number",
                  type: "inRange",
                }
              : undefined,
          name: name
            ? {
                filterType: "text",
                type: "contains",
                filter: name.value,
              }
            : undefined,
          plant: plant
            ? {
                filterType: "text",
                type: "contains",
                filter: plant.value,
              }
            : undefined,
          machine: machine
            ? {
                filterType: "text",
                type: "contains",
                filter: machine.value,
              }
            : undefined,
          status: status
            ? {
                values: [status.value],
                filterType: "set",
              }
            : undefined,
        },
        sortModel: [],
      })
    );
    return () => {
      dispatch(workOrderActions.resetAllWorkOrdersCalendar());
    };
  }, [dispatch, endDate, machine, name, plant, startDate, status]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={nameLoading}
                onOpen={onOpenName}
                onClose={onCloseName}
                label="การทำงาน"
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    dispatch(workOrderActions.resetAllWorkOrdersCalendar());
                    field.onChange(null);
                  }
                }}
                options={nameOptions || []}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <Controller
            control={control}
            name="plant"
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={plantLoading}
                label="โรงงาน"
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    dispatch(workOrderActions.resetAllWorkOrdersCalendar());
                    field.onChange(null);
                  }
                }}
                options={plantOptions || []}
                onOpen={onOpenPlant}
                onClose={onClosePlant}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <Controller
            control={control}
            name="machine"
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={machineLoading}
                label="ศูนย์ผลิต"
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    dispatch(workOrderActions.resetAllWorkOrdersCalendar());
                    field.onChange(null);
                  }
                }}
                options={machineOptions || []}
                onOpen={onOpenMachine}
                onClose={onCloseMachine}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                label="สถานะ"
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    dispatch(workOrderActions.resetAllWorkOrdersCalendar());
                    field.onChange(null);
                  }
                }}
                options={statusOptions}
              />
            )}
          />
        </Grid>
      </Grid>
      <Calendar
        events={allWorkOrdersCalendar}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </Box>
  );
};

export default WorkOrderCalendar;
