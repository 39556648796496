import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import AgGrid from "../../../components/Table/AgGrid";
import { woColumnDef } from "../../../components/Table/ColumnDefs/woColumnDef";
import { getAllWorkOrders } from "../../../features/Manufacture/WorkOrder/work-order-actions";
import { workOrderActions } from "../../../features/Manufacture/WorkOrder/work-order-slice";
import { filterDateWithUnix } from "../../../utils/filterparams";
import WorkOrderCalendar from "./WorkOrderCalendar";

const WorkOrder = ({ report }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.workOrder.index"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.manufacturing"),
      path: `${pathname}?filter=in_progress`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=canceled`,
    },
  ];

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("status");
      switch (isFilter) {
        case "draft":
          instance?.setModel({ values: ["draft"] });
          break;
        case "in_progress":
          instance?.setModel({ values: ["in_progress"] });
          break;
        case "finished":
          instance?.setModel({ values: ["finished"] });
          break;
        case "canceled":
          instance?.setModel({ values: ["canceled"] });
          break;
        default:
          instance?.setModel({
            values: ["draft", "in_progress", "finished"],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { start_date, group, ...otherFilterModal } = request.filterModel;
      const formatFilterModel = {
        ...otherFilterModal,
        start_date: start_date && filterDateWithUnix(start_date),
        manufacture_order: group
          ? {
              filterType: "object",
              filter: {
                group: {
                  filterType: "objectArray",
                  type: "some",
                  filter: {
                    name: {
                      filterType: "set",
                      values: group.values,
                    },
                  },
                },
              },
            }
          : {},
      };
      dispatch(
        getAllWorkOrders(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(datasource);
  };

  const onRowDoubleClicked = (params) => {
    navigate(`${pathname}/${encodeURIComponent(params.data.id)}`);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    return () => {
      dispatch(workOrderActions.resetAllWorkOrders());
    };
  }, [dispatch, onFilterChanged]);

  const [view, setView] = useState("table");
  const handleView = (_, newView) => {
    setView(newView);
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("manufacture.workOrder.index")}</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ToggleButtonGroup
            color="primary"
            value={view}
            size="small"
            exclusive
            onChange={handleView}
            aria-label="view"
          >
            <ToggleButton value="table" aria-label="table view">
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton value="calendar" aria-label="calendar view">
              <CalendarTodayIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      {view === "table" && (
        <>
          {!report && (
            <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
          )}
          <AgGrid
            ref={gridRef}
            columnDefs={woColumnDef(t)}
            height={680}
            onGridReady={onGridReady}
            onRowDoubleClicked={onRowDoubleClicked}
            path={pathname}
          />
        </>
      )}
      {view === "calendar" && <WorkOrderCalendar />}
    </>
  );
};

export default WorkOrder;
