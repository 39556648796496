import { createSlice } from "@reduxjs/toolkit";
export const REDIRECT = "REDIRECT";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarType: "",
  },
  reducers: {
    openSnackbar(state, action) {
      state.snackbarType = action.payload.type;
      state.snackbarMessage = action.payload.message;
      state.snackbarOpen = true;
    },
    closeSnackbar(state) {
      state.snackbarMessage = "";
      state.snackbarOpen = false;
      state.infoSnackbarOpen = false;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
