import styled from "@emotion/styled";
import { Avatar, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../utils/filterparams";
import { unixToDateWithFormat } from "../../utils/date-converter";
import { formatNumberWithValidate } from "../../utils/dataTransformer";

import CustomizedLetterAvatar from "../../components/Custom/CustomizedLetterAvatar";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const useInActiveSerialColumnDef = (isReport, getAllItemGroupLevel1) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState([]);

  const navigateToGoodPage = (type, document_id) => {
    window.open(
      `/inventory/${type}/${encodeURIComponent(document_id)}`,
      "_blank"
    );
  };

  const mapTypeToEng = (type) => {
    switch (type) {
      case "นำเข้า":
        return "receive";
      case "นำออก":
        return "issue";
      case "โอนย้าย":
        return "tranfer";
      case "รับคืน":
        return "adjustment";
      default:
        return type;
    }
  };

  useEffect(() => {
    if (isReport) {
      setColumnDefs([
        {
          field: "created_date",
          headerName: t("inventory.stockEntry.createdDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
          },
          valueFormatter: (params) => unixToDateWithFormat(params.value),
        },
        {
          field: "serial_number",
          headerName: t("inventory.serialNumber"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_group_sub_level_1_name",
          headerName: t("inventory.items.itemGroup"),
          filter: "agSetColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
            values: async (params) => {
              // fetch values from server
              const values = await getAllItemGroupLevel1();
              const formatValues = values.map((group) => group.name);
              params.success(formatValues);
            },
          },
        },
        {
          field: "item_document_id",
          headerName: t("inventory.items.itemId"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_name",
          headerName: t("inventory.items.itemName"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "item_description",
          headerName: t("inventory.items.itemDescription"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "posted_quantity",
          headerName: t("inventory.list.quantityDifference"),
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("number"),
          },
          valueFormatter: (params) => formatNumberWithValidate(params.value),
        },
        {
          field: "uom_name",
          headerName: t("inventory.unit"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "reference_document_type_thai_name",
          headerName: t("inventory.stockEntry.referenceDocument"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "source_warehouse_thai_name",
          headerName: t("inventory.list.sourceWarehouse"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "batch_number",
          headerName: "Barcode",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "reference_document_id",
          headerName: t("inventory.stockEntry.referenceDocument"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          cellRenderer: (params) => {
            const { reference_document_type_thai_name, reference_document_id } =
              params.data;
            if (!reference_document_type_thai_name || !reference_document_id) {
              return "-";
            }
            return (
              <StyledLink
                onClick={() =>
                  navigateToGoodPage(
                    mapTypeToEng(reference_document_type_thai_name),
                    reference_document_id
                  )
                }
              >
                {reference_document_id}
              </StyledLink>
            );
          },
        },
        {
          field: "status",
          headerName: t("inventory.status"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params) => {
              return filterStatusValueFormatter(params.value);
            },
            values: ["draft", "finished", "cancelled"],
          },
          cellRenderer: (params) => {
            return <CustomizedStatus status={params.value} />;
          },
          cellStyle: {
            display: "flex",
            alignItems: "center",
          },
        },
        {
          field: "created_by",
          headerName: t("inventory.stockEntry.createdBy"),
          sortable: false,
          filter: "agSetColumnFilter",
          cellRenderer: (params) => {
            if (params.data.created_by_img_url) {
              return (
                <Avatar alt="img_url" src={params.data.created_by_img_url} />
              );
            } else {
              return <CustomizedLetterAvatar name={params.value} />;
            }
          },
        },
      ]);
    }
  }, [getAllItemGroupLevel1, isReport, t]);
  return columnDefs;
};

export default useInActiveSerialColumnDef;
