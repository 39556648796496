import { remarkTemplateActions } from "./remark-template-slice";
// import { showSnackbar } from "../../UI/ui-actions";
import SettingService from "../../../services/Setting/index";

export const getAllRemarkTemplate = (input, type) => async (dispatch) => {
  dispatch(remarkTemplateActions.onLoading("allRemarkTemplate"));
  try {
    const allRemarkTemplate = await SettingService.getRemarkTemplates(input);
    dispatch(
      remarkTemplateActions.loadedAllRemarkTemplate({ allRemarkTemplate, type })
    );
  } catch (err) {
    dispatch(
      remarkTemplateActions.rejectedActions({
        ...err,
        name: "allRemarkTemplate",
      })
    );
  }
};

export const createRemarkTemplate =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(remarkTemplateActions.onLoading("allRemarkTemplate"));
    try {
      await SettingService.createRemarkTemplate(input);
      if (enqueueSnackbar)
        enqueueSnackbar("สร้างสถานที่สำเร็จ", {
          variant: "success",
        });
    } catch (err) {
      if (enqueueSnackbar)
        enqueueSnackbar("สร้างสถานที่ไม่สำเร็จ", {
          variant: "error",
        });
      dispatch(
        remarkTemplateActions.rejectedActions({
          ...err,
          name: "allRemarkTemplate",
        })
      );
    }
  };

export const updateRemarkTemplate =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(remarkTemplateActions.onLoading("allRemarkTemplate"));
    try {
      await SettingService.updateRemarkTemplate(input);
    } catch (err) {
      dispatch(
        remarkTemplateActions.rejectedActions({
          ...err,
          name: "allRemarkTemplate",
        })
      );
    }
  };

export const deleteRemarkTemplate =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(remarkTemplateActions.onLoading("allRemarkTemplate"));
    try {
      await SettingService.deleteRemarkTemplate(input);
    } catch (err) {
      dispatch(
        remarkTemplateActions.rejectedActions({
          ...err,
          name: "allRemarkTemplate",
        })
      );
    }
  };
