import { useEffect, useState } from "react";
import { permissionAction } from "../../utils/userInfo";

const allPermissionList = permissionAction();

const formatAction = (formatList) => {
  return formatList.reduce((a, v) => {
    const action = v.split("__")[2];
    return { ...a, [action]: v };
  }, {});
};

export const useItemPermission = () => {
  const [itemPermission, setItemPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("INVENTORY__ITEM_MASTER")
    );
    const actions = formatAction(formatList);
    setItemPermission(actions);
  }, []);

  return itemPermission;
};

export const useGoodsReceivePermission = () => {
  const [itemPermission, setItemPermission] = useState();
  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("INVENTORY__RECEIVE")
    );
    const actions = formatAction(formatList);
    setItemPermission(actions);
  }, []);

  return itemPermission;
};

export const useGoodsReceiveReturnPermission = () => {
  const [itemPermission, setItemPermission] = useState();

  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("INVENTORY__RETURN")
    );
    const actions = formatAction(formatList);
    setItemPermission(actions);
  }, []);
  return itemPermission;
};

export const useGoodsIssuePermission = () => {
  const [itemPermission, setItemPermission] = useState();
  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("INVENTORY__ISSUE")
    );
    const actions = formatAction(formatList);
    setItemPermission(actions);
  }, []);

  return itemPermission;
};

export const useGoodsAdjustmentPermission = () => {
  const [itemPermission, setItemPermission] = useState();
  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("INVENTORY__ADJUSTMENT")
    );
    const actions = formatAction(formatList);
    setItemPermission(actions);
  }, []);

  return itemPermission;
};

export const useGoodsTransferPermission = () => {
  const [itemPermission, setItemPermission] = useState();
  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("INVENTORY__TRANSFER")
    );
    const actions = formatAction(formatList);
    setItemPermission(actions);
  }, []);

  return itemPermission;
};

export const useInventoryReport = () => {
  const [itemPermission, setItemPermission] = useState();
  useEffect(() => {
    const formatList = allPermissionList.filter((permission) =>
      permission.includes("INVENTORY__REPORT")
    );
    const actions = formatAction(formatList);
    setItemPermission(actions);
  }, []);

  return itemPermission;
};
