import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";

const DeleteTagListConfirmation = ({
  payload,
  openDeleteConfirmation,
  comfirmationDeleteAction,
  closeModalHandler,
}) => {
  return (
    <ModalUI
      title={`การจัดกลุ่ม ${payload.optionToDelete} ถูกใช้อยู่ใน${payload.document_type}`}
      open={openDeleteConfirmation}
      handleClose={closeModalHandler}
      maxWidth="xs"
      fullWidth
    >
      <Typography>
        {payload.document_type}ที่ใช้งานการจัดกลุ่มนี้ คือ
      </Typography>
      <List sx={{ listStyleType: "disc", ml: 3 }}>
        {payload.document_id_list?.map((documentId, index) => {
          if (index > 5) return null;
          else
            return (
              <ListItem sx={{ display: "list-item" }}>
                {index > 4 ? "..." : documentId}
              </ListItem>
            );
        })}
      </List>
      <Typography
        mt={2}
        sx={{
          fontWeight: 600,
          fontSize: "1rem",
          lineHeight: 1.375,
          color: "#333333",
        }}
      >
        หากลบการจัดกลุ่ม {payload.optionToDelete} การจัดกลุ่มที่อยู่ในแต่ละ
        {payload.document_type}ตามที่แสดงด้านบนจะถูกลบด้วย
      </Typography>
      <Typography>กดยืนยันหากต้องการลบการจัดกลุ่ม</Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeModalHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={comfirmationDeleteAction}
        />
      </Box>
    </ModalUI>
  );
};

export default DeleteTagListConfirmation;
