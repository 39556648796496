import * as Yup from "yup";

export const schema = {
  created_date: "",
  company_document_id: "",
  last_updator_document_id: "",
  identity_no: "",
  registered_capital: "",
  contact_type_1: "นิติบุคคล",
  contact_type_2: "",
  company_name: "",
  contact_name_1: "",
  contact_name_2: "",
  initial: "",
  register_date: "",
  is_registered_vat: false,
  vat_registration_date: "",
  img_url: "",
  contact_channel_list: [],
  contact_person_list: [],
  address_list: [
    {
      address_type: "ที่อยู่จดทะเบียน",
      default_address: true,
      same_as_default_address: null,
      address_contact_name: "",
      address_contact_phone: "",
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  ],
};

const dateIsValid = (value) => {
  return value instanceof Date && !isNaN(value) ? value : null;
};

export const validation = Yup.object().shape({
  company_name: Yup.string().required("กรุณาระบุชื่อกิจการ"),
  identity_no: Yup.string()
    .required("กรุณาระบุเลขประจำตัวผู้เสียภาษี")
    .test(
      "len",
      'เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"',
      (val) => !val || (val && val.length <= 13)
    ),
  register_date: Yup.date()
    .required("กรุณาระบุวันที่จดทะเบียนกิจการ")
    .nullable()
    .transform((value) => dateIsValid(value)),
  address_list: Yup.array().of(
    Yup.object().shape({
      default_address: Yup.boolean(),
      same_as_default_address: Yup.boolean().nullable(),
      address: Yup.string().nullable(),
      address_contact_name: Yup.string().nullable(),
      address_contact_phone: Yup.string().nullable(),
      address_type: Yup.string().nullable(),
      country: Yup.string().nullable(),
      sub_district: Yup.string().nullable(),
      district: Yup.string().nullable(),
      province: Yup.string().nullable(),
      postal_code: Yup.string().nullable(),
    })
  ),
});
