import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { filteredRow } from "../../../utils/dataTransformer";
import AgGrid from "../../Table/AgGrid";

const InventoryPermission = ({ columnDefs, priviledgeList, resource }) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "ITEM_MASTER",
        name: t("inventory.items.index"),
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
      },
      {
        functionName: "RECEIVE",
        name: t("inventory.receive.index"),
        view: "view",
        create: "create",
        cancel: "cancel",
      },
      {
        functionName: "RETURN",
        name: t("inventory.return.index"),
        view: "view",
        create: "create",
      },
      {
        functionName: "ISSUE",
        name: t("inventory.issue.index"),
        view: "view",
        create: "create",
      },
      {
        functionName: "TRANSFER",
        name: t("inventory.transfer.index"),
        view: "view",
        create: "create",
      },
      {
        functionName: "ADJUSTMENT",
        name: t("inventory.adjustment.index"),
        view: "view",
        create: "create",
      },
      {
        functionName: "REPORT",
        name: t("report"),
        view: "view",
      },
    ];

    return filteredRow(rows, priviledgeList, resource);
  }, [priviledgeList, resource, t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      isClientSide
    />
  );
};

export default InventoryPermission;
