import { Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedMenuOption from "../../../components/Custom/CustomizedMenuOption";
import UserAccountForm from "../../../components/Form/UserAccount";
import { usePermission } from "../../../hooks/use-auth";

const AccountTab = ({
  control,
  errors,
  setValue,
  getValues,
  trigger,
  viewOnly,
  onTriggerRevalidate,
  setting,
  addable,
}) => {
  const { t } = useTranslation();
  const [option, setOption] = useState("");
  const { createPermission, editPermission } = usePermission();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mt={3}>
        <Grid item>
          <Typography variant="h5">{t("user.account.index")}</Typography>
        </Grid>
        <Grid item>
          {viewOnly && (
            <CustomizedMenuOption
              label={"ตัวเลือก"}
              options={[{ value: "แก้ไข", disabled: !editPermission }]}
              onSelect={(e) => {
                setOption(e.target.innerText);
              }}
            />
          )}
        </Grid>
      </Grid>
      <UserAccountForm
        control={control}
        errors={errors}
        setValue={setValue}
        trigger={trigger}
        viewOnly={viewOnly}
        option={option}
        getValues={getValues}
        onTriggerRevalidate={onTriggerRevalidate}
        setting={setting}
        addable={addable}
        createPermission={createPermission}
        editPermission={editPermission}
      />
    </>
  );
};

export default AccountTab;
