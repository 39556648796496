import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./delivery-trip-initial";

const deliveryTripSlice = createSlice({
  name: "deliveryTrip",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    onLoaded(state, action) {
      state.isLoading[action.payload] = false;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllDeliveryTrips(state, action) {
      state.allDeliveryTrips = action.payload;
      state.isLoading.allDeliveryTrips = false;
    },
    loadedAllDeliveryTripsExport(state, action) {
      state.allDeliveryTripsExport = action.payload;
      state.isLoading.allDeliveryTrips = false;
    },
    loadedDeliveryTrip(state, action) {
      state.deliveryTrip = action.payload;
      state.isLoading.deliveryTrip = false;
    },
    updateDeliveryTripStatus(state, action) {
      state.deliveryTrip.render_status = action.payload;
      state.isLoading.deliveryTrip = false;
    },
    resetAllDeliveryTrips(state) {
      state.allDeliveryTrips = initialState.allDeliveryTrips;
      state.error = null;
    },
    resetAllDeliveryTripsExport(state) {
      state.allDeliveryTripsExport = initialState.allDeliveryTripsExport;
      state.error = null;
    },
    resetDeliveryTrip(state) {
      state.deliveryTrip = initialState.deliveryTrip;
      state.error = null;
    },
  },
});

export const deliveryTripActions = deliveryTripSlice.actions;

export default deliveryTripSlice.reducer;
