import React, { useCallback } from "react";
import AgGrid from "../AgGrid";

import SettingService from "../../../services/Setting";
import useStockEntryColumnDef from "../../../hooks/Inventory/useStockEntryColumnDef";

const StockEntryReportTable = ({ gridRef, onGridReady, rowData, isReport }) => {
  const getAllItemGroupLevel1 = useCallback(async () => {
    const data = await SettingService.getAllCategory();
    return data;
  }, []);

  const columnDefs = useStockEntryColumnDef(isReport, getAllItemGroupLevel1);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={680}
      onGridReady={onGridReady}
      rowData={rowData}
    />
  );
};

export default StockEntryReportTable;
