import ActivityLogsService from "../../services/ActivityLogs";
import { activityLogsActions } from "./activityLogs-slice";

export const getActivityLogsById =
  (input, documentType) => async (dispatch) => {
    try {
      const logs = await ActivityLogsService.getActivityLogs(input);
      dispatch(activityLogsActions.loadedActivityLogs({ logs, documentType }));
    } catch (err) {
      dispatch(activityLogsActions.rejectedActions({ ...err }));
    }
  };

export const createActivityLogs =
  (input, reset, enqueueSnackbar) => async (dispatch) => {
    try {
      const logs = await ActivityLogsService.createActivityLogs(input);
      dispatch(activityLogsActions.addActivityLogs(logs));
      enqueueSnackbar("คอมเมนต์สำเร็จ", {
        variant: "success",
      });
      reset({ comment: "", attachment_list: [] });
    } catch (err) {
      dispatch(activityLogsActions.rejectedActions({ ...err }));
      enqueueSnackbar("คอมเมนต์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
