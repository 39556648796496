import { Box } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";

const LowerQtyConfirmation = ({
  openLowerQtyConfirmation,
  lowerQtyConfirmationAction,
  closeLowerQtyConfirmationHandler,
}) => {
  return (
    <ModalUI
      title="มีการระบุจำนวนวัตถุดิบต่ำกว่าจำนวนที่ต้องใช้ตาม BOM 
      ต้องการบันทึกใบสั่งผลิตหรือไม่"
      open={openLowerQtyConfirmation}
      handleClose={closeLowerQtyConfirmationHandler}
      maxWidth="sm"
    >
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeLowerQtyConfirmationHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={lowerQtyConfirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default LowerQtyConfirmation;
