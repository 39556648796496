import { Typography } from "@mui/material";
import {
  customHeaderColRender,
  RenderCustomizedTextField,
} from "../../../../utils/customCellRenderer";
import { unixToDateWithFormat } from "../../../../utils/date-converter";

export const rtColumnDefs = (
  t,
  control,
  getValues,
  setValue,
  errors,
  viewOnly
) => {
  const customCellStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 6px",
  };
  return [
    {
      field: "document_id",
      headerName: t("sales.documentId"),
      headerClass: "center",
      width: 200,
    },
    {
      field: "issue_date",
      valueFormatter: ({ value }) => unixToDateWithFormat(value),
      headerComponentParams: {
        template: customHeaderColRender("", t("sales.issueDate")),
      },
      cellStyle: { ...customCellStyle, padding: "10px 20px " },
      width: 175,
    },
    {
      field: "due_date",
      valueFormatter: ({ value }) => unixToDateWithFormat(value),
      headerComponentParams: {
        template: customHeaderColRender("", t("sales.dueDate")),
      },
      cellStyle: { ...customCellStyle, padding: "10px 20px " },
      width: 150,
    },
    {
      field: "total_amount",
      headerComponentParams: {
        template: customHeaderColRender("", t("sales.totalNetAmount")),
      },
      cellStyle: { ...customCellStyle, padding: "10px 20px " },
      width: 150,
    },
    {
      field: "pending_payment_amount",
      headerComponentParams: {
        template: customHeaderColRender(
          "",
          t("sales.paymentReceipt.waitingToPay")
        ),
      },
      cellStyle: { ...customCellStyle, padding: "10px 20px " },
      width: 150,
    },
    {
      field: "payment_amount",
      headerComponentParams: {
        template: customHeaderColRender("", t("sales.amount")),
      },
      cellRenderer: (params) => (
        <>
          {viewOnly ? (
            <Typography variant="body2" align="center">
              {params.data.amount}
            </Typography>
          ) : (
            <RenderCustomizedTextField
              control={control}
              name={`sales_invoice_list.[${params.node.rowIndex}].payment_amount`}
              onChange={(e) => {
                const rowNode = params.api.getRowNode(params.data.document_id);
                let newPendingPaymentAmount;
                const totalAmount = getValues(
                  `sales_invoice_list.[${params.node.rowIndex}].total_amount`
                );
                if (totalAmount < e.target.value) {
                  setValue(
                    `sales_invoice_list.[${params.node.rowIndex}].payment_amount`,
                    totalAmount
                  );
                  rowNode.setDataValue("payment_amount", totalAmount);
                  newPendingPaymentAmount = 0;
                } else {
                  newPendingPaymentAmount = totalAmount - e.target.value;
                }
                setValue(
                  `sales_invoice_list.[${params.node.rowIndex}].pending_payment_amount`,
                  newPendingPaymentAmount
                );
                rowNode.setDataValue(
                  "pending_payment_amount",
                  newPendingPaymentAmount
                );
              }}
              type="number"
            />
          )}
        </>
      ),
      cellStyle: { ...customCellStyle, padding: "10px 20px " },
      width: 200,
    },
  ];
};
