import React, { useRef, forwardRef } from "react";
import ReturnReasonForm from "../../../components/Form/Purchase/ReturnReason";
import VendorDescriptionForm from "../../../components/Form/Purchase/VendorDescription";
import AddItemListForm from "../../../components/Form/Purchase/AddItemListForm";
import Summary from "../../../components/Form/Purchase/Summary";
import { useTranslation } from "react-i18next";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const DocumentInfoTab = forwardRef(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      setHeaderValue,
      getHeaderValues,
      submitVendorDesc,
      vendorDescUseForm,
      contactUseForm,
      reasonRef,
      itemListRef,
      viewOnly,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const gridRef = useRef();
    const {
      control: vendorDescControl,
      setValue: setVendorDescValue,
      getValues: getVendorDescValues,
      formState: { errors: vendorDescErrors },
    } = vendorDescUseForm;

    const priceVatTypeOption = [
      {
        id: 1,
        label: t("sales.includedVat"),
        value: "included_vat",
      },
      {
        id: 2,
        label: t("sales.excludedVat"),
        value: "excluded_vat",
      },
    ];

    return (
      <>
        <ReturnReasonForm
          control={control}
          errors={errors}
          ref={reasonRef}
          viewOnly={viewOnly}
          setValue={setValue}
        />
        <VendorDescriptionForm
          ref={ref}
          mainControl={control}
          control={vendorDescControl}
          onSubmitContact={submitVendorDesc}
          setValue={setVendorDescValue}
          getValues={getVendorDescValues}
          getMainValues={getValues}
          errors={vendorDescErrors}
          contactUseForm={contactUseForm}
          viewOnly={viewOnly}
          setHeaderValue={setHeaderValue}
          isReturn
        />
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "flex-start",
            gap: 0.5,
          }}
        >
          <Box sx={{ width: 200 }}>
            <Controller
              name="price_vat_type"
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  {...field}
                  error={Boolean(errors.price_vat_type)}
                  helperText={errors.price_vat_type?.message}
                  label={t("sales.priceVatType")}
                  options={priceVatTypeOption}
                  disabled={viewOnly}
                />
              )}
            />
          </Box>
          <CustomizedTooltip
            title={
              <Box>
                <Typography sx={{ fontSize: 12 }}>
                  ราคาแยกภาษี คือ ราคาซื้อ + ภาษีมูลค่าเพิ่ม 7%
                </Typography>
                <Divider variant="middle" sx={{ my: 0.5 }} />
                <Typography sx={{ fontSize: 12 }}>
                  ราคารวมภาษี คือ ราคาซื้อรวมภาษีมูลค่าเพิ่ม 7% แล้ว
                </Typography>
              </Box>
            }
          >
            <IconButton>
              <ErrorOutlineOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        </Box>
        <AddItemListForm
          itemListRef={itemListRef}
          gridRef={gridRef}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          setHeaderValue={setHeaderValue}
          getHeaderValues={getHeaderValues}
          documentType="rs"
          draggable
        />
        <Summary
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          viewOnly={viewOnly}
          documentType="rs"
        />
      </>
    );
  }
);

export default DocumentInfoTab;
