import { Fragment } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Box, Divider, IconButton } from "@mui/material";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedCheckboxes from "../../../Custom/CustomizedCheckboxes";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";
import CloseIcon from "@mui/icons-material/Close";
import addressData from "../../../../data/addressWithZone.json";

const NewAddress = ({
  control,
  errors,
  setValue,
  getValues,
  remove,
  viewOnly,
  setSubdistrict,
  setDistrict,
  setProvince,
  setSubdistrictOptions,
  subdistrictOptions,
  districtOptions,
  provinceOptions,
  itemId,
  index,
  isSameAddress,
}) => {
  const { t } = useTranslation();

  const addressOptions = [
    {
      value: "ที่อยู่จัดส่ง",
      label: "ที่อยู่จัดส่ง",
    },
    {
      value: "ที่อยู่ส่งเอกสาร",
      label: "ที่อยู่ส่งเอกสาร",
    },
  ];

  const countryOptions = ["ไทย"];

  const autofillAddress = (index) => {
    const addressList = getValues("address_list");
    const mainAddress = addressList[0];
    const currentAddress = addressList[index];
    const updatedAddress = {
      default_address: false,
      same_as_default_address: currentAddress.same_as_default_address,
      address_type: currentAddress.address_type,
      address_contact_name: currentAddress.address_contact_name,
      address_contact_phone: currentAddress.address_contact_phone,
      address: mainAddress.address,
      sub_district: mainAddress.sub_district,
      district: mainAddress.district,
      province: mainAddress.province,
      postal_code: mainAddress.postal_code,
      country: mainAddress.country,
    };
    setValue(`address_list.${index}`, updatedAddress);
  };

  return (
    <>
      <Box sx={{ m: 4 }}>
        <Divider variant="fullWidth" />
      </Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          order={{ xs: 2, md: 1 }}
        >
          <Controller
            key={`address_type-${itemId}`}
            name={`address_list.${index}.address_type`}
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={addressOptions}
                title={t("contact.address.index")}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  if (option.value) {
                    return field.onChange(option.value);
                  }
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          order={{ xs: 1, md: 2 }}
        >
          {!viewOnly && index !== 0 && (
            <IconButton
              aria-label="delete"
              onClick={() => remove(index)}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={10} sm={10} md={4} lg={4} xl={4} order={{ xs: 3 }}>
          <Controller
            key={`same_address-${itemId}`}
            name={`address_list.${index}.same_as_default_address`}
            control={control}
            render={({ field }) => (
              <CustomizedCheckboxes
                label={"เหมือนที่อยู่จดทะเบียน"}
                {...field}
                onChange={(e, selected) => {
                  if (selected) {
                    autofillAddress(index);
                  }
                  return field.onChange(selected);
                }}
                isDisabled={viewOnly}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`full_name-${itemId}`}
              name={`address_list.${index}.address_contact_name`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(
                    errors.address_list?.[index]?.address_contact_name
                  )}
                  helperText={
                    errors.address_list?.[index]?.address_contact_name?.message
                  }
                  label={t("contact.address.contact_person_name")}
                  {...field}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`phone_number-${itemId}`}
              name={`address_list.${index}.address_contact_phone`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(
                    errors.address_list?.[index]?.address_contact_phone
                  )}
                  helperText={
                    errors.address_list?.[index]?.address_contact_phone?.message
                  }
                  label={t("contact.address.phone_number")}
                  {...field}
                  disabled={viewOnly}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              key={`address-${itemId}`}
              name={`address_list.${index}.address`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.address_list?.[index]?.address)}
                  helperText={errors.address_list?.[index]?.address?.message}
                  label={t("contact.address.index")}
                  {...field}
                  disabled={viewOnly || isSameAddress[index]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`sub_district-${itemId}`}
              name={`address_list.${index}.sub_district`}
              control={control}
              render={({ field }) => {
                if (isSameAddress[index]) {
                  return (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(
                        errors.address_list?.[index]?.sub_district
                      )}
                      helperText={
                        errors.address_list?.[index]?.sub_district?.message
                      }
                      label={t("contact.address.sub_district")}
                      {...field}
                      disabled={true}
                    />
                  );
                }
                return (
                  <CustomizedCreatable
                    {...field}
                    options={subdistrictOptions}
                    title={t("contact.address.sub_district")}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        return;
                      } else {
                        setSubdistrict();
                        setDistrict();
                        setProvince();
                        return;
                      }
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      setSubdistrict(option);
                      const filter = addressData.filter((adddress) => {
                        return `${adddress.sub_district}` === `${option}`;
                      });
                      if (filter && filter.length !== 0) {
                        let fieldsValue = {
                          ...getValues("address_list")[index],
                          district: filter[0].district,
                          province: filter[0].province,
                          postal_code: filter[0].postal_code,
                          country: "ไทย",
                        };
                        setValue(`address_list.${index}`, fieldsValue);
                      } else if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    readOnly={viewOnly || isSameAddress[index]}
                    disabled={viewOnly || isSameAddress[index]}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`district-${itemId}`}
              name={`address_list.${index}.district`}
              control={control}
              render={({ field }) => {
                if (isSameAddress[index]) {
                  return (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.address_list?.[index]?.district)}
                      helperText={
                        errors.address_list?.[index]?.district?.message
                      }
                      label={t("contact.address.district")}
                      {...field}
                      disabled={true}
                    />
                  );
                }
                return (
                  <CustomizedCreatable
                    {...field}
                    options={districtOptions}
                    title={t("contact.address.district")}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        return;
                      } else {
                        setSubdistrict();
                        setDistrict();
                        setProvince();
                        return;
                      }
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      const filter = addressData.filter((adddress) => {
                        return `${adddress.district}` === `${option}`;
                      });
                      if (filter && filter.length !== 0) {
                        const filteredSubdistricts = filter.map(
                          (address) => address.sub_district
                        );
                        setSubdistrictOptions(filteredSubdistricts);
                        let fieldsValue = {
                          ...getValues("address_list")[index],
                          province: filter[0].province,
                          postal_code: filter[0].postal_code,
                          country: "ไทย",
                        };
                        setValue(`address_list.${index}`, fieldsValue);
                      } else if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    readOnly={viewOnly || isSameAddress[index]}
                    disabled={viewOnly || isSameAddress[index]}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`province-${itemId}`}
              name={`address_list.${index}.province`}
              control={control}
              render={({ field }) => {
                if (isSameAddress[index]) {
                  return (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.address_list?.[index]?.province)}
                      helperText={
                        errors.address_list?.[index]?.province?.message
                      }
                      label={t("contact.address.province")}
                      {...field}
                      disabled={true}
                    />
                  );
                }
                return (
                  <CustomizedCreatable
                    {...field}
                    options={provinceOptions}
                    title={t("contact.address.province")}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "reset") {
                        return;
                      } else {
                        setSubdistrict();
                        setDistrict();
                        setProvince();
                        return;
                      }
                    }}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      setProvince(option);
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      setValue(`address_list.${index}.country`, "ไทย");
                      return field.onChange(option);
                    }}
                    readOnly={viewOnly || isSameAddress[index]}
                    disabled={viewOnly || isSameAddress[index]}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`postal_code-${itemId}`}
              name={`address_list.${index}.postal_code`}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.address_list?.[index]?.postal_code)}
                  helperText={
                    errors.address_list?.[index]?.postal_code?.message
                  }
                  label={t("contact.address.postal_code")}
                  {...field}
                  disabled={viewOnly || isSameAddress[index]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`country-${itemId}`}
              name={`address_list.${index}.country`}
              control={control}
              render={({ field }) => {
                if (isSameAddress[index]) {
                  return (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.address_list?.[index]?.country)}
                      helperText={
                        errors.address_list?.[index]?.country?.message
                      }
                      label={t("contact.address.country")}
                      {...field}
                      disabled={true}
                    />
                  );
                }
                return (
                  <CustomizedCreatable
                    {...field}
                    options={countryOptions}
                    title={t("contact.address.country")}
                    onChange={(e, option) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      if (option.value) {
                        return field.onChange(option.value);
                      }
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    readOnly={viewOnly || isSameAddress[index]}
                    disabled={viewOnly || isSameAddress[index]}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NewAddress;
