import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogContentText } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ManufactureOrderDetailForm from "../../../components/Form/Manufacture/Order/Detail";
import ManufactureOrderHeaderForm from "../../../components/Form/Manufacture/Order/Header";
import ManufactureOrderRemarkForm from "../../../components/Form/Manufacture/Order/Remark";
import ManufactureOrderTableForm from "../../../components/Form/Manufacture/Order/Table";
import LowerQtyConfirmation from "../../../components/UI/Confirmation/LowerQtyConfirmation";
import ModalUI from "../../../components/UI/ModalUI";
import {
  createManufactureOrder,
  getManufactureOrderById,
  updateManufactureOrder,
} from "../../../features/Manufacture/ManufactureOrder/manufacture-order-actions";
import {
  manufactureOrderInitialState,
  manufactureOrderValidation,
} from "../../../features/Manufacture/ManufactureOrder/manufacture-order-initial";
import { manufactureOrderActions } from "../../../features/Manufacture/ManufactureOrder/manufacture-order-slice";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import ManufactureService from "../../../services/Manufacture";
import { unixToDate } from "../../../utils/date-converter";
import AcceptConfirmation from "../../../components/UI/Confirmation/AcceptConfirmation";

const DocumentInfoTab = () => {
  const { user } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const { bom } = useSelector((state) => state.bom);
  const [roundedProductionQty, setRoundedProductionQty] = useState(0);
  const [openLowerQtyConfirmation, setOpenLowerQtyConfirmation] =
    useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [openChangeitemModal, setOpenChangeItemModal] = useState(false);
  const [openAcceptConfirmation, setOpenAcceptConfirmation] = useState(false);
  const { createPermission, editPermission } = usePermission();
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      ...manufactureOrderInitialState.manufactureOrder,
      created_date: unixToDate(
        manufactureOrderInitialState.manufactureOrder.created_date
      ),
      issue_date: unixToDate(
        manufactureOrderInitialState.manufactureOrder.issue_date
      ),
      expect_date: unixToDate(
        manufactureOrderInitialState.manufactureOrder.expect_date
      ),
      expect_product_date: unixToDate(
        manufactureOrderInitialState.manufactureOrder.expect_product_date
      ),
      delivery_date: manufactureOrderInitialState.manufactureOrder.delivery_date
        ? unixToDate(
            manufactureOrderInitialState.manufactureOrder.delivery_date
          )
        : null,
    },
    resolver: yupResolver(manufactureOrderValidation),
  });

  const {
    fields: ingredientFields,
    append: ingredientAppend,
    replace: ingredientReplace,
    remove: ingredientRemove,
    update: ingredientUpdate,
  } = useFieldArray({
    control,
    name: "ingredient_list",
  });

  const {
    fields: workOrderFields,
    append: workOrderAppend,
    remove: workOrderRemove,
    update: workOrderUpdate,
  } = useFieldArray({
    control,
    name: "work_order_list",
    keyName: "uid",
  });

  const { enqueueSnackbar } = useSnackbar();

  const status = useWatch({
    control,
    name: "render_status",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { manufactureOrder } = useSelector((state) => state.manufactureOrder);

  useEffect(() => {
    if (id) {
      dispatch(getManufactureOrderById(id, enqueueSnackbar));
    }
    return () => {
      dispatch(manufactureOrderActions.resetManufactureOrder());
    };
  }, [dispatch, enqueueSnackbar, id]);

  useEffect(() => {
    if (id) {
      reset({
        ...manufactureOrder,
        created_date: unixToDate(manufactureOrder.created_date),
        issue_date: unixToDate(manufactureOrder.issue_date),
        expect_date: unixToDate(manufactureOrder.expect_date),
        expect_product_date: unixToDate(manufactureOrder.expect_product_date),
        delivery_date: manufactureOrder.delivery_date
          ? unixToDate(manufactureOrder.delivery_date)
          : null,
        work_order_list: manufactureOrder.work_order_list.map((list) => ({
          ...list,
          start_date_time: unixToDate(list.start_date_time),
        })),
      });
    } else if (state) {
      if (state.isCopied) {
        const {
          render_status,
          id,
          document_id,
          creator_document_id,
          created_by,
          isCopied,
          work_order_list,
          ingredient_list,
          ...payload
        } = state;

        const formatWorkOrderList = work_order_list.map(
          ({ id, status, ...workOrder }) => ({
            ...workOrder,
            start_date_time: moment.unix(workOrder.start_date_time),
          })
        );

        reset({
          ...payload,
          created_date: moment().startOf("day"),
          issue_date: moment().startOf("day"),
          expect_date: moment().endOf("day").add(7, "days"),
          expect_product_date: moment().endOf("day").add(7, "days"),
          // delivery_date: moment().endOf("day").add(7, "days"),
          work_order_list: formatWorkOrderList,
          ingredient_list: ingredient_list.map((list) => ({
            ...list,
            goods_issue_qty: 0,
          })),
          under_quality_item_list: [],
        });
      } else {
        const {
          reference_document_type,
          isCopied,
          ingredient_list,
          ...payload
        } = state;
        reset({
          ...payload,
          created_date: moment().startOf("day"),
          issue_date: moment().startOf("day"),
          expect_date: moment().endOf("day").add(7, "days"),
          expect_product_date: moment().endOf("day").add(7, "days"),
        });
        ingredientReplace(ingredient_list);
      }
    }
  }, [id, ingredientReplace, manufactureOrder, reset, state]);

  useEffect(() => {
    switch (status) {
      case "pendingManu":
      case "inProgress":
      case "finished":
      case "cancelled":
        setShowButton(false);
        setDisabled(true);
        break;
      default:
        setDisabled(false);
        setShowButton(true);
    }
  }, [status]);

  const watchBom = useWatch({
    control,
    name: "bom_detail",
  });

  useEffect(() => {
    if (watchBom) {
      const { mfg_qty } = watchBom;
      if (mfg_qty && mfg_qty > 0) {
        const currentProductionQty = getValues("production_qty")
          ? parseInt(getValues("production_qty"))
          : 0;
        const ratio = currentProductionQty / mfg_qty;
        const roundedRatio = Math.ceil(ratio);
        const recommendProductionQty = roundedRatio * mfg_qty;
        setRoundedProductionQty(recommendProductionQty);
      }
    }
  }, [getValues, watchBom]);

  const halfRoundDecimal = (qty) => {
    const newQty = qty.toFixed(4);
    const absNumber = Math.floor(qty);
    const decimal = parseFloat(newQty) - absNumber;
    if (decimal === 0) return absNumber;
    else if (decimal < 0.5) return absNumber + 0.5;
    else return Math.ceil(qty);
  };

  const ingredientQtyCalculation = useCallback(() => {
    if (bom.name) {
      const { ingredient_list, mfg_qty } = bom;
      if (mfg_qty && mfg_qty > 0) {
        const currentProductionQty = getValues("production_qty")
          ? parseFloat(getValues("production_qty"))
          : 0;
        const ratio = currentProductionQty / mfg_qty;
        const roundedRatio = Math.ceil(ratio);
        const recommendProductionQty = roundedRatio * mfg_qty;
        setRoundedProductionQty(recommendProductionQty);

        ingredient_list.forEach((ingredient) => {
          const foundIndex = ingredientFields.findIndex(
            (bomIngredient) => bomIngredient.uid === ingredient.uid
          );

          if (foundIndex !== -1) {
            const qty = ratio * ingredient.qty;
            const roundedQty = halfRoundDecimal(qty);

            ingredientUpdate(foundIndex, {
              ...ingredient_list[foundIndex],
              qty: roundedQty,
              snapshot_qty: roundedQty,
              unrounded_qty: qty,
              goods_issue_qty: ingredientFields[foundIndex].goods_issue_qty,
            });
          }
        });
      }
    } else {
      const { ingredient_list, mfg_qty } = getValues("bom_detail");
      if (mfg_qty && mfg_qty > 0) {
        const currentProductionQty = getValues("production_qty")
          ? parseFloat(getValues("production_qty"))
          : 0;
        const ratio = currentProductionQty / mfg_qty;
        const roundedRatio = Math.ceil(ratio);
        const recommendProductionQty = roundedRatio * mfg_qty;
        setRoundedProductionQty(recommendProductionQty);

        ingredient_list.forEach((ingredient) => {
          const foundIndex = ingredientFields.findIndex(
            (bomIngredient) => bomIngredient.uid === ingredient.uid
          );

          if (foundIndex !== -1) {
            const qty = ratio * ingredient.qty;
            const roundedQty = halfRoundDecimal(qty);

            ingredientUpdate(foundIndex, {
              ...ingredient_list[foundIndex],
              qty: roundedQty,
              snapshot_qty: roundedQty,
              unrounded_qty: qty,
              goods_issue_qty: ingredientFields[foundIndex].goods_issue_qty,
            });
          }
        });
      }
    }
  }, [bom, getValues, ingredientFields, ingredientUpdate]);

  useEffect(() => {
    if (bom.name) {
      ingredientQtyCalculation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bom.name]);

  const checkLowerQtyModal = (data) => {
    const isLower = data?.ingredient_list?.some(
      (ingredient) =>
        parseInt(ingredient.qty) < parseInt(ingredient.snapshot_qty)
    );
    return isLower;
  };

  const closeLowerQtyConfirmationHandler = () => {
    setOpenLowerQtyConfirmation(false);
  };

  const closeChangeItemHandler = () => {
    setOpenChangeItemModal(false);
  };

  const openAcceptConfirmationHandler = () => {
    setOpenAcceptConfirmation(true);
  };

  const closeAcceptConfirmationHandler = () => {
    setOpenAcceptConfirmation(false);
  };

  const acceptConfirmationAction = async () => {
    const data = getValues();
    const newData = { ...data, actual_production_qty: data.production_qty };

    if (!id) {
      dispatch(
        createManufactureOrder(
          newData,
          true,
          state,
          user,
          enqueueSnackbar,
          navigate
        )
      );
    } else {
      dispatch(
        updateManufactureOrder(
          id,
          status === "draft" ? newData : data,
          true,
          manufactureOrder,
          user,
          enqueueSnackbar
        )
      );
    }
    closeAcceptConfirmationHandler();
  };

  const lowerQtyConfirmationAction = async (data) => {
    if (!data.render_status || data.render_status === "draft") {
      if (changeStatus) {
        submitHandler(data, true);
      } else {
        saveHandler(data, true);
      }
    } else {
      saveEditHandler(data, true);
    }

    closeLowerQtyConfirmationHandler();
  };

  const saveHandler = async (data, isAcceptQty) => {
    setChangeStatus(false);
    const bom = await ManufactureService.getBomById(data.bom_id);
    if (
      bom.item_document_id !== data.item_document_id &&
      data.render_status === "draft"
    ) {
      setOpenChangeItemModal(true);
    } else {
      const isLower = checkLowerQtyModal(data);
      if (isAcceptQty || !isLower) {
        if (!id) {
          dispatch(
            createManufactureOrder(
              data,
              false,
              state,
              user,
              enqueueSnackbar,
              navigate
            )
          );
        } else {
          dispatch(
            updateManufactureOrder(
              id,
              data,
              false,
              manufactureOrder,
              user,
              enqueueSnackbar
            )
          );
        }
      } else {
        setOpenLowerQtyConfirmation(true);
      }
    }
  };

  const submitHandler = async (data, isAcceptQty) => {
    setChangeStatus(true);
    const bom = await ManufactureService.getBomById(data.bom_id);
    if (
      bom.item_document_id !== data.item_document_id &&
      data.render_status === "draft"
    ) {
      setOpenChangeItemModal(true);
    } else {
      const isLower = checkLowerQtyModal(data);
      if (isAcceptQty || !isLower) {
        openAcceptConfirmationHandler(true);
      } else {
        setOpenLowerQtyConfirmation(true);
      }
    }
  };

  const editClickHandler = () => {
    setEditMode(true);
    setDisabled(false);
  };

  const saveEditHandler = async (data, isAcceptQty) => {
    const bom = await ManufactureService.getBomById(data.bom_id);
    if (
      bom.item_document_id !== data.item_document_id &&
      data.render_status === "draft"
    ) {
      setOpenChangeItemModal(true);
    } else {
      const isLower = checkLowerQtyModal(data);
      if (isAcceptQty || !isLower) {
        dispatch(
          updateManufactureOrder(
            id,
            data,
            false,
            manufactureOrder,
            user,
            enqueueSnackbar
          )
        );
      } else {
        setOpenLowerQtyConfirmation(true);
      }
    }
    setDisabled(true);
    setEditMode(false);
  };

  const copyManufactureHandler = () => {
    navigate("/manufacture/order/add", {
      state: { ...manufactureOrder, isCopied: true },
    });
  };

  useEffect(() => {
    if (errors.document_id) {
      enqueueSnackbar(errors.document_id.message, {
        variant: "error",
      });
    }
    if (errors.customer) {
      enqueueSnackbar(errors.customer.message, {
        variant: "error",
      });
    }
    if (errors.item_document_id) {
      enqueueSnackbar(errors.item_document_id.message, {
        variant: "error",
      });
    }
    if (errors.production_qty) {
      enqueueSnackbar(errors.production_qty.message, {
        variant: "error",
      });
    }
    if (errors.bom_name) {
      enqueueSnackbar(errors.bom_name.message, {
        variant: "error",
      });
    }
    if (errors.ingredient_list) {
      if (errors.ingredient_list.message) {
        enqueueSnackbar(errors?.ingredient_list?.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(errors?.ingredient_list[0]?.qty?.message, {
          variant: "error",
        });
      }
    }
    if (errors.work_order_list) {
      if (errors.work_order_list.some((list) => list.machine.message)) {
        enqueueSnackbar("กรุณาระบุศูนย์การผลิต", {
          variant: "error",
        });
      }
    }
  }, [
    enqueueSnackbar,
    errors.bom_name,
    errors.customer,
    errors.ingredient_list,
    errors.item_document_id,
    errors.document_id,
    errors.production_qty,
    errors.work_order_list,
  ]);

  return (
    <form>
      <ManufactureOrderHeaderForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={
          disabled || (!createPermission && !id) || (!editPermission && id)
        }
        editClickHandler={editClickHandler}
        copyManufactureHandler={copyManufactureHandler}
        currentState={state?.id ?? manufactureOrder}
        isEdit={editMode}
      />
      <ManufactureOrderDetailForm
        control={control}
        errors={errors}
        setValue={setValue}
        reset={reset}
        ingredientReplace={ingredientReplace}
        roundedProductionQty={roundedProductionQty}
        ingredientQtyCalculation={ingredientQtyCalculation}
        disabled={
          disabled || (!createPermission && !id) || (!editPermission && id)
        }
      />
      <ManufactureOrderTableForm
        control={control}
        errors={errors}
        setValue={setValue}
        ingredientFields={ingredientFields}
        ingredientAppend={ingredientAppend}
        ingredientRemove={ingredientRemove}
        workOrderFields={workOrderFields}
        workOrderAppend={workOrderAppend}
        workOrderRemove={workOrderRemove}
        workOrderUpdate={workOrderUpdate}
        disabled={
          disabled || (!createPermission && !id) || (!editPermission && id)
        }
      />
      <ManufactureOrderRemarkForm
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={
          disabled || (!createPermission && !id) || (!editPermission && id)
        }
      />
      {showButton && ((createPermission && !id) || (editPermission && id)) && (
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.saveDraft")}
            variant="outlined"
            onClick={handleSubmit((data) => saveHandler(data, false))}
          />
          <CustomizedButton
            variant="contained"
            title="ยืนยัน"
            onClick={handleSubmit((data) => submitHandler(data, false))}
          />
        </Box>
      )}
      {editMode && (
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={() => navigate("/manufacture/order")}
          />
          <CustomizedButton
            variant="contained"
            title="บันทึก"
            onClick={handleSubmit((data) => saveEditHandler(data, false))}
          />
        </Box>
      )}
      <LowerQtyConfirmation
        openLowerQtyConfirmation={openLowerQtyConfirmation}
        closeLowerQtyConfirmationHandler={closeLowerQtyConfirmationHandler}
        lowerQtyConfirmationAction={handleSubmit((data) =>
          lowerQtyConfirmationAction(data)
        )}
      />
      <AcceptConfirmation
        openAcceptConfirmation={openAcceptConfirmation}
        acceptConfirmationAction={acceptConfirmationAction}
        closeAcceptConfirmationHandler={closeAcceptConfirmationHandler}
      />
      <ModalUI
        open={openChangeitemModal}
        title="ไม่สามารถบันทึกใบสั่งผลิตได้ เนื่องจากสูตรมีการเปลี่ยนแปลง"
        handleClose={closeChangeItemHandler}
        maxWidth="sm"
      >
        <DialogContentText>
          กรุณาเลือกสูตรการผลิตใหม่ที่อ้างอิงถึงสินค้าที่ต้องการผลิต
        </DialogContentText>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 1, gap: 1 }}
        >
          <CustomizedButton
            title={"ยกเลิก"}
            variant="outlined"
            size="medium"
            onClick={closeChangeItemHandler}
          />
          <CustomizedButton
            title={"ยืนยัน"}
            variant="contained"
            size="medium"
            onClick={closeChangeItemHandler}
          />
        </Box>
      </ModalUI>
    </form>
  );
};

export default DocumentInfoTab;
