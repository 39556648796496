import moment from "moment";
import { Box, Grid, Typography } from "@mui/material";
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatExportQuotes } from "../../../utils/exporter";

import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import NewReportDateFilter from "../../../components/UI/NewReportDateFilter";
import ControlledSelect from "../../../components/Controlled/ControlledSelect";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GoodsReceiveReportTable from "../../../components/Table/DocumentTable/GoodsReceiveReportTable";

import GlobalService from "../../../services/Global";
import InventoryService from "../../../services/Inventory";

import { getNewGoodsReceiveReport } from "../../../features/Inventory/Report/report-actions";
import useGoodsReceiveColumnDef from "../../../hooks/Inventory/useGoodsReceiveColumnDef";
import { goodsReceiveActions } from "../../../features/Inventory/GoodsReceive/goodsReceive-slice";

import {
  dateToUnix,
  formatDateAgGrid,
  formatDateExport,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import {
  filterDateWithUnix,
  filterStatusValueFormatter,
} from "../../../utils/filterparams";
import TimeStampReportLayout from "../../../components/UI/TimeStampReportLayout";

const typeFilter = ["วันที่ออกเอกสาร", "วันที่นำเข้า"];

const defaultDate = {
  type: typeFilter[0],
  dateType: "lastWeek",
  date: new Date(moment().startOf("day").subtract(6, "day")),
  dateTo: new Date(moment().endOf("day")),
};

const GoodsReceiveReportView = () => {
  const { isLoading } = useSelector((state) => state.goodsReceive);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const [filename, setFilename] = useState("");
  const [data, setData] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [fetchTimestamp, setFetchTimestamp] = useState(null);
  const csvInstance = useRef(null);
  const [showTable, setShowTable] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [detail, setDetail] = useState(null);
  const [genDetail, setGenDetail] = useState(null);

  const columnDef = useGoodsReceiveColumnDef(true);

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.receive.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: defaultDate,
  });

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const { document_date, created_date } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        document_date: document_date && filterDateWithUnix(document_date),
        created_date: created_date && filterDateWithUnix(created_date),
      };

      dispatch(
        getNewGoodsReceiveReport(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      let startDate = getValues("date");
      let endDate = getValues("dateTo");
      let type = getValues("type");

      const document_date = params.api.getFilterInstance("document_date");
      const created_date = params.api.getFilterInstance("created_date");
      if (type === "วันที่นำเข้า")
        document_date?.setModel({
          filterType: "date",
          type: "inRange",
          dateFrom: formatDateAgGrid(startDate),
          dateTo: formatDateAgGrid(endDate),
        });
      if (type === "วันที่ออกเอกสาร")
        created_date?.setModel({
          filterType: "date",
          type: "inRange",
          dateFrom: formatDateAgGrid(startDate),
          dateTo: formatDateAgGrid(endDate),
        });
      params.api.onFilterChanged();
      setFilename(
        `${
          t("inventory.report") + t("inventory.receive.index")
        }_${formatDateExport(startDate)}_${formatDateExport(endDate)}.csv`
      );
    },
    [getValues, t]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const getFiltered = async () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
    const { timestamp, in_queue_timestamp, detail } =
      await GlobalService.getReportViewTimestamp("GoodsReceive");
    setDetail(detail);
    setLastTimestamp(timestamp);
    setFetchTimestamp(
      in_queue_timestamp ? in_queue_timestamp.timestamp * 1000 : null
    );
    setGenDetail(null);
  };

  const exportAllDataAsAgGridCsv = async () => {
    try {
      const filterModel = gridRef.current.api.getFilterModel();
      const sortModel = gridRef.current.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const { document_date, created_date } = filterModel;

      const formatFilterModel = {
        ...filterModel,
        document_date: document_date && filterDateWithUnix(document_date),
        created_date: created_date && filterDateWithUnix(created_date),
      };

      dispatch(goodsReceiveActions.onLoading("allItems"));
      const { results: goodsReceiveReport } =
        await InventoryService.getAllNewGoodsReceiveReport({
          startRow: 0,
          endRow: Math.pow(10, 5),
          filterModel: formatFilterModel,
          sortModel,
        });

      const formatValue = await goodsReceiveReport.map(
        ({ document_date, created_date, status, lot, ...other }) => ({
          ...other,
          document_date: document_date
            ? unixToDateWithFormat(document_date)
            : "-",
          created_date: created_date ? unixToDateWithFormat(created_date) : "-",
          lot: lot ? unixToDateWithFormat(lot) : "-",
          status: filterStatusValueFormatter(status),
        })
      );
      setData(formatExportQuotes(formatValue));
      dispatch(goodsReceiveActions.onLoaded("allItems"));
      setTimeout(() => {
        if (csvInstance.current) {
          csvInstance.current.link.click();
        }
      });
      enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error exporting CSV:", error);
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const columnDefHeader = columnDef.map((col) => ({
    label: col.headerName,
    key: col.field,
  }));

  const generateReportHandler = async () => {
    setFetching(true);
    let type = getValues("type");
    let endDate = getValues("dateTo");
    let startDate = getValues("date");

    const { status, timestamp, detail } = await GlobalService.generateReport(
      "GoodsReceive",
      {
        date_field_name:
          type === "วันที่ออกเอกสาร" ? "created_date" : "document_date",
        end_date: dateToUnix(endDate),
        start_date: dateToUnix(startDate),
      }
    );
    setGenDetail(detail);
    setTimeout(() => {
      setFetching(false);
    }, [5000]);

    if (status === "success") {
      setFetchTimestamp(timestamp);
      enqueueSnackbar("ส่งคำขอสำเร็จ กรุณารอสักครู่", {
        variant: "success",
      });
    } else if (status === "error") {
      enqueueSnackbar(
        `ระบบกำลังดำเนินการ กรุณารอสักครู่\n(ขอล่าสุด ${moment(
          fetchTimestamp
        ).fromNow()})`,
        {
          variant: "warning",
          style: { whiteSpace: "pre-line" },
        }
      );
    }
  };

  const onFilterReset = () => {
    reset(defaultDate);
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          {t("inventory.report") + t("inventory.receive.index")}
        </Typography>
        <Box>
          <CustomizedButton
            title={t("inventory.exportReport")}
            variant="contained"
            onClick={exportAllDataAsAgGridCsv}
            disabled={isLoading.allItems}
          />
          <CSVLink
            headers={columnDefHeader}
            data={data}
            filename={filename}
            ref={csvInstance}
          />
        </Box>
      </Box>
      <CustomizedBox>
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2.5}>
              <ControlledSelect
                control={control}
                name="type"
                options={typeFilter}
                label={"ชื่อตัวกรอง"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1.5} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFiltered}
                disabled={isLoading.allItems}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1.5} alignSelf="center">
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.allItems}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </CustomizedBox>
      {showTable && (
        <TimeStampReportLayout
          detail={detail}
          genDetail={genDetail}
          fetching={fetching}
          generateReportHandler={generateReportHandler}
          lastTimestamp={lastTimestamp}
          fetchTimestamp={fetchTimestamp}
          documentType="gr"
        />
      )}
      {showTable && (
        <GoodsReceiveReportTable
          gridRef={gridRef}
          onGridReady={onGridReady}
          isReport
        />
      )}
    </>
  );
};

export default GoodsReceiveReportView;
