import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";

const DeleteActiveBarcodeConfirmation = ({
  payload,
  openDeleteConfirmation,
  comfirmationDeleteAction,
  closeModalHandler,
  handleCancelDocument,
}) => {
  return (
    <ModalUI
      title={
        payload.format === 0
          ? "รายการทั้งหมดถูกนำเข้าแล้ว ไม่สามารถสร้างเอกสารได้"
          : payload.format === 1
          ? `มีรายการที่ถูกนำเข้าแล้ว ${payload.list.length} รายการ ระบบจะนำรายการที่ซ้ำออก`
          : `รายการทั้งหมดถูกนำเข้าแล้ว คุณต้องการยกเลิกเอกสารหรือไม่`
      }
      open={openDeleteConfirmation}
      handleClose={closeModalHandler}
      maxWidth="xs"
      fullWidth
    >
      {payload.format === 1 && (
        <>
          <Typography>รายการ SN ที่นำออกมีดังนี้</Typography>
          <List sx={{ listStyleType: "disc", ml: 3 }}>
            {payload.list?.map(({ serial_number }, index) => {
              if (index > 5) return null;
              else
                return (
                  <ListItem sx={{ display: "list-item" }} key={index}>
                    {index > 4 ? "..." : serial_number}
                  </ListItem>
                );
            })}
          </List>
        </>
      )}
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        {/* <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeModalHandler}
        /> */}
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={
            payload.format === 0
              ? closeModalHandler
              : payload.format === 2
              ? handleCancelDocument
              : () => comfirmationDeleteAction()
          }
        />
      </Box>
    </ModalUI>
  );
};

export default DeleteActiveBarcodeConfirmation;
