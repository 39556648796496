import { Controller } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedCreatable from "../../../Custom/CustomizedCreatable";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import ModalUI from "../../../UI/ModalUI";
import addressData from "../../../../data/address.json";

const BillingAddressModal = ({
  control,
  errors,
  setValue,
  onSubmit,
  isOpen,
  onClose,
  viewOnly,
  setSubdistrict,
  setDistrict,
  setProvince,
  subdistrictOptions,
  districtOptions,
  provinceOptions,
}) => {
  const { t } = useTranslation();

  const countryOptions = ["ไทย"];

  return (
    <ModalUI open={isOpen} handleClose={onClose} fullWidth maxWidth="md">
      <Typography fontWeight="bold" sx={{ ml: 1, mb: 4 }}>
        ที่อยู่จดทะเบียน
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            key="billing-address"
            name="address"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.address)}
                helperText={errors.address?.message}
                label="ที่อยู่"
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="billing-subdistrict"
            name="sub_district"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={subdistrictOptions}
                title="แขวง/ตำบล"
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSubdistrict();
                    setDistrict();
                    setProvince();
                    return;
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setSubdistrict(option);
                  const filter = addressData.filter((adddress) => {
                    return `${adddress.sub_district}` === `${option}`;
                  });
                  if (filter && filter.length !== 0) {
                    setValue("district", filter[0].district);
                    setValue("province", filter[0].province);
                    setValue("postal_code", filter[0].postal_code);
                  } else if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="billing-district"
            name="district"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={districtOptions}
                title="เขต/อำเภอ"
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSubdistrict();
                    setDistrict();
                    setProvince();
                    return;
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setDistrict(option);
                  const filter = addressData.filter((adddress) => {
                    return `${adddress.district}` === `${option}`;
                  });
                  if (filter && filter.length !== 0) {
                    setValue("province", filter[0].province);
                  } else if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="billing-province"
            name="province"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={provinceOptions}
                title="จังหวัด"
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSubdistrict();
                    setDistrict();
                    setProvince();
                    return;
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setProvince(option);
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="billing-postal_code"
            name="postal_code"
            control={control}
            render={({ field }) => (
              <CustomizedTextField
                fullWidth
                error={Boolean(errors.postal_code)}
                helperText={errors.postal_code?.message}
                label="รหัสไปรษณีย์"
                {...field}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="billing-country"
            name="country"
            control={control}
            render={({ field }) => (
              <CustomizedCreatable
                {...field}
                options={countryOptions}
                title="ประเทศ"
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  if (option.value) {
                    return field.onChange(option.value);
                  }
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                readOnly={viewOnly}
                disabled={viewOnly}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
        <CustomizedButton
          title={t("button.cancel")}
          variant="outlined"
          size="medium"
          onClick={onClose}
          disabled={viewOnly}
        />
        <CustomizedButton
          title={t("button.save")}
          onClick={onSubmit}
          type="submit"
          variant="contained"
          size="medium"
          disabled={viewOnly}
        />
      </Box>
    </ModalUI>
  );
};

export default BillingAddressModal;
