import { useEffect, useState } from "react";

const useReportOptions = (type) => {
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (type === "type") {
      setColumnDefs([
        {
          label: "ทั้งหมด",
          value: "ทั้งหมด",
          ref: ["ทั้งหมด", "receive", "issue", "transfer", "adjustment"],
        },
        {
          label: "ซื้อ",
          value: "ซื้อ",
          ref: ["ทั้งหมด", "receive"],
        },
        {
          label: "ผลิต",
          value: "ผลิต",
          ref: ["ทั้งหมด", "receive"],
        },
        {
          label: "แปรรูป",
          value: "แปรรูป",
          ref: ["ทั้งหมด", "receive"],
        },
        {
          label: "รับคืนจากลูกค้า",
          value: "รับคืนจากลูกค้า",
          ref: ["ทั้งหมด", "receive"],
        },
        {
          label: "รับคืนจากผลิต",
          value: "รับคืนจากผลิต",
          ref: ["ทั้งหมด", "receive"],
        },
        {
          label: "ขาย",
          value: "ขาย",
          ref: ["ทั้งหมด", "issue"],
        },
        {
          label: "ขายออนไลน์",
          value: "ขายออนไลน์",
          ref: ["ทั้งหมด", "issue"],
        },
        {
          label: "ผลิตวัตถุดิบ",
          value: "ผลิตวัตถุดิบ",
          ref: ["ทั้งหมด", "issue"],
        },
        {
          label: "ผลิตแปรรูป",
          value: "ผลิตแปรรูป",
          ref: ["ทั้งหมด", "issue"],
        },
        {
          label: "ผลิตแปรรูป - PT",
          value: "ผลิตแปรรูป - PT",
          ref: ["ทั้งหมด", "issue"],
        },
        {
          label: "ส่งคืน",
          value: "ส่งคืน",
          ref: ["ทั้งหมด", "issue"],
        },
        {
          label: "สินค้าเสีย",
          value: "สินค้าเสีย",
          ref: ["ทั้งหมด", "issue"],
        },
        {
          label: "อื่นๆ",
          value: "อื่นๆ",
          ref: ["ทั้งหมด", "receive", "issue"],
        },
      ]);
    } else if (type === "refType") {
      setColumnDefs([
        {
          label: "ทั้งหมด",
          value: "ทั้งหมด",
        },
        {
          label: "นำเข้า",
          value: "receive",
        },
        {
          label: "นำออก",
          value: "issue",
        },
        {
          label: "โอนย้าย",
          value: "transfer",
        },
        {
          label: "ปรับปรุง",
          value: "adjustment",
        },
      ]);
    }
  }, [type]);
  return columnDefs;
};

export default useReportOptions;
