import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";

const RequireAuth = ({ allowedPermissions }) => {
  const { isAuthenticated, permissions } = useAuth();
  const location = useLocation();

  return isAuthenticated &&
    permissions &&
    permissions.find((permission) =>
      [...allowedPermissions, "ADMIN__ADMIN__ADMIN"]?.includes(permission)
    ) ? (
    <Outlet />
  ) : isAuthenticated ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
export default RequireAuth;
