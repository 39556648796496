import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import TraceEntryList from "./TraceEntryList";
import { formatNumber } from "../../../../../utils/dataTransformer";

const headers = [
  {
    label: "ลำดับ",
    width: 40,
  },
  {
    label: "วันที่และเวลา",
    width: 80,
  },
  {
    label: "รหัสสินค้า - ชื่อสินค้า",
    width: 130,
  },
  {
    label: "จำนวน",
    width: 45,
  },
  {
    label: "หน่วย",
    width: 50,
  },
  {
    label: "SN",
    width: 100,
  },
  {
    label: "สถานที่ต้นทาง",
    width: 90,
  },
  {
    label: "สถานที่ปลายทาง",
    width: 100,
  },
  {
    label: "ผู้สแกน",
    width: 60,
  },
  {
    label: "Barcode",
    width: 100,
  },
];

const GoodsTransferTablePDF = ({ fields, disabled }) => {
  const TotalPostedQuantity = () => {
    const totalQuantity =
      fields?.reduce(
        (prev, curr) => prev + parseFloat(curr?.posted_quantity),
        0
      ) || 0;
    return (
      <TableCell
        align="center"
        sx={{
          py: 1,
          px: 0.25,
        }}
      >
        <Typography fontSize={11}>
          {formatNumber(Math.abs(totalQuantity))}
        </Typography>
      </TableCell>
    );
  };

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, width: "100%", overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead sx={{ backgroundColor: "#E8F1E8" }}>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                align="center"
                key={index}
                sx={{
                  px: 0.25,
                  py: 1,
                }}
              >
                <Typography width={header.width} fontSize={11} fontWeight={600}>
                  {header.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row, index) => (
            <Fragment key={index}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>{index + 1}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>
                    {row.stock_entry_list?.item?.document_id +
                      " - " +
                      row.stock_entry_list?.item?.name}
                  </Typography>
                </TableCell>
                <TotalPostedQuantity />
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>
                    {row.stock_entry_list?.uom?.name}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                ></TableCell>
              </TableRow>
              <TraceEntryList
                nestFields={row.stock_entry_list.trace_entry_list}
                nestIndex={index}
                disabled={disabled}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GoodsTransferTablePDF;
