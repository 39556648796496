import { useEffect } from "react";
import { useAuth } from "../hooks/use-auth";
import { Navigate } from "react-router-dom";

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Navigate to="/login" replace />;
};

export default Logout;
