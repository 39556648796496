import { TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import {
  formatDateTime,
  unixToDateTimeWithFormat,
} from "../../../../../utils/date-converter";
import { formatNumber } from "../../../../../utils/dataTransformer";

const TraceEntryList = ({ nestIndex, disabled, nestFields }) => {
  return (
    <>
      {nestFields.map((trace, index) => (
        <TableRow key={trace.genId}>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          ></TableCell>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          >
            <Typography fontSize={11}>
              {trace.posted_date
                ? typeof trace.posted_date === "string"
                  ? formatDateTime(trace.posted_date)
                  : unixToDateTimeWithFormat(trace.posted_date)
                : null}
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          ></TableCell>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          >
            <Typography fontSize={11}>
              {formatNumber(Math.abs(trace.posted_quantity))}
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          >
            <Typography fontSize={11}>{trace.uom.name}</Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          >
            <Typography fontSize={11}> {trace.serial_number}</Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          >
            <Typography fontSize={11}>
              {trace.source_bin_location_document_id}
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          >
            <Typography fontSize={11}>
              {trace.destination_bin_location.document_id}
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          >
            <Typography fontSize={11}>
              {trace.created_by?.first_name + " " + trace.created_by?.last_name}
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              py: 1,
              px: 0.25,
            }}
          >
            <Typography fontSize={11}>{trace.batch_number}</Typography>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TraceEntryList;
